import { Navigate, useLocation, useParams } from 'react-router';

import Footer from 'components/Footer';
import { SearchBar } from 'components/knowledge-center/SearchBar';
import Header from 'components/navigation/Header';
import { useGlobal } from 'contexts/Global';
import { useArticle } from 'hooks/queries/support';
import Article from 'pages/authenticated/knowledge-center/Article';

export default function PublicArticle() {
  const location = useLocation();
  const { scrollContainer } = useGlobal();

  const params = useParams<{ article: string }>();
  const { isError } = useArticle(params.article);

  if (isError) return <Navigate to="/login" state={{ from: location }} />;

  return (
    <div id="authenticated-wrapper">
      <div id="sidebar" />
      <div id="page" className="knowledge-center">
        <Header />
        <div id="content-wrapper">
          <div id="content-outer">
            <div id="content-inner" className="knowledge-center-wrapper" ref={scrollContainer}>
              <div id="content">
                <SearchBar />
                <Article />
              </div>
              <Footer placement="right" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
