import NotificationInput from 'components/admin/NotificationForm';
import { useSaveNotification } from 'hooks/queries/notification';
import { useTranslate } from 'hooks/translate';

export default function NotificationNew() {
  const translateText = useTranslate();
  const { saveNotification } = useSaveNotification();

  return (
    <>
      <NotificationInput
        title={translateText('label', 'Notification')}
        initValues={{ title: '', summary: '', message: '' }}
        onSubmit={async values => await saveNotification(values)}
      />
    </>
  );
}
