import { useMutation, useQuery } from '@tanstack/react-query';
import { camelCase } from 'lodash';

import { getDefaultFilters, useFilters } from 'contexts/Filters';
import { useMessages } from 'contexts/Messages';
import {
  TABLE_KEY_CALL_INDEX,
  TABLE_KEY_CALL_REQUEST_INDEX,
  TYPE_CALL_TRACKING,
} from 'globals/constants';
import { Call, ServiceType } from 'globals/types';
import { useSetQueryData, useSetTableData, useTableData } from 'hooks/data';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';
import { ADMIN_CALL_HISTORY_EXPAND } from 'pages/authenticated/admin/logs/CallHistory';
import { CALL_HISTORY_EXPAND } from 'pages/authenticated/call-tracking/history/History';

export function useCall(id: number | null = null) {
  const { fetchData } = useFetch();

  const { getAppliedFilters } = useFilters();
  const tableKey = camelCase(TABLE_KEY_CALL_INDEX.join(' '));
  const filters = {
    ...getDefaultFilters(TYPE_CALL_TRACKING),
    previousPeriod: false,
    statusLef: true,
    custom: { expand: CALL_HISTORY_EXPAND },
  };
  const { bodyParams, queryParams } = getAppliedFilters(filters, tableKey, true);
  const callData = useTableData<Call>([...TABLE_KEY_CALL_INDEX, { ...bodyParams, ...queryParams }]);

  const { data, isFetching, isError, error } = useQuery({
    queryKey: ['call', id],
    queryFn: (): Promise<Call> =>
      fetchData('/call/' + id, { queryParams: { expand: CALL_HISTORY_EXPAND } }),
    enabled: id !== null,
    meta: { noError: true },
    initialData: () => callData?.find(c => c.id === id),
  });

  return { call: data, isLoading: isFetching, isError, error };
}

export function useUpdateMarking(type: ServiceType = TYPE_CALL_TRACKING) {
  const setQueryData = useSetQueryData();
  const setTableData = useSetTableData();
  const { fetchData } = useFetch();

  const { mutate, isPending } = useMutation({
    mutationFn: ({ id, marking }: { id: number; marking: boolean }) =>
      fetchData(
        `/${type === TYPE_CALL_TRACKING ? 'call' : 'call-request'}/${marking ? 'mark' : 'unmark'}`,
        {
          method: 'POST',
          queryParams: { id },
        },
      ),
    onSuccess: (data, variables) => {
      setTableData(
        type === TYPE_CALL_TRACKING ? TABLE_KEY_CALL_INDEX : TABLE_KEY_CALL_REQUEST_INDEX,
        oldData => {
          const item = oldData.find(i => i.id === variables.id);
          if (item) item.marked = variables.marking;
          return oldData;
        },
      );
      setQueryData<Call>(
        [type === TYPE_CALL_TRACKING ? 'call' : 'callRequest', variables.id],
        oldData => ({
          ...oldData,
          marked: variables.marking,
        }),
      );
    },
  });

  return { updateMarking: mutate, isLoading: isPending };
}

export function useCallNotes(type: ServiceType = TYPE_CALL_TRACKING) {
  const setQueryData = useSetQueryData();
  const { fetchData } = useFetch();

  const { mutateAsync: addCallNote } = useMutation({
    mutationFn: ({ itemId, note }: { itemId: number; note: string }) =>
      fetchData((type === TYPE_CALL_TRACKING ? '/call' : '/call-request') + '/add-note', {
        method: 'POST',
        queryParams: { id: itemId },
        bodyParams: { body: note },
      }),
    onSuccess: (data, variables) => {
      setQueryData<Call>(
        [type === TYPE_CALL_TRACKING ? 'call' : 'callRequest', variables.itemId],
        oldData => {
          oldData.notes.unshift(data);
          return oldData;
        },
      );
    },
  });

  const { mutateAsync: removeCallNote } = useMutation({
    mutationFn: ({ noteId }: { noteId: number; itemId: number }) =>
      fetchData((type === TYPE_CALL_TRACKING ? '/call' : '/call-request') + '/remove-note', {
        method: 'DELETE',
        queryParams: { id: noteId },
      }),
    onSuccess: (data, variables) => {
      setQueryData<Call>(
        [type === TYPE_CALL_TRACKING ? 'call' : 'callRequest', variables.itemId],
        oldData => ({
          ...oldData,
          notes: oldData.notes.filter(n => n.id !== variables.noteId),
        }),
      );
    },
  });

  return { addCallNote, removeCallNote };
}

export function useSendCallDetails(type: ServiceType = TYPE_CALL_TRACKING) {
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync: sendCallDetails } = useMutation({
    mutationFn: ({
      itemId,
      email,
      bcc,
      remarks,
    }: {
      itemId: number;
      email: string;
      bcc: string;
      remarks: string;
    }) =>
      fetchData((type === TYPE_CALL_TRACKING ? '/call' : '/call-request') + '/send-details', {
        method: 'POST',
        queryParams: { id: itemId },
        bodyParams: { email, bcc, comment: remarks.replace(/(?:\r\n|\r|\n)/g, '<br />') },
      }),
    onSuccess: (data, variables) => {
      const email =
        variables.email && variables.bcc
          ? variables.email + ', ' + variables.bcc
          : variables.email || variables.bcc;
      addSuccessMessage(
        type === TYPE_CALL_TRACKING
          ? translateText('message', 'Call details sent to: <span data-private>{email}</span>', {
              email,
            })
          : translateText(
              'message',
              'Call request details sent to: <span data-private>{email}</span>',
              { email },
            ),
      );
    },
  });

  return { sendCallDetails };
}

export function useAdminCall(id: number | null = null) {
  const { fetchData } = useFetch();
  const callData = useTableData<Call>(['callHistory', 'admin']);

  const { data, isFetching, isError, error } = useQuery({
    queryKey: ['call', id],
    queryFn: (): Promise<Call> =>
      fetchData('/call/' + id, { queryParams: { expand: ADMIN_CALL_HISTORY_EXPAND } }),
    enabled: id !== null,
    meta: { noError: true },
    initialData: () => callData?.find(c => c.id === id),
  });

  return { call: data, isLoading: isFetching, isError, error };
}
