import { useRef, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import ErrorFocus from 'components/ErrorFocus';
import Select from 'components/input/Select';
import { useOnClickOutside } from 'hooks/event';
import { useMultiUpdateDomain } from 'hooks/queries/domain';
import { useResellerMultiUpdate } from 'hooks/queries/reseller';
import { useAccountManagers } from 'hooks/queries/user';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation } from 'hooks/validation';

type Props = {
  type: 'domain' | 'reseller';
  selectedRows: number[];
  onSuccess?: () => void;
};

export default function ChangeAccountManager({ type, selectedRows, onSuccess }: Props) {
  const translateText = useTranslate();
  const { accountManagers } = useAccountManagers();
  const { domainMultiUpdate } = useMultiUpdateDomain();
  const { resellerMultiUpdate } = useResellerMultiUpdate();
  const { requiredSelect } = useCustomValidation();

  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => setShowDropdown(false));

  const validationSchema = Yup.object({
    accountManager: requiredSelect,
  });

  async function save(values: { accountManager: string }) {
    const updateFunction = type === 'domain' ? domainMultiUpdate : resellerMultiUpdate;
    const updateArray = selectedRows.map(id => ({
      pk: id,
      account_manager: values.accountManager,
    }));

    await updateFunction(updateArray).then(() => {
      setShowDropdown(false);
      if (onSuccess) onSuccess();
    });
  }

  return (
    <div ref={dropdownRef}>
      <button
        className="btn btn-lightblue"
        onClick={() => setShowDropdown(!showDropdown)}
        disabled={selectedRows.length === 0}
      >
        {translateText('label', 'Change account manager')}
      </button>
      {showDropdown && (
        <Formik
          initialValues={{ accountManager: 'select' }}
          onSubmit={save}
          validationSchema={validationSchema}
        >
          {({ values, handleChange, submitForm, errors }) => (
            <div className="multi-select-dropdown-wrapper">
              <ErrorFocus />
              <div className="multi-select-dropdown change-account-manager">
                <h3 className="bold">{translateText('label', 'Change account manager')}</h3>
                <div className="data">
                  <div className="data-row">
                    <div className="row-label">{translateText('label', 'Account manager')}</div>
                    <div className="row-input">
                      <Select
                        name="accountManager"
                        options={accountManagers ?? {}}
                        onChange={handleChange}
                        value={values.accountManager}
                        error={errors.accountManager}
                        addSelect
                      />
                    </div>
                  </div>
                </div>
                <div className="buttons right">
                  <button onClick={submitForm} className="btn btn-green">
                    {translateText('label', 'Save')}
                  </button>
                </div>
              </div>
            </div>
          )}
        </Formik>
      )}
    </div>
  );
}
