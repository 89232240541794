import { cloneElement, PropsWithChildren, ReactElement, useState } from 'react';

import { useMedia } from 'hooks/media';
import { useTranslate } from 'hooks/translate';
import Setup, { SetupProps } from './Setup';
import { slideInTransitionTime } from './SlideIn';

type Props = {
  sub: ReactElement<SetupProps | Props>;
  maxWidth: number;
  depth?: number;
} & SetupProps;

export default function SetupWithSub(props: PropsWithChildren<Props>) {
  const translateText = useTranslate();
  const [isClosing, setIsClosing] = useState(false);
  const subProps = props.sub.props;

  // The sub should always be wider than the main setup, so use this width
  // when the sub is open + 50px for the sidebar
  const currentWidth = subProps.show ? (subProps.maxWidth ?? 0) + 50 : props.maxWidth;
  const tooWide = useMedia(`(max-width: ${currentWidth}px)`);

  const depth = props?.depth ?? 0;
  function closeSub(closeTop = false, checkChanges = false) {
    if (
      subProps.isSaving ||
      (checkChanges &&
        subProps.hasChanges &&
        !window.confirm(
          translateText('message', 'You have unsaved changes! Are you sure you want to leave?'),
        ))
    ) {
      return;
    }

    // When you click the back button the current sub needs to close, but when you click outside of the setup,
    // the top sub needs to close. This loop finds the props of the top sub.
    let subToClose = subProps;
    if (closeTop) {
      while (subToClose.show) {
        if ('sub' in subToClose && subToClose.sub.props.show) subToClose = subToClose.sub.props;
        else break;
      }
    }

    subToClose.close();
    setIsClosing(true);
    setTimeout(() => setIsClosing(false), slideInTransitionTime);
  }

  let className = props.className ?? '';
  if (subProps.show) className += ' sub-open';

  return (
    <Setup
      {...props}
      className={className}
      removeClose={subProps.show || isClosing}
      style={{
        width: tooWide ? '100vw' : currentWidth, // make it full width on smaller screens
        ...(subProps.show && { paddingRight: tooWide ? 'calc(100vw - 50px)' : subProps.maxWidth }), // the main setup turns into a sidebar when the sub is open, the padding adds space for the content of the sub
        ...props.style, // place this last so it has priority over the styles above
      }}
      setupStyle={{
        width: tooWide ? '100vw' : props.maxWidth,
        ...(subProps.show && { overflow: 'hidden' }), // don't show a scrollbar in the sidebar
      }}
      backButton={
        <div className="back-button-wrapper">
          <button className="btn btn-lightblue close-sub" onClick={() => closeSub(false, true)}>
            <div className="arrow arrow-left big" />
          </button>
        </div>
      }
      subSlideIn={cloneElement(props.sub, {
        overlay: depth === 0 ? 'under' : false,
        wrapperClassName: 'is-sub',
        close: () => closeSub(true),
        style: { width: tooWide ? 'calc(100vw - 50px)' : subProps.maxWidth },
        setupStyle: { width: tooWide ? 'calc(100vw - 50px)' : subProps.maxWidth },
        depth: depth + 1,
      })}
    />
  );
}
