import { ReactElement, ReactNode } from 'react';
import { QueryKey } from '@tanstack/react-query';
import { Formik } from 'formik';
import { camelCase, isEqual } from 'lodash';

import Pagination from 'components/Pagination';
import Skeleton from 'components/Skeleton';
import Tooltip from 'components/Tooltip';
import { useHasAdminAccess } from 'hooks/access';
import { useChosenColumns } from 'hooks/queries/user';
import { useTranslate } from 'hooks/translate';
import ColumnSelector from './ColumnSelector';
import FilterRow from './FilterRow';
import Head from './Head';
import Row from './Row';

const MAX_LOADING_ROW_COUNT = 100 as const;

export type ColumnSettings<T = Record<string, unknown>> = {
  [P in keyof T]?: ColumnSetting<T, T[P]>;
};

export type ColumnSetting<T = Record<string, unknown>, V = unknown> = {
  // Pass the title of the column as a string or an object with header settings
  header?:
    | string
    | {
        name?: string | ReactElement; // TODO AC-9655 remove ReactElements // Title of the column. If not set, the title will be the db_column_name renamed to Db column name.
        tooltip?: string; // Add text as a (i) tooltip to the header
        className?: string; // Add a classname to the th
        sort?: boolean; // Makes header sortable
      };
  defaultSelected?: boolean; // If the user can manually select columns, this column is selected by default
  noNumberFormat?: boolean; // Number is not formatted with toLocaleString when true (this is always the case if the column name is 'id')
  search?: boolean | string | Record<string, string>; // If object adds a dropdown filter below the header, otherwise a search field. The search param is the column name or the given string.
  forceBoolean?: boolean; // Force a column to be handled as a boolean instead of 0 or 1
  keyValues?: Record<string, string | ReactElement>; // Set values for some keys, for example: { 0: 'anoniem' } in numbers will print anoniem instead of 0.
  // The column contains buttons
  button?: {
    text: string; // Text that will be inside the button
    onClick: (row: T, index: number) => void; // Function of the button when clicked
    className?: string; // Classname used to style the button
    disabled?: boolean; // Whether the button is disabled or not
  };
  // The column contains big dots
  bigDot?: {
    classes: Record<string, 'active' | 'inactive' | 'invited'>; // Which values need which big dot classes
    hover?: keyof T | Record<string, string>; // Give the column name in which the hover text can be found, or provide an object of the hover text for each value.
  };
  // The column contains dates. Use true if the date does not require any additional settings.
  date?:
    | boolean
    | {
        original?: string; // Give the format of the original date. If not set, the default will be used: YYYY-MM-DD HH:mm:ss
        includeSeconds?: boolean; // By default time is shown as HH:mm, if this is true it will be shown as HH:mm:ss
        excludeTime?: boolean; // Only show the date and not the time
        separateColumns?: boolean; // Use this if the date is separated over multiple columns (year, month, day)
        connector?: 'at' | 'till' | 'none'; // Give the connector between date and time, the default is 'at'. The connector will be shown translated.
      };
  customValue?: (value: V, row: T) => ReactNode; // Use this to return a custom value in the table cell, for example an element.
  customNumber?: (number: string) => string; // Use this to return a custom formatted number, for example with a percentage sign.
  viewCondition?: (row: T) => boolean; // Returns null instead of value when condition is false
  compareType?: 'time' | 'seconds' | 'star' | 'none'; // Which way the data needs to be compared when previous period is selected, use 'none' when the data should not be compared.
  emptyText?: string; // If the value is null, print this text. If not set, '-' is printed.
  className?: string; // Add a classname to the td
  private?: boolean; // If true, this cell will be masked in the PostHog recording
  valueAsTitle?: boolean; // If true, sets the value as title attribute
};

export type TableProps<T> = {
  tableKey?: QueryKey; // Unique key for the table, used to store filters and for react query
  data: T[]; // An array containing the table data
  previousData?: { data: T[]; column: string }; // An object containing previous data that needs to be compared with the regular data and the column on which the rows need to be matched
  columns: ColumnSettings<T>; // Pass the column name, as expected in the data, as object key
  onRowClick?: (row: T) => void; // Function for when a user clicks a table row
  canChooseColumns?: boolean; // Allow the user to manually select which columns are shown
  header?: string | ReactElement; // Add a h3 header to the table
  tooltip?: string; // Add text as a (i) tooltip to the table
  emptyText?: string; // Text to be shown when there is no data, default is a translation of 'No data'
  // Add the option to select rows in the table
  multiSelect?: {
    selected: (string | number)[]; // The selected rows in an array
    update: (row: T | number | string | T[] | string[] | number[]) => void; // Function to update the selected rows
    canSelectAll?: boolean; // Allow user to select all rows at once
    fullRow?: boolean; // When a row is selected return the full row instead of the ID
  };
  followNumber?: boolean; // Add a follow number in front of the row
  isResponsive?: boolean; // The table will collapse to "card view" on mobile
  noWhiteBlock?: boolean; // Do not add a white block around the table
  gridColumns?: number; // Number of columns table needs in the auto grid
  className?: string; // Add a classname to the table
  extraTopLeft?: ReactElement | null; // Add an element to the top left of the table
  extraTopRight?: ReactElement | null; // Add an element to the top right of the table
  isLoading?: boolean; // Show skeleton in the table
  // Add pagination to the table
  pagination?: {
    show: boolean; // Whether to show the pagination element
    hideLimit?: boolean; // Whether to show the limit selector
    page: number; // The current page
    pageCount: number; // The total number of pages
    pageLimit: number; // The limit of items per page
    itemCount: number; // The total number of items
    update: (page: number, limit: number) => void; // Update the page or limit
  };
};

export default function Table<T extends Record<string, unknown>>(props: TableProps<T>) {
  const translateText = useTranslate();
  const isAdmin = useHasAdminAccess();

  const tableKey = camelCase(props.tableKey?.join(' '));
  const {
    chosenColumns,
    mutateChosenColumns,
    isLoading: isLoadingColumns,
  } = useChosenColumns(tableKey, !!props.canChooseColumns);

  const isLoading = !!props.isLoading || isLoadingColumns;

  function saveChosenColumns(columns: string[]) {
    if (!isEqual(columns, chosenColumns)) mutateChosenColumns(columns);
  }

  let rowCount = 5;
  if (props.pagination) {
    if (props.pagination.itemCount > props.pagination.pageLimit) {
      rowCount = props.pagination.pageLimit;
    }
    if (props.pagination.pageCount && props.pagination.pageCount === props.pagination.page) {
      rowCount =
        props.pagination.itemCount % props.pagination.pageLimit || props.pagination.pageLimit;
    }
  }

  const tableData: T[] = isLoading
    ? Array(Math.min(rowCount, MAX_LOADING_ROW_COUNT)).fill({})
    : props.data;

  let columns = props.columns;
  // This is only for the top 5 mediums table on the location report page, because the
  // columns are dependent on the API data
  if (columns.calculateHeaders) {
    const settings: Record<string, ColumnSetting<T>> = {};
    if (tableData.length !== 0 && tableData[0]) {
      Object.keys(tableData[0]).forEach(column => {
        if (!['calls', 'id'].includes(column)) settings[column] = {};
      });
    }
    columns = {
      id: { header: translateText('label', 'Location'), compareType: 'none' },
      ...settings,
    };
  }
  const columnsArray = Object.entries(columns); // do this so we can use .map

  let className = 'table';
  if (props.isResponsive) className += ' responsive-table';
  if (props.onRowClick) className += ' highlight-rows';
  if (props.className) className += ' ' + props.className;

  let rows: ReactElement[] = [];
  let extraColumns = 0;
  if (props.followNumber) extraColumns++;
  if (props.multiSelect) extraColumns++;

  if (tableData.length === 0) {
    rows = [
      <tr className="no-data" key="no-data">
        <td colSpan={columnsArray.length + extraColumns} className="no-data">
          {props.emptyText ?? translateText('label', 'No data')}
        </td>
      </tr>,
    ];
  } else {
    rows = tableData.map((row, rowIndex) => {
      const rowPrevious = props.previousData?.data.find(
        previousRow => row[props.previousData!.column] === previousRow[props.previousData!.column],
      );

      return (
        <Row
          key={rowIndex}
          row={row}
          previousData={props.previousData ? rowPrevious ?? ({} as T) : undefined}
          index={rowIndex}
          columns={columnsArray}
          isResponsive={!!props.isResponsive}
          onRowClick={props.onRowClick}
          multiSelect={props.multiSelect}
          isLoading={isLoading}
          followNumber={
            props.followNumber
              ? (props.pagination?.pageLimit ?? 0) * ((props.pagination?.page ?? 1) - 1) +
                rowIndex +
                1
              : null
          }
        />
      );
    });
  }

  return (
    <div
      className={'table-wrapper' + (!props.noWhiteBlock ? ' white-block' : '')}
      data-col={props.gridColumns}
    >
      {!isLoading && props.tooltip && (
        <div className="tooltip-i">
          <Tooltip text={props.tooltip} />
        </div>
      )}
      {props.header && (
        <h3 className="table-title">{isLoading ? <Skeleton width={200} /> : props.header}</h3>
      )}
      <Formik
        initialValues={{
          columns: props.canChooseColumns
            ? chosenColumns ?? Object.keys(columns).filter(col => columns[col]?.defaultSelected)
            : Object.keys(columns),
        }}
        enableReinitialize
        onSubmit={values => saveChosenColumns(values.columns)}
      >
        <div className="top-wrapper">
          {(props.extraTopRight || props.canChooseColumns || props.extraTopLeft) && (
            <div className="top-row">
              <div className="left">
                {props.canChooseColumns && (
                  <ColumnSelector id={tableKey} columns={columnsArray} isLoading={isLoading} />
                )}
                {props.extraTopLeft}
              </div>
              <div className="right">{props.extraTopRight}</div>
            </div>
          )}
          <div className="wrap-table">
            <table className={className}>
              <Head
                tableKey={tableKey}
                tableData={tableData}
                columns={columnsArray}
                multiSelect={props.multiSelect ?? null}
                followNumber={!!props.followNumber}
                rowCount={tableData.length}
                isLoading={!!props.isLoading}
              />
              <tbody>
                <FilterRow
                  tableKey={tableKey}
                  columns={columnsArray}
                  multiSelect={!!props.multiSelect}
                  followNumber={!!props.followNumber}
                  isResponsive={!!props.isResponsive}
                  isLoading={isLoading}
                />
                {rows}
              </tbody>
            </table>
          </div>
          {props.pagination?.show && (
            <Pagination
              activePage={props.pagination.page}
              itemsCountPerPage={props.pagination.pageLimit}
              totalItemsCount={props.pagination.itemCount}
              totalPageCount={props.pagination.pageCount}
              onChange={props.pagination.update}
              options={[5, 10, 20, 50, 100, ...(isAdmin ? [200, 1000] : [])]}
              hideLimitSelector={props.pagination.hideLimit}
            />
          )}
        </div>
      </Formik>
    </div>
  );
}
