import iconBGenius from 'assets/images/icons/integrations/b-genius.svg';
import IntegrationRequest from 'components/integrations/IntegrationRequest';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import { INTEGRATION_BGENIUS } from 'globals/constants';
import { SUP_ART_BGENIUS } from 'globals/support';
import { useHasAccessToIntegration } from 'hooks/access';
import { useTranslate } from 'hooks/translate';

export default function BGenius({ show, close }: BaseSlideInProps) {
  const translateText = useTranslate();
  const title = translateText('label', 'bGenius');
  const hasAccessToIntegration = useHasAccessToIntegration();

  if (!hasAccessToIntegration(INTEGRATION_BGENIUS)) return null;

  return (
    <IntegrationRequest
      show={show}
      title={title}
      icon={iconBGenius}
      infoText={translateText(
        'integration',
        'bGenius offers online marketers the opportunity to optimize and manage campaigns in different search engines in one system.',
      )}
      articleId={SUP_ART_BGENIUS}
      close={close}
      price={49}
    />
  );
}
