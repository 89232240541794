import { useEffect, useState } from 'react';

// if changed also change mixins.scss
const NAVIGATION_MEDIA_QUERY =
  '(max-width: 1023px), (min-width: 1024px) and (height <= 440px), (min-width: 1200px) and (height <= 550px)' as const;

export function useMedia(query: string): boolean {
  const getMatches = (query: string): boolean => {
    // Prevents SSR issues
    if (typeof window !== 'undefined') {
      return window.matchMedia(query).matches;
    }
    return false;
  };

  const [matches, setMatches] = useState(getMatches(query));

  function handleChange() {
    setMatches(getMatches(query));
  }

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    // Triggered at the first client-side load and if query changes
    handleChange();

    // Listen matchMedia
    matchMedia.addEventListener('change', handleChange);

    return () => {
      matchMedia.removeEventListener('change', handleChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return matches;
}

export function useIsMobileNavigation() {
  return useMedia(NAVIGATION_MEDIA_QUERY);
}

export function useIsMediumScreen() {
  return useMedia('(max-width: 1200px)');
}
