import icon from 'assets/images/icons/integrations/zoho.svg';
import BaseCrmIntegration from 'components/integrations/BaseCrmIntegration';
import { REDIRECT_URI_ZOHO } from 'components/oauth2/Verification';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import { INTEGRATION_ZOHO } from 'globals/constants';
import { useHasAccessToIntegration } from 'hooks/access';
import { useTranslate } from 'hooks/translate';

export default function Zoho({ show, close }: BaseSlideInProps) {
  const translateText = useTranslate();
  const hasAccessToIntegration = useHasAccessToIntegration();

  if (!hasAccessToIntegration(INTEGRATION_ZOHO)) return null;

  return (
    <BaseCrmIntegration
      show={show}
      close={close}
      icon={icon}
      integrationKey="zoho"
      redirectUriKey={REDIRECT_URI_ZOHO}
      title={translateText('label', 'Zoho')}
      infoText={translateText(
        'integration',
        'Zoho is a complete CRM platform. With the help of this integration, you can enrich the contact information in Zoho with the data of incoming calls.',
      )}
      verificationTooltip={translateText(
        'integration',
        'Grant access to the {integration} account so that the calls can be processed in {integration}.',
        { integration: translateText('label', 'Zoho') },
      )}
      price={99}
    />
  );
}
