import gif from 'assets/images/guided-tour/05-reports-config-integrations.gif';
import { useTranslate } from 'hooks/translate';
import BaseTour from './BaseTour';

export default function ReportsTour() {
  const translateText = useTranslate();
  return (
    <BaseTour
      title={translateText('guided-tour', 'Reports, configuration and integrations')}
      image={gif}
      description={
        <p
          dangerouslySetInnerHTML={{
            __html: translateText(
              'guided-tour',
              'In the sub-navigation you can view different <b>reporting pages</b> per service, go through the entire <b>configuration</b> and create <b>integrations</b>.',
            ),
          }}
        />
      }
    />
  );
}
