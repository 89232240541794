import { useQuery } from '@tanstack/react-query';

import { useTenant } from 'contexts/Tenant';
import { useFetch } from 'hooks/fetch';

type Source = {
  id: string;
  name: string;
  is_tracked: 'true' | 'false';
  has_call: 'true' | 'false';
};

export function useSources(params: Record<string, unknown> = {}) {
  const { fetchData } = useFetch();
  const { selectedDomain } = useTenant();

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ['source', 'domain', selectedDomain, params],
    queryFn: (): Promise<Source[]> =>
      fetchData('/source/domain', {
        queryParams: { domain: selectedDomain, has_call: true, ...params },
      }),
    enabled: selectedDomain !== null,
  });

  return { sources: data, isLoading };
}
