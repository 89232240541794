import { useState } from 'react';
import { Formik } from 'formik';

import ConfigurationCard from 'components/configuration/ConfigurationCard';
import GrayBars from 'components/GrayBars';
import Select from 'components/input/Select';
import Toggle from 'components/input/Toggle';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import SupportParagraph from 'components/SupportParagraph';
import { SUP_ART_CALL_RECORDING_CT } from 'globals/support';
import { useCallFunctionalities, useDomainAuth } from 'hooks/queries/domain';
import { useUpdateCallRecording } from 'hooks/queries/domain-setting';
import { useTranslate } from 'hooks/translate';

export default function CallRecording() {
  const translateText = useTranslate();
  const { updateCallRecording } = useUpdateCallRecording();
  const { callFunctionalities, isLoading: isLoadingCallFunctionalities } = useCallFunctionalities();
  const { isLoading: isLoadingDomainAuth } = useDomainAuth();
  const isLoading = isLoadingCallFunctionalities || isLoadingDomainAuth;
  const [showEdit, setShowEdit] = useState(false);

  const supportText = translateText(
    'call-tracking',
    'Enabling call recording will record all incoming calls established via Call Tracking.',
  );

  const callRecordingEnabled = callFunctionalities?.call_recording?.call_recording;
  const notificationEnabled = callFunctionalities?.call_recording?.notification !== 'FALSE';

  function getRows() {
    const rows: { key: string; status?: boolean; value?: string }[] = [
      { key: translateText('label', 'Call recording'), status: callRecordingEnabled },
    ];
    if (callRecordingEnabled) {
      rows.push({ key: translateText('label', 'Announcement'), status: notificationEnabled });
    }
    if (callRecordingEnabled && notificationEnabled) {
      let language = translateText('label', 'Dutch');
      switch (callFunctionalities.call_recording.notification) {
        case 'CALLRECORDING_DE':
          language = translateText('label', 'German');
          break;
        case 'CALLRECORDING_EN':
          language = translateText('label', 'English');
          break;
        case 'CALLRECORDING_FR':
          language = translateText('label', 'French');
          break;
      }
      rows.push({ key: translateText('label', 'Language'), value: language });
    }
    return rows;
  }

  async function save(values: { enabled: boolean; notification: string }) {
    await updateCallRecording(values).then(() => {
      setShowEdit(false);
    });
  }

  return (
    <>
      <ConfigurationCard
        title={translateText('label', 'Call recording')}
        supportArticleId={SUP_ART_CALL_RECORDING_CT}
        onEdit={() => setShowEdit(true)}
        isLoading={isLoading}
        rightParagraph={supportText}
      >
        <GrayBars rows={getRows()} isLoading={isLoading} />
      </ConfigurationCard>
      <Formik
        initialValues={{
          enabled: callRecordingEnabled ?? false,
          notification: callFunctionalities?.call_recording?.notification ?? 'CALLRECORDING',
        }}
        onSubmit={async values => await save(values)}
        enableReinitialize
      >
        {({ values, submitForm, resetForm, isSubmitting, dirty, handleChange }) => (
          <Setup
            show={showEdit}
            title={translateText('label', 'Call recording')}
            save={submitForm}
            close={() => setShowEdit(false)}
            afterClose={resetForm}
            isSaving={isSubmitting}
            maxWidth={410}
            hasChanges={dirty}
          >
            <p>{supportText}</p>
            <SupportParagraph articleId={SUP_ART_CALL_RECORDING_CT} />
            <div className="section">
              <InputWrapper label={translateText('label', 'Call recording')}>
                <Toggle
                  name="enabled"
                  checked={values.enabled}
                  onClick={handleChange}
                  disabled={isSubmitting}
                />
              </InputWrapper>
              {values.enabled && (
                <InputWrapper label={translateText('label', 'Announcement')}>
                  <Select
                    name="notification"
                    value={values.notification}
                    onChange={handleChange}
                    options={{
                      FALSE: translateText('label', 'Disabled'),
                      CALLRECORDING: translateText('label', 'Dutch'),
                      CALLRECORDING_EN: translateText('label', 'English'),
                    }}
                  />
                </InputWrapper>
              )}
            </div>
          </Setup>
        )}
      </Formik>
    </>
  );
}
