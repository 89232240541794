import { ReactElement, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { isEmpty } from 'lodash';

import TextField from 'components/input/TextField';
import Skeleton from 'components/Skeleton';
import { useEventListener, useOnClickOutside } from 'hooks/event';
import { useSearch } from 'hooks/queries/support';
import { useTranslate } from 'hooks/translate';

let timerId: NodeJS.Timeout | null = null;

export function SearchBar() {
  const translateText = useTranslate();
  const navigate = useNavigate();

  const [inputText, setInputText] = useState('');
  const [searchText, setSearchText] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const { searchResults, isLoading } = useSearch(searchText);
  const searchRef = useRef(null);

  useOnClickOutside(searchRef, () => setShowResults(false));
  useEventListener('keydown', e => handleShortcut(e as KeyboardEvent));

  function handleShortcut(e: KeyboardEvent) {
    if (e.key === 'ArrowUp') arrowPressed('up', e);
    if (e.key === 'ArrowDown') arrowPressed('down', e);
  }

  function arrowPressed(direction: 'up' | 'down', e: KeyboardEvent) {
    e.preventDefault();
    const elements = document.getElementsByClassName('results')[0].getElementsByClassName('item');
    const options = Array.prototype.slice.call(elements);
    const currentIndex = options.indexOf(document.activeElement);
    if (direction === 'up' && currentIndex <= 0) {
      (elements[elements.length - 1] as HTMLElement)?.focus();
    } else if (direction === 'up' && currentIndex - 1 >= 0) {
      (elements[currentIndex - 1] as HTMLElement)?.focus();
    } else if (direction === 'down' && currentIndex + 1 >= elements.length) {
      (elements[0] as HTMLElement)?.focus();
    } else if (direction === 'down' && currentIndex + 1 < elements.length) {
      (elements[currentIndex + 1] as HTMLElement)?.focus();
    }
  }

  function goTo(url: string) {
    setSearchText('');
    setInputText('');
    navigate(url);
  }

  function getResults() {
    if (!showResults || searchText === '') return null;

    if (isLoading || isTyping) {
      return (
        <div className="results load">
          <Skeleton count={5} />
        </div>
      );
    }

    if (isEmpty(searchResults)) {
      return (
        <div className="results none">
          {translateText('label', 'No results, try another search term.')}
        </div>
      );
    }

    const results: ReactElement[] = [];
    // Tab index is needed to handle the arrow key cycle
    let tabIndex = 0;
    Object.entries(searchResults).forEach(([key, searchResult]) => {
      results.push(
        <div
          tabIndex={tabIndex}
          key={key}
          className="item category"
          onClick={() =>
            goTo(`/knowledge-center/${searchResult.typeId}/category/${searchResult.categoryId}`)
          }
          onKeyUp={e => {
            if (e.key === 'Enter') {
              goTo(`/knowledge-center/${searchResult.typeId}/category/${searchResult.categoryId}`);
            }
          }}
        >
          {searchResult.typeLabel} / {searchResult.categoryLabel}
        </div>,
      );
      tabIndex++;

      searchResult.articles.forEach(article => {
        results.push(
          <div
            tabIndex={tabIndex}
            key={article.id}
            className="item"
            onClick={() => goTo(`/knowledge-center/article/${article.id}`)}
            onKeyUp={e => {
              if (e.key === 'Enter') goTo(`/knowledge-center/article/${article.id}`);
            }}
          >
            {article.title}
          </div>,
        );
        tabIndex++;
      });
    });

    return <div className="results">{results}</div>;
  }

  return (
    <div className="search-bar-wrapper" ref={searchRef} data-testid="search-bar">
      <TextField
        value={inputText}
        onChange={e => {
          if (timerId !== null) clearTimeout(timerId);
          setIsTyping(true);
          setShowResults(true);
          setInputText(e.target.value);
          timerId = setTimeout(() => {
            setSearchText(e.target.value);
            setIsTyping(false);
          }, 300);
        }}
        onFocus={() => setShowResults(true)}
        placeholder={translateText('label', 'Search within the knowledge center')}
      />
      {getResults()}
    </div>
  );
}
