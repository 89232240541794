import { ChangeEvent, useCallback, useRef, useState } from 'react';

import { ReactComponent as Pause } from 'assets/images/icons/pause-media-player.svg';
import { ReactComponent as Play } from 'assets/images/icons/play-media-player.svg';
import { useStateCallback } from 'hooks/effect';
import { useTranslate } from 'hooks/translate';

type Props = {
  audioData: string;
};

export default function AudioPlayer({ audioData }: Props) {
  const translateText = useTranslate();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const playButtonRef = useRef<HTMLButtonElement | null>(null);
  const frameRequest = useRef<ReturnType<typeof requestAnimationFrame> | null>(null);

  const audioRef = useCallback((node: HTMLAudioElement | null) => {
    // This ref is made with useCallback instead of useRef to make sure that the
    // component rerenders when the ref changes
    if (node !== null) setAudio(node);
  }, []);

  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useStateCallback(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  try {
    new Audio();
  } catch {
    return <p>{translateText('text', 'Your browser does not support the audio element.')}</p>;
  }

  function getAudioDuration() {
    if (audio?.duration && audio.duration !== Infinity && audio.duration !== duration) {
      setDuration(Math.floor(audio.duration));
    }
  }

  function onPlayButtonClick() {
    if (!audio) return;
    if (!isPlaying) {
      setIsPlaying(true, () => {
        audio.play();
        requestAnimationFrame(whilePlaying);
      });
    } else {
      setIsPlaying(false, () => {
        audio.pause();
        cancelAnimationFrame(frameRequest.current!);
      });
    }
  }

  function setSeekSliderWidth(value: number) {
    containerRef?.current?.style.setProperty('--seek-slider-width', (value / duration) * 100 + '%');
  }

  function onSeekSliderInput(e: ChangeEvent<HTMLInputElement>) {
    if (!audio) return;
    setCurrentTime(e.target.valueAsNumber);
    setSeekSliderWidth(e.target.valueAsNumber);
    audio.currentTime = e.target.valueAsNumber;
  }

  function whilePlaying() {
    if (!audio) return;
    const value = Math.floor(audio!.currentTime);
    if (value < duration) {
      setCurrentTime(value);
      setSeekSliderWidth(value);
      frameRequest.current = requestAnimationFrame(whilePlaying);
    } else {
      // This means the last second has started, so after a second we reset the player
      setCurrentTime(duration);
      setSeekSliderWidth(duration);
      cancelAnimationFrame(frameRequest.current!);
      setTimeout(() => {
        audio.currentTime = 0;
        setCurrentTime(0);
        setSeekSliderWidth(0);
        setIsPlaying(false);
      }, 1000);
    }
  }

  return (
    <div ref={containerRef} className="audio-player-container">
      <div className="play-and-seek-container">
        <audio
          id="audio"
          ref={audioRef}
          onContextMenu={e => e.preventDefault()}
          onLoadedData={getAudioDuration}
          onLoadedMetadata={getAudioDuration}
          controls
          controlsList="nodownload"
          preload="auto"
          hidden
        >
          <source src={`data:audio/wav;base64,${audioData}`} type="audio/wav" />
        </audio>
        <button
          id="control-icon"
          ref={playButtonRef}
          onClick={onPlayButtonClick}
          disabled={duration === 0}
          onMouseLeave={() => playButtonRef?.current?.blur()}
        >
          {isPlaying ? <Pause /> : <Play />}
        </button>
        <span id="current-time" className="time">
          {calculateTime(currentTime)}
        </span>
        <span className="separator"> / </span>
        <span id="duration" className="time">
          {calculateTime(duration)}
        </span>
        <input type="range" onInput={onSeekSliderInput} max={duration} value={currentTime} />
      </div>
    </div>
  );
}

function calculateTime(secs: number) {
  const minutes = Math.floor(secs / 60);
  const seconds = Math.floor(secs % 60);
  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
}
