export default {
    "A call request gets a status of 'Not reached' when a successful call was not established because the phone number was not valid or the call request was stopped.": "Een terugbelverzoek krijgt de status \u2018Niet bereikt\u2019 wanneer er geen succesvolle oproep tot stand is gebracht, omdat het telefoonnummer niet valide was of het terugbelverzoek werd stopgezet.",
    "A call request gets the status 'Denied by lead' when the call request is declined by the lead.": "Een terugbelverzoek krijgt de status \u2018Geweigerd door lead\u2019 wanneer het terugbelverzoek geweigerd is door de lead.",
    "A call request gets the status 'Internally missed' when the call request has been missed internally.": "Een terugbelverzoek krijgt de status \u2018Intern gemist\u2019 wanneer het terugbelverzoek intern gemist is.",
    "A call request gets the status 'Internally rejected' when the call request has been rejected internally.": "Een terugbelverzoek krijgt de status \u2018Intern geweigerd\u2019 wanneer het terugbelverzoek intern geweigerd is.",
    "A call request gets the status 'Missed by lead' when the call request is missed by the lead.": "Een terugbelverzoek krijgt de status \u2018Gemist door lead\u2019 wanneer het terugbelverzoek gemist is door de lead. ",
    "A call request gets the status 'Successful' when a call has been made between both parties.": "Een terugbelverzoek krijgt de status \u2018Succesvol\u2019 wanneer er een oproep is gerealiseerd tussen beide partijen.",
    "A call request is given the status 'Missed' when a successful call is not established because the maximum number of call attempts has been reached.": "Een terugbelverzoek krijgt de status \u2018Gemist\u2019 wanneer er geen succesvolle oproep tot stand is gebracht, omdat het maximum aantal belpogingen is bereikt.",
    "Change the evaluation of the call request here.": "Wijzig hier de beoordeling van het terugbelverzoek.",
    "Here you will find a log of the previous evaluations associated with this call request.": "Hier vind je een log van de eerdere beoordelingen die aan dit terugbelverzoek zijn gekoppeld.",
    "Here you will find all call attempts of this call request.": "Hier vind je alle belpogingen van dit terugbelverzoek.",
    "Listen to the recording of this call request here.": "Luister hier de gespreksopname van dit terugbelverzoek terug.",
    "The call duration is the duration of the successful call that resulted from the call request.": "De gespreksduur is de duur van de succesvolle oproep dat is voortgekomen uit het terugbelverzoek.",
    "The percentage of call requests for which a successful call was established.": "Het percentage terugbelverzoeken waarvoor een succesvolle oproep tot stand is gebracht.",
    "The response time is the time between submitting the call request and establishing a successful call.": "De reactietijd is de tijd tussen het indienen van het terugbelverzoek en het tot stand brengen van een succesvolle oproep.",
    "This bar chart shows the number of call requests per status per hour of the day.": "Dit staafdiagram toont het aantal terugbelverzoeken per status per uur van de dag.",
    "This bar chart shows the number of call requests stacked by status per day of the week.": "Dit staafdiagram toont het aantal terugbelverzoeken gestapeld per status per dag van de week.",
    "This chart shows an overview of the call requests and the corresponding status per period.": "Deze grafiek toont een overzicht van de terugbelverzoeken en de bijbehorende status per periode.",
    "This diagram shows an overview of the number of call requests per status.": "Dit diagram toont een overzicht van het aantal terugbelverzoeken per status.",
    "This pie chart shows the number of calls per evaluation.": "Dit cirkeldiagram toont het aantal oproepen per beoordeling."
}