import { useNavigate } from 'react-router';
import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';

import { useMessages } from 'contexts/Messages';
import { useSetQueryData, useSetTableData, useTableData } from 'hooks/data';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';
import { useUserDateFormat } from 'hooks/user';

export function useBlacklistedNumber(id: number | null = null) {
  const { fetchData } = useFetch();

  const numberData = useTableData<{
    id: number;
    number: string;
    blacklisted_till: string;
    created_at: string;
  }>(['admin', 'blacklistedNumber', 'index']);

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ['admin', 'blacklistedNumber', id],
    queryFn: (): Promise<{
      id: number;
      number: string;
      blacklisted_till: string;
      created_at: string;
    }> =>
      fetchData('/blacklisted-number/view', {
        queryParams: { id },
      }),
    enabled: id !== null,
    initialData: () => numberData?.find(n => n.id === id),
  });

  return { data, isLoading };
}

export function useAddBlacklistedNumber() {
  const userDateFormat = useUserDateFormat();
  const { fetchData } = useFetch();
  const translateText = useTranslate();
  const setTableData = useSetTableData();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (data: { number: string; date: string }) =>
      fetchData('/blacklisted-number/create', {
        method: 'POST',
        bodyParams: {
          number: data.number,
          blacklisted_till: moment(data.date, userDateFormat).format('YYYY-MM-DD') + ' 23:59:59',
        },
      }),
    meta: { noError: true },
    onSuccess: data => {
      addSuccessMessage(translateText('text', 'The phone number has been added to the blacklist'));
      setTableData(['admin', 'blacklistedNumber', 'index'], oldData => [...oldData, data]);
    },
  });

  return { addBlacklistedNumber: mutateAsync, isSaving: isPending };
}

export function useUpdateBlacklistedNumber() {
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const setTableData = useSetTableData();
  const setQueryData = useSetQueryData();
  const { addSuccessMessage } = useMessages();
  const userDateFormat = useUserDateFormat();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (data: { id: number; number: string; date: string }) =>
      fetchData('/blacklisted-number/update', {
        method: 'PUT',
        queryParams: { id: data.id },
        bodyParams: {
          number: data.number,
          blacklisted_till: moment(data.date, userDateFormat).format('YYYY-MM-DD') + ' 23:59:59',
        },
      }),
    meta: { noError: true },
    onSuccess: data => {
      addSuccessMessage(
        translateText('admin/text', 'The blacklisted phone number has been updated'),
      );
      setTableData(['admin', 'blacklistedNumber', 'index'], oldData =>
        oldData.map(i => (i?.id === data.id ? data : i)),
      );
      setQueryData(['admin', 'blacklistedNumber', data.id], () => data);
    },
  });

  return { updateBlacklistedNumber: mutateAsync, isSaving: isPending };
}

export function useDeleteBlacklistedNumber() {
  const translateText = useTranslate();
  const navigate = useNavigate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const setTableData = useSetTableData();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (id: number) =>
      fetchData('/blacklisted-number/delete', {
        method: 'DELETE',
        queryParams: { id },
      }),
    onSuccess: (data, id) => {
      addSuccessMessage(
        translateText('admin/text', 'The blacklisted phone number has been removed'),
      );
      navigate('/admin/phone-numbers/blacklist');
      setTableData(['admin', 'blacklistedNumber', 'index'], oldData =>
        oldData.filter(i => i.id !== id),
      );
    },
  });

  return { deleteBlacklistedNumber: mutateAsync, isDeleting: isPending };
}
