import { Formik, FormikHelpers } from 'formik';

import ErrorFocus from 'components/ErrorFocus';
import TextField from 'components/input/TextField';
import PasswordValidator from 'components/PasswordValidator';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import { useMessages } from 'contexts/Messages';
import { useChangePassword } from 'hooks/queries/user';
import { useTranslate } from 'hooks/translate';
import { useValidationSchema } from 'hooks/validation';

type FormValues = {
  password: string;
  newPassword: string;
  newPasswordVerification: string;
  conditionsPassed: boolean;
};

export default function ChangePassword({ show, close }: BaseSlideInProps) {
  const translateText = useTranslate();
  const { addErrorMessage } = useMessages();
  const { updatePasswordValidation } = useValidationSchema();
  const { changePassword } = useChangePassword();

  async function save(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    await changePassword(values)
      .then(close)
      .catch(error => {
        if (error.password) formikHelpers.setFieldError('newPassword', error.password[0]);
        else addErrorMessage(error);
      });
  }

  return (
    <Formik
      initialValues={{
        password: '',
        newPassword: '',
        newPasswordVerification: '',
        conditionsPassed: false,
      }}
      onSubmit={save}
      validationSchema={updatePasswordValidation}
    >
      {({
        values,
        handleChange,
        setFieldValue,
        submitForm,
        errors,
        resetForm,
        dirty,
        isSubmitting,
      }) => (
        <Setup
          id="change-password"
          show={show}
          close={close}
          afterClose={resetForm}
          save={submitForm}
          title={translateText('label', 'New password')}
          maxWidth={400}
          hasChanges={dirty}
          isSaving={isSubmitting}
        >
          <ErrorFocus element={document.getElementById('change-password')} />
          <InputWrapper label={translateText('label', 'Current password')}>
            <TextField
              name="password"
              type="password"
              onChange={handleChange}
              value={values.password}
              error={errors.password}
            />
          </InputWrapper>
          <PasswordValidator
            password={values.newPassword}
            passwordVerification={values.newPasswordVerification}
            setPassword={value => setFieldValue('newPassword', value)}
            setPasswordVerification={value => setFieldValue('newPasswordVerification', value)}
            setConditionsPassed={value => setFieldValue('conditionsPassed', value)}
            error={errors.conditionsPassed ?? errors.newPassword}
            label={translateText('label', 'New password')}
          />
        </Setup>
      )}
    </Formik>
  );
}
