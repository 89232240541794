import ConfigurationPage from 'components/configuration/ConfigurationPage';
import IpFilter from 'components/configuration/tracking-and-filters/IpFilter';
import ManualTagging from 'components/configuration/tracking-and-filters/ManualTagging';
import SessionStop from 'components/configuration/tracking-and-filters/SessionStop';
import Sources from 'components/configuration/tracking-and-filters/Sources';
import { useDomainAuth, useIpFilters, useUpdateTrackedSources } from 'hooks/queries/domain';
import { usePendingChanges } from 'hooks/queries/domain-change-request';
import { useManualTagging, useSessionStop, useTrackedSources } from 'hooks/queries/domain-setting';
import { useSources } from 'hooks/queries/source';
import { useTranslate } from 'hooks/translate';

export default function TrackingAndFilters() {
  const translateText = useTranslate();
  const { trackedSources } = useTrackedSources();
  const { updateTrackedSources } = useUpdateTrackedSources();
  const { isLoading: isLoadingIpFilter } = useIpFilters();
  const { isLoading: isLoadingDomainAuth } = useDomainAuth();
  const { isLoading: isLoadingTagging } = useManualTagging();
  const { isLoading: isLoadingSessionStop } = useSessionStop();
  const { isLoading: isLoadingSources } = useSources();
  const { isLoading: isLoadingPending } = usePendingChanges('');

  const isLoading =
    isLoadingIpFilter ||
    isLoadingDomainAuth ||
    isLoadingTagging ||
    isLoadingSessionStop ||
    isLoadingSources ||
    isLoadingPending;

  const sources = !trackedSources?.allTracked ? trackedSources?.tracked : trackedSources?.denied;

  return (
    <ConfigurationPage title={translateText('label', 'Tracking and filters')}>
      <Sources
        isLoading={isLoading}
        onSave={updateTrackedSources}
        sources={sources ?? []}
        included={!trackedSources?.allTracked ? 1 : 0}
      />
      <ManualTagging isLoading={isLoading} />
      <IpFilter isLoading={isLoading} />
      <SessionStop isLoading={isLoading} />
    </ConfigurationPage>
  );
}
