import { camelCase } from 'lodash';

import AutoGrid from 'components/AutoGrid';
import ReportsPage from 'components/ReportsPage';
import QueryTable from 'components/table/QueryTable';
import Widget from 'components/widget/Widget';
import { getDefaultFilters, useFilters } from 'contexts/Filters';
import { TYPE_CALL_TRACKING } from 'globals/constants';
import { useIsFetchingReport } from 'hooks/fetching';
import { useDomainAuth } from 'hooks/queries/domain';
import { useTranslate } from 'hooks/translate';

export default function Evaluations() {
  const translateText = useTranslate();
  const { domainAuth } = useDomainAuth();
  const { getAppliedFilters } = useFilters();

  const queryKey = ['callTracking', 'evaluations'];
  const isLoading = useIsFetchingReport(queryKey);

  const defaultFilters = getDefaultFilters(TYPE_CALL_TRACKING);
  const selectionTableFilters = getAppliedFilters(
    defaultFilters,
    camelCase([...queryKey, 'selectionMenuTable'].join(' ')),
    true,
  );
  const evaluationTableFilters = getAppliedFilters(
    defaultFilters,
    camelCase([...queryKey, 'evaluationsTable'].join(' ')),
    true,
  );

  return (
    <ReportsPage
      queryKey={queryKey}
      title={translateText('label', 'Evaluations')}
      download={{
        endpoint: '/reports/evaluations',
        disabled: isLoading,
        filters: {
          ...defaultFilters,
          custom: {
            evaluationPage: evaluationTableFilters.queryParams.page,
            evaluationLimit: evaluationTableFilters.queryParams.limit,
            selectionPage: selectionTableFilters.queryParams.page,
            selectionLimit: selectionTableFilters.queryParams.limit,
          },
        },
      }}
    >
      {domainAuth?.selection_menu && (
        <AutoGrid stretch growToEnd>
          <Widget
            widgetKey={[...queryKey, 'selectionMenuPie']}
            type="pie"
            title={translateText('label', 'Selection menu')}
            titleTooltip={translateText(
              'tooltip/widgets',
              'The pie chart shows the top 10 of the chosen options within the selection menu.',
            )}
            endpoint="/widget/customer-evaluations"
            maxPieces={8}
            filters={defaultFilters}
            isLoading={isLoading}
          />
          <QueryTable
            tableKey={[...queryKey, 'selectionMenuTable']}
            dataKey="data"
            gridColumns={2}
            columns={{
              customer_evaluation: {
                header: translateText('label', 'Selection'),
                compareType: 'none',
              },
              calls: { header: translateText('label', 'Amount of calls') },
              uq_callers: { header: translateText('label', 'Unique calls') },
              avg_duration: {
                header: translateText('label', 'Avg. call duration'),
                compareType: 'time',
              },
            }}
            endpoint="/widget/customer-evaluations-stats"
            header={translateText('label', 'Selection menu')}
            pagination={{ defaultLimit: 5, hideLimit: true, hideOnOne: true }}
            isResponsive
            filters={defaultFilters}
            previousPeriodColumn="customer_evaluation"
            isLoading={isLoading}
          />
        </AutoGrid>
      )}
      <AutoGrid stretch growToEnd>
        <Widget
          widgetKey={[...queryKey, 'evaluationsPie']}
          type="pie"
          title={translateText('label', 'Evaluations')}
          titleTooltip={translateText(
            'tooltip/widgets',
            'The pie chart shows the top 10 of the chosen options within the rating menu.',
          )}
          endpoint="/widget/evaluations"
          emptyLabel={translateText('message', 'There are no call evaluations.')}
          maxPieces={8}
          filters={defaultFilters}
          isLoading={isLoading}
        />
        <QueryTable
          tableKey={[...queryKey, 'evaluationsTable']}
          dataKey="data"
          gridColumns={2}
          columns={{
            evaluations: { header: translateText('label', 'Evaluation'), compareType: 'none' },
            count: { header: translateText('label', 'Amount of calls') },
            uq_callers: { header: translateText('label', 'Unique calls') },
            avg_duration: {
              header: translateText('label', 'Avg. call duration'),
              compareType: 'time',
            },
            tot_value: { header: translateText('label', 'Tot. value') },
            value: { header: translateText('label', 'Avg. value') },
          }}
          endpoint="/widget/evaluations-stats"
          header={translateText('label', 'Evaluations')}
          pagination={{ defaultLimit: 5, hideLimit: true, hideOnOne: true }}
          isResponsive
          filters={defaultFilters}
          previousPeriodColumn="evaluations"
          isLoading={isLoading}
        />
      </AutoGrid>
    </ReportsPage>
  );
}
