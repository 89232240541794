import { CSSProperties } from 'react';
import { omit, set } from 'lodash';

import deleteIcon from 'assets/images/icons/delete-purple.svg';
import Select from 'components/input/Select';
import TextField from 'components/input/TextField';
import Toggle from 'components/input/Toggle';
import { parseValue } from './EditableJson';

type Props = {
  objectKey: string | number;
  value: unknown;
  parent: object;
  handleChange: (value: unknown) => void;
  level: number;
  canEdit: boolean;
};

function changeType(value: unknown, type: string) {
  let newValue: string | boolean | number | object = '';
  if (type === 'string') {
    newValue = JSON.stringify(value);
  } else if (type === 'boolean') {
    newValue = !!value;
  } else if (type === 'number') {
    newValue = Number(value) || 0;
  } else if (type === 'array') {
    if (value && typeof value === 'object') {
      // object to array
      newValue = Object.values(value);
    } else if (typeof value === 'string' && typeof parseValue(value) === 'object') {
      // string of an array or object to array
      newValue = Object.values(parseValue(value));
    } else {
      newValue = [];
    }
  } else if (type === 'object') {
    if (value && typeof value === 'object') {
      // array to object
      newValue = { ...value };
    } else if (typeof value === 'string' && typeof parseValue(value) === 'object') {
      // string of an array or object to object
      newValue = { ...parseValue(value) };
    } else {
      newValue = {};
    }
  }
  return newValue;
}

export default function EditableJsonRow({
  objectKey,
  value,
  parent,
  handleChange,
  level,
  canEdit,
}: Props) {
  const parentIsArray = Array.isArray(parent);

  function updateValue(value: string | boolean | number | object) {
    handleChange(set(parent, objectKey, value));
  }

  function removeRow() {
    const newParent = parentIsArray
      ? parent.filter((_, index) => index !== Number(objectKey))
      : omit(parent, objectKey);
    handleChange(newParent);
  }

  const valueType = Array.isArray(value) ? 'array' : typeof value;
  return (
    <tr>
      {level > 0 && (
        <td>
          <span style={{ '--level': level } as CSSProperties}>↳</span>
        </td>
      )}
      {!parentIsArray && <td colSpan={level === 0 ? 2 : 1}>{objectKey}</td>}
      <td colSpan={parentIsArray ? 2 : 1}>
        {valueType === 'string' && (
          <TextField
            value={value as string}
            onChange={e => updateValue(e.target.value)}
            canEdit={canEdit}
          />
        )}
        {valueType === 'number' && (
          <TextField
            type="number"
            value={value as number}
            onChange={e => updateValue(e.target.valueAsNumber || 0)}
            canEdit={canEdit}
          />
        )}
        {valueType === 'boolean' &&
          (canEdit ? (
            <Toggle checked={!!value} onClick={() => updateValue(!value)} />
          ) : (
            (value as boolean).toString()
          ))}
      </td>
      <td>
        <Select
          value={valueType}
          onChange={e => updateValue(changeType(value, e.target.value))}
          canEdit={canEdit}
          options={{
            string: 'String',
            number: 'Number',
            boolean: 'Boolean',
            object: 'Object',
            array: 'Array',
          }}
        />
      </td>
      <td>
        {canEdit && (
          <img alt="delete" src={deleteIcon} className="clickable-icon" onClick={removeRow} />
        )}
      </td>
    </tr>
  );
}
