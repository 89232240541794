import { ChangeEventHandler, LegacyRef } from 'react';

import ErrorTooltip from 'components/ErrorTooltip';
import { useTranslate } from 'hooks/translate';

type BaseProps<T> = {
  id?: string;
  name?: string;
  options: Record<string, string | number> | readonly (string | number)[] | readonly T[];
  onChange: ChangeEventHandler<HTMLSelectElement>;
  optionText?: keyof T;
  optionValue?: keyof T;
  className?: string;
  selectClassName?: string;
  hiddenKeys?: (string | number)[];
  prefix?: string;
  addSelect?: boolean;
  selectValue?: string | number;
  selectText?: string;
  disabled?: boolean;
  error?: boolean | string;
  hideSelectOption?: boolean;
  canEdit?: boolean;
  tabIndex?: number;
  inputRef?: LegacyRef<HTMLSelectElement> | ((elementRef: HTMLSelectElement) => void);
};

type PropsWithValue<T> = {
  value: string | number;
  defaultValue?: never;
} & BaseProps<T>;

type PropsWithDefaultValue<T> = {
  value?: never;
  defaultValue: string | number;
} & BaseProps<T>;

export default function Select<T extends Record<string, unknown>>({
  id,
  name,
  options,
  value,
  defaultValue,
  onChange,
  optionText = 'text',
  optionValue = 'value',
  className,
  selectClassName,
  hiddenKeys,
  prefix,
  addSelect,
  selectValue = 'select',
  selectText,
  disabled,
  error,
  hideSelectOption = true,
  canEdit = true,
  tabIndex,
  inputRef,
}: PropsWithValue<T> | PropsWithDefaultValue<T>) {
  const translateText = useTranslate();

  function renderOptions() {
    if (Array.isArray(options)) {
      return options
        .filter(option => option !== null)
        .map((option, index) => {
          const value = option.constructor === Object ? option[optionValue] ?? index : option;
          return (
            <option key={index} value={value} hidden={hiddenKeys?.includes(value)}>
              {getText(value)}
            </option>
          );
        });
    }

    return Object.keys(options).map(key => {
      return (
        <option key={key} value={key} hidden={hiddenKeys?.includes(key)}>
          {getText(key)}
        </option>
      );
    });
  }

  function getText(val: Record<string, string | number> | string | number) {
    let text;
    if (Array.isArray(options)) {
      if (options[0]?.constructor === Object) {
        const option = (options as []).find(option => String(option[optionValue]) === String(val));
        text = option !== undefined ? option[optionText] : null;
      } else {
        text = val as string;
      }
    } else {
      text = (options as Record<string, string | number>)[val as string | number];
    }

    if (prefix && text) text = prefix + text;
    return text;
  }

  const optionsArray = renderOptions();

  if (addSelect) {
    optionsArray.unshift(
      <option key="select" value={selectValue} hidden={hideSelectOption}>
        {selectText ?? translateText('label', 'Select')}
      </option>,
    );
  }

  if (!canEdit) {
    const currentValue = value ?? defaultValue;
    const text = currentValue && currentValue !== selectValue ? getText(currentValue) : null;
    return <div className={className}>{text}</div>;
  }

  return (
    <div className={'select-box' + (className ? ' ' + className : '')}>
      <ErrorTooltip
        error={error}
        html={
          <>
            <select
              id={id}
              name={name}
              value={value}
              defaultValue={defaultValue}
              onChange={onChange}
              className={(selectClassName ?? '') + (error ? ' error' : '')}
              disabled={disabled}
              tabIndex={tabIndex}
              ref={inputRef}
            >
              {optionsArray}
            </select>
          </>
        }
      />
      <div className="arrow arrow-down" />
    </div>
  );
}
