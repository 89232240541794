import image404 from 'assets/images/404.svg';
import ImagePage from 'components/ImagePage';
import { useTranslate } from 'hooks/translate';

type Props = {
  title?: string;
  firstParagraph?: string;
};

export default function Error404({ title, firstParagraph }: Props) {
  const translateText = useTranslate();
  return (
    <ImagePage
      title="404"
      subtitle={title ?? translateText('text', 'Unfortunately, this page does not exist')}
      link="/"
      buttonText={translateText('label', 'Back to the homepage')}
      image={image404}
      content={
        <>
          {firstParagraph && <p>{firstParagraph}</p>}
          <p>
            {!firstParagraph &&
              translateText('text', 'The page you were looking for does not exist.') + ' '}
            {translateText('text', 'Navigate to another page via the menu or contact us at ')}
            <a href="mailto:support@adcalls.nl">support@adcalls.nl</a>.
          </p>
        </>
      }
    />
  );
}
