import { Cell, Legend as RechartsLegend, Pie, PieChart, ResponsiveContainer } from 'recharts';

import { useTranslate } from 'hooks/translate';
import Legend, { EmptyLegend } from './Legend';
import { DonutDataProps, DonutProps } from './types';

export default function Donut({
  data,
  title,
  previousData,
  emptyLabel,
}: DonutProps & DonutDataProps) {
  const translateText = useTranslate();

  function renderPie(renderData: { value: number; title?: string }[], isPrev: boolean) {
    let outerRadius = '80%';
    let innerRadius = '48%';
    let prefix = '';
    let color = '#043CA0';
    if (isPrev) {
      outerRadius = '33%';
      innerRadius = '17%';
      prefix = 'small_';
      color = '#004FFF';
    }

    return [
      <Pie
        key={prefix + '0'}
        nameKey="argument"
        paddingAngle={0}
        stroke="0"
        data={[{ value: 100 }]}
        outerRadius={outerRadius}
        innerRadius={innerRadius}
        dataKey="value"
        startAngle={90}
        endAngle={-270}
      >
        <Cell fill="transparent" stroke="#E3E6EE" stroke-width="0.8" />
      </Pie>,
      <Pie
        key={prefix + '1'}
        nameKey="argument"
        paddingAngle={0}
        stroke="0"
        outerRadius={outerRadius}
        innerRadius={innerRadius}
        data={renderData}
        dataKey="value"
        startAngle={90}
        endAngle={-270}
        labelLine={false}
      >
        {renderData.map((entry, index) => (
          <Cell key={index} fill={index === 0 ? color : 'transparent'} />
        ))}
      </Pie>,
    ];
  }

  let renderData = [{ value: data, title }, { value: 100 - data }];
  let renderPrevPie;
  if (previousData !== undefined && data !== 0) {
    renderData = [
      { value: data, title: translateText('label', 'Current period') },
      { value: 100 - data },
    ];
    const renderPrev = [
      { value: previousData, title: translateText('label', 'Previous period') },
      { value: 100 - previousData },
    ];
    renderPrevPie = renderPie(renderPrev, true);
  }

  const legend =
    data === 0 ? <EmptyLegend label={emptyLabel} /> : <Legend type="donut" legendKey="title" />;

  return (
    <ResponsiveContainer className="widget donut">
      <PieChart>
        <RechartsLegend content={legend} />
        {renderPie(renderData, false)}
        {renderPrevPie}
      </PieChart>
    </ResponsiveContainer>
  );
}
