import { useQuery } from '@tanstack/react-query';

import { useHasAdminAccess } from 'hooks/access';
import { useFetch } from 'hooks/fetch';

export type SettingType = {
  id: number;
  name: string;
  datatype?: number;
};

export function useDomainSettingTypes() {
  const { fetchData } = useFetch();
  const isAdmin = useHasAdminAccess();

  const { data, isFetching } = useQuery({
    queryKey: ['settingTypes'],
    queryFn: (): Promise<SettingType[]> => fetchData('/setting-type/index'),
    enabled: isAdmin,
  });

  return { settingTypes: data, isLoading: isFetching };
}
