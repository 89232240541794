import { ReactElement, useEffect, useReducer } from 'react';
import { Link } from 'react-router-dom';

import { isLeafItem, Route, useRouteMap, useRoutes } from 'contexts/Routes';
import { useSession } from 'contexts/Session';

type Props = {
  shouldReset: boolean;
  setShouldReset: (value: boolean) => void;
  setMenuOpen: (value: boolean) => void;
};

export default function MobileMenu({ shouldReset, setShouldReset, setMenuOpen }: Props) {
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const { token } = useSession();
  const { navigationDisabled, setOpenItems, selectedItems, openItems } = useRoutes();
  const routeMap = useRouteMap();

  useEffect(() => {
    if (shouldReset) {
      setShouldReset(false);
      forceUpdate();
    }
  }, [shouldReset, setShouldReset]);

  function getClassName(collection: string[], route: string) {
    return 'route-row' + (collection.includes(route) ? ' selected' : '');
  }

  function renderItem(item: Route, index: string, depth = 0) {
    if (isLeafItem(item)) {
      return (
        <Link
          key={index}
          to={item.endRoute}
          className="extension-nav-link"
          onClick={() => setMenuOpen(false)}
        >
          <div
            className={getClassName(selectedItems, item.endRoute)}
            style={depth > 0 ? { paddingLeft: 20 + depth * 10 } : {}}
            data-testid="item"
          >
            {item.icon ? <div className="icon">{item.icon}</div> : null}
            <div className="title-wrapper">{item.title}</div>
          </div>
        </Link>
      );
    }
    let arrowDirection = 'arrow-right',
      subItems;
    if (openItems.includes(item.endRoute)) {
      arrowDirection = 'arrow-down-right';
      subItems = item.subItems?.map((subItem, index) =>
        renderItem(subItem, String(index), depth + 1),
      );
    }
    return (
      <div key={index} className="route-row-wrapper">
        <div
          className={getClassName(openItems, item.endRoute)}
          onClick={() => setOpenItems(item.endRoute, depth, false)}
          style={depth > 0 ? { paddingLeft: 20 + depth * 10 } : {}}
          data-testid="item"
        >
          {item.icon ? <div className="icon">{item.icon}</div> : null}
          <div className="title-wrapper">
            {item.title}
            <div className={'arrow ' + arrowDirection} />
          </div>
        </div>
        {subItems}
      </div>
    );
  }

  if (!token || navigationDisabled) {
    return null;
  }

  const items: ReactElement[] = [];
  routeMap.forEach((routeGroup, index) => {
    if (index !== 0) {
      items.push(<div key={`divider-${index}`} className="sidemenu-divider" />);
    }
    items.push(...routeGroup.map((routeItem, i) => renderItem(routeItem, `${index}-${i}`)));
  });
  return <div className="mobile-routes-wrapper">{items}</div>;
}
