import { useIsMutating } from '@tanstack/react-query';

import { useSession } from 'contexts/Session';
import { LANGUAGE_DUTCH } from 'globals/constants';
import { useInvitationCount } from 'hooks/queries/invitation';
import { useUnreadNotifications } from 'hooks/queries/notification';
import { useDisplayTour, useUser, useUserMcc } from 'hooks/queries/user';

export function useUserLanguage() {
  const { user } = useUser();
  return user?.language?.id ?? LANGUAGE_DUTCH;
}

export function useUserDateFormat() {
  const { user } = useUser();
  return user?.date_format ?? 'DD-MM-YYYY';
}

export function useIsLoggingIn() {
  const { token } = useSession();
  const { user } = useUser();
  const { mcc } = useUserMcc();
  const { invitationCount } = useInvitationCount();
  const { notifications } = useUnreadNotifications();
  const { displayTour } = useDisplayTour();
  const isUpdatingLanguage = useIsMutating({ mutationKey: ['user', 'language'] });

  return (
    !!token &&
    (!user ||
      !mcc ||
      invitationCount === undefined ||
      !notifications ||
      displayTour === undefined ||
      !!isUpdatingLanguage)
  );
}
