import { isEmpty } from 'lodash';
import moment from 'moment';

import CreateDeleteTable from 'components/CreateDeleteTable';
import {
  OFFSITE_STATUS_DISCONTINUE_ACCEPTED,
  OFFSITE_STATUS_DISCONTINUE_REQUESTED,
  OFFSITE_STATUS_PENDING,
} from 'globals/constants';
import { Offsite } from 'globals/types';
import { useTranslate } from 'hooks/translate';
import { useUserDateFormat } from 'hooks/user';

type Props = {
  offsites: Offsite[];
  addedOffsites: Offsite[];
  deletedOffsites: Offsite[];
  onAdd: () => void;
  onEdit: (offsite: Offsite) => void;
  setAddedOffsites: (items: Offsite[]) => void;
  setDeletedOffsites: (items: Offsite[]) => void;
  intake?: boolean;
  addButtonDisabled?: boolean;
};

export default function OffsitesTable({
  offsites,
  addedOffsites,
  deletedOffsites,
  onAdd,
  onEdit,
  setAddedOffsites,
  setDeletedOffsites,
  intake,
  addButtonDisabled,
}: Props) {
  const translateText = useTranslate();
  const dateFormat = useUserDateFormat();

  function getStatus(offsite: Offsite) {
    switch (offsite.status) {
      case OFFSITE_STATUS_PENDING:
        return { type: 'info' as const, text: translateText('label', 'In request') };
      case OFFSITE_STATUS_DISCONTINUE_REQUESTED:
        return { type: 'warning' as const, text: translateText('label', 'Stop requested') };
      case OFFSITE_STATUS_DISCONTINUE_ACCEPTED:
        return {
          type: 'warning' as const,
          text: translateText('label', 'Will be stopped at {date}', {
            date: moment(offsite.stop_requested_for).format(dateFormat),
          }),
        };
      default:
        return null;
    }
  }

  function addFormattedCountry(offsite: Offsite) {
    return {
      ...offsite,
      formattedCountry: '+' + offsite.country,
    };
  }

  function editOffsite(offsite: Offsite & { formattedCountry?: string }) {
    delete offsite.formattedCountry;
    onEdit(offsite);
  }

  return (
    <CreateDeleteTable
      dataKeys={['name', 'formattedCountry', 'area', 'destination']}
      privateKeys={['name', 'destination']}
      items={!intake ? offsites.map(addFormattedCountry) : undefined}
      onAdd={onAdd}
      onEdit={item => editOffsite(item)}
      addedItems={addedOffsites.map(addFormattedCountry)}
      deletedItems={!intake ? deletedOffsites.map(addFormattedCountry) : undefined}
      setAddedItems={items => setAddedOffsites(items)}
      setDeletedItems={!intake ? items => setDeletedOffsites(items) : undefined}
      getStatus={item => getStatus(item)}
      title={translateText('label', 'Offsite phone numbers')}
      titleAdded={
        isEmpty(offsites) || intake
          ? translateText('label', 'Offsite phone numbers')
          : translateText('label', 'New offsite phone numbers')
      }
      addButtonDisabled={addButtonDisabled}
    />
  );
}
