import { useEffect, useState } from 'react';

import Checkbox from 'components/input/Checkbox';
import Select from 'components/input/Select';
import QueryTable from 'components/table/QueryTable';
import { ColumnSetting } from 'components/table/Table';
import { useGlobal } from 'contexts/Global';
import { useTranslate } from 'hooks/translate';

export default function NumberAssignOverview() {
  const { setShowReportsFilter } = useGlobal();
  const translateText = useTranslate();

  const [scale, setScale] = useState(1);
  const [sendDates, setSendDates] = useState(false);

  useEffect(() => {
    setShowReportsFilter('admin');
    return () => setShowReportsFilter(false);
  }, [setShowReportsFilter]);

  const columns: Record<string, ColumnSetting> = {
    domain_id: {
      header: { name: translateText('label', 'Domain ID'), sort: true },
      search: true,
      noNumberFormat: true,
    },
    domain_title: { header: { name: translateText('label', 'Title'), sort: true }, search: true },
  };

  if (scale === 0) {
    columns.numbers_to_remove = { header: translateText('label', 'Numbers to remove') };
  } else if (scale === 1) {
    columns.fallback_assignments = { header: translateText('label', 'Fallback assignments') };
  }

  return (
    <div className="number-assign-overview">
      <h1>{translateText('menu', 'Number assign overview')}</h1>
      <div className="white-block margin-bottom">
        <Select
          name="order"
          className="margin-bottom"
          value={scale}
          options={{ 0: translateText('label', 'Downscale'), 1: translateText('label', 'Upscale') }}
          onChange={e => setScale(Number(e.target.value))}
        />
        {scale === 0 && (
          <>
            <p>
              {translateText(
                'admin/text',
                'The overview is based on the highest amount of number assignments per number pool on one day.',
              )}
            </p>
            <p>
              {translateText(
                'admin/text',
                'To calculate the amount of numbers to scale down, the highest amount of assignments, per number pool, is divided by the amount of workable half hours in a day (20) multiplied by the safety margin (2). These amounts are all added together to determine the priority.',
              )}
            </p>
            <p>
              {translateText(
                'admin/text',
                'The minimum amount of numbers that should be in a number pool after scaling is 5.',
              )}
            </p>
          </>
        )}
        {scale === 1 && (
          <p>
            {translateText(
              'admin/text',
              'The overview is based on the total amount of fallback number assignments per domain in the selected period.',
            )}
          </p>
        )}
        <p>
          {translateText(
            'admin/text',
            'If no date is sent to the API, the default period from a month ago till now is used.',
          )}
        </p>
      </div>
      <QueryTable
        tableKey={['admin', 'numberAssignOverview']}
        endpoint="/domain/number-assign-overview"
        columns={columns}
        filters={{ date: sendDates, domain: false, custom: { scale_up: scale } }}
        pagination={{ defaultLimit: 100 }}
        extraTopLeft={
          <Checkbox
            id="send-date"
            checked={sendDates}
            onChange={() => setSendDates(!sendDates)}
            label={translateText('label', 'Send date to API')}
          />
        }
        refresh
      />
    </div>
  );
}
