import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { DomainUser } from 'components/settings/account-access/DomainUserSetup';
import { useMessages } from 'contexts/Messages';
import { useSession } from 'contexts/Session';
import { useTenant } from 'contexts/Tenant';
import { useSetQueryData, useSetTableData } from 'hooks/data';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';
import { ResellerUser } from './reseller';

export function useInvitationCount() {
  const { fetchData } = useFetch();
  const { token } = useSession();

  const {
    data,
    isPending: isLoading,
    refetch,
  } = useQuery({
    queryKey: ['invitationCount'],
    queryFn: (): Promise<number> => fetchData('/invitation/pending-count'),
    enabled: !!token,
  });

  return { invitationCount: data, isLoading, refetch };
}

export function useAcceptInvitation() {
  const queryClient = useQueryClient();
  const setQueryData = useSetQueryData();
  const setTableData = useSetTableData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutate, isPending } = useMutation({
    mutationFn: (id: number) =>
      fetchData('/invitation/accept', {
        method: 'POST',
        queryParams: { id },
      }),
    onSuccess: (data, id) => {
      queryClient.invalidateQueries({ queryKey: ['userMcc'] });
      queryClient.invalidateQueries({ queryKey: ['domains'] });
      setTableData(['invitations'], oldData => oldData.map(i => (i?.id === id ? data : i)));
      setQueryData<number>(['invitationCount'], oldData => oldData - 1);
      addSuccessMessage(translateText('message', 'The invitation has been accepted.'));
    },
  });

  return { acceptInvitation: mutate, isLoading: isPending };
}

export function useDeclineInvitation() {
  const queryClient = useQueryClient();
  const setQueryData = useSetQueryData();
  const setTableData = useSetTableData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutate, isPending } = useMutation({
    mutationFn: (id: number) =>
      fetchData('/invitation/decline', {
        method: 'POST',
        queryParams: { id },
      }),
    onSuccess: (data, id) => {
      queryClient.invalidateQueries({ queryKey: ['userMcc'] });
      setTableData(['invitations'], oldData => oldData.map(i => (i?.id === id ? data : i)));
      setQueryData<number>(['invitationCount'], oldData => oldData - 1);
      addSuccessMessage(translateText('message', 'The invitation has been declined.'));
    },
  });

  return { declineInvitation: mutate, isLoading: isPending };
}

export function useRevokeInvitation() {
  const setTableData = useSetTableData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain, selectedMcc } = useTenant();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (id: string) =>
      fetchData('/invitation/revoke', {
        method: 'DELETE',
        queryParams: { id },
      }),
    onSuccess: (data, id) => {
      setTableData<DomainUser>(['domain', 'users', selectedDomain], oldData =>
        oldData.filter(u => u.highestRole?.id !== id),
      );
      setTableData<ResellerUser>(['reseller', 'users', selectedMcc], oldData =>
        oldData.filter(u => String(u.invitation_id) !== id),
      );
      addSuccessMessage(translateText('message', 'The invitation has been successfully revoked.'));
    },
  });

  return {
    revokeInvitation: async (id: string) => {
      if (
        window.confirm(translateText('text', 'Are you sure you want to revoke the invitation?'))
      ) {
        await mutateAsync(id);
      }
    },
    isLoading: isPending,
  };
}
