import { MouseEventHandler, PropsWithChildren, ReactElement } from 'react';

import EditButton from 'components/buttons/EditButton';
import Skeleton from 'components/Skeleton';
import { SUP_ART_URL } from 'globals/support';
import { useTranslate } from 'hooks/translate';

type Props = {
  className?: string;
  title?: string;
  enabled?: boolean;
  onEdit?: MouseEventHandler;
  disabled?: boolean;
  disabledText?: string;
  noAccessCheck?: boolean;
  extraEnabledText?: string;
  topParagraph?: string | ReactElement | null;
  rightParagraph?: string;
  supportArticleId?: number;
  isLoading?: boolean;
};

export default function ConfigurationCard({
  className,
  title,
  enabled,
  onEdit,
  disabled,
  disabledText,
  noAccessCheck,
  extraEnabledText,
  topParagraph,
  rightParagraph,
  supportArticleId,
  isLoading,
  children,
}: PropsWithChildren<Props>) {
  const translateText = useTranslate();

  function getSubTitle() {
    if (enabled === undefined) return null;
    if (isLoading) {
      return (
        <span className="sub-title">
          <Skeleton className="big-dot" />
          <Skeleton />
        </span>
      );
    }

    return (
      <span className="sub-title">
        <div className={'big-dot ' + (enabled ? 'active' : 'inactive')} />
        {enabled ? translateText('label', 'Enabled') : translateText('label', 'Disabled')}
        {enabled && extraEnabledText && ' (' + extraEnabledText + ')'}
      </span>
    );
  }

  function getRightParagraph() {
    if (!rightParagraph && !supportArticleId) return null;
    if (isLoading) return <Skeleton count={3} containerClass="right-paragraph" />;
    const supportText = supportArticleId
      ? ' ' +
        translateText(
          'knowledge-center',
          'For more information, see the following <a href="{link}">support article</a>.',
          { link: SUP_ART_URL + supportArticleId },
        )
      : '';
    return (
      <p
        className="right-paragraph"
        dangerouslySetInnerHTML={{ __html: rightParagraph + supportText }}
      />
    );
  }

  return (
    <div className={'configuration-card white-block' + (className ? ' ' + className : '')}>
      {onEdit && (
        <EditButton
          onClick={onEdit}
          disabled={disabled}
          disabledText={disabledText}
          noAccessCheck={noAccessCheck}
          isLoading={isLoading}
        />
      )}
      {title && (
        <h3>
          {isLoading ? <Skeleton width={title.length * 9} /> : title}
          {getSubTitle()}
        </h3>
      )}
      {isLoading && topParagraph ? <Skeleton count={2} width="70%" /> : topParagraph}
      <div className="content">
        <div>{children}</div>
        {getRightParagraph()}
      </div>
    </div>
  );
}
