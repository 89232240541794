import { Formik, FormikValues } from 'formik';

import Select from 'components/input/Select';
import {
  SIP_TRUNK_DSTNY_PBX_AD_CALLS,
  SIP_TRUNK_DSTNY_PBX_ELEVATE,
  SIP_TRUNK_DSTNY_PBX_LIVE,
  SIP_TRUNK_DSTNY_PBX_TEST,
  SIP_TRUNK_DSTNY_PROBLEMEN_KWIJT,
  SIP_TRUNK_DSTNY_VPS_LIVE,
  SIP_TRUNK_DSTNY_VPS_LIVE_2,
  SIP_TRUNK_DSTNY_VPS_TEST,
  SIP_TRUNK_STEAM_CONNECT_LIVE,
  SIP_TRUNK_STEAM_CONNECT_PROD,
  SIP_TRUNK_STEAM_CONNECT_TEST,
  SIP_TRUNK_VOXBONE,
} from 'globals/constants';
import { useCompareNumbers } from 'hooks/queries/flux';
import { useCompareWithVendors } from 'hooks/queries/number';
import { useTranslate } from 'hooks/translate';

export default function Check() {
  const translateText = useTranslate();
  const { compareNumbers, isLoading: isLoadingNumbers } = useCompareNumbers();
  const { compareWithVendors, isLoading: isLoadingCompare } = useCompareWithVendors();

  const isLoading = isLoadingNumbers || isLoadingCompare;

  function checkNumbers(values: FormikValues) {
    const formData = new FormData();
    formData.append('numbers', values.file);
    if (values.sipTrunk !== '') formData.append('siptrunk', values.sipTrunk);
    compareNumbers(formData);
  }

  return (
    <>
      <h1>{translateText('label', 'Phone number check')}</h1>
      <Formik<{ file: File | null; sipTrunk: string }>
        initialValues={{ file: null, sipTrunk: '' }}
        onSubmit={checkNumbers}
      >
        {({ values, handleChange, setFieldValue, submitForm }) => (
          <div className="white-block">
            <label htmlFor="file" className="btn btn-lightblue">
              {translateText('label', 'Upload a file')}
            </label>
            <input
              id="file"
              type="file"
              accept=".csv"
              onChange={e => setFieldValue('file', e.target.files?.[0])}
              hidden
              disabled={isLoading}
            />
            <span className="margin-left">
              {values.file
                ? translateText('label', 'Uploaded file: {file}', { file: values.file.name })
                : translateText('label', 'No file uploaded')}
            </span>
            <div className="margin-top">
              <Select
                name="sipTrunk"
                value={values.sipTrunk}
                onChange={handleChange}
                options={{
                  '': translateText('label', 'All SIP trunks'),
                  [SIP_TRUNK_DSTNY_PBX_LIVE]: 'Dstny Live (PBX)',
                  [SIP_TRUNK_DSTNY_PBX_TEST]: 'Dstny Test (PBX)',
                  [SIP_TRUNK_DSTNY_PBX_AD_CALLS]: 'Dstny AdCalls (PBX)',
                  [SIP_TRUNK_DSTNY_PBX_ELEVATE]: 'Dstny Elevate (PBX)',
                  [SIP_TRUNK_DSTNY_VPS_LIVE]: 'Dstny Live (VPS)',
                  [SIP_TRUNK_DSTNY_VPS_LIVE_2]: 'Dstny Live 2 (VPS)',
                  [SIP_TRUNK_DSTNY_PROBLEMEN_KWIJT]: 'Dstny Problemen Kwijt',
                  [SIP_TRUNK_DSTNY_VPS_TEST]: 'Dstny Test (VPS)',
                  [SIP_TRUNK_VOXBONE]: 'Voxbone',
                  [SIP_TRUNK_STEAM_CONNECT_PROD]: 'Steam-connect Prod',
                  [SIP_TRUNK_STEAM_CONNECT_LIVE]: 'Steam-connect Live (outbound)',
                  [SIP_TRUNK_STEAM_CONNECT_TEST]: 'Steam-connect Test',
                }}
                disabled={isLoading}
              />
            </div>
            <div className="margin-top">
              <button
                className="btn btn-lightblue"
                disabled={!values.file || isLoading}
                onClick={submitForm}
              >
                {translateText('label', 'Download')}
              </button>
            </div>
          </div>
        )}
      </Formik>
      <h1>{translateText('admin/text', 'Check numbers with APIs of vendor')}</h1>
      <div className="white-block">
        <p
          dangerouslySetInnerHTML={{
            __html: translateText(
              'admin/text',
              'Automatically check all numbers from every vendor.',
            ),
          }}
        />
        <button
          disabled={isLoading}
          className="btn btn-lightblue"
          onClick={() => compareWithVendors()}
        >
          {translateText('admin/text', 'Auto check')}
        </button>
      </div>
    </>
  );
}
