import { ReactComponent as Star } from 'assets/images/icons/icon-status-star.svg';
import ErrorTooltip from 'components/ErrorTooltip';

type Props = {
  value: number;
  onChange: (value: number) => void;
  error?: boolean | string;
};

export default function StarRating({ value, onChange, error }: Props) {
  function getStars(starNumber = 1) {
    if (starNumber > 5) return;
    let starClass = 'star';
    if (error) starClass += ' star-error';
    if (value >= starNumber) starClass += ' active';
    if (starNumber === 5) starClass += ' no-margin';
    return (
      <div className="star-rating">
        <Star className={starClass} onClick={() => onChange(starNumber)} data-testid="star" />
        {getStars(starNumber + 1)}
      </div>
    );
  }

  return (
    <ErrorTooltip
      error={error}
      html={
        <div
          tabIndex={1}
          className="star-rating-wrapper"
          data-testid="star-rating"
          {...(error && { 'data-error': true })} // This is needed for the query selector in `ErrorFocus`
        >
          {getStars()}
        </div>
      }
    />
  );
}
