import iconMicrosoftDynamics from 'assets/images/icons/integrations/microsoft-dynamics.svg';
import IntegrationRequest from 'components/integrations/IntegrationRequest';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import { INTEGRATION_MICROSOFT_DYNAMICS_REQUEST } from 'globals/constants';
import { useHasAccessToIntegration } from 'hooks/access';
import { useTranslate } from 'hooks/translate';

export default function MicrosoftDynamicsRequest({ show, close }: BaseSlideInProps) {
  const translateText = useTranslate();
  const hasAccessToIntegration = useHasAccessToIntegration();

  if (!hasAccessToIntegration(INTEGRATION_MICROSOFT_DYNAMICS_REQUEST)) return null;

  return (
    <IntegrationRequest
      show={show}
      title={translateText('label', 'Microsoft Dynamics')}
      icon={iconMicrosoftDynamics}
      infoText={translateText(
        'integration',
        'Microsoft Dynamics is a line of enterprise resource planning (ERP) and customer relationship management (CRM) applications developed by Microsoft. With the help of this integration you can enrich the contact details in Microsoft Dynamics with the data of incoming calls.',
      )}
      close={close}
      price={99}
    />
  );
}
