import { useFormikContext } from 'formik';
import { mapValues } from 'lodash';

import DetailsSection from 'components/DetailsSection';
import Skeleton from 'components/Skeleton';
import { useEditSubscriptionPricingIndication } from 'hooks/queries/domain';
import { useTranslate } from 'hooks/translate';
import { useUserLanguage } from 'hooks/user';
import { EditSubscriptionFormValues, EditSubscriptionProps } from './EditSubscription';

export type PricingIndicationRow = {
  value: number;
  params: Record<string, string | number>;
};

type Props = EditSubscriptionProps & {
  isLoading: boolean;
};

export default function PricingIndication({ type, isLoading }: Props) {
  const translateText = useTranslate();
  const userLanguage = useUserLanguage();
  const { values } = useFormikContext<EditSubscriptionFormValues>();

  const pricingPlan = values.pricingPlanId !== 'select' ? values.pricingPlanId : null;
  const contractDuration = values.contractDuration !== 'select' ? values.contractDuration : null;

  const { data, isLoading: isLoadingData } = useEditSubscriptionPricingIndication(
    type,
    contractDuration,
    pricingPlan,
  );

  const intro =
    pricingPlan && contractDuration
      ? translateText(
          'text',
          'Below you will find a price indication for the new contract. The indication is based on the usage of the past month and the current setup of the selected domain.',
        )
      : translateText(
          'text',
          'First select a pricing plan and a contract duration to get a price indication.',
        );

  function getLabelForKey(key: string, params: Record<string, string | number>) {
    const integrationLabel = translateText('label', 'Integration').toLowerCase();
    switch (key) {
      case 'license-costs':
        return translateText('label', '{pricingPlan} incl. {includedSessions} sessions', params);
      case 'sessions':
        return translateText('label', '{sessions} extra sessions', params);
      case 'extra-locations':
        return translateText(
          'label',
          '{locations} extra location(s) per {thousand} sessions',
          params,
        );
      case 'extra-offsite-numbers':
        return translateText(
          'label',
          '{extraOffsiteNumbers} extra offsite phone number(s)',
          params,
        );
      case 'offsite-calls':
        return translateText('label', '{offsiteCalls} calls', params);
      case 'offsite-minutes':
        return translateText('label', '{offsiteMinutes} call minutes', params);
      case 'hubspot':
        return translateText('label', 'HubSpot') + '-' + integrationLabel;
      case 'api':
        return translateText('label', 'API') + '-' + integrationLabel;
      case 'salesforce':
        return translateText('label', 'Salesforce') + '-' + integrationLabel;
      case 'microsoft-dynamics':
        return translateText('label', 'Microsoft Dynamics') + '-' + integrationLabel;
      case 'zoho':
        return translateText('label', 'Zoho') + '-' + integrationLabel;
      case 'pipedrive':
        return translateText('label', 'Pipedrive') + '-' + integrationLabel;
      case 'lef':
        return translateText('label', 'LEF') + '-' + integrationLabel;
      case 'bgenius':
        return translateText('label', 'bGenius') + '-' + integrationLabel;
      case 'relay42':
        return translateText('label', 'Relay42') + '-' + integrationLabel;
      case 'blueconic':
        return translateText('label', 'BlueConic') + '-' + integrationLabel;
      case 'adform':
        return translateText('label', 'Adform') + '-' + integrationLabel;
      case 'activation-discount':
        return translateText('label', '10% discount for the first six months');
      case 'duration-discount':
        return translateText(
          'label',
          '{percentage}% discount contract duration {months} months',
          params,
        );
      default:
        return '';
    }
  }

  function getPriceRows(rows: Record<string, PricingIndicationRow>) {
    return Object.entries(rows).map(([key, item], index) => {
      const params = mapValues(item.params, v => v.toLocaleString(userLanguage));
      const label = getLabelForKey(key, params);
      return (
        <tr key={key + index}>
          <td className="name">{label}</td>
          <td className="value">{getFormattedPrice(item.value)}</td>
        </tr>
      );
    });
  }

  function getFormattedPrice(price: number | string) {
    return (
      '€ ' +
      price.toLocaleString(userLanguage, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    );
  }

  // isLoading and isLoadingData need to show different skeletons because isLoading means
  // the whole page is loading, while isLoadingData means a different pricing plan or
  // contract duration has been selected and a new pricing indication is being fetched.
  return (
    <DetailsSection
      title={translateText('label', 'Price indication')}
      intro={!isLoadingData ? intro : undefined}
      isLoading={isLoading}
      className="pricing-indication"
    >
      {isLoadingData ? (
        <p>
          <Skeleton count={3} />
          <br />
          <Skeleton count={4} />
        </p>
      ) : data ? (
        <table className="upgrade-price-indication">
          <tbody>
            {getPriceRows(data.usage)}
            <tr className="total bold">
              <td>{translateText('label', 'Total per month')}</td>
              <td>{getFormattedPrice(data.total)}</td>
            </tr>
            {getPriceRows(data.discounts)}
            <tr className="total bold">
              <td>{translateText('label', 'Investment per month')}</td>
              <td>{getFormattedPrice(data.invest)}</td>
            </tr>
          </tbody>
        </table>
      ) : (
        <></>
      )}
    </DetailsSection>
  );
}
