import { useState } from 'react';
import { useNavigate } from 'react-router';

import Skeleton from 'components/Skeleton';
import {
  KNOWLEDGE_CENTER_TYPE_INSPIRATION,
  KNOWLEDGE_CENTER_TYPE_MARKETING,
} from 'globals/constants';
import { CategoryFaq, CategoryType } from 'globals/types';
import { useTranslate } from 'hooks/translate';

type Props = {
  faqs: CategoryFaq[];
  categoryTypeId: CategoryType;
  isLoading: boolean;
};

export function Faq({ faqs, categoryTypeId, isLoading }: Props) {
  const translateText = useTranslate();
  const navigate = useNavigate();

  const [activeFaq, setActiveFaq] = useState<number | null>(null);

  let buttonClass = 'btn-lightblue';
  switch (categoryTypeId) {
    case KNOWLEDGE_CENTER_TYPE_MARKETING:
      buttonClass = 'btn-green';
      break;
    case KNOWLEDGE_CENTER_TYPE_INSPIRATION:
      buttonClass = 'btn-yellow';
      break;
  }

  return (
    <div className="faq-wrapper">
      <h2 className="faq-header">{isLoading ? <Skeleton /> : translateText('label', 'FAQ')}</h2>
      <div className={'faq-container' + (isLoading ? ' fade-out' : '')}>
        {isLoading && <Skeleton height={60} count={4} />}
        {faqs.map((faq, index) => (
          <div key={index} className={'faq-expand' + (activeFaq === index ? ' active' : '')}>
            <div
              className="top clickable"
              onClick={() => setActiveFaq(activeFaq === index ? null : index)}
            >
              <span className="bold">{faq.title}</span>
              <div className="arrow-wrapper">
                <div className={'arrow arrow-' + (activeFaq === index ? 'down-right' : 'right')} />
              </div>
            </div>
            {activeFaq === index && (
              <div className="bottom">
                <div dangerouslySetInnerHTML={{ __html: faq.content }} />
                {faq.url && (
                  <button
                    className={'btn ' + buttonClass}
                    onClick={() => navigate(`/knowledge-center/article/${faq.url}`)}
                  >
                    {translateText('label', 'Go to support article')}
                  </button>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
