import { PropsWithChildren } from 'react';

type Props = {
  type?: 'top' | 'bottom';
  noStick?: boolean;
};

export default function StickyBar({ children, type = 'top', noStick }: PropsWithChildren<Props>) {
  let className = 'sticky-bar ' + type;
  if (noStick) className += ' no-stick';

  return (
    <div className={className} data-testid="sticky-bar">
      {children}
    </div>
  );
}
