export default {
    "By setting multiple filters, you can send multiple events for one call. For example, to distinguish call evaluations.": "Door meerdere filters in te stellen, kun je meerdere events versturen voor \u00e9\u00e9n oproep. Bijvoorbeeld voor het onderscheiden van oproepbeoordelingen.",
    "Determine if you want to send a second event to Google Analytics when the call evaluation has changed.": "Bepaal of je een tweede gebeurtenis wilt versturen naar Google Analytics wanneer de beoordeling van de oproep is gewijzigd.",
    "Determine whether calls to fallback phone numbers can be processed in Google Analytics.": "Bepaal of de oproepen op fallback telefoonnummers verwerkt mogen worden in Google Analytics.",
    "Determine whether calls to offsite phone numbers can be processed in Google Analytics.": "Bepaal of de oproepen op offsite telefoonnummers verwerkt mogen worden in Google Analytics.",
    "Determine whether the conversion value may be processed as a transaction in Google Analytics 4.": "Bepaal of de beoordeelde waarde als transactie mag worden verwerkt in Google Analytics 4.",
    "Determine whether the recurring calls can be processed in Google Analytics.": "Bepaal of de terugkerende oproepen verwerkt mogen worden in Google Analytics.",
    "Disable and enable the sending of events to Google Analytics 4 here.": "Schakel hier het versturen van gebeurtenissen naar Google Analytics 4 uit en in.",
    "Enter the API secret associated with the respective Google Analytics property here.": "Vul hier het API-geheim in dat toebehoort aan de betreffende Google Analytics-property.",
    "Enter the measurement ID associated with the respective Google Analytics property here.": "Vul hier het metings-ID in dat toebehoort aan de betreffende Google Analytics-property.",
    "Indicate whether the default AdCalls event can be sent to Google Analytics 4 for each call.": "Geef aan of voor elke oproep de standaardgebeurtenis van AdCalls verstuurd mag worden naar Google Analytics 4.",
    "Name the filter. This is also the name for the event in Google Analytics 4.": "Geef een benaming aan het filter. Dit is ook de benaming voor de gebeurtenis in Google Analytics 4.",
    "Select the desired currency.": "Selecteer de gewenste valuta."
}