import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { cloneDeep, isEqual, pickBy, set } from 'lodash';
import moment from 'moment';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { DEFAULT_PAGE_LIMIT, Filters as TableFilters } from 'components/table/QueryTable';
import {
  CALL_REQUEST_STATUS_DUPLICATE,
  CALL_REQUEST_STATUS_IN_PROCESS,
  CALL_REQUEST_STATUS_INVALID_NUMBER,
  CALL_REQUEST_STATUS_MANUALLY_STOPPED,
  CALL_REQUEST_STATUS_MAXIMUM_ATTEMPTS_REACHED,
  CALL_REQUEST_STATUS_OFFICE_CLOSED,
  CALL_REQUEST_STATUS_PENDING,
  CALL_REQUEST_STATUS_PENDING_DISABLED,
  CALL_REQUEST_STATUS_SUCCESSFUL,
  DATE_PREVIOUS_TYPE_PERIOD,
  DATE_PREVIOUS_TYPE_YEAR,
  TRAFFIC_TYPE_APP,
  TRAFFIC_TYPE_DYNAMIC,
  TRAFFIC_TYPE_OFFSITE,
  TYPE_CALL_TRACKING,
} from 'globals/constants';
import { ServiceType } from 'globals/types';
import { useLocations } from 'hooks/queries/aen';
import { useSources } from 'hooks/queries/source';
import { useTenant } from './Tenant';

export type SelectedFilters = {
  dates: {
    from: string;
    to: string;
    previous: {
      enabled: boolean;
      type: typeof DATE_PREVIOUS_TYPE_PERIOD | typeof DATE_PREVIOUS_TYPE_YEAR;
      to: string;
      from: string;
    };
  };
  locations: string[] | undefined;
  sources: string[] | undefined;
  marked: 0 | 1 | 'allCalls';
  unique: 0 | 1 | 'allCalls';
  traffic: (typeof TRAFFIC_TYPE_DYNAMIC | typeof TRAFFIC_TYPE_OFFSITE | typeof TRAFFIC_TYPE_APP)[];
  statusCallTracking: 0 | 1 | 'allCalls';
  statusFormTracking: (
    | typeof CALL_REQUEST_STATUS_PENDING
    | typeof CALL_REQUEST_STATUS_PENDING_DISABLED
    | typeof CALL_REQUEST_STATUS_IN_PROCESS
    | typeof CALL_REQUEST_STATUS_INVALID_NUMBER
    | typeof CALL_REQUEST_STATUS_DUPLICATE
    | typeof CALL_REQUEST_STATUS_MANUALLY_STOPPED
    | typeof CALL_REQUEST_STATUS_OFFICE_CLOSED
    | typeof CALL_REQUEST_STATUS_MAXIMUM_ATTEMPTS_REACHED
    | typeof CALL_REQUEST_STATUS_SUCCESSFUL
  )[];
  duration: { selected: '>' | '<' | '=' | 'allCalls'; seconds: string };
  statusGA4: ('sent' | 'notSent' | 'notSentYet')[];
  statusGoogleAds: ('sent' | 'notSent' | 'errorOnSending')[];
  statusGoogleAdsEC: ('sent' | 'notSent' | 'errorOnSending')[];
  statusMicrosoftAdvertising: ('sent' | 'notSent' | 'errorOnSending')[];
  statusDoubleClick: ('sent' | 'notSent' | 'errorOnSending')[];
  statusLef: ('sent' | 'notSent' | 'errorOnSending')[];
};

type Filters = {
  filters: State;
  updateFilter: (type: string, value: unknown) => void;
  updateTableFilter: ({ table, type, value }: TableFilterParams) => void;
  resetFilters: () => void;
  getAppliedFilters: (
    filters: Record<string, unknown>,
    tableKey?: string,
    hasPagination?: boolean,
  ) => {
    bodyParams: Record<string, unknown>;
    queryParams: Record<string, unknown>;
  };
};

const FiltersContext = createContext<Filters | null>(null);

const FILTER_UPDATE = 'FILTER_UPDATE' as const;
const FILTER_UPDATE_TABLE = 'FILTER_UPDATE_TABLE' as const;
const FILTER_RESET = 'FILTER_RESET' as const;

type State = SelectedFilters & {
  domain: number | null;
  tables: Record<
    string,
    {
      pagination?: { page: number; pageLimit: number };
    } & Record<string, string>
  >;
};

type TableFilterParams = { table: string } & (
  | { type: 'pagination'; value: { page: number; pageLimit: number } }
  | { type: string; value: string }
);

type Action =
  | { type: typeof FILTER_UPDATE; filter: { type: string; value: unknown } }
  | { type: typeof FILTER_UPDATE_TABLE; filter: TableFilterParams }
  | { type: typeof FILTER_RESET };

export const defaultFilterValues = {
  marked: 'allCalls' as const,
  unique: 'allCalls' as const,
  traffic: [TRAFFIC_TYPE_DYNAMIC, TRAFFIC_TYPE_OFFSITE, TRAFFIC_TYPE_APP],
  statusCallTracking: 'allCalls' as const,
  statusFormTracking: [
    CALL_REQUEST_STATUS_PENDING,
    CALL_REQUEST_STATUS_PENDING_DISABLED,
    CALL_REQUEST_STATUS_IN_PROCESS,
    CALL_REQUEST_STATUS_INVALID_NUMBER,
    CALL_REQUEST_STATUS_DUPLICATE,
    CALL_REQUEST_STATUS_MANUALLY_STOPPED,
    CALL_REQUEST_STATUS_OFFICE_CLOSED,
    CALL_REQUEST_STATUS_MAXIMUM_ATTEMPTS_REACHED,
    CALL_REQUEST_STATUS_SUCCESSFUL,
  ],
  duration: { selected: 'allCalls' as const, seconds: '' },
  statusGA4: ['sent' as const, 'notSent' as const, 'notSentYet' as const],
  statusGoogleAds: ['sent' as const, 'notSent' as const, 'errorOnSending' as const],
  statusGoogleAdsEC: ['sent' as const, 'notSent' as const, 'errorOnSending' as const],
  statusMicrosoftAdvertising: ['sent' as const, 'notSent' as const, 'errorOnSending' as const],
  statusDoubleClick: ['sent' as const, 'notSent' as const, 'errorOnSending' as const],
  statusLef: ['sent' as const, 'notSent' as const, 'errorOnSending' as const],
};

export function FiltersProvider({ children }: PropsWithChildren<Record<string, unknown>>) {
  const { selectedDomain } = useTenant();
  const localStorageFilters = localStorage.getItem('filters');

  const { locations } = useLocations({
    limit: 10000,
    include_inactive: 1,
    expand: 'active',
    is_offline: [TRAFFIC_TYPE_DYNAMIC, TRAFFIC_TYPE_OFFSITE, TRAFFIC_TYPE_APP].join(),
  });
  const { sources } = useSources({
    is_offline: [TRAFFIC_TYPE_DYNAMIC, TRAFFIC_TYPE_OFFSITE, TRAFFIC_TYPE_APP].join(),
  });

  const [state, dispatch] = useReducer(
    reducer,
    localStorageFilters ? JSON.parse(localStorageFilters) : { ...initFilters(), tables: {} },
  );

  function initFilters() {
    const from = moment().subtract(6, 'days').format('DD-MM-YYYY');
    const to = moment().format('DD-MM-YYYY');
    return {
      domain: selectedDomain,
      dates: { from, to, previous: { enabled: false, type: DATE_PREVIOUS_TYPE_PERIOD, to, from } },
      locations: locations ? [...locations.map(l => l.aen_identifier), 'null'] : undefined,
      sources: sources?.filter(s => s.has_call === 'true').map(s => s.id),
      ...defaultFilterValues,
    };
  }

  useEffect(() => {
    if (localStorage.getItem('filters') !== JSON.stringify(state)) {
      localStorage.setItem('filters', JSON.stringify(state));
    }
  }, [state]);

  function reducer(state: State, action: Action): State {
    switch (action.type) {
      case FILTER_UPDATE:
        return {
          ...state,
          [action.filter.type]: action.filter.value,
        };
      case FILTER_UPDATE_TABLE:
        return cloneDeep(
          set(state, ['tables', action.filter.table, action.filter.type], action.filter.value),
        );
      case FILTER_RESET:
        return {
          ...state,
          ...initFilters(),
        };
    }
  }

  const updateFilter = useCallback((type: string, value: unknown) => {
    dispatch({ type: FILTER_UPDATE, filter: { type, value } });
  }, []);

  const updateTableFilter = useCallback((tableParams: TableFilterParams) => {
    dispatch({ type: FILTER_UPDATE_TABLE, filter: tableParams });
  }, []);

  const resetFilters = useCallback(() => {
    dispatch({ type: FILTER_RESET });
  }, []);

  useDeepCompareEffect(() => {
    // This check is needed because the sources and locations only need to be selected when
    // there are no selected sources/locations yet or when the domain is changed
    if (
      locations &&
      sources &&
      ((state.sources === undefined && state.locations === undefined) ||
        selectedDomain !== state.domain)
    ) {
      updateFilter('domain', selectedDomain);
      updateFilter(
        'sources',
        sources?.filter(s => s.has_call === 'true').map(s => s.id),
      );
      updateFilter('locations', [...(locations ?? []).map(l => l.aen_identifier), 'null']);
    }
  }, [
    selectedDomain,
    sources,
    state.domain,
    state.sources,
    locations,
    state.locations,
    updateFilter,
  ]);

  // Update previous dates when date or type changes
  useEffect(() => {
    const from = moment(state.dates.from, 'DD-MM-YYYY');
    const to = moment(state.dates.to, 'DD-MM-YYYY');

    const isTypePeriod = state.dates.previous.type === DATE_PREVIOUS_TYPE_PERIOD;
    const interval = isTypePeriod ? to.diff(from, 'days') + 1 : 1;
    const intervalType = isTypePeriod ? 'days' : 'years';

    updateFilter('dates', {
      ...state.dates,
      previous: {
        ...state.dates.previous,
        from: from.subtract(interval, intervalType).format('DD-MM-YYYY'),
        to: to.subtract(interval, intervalType).format('DD-MM-YYYY'),
      },
    });
  }, [state.dates.from, state.dates.to, state.dates.previous.type]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAppliedFilters = useCallback(
    (filters: TableFilters, tableKey?: string, hasPagination?: boolean) => {
      const currentFilters = tableKey ? state.tables?.[tableKey] : null;

      const bodyParams = {
        ...(filters.locations && state.locations && { aen: state.locations.join() }),
        ...(filters.sources && state.sources && { source: state.sources.join() }),
      };

      const queryParams = {
        ...(filters.domain !== false && selectedDomain && { domain: selectedDomain }),
        ...(currentFilters?.globalSearch ? { global_search: currentFilters.globalSearch } : {}),
        ...(hasPagination && {
          page: currentFilters?.pagination?.page ?? 1,
          limit: currentFilters?.pagination?.pageLimit ?? DEFAULT_PAGE_LIMIT,
        }),
        ...(filters.date && { from: state.dates.from, to: state.dates.to }),
        ...(filters.previousPeriod &&
          state.dates.previous.enabled && { comparison_type: state.dates.previous.type }),
        ...(filters.traffic &&
          !isEqual(defaultFilterValues.traffic, state.traffic) && {
            is_offline: state.traffic.join(),
          }),
        ...(filters.marked &&
          defaultFilterValues.marked !== state.marked && { marked: !!state.marked }),
        ...(filters.unique &&
          defaultFilterValues.unique !== state.unique && { is_recurring: state.unique }),
        ...(filters.duration &&
          state.duration.selected !== defaultFilterValues.duration.selected && {
            durationOperand: state.duration.selected,
            duration: moment.unix(Number(state.duration.seconds)).utcOffset(0).format('HH:mm:ss'),
          }),
        ...(filters.statusCallTracking &&
          defaultFilterValues.statusCallTracking !== state.statusCallTracking && {
            answered: state.statusCallTracking,
          }),
        ...(filters.statusFormTracking &&
          !isEqual(defaultFilterValues.statusFormTracking, state.statusFormTracking) && {
            status: state.statusFormTracking.join(),
          }),
        ...(filters.statusGA4 &&
          !isEqual(defaultFilterValues.statusGA4, state.statusGA4) && {
            is_sent_to_ga4: state.statusGA4?.join(),
          }),
        ...(filters.statusGoogleAds &&
          !isEqual(defaultFilterValues.statusGoogleAds, state.statusGoogleAds) && {
            is_sent_to_google: state.statusGoogleAds.join(),
          }),
        ...(filters.statusGoogleAdsEC &&
          !isEqual(defaultFilterValues.statusGoogleAds, state.statusGoogleAds) && {
            status_google_ads_ec: state.statusGoogleAdsEC.join(),
          }),
        ...(filters.statusMicrosoftAdvertising &&
          !isEqual(
            defaultFilterValues.statusMicrosoftAdvertising,
            state.statusMicrosoftAdvertising,
          ) && { is_sent_to_bing: state.statusMicrosoftAdvertising.join() }),
        ...(filters.statusDoubleClick &&
          !isEqual(defaultFilterValues.statusDoubleClick, state.statusDoubleClick) && {
            is_sent_to_doubleclick: state.statusDoubleClick.join(),
          }),
        ...(filters.statusLef &&
          !isEqual(defaultFilterValues.statusLef, state.statusLef) && {
            is_sent_to_lef: state.statusLef.join(),
          }),
        ...(filters.custom ?? {}),
        ...pickBy({ ...currentFilters }, (value, key) => {
          return key !== 'pagination' && key !== 'globalSearch' && value;
        }),
      };

      return { bodyParams, queryParams };
    },
    [selectedDomain, state],
  );

  return (
    <FiltersContext.Provider
      value={{
        filters: useMemo(() => state, [state]),
        updateFilter,
        updateTableFilter,
        resetFilters,
        getAppliedFilters,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
}

export function useFilters() {
  const filters = useContext(FiltersContext);
  if (filters === null) {
    throw new Error('Filters context not available');
  }
  return filters;
}

export function getDefaultFilters(type: ServiceType) {
  const callTrackingOnly = type === TYPE_CALL_TRACKING;
  return {
    date: true,
    previousPeriod: true,
    traffic: callTrackingOnly,
    locations: true,
    sources: callTrackingOnly,
    marked: callTrackingOnly,
    unique: callTrackingOnly,
    statusCallTracking: callTrackingOnly,
    statusFormTracking: !callTrackingOnly,
    duration: callTrackingOnly,
    statusGA4: true,
    statusGoogleAds: true,
    statusGoogleAdsEC: callTrackingOnly,
    statusMicrosoftAdvertising: true,
    statusDoubleClick: callTrackingOnly,
  };
}
