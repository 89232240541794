import icon from 'assets/images/icons/integrations/salesforce.svg';
import BaseCrmIntegration from 'components/integrations/BaseCrmIntegration';
import { REDIRECT_URI_SALESFORCE } from 'components/oauth2/Verification';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import { INTEGRATION_SALESFORCE } from 'globals/constants';
import { SUP_ART_SALESFORCE } from 'globals/support';
import { useHasAccessToIntegration } from 'hooks/access';
import { useTranslate } from 'hooks/translate';

export default function Salesforce({ show, close }: BaseSlideInProps) {
  const translateText = useTranslate();
  const hasAccessToIntegration = useHasAccessToIntegration();

  if (!hasAccessToIntegration(INTEGRATION_SALESFORCE)) return null;

  return (
    <BaseCrmIntegration
      show={show}
      close={close}
      icon={icon}
      integrationKey="salesforce"
      redirectUriKey={REDIRECT_URI_SALESFORCE}
      title={translateText('label', 'Salesforce')}
      articleId={SUP_ART_SALESFORCE}
      infoText={translateText(
        'integration',
        'Salesforce is a cloud based platform for customer relationship management (CRM). With the help of this integration you can enrich contact profiles in Salesforce with data of incoming calls.',
      )}
      canAddContact
      price={99}
    />
  );
}
