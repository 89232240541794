import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';

import { useMessages } from 'contexts/Messages';
import { useTenant } from 'contexts/Tenant';
import { useSetQueryData } from 'hooks/data';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';

export function useApi(enabled: boolean) {
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ['api', selectedDomain],
    queryFn: (): Promise<{ enabled: '0' | '1'; token: string | null }> =>
      fetchData('/api-user/view', { queryParams: { domain_id: selectedDomain } }),
    enabled: selectedDomain !== null && enabled,
  });

  const { mutateAsync: updateApi } = useMutation({
    mutationFn: (enabled: boolean) =>
      fetchData('/api-user/change', {
        method: 'PUT',
        queryParams: { domain_id: selectedDomain },
        bodyParams: { enabled: String(+enabled) },
      }),
    onSuccess: (data, enabled) => {
      setQueryData<Record<string, number>>(['integrationStatuses', selectedDomain], oldData => ({
        ...oldData,
        adCallsApi: +enabled,
      }));
      setQueryData<{ enabled: string; token: string }>(['api', selectedDomain], () => ({
        enabled: String(+enabled),
        token: data ?? null,
      }));
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { data, isLoading, updateApi };
}

export function useRefreshToken() {
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const [isRefreshed, setIsRefreshed] = useState(false);

  const { mutateAsync: refreshToken, isPending: isRefreshing } = useMutation({
    mutationFn: () =>
      fetchData('/api-user/reset', {
        method: 'PUT',
        queryParams: { domain_id: selectedDomain },
      }),
    onSuccess: data => {
      setIsRefreshed(true); // This is to ensure that the user cannot spam the refresh token.
      setQueryData<{ enabled: string; token: string }>(['api', selectedDomain], oldData => ({
        ...oldData,
        token: data,
      }));
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { refreshToken, isRefreshing, isRefreshed };
}
