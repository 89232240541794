import { useTranslate } from 'hooks/translate';
import StepBlock from './StepBlock';

export default function ThanksSection() {
  const translateText = useTranslate();
  return (
    <div className="thanks-section">
      <StepBlock title={translateText('text', 'Thank you for your request.')}>
        <div className="white-block">
          <h4 className="bold">
            {translateText('text', 'Nice that you are going to start with AdCalls.')}
          </h4>
          <p
            dangerouslySetInnerHTML={{
              __html: translateText(
                'text',
                'Thank you for your request! The domain will be delivered immediately or soon. You will be kept informed of the status of your application by e-mail. Or check the status on the <a href="/requests/overview">Requests</a> page.',
              ),
            }}
          />

          <p
            dangerouslySetInnerHTML={{
              __html: translateText(
                'text',
                'Once the application has been submitted, you can start implementing AdCalls. You can find all steps for implementing AdCalls on our support. <a href="/knowledge-center/support">Take a look.</a>',
              ),
            }}
          />

          <p
            dangerouslySetInnerHTML={{
              __html: translateText(
                'text',
                'Do you need help with the implementation or would you like to check whether the implementation is complete? Please contact us via <a href="mailto:support@adcalls.nl">support@adcalls.nl</a>.',
              ),
            }}
          />
        </div>
      </StepBlock>
    </div>
  );
}
