import { MouseEventHandler } from 'react';

import ConfigurationCard from 'components/configuration/ConfigurationCard';
import GrayBars from 'components/GrayBars';
import { FREQUENCY_MONTHLY, FREQUENCY_OFF, FREQUENCY_WEEKLY } from 'globals/constants';
import { SUP_ART_PERIODIC_REPORTS } from 'globals/support';
import { useDomainAuth } from 'hooks/queries/domain';
import { useReportEmail } from 'hooks/queries/domain-setting';
import { useTranslate } from 'hooks/translate';

type Props = {
  settingsKey: 'history' | 'prestation' | 'reports';
  title: string;
  onEdit: MouseEventHandler;
};

export default function SendReportCard({ settingsKey, title, onEdit }: Props) {
  const translateText = useTranslate();
  const { reportEmailSettings, isLoading: isLoadingReportEmail } = useReportEmail();
  const { isLoading: isLoadingDomainAuth } = useDomainAuth();
  const isLoading = isLoadingReportEmail || isLoadingDomainAuth;
  let frequency = translateText('label', 'Daily');
  if (reportEmailSettings?.[settingsKey].frequency === FREQUENCY_WEEKLY) {
    frequency = translateText('label', 'Weekly');
  } else if (reportEmailSettings?.[settingsKey].frequency === FREQUENCY_MONTHLY) {
    frequency = translateText('label', 'Monthly');
  }
  return (
    <ConfigurationCard
      title={title}
      enabled={reportEmailSettings?.[settingsKey].frequency !== FREQUENCY_OFF}
      extraEnabledText={frequency}
      rightParagraph={translateText(
        'call-tracking',
        'Send a periodic report of your Call Tracking data. Depending on the period, the reports will be sent every first of the month or on Monday.',
      )}
      supportArticleId={SUP_ART_PERIODIC_REPORTS}
      onEdit={onEdit}
      isLoading={isLoading}
    >
      <GrayBars
        rows={reportEmailSettings?.[settingsKey].emailAddresses ?? []}
        emptyLabel={translateText('label', 'There are no email addresses set.')}
        isLoading={isLoading}
        singleItems
        private
      />
    </ConfigurationCard>
  );
}
