export default {
    "A filter can only be added once a stream is created.": "Er kan pas een filter worden toegevoegd zodra er een stream gekoppeld is.",
    "Activate your account so you can get started with AdCalls right away. An email with further instructions has been sent to <span data-private>{email}<\/span>.": "Activeer je account vandaag nog, zodat je direct aan de slag kan met AdCalls. Er is een e-mail met verdere instructies toegestuurd aan <span data-private>{email}<\/span>.",
    "Add the phone numbers you want to track for Call Tracking here. Create multiple locations for, for example, different locations or departments.": "Voeg hier de telefoonnummers toe die je wilt meten voor Call Tracking. Maak meerdere locaties aan voor bijvoorbeeld verschillende locaties of afdelingen.",
    "An application is requesting access to the data of the AdCalls domains to which you have access.": "Een applicatie vraagt toegang tot de data van de AdCalls domeinen waar je toegang toe hebt.",
    "Are you running into problems? Then see the following <a href=\"{link}\" target=\"_blank\">support article<\/a>. Or contact us via <a href=\"tel:+31307400990\">+31 (0)30 7400 990<\/a> or via <a href=\"mailto:support@adcalls.nl\">support@adcalls.nl<\/a>.": "Loop je tegen problemen aan? Zie dan ons volgende <a href=\"{link}\" target=\"_blank\">support-artikel<\/a>. Of neem contact met ons op via <a href=\"mailto:support@adcalls.nl\">support@adcalls.nl<\/a> of via <a href=\"tel:+31307400990\">+31 (0)30 7400 990 <\/a>.",
    "Are you sure you want to revoke access for the domain {domain}?": "Weet je zeker dat je de toegang van het domein {domain} wilt intrekken?",
    "Are you sure you want to revoke access from the customer center {name}?": "Weet je zeker dat je de toegang van het klantcentrum {name} wilt intrekken?",
    "Are you sure you want to revoke access to the customer center for user {user}?": "Weet je zeker dat je de toegang tot het klantcentrum voor de gebruiker {user} wilt ontzeggen?",
    "Are you sure you want to revoke access to {domain} for user {user}?": "Weet je zeker dat je de toegang tot {domain} voor de gebruiker {user} wilt ontzeggen?",
    "Are you sure you want to revoke the invitation?": "Weet je zeker dat je de uitnodiging wilt intrekken?",
    "Are you sure you want to switch to the Optimize package and start invoicing immediately?": "Weet je zeker dat je wilt overstappen naar het Optimize-pakket en de facturatie per direct wilt starten?",
    "Are you sure you want to sync script for {domain}?": "Weet je zeker dat je het script voor {domain} wil synchroniseren?",
    "Are you sure you want to sync scripts for all domains?": "Weet je zeker dat je het script voor alle domeinen wil synchroniseren?",
    "Below you will find a price indication for the new contract. The indication is based on the usage of the past month and the current setup of the selected domain.": "Onderstaand vind je een prijsindicatie voor het nieuwe contract. De indicatie is gebaseerd op het verbruik van de afgelopen maand en de huidige opzet van het geselecteerde domein.",
    "By accepting the 'AdCalls Processor Agreement' you declare that you are authorized as a 'Data Controller'. Are you not authorized? Then invite the 'Data Controller' to the AdCalls domain as soon as you have completed the application. So that he or she can still accept the processing agreement via our dashboard.": "Met het accepteren van de 'Verwerkersovereenkomst van AdCalls' verklaar je bevoegd te zijn als 'Verwerkingsverantwoordelijke'. Ben je niet bevoegd? Nodig dan de 'Verwerkingsverantwoordelijke' uit tot het AdCalls-domein, zodra je de aanvraag hebt afgerond. Zodat hij of zij alsnog de verwerkersovereenkomst kan accepteren via ons dashboard.",
    "By setting up two-step verification, you add extra security to your user account.": "Door het instellen van tweestapsverificatie voeg je een extra beveiliging toe aan je gebruikersaccount.",
    "Call recording is disabled. Read <a href=\"{link}\">here<\/a> how to enable call recording.": "Call recording staat uitgeschakeld. Lees <a href=\"{link}\">hier<\/a> hoe je call recording inschakelt.",
    "Change the details of this location here.": "Wijzig hier de details van deze locatie.",
    "Click here for the change request.": "Klik hier voor het wijzigingsverzoek.",
    "Do you have any questions about your subscription? Please contact us via <a href=\"tel:+31307400990\">+31 (0)30 7400 990<\/a> or via <a href=\"mailto:support@adcalls.nl\">support@adcalls.nl<\/a>.": "Heb je nog vragen over je abonnement? Neem dan contact met ons op via <a href=\"tel:+31307400990\">+31 (0)30 7400 990<\/a> of via <a href=\"mailto:support@adcalls.nl\">support@adcalls.nl<\/a>.",
    "Do you have any questions? Contact us by email at <a href=\"mailto:support@adcalls.nl\">support@adcalls.nl<\/a> or call <a href=\"tel:+31307400990\">+31 (0)30 7400 990<\/a>.": "Heb je nog vragen? Neem dan contact met ons op via <a href=\"mailto:support@adcalls.nl\">support@adcalls.nl<\/a> of bel <a href=\"tel:+31307400990\">+31 (0)30 7400 990<\/a>.",
    "Do you need help with the implementation or would you like to check whether the implementation is complete? Please contact us via <a href=\"mailto:support@adcalls.nl\">support@adcalls.nl<\/a>.": "Heb je hulp nodig bij de implementatie of wil jij graag een check of de implementatie volledig is? Neem dan contact met ons op via <a href=\"mailto:support@adcalls.nl\">support@adcalls.nl<\/a>",
    "Do you want to permanently stop using AdCalls for this domain? Then click on the gears. No cancellation period applies to trial subscriptions. The cancellation request and the definitive cancellation will be confirmed by e-mail.": "Wil jij de inzet van AdCalls voor dit domein definitief stopzetten? Klik dan op de tandwielen. Voor proefabonnementen is geen opzegtermijn van toepassing. De aanvraag voor de stopzetting en de definitieve stopzetting worden per e-mail bevestigd.",
    "Do you want to permanently stop using AdCalls for this domain? Then click on the gears. We use a notice period of at least one month. The cancellation request and the definitive cancellation will be confirmed by e-mail.": "Wil jij de inzet van AdCalls voor dit domein definitief stopzetten? Klik dan op de tandwielen. Wij hanteren een opzegtermijn van minimaal \u00e9\u00e9n maand. De aanvraag voor de stopzetting en de definitieve stopzetting worden per e-mail bevestigd.",
    "Does your audio player not work properly? You can best listen to the calls in one of the following browsers: Google Chrome, Microsoft Edge or Safari.": "Functioneert je audioplayer niet optimaal? Je kunt de oproepen het beste terugluisteren in \u00e9\u00e9n van de volgende browsers: Google Chrome, Microsoft Edge of Safari.",
    "Don't have an account yet, but want to start with AdCalls? Create an account and start today.": "Heb je nog geen account, maar wil je starten met AdCalls? Maak een account aan en start vandaag nog.",
    "Enter a title for the expandable paragraph": "Geef een titel op voor de uitklapbare alinea",
    "Enter the desired email addresses below. Multiple e-mail addresses must be entered with a comma.": "Voer hieronder de gewenste e-mailadressen in. Meerdere e-mailadressen moet worden gescheiden door een komma.",
    "Enter your information to activate your account.": "Voer jouw gegevens in om jouw account te activeren.",
    "Enter your new password below.": "Vul hieronder je nieuwe wachtwoord in.",
    "Enter your password to resume the session.": "Geef je wachtwoord op om de sessie te hervatten.",
    "First select a pricing plan and a contract duration to get a price indication.": "Selecteer eerst een prijsplan en een contractduur om een prijsindicatie te krijgen.",
    "For a longer term, please contact your AdCalls Partner Manager.": "Neem voor een langere termijn contact op met je Partner Manager van AdCalls.",
    "For security reasons, we require two-step verification for your user account. Please follow the steps to activate two-step verification.": "Uit veiligheidsoverwegingen verplichten wij tweestapsverificatie voor je gebruikersaccount. Doorloop de stappen om tweestapsverificatie te activeren.",
    "Futy is a tool that helps you convert website visitors into leads. These leadbots are small, interactive chatbots that appear on your website and can help visitors with various tasks, such as contacting your company, answering questions, scheduling appointments, or filling out forms.": "Futy is een tool die je helpt websitebezoekers om te zetten in leads. Deze leadbots zijn kleine, interactieve chatbots die op je website verschijnen en bezoekers kunnen helpen met verschillende taken, zoals contact opnemen met je bedrijf, vragen beantwoorden, afspraken inplannen of formulieren invullen.",
    "Go to the authenticator app on your phone and enter the code in the input field below.": "Ga naar de authenticator app op je telefoon en geef de code op in het onderstaande invoerveld.",
    "Have the billing details changed? Please notify us of the changes via <a href=\"mailto:support@adcalls.nl\">support@adcalls.nl<\/a>.": "Zijn de facturatiegegevens gewijzigd? Geef de wijzigingen aan ons door via <a href=\"mailto:support@adcalls.nl\">support@adcalls.nl<\/a>.",
    "If you have any questions about this, you can always contact us via <a href=\"mailto:support@adcalls.nl\">support@adcalls.nl<\/a> or by calling <a href=\"tel:+31307400990\">+31 (0)30 7400 990<\/a>.": "Mocht je hierover nog vragen hebben, dan kun je altijd contact met ons opnemen via <a href=\"mailto:support@adcalls.nl\">support@adcalls.nl<\/a> of door te bellen naar <a href=\"tel:+31307400990\">+31 (0)30 7400 990 <\/a>.",
    "If you have not been able to solve your problem via our support page, request a support ticket using the form below.": "Als je je probleem niet hebt kunnen oplossen via onze support-pagina, vraag dan een support-ticket aan via onderstaand formulier.",
    "If your e-mail address is known to us, you have received an e-mail. Set a new password via the link in the email. The link is valid for 24 hours.": "Als je e-mailadres bij ons bekend is, heb je een mail ontvangen. Stel via de link in de mail snel een nieuw wachtwoord in. De link is 24 uur geldig.",
    "In addition, within the Enterprise package, we offer an onboarding process, customized solutions, and assign a dedicated Customer Success Manager to you. You also have the option for an SLA, and we provide support for privacy issues.": "Daarnaast bieden wij binnen het Enterprise pakket een onboardingstraject, maatwerk en krijg je een Customer Success Manager toegewezen. Ook heb je de optie voor een SLA en bieden wij ondersteuning bij privacy vraagstukken.",
    "In the Enterprise package, we offer dynamic Call Tracking for businesses that want to demonstrate the value of phone calls by integrating Call Tracking data into their CRM system and campaigns.": "In het Enterprise pakket bieden wij dynamische Call Tracking voor bedrijven die de waarde van telefonie aantoonbaar willen maken door Call Tracking-data te integreren in hun CRM-systeem en campagnes.",
    "In the Optimize package, we offer dynamic Call Tracking for businesses that want to measure phone conversions to optimize their campaigns based on search behavior.": "In het Optimize pakket bieden wij dynamische Call Tracking voor bedrijven die telefonische conversies willen meten om hun campagnes te optimaliseren op basis van zoekgedrag.",
    "Indicate whether you want to resume the session.": "Geef aan of je de sessie wilt hervatten.",
    "Is someone else performing the implementation? In this case, you can send the full implementation instructions by email. Enter the desired email addresses below. Multiple e-mail addresses must be entered with a comma.": "Verricht iemand anders de implementatie? In dit geval kun je de volledige implementatie-instructies per e-mail versturen. Voer hieronder de gewenste e-mailadressen in. Meerdere e-mailadressen moet worden gescheiden door een komma.",
    "Navigate to another page via the menu or contact us at ": "Navigeer naar een andere pagina via het menu of neem contact met ons op via ",
    "Nice that you are going to create an account for our dashboard. Enter your information and get started with the services of AdCalls. Your account will be activated once you have confirmed the verification link. This will be sent to you per email immediately after registering.": "Leuk dat je een account gaat aanmaken voor ons dashboard. Vul hieronder al jouw gegevens in en ga aan de slag met de diensten van AdCalls. Je account wordt geactiveerd zodra je de verificatie-link hebt bevestigd. De link wordt direct na het aanmaken van jouw account per e-mail toegestuurd.",
    "Nice that you are going to start with AdCalls.": "Leuk dat je gaat starten met AdCalls.",
    "No filters have been set yet. Click on the + to set up a filter.": "Er zijn nog geen filters ingesteld. Klik op de + om een filter in te stellen.",
    "No problem! Enter your email address and receive a link by e-mail with which you can set a new password.": "Geen probleem! Vul je e-mailadres in en ontvang per e-mail een link waarmee je een nieuw wachtwoord instelt.",
    "No recording available.": "Geen opname beschikbaar.",
    "No successful call request has taken place.": "Er heeft geen succesvol terugbelverzoek plaatsgevonden.",
    "No webhooks have been configured.": "Er zijn geen webhooks ingesteld.",
    "Once the application has been submitted, you can start implementing AdCalls. You can find all steps for implementing AdCalls on our support. <a href=\"\/knowledge-center\/support\">Take a look.<\/a>": "Zodra de aanvraag is opgeleverd, kun je aan de slag met het implementeren van AdCalls. Je vindt alle stappen voor het implementeren van AdCalls op onze support. <a href=\"\/knowledge-center\/support\">Neem alvast een kijkje.<\/a>",
    "Possible punctuation marks: {marks}": "Mogelijke leestekens: {marks}",
    "Scan the QR code with an authenticator app or enter the code below into the app. We recommend using the Google Authenticator app.": "Scan de QR-code met een authenticator app of voer de onderstaande code in bij de app. Wij adviseren gebruik te maken van de Google Authenticator app.",
    "Select a domain in the domain selector.": "Selecteer een domein in de domein selector.",
    "Specify which traffic sources you want to track for Call Tracking.": "Geef aan welke verkeersbronnen je wilt meten voor Call Tracking.",
    "Thank you for extending your subscription!": "Bedankt voor het verlengen van je abonnement!",
    "Thank you for upgrading your subscription!": "Bedankt voor het upgraden van je abonnement!",
    "Thank you for your request! The domain will be delivered immediately or soon. You will be kept informed of the status of your application by e-mail. Or check the status on the <a href=\"\/requests\/overview\">Requests<\/a> page.": "Bedankt voor je aanvraag! De oplevering zal direct of spoedig worden verricht. Middels e-mail word je op de hoogte gehouden van de status van je aanvraag. Ook kun je de status checken op de pagina <a href=\"\/requests\/overview\">Aanvragen<\/a>.",
    "Thank you for your request.": "Bedankt voor je aanvraag.",
    "The costs for Call Tracking consist of fixed costs and variable costs. We apply a fixed rate for the license fee, and the costs are further calculated based on additional usage. We offer two packages:": "De kosten voor Call Tracking bestaan uit vaste kosten en variabele kosten. Zo hanteren wij een vast tarief voor de licentiekosten en worden de kosten verder berekend op basis van het verdere verbruik. Wij bieden twee pakketten aan:",
    "The data processor agreement has been signed offline.": "De verwerkersovereenkomst is offline getekend.",
    "The domain has been stopped on {stopDate}.": "Het domein is stopgezet op {stopDate}.",
    "The dynamic phone number for Call Tracking will be displayed on the website in this format. We advise you to use the same format as the phone numbers without Call Tracking that are communicated on the website.[manager] Do you want to change the format? Then click on the gears.[\/manager]": "In dit format zal het dynamische telefoonnummer voor Call Tracking getoond worden op de website. Wij adviseren hier hetzelfde format aan te houden zoals de telefoonnummers zonder Call Tracking worden gecommuniceerd op de website.[manager] Wil jij het format wijzigen? Klik dan op de tandwielen.[\/manager]",
    "The listed prices are per license, per month excluding 21% VAT, unless otherwise stated.": "De genoemde prijzen zijn per licentie, per maand excl. 21% BTW, tenzij anders vermeld.",
    "The page you were looking for does not exist.": "De pagina waar je naar op zoek bent, bestaat niet (meer).",
    "The phone number has been added to the blacklist": "Het telefoonnummer is aan de zwarte lijst toegevoegd",
    "There are no call request evaluations.": "Er zijn geen terugbelverzoek beoordelingen.",
    "There is a request stop this domain. The domain will be stopped on {stopDate}. Do you want to revoke the stop? Then click on the gears.": "Voor dit domein is een stopzetting aangevraagd. Het domein wordt stopgezet op {stopDate}. Wil je de stopzetting intrekken? Klik dan op de tandwielen.",
    "There is a request to stop this location.": "Er is een verzoek om deze locatie te stoppen.",
    "This account does not have access to a domain yet. A manager can invite you to an existing domain, or you can request a new domain yourself.": "Er is nog geen domein gekoppeld aan dit account. Een beheerder kan je uitnodigen tot een bestaand domein of je kunt zelf een nieuw domein aanvragen.",
    "This intake does not meet all the conditions for a trial.": "Deze intake voldoet niet aan alle voorwaarden voor een proefperiode.",
    "This location is in request.": "Deze locatie is in aanvraag.",
    "This package is available from \u20ac{price},-. This includes {sessions} sessions, 1 dynamic location (destination number), and 10 offsite phone numbers. Of course, you can expand with more sessions, locations, and offsite phone numbers.": "Dit pakket is beschikbaar vanaf \u20ac{price},-. Dit is inclusief {sessions} sessies, 1 dynamische locatie (bestemmingsnummer) en 10 offsite telefoonnummers. Uiteraard kun je uitbreiden met meer sessies, locaties en offsite telefoonnummers.",
    "This page is not yet visible, because the domain has not yet been delivered. The delivery of a domain usually takes two working days, but can only take place once the Processor Agreement has been signed.": "Deze pagina is nog niet zichtbaar, omdat het domein nog niet is opgeleverd. De oplevering van een domein neemt doorgaans twee werkdagen in beslag, maar kan pas plaatsvinden zodra de Verwerkersovereenkomst is ondertekend.",
    "This recording is no longer available due to privacy guidelines (<a href=\"{link}\">more information<\/a>).": "Deze opname is niet meer beschikbaar in verband met privacy-richtlijnen (<a href=\"{link}\">meer informatie<\/a>).",
    "To set or change the traffic sources, click on the gears.": "Voor het instellen of wijzigen van de verkeersbronnen, klik je op de tandwielen.",
    "Unfortunately, the verification of your account failed. Most likely the verification link is no longer valid. Please contact us at <a href=\"mailto:support@adcalls.nl\">support@adcalls.nl<\/a>, so that you can still verify your account.": "Helaas, de verificatie van jouw gebruikersaccount is mislukt. Hoogstwaarschijnlijk is de verificatielink niet meer geldig. Neem contact met ons op via <a href=\"mailto:support@adcalls.nl\">support@adcalls.nl<\/a>, zodat je alsnog je gebruikersaccount kunt verifi\u00ebren.",
    "Unfortunately, this call does not exist or you do not have access to the call.": "Helaas, deze oproep bestaat niet of je hebt geen toegang tot de oproep.",
    "Unfortunately, this call request does not exist or you do not have access to the request.": "Helaas, dit terugbelverzoek bestaat niet of je hebt geen toegang tot het verzoek.",
    "Unfortunately, this change does not exist or you do not have access to the change.": "Helaas, deze wijziging bestaat niet of je hebt geen toegang tot de wijziging.",
    "Unfortunately, this notification does not exist or you do not have access to the notication.": "Helaas, deze notificatie bestaat niet of je hebt geen toegang tot de notificatie.",
    "Unfortunately, this page does not exist": "Helaas, deze pagina bestaat niet (meer)",
    "Using the AdCalls integration, you can also show phone numbers for Call Tracking in Futy's chatbot. Use of this integration must be enabled on the Futy side.": "Met behulp van de integratie van AdCalls kun je ook telefoonnummers voor Call Tracking tonen in de chatbot van Futy. Het gebruik van deze integratie moet aan de zijde van Futy worden ingeschakeld.",
    "With Call Tracking, you link your search and browsing behavior to your phone conversations. This way, you know what the full results of your marketing activities are. With this information, you can use your marketing budget much more efficiently and increase your return on investment.": "Met Call Tracking koppel je het zoek- en surfgedrag aan jouw telefonische conversies. Zo weet jij wat de volledige resultaten van jouw marketingactiviteiten zijn. Hiermee kun jij je marketingbudget veel effici\u00ebnter inzetten en het rendement verhogen.",
    "Yes, I want to switch to the Optimize package for full functionality. Billing may be activated.": "Ja, ik wil overstappen naar het Optimize-pakket voor volledige functionaliteit. De facturatie mag worden geactiveerd.",
    "You are not the manager of this domain. For this reason you can't view or sign the Data Processor Agreement. Optionally, select a different domain.": "Je bent niet de beheerder van dit domein. Om deze reden kun je de Verwerkersovereenkomst niet inzien en accepteren. Selecteer eventueel een ander domein.",
    "You can enter multiple formats by separating them with a comma (,).": "Je kunt meerdere formats opgeven door ze te scheiden met behulp van een komma (,).",
    "You can use different authenticators. We recommend the one from Google. Download the Google Authenticator app in the <a href=\"{playStoreLink}\" target=\"_blank\">Play store<\/a> or in the <a href=\"{appStoreLink}\" target=\"_blank\">App store<\/a>.": "Je kunt gebruik maken van verschillende authenticators. Wij adviseren die van Google. Download de Google Authenticator app in de <a href=\"{playStoreLink}\" target=\"_blank\">Play store<\/a> of in de <a href=\"{appStoreLink}\" target=\"_blank\">App store<\/a>.",
    "You cannot add {item}, because you do not have the correct permissions.": "Je kunt geen {item} toevoegen, omdat je niet beschikt over de juiste rechten.",
    "You cannot edit the settings, because there is no active location available for this domain.": "Je kunt de instellingen niet aanpassen, omdat er nog geen locatie actief staat voor dit domein.",
    "You cannot edit the settings, because you do not have the correct permissions.": "Je kunt de instellingen niet aanpassen, omdat je niet beschikt over de juiste rechten.",
    "You cannot extend the subscription, because you do not have the correct permissions. Ask a domain manager for help.": "Je kunt geen verlenging uitvoeren, omdat je niet beschikt over de juiste rechten. Vraag een beheerder van het domein om hulp.",
    "You cannot upgrade the subscription, because you do not have the correct permissions. Ask a domain manager for help.": "Je kunt geen upgrade uitvoeren, omdat je niet beschikt over de juiste rechten. Vraag een beheerder van het domein om hulp.",
    "You were logged in as <span data-private>{name}<\/span> with the e-mail address <span data-private>{email}<\/span>.": "Je was ingelogd als <span data-private>{name}<\/span> met het e-mailadres <span data-private>{email}<\/span>.",
    "You were logged in with the email address <span data-private>{email}<\/span>.": "Je was ingelogd met het e-mailadres <span data-private>{email}<\/span>.",
    "Your account has been verified. You can now log in to our dashboard and get started with AdCalls right away. Do you have any questions about all the possibilities of AdCalls or do you have any questions about our dashboard? Please contact us via <a href=\"mailto:support@adcalls.nl\">support@adcalls.nl<\/a> or call <a href=\"tel:+31307400990\">+31 (0)30 7400 990<\/a>.": "Jouw gebruikersaccount is geverifieerd. Je kunt nu inloggen op ons dashboard en direct aan de slag met AdCalls. Heb je nog vragen over alle mogelijkheden van AdCalls of heb je nog vragen over ons dashboard? Neem dan contact met ons op via <a href=\"mailto:support@adcalls.nl\">support@adcalls.nl<\/a> of bel <a href=\"tel:+31307400990\">+31 (0)30 7400 990<\/a>.",
    "Your browser does not support the audio element.": "Je browser ondersteunt het audio-element niet.",
    "Your session has expired, but we have remembered your details. Indicate whether you want to proceed to the dashboard or log in as another user.": "Je sessie is verlopen, maar wij hebben jouw gegevens onthouden. Geef aan of je wilt doorgaan naar het dashboard of wilt inloggen als een andere gebruiker."
}