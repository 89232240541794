import { useSyncChargebeeInvoicesToExact } from 'hooks/queries/invoicing';
import { useTranslate } from 'hooks/translate';

export default function Invoicing() {
  const translateText = useTranslate();
  const { syncChargbeeInvoicesToExact, isLoading } = useSyncChargebeeInvoicesToExact();
  return (
    <>
      <h1>{translateText('label', 'Invoicing')}</h1>
      <button
        className="btn btn-green"
        type="submit"
        disabled={isLoading}
        onClick={() => syncChargbeeInvoicesToExact()}
      >
        {translateText('label', 'Sync Chargebee invoices to Exact')}
      </button>
    </>
  );
}
