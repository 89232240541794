import { useIsFetching } from '@tanstack/react-query';

import ReportsPage from 'components/ReportsPage';
import QueryTable from 'components/table/QueryTable';
import Widget from 'components/widget/Widget';
import { useRemoveExcludeFromUpgrade } from 'hooks/queries/domain';
import { useTranslate } from 'hooks/translate';

export default function UpgradeStatus() {
  const translateText = useTranslate();
  const { removeExcludeFromUpgrade } = useRemoveExcludeFromUpgrade();
  const queriesFetching = useIsFetching({ queryKey: ['admin', 'upgrade'] });
  const isLoading = queriesFetching > 0;

  function getUpgradeText(text: string) {
    return translateText('duplicate', 'Upgrade') + ' ' + text.toLowerCase();
  }

  return (
    <ReportsPage queryKey={['admin', 'upgrade']} title={translateText('label', 'Upgrade status')}>
      <Widget
        endpoint="/domain/upgrade-statistics"
        type="statistics"
        className="upgrade-statistics"
        dataKeys={{
          no_upgrade_requested: { name: translateText('label', 'No upgrade requested') },
          upgrade_open: { name: getUpgradeText(translateText('label', 'Open')) },
          upgrade_processed: { name: getUpgradeText(translateText('label', 'Processed')) },
          discontinue_requested: { name: translateText('label', 'Discontinue requested') },
          excluded_from_upgrade: { name: translateText('label', 'Excluded from upgrade') },
        }}
        widgetKey={['admin', 'upgrade', 'statistics']}
        isLoading={isLoading}
      />
      <Widget
        endpoint="/domain/upgrade-statistics"
        type="statistics"
        className="upgrade-statistics"
        dataKeys={{
          basic: { name: translateText('label', 'Basic') },
          basic_intl: { name: translateText('label', 'Basic international') },
          light: { name: translateText('label', 'Light') },
          light_intl: { name: translateText('label', 'Light international') },
          optimize: { name: translateText('label', 'Optimize') },
          optimize_intl: { name: translateText('label', 'Optimize international') },
          enterprise: { name: translateText('label', 'Enterprise') },
          enterprise_intl: { name: translateText('label', 'Enterprise international') },
        }}
        widgetKey={['admin', 'upgrade', 'statistics']}
        isLoading={isLoading}
      />
      <QueryTable<{
        id: number;
        title: string;
        reseller: string;
        account_manager: string;
        pricing_plan: string;
        change_request_id: number | null;
        status: string;
        can_upgrade: boolean;
        contract_duration: string;
        contract_end_date: string;
        dontExclude: undefined;
      }>
        tableKey={['admin', 'upgrade', 'status']}
        columns={{
          id: { header: { name: translateText('label', 'ID'), sort: true }, search: true },
          title: { header: { name: translateText('label', 'Title'), sort: true }, search: true },
          reseller: {
            header: { name: translateText('label', 'Partner'), sort: true },
            search: true,
          },
          account_manager: {
            header: { name: translateText('label', 'Account manager'), sort: true },
            search: true,
          },
          status: {
            header: { name: translateText('label', 'Status'), sort: true },
            search: {
              0: translateText('label', 'No upgrade requested'),
              1: getUpgradeText(translateText('label', 'Open')),
              4: getUpgradeText(translateText('label', 'Processed')),
              9: translateText('label', 'Discontinue requested'),
              10: translateText('label', 'Excluded from upgrade'),
            },
          },
          pricing_plan: {
            header: { name: translateText('label', 'Pricing plan') },
            search: {
              'basic': translateText('label', 'Basic'),
              'light': translateText('label', 'Light'),
              'basic-international': translateText('label', 'Basic international'),
              'light-international': translateText('label', 'Light international'),
            },
          },
          contract_duration: {
            header: translateText('label', 'Contract duration'),
            search: true,
          },
          contract_end_date: {
            header: translateText('label', 'Contract end date'),
            search: true,
          },
          dontExclude: {
            header: { name: translateText('label', "Don't exclude") },
            viewCondition: row => !row.can_upgrade,
            button: {
              onClick: row => removeExcludeFromUpgrade({ id: row.id, domain: row.title }),
              text: translateText('label', "Don't exclude"),
              className: 'btn btn-lightblue',
              disabled: isLoading,
            },
          },
        }}
        className="wrap"
        pagination={{ defaultLimit: 20 }}
        endpoint="/domain/upgrade-status"
        filters={{ domain: false }}
        isLoading={isLoading}
        canSearch
      />
    </ReportsPage>
  );
}
