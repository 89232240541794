import * as Yup from 'yup';

import { createError } from 'contexts/Messages';
import { useValidateNumber } from './queries/number';
import { useTranslate } from './translate';

export function useCustomValidation() {
  const translateText = useTranslate();
  const { validateNumber } = useValidateNumber();

  const required = Yup.string()
    .trim()
    .required(translateText('message', 'This field is required.'));

  const email = required.email(translateText('message', 'Invalid email address.'));

  const multipleEmail = Yup.string().test({
    name: 'emails',
    test: function (value) {
      const firstInvalidEmail = (value ?? '')
        .split(',')
        .map(v => v.trim())
        .filter(v => !!v)
        .find(v => !Yup.string().email().isValidSync(v));

      return firstInvalidEmail
        ? this.createError({
            message: translateText(
              'message',
              '<span data-private>{value}</span> is not a valid email address.',
              { value: firstInvalidEmail },
            ),
          })
        : true;
    },
  });

  const destinationNumber = Yup.string().test({
    test: function (value) {
      if (!value || value === String(this.parent.country ?? '') + String(this.parent.area ?? '')) {
        return this.createError({ message: translateText('message', 'This field is required.') });
      }

      return new Promise(resolve =>
        validateNumber(value)
          .then(() => resolve(true))
          .catch(error => resolve(this.createError({ message: createError(error) as string }))),
      );
    },
  });

  const requiredCheckbox = Yup.boolean().isTrue(
    translateText('message', 'This field is required.'),
  );

  const requiredSelect = Yup.string().test({
    test: value => !!value && value !== 'select',
    message: translateText('message', 'This field is required.'),
  });

  return { required, email, multipleEmail, destinationNumber, requiredCheckbox, requiredSelect };
}

export function useValidationSchema() {
  const translateText = useTranslate();
  const { required, destinationNumber, email } = useCustomValidation();

  const passwordValidation = Yup.object().shape({
    conditionsPassed: Yup.boolean().isTrue(
      translateText('message', 'Not all password conditions have been met'),
    ),
  });
  const updatePasswordValidation = passwordValidation.shape({
    password: Yup.string().required(
      translateText('message', 'Current password must be filled in.'),
    ),
  });

  const invoiceProfileValidation = Yup.object({
    email,
    company: required,
    name: required,
    vat_number: Yup.mixed().test({
      test: function (value) {
        return ['', 'NL'].includes(this.parent.country) || !!value;
      },
      message: translateText('message', 'This field is required.'),
    }),
    phone_number: required,
    address: required,
    zipcode: required,
    city: required,
    country: required,
  });

  const termsAndConditionsValidation = Yup.object({
    termsAndConditions: Yup.boolean().isTrue(
      translateText('message', "Accept the 'AdCalls Terms and Conditions' to submit the request."),
    ),
    agreement: Yup.boolean().isTrue(
      translateText('message', "Accept the 'AdCalls Agreement' to submit the request."),
    ),
  });

  function getOffsiteValidation(
    currentOffsites: { name: string; area?: number | null; destination: string }[],
    message?: string,
  ) {
    return Yup.object({
      name: required.test({
        message: message ?? translateText('message', 'Offsite phone number names must be unique.'),
        test: value => !currentOffsites.map(o => o.name).includes(value?.trim() ?? ''),
      }),
      area: Yup.mixed()
        .nullable()
        .test({
          message: translateText('message', 'This field is required.'),
          test: value => value !== null,
        }),
      destination: destinationNumber,
    });
  }

  return {
    passwordValidation,
    updatePasswordValidation,
    invoiceProfileValidation,
    termsAndConditionsValidation,
    getOffsiteValidation,
  };
}
