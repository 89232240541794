import { camelCase } from 'lodash';

import ReportsPage from 'components/ReportsPage';
import QueryTable from 'components/table/QueryTable';
import Widget from 'components/widget/Widget';
import { getDefaultFilters, useFilters } from 'contexts/Filters';
import { TYPE_CALL_TRACKING } from 'globals/constants';
import { useOverviewWidgetContent } from 'hooks/content';
import { useIsFetchingReport } from 'hooks/fetching';
import { useTranslate } from 'hooks/translate';

export default function Sources() {
  const translateText = useTranslate();
  const { callTrackingWidgetKeys } = useOverviewWidgetContent({
    showConvRate: false,
    showSessions: false,
  });
  const { getAppliedFilters } = useFilters();

  const queryKey = ['callTracking', 'sources'];
  const isLoading = useIsFetchingReport(queryKey);

  const defaultFilters = getDefaultFilters(TYPE_CALL_TRACKING);
  const sourceTableFilters = getAppliedFilters(
    defaultFilters,
    camelCase([...queryKey, 'table'].join(' ')),
    true,
  );

  return (
    <ReportsPage
      queryKey={queryKey}
      title={translateText('label', 'Traffic sources')}
      download={{
        endpoint: '/reports/sources',
        disabled: isLoading,
        filters: {
          ...defaultFilters,
          custom: {
            page: sourceTableFilters.queryParams.page,
            limit: sourceTableFilters.queryParams.limit,
          },
        },
      }}
    >
      <Widget
        widgetKey={[...queryKey, 'overview']}
        endpoint="/widget/overview"
        type="statistics"
        dataKeys={callTrackingWidgetKeys}
        filters={defaultFilters}
        isLoading={isLoading}
      />
      <QueryTable
        tableKey={[...queryKey, 'table']}
        previousPeriodColumn="source_id"
        columns={{
          argument: {
            header: {
              name: translateText('label', 'Traffic source'),
              tooltip: translateText(
                'tooltip/widgets',
                'The traffic source that led to the calls.',
              ),
            },
            defaultSelected: true,
            compareType: 'none',
            private: true,
          },
          sessions: {
            header: {
              name: translateText('label', 'Amount of sessions'),
              tooltip: translateText(
                'tooltip/widgets',
                'The total number of sessions. A session is the period in which a user is active on the website. By default, if a user is inactive for 30 minutes or more, new activity is attributed to a new session. If users leave the website and return within 30 minutes, this is counted as the original session.',
              ),
            },
            defaultSelected: true,
          },
          calls: {
            header: {
              name: translateText('label', 'Amount of calls'),
              tooltip: translateText('tooltip/widgets', 'The total number of calls made.'),
            },
            defaultSelected: true,
          },
          conversionrate: {
            header: {
              name: translateText('label', 'Conversion rate'),
              tooltip: translateText(
                'tooltip/widgets',
                'The ratio between the number of calls and the number of sessions realized, expressed as a percentage. This looks at the number of calls processed in Google Analytics.',
              ),
            },
            defaultSelected: true,
            customNumber: value => value + '%',
          },
          avg_duration: {
            header: {
              name: translateText('label', 'Avg. call duration'),
              tooltip: translateText(
                'tooltip/widgets',
                'The average call duration of the total number of calls made.',
              ),
            },
            defaultSelected: true,
            compareType: 'time',
          },
        }}
        isResponsive
        pagination={{ defaultLimit: 100 }}
        endpoint="/widget/sources"
        dataKey="data"
        filters={defaultFilters}
        canChooseColumns
        isLoading={isLoading}
      />
    </ReportsPage>
  );
}
