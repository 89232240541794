import { useNavigate } from 'react-router';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { posthog } from 'posthog-js';

import { useGlobal } from 'contexts/Global';
import { useMessages } from 'contexts/Messages';
import { clearSession, useSession } from 'contexts/Session';
import { useTenant } from 'contexts/Tenant';
import { User } from 'globals/types';
import { useSetQueryData } from 'hooks/data';
import { useFetch } from 'hooks/fetch';
import { useUpdateLanguage } from 'hooks/queries/user';
import { useTranslate } from 'hooks/translate';

export type LoginData = {
  remember_me: boolean;
  email?: string;
  auth_key?: string;
  password?: string;
  code?: string;
};

export function useLogin() {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { setIsTokenValid, setExpiresAt } = useSession();
  const { addErrorMessage } = useMessages();
  const { updateLanguage } = useUpdateLanguage(true);
  const { selectedLanguage } = useGlobal();

  const { mutateAsync: logIn, isPending } = useMutation({
    mutationFn: (data: LoginData) =>
      fetchData(`/auth/login`, {
        method: 'POST',
        bodyParams: data,
        includeStatus: true,
      }),
    onSuccess: async (data, loginData) => {
      if (selectedLanguage) {
        await updateLanguage({ language: selectedLanguage, token: data.token });
      }
      localStorage.setItem('session', data.token);
      setExpiresAt(data.expires_at * 1000);
      setIsTokenValid(true);
      if (__PROD__) posthog.identify(data.user.id, { adcalls_user_id: data.user.id });

      if (loginData.remember_me) {
        localStorage.setItem(
          'rememberMe',
          JSON.stringify({
            authKey: data.auth_key,
            email: data.user.email,
            name: data.user.first_name + ' ' + data.user.last_name,
          }),
        );
      } else {
        localStorage.removeItem('rememberMe');
      }
      queryClient.invalidateQueries({ queryKey: ['knowledgeCenter'] });
    },
    onError: (response: { status: number; error: string }) => {
      if (response.status === 401) {
        addErrorMessage(
          translateText('message', 'An invalid email address or password has been entered.'),
        );
      } else if (response.status !== 409) {
        addErrorMessage(response.error);
      }
    },
    meta: { noError: true },
  });

  return { logIn, isLoading: isPending };
}

export function useLogout() {
  const { fetchData } = useFetch();

  const { mutateAsync: logOut } = useMutation({
    mutationFn: () => fetchData('/auth/logout', { method: 'PUT' }),
    onMutate: () => clearSession(),
  });

  return { logOut };
}

export function useTwoStep() {
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync: enableTwoStep, isPending: isLoadingEnable } = useMutation({
    mutationFn: (auth: LoginData) =>
      fetchData('/auth/two-step-enable', { method: 'POST', bodyParams: auth }),
    onSuccess: response =>
      setQueryData<User>(['user', 'me'], oldData => ({ ...oldData, twoStep: response })),
  });

  const { mutateAsync: verifyTwoStep } = useMutation({
    mutationFn: (data: { auth: LoginData; code: string }) =>
      fetchData('/auth/two-step-verify', {
        method: 'PUT',
        queryParams: { code: data.code },
        bodyParams: data.auth,
      }),
    onSuccess: () => {
      setQueryData<User>(['user', 'me'], oldData => ({
        ...oldData,
        twoStep: { ...oldData.twoStep!, enabled: true },
      }));
      addSuccessMessage(
        translateText(
          'message',
          'You have completed the steps to set up the two-step verification.',
        ),
      );
    },
  });

  return { enableTwoStep, isLoadingEnable, verifyTwoStep };
}

export function useEmailTwoStep() {
  const { fetchData } = useFetch();
  const translateText = useTranslate();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync: sendTwoStepCode, isPending: isSendingCode } = useMutation({
    mutationFn: (email: string) =>
      fetchData('/auth/email-two-step', { method: 'POST', bodyParams: { email } }),
    onSuccess: (data, email) => {
      addSuccessMessage(
        translateText(
          'message',
          'An email with the verification code has been sent to <span data-private>{email}</span>.',
          { email },
        ),
      );
    },
  });

  return { sendTwoStepCode, isSendingCode };
}

export function useInviteUser() {
  const queryClient = useQueryClient();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (values: { email: string; role: string }) =>
      fetchData('/auth/invite', {
        method: 'POST',
        bodyParams: {
          email: values.email,
          domains: [selectedDomain],
          roles: [values.role],
        },
      }),
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: ['domain', 'users', selectedDomain] });
      addSuccessMessage(data);
    },
  });

  return { inviteUser: mutateAsync, isLoading: isPending };
}

export function useRegister() {
  const { fetchData } = useFetch();

  const { mutateAsync } = useMutation({
    mutationFn: (data: {
      company: string;
      firstName: string;
      lastName: string;
      phonenumber: string;
      email: string;
      password: string;
      verifyPassword: string;
    }) =>
      fetchData('/auth/register', {
        method: 'POST',
        bodyParams: data,
      }),
    meta: { noError: true },
  });

  return { register: mutateAsync };
}

export function useAcceptInvitation() {
  const navigate = useNavigate();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync } = useMutation({
    mutationFn: (data: {
      company: string;
      firstName: string;
      lastName: string;
      phonenumber: string;
      newPassword: string;
      verifyNewPassword: string;
      token: string;
    }) =>
      fetchData('/auth/accept-invitation', {
        method: 'PUT',
        bodyParams: data,
      }),
    meta: { noError: true },
    onSuccess: () => {
      addSuccessMessage(
        translateText(
          'message',
          'Your details have been saved. You can now log in with your account.',
        ),
      );
      navigate('/login');
    },
  });

  return { acceptInvitation: mutateAsync };
}

export function useVerifyEmail() {
  const { fetchData } = useFetch();

  const { mutate, status } = useMutation({
    mutationFn: (token: string) =>
      fetchData('/auth/verify-email', {
        method: 'PUT',
        bodyParams: { token },
      }),
    meta: { noError: true },
  });

  return { verifyEmail: mutate, status };
}

export function useResetEmail() {
  const { fetchData } = useFetch();

  const { mutateAsync, status } = useMutation({
    mutationFn: (values: { token: string; password: string }) =>
      fetchData('/auth/reset-email', {
        method: 'POST',
        bodyParams: values,
      }),
    onSuccess: () => {
      clearSession();
      localStorage.removeItem('rememberMe');
    },
  });

  return { resetEmail: mutateAsync, status };
}

export function useRequestResetPassword() {
  const { fetchData } = useFetch();

  const { mutateAsync, status, reset } = useMutation({
    mutationFn: (email: string) =>
      fetchData('/auth/request-reset-password', {
        method: 'PUT',
        bodyParams: { email },
      }),
  });

  return { requestResetPassword: mutateAsync, status, reset };
}

export function useResetPassword() {
  const navigate = useNavigate();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync, status } = useMutation({
    mutationFn: (values: { token: string; password: string }) =>
      fetchData('/auth/reset-password', {
        method: 'PUT',
        bodyParams: values,
      }),
    meta: { noError: true },
    onSuccess: () => {
      addSuccessMessage(
        translateText(
          'message',
          'Your password has been changed. You can now login with your new password.',
        ),
      );
      navigate('/login');
    },
  });

  return { resetPassword: mutateAsync, status };
}
