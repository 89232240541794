export default {
    "AEN table updated": "AEN tabel is bijgewerkt",
    "Amount: {amount} numbers": "Aantal: {amount} nummers",
    "Approve domain change request?": "Domein wijzigingsverzoek goedkeuren?",
    "Are you sure you want to delete this entry?": "Weet je zeker dat je deze waarde wilt verwijderen?",
    "Are you sure you want to delete this pool and all its session filters?": "Weet je zeker dat je deze pool met alle bijhorende sessie filters wilt verwijderen?",
    "Are you sure you want to delete this {type}?": "Weet je zeker dat je deze {type} wilt verwijderen?",
    "Are you sure you want to do this? Then enter the password down below.": "Weet je zeker dat je dit wilt doen? Vul dan hieronder het wachtwoord in.",
    "Are you sure you want to put this number pool back in stock?": "Weet je zeker dat je deze nummerpool terug wilt zetten naar de voorraad?",
    "Are you sure you want to remove this number from the blacklist?": "Weet je zeker dat je dit nummer van de zwarte lijst wilt halen?",
    "Attention! Session filter is disabled. All sessions are tracked.": "Let op! Sessie filter is uitgeschakeld. Alle sessies worden gemeten.",
    "Auto check": "Automatisch controleren",
    "Automatically check all numbers from every vendor.": "Check automatisch alle nummers van iedere provider.",
    "Below you'll find a list of numbers that couldn't be deleted:": "Hieronder vind je een lijst van nummers die niet verwijderd konden worden:",
    "Below you'll find a list of numbers that couldn't be stopped at {vendor}:": "Hieronder vind je een lijst van nummers die niet stopgezet konden worden bij {vendor}:",
    "Change number distribution": "Nummeruitgifte wijzigen",
    "Check numbers with APIs of vendor": "Telefoonnummers checken met de APIs van vendors",
    "Domain change request has been {action}.": "Domein wijzigingsverzoek is {action}.",
    "Fallback assignments divided by total assignments times current amount of numbers (for upscaling only).": "Fallbackuitgiftes gedeeld door totaal uitgiftes, maal huidig aantal nummers (alleen voor opschalen).",
    "Fill in a value for the domain setting.": "Vul een waarde voor de domein-instelling in.",
    "If no date is sent to the API, the default period from a month ago till now is used.": "Wanneer er geen datum naar de API wordt gestuurd wordt de standaard periode van een maand geleden tot en met nu genomen.",
    "Login content has been saved": "Login content is opgeslagen",
    "No session filters set for this domain.": "Geen sessie filter ingesteld voor dit domein.",
    "Not all numbers have been fetched due to the limit of 5000": "Niet alle nummers zijn opgehaald vanwege de limiet van 5000",
    "Partner has been added": "Partner is toegevoegd",
    "Range: {amount} numbers": "Reeks: {amount} nummers",
    "Select a domain setting and fill in a value.": "Selecteer een domein-instelling en vul een waarde in.",
    "Select a domain setting.": "Selecteer een domein-instelling.",
    "Session filter method": "Sessie filter methode",
    "The blacklisted phone number has been removed": "'Het telefoonnummer is van de zwarte lijst gehaald",
    "The blacklisted phone number has been updated": "Het telefoonnummer op de zwarte lijst is gewijzigd",
    "The domain intake has been declined": "De domein intake is geweigerd",
    "The domain intake has been imported successfully": "De domein intake is succesvol ge\u00efmporteerd",
    "The domain intake has been updated": "De domein intake is gewijzigd",
    "The highest amount of assignments, divided by the amount of workable half hours in a day (20) multiplied by the safety margin (2) per half hour.": "Het hoogste aantal uitgiftes, gedeeld door het aantal werkbare halve uren in een dag (20) vermenigvuldigd met de veiligheidsmarge (2) per half uur.",
    "The minimum amount of numbers that should be in a number pool after scaling is 5.": "Het minimale aantal nummers dat na afschalen in een nummer pool hoort te zitten is 5.",
    "The notification has been created.": "De notificatie is aangemaakt.",
    "The notification has been saved.": "De notificatie is opgeslagen.",
    "The notification has been triggered successfully.": "De notificatie is succesvol verstuurd.",
    "The numbers are reserved for the domain {domain}. These have yet to be linked to a pool in the campaign builder.": "De telefoonnummers zijn gereserveerd voor het domein {domain}. Deze moeten nog worden gekoppeld aan een pool in de campaign builder.",
    "The numbers of order with ID \"{orderReference}\" have been added.": "De nummers van order met ID \"{orderReference}\" zijn toegevoegd.",
    "The numbers of order with ID \"{orderReference}\" have been added. However some numbers already existed in the database. Check Graylog for more details.": "De nummers van order met ID \"{orderReference}\" zijn toegevoegd. Sommige nummers waren echter al toegevoegd aan de database. Check Graylog voor meer details.",
    "The overview is based on the highest amount of number assignments per number pool on one day.": "Het overzicht is gebaseerd op het hoogste aantal nummeruitgiftes per nummer pool op \u00e9\u00e9n dag.",
    "The overview is based on the total amount of fallback number assignments per domain in the selected period.": "Het overzicht is gebaseerd op het totaal aantal fallback nummer uitgiftes per domein in de geselecteerde periode.",
    "The translation has been created.": "De vertaling is aangemaakt.",
    "The translation has been saved.": "De vertaling is opgeslagen.",
    "The {type} has been added.": "De {type} is toegevoegd.",
    "The {type} has been deleted.": "De {type} is verwijderd.",
    "This does not cancel the numbers at their respective vendor, this only deletes them from our database. Are you sure all numbers are cancelled at the vendor?": "Dit zet de nummers niet stop bij de bijbehorende vendor, dit verwijdert de nummers alleen uit onze database. Weet je zeker dat alle nummers zijn stopgezet bij de vendor?",
    "To calculate the amount of numbers to scale down, the highest amount of assignments, per number pool, is divided by the amount of workable half hours in a day (20) multiplied by the safety margin (2). These amounts are all added together to determine the priority.": "Om het aantal af te schalen nummers te berekenen is het hoogste aantal uitgiftes, per nummer pool, gedeeld door het aantal werkbare halve uren in een dag (20) vermenigvuldigd met de veiligheidsmarge (2). Deze aantallen worden allemaal bij elkaar opgeteld om de prioriteit te bepalen.",
    "When all conditions in a pool are TRUE, the session IS tracked.": "Als alle condities in een pool TRUE zijn, wordt de sessie WEL gemeten.",
    "When all conditions in a pool are TRUE, the session is NOT tracked.": "Als alle condities in een pool TRUE zijn, wordt de sessie NIET gemeten",
    "When there is no pool in which all conditions are met, the session will NOT be tracked.": "Als er geen pool is waar aan alle condities wordt voldaan, wordt de sessie NIET gemeten.",
    "You are about change the number distribution": "Je staat op het punt om de nummeruitgifte te wijzigen",
    "You are therefore EXCLUDED from Call Tracking.": "Je bent daardoor UITGESLOTEN van Call Tracking",
    "{amount} number(s) will be attempted to be updated": "{amount} nummer(s) zullen worden bijgewerkt",
    "{amount} numbers have been updated": "{amount} nummer(s) zijn gewijzigd",
    "{amount} of {total} numbers have been logged and deleted": "{amount} van {total} nummers zijn gelogd en verwijderd",
    "{name} saved as: {value}": "{name} opgeslagen als: {value}",
    "{value} added.": "{value} toegevoegd."
}