import gif from 'assets/images/guided-tour/02-knowledge-center.gif';
import { useTranslate } from 'hooks/translate';
import BaseTour from './BaseTour';

export default function KnowledgeCenterTour() {
  const translateText = useTranslate();
  return (
    <BaseTour
      title={translateText('guided-tour', 'Knowledge center')}
      image={gif}
      description={
        <>
          <p
            dangerouslySetInnerHTML={{
              __html: translateText(
                'guided-tour',
                'Easily navigate to our <b>knowledge center</b> from the main navigation. Within the knowledge center you will find a lot of knowledge and inspiration about all our services, functionalities and integrations. Under support you will find all our support articles in which we explain how you can get the most out of our services.',
              ),
            }}
          />
          <p>
            {translateText(
              'guided-tour',
              'In addition, you will also find marketing material in the knowledge center, such as cases, leaflets and white papers.',
            )}
          </p>
        </>
      }
    />
  );
}
