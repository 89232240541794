import { Formik } from 'formik';
import { cloneDeep, includes, isEmpty, pickBy } from 'lodash';

import InputWrapper from 'components/slide-in/InputWrapper';
import { useTranslate } from 'hooks/translate';
import Condition from './Condition';

type ConditionValues<T extends string> = {
  key: T;
  operand: string;
  value: string;
};

type Props<T extends string> = {
  options: Record<T, string>;
  conditions: ConditionValues<T>[];
  setConditions: (conditions: ConditionValues<T>[]) => void;
  disabled: boolean;
  removeOptionsForNew?: T[]; // Array of options that should only be shown for existing conditions, they cannot be added for new conditions
};

export default function Conditions<T extends string>({
  options,
  conditions,
  setConditions,
  disabled,
  removeOptionsForNew,
}: Props<T>) {
  const translateText = useTranslate();

  return (
    <>
      <Formik<Omit<ConditionValues<T>, 'key'> & { key: T | 'select' }>
        initialValues={{ key: 'select', operand: '==', value: '' }}
        onSubmit={(condition, helpers) => {
          setConditions([...conditions, condition as ConditionValues<T>]);
          helpers.resetForm();
        }}
        enableReinitialize
      >
        {({ values, setFieldValue, submitForm }) => (
          <>
            <InputWrapper
              label={translateText('label', 'New condition')}
              tooltip={translateText(
                'integration',
                'Add one or more conditions for the filter. You can add or exclude conditions.',
              )}
              wrapperClassName="filter-condition"
            >
              <Condition
                conditionKey={{
                  value: values.key,
                  setValue: value => setFieldValue('key', value),
                }}
                operand={{
                  value: values.operand,
                  setValue: value => setFieldValue('operand', value),
                }}
                value={{
                  value: values.value,
                  setValue: value => setFieldValue('value', value),
                }}
                conditions={conditions}
                keyOptions={pickBy<string>(
                  options,
                  (_, key) => !includes(removeOptionsForNew, key),
                )}
                disabled={disabled}
              />
            </InputWrapper>
            <button
              className="btn btn-lightblue margin-top margin-bottom"
              onClick={submitForm}
              disabled={values.key === 'select' || disabled}
            >
              {translateText('label', 'Add')}
            </button>
          </>
        )}
      </Formik>
      <div className="input-wrapper filter-condition">
        <span className="input-label">{translateText('duplicate', 'Conditions')}</span>
        <div className="conditions-list">
          {isEmpty(conditions) ? (
            <p className="no-data">{translateText('label', 'No conditions have been set yet.')}</p>
          ) : (
            conditions.map((condition, index) => {
              const updateCondition = (key: string, value: string) => {
                const newConditions = cloneDeep(conditions);
                newConditions[index] = { ...condition, [key]: value };
                setConditions(newConditions);
              };
              return (
                <Condition
                  key={index}
                  conditionKey={condition.key}
                  operand={{
                    value: condition.operand,
                    setValue: value => updateCondition('operand', value),
                  }}
                  value={{
                    value: condition.value,
                    setValue: value => updateCondition('value', value),
                  }}
                  onDelete={() => setConditions(conditions.filter((_, i) => i !== index))}
                  keyOptions={options}
                  disabled={disabled}
                />
              );
            })
          )}
        </div>
      </div>
    </>
  );
}
