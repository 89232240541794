import { useNavigate } from 'react-router';

import QueryTable from 'components/table/QueryTable';
import { useTranslate } from 'hooks/translate';

export default function CategoriesWithoutArticles() {
  const navigate = useNavigate();
  const translateText = useTranslate();

  return (
    <>
      <h1>{translateText('label', 'Categories without active articles')}</h1>
      <QueryTable<{ id: number; name: string; type: number }>
        tableKey={['knowledgeCenter', 'articleOverview']}
        endpoint="/support/article/overview"
        dataKey="categoriesWithoutArticles"
        columns={{
          id: { header: translateText('label', 'ID') },
          name: { header: translateText('label', 'Name') },
        }}
        filters={{ domain: false }}
        onRowClick={category =>
          navigate('/knowledge-center/' + category.type + '/category/' + category.id + '/edit')
        }
        refresh
      />
    </>
  );
}
