import { Formik } from 'formik';
import { keyBy, mapValues } from 'lodash';
import * as Yup from 'yup';

import ErrorFocus from 'components/ErrorFocus';
import Select from 'components/input/Select';
import TextField from 'components/input/TextField';
import Skeleton from 'components/Skeleton';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import { useLocations } from 'hooks/queries/aen';
import { useAddFormat } from 'hooks/queries/domain';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation } from 'hooks/validation';

type Props = {
  format: string;
  location: string;
  isManual: boolean;
} & BaseSlideInProps;

export default function AddFormat({ show, close, format, location, isManual }: Props) {
  const translateText = useTranslate();
  const { required, requiredSelect } = useCustomValidation();
  const { locations, isLoading } = useLocations({ active: 1 });
  const { addFormat } = useAddFormat();

  const locationOptions = mapValues(keyBy(locations ?? [], 'aen_identifier'), location =>
    location.name ? location.name + ' (' + location.aen_identifier + ')' : location.aen_identifier,
  );

  const validationSchema = Yup.object({
    format: required,
    location: requiredSelect,
  });

  return (
    <Formik
      initialValues={{ format, location }}
      onSubmit={async values => await addFormat(values).then(close)}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({
        values,
        submitForm,
        errors,
        setFieldValue,
        resetForm,
        dirty,
        submitCount,
        isSubmitting,
      }) => (
        <Setup
          show={show}
          close={close}
          afterClose={resetForm}
          save={submitForm}
          title={
            isManual
              ? translateText('label', 'Add format manually')
              : translateText('label', 'Add format')
          }
          maxWidth={370}
          isSaving={isSubmitting}
          hasChanges={dirty}
        >
          <ErrorFocus element={document.getElementsByClassName('setup-wrapper')[0]} />
          {isLoading ? (
            <Skeleton count={2} />
          ) : (
            <p>
              {isManual
                ? translateText('label', 'Add a format manually.')
                : translateText(
                    'label',
                    'Select which location you want the assign the format to.',
                  )}
            </p>
          )}
          <InputWrapper label={translateText('label', 'Format')} isLoading={isLoading}>
            {isLoading ? (
              <Skeleton height={38} />
            ) : (
              <TextField
                value={decodeURIComponent(values.format)}
                onChange={e => setFieldValue('format', encodeURIComponent(e.target.value))}
                canEdit={isManual}
                error={submitCount > 0 && errors.format}
              />
            )}
          </InputWrapper>
          <InputWrapper label={translateText('label', 'Location')} isLoading={isLoading}>
            {isLoading ? (
              <Skeleton height={38} />
            ) : (
              <Select
                options={locationOptions}
                onChange={e => setFieldValue('location', e.target.value)}
                addSelect
                value={values.location}
                error={submitCount > 0 && errors.location}
              />
            )}
          </InputWrapper>
        </Setup>
      )}
    </Formik>
  );
}
