import SubmitTicket from 'components/knowledge-center/SubmitTicket';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import { PLAN_ENTERPRISE } from 'globals/constants';
import { useTranslate } from 'hooks/translate';
import CostParagraph from './CostParagraph';

type Props = {
  title: string;
  icon: string;
  infoText: string;
  articleId?: number;
  price: number;
} & BaseSlideInProps;

export default function IntegrationRequest({
  show,
  close,
  title,
  infoText,
  icon,
  articleId,
  price,
}: Props) {
  const translateText = useTranslate();

  return (
    <SubmitTicket
      show={show}
      close={close}
      title={title}
      icon={icon}
      infoText={infoText}
      extraText={translateText(
        'integration',
        'To set up the integration with {integration}, you can contact our support using the form below.',
        { integration: title },
      )}
      extraComponent={isLoading => (
        <CostParagraph
          integration={title}
          price={price}
          plan={PLAN_ENTERPRISE}
          isLoading={isLoading}
        />
      )}
      articleId={articleId}
      subject={translateText('integration', 'I want to use the integration with {integration}', {
        integration: title,
      })}
      saveButtonText={translateText('label', 'Send request')}
    />
  );
}
