import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { WebhookFormValues } from 'components/integrations/adcalls-api/WebhookForm';
import { useMessages } from 'contexts/Messages';
import { useTenant } from 'contexts/Tenant';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';

export type Hook = {
  id: number;
  method: 'PUT' | 'POST';
  address: string;
  trigger_raw: 0 | 1 | 3 | 4 | 5;
  format_raw: 0 | 1;
  auth_type_raw: 0 | 1;
  token: string;
  custom_headers: { key: string; value: string }[];
};

export function useHooks(page: number, enabled: boolean) {
  const { fetchData } = useFetch();
  const { selectedDomain } = useTenant();

  const { data: response, isFetching: isLoading } = useQuery({
    queryKey: ['hooks', selectedDomain, page],
    queryFn: (): Promise<{ data: Hook[]; headers: Headers }> =>
      fetchData('/hooks/index', {
        queryParams: {
          domain_id: selectedDomain,
          expand: 'trigger_raw,auth_type_raw,format_raw,custom_headers',
          limit: 5,
          page,
        },
        includeHeaders: true,
      }),
    enabled: selectedDomain !== null && enabled,
  });

  return {
    hooks: response?.data.map(item => ({
      id: item.id,
      method: item.method,
      address: item.address,
      trigger: item.trigger_raw,
      format: item.format_raw,
      authType: item.auth_type_raw,
      authToken: item.token,
      headers: item.custom_headers,
    })),
    isLoading,
    pageCount: Number(response?.headers.get('x-pagination-page-count') ?? 1),
    itemCount: Number(response?.headers.get('x-pagination-total-count') ?? 0),
  };
}

export function useTestHook() {
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { selectedDomain: domain } = useTenant();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync: testHook, isPending: isTesting } = useMutation({
    mutationFn: (hookValues: WebhookFormValues) =>
      fetchData('/hooks/test', {
        method: 'POST',
        queryParams: { domain },
        bodyParams: {
          method: hookValues.method,
          address: hookValues.address,
          format: hookValues.format,
          trigger: hookValues.trigger,
          pushable_id: null,
          auth_type: hookValues.authType,
          auth_token: hookValues.authToken,
          custom_headers: hookValues.headers,
        },
      }),
    onSuccess: () => addSuccessMessage(translateText('message', 'Data has been sent.')),
  });

  return { testHook, isTesting };
}

export function useSaveHook() {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { selectedDomain } = useTenant();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync: saveHook, isPending: isSaving } = useMutation({
    mutationFn: (hookValues: WebhookFormValues) =>
      fetchData(`/hooks/${hookValues.id ?? 'create'}`, {
        method: hookValues.id ? 'PUT' : 'POST',
        bodyParams: {
          ...(!hookValues.id && { domain_id: selectedDomain }),
          method: hookValues.method,
          address: hookValues.address,
          format: hookValues.format,
          trigger: hookValues.trigger,
          auth_type: hookValues.authType,
          token: hookValues.authToken,
          custom_headers: hookValues.headers,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['hooks'] });
      addSuccessMessage(translateText('message', 'Webhook has been saved.'));
    },
  });

  return { saveHook, isSaving };
}

export function useDeleteHook() {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutate } = useMutation({
    mutationFn: (id: number) =>
      fetchData('/hooks/' + id, {
        method: 'DELETE',
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['hooks'] });
      addSuccessMessage(
        translateText('message', 'The {type} has been deleted.', {
          type: translateText('label', 'Webhook').toLowerCase(),
        }),
      );
    },
  });

  return {
    deleteHook: (id: number) => {
      if (
        window.confirm(
          translateText('message', 'Are you sure you want to delete this {type}?', {
            type: translateText('label', 'Webhook').toLowerCase(),
          }),
        )
      ) {
        mutate(id);
      }
    },
  };
}
