import iconZoho from 'assets/images/icons/integrations/zoho.svg';
import IntegrationRequest from 'components/integrations/IntegrationRequest';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import { INTEGRATION_ZOHO_REQUEST } from 'globals/constants';
import { useHasAccessToIntegration } from 'hooks/access';
import { useTranslate } from 'hooks/translate';

export default function ZohoRequest({ show, close }: BaseSlideInProps) {
  const translateText = useTranslate();
  const hasAccessToIntegration = useHasAccessToIntegration();

  if (!hasAccessToIntegration(INTEGRATION_ZOHO_REQUEST)) return null;

  return (
    <IntegrationRequest
      show={show}
      title={translateText('label', 'Zoho')}
      icon={iconZoho}
      infoText={translateText(
        'integration',
        'Zoho is a complete CRM platform. With the help of this integration, you can enrich the contact information in Zoho with the data of incoming calls.',
      )}
      close={close}
      price={99}
    />
  );
}
