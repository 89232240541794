export default {
    "Are you sure you want to cancel the request to stop this domain?": "Weet je zeker dat je de stopzetting voor dit domein wilt intrekken?",
    "Are you sure you want to stop this domain?": "Weet je zeker dat je dit domein wilt stopzetten?",
    "Because of the minimum subscription duration of three months and the stop term of one calendar month, the domain will be stopped as of {stopDate}.": "In verband met de minimale looptijd van drie maanden en de opzegtermijn van \u00e9\u00e9n kalendermaand, zal het domein per {stopDate} stop worden gezet.",
    "Because of the stop term of one calendar month, the domain will be stopped as of {stopDate}.": "In verband met de opzegtermijn van \u00e9\u00e9n kalendermaand, zal het domein per {stopDate} stop worden gezet.",
    "Below you will find an overview of all changes that have been made for this domain.": "Hieronder vind je een overzicht van alle wijzigingen die doorgevoerd zijn voor dit domein.",
    "Do you have a commercial question? Please contact your account manager.": "Heb je een commerci\u00eble vraag? Neem dan contact op met jouw accountmanager.",
    "Do you have a technical or marketing-related question? Please contact your Customer Success Manager.": "Heb je een technische of marketinggerelateerde vraag? Neem dan contact op met jouw Customer Success Manager.",
    "Due to the contract duration and one-month notice period, the domain will be stopped as of {date}.": "In verband met de contractduur en de opzegtermijn van \u00e9\u00e9n kalendermaand, zal het domein per {date} stop worden gezet.",
    "Due to the contract duration, the domain will be stopped as of {date}.": "In verband met de contractduur, zal het domein per {date} stop worden gezet.",
    "Explain your reason further": "Licht je reden verder toe",
    "How much would you recommend AdCalls?": "In welke mate zou je AdCalls aanbevelen?",
    "Other, namely ...": "Anders, namelijk ...",
    "Please note: sessions are still registered and invoicing will continue in all cases.": "Let op: sessies worden nog wel geregistreerd en de facturatie wordt in alle gevallen gewoon voortgezet.",
    "The added value is not clear": "De meerwaarde is niet duidelijk",
    "The client is switching to another marketing agency": "De opdrachtgever stapt over naar een ander marketingbureau",
    "The domain will be stopped as of {stopDate}.": "Het domein wordt stopgezet per {stopDate}",
    "We are experiencing technical implementation issues": "Wij ervaren problemen met de technische implementatie",
    "We are stopping with marketing": "Wij stoppen met marketing",
    "We don't want to show a dynamic phone number on the website": "Wij willen geen dynamisch telefoonnummer op de website tonen",
    "We got the most out of AdCalls": "Wij hebben het maximale uit AdCalls gehaald",
    "We want to reduce costs": "Wij willen kosten besparen",
    "Why do you want to quit AdCalls?": "Waarom wil je stoppen met AdCalls?",
    "With this function you can enable and disable the number distribution. When number distribution is disabled, no more dynamic phone numbers for Call Tracking are distributed on the website.": "Met deze functie kun je de nummeruitgifte in- en uitschakelen. Bij het uitschakelen van de nummeruitgifte worden er geen dynamische telefoonnummers voor Call Tracking meer uitgegeven op de website.",
    "With this function you can enable and disable the number distribution. When number distribution is disabled, no more dynamic phone numbers for Call Tracking are distributed on the website.[manager] Click on the gears to adjust the status of the number distribution.[\/manager]": "Met deze functie kun je de nummeruitgifte in- en uitschakelen. Bij het uitschakelen van de nummeruitgifte worden er geen dynamische telefoonnummers voor Call Tracking meer uitgegeven op de website.[manager] Klik op de tandwielen om de status van de nummeruitgifte aan te passen.[\/manager]",
    "Yes, I agree to cancel the request to stop this domain": "Ja, ik ga akkoord met het intrekken van de stopzetting van dit domein",
    "Yes, I agree to stop this domain": "Ja, ik ga akkoord met het stopzetten van dit domein",
    "You will receive the last invoice in {invoiceMonth} for the period {stopMonth}.": "De laatste factuur ontvang je in {invoiceMonth} over de periode {stopMonth}."
}