import moment from 'moment';

import exclamationBlue from 'assets/images/icons/exclamation-blue.svg';
import exclamationPurple from 'assets/images/icons/exclamation-purple.svg';
import Tooltip from 'components/Tooltip';
import {
  OFFSITE_STATUS_DISCONTINUE_ACCEPTED,
  OFFSITE_STATUS_DISCONTINUE_REQUESTED,
  OFFSITE_STATUS_PENDING,
  PH_MASK,
} from 'globals/constants';
import { Offsite } from 'globals/types';
import { useTranslate } from 'hooks/translate';
import { useUserDateFormat } from 'hooks/user';
import { LocationItem } from 'pages/authenticated/call-tracking/config/Locations';

type Props = {
  location?: LocationItem | false;
  isIntake?: boolean;
};

export default function LocationCard({ location = false, isIntake = false }: Props) {
  const translateText = useTranslate();
  const dateFormat = useUserDateFormat();
  if (!location) return null;

  function renderOffsite(offsite: Offsite) {
    let extra = null;
    switch (offsite.status) {
      case OFFSITE_STATUS_PENDING:
        extra = (
          <Tooltip
            html={<img alt="warning" src={exclamationBlue} className="exclamation-tooltip" />}
            text={translateText('label', 'In request')}
          />
        );
        break;
      case OFFSITE_STATUS_DISCONTINUE_REQUESTED:
        extra = (
          <Tooltip
            html={<img alt="warning" src={exclamationPurple} className="exclamation-tooltip" />}
            text={translateText('label', 'Stop requested')}
          />
        );
        break;
      case OFFSITE_STATUS_DISCONTINUE_ACCEPTED:
        extra = (
          <Tooltip
            html={<img alt="warning" src={exclamationPurple} className="exclamation-tooltip" />}
            text={translateText('label', 'Will be stopped at {date}', {
              date: offsite.stop_requested_for,
            })}
          />
        );
    }

    let lastCallDate = '-';
    if (offsite.last_call) {
      lastCallDate = moment(offsite.last_call, 'YYYY-MM-DD HH:mm:ss').format(
        `${dateFormat} HH:mm:ss`,
      );
    }

    return (
      <div key={offsite.numberpool_id + offsite.name} className="offsite-details">
        <span className="extra-tooltip">
          {extra}
          {!isIntake && (
            <Tooltip text={translateText('label', 'Last call') + ': ' + lastCallDate} />
          )}
        </span>
        <div className="offsite-row">
          <div className="bold">{translateText('label', 'Name')}</div>
          <div className={PH_MASK}>{offsite.name}</div>
        </div>
        {!isIntake && (
          <div className="offsite-row">
            <div className="bold">{translateText('label', 'Phone number')}</div>
            <div className={PH_MASK}>{offsite.number ? offsite.number : '-'}</div>
          </div>
        )}
        <div className="offsite-row">
          <div className="bold">{translateText('label', 'Destination number')}</div>
          <div className={PH_MASK}>{offsite.destination}</div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="location-number-details">
        <div>
          <div className="bold">{translateText('label', 'Area code')}</div>
          <div>{location.netnumber}</div>
        </div>
        <div>
          <div className="bold">{translateText('label', 'Destination number')}</div>
          <div className={PH_MASK}>{location.destination}</div>
        </div>
      </div>
      {!!location.tracking_type && (
        <div className="location-type-details">
          <div className="bold">{translateText('label', 'Type')}</div>
          <div>{location.tracking_type}</div>
        </div>
      )}
      {!!location.offsite?.length && (
        <div className="location-offsite-details">
          <div className="bold">{translateText('label', 'Offsite phone numbers')}</div>
          {location.offsite.map(offsite => renderOffsite(offsite))}
        </div>
      )}
    </>
  );
}
