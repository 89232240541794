export default {
    "At the conversion action level, you can control whether a separate bid optimization conversion action is used (primary action) or not (secondary action). Secondary actions are used for reporting in the 'All conversions' column but not for bidding strategies.": "Op het niveau van de conversieactie kun je bepalen of een afzonderlijke conversieactie voor bodoptimalisatie wordt gebruikt (primaire actie) of niet (secundaire actie). Secundaire acties worden gebruikt voor de rapportage in de kolom \u2018Alle conversies\u2019 maar niet voor biedstrategie\u00ebn.",
    "Determine how many conversions to count per click or interaction.": "Bepaal hoeveel conversies moeten worden geteld per klik of interactie.",
    "Determine the category in which the conversion action belongs.": "Bepaal de categorie waarin de conversieactie thuishoort.",
    "Determine the period in which the conversion may be counted.": "Bepaal de periode waarin de conversie mag worden geteld.",
    "Determine whether the conversion values of the calls can be processed in Google Ads.": "Bepaal of de conversiewaarden van de oproepen verwerkt mogen worden in Google Ads.",
    "Determine which attribution model applies.": "Bepaal welk attributiemodel van toepassing is.",
    "Enter the customer ID associated with the relevant Google Ads account here.": "Vul hier het klant-id in wat toebehoort aan het desbetreffende Google Ads-account.",
    "Select the desired currency.": "Selecteer de gewenste valuta.",
    "Set the name of the conversion action.": "Stel de naam in voor de conversieactie."
}