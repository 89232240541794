import { useState } from 'react';

import BlacklistForm from 'components/admin/BlacklistForm';
import { useAddBlacklistedNumber } from 'hooks/queries/blacklisted-number';
import { useTranslate } from 'hooks/translate';

export default function BlacklistNew() {
  const translateText = useTranslate();
  const { addBlacklistedNumber } = useAddBlacklistedNumber();
  const [lastAdded, setLastAdded] = useState('');

  async function save(values: { number: string; date: string }) {
    await addBlacklistedNumber(values).then(() => setLastAdded(values.number));
  }

  return (
    <>
      <h1>{translateText('label', 'Add phone number to blacklist')}</h1>
      <div className="white-block">
        <BlacklistForm lastAdded={lastAdded} type="new" onSave={values => save(values)} />
      </div>
    </>
  );
}
