import ImageArticle from 'components/knowledge-center/ImageArticle';
import Skeleton from 'components/Skeleton';
import { CategoryArticle } from 'globals/types';
import { useTranslate } from 'hooks/translate';

type Props = {
  articles: CategoryArticle[];
  categoryState?: { categoryName: string | undefined; categorySlug: string | undefined };
  isLoading: boolean;
};

export default function ImageArticleList({ articles, categoryState, isLoading }: Props) {
  const translateText = useTranslate();

  if (!isLoading && !articles.length) return null;

  return (
    <div className="navigation-list">
      <h2>{isLoading ? <Skeleton width={96} /> : translateText('label', 'Articles')}</h2>
      <div className={'image-articles' + (isLoading ? ' fade-out' : '')}>
        {(isLoading ? [...Array(3)] : articles).map((item, index) => (
          <ImageArticle
            key={index}
            author={item?.article_author}
            createdOn={item?.article_created_on}
            image={item?.article_image}
            isLoading={isLoading}
            slug={item?.article_slug}
            title={item?.article_title}
            categoryState={categoryState}
          />
        ))}
      </div>
    </div>
  );
}
