import { TRAFFIC_TYPE_APP, TRAFFIC_TYPE_DYNAMIC, TRAFFIC_TYPE_OFFSITE } from 'globals/constants';
import { useLocations } from './queries/aen';
import { useSources } from './queries/source';

export function useIsFetchingReportsFilter() {
  const { isLoading: isLoadingLocations } = useLocations({
    limit: 10000,
    include_inactive: 1,
    expand: 'active',
    is_offline: [TRAFFIC_TYPE_DYNAMIC, TRAFFIC_TYPE_OFFSITE, TRAFFIC_TYPE_APP].join(),
  });
  const { isLoading: isLoadingSources } = useSources({
    is_offline: [TRAFFIC_TYPE_DYNAMIC, TRAFFIC_TYPE_OFFSITE, TRAFFIC_TYPE_APP].join(),
  });
  return isLoadingLocations || isLoadingSources;
}

export function useIsFetchingReport(queryKey: string[]) {
  const isReportsFilterFetching = useIsFetchingReportsFilter();
  // TODO AC-8897 check if this can be put back or should be removed completely
  // const reportFetching = useIsFetching(queryKey);
  // return isReportsFilterFetching || reportFetching > 0;
  return isReportsFilterFetching;
}
