import { Dispatch, SetStateAction } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import fileDownload from 'js-file-download';
import moment from 'moment';

import { useMessages } from 'contexts/Messages';
import { SIP_TRUNK_VOXBONE } from 'globals/constants';
import { useTableData } from 'hooks/data';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';
import { OrderSteamConnect } from 'pages/authenticated/admin/phone-numbers/AvailablePhoneNumbers';

type PhoneNumber = {
  id: number;
  domain_id: number;
  number: string;
  destination: string;
  is_fallback: boolean;
  ext_ref_id: string | null;
  domain_title: string;
  domain_url: string;
  is_assignable: boolean;
  stop_requested_for: string | null;
  number_of_assignments: number;
  conditions: string[];
  call_count: string;
  sip_trunk: string | null;
};

export type StopNumbersResponse = {
  totalToBeStopped: string;
  unstoppableSteamConnect: string[];
  unstoppableDstny: string[];
  unstoppableVoxbone: string[];
};

export type DeleteNumbersResponse = {
  total: string;
  deletedNumbers: string[];
  notDeletedNumbers: string[];
};

export function useNumber(id: number | null = null) {
  const { fetchData } = useFetch();
  const overviewData = useTableData<PhoneNumber>(['admin', 'number', 'overview']);

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ['admin', 'number', id],
    queryFn: (): Promise<PhoneNumber> =>
      fetchData('/number/view', {
        queryParams: {
          id,
          expand: 'number_of_assignments,conditions,stop_requested_for,call_count,sip_trunk',
        },
      }),
    enabled: id !== null,
    initialData: () => overviewData?.find(number => number.id === id),
  });

  return { data, isLoading };
}

export function useValidateNumber() {
  const { fetchData } = useFetch();

  const { mutateAsync } = useMutation({
    mutationFn: (number: string) =>
      fetchData('/number/validate', {
        queryParams: { number, type: 'destination', blockStopped: 1 },
      }),
    meta: { noError: true },
  });

  return { validateNumber: mutateAsync };
}

export function useStopNumbers() {
  const queryClient = useQueryClient();
  const { fetchData } = useFetch();
  const { addErrorMessage } = useMessages();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: ({
      numbers,
      stopRequestedForCheck,
    }: {
      numbers: string[];
      stopRequestedForCheck?: boolean;
    }): Promise<StopNumbersResponse> =>
      fetchData('/number/stop', {
        method: 'POST',
        bodyParams: { numbers, stopRequestedForCheck },
      }),
    onError: (error: string | { numbersNoStopRequestedFor: string[] }) => {
      if (typeof error !== 'object' || !error.hasOwnProperty('numbersNoStopRequestedFor')) {
        addErrorMessage(error);
      }
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['admin', 'numbers'] }),
    meta: { noError: true },
  });

  return { stopNumbers: mutateAsync, isUpdating: isPending };
}

export function useDeleteNumbers() {
  const queryClient = useQueryClient();
  const { fetchData } = useFetch();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: ({ numbers }: { numbers: string[] }): Promise<DeleteNumbersResponse> =>
      fetchData('/number/delete-and-log', {
        method: 'POST',
        bodyParams: { numbers },
      }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['admin', 'numbers'] }),
  });

  return { deleteNumbers: mutateAsync, isUpdating: isPending };
}

type StopVoxboneResponseData = {
  stoppedNumbersCount: number;
  stoppedNumbers: Record<string, string>;
  unstoppableNumbers: Record<string, string>;
  numbersNotFound: string[];
  notStoppedNumbers: Record<string, string>;
  numbersNotFoundAtAdCalls: Record<string, string>;
  unstoppableNumbersByAdCalls: Record<string, string>;
  failedSaveNumbers: Record<string, string>;
};

export function useStopVoxboneNumbers(setLogs: Dispatch<SetStateAction<(string | string[])[]>>) {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addErrorMessage, addSuccessMessage } = useMessages();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (numbers: string[]): Promise<StopVoxboneResponseData> =>
      fetchData('/number/stop-voxbone-numbers', {
        method: 'POST',
        bodyParams: { numbers },
      }),
    onSuccess: (response, numbers) => {
      if (response.stoppedNumbersCount === numbers.length) {
        queryClient.invalidateQueries({ queryKey: ['admin', 'numbers'] });
        return addSuccessMessage(translateText('message', 'Changes saved'));
      }
      const message = translateText(
        'message',
        '{amount} of {total} number(s) have been stopped at Voxbone.',
        {
          amount: response.stoppedNumbersCount,
          total: numbers.length,
        },
      );
      addErrorMessage(message);
      addErrorMessage(
        translateText('message', 'View the logs above the table for more information.'),
      );
      const logs: (string | string[])[] = [message];
      const checks: Record<keyof Omit<StopVoxboneResponseData, 'stoppedNumbersCount'>, string> = {
        stoppedNumbers: translateText(
          'message',
          "Below you'll find a list of numbers that have been stopped:",
        ),
        unstoppableNumbers: translateText(
          'message',
          "Below you'll find a list of numbers that have not been stopped yet, because of the minimum duration:",
        ),
        numbersNotFound: translateText(
          'message',
          "Below you'll find a list of numbers that have not been stopped, because the number couldn't be found in Voxbone.",
        ),
        notStoppedNumbers: translateText(
          'message',
          "Below you'll find a list of numbers that have not been stopped, because of an unknown reason at Voxbone.",
        ),
        numbersNotFoundAtAdCalls: translateText(
          'message',
          "Below you'll find a list of numbers that couldn't be found in AdCalls.",
        ),
        unstoppableNumbersByAdCalls: translateText(
          'message',
          "Below you'll find a list of numbers that have not been stopped, because the number couldn't be stopped by the AdCalls guidelines.",
        ),
        failedSaveNumbers: translateText(
          'message',
          "Below you'll find a list of numbers that have been stopped, but marking them as stopped in our system went wrong.",
        ),
      };
      for (const [key, msg] of Object.entries(checks)) {
        if (
          !response.hasOwnProperty(key) ||
          typeof response[key as keyof StopVoxboneResponseData] !== 'object'
        ) {
          continue;
        }
        const data = Object.values(response[key as keyof StopVoxboneResponseData]);
        if (data.length > 0) {
          logs.push(msg);
          logs.push(data);
        }
      }
      setLogs(logs);
    },
  });

  async function stopVoxboneNumbers(numbers: Record<string, string>) {
    if (Object.values(numbers).filter(t => t !== SIP_TRUNK_VOXBONE).length > 0) {
      return addErrorMessage(
        translateText('message', 'Not all selected numbers are Voxbone numbers.'),
      );
    }
    await mutateAsync(Object.keys(numbers));
  }

  return { stopVoxboneNumbers, isUpdating: isPending };
}

export function useOrderAtSteamConnect() {
  const { fetchData } = useFetch();
  const translateText = useTranslate();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync } = useMutation({
    mutationFn: (order: OrderSteamConnect) =>
      fetchData('/number/order-at-steam-connect', {
        method: 'POST',
        bodyParams: order,
      }),
    onSuccess: () =>
      addSuccessMessage(translateText('message', 'Phone numbers have been ordered and added.')),
  });

  return { order: mutateAsync };
}

export function useBulkUpdate() {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync } = useMutation({
    mutationFn: (values: {
      numbers: string[];
      destination?: string;
      is_fallback?: boolean;
      sip_trunk?: string;
      call_me_back?: boolean;
    }) =>
      fetchData('/number/bulk-update', {
        method: 'POST',
        bodyParams: values,
      }),
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: ['admin', 'numbers'] });
      addSuccessMessage(
        translateText('admin/text', '{amount} numbers have been updated', { amount: data }),
      );
    },
  });

  return { bulkUpdate: mutateAsync };
}

export function useCompareWithVendors() {
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync: compareWithVendors, isPending } = useMutation({
    mutationFn: () =>
      fetchData('/number/compare-with-vendors', {
        method: 'POST',
      }),
    onSuccess: data => {
      fileDownload(
        JSON.stringify(data, null, 2),
        'number-check-' + moment().format('YYYY-MM-DD') + '.txt',
      );
      addSuccessMessage(translateText('message', 'Your file is ready.'));
    },
  });

  return { compareWithVendors, isLoading: isPending };
}

export function useCreateRange() {
  const { fetchData } = useFetch();
  const translateText = useTranslate();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync } = useMutation({
    mutationFn: (values: { numbers: string; sip_trunk: string; domain_id?: string }) =>
      fetchData('/number/create-range', {
        method: 'POST',
        bodyParams: values,
      }),
    onSuccess: (data, variables) => {
      if (variables.domain_id) {
        addSuccessMessage(
          translateText(
            'admin/text',
            'The numbers are reserved for the domain {domain}. These have yet to be linked to a pool in the campaign builder.',
            { domain: variables.domain_id },
          ),
        );
      } else {
        addSuccessMessage(translateText('message', 'Phone numbers have been added.'));
      }
    },
  });

  return { createRange: mutateAsync };
}

export function useVoipProviderNumbers(
  providerKey: 'voxbone' | 'steam-connect' | 'dstny',
  reference: string,
) {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage, addErrorMessage } = useMessages();

  const {
    data,
    isFetching: isLoading,
    refetch: getNumbers,
  } = useQuery({
    queryKey: ['voipNumbers', providerKey, reference],
    queryFn: (): Promise<
      | {
          numbers: { id: string; number: string }[];
          resultCount: number;
        }
      | {
          dids: {
            e164: string;
            countryCodeA3: string;
            areaCode: string;
            cityName: string;
            type: string;
            orderReference: string;
          }[];
          resultCount: number;
        }
    > =>
      fetchData('/number/get-' + providerKey + '-numbers', {
        queryParams: { orderReference: reference },
      }),
    enabled: false,
  });

  const { mutateAsync: saveNumbers, isPending: isSaving } = useMutation({
    mutationFn: (bodyParams: Record<string, unknown>) =>
      fetchData('/number/save-' + providerKey + '-numbers', {
        method: 'POST',
        bodyParams,
      }),
    onSuccess: data => {
      queryClient.resetQueries({ queryKey: ['voipNumbers', providerKey, reference] });
      if (data?.numbersAlreadyInDatabase) {
        addErrorMessage(
          translateText(
            'admin/text',
            'The numbers of order with ID "{orderReference}" have been added. However some numbers already existed in the database. Check Graylog for more details.',
            { orderReference: reference },
          ),
          20,
        );
      } else {
        addSuccessMessage(
          translateText(
            'admin/text',
            'The numbers of order with ID "{orderReference}" have been added.',
            { orderReference: reference },
          ),
        );
      }
    },
  });

  return { data, isLoading, getNumbers, saveNumbers, isSaving };
}
