import { Link, useNavigate } from 'react-router-dom';

import RestrictedAccess from 'components/RestrictedAccess';
import QueryTable from 'components/table/QueryTable';
import { ROLE_ADMIN } from 'globals/constants';
import { AdminIntake } from 'globals/types';
import { useIntakeImport } from 'hooks/queries/domain-intake';
import { useTranslate } from 'hooks/translate';

export default function Intakes() {
  const navigate = useNavigate();
  const translateText = useTranslate();
  const { importIntake } = useIntakeImport();

  function uploadIntake(file: string | Blob) {
    const formData = new FormData();
    formData.append('domain_intake', file);
    importIntake(formData);
  }

  return (
    <>
      <h1>{translateText('label', 'Requests')}</h1>
      <QueryTable<AdminIntake>
        className="wrap"
        tableKey={['admin', 'intake']}
        endpoint="/domain-intake/index"
        columns={{
          id: { header: { name: translateText('label', 'ID'), sort: true }, search: true },
          domain_url: {
            header: { name: translateText('label', 'Domain URL'), sort: true },
            search: true,
            private: true,
          },
          company: {
            header: { name: translateText('label', 'Company'), sort: true },
            search: true,
            private: true,
          },
          created_by: {
            header: { name: translateText('label', 'Requesting user'), sort: true },
            search: true,
            private: true,
          },
          status: {
            header: { name: translateText('label', 'Status'), sort: true },
            search: {
              1: translateText('label', 'Open'),
              2: translateText('label', 'Waiting for customer'),
              3: translateText('label', 'Processing'),
              4: translateText('label', 'Processed'),
              5: translateText('label', 'Verified'),
              6: translateText('label', 'Refused'),
              9: translateText('label', 'Missing agreement'),
            },
          },
          created_on: {
            header: { name: translateText('label', 'Request date'), sort: true },
            search: true,
            date: true,
          },
          account_manager: {
            header: { name: translateText('label', 'Account manager') },
            search: true,
            customValue: value => value.name,
          },
          needed_numbers: {
            header: { name: translateText('label', 'Needed numbers') },
            customValue: value => <pre>{JSON.stringify(value, undefined, 4)}</pre>,
          },
        }}
        filters={{
          domain: false,
          custom: {
            expand:
              'needed_numbers,chargebee_customer_id,reseller_id,account_manager,status_id,contract_duration,trial_end_date',
          },
        }}
        pagination={{ defaultLimit: 20 }}
        refresh
        onRowClick={row => navigate('/admin/intakes/' + row.id)}
        extraTopRight={
          <RestrictedAccess role={ROLE_ADMIN}>
            <Link to="/admin/phone-numbers/new" className="btn btn-lightblue margin-right">
              {translateText('label', 'Add new phone numbers')}
            </Link>
          </RestrictedAccess>
        }
        extraTopLeft={
          <RestrictedAccess role={ROLE_ADMIN}>
            <label htmlFor="domain-intake-import" className="btn btn-lightblue">
              {translateText('label', 'Import domain intake')}
            </label>
            <input
              id="domain-intake-import"
              hidden
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onChange={e => e.target.files?.[0] && uploadIntake(e.target.files?.[0])}
            />
          </RestrictedAccess>
        }
      />
    </>
  );
}
