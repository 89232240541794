import { QueryKey, useQueryClient } from '@tanstack/react-query';

export function useSetQueryData() {
  const queryClient = useQueryClient();
  return <T>(queryKey: QueryKey, updater: (oldData: T) => T) => {
    queryClient.setQueryData<T>(queryKey, oldData =>
      oldData !== undefined ? updater(oldData) : undefined,
    );
  };
}

export function useTableData<T>(queryKey: QueryKey) {
  const queryClient = useQueryClient();
  return queryClient
    .getQueriesData<{ data: T[]; headers: Headers }>({ queryKey })
    .flatMap(query => query[1]?.data ?? []);
}

export function useSetTableData() {
  const queryClient = useQueryClient();
  return <T extends Record<string, unknown>>(
    queryKey: QueryKey,
    updater: (oldData: T[]) => T[],
  ) => {
    queryClient.setQueriesData<{ data: T[]; headers: Headers }>({ queryKey }, oldData => {
      if (!oldData) return undefined;
      return { ...oldData, data: updater(oldData.data) };
    });
  };
}
