import { Formik } from 'formik';
import * as Yup from 'yup';

import ErrorFocus from 'components/ErrorFocus';
import TextField from 'components/input/TextField';
import { SUP_ART_TWO_STEP, SUP_ART_URL } from 'globals/support';
import { LoginData, useEmailTwoStep, useLogin } from 'hooks/queries/auth';
import { useTranslate } from 'hooks/translate';
import { useIsLoggingIn } from 'hooks/user';
import { useCustomValidation } from 'hooks/validation';
import { RememberMe } from 'pages/unauthenticated/Login';

type Props = {
  className?: string;
  cancel: () => void;
  email?: string;
  password?: string;
  rememberMe: RememberMe & { enabled?: boolean };
  onLoginSuccess?: () => void;
  onUpdatePassword?: () => void;
};

export default function TwoStepAuthForm({
  className,
  cancel,
  email,
  password,
  rememberMe,
  onLoginSuccess,
  onUpdatePassword,
}: Props) {
  const translateText = useTranslate();
  const { logIn } = useLogin();
  const { sendTwoStepCode, isSendingCode } = useEmailTwoStep();
  const { required } = useCustomValidation();
  const isLoggingIn = useIsLoggingIn();

  async function handleVerifyLogin(values: { code: string }) {
    const data: LoginData = {
      remember_me: !!rememberMe.authKey || !!rememberMe.enabled,
      code: values.code,
    };

    if (rememberMe.authKey && rememberMe.email) {
      data.email = rememberMe.email;
      data.auth_key = rememberMe.authKey;
    } else if (email && password) {
      data.email = email;
      data.password = password;
    }

    await logIn(data)
      .then(() => {
        if (onLoginSuccess) onLoginSuccess();
      })
      .catch(response => {
        if (response.status === 409 && response.error.update_password && onUpdatePassword) {
          onUpdatePassword();
        }
      });
  }

  const validationSchema = Yup.object({
    code: required.length(6, translateText('message', 'Invalid code.')),
  });

  return (
    <>
      <p>
        {translateText(
          'text',
          'Go to the authenticator app on your phone and enter the code in the input field below.',
        )}
      </p>
      <Formik
        initialValues={{ code: '' }}
        validationSchema={validationSchema}
        onSubmit={handleVerifyLogin}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, values, touched }) => (
          <div className="two-step-auth">
            <ErrorFocus />
            <form onSubmit={handleSubmit} className={className}>
              <div className="section">
                <div className="bold">{translateText('label', 'Enter your code')}</div>
                <TextField
                  id="code"
                  placeholder={translateText('label', 'Verification code')}
                  value={values.code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoFocus
                  error={touched.code && errors.code}
                />
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: translateText(
                    'text',
                    'Are you running into problems? Then see the following <a href="{link}" target="_blank">support article</a>. Or contact us via <a href="tel:+31307400990">+31 (0)30 7400 990</a> or via <a href="mailto:support@adcalls.nl">support@adcalls.nl</a>.',
                    { link: SUP_ART_URL + SUP_ART_TWO_STEP },
                  ),
                }}
              />
              <div className="buttons-wrapper">
                <button
                  className="btn btn-lightblue"
                  type="button"
                  onClick={() => cancel()}
                  disabled={isSubmitting || isSendingCode || isLoggingIn}
                >
                  {translateText('label', 'Cancel')}
                </button>
                <button
                  className="btn btn-green margin"
                  type="submit"
                  disabled={isSubmitting || isSendingCode || isLoggingIn}
                >
                  {translateText('label', 'Submit')}
                </button>
              </div>
            </form>
            <button
              className="btn btn-text"
              onClick={() => sendTwoStepCode(rememberMe.email ?? email!)}
              disabled={isSubmitting || isSendingCode || isLoggingIn}
            >
              {translateText('label', 'Send verification code by email')}
            </button>
          </div>
        )}
      </Formik>
    </>
  );
}
