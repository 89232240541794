import moment from 'moment';

import { useTranslate } from './translate';
import { useUserDateFormat } from './user';

export function useDateTime() {
  const translateText = useTranslate();
  const dateFormat = useUserDateFormat();

  function formatDateTime(value: string, addConnector = false, timeFormat = 'HH:mm:ss') {
    const connector = addConnector ? translateText('label', 'At').toLowerCase() + ' ' : '';
    const dateMoment = moment(value, 'YYYY-MM-DD HH:mm:ss');
    if (dateMoment.isValid()) {
      return dateMoment.format(dateFormat) + ' ' + connector + dateMoment.format(timeFormat);
    }
    return '-';
  }

  function formatSeconds(totalSeconds: string) {
    const parsedTotalSeconds = parseInt(totalSeconds, 10);
    const seconds = parsedTotalSeconds % 60;
    const minutes = Math.floor(parsedTotalSeconds / 60) % 60;
    const hours = Math.floor(parsedTotalSeconds / 60 / 60) % 24;
    const days = Math.floor(parsedTotalSeconds / 60 / 60 / 24);
    let text = '';

    if (days !== 0) text += translateText('label', '{days}d', { days });
    if ((days !== 0 || hours !== 0) && days < 100) {
      text += translateText('label', '{hours}h', { hours });
    }
    if (days === 0) text += translateText('label', '{minutes}m', { minutes });
    if (days === 0 && hours === 0) text += translateText('label', '{seconds}s', { seconds });
    return text;
  }

  function timeToSeconds(time: string) {
    const units = time.split(':');
    if (units.length === 1) return parseInt(time);
    let seconds = 0;
    if (units.length === 3) {
      seconds = parseInt(units[0]) * 60 * 60;
      seconds += parseInt(units[1]) * 60;
      return seconds + parseInt(units[2]);
    }
    return parseInt(units[0]) * 60 + parseInt(units[1]);
  }

  return { formatDateTime, formatSeconds, timeToSeconds };
}
