import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';

import icon from 'assets/images/icons/integrations/vwo.svg';
import TextField from 'components/input/TextField';
import Toggle from 'components/input/Toggle';
import Conditions from 'components/integrations/Conditions';
import CostParagraph from 'components/integrations/CostParagraph';
import Skeleton from 'components/Skeleton';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import SupportParagraph from 'components/SupportParagraph';
import { INTEGRATION_VWO, PLAN_ENTERPRISE } from 'globals/constants';
import { SUP_ART_VWO } from 'globals/support';
import { useHasAccessToIntegration } from 'hooks/access';
import { useTogglePaidIntegration } from 'hooks/integrations';
import { useVwo } from 'hooks/queries/integration';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation } from 'hooks/validation';

export type VwoData = {
  id?: number;
  enabled: boolean;
  account_id: string;
  goal_id: string;
  filters_enabled: boolean;
  conditions: VwoCondition[];
};

type VwoCondition = {
  integration_vwo_id: number;
  key: VwoConditionKey;
  operand: string;
  value: string;
};

export type VwoConditionKey = 'location' | 'evaluation' | 'evaluation_value' | 'selection_menu';

export default function Vwo({ show, close }: BaseSlideInProps) {
  const translateText = useTranslate();
  const hasAccessToIntegration = useHasAccessToIntegration();
  const { required } = useCustomValidation();

  const title = translateText('label', 'VWO');
  const PRICE = 49 as const;

  const toggleEnabled = useTogglePaidIntegration(title, PRICE);
  const { data, isLoading, createVwo, updateVwo } = useVwo(show);

  if (!hasAccessToIntegration(INTEGRATION_VWO)) return null;

  const vwoData = data && !isEmpty(data) ? data[0] : null;

  const conditionKeys: Record<VwoConditionKey, string> = {
    location: translateText('label', 'Location'),
    evaluation: translateText('label', 'Evaluation'),
    evaluation_value: translateText('label', 'Evaluation value'),
    selection_menu: translateText('label', 'Customer evaluation'),
  };

  const validationSchema = Yup.object({
    account_id: required,
    goal_id: required,
  });

  async function save(values: VwoData) {
    const action = values.id ? updateVwo : createVwo;
    await action(values).then(() => close());
  }

  return (
    <Formik
      initialValues={{
        id: vwoData?.id,
        enabled: vwoData?.enabled ?? false,
        account_id: vwoData?.account_id ?? '',
        goal_id: vwoData?.goal_id ?? '',
        filters_enabled: vwoData?.filters_enabled ?? false,
        conditions: vwoData?.conditions ?? [],
      }}
      onSubmit={save}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({
        values,
        submitForm,
        resetForm,
        setFieldValue,
        handleChange,
        isSubmitting,
        dirty,
        submitCount,
        errors,
      }) => (
        <Setup
          show={show}
          close={close}
          afterClose={resetForm}
          icon={icon}
          title={translateText('label', 'Visual Website Optimizer')}
          hasChanges={dirty}
          save={submitForm}
          isSaving={isSubmitting}
          maxWidth={440}
        >
          <p>
            {isLoading ? (
              <Skeleton count={6} />
            ) : (
              translateText(
                'integration',
                'Visual Website Optimizer (VWO) is a powerful software tool designed to make website optimization and A/B testing accessible to marketers and website owners. VWO allows users to enhance their websites and digital experiences through a range of features and tools.',
              )
            )}
          </p>
          <p>
            {isLoading ? (
              <Skeleton count={3} />
            ) : (
              translateText(
                'integration',
                'With this integration, you can use incoming calls as offline conversion data for A/B testing.',
              )
            )}
          </p>
          <CostParagraph
            integration={title}
            price={PRICE}
            plan={PLAN_ENTERPRISE}
            isLoading={isLoading}
          />
          <SupportParagraph articleId={SUP_ART_VWO} isLoading={isLoading} />
          <h3 className="section-title">
            {isLoading ? <Skeleton width={200} /> : translateText('label', 'Connect account')}
          </h3>
          <InputWrapper
            label={translateText('label', 'Send conversions')}
            tooltip={translateText(
              'integration',
              'Disable and enable the sending of calls to {integration} here.',
              { integration: title },
            )}
            isLoading={isLoading}
          >
            <Toggle
              name="enabled"
              checked={values.enabled}
              onClick={() => toggleEnabled(values.enabled, setFieldValue)}
              disabled={isSubmitting}
              isLoading={isLoading}
            />
          </InputWrapper>
          <InputWrapper label={translateText('label', 'Account ID')} isLoading={isLoading}>
            {isLoading ? (
              <Skeleton height={35} width={300} />
            ) : (
              <TextField
                name="account_id"
                value={values.account_id}
                disabled={isSubmitting}
                onChange={e => setFieldValue('account_id', e.target.value.replace(/[^0-9]/g, ''))}
                error={submitCount > 0 && errors.account_id}
                maxLength={10}
              />
            )}
          </InputWrapper>
          <InputWrapper label={translateText('label', 'Goal ID')} isLoading={isLoading}>
            {isLoading ? (
              <Skeleton height={35} width={300} />
            ) : (
              <TextField
                name="goal_id"
                value={values.goal_id}
                disabled={isSubmitting}
                onChange={e => setFieldValue('goal_id', e.target.value.replace(/[^0-9]/g, ''))}
                error={submitCount > 0 && errors.goal_id}
                maxLength={10}
              />
            )}
          </InputWrapper>
          <h3 className="section-title">
            {isLoading ? <Skeleton width={200} /> : translateText('label', 'Filters')}
          </h3>
          <InputWrapper label={translateText('label', 'Enable filters')} isLoading={isLoading}>
            <Toggle
              name="filters_enabled"
              checked={values.filters_enabled}
              onClick={handleChange}
              disabled={isSubmitting}
              isLoading={isLoading}
            />
          </InputWrapper>

          {values.filters_enabled && (
            <Conditions
              options={conditionKeys}
              conditions={values.conditions}
              setConditions={conditions => setFieldValue('conditions', conditions)}
              disabled={isSubmitting}
            />
          )}
        </Setup>
      )}
    </Formik>
  );
}
