import { ReactElement, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import successVisual from 'assets/images/create-account-success.svg';
import Skeleton from 'components/Skeleton';
import ContentSidebar from 'components/unauthenticated/ContentSidebar';
import LoginNewsPage from 'components/unauthenticated/LoginNewsPage';
import { useVerifyEmail } from 'hooks/queries/auth';
import { useTranslate } from 'hooks/translate';

export default function VerifyEmail() {
  const navigate = useNavigate();
  const translateText = useTranslate();
  const { verifyEmail, status } = useVerifyEmail();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  useEffect(() => {
    if (token) verifyEmail(token);
  }, [token, verifyEmail]);

  let title: string | ReactElement = translateText('label', 'Account verification failed');
  let message: string | ReactElement = translateText(
    'text',
    'Unfortunately, the verification of your account failed. Most likely the verification link is no longer valid. Please contact us at <a href="mailto:support@adcalls.nl">support@adcalls.nl</a>, so that you can still verify your account.',
  );
  let button = (
    <button className="btn btn-green btn-wide" onClick={() => navigate('/login')}>
      {translateText('label', 'Go to the login page')}
    </button>
  );

  if ((token && status === 'idle') || status === 'pending') {
    title = <Skeleton width={200} />;
    message = <Skeleton count={3} />;
    button = <Skeleton height={38} />;
  } else if (status === 'success') {
    title = translateText('label', 'Account verified');
    message = translateText(
      'text',
      'Your account has been verified. You can now log in to our dashboard and get started with AdCalls right away. Do you have any questions about all the possibilities of AdCalls or do you have any questions about our dashboard? Please contact us via <a href="mailto:support@adcalls.nl">support@adcalls.nl</a> or call <a href="tel:+31307400990">+31 (0)30 7400 990</a>.',
    );
  }

  return (
    <ContentSidebar
      left={
        <>
          <h2 className="bold">{title}</h2>
          {typeof message === 'string' ? (
            <p dangerouslySetInnerHTML={{ __html: message }} />
          ) : (
            <p>{message}</p>
          )}
          {button}
        </>
      }
      right={status === 'success' ? <img alt="AdCalls" src={successVisual} /> : <LoginNewsPage />}
      alignCenter
    />
  );
}
