import { Navigate, Route, Routes } from 'react-router';

import EditSubscription, {
  EditSubscriptionType,
} from 'components/edit-subscription/EditSubscription';
import AuthenticatedRoute from 'components/routes/AuthenticatedRoute';
import AccountAccess from 'pages/authenticated/settings/AccountAccess';
import ChangeHistory from 'pages/authenticated/settings/ChangeHistory';
import ChangeRequestDetails from 'pages/authenticated/settings/ChangeRequestDetails';
import ChangeRequests from 'pages/authenticated/settings/ChangeRequests';
import Privacy from 'pages/authenticated/settings/Privacy';
import Properties from 'pages/authenticated/settings/Properties';
import Error404 from 'pages/Error404';

export default function Settings() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/settings/properties" />} />
      <Route path="/properties" element={<Properties />} />
      <Route
        path="/properties/edit-subscription"
        element={
          <AuthenticatedRoute checkDomain>
            <EditSubscription type={EditSubscriptionType.Extend} />
          </AuthenticatedRoute>
        }
      />
      <Route path="/account-access" element={<AccountAccess />} />
      <Route
        path="/change-history"
        element={
          <AuthenticatedRoute checkDomain>
            <ChangeHistory />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/change-requests"
        element={
          <AuthenticatedRoute checkDomain>
            <ChangeRequests />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/change-requests/:id"
        element={
          <AuthenticatedRoute checkDomain>
            <ChangeRequestDetails />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/privacy"
        element={
          <AuthenticatedRoute checkDomain>
            <Privacy />
          </AuthenticatedRoute>
        }
      />
      <Route path="/*" element={<Error404 />} />
    </Routes>
  );
}
