import { PropsWithChildren } from 'react';
import { useLocation } from 'react-router';

import { useSession } from 'contexts/Session';
import { useIsLoggingIn } from 'hooks/user';
import BaseRoute from './BaseRoute';

type LocationState = {
  from: { pathname: string; search: string };
};

export default function UnauthenticatedRoute({
  children,
}: PropsWithChildren<Record<string, unknown>>) {
  const location = useLocation();
  const isLoggingIn = useIsLoggingIn();
  const { token } = useSession();

  const { from } = (location.state as LocationState) ?? {
    from: { pathname: '/overview', search: '' },
  };

  return (
    <BaseRoute redirectTo={from} shouldRedirect={!!token && !isLoggingIn}>
      {children}
    </BaseRoute>
  );
}
