import DetailsSection from 'components/DetailsSection';
import { PH_MASK } from 'globals/constants';
import { useCountryTranslations } from 'hooks/content';
import { useEditSubscriptionPrerequisiteData } from 'hooks/queries/domain';
import { useTranslate } from 'hooks/translate';
import { EditSubscriptionProps } from './EditSubscription';

type Props = EditSubscriptionProps & {
  isLoading?: boolean;
};

export default function InvoicingDetails({ type, isLoading }: Props) {
  const translateText = useTranslate();
  const countries = useCountryTranslations();
  const { data } = useEditSubscriptionPrerequisiteData(type);

  return (
    <DetailsSection
      title={translateText('label', 'Billing information')}
      data={{
        [translateText('label', 'Email address')]: data?.billing_info.email,
        [translateText('label', 'Company')]: data?.billing_info.company,
        [translateText('label', 'To the attention of')]: data?.billing_info.customer_name,
        [translateText('label', 'Reference / Purchase Order Number')]: data?.billing_info.reference,
        [translateText('label', 'Address')]: data?.billing_info.address,
        [translateText('label', 'Postal code + city')]: data?.billing_info.zip_code_and_city,
        [translateText('label', 'Country')]: countries[data?.billing_info.country ?? ''],
      }}
      intro={translateText(
        'text',
        'Have the billing details changed? Please notify us of the changes via <a href="mailto:support@adcalls.nl">support@adcalls.nl</a>.',
      )}
      isLoading={isLoading}
      className={PH_MASK}
      boldKeys
    />
  );
}
