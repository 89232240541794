import { PropsWithChildren } from 'react';

type Props = {
  id?: string;
  show: boolean;
  size?: 'small' | 'medium' | 'big' | 'standard';
  close?: () => void;
  mostImportant?: boolean;
  extraClass?: string;
  left?: boolean;
  image?: boolean;
};

export default function PopupModal({
  id,
  show,
  size = 'standard',
  close,
  mostImportant = false,
  extraClass,
  left = false,
  image = false,
  children,
}: PropsWithChildren<Props>) {
  if (!show) return null;

  let popupClass = `popup popup-${size}`;
  if (mostImportant) popupClass += ' most-important';
  if (extraClass) popupClass += ' ' + extraClass;

  const closeButton = close ? (
    <div className="close-popup" onClick={() => close()}>
      ×
    </div>
  ) : null;

  let content = (
    <div id={id} className={popupClass}>
      {closeButton}
      <div className="popup-body-wrapper">
        <div className={'popup-body' + (left ? ' align-left' : '')}>{children}</div>
      </div>
    </div>
  );

  if (image) {
    content = (
      <div id={id} className="popup-image">
        {closeButton}
        {children}
      </div>
    );
  }

  return (
    <>
      <div
        className={'overlay' + (mostImportant ? ' most-important' : '')}
        onClick={() => close?.()}
        data-testid="overlay"
      />
      {content}
    </>
  );
}
