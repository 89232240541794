import { Formik } from 'formik';
import * as Yup from 'yup';

import icon from 'assets/images/icons/integrations/microsoft-advertising.svg';
import ErrorFocus from 'components/ErrorFocus';
import TextField from 'components/input/TextField';
import Toggle from 'components/input/Toggle';
import CostParagraph from 'components/integrations/CostParagraph';
import { REDIRECT_URI_MICROSOFT_ADVERTISING } from 'components/oauth2/Verification';
import VerificationButton from 'components/oauth2/VerificationButton';
import Skeleton from 'components/Skeleton';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import SupportParagraph from 'components/SupportParagraph';
import { useTenant } from 'contexts/Tenant';
import { SUP_ART_MICROSOFT_ADVERTISING } from 'globals/support';
import { useSetQueryData } from 'hooks/data';
import { useMicrosoftAds } from 'hooks/queries/domain-setting';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation } from 'hooks/validation';

export type MicrosoftAdsFormValues = {
  accountId: string;
  customerId: string;
  enabledCallTracking: 0 | 1;
  allowRecurring: 0 | 1;
  verified: boolean;
};

export default function Microsoft({ show, close }: BaseSlideInProps) {
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { selectedDomain } = useTenant();
  const { microsoftAdsSettings: data, isLoading, updateMicrosoftAds } = useMicrosoftAds(show);
  const { required } = useCustomValidation();

  // Form data is saved in local storage when the account gets verified, so the form is
  // still filled after redirect
  const localStorageData = localStorage.getItem('microsoftAds');
  const savedValues = localStorageData
    ? (JSON.parse(localStorageData) as MicrosoftAdsFormValues)
    : null;

  const title = translateText('label', 'Microsoft Advertising');

  const validationSchema = Yup.object({
    accountId: required,
    customerId: required,
  });

  async function save(values: MicrosoftAdsFormValues) {
    await updateMicrosoftAds(values).then(() => close());
  }

  return (
    <Formik
      initialValues={{
        accountId: savedValues?.accountId ?? data?.accountId ?? '',
        customerId: savedValues?.customerId ?? data?.customerId ?? '',
        enabledCallTracking: savedValues?.enabledCallTracking ?? data?.enabledCallTracking ?? 0,
        allowRecurring: savedValues?.allowRecurring ?? data?.allowRecurring ?? 0,
        verified: data?.verified ?? false,
      }}
      onSubmit={save}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({
        values,
        submitForm,
        resetForm,
        handleChange,
        setFieldValue,
        isSubmitting,
        dirty,
        submitCount,
        errors,
      }) => (
        <Setup
          id="microsoft-ads"
          show={show}
          close={close}
          afterClose={() => {
            resetForm();
            if (savedValues) localStorage.removeItem('microsoftAds');
          }}
          icon={icon}
          title={title}
          hasChanges={dirty || !!savedValues}
          save={submitForm}
          isSaving={isSubmitting}
          maxWidth={500}
        >
          <ErrorFocus element={document.getElementById('microsoft-ads')} />
          <p>
            {isLoading ? (
              <Skeleton count={2} />
            ) : (
              translateText(
                'integration',
                'Process your calls in Microsoft Advertising so you can optimize your campaigns down to the keyword level.',
              )
            )}
          </p>
          <p>
            {isLoading ? (
              <Skeleton count={2} />
            ) : (
              translateText(
                'integration',
                'To link your Microsoft Advertising account, you must have a Microsoft account with the "Super Admin" role.',
              )
            )}
          </p>
          <CostParagraph price={null} isLoading={isLoading} />
          <SupportParagraph articleId={SUP_ART_MICROSOFT_ADVERTISING} isLoading={isLoading} />
          <h3 className="section-title">
            {isLoading ? <Skeleton width={200} /> : translateText('label', 'Connect account')}
          </h3>
          <InputWrapper
            label={translateText('label', 'Account ID')}
            isLoading={isLoading}
            tooltip={translateText(
              'tooltip/microsoft-advertising',
              'Enter the account ID associated with the relevant Microsoft Advertising account here.',
            )}
          >
            {isLoading ? (
              <Skeleton height={38} width={300} />
            ) : (
              <TextField
                name="accountId"
                value={values.accountId}
                disabled={isSubmitting}
                onChange={handleChange}
                error={submitCount > 0 && errors.accountId}
              />
            )}
          </InputWrapper>
          <InputWrapper
            label={translateText('label', 'Customer ID')}
            tooltip={translateText(
              'tooltip/microsoft-advertising',
              'Enter the customer ID associated with the relevant Microsoft Advertising account here.',
            )}
            isLoading={isLoading}
          >
            {isLoading ? (
              <Skeleton height={38} width={300} />
            ) : (
              <TextField
                name="customerId"
                value={values.customerId}
                onChange={handleChange}
                disabled={isSubmitting}
                error={submitCount > 0 && errors.customerId}
              />
            )}
          </InputWrapper>
          <VerificationButton
            verified={values.verified}
            integrationName={title}
            disconnectEndpoint="/domain-setting/bing-ads-disconnect"
            redirectUri="/domain-setting/bing-ads-redirect-uri"
            redirectUriKey={REDIRECT_URI_MICROSOFT_ADVERTISING}
            queryParams={{ type: 'call-tracking' }}
            beforeRedirect={() =>
              dirty && localStorage.setItem('microsoftAds', JSON.stringify(values))
            }
            isLoading={isLoading}
            onDisconnectSuccess={() =>
              setQueryData<MicrosoftAdsFormValues>(
                ['domainSetting', 'microsoftAds', selectedDomain],
                oldData => ({ ...oldData, verified: false }),
              )
            }
          />
          <InputWrapper
            label={translateText('label', 'Send conversions')}
            tooltip={translateText(
              'integration',
              'Disable and enable the sending of calls to {integration} here.',
              { integration: title },
            )}
            isLoading={isLoading}
          >
            <Toggle
              checked={!!values.enabledCallTracking}
              disabled={isSubmitting}
              onClick={() => {
                if (
                  values.enabledCallTracking ||
                  window.confirm(
                    translateText(
                      'message',
                      'To use this functionality, you must first make settings in {integration}.',
                      { integration: title },
                    ) +
                      '\n\n' +
                      translateText(
                        'message',
                        'Are you sure you want to enable this functionality right now?',
                      ),
                  )
                ) {
                  setFieldValue('enabledCallTracking', +!values.enabledCallTracking);
                }
              }}
              isLoading={isLoading}
            />
          </InputWrapper>
          {!!values.enabledCallTracking && (
            <InputWrapper
              label={translateText('label', 'Send recurring calls')}
              tooltip={translateText(
                'tooltip/microsoft-advertising',
                'Determine whether the recurring calls can be processed in Microsoft Advertising.',
              )}
            >
              <Toggle
                checked={!!values.allowRecurring}
                disabled={isSubmitting}
                onClick={() => setFieldValue('allowRecurring', +!values.allowRecurring)}
                isLoading={isLoading}
              />
            </InputWrapper>
          )}
        </Setup>
      )}
    </Formik>
  );
}
