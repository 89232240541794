import { useFormikContext } from 'formik';

import Select from 'components/input/Select';
import TextField from 'components/input/TextField';
import Skeleton from 'components/Skeleton';

type Props = {
  isLoading: boolean;
  domain?: number | null;
};

export default function EvaluationValueInput({ isLoading }: Props) {
  const { values, handleChange, setFieldValue } = useFormikContext<{
    evaluation: string;
    value: string | number;
  }>();

  if (isLoading) return <Skeleton height={38} />;
  if (values.evaluation === 'lead') {
    return (
      <Select name="value" onChange={handleChange} options={[1, 2, 3, 4, 5]} value={values.value} />
    );
  }
  return (
    <TextField
      onChange={e => setFieldValue('value', e.target.value.replace(/[^0-9,]/g, ''))}
      value={values.value}
    />
  );
}
