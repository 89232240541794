import { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import TextField from 'components/input/TextField';
import Toggle from 'components/input/Toggle';
import Loader from 'components/Loader';
import { useNumberDistribution, useSaveNumberDistribution } from 'hooks/queries/system-setting';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation } from 'hooks/validation';

export type NumberDistributionSetting = {
  global: boolean;
  Destiny: boolean;
  In2Voice: boolean;
  Voxbone: boolean;
};

export default function NumberDistribution() {
  const translateText = useTranslate();
  const { distribution, isLoading } = useNumberDistribution();
  const { saveNumberDistribution } = useSaveNumberDistribution();
  const { required } = useCustomValidation();

  const [showWarning, setShowWarning] = useState(false);

  if (isLoading || !distribution) return <Loader />;

  const validationSchema = Yup.object({
    password: required,
  });

  return (
    <>
      <h1>{translateText('label', 'Number distribution')}</h1>
      <Formik
        initialValues={{ distribution, password: '' }}
        onSubmit={values => saveNumberDistribution(values).then(() => setShowWarning(false))}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({
          values,
          handleChange,
          submitForm,
          errors,
          resetForm,
          dirty,
          isSubmitting,
          submitCount,
        }) => (
          <div className="white-block">
            <div className="form-row">
              <div className="name-label">{translateText('label', 'Number distribution')}</div>
              <div className="value-wrapper">
                <Toggle
                  name="distribution.global"
                  checked={values.distribution.global}
                  onClick={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="name-label">
                {translateText('label', 'Number distribution') + ' Steam Connect'}
              </div>
              <div className="value-wrapper">
                <Toggle
                  name="distribution.In2Voice"
                  checked={values.distribution.In2Voice}
                  onClick={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="name-label">
                {translateText('label', 'Number distribution') + ' Dstny'}
              </div>
              <div className="value-wrapper">
                <Toggle
                  name="distribution.Destiny"
                  checked={values.distribution.Destiny}
                  onClick={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="name-label">
                {translateText('label', 'Number distribution') + ' Voxbone'}
              </div>
              <div className="value-wrapper">
                <Toggle
                  name="distribution.Voxbone"
                  checked={values.distribution.Voxbone}
                  onClick={handleChange}
                />
              </div>
            </div>
            {!showWarning && (
              <button
                className="btn btn-green margin-top"
                type="submit"
                onClick={() => setShowWarning(true)}
                disabled={!dirty}
              >
                {translateText('label', 'Save')}
              </button>
            )}

            {showWarning && (
              <div className="extreme-danger-wrapper">
                <h1 className="bold">{translateText('label', 'Warning')}!</h1>
                <h2>
                  {translateText('admin/text', 'You are about change the number distribution')}.
                </h2>
                <h2>
                  {translateText(
                    'admin/text',
                    'Are you sure you want to do this? Then enter the password down below.',
                  )}
                </h2>
                <TextField
                  id="password"
                  type="password"
                  placeholder={translateText('label', 'Password')}
                  value={values.password}
                  onChange={handleChange}
                  autoComplete="off"
                  error={submitCount > 0 && errors.password}
                />
                <div>
                  <button onClick={submitForm} className="btn btn-purple" disabled={isSubmitting}>
                    {translateText('admin/text', 'Change number distribution')}
                  </button>
                  <button
                    onClick={() => {
                      setShowWarning(false);
                      resetForm();
                    }}
                    disabled={isSubmitting}
                    className="btn btn-lightblue"
                  >
                    {translateText('label', 'Cancel')}
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </Formik>
    </>
  );
}
