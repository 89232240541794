import iconPipedrive from 'assets/images/icons/integrations/pipedrive.svg';
import IntegrationRequest from 'components/integrations/IntegrationRequest';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import { INTEGRATION_PIPEDRIVE_REQUEST } from 'globals/constants';
import { useHasAccessToIntegration } from 'hooks/access';
import { useTranslate } from 'hooks/translate';

export default function PipedriveRequest({ show, close }: BaseSlideInProps) {
  const translateText = useTranslate();
  const hasAccessToIntegration = useHasAccessToIntegration();

  if (!hasAccessToIntegration(INTEGRATION_PIPEDRIVE_REQUEST)) return null;

  return (
    <IntegrationRequest
      show={show}
      title={translateText('label', 'Pipedrive')}
      icon={iconPipedrive}
      infoText={translateText(
        'integration',
        'Pipedrive is a CRM tool that helps you manage leads and deals. Using this integration you can enrich the leads in Pipedrive with the data from incoming calls.',
      )}
      close={close}
      price={99}
    />
  );
}
