import { Dispatch, SetStateAction } from 'react';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import ErrorFocus from 'components/ErrorFocus';
import TextField from 'components/input/TextField';
import { SUP_ART_TWO_STEP, SUP_ART_URL } from 'globals/support';
import { LoginData, useTwoStep } from 'hooks/queries/auth';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation } from 'hooks/validation';
import TwoStepAuthQrCode from '../account/TwoStepAuthQrCode';

export type FormValues = {
  code: string;
};

export enum Section {
  Enable,
  Verify,
}

type Props = {
  auth: LoginData;
  config?: { qrUri: string; secret: string };
  close: () => void;
  section: Section;
  setSection: Dispatch<SetStateAction<Section>>;
};

export default function TwoStepAuthSetupForm({ auth, config, close, section, setSection }: Props) {
  const translateText = useTranslate();
  const { required } = useCustomValidation();
  const { verifyTwoStep } = useTwoStep();

  async function save(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    if (section === Section.Enable) {
      setSection(Section.Verify);
    } else if (section === Section.Verify) {
      await verifyTwoStep({ auth, code: values.code }).then(() => {
        close();
        formikHelpers.resetForm();
      });
    }
  }

  const validationSchema = Yup.object({
    ...(section === Section.Verify && {
      code: required.matches(/\b\d{6}\b/, translateText('message', 'Invalid code.')),
    }),
  });

  return (
    <Formik initialValues={{ code: '' }} onSubmit={save} validationSchema={validationSchema}>
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => {
        const submitDisabled = isSubmitting || (section === Section.Enable && !config?.qrUri);

        function getContent() {
          if (section === Section.Enable) {
            return (
              <>
                <p>
                  {translateText(
                    'text',
                    'For security reasons, we require two-step verification for your user account. Please follow the steps to activate two-step verification.',
                  )}
                </p>
                <h3 className="bold">{translateText('label', 'Enable two-step verification')}</h3>
                <TwoStepAuthQrCode config={config} />
              </>
            );
          }
          return (
            <>
              <p>
                {translateText(
                  'text',
                  'Go to the authenticator app on your phone and enter the code in the input field below.',
                )}
              </p>
              <div className="section">
                <div className="bold">{translateText('label', 'Enter your code')}</div>
                <TextField
                  id="code"
                  placeholder={translateText('label', 'Verification code')}
                  value={values.code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoFocus
                  error={touched.code && errors.code}
                />
              </div>
              <p
                className="support-link"
                dangerouslySetInnerHTML={{
                  __html: translateText(
                    'text',
                    'Are you running into problems? Then see the following <a href="{link}" target="_blank">support article</a>. Or contact us via <a href="tel:+31307400990">+31 (0)30 7400 990</a> or via <a href="mailto:support@adcalls.nl">support@adcalls.nl</a>.',
                    { link: SUP_ART_URL + SUP_ART_TWO_STEP },
                  ),
                }}
              />
            </>
          );
        }

        return (
          <div className="two-step-auth">
            <ErrorFocus />
            <form onSubmit={handleSubmit}>
              <h2 className="bold">{translateText('label', 'Two-step verification')}</h2>
              {getContent()}
              <div className="buttons-wrapper">
                <button
                  className={'btn btn-lightblue' + (isSubmitting ? ' disabled' : '')}
                  disabled={isSubmitting}
                  onClick={section === Section.Verify ? () => setSection(Section.Enable) : close}
                  type="button"
                >
                  {section === Section.Enable
                    ? translateText('label', 'Cancel')
                    : translateText('label', 'Back')}
                </button>
                <button
                  className={'btn btn-green margin' + (submitDisabled ? ' disabled' : '')}
                  disabled={submitDisabled}
                  type="submit"
                >
                  {section === Section.Enable
                    ? translateText('label', 'Verify link')
                    : translateText('label', 'Verify code')}
                </button>
              </div>
            </form>
          </div>
        );
      }}
    </Formik>
  );
}
