import { Formik } from 'formik';

import SelectFromList from 'components/input/SelectFromList';
import Loader from 'components/Loader';
import { KNOWLEDGE_CENTER_TYPE_OVERVIEW } from 'globals/constants';
import { useArticles, useUpdatePopular, useUpdateSlider } from 'hooks/queries/support';
import { useTranslate } from 'hooks/translate';

export default function OverviewEdit() {
  const translateText = useTranslate();
  const { updateSlider, isLoading: isUpdatingSlider } = useUpdateSlider();
  const { updatePopular, isLoading: isUpdatingPopular } = useUpdatePopular(
    KNOWLEDGE_CENTER_TYPE_OVERVIEW,
  );
  const { articles, isLoading } = useArticles({
    popular_type: KNOWLEDGE_CENTER_TYPE_OVERVIEW,
    expand: 'slider,popular',
  });

  if (isLoading || isUpdatingSlider || isUpdatingPopular) return <Loader />;

  return (
    <div className="knowledge-center-overview-edit">
      <h1>{translateText('label', 'Edit slider articles')}</h1>
      <Formik
        initialValues={{
          sliderArticles:
            articles
              ?.filter(article => article.slider)
              .sort((a, b) => a.slider!.order - b.slider!.order)
              .map(article => String(article.id)) ?? [],
        }}
        onSubmit={values => updateSlider(values.sliderArticles)}
        enableReinitialize
      >
        {({ values, setFieldValue, submitForm, dirty }) => (
          <>
            <SelectFromList
              id="slider-articles"
              canChangeOrder
              items={articles ?? []}
              onChange={articles => setFieldValue('sliderArticles', articles)}
              nameKey="title"
              selected={values.sliderArticles}
              availableLabel={translateText('label', 'Available articles')}
              selectedLabel={translateText('label', 'Displayed articles')}
              limit={3}
            />
            <button className="btn btn-green" disabled={!dirty} onClick={submitForm}>
              {translateText('label', 'Save')}
            </button>
          </>
        )}
      </Formik>

      <h1>{translateText('label', 'Edit popular articles')}</h1>

      <Formik
        initialValues={{
          popularArticles:
            articles
              ?.filter(article => (article.popular ?? []).length > 0)
              .sort((a, b) => a.popular![0]!.order - b.popular![0]!.order)
              .map(article => String(article.id)) ?? [],
        }}
        onSubmit={values => updatePopular(values.popularArticles)}
        enableReinitialize
      >
        {({ values, setFieldValue, submitForm, dirty }) => (
          <>
            <SelectFromList
              id="popular-articles"
              canChangeOrder
              items={articles ?? []}
              onChange={articles => setFieldValue('popularArticles', articles)}
              nameKey="title"
              selected={values.popularArticles}
              availableLabel={translateText('label', 'Available articles')}
              selectedLabel={translateText('label', 'Displayed articles')}
              limit={7}
            />
            <button className="btn btn-green" disabled={!dirty} onClick={submitForm}>
              {translateText('label', 'Save')}
            </button>
          </>
        )}
      </Formik>
    </div>
  );
}
