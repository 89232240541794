import { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import icon from 'assets/images/icons/integrations/google.svg';
import CreateDeleteTable from 'components/CreateDeleteTable';
import ErrorFocus, { focusErrorElement } from 'components/ErrorFocus';
import TextField from 'components/input/TextField';
import Toggle from 'components/input/Toggle';
import CostParagraph from 'components/integrations/CostParagraph';
import ConversionAction from 'components/integrations/google-ads/ConversionAction';
import ConversionActionButtons from 'components/integrations/google-ads/ConversionActionButtons';
import { REDIRECT_URI_GOOGLE_ADS } from 'components/oauth2/Verification';
import VerificationButton from 'components/oauth2/VerificationButton';
import Skeleton from 'components/Skeleton';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import SetupWithSub from 'components/slide-in/SetupWithSub';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import SupportParagraph from 'components/SupportParagraph';
import { useTenant } from 'contexts/Tenant';
import {
  CURRENCIES_LIST,
  INTEGRATION_ACTIVE,
  INTEGRATION_ERROR,
  INTEGRATION_INACTIVE,
} from 'globals/constants';
import { useSetQueryData } from 'hooks/data';
import { useGoogleAds, useGoogleAdsConversionAction } from 'hooks/queries/integration';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation } from 'hooks/validation';

export const GOOGLE_ADS_KEY = 'googleAds' as const;
export const GOOGLE_ADS_ENHANCED_CONVERSIONS_KEY = 'googleAdsEC' as const;

export const CONVERSION_ACTION_OPTIONS = {
  100: 'Extern',
  101: 'Last click',
  106: 'Data driven',
} as const;

function getDefaultConversionAction(type: GoogleAdsType) {
  return {
    id: 'new' as const,
    type: 'call' as const,
    enabled: false,
    name: '',
    resource_name: '',
    category: 'select' as const,
    attribution_model: 106 as const,
    counting_type: 2,
    conversion_period: 90,
    primary_for_goal: true,
    ecommerce_enabled: false,
    ecommerce_currency: 'EUR' as const,
    ...(type === GOOGLE_ADS_ENHANCED_CONVERSIONS_KEY && { send_offsite_calls: false }),
    filters_enabled: false,
    conditions: [] as GoogleAdsCondition[],
  };
}

export type GoogleAdsSetting<T extends GoogleAdsType> = {
  customer_id: string;
  verified: boolean;
  login_customer_id: string;
  ecommerceTransactions: boolean;
  currency: string;
  enabled?: T extends typeof GOOGLE_ADS_ENHANCED_CONVERSIONS_KEY ? boolean : never;
  enabled_call_tracking?: T extends typeof GOOGLE_ADS_KEY ? boolean : never;
};

export type ConversionActionSetting<T extends GoogleAdsType> = {
  id: number | 'new';
  type: 'call' | 'call_request_successful' | 'call_request_unsuccessful' | 'call_request_failed';
  enabled: boolean;
  name: string;
  resource_name: string;
  category: number | 'select';
  attribution_model: keyof typeof CONVERSION_ACTION_OPTIONS;
  counting_type: number;
  conversion_period: number;
  primary_for_goal: boolean;
  ecommerce_enabled: boolean;
  ecommerce_currency: (typeof CURRENCIES_LIST)[number];
  send_offsite_calls?: T extends typeof GOOGLE_ADS_ENHANCED_CONVERSIONS_KEY ? boolean : undefined;
  filters_enabled: boolean;
  conditions: GoogleAdsCondition[];
};

type GoogleAdsCondition = {
  integration_ga_id: number;
  key: GoogleAdsConditionKey;
  operand: string;
  value: string;
};

export type GoogleAdsConditionKey =
  | 'location'
  | 'evaluation'
  | 'evaluation_value'
  | 'selection_menu'
  | 'answered'
  | 'returning_caller'
  | 'second_event';

export type GoogleAdsType = typeof GOOGLE_ADS_KEY | typeof GOOGLE_ADS_ENHANCED_CONVERSIONS_KEY;

type Props = {
  type: GoogleAdsType;
  extraParagraph?: string;
  supportArticleId?: number;
} & BaseSlideInProps;

export default function BaseGoogleAds({
  show,
  close,
  type,
  extraParagraph,
  supportArticleId,
}: Props) {
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { selectedDomain } = useTenant();
  const { required, requiredSelect } = useCustomValidation();
  const {
    data,
    isLoading: isLoadingGoogleAds,
    conversionActions,
    updateGoogleAds,
    isLoadingConversionActions,
  } = useGoogleAds(type, show);
  const { createAction, updateAction, deleteAction } = useGoogleAdsConversionAction(type);

  const [showConversionAction, setShowConversionAction] = useState<number | 'new' | false>(false);
  const [triedVerification, setTriedVerification] = useState(false);
  const [loadedConversionAction, setLoadedConversionAction] = useState<
    boolean | 'loading' | 'failed'
  >(false);

  const isLoading = isLoadingGoogleAds || isLoadingConversionActions;
  const title = translateText('label', 'Google Ads');
  const endpointBase = type === GOOGLE_ADS_KEY ? 'google-advertising' : 'google-ads-ec';
  const enabledKey = type === GOOGLE_ADS_KEY ? 'enabled_call_tracking' : 'enabled';

  const validationSchema = Yup.object({
    customer_id: required.matches(
      /^(\d{3})-(\d{3})-(\d{4})$/,
      translateText(
        'message',
        'The customer ID provided is not in the correct format. The format is `xxx-xxx-xxxx` where the `x`s are all digits.',
      ),
    ),
    login_customer_id: Yup.string()
      .trim()
      .required(translateText('message', 'You need to authorize your account.')),
  });

  const subValidationSchema = Yup.object({
    name: Yup.string().required(translateText('message', 'This field is required.')),
    category: requiredSelect,
    attribution_model: Yup.string().oneOf(
      Object.keys(CONVERSION_ACTION_OPTIONS),
      translateText('message', 'Select an attribution model or sync your conversion action.'),
    ),
    conversion_period: Yup.string().test({
      test: value => {
        const number = Number(value);
        return number >= 7 && number <= 90;
      },
      message: translateText('message', 'The value must be between {min} and {max}.', {
        min: 7,
        max: 90,
      }),
    }),
  });

  return (
    <Formik<GoogleAdsSetting<typeof type>>
      initialValues={{
        customer_id: data?.customer_id ?? '',
        verified: data?.verified ?? false,
        login_customer_id: data?.login_customer_id ?? '',
        ecommerceTransactions: data?.ecommerceTransactions ?? false,
        currency: data?.currency ?? 'EUR',
        [enabledKey]: data?.[enabledKey] ?? false,
      }}
      onSubmit={async values => await updateGoogleAds(values).then(close)}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({
        initialValues,
        values,
        submitForm,
        resetForm,
        setFieldValue,
        handleChange,
        validateForm,
        isSubmitting,
        dirty,
        submitCount,
        errors,
      }) => (
        <>
          <ErrorFocus element={document.getElementsByClassName('setup-wrapper')[0]} />
          <Formik
            initialValues={
              typeof showConversionAction === 'number'
                ? conversionActions![showConversionAction]
                : getDefaultConversionAction(type)
            }
            onSubmit={async values => {
              const action = showConversionAction === 'new' ? createAction : updateAction;
              await action(values).then(() => setShowConversionAction(false));
            }}
            enableReinitialize
            validationSchema={subValidationSchema}
          >
            {({ submitForm: subSubmitForm, resetForm: subResetForm, dirty: subDirty }) => (
              <SetupWithSub
                show={show}
                close={close}
                afterClose={resetForm}
                icon={icon}
                title={
                  type === GOOGLE_ADS_KEY
                    ? title
                    : translateText('label', 'Google Ads Enhanced Conversions')
                }
                hasChanges={dirty}
                saveButtonDisabled={
                  values.customer_id !== data?.customer_id && initialValues.customer_id !== ''
                }
                save={submitForm}
                isSaving={isSubmitting}
                maxWidth={420}
                className="integration-google-ads"
                sub={
                  <Setup
                    show={showConversionAction !== false}
                    close={() => setShowConversionAction(false)}
                    afterClose={() => {
                      subResetForm();
                      setLoadedConversionAction(false);
                    }}
                    maxWidth={370}
                    save={subSubmitForm}
                    hasChanges={subDirty}
                    title={translateText('label', 'Configure conversion action')}
                    className="conversion-action"
                    alternativeButtons={
                      <ConversionActionButtons
                        type={type}
                        loadedConversionAction={loadedConversionAction}
                        setLoadedConversionAction={setLoadedConversionAction}
                        setShowConversionAction={setShowConversionAction}
                      />
                    }
                  >
                    <ConversionAction
                      type={type}
                      loadedConversionAction={loadedConversionAction}
                      setLoadedConversionAction={setLoadedConversionAction}
                    />
                  </Setup>
                }
              >
                <ErrorFocus element={document.getElementsByClassName('setup-wrapper')[0]} />
                <p>
                  {isLoading ? (
                    <Skeleton count={2} />
                  ) : (
                    translateText(
                      'integration',
                      'With this integration, you can process calls in Google Ads, so you can optimize your campaigns down to the keyword level.',
                    )
                  )}
                </p>
                {extraParagraph && <p>{isLoading ? <Skeleton count={5} /> : extraParagraph}</p>}
                <CostParagraph isLoading={isLoading} price={null} />
                {supportArticleId && (
                  <SupportParagraph articleId={supportArticleId} isLoading={isLoading} />
                )}
                <h3 className="section-title">
                  {isLoading ? <Skeleton width={200} /> : translateText('label', 'Connect account')}
                </h3>
                <InputWrapper
                  label={translateText('label', 'Customer ID')}
                  tooltip={translateText(
                    'tooltip/google-ads',
                    'Enter the customer ID associated with the relevant Google Ads account here.',
                  )}
                  isLoading={isLoading}
                >
                  {isLoading ? (
                    <Skeleton height={38} width={300} />
                  ) : (
                    <TextField
                      name="customer_id"
                      value={values.customer_id}
                      onChange={handleChange}
                      disabled={isSubmitting}
                      error={(submitCount > 0 || triedVerification) && errors.customer_id}
                    />
                  )}
                </InputWrapper>
                <VerificationButton
                  verified={values.verified && values.customer_id === data?.customer_id}
                  integrationName={title}
                  disconnectEndpoint={`/integration/${endpointBase}/disconnect`}
                  redirectUri={`/integration/${endpointBase}/redirect-uri`}
                  redirectUriKey={REDIRECT_URI_GOOGLE_ADS}
                  queryParams={{
                    type: 'call-tracking',
                    customerId: values.customer_id,
                  }}
                  isLoading={isLoading}
                  onDisconnectSuccess={() => {
                    setQueryData<GoogleAdsSetting<typeof type>>(
                      [type, selectedDomain],
                      oldData => ({
                        ...oldData,
                        verified: false,
                      }),
                    );
                    setQueryData<Record<string, number>>(
                      ['integrationStatuses', selectedDomain],
                      oldData => ({
                        ...oldData,
                        [type]:
                          oldData[type] === INTEGRATION_ACTIVE
                            ? INTEGRATION_ERROR
                            : INTEGRATION_INACTIVE,
                      }),
                    );
                  }}
                  isGoogle
                  validateBeforeVerify={async () => {
                    setTriedVerification(true);
                    const errors = await validateForm();
                    if (errors.customer_id) {
                      focusErrorElement(document.getElementsByClassName('setup-wrapper')[0]);
                      return false;
                    }
                    return true;
                  }}
                  error={submitCount > 0 && errors.login_customer_id}
                />
                {values.verified &&
                  values.customer_id !== initialValues.customer_id &&
                  initialValues.customer_id !== '' && (
                    <button
                      className="btn btn-text"
                      onClick={() => setFieldValue('customer_id', initialValues.customer_id)}
                    >
                      {translateText('label', 'Restore customer ID')}
                    </button>
                  )}

                {values.verified &&
                  values.login_customer_id &&
                  values.customer_id === initialValues.customer_id && (
                    <>
                      <h3 className="section-title">
                        {translateText('label', 'Send conversions')}
                      </h3>
                      <InputWrapper
                        label={translateText('label', 'Send conversions')}
                        tooltip={translateText(
                          'integration',
                          'Disable and enable the sending of calls to {integration} here.',
                          { integration: title },
                        )}
                        isLoading={isLoading}
                      >
                        <Toggle
                          checked={!!values[enabledKey]}
                          disabled={isSubmitting}
                          onClick={() => setFieldValue(enabledKey, !values[enabledKey])}
                          isLoading={isLoading}
                        />
                      </InputWrapper>
                      <CreateDeleteTable
                        className="margin-top"
                        dataKeys={['status', 'name']}
                        items={conversionActions?.map(action => ({
                          ...action,
                          status: (
                            <div
                              className={'big-dot ' + (action.enabled ? 'active' : 'inactive')}
                            />
                          ),
                        }))}
                        onAdd={() => setShowConversionAction('new')}
                        onEdit={(row, index) => setShowConversionAction(index)}
                        onDelete={row => deleteAction(+row.id)}
                        buttonsDisabled={isLoading}
                        emptyText={translateText(
                          'label',
                          'No conversion actions have been set yet.',
                        )}
                      />
                    </>
                  )}
              </SetupWithSub>
            )}
          </Formik>
        </>
      )}
    </Formik>
  );
}
