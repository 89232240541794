import {
  createContext,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useContext,
  useReducer,
} from 'react';
import { useLocation } from 'react-router';
import { cloneDeep } from 'lodash';

import { ReactComponent as Admin } from 'assets/images/icons/menu/admin.svg';
import { ReactComponent as CallTracking } from 'assets/images/icons/menu/call-tracking.svg';
import { ReactComponent as DomainOverview } from 'assets/images/icons/menu/domain-overview.svg';
import { ReactComponent as FormTracking } from 'assets/images/icons/menu/form-tracking.svg';
import { ReactComponent as KnowledgeCenter } from 'assets/images/icons/menu/knowledge-center.svg';
import { ReactComponent as Overview } from 'assets/images/icons/menu/overview.svg';
import { ReactComponent as Requests } from 'assets/images/icons/menu/requests.svg';
import { ReactComponent as Settings } from 'assets/images/icons/menu/settings.svg';
import * as self from 'contexts/Routes';
import { ROLE_ADMIN, ROLE_EMPLOYEE, ROLE_MANAGER } from 'globals/constants';
import { Role } from 'globals/types';
import { useHasRoleAccess } from 'hooks/access';
import { useDomainAuth } from 'hooks/queries/domain';
import { useTranslate } from 'hooks/translate';
import { useTenant } from './Tenant';

export type Route = {
  title: string;
  endRoute: string;
  icon?: ReactElement;
  role?: Role;
  directGo?: string;
  display?: boolean;
  subItems?: Route[];
};

type Routes = {
  displaySidebarExtension: boolean;
  openItems: string[];
  selectedItems: string[];
  selectedSubItems: Route[];
  navigationDisabled: boolean;
  initRoutes: () => void;
  setDisplaySidebarExtension: (display: boolean) => void;
  setOpenItems: (path: string, depth?: number, findSelected?: boolean) => void;
};

export const RoutesContext = createContext<Routes | null>(null);

const INIT_ROUTES = 'INIT_ROUTES' as const;
const SET_DISPLAY_SIDE_BAR_EXTENSION = 'SET_DISPLAY_SIDE_BAR_EXTENSION' as const;
const SET_OPEN_ITEMS = 'TOGGLE_SUB_ITEMS' as const;

type State = {
  displaySidebarExtension: boolean;
  openItems: string[];
  selectedItems: string[];
  selectedSubItems: Route[];
  navigationDisabled: boolean;
};

type Action =
  | { type: typeof INIT_ROUTES; routeMap: Route[][] }
  | { type: typeof SET_DISPLAY_SIDE_BAR_EXTENSION; display: boolean }
  | { type: typeof SET_OPEN_ITEMS; path: string; depth: number; findSelected: boolean };

export function RoutesProvider({ children }: PropsWithChildren<Record<string, unknown>>) {
  const routeMap = self.useRouteMap(); // self needed for testing
  const location = useLocation();
  const [state, dispatch] = useReducer(reducer, initState());

  function reducer(state: State, action: Action) {
    switch (action.type) {
      case INIT_ROUTES:
        return initState();
      case SET_DISPLAY_SIDE_BAR_EXTENSION:
        return { ...cloneDeep(state), displaySidebarExtension: action.display };
      case SET_OPEN_ITEMS: {
        const newState = cloneDeep(state);
        // If it's a main route it directly goes to an end route, so we set the open items to the end route
        if (action.findSelected && action.depth === 0) {
          newState.openItems = findSelectedItemsByRoute(action.path);
        } else {
          const openItems = newState.openItems;
          const index = openItems.indexOf(action.path);
          openItems.splice(action.depth);
          if (index === -1) openItems.push(action.path);
          newState.openItems = openItems;
        }
        return newState;
      }
    }
  }

  function initState() {
    const selectedItems = findSelectedItemsByRoute(location.pathname);
    const selectedSubItems = findSelectedSubItems(routeMap, location.pathname);
    return {
      displaySidebarExtension: selectedSubItems.length > 0,
      openItems: cloneDeep(selectedItems),
      selectedItems,
      selectedSubItems,
      navigationDisabled: selectedItems.includes('/oauth2'),
    };
  }

  function findSelectedSubItems(routeItems: Route[][], path: string) {
    const selectedRoute = routeItems.flat().find(routeItem => path.startsWith(routeItem.endRoute));

    return selectedRoute?.subItems ?? [];
  }

  function findSelectedItemsByRoute(path: string) {
    let routeChunks = path.split('/');
    if (routeChunks[0] === '') {
      routeChunks = routeChunks.slice(1);
    }
    return routeChunks.map((item, index) => '/' + routeChunks.slice(0, index + 1).join('/'));
  }

  const initRoutes = useCallback(() => dispatch({ type: INIT_ROUTES, routeMap }), [routeMap]);
  const setOpenItems = useCallback(
    (path: string, depth = 0, findSelected = true) =>
      dispatch({ type: SET_OPEN_ITEMS, path, depth, findSelected }),
    [],
  );
  const setDisplaySidebarExtension = useCallback(
    (display: boolean) => dispatch({ type: SET_DISPLAY_SIDE_BAR_EXTENSION, display }),
    [],
  );

  return (
    <RoutesContext.Provider
      value={{
        ...state,
        initRoutes,
        setDisplaySidebarExtension,
        setOpenItems,
      }}
    >
      {children}
    </RoutesContext.Provider>
  );
}

export function useRoutes() {
  const routes = useContext(RoutesContext);
  if (routes === null) {
    throw new Error('Routes context not available');
  }
  return routes;
}

// Route map
export function useRouteMap(): Route[][] {
  const hasRoleAccess = useHasRoleAccess();
  const translateText = useTranslate();
  const { selectedDomain, selectedMcc } = useTenant();

  const { domainAuth } = useDomainAuth();

  const hasPortals = domainAuth?.portals ?? false;
  const showFormTracking =
    !!domainAuth?.ft_enabled || window.location.pathname?.startsWith('/form-tracking');

  const routes: Route[][] = [];
  if (selectedDomain !== null) {
    routes.push([
      {
        title: translateText('menu', 'Overview'),
        endRoute: '/overview',
        icon: <Overview />,
      },
      {
        title: translateText('menu', 'Call Tracking'),
        endRoute: '/call-tracking',
        icon: <CallTracking />,
        directGo: '/call-tracking/reports/overview',
        subItems: [
          {
            title: translateText('menu', 'Reports'),
            endRoute: '/call-tracking/reports',
            subItems: [
              {
                title: translateText('menu', 'Overview'),
                endRoute: '/call-tracking/reports/overview',
              },
              {
                title: translateText('menu', 'Locations'),
                endRoute: '/call-tracking/reports/locations',
              },
              ...(hasPortals
                ? [
                    {
                      title: translateText('menu', 'Portals'),
                      endRoute: '/call-tracking/reports/portals',
                    },
                  ]
                : []),
              {
                title: translateText('menu', 'Days and times'),
                endRoute: '/call-tracking/reports/days-and-times',
              },
              {
                title: translateText('menu', 'Evaluations'),
                endRoute: '/call-tracking/reports/evaluations',
              },
            ],
          },
          {
            title: translateText('menu', 'History'),
            endRoute: '/call-tracking/history',
          },
          {
            title: translateText('menu', 'Configuration'),
            endRoute: '/call-tracking/config',
            subItems: [
              {
                title: translateText('menu', 'Tracking and filters'),
                endRoute: '/call-tracking/config/tracking-and-filters',
              },
              {
                title: translateText('menu', 'Locations'),
                endRoute: '/call-tracking/config/locations',
              },
              {
                title: translateText('menu', 'Offsite phone numbers'),
                endRoute: '/call-tracking/config/offsite-phone-numbers',
              },
              {
                title: translateText('menu', 'Implementation'),
                endRoute: '/call-tracking/config/implementation',
              },
              {
                title: translateText('menu', 'Call functionalities'),
                endRoute: '/call-tracking/config/call-functionalities',
              },
              {
                title: translateText('menu', 'Periodic report'),
                endRoute: '/call-tracking/config/send-reports',
              },
              {
                title: translateText('menu', 'Debug mode'),
                endRoute: '/call-tracking/config/debug-mode',
                role: ROLE_ADMIN,
              },
            ],
          },
          {
            title: translateText('menu', 'Integrations'),
            endRoute: '/call-tracking/integrations',
          },
        ],
      },
      ...(showFormTracking
        ? [
            {
              title: 'Form Tracking',
              endRoute: '/form-tracking',
              icon: <FormTracking />,
              directGo: '/form-tracking/reports/overview',
              subItems: [
                {
                  title: translateText('menu', 'Reports'),
                  endRoute: '/form-tracking/reports',
                  subItems: [
                    {
                      title: translateText('menu', 'Overview'),
                      endRoute: '/form-tracking/reports/overview',
                    },
                    {
                      title: translateText('menu', 'Locations'),
                      endRoute: '/form-tracking/reports/locations',
                    },
                    {
                      title: translateText('menu', 'Profiles'),
                      endRoute: '/form-tracking/reports/profiles',
                    },
                  ],
                },
                {
                  title: translateText('menu', 'History'),
                  endRoute: '/form-tracking/history',
                },
              ],
            },
          ]
        : []),
    ]);
  }

  const bottom: Route[] = [
    {
      title: translateText('menu', 'Domain overview'),
      endRoute: '/domain-overview',
      icon: <DomainOverview />,
      directGo: '/domain-overview',
    },
    {
      title: translateText('menu', 'Requests'),
      endRoute: '/requests',
      icon: <Requests />,
      directGo: '/requests/intake',
      subItems: [
        {
          title: translateText('menu', 'New request'),
          endRoute: '/requests/intake',
        },
        {
          title: translateText('menu', 'Requests'),
          endRoute: '/requests/overview',
        },
      ],
    },
    {
      title: translateText('menu', 'Settings'),
      endRoute: '/settings',
      icon: <Settings />,
      directGo: '/settings/properties',
      display: selectedDomain !== null || (selectedMcc !== null && selectedMcc !== -1),
      subItems: [
        {
          title: translateText('menu', 'Properties'),
          endRoute: '/settings/properties',
        },
        {
          title: translateText('menu', 'Changes'),
          endRoute: '/settings/change-requests',
          display: selectedDomain !== null,
        },
        {
          title: translateText('menu', 'Change history'),
          endRoute: '/settings/change-history',
          role: ROLE_MANAGER,
          display: selectedDomain !== null,
        },
        {
          title: translateText('menu', 'Account access'),
          endRoute: '/settings/account-access',
        },
        {
          title: translateText('menu', 'Data Processor Agreement'),
          endRoute: '/settings/privacy',
          role: ROLE_MANAGER,
          display: selectedDomain !== null,
        },
      ],
    },
    {
      title: translateText('menu', 'Knowledge Center'),
      endRoute: '/knowledge-center',
      icon: <KnowledgeCenter />,
      directGo: '/knowledge-center/overview',
      subItems: [
        {
          title: translateText('menu', 'Overview'),
          endRoute: '/knowledge-center/overview',
        },
        {
          title: translateText('menu', 'Inspiration'),
          endRoute: '/knowledge-center/inspiration',
        },
        {
          title: translateText('menu', 'Support'),
          endRoute: '/knowledge-center/support',
        },
        {
          title: translateText('menu', 'Marketing'),
          endRoute: '/knowledge-center/marketing',
        },
        {
          title: translateText('menu', 'Admin overview'),
          endRoute: '/knowledge-center/admin-overview',
          role: ROLE_ADMIN,
          subItems: [
            {
              title: translateText('menu', 'Articles without categories'),
              endRoute: '/knowledge-center/admin-overview/articles-without-categories',
              role: ROLE_ADMIN,
            },
            {
              title: translateText('menu', 'Categories without active articles'),
              endRoute: '/knowledge-center/admin-overview/categories-without-articles',
              role: ROLE_ADMIN,
            },
            {
              title: translateText('menu', 'Articles with status concept'),
              endRoute: '/knowledge-center/admin-overview/articles-with-status-concept',
              role: ROLE_ADMIN,
            },
            {
              title: translateText('menu', 'Articles with status archived'),
              endRoute: '/knowledge-center/admin-overview/articles-with-status-archived',
              role: ROLE_ADMIN,
            },
          ],
        },
      ],
    },
  ];

  if (hasRoleAccess(ROLE_EMPLOYEE)) {
    bottom.push({
      title: translateText('menu', 'Admin'),
      endRoute: '/admin',
      icon: <Admin />,
      directGo: '/admin/domains/overview',
      subItems: [
        {
          title: translateText('menu', 'Domains'),
          endRoute: '/admin/domains',
          subItems: [
            {
              title: translateText('menu', 'Overview domains'),
              endRoute: '/admin/domains/overview',
            },
            {
              title: translateText('menu', 'Upgrade'),
              endRoute: '/admin/domains/upgrade-status',
            },
            {
              title: translateText('menu', 'Domain settings'),
              endRoute: '/admin/domains/domain-settings',
            },
            {
              title: translateText('menu', 'Campaign builder'),
              endRoute: '/admin/domains/campaign-builder',
            },
            {
              title: translateText('menu', 'Domain stop requests'),
              endRoute: '/admin/domains/stop-requests',
            },
            {
              title: translateText('menu', 'Deleted domains'),
              endRoute: '/admin/domains/deleted-log',
            },
            {
              title: translateText('menu', 'Sync script'),
              endRoute: '/admin/domains/sync-script',
              role: ROLE_ADMIN,
            },
          ],
        },
        {
          title: translateText('menu', 'Users'),
          endRoute: '/admin/users',
        },
        {
          title: translateText('menu', 'Resellers'),
          endRoute: '/admin/resellers',
        },
        {
          title: translateText('menu', 'Intakes'),
          endRoute: '/admin/intakes',
        },
        {
          title: translateText('menu', 'Invoicing'),
          endRoute: '/admin/invoicing',
        },
        {
          title: translateText('menu', 'Changes'),
          endRoute: '/admin/change-requests',
        },
        {
          title: translateText('menu', 'Phone numbers'),
          endRoute: '/admin/phone-numbers',
          role: ROLE_ADMIN,
          subItems: [
            {
              title: translateText('menu', 'Overview phone numbers'),
              endRoute: '/admin/phone-numbers/overview',
            },
            {
              title: translateText('menu', 'Available phone numbers'),
              endRoute: '/admin/phone-numbers/available-phone-numbers',
            },
            {
              title: translateText('menu', 'Bulk update phone numbers'),
              endRoute: '/admin/phone-numbers/bulk',
            },
            {
              title: translateText('menu', 'Stop requests phone numbers'),
              endRoute: '/admin/phone-numbers/stop-requests',
            },
            {
              title: translateText('menu', 'Stop logs'),
              endRoute: '/admin/phone-numbers/stop-logs',
            },
            {
              title: translateText('menu', 'Phone numbers blacklist'),
              endRoute: '/admin/phone-numbers/blacklist',
            },
            {
              title: translateText('menu', 'Number assign stats'),
              endRoute: '/admin/phone-numbers/number-assign-stats',
            },
            {
              title: translateText('menu', 'Number assign overview'),
              endRoute: '/admin/phone-numbers/number-assign-overview',
            },
            {
              title: translateText('menu', 'Number check'),
              endRoute: '/admin/phone-numbers/check',
            },
          ],
        },
        {
          title: translateText('menu', 'Functionalities'),
          endRoute: '/admin/functionalities',
          subItems: [
            {
              title: translateText('menu', 'Session filter'),
              endRoute: '/admin/functionalities/session-filter',
            },
          ],
        },
        {
          title: translateText('menu', 'Release notes'),
          endRoute: '/admin/release-notes',
          role: ROLE_ADMIN,
        },
        {
          title: translateText('menu', 'Logs'),
          endRoute: '/admin/logs',
          role: ROLE_ADMIN,
          subItems: [
            {
              title: translateText('menu', 'Admin call history'),
              endRoute: '/admin/logs/call-history',
            },
            {
              title: translateText('menu', 'VoIP'),
              endRoute: '/admin/logs/voip',
            },
            {
              title: translateText('menu', 'Mail log'),
              endRoute: '/admin/logs/mail',
            },
          ],
        },
        {
          title: translateText('menu', 'Content'),
          endRoute: '/admin/content',
          role: ROLE_ADMIN,
          subItems: [
            {
              title: translateText('menu', 'Notifications'),
              endRoute: '/admin/content/notifications',
            },
            {
              title: translateText('menu', 'Content login page'),
              endRoute: '/admin/content/login-page',
            },
          ],
        },
        {
          title: translateText('menu', 'Email blacklist'),
          endRoute: '/admin/email-blacklist',
          role: ROLE_ADMIN,
        },
        {
          title: translateText('menu', 'Number distribution'),
          endRoute: '/admin/number-distribution',
          role: ROLE_ADMIN,
        },
      ],
    });
  }
  routes.push(bottom);

  return routes
    .map(routes => {
      return routes
        .map(route => filterRoute(route, hasRoleAccess))
        .filter((route: Route | null): route is Route => route !== null);
    })
    .filter(routes => routes.length > 0);
}

function filterRoute(route: Route, hasRoleAccess: (role: Role) => boolean) {
  const displayRoute =
    route.display !== false && (route.role === undefined || hasRoleAccess(route.role));
  if (!displayRoute) {
    return null;
  } else if (!route.subItems || route.subItems.length === 0) {
    return route;
  }
  const subItems: Route[] = [];
  route.subItems.forEach(route => {
    const filteredRoute = filterRoute(route, hasRoleAccess);
    if (filteredRoute) {
      subItems.push(filteredRoute);
    }
  });
  if (subItems.length === 0 && route.subItems.length > 0) {
    return null;
  }
  route.subItems = subItems;
  return route;
}

export function isLeafItem(item: Route): boolean {
  return (item.subItems?.length ?? 0) === 0;
}
