import { useParams } from 'react-router';
import moment from 'moment';

import DetailsSection from 'components/DetailsSection';
import SettingsPage from 'components/settings/SettingsPage';
import Table, { ColumnSettings } from 'components/table/Table';
import { useDateTime } from 'hooks/date-time';
import { useChangeRequest } from 'hooks/queries/domain-change-request';
import { usePricingPlans } from 'hooks/queries/pricing';
import { useTranslate } from 'hooks/translate';
import { useUserDateFormat } from 'hooks/user';
import Error404 from 'pages/Error404';

export default function ChangeRequestDetails() {
  const translateText = useTranslate();
  const userDateFormat = useUserDateFormat();
  const { formatDateTime } = useDateTime();
  const { pricingPlans, isLoading: isLoadingPricingPlans } = usePricingPlans();

  const params = useParams();
  const id = Number(params.id);

  const { changeRequest, isLoading: isLoadingChangeRequest, isError } = useChangeRequest(id);
  const isLoading = isLoadingChangeRequest || isLoadingPricingPlans;

  if (isNaN(id) || isError) {
    return (
      <Error404
        title={translateText('label', 'Change not found')}
        firstParagraph={translateText(
          'text',
          'Unfortunately, this change does not exist or you do not have access to the change.',
        )}
      />
    );
  }

  function getTrackedType(sources: string[], included: 0 | 1) {
    if (sources?.length === 0) {
      return included === 0
        ? translateText('label', 'All traffic sources')
        : translateText('label', 'No dynamic traffic sources');
    }
    return included === 1
      ? translateText('label', 'Requested traffic sources') + ': '
      : translateText('label', 'All traffic sources except') + ': ';
  }

  let extraRows = {};
  let title = changeRequest?.type;
  let requestedChanges: {
    columns: ColumnSettings;
    data: Record<string, unknown>[];
  } | null = null;
  switch (changeRequest?.type_name) {
    case 'aen-new':
      requestedChanges = {
        columns: {
          ...(changeRequest.wanted_changes.some(l => !!l.identifier) && {
            identifier: { header: translateText('label', 'AEN code') },
          }),
          name: { header: translateText('label', 'Name') },
          netNumber: {
            header: translateText('label', 'Area code'),
            customValue: formatPhoneNumber,
          },
          destination: {
            header: translateText('label', 'Destination number'),
            customValue: formatPhoneNumber,
            private: true,
          },
          ...(changeRequest.wanted_changes.some(l => l.offsite?.length) && {
            offsite: { header: translateText('label', 'Offsite phone number(s)'), private: true },
          }),
        },
        data: changeRequest.wanted_changes.map(change => ({
          ...change,
          offsite: change.offsite?.map(o => (typeof o === 'string' ? o : o.name)).join(', '),
        })),
      };
      break;
    case 'aen-change':
      if (changeRequest.wanted_changes[0].addOffsite) {
        title += ': ' + translateText('label', 'Add offsite number(s)').toLowerCase();
        requestedChanges = {
          columns: {
            identifier: { header: translateText('label', 'AEN code') },
            name: {
              header: translateText('label', 'Offsite phone number'),
              private: true,
            },
            ...(typeof changeRequest.wanted_changes[0].addOffsite[0] !== 'string' && {
              net_number: {
                header: translateText('label', 'Area code'),
                customValue: formatPhoneNumber,
              },
              destination: {
                header: translateText('label', 'Destination'),
                customValue: formatPhoneNumber,
                private: true,
              },
            }),
          },
          data: changeRequest.wanted_changes.flatMap(change =>
            (change.addOffsite ?? []).map(offsite => ({
              identifier: change.aenIdentifier,
              ...(typeof offsite === 'string' ? { name: offsite } : offsite),
            })),
          ),
        };
      }
      break;
    case 'aen-stop':
      requestedChanges = {
        columns: {
          identifier: { header: translateText('label', 'AEN code') },
          label: { header: translateText('label', 'Name') },
          netnumber: {
            header: translateText('label', 'Area code'),
            customValue: formatPhoneNumber,
          },
          destination: {
            header: translateText('label', 'Destination number'),
            customValue: formatPhoneNumber,
            private: true,
          },
        },
        data: changeRequest.wanted_changes,
      };
      break;
    case 'offsite-new':
      requestedChanges = {
        columns: {
          name: { header: translateText('label', 'Name') },
          net_number: {
            header: translateText('label', 'Area code'),
            customValue: formatPhoneNumber,
          },
          destination: {
            header: translateText('label', 'Destination number'),
            customValue: formatPhoneNumber,
            private: true,
          },
        },
        data: changeRequest.wanted_changes,
      };
      break;
    case 'offsite-stop':
      requestedChanges = {
        columns: {
          name: {
            header: translateText('label', 'Name'),
            customValue: (value, row) =>
              value + (row.location ? ' (' + String(row.location) + ')' : ''),
          },
          number: {
            header: translateText('label', 'Phone number'),
            customValue: formatPhoneNumber,
            private: true,
          },
          destination: {
            header: translateText('label', 'Destination number'),
            customValue: formatPhoneNumber,
            private: true,
          },
        },
        data: changeRequest.wanted_changes,
      };
      break;
    case 'sources-change':
      extraRows = {
        [translateText('label', 'Traffic sources')]: `${getTrackedType(
          changeRequest.wanted_changes.sources,
          changeRequest.wanted_changes.included,
        )} ${changeRequest.wanted_changes.sources.join(', ')}`,
      };
      break;
    case 'upgrade-pricing-plan':
    case 'extend-contract':
      extraRows = {
        [translateText('label', 'Pricing plan')]: pricingPlans?.find(
          pp => pp.id === changeRequest.wanted_changes.pricingPlanId,
        )?.invoice_label,
        [translateText('label', 'Contract duration')]: translateText(
          'datetime',
          '{months} months',
          { months: changeRequest.wanted_changes.contractDuration },
        ),
        [translateText('label', 'Start date')]: moment(
          changeRequest?.type_name === 'upgrade-pricing-plan'
            ? changeRequest.wanted_changes.executionDate
            : changeRequest.wanted_changes.startingDate,
        ).format(userDateFormat),
      };
      break;
    case 'deactivate':
      extraRows = {
        [translateText('label', 'End date')]: moment(changeRequest.wanted_changes).format(
          userDateFormat,
        ),
      };
      break;
  }

  return (
    <SettingsPage
      title={translateText('label', 'Change') + ' #' + id}
      className="change-request-details"
    >
      <DetailsSection
        title={translateText('label', 'Details')}
        isLoading={isLoading}
        boldKeys
        data={{
          [translateText('label', 'Requested by')]: {
            value: changeRequest?.requesting_user,
            private: true,
          },
          [translateText('label', 'Requested at')]: formatDateTime(
            changeRequest?.created_at ?? '',
            true,
            'HH:mm',
          ),
          [translateText('label', 'Domain')]: {
            value: changeRequest?.domain_title,
            private: true,
          },
          [translateText('label', 'Status')]: changeRequest?.status,
          [translateText('label', 'Type')]: changeRequest?.type,
          ...extraRows,
        }}
      />
      {requestedChanges && (
        <DetailsSection title={title}>
          <Table columns={requestedChanges.columns} data={requestedChanges.data} noWhiteBlock />
        </DetailsSection>
      )}
    </SettingsPage>
  );

  function formatPhoneNumber(value: unknown): string {
    if (value && (typeof value === 'string' || typeof value === 'number')) return '+' + value;
    return '-';
  }
}
