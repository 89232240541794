import { useState } from 'react';
import { useNavigate } from 'react-router';

import AutoGrid from 'components/AutoGrid';
import ReportsPage from 'components/ReportsPage';
import QueryTable from 'components/table/QueryTable';
import Widget from 'components/widget/Widget';
import { getDefaultFilters } from 'contexts/Filters';
import { TYPE_CALL_TRACKING } from 'globals/constants';
import { useOverviewWidgetContent } from 'hooks/content';
import { useIsFetchingReport } from 'hooks/fetching';
import { useTranslate } from 'hooks/translate';

export default function Overview() {
  const navigate = useNavigate();
  const translateText = useTranslate();
  const [selectedTile, setSelectedTile] = useState('sessions');
  const { callTrackingWidgetKeys, getCallTrackingWidgetBySelected } = useOverviewWidgetContent();

  const queryKey = ['callTracking', 'overview'];
  const isLoading = useIsFetchingReport(queryKey);

  const defaultFilters = getDefaultFilters(TYPE_CALL_TRACKING);
  const [dataKeys, tickFormatter] = getCallTrackingWidgetBySelected(selectedTile);
  const tilesGraph = (
    <Widget
      widgetKey={[...queryKey, 'tilesGraph']}
      gridColumns={2}
      title={Object.values(dataKeys)[0].name}
      endpoint="/widget/calls"
      filters={defaultFilters}
      type="area"
      hideTooltipTitle
      dataKeys={dataKeys}
      hideTotal
      tickFormatter={tickFormatter}
      isLoading={isLoading}
    />
  );

  const evaluations = (
    <Widget
      widgetKey={[...queryKey, 'evaluations']}
      type="pie"
      title={translateText('label', 'Evaluations')}
      titleTooltip={translateText(
        'tooltip/widgets',
        'This pie chart provides insight into the top 10 evaluations. You will see the number of calls per evaluation over the selected period.',
      )}
      endpoint="/widget/evaluations"
      filters={defaultFilters}
      emptyLabel={translateText('message', 'There are no call evaluations.')}
      maxPieces={8}
      isLoading={isLoading}
    />
  );

  const sources = (
    <QueryTable
      tableKey={[...queryKey, 'trafficSources']}
      dataKey="data"
      gridColumns={3}
      header={
        <>
          {translateText('label', 'Traffic sources')}
          <button
            className="btn btn-text btn-small no-padding"
            onClick={() => navigate('/call-tracking/reports/overview/sources')}
          >
            {translateText('label', 'View all')}
          </button>
        </>
      }
      previousPeriodColumn="argument"
      columns={{
        argument: {
          header: {
            name: translateText('label', 'Traffic source'),
            tooltip: translateText('tooltip/widgets', 'The traffic source that led to the calls.'),
          },
          compareType: 'none',
          private: true,
        },
        sessions: {
          header: {
            name: translateText('label', 'Amount of sessions'),
            tooltip: translateText(
              'tooltip/widgets',
              'The total number of sessions. A session is the period in which a user is active on the website. By default, if a user is inactive for 30 minutes or more, new activity is attributed to a new session. If users leave the website and return within 30 minutes, this is counted as the original session.',
            ),
          },
        },
        calls: {
          header: {
            name: translateText('label', 'Amount of calls'),
            tooltip: translateText('tooltip/widgets', 'The total number of calls made.'),
          },
        },
        conversionrate: {
          header: {
            name: translateText('label', 'Conversion rate'),
            tooltip: translateText(
              'tooltip/widgets',
              'The ratio between the number of calls and the number of sessions realized, expressed as a percentage. This looks at the number of calls processed in Google Analytics.',
            ),
          },
          customNumber: value => value + '%',
        },
        avg_duration: {
          header: {
            name: translateText('label', 'Avg. call duration'),
            tooltip: translateText(
              'tooltip/widgets',
              'The average call duration of the total number of calls made.',
            ),
          },
          compareType: 'time',
        },
      }}
      endpoint="/widget/sources"
      filters={defaultFilters}
      isResponsive
      isLoading={isLoading}
    />
  );

  const days = (
    <Widget
      widgetKey={[...queryKey, 'days']}
      gridColumns={2}
      type="bar"
      title={translateText('label', 'Days')}
      titleTooltip={translateText(
        'tooltip/widgets',
        'This bar graph shows the number of received and missed calls per day of the week in a stack.',
      )}
      endpoint="/widget/days"
      filters={defaultFilters}
      isLoading={isLoading}
      dataKeys={{
        notmissed: { name: translateText('label', 'Calls') },
        missed: { name: translateText('label', 'Missed calls') },
      }}
    />
  );

  const times = (
    <Widget
      widgetKey={[...queryKey, 'times']}
      gridColumns={2}
      type="bar"
      title={translateText('label', 'Times')}
      titleTooltip={translateText(
        'tooltip/widgets',
        'This bar graph shows the number of received and missed calls per hour of the day in a stack.',
      )}
      endpoint="/widget/times"
      filters={defaultFilters}
      isLoading={isLoading}
      dataKeys={{
        notmissed: { name: translateText('label', 'Calls') },
        missed: { name: translateText('label', 'Missed calls') },
      }}
    />
  );

  return (
    <ReportsPage
      queryKey={queryKey}
      title={translateText('label', 'Overview')}
      download={{
        endpoint: '/reports/call-tracking-overview',
        disabled: isLoading,
        filters: {
          ...defaultFilters,
          custom: { selectedKey: selectedTile },
        },
      }}
    >
      <Widget
        widgetKey={[...queryKey, 'tiles']}
        endpoint="/widget/call-tracking-overview"
        type="statistics"
        selected={selectedTile}
        updateSelected={value => setSelectedTile(value)}
        dataKeys={callTrackingWidgetKeys}
        isLoading={isLoading}
        filters={defaultFilters}
      />
      <AutoGrid>
        {tilesGraph}
        {evaluations}
        {sources}
        {days}
        {times}
      </AutoGrid>
    </ReportsPage>
  );
}
