import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import DetailsSection from 'components/DetailsSection';
import TextField from 'components/input/TextField';
import Skeleton from 'components/Skeleton';
import { TYPE_CALL_TRACKING } from 'globals/constants';
import { ServiceType } from 'globals/types';
import { useSendCallDetails } from 'hooks/queries/call';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation } from 'hooks/validation';

type Props = {
  type: ServiceType;
  itemId: number | undefined; // call or call request id
  isLoading: boolean;
};

export default function SendEmailSection({ itemId, type, isLoading }: Props) {
  const translateText = useTranslate();
  const { multipleEmail } = useCustomValidation();
  const { sendCallDetails } = useSendCallDetails(type);

  async function sendMail(
    values: { email: string; bcc: string; remarks: string },
    formikHelpers: FormikHelpers<{ email: string; bcc: string; remarks: string }>,
  ) {
    await sendCallDetails({ itemId: itemId!, ...values }).then(() => formikHelpers.resetForm());
  }

  const title =
    type === TYPE_CALL_TRACKING
      ? translateText('label', 'Send call details by email')
      : translateText('label', 'Send call request details by email');

  const validationSchema = Yup.object({
    email: multipleEmail.test({
      test: function (value) {
        return !!value || !!this.parent.bcc;
      },
      message: translateText('message', 'Fill in one or more email addresses.'),
    }),
    bcc: multipleEmail.test({
      test: function (value) {
        return !!value || !!this.parent.email;
      },
      message: translateText('message', 'Fill in one or more email addresses.'),
    }),
  });

  return (
    <DetailsSection title={title} isLoading={isLoading}>
      <Formik
        initialValues={{ email: '', bcc: '', remarks: '' }}
        onSubmit={sendMail}
        validationSchema={validationSchema}
      >
        {({ values, handleChange, handleBlur, isSubmitting, submitForm, errors, touched }) => (
          <div className="input-container">
            <div className="input-row">
              <div className="input-label">
                {isLoading ? (
                  <Skeleton width={200} />
                ) : (
                  translateText('label', 'Email address receiver')
                )}
              </div>
              <div className="input">
                {isLoading ? (
                  <Skeleton height={38} />
                ) : (
                  <TextField
                    name="email"
                    placeholder={translateText('label', 'Email address')}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && touched.bcc && errors.email}
                  />
                )}
              </div>
            </div>
            <div className="input-row">
              <div className="input-label">
                {isLoading ? <Skeleton width={200} /> : translateText('label', 'Email address bcc')}
              </div>
              <div className="input">
                {isLoading ? (
                  <Skeleton height={38} />
                ) : (
                  <TextField
                    name="bcc"
                    placeholder={translateText('label', 'Email address')}
                    value={values.bcc}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && touched.bcc && errors.bcc}
                  />
                )}
              </div>
            </div>
            <div className="input-row">
              <div className="input">
                {isLoading ? (
                  <Skeleton height={120} />
                ) : (
                  <TextField
                    name="remarks"
                    type="textarea"
                    placeholder={translateText('label', 'Remarks')}
                    value={values.remarks}
                    onChange={handleChange}
                  />
                )}
              </div>
            </div>
            <div className="input-row submit-row">
              {isLoading ? (
                <Skeleton className="btn" width={120} />
              ) : (
                <button disabled={isSubmitting} className="btn btn-green" onClick={submitForm}>
                  {translateText('label', 'Send')}
                </button>
              )}
            </div>
          </div>
        )}
      </Formik>
    </DetailsSection>
  );
}
