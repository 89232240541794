import { useDateTime } from './date-time';
import { useUserLanguage } from './user';

export function useCalculate() {
  const language = useUserLanguage();
  const { timeToSeconds } = useDateTime();

  function calculatePercentage(
    value: number | string,
    total: number | string,
    showValue?: boolean | 'diff',
  ) {
    if (Number(total) === 0 || total === '-') return NaN;

    if (typeof value === 'string') value = parseFloat(value);
    if (typeof total === 'string') total = parseFloat(total);

    let percentage = (value / total) * 100;
    if (showValue === 'diff') percentage -= 100;

    return percentage;
  }

  function getFormatted(
    value: number | string,
    total: number | string,
    percentage: number,
    showValue?: boolean | 'diff',
    toFixed?: number | false,
  ) {
    let additional = '';
    if (showValue) {
      let additionalValue = Number(value);
      if (showValue === 'diff') additionalValue -= Number(total);
      additional = ' (' + additionalValue.toLocaleString(language) + ')';
    }

    let digits = 2;
    if (toFixed === false || toFixed === undefined) {
      if (percentage >= 1000) {
        digits = 0;
      } else if (percentage >= 100) {
        digits = 1;
      }
    } else {
      digits = toFixed;
    }

    const formattedPercentage =
      percentage.toLocaleString(language, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
      }) + '%';

    return { formattedPercentage, additional };
  }

  function getPercentage(
    value: number | string,
    total: number | string,
    showValue?: boolean | 'diff',
    toFixed?: number | false,
  ) {
    const percentage = calculatePercentage(value, total, showValue);
    if (isNaN(percentage)) return '-';

    const { formattedPercentage, additional } = getFormatted(
      value,
      total,
      percentage,
      showValue,
      toFixed,
    );

    return formattedPercentage + additional;
  }

  function getDifference(
    current: number | string,
    previous: number | string | [number | string],
    showDifference?: boolean,
    type?: 'time' | 'star' | 'seconds',
    lessIsBetter?: boolean,
  ) {
    if (Array.isArray(previous) && previous.length === 1) previous = previous[0];

    switch (type) {
      case 'time':
        current = timeToSeconds(current as string);
        previous = timeToSeconds(previous as string);
        break;
      case 'star':
        current = (current as string).replace('<span>&#9733;</span>', '').replace(',', '.').trim();
        previous = (previous as string)
          .replace('<span>&#9733;</span>', '')
          .replace(',', '.')
          .trim();
        break;
    }

    if (current === undefined || current === null || current === '') current = 0;
    if (previous === undefined || previous === null || previous === '') previous = 0;

    if (Number(previous) === 0 || previous === '-') return ['-', ''];
    if (Number(current) === 0) {
      return ['-100,0%', lessIsBetter ? ' positive' : ' negative'];
    }

    const percentage = calculatePercentage(current, previous, 'diff');
    if (isNaN(percentage)) return ['-', ''];

    const { formattedPercentage, additional } = getFormatted(
      current,
      previous,
      percentage,
      showDifference ? 'diff' : false,
      false,
    );

    let returnValue = null;
    if (percentage > 0) {
      returnValue = ['+' + formattedPercentage, lessIsBetter ? ' negative' : ' positive'];
    } else if (percentage < 0) {
      returnValue = [formattedPercentage, lessIsBetter ? ' positive' : ' negative'];
    } else {
      returnValue = [formattedPercentage, ''];
    }
    returnValue[0] = returnValue[0] + additional;
    return returnValue;
  }

  return { getPercentage, getDifference };
}
