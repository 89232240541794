import gif from 'assets/images/guided-tour/04-domain-overview.gif';
import { useTranslate } from 'hooks/translate';
import BaseTour from './BaseTour';

export default function DomainOverviewTour() {
  const translateText = useTranslate();
  return (
    <BaseTour
      title={translateText('guided-tour', 'Domain overview')}
      image={gif}
      description={
        <>
          <p
            dangerouslySetInnerHTML={{
              __html: translateText(
                'guided-tour',
                'On the <b>domain overview</b> page you will find an overview of all your domains. Select a domain to view reports of the domain, request an extension of the domain or create an integration.',
              ),
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: translateText(
                'guided-tour',
                'You can switch domains at any time in the entire dashboard with the <b>domain selector</b>.',
              ),
            }}
          />
        </>
      }
    />
  );
}
