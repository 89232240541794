import icon from 'assets/images/icons/integrations/google-search-ads-360.svg';
import BaseGoogle360, { TYPE_SEARCH_ADS_360 } from 'components/integrations/BaseGoogle360';
import { REDIRECT_URI_GOOGLE_SA360 } from 'components/oauth2/Verification';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import { INTEGRATION_GOOGLE_SEARCH_ADS_360 } from 'globals/constants';
import { SUP_ART_SEARCH_ADS_360 } from 'globals/support';
import { useHasAccessToIntegration } from 'hooks/access';
import { useTranslate } from 'hooks/translate';

export default function GoogleSearchAds360({ show, close }: BaseSlideInProps) {
  const translateText = useTranslate();
  const hasAccessToIntegration = useHasAccessToIntegration();

  if (!hasAccessToIntegration(INTEGRATION_GOOGLE_SEARCH_ADS_360)) return null;

  return (
    <BaseGoogle360
      show={show}
      close={close}
      icon={icon}
      infoText={translateText(
        'integration',
        'With the help of this app you can integrate call-conversions in Google Search Ads 360.',
      )}
      articleId={SUP_ART_SEARCH_ADS_360}
      type={TYPE_SEARCH_ADS_360}
      title={translateText('label', 'Google Search Ads 360')}
      redirectUriKey={REDIRECT_URI_GOOGLE_SA360}
      integrationKey={INTEGRATION_GOOGLE_SEARCH_ADS_360}
    />
  );
}
