export default {
    "Account access": "Accounttoegang",
    "Admin": "Admin",
    "Admin call history": "Admin oproepgeschiedenis",
    "Admin overview": "Admin overzicht",
    "Articles with status archived": "Artikelen met status gearchiveerd",
    "Articles with status concept": "Artikelen met status concept",
    "Articles without categories": "Artikelen zonder categorie\u00ebn",
    "Available phone numbers": "Voorraad telefoonnummers",
    "Bulk update phone numbers": "Bulk update telefoonnummers",
    "Call Tracking": "Call Tracking",
    "Call functionalities": "Oproepfunctionaliteiten",
    "Campaign builder": "Campaign builder",
    "Categories without active articles": "Categorie\u00ebn zonder actieve artikelen",
    "Change history": "Wijzigingsgeschiedenis",
    "Changes": "Wijzigingen",
    "Configuration": "Configuratie",
    "Content": "Content",
    "Content login page": "Content loginpagina",
    "Data Processor Agreement": "Verwerkersovereenkomst",
    "Days and times": "Dagen en tijden",
    "Debug mode": "Debug mode",
    "Deleted domains": "Verwijderde domeinen",
    "Domain overview": "Domeinoverzicht",
    "Domain settings": "Domein-instellingen",
    "Domain stop requests": "Domein stopzettingen",
    "Domains": "Domeinen",
    "E-mail blacklist": "E-mail zwarte lijst",
    "Email blacklist": "E-mail zwarte lijst",
    "Evaluations": "Beoordelingen",
    "Functionalities": "Functionaliteiten",
    "History": "Geschiedenis",
    "Implementation": "Implementatie",
    "Inspiration": "Inspiratie",
    "Intakes": "Intakes",
    "Integrations": "Integraties",
    "Invoicing": "Facturatie",
    "Knowledge Center": "Kenniscentrum",
    "Locations": "Locaties",
    "Login content": "Login content",
    "Logs": "Logs",
    "Mail log": "Mail log",
    "Marketing": "Marketing",
    "New request": "Nieuwe aanvraag",
    "Notifications": "Notificaties",
    "Number assign overview": "Nummeruitgifte overzicht",
    "Number assign stats": "Nummeruitgifte statistieken",
    "Number check": "Telefoonnummer check",
    "Number distribution": "Nummeruitgifte",
    "Offsite phone numbers": "Offsite telefoonnummers",
    "Overview": "Overzicht",
    "Overview domains": "Overzicht domeinen",
    "Overview phone numbers": "Overzicht telefoonnummers",
    "Periodic report": "Periodieke rapportage",
    "Phone numbers": "Telefoonnummers",
    "Phone numbers blacklist": "Zwarte lijst telefoonnummers",
    "Portals": "Portalen",
    "Profiles": "Profielen",
    "Properties": "Eigenschappen",
    "Release notes": "Release notes",
    "Reports": "Rapportages",
    "Requests": "Aanvragen",
    "Resellers": "Partners",
    "Session filter": "Sessie filter",
    "Settings": "Instellingen",
    "Stop logs": "Log telefoonnummers",
    "Stop requests phone numbers": "Stopzettingen telefoonnummers",
    "Support": "Support",
    "Sync script": "Synchroniseer script",
    "Tracking and filters": "Tracking en filters",
    "Upgrade": "Upgrade",
    "Users": "Gebruikers",
    "VoIP": "VoIP"
}