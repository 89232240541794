export default {
    "The average time between submitting the call request and establishing a successful conversation between both parties.": "De gemiddelde tijd tussen het indienen van het terugbelverzoek en het tot stand brengen van een succesvol gesprek tussen beide partijen.",
    "The number of call request(s) that will soon be started based on the chosen settings.": "Het aantal verzoek(en) dat binnenkort zal worden aangeboden op basis van de gekozen instellingen.",
    "The number of calls made.": "Het aantal gerealiseerde oproepen.",
    "The number of requests for which a successful call was established.": "Het aantal verzoeken waarvoor een succesvolle oproep tot stand is gebracht.",
    "The top 5 of locations with the fastest response time.": "De top-5 van locaties met de snelste reactietijd.",
    "The top 5 of locations with the highest successfully processed call requests.": "De top-5 van locaties met het hoogste succespercentage.",
    "The top 5 of locations with the most call requests.": "De top-5 van locaties met de meeste terugbelverzoeken.",
    "The top 5 of locations with the most calls.": "De top 5 van locaties met de meeste oproepen.",
    "The top 5 of profiles with the fastest response time.": "De top-5 van profielen met de snelste reactietijd.",
    "The top 5 of profiles with the highest successfully processed call requests.": "De top-5 van profielen met het hoogste succespercentage.",
    "The top 5 of profiles with the most call requests.": "De top-5 van profielen met de meeste oproepen.",
    "The top 5 of profiles with the most calls.": "De top 5 van profielen met de meeste oproepen.",
    "The total number of call requests.": "Het totaal aantal terugbelverzoeken."
}