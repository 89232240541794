import { Dispatch, SetStateAction } from 'react';
import { FormikProps } from 'formik';

import CreateDeleteTable from 'components/CreateDeleteTable';
import Select from 'components/input/Select';
import TextField from 'components/input/TextField';
import Toggle from 'components/input/Toggle';
import RestrictedAccess from 'components/RestrictedAccess';
import InputWrapper from 'components/slide-in/InputWrapper';
import SupportParagraph from 'components/SupportParagraph';
import { CURRENCIES_LIST, ROLE_ADMIN } from 'globals/constants';
import { SUP_ART_GA4_ECOMMERCE, SUP_ART_GA4_FILTERS } from 'globals/support';
import { Filter, Stream as StreamValues, useDeleteFilter } from 'hooks/queries/app';
import { useTranslate } from 'hooks/translate';

type Props = {
  stream: 'new' | number | false;
  setShowFilter: Dispatch<SetStateAction<number | 'new' | false>>;
  formProps: FormikProps<Omit<StreamValues, 'id' | 'filters'>>;
  filters: Filter[];
};

export default function Stream({ stream, setShowFilter, formProps, filters }: Props) {
  const translateText = useTranslate();
  const { deleteFilter, isDeleting } = useDeleteFilter();

  const { values, handleChange, isSubmitting, errors, submitCount } = formProps;

  return (
    <>
      <InputWrapper
        label={translateText('label', 'Send events')}
        tooltip={translateText(
          'tooltip/google-analytics-4',
          'Disable and enable the sending of events to Google Analytics 4 here.',
        )}
      >
        <Toggle
          name="enabled"
          checked={values.enabled}
          onClick={handleChange}
          disabled={isSubmitting}
        />
      </InputWrapper>
      <InputWrapper
        label={translateText('label', 'Measurement ID')}
        tooltip={translateText(
          'tooltip/google-analytics-4',
          'Enter the measurement ID associated with the respective Google Analytics property here.',
        )}
      >
        <TextField
          name="measurement_id"
          onChange={handleChange}
          value={values.measurement_id}
          error={submitCount > 0 && errors.measurement_id}
          disabled={isSubmitting}
        />
      </InputWrapper>
      <InputWrapper
        label={translateText('label', 'API secret')}
        tooltip={translateText(
          'tooltip/google-analytics-4',
          'Enter the API secret associated with the respective Google Analytics property here.',
        )}
      >
        <TextField
          name="api_secret"
          onChange={handleChange}
          value={values.api_secret}
          error={submitCount > 0 && errors.api_secret}
          disabled={isSubmitting}
        />
      </InputWrapper>
      <h3 className="section-title">{translateText('label', 'Conversion measurement')}</h3>
      <SupportParagraph customLink="/knowledge-center/support/category/48" />
      <InputWrapper
        label={translateText('label', 'Send recurring calls')}
        tooltip={translateText(
          'tooltip/google-analytics-4',
          'Determine whether the recurring calls can be processed in Google Analytics.',
        )}
      >
        <Toggle
          name="send_recurring_calls"
          checked={values.send_recurring_calls}
          onClick={handleChange}
          disabled={!values.enabled || isSubmitting}
        />
      </InputWrapper>
      <InputWrapper
        label={translateText('label', 'Send offsite conversions')}
        tooltip={translateText(
          'tooltip/google-analytics-4',
          'Determine whether calls to offsite phone numbers can be processed in Google Analytics.',
        )}
      >
        <Toggle
          name="send_offsite_calls"
          checked={values.send_offsite_calls}
          onClick={handleChange}
          disabled={!values.enabled || isSubmitting}
        />
      </InputWrapper>
      <RestrictedAccess role={ROLE_ADMIN} show="outline" right>
        <InputWrapper
          label={translateText('label', 'Send fallback conversions')}
          tooltip={translateText(
            'tooltip/google-analytics-4',
            'Determine whether calls to fallback phone numbers can be processed in Google Analytics.',
          )}
        >
          <Toggle
            name="send_fallback_calls"
            checked={values.send_fallback_calls}
            onClick={handleChange}
            disabled={!values.enabled || isSubmitting}
          />
        </InputWrapper>
      </RestrictedAccess>
      <InputWrapper
        label={translateText('label', 'Send second event')}
        tooltip={translateText(
          'tooltip/google-analytics-4',
          'Determine if you want to send a second event to Google Analytics when the call evaluation has changed.',
        )}
      >
        <Toggle
          name="second_event_enabled"
          checked={values.second_event_enabled}
          onClick={handleChange}
          disabled={!values.enabled || isSubmitting}
        />
      </InputWrapper>
      <h3 className="section-title">{translateText('label', 'Filters')}</h3>
      <SupportParagraph articleId={SUP_ART_GA4_FILTERS} />
      <InputWrapper
        label={translateText('label', 'Enable filters')}
        tooltip={translateText(
          'tooltip/google-analytics-4',
          'By setting multiple filters, you can send multiple events for one call. For example, to distinguish call evaluations.',
        )}
      >
        <Toggle
          name="filters_enabled"
          checked={values.filters_enabled}
          onClick={handleChange}
          disabled={!values.enabled || isSubmitting}
        />
      </InputWrapper>
      {values.filters_enabled && (
        <>
          <InputWrapper
            label={translateText('label', 'Send default event for all calls')}
            tooltip={translateText(
              'tooltip/google-analytics-4',
              'Indicate whether the default AdCalls event can be sent to Google Analytics 4 for each call.',
            )}
          >
            <Toggle
              name="send_default_event"
              checked={values.send_default_event}
              onClick={handleChange}
              disabled={!values.enabled || isSubmitting}
            />
          </InputWrapper>
          <CreateDeleteTable
            items={filters.map(filter => ({
              ...filter,
              status: <div className={'big-dot ' + (filter.enabled ? 'active' : 'inactive')} />,
            }))}
            dataKeys={['status', 'event_name']}
            onAdd={() => setShowFilter('new')}
            onEdit={row => setShowFilter(row.id)}
            onDelete={filter => deleteFilter(filter)}
            addButtonDisabled={stream === 'new' || isSubmitting}
            buttonsDisabled={isSubmitting || isDeleting}
            className="margin-top"
            emptyText={
              stream === 'new'
                ? translateText('text', 'A filter can only be added once a stream is created.')
                : translateText(
                    'text',
                    'No filters have been set yet. Click on the + to set up a filter.',
                  )
            }
          />
        </>
      )}
      <h3 className="section-title">{translateText('label', 'Track e-commerce transactions')}</h3>
      <SupportParagraph articleId={SUP_ART_GA4_ECOMMERCE} />
      <InputWrapper
        label={translateText('label', 'Send e-commerce transactions')}
        tooltip={translateText(
          'tooltip/google-analytics-4',
          'Determine whether the conversion value may be processed as a transaction in Google Analytics 4.',
        )}
      >
        <Toggle
          name="ecommerce_enabled"
          checked={values.ecommerce_enabled}
          onClick={handleChange}
          disabled={!values.enabled || isSubmitting}
        />
      </InputWrapper>
      <InputWrapper
        label={translateText('label', 'Currency value')}
        tooltip={translateText('tooltip/google-analytics-4', 'Select the desired currency.')}
      >
        <Select
          name="ecommerce_currency"
          disabled={!values.enabled || isSubmitting}
          value={values.ecommerce_currency}
          onChange={handleChange}
          options={CURRENCIES_LIST}
        />
      </InputWrapper>
    </>
  );
}
