import { useRef, useState } from 'react';
import { Formik } from 'formik';

import Select from 'components/input/Select';
import Skeleton from 'components/Skeleton';
import { useOnClickOutside } from 'hooks/event';
import { useEvaluationOptions, useMultiUpdateEvaluations } from 'hooks/queries/evaluation';
import { useTranslate } from 'hooks/translate';
import EvaluationValueInput from './EvaluationValueInput';

type Props = {
  onSave: (evaluation: string, value: string, close: () => void) => void;
  onDelete: (close: () => void) => void;
  isLoadingTable: boolean;
};

export default function MultiEvaluate({ onSave, onDelete, isLoadingTable }: Props) {
  const translateText = useTranslate();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  const { evaluationOptions, isLoading } = useEvaluationOptions();
  const { isUpdating } = useMultiUpdateEvaluations();

  useOnClickOutside(ref, () => {
    if (isOpen) setIsOpen(false);
  });

  if (isLoadingTable) {
    return <Skeleton className="btn" width={175} containerClass="multi-evaluate" />;
  }

  function saveChanges(values: { evaluation: string; value: string | number }) {
    const replacedValue = String(values.value).replace(',', '.');
    const parsedValue = parseFloat(replacedValue);
    onSave(values.evaluation, !isNaN(parsedValue) ? parsedValue.toFixed(2) : replacedValue, () =>
      setIsOpen(false),
    );
  }

  return (
    <Formik
      initialValues={{
        evaluation: evaluationOptions?.[0] ?? '',
        value: evaluationOptions?.[0] === 'lead' ? '1' : '',
      }}
      onSubmit={saveChanges}
      enableReinitialize
    >
      {({ values, submitForm, setFieldValue }) => {
        return (
          <div className="multi-select-dropdown-wrapper" ref={ref}>
            <button className="btn btn-lightblue" onClick={() => setIsOpen(open => !open)}>
              <span className="extra-button-text">
                {translateText('label', 'Change evaluation')}
              </span>
              <div className="extra-button-arrow arrow-wrapper">
                <div className={'arrow arrow-' + (isOpen ? 'down-right' : 'right')} />
              </div>
            </button>
            {isOpen && (
              <div className="multi-select-dropdown">
                <h3 className="bold">
                  {isLoading ? (
                    <Skeleton width={200} />
                  ) : (
                    translateText('label', 'Change evaluation')
                  )}
                </h3>
                <div className="data-row">
                  <div className="row-label">
                    {isLoading ? <Skeleton /> : translateText('label', 'Evaluation')}
                  </div>
                  <div className="row-input">
                    {isLoading ? (
                      <Skeleton height={38} />
                    ) : (
                      <Select
                        name="evaluation"
                        options={evaluationOptions ?? []}
                        value={values.evaluation}
                        onChange={e => {
                          setFieldValue('value', e.target.value === 'lead' ? '1' : '');
                          setFieldValue('evaluation', e.target.value);
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="data-row">
                  <div className="row-label">
                    {isLoading ? <Skeleton /> : translateText('label', 'Value')}
                  </div>
                  <div className="row-input">
                    <EvaluationValueInput isLoading={isLoading} />
                  </div>
                </div>
                <div className="buttons">
                  {isLoading ? (
                    <Skeleton className="btn" />
                  ) : (
                    <button
                      className="btn btn-lightblue"
                      onClick={() => onDelete(() => setIsOpen(false))}
                      disabled={isUpdating}
                    >
                      {translateText('label', 'Delete evaluations')}
                    </button>
                  )}
                  {isLoading ? (
                    <Skeleton className="btn" />
                  ) : (
                    <button className="btn btn-green" onClick={submitForm} disabled={isUpdating}>
                      {translateText('label', 'Save changes')}
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        );
      }}
    </Formik>
  );
}
