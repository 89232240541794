import { useNavigate } from 'react-router';
import { capitalize, pick } from 'lodash';

import { Intake } from 'globals/types';
import QueryTable from '../../../components/table/QueryTable';
import { useTranslate } from '../../../hooks/translate';

export default function Overview() {
  const translateText = useTranslate();
  const navigate = useNavigate();

  function continueConcept(concept: Intake) {
    const intakeValues = {
      ...pick(concept, [
        'id',
        'reseller_id',
        'contract_duration',
        'invoice_profile_id',
        'email',
        'company',
        'name',
        'reference',
        'kvk_number',
        'vat_number',
        'phone_number',
        'address',
        'city',
        'country',
        'automatic_collection',
        'sources',
        'included',
        'destinations',
        'offsite_numbers',
        'remarks',
      ]),
      url: concept.domain_title.toLowerCase(),
      urlScheme: concept.domain_url.startsWith('http://') ? 'http://' : 'https://',
      pricingPlanId: concept.pricing_plan_id,
      zipcode: concept.postal_code,
      format: +concept.numberformat || concept.numberformat,
    };

    localStorage.setItem('intake', JSON.stringify(intakeValues));
    navigate('/requests/intake');
  }

  return (
    <>
      <h1>{translateText('label', 'Concepts')}</h1>
      <QueryTable<Intake & { continue: undefined }>
        tableKey={['requests', 'conceptIntakes']}
        endpoint="/domain-intake/concepts"
        columns={{
          domain_title: {
            header: { name: translateText('label', 'Domain title'), sort: true },
            customValue: value => capitalize(value),
          },
          created_on: {
            header: { name: translateText('label', 'Created on'), sort: true },
            date: true,
          },
          updated_on: {
            header: { name: translateText('label', 'Last change'), sort: true },
            date: true,
          },
          continue: {
            header: '',
            button: {
              text: translateText('label', 'Continue'),
              onClick: intake => continueConcept(intake),
              className: 'btn btn-text no-padding',
            },
            className: 'intake-continue',
          },
        }}
        filters={{ domain: false, custom: { expand: 'reseller_id,contract_duration' } }}
        className="wrap"
        pagination={{ defaultLimit: 20 }}
        isResponsive
        refresh
        canSearch
        emptyText={translateText('label', "You don't have any saved requests.")}
      />
      <h1>{translateText('label', 'Requests')}</h1>
      <QueryTable<Intake>
        tableKey={['requests', 'intakes']}
        endpoint="/domain-intake/index"
        columns={{
          domain_url: { header: { name: translateText('label', 'Domain URL'), sort: true } },
          company: { header: { name: translateText('label', 'Company'), sort: true } },
          created_by: { header: { name: translateText('label', 'Created by'), sort: true } },
          created_on: {
            header: { name: translateText('label', 'Created on'), sort: true },
            date: true,
          },
          status: { header: { name: translateText('label', 'Status'), sort: true } },
        }}
        filters={{ domain: false, custom: { expand: 'reseller_id,contract_duration' } }}
        className="wrap"
        pagination={{ defaultLimit: 20 }}
        onRowClick={row => navigate('/requests/overview/' + row.id)}
        isResponsive
        refresh
        canSearch
        emptyText={translateText('label', 'You currently have no requests.')}
      />
    </>
  );
}
