import { useState } from 'react';
import { cloneDeep } from 'lodash';
import {
  Area as RechartsArea,
  AreaChart,
  CartesianGrid,
  Legend as RechartsLegend,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { useTranslate } from 'hooks/translate';
import Legend from './Legend';
import Tooltip from './Tooltip';
import { AreaProps, DataKey, DataProps } from './types';
import { PREVIOUS_PREFIX } from './Widget';

export default function Area(props: AreaProps & DataProps) {
  const { id, palette, emptyLabel, tickFormatter, previousData } = props;
  const translateText = useTranslate();
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  // Data keys can be given as prop or can be decided dynamically from the data.
  const dataKeys = props.dataKeys ?? {};
  if (!props.dataKeys && props.data[0]) {
    Object.keys(props.data[0]).forEach(key => {
      if (key !== 'argument') dataKeys[key] = { name: key };
    });
  }

  // When there is previous data, each data key needs to be duplicated for the previous period
  // and the previous data needs to be merged with the regular data.
  const data = cloneDeep(props.data);
  const dataKeysWithPrev: AreaProps['dataKeys'] = {};
  if (previousData) {
    Object.entries(dataKeys).forEach(([key, value]) => {
      dataKeysWithPrev[PREVIOUS_PREFIX + key] = {
        name: translateText('label', '{key} (previous period)', { key: value.name }),
      };
      dataKeysWithPrev[key] = value;
    });
    props.data.forEach((item, index) => {
      Object.keys(item).forEach(key => {
        if (key !== 'argument') data[index][PREVIOUS_PREFIX + key] = previousData[index]?.[key];
      });
    });
  }

  // This prop is only used on the overview page, where the x-axis shows the numbers 1-28 for the days,
  // and the tooltip shows the specific date instead of the number. The section below ensures the
  // arguments are the numbers instead of the dates and the dates are included in the data.
  if (props.dateAsTooltipKey) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].argument !== i + 1) {
        data[i].date = data[i].argument;
        data[i].argument = i + 1;
        if (previousData) data[i][PREVIOUS_PREFIX + 'date'] = previousData[i].argument;
      }
    }
  }

  function createRechartsArea(settings: { key: string; index: number } & DataKey) {
    const color = palette[settings.index % palette.length];
    const opacity = activeIndex === null || activeIndex === settings.index ? '1' : '0.1';
    return (
      <RechartsArea
        key={settings.key}
        name={settings.name}
        dataKey={settings.key}
        label={settings.tooltip}
        fill="0"
        fillOpacity="0"
        stroke={color}
        strokeOpacity={opacity}
        strokeWidth="2"
        dot={{
          stroke: color,
          strokeWidth: 3,
          r: 1,
          fillOpacity: opacity,
          strokeOpacity: opacity,
        }}
        activeDot={{ stroke: color, strokeWidth: 2, fill: 'white', r: 3, fillOpacity: 'false' }}
      />
    );
  }

  let formatX, formatY;
  if (tickFormatter?.x) formatX = tickFormatter.format;
  if (tickFormatter?.y === true) formatY = tickFormatter.format;
  else if (tickFormatter?.y) formatY = tickFormatter.y;

  return (
    <ResponsiveContainer id={id} className="widget">
      <AreaChart data={data}>
        <RechartsLegend content={<Legend setActiveIndex={setActiveIndex} type="area" />} />
        <XAxis
          dataKey="argument"
          tickLine={false}
          axisLine={false}
          tickFormatter={formatX}
          domain={[emptyLabel, emptyLabel]}
        />
        <YAxis width={40} tickLine={false} axisLine={false} tickFormatter={formatY} />
        <RechartsTooltip cursor={false} content={<Tooltip {...props} />} />
        <CartesianGrid vertical={false} className="horizontal-lines" />
        {Object.entries(previousData ? dataKeysWithPrev : dataKeys).map(([key, item], index) =>
          createRechartsArea({ ...item, key, index }),
        )}
      </AreaChart>
    </ResponsiveContainer>
  );
}
