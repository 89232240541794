import { Fragment, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';

import Checkbox from 'components/input/Checkbox';
import Radio from 'components/input/Radio';
import Select from 'components/input/Select';
import TextField from 'components/input/TextField';
import VerifyProceedPrompt from 'components/VerifyProceedPrompt';
import { useMessages } from 'contexts/Messages';
import {
  SIP_TRUNK_DSTNY_PBX_AD_CALLS,
  SIP_TRUNK_DSTNY_PBX_ELEVATE,
  SIP_TRUNK_DSTNY_PBX_LIVE,
  SIP_TRUNK_DSTNY_PBX_TEST,
  SIP_TRUNK_DSTNY_PROBLEMEN_KWIJT,
  SIP_TRUNK_DSTNY_VPS_LIVE,
  SIP_TRUNK_DSTNY_VPS_LIVE_2,
  SIP_TRUNK_DSTNY_VPS_TEST,
  SIP_TRUNK_STEAM_CONNECT_LIVE,
  SIP_TRUNK_STEAM_CONNECT_PROD,
  SIP_TRUNK_STEAM_CONNECT_TEST,
  SIP_TRUNK_VOXBONE,
} from 'globals/constants';
import {
  DeleteNumbersResponse,
  StopNumbersResponse,
  useBulkUpdate,
  useDeleteNumbers,
  useStopNumbers,
} from 'hooks/queries/number';
import { useTranslate } from 'hooks/translate';

type FormValues = {
  includeDestination: boolean;
  destination: string;
  includeFallback: boolean;
  isFallback: boolean;
  includeSipTrunk: boolean;
  sipTrunk: string;
  includeFormTracking: boolean;
  isFormTracking: boolean;
};

type Logs = (string | { title: string; logs: string[] })[];

export default function Bulk() {
  const translateText = useTranslate();
  const { addSuccessMessage } = useMessages();
  const { bulkUpdate } = useBulkUpdate();
  const { stopNumbers, isUpdating: isUpdatingStop } = useStopNumbers();
  const { deleteNumbers, isUpdating: isUpdatingDelete } = useDeleteNumbers();
  const [logs, setLogs] = useState<Logs>([]);

  const [numbers, setNumbers] = useState('');
  const [markNumbers, setMarkNumbers] = useState(false);
  const [markType, setMarkType] = useState<'delete' | 'stop'>('delete');

  const [warning, setWarning] = useState('');

  async function updateNumbers(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    await bulkUpdate({
      numbers: numbers.split('\n'),
      ...(values.includeDestination && { destination: values.destination }),
      ...(values.includeFallback && { is_fallback: values.isFallback }),
      ...(values.includeSipTrunk && { sip_trunk: values.sipTrunk }),
      ...(values.includeFormTracking && { call_me_back: values.isFormTracking }),
    }).then(() => {
      formikHelpers.resetForm();
      setNumbers('');
    });
  }

  function onDeletedOrStopped(logs: Logs) {
    if (typeof logs[0] === 'string') addSuccessMessage(logs[0]);
    setLogs(logs);
    setNumbers('');
    setWarning('');
    setMarkNumbers(false);
  }

  function onMarkAsDeleted() {
    if (
      !window.confirm(
        translateText(
          'admin/text',
          'This does not cancel the numbers at their respective vendor, this only deletes them from our database. Are you sure all numbers are cancelled at the vendor?',
        ),
      )
    ) {
      return;
    }
    deleteNumbers({
      numbers: numbers.split('\n'),
    }).then((data: DeleteNumbersResponse) => {
      const logs: Logs = [
        translateText('admin/text', '{amount} of {total} numbers have been logged and deleted', {
          amount: data.deletedNumbers.length,
          total: data.total,
        }),
      ];
      if (data.notDeletedNumbers.length > 0) {
        logs.push({
          title: translateText(
            'admin/text',
            "Below you'll find a list of numbers that couldn't be deleted:",
          ),
          logs: data.notDeletedNumbers,
        });
      }
      onDeletedOrStopped(logs);
    });
  }

  function onMarkAsStopped(stopRequestedForCheck = true) {
    stopNumbers({
      numbers: numbers.split('\n'),
      stopRequestedForCheck,
    })
      .then((data: StopNumbersResponse) => {
        const logs: Logs = [
          translateText('admin/text', '{amount} number(s) will be attempted to be updated', {
            amount: data.totalToBeStopped,
          }),
        ];
        if (data.unstoppableSteamConnect.length > 0) {
          logs.push({
            title: translateText(
              'admin/text',
              "Below you'll find a list of numbers that couldn't be stopped at {vendor}:",
              { vendor: 'SteamConnect' },
            ),
            logs: data.unstoppableSteamConnect,
          });
        }
        if (data.unstoppableDstny.length > 0) {
          logs.push({
            title: translateText(
              'admin/text',
              "Below you'll find a list of numbers that couldn't be stopped at {vendor}:",
              { vendor: 'Dstny' },
            ),
            logs: data.unstoppableDstny,
          });
        }
        if (data.unstoppableVoxbone.length > 0) {
          logs.push({
            title: translateText(
              'admin/text',
              "Below you'll find a list of numbers that couldn't be stopped at {vendor}:",
              { vendor: 'Voxbone' },
            ),
            logs: data.unstoppableVoxbone,
          });
        }
        onDeletedOrStopped(logs);
      })
      .catch(error => {
        if (typeof error === 'object' && error.hasOwnProperty('numbersNoStopRequestedFor')) {
          setWarning(
            translateText('message', "The following numbers don't have a stop date: {numbers}", {
              numbers: `<ul><li>${error.numbersNoStopRequestedFor.join('</li><li>')}</li></ul>`,
            }),
          );
        }
      });
  }

  return (
    <div className="bulk-update">
      <VerifyProceedPrompt
        show={warning !== ''}
        isSaving={isUpdatingStop}
        title={translateText('label', 'Numbers without a stop date') + '!'}
        intro={warning}
        verifyText={translateText('label', 'Yes, stop the numbers')}
        proceed={{ onClick: () => onMarkAsStopped(false) }}
        cancel={{ onClick: () => setWarning('') }}
      />
      <h1>{translateText('label', 'Update numbers')}</h1>
      {logs.map((log, index) => {
        if (typeof log === 'string') return <p key={`log-${index}`}>{log}</p>;
        return (
          <Fragment key={`log-${index}`}>
            <p>{log.title}</p>
            <ul>
              {log.logs.map((l, i) => (
                <li key={`log-entry-${index}-${i}`}>{l}</li>
              ))}
            </ul>
          </Fragment>
        );
      })}
      <div className="white-block">
        <div className="bulk-numbers">
          <div className="bold">{translateText('label', 'Numbers')}</div>
          <TextField
            type="textarea"
            rows={28}
            value={numbers}
            onChange={e => setNumbers(e.target.value)}
          />
        </div>
        <div className="bulk-options">
          <Formik<FormValues>
            initialValues={{
              includeDestination: false,
              destination: '',
              includeFallback: false,
              isFallback: false,
              includeSipTrunk: false,
              sipTrunk: SIP_TRUNK_DSTNY_VPS_LIVE_2,
              includeFormTracking: false,
              isFormTracking: false,
            }}
            onSubmit={updateNumbers}
          >
            {({ values, handleChange, dirty, submitForm, isSubmitting }) => (
              <>
                <div className="form-row">
                  <div className="name-label">{translateText('label', 'Destination')}</div>
                  <div className="value-wrapper">
                    <Checkbox
                      id="includeDestination"
                      checked={values.includeDestination}
                      onChange={handleChange}
                      disabled={values.includeFormTracking || isSubmitting}
                    />
                    <TextField
                      name="destination"
                      value={values.destination}
                      onChange={handleChange}
                      disabled={
                        values.includeFormTracking || !values.includeDestination || isSubmitting
                      }
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="name-label">{translateText('label', 'Fallback')}</div>
                  <div className="value-wrapper">
                    <Checkbox
                      id="includeFallback"
                      checked={values.includeFallback}
                      onChange={handleChange}
                      disabled={isSubmitting}
                    />
                    <Checkbox
                      id="isFallback"
                      checked={values.isFallback}
                      onChange={handleChange}
                      disabled={!values.includeFallback || isSubmitting}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="name-label">{translateText('label', 'SIP trunk')}</div>
                  <div className="value-wrapper">
                    <Checkbox
                      id="includeSipTrunk"
                      checked={values.includeSipTrunk}
                      onChange={handleChange}
                      disabled={isSubmitting}
                    />
                    <Select
                      name="sipTrunk"
                      value={values.sipTrunk}
                      onChange={handleChange}
                      disabled={!values.includeSipTrunk || isSubmitting}
                      options={{
                        '': '',
                        [SIP_TRUNK_DSTNY_PBX_LIVE]: 'Dstny Live (PBX)',
                        [SIP_TRUNK_DSTNY_PBX_TEST]: 'Dstny Test (PBX)',
                        [SIP_TRUNK_DSTNY_PBX_AD_CALLS]: 'Dstny AdCalls (PBX)',
                        [SIP_TRUNK_DSTNY_PBX_ELEVATE]: 'Dstny Elevate (PBX)',
                        [SIP_TRUNK_DSTNY_VPS_LIVE]: 'Dstny Live (VPS)',
                        [SIP_TRUNK_DSTNY_VPS_LIVE_2]: 'Dstny Live 2 (VPS)',
                        [SIP_TRUNK_DSTNY_PROBLEMEN_KWIJT]: 'Dstny Problemen Kwijt',
                        [SIP_TRUNK_DSTNY_VPS_TEST]: 'Dstny Test (VPS)',
                        [SIP_TRUNK_VOXBONE]: 'Voxbone',
                        [SIP_TRUNK_STEAM_CONNECT_PROD]: 'Steam-connect Prod',
                        [SIP_TRUNK_STEAM_CONNECT_LIVE]: 'Steam-connect Live (outbound)',
                        [SIP_TRUNK_STEAM_CONNECT_TEST]: 'Steam-connect Test',
                      }}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="name-label">{translateText('label', 'Form Tracking')}</div>
                  <div className="value-wrapper">
                    <Checkbox
                      id="includeFormTracking"
                      checked={values.includeFormTracking}
                      onChange={handleChange}
                      disabled={values.includeDestination || isSubmitting}
                    />
                    <Checkbox
                      id="isFormTracking"
                      checked={values.isFormTracking}
                      onChange={handleChange}
                      disabled={values.includeDestination || !values.isFormTracking || isSubmitting}
                    />
                  </div>
                </div>
                <div className="form-button">
                  <button
                    className="btn btn-lightblue"
                    disabled={!numbers || !dirty || isSubmitting}
                    onClick={submitForm}
                  >
                    {translateText('label', 'Update numbers')}
                  </button>
                </div>
              </>
            )}
          </Formik>
          <hr />
          <div className="form-row">
            <div className="name-label">{translateText('label', 'Use stop functions')}</div>
            <div className="value-wrapper">
              <Checkbox
                id="markAsStopped"
                checked={markNumbers}
                onChange={() => setMarkNumbers(!markNumbers)}
              />
            </div>
          </div>
          <div className="form-row">
            <Radio
              id="radio-delete"
              disabled={!markNumbers}
              label={translateText('label', 'Mark as cancelled at vendor')}
              checked={markType === 'delete'}
              onChange={() => setMarkType('delete')}
            />
            <Radio
              id="radio-stop"
              disabled={!markNumbers}
              label={translateText('label', 'Stop at vendor')}
              checked={markType === 'stop'}
              onChange={() => setMarkType('stop')}
            />
          </div>
          <div className="form-button left-button">
            <button
              className="btn btn-lightblue"
              disabled={!numbers || !markNumbers || isUpdatingStop || isUpdatingDelete}
              onClick={() => (markType === 'delete' ? onMarkAsDeleted() : onMarkAsStopped())}
            >
              {translateText('label', 'Mark')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
