import Select from 'components/input/Select';
import Skeleton from 'components/Skeleton';
import InputWrapper from 'components/slide-in/InputWrapper';
import { useHasIntlPlan } from 'hooks/access';
import { useAreas } from 'hooks/queries/area';
import { useCountries } from 'hooks/queries/country';
import { useTranslate } from 'hooks/translate';

type Props = {
  selectedCountry: number;
  selectedArea: number | null;
  setAreaCode: (country: number, area: number | null) => void;
  addTitles?: boolean;
  onlyShow?: boolean;
  countryError?: boolean | string;
  areaError?: boolean | string;
  disabled?: boolean;
  countryDisabled?: boolean;
  countryTooltip?: string;
  areaTooltip?: string;
  selectClassName?: string;
  canChangeCountry?: boolean;
};

export default function AreaCodeSelector({
  selectedCountry,
  selectedArea,
  setAreaCode,
  addTitles = false,
  onlyShow = false,
  countryError = false,
  areaError = false,
  disabled = false,
  countryDisabled = false,
  countryTooltip,
  areaTooltip,
  selectClassName,
  canChangeCountry,
}: Props) {
  const translateText = useTranslate();
  const isInternational = useHasIntlPlan();

  const { countries, isLoading: isLoadingCountries } = useCountries(!canChangeCountry);
  const { areas, isLoading: isLoadingAreas } = useAreas(selectedCountry);
  const isLoading = isLoadingCountries || isLoadingAreas;

  function updateAreaCode(key: 'country' | 'area', value: string | null) {
    if (value === 'select') value = null;
    setAreaCode(
      key === 'country' ? Number(value) : selectedCountry,
      key === 'area' ? Number(value) : null,
    );
  }

  let countrySelector = (
    <Select
      className={'country-selector' + (selectClassName ? ' ' + selectClassName : '')}
      prefix="+"
      value={selectedCountry}
      options={countries?.map(c => c.code) ?? []}
      onChange={e => updateAreaCode('country', e.target.value)}
      error={countryError}
      disabled={disabled || countryDisabled}
    />
  );

  let areaSelector = (
    <Select
      className={'area-selector' + (selectClassName ? ' ' + selectClassName : '')}
      prefix="0"
      value={selectedArea ?? 'select'}
      options={areas?.map(a => a.code) ?? []}
      onChange={e => updateAreaCode('area', e.target.value)}
      error={areaError}
      disabled={disabled}
      addSelect
    />
  );

  if (onlyShow || (!isInternational && !canChangeCountry)) {
    countrySelector = <span>{'+' + selectedCountry}</span>;
  }
  if (onlyShow) areaSelector = <span>{'0' + selectedArea}</span>;

  if (isLoading && !onlyShow) {
    countrySelector = <Skeleton containerClass="country-selector" height={38} />;
    areaSelector = <Skeleton containerClass="area-selector" height={38} />;
  }

  if (addTitles) {
    countrySelector = (
      <InputWrapper label={translateText('label', 'Country code')} tooltip={countryTooltip}>
        {countrySelector}
      </InputWrapper>
    );
    areaSelector = (
      <InputWrapper label={translateText('label', 'Area code')} tooltip={areaTooltip}>
        {areaSelector}
      </InputWrapper>
    );
  }

  return (
    <>
      {countrySelector}
      {areaSelector}
    </>
  );
}
