import { useState } from 'react';
import { Formik } from 'formik';

import ConfigurationCard from 'components/configuration/ConfigurationCard';
import GrayBars from 'components/GrayBars';
import Radio from 'components/input/Radio';
import Setup from 'components/slide-in/Setup';
import { IMPLEMENTATION_TYPE_ADVANCED, IMPLEMENTATION_TYPE_STANDARD } from 'globals/constants';
import { SUP_ART_TECHNICAL_IMPLEMENTATION, SUP_ART_URL } from 'globals/support';
import { useDomainAuth, useImplementation } from 'hooks/queries/domain';
import { useTranslate } from 'hooks/translate';

export default function TypeSection() {
  const translateText = useTranslate();
  const {
    implementation,
    isLoading: isLoadingImplementation,
    updateImplementation,
  } = useImplementation();
  const { isLoading: isLoadingDomainAuth } = useDomainAuth();
  const isLoading = isLoadingImplementation || isLoadingDomainAuth;
  const [showEdit, setShowEdit] = useState(false);

  function getTypeText() {
    switch (implementation?.type) {
      case IMPLEMENTATION_TYPE_STANDARD:
        return translateText('label', 'Standard implementation');
      case IMPLEMENTATION_TYPE_ADVANCED:
        return translateText('label', 'Advanced implementation');
      default:
        return translateText('label', 'Not set');
    }
  }

  async function save(values: {
    type: typeof IMPLEMENTATION_TYPE_ADVANCED | typeof IMPLEMENTATION_TYPE_STANDARD | undefined;
  }) {
    await updateImplementation(values.type!).then(() => setShowEdit(false));
  }

  return (
    <>
      <ConfigurationCard
        title={translateText('label', 'Step {x}: {label}', {
          x: 1,
          label: translateText('label', 'Choose an implementation method'),
        })}
        rightParagraph={translateText(
          'call-tracking',
          'You can choose from two different methods for the technical implementation of AdCalls. Read the instructions in the following <a href="{link}">support article</a> to determine which method works best.',
          { link: SUP_ART_URL + SUP_ART_TECHNICAL_IMPLEMENTATION },
        )}
        isLoading={isLoading}
        onEdit={() => setShowEdit(true)}
      >
        <GrayBars
          rows={[{ key: translateText('label', 'Method'), value: getTypeText() }]}
          isLoading={isLoading}
        />
      </ConfigurationCard>
      <Formik initialValues={{ type: implementation?.type }} onSubmit={save} enableReinitialize>
        {({ values, submitForm, resetForm, isSubmitting, dirty, setFieldValue }) => (
          <Setup
            show={showEdit}
            title={
              translateText('label', 'Step {x}: {label}', {
                x: 1,
                label: translateText('label', 'Choose an implementation method'),
              }) +
              ' (1/' +
              (values.type === IMPLEMENTATION_TYPE_STANDARD ? 3 : 2) +
              ')'
            }
            save={submitForm}
            isSaving={isSubmitting}
            close={() => setShowEdit(false)}
            afterClose={resetForm}
            maxWidth={440}
            hasChanges={dirty}
          >
            <p
              dangerouslySetInnerHTML={{
                __html: translateText(
                  'call-tracking',
                  'You can choose from two different methods for the technical implementation of AdCalls. Choose one of the two methods below. Read the instructions in the following <a href="{link}">support article</a> to determine which method works best for you.',
                  { link: SUP_ART_URL + SUP_ART_TECHNICAL_IMPLEMENTATION },
                ),
              }}
            />
            {implementation?.aens && Object.keys(implementation.aens).length >= 20 && (
              <p className="purple-text">
                {translateText(
                  'call-tracking',
                  'We recommend using the advanced implementation for this domain, because more than 20 locations are tracked.',
                )}
              </p>
            )}
            <div className="section">
              <Radio
                onChange={() => setFieldValue('type', IMPLEMENTATION_TYPE_STANDARD)}
                id="radio-standard"
                label={translateText('label', 'Standard implementation')}
                checked={values.type === IMPLEMENTATION_TYPE_STANDARD}
              />
              <Radio
                onChange={() => setFieldValue('type', IMPLEMENTATION_TYPE_ADVANCED)}
                id="radio-advanced"
                label={translateText('label', 'Advanced implementation')}
                checked={values.type === IMPLEMENTATION_TYPE_ADVANCED}
              />
            </div>
          </Setup>
        )}
      </Formik>
    </>
  );
}
