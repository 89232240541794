import moment, { Moment } from 'moment';
import { UAParser } from 'ua-parser-js';

import DetailsSection from 'components/DetailsSection';
import Skeleton from 'components/Skeleton';
import { TYPE_CALL_TRACKING } from 'globals/constants';
import { SUP_ART_CALL_RECORDING_CT, SUP_ART_CALL_RECORDING_FT, SUP_ART_URL } from 'globals/support';
import { ServiceType } from 'globals/types';
import { useCallRecordingFile } from 'hooks/queries/audio';
import { useCallRecordingSettings } from 'hooks/queries/domain-setting';
import { useTranslate } from 'hooks/translate';
import AudioPlayer from './AudioPlayer';

type Props = {
  type: ServiceType;
  itemId: number; // call or call request id
  isLoading: boolean;
  timeCalled: Moment | null;
};

export default function CallRecordingSection({ type, itemId, isLoading, timeCalled }: Props) {
  const translateText = useTranslate();
  const { callRecordingSettings } = useCallRecordingSettings();
  const { file, isLoading: isLoadingFile } = useCallRecordingFile(itemId, timeCalled);

  const supportArticle =
    SUP_ART_URL +
    (type === TYPE_CALL_TRACKING ? SUP_ART_CALL_RECORDING_CT : SUP_ART_CALL_RECORDING_FT);

  function renderAudio() {
    if (isLoading || isLoadingFile || timeCalled === null) {
      return <Skeleton />;
    } else if (file) {
      const browser = new UAParser().getBrowser().name;
      return (
        <>
          <AudioPlayer audioData={file} />
          {browser !== 'Chrome' && browser !== 'Edge' && browser !== 'Safari' && (
            <p className="margin-top">
              {translateText(
                'text',
                'Does your audio player not work properly? You can best listen to the calls in one of the following browsers: Google Chrome, Microsoft Edge or Safari.',
              )}
            </p>
          )}
        </>
      );
    } else if (timeCalled < moment().subtract(3, 'months')) {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: translateText(
              'text',
              'This recording is no longer available due to privacy guidelines (<a href="{link}">more information</a>).',
              { link: supportArticle },
            ),
          }}
        />
      );
    } else if (callRecordingSettings?.call_recording === false) {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: translateText(
              'text',
              'Call recording is disabled. Read <a href="{link}">here</a> how to enable call recording.',
              { link: supportArticle },
            ),
          }}
        />
      );
    } else {
      return <span>{translateText('text', 'No recording available.')}</span>;
    }
  }

  const tooltip =
    type === TYPE_CALL_TRACKING
      ? translateText('tooltip/call-tracking', 'Listen to the recording of this call here.')
      : translateText(
          'tooltip/form-tracking',
          'Listen to the recording of this call request here.',
        );

  return (
    <DetailsSection
      title={translateText('label', 'Call recording')}
      tooltip={tooltip}
      isLoading={isLoading}
    >
      {renderAudio()}
    </DetailsSection>
  );
}
