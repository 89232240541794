import { useMutation, useQuery } from '@tanstack/react-query';

import { useMessages } from 'contexts/Messages';
import { useSetTableData } from 'hooks/data';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';

export function useReleaseNotes(page: number, limit: number, method: string) {
  const { fetchData } = useFetch();

  const { data: response, isFetching: isLoading } = useQuery({
    queryKey: ['github', 'releaseNotes', method, page, limit],
    queryFn: (): Promise<{
      data: {
        name: string;
        data: Record<string, Record<string, string>>;
        sent_at: string | null;
        draft: 1 | 0;
      }[];
      headers: Headers;
    }> =>
      fetchData('/integration/git-hub/view', {
        queryParams: { method, page, limit },
        includeHeaders: true,
      }),
  });

  return {
    releaseNotes: response?.data ?? [],
    isLoading,
    pageCount: Number(response?.headers.get('x-pagination-page-count') ?? 0),
    itemCount: Number(response?.headers.get('x-pagination-total-count') ?? 1),
  };
}

export function useSendReleaseNotes() {
  const translateText = useTranslate();
  const setTableData = useSetTableData();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutate, isPending } = useMutation({
    mutationFn: ({
      name,
      method,
      isPreRelease,
    }: {
      name: string;
      method: string;
      isPreRelease: boolean;
    }) =>
      fetchData('/integration/git-hub/send', {
        method: 'POST',
        queryParams: { name, method, is_pre_release: isPreRelease ? 1 : 0 },
      }),
    onSuccess: (data, variables) => {
      setTableData(['github', 'releaseNotes', variables.method], oldData =>
        oldData.map(i => (i.name === variables.name ? { ...i, sent_at: 'sent' } : i)),
      );
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { sendRelease: mutate, isSending: isPending };
}
