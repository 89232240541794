import { Route, Routes } from 'react-router';

import { SearchBar } from 'components/knowledge-center/SearchBar';
import AuthenticatedRoute from 'components/routes/AuthenticatedRoute';
import { ROLE_ADMIN } from 'globals/constants';
import ArticlesWithoutCategories from 'pages/authenticated/knowledge-center/admin-overview/ArticlesWithoutCategories';
import ArticlesWithStatusArchived from 'pages/authenticated/knowledge-center/admin-overview/ArticlesWithStatusArchived';
import ArticlesWithStatusConcept from 'pages/authenticated/knowledge-center/admin-overview/ArticlesWithStatusConcept';
import CategoriesWithoutArticles from 'pages/authenticated/knowledge-center/admin-overview/CategoriesWithoutArticles';
import Article from 'pages/authenticated/knowledge-center/Article';
import ArticleEdit from 'pages/authenticated/knowledge-center/ArticleEdit';
import Category from 'pages/authenticated/knowledge-center/Category';
import CategoryEdit from 'pages/authenticated/knowledge-center/CategoryEdit';
import CategoryOverview from 'pages/authenticated/knowledge-center/CategoryOverview';
import CategoryOverviewEdit from 'pages/authenticated/knowledge-center/CategoryOverviewEdit';
import Overview from 'pages/authenticated/knowledge-center/Overview';
import OverviewEdit from 'pages/authenticated/knowledge-center/OverviewEdit';
import Error404 from 'pages/Error404';

export default function KnowledgeCenter() {
  return (
    <div className="knowledge-center-wrapper">
      <Routes>
        <Route path="/overview" element={<Overview />} />
        <Route
          path="/overview/edit"
          element={
            <AuthenticatedRoute role={ROLE_ADMIN}>
              <OverviewEdit />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/admin-overview/articles-without-categories"
          element={
            <AuthenticatedRoute role={ROLE_ADMIN}>
              <ArticlesWithoutCategories />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/admin-overview/categories-without-articles"
          element={
            <AuthenticatedRoute role={ROLE_ADMIN}>
              <CategoriesWithoutArticles />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/admin-overview/articles-with-status-concept"
          element={
            <AuthenticatedRoute role={ROLE_ADMIN}>
              <ArticlesWithStatusConcept />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/admin-overview/articles-with-status-archived"
          element={
            <AuthenticatedRoute role={ROLE_ADMIN}>
              <ArticlesWithStatusArchived />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/article/new"
          element={
            <AuthenticatedRoute role={ROLE_ADMIN}>
              <ArticleEdit type="new" />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/category/new"
          element={
            <AuthenticatedRoute role={ROLE_ADMIN}>
              <CategoryEdit type="new" />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/article/:article/edit"
          element={
            <AuthenticatedRoute role={ROLE_ADMIN}>
              <ArticleEdit type="edit" />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/:categoryType/edit"
          element={
            <AuthenticatedRoute role={ROLE_ADMIN}>
              <CategoryOverviewEdit />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/category/:category/edit"
          element={
            <AuthenticatedRoute role={ROLE_ADMIN}>
              <CategoryEdit type="edit" />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/:categoryType/category/:category/edit"
          element={
            <AuthenticatedRoute role={ROLE_ADMIN}>
              <CategoryEdit type="edit" />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/article/:article"
          element={
            <>
              <SearchBar />
              <Article />
            </>
          }
        />
        <Route
          path="/:categoryType"
          element={
            <>
              <SearchBar />
              <CategoryOverview />
            </>
          }
        />
        <Route
          path="/:categoryType/category/:category"
          element={
            <>
              <SearchBar />
              <Category />
            </>
          }
        />
        <Route path="/*" element={<Error404 />} />
      </Routes>
    </div>
  );
}
