import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useMessages } from 'contexts/Messages';
import { useTenant } from 'contexts/Tenant';
import { useSetTableData } from 'hooks/data';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';

export function useAcceptInvitation() {
  const queryClient = useQueryClient();
  const setTableData = useSetTableData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const { mutateAsync } = useMutation({
    mutationFn: (mcc: { id: string; invitation: string }) =>
      fetchData('/reseller-domain-invitation/accept', {
        method: 'POST',
        queryParams: { id: mcc.invitation },
      }),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['userMcc'] });
      queryClient.invalidateQueries({ queryKey: ['domains'] });
      setTableData(['domain', 'mcc', selectedDomain], oldData =>
        oldData.map(mcc => (mcc.id === variables.id ? { ...mcc, invitation: null } : mcc)),
      );
      addSuccessMessage(translateText('message', 'The invitation has been successfully accepted.'));
    },
  });

  return { acceptInvitation: mutateAsync };
}
