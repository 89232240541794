export default {
    "+ can only be in front of the number.": "+ mag alleen voor het nummer staan.",
    "<span data-private>{email}<\/span> is already in use.": "<span data-private>{email}<\/span> is al in gebruik.",
    "<span data-private>{value}<\/span> is not a valid email address.": "<span data-private>{value}<\/span> is geen geldig e-mailadres.",
    "A location with this name has already been requested.": "Een locatie met deze naam is al aangevraagd.",
    "Accept the 'AdCalls Agreement' to submit the request.": "Accepteer de 'Overeenkomst van AdCalls' om de aanvraag te kunnen verzenden.",
    "Accept the 'AdCalls Terms and Conditions' to submit the request.": "Accepteer de 'Algemene Voorwaarden van AdCalls' om de aanvraag te kunnen verzenden.",
    "Access for user <span data-private>{user}<\/span> has been denied.": "De toegang voor de gebruiker <span data-private>{user}<\/span> is ontzegd.",
    "Access has been successfully revoked from the customer center <span data-private>{name}<\/span>.": "De toegang van het klantcentrum <span data-private>{name}<\/span> is succesvol ingetrokken.",
    "Access has been successfully revoked from the domain <span data-private>{domain}<\/span>.": "De toegang van het domein <span data-private>{domain}<\/span> is succesvol ingetrokken.",
    "Access to the account could not be granted. Try again or contact us at support@adcalls.nl.": "Er kon geen toegang worden verleend tot het account. Probeer het opnieuw of neem contact met ons op via support@adcalls.nl.",
    "Add at least one condition": "Voeg minstens \u00e9\u00e9n conditie toe",
    "Add at least one email address.": "Voeg tenminste \u00e9\u00e9n e-mailadres toe.",
    "After the first x there can only be x's, dashes and spaces.": "Na de eerste x mogen er alleen nog x'en, streepjes en spaties volgen.",
    "All Chargebee invoices that were created between {daysLookedBack} days ago and now, are already synced.": "Alle Chargebee facturen die zijn aangemaakt tussen {daysLookedBack} dagen geleden en nu, zijn allemaal al gesynchroniseerd.",
    "An article can only be assigned to categories that have the same category type.": "Een artikel kan alleen aan categorie\u00ebn met dezelfde categorietype toegewezen worden.",
    "An email with the verification code has been sent to <span data-private>{email}<\/span>.": "Een e-mail met de verificatiecode is verstuurd naar <span data-private>{email}<\/span>.",
    "An invalid email address or password has been entered.": "Er is een ongeldig e-mailadres of wachtwoord ingevuld.",
    "An invalid password has been entered.": "Er is een ongeldig wachtwoord ingevuld.",
    "Are you sure you want to delete the last evaluation?": "Weet je zeker dat je de laatste beoordeling wilt verwijderen?",
    "Are you sure you want to delete this invoice profile?": "Weet je zeker dat je dit facturatieprofiel wilt verwijderen?",
    "Are you sure you want to delete this location?": "Weet je zeker dat je deze locatie wilt verwijderen?",
    "Are you sure you want to delete this {type}?": "Weet je zeker dat je deze {type} wilt verwijderen?",
    "Are you sure you want to disconnect your account?": "Weet je zeker dat je je account wilt ontkoppelen?",
    "Are you sure you want to enable this functionality right now?": "Weet je zeker dat je deze functionaliteit nu wilt inschakelen?",
    "Are you sure you want to include {domain} (id: {id}) in the upgrade?": "Weet je zeker dat je {domain} (id: {id}) niet meer wilt uitsluiten?",
    "Are you sure you want to make this change? Deleted locations cannot be undone.": "Weet je zeker dat je deze wijziging wilt doorvoeren? Verwijderde locaties kunnen niet ongedaan worden gemaakt.",
    "Are you sure you want to remove this conversion action?": "Weet je zeker dat je deze conversieactie wilt verwijderen?",
    "Are you sure you want to save this format? There are still points for attention:": "Weet je zeker dat je dit format wilt opslaan? Er zijn nog aandachtspunten:",
    "Are you sure you want to send the latest event to Google Analytics?": "Weet je zeker dat je de laatste gebeurtenis (event) wilt versturen naar Google Analytics?",
    "Are you sure you want to start this call request?": "Weet je zeker dat je dit terugbelverzoek wilt starten?",
    "Are you sure you want to stop this call request?": "Weet je zeker dat je dit terugbelverzoek wilt stoppen?",
    "Below you'll find a list of numbers that couldn't be found in AdCalls.": "Hieronder vind je een lijst van nummers die niet zijn gevonden in AdCalls.",
    "Below you'll find a list of numbers that have been stopped, but marking them as stopped in our system went wrong.": "Hieronder vind je een lijst van stopgezette nummers, waarbij het markeren als stopgezet is fout gegaan in ons systeem.",
    "Below you'll find a list of numbers that have been stopped:": "Hieronder vind je een lijst van nummers die zijn gestopt:",
    "Below you'll find a list of numbers that have not been stopped yet, because of the minimum duration:": "Hieronder vind je een lijst van nummers die nog niet gestopt konden worden, omdat de minimale looptijd nog niet is verstreken:",
    "Below you'll find a list of numbers that have not been stopped, because of an unknown reason at Voxbone.": "Hieronder vind je een lijst van nummers die niet gestopt konden worden, door een onbekende reden bij Voxbone.",
    "Below you'll find a list of numbers that have not been stopped, because the number couldn't be found in Voxbone.": "Hieronder vind je een lijst van nummers die niet gestopt konden worden, omdat deze niet gevonden zijn in Voxbone.",
    "Below you'll find a list of numbers that have not been stopped, because the number couldn't be stopped by the AdCalls guidelines.": "Hieronder vind je een lijst van nummers die niet zijn stopgezet, omdat het nog niet mag volgens de richtlijnen van AdCalls.",
    "Blacklist has been saved": "De zwarte lijst is opgeslagen",
    "Call details sent to: <span data-private>{email}<\/span>": "Oproepdetails verstuurd naar: <span data-private>{email}<\/span>",
    "Call request details sent to: <span data-private>{email}<\/span>": "Terugbelverzoekdetails verstuurd naar: <span data-private>{email}<\/span>",
    "Cancel domain change request?": "Wijzigingsverzoek annuleren?",
    "Changes saved": "Wijzigingen opgeslagen",
    "Copied to clipboard": "Gekopieerd naar klembord",
    "Country code has to be in front of area code.": "De landcode moet voor het netnummer staan.",
    "Current password must be filled in.": "Huidig wachtwoord moet ingevuld zijn.",
    "Data has been sent.": "Data is verstuurd.",
    "Enter the amount of seconds for the filter 'call duration'.": "Vul seconden in bij het filter \u2018gespreksduur\u2019.",
    "Error: {errorText}": "Foutmelding: {errorText}",
    "Evaluations must be unique.": "Beoordelingen moeten uniek zijn.",
    "File not found.": "Bestand niet gevonden.",
    "Fill in one or more email addresses.": "Vul \u00e9\u00e9n of meerdere e-mailadressen in.",
    "Filter deleted": "Filter verwijderd",
    "Filter saved": "Filter opgeslagen",
    "Format added": "Format toegevoegd",
    "In {days} days your subscription will become monthly terminable. Any discounts will expire. Extend your subscription or contact us for an extension proposal.": "Over {days} dagen wordt je abonnement maandelijks opzegbaar. Eventuele kortingen komen te vervallen. Verleng je abonnement of neem contact met ons op voor een verlengingsvoorstel.",
    "Invalid IP address": "Ongeldig IP-adres",
    "Invalid URL.": "Ongeldige URL.",
    "Invalid code.": "Ongeldige code.",
    "Invalid domain ID.": "Ongeldig domein-id.",
    "Invalid email address.": "Ongeldig e-mailadres.",
    "It is not possible to manage your own rights or remove yourself as a user.": "Het is niet mogelijk om je eigen rechten aan te passen of jezelf als gebruiker te verwijderen.",
    "JSON is not valid.": "JSON is niet geldig.",
    "No call requests have been selected.": "Er zijn geen terugbelverzoeken geselecteerd.",
    "No calls have been selected.": "Er zijn geen oproepen geselecteerd.",
    "No numbers have been selected.": "Er zijn geen nummers geselecteerd.",
    "Not a valid address.": "Geen geldig adres.",
    "Not all password conditions have been met": "Het wachtwoord voldoet niet aan alle condities",
    "Not all selected numbers are Voxbone numbers.": "Niet alle geselecteerde nummers zijn Voxbone nummers.",
    "Note added": "Notitie toegevoegd",
    "Note deleted": "Notitie verwijderd",
    "Note must be filled in.": "Notitie moet ingevuld zijn.",
    "Offsite phone number names must be unique.": "Offsite telefoonnummers benamingen moeten uniek zijn.",
    "Phone numbers have been added.": "Telefoonnummers zijn toegevoegd.",
    "Phone numbers have been ordered and added.": "Telefoonnummers zijn besteld en toegevoegd.",
    "Please note: this page does not contain the AdCalls JavaScript. The debug functionality cannot be used without the script. The cookies may still need to be accepted.": "Let op: deze pagina bevat niet het AdCalls JavaScript. De debugfunctionaliteit kan niet worden gebruikt zonder het script. Mogelijk moeten de cookies nog geaccepteerd worden.",
    "Please select at least one traffic source before proceeding.": "Selecteer minimaal \u00e9\u00e9n verkeersbron voordat je verder gaat.",
    "Remove note?": "Notitie verwijderen?",
    "Request at least one location or offsite phone number before proceeding.": "Vraag minstens \u00e9\u00e9n locatie of offsite telefoonnummer aan voordat je verder gaat.",
    "Script has been synced": "Script is gesynchroniseerd",
    "Scripts have been synced": "Scripts zijn gesynchroniseerd",
    "Select a method to show the release notes.": "Selecteer een methode om de release notes te bekijken",
    "Select a phone number format before proceeding.": "Selecteer een telefoonnummer format voordat je verder gaat.",
    "Select an attribution model or sync your conversion action.": "Selecteer een attributiemodel of synchroniseer je conversieactie.",
    "Select at least 1 location.": "Selecteer minimaal 1 locatie.",
    "Select at least 1 traffic source.": "Selecteer minimaal 1 verkeersbron.",
    "Select at least 1 traffic type.": "Selecteer minimaal 1 type verkeer.",
    "Select at least one domain that the user will be granted access to.": "Selecteer minimaal \u00e9\u00e9n domein waartoe de gebruiker toegang krijgt.",
    "Select at least one location that the user will be granted access to.": "Selecteer minimaal \u00e9\u00e9n locatie waartoe de gebruiker toegang krijgt.",
    "Something unexpected happened that prevented the authorization from completing.": "Er gebeurde iets onverwachts waardoor de autorisatie niet kon worden voltooid.",
    "Something went wrong, please try again later.": "Er ging iets mis, probeer het later opnieuw.",
    "Starting tomorrow, your subscription will become monthly terminable. Any discounts will expire. Extend your subscription or contact us for an extension proposal.": "Vanaf morgen is je abonnement maandelijks opzegbaar. Eventuele kortingen komen te vervallen. Verleng je abonnement of neem contact met ons op voor een verlengingsvoorstel.",
    "Stream deleted": "Stream verwijderd",
    "Stream saved": "Stream opgeslagen",
    "Sync all scripts": "Synchroniseer alle scripts",
    "Sync script for {domain}": "Synchroniseer script voor {domain}",
    "The API secret must be filled in.": "Het API-geheim moet ingevuld zijn.",
    "The Data Processor Agreement has been signed.": "De Verwerkersovereenkomst is ondertekend.",
    "The ID must be a numeric value.": "Het id moet een numerieke waarde zijn.",
    "The JavaScript has been published in Google Tag Manager.": "Het JavaScript is gepubliceerd in Google Tag Manager.",
    "The account verification has been cancelled. Please try to re-establish the link.": "De account-verificatie werd geannuleerd. Probeer de koppeling opnieuw tot stand te brengen.",
    "The area code is missing. This is a required element.": "Het netnummer ontbreekt. Dit is een verplicht element.",
    "The article has been added.": "Het artikel is toegevoegd.",
    "The call request has been stopped.": "Het terugbelverzoek is gestopt.",
    "The call request will be started at {date} around {time}.": "Het terugbelverzoek zal worden gestart op {date} rond {time}.",
    "The category has been added.": "De categorie is toegevoegd.",
    "The conversion action has been loaded successfully.": "De conversieactie is successvol ingeladen.",
    "The conversion action has been removed": "De conversieactie is verwijderd",
    "The conversion action with the name '{name}' could not be found. Place a new conversion action or try to load another one.": "De conversieactie met de naam '{name}' is niet gevonden. Plaats een nieuwe conversieactie of probeer een andere conversieactie in te laden.",
    "The conversion action's settings have been updated. Check the conversion action in your Google Ads account.": "De instellingen van de bestaande conversieactie zijn aangepast. Controleer de conversieactie in je Google Ads-account.",
    "The copy functionality is not supported by your browser": "De kopieer functionaliteit werkt niet in deze browser",
    "The customer ID provided is not in the correct format. The format is `xxx-xxx-xxxx` where the `x`s are all digits.": "Het klant-id is niet in het juiste format. Het format is `xxx-xxx-xxxx` waar de `x`-en cijfers zijn.",
    "The deletion of this domain has been scheduled.": "De verwijdering van dit domein is ingepland.",
    "The domain <span data-private>{domain}<\/span> (id: {id}) is not excluded anymore.": "Het domein <span data-private>{domain}<\/span> (id: {id}) is niet meer uitgesloten.",
    "The domain <span data-private>{domain}<\/span> has been upgraded to the Optimize package. Do you have any questions? Let us know via support@adcalls.nl.": "Het domein <span data-private>{domain}<\/span> is ge\u00fcpgraded naar het Optimize-pakket. Heb je nog vragen? Laat het ons weten via support@adcalls.nl.",
    "The domain has been invited to the customer center.": "Het domein is uitgenodigd voor het klantcentrum.",
    "The entry is invalid (desired format: {format}).": "De invoer is ongeldig (gewenst format: {format}).",
    "The evaluation has been successfully deleted.": "De beoordeling is verwijderd.",
    "The evaluation must be filled in.": "De beoordeling moet ingevuld zijn.",
    "The evaluation(s) are successfully deleted.": "De beoordeling(en) zijn succesvol verwijderd.",
    "The following numbers don't have a stop date: {numbers}": "De volgende nummers hebben geen stopzettingsdatum: {numbers}",
    "The implementation mail has been sent to <span data-private>{email}<\/span>": "De implementatie-mail is verstuurd naar <span data-private>{email}<\/span>.",
    "The intake is saved as a draft. Do not forget to send the intake.": "De intake is opgeslagen als concept. Vergeet de intake niet alsnog te verzenden.",
    "The invitation has been accepted.": "De uitnodiging is geaccepteerd.",
    "The invitation has been declined.": "De uitnodiging is geweigerd.",
    "The invitation has been successfully accepted.": "De uitnodiging is succesvol geaccepteerd.",
    "The invitation has been successfully revoked.": "De uitnodiging is succesvol ingetrokken.",
    "The invoice profile has been created.": "Het facturatieprofiel is aangemaakt.",
    "The invoice profile has been saved.": "Het facturatieprofiel is opgeslagen.",
    "The invoice profile has been successfully deleted.": "Het facturatieprofiel is succesvol verwijderd.",
    "The measurement ID must be filled in.": "Het metings-ID moet ingevuld zijn.",
    "The measurement ID should start with `G-`.": "Het metings-ID moet met `G-` beginnen.",
    "The name \"call\" should not be used because it is already used for the standard event.": "De benaming \"call\" mag niet worden gebruikt, omdat deze al gebruikt wordt voor de standaardgebeurtenis.",
    "The name cannot contain spaces.": "Benaming mag geen spaties bevatten.",
    "The name must be filled in.": "Benaming moet ingevuld zijn.",
    "The number can't start with this character: `{char}`.": "Het nummer mag niet met dit karakter starten: `{char}`.",
    "The number distribution has been changed.": "De nummeruitgifte is gewijzigd",
    "The number distribution has been {action}.": "De nummeruitgifte is {action}.",
    "The phone number format has been changed.": "Het telefoonnummer format is gewijzigd.",
    "The request for stopping the domain has been successfully canceled.": "De aanvraag voor het stopzetten van het domein is succesvol ingetrokken.",
    "The request is missing a required parameter, included an invalid parameter value, included a parameter value more than once, or is otherwise malformed.": "Het verzoek mist een vereiste parameter, bevatte een ongeldige parameterwaarde, bevatte een parameterwaarde meer dan een keer of is anderszins misvormd.",
    "The requested client is not registered or its credentials are invalid. Make sure you supplied the correct client ID and redirect URI.": "De gevraagde client is niet geregistreerd of de gegevens kloppen niet. Zorg ervoor dat de juiste client-ID en redirect URI zijn opgegeven.",
    "The requested grant type is not supported.": "Het aangevraagde grant type wordt niet ondersteund.",
    "The requested scope is invalid. We currently support the scopes listed below.": "De gevraagde scope is ongeldig. We ondersteunen momenteel de onderstaande scopes.",
    "The slug has been added.": "De slug is toegevoegd.",
    "The slug {slug} is marked as main slug.": "De slug {slug} is gemarkeerd als primaire slug.",
    "The value for must be equal to or bigger than 200.": "De waarde moet groter of gelijk zijn aan 200.",
    "The value must be between {min} and {max}.": "De waarde moet tussen {min} en {max} zijn.",
    "The {integrationName} account has been successfully disconnected.": "Het {integrationName} account is succesvol ontkoppeld.",
    "The {type} has been deleted.": "De {type} is verwijderd.",
    "There are dashes side by side.": "Er staan streepjes naast elkaar.",
    "There are more than 1 zeros before the area code.": "Er staan meerdere nullen voor het netnummer.",
    "There are more than 2 zeros before the country code.": "Er staan meer dan 2 nullen voor de landcode.",
    "There are no call evaluations.": "Er zijn geen oproepbeoordelingen.",
    "There aren't as many `(` as there are `)`.": "Er zijn niet evenveel `(` als `)`.",
    "There is already a filter for this IP address": "Er is al een filter met dit IP-adres.",
    "There is no `0` for the area code. Do you want to add it?": "Er staat geen `0` voor het netnummer. Wil je deze toevoegen?",
    "There is only 1 zero before the country code.": "Er is maar 1 nul voor de landcode.",
    "This field is required.": "Dit veld is verplicht.",
    "This value is already on the blacklist.": "Deze waarde staat al op de zwarte lijst.",
    "To use this functionality, you must first make settings in {integration}.": "Om gebruik te kunnen maken van deze functionaliteit moet je eerst instellingen verrichten in {integration}.",
    "Unknown error.": "Onbekende fout.",
    "Use the comment field for additional information about the later delivery of your intake.": "Gebruik het opmerkingenveld voor aanvullende informatie over de latere oplevering van je aanvraag.",
    "View the logs above the table for more information.": "Bekijk de logs boven de tabel voor meer informatie.",
    "Webhook has been saved.": "Webhook is opgeslagen.",
    "Within the {package} package, {sessions} sessions are included.": "Binnen het {package} pakket zijn er {sessions} sessies inbegrepen.",
    "You cannot manage your own settings for a customer center account.": "Je kunt niet je eigen instellingen van een klantcentrum account beheren.",
    "You cannot set the session stop below {sessions} because your package includes {sessions} sessions.": "Je kunt de sessie-stop niet onder de {sessions} instellen omdat er in je pakket {sessions} sessies zijn inbegrepen.",
    "You have completed the steps to set up the two-step verification.": "Je hebt de stappen voor het instellen van de tweestapsverificatie voltooid.",
    "You have unsaved changes! Are you sure you want to leave?": "Je hebt niet opgeslagen wijzigingen! Weet je zeker dat je deze pagina wilt verlaten?",
    "You need to authorize your account.": "Je moet je account autoriseren.",
    "You're trying to update a subscription that has already been delivered. Are you sure you want to update it?": "Je probeert een abonnement bij te werken dat al is opgeleverd. Weet je zeker dat je het bij wilt werken?",
    "Your details have been saved. You can now log in with your account.": "Je gegevens zijn opgeslagen, je kunt nu inloggen met je account.",
    "Your file is ready.": "Je bestand is gereed.",
    "Your format is too long.": "Je format is te lang.",
    "Your password has been changed. You can now login with your new password.": "Je wachtwoord is succesvol aangepast. Je kunt nu inloggen met het nieuwe wachtwoord.",
    "Your password is too old. To ensure account safety you are required to change your password.": "Je wachtwoord is te oud. Om de veiligheid van jouw account te garanderen, dien je je wachtwoord te wijzigen.",
    "Your subscription is monthly terminable. Extend your subscription or contact us for an attractive renewal proposal.": "Je abonnement is maandelijks opzegbaar. Verleng je abonnement of neem contact met ons op voor een aantrekkelijk verlengingsvoorstel.",
    "Your ticket has been sent to support@adcalls.nl": "Je ticket is verzonden naar support@adcalls.nl",
    "Your trial period ends <span class=\"counter low\">today<\/span>. Starting tomorrow you can use all the functionalities in the Optimize package.": "Je proefperiode loopt <span class=\"counter low\">vandaag<\/span> af. Vanaf morgen kun je gebruik maken van alle functionaliteiten in het Optimize-pakket.",
    "Your trial period will expire in <span class=\"{className}\">{amount}<\/span> {unit}. Upgrade to the Optimize package for full functionality.": "Je proefperiode loopt af over <span class=\"{className}\">{amount}<\/span> {unit}. Upgrade naar het Optimize-pakket voor volledige functionaliteit.",
    "Zero can only be in front of countrycode or areacode.": "Het cijfer \u20180\u2019 mag alleen voor de landcode of het netnummer staan.",
    "`(` and `)` are side by side.": "`(` en `)` staan naast elkaar.",
    "{amount} of {total} number(s) have been stopped at Voxbone.": "{amount} van {total} nummer(s) zijn stopgezet bij Voxbone.",
    "{fieldName} has a limit of {number} characters.": "{fieldName} kan maximaal {number} tekens bevatten.",
    "{fieldName} has a minimum of {number} characters.": "{fieldName} moet minimaal {number} tekens bevatten.",
    "{format} is already used in location {location}": "{format} wordt al gebruikt door locatie {location}.",
    "{invoices} {invoiceType} could not be synced that were created between {daysLookedBack} days ago and now. Check Graylog for more info.": "{invoices} {invoiceType} die zijn aangemaakt tussen {daysLookedBack} dagen geleden en nu, konden niet worden gesynchroniseerd. Zie Graylog voor meer informatie.",
    "{invoices} {invoiceType} were synced that were created between {daysLookedBack} days ago and now.": "{invoices} {invoiceType} die zijn aangemaakt tussen {daysLookedBack} dagen geleden en nu, zijn gesynchroniseerd."
}