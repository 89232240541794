import { useEffect, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';

import iconApi from 'assets/images/icons/integrations/adcalls-api.svg';
import CreateDeleteTable from 'components/CreateDeleteTable';
import ErrorFocus from 'components/ErrorFocus';
import Toggle from 'components/input/Toggle';
import HttpHeaderForm from 'components/integrations/adcalls-api/HttpHeaderForm';
import WebhookForm, {
  HookValues,
  initHookValues,
  WebhookFormValues,
} from 'components/integrations/adcalls-api/WebhookForm';
import CostParagraph from 'components/integrations/CostParagraph';
import Pagination from 'components/Pagination';
import Skeleton from 'components/Skeleton';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import SetupWithSub from 'components/slide-in/SetupWithSub';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import SupportParagraph from 'components/SupportParagraph';
import { useTenant } from 'contexts/Tenant';
import { INTEGRATION_API, PH_MASK, PLAN_ENTERPRISE } from 'globals/constants';
import { SUP_ART_API } from 'globals/support';
import { useHasAccessToIntegration } from 'hooks/access';
import { useTogglePaidIntegration } from 'hooks/integrations';
import { useApi, useRefreshToken } from 'hooks/queries/api-user';
import { useDeleteHook, useHooks, useSaveHook, useTestHook } from 'hooks/queries/hooks';
import { useTranslate } from 'hooks/translate';

export default function AdCallsApi({ show, close }: BaseSlideInProps) {
  const translateText = useTranslate();
  const { selectedDomain } = useTenant();

  const title = translateText('label', 'AdCalls API');
  const PRICE = 49 as const;

  const hasAccessToIntegration = useHasAccessToIntegration();
  const toggleEnabled = useTogglePaidIntegration(title, PRICE, false);

  const [showWebhookForm, setShowWebhookForm] = useState<HookValues | false>(false);
  const [showHeaderForm, setShowHeaderForm] = useState(false);
  const [hooksPage, setHooksPage] = useState(1);

  const { data, isLoading, updateApi } = useApi(show);
  const { hooks, isLoading: isLoadingHooks, pageCount, itemCount } = useHooks(hooksPage, show);
  const { testHook, isTesting } = useTestHook();
  const { saveHook, isSaving } = useSaveHook();
  const { deleteHook } = useDeleteHook();
  const { refreshToken, isRefreshing, isRefreshed } = useRefreshToken();

  useEffect(() => setHooksPage(1), [selectedDomain]);

  if (!hasAccessToIntegration(INTEGRATION_API)) return null;

  const webhookFormValidation = Yup.object({
    address: Yup.string().test(function (value) {
      if (showHeaderForm) return true; // Should not be validated when adding a header
      if (!value?.trim()) {
        return this.createError({ message: translateText('message', 'This field is required.') });
      }
      return /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)$/.test(
        value,
      )
        ? true
        : this.createError({ message: translateText('message', 'Not a valid address.') });
    }),
    authToken: Yup.string().test(function (value) {
      return showHeaderForm || !this.parent.authType || value?.trim() // Should not be validated when adding a header or authType is 0
        ? true
        : this.createError({ message: translateText('message', 'This field is required.') });
    }),
    headerKey: Yup.string().test({
      test: value => !showHeaderForm || !!value?.trim(), // Should only be validated when adding a header
      message: translateText('message', 'This field is required.'),
    }),
    headerValue: Yup.string().test({
      test: value => !showHeaderForm || !!value?.trim(), // Should only be validated when adding a header
      message: translateText('message', 'This field is required.'),
    }),
  });

  async function submitWebhookForm(
    values: WebhookFormValues,
    formikHelpers: FormikHelpers<WebhookFormValues>,
  ) {
    if (values.isTest) {
      await testHook(values).then(() => formikHelpers.setFieldValue('isTested', true));
    } else {
      await saveHook(values).then(() => setShowWebhookForm(false));
    }
  }

  return (
    <Formik
      initialValues={{ enabled: data?.enabled === '1' ?? false }}
      onSubmit={values => updateApi(values.enabled)}
      enableReinitialize
    >
      {({ values, setFieldValue, isSubmitting, submitForm, dirty, resetForm }) => (
        <Formik
          initialValues={{
            ...(showWebhookForm || initHookValues),
            headerKey: '',
            headerValue: '',
            isTest: false,
            isTested: false,
          }}
          onSubmit={submitWebhookForm}
          validationSchema={webhookFormValidation}
          validateOnChange={false}
          enableReinitialize
        >
          {({
            values: subValues,
            submitForm: submitSub,
            setFieldValue: setSubFieldValue,
            resetForm: resetSub,
            dirty: dirtySub,
            validateForm,
            setFieldError,
          }) => (
            <SetupWithSub
              show={show}
              title={title}
              icon={iconApi}
              close={close}
              afterClose={resetForm}
              save={submitForm}
              isSaving={isSubmitting}
              hasChanges={dirty}
              maxWidth={580}
              sub={
                <SetupWithSub
                  id="webhook"
                  show={showWebhookForm !== false}
                  close={() => setShowWebhookForm(false)}
                  afterClose={resetSub}
                  title={translateText('label', 'Add webhook')}
                  maxWidth={530}
                  save={() => {
                    setSubFieldValue('isTest', false, false);
                    submitSub();
                  }}
                  isSaving={isTesting || isSaving}
                  saveButtonDisabled={!subValues.isTested}
                  hasChanges={dirtySub}
                  sub={
                    <Setup
                      show={showHeaderForm}
                      close={() => setShowHeaderForm(false)}
                      afterClose={() => {
                        setSubFieldValue('headerKey', '');
                        setSubFieldValue('headerValue', '');
                        setFieldError('headerKey', undefined);
                        setFieldError('headerValue', undefined);
                      }}
                      title={translateText('label', 'Add HTTP header')}
                      save={async () => {
                        const errors = await validateForm();
                        if (isEmpty(errors)) {
                          setSubFieldValue('headers', [
                            ...subValues.headers,
                            { key: subValues.headerKey, value: subValues.headerValue },
                          ]);
                          setShowHeaderForm(false);
                          setSubFieldValue('headerKey', '');
                          setSubFieldValue('headerValue', '');
                        }
                      }}
                      maxWidth={480}
                    >
                      <HttpHeaderForm />
                    </Setup>
                  }
                >
                  <WebhookForm addHeader={() => setShowHeaderForm(true)} />
                </SetupWithSub>
              }
            >
              <ErrorFocus element={document.getElementById('webhook')} />
              <p>
                {isLoading ? (
                  <Skeleton count={3} />
                ) : (
                  translateText(
                    'integration',
                    'Using the AdCalls API you can make Call Tracking data available in external systems, including Analytics, Advertising and CRM software.',
                  )
                )}
              </p>
              <CostParagraph
                integration={title}
                price={PRICE}
                plan={PLAN_ENTERPRISE}
                isLoading={isLoading}
                checkNewPricingPlan={false}
              />
              <SupportParagraph articleId={SUP_ART_API} isLoading={isLoading} />
              <h3 className="section-title">
                {isLoading ? <Skeleton width={200} /> : translateText('label', 'Integration')}
              </h3>
              <InputWrapper label={title} isLoading={isLoading}>
                <Toggle
                  disabled={isSubmitting}
                  checked={values.enabled}
                  onClick={() => toggleEnabled(values.enabled, setFieldValue)}
                  isLoading={isLoading}
                />
              </InputWrapper>
              {!isLoading && data?.token && (
                <div className="token-wrapper">
                  <div className="token-label">
                    <span>{translateText('label', 'API token')}</span>
                    <button
                      onClick={() => refreshToken()}
                      disabled={isRefreshing || isRefreshed}
                      type="button"
                      className="btn btn-text no-padding"
                    >
                      {translateText('label', 'Refresh token')}
                    </button>
                  </div>
                  {isRefreshing ? (
                    <Skeleton height={60} />
                  ) : (
                    <pre className={`token code ${PH_MASK}`}>{data?.token}</pre>
                  )}
                </div>
              )}
              <div className="webhook-input">
                <h3 className="section-title">
                  {isLoading ? <Skeleton width={200} /> : translateText('label', 'Webhooks')}
                </h3>
                <p>
                  {isLoading ? (
                    <Skeleton count={2} />
                  ) : (
                    translateText(
                      'integration',
                      'Set up a webhook to send call or call request data to an endpoint.',
                    )
                  )}
                </p>
                {isLoading ? (
                  <Skeleton width={170} height={38} className="add-webhook" />
                ) : (
                  <button
                    className="btn btn-lightblue add-webhook"
                    onClick={() => setShowWebhookForm(initHookValues)}
                    disabled={isSubmitting}
                  >
                    {translateText('label', 'Add webhook')}
                    <div className="arrow arrow-right" />
                  </button>
                )}
                <InputWrapper
                  label={translateText('label', 'Added webhooks')}
                  isLoading={isLoading}
                >
                  {isLoading || isLoadingHooks ? (
                    <Skeleton count={!isLoading && pageCount > 1 ? 5 : 1} height={35} />
                  ) : (
                    <CreateDeleteTable
                      dataKeys={['address']}
                      privateKeys={['address']}
                      items={hooks}
                      onEdit={hook => setShowWebhookForm(hook)}
                      onDelete={hook => deleteHook(hook.id)}
                      emptyText={translateText('text', 'No webhooks have been configured.')}
                    />
                  )}
                </InputWrapper>
                {!isLoading && pageCount > 1 && (
                  <Pagination
                    activePage={hooksPage}
                    itemsCountPerPage={5}
                    totalItemsCount={itemCount}
                    totalPageCount={pageCount}
                    onChange={pageNumber => setHooksPage(pageNumber)}
                    hideLimitSelector
                  />
                )}
              </div>
            </SetupWithSub>
          )}
        </Formik>
      )}
    </Formik>
  );
}
