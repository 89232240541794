import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Toggle from 'components/input/Toggle';
import QueryTable from 'components/table/QueryTable';
import { useTranslate } from 'hooks/translate';
import {
  SIP_TRUNK_DSTNY_PBX_AD_CALLS,
  SIP_TRUNK_DSTNY_PBX_ELEVATE,
  SIP_TRUNK_DSTNY_PBX_LIVE,
  SIP_TRUNK_DSTNY_PBX_TEST,
  SIP_TRUNK_DSTNY_PROBLEMEN_KWIJT,
  SIP_TRUNK_DSTNY_VPS_LIVE,
  SIP_TRUNK_DSTNY_VPS_LIVE_2,
  SIP_TRUNK_DSTNY_VPS_TEST,
  SIP_TRUNK_STEAM_CONNECT_LIVE,
  SIP_TRUNK_STEAM_CONNECT_PROD,
  SIP_TRUNK_STEAM_CONNECT_TEST,
  SIP_TRUNK_VOXBONE,
} from '../../../../globals/constants';

export default function Overview() {
  const translateText = useTranslate();
  const navigate = useNavigate();
  const [onlyFreeNumbers, setOnlyFreeNumbers] = useState(false);
  return (
    <>
      <h1>{translateText('label', 'Numbers')}</h1>
      <div className="free-numbers-row">
        <span>{translateText('label', 'Only free numbers')}</span>
        <Toggle checked={onlyFreeNumbers} onClick={() => setOnlyFreeNumbers(!onlyFreeNumbers)} />
      </div>
      <QueryTable
        tableKey={['admin', 'number', 'overview']}
        columns={{
          id: { header: { name: translateText('label', 'ID'), sort: true }, search: true },
          domain_id: {
            header: { name: translateText('label', 'Domain ID'), sort: true },
            search: true,
            defaultSelected: true,
            noNumberFormat: true,
          },
          domain_title: {
            header: { name: translateText('label', 'Domain title'), sort: true },
            search: true,
            defaultSelected: true,
          },
          domain_url: {
            header: { name: translateText('label', 'Domain URL'), sort: true },
            search: true,
            defaultSelected: true,
          },
          number: {
            header: { name: translateText('label', 'Number'), sort: true },
            search: true,
            defaultSelected: true,
            noNumberFormat: true,
          },
          destination: {
            header: { name: translateText('label', 'Destination'), sort: true },
            search: true,
            defaultSelected: true,
            noNumberFormat: true,
          },
          is_fallback: {
            header: { name: translateText('label', 'Is fallback'), sort: true },
            search: true,
            forceBoolean: true,
            defaultSelected: true,
          },
          number_of_assignments: {
            header: { name: translateText('label', 'Number of assignments'), sort: true },
            defaultSelected: true,
          },
          call_count: { header: translateText('label', 'Call count'), defaultSelected: true },
          stop_requested_for: {
            header: { name: translateText('label', 'Stop requested for'), sort: true },
            search: true,
            date: true,
          },
          sip_trunk: {
            header: { name: translateText('label', 'SIP trunk'), sort: true },
            search: {
              [SIP_TRUNK_DSTNY_PBX_LIVE]: 'Dstny Live (PBX)',
              [SIP_TRUNK_DSTNY_PBX_TEST]: 'Dstny Test (PBX)',
              [SIP_TRUNK_DSTNY_PBX_AD_CALLS]: 'Dstny AdCalls (PBX)',
              [SIP_TRUNK_DSTNY_PBX_ELEVATE]: 'Dstny Elevate (PBX)',
              [SIP_TRUNK_DSTNY_VPS_LIVE]: 'Dstny Live (VPS)',
              [SIP_TRUNK_DSTNY_VPS_LIVE_2]: 'Dstny Live 2 (VPS)',
              [SIP_TRUNK_DSTNY_PROBLEMEN_KWIJT]: 'Dstny Problemen Kwijt',
              [SIP_TRUNK_DSTNY_VPS_TEST]: 'Dstny Test (VPS)',
              [SIP_TRUNK_VOXBONE]: 'Voxbone',
              [SIP_TRUNK_STEAM_CONNECT_PROD]: 'Steam-connect Prod',
              [SIP_TRUNK_STEAM_CONNECT_LIVE]: 'Steam-connect Live (outbound)',
              [SIP_TRUNK_STEAM_CONNECT_TEST]: 'Steam-connect Test',
            },
          },
        }}
        onRowClick={row => navigate('/admin/phone-numbers/overview/' + row.id)}
        endpoint="/number/index"
        pagination={{ defaultLimit: 20 }}
        filters={{
          domain: false,
          custom: {
            expand: 'number_of_assignments,conditions,stop_requested_for,call_count,sip_trunk',
            domain_id: onlyFreeNumbers ? 'NULL' : '',
          },
        }}
        canChooseColumns
        extraTopRight={
          <>
            <Link to="/admin/phone-numbers/new" className="btn btn-lightblue margin-right">
              {translateText('label', 'Add new phone numbers')}
            </Link>
            <Link to="/admin/phone-numbers/check" className="btn btn-lightblue margin-right">
              {translateText('label', 'Number check')}
            </Link>
          </>
        }
        refresh
      />
    </>
  );
}
