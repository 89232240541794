import {
  Dispatch,
  MouseEvent as ReactMouseEvent,
  MutableRefObject,
  SetStateAction,
  useRef,
} from 'react';
import { throttle } from 'lodash';

import resizeIcon from 'assets/images/icons/resize.svg';

type Props = {
  dimensions: { width: number; height: number };
  setDimensions: Dispatch<SetStateAction<{ width: number; height: number }>>;
  setIsResizing: Dispatch<SetStateAction<boolean>>;
  containerRef: MutableRefObject<HTMLDivElement | null>;
};

export default function Resizer({ dimensions, setDimensions, setIsResizing, containerRef }: Props) {
  const startPoints = useRef<{
    x: number;
    y: number;
    width: number;
    height: number;
    containerWidth: number;
  }>();

  function startDrag(event: ReactMouseEvent<HTMLImageElement>) {
    setIsResizing(true);
    startPoints.current = {
      x: event.clientX,
      y: event.clientY,
      ...dimensions,
      containerWidth: containerRef.current?.scrollWidth ?? 0,
    };
    document.addEventListener('mousemove', drag, false);
    document.addEventListener('mouseup', stopDrag, false);
  }

  function drag(event: MouseEvent) {
    if (!startPoints.current || !containerRef.current) return;

    const widthChange = event.clientX - startPoints.current.x;
    const startWidth = startPoints.current.width;
    // When the iframe is smaller than its container it will be centered in the container,
    // meaning width changes should be on both sides. We need a width correction for this.
    const widthCorrection = getWidthCorrection({
      widthChange,
      startWidth,
      containerWidth: containerRef.current.scrollWidth,
      containerStartWidth: startPoints.current.containerWidth,
    });

    const newWidth = startWidth + widthChange + widthCorrection;
    const newHeight = startPoints.current.height + event.clientY - startPoints.current.y;
    resize(getNewDimension(newWidth), getNewDimension(newHeight));
  }

  function stopDrag() {
    setIsResizing(false);
    resize.cancel();
    document.removeEventListener('mousemove', drag, false);
    document.removeEventListener('mouseup', stopDrag, false);
  }

  const resize = throttle((width: number, height: number) => setDimensions({ width, height }), 50);

  return (
    <img
      src={resizeIcon}
      alt="resize"
      className="resize"
      draggable={false}
      onMouseDown={startDrag}
    />
  );
}

function getNewDimension(dimension: number) {
  return Math.max(Math.round(dimension), 200);
}

function getWidthCorrection({
  widthChange,
  startWidth,
  containerWidth,
  containerStartWidth,
}: {
  widthChange: number;
  startWidth: number;
  containerWidth: number;
  containerStartWidth: number;
}) {
  const newWidth = startWidth + widthChange;

  if (startWidth < containerStartWidth) {
    // In this case the container starts with padding, so when the iframe is still smaller
    // than the container, the width change needs to be doubled, otherwise only the starting
    // padding needs to be added as a correction
    const containerPadding = (containerStartWidth - startWidth) / 2;
    return widthChange < containerPadding ? widthChange : containerPadding;
  } else if (newWidth < containerWidth) {
    // In this case the container started without padding but is made smaller so the difference
    // between the container and the width needs to be doubled
    return newWidth - containerWidth;
  }

  return 0;
}
