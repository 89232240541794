import { useMutation } from '@tanstack/react-query';

import { useMessages } from 'contexts/Messages';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';

export type SyncChargebeeInvoicesToExactResponse = {
  invoicesSynced: number;
  invoicesNotSynced: number;
  creditNotesSynced: number;
  creditNotesNotSynced: number;
  daysLookedBack: number;
};

export function useSyncChargebeeInvoicesToExact() {
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage, addErrorMessage } = useMessages();

  const { mutate, isPending } = useMutation({
    mutationFn: (): Promise<SyncChargebeeInvoicesToExactResponse> =>
      fetchData('/invoicing/sync-chargebee-invoices-to-exact', {
        method: 'POST',
      }),
    onSuccess: (data, variables) => {
      const {
        invoicesSynced,
        invoicesNotSynced,
        creditNotesSynced,
        creditNotesNotSynced,
        daysLookedBack,
      } = data;
      if (
        invoicesSynced === 0 &&
        invoicesNotSynced === 0 &&
        creditNotesSynced === 0 &&
        creditNotesNotSynced === 0
      ) {
        addSuccessMessage(
          translateText(
            'message',
            'All Chargebee invoices that were created between {daysLookedBack} days ago and now, are already synced.',
            { daysLookedBack },
          ),
        );
      } else {
        if (invoicesSynced > 0) {
          addSuccessMessage(
            translateText(
              'message',
              '{invoices} {invoiceType} were synced that were created between {daysLookedBack} days ago and now.',
              {
                invoices: invoicesSynced,
                invoiceType: translateText('label', 'Invoices').toLowerCase(),
                daysLookedBack,
              },
            ),
          );
        }
        if (invoicesNotSynced > 0) {
          addErrorMessage(
            translateText(
              'message',
              '{invoices} {invoiceType} could not be synced that were created between {daysLookedBack} days ago and now. Check Graylog for more info.',
              {
                invoices: invoicesNotSynced,
                invoiceType: translateText('label', 'Invoices').toLowerCase(),
                daysLookedBack,
              },
            ),
          );
        }
        if (creditNotesSynced > 0) {
          addSuccessMessage(
            translateText(
              'message',
              '{invoices} {invoiceType} were synced that were created between {daysLookedBack} days ago and now.',
              {
                invoices: creditNotesSynced,
                invoiceType: translateText('label', 'Credit notes').toLowerCase(),
                daysLookedBack,
              },
            ),
          );
        }
        if (creditNotesNotSynced > 0) {
          addErrorMessage(
            translateText(
              'message',
              '{invoices} {invoiceType} could not be synced that were created between {daysLookedBack} days ago and now. Check Graylog for more info.',
              {
                invoices: creditNotesNotSynced,
                invoiceType: translateText('label', 'Credit notes').toLowerCase(),
                daysLookedBack,
              },
            ),
          );
        }
      }
    },
  });

  return { syncChargbeeInvoicesToExact: mutate, isLoading: isPending };
}
