import { Formik, FormikHelpers } from 'formik';

import ErrorFocus from 'components/ErrorFocus';
import TextField from 'components/input/TextField';
import PasswordValidator from 'components/PasswordValidator';
import InputWrapper from 'components/slide-in/InputWrapper';
import { useMessages } from 'contexts/Messages';
import { useUpdatePassword } from 'hooks/queries/user';
import { useTranslate } from 'hooks/translate';
import { useValidationSchema } from 'hooks/validation';

type FormValues = {
  password: string;
  newPassword: string;
  newPasswordVerification: string;
  conditionsPassed: boolean;
};

type Props = {
  email: string;
  onSuccess: () => void;
};

export default function UpdatePassword({ email, onSuccess }: Props) {
  const translateText = useTranslate();
  const { addErrorMessage } = useMessages();
  const { updatePasswordValidation } = useValidationSchema();
  const { updatePassword } = useUpdatePassword();

  async function save(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    await updatePassword({ email, ...values })
      .then(onSuccess)
      .catch(response => {
        if (response.error.password) {
          formikHelpers.setFieldError('newPassword', response.error.password[0]);
        } else if (response.status !== 401) {
          addErrorMessage(response.error);
        }
      });
  }

  return (
    <Formik
      initialValues={{
        password: '',
        newPassword: '',
        newPasswordVerification: '',
        conditionsPassed: false,
      }}
      onSubmit={save}
      validationSchema={updatePasswordValidation}
      validateOnChange={false}
    >
      {({ values, handleChange, setFieldValue, handleSubmit, errors, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <h1>{translateText('label', 'Password needs to be updated')}</h1>
          <div className="align-left margin-bottom">
            {translateText(
              'message',
              'Your password is too old. To ensure account safety you are required to change your password.',
            )}
          </div>
          <ErrorFocus />
          <InputWrapper label={translateText('label', 'Current password')}>
            <TextField
              name="password"
              type="password"
              wrapperClassName="margin-bottom"
              onChange={handleChange}
              value={values.password}
              error={errors.password}
            />
          </InputWrapper>
          <PasswordValidator
            password={values.newPassword}
            passwordVerification={values.newPasswordVerification}
            setPassword={value => setFieldValue('newPassword', value)}
            setPasswordVerification={value => setFieldValue('newPasswordVerification', value)}
            setConditionsPassed={value => setFieldValue('conditionsPassed', value)}
            error={errors.conditionsPassed ?? errors.newPassword}
            label={translateText('label', 'New password')}
            className="margin-bottom"
          />
          <button
            className="btn btn-green btn-wide margin-top"
            type="submit"
            disabled={isSubmitting}
          >
            {translateText('label', 'Save')}
          </button>
        </form>
      )}
    </Formik>
  );
}
