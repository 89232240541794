import { SUP_ART_URL } from 'globals/support';
import { useTranslate } from 'hooks/translate';
import Skeleton from './Skeleton';

type BaseProps = {
  extraText?: string;
  isLoading?: boolean;
  skeletonCount?: number;
};

type PropsWithArticleId = {
  articleId: number;
  customLink?: never;
} & BaseProps;

type PropsWithCustomLink = {
  articleId?: never;
  customLink: string;
} & BaseProps;

export default function SupportParagraph({
  articleId,
  extraText,
  isLoading,
  skeletonCount,
  customLink,
}: PropsWithArticleId | PropsWithCustomLink) {
  const translateText = useTranslate();

  if (isLoading) {
    return (
      <p>
        <Skeleton count={skeletonCount} />
      </p>
    );
  }

  let text = translateText(
    'knowledge-center',
    'For more information, see the following <a href="{link}">support article</a>.',
    { link: customLink ?? SUP_ART_URL + articleId },
  );
  if (extraText) text = extraText + ' ' + text;

  return <p dangerouslySetInnerHTML={{ __html: text }} />;
}
