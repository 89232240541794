import { PropsWithChildren, ReactNode, useRef, useState } from 'react';
import { Dropdown, NavDropdown } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router';
import { cloneDeep } from 'lodash';

import TextField from 'components/input/TextField';
import Skeleton from 'components/Skeleton';
import { useRoutes } from 'contexts/Routes';
import { useSession } from 'contexts/Session';
import { useTenant } from 'contexts/Tenant';
import { PH_MASK } from 'globals/constants';
import { Domain } from 'globals/types';
import { useEventListener, useOnClickOutside } from 'hooks/event';
import { useUserMcc } from 'hooks/queries/user';
import { useTranslate } from 'hooks/translate';

type FirstFound = { type: 'mcc'; id: number } | { type: 'domain'; id: number; mcc: number } | false;

export default function DomainSelector() {
  const translateText = useTranslate();
  const location = useLocation();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const { token } = useSession();
  const { navigationDisabled } = useRoutes();
  const { selectedMcc, setSelectedDomain, setSelectedMcc, selectedMccData, selectedDomainData } =
    useTenant();

  const [searchText, setSearchText] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);

  useOnClickOutside(dropdownRef, () => setShowDropdown(false));
  useEventListener('keydown', e => handleShortcut(e as KeyboardEvent));

  const { mcc, isLoading, isError } = useUserMcc();
  if (isError || !token || navigationDisabled) return null;

  function handleShortcut(e: KeyboardEvent) {
    if (e.key === 'ArrowUp') arrowPressed('up', e);
    if (e.key === 'ArrowDown') arrowPressed('down', e);
  }

  function arrowPressed(direction: 'up' | 'down', e: KeyboardEvent) {
    e.preventDefault();
    const elements = document.getElementsByClassName('dropdown-item');
    const options = Array.prototype.slice.call(elements);
    const currentIndex = options.indexOf(e.target);
    if (direction === 'up' && currentIndex <= 0) {
      (elements[elements.length - 1] as HTMLElement)?.focus();
    } else if (direction === 'up' && currentIndex - 1 >= 0) {
      (elements[currentIndex - 1] as HTMLElement)?.focus();
    } else if (direction === 'down' && currentIndex + 1 >= elements.length) {
      (elements[0] as HTMLElement)?.focus();
    } else if (direction === 'down' && currentIndex + 1 < elements.length) {
      (elements[currentIndex + 1] as HTMLElement)?.focus();
    }
  }

  function selectDomain(domainId: number, mccId: number) {
    setSearchText('');
    setSelectedDomain(Number(domainId));
    setSelectedMcc(mccId);
    setShowDropdown(false);
  }

  function selectMcc(mccId: number) {
    setSearchText('');
    setSelectedMcc(mccId);
    setSelectedDomain(null);
    if (location.pathname !== '/overview') navigate('/overview');
    setShowDropdown(false);
  }

  function searchKeyPressed(e: React.KeyboardEvent, firstFound: FirstFound) {
    if (!firstFound || e.key !== 'Enter') return;

    if (firstFound.type === 'mcc') selectMcc(firstFound.id);
    else selectDomain(firstFound.id, firstFound.mcc);
  }

  let firstFound: FirstFound = false;
  let mccArray = cloneDeep(mcc ?? []);

  if (searchText !== '') {
    mccArray = mccArray.filter(mcc => {
      const name = mcc.name ?? '';
      const hasOwnMatch = name.toLowerCase().includes(searchText.toLowerCase());
      if (hasOwnMatch && firstFound === false) {
        firstFound = { type: 'mcc', id: mcc.id };
      }
      mcc.domains = mcc.domains.filter((domain: Domain) => {
        const hasDomainMatch = domain.name.toLowerCase().includes(searchText.toLowerCase());
        if (hasDomainMatch && firstFound === false) {
          firstFound = { type: 'domain', id: domain.id, mcc: mcc.id };
        }
        return hasDomainMatch;
      });
      return hasOwnMatch || mcc.domains.length > 0;
    });
  }

  if (!firstFound) {
    for (let i = 0; i < (mccArray?.length || 0); i++) {
      if (mccArray[i].domains?.[0] !== undefined) {
        firstFound = { type: 'domain', id: mccArray[i].domains[0].id, mcc: mccArray[i].id };
        break;
      }
    }
  }

  let displayName = translateText('label', 'No domain selected');
  if (selectedDomainData) displayName = selectedDomainData.name;
  else if (selectedMcc !== -1 && selectedMccData) displayName = selectedMccData.name;

  const selectableDomains: ReactNode[] = Object.values(mccArray).map(mcc => [
    <li className="item" key={'mcc' + mcc.id}>
      <Dropdown.Item data-id={mcc.id} onClick={() => selectMcc(mcc.id)}>
        {mcc.name}
      </Dropdown.Item>
    </li>,
    mcc.domains?.map(domain => (
      <li className="sub item" key={'domain' + domain.id}>
        <Dropdown.Item
          data-id={domain.id}
          title={domain.name}
          onClick={() => selectDomain(domain.id, mcc.id)}
        >
          {domain.name}
        </Dropdown.Item>
      </li>
    )),
  ]);

  if (selectableDomains.length === 0) {
    selectableDomains.push(
      <li className="item" key="no-domain">
        <div className="no-data">{translateText('label', 'No domains found')}</div>
      </li>,
    );
  }

  return (
    <NavDropdown
      ref={dropdownRef}
      show={showDropdown}
      id="domain-selector-dropdown"
      className={`domain-selector-wrapper ${PH_MASK}`}
      focusFirstItemOnShow
      title={
        <div className="domain-selector" onClick={() => setShowDropdown(show => !show)}>
          <div className="domain-title" title={displayName}>
            {displayName}
          </div>
          <div className="dropdown-arrow-wrapper">
            <div className="arrow arrow-down" />
          </div>
        </div>
      }
    >
      <TextField
        id="search-bar"
        className="dropdown-item"
        value={searchText}
        placeholder={translateText('label', 'Search') + '..'}
        onChange={e => setSearchText(e.target.value)}
        onKeyDown={e => searchKeyPressed(e, firstFound)}
        autoComplete="off"
      />
      {isLoading ? (
        <Skeleton
          count={5}
          wrapper={({ children }: PropsWithChildren) => (
            <li className="item">
              <div className="dropdown-item">{children}</div>
            </li>
          )}
          hasFade
        />
      ) : (
        selectableDomains
      )}
    </NavDropdown>
  );
}
