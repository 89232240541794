import { Link } from 'react-router-dom';

import logoutIcon from 'assets/images/icons/logout.svg';
import Skeleton from 'components/Skeleton';
import { clearSession } from 'contexts/Session';
import { PH_MASK } from 'globals/constants';
import { useLogout } from 'hooks/queries/auth';
import { useUser } from 'hooks/queries/user';
import { useTranslate } from 'hooks/translate';
import UserAvatar from './UserAvatar';

type Props = {
  toggleDisplay?: () => void;
  hasError?: boolean;
  hasWhiteSkeleton?: boolean;
};

export default function UserData({
  toggleDisplay,
  hasError = false,
  hasWhiteSkeleton = true,
}: Props) {
  const translateText = useTranslate();
  const { logOut } = useLogout();

  const { user, isError } = useUser();
  if (isError) return null;

  const logoutButtonContent = (
    <>
      <img alt={translateText('label', 'Log out')} className="logout" src={logoutIcon} />{' '}
      <div className="logout-text">{translateText('label', 'Log out')}</div>
    </>
  );

  if (hasError)
    return (
      <div
        className="logout-wrapper"
        onClick={() => {
          localStorage.removeItem('rememberMe');
          clearSession();
          window.location.reload();
        }}
      >
        {logoutButtonContent}
      </div>
    );

  let arrow,
    avatar = <UserAvatar />;
  if (toggleDisplay) {
    arrow = (
      <div className="arrow-wrapper" onClick={toggleDisplay}>
        <div className="arrow arrow-right" />
      </div>
    );
    avatar = (
      <Link onClick={toggleDisplay} to="/account/my-account">
        {avatar}
      </Link>
    );
  }

  const userName = user ? user.first_name + ' ' + user.last_name : null;
  const userContact = user ? user.email + (user.company ? ' - ' + user?.company : '') : null;
  return (
    <div className="user-data">
      <div className="left-side-wrapper">
        {arrow}
        <div className="avatar-wrapper">{avatar}</div>
        <div className={`user-details ${PH_MASK}`}>
          <div className="full-name">
            {userName ?? <Skeleton width={100} isWhite={hasWhiteSkeleton} />}
          </div>
          <div className="email-partner">
            {userContact ?? <Skeleton width={80} isWhite={hasWhiteSkeleton} noMargin />}
          </div>
        </div>
      </div>
      <div
        className="logout-wrapper"
        onClick={() => {
          localStorage.removeItem('rememberMe');
          logOut();
        }}
      >
        {!user ? <Skeleton isWhite={hasWhiteSkeleton} width={50} /> : logoutButtonContent}
      </div>
    </div>
  );
}
