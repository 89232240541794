import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import fileDownload from 'js-file-download';
import moment from 'moment';

import { useMessages } from 'contexts/Messages';
import { useTenant } from 'contexts/Tenant';
import {
  LOCATION_STATUS_ACTIVE,
  LOCATION_STATUS_IN_REQUEST,
  LOCATION_STATUS_INACTIVE,
} from 'globals/constants';
import { Offsite } from 'globals/types';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';
import { useUserDateFormat } from 'hooks/user';

export type Location = {
  aen_identifier: string;
  destination: string;
  included?: 0 | 1;
  name: string;
  netnumber?: string;
  area?: number | null;
  country?: number;
  offsite: Offsite[];
  status?:
    | typeof LOCATION_STATUS_ACTIVE
    | typeof LOCATION_STATUS_INACTIVE
    | typeof LOCATION_STATUS_IN_REQUEST;
  active?: 0 | 1;
  stop_requested_for?: string | null;
  tracking_type: string;
};

export function useLocations(params: Record<string, unknown> = {}, enabled = true) {
  const { fetchData } = useFetch();
  const { selectedDomain: domain } = useTenant();

  const { data: response, isFetching } = useQuery({
    queryKey: ['locations', 'index', domain, params],
    queryFn: (): Promise<{ data: Location[]; headers: Headers }> =>
      fetchData('/aen/index', {
        queryParams: { domain, ...params },
        includeHeaders: true,
      }),
    enabled: domain !== null && enabled,
  });

  return {
    locations: response?.data,
    isLoading: isFetching,
    pageCount: Number(response?.headers.get('x-pagination-page-count') ?? 1),
    itemCount: Number(response?.headers.get('x-pagination-total-count') ?? 0),
  };
}

export function useCreateLocation() {
  const queryClient = useQueryClient();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain: domain } = useTenant();

  const { mutateAsync } = useMutation({
    mutationFn: (location: {
      name: string;
      destination: string;
      net_number: string;
      offsitesRequest: Offsite[];
    }) =>
      fetchData('/aen/create', {
        method: 'POST',
        queryParams: { domain },
        bodyParams: location,
      }),
    onSuccess: data => {
      if (data.message) addSuccessMessage(data.message);
      queryClient.invalidateQueries({ queryKey: ['locations'] });
      queryClient.invalidateQueries({ queryKey: ['pendingChanges'] });
      queryClient.invalidateQueries({ queryKey: ['changeRequests'] });
    },
  });

  return { createLocation: mutateAsync };
}

export function useDeleteLocation() {
  const translateText = useTranslate();
  const queryClient = useQueryClient();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const { mutateAsync } = useMutation({
    mutationFn: (identifier: string) =>
      fetchData('/aen/delete', {
        method: 'DELETE',
        queryParams: { domain_id: selectedDomain, identifier },
      }),
    onSuccess: data => {
      addSuccessMessage(data.message);
      queryClient.invalidateQueries({ queryKey: ['pendingChanges'] });
      queryClient.invalidateQueries({ queryKey: ['changeRequests'] });
    },
  });

  async function deleteLocation(identifier: string) {
    if (
      window.confirm(
        translateText(
          'message',
          'Are you sure you want to make this change? Deleted locations cannot be undone.',
        ),
      )
    ) {
      await mutateAsync(identifier);
    }
  }

  return { deleteLocation };
}

export function useExportLocations() {
  const { selectedDomain: domain } = useTenant();
  const { getFetchConfig } = useFetch();
  const dateFormat = useUserDateFormat();

  const { query, config } = getFetchConfig('/aen/export', {
    queryParams: { domain },
  });

  const { mutateAsync, isPending } = useMutation({
    mutationFn: () => fetch(query, config),
    onSuccess: async response => {
      const data = await response.blob();
      const date = moment().format(dateFormat);
      fileDownload(data, 'locations_' + date + '.csv', 'text/csv');
    },
  });

  return { exportLocations: mutateAsync, isExporting: isPending };
}
