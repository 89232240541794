import { useRef, useState } from 'react';
import { useNavigate } from 'react-router';

import AddButton from 'components/buttons/AddButton';
import Skeleton from 'components/Skeleton';
import { useTenant } from 'contexts/Tenant';
import { useHasMccAdminAccess } from 'hooks/access';
import { useOnClickOutside } from 'hooks/event';
import { useTranslate } from 'hooks/translate';
import InviteDomain from './InviteDomain';

type Props = {
  isLoading: boolean;
};

export default function AddDomain({ isLoading }: Props) {
  const navigate = useNavigate();
  const translateText = useTranslate();
  const isMccAdmin = useHasMccAdminAccess();
  const { selectedMcc } = useTenant();

  const [showInviteDomain, setShowInviteDomain] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  useOnClickOutside(dropdownRef, () => setShowDropdown(false));

  if (isLoading) return <Skeleton width={35} height={35} />;

  if (selectedMcc === -1 || selectedMcc === null || !isMccAdmin) {
    return (
      <AddButton
        onClick={() => navigate('/requests/intake')}
        tooltipText={translateText(
          'tooltip/domain-overview',
          'Request a new domain for the AdCalls services here.',
        )}
      />
    );
  }

  return (
    <>
      <div className="add-domain-wrapper" ref={dropdownRef}>
        <AddButton
          onClick={() => setShowDropdown(open => !open)}
          tooltipPlacement="right"
          tooltipText={translateText(
            'tooltip/domain-overview',
            'Request a new domain here or invite an active domain.',
          )}
        />
        {showDropdown && (
          <div className="add-domain-dropdown">
            <div onClick={() => navigate('/requests/intake')}>
              {translateText('label', 'New domain')}
            </div>
            <div
              onClick={() => {
                setShowInviteDomain(true);
                setShowDropdown(false);
              }}
            >
              {translateText('label', 'Invite domain')}
            </div>
          </div>
        )}
      </div>
      <InviteDomain show={showInviteDomain} close={() => setShowInviteDomain(false)} />
    </>
  );
}
