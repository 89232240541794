import { PropsWithChildren } from 'react';

type Props = {
  title: string;
  className?: string;
};

export default function SettingsPage({ title, className, children }: PropsWithChildren<Props>) {
  return (
    <div className={'settings-wrapper' + (className ? ' ' + className : '')}>
      <h1>{title}</h1>
      {children}
    </div>
  );
}
