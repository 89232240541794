import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import DetailsSection from 'components/DetailsSection';
import Select from 'components/input/Select';
import Skeleton from 'components/Skeleton';
import QueryTable from 'components/table/QueryTable';
import Tooltip from 'components/Tooltip';
import { TYPE_CALL_TRACKING, TYPE_FORM_TRACKING } from 'globals/constants';
import { ServiceType } from 'globals/types';
import { useCall } from 'hooks/queries/call';
import { useCallRequest } from 'hooks/queries/call-request';
import { useGoogleAnalyticsSettings } from 'hooks/queries/domain-setting';
import { useEvaluationOptions, useUpdateEvaluation } from 'hooks/queries/evaluation';
import { useTranslate } from 'hooks/translate';
import EvaluationValueInput from './EvaluationValueInput';

type FormValues = {
  evaluation: string;
  value: string | number;
  analyticsPush: boolean;
};

type Props = {
  type: ServiceType;
  itemId: number;
  isLoading: boolean;
};

export default function EvaluateSection({ type, itemId, isLoading }: Props) {
  const translateText = useTranslate();
  const { call } = useCall(type === TYPE_CALL_TRACKING && !isNaN(itemId) ? itemId : null);
  const { callRequest } = useCallRequest(
    type === TYPE_FORM_TRACKING && !isNaN(itemId) ? itemId : null,
  );

  const item = type === TYPE_CALL_TRACKING ? call : callRequest;

  const { googleAnalyticsSettings } = useGoogleAnalyticsSettings();
  const { evaluationOptions } = useEvaluationOptions({ domain: item?.domain_id ?? null });
  const { updateEvaluation, deleteEvaluation } = useUpdateEvaluation(type);

  async function changeEvaluation(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    if (values.evaluation === '') {
      if (
        window.confirm(
          translateText('message', 'Are you sure you want to delete the last evaluation?'),
        )
      ) {
        await deleteEvaluation(itemId).then(() => formikHelpers.resetForm());
      }
    } else {
      if (values.analyticsPush) {
        if (
          !window.confirm(
            translateText(
              'message',
              'Are you sure you want to send the latest event to Google Analytics?',
            ),
          )
        ) {
          return;
        }
      }

      const value = String(values.value).replace(',', '.');
      const parsedValue = parseFloat(value);

      await updateEvaluation({
        itemId,
        evaluation: values.evaluation,
        value: !isNaN(parsedValue) ? parsedValue.toFixed(2) : value,
        analyticsPush: values.analyticsPush,
      }).then(() => formikHelpers.resetForm());
    }
  }

  const validationSchema = Yup.object({
    evaluation: Yup.string().notOneOf(
      ['select'],
      translateText('message', 'The evaluation must be filled in.'),
    ),
  });

  return (
    <DetailsSection
      title={
        type === TYPE_CALL_TRACKING
          ? translateText('label', 'Evaluate call')
          : translateText('label', 'Evaluate call request')
      }
      tooltip={
        type === TYPE_CALL_TRACKING
          ? translateText('tooltip/call-tracking', 'Change the evaluation of the call here.')
          : translateText(
              'tooltip/form-tracking',
              'Change the evaluation of the call request here.',
            )
      }
      isLoading={isLoading}
    >
      <Formik<FormValues>
        initialValues={{ value: '', evaluation: 'select', analyticsPush: false }}
        onSubmit={changeEvaluation}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, submitForm, isSubmitting, errors, submitCount }) => {
          const optionsObject: Record<string, string> = {
            'select': translateText('label', 'Select evaluation'),
            '': translateText('label', 'Delete evaluation'),
          };
          if (evaluationOptions) {
            for (const evaluationType of evaluationOptions) {
              optionsObject[evaluationType] = evaluationType;
            }
          }

          let valueInputClass = 'input-row';
          if (['', 'select'].includes(values.evaluation)) valueInputClass += ' invisible';

          return (
            <div className="input-container">
              <div className="input-row">
                <div className="input-label">
                  {isLoading ? <Skeleton width={150} /> : translateText('label', 'Evaluation')}
                </div>
                <div className="input">
                  {isLoading ? (
                    <Skeleton height={38} />
                  ) : (
                    <Select
                      name="evaluation"
                      options={optionsObject}
                      hiddenKeys={['select']}
                      value={values.evaluation}
                      error={submitCount > 0 && errors.evaluation}
                      onChange={e => {
                        setFieldValue('value', e.target.value === 'lead' ? '1' : '');
                        setFieldValue('evaluation', e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className={valueInputClass}>
                <div className="input-label">
                  {isLoading ? <Skeleton width={150} /> : translateText('label', 'Value')}
                </div>
                <div className="input">
                  <EvaluationValueInput isLoading={isLoading} domain={item?.domain_id ?? null} />
                </div>
              </div>
              <div className="input-row submit-row">
                <div className="input-label">
                  {!isLoading &&
                    googleAnalyticsSettings?.GA4SecondEvent &&
                    call?.is_sent_to_ga4 === 2 &&
                    call?.can_push_extra_ga4_event && (
                      <button
                        className="btn btn-text"
                        disabled={isSubmitting || values.evaluation === ''}
                        onClick={() => {
                          setFieldValue('analyticsPush', true, false);
                          submitForm();
                        }}
                      >
                        {translateText('label', 'Save and send to GA')}
                      </button>
                    )}
                </div>
                <div className="input">
                  {isLoading ? (
                    <Skeleton className="btn" width={150} />
                  ) : (
                    <button
                      disabled={isSubmitting}
                      onClick={() => {
                        setFieldValue('analyticsPush', false, false);
                        submitForm();
                      }}
                      className="btn btn-lightblue"
                    >
                      {translateText('label', 'Save changes')}
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
      <div className="evaluations-log">
        {!isLoading && (
          <div className="tooltip-i">
            <Tooltip
              text={
                type === TYPE_CALL_TRACKING
                  ? translateText(
                      'tooltip/call-tracking',
                      'Here you will find a log of the previous evaluations associated with this call.',
                    )
                  : translateText(
                      'tooltip/form-tracking',
                      'Here you will find a log of the previous evaluations associated with this call request.',
                    )
              }
            />
          </div>
        )}
        <h3>
          {isLoading ? (
            <Skeleton width={200} />
          ) : type === TYPE_CALL_TRACKING ? (
            translateText('label', 'Call evaluations')
          ) : (
            translateText('label', 'Evaluations')
          )}
        </h3>
        <QueryTable
          tableKey={['evaluation', 'log']}
          columns={{
            date: {
              header: translateText('label', 'Date'),
              date: { includeSeconds: true, connector: 'none' },
            },
            evaluation: {
              header: translateText('label', 'Evaluation'),
              emptyText: translateText('label', 'Evaluation removed'),
            },
            value: { header: translateText('label', 'Value') },
            ga4_event_number: { header: translateText('label', 'Event #') },
          }}
          endpoint={
            (type === TYPE_CALL_TRACKING ? '/evaluation' : '/call-request-evaluation') + '/index'
          }
          filters={{
            custom: { [type === TYPE_CALL_TRACKING ? 'call_id' : 'call_request_id']: itemId },
          }}
          pagination={{ defaultLimit: 5, hideLimit: true }}
          isLoading={isLoading}
          isResponsive
          noWhiteBlock
        />
      </div>
    </DetailsSection>
  );
}
