import { useGlobal } from 'contexts/Global';
import { LANGUAGE_ENGLISH_GB, LANGUAGE_ENGLISH_US, ROLE_MANAGER } from 'globals/constants';
import { Role } from 'globals/types';
import { useHasRoleAccess } from './access';
import { useUser } from './queries/user';

export function useTranslate() {
  const { selectedLanguage } = useGlobal();
  const hasRoleAccess = useHasRoleAccess();
  const { user } = useUser();

  function getLanguage() {
    switch (user?.language?.id ?? selectedLanguage) {
      case LANGUAGE_ENGLISH_US:
      case LANGUAGE_ENGLISH_GB:
        return 'english';
      default:
        return 'dutch';
    }
  }

  return (category: string, text: string, parameters: Record<string, unknown> = {}) => {
    const language = getLanguage();
    let translated = language === 'english' ? text : '';

    if (language !== 'english') {
      let translations: Record<string, string> = {};
      let warning: string | null = null;
      try {
        translations = require(`assets/languages/${language}/${category}.ts`).default;
        translated = translations[text];
        if (!translated) {
          warning = 'Missing translation in ' + language + '/' + category + ': ' + text;
          translated = text;
        }
      } catch (error) {
        warning = 'Missing translation folder: ' + language + '/' + category;
        translated = text;
      }
      if (!__PROD__ && warning) console.warn(warning);
    }

    for (const key in parameters) {
      translated = translated.replace(new RegExp(`{${key}}`, 'g'), String(parameters[key]));
    }

    if (translated.includes('[')) {
      // Add other roles if you need to filter them
      const roles: Role[] = [ROLE_MANAGER];
      roles.forEach(role => {
        const hasAccess = hasRoleAccess(role);
        const regex = new RegExp('(\\[' + role + '])(.*?)(\\[/' + role + '])', 's');
        let match;
        while ((match = regex.exec(translated))) {
          if (!hasAccess) {
            translated = translated.replace(match[0], '');
          } else {
            translated = translated.replace(match[1], '').replace(match[3], '');
          }
        }
      });
    }

    return translated;
  };
}
