import { ReactElement } from 'react';

import { useTranslate } from 'hooks/translate';
import TextField from './input/TextField';
import InputWrapper from './slide-in/InputWrapper';
import Tooltip from './Tooltip';

type Props = {
  password: string;
  passwordVerification: string;
  setPassword: (value: string) => void;
  setPasswordVerification: (value: string) => void;
  setConditionsPassed: (value: boolean) => void;
  error?: boolean | string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
};

const PASSWORD_LENGTH = 9 as const;

export default function PasswordValidator({
  password,
  passwordVerification,
  setPassword,
  setPasswordVerification,
  setConditionsPassed,
  error,
  label,
  placeholder,
  disabled = false,
  className,
}: Props) {
  const translateText = useTranslate();

  function handleChange(value: string, isVerification = false) {
    if (isVerification) setPasswordVerification(value);
    else setPassword(value);

    const currentPassword = isVerification ? password : value;
    const currentPasswordVerification = isVerification ? value : passwordVerification;

    setConditionsPassed(
      currentPassword !== '' &&
        currentPassword === currentPasswordVerification &&
        currentPassword.length >= PASSWORD_LENGTH &&
        /[a-z]/.test(currentPassword) &&
        /[A-Z]/.test(currentPassword) &&
        /[0-9]/.test(currentPassword) &&
        /[!@#$%^&*?.()]+/.test(currentPassword),
    );
  }

  function getValidationRow(text: string, isPassed: boolean, tooltip: ReactElement | null = null) {
    return (
      <div className="validation-row">
        <div className={'big-dot ' + (isPassed ? 'passed' : 'failed')} />
        <div className="validation-label">
          {text}
          {tooltip}
        </div>
      </div>
    );
  }

  let passwordInput = (
    <TextField
      id="password"
      type="password"
      placeholder={placeholder ?? ''}
      onChange={e => handleChange(e.target.value)}
      value={password}
      autoComplete="new-password"
      error={error}
      disabled={disabled}
      wrapperClassName={className}
    />
  );

  if (label) {
    passwordInput = (
      <InputWrapper id="password" label={label}>
        {passwordInput}
      </InputWrapper>
    );
  }

  let passwordVerificationInput = (
    <TextField
      id="repeatPassword"
      type="password"
      placeholder={
        placeholder ? translateText('label', 'Repeat') + ' ' + placeholder.toLowerCase() : ''
      }
      onChange={e => handleChange(e.target.value, true)}
      value={passwordVerification}
      autoComplete="new-password"
      error={error}
      disabled={disabled}
    />
  );

  if (label) {
    passwordVerificationInput = (
      <InputWrapper
        id="repeatPassword"
        label={translateText('label', 'Repeat') + ' ' + label.toLowerCase()}
      >
        {passwordVerificationInput}
      </InputWrapper>
    );
  }

  return (
    <div className="password-validator">
      {passwordInput}
      {passwordVerificationInput}
      <div className="at-least">{translateText('label', 'At least:')}</div>
      <div className="password-conditions">
        <div className="column-one">
          {getValidationRow(
            translateText('label', '{number} characters', { number: PASSWORD_LENGTH }),
            password.length >= PASSWORD_LENGTH,
          )}
          {getValidationRow(
            translateText('label', '1 lowercase character'),
            /[a-z]/.test(password),
          )}
          {getValidationRow(translateText('label', '1 capital character'), /[A-Z]/.test(password))}
        </div>
        <div className="column-two">
          {getValidationRow(translateText('label', '1 digit'), /[0-9]/.test(password))}
          {getValidationRow(
            translateText('label', '1 punctuation mark'),
            /[!@#$%^&*?.()]+/.test(password),
            <Tooltip
              text={translateText('text', 'Possible punctuation marks: {marks}', {
                marks: '! @ # $ % ^ & * ? . ( )',
              })}
            />,
          )}
          {getValidationRow(
            translateText('label', 'Passwords match'),
            password !== '' && password === passwordVerification,
          )}
        </div>
      </div>
    </div>
  );
}
