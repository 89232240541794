import { useFormikContext } from 'formik';

import AutoGrid from 'components/AutoGrid';
import DetailsSection from 'components/DetailsSection';
import Checkbox from 'components/input/Checkbox';
import TextField from 'components/input/TextField';
import TermsAndConditions from 'components/TermsAndConditions';
import Tooltip from 'components/Tooltip';
import { PH_MASK } from 'globals/constants';
import { useCountryTranslations, useTrafficSourcesText } from 'hooks/content';
import { useNumberFormats } from 'hooks/queries/domain-setting';
import { usePricingPlans } from 'hooks/queries/pricing';
import { useUserMcc } from 'hooks/queries/user';
import { useTranslate } from 'hooks/translate';
import { IntakeFormValues, IntakeSection } from 'pages/authenticated/requests/Intake';
import { getFormatDisplay } from './NumberFormat';
import StepBlock from './StepBlock';

export default function SummarySection() {
  const translateText = useTranslate();
  const countries = useCountryTranslations();
  const getTrafficSourcesText = useTrafficSourcesText();
  const { mcc, isLoading: isLoadingMcc } = useUserMcc();
  const { pricingPlans, isLoading: isLoadingPricingPlan } = usePricingPlans();
  const { numberFormats } = useNumberFormats();
  const { values, setFieldValue, handleChange, errors, isSubmitting } =
    useFormikContext<IntakeFormValues>();

  const isValidated = typeof values.validated[IntakeSection.Summary] === 'boolean';
  const isLoading = isLoadingMcc || isLoadingPricingPlan;

  return (
    <AutoGrid className="summary-section" size="large">
      <StepBlock number={1} title={translateText('label', 'Properties')}>
        <DetailsSection
          data={{
            ...(mcc &&
              mcc.length > 1 && {
                [translateText('label', 'Customer center')]: {
                  value: mcc.find(mcc => mcc.id === +values.reseller_id)?.name,
                  private: true,
                },
              }),
            [translateText('label', 'Domain URL')]: { value: values.url, private: true },
            [translateText('label', 'Pricing plan')]: pricingPlans?.find(
              pp => pp.id === values.pricingPlanId,
            )?.invoice_label,
            [translateText('label', 'Contract duration')]: translateText(
              'datetime',
              '{months} months',
              { months: values.contract_duration },
            ),
          }}
          button={{
            text: translateText('label', 'Adjust'),
            onClick: () => setFieldValue('section', IntakeSection.Properties),
            disabled: isSubmitting,
          }}
          isLoading={isLoading}
          boldKeys
        />
      </StepBlock>
      <StepBlock number={2} title={translateText('label', 'Invoicing')}>
        <DetailsSection
          data={{
            [translateText('label', 'Email address')]: values.email,
            [translateText('label', 'Company name')]: values.company,
            [translateText('label', 'To the attention of')]: values.name,
            [translateText('label', 'Reference / Purchase Order Number')]: values.reference || '-',
            [translateText('label', 'KVK-number')]: values.kvk_number || '-',
            [translateText('label', 'VAT-number')]: values.vat_number || '-', // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
            [translateText('label', 'Phone number')]: values.phone_number || '-',
            [translateText('label', 'Address')]: values.address,
            [translateText('label', 'Postal code + city')]: values.zipcode + ' ' + values.city,
            [translateText('label', 'Country')]: countries[values.country],
            [translateText('label', 'Automatic debit')]: values.automatic_collection
              ? translateText('label', 'Yes')
              : translateText('label', 'No'),
          }}
          button={{
            text: translateText('label', 'Adjust'),
            onClick: () => setFieldValue('section', IntakeSection.Properties),
            disabled: isSubmitting,
          }}
          isLoading={isLoading}
          className={PH_MASK}
          boldKeys
        />
      </StepBlock>
      <StepBlock number={3} title={translateText('label', 'Call Tracking')}>
        <DetailsSection
          data={{
            [translateText('label', 'Traffic sources')]: getTrafficSourcesText(
              values.sources,
              values.included,
            ),
            [translateText('label', 'Format phone number')]: getFormatDisplay(
              values.format,
              numberFormats ?? [],
            ),
            [translateText('label', 'Number of locations')]:
              typeof values.destinations === 'string' ? 0 : values.destinations.length,
            [translateText('label', 'Number of offsite phone numbers')]:
              values.offsite_numbers.length,
          }}
          button={{
            text: translateText('label', 'Adjust'),
            onClick: () => setFieldValue('section', IntakeSection.CallTracking),
            disabled: isSubmitting,
          }}
          isLoading={isLoading}
          boldKeys
        />
      </StepBlock>
      <StepBlock number={4} title={translateText('label', 'Remarks')}>
        <div className="white-block">
          <Checkbox
            id="deliver-immediately"
            checked={!values.deliver_immediately}
            onChange={() => setFieldValue('deliver_immediately', !values.deliver_immediately)}
            label={
              <>
                {translateText('label', "Don't deliver domain immediately")}
                <Tooltip
                  text={translateText(
                    'tooltip/intake',
                    'If the domain does not need to be delivered immediately, you can indicate this here. For example, because the delivery does not have to take place until later. Please use the comment field for additional information about the request.',
                  )}
                />
              </>
            }
            labelClassName="extra-margin"
          />
          <TextField
            name="remarks"
            type="textarea"
            value={values.remarks}
            onChange={handleChange}
            placeholder={translateText('label', 'Remarks')}
            rows={10}
            error={isValidated && errors.remarks}
          />
        </div>
      </StepBlock>
      <TermsAndConditions
        termsAndConditions={{
          value: values.termsAndConditions,
          setValue: value => setFieldValue('termsAndConditions', value),
          error: isValidated && errors.termsAndConditions,
        }}
        agreement={{
          value: values.agreement,
          setValue: value => setFieldValue('agreement', value),
          error: isValidated && errors.agreement,
        }}
        privacy={{
          value: values.privacy,
          setValue: value => setFieldValue('privacy', value),
          error: isValidated && errors.privacy,
        }}
        className="margin-top"
        disabled={isSubmitting}
      />
    </AutoGrid>
  );
}
