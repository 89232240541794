import { useParams } from 'react-router';
import moment from 'moment';

import AutoGrid from 'components/AutoGrid';
import DetailsSection from 'components/DetailsSection';
import Skeleton from 'components/Skeleton';
import { PH_MASK } from 'globals/constants';
import { useCountryTranslations, useTrafficSourcesText } from 'hooks/content';
import { useIntake } from 'hooks/queries/domain-intake';
import { useUserMcc } from 'hooks/queries/user';
import { useTranslate } from 'hooks/translate';
import Error404 from 'pages/Error404';

export default function Details() {
  const params = useParams();
  const translateText = useTranslate();
  const countries = useCountryTranslations();
  const getTrafficSourcesText = useTrafficSourcesText();
  const { mcc, isLoading: mccIsLoading } = useUserMcc();

  const intakeId = Number(params.id);
  const {
    intake,
    isLoading: intakeIsLoading,
    isError,
  } = useIntake(!isNaN(intakeId) ? intakeId : null);

  const isLoading = mccIsLoading || intakeIsLoading;

  if (isNaN(intakeId) || isError) {
    return <Error404 title={translateText('label', 'Intake not found')} />;
  }

  const customerCenterName = intake?.reseller_id
    ? mcc?.find(mcc => mcc?.id === intake?.reseller_id)?.name ?? null
    : null;

  return (
    <div>
      <h1>
        {isLoading ? (
          <Skeleton width={120} />
        ) : (
          translateText('label', 'Request') + ' ' + intake?.domain_title
        )}
      </h1>
      <AutoGrid size="large">
        <DetailsSection
          title={'1. ' + translateText('label', 'Properties')}
          isLoading={isLoading}
          boldKeys
          data={{
            ...(customerCenterName && {
              [translateText('label', 'Customer center')]: {
                value: customerCenterName,
                private: true,
              },
            }),
            [translateText('label', 'Domain URL')]: { value: intake?.domain_url, private: true },
            [translateText('label', 'Created by')]: { value: intake?.created_by, private: true },
            [translateText('label', 'Created on')]: intake?.created_on
              ? translateText('datetime', '{date} at {time}', {
                  date: moment(intake?.created_on, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY'),
                  time: moment(intake?.created_on, 'YYYY-MM-DD HH:mm:ss').format('HH:mm'),
                })
              : '',
            [translateText('label', 'Status')]: intake?.status,
            [translateText('label', 'Pricing plan')]: intake?.pricing_plan_label,
            ...(intake?.contract_duration && {
              [translateText('label', 'Contract duration')]: translateText(
                'datetime',
                '{months} months',
                { months: intake?.contract_duration },
              ),
            }),
          }}
        />
        <DetailsSection
          title={'2. ' + translateText('label', 'Invoicing')}
          isLoading={isLoading}
          className={PH_MASK}
          boldKeys
          data={{
            [translateText('label', 'Email address for invoicing')]: intake?.email,
            [translateText('label', 'Company name')]: intake?.company,
            [translateText('label', 'To the attention of')]: intake?.name,
            [translateText('label', 'Reference / Purchase Order Number')]: intake?.reference ?? '-',
            [translateText('label', 'KVK-number')]: intake?.kvk_number ?? '-',
            [translateText('label', 'VAT-number')]: intake?.vat_number ?? '-',
            [translateText('label', 'Phone number')]: intake?.phone_number ?? '-',
            [translateText('label', 'Address')]: intake?.address,
            [translateText('label', 'Postal code + city')]:
              intake?.postal_code + ' ' + intake?.city,
            [translateText('label', 'Country')]:
              countries[intake?.country ?? ''] ?? intake?.country ?? '',
          }}
        />
        <DetailsSection
          title={'3. ' + translateText('label', 'Call Tracking')}
          isLoading={isLoading}
          boldKeys
          data={{
            [translateText('label', 'Traffic sources')]: intake
              ? getTrafficSourcesText(intake.sources, intake.included)
              : '',
            [translateText('label', 'Number of locations')]:
              typeof intake?.destinations === 'string' ? 0 : intake?.destinations.length,
            [translateText('label', 'Number of offsite phone numbers')]:
              intake?.offsite_numbers.length,
          }}
        />
        <DetailsSection title={'4. ' + translateText('label', 'Remarks')} isLoading={isLoading}>
          <p className={!intake?.remarks ? 'no-data' : ''}>
            {isLoading ? (
              <Skeleton count={2} />
            ) : (
              (intake?.remarks ?? '') || translateText('label', 'There are no remarks.')
            )}
          </p>
        </DetailsSection>
      </AutoGrid>
    </div>
  );
}
