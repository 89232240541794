import { useMutation, useQuery } from '@tanstack/react-query';

import { useMessages } from 'contexts/Messages';
import { useTenant } from 'contexts/Tenant';
import { useSetQueryData } from 'hooks/data';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';

export type SessionFilterPool = {
  id: number;
  conditions: {
    id: number;
    type_const: 1 | 2;
    operator_const: number;
    value: string;
  }[];
};

export function useSessionFilterPools() {
  const { fetchData } = useFetch();
  const { selectedDomain } = useTenant();

  const { data, isFetching, refetch } = useQuery({
    queryKey: ['sessionFilter', 'pools', selectedDomain],
    queryFn: (): Promise<SessionFilterPool[]> =>
      fetchData('/session-filter-pool/index', {
        queryParams: { domain_id: selectedDomain, expand: 'conditions', limit: 5000 },
      }),
    enabled: selectedDomain !== null,
  });

  return { pools: data, isLoading: isFetching, refetch };
}

export function useCreateSessionFilterPool() {
  const translateText = useTranslate();
  const setQueryData = useSetQueryData();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const { mutate, isPending } = useMutation({
    mutationFn: () =>
      fetchData('/session-filter-pool/create', {
        method: 'POST',
        bodyParams: { domain_id: selectedDomain },
      }),
    onSuccess: data => {
      setQueryData<SessionFilterPool[]>(['sessionFilter', 'pools', selectedDomain], oldData => [
        ...oldData,
        { ...data, conditions: [] },
      ]);
      addSuccessMessage(
        translateText('admin/text', 'The {type} has been added.', {
          type: translateText('label', 'Pool').toLowerCase(),
        }),
      );
      setTimeout(() => document.getElementById(data.id)?.scrollIntoView(), 1); // Timeout is needed because otherwise the element does not exist yet
    },
  });

  return { createPool: mutate, isCreatingPool: isPending };
}

export function useDeleteSessionFilterPool() {
  const translateText = useTranslate();
  const setQueryData = useSetQueryData();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const { mutate, isPending } = useMutation({
    mutationFn: (id: number) =>
      fetchData('/session-filter-pool/delete', {
        method: 'DELETE',
        queryParams: { id },
      }),
    onSuccess: (data, id) => {
      setQueryData<SessionFilterPool[]>(['sessionFilter', 'pools', selectedDomain], oldData =>
        oldData.filter(p => p.id !== id),
      );
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  const deletePool = (id: number) => {
    if (
      window.confirm(
        translateText(
          'admin/text',
          'Are you sure you want to delete this pool and all its session filters?',
        ),
      )
    ) {
      mutate(id);
    }
  };

  return { deletePool, isDeleting: isPending };
}
