import AutoGrid from 'components/AutoGrid';
import ReportsPage from 'components/ReportsPage';
import QueryTable from 'components/table/QueryTable';
import Widget from 'components/widget/Widget';
import { getDefaultFilters } from 'contexts/Filters';
import { TYPE_FORM_TRACKING } from 'globals/constants';
import { useDateTime } from 'hooks/date-time';
import { useIsFetchingReport } from 'hooks/fetching';
import { useTranslate } from 'hooks/translate';

export default function Locations() {
  const translateText = useTranslate();
  const { formatSeconds } = useDateTime();

  const queryKey = ['formTracking', 'locations'];
  const isLoading = useIsFetchingReport(queryKey);

  const defaultFilters = getDefaultFilters(TYPE_FORM_TRACKING);
  const requests = (
    <Widget
      widgetKey={[...queryKey, 'callRequests']}
      type="bar"
      title={translateText('label', 'Call requests')}
      titleTooltip={translateText('report', 'The top 5 of locations with the most call requests.')}
      endpoint="/widget/call-requests-per-location"
      filters={defaultFilters}
      isLoading={isLoading}
      dataKeys={{ call_requests: { name: translateText('label', 'Call requests') } }}
      isVertical
      hideTotal
      emptyLabel={translateText('label', 'No locations')}
    />
  );

  const calls = (
    <Widget
      widgetKey={[...queryKey, 'calls']}
      type="bar"
      title={translateText('label', 'Calls')}
      titleTooltip={translateText('report', 'The top 5 of locations with the most calls.')}
      endpoint="/widget/call-request-calls-per-location"
      filters={defaultFilters}
      isLoading={isLoading}
      dataKeys={{ calls: { name: translateText('label', 'Calls') } }}
      isVertical
      hideTotal
      emptyLabel={translateText('label', 'No locations')}
    />
  );

  const responseTime = (
    <Widget
      widgetKey={[...queryKey, 'responseTime']}
      type="bar"
      title={translateText('label', 'Response time')}
      titleTooltip={translateText(
        'report',
        'The top 5 of locations with the fastest response time.',
      )}
      endpoint="/widget/call-requests-average-response-time-per-location"
      filters={defaultFilters}
      isLoading={isLoading}
      dataKeys={{ response_time: { name: translateText('label', 'Response time') } }}
      tickFormatter={{
        x: true,
        format: value => formatSeconds(String(value)),
      }}
      isVertical
      hideTotal
      emptyLabel={translateText('label', 'No locations')}
    />
  );

  const successpercentage = (
    <Widget
      widgetKey={[...queryKey, 'successPercentage']}
      type="bar"
      title={translateText('label', 'Success percentage')}
      titleTooltip={translateText(
        'report',
        'The top 5 of locations with the highest successfully processed call requests.',
      )}
      endpoint="/widget/call-requests-success-percentage-per-location"
      filters={defaultFilters}
      isLoading={isLoading}
      dataKeys={{ success_percentage: { name: translateText('label', 'Success percentage') } }}
      tickFormatter={{
        x: true,
        format: value => value + '%',
      }}
      isVertical
      hideTotal
      emptyLabel={translateText('label', 'No locations')}
    />
  );

  return (
    <ReportsPage queryKey={queryKey} title={translateText('label', 'Locations')}>
      <Widget
        widgetKey={[...queryKey, 'tiles']}
        type="statistics"
        endpoint="/widget/form-tracking-overview"
        filters={defaultFilters}
        isLoading={isLoading}
        dataKeys={{
          call_requests: {
            name: translateText('label', 'Call requests'),
            tooltip: translateText('report', 'The total number of call requests.'),
          },
          call_requests_pending: {
            name: translateText('label', 'Pending'),
            tooltip: translateText(
              'report',
              'The number of call request(s) that will soon be started based on the chosen settings.',
            ),
            noTooltipPrevious: true,
          },
          total_calls: {
            name: translateText('label', 'Calls'),
            tooltip: translateText('report', 'The number of calls made.'),
          },
          calls_avgresponse: {
            name: translateText('label', 'Avg. response time'),
            tooltip: translateText(
              'report',
              'The average time between submitting the call request and establishing a successful conversation between both parties.',
            ),
            format: value => formatSeconds(String(value)),
          },
          call_requests_success: {
            name: translateText('label', 'Successful'),
            tooltip: translateText(
              'report',
              'The number of requests for which a successful call was established.',
            ),
          },
        }}
      />
      <AutoGrid evenly stretch noGrow>
        {requests}
        {calls}
        {responseTime}
        {successpercentage}
      </AutoGrid>
      <h1>{translateText('label', 'Overview')}</h1>
      <QueryTable
        tableKey={[...queryKey, 'overview']}
        columns={{
          location: {
            header: { name: translateText('label', 'Location'), sort: true },
            compareType: 'none',
          },
          call_requests: {
            header: { name: translateText('label', 'Number of call requests'), sort: true },
          },
          calls: {
            header: { name: translateText('label', 'Number of call attempts'), sort: true },
          },
          average_response_time: {
            header: { name: translateText('label', 'Avg. response time'), sort: true },
            customValue: value => formatSeconds(String(value)),
          },
          success_percentage: {
            header: { name: translateText('label', 'Success percentage'), sort: true },
            customNumber: value => value + '%',
          },
        }}
        pagination
        endpoint="/widget/call-requests-overview-per-location"
        filters={defaultFilters}
        isLoading={isLoading}
        dataKey="data"
        previousPeriodColumn="location"
      />
    </ReportsPage>
  );
}
