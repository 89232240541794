import AutoGrid from 'components/AutoGrid';
import StatisticsTile from './StatisticsTile';
import { StatisticsDataProps, StatisticsProps } from './types';

export default function Statistics(props: StatisticsProps & StatisticsDataProps) {
  const { id, emptyLabel, className } = props;

  // Data keys can be given as prop or can be decided dynamically from the data.
  // When there are data keys, `data` is an object. When there are no data keys,
  // `data` is an array of objects. The section below makes sure that `data` is
  // always an object.
  const dataKeys = props.dataKeys ?? {};
  const data = props.dataKeys ? (props.data as Record<string, number | string>) : {};
  const previousData = props.dataKeys
    ? (props.previousData as Record<string, number | string>)
    : {};
  if (!props.dataKeys) {
    (props.data as Record<string, number | string>[]).forEach(item => {
      (data as Record<string, number | string>)[item.argument] = item.value;
      dataKeys[item.argument] = { name: item.argument as string };
    });

    (props.previousData as Record<string, number | string>[])?.forEach(item => {
      (previousData as Record<string, number | string>)[item.argument] = item.value;
    });
  }

  if (Object.keys(dataKeys).length === 0) dataKeys['no data'] = { name: emptyLabel };

  return (
    <AutoGrid
      id={id}
      size="small"
      evenly
      noGrow
      className={'statistics-wrapper' + (className ? ' ' + className : '')}
    >
      {Object.entries(dataKeys).map(([key, item]) => (
        <StatisticsTile
          {...props}
          key={key}
          item={{ ...item, key }}
          value={data[key]}
          previousValue={props.previousData ? previousData[key] ?? '0' : null}
        />
      ))}
    </AutoGrid>
  );
}
