import { FormikProps } from 'formik';
import { sortBy } from 'lodash';

import apiIcon from 'assets/images/icons/integrations/adcalls-api.svg';
import adformIcon from 'assets/images/icons/integrations/adform.svg';
import bGeniusIcon from 'assets/images/icons/integrations/b-genius.svg';
import bigqueryIcon from 'assets/images/icons/integrations/bigquery.svg';
import billyGraceIcon from 'assets/images/icons/integrations/billy-grace.svg';
import blueConicIcon from 'assets/images/icons/integrations/blue-conic.svg';
import futyIcon from 'assets/images/icons/integrations/futy.svg';
import googleAdsIcon from 'assets/images/icons/integrations/google.svg';
import googleAnalytics4Icon from 'assets/images/icons/integrations/google-analytics-4.svg';
import googleDisplayVideoIcon from 'assets/images/icons/integrations/google-display-video-360.svg';
import doubleClickIcon from 'assets/images/icons/integrations/google-double-click.svg';
import googleSearchAdsIcon from 'assets/images/icons/integrations/google-search-ads-360.svg';
import hubspotIcon from 'assets/images/icons/integrations/hubspot.svg';
import lefIcon from 'assets/images/icons/integrations/lef.svg';
import lookerStudio from 'assets/images/icons/integrations/looker-studio.svg';
import metaAdsIcon from 'assets/images/icons/integrations/meta-ads.svg';
import microsoftAdvertisingIcon from 'assets/images/icons/integrations/microsoft-advertising.svg';
import microsoftDynamicsIcon from 'assets/images/icons/integrations/microsoft-dynamics.svg';
import pipedriveIcon from 'assets/images/icons/integrations/pipedrive.svg';
import relay42Icon from 'assets/images/icons/integrations/relay-42.svg';
import salesforceIcon from 'assets/images/icons/integrations/salesforce.svg';
import vwoIcon from 'assets/images/icons/integrations/vwo.svg';
import zohoIcon from 'assets/images/icons/integrations/zoho.svg';
import {
  INTEGRATION_ADFORM,
  INTEGRATION_API,
  INTEGRATION_BGENIUS,
  INTEGRATION_BIGQUERY,
  INTEGRATION_BILLY_GRACE,
  INTEGRATION_BLUE_CONIC,
  INTEGRATION_DOUBLE_CLICK,
  INTEGRATION_FUTY,
  INTEGRATION_GOOGLE_ADS,
  INTEGRATION_GOOGLE_ADS_ENHANCED_CONVERSIONS,
  INTEGRATION_GOOGLE_ANALYTICS_4,
  INTEGRATION_GOOGLE_DISPLAY_VIDEO_360,
  INTEGRATION_GOOGLE_SEARCH_ADS_360,
  INTEGRATION_HUBSPOT,
  INTEGRATION_LEF,
  INTEGRATION_LOOKER_STUDIO,
  INTEGRATION_META_ADS,
  INTEGRATION_MICROSOFT_ADVERTISING,
  INTEGRATION_MICROSOFT_DYNAMICS,
  INTEGRATION_MICROSOFT_DYNAMICS_REQUEST,
  INTEGRATION_PIPEDRIVE,
  INTEGRATION_PIPEDRIVE_REQUEST,
  INTEGRATION_RELAY42,
  INTEGRATION_SALESFORCE,
  INTEGRATION_SALESFORCE_REQUEST,
  INTEGRATION_VWO,
  INTEGRATION_ZOHO,
  INTEGRATION_ZOHO_REQUEST,
  PLAN_LIGHT,
  PLAN_OPTIMIZE,
} from 'globals/constants';
import { PricingPlan } from 'globals/types';
import { useTranslate } from 'hooks/translate';
import { useHasNewPricingPlan } from './access';

type IntegrationContent = {
  integrationKey: string;
  title: string;
  icon: string;
  link: string;
  restrictedPricingPlans: PricingPlan[];
  onlyShowWhenEnabled?: boolean;
  adminOnly?: boolean;
  dateAdded?: string;
  isRequest?: boolean;
};

export function useIntegrationsContent() {
  const translateText = useTranslate();
  const integrations: IntegrationContent[] = [
    {
      integrationKey: INTEGRATION_GOOGLE_ANALYTICS_4,
      title: translateText('label', 'Google Analytics 4'),
      icon: googleAnalytics4Icon,
      link: 'web-analysis/google-analytics-4',
      restrictedPricingPlans: [],
    },
    {
      integrationKey: INTEGRATION_GOOGLE_ADS,
      title: translateText('label', 'Google Ads'),
      icon: googleAdsIcon,
      link: 'advertising/google',
      restrictedPricingPlans: [PLAN_LIGHT],
    },
    {
      integrationKey: INTEGRATION_GOOGLE_ADS_ENHANCED_CONVERSIONS,
      title: translateText('label', 'Google Ads Enhanced Conversions'),
      icon: googleAdsIcon,
      link: 'advertising/google-ads-enhanced-conversions',
      restrictedPricingPlans: [PLAN_LIGHT],
      adminOnly: true,
      // dateAdded: '', // TODO AC-9633
    },
    {
      integrationKey: INTEGRATION_MICROSOFT_ADVERTISING,
      title: translateText('label', 'Microsoft Advertising'),
      icon: microsoftAdvertisingIcon,
      link: 'advertising/microsoft',
      restrictedPricingPlans: [PLAN_LIGHT],
    },
    {
      integrationKey: INTEGRATION_LOOKER_STUDIO,
      title: translateText('label', 'Looker Studio'),
      icon: lookerStudio,
      link: 'reports/looker-studio',
      restrictedPricingPlans: [],
    },
    {
      integrationKey: INTEGRATION_API,
      title: translateText('label', 'AdCalls API'),
      icon: apiIcon,
      link: 'api-webhooks/adcalls-api',
      restrictedPricingPlans: [PLAN_OPTIMIZE],
    },
    {
      integrationKey: INTEGRATION_DOUBLE_CLICK,
      title: translateText('label', 'DoubleClick Campaign Manager'),
      icon: doubleClickIcon,
      link: 'advertising/doubleclick',
      restrictedPricingPlans: [PLAN_LIGHT, PLAN_OPTIMIZE],
    },
    {
      integrationKey: INTEGRATION_GOOGLE_DISPLAY_VIDEO_360,
      title: translateText('label', 'Google Display & Video 360'),
      icon: googleDisplayVideoIcon,
      link: 'advertising/display-video-360',
      restrictedPricingPlans: [PLAN_OPTIMIZE],
    },
    {
      integrationKey: INTEGRATION_GOOGLE_SEARCH_ADS_360,
      title: translateText('label', 'Google Search Ads 360'),
      icon: googleSearchAdsIcon,
      link: 'advertising/search-360',
      restrictedPricingPlans: [PLAN_OPTIMIZE],
    },
    {
      integrationKey: INTEGRATION_BGENIUS,
      title: translateText('label', 'bGenius'),
      icon: bGeniusIcon,
      link: 'data-management/bgenius',
      restrictedPricingPlans: [PLAN_LIGHT, PLAN_OPTIMIZE],
    },
    {
      integrationKey: INTEGRATION_BIGQUERY,
      title: translateText('label', 'BigQuery'),
      icon: bigqueryIcon,
      link: 'data-management/bigquery',
      restrictedPricingPlans: [PLAN_LIGHT, PLAN_OPTIMIZE],
      dateAdded: '2024-03-01',
    },
    {
      integrationKey: INTEGRATION_RELAY42,
      title: translateText('label', 'Relay42'),
      icon: relay42Icon,
      link: 'data-management/relay42',
      restrictedPricingPlans: [PLAN_LIGHT, PLAN_OPTIMIZE],
    },
    {
      integrationKey: INTEGRATION_LEF,
      title: translateText('label', 'Lead & E-commerce Follow-up'),
      icon: lefIcon,
      link: 'crm/lef',
      restrictedPricingPlans: [],
    },
    {
      integrationKey: INTEGRATION_BLUE_CONIC,
      title: translateText('label', 'BlueConic'),
      icon: blueConicIcon,
      link: 'data-management/blueconic',
      restrictedPricingPlans: [PLAN_LIGHT, PLAN_OPTIMIZE],
    },
    {
      integrationKey: INTEGRATION_HUBSPOT,
      title: translateText('label', 'HubSpot'),
      icon: hubspotIcon,
      link: 'crm/hubspot',
      restrictedPricingPlans: [PLAN_LIGHT],
    },
    {
      integrationKey: INTEGRATION_META_ADS,
      title: translateText('label', 'Meta Ads'),
      icon: metaAdsIcon,
      link: 'advertising/meta-ads',
      restrictedPricingPlans: [PLAN_LIGHT],
      adminOnly: true,
      // dateAdded: '', // TODO AC-9634
    },
    {
      integrationKey: INTEGRATION_ADFORM,
      title: translateText('label', 'Adform'),
      icon: adformIcon,
      link: 'advertising/adform',
      restrictedPricingPlans: [PLAN_OPTIMIZE],
    },
    {
      integrationKey: INTEGRATION_SALESFORCE,
      title: translateText('label', 'Salesforce'),
      icon: salesforceIcon,
      link: 'crm/salesforce',
      restrictedPricingPlans: [PLAN_LIGHT, PLAN_OPTIMIZE],
      onlyShowWhenEnabled: true,
    },
    {
      integrationKey: INTEGRATION_SALESFORCE_REQUEST,
      title: translateText('label', 'Salesforce'),
      icon: salesforceIcon,
      link: 'crm/salesforce-request',
      restrictedPricingPlans: [PLAN_LIGHT, PLAN_OPTIMIZE],
      isRequest: true,
    },
    {
      integrationKey: INTEGRATION_MICROSOFT_DYNAMICS,
      title: translateText('label', 'Microsoft Dynamics'),
      icon: microsoftDynamicsIcon,
      link: 'crm/microsoft-dynamics',
      restrictedPricingPlans: [PLAN_LIGHT, PLAN_OPTIMIZE],
      onlyShowWhenEnabled: true,
    },
    {
      integrationKey: INTEGRATION_MICROSOFT_DYNAMICS_REQUEST,
      title: translateText('label', 'Microsoft Dynamics'),
      icon: microsoftDynamicsIcon,
      link: 'crm/microsoft-dynamics-request',
      restrictedPricingPlans: [PLAN_LIGHT, PLAN_OPTIMIZE],
      isRequest: true,
    },
    {
      integrationKey: INTEGRATION_PIPEDRIVE,
      title: translateText('label', 'Pipedrive'),
      icon: pipedriveIcon,
      link: 'crm/pipedrive',
      restrictedPricingPlans: [PLAN_LIGHT, PLAN_OPTIMIZE],
      onlyShowWhenEnabled: true,
    },
    {
      integrationKey: INTEGRATION_PIPEDRIVE_REQUEST,
      title: translateText('label', 'Pipedrive'),
      icon: pipedriveIcon,
      link: 'crm/pipedrive-request',
      restrictedPricingPlans: [PLAN_LIGHT, PLAN_OPTIMIZE],
      isRequest: true,
    },
    {
      integrationKey: INTEGRATION_ZOHO,
      title: translateText('label', 'Zoho'),
      icon: zohoIcon,
      link: 'crm/zoho',
      restrictedPricingPlans: [PLAN_LIGHT, PLAN_OPTIMIZE],
      onlyShowWhenEnabled: true,
    },
    {
      integrationKey: INTEGRATION_ZOHO_REQUEST,
      title: translateText('label', 'Zoho'),
      icon: zohoIcon,
      link: 'crm/zoho-request',
      restrictedPricingPlans: [PLAN_LIGHT, PLAN_OPTIMIZE],
      isRequest: true,
    },
    {
      integrationKey: INTEGRATION_BILLY_GRACE,
      title: translateText('label', 'Billy Grace'),
      icon: billyGraceIcon,
      link: 'advertising/billy-grace',
      restrictedPricingPlans: [PLAN_LIGHT],
    },
    {
      integrationKey: INTEGRATION_VWO,
      title: translateText('label', 'Visual Website Optimizer'),
      icon: vwoIcon,
      link: 'web-analysis/vwo',
      restrictedPricingPlans: [PLAN_LIGHT, PLAN_OPTIMIZE],
      dateAdded: '2024-04-01',
    },
    {
      integrationKey: INTEGRATION_FUTY,
      title: translateText('label', 'Futy'),
      icon: futyIcon,
      link: 'crm/futy',
      restrictedPricingPlans: [],
    },
  ];
  return sortBy(integrations, [integration => integration.title.toLowerCase()]);
}

export function useTogglePaidIntegration(
  integration: string,
  price: number,
  checkNewPricingPlan = true,
) {
  const translateText = useTranslate();
  const hasNewPricingPlan = useHasNewPricingPlan();

  return (
    enabled: 0 | 1 | boolean,
    setFieldValue: FormikProps<unknown>['setFieldValue'],
    sideEffect?: () => void,
  ) => {
    if (
      enabled ||
      (checkNewPricingPlan && !hasNewPricingPlan) ||
      window.confirm(
        translateText('label', 'Enable {integration}?', { integration }) +
          '\n\n' +
          translateText(
            'integration',
            'Additional costs are associated with the use of the {integration} integration at €{price},- per month.',
            { integration, price },
          ),
      )
    ) {
      if (sideEffect) sideEffect();
      setFieldValue('enabled', typeof enabled === 'boolean' ? !enabled : +!enabled);
    }
  };
}
