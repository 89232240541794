import { ReactElement } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import ErrorFocus from 'components/ErrorFocus';
import TextField from 'components/input/TextField';
import Skeleton from 'components/Skeleton';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import SupportParagraph from 'components/SupportParagraph';
import { useTenant } from 'contexts/Tenant';
import { useTicketSubmit } from 'hooks/queries/support';
import { useUser } from 'hooks/queries/user';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation } from 'hooks/validation';

type Props = {
  title: string;
  infoText: string;
  subject?: string;
  icon?: string;
  articleId?: number;
  saveButtonText?: string;
  extraText?: string;
  extraComponent?: (isLoading: boolean) => ReactElement;
} & BaseSlideInProps;

type FormValues = {
  name: string;
  email: string;
  domain: string;
  subject: string;
  message: string;
  attachments: File[];
};

export default function SubmitTicket({
  show,
  close,
  title,
  infoText,
  subject,
  icon,
  articleId,
  saveButtonText,
  extraText,
  extraComponent,
}: Props) {
  const translateText = useTranslate();
  const { sendTicket } = useTicketSubmit();
  const { selectedDomainData, isLoading: isLoadingDomainData } = useTenant();
  const { user, isLoading: isLoadingUser } = useUser();
  const { email: emailValidation, required } = useCustomValidation();

  const isLoading = isLoadingUser || isLoadingDomainData;
  const name = user?.first_name && user?.last_name ? user.first_name + ' ' + user.last_name : '';
  const email = user?.email ?? '';
  const domain = selectedDomainData?.name ?? '';

  const initialValues: FormValues = {
    name,
    email,
    domain,
    subject: subject ?? '',
    message: '',
    attachments: [],
  };

  async function sendMail(values: FormValues) {
    const formData = new FormData();
    values.attachments.forEach(file => formData.append('attachment[]', file));
    formData.append('name', values.name);
    formData.append('email', values.email);
    formData.append('domain', values.domain);
    formData.append('subject', values.subject);
    formData.append('message', values.message);
    await sendTicket(formData).then(close);
  }

  const validationSchema = Yup.object({
    name: required,
    email: emailValidation,
    domain: required,
    subject: required,
    message: required,
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={sendMail}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({
        values,
        submitForm,
        errors,
        handleChange,
        isSubmitting,
        setFieldValue,
        dirty,
        submitCount,
        resetForm,
      }) => (
        <Setup
          id="submit-ticket"
          show={show}
          icon={icon}
          title={title}
          save={submitForm}
          saveButtonText={saveButtonText ?? translateText('label', 'Send')}
          maxWidth={520}
          close={close}
          afterClose={resetForm}
          isSaving={isSubmitting}
          hasChanges={dirty}
        >
          <ErrorFocus element={document.getElementById('submit-ticket')} />
          <p>{isLoading ? <Skeleton count={2} /> : infoText}</p>
          {extraComponent?.(isLoading)}
          {extraText && <p>{isLoading ? <Skeleton count={2} /> : extraText}</p>}
          {articleId && <SupportParagraph articleId={articleId} isLoading={isLoading} />}
          <div className="section">
            <div className="double-input">
              <InputWrapper label={translateText('label', 'Full name')} isLoading={isLoading}>
                {isLoading ? (
                  <Skeleton height={38} />
                ) : (
                  <TextField
                    id="name"
                    value={values.name}
                    onChange={handleChange}
                    error={submitCount > 0 && errors.name}
                  />
                )}
              </InputWrapper>
              <InputWrapper label={translateText('label', 'Email address')} isLoading={isLoading}>
                {isLoading ? (
                  <Skeleton height={38} />
                ) : (
                  <TextField
                    id="email"
                    value={values.email}
                    onChange={handleChange}
                    error={submitCount > 0 && errors.email}
                  />
                )}
              </InputWrapper>
            </div>
            <div className="double-input no-align">
              <InputWrapper label={translateText('label', 'Domain')} isLoading={isLoading}>
                {isLoading ? (
                  <Skeleton height={38} />
                ) : (
                  <TextField
                    id="domain"
                    value={values.domain}
                    onChange={handleChange}
                    error={submitCount > 0 && errors.domain}
                  />
                )}
              </InputWrapper>
              <InputWrapper
                label={translateText('label', 'Attachments')}
                isLoading={isLoading}
                fieldClassName="attachment-container"
              >
                {isLoading ? (
                  <Skeleton height={38} />
                ) : (
                  <>
                    <label htmlFor="attachment" className="file-input btn btn-lightblue">
                      {translateText('label', 'Add files')}
                    </label>
                    <input
                      id="attachment"
                      type="file"
                      multiple
                      onChange={e => setFieldValue('attachments', Array.from(e.target.files ?? []))}
                      name="attachment"
                      hidden
                    />
                  </>
                )}
                {values.attachments.length > 0 && (
                  <div className="file-names">{values.attachments.map(f => f.name).join(', ')}</div>
                )}
              </InputWrapper>
            </div>
            <div className="section">
              <InputWrapper label={translateText('label', 'Subject')} isLoading={isLoading}>
                {isLoading ? (
                  <Skeleton height={38} />
                ) : (
                  <TextField
                    id="subject"
                    wrapperClassName="full"
                    onChange={handleChange}
                    value={values.subject}
                    error={submitCount > 0 && errors.subject}
                  />
                )}
              </InputWrapper>
              <InputWrapper label={translateText('label', 'Message')} isLoading={isLoading}>
                {isLoading ? (
                  <Skeleton height={190} />
                ) : (
                  <TextField
                    id="message"
                    wrapperClassName="full"
                    type="textarea"
                    onChange={handleChange}
                    value={values.message}
                    error={submitCount > 0 && errors.message}
                    rows={5}
                  />
                )}
              </InputWrapper>
            </div>
          </div>
        </Setup>
      )}
    </Formik>
  );
}
