import { Navigate } from 'react-router';

import DomainAccountAccess from 'components/settings/account-access/DomainAccountAccess';
import { DomainUser } from 'components/settings/account-access/DomainUserSetup';
import MccAccountAccess from 'components/settings/account-access/MccAccountAccess';
import { ColumnSetting } from 'components/table/Table';
import { useTenant } from 'contexts/Tenant';
import { USER_STATUS_CLASSES } from 'globals/constants';
import { ResellerUser } from 'hooks/queries/reseller';
import { useTranslate } from 'hooks/translate';

export type AccountAccessProps<T extends ResellerUser | DomainUser> = {
  getRoleTooltip: (hasAccess: boolean) => string;
  statusColumn: ColumnSetting<T>;
};

export default function AccountAccess() {
  const translateText = useTranslate();
  const { selectedMcc, selectedDomain } = useTenant();

  function getRoleTooltip(hasAccess: boolean) {
    let text = translateText('tooltip/domain', 'The rights of the user.');
    if (hasAccess) {
      text += ' ' + translateText('tooltip/domain', "Click the user's row to change its rights.");
    }
    return text;
  }

  const statusColumn = {
    header: {
      name: translateText('label', 'Status'),
      tooltip: translateText('tooltip/domain', 'The status of the user.'),
    },
    bigDot: {
      hover: 'status' as const,
      classes: USER_STATUS_CLASSES,
    },
  };

  const props = { getRoleTooltip, statusColumn };
  if (selectedDomain) return <DomainAccountAccess {...props} />;
  if (selectedMcc && selectedMcc !== -1) return <MccAccountAccess {...props} />;
  return <Navigate to="/overview" />;
}
