import BaseGoogleAds, {
  GOOGLE_ADS_ENHANCED_CONVERSIONS_KEY,
} from 'components/integrations/google-ads/BaseGoogleAds';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import { SUP_ART_GOOGLE_ADS_EC } from 'globals/support';
import { useTranslate } from 'hooks/translate';

export default function GoogleAds(props: BaseSlideInProps) {
  const translateText = useTranslate();

  return (
    <BaseGoogleAds
      {...props}
      type={GOOGLE_ADS_ENHANCED_CONVERSIONS_KEY}
      extraParagraph={translateText(
        'integration',
        'Using Enhanced Conversions for Google Ads, you can improve conversion measurements by using additional customer data, leading to more accurate data and better insight into the customer journey. Compared to classic conversion measurement, this integration offers a solution to limitations of cookies and tracking due to privacy considerations.',
      )}
      supportArticleId={SUP_ART_GOOGLE_ADS_EC}
    />
  );
}
