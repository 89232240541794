import QueryTable from 'components/table/QueryTable';
import { useTranslate } from 'hooks/translate';

export default function Mail() {
  const translateText = useTranslate();
  return (
    <>
      <h1>{translateText('label', 'Mail log')}</h1>
      <QueryTable
        tableKey={['mail', 'log']}
        className="wrap"
        columns={{
          domain_title: {
            header: { name: translateText('label', 'Domain'), sort: true },
            search: true,
          },
          emails: { header: { name: translateText('label', 'Email'), sort: true }, search: true },
          datetime: {
            header: { name: translateText('label', 'Date & time'), sort: true },
            date: true,
            search: true,
          },
          user_first_name: { header: translateText('label', 'First name') },
          type: { header: { name: translateText('label', 'Type'), sort: true }, search: true },
        }}
        endpoint="/mail-log/index"
        pagination
        filters={{ domain: false }}
        refresh
      />
    </>
  );
}
