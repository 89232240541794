import { Formik } from 'formik';
import * as Yup from 'yup';

import iconMetaAds from 'assets/images/icons/integrations/meta-ads.svg';
import ErrorFocus from 'components/ErrorFocus';
import TextField from 'components/input/TextField';
import Toggle from 'components/input/Toggle';
import CostParagraph from 'components/integrations/CostParagraph';
import Skeleton from 'components/Skeleton';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import { INTEGRATION_META_ADS } from 'globals/constants';
import { useHasAccessToIntegration } from 'hooks/access';
import { useMetaAds } from 'hooks/queries/integration';
import { useTranslate } from 'hooks/translate';

export type MetaAdsData = {
  enabled: true | false;
  pixel_id: string;
  access_token: string;
};

export default function MetaAds({ show, close }: BaseSlideInProps) {
  const translateText = useTranslate();
  const hasAccessToIntegration = useHasAccessToIntegration();
  const { data, isLoading, updateMetaAds } = useMetaAds(show);

  if (!hasAccessToIntegration(INTEGRATION_META_ADS)) return null;

  const title = translateText('label', 'Meta Ads');

  async function save(values: MetaAdsData) {
    await updateMetaAds(values).then(close);
  }

  // The form values only need to be validated when the setting is enabled
  const customRequired = Yup.string().test(function (value) {
    return this.parent.enabled && !value?.trim()
      ? this.createError({
          message: translateText('message', 'This field is required.'),
        })
      : true;
  });
  const validationSchema = Yup.object({
    pixel_id: customRequired,
    access_token: customRequired,
  });

  return (
    <Formik
      initialValues={{
        enabled: data?.enabled ?? false,
        pixel_id: data?.pixel_id ?? '',
        access_token: data?.access_token ?? '',
      }}
      onSubmit={save}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({
        values,
        handleChange,
        submitForm,
        errors,
        isSubmitting,
        dirty,
        resetForm,
        submitCount,
      }) => (
        <Setup
          id="meta-ads"
          show={show}
          title={title}
          icon={iconMetaAds}
          save={submitForm}
          close={close}
          afterClose={resetForm}
          isSaving={isSubmitting}
          maxWidth={440}
          hasChanges={dirty}
        >
          <ErrorFocus element={document.getElementById('meta-ads')} />
          <p>
            {isLoading ? (
              <Skeleton count={2} />
            ) : (
              translateText(
                'integration',
                'Process your calls in Meta Ads so you can optimize your campaigns based on call conversions.',
              )
            )}
          </p>
          <CostParagraph price={null} isLoading={isLoading} />
          {/* TODO AC-9634
          <SupportParagraph articleId={SUP_ART_META_ADS>} isLoading={isLoading} /> */}
          <div className="section">
            <InputWrapper
              label={translateText('label', 'Dataset ID')}
              tooltip={translateText(
                'tooltip/meta-ads',
                'Enter the Dataset ID or Pixel ID belonging to the Meta Ads Business Manager account here.',
              )}
              isLoading={isLoading}
            >
              {isLoading ? (
                <Skeleton height={38} width={300} />
              ) : (
                <TextField
                  name="pixel_id"
                  value={values.pixel_id}
                  onChange={handleChange}
                  disabled={isSubmitting}
                  error={submitCount > 0 && errors.pixel_id}
                />
              )}
            </InputWrapper>
            <InputWrapper
              label={translateText('label', 'Access token')}
              tooltip={translateText(
                'tooltip/meta-ads',
                'Enter the Access token belonging to the Meta Ads Business Manager account here.',
              )}
              isLoading={isLoading}
            >
              {isLoading ? (
                <Skeleton height={38} width={300} />
              ) : (
                <TextField
                  name="access_token"
                  value={values.access_token}
                  onChange={handleChange}
                  disabled={isSubmitting}
                  error={submitCount > 0 && errors.access_token}
                />
              )}
            </InputWrapper>
            <InputWrapper
              label={translateText('label', 'Send conversions')}
              isLoading={isLoading}
              tooltip={translateText(
                'integration',
                'Disable and enable the sending of calls to {integration} here.',
                { integration: title },
              )}
            >
              <Toggle
                name="enabled"
                checked={values.enabled}
                onClick={handleChange}
                disabled={isSubmitting}
                isLoading={isLoading}
              />
            </InputWrapper>
          </div>
        </Setup>
      )}
    </Formik>
  );
}
