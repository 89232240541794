import { useParams } from 'react-router';

import Skeleton from 'components/Skeleton';
import { PH_MASK } from 'globals/constants';
import { useDataProcessorAgreement } from 'hooks/queries/data-processor-agreement';

export default function DataProcessorAgreement() {
  const params = useParams();
  const version = params.version?.replace(/_/g, '.');

  const { data, isLoading } = useDataProcessorAgreement(version);
  const document = data?.file ?? '';

  return (
    <div id="data-processor-agreement" className={PH_MASK + (isLoading ? ' fade-out' : '')}>
      {isLoading ? <Skeleton count={30} /> : <div dangerouslySetInnerHTML={{ __html: document }} />}
    </div>
  );
}
