import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import MultiEvaluate from 'components/history/MultiEvaluate';
import ReportsPage from 'components/ReportsPage';
import QueryTable from 'components/table/QueryTable';
import Widget from 'components/widget/Widget';
import { getDefaultFilters } from 'contexts/Filters';
import { useGlobal } from 'contexts/Global';
import { TYPE_CALL_TRACKING } from 'globals/constants';
import { Call } from 'globals/types';
import { useHasManagerAccess } from 'hooks/access';
import { useAnalyticsCodes } from 'hooks/content';
import { useIsFetchingReport } from 'hooks/fetching';
import { useUpdateMarking } from 'hooks/queries/call';
import { useDomainAuth } from 'hooks/queries/domain';
import { useEvaluationImport, useMultiUpdateEvaluations } from 'hooks/queries/evaluation';
import { useTranslate } from 'hooks/translate';

export const CALL_HISTORY_EXPAND = [
  'customer_evaluation',
  'evaluation',
  'marked',
  'notes',
  'aen',
  'aen_name',
  'is_sent_to_ga4',
  'is_sent_to_ga4_second_event',
  'is_sent_to_ga4_labels',
  'domain_id',
  'is_sent_to_bing',
  'is_sent_to_google',
  'status_google_ads_ec',
  'is_sent_to_doubleclick',
  'is_sent_to_lef',
  'campaign_data',
  'last_url',
  'can_push_extra_ga4_event',
].join(',');

export default function History() {
  const translateText = useTranslate();
  const navigate = useNavigate();
  const analyticsCodes = useAnalyticsCodes();
  const hasManagerAccess = useHasManagerAccess();
  const { domainAuth } = useDomainAuth();
  const { setShowReportsFilter } = useGlobal();
  const { updateMarking } = useUpdateMarking();
  const { multiUpdateEvaluations } = useMultiUpdateEvaluations();
  const { importEvaluations, isLoading: isLoadingImport } = useEvaluationImport();
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  useEffect(() => {
    setShowReportsFilter(TYPE_CALL_TRACKING);
    return () => setShowReportsFilter(false);
  }, [setShowReportsFilter]);

  const queryKey = ['callTracking', 'history'];
  const isLoading = useIsFetchingReport(queryKey);

  const defaultFilters = getDefaultFilters(TYPE_CALL_TRACKING);

  function updateEvaluations(evaluation: string, value: string, close: () => void) {
    multiUpdateEvaluations('update', selectedRows, evaluation, value).then(() => {
      close();
      setSelectedRows([]);
    });
  }

  function deleteEvaluations(close: () => void) {
    multiUpdateEvaluations('delete', selectedRows).then(() => {
      close();
      setSelectedRows([]);
    });
  }

  function updateSelectedRows(id: number) {
    if (selectedRows.includes(id)) setSelectedRows(selectedRows.filter(i => i !== id));
    else setSelectedRows([...selectedRows, id]);
  }

  function getTopLeftButtons() {
    return (
      <>
        <MultiEvaluate
          onSave={updateEvaluations}
          onDelete={deleteEvaluations}
          isLoadingTable={isLoading}
        />
        {domainAuth?.csv_import === true && hasManagerAccess && !isLoading && (
          <>
            <label
              htmlFor="upload"
              className={'btn btn-lightblue' + (isLoadingImport ? ' disabled' : '')}
            >
              {translateText('label', 'Import evaluations')}
            </label>
            <input
              disabled={isLoadingImport}
              id="upload"
              type="file"
              accept=".csv"
              onChange={e =>
                importEvaluations(e.target.files?.[0]).finally(() => (e.target.value = ''))
              }
              hidden
            />
          </>
        )}
      </>
    );
  }

  return (
    <ReportsPage
      queryKey={queryKey}
      title={translateText('label', 'Evaluations')}
      className="call-history-overview"
    >
      <Widget
        widgetKey={[...queryKey, 'evaluations']}
        endpoint="/widget/evaluations"
        type="statistics"
        filters={{ ...defaultFilters, previousPeriod: false, statusLef: true }}
        isLoading={isLoading}
      />
      <h1>{translateText('label', 'Calls')}</h1>
      <QueryTable<Call & { 'evaluation.evaluation': undefined; 'evaluation.value': undefined }>
        tableKey={[...queryKey, 'index']}
        columns={{
          'is_missed': {
            header: { name: translateText('label', 'Status'), sort: true },
            defaultSelected: true,
            className: 'is-missed',
            bigDot: {
              classes: { false: 'active', true: 'inactive' },
              hover: {
                false: translateText('label', 'Picked up'),
                true: translateText('label', 'Missed'),
              },
            },
          },
          'time_called': {
            header: { name: translateText('label', 'Date'), sort: true },
            defaultSelected: true,
            date: { includeSeconds: true, connector: 'none' },
            className: 'bold',
          },
          'aen_name': {
            header: translateText('label', 'Location'),
            defaultSelected: true,
            private: true,
          },
          'destination': {
            header: translateText('label', 'Destination'),
            noNumberFormat: true,
            private: true,
          },
          'source': {
            header: { name: translateText('label', 'Traffic source'), sort: true },
            defaultSelected: true,
            private: true,
          },
          'caller': {
            header: { name: translateText('label', 'Sender'), sort: true },
            keyValues: { 0: translateText('label', 'Anonymous') },
            defaultSelected: true,
            noNumberFormat: true,
            private: true,
          },
          'duration': {
            header: { name: translateText('label', 'Duration'), sort: true },
            defaultSelected: true,
          },
          'customer_evaluation': {
            header: { name: translateText('label', 'IVR menu'), sort: true },
          },
          'evaluation.evaluation': {
            header: { name: translateText('label', 'Evaluation'), sort: true },
            defaultSelected: true,
            customValue: (value, row) => row?.evaluation?.evaluation ?? '-',
          },
          'evaluation.value': {
            header: { name: translateText('label', 'Value'), sort: true },
            defaultSelected: true,
            customValue: (value, row) => row?.evaluation?.value ?? '-',
          },
          'id': { header: translateText('label', 'Call ID'), defaultSelected: true },
          'is_sent_to_ga4': {
            header: translateText('label', 'Status Google Analytics 4'),
            defaultSelected: true,
            keyValues: analyticsCodes,
          },
          'is_sent_to_ga4_second_event': {
            header:
              translateText('label', 'Status Google Analytics 4') +
              ' ' +
              translateText('label', 'Second event').toLowerCase(),
            keyValues: analyticsCodes,
          },
          'is_sent_to_google': { header: translateText('label', 'Status Google Ads') },
          'status_google_ads_ec': {
            header: translateText('label', 'Status Google Ads Enhanced Conversions'),
          },
          'is_sent_to_doubleclick': {
            header: translateText('label', 'Status Double Click CM'),
          },
          'is_sent_to_bing': { header: translateText('label', 'Status Microsoft Advertising') },
          'is_sent_to_lef': { header: translateText('label', 'Status LEF') },
          'marked': {
            header: translateText('label', 'Marked'),
            defaultSelected: true,
            className: 'marked',
            customValue: (value, row) => (
              <button
                className={'star-icon' + (value ? ' marked' : '')}
                onClick={e => {
                  e.stopPropagation();
                  updateMarking({ id: row.id, marking: !value });
                }}
              />
            ),
          },
        }}
        endpoint="/call/index"
        pagination
        canSearch
        refresh={['call']}
        onRowClick={row => navigate('/call-tracking/history/' + row.id)}
        isResponsive
        filters={{
          ...defaultFilters,
          previousPeriod: false,
          statusLef: true,
          custom: { expand: CALL_HISTORY_EXPAND },
        }}
        followNumber
        canChooseColumns
        download="csv"
        isLoading={isLoading}
        extraTopLeft={getTopLeftButtons()}
        multiSelect={{ selected: selectedRows, update: id => updateSelectedRows(id as number) }}
      />
    </ReportsPage>
  );
}
