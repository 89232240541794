import { useState } from 'react';

import DetailsSection from 'components/DetailsSection';
import TextField from 'components/input/TextField';
import Loader from 'components/Loader';
import { useVoip } from 'hooks/queries/log';
import { useTranslate } from 'hooks/translate';

export default function Voip() {
  const translateText = useTranslate();

  const [id, setId] = useState('');

  const { voipLogs, isLoading, refetch } = useVoip(id);

  function getBlocks() {
    if (isLoading) {
      return (
        <div className="white-block margin-top">
          <Loader />
        </div>
      );
    }

    if (!voipLogs) return null;

    return voipLogs.map((log, index) => (
      <DetailsSection
        key={index}
        title={log.type ? (log.type as string) : ''}
        boldKeys
        data={log ?? []}
        className="margin-top"
      />
    ));
  }

  return (
    <div className="voip">
      <h1>{translateText('label', 'VoIP')}</h1>
      <form
        className="form-row"
        onSubmit={e => {
          e.preventDefault();
          refetch();
        }}
      >
        <TextField
          placeholder={translateText('label', 'PBX-id or Call-id')}
          onChange={e => setId(e.target.value)}
          value={id}
        />
        <button className="btn btn-lightblue margin-left" onClick={() => refetch()}>
          {translateText('label', 'Search')}
        </button>
      </form>
      {getBlocks()}
    </div>
  );
}
