import { ReactComponent as Gear } from 'assets/images/icons/gear.svg';
import Skeleton from 'components/Skeleton';
import Tooltip from 'components/Tooltip';
import { useHasManagerAccess } from 'hooks/access';
import { useTranslate } from 'hooks/translate';

type Props = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  disabledText?: string;
  isSmall?: boolean;
  noAccessCheck?: boolean;
  isLoading?: boolean;
};

export default function EditButton({
  onClick,
  disabled,
  disabledText,
  isSmall,
  noAccessCheck,
  isLoading,
}: Props) {
  const translateText = useTranslate();
  const hasManagerAccess = useHasManagerAccess();
  const hasAccess = !!noAccessCheck || hasManagerAccess;

  const size = isSmall ? '14' : '21';
  let button = (
    <button
      className={'btn btn-adcalls-blue no-padding' + (!hasAccess || disabled ? ' disabled' : '')}
      onClick={onClick}
      disabled={!hasAccess || disabled}
    >
      <Gear height={size} width={size} />
    </button>
  );

  if (!hasAccess || (disabled && disabledText)) {
    button = (
      <Tooltip
        html={button}
        text={
          !hasAccess
            ? translateText(
                'text',
                'You cannot edit the settings, because you do not have the correct permissions.',
              )
            : disabledText!
        }
        placement="bottom"
      />
    );
  }
  return (
    <div className={'edit-button' + (isSmall ? ' small' : '')}>
      {isLoading ? <Skeleton className="btn" noMargin /> : button}
    </div>
  );
}
