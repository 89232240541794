import { useNavigate } from 'react-router';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { MccUserFormValues } from 'components/settings/account-access/MccUserSetup';
import { useMessages } from 'contexts/Messages';
import { useTenant } from 'contexts/Tenant';
import { useSetTableData, useTableData } from 'hooks/data';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';
import { ADMIN_RESELLER_EXPAND, AdminReseller } from 'pages/authenticated/admin/Resellers';

export type ResellerUser = {
  reseller_id?: number;
  reseller?: string;
  user_id?: number;
  user?: string;
  role: {
    description: string;
    id: number;
  } | null;
  is_admin: 0 | 1;
  invite_to_new: 0 | 1;
  first_name: string;
  last_name: string;
  email: string;
  last_login: string;
  assigned_domains: string[];
  status: string;
  status_code?: number;
  invitation_id?: number | null;
  invited?: boolean;
};

export function useReseller() {
  const { selectedMcc: id } = useTenant();
  const { fetchData } = useFetch();

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ['resellerView', id],
    queryFn: (): Promise<{
      id: number;
      name: string;
      partner_status: string | null;
      account_manager: { id: number; name: string; email: string; phone: string };
    }> => fetchData('/reseller/view', { queryParams: { id, expand: 'account_manager' } }),
    enabled: id !== null,
  });

  return { data, isLoading };
}

export function useResellers() {
  const { fetchData } = useFetch();

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ['resellers'],
    queryFn: (): Promise<
      {
        id: number;
        name: string;
        partner_status: string | null;
        account_manager: { id: number; name: string; email: string; phone: string };
      }[]
    > => fetchData('/reseller/index', { queryParams: { expand: 'account_manager' } }),
  });

  return { resellers: data, isLoading };
}

export function useInviteUser(resellerId?: number) {
  const queryClient = useQueryClient();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedMcc } = useTenant();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (values: MccUserFormValues) =>
      fetchData('/reseller/invite-user', {
        method: 'POST',
        bodyParams: {
          reseller_id: resellerId ?? selectedMcc,
          email: values.email,
          role_id: values.role,
          invite_to_new: !!values.inviteToNewDomains,
          is_admin: !!values.isAdmin,
          specific_domains: !!values.specificDomains,
          domains: values.specificDomains ? values.assignedDomains : [],
        },
      }),
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: ['reseller', 'users', resellerId ?? selectedMcc] });
      addSuccessMessage(data);
    },
  });

  return { inviteUser: mutateAsync, isLoading: isPending };
}

export function useUpdateUser() {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedMcc } = useTenant();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: ({ values, userId }: { values: MccUserFormValues; userId: number }) =>
      fetchData('/reseller/update-user', {
        method: 'POST',
        queryParams: {
          reseller_id: selectedMcc,
          user_id: userId,
        },
        bodyParams: {
          role_id: values.role,
          invite_to_new: values.inviteToNewDomains,
          is_admin: values.isAdmin,
          specific_domains: !!values.specificDomains,
          assigned_domains: values.specificDomains ? values.assignedDomains : [],
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['reseller', 'users', selectedMcc] });
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { updateUser: mutateAsync, isLoading: isPending };
}

export function useRevokeUser() {
  const setTableData = useSetTableData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedMcc } = useTenant();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (user: ResellerUser) =>
      fetchData('/reseller/revoke', {
        method: 'POST',
        bodyParams: { reseller_id: user.reseller_id, user_id: user.user_id },
      }),
    onSuccess: (data, variables) => {
      setTableData(['reseller', 'users', selectedMcc], oldData =>
        oldData.filter(u => u.user_id !== variables.user_id),
      );
      addSuccessMessage(data);
    },
  });

  return {
    revokeUser: async (user: ResellerUser) => {
      if (
        window.confirm(
          translateText(
            'text',
            'Are you sure you want to revoke access to the customer center for user {user}?',
            { user: user.email },
          ),
        )
      ) {
        await mutateAsync(user);
      }
    },
    isLoading: isPending,
  };
}

export function useRevokeDomain() {
  const queryClient = useQueryClient();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const translateText = useTranslate();

  const { mutate } = useMutation({
    mutationFn: ({ mccId, domain }: { mccId: number; domain: { id: number; title: string } }) =>
      fetchData('/reseller/revoke-domain', {
        method: 'DELETE',
        queryParams: { reseller: mccId, domain: domain.id },
      }),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['userMcc'] });
      queryClient.invalidateQueries({ queryKey: ['domains'] });
      addSuccessMessage(
        translateText(
          'message',
          'Access has been successfully revoked from the domain <span data-private>{domain}</span>.',
          { domain: variables.domain.title },
        ),
      );
    },
  });

  return {
    revokeDomain: (domain: { id: number; title: string }, mccId: number) => {
      if (
        window.confirm(
          translateText('text', 'Are you sure you want to revoke access for the domain {domain}?', {
            domain: domain.title,
          }),
        )
      ) {
        mutate({ mccId, domain: { id: domain.id, title: domain.title } });
      }
    },
  };
}

export function useInviteDomain() {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync } = useMutation({
    mutationFn: ({
      mccId,
      domainId,
      reference,
    }: {
      mccId: number;
      domainId: string;
      reference: string;
    }) =>
      fetchData('/reseller/invite-domain', {
        method: 'POST',
        bodyParams: { reseller_id: mccId, domain_id: domainId, reference },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['domain', 'mcc'] });
      addSuccessMessage(
        translateText('message', 'The domain has been invited to the customer center.'),
      );
    },
  });

  return { inviteDomain: mutateAsync };
}

export function useResellerMultiUpdate() {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const { addSuccessMessage } = useMessages();
  const { fetchData } = useFetch();

  const { mutateAsync } = useMutation({
    mutationFn: (models: Record<string, unknown>[]) =>
      fetchData('/reseller/multi-update', {
        method: 'PUT',
        bodyParams: { models },
      }),
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: ['resellers', 'admin'] });
      data.forEach((r: AdminReseller) => {
        queryClient.invalidateQueries({ queryKey: ['adminReseller', r.id] });
      });
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { resellerMultiUpdate: mutateAsync };
}

export function useCreateReseller() {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const navigate = useNavigate();
  const { addSuccessMessage } = useMessages();
  const { fetchData } = useFetch();

  const { mutateAsync } = useMutation({
    mutationFn: (reseller: {
      name: string;
      email: string;
      account_manager: string;
      partner_status: string;
    }) =>
      fetchData('/reseller/create', {
        method: 'POST',
        bodyParams: reseller,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['resellers', 'admin'] });
      addSuccessMessage(translateText('admin/text', 'Partner has been added'));
      navigate('/admin/resellers');
    },
  });

  return { createReseller: mutateAsync };
}

export function useUpdateReseller(id: number) {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const setTableData = useSetTableData();
  const { addSuccessMessage } = useMessages();
  const { fetchData } = useFetch();

  const { mutateAsync } = useMutation({
    mutationFn: (values: {
      partner_status?: string;
      kickback_email?: string;
      kickback_name?: string;
    }) =>
      fetchData('/reseller/update', {
        method: 'PUT',
        queryParams: { id, expand: ADMIN_RESELLER_EXPAND },
        bodyParams: values,
      }),
    onSuccess: data => {
      queryClient.setQueryData(['adminReseller', id], () => data);
      setTableData(['resellers', 'admin'], oldData => oldData.map(i => (i?.id === id ? data : i)));
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { updateReseller: mutateAsync };
}

export function useUpdateAccountManager(id: number) {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const setTableData = useSetTableData();
  const { addSuccessMessage } = useMessages();
  const { fetchData } = useFetch();

  const { mutate } = useMutation({
    mutationFn: (accountManager: string) =>
      fetchData('/reseller/update-account-manager', {
        method: 'PUT',
        queryParams: { id, expand: ADMIN_RESELLER_EXPAND },
        bodyParams: { account_manager: accountManager },
      }),
    onSuccess: data => {
      queryClient.setQueryData(['adminReseller', id], () => data);
      setTableData(['resellers', 'admin'], oldData => oldData.map(i => (i?.id === id ? data : i)));
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { changeAccountManager: mutate };
}

export function useAdminResellerDetails(id: number | null) {
  const { fetchData } = useFetch();
  const resellerData = useTableData<AdminReseller>(['resellers', 'admin']);

  const { data, isFetching } = useQuery({
    queryKey: ['adminReseller', id],
    queryFn: (): Promise<AdminReseller> =>
      fetchData('/reseller/view', { queryParams: { id, expand: ADMIN_RESELLER_EXPAND } }),
    enabled: id !== null,
    meta: { noError: true },
    initialData: () => resellerData?.find(r => r.id === id),
  });

  return { reseller: data, isLoading: isFetching };
}
