import { createRef, ReactElement, useEffect, useRef, useState } from 'react';
import { CSSTransition, SwitchTransition, TransitionGroup } from 'react-transition-group';

import AddButton from 'components/buttons/AddButton';
import ExportButton from 'components/buttons/ExportButton';
import Card from 'components/configuration/card-list/Card';
import ListItem from 'components/configuration/card-list/ListItem';
import TextField from 'components/input/TextField';
import Pagination from 'components/Pagination';
import Skeleton from 'components/Skeleton';
import { useTenant } from 'contexts/Tenant';
import { useTranslate } from 'hooks/translate';

export type BaseItem = {
  enabled?: boolean;
  name: string;
  identifier?: string;
  criticalWarning?: false | string;
  warning?: string | null;
  extra?: ReactElement | null;
};

type Props = {
  items: BaseItem[] | undefined;
  renderContent: (item: BaseItem) => ReactElement;
  setEdit: (value: 'new' | number | false) => void;
  search?: (value: string) => void;
  isLoading?: boolean;
  showIdentifier?: boolean;
  pagination?: {
    page: number;
    limit: number;
    pageCount: number;
    itemCount: number;
    onChange: (pageNumber: number, itemsPerPage: number) => void;
  };
  noAccessCheck?: boolean;
  checkError?: (index: string | number) => boolean;
  rowCount?: number;
  exportCsv?: () => void;
  isExporting?: boolean;
  itemName?: string; // Use this when the add button should be disabled without manager access, the item name will be used in the hover message
  disableAdd?: string | null; // Pass the tooltip that should be shown when the add button is disabled
};

export default function CardListView({
  items,
  search,
  isLoading = false,
  showIdentifier = false,
  setEdit,
  pagination,
  renderContent,
  noAccessCheck = false,
  checkError,
  rowCount = 1,
  exportCsv,
  isExporting,
  itemName,
  disableAdd,
}: Props) {
  const cardRef = useRef<HTMLDivElement | null>(null);
  const translateText = useTranslate();
  const { selectedDomain } = useTenant();
  const [selected, setSelected] = useState<number | false>(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setSelected(false);
  }, [selectedDomain]);

  let className = 'all-items';
  if (selected !== false) className += ' selected';

  function getSearch() {
    if (!search) return null;
    if (isLoading) return <Skeleton width={200} height={38} />;
    return (
      <div className="search">
        <TextField
          placeholder={translateText('label', 'Search') + '...'}
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          onKeyDown={e => e.key === 'Enter' && search((e.target as HTMLInputElement).value)}
          onBlur={() => search(searchTerm)}
        />
      </div>
    );
  }

  return (
    <div className="card-list-view">
      <div className="list">
        <div className="search-row white-block">
          <AddButton
            onClick={() => setEdit('new')}
            isLoading={isLoading}
            itemName={itemName}
            disabled={!!disableAdd}
            tooltipText={disableAdd}
          />
          <div className="right-wrapper">
            {exportCsv && (
              <ExportButton
                type="csv"
                onClick={exportCsv}
                disabled={isExporting}
                isLoading={isLoading}
              />
            )}
            {getSearch()}
          </div>
        </div>
        <div className={className}>
          <TransitionGroup component={null}>
            {(items ?? Array(rowCount).fill({})).map((item, index) => {
              const ref = createRef<HTMLDivElement>();
              return (
                <CSSTransition key={index} classNames="list-item" nodeRef={ref} timeout={500}>
                  <div ref={ref}>
                    <ListItem
                      isLoading={isLoading}
                      key={index}
                      item={item}
                      isSelected={selected === index}
                      showIdentifier={showIdentifier}
                      setSelected={() => setSelected(selected !== index ? index : false)}
                      setEdit={() => setEdit(index)}
                      hasError={checkError?.(index) ?? false}
                      noAccessCheck={noAccessCheck}
                    />
                  </div>
                </CSSTransition>
              );
            })}
          </TransitionGroup>
        </div>
        {pagination && pagination.itemCount > 10 && (
          <Pagination
            activePage={pagination.page}
            itemsCountPerPage={pagination.limit}
            totalItemsCount={pagination.itemCount}
            totalPageCount={pagination.pageCount}
            onChange={(page, itemCount) => {
              pagination.onChange(page, itemCount);
              setSelected(false);
            }}
          />
        )}
      </div>
      <SwitchTransition>
        <CSSTransition
          key={!isLoading && selected !== false ? selected : null}
          classNames="card-view"
          nodeRef={cardRef}
          timeout={300}
        >
          <Card
            cardRef={cardRef}
            item={!isLoading && items && selected !== false ? items[selected] : false}
            setEdit={() => setEdit(selected)}
            renderContent={renderContent}
            showIdentifier={showIdentifier}
            noAccessCheck={noAccessCheck}
          />
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
}
