import image404 from 'assets/images/404.svg';
import { useTranslate } from 'hooks/translate';
import Footer from './Footer';
import ImagePage from './ImagePage';
import Header from './navigation/Header';

export default function ErrorPage() {
  const translateText = useTranslate();
  return (
    <div id="authenticated-wrapper" className="error-page-wrapper">
      <div id="sidebar" />
      <div id="page">
        <Header hasError />
        <div id="content-wrapper">
          <div id="content-outer">
            <div id="content-inner">
              <div id="content">
                <ImagePage
                  title={translateText('label', 'The page cannot be loaded')}
                  content={
                    <>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: translateText(
                            'label',
                            'This page cannot be loaded at the moment. Try refreshing the page or contact us at <a href="mailto:support@adcalls.nl">support@adcalls.nl</a>',
                          ),
                        }}
                      />
                      <div className="button-wrapper">
                        <button
                          className="btn btn-lightblue"
                          onClick={() => window.location.reload()}
                        >
                          {translateText('label', 'Refresh')}
                        </button>
                      </div>
                    </>
                  }
                  image={image404}
                />
              </div>
              <Footer placement="right" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
