import { Link } from 'react-router-dom';

import Skeleton from 'components/Skeleton';
import { CategorySlug } from 'globals/types';
import { useCategoryType } from 'hooks/knowledge-center';
import { useTranslate } from 'hooks/translate';

type Props = {
  categoryType?: CategorySlug | null;
  category?: { slug: string; title: string } | null;
  article?: { slug: string; title: string } | null;
  isLoading: boolean;
};

export default function Breadcrumb({ categoryType, category, article, isLoading }: Props) {
  const translateText = useTranslate();
  const { categoryTypeSlug, categoryTypeText } = useCategoryType(categoryType ?? null);

  if (isLoading) {
    return (
      <div className="breadcrumbs">
        <Skeleton
          width={120 + (category !== undefined ? 120 : 0) + (article !== undefined ? 120 : 0)}
        />
      </div>
    );
  }

  const breadcrumbs: { path: string; name: string }[] = [];
  if (categoryTypeSlug) {
    breadcrumbs.push({
      path: '/knowledge-center/' + categoryTypeSlug,
      name: translateText('label', '{type} categories', { type: categoryTypeText }),
    });

    if (category) {
      breadcrumbs.push({
        path:
          '/knowledge-center/' +
          categoryTypeSlug +
          '/category/' +
          encodeURIComponent(category.slug),
        name: category.title,
      });
    }
  }

  if (article) {
    breadcrumbs.push({
      path: '/knowledge-center/article/' + encodeURIComponent(article.slug),
      name: article.title,
    });
  }

  return breadcrumbs.length ? (
    <div className="breadcrumbs">
      {breadcrumbs.map((crumb, i) => (
        <span key={i} className="breadcrumb-item">
          <Link to={crumb.path}>{crumb.name}</Link>
        </span>
      ))}
    </div>
  ) : null;
}
