import { Formik, FormikHelpers } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import deleteIcon from 'assets/images/icons/delete.svg';
import DetailsSection from 'components/DetailsSection';
import TextField from 'components/input/TextField';
import Skeleton from 'components/Skeleton';
import { useMessages } from 'contexts/Messages';
import { PH_MASK } from 'globals/constants';
import { ServiceType } from 'globals/types';
import { useCallNotes } from 'hooks/queries/call';
import { useTranslate } from 'hooks/translate';
import { useUserDateFormat } from 'hooks/user';

export type Note = {
  id: number;
  body: string;
  created_on: string;
  user: string;
};

type Props = {
  type: ServiceType;
  itemId: number | undefined; // call or call request id
  notes: Note[];
  isLoading: boolean;
};

export default function NoteSection({ type, itemId, notes, isLoading }: Props) {
  const translateText = useTranslate();
  const { addSuccessMessage } = useMessages();
  const dateFormat = useUserDateFormat();
  const { addCallNote, removeCallNote } = useCallNotes(type);

  async function addNote(values: { note: string }, formikHelpers: FormikHelpers<{ note: string }>) {
    await addCallNote({ itemId: itemId!, note: values.note }).then(() => {
      addSuccessMessage(translateText('message', 'Note added'));
      formikHelpers.resetForm();
    });
  }

  async function removeNote(id: number) {
    if (!window.confirm(translateText('message', 'Remove note?'))) return;
    await removeCallNote({ noteId: id, itemId: itemId! }).then(() =>
      addSuccessMessage(translateText('message', 'Note deleted')),
    );
  }

  const validationSchema = Yup.object({
    note: Yup.string().required(translateText('message', 'Note must be filled in.')),
  });

  return (
    <DetailsSection className="notes" title={translateText('label', 'Notes')} isLoading={isLoading}>
      <Formik initialValues={{ note: '' }} onSubmit={addNote} validationSchema={validationSchema}>
        {({ values, handleChange, submitForm, errors, isSubmitting }) => (
          <>
            {!isLoading ? (
              <TextField
                name="note"
                type="textarea"
                placeholder={translateText('label', 'Type a note')}
                value={values.note}
                onChange={handleChange}
                error={errors.note}
              />
            ) : (
              <Skeleton height={160} />
            )}
            <div className="button-wrapper">
              {!isLoading ? (
                <button className="btn btn-green" onClick={submitForm} disabled={isSubmitting}>
                  {translateText('label', 'Add note')}
                </button>
              ) : (
                <Skeleton className="btn" width="120px" />
              )}
            </div>
          </>
        )}
      </Formik>
      {!isLoading && notes?.length > 0 && (
        <>
          <h2 className="notes-history-title">{translateText('label', 'Notes history')}</h2>
          {notes.map((note, index) => (
            <div key={index} className="note-item-wrapper">
              <img
                className="delete"
                src={deleteIcon}
                alt="delete"
                onClick={() => removeNote(note.id)}
              />
              <div className="white-block">
                <p className="bold">
                  <span className={PH_MASK}>{note.user}</span>-{' '}
                  <span>
                    {translateText('datetime', '{date} at {time}', {
                      date: moment(note.created_on).format(dateFormat),
                      time: moment(note.created_on).format('HH:mm:ss'),
                    })}
                  </span>
                </p>
                {note.body && <p className={'body ' + PH_MASK}>{note.body}</p>}
              </div>
            </div>
          ))}
        </>
      )}
    </DetailsSection>
  );
}
