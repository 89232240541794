import { Navigate, Route, Routes } from 'react-router';

import AuthenticatedRoute from 'components/routes/AuthenticatedRoute';
import { TYPE_CALL_TRACKING } from 'globals/constants';
import History from 'pages/authenticated/call-tracking/history/History';
import HistoryDetails from 'pages/authenticated/call-tracking/history/HistoryDetails';
import Error404 from 'pages/Error404';
import Config from './Config';
import Integrations from './Integrations';
import Reports from './Reports';

export default function CallTracking() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/call-tracking/reports/overview" />} />
      <Route path="/reports" element={<Navigate to="/call-tracking/reports/overview" />} />
      <Route
        path="/reports/*"
        element={
          <AuthenticatedRoute checkDomain>
            <Reports type={TYPE_CALL_TRACKING} />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/history"
        element={
          <AuthenticatedRoute checkDomain>
            <History />
          </AuthenticatedRoute>
        }
      />
      <Route path="/history/:id" element={<HistoryDetails />} />

      <Route
        path="/config/*"
        element={
          <AuthenticatedRoute checkDomain>
            <Config />
          </AuthenticatedRoute>
        }
      />

      <Route
        path="/integrations/*"
        element={
          <AuthenticatedRoute checkDomain>
            <Integrations />
          </AuthenticatedRoute>
        }
      />

      <Route path="/*" element={<Error404 />} />
    </Routes>
  );
}
