import { useRef, useState } from 'react';
import { Formik } from 'formik';

import Checkbox from 'components/input/Checkbox';
import VerifyProceedPrompt from 'components/VerifyProceedPrompt';
import { useMessages } from 'contexts/Messages';
import { useOnClickOutside } from 'hooks/event';
import { useStopNumbers, useStopVoxboneNumbers } from 'hooks/queries/number';
import { useTranslate } from 'hooks/translate';

type Props = {
  numbers: Record<string, string>;
  setLogs: React.Dispatch<React.SetStateAction<(string | string[])[]>>;
};

export default function StopNumbers({ numbers, setLogs }: Props) {
  const { addSuccessMessage, addErrorMessage } = useMessages();
  const translateText = useTranslate();
  const { stopNumbers, isUpdating } = useStopNumbers();
  const { stopVoxboneNumbers, isUpdating: isUpdatingVoxbone } = useStopVoxboneNumbers(setLogs);
  const [isOpen, setIsOpen] = useState(false);
  const [warning, setWarning] = useState('');
  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    if (isOpen) setIsOpen(false);
  });

  async function saveChanges(values: { voxbone: boolean }) {
    if (Object.keys(numbers).length === 0) {
      addErrorMessage(translateText('message', 'No numbers have been selected.'));
    } else if (values.voxbone) {
      await stopVoxboneNumbers(numbers).then(() => setIsOpen(false));
    } else {
      await stopSelectedNumbers();
    }
  }

  async function stopSelectedNumbers(stopRequestedForCheck = true) {
    await stopNumbers({
      numbers: Object.keys(numbers),
      stopRequestedForCheck,
    })
      .then(() => {
        addSuccessMessage(translateText('message', 'Changes saved'));
        setIsOpen(false);
        setWarning('');
      })
      .catch(error => {
        if (typeof error === 'object' && error.hasOwnProperty('numbersNoStopRequestedFor')) {
          setWarning(
            translateText('message', "The following numbers don't have a stop date: {numbers}", {
              numbers: `<ul><li>${error.numbersNoStopRequestedFor.join('</li><li>')}</li></ul>`,
            }),
          );
        }
      });
  }

  return (
    <>
      <VerifyProceedPrompt
        show={warning !== ''}
        isSaving={isUpdating}
        title={translateText('label', 'Numbers without a stop date') + '!'}
        intro={warning}
        verifyText={translateText('label', 'Yes, stop the numbers')}
        proceed={{ onClick: () => stopSelectedNumbers(false) }}
        cancel={{ onClick: () => setWarning('') }}
      />
      <div ref={ref}>
        <button className="btn btn-lightblue" onClick={() => setIsOpen(open => !open)}>
          {translateText('label', 'Mark')}
        </button>
        {isOpen && (
          <Formik initialValues={{ voxbone: false }} onSubmit={saveChanges} enableReinitialize>
            {({ values, handleChange, submitForm }) => (
              <div className="multi-select-dropdown-wrapper">
                <div className="multi-select-dropdown">
                  <h3 className="bold">{translateText('label', 'Mark stop requests')}</h3>
                  <div className="data-row">
                    <Checkbox
                      id="voxbone"
                      label={translateText('label', 'Stop at Voxbone')}
                      checked={values.voxbone}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="buttons right">
                    <button
                      className="btn btn-purple"
                      disabled={isUpdating || isUpdatingVoxbone}
                      onClick={submitForm}
                    >
                      {translateText('label', 'Save')}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        )}
      </div>
    </>
  );
}
