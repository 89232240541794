import { Formik } from 'formik';
import * as Yup from 'yup';

import iconAdform from 'assets/images/icons/integrations/adform.svg';
import ErrorFocus from 'components/ErrorFocus';
import TextField from 'components/input/TextField';
import Toggle from 'components/input/Toggle';
import CostParagraph from 'components/integrations/CostParagraph';
import Skeleton from 'components/Skeleton';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import SupportParagraph from 'components/SupportParagraph';
import { INTEGRATION_ADFORM, PLAN_ENTERPRISE } from 'globals/constants';
import { SUP_ART_ADFORM } from 'globals/support';
import { useHasAccessToIntegration } from 'hooks/access';
import { useTogglePaidIntegration } from 'hooks/integrations';
import { useAdform } from 'hooks/queries/integration';
import { useTranslate } from 'hooks/translate';

export type AdformData = {
  enabled: 0 | 1;
  clientTrackingId: string;
  trackingPointName: string;
};

export default function Adform({ show, close }: BaseSlideInProps) {
  const translateText = useTranslate();

  const title = translateText('label', 'Adform');
  const PRICE = 49 as const;

  const hasAccessToIntegration = useHasAccessToIntegration();
  const toggleEnabled = useTogglePaidIntegration(title, PRICE);

  const { data, isLoading, updateAdform } = useAdform(show);

  if (!hasAccessToIntegration(INTEGRATION_ADFORM)) return null;

  async function save(values: AdformData) {
    await updateAdform(values).then(close);
  }

  // The form values only need to be validated when the setting is enabled
  const customRequired = Yup.string().test(function (value) {
    return this.parent.enabled && !value?.trim()
      ? this.createError({
          message: translateText('message', 'This field is required.'),
        })
      : true;
  });
  const validationSchema = Yup.object({
    clientTrackingId: customRequired,
    trackingPointName: customRequired,
  });

  return (
    <Formik
      initialValues={{
        enabled: data?.enabled ?? 0,
        clientTrackingId: data?.clientTrackingId ?? '',
        trackingPointName: data?.trackingPointName ?? '',
      }}
      onSubmit={save}
      validationSchema={validationSchema}
      validateOnChange={false}
      enableReinitialize
    >
      {({
        values,
        handleChange,
        setFieldValue,
        submitForm,
        errors,
        isSubmitting,
        dirty,
        resetForm,
      }) => (
        <Setup
          id="adform"
          show={show}
          title={title}
          icon={iconAdform}
          save={submitForm}
          close={close}
          afterClose={resetForm}
          isSaving={isSubmitting}
          maxWidth={440}
          hasChanges={dirty}
        >
          <ErrorFocus element={document.getElementById('adform')} />
          <p>
            {isLoading ? (
              <Skeleton count={3} />
            ) : (
              translateText(
                'integration',
                'The Adform platform provides access to all major Dutch ad exchanges and networks, such as Improve Digital, AppNexus, Google Display Network, YouTube and more. With the help of this integration you can measure call conversions in Adform.',
              )
            )}
          </p>
          <CostParagraph
            integration={title}
            price={PRICE}
            plan={PLAN_ENTERPRISE}
            isLoading={isLoading}
          />
          <SupportParagraph articleId={SUP_ART_ADFORM} isLoading={isLoading} />
          <div className="section">
            <InputWrapper
              label={translateText('label', 'Send conversions')}
              isLoading={isLoading}
              tooltip={translateText(
                'integration',
                'Disable and enable the sending of calls to {integration} here.',
                { integration: title },
              )}
            >
              <Toggle
                checked={!!values.enabled}
                onClick={() => toggleEnabled(values.enabled, setFieldValue)}
                disabled={isSubmitting}
                isLoading={isLoading}
              />
            </InputWrapper>
            <InputWrapper
              label={translateText('label', 'Tracking ID')}
              tooltip={translateText(
                'tooltip/adform',
                'Enter the ID associated with the Adform tag here.',
              )}
              isLoading={isLoading}
            >
              {isLoading ? (
                <Skeleton height={38} width={300} />
              ) : (
                <TextField
                  name="clientTrackingId"
                  value={values.clientTrackingId}
                  onChange={handleChange}
                  disabled={!values.enabled || isSubmitting}
                  error={errors.clientTrackingId}
                />
              )}
            </InputWrapper>
            <InputWrapper
              label={translateText('label', 'Tracking point name')}
              tooltip={translateText(
                'tooltip/adform',
                'Enter the name of the tracking point under which the calls made via Call Tracking may be registered.',
              )}
              isLoading={isLoading}
            >
              {isLoading ? (
                <Skeleton height={38} width={300} />
              ) : (
                <TextField
                  name="trackingPointName"
                  value={values.trackingPointName}
                  onChange={handleChange}
                  disabled={!values.enabled || isSubmitting}
                  error={errors.trackingPointName}
                />
              )}
            </InputWrapper>
          </div>
        </Setup>
      )}
    </Formik>
  );
}
