import { useNavigate } from 'react-router';

import alert from 'assets/images/icons/alert.svg';
import Skeleton from 'components/Skeleton';
import { PH_MASK } from 'globals/constants';

type Props = {
  title: string;
  summary: string;
  link: string | null;
  isCritical?: boolean;
  isLoading?: boolean;
  isPrivate?: boolean;
};

export default function MessageCard({
  title,
  summary,
  link,
  isCritical = false,
  isLoading = false,
  isPrivate = false,
}: Props) {
  const navigate = useNavigate();

  let className = 'white-block updates-and-recommendations';
  if (!isLoading) className += ' selectable';
  if (isPrivate) className += ' ' + PH_MASK;

  return (
    <div className={className} onClick={() => link && navigate(link)}>
      <div className="content">
        <div className="article-title">
          {isLoading ? (
            <h3>
              <Skeleton width={250} />
            </h3>
          ) : (
            <h3 dangerouslySetInnerHTML={{ __html: title }} />
          )}
          {isCritical && <img className="critical" src={alert} alt="critical" />}
        </div>
        {isLoading ? (
          <Skeleton count={3} />
        ) : (
          <div className="summary" dangerouslySetInnerHTML={{ __html: summary }} />
        )}
      </div>
    </div>
  );
}
