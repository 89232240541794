import { Navigate, Route, Routes } from 'react-router';

import Verification, {
  REDIRECT_URI_BIGQUERY,
  REDIRECT_URI_GOOGLE_ADS,
  REDIRECT_URI_GOOGLE_ADS_EC,
  REDIRECT_URI_GOOGLE_DOUBLE_CLICK,
  REDIRECT_URI_GOOGLE_DV360,
  REDIRECT_URI_GOOGLE_SA360,
  REDIRECT_URI_HUBSPOT,
  REDIRECT_URI_MICROSOFT_ADVERTISING,
  REDIRECT_URI_MICROSOFT_DYNAMICS,
  REDIRECT_URI_PIPEDRIVE,
  REDIRECT_URI_SALESFORCE,
  REDIRECT_URI_ZOHO,
} from 'components/oauth2/Verification';
import IntegrationsOverview from 'pages/authenticated/integrations/Integrations';
import Error404 from 'pages/Error404';

export default function Integrations() {
  return (
    <Routes>
      <Route
        path="/reports/google-data-studio"
        element={<Navigate to="/call-tracking/integrations/reports/looker-studio" />}
      />
      <Route
        path="/advertising/display-video-360/verify"
        element={<Verification redirectUriKey={REDIRECT_URI_GOOGLE_DV360} />}
      />
      <Route
        path="/advertising/doubleclick/verify"
        element={<Verification redirectUriKey={REDIRECT_URI_GOOGLE_DOUBLE_CLICK} />}
      />
      <Route
        path="/advertising/search-360/verify"
        element={<Verification redirectUriKey={REDIRECT_URI_GOOGLE_SA360} />}
      />
      <Route
        path="/data-management/bigquery/verify"
        element={<Verification redirectUriKey={REDIRECT_URI_BIGQUERY} />}
      />
      <Route
        path="/crm/salesforce/verify"
        element={<Verification redirectUriKey={REDIRECT_URI_SALESFORCE} />}
      />
      <Route
        path="/crm/hubspot/verify"
        element={<Verification redirectUriKey={REDIRECT_URI_HUBSPOT} />}
      />
      <Route
        path="/crm/microsoft-dynamics/verify"
        element={<Verification redirectUriKey={REDIRECT_URI_MICROSOFT_DYNAMICS} />}
      />
      <Route
        path="/crm/pipedrive/verify"
        element={<Verification redirectUriKey={REDIRECT_URI_PIPEDRIVE} />}
      />
      <Route
        path="/crm/zoho/verify"
        element={<Verification redirectUriKey={REDIRECT_URI_ZOHO} />}
      />
      <Route
        path="/advertising/google/verify"
        element={<Verification redirectUriKey={REDIRECT_URI_GOOGLE_ADS} />}
      />
      <Route
        path="/advertising/google-ads-enhanced-conversions/verify"
        element={<Verification redirectUriKey={REDIRECT_URI_GOOGLE_ADS_EC} />}
      />
      <Route
        path="/advertising/microsoft/verify"
        element={<Verification redirectUriKey={REDIRECT_URI_MICROSOFT_ADVERTISING} />}
      />
      <Route path="/" element={<IntegrationsOverview />}>
        <Route path="/:category/:integration" element={<IntegrationsOverview />}>
          <Route path="/:category/:integration/:section" element={<IntegrationsOverview />} />
        </Route>
      </Route>
      <Route path="/*" element={<Error404 />} />
    </Routes>
  );
}
