import { ReactElement } from 'react';
import { isEmpty, isEqual } from 'lodash';

import alert from 'assets/images/icons/alert.svg';
import exclBlue from 'assets/images/icons/exclamation-blue.svg';
import exclPurple from 'assets/images/icons/exclamation-purple.svg';
import gear from 'assets/images/icons/gear.svg';
import { ReactComponent as Minus } from 'assets/images/icons/minus.svg';
import { PH_MASK } from 'globals/constants';
import { isReactNode } from 'globals/helpers';
import AddButton from './buttons/AddButton';
import ErrorTooltip from './ErrorTooltip';
import Tooltip from './Tooltip';

type Props<T> = {
  dataKeys: (keyof T)[]; // These are the keys of the items that should be shown in the table
  privateKeys?: (keyof T)[]; // These are the keys of the items that should be masked in PostHog recordings
  items?: T[]; // Use this for items that, when deleted, can be placed back
  addedItems?: T[]; // Use this for items that, when deleted, get removed straight away
  deletedItems?: T[]; // This is used to keep track of items that are deleted but can be placed back
  className?: string; // Add a classname to the table
  onAdd?: () => void; // Use this for the click on the add button
  onEdit?: (item: T, index: number) => void; // Use this for the click on the edit button of an item
  onDelete?: (item: T) => void; // Use this if you want to use a custom delete function
  setAddedItems?: (items: T[]) => void; // This is used to set the new list of added items after an item gets deleted
  setDeletedItems?: (items: T[]) => void; // This is used to set the new list of deleted items after an item gets deleted or placed back
  getStatus?: (item: T) => { text: string; type: 'warning' | 'info' } | null; // Use this to show a info/warning icon based on a property of an item
  title?: string; // This is the title of the "items" list
  titleAdded?: string; // This is the title of the "addedItems" list
  addButtonDisabled?: boolean; // Whether the add button should be disabled
  buttonsDisabled?: boolean; // Whether the row buttons should be disabled
  extraAddElement?: ReactElement; // Use this to show an extra element next to the add button
  error?: boolean | string;
  id?: string;
  emptyText?: string; // Use this to show a particular string when the items array is empty
};

export default function CreateDeleteTable<T extends Record<string, unknown>>({
  dataKeys,
  privateKeys,
  items,
  addedItems,
  deletedItems,
  className,
  onAdd,
  onEdit,
  onDelete,
  setAddedItems,
  setDeletedItems,
  getStatus,
  addButtonDisabled,
  buttonsDisabled,
  title,
  titleAdded,
  extraAddElement,
  error,
  id,
  emptyText,
}: Props<T>) {
  const addButton = onAdd && (
    <tr className="add-row">
      <td colSpan={dataKeys.length}>
        {extraAddElement}
        <AddButton disabled={addButtonDisabled} onClick={onAdd} />
        <ErrorTooltip
          error={error}
          html={
            error ? (
              <div data-error tabIndex={1} id={id + '-error'}>
                <img className="error-icon" src={alert} alt="error" />
              </div>
            ) : (
              <></>
            )
          }
          className="margin-left"
        />
      </td>
    </tr>
  );

  function toggleDeleted(item: T, isDeleted: boolean, isAdded: boolean) {
    if (isAdded) setAddedItems?.(addedItems?.filter(i => !isEqual(item, i)) ?? []);
    if (isDeleted) setDeletedItems?.(deletedItems?.filter(i => !isEqual(item, i)) ?? []);
    else setDeletedItems?.([...(deletedItems ?? []), item]);
  }

  function renderTitle(isAdded = false) {
    if (isAdded && (!titleAdded || isEmpty(addedItems))) return null;
    if (!isAdded && (!title || (titleAdded && !isEmpty(addedItems) && isEmpty(items)))) return null;
    return (
      <tr>
        <td colSpan={dataKeys.length}>
          <h3>{isAdded ? titleAdded : title}</h3>
        </td>
      </tr>
    );
  }

  function renderRows(isAdded = false) {
    if (emptyText && ((!isAdded && items?.length === 0) || (isAdded && addedItems?.length === 0))) {
      return (
        <tr key="no-data">
          <td>
            <p className="no-data">{emptyText}</p>
          </td>
        </tr>
      );
    }
    return (isAdded ? addedItems! : items ?? []).map((item, index) => {
      const isDeleted = !!deletedItems?.some(i => isEqual(i, item));
      const status = getStatus?.(item);

      let extraCell = null;
      if (status) {
        extraCell = (
          <td className="status">
            <Tooltip
              html={<img alt={status.type} src={status.type === 'info' ? exclBlue : exclPurple} />}
              text={status.text}
            />
          </td>
        );
      } else if (
        onEdit ||
        (!isAdded && setDeletedItems) ||
        (isAdded && setAddedItems) ||
        onDelete
      ) {
        extraCell = (
          <td className="buttons">
            <div className="button-wrapper">
              {((!isAdded && setDeletedItems) || (isAdded && setAddedItems) || onDelete) && (
                <button
                  className={'btn btn-small-round ' + (isDeleted ? 'btn-darkblue' : 'btn-purple')}
                  disabled={buttonsDisabled}
                  onClick={() =>
                    onDelete ? onDelete(item) : toggleDeleted(item, isDeleted, isAdded)
                  }
                >
                  {isDeleted ? <span className="undo-button">↺</span> : <Minus />}
                </button>
              )}
              {onEdit && (
                <button
                  className="btn btn-lightblue btn-small-round"
                  onClick={() => onEdit(item, index)}
                  disabled={buttonsDisabled}
                >
                  <img alt="edit" src={gear} />
                </button>
              )}
            </div>
          </td>
        );
      }

      return (
        <tr key={index} className={'item-row' + (isDeleted || status ? ' faded' : '')}>
          {dataKeys.map((k, i) => {
            const currentItem = item[k];
            return (
              <td key={i} className={privateKeys?.includes(k) ? PH_MASK : ''}>
                {isReactNode(currentItem) && currentItem}
              </td>
            );
          })}
          {extraCell}
        </tr>
      );
    });
  }

  return (
    <table className={'create-delete-table' + (className ? ' ' + className : '')} id={id}>
      <tbody>
        {renderTitle(true)}
        {!isEmpty(addedItems) && addButton}
        {addedItems && renderRows(true)}
        {renderTitle()}
        {isEmpty(addedItems) && addButton}
        {renderRows()}
      </tbody>
    </table>
  );
}
