import { Link, useNavigate } from 'react-router-dom';

import QueryTable from 'components/table/QueryTable';
import { useTranslate } from 'hooks/translate';

export default function Notifications() {
  const translateText = useTranslate();
  const navigate = useNavigate();

  return (
    <>
      <h1>{translateText('label', 'Notifications')}</h1>
      <QueryTable
        tableKey={['notifications', 'admin']}
        endpoint="/notification/custom"
        columns={{
          id: { header: translateText('label', 'ID') },
          category: { header: translateText('label', 'Category') },
          title: { header: translateText('label', 'Title') },
        }}
        filters={{ domain: false, custom: { expand: 'translations' } }}
        onRowClick={row => navigate('/admin/content/notifications/' + row.id)}
        pagination
        refresh
        extraTopRight={
          <Link to="/admin/content/notifications/new" className="btn btn-lightblue margin-right">
            {translateText('label', 'Add new notification')}
          </Link>
        }
      />
    </>
  );
}
