import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import successVisual from 'assets/images/create-account-success.svg';
import ErrorFocus from 'components/ErrorFocus';
import TextField from 'components/input/TextField';
import ContentSidebar from 'components/unauthenticated/ContentSidebar';
import LoginNewsPage from 'components/unauthenticated/LoginNewsPage';
import { useResetEmail } from 'hooks/queries/auth';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation } from 'hooks/validation';

export default function NewEmail() {
  const navigate = useNavigate();
  const translateText = useTranslate();
  const { required } = useCustomValidation();
  const { resetEmail, status } = useResetEmail();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  return (
    <ContentSidebar
      left={
        <Formik
          initialValues={{ password: '' }}
          onSubmit={async values => await resetEmail({ ...values, token: token! })}
          validationSchema={Yup.object({ password: required })}
        >
          {({ values, handleChange, handleSubmit, errors, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <ErrorFocus />
              <h2 className="bold margin-bottom">
                {status === 'success'
                  ? translateText('label', 'Email verified')
                  : translateText('label', 'Verify email change')}
              </h2>
              {status !== 'success' && (
                <>
                  <TextField
                    name="password"
                    type="password"
                    disabled={!token || isSubmitting}
                    placeholder={translateText('label', 'Password')}
                    onChange={handleChange}
                    value={values.password}
                    error={errors.password}
                  />
                  <button
                    className="btn btn-green btn-wide margin-bottom"
                    type="submit"
                    disabled={!token || isSubmitting}
                  >
                    {translateText('label', 'Confirm')}
                  </button>
                </>
              )}
              <button
                className={`btn btn-${status === 'success' ? 'green' : 'text'} btn-wide`}
                onClick={() => navigate('/login')}
                type="button"
                disabled={isSubmitting}
              >
                {translateText('label', 'Go to the login page')}
              </button>
            </form>
          )}
        </Formik>
      }
      right={status === 'success' ? <img alt="AdCalls" src={successVisual} /> : <LoginNewsPage />}
      alignCenter
    />
  );
}
