import { useState } from 'react';
import { useIsFetching } from '@tanstack/react-query';

import AddButton from 'components/buttons/AddButton';
import QueryTable from 'components/table/QueryTable';
import { useMessages } from 'contexts/Messages';
import { useTenant } from 'contexts/Tenant';
import { useHasMccAdminAccess } from 'hooks/access';
import { ResellerUser } from 'hooks/queries/reseller';
import { useUser } from 'hooks/queries/user';
import { useTranslate } from 'hooks/translate';
import { AccountAccessProps } from 'pages/authenticated/settings/AccountAccess';
import SettingsPage from '../SettingsPage';
import MccUserSetup from './MccUserSetup';

export default function MccAccountAccess({
  getRoleTooltip,
  statusColumn,
}: AccountAccessProps<ResellerUser>) {
  const translateText = useTranslate();
  const isMccAdmin = useHasMccAdminAccess();
  const { addErrorMessage } = useMessages();
  const { selectedMcc } = useTenant();
  const { user, isLoading: isLoadingUser } = useUser();
  const [showUser, setShowUser] = useState<number | 'new' | false>(false);

  const queryKey = ['reseller', 'users', selectedMcc!];
  const isFetchingTable = useIsFetching({ queryKey: queryKey });
  const isLoading = !!isFetchingTable || isLoadingUser;

  function onRowClick(userId: number) {
    if (userId !== user?.id) return setShowUser(userId);
    addErrorMessage(
      translateText(
        'message',
        'You cannot manage your own settings for a customer center account.',
      ),
    );
  }

  return (
    <SettingsPage title={translateText('label', 'Users overview')}>
      <MccUserSetup showUser={showUser} close={() => setShowUser(false)} />
      <QueryTable<ResellerUser>
        tableKey={queryKey}
        columns={{
          user: { header: translateText('label', 'First and last name'), private: true },
          email: { header: translateText('label', 'Email address'), private: true },
          role: {
            header: { name: translateText('label', 'Rights'), tooltip: getRoleTooltip(isMccAdmin) },
            customValue: value => value?.description,
          },
          status_code: statusColumn,
          assigned_domains: {
            header: {
              name: translateText('label', 'Number of domains'),
              tooltip: translateText(
                'tooltip/mcc-users',
                'The total number of domains the user can access.',
              ),
            },
            customValue: (value, row) => (row.invited ? 0 : value.length),
          },
          is_admin: {
            header: {
              name: translateText('label', 'Admin'),
              tooltip: translateText(
                'tooltip/mcc-users',
                'The admin can invite other domains and users to the customer center.',
              ),
            },
            forceBoolean: true,
          },
        }}
        endpoint="/reseller/users"
        extraTopLeft={
          isMccAdmin ? (
            <AddButton
              isLoading={isLoading}
              onClick={() => setShowUser('new')}
              tooltipText={translateText('label', 'Invite user')}
            />
          ) : undefined
        }
        onRowClick={isMccAdmin ? row => onRowClick(row.user_id!) : undefined}
        pagination
        isLoading={isLoading}
        filters={{
          domain: false,
          custom: {
            reseller: selectedMcc,
            expand:
              'invitation_id,invited,status,status_code,last_login,email,assigned_domains,current_role',
          },
        }}
        download={{ csv: true, name: translateText('label', 'Customer center user overview') }}
      />
    </SettingsPage>
  );
}
