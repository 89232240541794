import { Formik } from 'formik';

import icon from 'assets/images/icons/integrations/billy-grace.svg';
import Toggle from 'components/input/Toggle';
import CostParagraph from 'components/integrations/CostParagraph';
import Skeleton from 'components/Skeleton';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import SupportParagraph from 'components/SupportParagraph';
import { INTEGRATION_BILLY_GRACE } from 'globals/constants';
import { SUP_ART_BILLY_GRACE } from 'globals/support';
import { useHasAccessToIntegration } from 'hooks/access';
import { useTogglePaidIntegration } from 'hooks/integrations';
import { useBillyGrace } from 'hooks/queries/integration';
import { useTranslate } from 'hooks/translate';

export default function BillyGrace({ show, close }: BaseSlideInProps) {
  const translateText = useTranslate();

  const title = translateText('label', 'Billy Grace');
  const PRICE = 49 as const;

  const hasAccessToIntegration = useHasAccessToIntegration();
  const toggleEnabled = useTogglePaidIntegration(title, PRICE);

  const { data, isLoading, updateBillyGrace } = useBillyGrace(show);

  if (!hasAccessToIntegration(INTEGRATION_BILLY_GRACE)) return null;

  return (
    <Formik
      initialValues={{ enabled: data?.enabled ?? 0 }}
      onSubmit={async values => await updateBillyGrace(values).then(close)}
      enableReinitialize
    >
      {({ values, setFieldValue, submitForm, isSubmitting, dirty, resetForm }) => (
        <Setup
          show={show}
          title={title}
          icon={icon}
          save={submitForm}
          close={close}
          afterClose={resetForm}
          isSaving={isSubmitting}
          maxWidth={440}
          hasChanges={dirty}
        >
          <p>
            {isLoading ? (
              <Skeleton count={3} />
            ) : (
              translateText(
                'integration',
                'Billy Grace provides marketing optimization software for ecommerce businesses. Using the data-driven attribution model, you can optimally use your marketing budget and increase the ROI of your campaigns.',
              )
            )}
          </p>
          <CostParagraph integration={title} price={PRICE} isLoading={isLoading} />
          <SupportParagraph articleId={SUP_ART_BILLY_GRACE} isLoading={isLoading} />
          <div className="section">
            <InputWrapper
              label={translateText('label', 'Send conversions')}
              tooltip={translateText(
                'integration',
                'Disable and enable the sending of calls to {integration} here.',
                { integration: title },
              )}
              isLoading={isLoading}
            >
              <Toggle
                checked={!!values.enabled}
                onClick={() => toggleEnabled(values.enabled, setFieldValue)}
                disabled={isSubmitting}
                isLoading={isLoading}
              />
            </InputWrapper>
          </div>
        </Setup>
      )}
    </Formik>
  );
}
