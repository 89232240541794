import { ChangeEvent } from 'react';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';

import icon from 'assets/images/icons/integrations/google-tag-manager.svg';
import ErrorFocus from 'components/ErrorFocus';
import Select from 'components/input/Select';
import TextField from 'components/input/TextField';
import Toggle from 'components/input/Toggle';
import { REDIRECT_URI_GOOGLE_TAG_MANAGER } from 'components/oauth2/Verification';
import VerificationButton from 'components/oauth2/VerificationButton';
import Skeleton from 'components/Skeleton';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import SupportParagraph from 'components/SupportParagraph';
import { useTenant } from 'contexts/Tenant';
import { SUP_ART_GOOGLE_TAG_MANAGER } from 'globals/support';
import { useSetQueryData } from 'hooks/data';
import { useGoogleTagManager } from 'hooks/queries/integration';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation } from 'hooks/validation';

export type GoogleTagManagerData = {
  verified: boolean;
  tags: Record<string, { account: string; container: string }>;
};

type FormValues = {
  verified: boolean;
  account: string;
  container: string;
  priority: number;
  liveOnly: boolean;
};

export default function GoogleTagManager({ show, close }: BaseSlideInProps) {
  const translateText = useTranslate();
  const { requiredSelect } = useCustomValidation();
  const { data, isLoading, publishTag } = useGoogleTagManager();
  const tag = data?.tags ? Object.values(data.tags)[0] : null;

  async function save(values: FormValues) {
    await publishTag(values).then(close);
  }

  const validationSchema = Yup.object({
    account: requiredSelect,
    container: Yup.string().test({
      test: function (value) {
        return this.parent.account === 'select' || value !== 'select';
      },
      message: translateText('message', 'This field is required.'),
    }),
  });

  return (
    <Formik
      initialValues={{
        verified: data?.verified ?? false,
        account: tag ? 'accounts/' + tag.account : 'select',
        container: tag ? 'accounts/' + tag.account + '/containers/' + tag.container : 'select',
        priority: 0,
        liveOnly: true,
      }}
      onSubmit={save}
      validationSchema={validationSchema}
      validateOnChange={false}
      enableReinitialize
    >
      {({ values, submitForm, isSubmitting, resetForm }) => (
        <Setup
          id="google-tag-manager"
          show={show}
          close={close}
          afterClose={resetForm}
          title={translateText('label', 'Google Tag Manager')}
          icon={icon}
          maxWidth={450}
          save={submitForm}
          saveButtonText={translateText('label', 'Place JavaScript')}
          isSaving={isSubmitting}
          saveButtonDisabled={!values.verified}
        >
          <p>
            {isLoading ? (
              <Skeleton count={2} />
            ) : (
              translateText(
                'integration',
                'With this integration you can directly place the JavaScript of AdCalls and publish it in Google Tag Manager.',
              )
            )}
          </p>
          <SupportParagraph articleId={SUP_ART_GOOGLE_TAG_MANAGER} isLoading={isLoading} />
          <h3 className="section-title">
            {isLoading ? <Skeleton width={200} /> : translateText('label', 'Connect account')}
          </h3>
          <Form />
        </Setup>
      )}
    </Formik>
  );
}

function Form() {
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { selectedDomain } = useTenant();

  const { values, handleChange, setFieldValue, errors, validateField, submitCount } =
    useFormikContext<FormValues>();
  const { accounts, containers, isLoading, isLoadingAccounts, isLoadingContainers } =
    useGoogleTagManager(values.account);

  return (
    <>
      <ErrorFocus element={document.getElementById('google-tag-manager')} />
      <VerificationButton
        verified={values.verified}
        disconnectEndpoint="/integration/google-tag-manager/disconnect"
        onDisconnectSuccess={() => {
          setQueryData<GoogleTagManagerData>(
            ['googleTagManager', 'view', selectedDomain],
            oldData => ({ ...oldData, verified: false }),
          );
          setFieldValue('account', 'select');
          setFieldValue('container', 'select');
        }}
        integrationName={translateText('label', 'Google Tag Manager')}
        redirectUri="/integration/google-tag-manager/redirect-uri"
        queryParams={{ type: 'call-tracking' }}
        redirectUriKey={REDIRECT_URI_GOOGLE_TAG_MANAGER}
        tooltip={translateText(
          'tooltip/google-tag-manager',
          'Grant access to the Google Tag Manager account so that the AdCalls JavaScript can be placed.',
        )}
        isLoading={isLoading}
        isGoogle
      />
      {values.verified && (
        <InputWrapper
          label={translateText('label', 'Account')}
          tooltip={translateText('tooltip/google-tag-manager', 'Select the desired account.')}
          isLoading={isLoading}
        >
          {isLoading || isLoadingAccounts ? (
            <Skeleton height={38} width={300} />
          ) : accounts && accounts?.length > 0 ? (
            <Select
              name="account"
              value={values.account}
              onChange={e => {
                handleChange(e);
                setTimeout(() => validateField('account'), 1); // Timeout is needed to validate the correct value
              }}
              options={accounts}
              optionValue="id"
              optionText="name"
              error={submitCount > 0 && errors.account}
              addSelect
            />
          ) : (
            translateText('label', 'No accounts found')
          )}
        </InputWrapper>
      )}
      {values.verified && values.account !== 'select' && (
        <InputWrapper
          label={translateText('label', 'Container')}
          isLoading={isLoading}
          tooltip={translateText(
            'tooltip/google-tag-manager',
            'Select the desired container in which you want to publish the AdCalls JavaScript.',
          )}
        >
          {isLoading || isLoadingContainers ? (
            <Skeleton height={38} width={300} />
          ) : containers && containers?.length > 0 ? (
            <Select
              name="container"
              value={values.container}
              onChange={e => {
                handleChange(e);
                setTimeout(() => validateField('container'), 1); // Timeout is needed to validate the correct value
              }}
              options={containers}
              optionValue="id"
              optionText="name"
              error={submitCount > 0 && errors.container}
              addSelect
            />
          ) : (
            translateText('label', 'No containers found')
          )}
        </InputWrapper>
      )}
      {values.verified && !isLoadingContainers && values.container !== 'select' && (
        <>
          <InputWrapper
            label={translateText('label', 'Priority')}
            isLoading={isLoading}
            tooltip={translateText(
              'tooltip/google-tag-manager',
              'Determine the priority. Tags with high priority values are activated first. If no priority is specified, the default value is 0. ',
            )}
          >
            <TextField
              value={values.priority}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFieldValue(
                  'priority',
                  Math.max(Number(e.target.value.replace(/[^0-9]/g, '')), 0),
                )
              }
              className="number"
            />
          </InputWrapper>
          <InputWrapper
            label={translateText('label', 'Publish in live containers only')}
            tooltip={translateText(
              'tooltip/google-tag-manager',
              'This setting prevents the tag from being triggered in a test environment. Disable this setting to also publish the tag in non-public environments.',
            )}
          >
            <Toggle name="liveOnly" checked={values.liveOnly} onClick={handleChange} />
          </InputWrapper>
        </>
      )}
    </>
  );
}
