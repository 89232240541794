import iconSalesforce from 'assets/images/icons/integrations/salesforce.svg';
import IntegrationRequest from 'components/integrations/IntegrationRequest';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import { INTEGRATION_SALESFORCE_REQUEST } from 'globals/constants';
import { useHasAccessToIntegration } from 'hooks/access';
import { useTranslate } from 'hooks/translate';

export default function SalesforceRequest({ show, close }: BaseSlideInProps) {
  const translateText = useTranslate();
  const hasAccessToIntegration = useHasAccessToIntegration();

  if (!hasAccessToIntegration(INTEGRATION_SALESFORCE_REQUEST)) return null;

  return (
    <IntegrationRequest
      show={show}
      title={translateText('label', 'Salesforce')}
      icon={iconSalesforce}
      infoText={translateText(
        'integration',
        'Salesforce is a cloud based platform for customer relationship management (CRM). With the help of this integration you can enrich contact profiles in Salesforce with data of incoming calls.',
      )}
      close={close}
      price={99}
    />
  );
}
