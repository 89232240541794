import { useState } from 'react';
import { Formik } from 'formik';

import ConfigurationCard from 'components/configuration/ConfigurationCard';
import GrayBars from 'components/GrayBars';
import Select from 'components/input/Select';
import Toggle from 'components/input/Toggle';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import SupportParagraph from 'components/SupportParagraph';
import { SUP_ART_MANUAL_TAGGING } from 'globals/support';
import { useManualTagging } from 'hooks/queries/domain-setting';
import { useTranslate } from 'hooks/translate';

type Props = {
  isLoading: boolean;
};

const ORDER_MAP = {
  0: 'Source - medium - campaign',
  1: 'Source - campaign - medium',
  2: 'Medium - source - campaign',
  3: 'Campaign - medium - source',
  4: 'Campaign - source - medium',
  5: 'Medium - campaign - source',
} as const;

export default function ManualTagging({ isLoading }: Props) {
  const translateText = useTranslate();
  const { manualTagging, updateManualTagging } = useManualTagging();
  const [showEdit, setShowEdit] = useState(false);

  async function save(values: { enabled: boolean; order: number }) {
    await updateManualTagging(values).then(() => {
      setShowEdit(false);
    });
  }

  function getRows() {
    if (!manualTagging?.enabled) return [];
    return [
      {
        key: translateText('label', 'Order for reading'),
        value: ORDER_MAP[(manualTagging?.order as keyof typeof ORDER_MAP) ?? 3],
      },
    ];
  }

  return (
    <>
      <ConfigurationCard
        title={translateText('label', 'Manual tagging')}
        supportArticleId={SUP_ART_MANUAL_TAGGING}
        onEdit={() => setShowEdit(true)}
        isLoading={isLoading}
        rightParagraph={translateText(
          'call-tracking',
          'Manual tagging allows you to better distinguish between different types of tracking.[manager] Would you like to set up or change manual tagging? Then click on the gears.[/manager]',
        )}
        enabled={manualTagging?.enabled === 1}
      >
        <GrayBars rows={getRows()} isLoading={isLoading} />
      </ConfigurationCard>
      <Formik
        initialValues={{ enabled: !!manualTagging?.enabled, order: manualTagging?.order ?? 3 }}
        onSubmit={save}
        enableReinitialize
      >
        {({ values, submitForm, resetForm, isSubmitting, dirty, handleChange }) => (
          <Setup
            show={showEdit}
            title={translateText('label', 'Set up manual tagging')}
            save={submitForm}
            close={() => setShowEdit(false)}
            afterClose={resetForm}
            isSaving={isSubmitting}
            maxWidth={375}
            hasChanges={dirty}
          >
            <SupportParagraph
              articleId={SUP_ART_MANUAL_TAGGING}
              extraText={translateText('call-tracking', 'Set up manual tagging.')}
            />
            <div className="section">
              <InputWrapper label={translateText('label', 'Manual tagging')}>
                <Toggle
                  name="enabled"
                  checked={values.enabled}
                  onClick={handleChange}
                  disabled={isSubmitting}
                />
              </InputWrapper>
              {values.enabled && (
                <InputWrapper label={translateText('label', 'Order for reading')}>
                  <Select
                    name="order"
                    value={values.order}
                    options={ORDER_MAP}
                    onChange={handleChange}
                  />
                </InputWrapper>
              )}
            </div>
          </Setup>
        )}
      </Formik>
    </>
  );
}
