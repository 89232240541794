import { ReactElement, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Formik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import ConfigurationCard from 'components/configuration/ConfigurationCard';
import ErrorFocus from 'components/ErrorFocus';
import Checkbox from 'components/input/Checkbox';
import Select from 'components/input/Select';
import StarRating from 'components/input/StarRating';
import TextField from 'components/input/TextField';
import Skeleton from 'components/Skeleton';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import SupportParagraph from 'components/SupportParagraph';
import { useMessages } from 'contexts/Messages';
import { useTenant } from 'contexts/Tenant';
import { SUP_ART_DOMAIN_STOP } from 'globals/support';
import { useTrial } from 'hooks/access';
import { useSetQueryData } from 'hooks/data';
import { DomainProperties, useProperties, useStopDomain } from 'hooks/queries/domain';
import { useCancelChangeRequest } from 'hooks/queries/domain-change-request';
import { useNumberFormats } from 'hooks/queries/domain-setting';
import { useTranslate } from 'hooks/translate';
import { useUserDateFormat } from 'hooks/user';

type FormValues = {
  agreed: boolean;
  reason: string;
  explanation: string;
  rating: number;
};

export default function StopDomainSection() {
  const queryClient = useQueryClient();
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const dateFormat = useUserDateFormat();

  const { addSuccessMessage } = useMessages();
  const { isTrial, trialMonth } = useTrial();
  const { selectedDomain } = useTenant();

  const { stopDomain } = useStopDomain();
  const { cancelWithoutConfirm } = useCancelChangeRequest();
  const { isLoading: isLoadingFormats } = useNumberFormats();
  const { data, isLoading: isLoadingProperties } = useProperties();
  const isLoading = isLoadingProperties || isLoadingFormats;

  const [showEdit, setShowEdit] = useState(false);

  async function save(values: FormValues) {
    if (!data?.stop_requested) {
      await stopDomain(values).then(() => setShowEdit(false));
    } else {
      await cancelWithoutConfirm(data.stop_requested.id).then(async () => {
        queryClient.invalidateQueries({ queryKey: ['domainAuth', selectedDomain] });
        setQueryData<DomainProperties>(['domainView', selectedDomain], oldData => ({
          ...oldData,
          stop_requested: null,
        }));
        addSuccessMessage(
          translateText(
            'message',
            'The request for stopping the domain has been successfully canceled.',
          ),
        );
        setShowEdit(false);
      });
    }
  }

  let text: ReactElement | string = <Skeleton count={3} />;
  if (!isLoading) {
    if (data?.stop_requested === null) {
      text = translateText(
        'text',
        'Do you want to permanently stop using AdCalls for this domain? Then click on the gears. We use a notice period of at least one month. The cancellation request and the definitive cancellation will be confirmed by e-mail.',
      );
      if (isTrial) {
        text = translateText(
          'text',
          'Do you want to permanently stop using AdCalls for this domain? Then click on the gears. No cancellation period applies to trial subscriptions. The cancellation request and the definitive cancellation will be confirmed by e-mail.',
        );
      }
    } else {
      const stopDate = moment(data?.stop_requested.date, 'YYYY-MM-DD').format(dateFormat);
      text = translateText(
        'text',
        'There is a request stop this domain. The domain will be stopped on {stopDate}. Do you want to revoke the stop? Then click on the gears.',
        { stopDate },
      );
      if (data?.stop_requested.status === 4) {
        text = translateText('text', 'The domain has been stopped on {stopDate}.', { stopDate });
      }
    }
  }

  let setupText = translateText(
    'domain',
    'Are you sure you want to cancel the request to stop this domain?',
  );
  if (data?.stop_requested === null) {
    setupText = translateText('domain', 'Are you sure you want to stop this domain?') + ' ';
    if (isTrial && trialMonth) {
      setupText += translateText('domain', 'The domain will be stopped as of {stopDate}.', {
        stopDate: moment(trialMonth, 'DD-MM-YYYY').endOf('month').format(dateFormat),
      });
    } else {
      const stopDate = data.contract_end_date
        ? moment(data.contract_end_date)
        : moment().add(1, 'month'); // The stop date is always at least 1 month from now
      if (data.contract_end_date) {
        if (moment().format('YYYY-MM') !== stopDate.format('YYYY-MM')) {
          // The contact does not end this month, so the stop date is the end of the contract
          setupText += translateText(
            'domain',
            'Due to the contract duration, the domain will be stopped as of {date}.',
            { date: stopDate.format(dateFormat) },
          );
        } else {
          // Contract ends this month, so stop date is next month
          stopDate.add(1, 'month').endOf('month');
          setupText += translateText(
            'domain',
            'Due to the contract duration and one-month notice period, the domain will be stopped as of {date}.',
            { date: stopDate.format(dateFormat) },
          );
        }
      } else {
        const createdOn = moment(data.start_date, 'YYYY-MM-DD HH:mm:ss');
        const startDate = moment(createdOn).add(1, 'month').startOf('month');
        const monthsActive = moment().diff(startDate, 'month');
        if (monthsActive < 1) {
          // Domain has not started yet or is in it's first month, because of the minimum period of 3 months the stop date needs extra months
          const extraMonths = createdOn.format('MM') === moment().format('MM') ? 2 : 1;
          stopDate.add(extraMonths, 'month');
          setupText += translateText(
            'domain',
            'Because of the minimum subscription duration of three months and the stop term of one calendar month, the domain will be stopped as of {stopDate}.',
            { stopDate: stopDate.endOf('month').format(dateFormat) },
          );
        } else {
          setupText += translateText(
            'domain',
            'Because of the stop term of one calendar month, the domain will be stopped as of {stopDate}.',
            { stopDate: stopDate.endOf('month').format(dateFormat) },
          );
        }
      }

      const stopMonth = stopDate.format('MMMM YYYY');
      const invoiceMonth = stopDate.add(1, 'month').format('MMMM YYYY');
      setupText +=
        ' ' +
        translateText(
          'domain',
          'You will receive the last invoice in {invoiceMonth} for the period {stopMonth}.',
          { stopMonth, invoiceMonth },
        );
    }
  }

  const validationSchema = Yup.object({
    reason: Yup.string().test({
      test: value => data?.stop_requested !== null || value !== 'select',
      message: translateText('message', 'This field is required.'),
    }),
    explanation: Yup.string().test({
      test: value => data?.stop_requested !== null || !!value?.trim(),
      message: translateText('message', 'This field is required.'),
    }),
    rating: Yup.number().test({
      test: value => data?.stop_requested !== null || value !== 0,
      message: translateText('message', 'This field is required.'),
    }),
  });

  return (
    <>
      <ConfigurationCard
        onEdit={() => setShowEdit(true)}
        title={translateText('label', 'Stop domain')}
        isLoading={isLoading}
      >
        <p>{text}</p>
      </ConfigurationCard>
      <Formik
        initialValues={{ agreed: false, reason: 'select', explanation: '', rating: 0 }}
        onSubmit={save}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({
          values,
          handleChange,
          setFieldValue,
          submitForm,
          dirty,
          isSubmitting,
          resetForm,
          errors,
          submitCount,
        }) => (
          <Setup
            id="stop-domain"
            show={showEdit}
            close={() => setShowEdit(false)}
            afterClose={resetForm}
            title={
              data?.stop_requested
                ? translateText('label', 'Cancel stop request')
                : translateText('label', 'Stop domain')
            }
            save={submitForm}
            hasChanges={dirty}
            isSaving={isSubmitting}
            saveButtonText={
              data?.stop_requested
                ? translateText('label', 'Cancel stop request')
                : translateText('label', 'Stop domain')
            }
            saveButtonDisabled={!values.agreed}
            saveButtonColor={data?.stop_requested ? 'green' : 'purple'}
            maxWidth={data?.stop_requested ? 360 : 520}
            appendage={
              <Checkbox
                id="domain-stop-agree"
                labelClassName="bold extra-margin"
                name="agreed"
                checked={values.agreed}
                onChange={handleChange}
                label={
                  data?.stop_requested === null
                    ? translateText('domain', 'Yes, I agree to stop this domain')
                    : translateText(
                        'domain',
                        'Yes, I agree to cancel the request to stop this domain',
                      )
                }
              />
            }
          >
            <ErrorFocus element={document.getElementById('stop-domain')} />
            <p className="purple-text">{setupText}</p>
            <SupportParagraph articleId={SUP_ART_DOMAIN_STOP} />
            {data?.stop_requested === null && (
              <>
                <InputWrapper label={translateText('domain', 'Why do you want to quit AdCalls?')}>
                  <Select
                    name="reason"
                    value={values.reason}
                    onChange={handleChange}
                    addSelect
                    options={{
                      1: translateText('domain', 'We got the most out of AdCalls'),
                      2: translateText(
                        'domain',
                        'The client is switching to another marketing agency',
                      ),
                      3: translateText(
                        'domain',
                        'We are experiencing technical implementation issues',
                      ),
                      4: translateText(
                        'domain',
                        "We don't want to show a dynamic phone number on the website",
                      ),
                      5: translateText('domain', 'We want to reduce costs'),
                      6: translateText('domain', 'The added value is not clear'),
                      7: translateText('domain', 'We are stopping with marketing'),
                      8: translateText('domain', 'Other, namely ...'),
                    }}
                    className="full"
                    error={submitCount > 0 && errors.reason}
                  />
                </InputWrapper>
                <InputWrapper
                  label={translateText('domain', 'Explain your reason further')}
                  fieldClassName="full"
                >
                  <TextField
                    name="explanation"
                    type="textarea"
                    value={values.explanation}
                    onChange={handleChange}
                    wrapperClassName="full"
                    error={submitCount > 0 && errors.explanation}
                  />
                </InputWrapper>
                <InputWrapper
                  label={translateText('domain', 'How much would you recommend AdCalls?')}
                >
                  <StarRating
                    value={values.rating}
                    onChange={value => setFieldValue('rating', value)}
                    error={submitCount > 0 && errors.rating}
                  />
                </InputWrapper>
              </>
            )}
          </Setup>
        )}
      </Formik>
    </>
  );
}
