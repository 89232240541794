import { useTranslate } from './translate';

export const DOMAIN_SETTING_SESSIONS_MAX = 353 as const;
export const DOMAIN_SETTING_SESSIONS_CURRENT = 354 as const;
export const DOMAIN_SETTING_IMPLEMENTATION_TYPE = 380 as const;
export const DOMAIN_SETTING_SCRIPT_SETTING_DEBUG = 381 as const;
export const DOMAIN_SETTING_SCRIPT_SETTING_ADVANCED_MULTI_FETCH = 383 as const;
export const DOMAIN_SETTING_SCRIPT_SETTING_REPLACE_ANCHOR_ATTRIBUTES = 386 as const;
export const DOMAIN_SETTING_NUMBER_FORMAT = 400 as const;
export const DOMAIN_SETTING_INVOICING_TEMPORARY_EXCLUDE = 407 as const;
export const DOMAIN_SETTING_BING_ADS = 460 as const;
export const DOMAIN_SETTING_OUTBOUND_SIP_PEER = 498 as const;
export const DOMAIN_SETTING_CALLRECORDINGNOTIFICATION = 503 as const;
export const DOMAIN_SETTING_PLAYBEEPS = 504 as const;
export const DOMAIN_SETTING_DISABLE_SPAMMED_NUMBER = 511 as const;
export const DOMAIN_SETTING_CALL_EMAIL_NOTIFICATION = 546 as const;
export const DOMAIN_SETTING_REPORT_EMAIL = 551 as const;
export const DOMAIN_SETTING_UTMORDERING = 618 as const;
export const DOMAIN_SETTING_MICROSOFT_DYNAMICS = 679 as const;
export const DOMAIN_SETTING_WEBSOLVE = 680 as const;
export const DOMAIN_SETTING_SOLEO = 681 as const;
export const DOMAIN_SETTING_ADFORM = 682 as const;
export const DOMAIN_SETTING_B_GENIUS = 687 as const;
export const DOMAIN_SETTING_RELAY42 = 688 as const;
export const DOMAIN_SETTING_ZOHO = 689 as const;
export const DOMAIN_SETTING_PIPEDRIVE = 690 as const;
export const DOMAIN_SETTING_GOOGLE_TAG_MANAGER = 692 as const;
export const DOMAIN_SETTING_SALESFORCE = 693 as const;
export const DOMAIN_SETTING_GOOGLE_SEARCH_ADS_360 = 694 as const;
export const DOMAIN_SETTING_GOOGLE_DISPLAY_VIDEO_360 = 695 as const;
export const DOMAIN_SETTING_HUB_SPOT = 696 as const;
export const DOMAIN_SETTING_BLUE_CONIC = 697 as const;
export const DOMAIN_SETTING_GOOGLE_DOUBLE_CLICK = 698 as const;
export const DOMAIN_SETTING_GOOGLE_ADS = 699 as const;
export const DOMAIN_SETTING_ACCEPTED_URLS = 700 as const;
export const DOMAIN_SETTING_BLOCK_LIGHTHOUSE_SESSIONS = 711 as const;
export const DOMAIN_SETTING_BLOCK_SAFEFRAME_SESSIONS = 712 as const;
export const DOMAIN_SETTING_SOURCES_TRACKED = 722 as const;
export const DOMAIN_SETTING_DEFAULT_POOL_SETUP = 723 as const;
export const DOMAIN_SETTING_DOMAIN_SESSION_FILTER = 750 as const;
export const DOMAIN_SETTING_PORTALS_PAGE = 801 as const;
export const DOMAIN_SETTING_VOIP_VENDOR_NATIONAL = 902 as const;
export const DOMAIN_SETTING_VOIP_VENDOR_INTERNATIONAL = 903 as const;
export const DOMAIN_SETTING_ATTRIBUTION_MODEL = 998 as const;

type DomainSettingOption = {
  type?: 'text' | 'list' | 'object' | 'numberFormat';
  options?: { value: string; text: string }[] | string[];
  canEdit?: boolean;
};

export function useDomainSettingOptions() {
  const translateText = useTranslate();

  const yesNoOptions = [
    { value: '0', text: translateText('label', 'No') },
    { value: '1', text: translateText('label', 'Yes') },
  ];

  const onOffOptions = [
    { value: 'false', text: translateText('label', 'Off') },
    { value: 'true', text: translateText('label', 'On') },
  ];

  const options: Record<string, DomainSettingOption> = {
    [DOMAIN_SETTING_SESSIONS_MAX]: { canEdit: false },
    [DOMAIN_SETTING_SESSIONS_CURRENT]: { canEdit: false },
    [DOMAIN_SETTING_IMPLEMENTATION_TYPE]: {
      type: 'list',
      options: [
        { value: '0', text: translateText('label', 'Standard implementation') },
        { value: '1', text: translateText('label', 'Advanced implementation') },
      ],
    },
    [DOMAIN_SETTING_SCRIPT_SETTING_DEBUG]: { type: 'list', options: onOffOptions },
    [DOMAIN_SETTING_SCRIPT_SETTING_ADVANCED_MULTI_FETCH]: {
      type: 'list',
      options: onOffOptions,
    },
    [DOMAIN_SETTING_SCRIPT_SETTING_REPLACE_ANCHOR_ATTRIBUTES]: { type: 'object' },
    [DOMAIN_SETTING_NUMBER_FORMAT]: { type: 'numberFormat' },
    [DOMAIN_SETTING_INVOICING_TEMPORARY_EXCLUDE]: { canEdit: false },
    [DOMAIN_SETTING_BING_ADS]: { type: 'object' },
    [DOMAIN_SETTING_OUTBOUND_SIP_PEER]: {
      type: 'list',
      options: [
        { value: 'in2voice', text: 'Steam-connect' },
        { value: 'Motto', text: 'Dstny' },
        { value: 'problemen-kwijt', text: 'Dstny Problemen Kwijt' },
      ],
    },
    [DOMAIN_SETTING_CALLRECORDINGNOTIFICATION]: {
      type: 'list',
      options: [
        { value: 'FALSE', text: translateText('label', 'Disabled') },
        { value: 'CALLRECORDING', text: translateText('label', 'Dutch') },
        { value: 'CALLRECORDING_EN', text: translateText('label', 'English') },
        { value: 'CALLRECORDING_FR', text: translateText('label', 'French') },
      ],
    },
    [DOMAIN_SETTING_PLAYBEEPS]: { type: 'list', options: onOffOptions },
    [DOMAIN_SETTING_DISABLE_SPAMMED_NUMBER]: { type: 'list', options: yesNoOptions },
    [DOMAIN_SETTING_CALL_EMAIL_NOTIFICATION]: { type: 'object' },
    [DOMAIN_SETTING_REPORT_EMAIL]: { type: 'object' },
    [DOMAIN_SETTING_UTMORDERING]: {
      type: 'list',
      options: [
        { value: '0', text: 'source-medium-campaign' },
        { value: '1', text: 'source-campaign-medium' },
        { value: '2', text: 'medium-source-campaign' },
        { value: '3', text: 'campaign-medium-source' },
        { value: '4', text: 'campaign-source-medium' },
        { value: '5', text: 'medium-campaign-source' },
      ],
    },
    [DOMAIN_SETTING_MICROSOFT_DYNAMICS]: { type: 'object' },
    [DOMAIN_SETTING_WEBSOLVE]: { type: 'object' },
    [DOMAIN_SETTING_SOLEO]: { type: 'object' },
    [DOMAIN_SETTING_ADFORM]: { type: 'object' },
    [DOMAIN_SETTING_B_GENIUS]: { type: 'object' },
    [DOMAIN_SETTING_RELAY42]: { type: 'object' },
    [DOMAIN_SETTING_ZOHO]: { type: 'object' },
    [DOMAIN_SETTING_PIPEDRIVE]: { type: 'object' },
    [DOMAIN_SETTING_GOOGLE_TAG_MANAGER]: { type: 'object' },
    [DOMAIN_SETTING_SALESFORCE]: { type: 'object' },
    [DOMAIN_SETTING_GOOGLE_SEARCH_ADS_360]: { type: 'object' },
    [DOMAIN_SETTING_GOOGLE_DISPLAY_VIDEO_360]: { type: 'object' },
    [DOMAIN_SETTING_HUB_SPOT]: { type: 'object' },
    [DOMAIN_SETTING_BLUE_CONIC]: { type: 'object' },
    [DOMAIN_SETTING_GOOGLE_DOUBLE_CLICK]: { type: 'object' },
    [DOMAIN_SETTING_GOOGLE_ADS]: { type: 'object' },
    [DOMAIN_SETTING_BLOCK_LIGHTHOUSE_SESSIONS]: { type: 'list', options: yesNoOptions },
    [DOMAIN_SETTING_BLOCK_SAFEFRAME_SESSIONS]: { type: 'list', options: yesNoOptions },
    [DOMAIN_SETTING_SOURCES_TRACKED]: { type: 'object' },
    [DOMAIN_SETTING_DEFAULT_POOL_SETUP]: { type: 'object' },
    [DOMAIN_SETTING_DOMAIN_SESSION_FILTER]: {
      type: 'list',
      options: [
        { value: '0', text: translateText('label', 'Off') },
        { value: '1', text: translateText('label', 'Exclude (standard)') },
        { value: '2', text: translateText('label', 'Specific (inverted)') },
      ],
    },
    [DOMAIN_SETTING_PORTALS_PAGE]: { type: 'list', options: yesNoOptions },
    [DOMAIN_SETTING_VOIP_VENDOR_NATIONAL]: {
      type: 'list',
      options: ['Voxbone', 'In2Voice', 'Destiny'],
    },
    [DOMAIN_SETTING_VOIP_VENDOR_INTERNATIONAL]: {
      type: 'list',
      options: ['Voxbone', 'In2Voice', 'Destiny'],
    },
    [DOMAIN_SETTING_ATTRIBUTION_MODEL]: {
      type: 'list',
      options: [
        { value: '0', text: translateText('label', 'First click') },
        {
          value: '1',
          text: translateText('label', 'First indirect click (prefer AdWords & BingCPC)'),
        },
      ],
    },
  };

  return options;
}
