import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

import { AppProviders } from 'contexts/Global';
import { PH_MASK } from 'globals/constants';
import App from './wrappers/App';

import './styles/main.scss';
import 'react-loading-skeleton/dist/skeleton.css';

if (__PROD__) {
  TagManager.initialize({ gtmId: process.env.REACT_APP_TAG_MANAGER_ID ?? '' });
  posthog.init(process.env.REACT_APP_POSTHOG_ID ?? '', {
    api_host: 'https://eu.posthog.com',
    autocapture: false,
    session_recording: {
      maskTextSelector: `${PH_MASK}, [data-private]`,
    },
  });
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <StrictMode>
    <PostHogProvider client={posthog}>
      <AppProviders>
        <App />
      </AppProviders>
    </PostHogProvider>
  </StrictMode>,
);
