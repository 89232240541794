import { FC, useState } from 'react';
import { useNavigate } from 'react-router';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';

import { focusErrorElement } from 'components/ErrorFocus';
import CallTrackingSection, {
  CallTrackingFormValues,
  callTrackingInitialValues,
} from 'components/intake/CallTrackingSection';
import { invoiceProfileInitialValues } from 'components/intake/InvoiceProfile';
import PropertiesSection from 'components/intake/PropertiesSection';
import SummarySection from 'components/intake/SummarySection';
import ThanksSection from 'components/intake/ThanksSection';
import Wrapper, { Type } from 'components/intake/Wrapper';
import PersistForm from 'components/PersistForm';
import { createError, useMessages } from 'contexts/Messages';
import { useCanRequestDomain } from 'hooks/queries/domain';
import { useCreateIntake, useUpdateIntake } from 'hooks/queries/domain-intake';
import { InvoiceProfile } from 'hooks/queries/invoice-profile';
import { useUserMcc } from 'hooks/queries/user';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation, useValidationSchema } from 'hooks/validation';

export enum IntakeSection {
  Properties = 'properties',
  CallTracking = 'call-tracking',
  Summary = 'summary',
  Thanks = 'thanks',
}

export type IntakeFormValues = {
  id?: number;
  section: IntakeSection;
  validated: { [key in IntakeSection]?: boolean | 'concept' };
  urlScheme: 'http://' | 'https://';
  url: string;
  pricingPlanId: string;
  contract_duration: string;
  reseller_id: string;
  invoice_profile_id: string;
  termsAndConditions: boolean;
  agreement: boolean;
  privacy: boolean;
  deliver_immediately: boolean;
  remarks: string;
} & InvoiceProfile &
  CallTrackingFormValues;

export default function Intake() {
  const navigate = useNavigate();
  const translateText = useTranslate();
  const { addSuccessMessage } = useMessages();
  const { mcc } = useUserMcc();
  const { required, requiredSelect } = useCustomValidation();
  const { invoiceProfileValidation, termsAndConditionsValidation } = useValidationSchema();
  const { checkCanRequest } = useCanRequestDomain();
  const { createIntake, isLoading: isCreatingIntake } = useCreateIntake();
  const { updateIntake, isLoading: isUpdatingIntake } = useUpdateIntake();

  const [lastValidatedUrl, setLastValidatedUrl] = useState('');

  const sections = Object.values(IntakeSection);

  function getSectionComponent(section: IntakeSection): [string, FC] {
    switch (section) {
      case IntakeSection.Properties:
        return [translateText('label', 'Properties'), PropertiesSection];
      case IntakeSection.CallTracking:
        return [translateText('label', 'Call Tracking'), CallTrackingSection];
      case IntakeSection.Summary:
        return [translateText('label', 'Summary'), SummarySection];
      case IntakeSection.Thanks:
        return [translateText('label', 'Sent'), ThanksSection];
    }
  }

  const resellers = mcc?.filter(mcc => mcc.id !== -1) ?? [];
  const validationSchema = Yup.lazy(values => {
    switch (values.section) {
      case IntakeSection.Properties:
        return invoiceProfileValidation.shape({
          ...(resellers.length >= 1 && { reseller_id: requiredSelect }),
          url: required.matches(/^(.+)\.(.+)$/, translateText('message', 'Invalid URL.')),
          pricingPlanId: requiredSelect,
          contract_duration: requiredSelect,
        });
      case IntakeSection.CallTracking:
        return Yup.object({
          included: Yup.mixed()
            .nullable()
            .test({
              message: translateText(
                'message',
                'Please select at least one traffic source before proceeding.',
              ),
              test: value => value !== null,
            }),
          format: Yup.mixed()
            .nullable()
            .test({
              message: translateText('message', 'Select a phone number format before proceeding.'),
              test: value => value !== null,
            }),
          offsite_numbers: Yup.array().test({
            test: function (value) {
              return !!value?.length || this.parent.destinations !== 'offsite_only';
            },
            message: translateText(
              'message',
              'Request at least one location or offsite phone number before proceeding.',
            ),
          }),
        });
      case IntakeSection.Summary:
        return termsAndConditionsValidation.shape({
          remarks: Yup.string().test({
            test: function (value) {
              return !!this.parent.deliver_immediately || !!value;
            },
            message: translateText(
              'message',
              'Use the comment field for additional information about the later delivery of your intake.',
            ),
          }),
        });
      default:
        return Yup.object();
    }
  });

  async function onSaveConcept(
    values: IntakeFormValues,
    setFieldValue: FormikProps<IntakeFormValues>['setFieldValue'],
    resetForm: FormikProps<IntakeFormValues>['resetForm'],
  ) {
    if (!values.url || values.pricingPlanId === 'select' || values.contract_duration === 'select') {
      setFieldValue('validated', { [IntakeSection.Properties]: 'concept' }); // URL, pricing plan and contract duration are the only property that listen to validated: 'concept'
      focusErrorElement();
      return;
    }

    const promise = values.id
      ? updateIntake({ intake: { ...values, id: values.id }, concept: true })
      : createIntake({ intake: values, concept: true });
    await promise.then(() => {
      resetForm();
      addSuccessMessage(
        translateText(
          'message',
          'The intake is saved as a draft. Do not forget to send the intake.',
        ),
      );
    });
  }

  async function onSubmit(
    values: IntakeFormValues,
    formikHelpers: FormikHelpers<IntakeFormValues>,
  ) {
    const nextSection = sections[sections.indexOf(values.section) + 1];

    formikHelpers.setFieldValue('validated', { ...values.validated, [values.section]: true });
    if (values.section === IntakeSection.Summary) {
      const promise = values.id
        ? updateIntake({ intake: { ...values, id: values.id } })
        : createIntake({ intake: values });
      await promise.then(() => formikHelpers.setFieldValue('section', IntakeSection.Thanks));
    } else if (values.section === IntakeSection.Thanks) {
      formikHelpers.resetForm();
      navigate('/requests/overview');
    } else if (values.section === IntakeSection.Properties) {
      if (values.urlScheme + values.url !== lastValidatedUrl) {
        await checkCanRequest(values.urlScheme + values.url)
          .then(() => {
            setLastValidatedUrl(values.urlScheme + values.url);
            formikHelpers.setFieldValue('section', nextSection);
          })
          .catch(error => formikHelpers.setFieldError('url', createError(error) as string));
      } else {
        formikHelpers.setFieldValue('section', nextSection);
      }
    } else {
      formikHelpers.setFieldValue('section', nextSection);
    }
  }

  return (
    <Formik<IntakeFormValues>
      initialValues={{
        section: IntakeSection.Properties,
        validated: {},
        urlScheme: 'https://',
        url: '',
        pricingPlanId: 'select',
        contract_duration: 'select',
        reseller_id: resellers.length === 1 ? String(resellers[0].id) : 'select',
        ...invoiceProfileInitialValues,
        ...callTrackingInitialValues,
        termsAndConditions: false,
        agreement: false,
        privacy: false,
        deliver_immediately: true,
        remarks: '',
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialErrors={{ section: '' }}
    >
      {({ values, setFieldValue, resetForm }) => {
        const [title, Section] = getSectionComponent(values.section);
        return (
          <Wrapper
            type={Type.Intake}
            title={`${translateText('label', 'Request domain')} - ${title}`}
            disabled={isCreatingIntake || isUpdatingIntake}
            onSaveConcept={() => onSaveConcept(values, setFieldValue, resetForm)}
          >
            <PersistForm name="intake" />
            <Section />
          </Wrapper>
        );
      }}
    </Formik>
  );
}
