import { useState } from 'react';
import { useNavigate } from 'react-router';
import { FormikHelpers } from 'formik';

import ContentSidebar from 'components/unauthenticated/ContentSidebar';
import LoginNewsPage from 'components/unauthenticated/LoginNewsPage';
import RegistrationForm, { FormValues } from 'components/unauthenticated/RegistrationForm';
import { useMessages } from 'contexts/Messages';
import { useRegister } from 'hooks/queries/auth';
import { useTranslate } from 'hooks/translate';

export default function RequestAccount() {
  const navigate = useNavigate();
  const translateText = useTranslate();
  const { addErrorMessage } = useMessages();
  const { register } = useRegister();

  const [registeredEmail, setRegisteredEmail] = useState('');

  async function save(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    const email = values.email.trim();
    await register({
      company: values.company.trim(),
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      phonenumber: values.phonenumber.trim(),
      email,
      password: values.password,
      verifyPassword: values.passwordVerification,
    })
      .then(() => setRegisteredEmail(email))
      .catch(error => {
        if (error.password) formikHelpers.setFieldError('password', error.password[0]);
        else addErrorMessage(error);
      });
  }

  function getContent() {
    if (registeredEmail !== '') {
      return (
        <div id="request-account-info">
          <h2>{translateText('label', 'Thanks for registering!')}</h2>
          <p
            dangerouslySetInnerHTML={{
              __html: translateText(
                'text',
                'Activate your account so you can get started with AdCalls right away. An email with further instructions has been sent to <span data-private>{email}</span>.',
                { email: registeredEmail },
              ),
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: translateText(
                'text',
                'Do you have any questions? Contact us by email at <a href="mailto:support@adcalls.nl">support@adcalls.nl</a> or call <a href="tel:+31307400990">+31 (0)30 7400 990</a>.',
              ),
            }}
          />
          <button className="btn btn-green btn-wide" onClick={() => navigate('/login')}>
            {translateText('label', 'Go to the login page')}
          </button>
        </div>
      );
    }

    return (
      <RegistrationForm
        save={save}
        title={translateText('label', 'Create account')}
        intro={
          <>
            <p>
              {translateText(
                'text',
                'Nice that you are going to create an account for our dashboard. Enter your information and get started with the services of AdCalls. Your account will be activated once you have confirmed the verification link. This will be sent to you per email immediately after registering.',
              )}
            </p>
            <p
              dangerouslySetInnerHTML={{
                __html: translateText(
                  'text',
                  'Do you have any questions? Contact us by email at <a href="mailto:support@adcalls.nl">support@adcalls.nl</a> or call <a href="tel:+31307400990">+31 (0)30 7400 990</a>.',
                ),
              }}
            />
          </>
        }
        submitLabel={translateText('label', 'Create account')}
      />
    );
  }
  return <ContentSidebar left={getContent()} right={<LoginNewsPage />} />;
}
