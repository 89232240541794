import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  title: string;
  subtitle?: string;
  content: ReactElement;
  buttonText?: string;
  link?: string;
  image: string;
};

export default function ImagePage({ title, subtitle, content, buttonText, link, image }: Props) {
  return (
    <div className="image-page">
      <h1>{title}</h1>
      <div className="content white-block">
        {subtitle && <h2>{subtitle}</h2>}
        {content}
        {buttonText && link && (
          <div className="button-wrapper">
            <Link to={link}>
              <button className="btn btn-lightblue">{buttonText}</button>
            </Link>
          </div>
        )}
      </div>
      <div className="image-wrapper">
        <img alt="" src={image} />
      </div>
    </div>
  );
}
