import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import iconBlueConic from 'assets/images/icons/integrations/blue-conic.svg';
import ErrorFocus from 'components/ErrorFocus';
import Select from 'components/input/Select';
import TextField from 'components/input/TextField';
import Toggle from 'components/input/Toggle';
import CostParagraph from 'components/integrations/CostParagraph';
import Skeleton from 'components/Skeleton';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import SupportParagraph from 'components/SupportParagraph';
import { useMessages } from 'contexts/Messages';
import { INTEGRATION_BLUE_CONIC, PLAN_ENTERPRISE } from 'globals/constants';
import { SUP_ART_BLUECONIC } from 'globals/support';
import { useHasAccessToIntegration } from 'hooks/access';
import { useTogglePaidIntegration } from 'hooks/integrations';
import { useBlueConic } from 'hooks/queries/app';
import { useTranslate } from 'hooks/translate';

export type BlueConicData = {
  address: string;
  createProfile: boolean;
  enabled: boolean;
  match: { type: string; key: string };
  token: string;
};

const MATCH_TYPE_PROFILE_ID = 'profileId' as const;
const MATCH_TYPE_VISITOR_ID = 'visitorId' as const;
const MATCH_TYPE_GOOGLE_CID = 'googleCID' as const;

export default function BlueConic({ show, close }: BaseSlideInProps) {
  const translateText = useTranslate();
  const { addErrorMessage } = useMessages();

  const title = translateText('label', 'BlueConic');
  const PRICE = 49 as const;

  const hasAccessToIntegration = useHasAccessToIntegration();
  const toggleEnabled = useTogglePaidIntegration(title, PRICE);

  const { blueConic, isLoading, updateBlueConic } = useBlueConic(show);

  if (!hasAccessToIntegration(INTEGRATION_BLUE_CONIC)) return null;

  async function save(values: BlueConicData, formikHelpers: FormikHelpers<BlueConicData>) {
    await updateBlueConic(values)
      .then(close)
      .catch(error => {
        if (error.address) formikHelpers.setFieldError('address', error.address[0]);
        else addErrorMessage(error);
      });
  }

  const validationSchema = Yup.object({
    address: Yup.string().required(translateText('message', 'This field is required.')),
    token: Yup.string().required(translateText('message', 'This field is required.')),
    match: Yup.object({ key: Yup.string(), type: Yup.string() }).test({
      test: function (value) {
        if (
          this.parent.createProfile === true &&
          value.type !== MATCH_TYPE_PROFILE_ID &&
          !value.key?.trim()
        ) {
          return this.createError({
            path: 'match.key',
            message: translateText('message', 'This field is required.'),
          });
        }
        return true;
      },
    }),
  });

  return (
    <Formik
      initialValues={{
        enabled: blueConic?.enabled ?? false,
        createProfile: blueConic?.createProfile ?? false,
        address: blueConic?.address ?? '',
        match: {
          type: blueConic?.match.type ?? MATCH_TYPE_VISITOR_ID,
          key: blueConic?.match.key ?? '',
        },
        token: blueConic?.token ?? '',
      }}
      onSubmit={save}
      validationSchema={validationSchema}
      validateOnChange={false}
      enableReinitialize
    >
      {({
        values,
        handleChange,
        setFieldValue,
        submitForm,
        errors,
        isSubmitting,
        dirty,
        resetForm,
      }) => (
        <Setup
          id="blueconic"
          show={show}
          title={title}
          icon={iconBlueConic}
          save={submitForm}
          close={close}
          afterClose={resetForm}
          isSaving={isSubmitting}
          maxWidth={440}
          hasChanges={dirty}
        >
          <ErrorFocus element={document.getElementById('blueconic')} />
          <p>
            {isLoading ? (
              <Skeleton count={3} />
            ) : (
              translateText(
                'integration',
                'BlueConic is a platform for collecting customer data online based on which relevant personal communication can take place.',
              )
            )}
          </p>
          <CostParagraph
            integration={title}
            price={PRICE}
            plan={PLAN_ENTERPRISE}
            isLoading={isLoading}
          />
          <SupportParagraph articleId={SUP_ART_BLUECONIC} isLoading={isLoading} />
          <div className="section">
            <InputWrapper
              label={translateText('label', 'Send conversions')}
              tooltip={translateText(
                'integration',
                'Disable and enable the sending of calls to {integration} here.',
                { integration: title },
              )}
              isLoading={isLoading}
            >
              <Toggle
                name="enabled"
                checked={values.enabled}
                onClick={() => toggleEnabled(values.enabled, setFieldValue)}
                disabled={isSubmitting}
                isLoading={isLoading}
              />
            </InputWrapper>
            <InputWrapper
              label={translateText('label', 'API key')}
              tooltip={translateText(
                'tooltip/blueconic',
                'Enter the BlueConic API key here. This can be found at the Webhook connection.',
              )}
              isLoading={isLoading}
            >
              {isLoading ? (
                <Skeleton height={38} width={300} />
              ) : (
                <TextField
                  name="token"
                  value={values.token}
                  onChange={handleChange}
                  disabled={!values.enabled || isSubmitting}
                  error={errors.token}
                />
              )}
            </InputWrapper>
            <InputWrapper
              label={translateText('label', 'Request URL')}
              tooltip={translateText(
                'tooltip/blueconic',
                'Enter the desired request URL from BlueConic that was obtained when creating the incoming webhook here. The correct URL can be obtained by copying the URL under "Copy link".',
              )}
              isLoading={isLoading}
            >
              {isLoading ? (
                <Skeleton height={38} width={300} />
              ) : (
                <TextField
                  name="address"
                  value={values.address}
                  onChange={handleChange}
                  disabled={!values.enabled || isSubmitting}
                  error={errors.address}
                />
              )}
            </InputWrapper>
            <InputWrapper
              label={translateText('label', 'Create new profile')}
              tooltip={translateText(
                'tooltip/blueconic',
                'Determine whether to create a new profile if the call cannot be linked to an existing profile.',
              )}
              isLoading={isLoading}
            >
              <Toggle
                name="createProfile"
                onClick={handleChange}
                checked={values.createProfile}
                disabled={!values.enabled || isSubmitting}
                isLoading={isLoading}
              />
            </InputWrapper>
            <InputWrapper
              label={translateText('label', 'Match type')}
              tooltip={translateText('tooltip/blueconic', 'Select the desired matching type.')}
              isLoading={isLoading}
            >
              {isLoading ? (
                <Skeleton height={38} width={300} />
              ) : (
                <Select
                  name="match.type"
                  onChange={handleChange}
                  value={values.match.type}
                  options={{
                    [MATCH_TYPE_VISITOR_ID]: translateText('label', 'AdCalls visitor ID'),
                    [MATCH_TYPE_PROFILE_ID]: translateText('label', 'BlueConic profile ID'),
                    [MATCH_TYPE_GOOGLE_CID]: translateText('label', 'Google client ID'),
                  }}
                  disabled={!values.enabled || isSubmitting}
                />
              )}
            </InputWrapper>
            <InputWrapper
              label={translateText('label', 'Match key')}
              tooltip={translateText(
                'tooltip/blueconic',
                'Enter the desired match key here, as it is known in BlueConic.',
              )}
              isLoading={isLoading}
            >
              {isLoading ? (
                <Skeleton height={38} width={300} />
              ) : (
                <TextField
                  name="match.key"
                  onChange={handleChange}
                  value={values.match.key}
                  disabled={!values.enabled || isSubmitting}
                  error={errors.match?.key}
                />
              )}
            </InputWrapper>
          </div>
        </Setup>
      )}
    </Formik>
  );
}
