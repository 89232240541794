import { Route, Routes } from 'react-router';

import Verification, { REDIRECT_URI_GOOGLE_TAG_MANAGER } from 'components/oauth2/Verification';
import AuthenticatedRoute from 'components/routes/AuthenticatedRoute';
import { DebugModeProvider } from 'contexts/DebugMode';
import { ROLE_ADMIN } from 'globals/constants';
import CallTrackingCallFunctionalities from 'pages/authenticated/call-tracking/config/CallFunctionalities';
import DebugMode from 'pages/authenticated/call-tracking/config/DebugMode';
import CallTrackingImplementation from 'pages/authenticated/call-tracking/config/Implementation';
import Locations from 'pages/authenticated/call-tracking/config/Locations';
import OffsitePhoneNumbers from 'pages/authenticated/call-tracking/config/OffsitePhoneNumbers';
import SendReports from 'pages/authenticated/call-tracking/config/SendReports';
import TrackingAndFilters from 'pages/authenticated/call-tracking/config/TrackingAndFilters';
import Error404 from 'pages/Error404';

export default function Config() {
  return (
    <Routes>
      <Route path="/tracking-and-filters" element={<TrackingAndFilters />} />
      <Route path="/locations" element={<Locations />} />
      <Route path="/offsite-phone-numbers" element={<OffsitePhoneNumbers />} />
      <Route path="/implementation" element={<CallTrackingImplementation />} />
      <Route
        path="/implementation/verify"
        element={<Verification redirectUriKey={REDIRECT_URI_GOOGLE_TAG_MANAGER} />}
      />
      <Route path="/call-functionalities" element={<CallTrackingCallFunctionalities />} />
      <Route path="/send-reports" element={<SendReports />} />
      <Route
        path="/debug-mode"
        element={
          <AuthenticatedRoute role={ROLE_ADMIN}>
            <DebugModeProvider>
              <DebugMode />
            </DebugModeProvider>
          </AuthenticatedRoute>
        }
      />
      <Route path="/*" element={<Error404 />} />
    </Routes>
  );
}
