import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { kebabCase, mapValues, omit } from 'lodash';

import { GoogleTagManagerData } from 'components/configuration/implementation/GoogleTagManager';
import { CrmIntegrationData } from 'components/integrations/BaseCrmIntegration';
import { Google360Data, Google360Type } from 'components/integrations/BaseGoogle360';
import {
  ConversionActionSetting,
  GOOGLE_ADS_ENHANCED_CONVERSIONS_KEY,
  GOOGLE_ADS_KEY,
  GoogleAdsSetting,
  GoogleAdsType,
} from 'components/integrations/google-ads/BaseGoogleAds';
import { useMessages } from 'contexts/Messages';
import { useTenant } from 'contexts/Tenant';
import {
  INTEGRATION_ACTIVE,
  INTEGRATION_DOUBLE_CLICK,
  INTEGRATION_GOOGLE_DISPLAY_VIDEO_360,
  INTEGRATION_GOOGLE_SEARCH_ADS_360,
  INTEGRATION_INACTIVE,
} from 'globals/constants';
import { Offsite } from 'globals/types';
import { useSetQueryData } from 'hooks/data';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';
import { AdformData } from 'pages/authenticated/integrations/advertising/Adform';
import { MetaAdsData } from 'pages/authenticated/integrations/advertising/MetaAds';
import { LefSettings } from 'pages/authenticated/integrations/crm/Lef';
import { BigQueryData } from 'pages/authenticated/integrations/data-management/BigQuery';
import { VwoData } from 'pages/authenticated/integrations/web-analysis/Vwo';

export function useStatuses() {
  const { selectedDomain: id } = useTenant();
  const { fetchData } = useFetch();

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ['integrationStatuses', id],
    queryFn: (): Promise<Record<string, 0 | 1 | 2>> =>
      fetchData('/integration/general/statuses', { queryParams: { id, type: 'call-tracking' } }),
    enabled: id !== null,
  });

  return { statuses: data, isLoading };
}

export function useGoogleAds(type: GoogleAdsType, enabled: boolean) {
  const queryClient = useQueryClient();
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const endpointBase = type === GOOGLE_ADS_KEY ? 'google-advertising' : 'google-ads-ec';
  const enabledKey = type === GOOGLE_ADS_KEY ? 'enabled_call_tracking' : 'enabled';

  const { data, isFetching: isLoading } = useQuery({
    queryKey: [type, selectedDomain],
    queryFn: (): Promise<GoogleAdsSetting<typeof type>> =>
      fetchData(`/integration/${endpointBase}/view`, {
        queryParams: { id: selectedDomain, expand: 'verified' },
      }),
    enabled: selectedDomain !== null && enabled,
    meta: { noError: true }, // When there is no data for google ads it gives a 404 not found
  });

  const { data: conversionActions, isFetching: isLoadingConversionActions } = useQuery({
    queryKey: [type, 'conversionActions', selectedDomain],
    queryFn: (): Promise<ConversionActionSetting<typeof type>[]> =>
      fetchData(`/integration/${endpointBase}/conversion-actions`, {
        queryParams: {
          domain_id: selectedDomain,
          expand: 'conditions',
          ...(type === GOOGLE_ADS_KEY ? { type: 'call' } : {}),
        },
      }),
    enabled: selectedDomain !== null && enabled,
  });

  const { mutateAsync: updateGoogleAds } = useMutation({
    mutationFn: (values: GoogleAdsSetting<typeof type>) =>
      fetchData(`/integration/${endpointBase}/update`, {
        method: 'PUT',
        queryParams: { id: selectedDomain },
        bodyParams: {
          customer_id: values.customer_id,
          [enabledKey]: values[enabledKey],
        },
      }),
    onSuccess: (data, values) => {
      let status: number = INTEGRATION_INACTIVE;
      if (
        values.verified &&
        values[enabledKey] &&
        queryClient
          .getQueryData<
            ConversionActionSetting<typeof type>[]
          >([type, 'conversionActions', selectedDomain])
          ?.some(action => action.enabled)
      ) {
        status = INTEGRATION_ACTIVE;
      }
      setQueryData<Record<string, number>>(['integrationStatuses', selectedDomain], oldData => ({
        ...oldData,
        [type]: status,
      }));
      setQueryData<GoogleAdsSetting<typeof type>>([type, selectedDomain], oldData => ({
        ...data,
        verified: oldData.verified,
      }));
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return {
    data,
    isLoading,
    conversionActions,
    isLoadingConversionActions,
    updateGoogleAds,
  };
}

export function useGoogleAdsConversionAction(type: GoogleAdsType) {
  const queryClient = useQueryClient();
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { addErrorMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const endpointBase = type === GOOGLE_ADS_KEY ? 'google-advertising' : 'google-ads-ec';
  const enabledKey = type === GOOGLE_ADS_KEY ? 'enabled_call_tracking' : 'enabled';

  const { mutateAsync: createAction, isPending: isCreating } = useMutation({
    mutationFn: (values: ConversionActionSetting<typeof type>) =>
      fetchData(`/integration/${endpointBase}/create-conversion-action`, {
        method: 'POST',
        queryParams: { expand: 'conditions' },
        bodyParams: {
          ...omit(values, ['id', 'resource_name']),
          domain_id: selectedDomain,
          ...(type === GOOGLE_ADS_KEY ? { type: 'call' } : {}),
        },
      }),
    onSuccess: (data, values) => {
      const setting = queryClient.getQueryData<GoogleAdsSetting<typeof type>>([
        type,
        selectedDomain,
      ]);
      if (setting?.[enabledKey] && values.enabled) {
        setQueryData<Record<string, number>>(['integrationStatuses', selectedDomain], oldData => ({
          ...oldData,
          [type]: INTEGRATION_ACTIVE,
        }));
      }
      setQueryData<ConversionActionSetting<typeof type>[]>(
        [type, 'conversionActions', selectedDomain],
        oldData => [...oldData, data.conversion_action_settings],
      );
      addSuccessMessage(data.message);
    },
  });

  const { mutateAsync: updateAction, isPending: isUpdating } = useMutation({
    mutationFn: (values: ConversionActionSetting<typeof type>) =>
      fetchData(`/integration/${endpointBase}/update-conversion-action`, {
        method: 'PUT',
        queryParams: { id: values.id, expand: 'conditions' },
        bodyParams: {
          ...omit(values, ['resource_name']),
          domain_id: selectedDomain,
          ...(type === GOOGLE_ADS_KEY ? { type: 'call' } : {}),
        },
      }),
    onSuccess: data => {
      setQueryData<ConversionActionSetting<typeof type>[]>(
        [type, 'conversionActions', selectedDomain],
        oldData => {
          const setting = queryClient.getQueryData<GoogleAdsSetting<typeof type>>([
            type,
            selectedDomain,
          ]);
          let status: number = INTEGRATION_INACTIVE;
          const conversionActions = oldData.map(oldAction => {
            const newAction = oldAction.id === data.id ? data : oldAction;
            if (setting?.[enabledKey] && newAction.enabled) {
              status = INTEGRATION_ACTIVE;
            }
            return newAction;
          });
          setQueryData<Record<string, number>>(
            ['integrationStatuses', selectedDomain],
            oldData => ({
              ...oldData,
              [type]: status,
            }),
          );
          return conversionActions;
        },
      );
      addSuccessMessage(
        translateText(
          'message',
          "The conversion action's settings have been updated. Check the conversion action in your Google Ads account.",
        ),
      );
    },
  });

  const { mutateAsync: loadAction, isPending: isLoadingAction } = useMutation({
    mutationFn: ({ name }: { name: string; hasResourceName: boolean }) =>
      fetchData(`/integration/${endpointBase}/load-conversion-action`, {
        method: 'GET',
        queryParams: {
          id: selectedDomain,
          name,
          ...(type === GOOGLE_ADS_KEY ? { type: 'call' } : {}),
        },
      }),
    meta: { noError: true },
    onSuccess: data => {
      setQueryData<ConversionActionSetting<typeof type>[]>(
        [type, 'conversionActions', selectedDomain],
        oldData =>
          oldData.map(conversionAction =>
            conversionAction.name === data.name ? data : conversionAction,
          ),
      );
      addSuccessMessage(
        translateText('message', 'The conversion action has been loaded successfully.'),
      );
    },
    onError: (data: { status: number; message: string }, variables) => {
      if (data.status === 404) {
        addErrorMessage(
          translateText(
            'message',
            "The conversion action with the name '{name}' could not be found. Place a new conversion action or try to load another one.",
            { name: variables.name },
          ),
        );
      } else {
        addErrorMessage(data.message);
      }
    },
  });

  const { mutateAsync: syncAction, isPending: isSyncingAction } = useMutation({
    mutationFn: (id: number) =>
      fetchData(`/integration/${endpointBase}/sync-conversion-action`, {
        method: 'PUT',
        queryParams: { id },
      }),
    onSuccess: (syncAction, id) => {
      setQueryData<ConversionActionSetting<typeof type>[]>(
        [type, 'conversionActions', selectedDomain],
        oldData =>
          oldData.map(conversionAction =>
            conversionAction.id === id ? syncAction : conversionAction,
          ),
      );
      addSuccessMessage(
        translateText('message', 'The conversion action has been loaded successfully.'),
      );
    },
  });

  const { mutateAsync } = useMutation({
    mutationFn: (id: number) =>
      fetchData(`/integration/${endpointBase}/delete-conversion-action`, {
        method: 'DELETE',
        queryParams: { id },
      }),
    onSuccess: (_, id) =>
      setQueryData<ConversionActionSetting<typeof type>[]>(
        [type, 'conversionActions', selectedDomain],
        oldData => {
          const setting = queryClient.getQueryData<GoogleAdsSetting<typeof type>>([
            type,
            selectedDomain,
          ]);
          let status: number = INTEGRATION_INACTIVE;
          const conversionActions = oldData.filter(conversionAction => {
            if (conversionAction.id === id) return false;
            if (setting?.[enabledKey] && conversionAction.enabled) {
              status = INTEGRATION_ACTIVE;
            }
            return true;
          });
          setQueryData<Record<string, number>>(
            ['integrationStatuses', selectedDomain],
            oldData => ({
              ...oldData,
              [type]: status,
            }),
          );
          return conversionActions;
        },
      ),
  });

  async function deleteAction(id: number) {
    if (
      window.confirm(
        translateText('message', 'Are you sure you want to remove this conversion action?'),
      )
    ) {
      await mutateAsync(id).then(() =>
        addSuccessMessage(translateText('message', 'The conversion action has been removed')),
      );
    }
  }

  return {
    createAction,
    isCreating,
    updateAction,
    isUpdating,
    loadAction,
    syncAction,
    isLoading: isLoadingAction || isSyncingAction,
    deleteAction,
  };
}

export function useOffsitesForGoogleAds(enabled = true) {
  const { selectedDomain } = useTenant();
  const { fetchData } = useFetch();

  const { data } = useQuery({
    queryKey: [GOOGLE_ADS_ENHANCED_CONVERSIONS_KEY, 'offsites', selectedDomain],
    queryFn: (): Promise<Offsite[]> =>
      fetchData('/integration/google-ads-ec/get-offsite-filter', {
        queryParams: { domain_id: selectedDomain },
      }),
    enabled: selectedDomain !== null && enabled,
  });

  return { offsiteNumbers: data };
}

export function useGoogle360(
  type: Google360Type,
  integrationKey:
    | typeof INTEGRATION_DOUBLE_CLICK
    | typeof INTEGRATION_GOOGLE_DISPLAY_VIDEO_360
    | typeof INTEGRATION_GOOGLE_SEARCH_ADS_360,
  enabled: boolean,
) {
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain: id } = useTenant();

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ['google360', type, id],
    queryFn: (): Promise<Google360Data> =>
      fetchData('/integration/google-' + type + '/view', { queryParams: { id } }),
    enabled: enabled && id !== null,
  });

  const { mutateAsync: updateGoogle360 } = useMutation({
    mutationFn: (values: Google360Data) =>
      fetchData('/integration/google-' + type + '/update', {
        method: 'PUT',
        queryParams: { id },
        bodyParams: omit(values, 'verified'),
      }),
    onSuccess: (data, values) => {
      setQueryData<Record<string, number>>(['integrationStatuses', id], oldData => ({
        ...oldData,
        [integrationKey]: values.enabled && !values.verified ? 2 : +values.enabled,
      }));
      setQueryData(['google360', type, id], () => values);
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { data, isLoading, updateGoogle360 };
}

export function useCrmIntegration(integrationKey: string, enabled: boolean) {
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain: id } = useTenant();

  const { data, isFetching: isLoading } = useQuery({
    queryKey: [integrationKey, id],
    queryFn: (): Promise<CrmIntegrationData> =>
      fetchData(`/integration/${kebabCase(integrationKey)}/view`, { queryParams: { id } }),
    enabled: id !== null && enabled,
  });

  const { mutateAsync: updateCrmIntegration } = useMutation({
    mutationFn: (values: CrmIntegrationData) =>
      fetchData(`/integration/${kebabCase(integrationKey)}/update`, {
        method: 'PUT',
        queryParams: { id },
        bodyParams: {
          enabled: !!values.enabled,
          ...(values.createMissingContact !== undefined && {
            createMissingContact: !!values.createMissingContact,
          }),
          ...(values.sendExtraData !== undefined && {
            sendExtraData: !!values.sendExtraData,
          }),
        },
      }),
    onSuccess: (data, values) => {
      setQueryData<Record<string, number>>(['integrationStatuses', id], oldData => ({
        ...oldData,
        [integrationKey]: values.enabled && !values.verified ? 2 : values.enabled,
      }));
      setQueryData([integrationKey, id], () => values);
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { data, isLoading, updateCrmIntegration };
}

export function useAdform(enabled: boolean) {
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain: id } = useTenant();

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ['adform', id],
    queryFn: (): Promise<AdformData> =>
      fetchData('/integration/adform/view', { queryParams: { id } }),
    enabled: id !== null && enabled,
  });

  const { mutateAsync: updateAdform } = useMutation({
    mutationFn: (values: AdformData) =>
      fetchData('/integration/adform/update', {
        method: 'PUT',
        queryParams: { id },
        bodyParams: {
          enabled: values.enabled,
          client_tracking_id: values.clientTrackingId,
          tracking_point_name: values.trackingPointName,
        },
      }),
    onSuccess: (data, values) => {
      setQueryData<Record<string, number>>(['integrationStatuses', id], oldData => ({
        ...oldData,
        adform: values.enabled,
      }));
      setQueryData(['adform', id], () => values);
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { data, isLoading, updateAdform };
}

export function useLef(enabled: boolean) {
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { selectedDomain: id } = useTenant();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ['lef', id],
    queryFn: (): Promise<LefSettings> =>
      fetchData('/integration/lef/view', { queryParams: { id } }),
    enabled: id !== null && enabled,
  });

  const { mutateAsync: updateLef } = useMutation({
    mutationFn: (
      settings: Omit<LefSettings, 'locations'> & { locations: Record<string, string | null> },
    ) =>
      fetchData('/integration/lef/update', {
        method: 'PUT',
        queryParams: { id },
        bodyParams: settings,
      }),
    onSuccess: (_, settings) => {
      setQueryData<Record<string, number>>(['integrationStatuses', id], oldData => ({
        ...oldData,
        lef: +settings.enabled,
      }));

      const updatedLocations = mapValues(data?.locations, (location, identifier) => {
        const newValue = settings?.locations[identifier];
        if (newValue !== undefined) return { ...location, value: newValue };
        return location;
      });

      setQueryData(['lef', id], () => ({ ...settings, locations: updatedLocations }));
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { lef: data, isLoading, updateLef };
}

export function useGoogleTagManager(selectedAccount?: string) {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const { selectedDomain: id } = useTenant();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ['googleTagManager', 'view', id],
    queryFn: (): Promise<GoogleTagManagerData> =>
      fetchData('/integration/google-tag-manager/view', { queryParams: { id } }),
    enabled: id !== null,
  });

  const { data: accounts, isFetching: isLoadingAccounts } = useQuery({
    queryKey: ['googleTagManager', 'accounts', id],
    queryFn: (): Promise<{ id: string; name: string }[]> =>
      fetchData('/integration/google-tag-manager/account', { queryParams: { id } }),
    enabled: id !== null && !!data?.verified,
  });

  const { data: containers, isFetching: isLoadingContainers } = useQuery({
    queryKey: ['googleTagManager', 'containers', id, selectedAccount],
    queryFn: (): Promise<{ id: string; name: string }[]> =>
      fetchData('/integration/google-tag-manager/container', {
        queryParams: { id, account: selectedAccount },
      }),
    enabled: id !== null && !!selectedAccount && selectedAccount !== 'select',
  });

  const { mutateAsync: publishTag } = useMutation({
    mutationFn: (values: { container: string; priority: number; liveOnly: boolean }) =>
      fetchData('/integration/google-tag-manager/publish', {
        method: 'POST',
        queryParams: { id, container: values.container },
        bodyParams: {
          priority: values.priority,
          live_only: values.liveOnly,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['googleTagManager', 'view', id] });
      addSuccessMessage(
        translateText('message', 'The JavaScript has been published in Google Tag Manager.'),
      );
    },
  });

  return {
    data,
    accounts,
    containers,
    isLoading,
    isLoadingAccounts,
    isLoadingContainers,
    publishTag,
  };
}

export function useBillyGrace(enabled: boolean) {
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain: id } = useTenant();

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ['billyGrace', id],
    queryFn: (): Promise<{ enabled: 0 | 1 }> =>
      fetchData('/integration/billy-grace/view', { queryParams: { id } }),
    enabled: id !== null && enabled,
  });

  const { mutateAsync: updateBillyGrace } = useMutation({
    mutationFn: (values: { enabled: 0 | 1 }) =>
      fetchData('/integration/billy-grace/update', {
        method: 'PUT',
        queryParams: { id },
        bodyParams: { enabled: !!values.enabled },
      }),
    onSuccess: (data, values) => {
      setQueryData<Record<string, number>>(['integrationStatuses', id], oldData => ({
        ...oldData,
        billyGrace: +values.enabled,
      }));
      setQueryData(['billyGrace', id], () => values);
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { data, isLoading, updateBillyGrace };
}

export function useBigQuery(enabled: boolean) {
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain: id } = useTenant();

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ['bigquery', id],
    queryFn: (): Promise<BigQueryData> =>
      fetchData('/integration/big-query/view', { queryParams: { id } }),
    enabled: id !== null && enabled,
  });

  const { mutateAsync: updateBigQuery } = useMutation({
    mutationFn: (values: BigQueryData) =>
      fetchData('/integration/big-query/update', {
        method: 'PUT',
        queryParams: { id },
        bodyParams: {
          enabled: !!values.enabled,
          projectId: values.projectId,
        },
      }),

    onSuccess: (data, values) => {
      setQueryData<Record<string, number>>(['integrationStatuses', id], oldData => ({
        ...oldData,
        bigquery: +values.enabled,
      }));
      setQueryData(['bigquery', id], () => values);
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { data, isLoading, updateBigQuery };
}

export function useVwo(enabled: boolean) {
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ['vwo', selectedDomain],
    queryFn: (): Promise<VwoData[]> =>
      fetchData('/integration/vwo/index', {
        queryParams: { domain_id: selectedDomain, expand: 'conditions' },
      }),
    enabled: selectedDomain !== null && enabled,
  });

  const { mutateAsync: createVwo } = useMutation({
    mutationFn: (values: VwoData) =>
      fetchData('/integration/vwo/create', {
        method: 'POST',
        queryParams: { expand: 'conditions' },
        bodyParams: { ...values, domain_id: selectedDomain },
      }),
    onSuccess: (data, values) => {
      setQueryData<Record<string, number>>(['integrationStatuses', selectedDomain], oldData => ({
        ...oldData,
        vwo: +values.enabled,
      }));
      setQueryData<VwoData[]>(['vwo', selectedDomain], oldData => [
        { ...oldData[0], ...values, id: data.id },
      ]);
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  const { mutateAsync: updateVwo } = useMutation({
    mutationFn: (values: VwoData) =>
      fetchData('/integration/vwo/update', {
        method: 'PUT',
        queryParams: { id: values.id, expand: 'conditions' },
        bodyParams: { ...values },
      }),
    onSuccess: (_, values) => {
      setQueryData<Record<string, number>>(['integrationStatuses', selectedDomain], oldData => ({
        ...oldData,
        vwo: +values.enabled,
      }));
      setQueryData<VwoData[]>(['vwo', selectedDomain], oldData => [{ ...oldData[0], ...values }]);
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { data, isLoading, createVwo, updateVwo };
}

export function useMetaAds(enabled: boolean) {
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain: id } = useTenant();

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ['metaAds', id],
    queryFn: (): Promise<MetaAdsData> =>
      fetchData('/integration/meta-ads/view', { queryParams: { id } }),
    enabled: id !== null && enabled,
    meta: { noError: true }, // When there is no data for meta ads it gives a 404 not found
  });

  const { mutateAsync: updateMetaAds } = useMutation({
    mutationFn: (values: MetaAdsData) => {
      const isNew = data === undefined;
      return fetchData(`/integration/meta-ads/${isNew ? 'create' : 'update'}`, {
        method: isNew ? 'POST' : 'PUT',
        queryParams: { id },
        bodyParams: { domain_id: id, ...values },
      });
    },
    onSuccess: (data, values) => {
      setQueryData<Record<string, number>>(['integrationStatuses', id], oldData => ({
        ...oldData,
        metaAds: +values.enabled,
      }));
      setQueryData<MetaAdsData>(['metaAds', id], () => values);
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { data, isLoading, updateMetaAds };
}
