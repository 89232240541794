import { useState } from 'react';
import { Formik } from 'formik';
import { difference, isEmpty } from 'lodash';

import ConfigurationPage from 'components/configuration/ConfigurationPage';
import SendReportCard from 'components/configuration/send-reports/SendReportCard';
import SetupSendReport from 'components/configuration/send-reports/SetupSendReport';
import ErrorFocus from 'components/ErrorFocus';
import RestrictedAccess from 'components/RestrictedAccess';
import { FREQUENCY_MONTHLY, FREQUENCY_OFF, FREQUENCY_WEEKLY, ROLE_ADMIN } from 'globals/constants';
import { useReportEmail } from 'hooks/queries/domain-setting';
import { useTranslate } from 'hooks/translate';

type ReportSetting = {
  emailAddresses: string[];
  frequency: typeof FREQUENCY_MONTHLY | typeof FREQUENCY_OFF | typeof FREQUENCY_WEEKLY;
  deletedEmails?: Record<string, string>[];
};

export type ReportEmailSettings = {
  history: ReportSetting;
  reports: ReportSetting;
  prestation: ReportSetting;
};

export default function SendReports() {
  const translateText = useTranslate();
  const { reportEmailSettings, updateReportEmail } = useReportEmail();
  const [showPrestation, setShowPrestation] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [showReports, setShowReports] = useState(false);

  async function save(values: ReportEmailSettings) {
    Object.values(values).forEach((reportEmailSetting: ReportSetting) => {
      reportEmailSetting.emailAddresses = difference(
        reportEmailSetting.emailAddresses,
        reportEmailSetting.deletedEmails?.map(item => item.email) ?? [],
      );
    });

    await updateReportEmail(values).then(() => {
      setShowPrestation(false);
      setShowHistory(false);
      setShowReports(false);
    });
  }

  function validate(values: ReportEmailSettings) {
    const errors: Record<string, Record<string, string>> = {};
    if (values.history.frequency !== FREQUENCY_OFF && isEmpty(values.history.emailAddresses)) {
      errors.history = {
        emailAddresses: translateText('message', 'Add at least one email address.'),
      };
    }
    if (
      values.prestation.frequency !== FREQUENCY_OFF &&
      isEmpty(values.prestation.emailAddresses)
    ) {
      errors.prestation = {
        emailAddresses: translateText('message', 'Add at least one email address.'),
      };
    }
    if (values.reports.frequency !== FREQUENCY_OFF && isEmpty(values.reports.emailAddresses)) {
      errors.reports = {
        emailAddresses: translateText('message', 'Add at least one email address.'),
      };
    }
    return errors;
  }

  return (
    <ConfigurationPage title={translateText('label', 'Periodic report')}>
      <RestrictedAccess role={ROLE_ADMIN} show="ribbon">
        <SendReportCard
          settingsKey="prestation"
          onEdit={() => setShowPrestation(true)}
          title={translateText('label', 'Prestation report')}
        />
      </RestrictedAccess>
      <SendReportCard
        settingsKey="history"
        onEdit={() => setShowHistory(true)}
        title={translateText('label', 'History report')}
      />
      <SendReportCard
        settingsKey="reports"
        onEdit={() => setShowReports(true)}
        title={translateText('label', 'Call Tracking report')}
      />
      <Formik
        initialValues={reportEmailSettings}
        onSubmit={async values => await save(values)}
        validate={validate}
        enableReinitialize
      >
        <>
          <ErrorFocus />
          <SetupSendReport
            settingsKey="prestation"
            show={showPrestation}
            close={() => setShowPrestation(false)}
            title={translateText('label', 'Prestation report')}
          />
          <SetupSendReport
            settingsKey="history"
            show={showHistory}
            close={() => setShowHistory(false)}
            title={translateText('label', 'History report')}
          />
          <SetupSendReport
            settingsKey="reports"
            show={showReports}
            close={() => setShowReports(false)}
            title={translateText('label', 'Call Tracking report')}
          />
        </>
      </Formik>
    </ConfigurationPage>
  );
}
