import { Navigate } from 'react-router';

import DomainProperties from 'components/settings/properties/DomainProperties';
import MccProperties from 'components/settings/properties/MccProperties';
import { useTenant } from 'contexts/Tenant';

export default function Properties() {
  const { selectedMcc, selectedDomain } = useTenant();
  if (selectedDomain) return <DomainProperties />;
  if (selectedMcc && selectedMcc !== -1) return <MccProperties />;
  return <Navigate to="/overview" />;
}
