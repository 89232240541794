import { useRouteMap, useRoutes } from 'contexts/Routes';
import { useSession } from 'contexts/Session';
import MainItem from './MainItem';

export default function Sidebar() {
  const { token } = useSession();
  const {
    navigationDisabled,
    displaySidebarExtension,
    setDisplaySidebarExtension,
    selectedSubItems,
  } = useRoutes();
  const routeMap = useRouteMap();

  function renderMenu() {
    if (!token || navigationDisabled) {
      return null;
    }

    const hamburgerItem = !selectedSubItems.length ? (
      <div key="hamburger" className="hamburger-icon-container" />
    ) : (
      <div key="hamburger" className="hamburger-icon-container">
        <div
          className="sidebar-item"
          onClick={() => setDisplaySidebarExtension(!displaySidebarExtension)}
        >
          <div
            className={'hamburger' + (displaySidebarExtension ? ' open' : '')}
            data-testid="hamburger"
          >
            <div />
            <div />
            <div />
          </div>
        </div>
      </div>
    );

    const top = [hamburgerItem];
    const bottom = routeMap[routeMap.length - 1].map((item, index) => (
      <MainItem key={index} item={item} />
    ));

    if (routeMap.length === 2) {
      routeMap[0].forEach((item, index) => top.push(<MainItem key={index} item={item} />));
    }

    return (
      <>
        <div>{top}</div>
        <div className="bottom-items">{bottom}</div>
      </>
    );
  }

  return <div id="sidebar">{renderMenu()}</div>;
}
