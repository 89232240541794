import { includes } from 'lodash';

import icon from 'assets/images/icons/integrations/hubspot.svg';
import BaseCrmIntegration from 'components/integrations/BaseCrmIntegration';
import { REDIRECT_URI_HUBSPOT } from 'components/oauth2/Verification';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import { useTenant } from 'contexts/Tenant';
import { INTEGRATION_HUBSPOT, MERIDE, VIABOVAG, VIABOVAG_TEST } from 'globals/constants';
import { SUP_ART_HUBSPOT } from 'globals/support';
import { useHasAccessToIntegration } from 'hooks/access';
import { useTranslate } from 'hooks/translate';

export default function HubSpot({ show, close }: BaseSlideInProps) {
  const translateText = useTranslate();
  const { selectedDomain } = useTenant();
  const hasAccessToIntegration = useHasAccessToIntegration();

  if (!hasAccessToIntegration(INTEGRATION_HUBSPOT)) return null;

  return (
    <BaseCrmIntegration
      show={show}
      close={close}
      icon={icon}
      integrationKey="hubspot"
      redirectUriKey={REDIRECT_URI_HUBSPOT}
      title={translateText('label', 'HubSpot')}
      articleId={SUP_ART_HUBSPOT}
      infoText={translateText(
        'integration',
        'HubSpot is a tool for managing sales, marketing and customer service activities. With the help of this integration, you can enrich the contact details in HubSpot with data from incoming calls.',
      )}
      canAddContact={selectedDomain !== MERIDE}
      canSendExtraData={!includes([VIABOVAG, VIABOVAG_TEST, MERIDE], selectedDomain)}
      includeOptimize
      price={99}
    />
  );
}
