export const SUP_ART_URL = '/knowledge-center/article/';

// Configuration Call Tracking
// Implementation
export const SUP_ART_OFFSITE = 136 as const;
export const SUP_ART_TECHNICAL_IMPLEMENTATION = 137 as const;
export const SUP_ART_PERIODIC_REPORTS = 140 as const;
export const SUP_ART_STANDARD_IMPLEMENTATION = 141 as const;
export const SUP_ART_ADVANCED_IMPLEMENTATION = 142 as const;
export const SUP_ART_GOOGLE_TAG_MANAGER = 249 as const;

// Tracking and Filters
export const SUP_ART_SOURCES = 138 as const;
export const SUP_ART_MANUAL_TAGGING = 143 as const;
export const SUP_ART_SESSION_STOP = 145 as const;
export const SUP_ART_IP_FILTER = 144 as const;

// Call Functionalities
export const SUP_ART_CALL_RECORDING_CT = 162 as const;
export const SUP_ART_CALL_SELECTION_MENU = 163 as const;
export const SUP_ART_EVALUATION_MENU = 164 as const;
export const SUP_ART_ANNOUNCEMENT = 165 as const;
export const SUP_ART_CALL_NOTIFICATIONS = 166 as const;
export const SUP_ART_CALL_RECORDING_FT = 194 as const;
export const SUP_ART_NUMBER_FORMAT = 197 as const;
export const SUP_ART_BEEP = 212 as const;

// Integrations
export const SUP_ART_GOOGLE_ADS = 131 as const;
export const SUP_ART_MICROSOFT_ADVERTISING = 167 as const;
export const SUP_ART_LOOKER_STUDIO = 168 as const;
export const SUP_ART_API = 170 as const;
export const SUP_ART_DOUBLE_CLICK = 171 as const;
export const SUP_ART_DV360 = 172 as const;
export const SUP_ART_SEARCH_ADS_360 = 173 as const;
export const SUP_ART_BGENIUS = 174 as const;
export const SUP_ART_RELAY42 = 175 as const;
export const SUP_ART_LEF = 176 as const;
export const SUP_ART_BLUECONIC = 177 as const;
export const SUP_ART_HUBSPOT = 178 as const;
export const SUP_ART_SALESFORCE = 179 as const;
export const SUP_ART_MICROSOFT_DYNAMICS = 180 as const;
export const SUP_ART_ADFORM = 182 as const;
export const SUP_ART_GA4_FILTERS = 283 as const;
export const SUP_ART_GA4_ECOMMERCE = 286 as const;
export const SUP_ART_BILLY_GRACE = 289 as const;
export const SUP_ART_BIG_QUERY = 291 as const;
export const SUP_ART_VWO = 292 as const;
export const SUP_ART_GOOGLE_ADS_EC = 295 as const;

// Settings
export const SUP_ART_NUMBER_DISTRIBUTION = 198 as const;
export const SUP_ART_DOMAIN_STOP = 199 as const;

// Account
export const SUP_ART_TWO_STEP = 275 as const;

// Invoicing
export const SUP_ART_TRIAL_SWITCH = 281 as const;
