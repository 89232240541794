import ReportsPage from 'components/ReportsPage';
import QueryTable from 'components/table/QueryTable';
import { getDefaultFilters } from 'contexts/Filters';
import { TYPE_CALL_TRACKING } from 'globals/constants';
import { useIsFetchingReport } from 'hooks/fetching';
import { useTranslate } from 'hooks/translate';

export default function Portals() {
  const translateText = useTranslate();

  const queryKey = ['callTracking', 'portals'];
  const isLoading = useIsFetchingReport(queryKey);

  const defaultFilters = getDefaultFilters(TYPE_CALL_TRACKING);

  return (
    <ReportsPage queryKey={queryKey} title={translateText('label', 'Overview mediums - Total')}>
      <QueryTable
        tableKey={[...queryKey, 'portalsTotals']}
        previousPeriodColumn="id"
        columns={{
          id: { header: translateText('label', 'Medium'), compareType: 'none' },
          calls: { header: translateText('label', 'Calls') },
          unique_callers: { header: translateText('label', 'New callers') },
          avg_duration: {
            header: translateText('label', 'Avg. call duration'),
            compareType: 'time',
          },
        }}
        endpoint="/widget/portals-totals"
        isResponsive
        filters={defaultFilters}
        isLoading={isLoading}
      />
      <h1>{translateText('label', 'Overview mediums - From portal')}</h1>
      <QueryTable
        tableKey={[...queryKey, 'portals']}
        previousPeriodColumn="id"
        columns={{
          id: { header: translateText('label', 'Medium'), compareType: 'none' },
          calls: { header: translateText('label', 'Calls') },
          unique_callers: { header: translateText('label', 'New callers') },
          avg_duration: {
            header: translateText('label', 'Avg. call duration'),
            compareType: 'time',
          },
        }}
        endpoint="/widget/portals"
        isResponsive
        filters={defaultFilters}
        isLoading={isLoading}
      />
      <h1>{translateText('label', 'Overview mediums - From website')}</h1>
      <QueryTable
        tableKey={[...queryKey, 'portalsWebsite']}
        previousPeriodColumn="id"
        columns={{
          id: { header: translateText('label', 'Medium'), compareType: 'none' },
          sessions: { header: translateText('label', 'Sessions') },
          calls: { header: translateText('label', 'Calls') },
          conversionrate: {
            header: translateText('label', 'Conversion rate'),
            customNumber: value => value + '%',
          },
          unique_callers: { header: translateText('label', 'New callers') },
          avg_duration: {
            header: translateText('label', 'Avg. call duration'),
            compareType: 'time',
          },
        }}
        endpoint="/widget/portals-website"
        isResponsive
        filters={defaultFilters}
        isLoading={isLoading}
      />
    </ReportsPage>
  );
}
