import { useNavigate } from 'react-router';

import importantIcon from 'assets/images/icons/important.svg';
import { PH_MASK } from 'globals/constants';
import { useReadNotification } from 'hooks/queries/notification';
import { useTranslate } from 'hooks/translate';

type Props = {
  notification: {
    id: number;
    title: string;
    created_at: string;
    summary: string;
    is_critical: boolean;
  };
  close: () => void;
};

export default function NotificationPreview({ notification, close }: Props) {
  const navigate = useNavigate();
  const translateText = useTranslate();
  const { markAsRead } = useReadNotification();

  function viewNotification() {
    navigate('/account/notifications/' + notification.id);
    close();
  }

  return (
    <div className={'notification-preview' + (notification.is_critical ? ' critical' : ' regular')}>
      <div className="notification-header">
        {notification.is_critical && (
          <div className="important">
            <img src={importantIcon} alt="important" />
          </div>
        )}
        <div className="title">
          <h4 className={`bold ${PH_MASK}`}>{notification.title}</h4>
          <span>{notification.created_at}</span>
        </div>
      </div>
      <div className={`message ${PH_MASK}`}>{notification.summary}</div>
      <div className="buttons">
        <div onClick={viewNotification}>{translateText('label', 'View')}</div>
        <div onClick={() => markAsRead(notification.id)}>
          {translateText('label', 'Do not show again')}
        </div>
      </div>
    </div>
  );
}
