import { Formik } from 'formik';
import { includes } from 'lodash';

import Select from 'components/input/Select';
import NumberFormatBuilder, {
  AREA_CODE,
  COUNTRY_CODE,
  MAX_LENGTH_IN_BUILDER,
} from 'components/settings/properties/NumberFormatBuilder';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import SupportParagraph from 'components/SupportParagraph';
import { SUP_ART_NUMBER_FORMAT } from 'globals/support';
import { useNumberFormats } from 'hooks/queries/domain-setting';
import { useTranslate } from 'hooks/translate';

const ALLOWED_FIRST_CHARS = ['+', '0', '(', COUNTRY_CODE, AREA_CODE] as const;

type FormValues = {
  format: string | number;
};

type Props = {
  numberFormat: string | number;
  updateNumberFormat: (format: string) => Promise<void>;
} & BaseSlideInProps;

export default function NumberFormatSetup({
  numberFormat,
  updateNumberFormat,
  show,
  close,
}: Props) {
  const translateText = useTranslate();
  const { numberFormats } = useNumberFormats();

  async function save(values: FormValues) {
    let warningText = null;
    const [errors, warnings] = validateFormat(String(values.format));
    if (typeof values.format !== 'number') {
      if (errors.length > 0) return;
      if (warnings.length > 0) {
        warningText =
          translateText(
            'message',
            'Are you sure you want to save this format? There are still points for attention:',
          ) +
          '\n • ' +
          warnings.join('\n • ');
      }
    }
    if (!warningText || window.confirm(warningText)) {
      await updateNumberFormat(
        String(values.format).replace(AREA_CODE, '{area}').replace(COUNTRY_CODE, '{nat}'),
      ).then(close);
    }
  }

  function convertFormat(format: string, country: string, area: string, numberOfX: number) {
    let newFormat = '';
    let replaceXWith = 1;
    for (let i = 0; i < format.length; i++) {
      switch (format.charAt(i)) {
        case COUNTRY_CODE:
          newFormat += country;
          break;
        case AREA_CODE:
          newFormat += area;
          break;
        case 'x':
          if (replaceXWith <= numberOfX) {
            newFormat += replaceXWith;
          }
          replaceXWith++;
          break;
        default: {
          newFormat += format.charAt(i);
          break;
        }
      }
    }

    while (replaceXWith <= numberOfX) {
      newFormat += replaceXWith;
      replaceXWith++;
    }

    return newFormat;
  }

  function validateFormat(format: string): [string[], string[]] {
    if (format === '') return [[translateText('label', 'Enter a format.')], []];

    const errors = [];
    const warnings = [];
    const firstChar = format.charAt(0);
    const indicesOfZero = Array.from(format.matchAll(/0/gi)).map(i => i.index!);

    const plusPosition = format.replace(/\s/g, '').indexOf('+');
    const bracketPlusPosition = format.replace(/\s/g, '').indexOf('(+');
    const zerosBeforeCountry = indicesOfZero.filter(
      indexZero => indexZero < format.indexOf(COUNTRY_CODE),
    );
    const zerosAfterCountryBeforeArea = indicesOfZero.filter(
      indexZero =>
        indexZero > format.indexOf(COUNTRY_CODE) && indexZero < format.indexOf(AREA_CODE),
    );

    if (format.length > MAX_LENGTH_IN_BUILDER) {
      errors.push(translateText('message', 'Your format is too long.'));
    }

    if (plusPosition > 0 && bracketPlusPosition !== 0) {
      // format contains '+' and '+' or '(+' is not in front
      errors.push(translateText('message', '+ can only be in front of the number.'));
    }
    if (!includes(ALLOWED_FIRST_CHARS, firstChar)) {
      errors.push(
        translateText('message', "The number can't start with this character: `{char}`.", {
          char: firstChar === ' ' ? translateText('label', 'Space').toLowerCase() : firstChar,
        }),
      );
    }
    if (!format.includes(AREA_CODE)) {
      errors.push(
        translateText('message', 'The area code is missing. This is a required element.'),
      );
    } else if (format.indexOf(COUNTRY_CODE) > format.indexOf(AREA_CODE)) {
      errors.push(translateText('message', 'Country code has to be in front of area code.'));
    }
    if (indicesOfZero.some(indexZero => indexZero > format.indexOf(AREA_CODE))) {
      errors.push(
        translateText('message', 'Zero can only be in front of countrycode or areacode.'),
      );
    }
    if (zerosBeforeCountry.length === 1) {
      errors.push(translateText('message', 'There is only 1 zero before the country code.'));
    } else if (zerosBeforeCountry.length > 2) {
      warnings.push(
        translateText('message', 'There are more than 2 zeros before the country code.'),
      );
    }
    if (zerosAfterCountryBeforeArea.length > 1) {
      warnings.push(translateText('message', 'There are more than 1 zeros before the area code.'));
    }
    if (format.includes('x') && !format.substring(format.indexOf('x')).match(/^(x| |-)+$/g)) {
      errors.push(
        translateText('message', "After the first x there can only be x's, dashes and spaces."),
      );
    }
    if (format.replace(' ', '').includes('--')) {
      warnings.push(translateText('message', 'There are dashes side by side.'));
    }
    if (format.replace(' ', '').includes('()')) {
      warnings.push(translateText('message', '`(` and `)` are side by side.'));
    }
    if ((format.match(/\(/g) || []).length !== (format.match(/\)/g) || []).length) {
      warnings.push(translateText('message', "There aren't as many `(` as there are `)`."));
    }
    if (format.replace(/\(/g, '').startsWith(AREA_CODE)) {
      warnings.push(
        translateText('message', 'There is no `0` for the area code. Do you want to add it?'),
      );
    }
    return [errors, warnings];
  }

  const initialValues: FormValues = { format: numberFormat ?? '' };
  return (
    <Formik initialValues={initialValues} onSubmit={save} enableReinitialize>
      {({ values, submitForm, dirty, isSubmitting, resetForm, setFieldValue }) => {
        let formatShortArea = '';
        let formatLongArea = '';
        let replacedFormat = '';
        let errors = [];
        if (numberFormats && typeof values.format === 'number') {
          const preselectedFormat = numberFormats.find(f => f.id === values.format)?.format;
          if (preselectedFormat) {
            [formatShortArea, formatLongArea] = preselectedFormat.split(' / ');
            replacedFormat = String(formatShortArea)
              .replace('31', COUNTRY_CODE)
              .replace('20', AREA_CODE);
          }
        } else {
          replacedFormat = String(values.format)
            .replace('31', COUNTRY_CODE)
            .replace('20', AREA_CODE);
          formatShortArea = replacedFormat;
          formatLongArea = replacedFormat;
          [errors] = validateFormat(String(replacedFormat));
        }
        const previewShortArea = convertFormat(String(formatShortArea), '31', '20', 7);
        const previewLongArea = convertFormat(String(formatLongArea), '31', '158', 6);
        return (
          <Setup
            show={show}
            close={close}
            afterClose={resetForm}
            title={translateText('label', 'Phone number format')}
            save={submitForm}
            hasChanges={dirty}
            isSaving={isSubmitting}
            maxWidth={700}
            saveButtonDisabled={errors.length > 0}
          >
            <p>
              {translateText(
                'call-tracking',
                'Indicate in which format the dynamic phone number for Call Tracking may be displayed on the website. We advise you to use the same format as the phone numbers without Call Tracking are communicated on the website.',
              )}
            </p>
            <SupportParagraph articleId={SUP_ART_NUMBER_FORMAT} />
            <p>
              {translateText(
                'call-tracking',
                'Create your own format using the elements below or choose a commonly used format.',
              )}
            </p>
            <hr />
            <InputWrapper
              label={translateText('call-tracking', 'Select a commonly used format here')}
            >
              <Select
                addSelect
                selectValue={-1}
                selectText={translateText('label', 'Custom')}
                name="format"
                options={numberFormats ?? []}
                value={typeof values.format === 'number' ? values.format : ''}
                onChange={e => setFieldValue('format', Number(e.target.value))}
                optionText="format"
                optionValue="id"
              />
            </InputWrapper>

            <div className="bold section">
              {translateText('call-tracking', 'Build your own format')}
            </div>
            <ol>
              <li>
                {translateText(
                  'call-tracking',
                  'Click on the elements to create the desired format.',
                )}
              </li>
              <li>
                {translateText(
                  'call-tracking',
                  'You can adjust the order of elements by dragging the elements.',
                )}
              </li>
              <li>
                {translateText(
                  'call-tracking',
                  'Remove an added element by navigating to it with your cursor and clicking the purple minus sign.',
                )}
              </li>
            </ol>
            <div className="section">
              <p className="number-formatter-preview">
                <span className="bold">{translateText('label', 'Example')}</span>:{' '}
                {previewShortArea + ' / ' + previewLongArea}
              </p>
            </div>
            <NumberFormatBuilder
              format={replacedFormat}
              setFormat={value => setFieldValue('format', value)}
              validateFormat={() => validateFormat(replacedFormat)}
            />
          </Setup>
        );
      }}
    </Formik>
  );
}
