import { useCallback, useState } from 'react';
import { useFormikContext } from 'formik';
import { includes } from 'lodash';

import AddButton from 'components/buttons/AddButton';
import ConfigurationCard from 'components/configuration/ConfigurationCard';
import OffsiteSetup from 'components/configuration/offsites/OffsiteSetup';
import ErrorTooltip from 'components/ErrorTooltip';
import GrayBars from 'components/GrayBars';
import { INTL_PLAN_IDS } from 'globals/constants';
import { Offsite } from 'globals/types';
import { useTranslate } from 'hooks/translate';
import { IntakeFormValues, IntakeSection } from 'pages/authenticated/requests/Intake';

export default function OffsiteNumbers() {
  const translateText = useTranslate();
  const [showEdit, setShowEdit] = useState<'add' | 'edit' | false>(false);

  const { values: intakeValues, setFieldValue, errors } = useFormikContext<IntakeFormValues>();

  const save = useCallback(
    (values: { addedOffsites: Offsite[] }) => {
      setFieldValue('offsite_numbers', values.addedOffsites);
      return Promise.resolve();
    },
    [setFieldValue],
  );

  const error =
    Object.keys(intakeValues.validated).includes(IntakeSection.CallTracking) &&
    (errors.offsite_numbers as string);

  return (
    <>
      {intakeValues.offsite_numbers.length ? (
        <ConfigurationCard onEdit={() => setShowEdit('edit')} noAccessCheck>
          <GrayBars
            rows={intakeValues.offsite_numbers.map(o => ({
              key: o.name,
              value: o.destination,
              private: true,
            }))}
            title={translateText('label', 'Offsite phone numbers')}
            titleTooltip={translateText(
              'tooltip/intake',
              'The offsite phone numbers that are requested.',
            )}
          />
        </ConfigurationCard>
      ) : (
        <ErrorTooltip
          error={error}
          html={
            <div className={'white-block no-data' + (error ? ' error' : '')} tabIndex={0}>
              <AddButton
                onClick={() => setShowEdit('add')}
                tooltipText={translateText(
                  'tooltip/intake',
                  'Click the + to create or edit the offsite phone numbers. With offsite phone numbers you can track media and locations outside your website.',
                )}
              />
              {translateText('label', 'No offsite phone numbers')}
            </div>
          }
          className="no-data"
        />
      )}
      <OffsiteSetup
        type={showEdit}
        offsites={intakeValues.offsite_numbers}
        close={() => setShowEdit(false)}
        onSave={save}
        canChangeCountry={includes(INTL_PLAN_IDS, intakeValues.pricingPlanId)}
        intake
      />
    </>
  );
}
