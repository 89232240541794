import { Fragment } from 'react';
import { useFormikContext } from 'formik';

import { ReactComponent as Checkmark } from 'assets/images/icons/checkmark.svg';
import {
  EditSubscriptionFormValues,
  EditSubscriptionSection,
} from 'components/edit-subscription/EditSubscription';
import { IntakeFormValues, IntakeSection } from 'pages/authenticated/requests/Intake';
import { Type } from './Wrapper';

type Props = {
  type: Type;
};

export default function ProgressBar({ type }: Props) {
  const { values, setFieldValue, isSubmitting } = useFormikContext<
    IntakeFormValues | EditSubscriptionFormValues
  >();
  const sections = Object.values(type === Type.Intake ? IntakeSection : EditSubscriptionSection);

  return (
    <div className="progress-bar-wrapper">
      {sections.map((section, index) => {
        let extraClass = '';
        if (values.section === section) extraClass += ' active';
        if (values.validated[section as keyof typeof values.validated]) extraClass += ' done';
        return (
          <Fragment key={section}>
            <button
              className={'progress-item' + extraClass}
              onClick={() => setFieldValue('section', section)}
              disabled={
                index >= sections.indexOf(values.section) ||
                values.section === IntakeSection.Thanks ||
                isSubmitting
              }
            >
              {section === IntakeSection.Thanks ? <Checkmark /> : index + 1}
            </button>
            {section !== IntakeSection.Thanks && <span className={'bar' + extraClass} />}
          </Fragment>
        );
      })}
    </div>
  );
}
