import { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import AddButton from 'components/buttons/AddButton';
import AreaCodeSelector from 'components/configuration/AreaCodeSelector';
import ErrorFocus from 'components/ErrorFocus';
import Checkbox from 'components/input/Checkbox';
import Select from 'components/input/Select';
import TextField from 'components/input/TextField';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import QueryTable from 'components/table/QueryTable';
import {
  SIP_TRUNK_DSTNY_PBX_AD_CALLS,
  SIP_TRUNK_DSTNY_PBX_ELEVATE,
  SIP_TRUNK_DSTNY_PBX_LIVE,
  SIP_TRUNK_DSTNY_PBX_TEST,
  SIP_TRUNK_DSTNY_PROBLEMEN_KWIJT,
  SIP_TRUNK_DSTNY_VPS_LIVE,
  SIP_TRUNK_DSTNY_VPS_LIVE_2,
  SIP_TRUNK_DSTNY_VPS_TEST,
  SIP_TRUNK_STEAM_CONNECT_LIVE,
  SIP_TRUNK_STEAM_CONNECT_PROD,
  SIP_TRUNK_STEAM_CONNECT_TEST,
  SIP_TRUNK_VOXBONE,
} from 'globals/constants';
import { useCountries } from 'hooks/queries/country';
import { useOrderAtSteamConnect } from 'hooks/queries/number';
import { useUserDomains } from 'hooks/queries/user';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation } from 'hooks/validation';

const sipTrunks = [
  SIP_TRUNK_STEAM_CONNECT_LIVE,
  SIP_TRUNK_STEAM_CONNECT_PROD,
  SIP_TRUNK_STEAM_CONNECT_TEST,
  SIP_TRUNK_DSTNY_PBX_AD_CALLS,
  SIP_TRUNK_DSTNY_PBX_ELEVATE,
  SIP_TRUNK_DSTNY_PBX_LIVE,
  SIP_TRUNK_DSTNY_PBX_TEST,
  SIP_TRUNK_DSTNY_VPS_LIVE,
  SIP_TRUNK_DSTNY_VPS_LIVE_2,
  SIP_TRUNK_DSTNY_PROBLEMEN_KWIJT,
  SIP_TRUNK_DSTNY_VPS_TEST,
  SIP_TRUNK_VOXBONE,
];

type FormValues = {
  amount: number;
  domain: string;
  area: number | null;
  specificDomain: boolean;
};

export type OrderSteamConnect = {
  order: Record<number, string>;
  domain?: string;
};

export default function AvailablePhoneNumbers() {
  const translateText = useTranslate();
  const { countries, isLoading } = useCountries(false);
  const { domains } = useUserDomains();
  const { order } = useOrderAtSteamConnect();
  const { required } = useCustomValidation();

  const [showOrderNumbers, setShowOrderNumbers] = useState(false);
  const [country, setCountry] = useState(31);
  const [sipTrunk, setSipTrunk] = useState<string>(SIP_TRUNK_STEAM_CONNECT_PROD);

  const initialValues: FormValues = {
    amount: 1,
    domain: 'select',
    area: null,
    specificDomain: false,
  };

  async function orderNumbers(values: FormValues) {
    const orderBody: OrderSteamConnect = {
      order: { [values.area!]: String(values.amount) },
    };
    if (values.specificDomain) orderBody.domain = values.domain;
    await order(orderBody).then(() => setShowOrderNumbers(false));
  }

  const validationSchema = Yup.object({
    area: required,
    amount: required,
    domain: Yup.string().test({
      test: function (value) {
        if (!this.parent.specificDomain) return true;
        return !!value && value !== 'select';
      },
      message: translateText('message', 'This field is required.'),
    }),
  });

  return (
    <div className="available-phone-numbers">
      <h1>{translateText('label', 'Available phone numbers')}</h1>
      <QueryTable
        tableKey={['available-numbers', 'admin']}
        endpoint="/number/available"
        columns={{
          netnumber: {
            header: translateText('label', 'Area code'),
            search: true,
            noNumberFormat: true,
          },
          available: { header: translateText('label', 'Available') },
        }}
        extraTopLeft={
          <>
            {country === 31 && sipTrunk === SIP_TRUNK_STEAM_CONNECT_PROD && (
              <AddButton
                onClick={() => setShowOrderNumbers(true)}
                tooltipText={translateText('label', 'Order phone numbers')}
              />
            )}
            <label className="bold">{translateText('label', 'Country code')}</label>
            <Select
              name="country"
              className="country"
              options={countries?.map(c => c.code) ?? [31]}
              onChange={e => setCountry(+e.target.value)}
              value={country}
              prefix="+"
              disabled={isLoading}
            />
            <label className="bold">{translateText('label', 'SIP trunk')}</label>
            <Select
              name="siptrunk"
              className="siptrunk"
              options={sipTrunks}
              onChange={e => setSipTrunk(e.target.value)}
              defaultValue={SIP_TRUNK_STEAM_CONNECT_PROD}
              disabled={isLoading}
            />
          </>
        }
        filters={{
          domain: false,
          custom: { country, siptrunk: sipTrunk },
        }}
        pagination
        refresh
      />
      <Formik
        initialValues={initialValues}
        onSubmit={orderNumbers}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({
          values,
          handleChange,
          setFieldValue,
          submitForm,
          errors,
          resetForm,
          submitCount,
          isSubmitting,
        }) => (
          <Setup
            show={showOrderNumbers}
            close={() => setShowOrderNumbers(false)}
            maxWidth={400}
            title={translateText('label', 'Order numbers at Steam-connect')}
            save={submitForm}
            afterClose={resetForm}
            isSaving={isSubmitting}
          >
            <ErrorFocus />
            <InputWrapper label={translateText('label', 'SIP trunk')}>
              <TextField value={SIP_TRUNK_STEAM_CONNECT_PROD} disabled wrapperClassName="full" />
            </InputWrapper>
            <InputWrapper label={translateText('label', 'Area code')}>
              <AreaCodeSelector
                selectedCountry={31}
                selectedArea={values.area}
                countryDisabled
                setAreaCode={(c, a) => setFieldValue('area', a)}
                areaError={submitCount > 0 && errors.area}
                disabled={isSubmitting}
              />
            </InputWrapper>
            <InputWrapper label={translateText('label', 'Amount')}>
              <TextField
                name="amount"
                value={values.amount}
                type="number"
                onChange={handleChange}
                wrapperClassName="full"
                minimalValue={1}
                disabled={isSubmitting}
                error={submitCount > 0 && errors.amount}
              />
            </InputWrapper>
            <div className="input-wrapper">
              <span className="input-label">{translateText('label', 'Domain')}</span>
              <div className="input-field">
                <Checkbox
                  id="specificDomain"
                  onChange={handleChange}
                  checked={values.specificDomain}
                  label={translateText('label', 'Add for a specific domain')}
                  disabled={isSubmitting}
                />
              </div>
              {values.specificDomain && (
                <div className="input-field">
                  <Select
                    name="domain"
                    addSelect
                    value={values.domain}
                    onChange={handleChange}
                    options={domains ?? []}
                    className="full"
                    optionText="title"
                    optionValue="id"
                    error={submitCount > 0 && errors.domain}
                    disabled={isSubmitting}
                  />
                </div>
              )}
            </div>
          </Setup>
        )}
      </Formik>
    </div>
  );
}
