export default {
    "1 capital character": "1 hoofdletter",
    "1 digit": "1 cijfer",
    "1 lowercase character": "1 kleine letter",
    "1 punctuation mark": "1 leesteken",
    "10% discount for the first six months": "10% korting voor de eerste zes maanden",
    "1<sup>st<\/sup> event": "1<sup>e<\/sup> gebeurtenis",
    "AEN code": "AEN-code",
    "API": "API",
    "API key": "API-sleutel",
    "API secret": "API-geheim",
    "API token": "API token",
    "Accept": "Accepteer",
    "Accept invitation": "Uitnodiging accepteren",
    "Access token": "Access-token",
    "Account": "Account",
    "Account ID": "Account-id",
    "Account manager": "Accountmanager",
    "Account settings": "Accountinstellingen",
    "Account verification failed": "Accountverificatie is mislukt",
    "Account verified": "Account geverifieerd",
    "Activate": "Activeer",
    "Active": "Actief",
    "Active since": "Actief sinds",
    "AdCalls API": "AdCalls API",
    "AdCalls agreement": "overeenkomst van AdCalls",
    "AdCalls number": "AdCalls nummer",
    "AdCalls processor agreement": "verwerkersovereenkomst van AdCalls",
    "AdCalls terms and conditions": "algemene voorwaarden van AdCalls",
    "AdCalls visitor ID": "AdCalls bezoeker ID",
    "Add": "Toevoegen",
    "Add Chargebee customer": "Chargebee klant toevoegen",
    "Add FAQ": "FAQ toevoegen",
    "Add HTTP header": "HTTP header toevoegen",
    "Add IP filter": "IP-filter toevoegen",
    "Add a LEF ID to location": "Voeg een LEF-id toe aan de locatie",
    "Add a format manually.": "Voeg een format handmatig toe.",
    "Add an evaluation to LEF": "Voeg een beoordeling toe voor LEF",
    "Add article": "Artikel toevoegen",
    "Add attachment": "Bijlage toevoegen",
    "Add category": "Categorie toevoegen",
    "Add domain or email address to blacklist": "Voeg domein of e-mailadres toe aan zwarte lijst",
    "Add domain setting": "Domein-instelling toevoegen",
    "Add expandable paragraph": "Uitklapbare alinea invoegen",
    "Add files": "Bestanden toevoegen",
    "Add filter": "Filter toevoegen",
    "Add for a specific domain": "Toevoegen voor een specifiek domein",
    "Add format": "Format toevoegen",
    "Add format manually": "Format handmatig toevoegen",
    "Add image": "Afbeelding invoegen",
    "Add link": "Link invoegen",
    "Add location": "Locatie toevoegen",
    "Add manually": "Handmatig toevoegen",
    "Add new domain setting": "Nieuwe domein-instelling toevoegen",
    "Add new notification": "Nieuwe notificatie toevoegen",
    "Add new partner": "Nieuwe partner toevoegen",
    "Add new phone numbers": "Nieuwe telefoonnummers toevoegen",
    "Add new reseller": "Nieuwe partner toevoegen",
    "Add note": "Notitie toevoegen",
    "Add numbers": "Nummers toevoegen",
    "Add offsite number(s)": "Offsite nummer(s) toevoegen",
    "Add offsite phone number": "Offsite telefoonnummer toevoegen",
    "Add phone number to blacklist": "Telefoonnummer toevoegen aan de zwarte lijst",
    "Add related articles": "Gerelateerde artikelen toevoegen",
    "Add slug": "Slug toevoegen",
    "Add stream": "Stream toevoegen",
    "Add to categories": "Toevoegen aan categorie\u00ebn",
    "Add video": "Video invoegen",
    "Add webhook": "Webhook instellen",
    "Added webhooks": "Ingestelde webhooks",
    "Address": "Adres",
    "Adform": "Adform",
    "Adjust": "Aanpassen",
    "Admin": "Admin",
    "Administrative user email": "Administratief gebruiker e-mail",
    "Advanced implementation": "Geavanceerde implementatie",
    "After the call": "Na de oproep",
    "After the call has been evaluated in the dashboard": "Nadat de oproep is beoordeeld in het dashboard",
    "After the call request": "Na het terugbelverzoek",
    "After the call request has been evaluated in the dashboard": "Nadat het terugbelverzoek is beoordeeld in het dashboard",
    "Alerts": "Alerts",
    "Align left": "Links uitlijnen",
    "Align right": "Rechts uitlijnen",
    "All SIP trunks": "Alle SIP trunks",
    "All calls": "Alle oproepen",
    "All domains on one invoice": "Alle domeinen op \u00e9\u00e9n factuur",
    "All traffic sources": "Alle verkeersbronnen",
    "All traffic sources except": "Alle verkeersbronnen, behalve",
    "All traffic sources, but excluded {amount} traffic source(s)": "Alle verkeersbronnen, maar {amount} verkeersbron(nen) uitgesloten",
    "Amount": "Aantal",
    "Amount of calls": "Aantal oproepen",
    "Amount of sessions": "Aantal sessies",
    "An automatic implementation mail will not be sent.": "Er wordt geen automatische implementatie-mail verzonden.",
    "Announcement": "Aankondiging",
    "Announcements": "Aankondigingen",
    "Anonymous": "Anoniem",
    "Answered": "Beantwoord",
    "App": "App",
    "Apply filters": "Filters toepassen",
    "Approve": "Goedkeuren",
    "Approved": "Goedgekeurd",
    "Archived": "Gearchiveerd",
    "Are you new?": "Ben je nieuw?",
    "Area code": "Netnummer",
    "Area code for distribution": "Netnummer voor uitgifte",
    "Articles": "Artikelen",
    "Articles with status archived": "Artikelen met status gearchiveerd",
    "Articles with status concept": "Artikelen met status concept",
    "Articles without categories": "Artikelen zonder categorie\u00ebn",
    "Assignable": "Uitgeefbaar",
    "Assignable numbers": "Uitgeefbare nummers",
    "Assigned domains": "Toegewezen domeinen",
    "Assigned locations": "Toegewezen locaties",
    "Assignments": "Uitgiftes",
    "At": "Om",
    "At least:": "Tenminste",
    "Attachments": "Bijlagen",
    "Attribution model": "Attributiemodel",
    "Authentication type": "Authenticatietype",
    "Automatic debit": "Automatische incasso",
    "Automatically grant access to new domains": "Automatisch toegang geven tot nieuwe domeinen",
    "Available": "Beschikbaar",
    "Available articles": "Beschikbare artikelen",
    "Available domains": "Beschikbare domeinen",
    "Available locations": "Beschikbare locaties",
    "Available phone numbers": "Voorraad telefoonnummers",
    "Available traffic sources": "Beschikbare verkeersbronnen",
    "Average call duration": "Gemiddelde gespreksduur",
    "Average duration": "Gemiddelde duratie",
    "Average number of call attempts": "Gemiddeld aantal belpogingen",
    "Average response time": "Gemiddelde reactietijd",
    "Avg. call duration": "Gem. gespreksduur",
    "Avg. duration": "Gem. duratie",
    "Avg. leadrating": "Gem. waarde",
    "Avg. number of call attempts": "Gem. aantal belpogingen",
    "Avg. response time": "Gem. reactietijd",
    "Avg. revenue": "Gem. omzet",
    "Avg. value": "Gem. waarde",
    "Back": "Terug",
    "Back to all notifications": "Terug naar alle notificaties",
    "Back to stock": "Terug naar voorraad",
    "Back to the homepage": "Terug naar de homepagina",
    "Basic": "Basis",
    "Basic international": "Basis internationaal",
    "Beep": "Pieptoon",
    "Before the call": "Voor de oproep",
    "Before trial": "Voor trial",
    "Below": "Onderstaande",
    "Best time": "Beste tijdstip",
    "BigQuery": "BigQuery",
    "Billing information": "Facturatiegegevens",
    "Billy Grace": "Billy Grace",
    "Black listed phone numbers": "Zwarte lijst telefoonnummers",
    "Blacklisted till": "Op de zwarte lijst tot",
    "Block quote": "Blok quote",
    "BlueConic": "BlueConic",
    "BlueConic profile ID": "BlueConic profiel ID",
    "Bold": "Dikgedrukt",
    "Button text": "Knop tekst",
    "By": "Door",
    "Call": "Oproep",
    "Call ID": "Oproep-id",
    "Call Tracking": "Call Tracking",
    "Call Tracking report": "Rapportage Call Tracking",
    "Call answered": "Oproep beantwoord",
    "Call attempts": "Belpogingen",
    "Call count": "Aantal oproepen",
    "Call details": "Oproepdetails",
    "Call duration": "Gespreksduur",
    "Call evaluations": "Oproepbeoordelingen",
    "Call functionalities": "Oproepfunctionaliteiten",
    "Call history": "Oproepgeschiedenis",
    "Call minutes (start + minute)": "Belminuten (start + minuut)",
    "Call not found": "Oproep niet gevonden",
    "Call notifications": "Oproepnotificaties",
    "Call recording": "Call recording",
    "Call request": "Terugbelverzoek",
    "Call request not found": "Terugbelverzoek niet gevonden",
    "Call requests": "Verzoeken",
    "Call time": "Gesprekstijd",
    "Calls": "Oproepen",
    "Calls from the same sender": "Oproepen van dezelfde afzender",
    "Calls to Analytics": "Oproepen naar Analytics",
    "Campaign": "Campagne",
    "Campaign builder": "Campaign builder",
    "Can't find the answer to your question?": "Kun je het antwoord op je vraag niet vinden?",
    "Cancel": "Annuleren",
    "Cancel request": "Aanvraag annuleren",
    "Cancel stop request": "Stopzetting intrekken",
    "Cancelled": "Geannuleerd",
    "Caps lock is on": "Caps lock is aan",
    "Categories without active articles": "Categorie\u00ebn zonder actieve artikelen",
    "Category": "Categorie",
    "Center": "Centreren",
    "Change": "Wijziging",
    "Change account manager": "Accountmanager wijzigen",
    "Change evaluation": "Beoordeling wijzigen",
    "Change history": "Wijzigingsgeschiedenis",
    "Change not found": "Wijziging niet gevonden",
    "Change password": "Wijzig wachtwoord",
    "Change requested": "Wijziging in aanvraag",
    "Change sources for AEN": "Wijziging bronnen voor AEN",
    "Change type": "Type wijziging",
    "Changed by": "Gewijzigd door",
    "Changes": "Wijzigingen",
    "Chargebee customer": "Chargebee klant",
    "Choose an implementation method": "Kies een implementatie-methode",
    "City": "Woonplaats",
    "Classes": "Klassen",
    "Clear all notifications": "Wis alle notificaties",
    "Clear filters": "Filters wissen",
    "Clear formatting": "Formatting verwijderen",
    "Clear selection": "Selectie wissen",
    "Close": "Sluiten",
    "Close full screen": "Volledig scherm afsluiten",
    "Code-block": "Code blok",
    "Comment (optional)": "Opmerking (optioneel)",
    "Company": "Bedrijf",
    "Company name": "Bedrijfsnaam",
    "Compare period": "Periode vergelijken",
    "Concept": "Concept",
    "Concepts": "Concepten",
    "Condition": "Conditie",
    "Conditions": "Voorwaarden",
    "Configure conversion action": "Conversieactie instellen",
    "Confirm": "Bevestigen",
    "Connect account": "Account koppelen",
    "Container": "Container",
    "Content": "Inhoud",
    "Continue": "Verder gaan",
    "Contract duration": "Contractduur",
    "Contract end date": "Einddatum contract",
    "Conversion action": "Conversie actie",
    "Conversion measurement": "Conversiemeting",
    "Conversion period": "Conversie periode",
    "Conversion rate": "Conversieratio",
    "Copy": "Kopieer",
    "Copy JavaScript": "Kopieer JavaScript",
    "Counting type": "Aantal",
    "Country": "Land",
    "Country code": "Landcode",
    "Create account": "Account aanmaken",
    "Create new contact": "Nieuw contact aanmaken",
    "Create new profile": "Nieuw profiel aanmaken",
    "Create offsite phone numbers": "Maak offsite telefoonnummers aan",
    "Created by": "Aangemaakt door",
    "Created on": "Aangemaakt op",
    "Credit notes": "Credit facturen",
    "Currency value": "Valutawaarde",
    "Current filters": "Huidige filters",
    "Current password": "Huidig wachtwoord",
    "Current period": "Huidige periode",
    "Custom": "Aangepast",
    "Customer ID": "Klant-id",
    "Customer Success Manager": "Customer Success Manager",
    "Customer center": "Klantcentrum",
    "Customer center ID": "Klantcentrum-id",
    "Customer center user overview": "Klantcentrum gebruikersoverzicht",
    "Customer evaluation": "Keuzemenu",
    "Daily": "Dagelijks",
    "Dashboard": "Dashboard",
    "Data Processor Agreement": "Verwerkersovereenkomst",
    "Data controller": "Verwerkingsverantwoordelijke",
    "Dataset ID": "Dataset-ID",
    "Date": "Datum",
    "Date & time": "Datum & tijdstip",
    "Date notation": "Notatie van datum",
    "Day": "Dag",
    "Days": "Dagen",
    "Days and times": "Dagen en tijdstippen",
    "Deactivate": "Deactiveer",
    "Debug mode": "Debug mode",
    "Decline": "Weiger",
    "Declined": "Geweigerd",
    "Delete": "Verwijderen",
    "Delete FAQ": "FAQ verwijderen",
    "Delete evaluation": "Beoordeling verwijderen",
    "Delete evaluations": "Beoordelingen verwijderen",
    "Delete invoice profile": "Facturatieprofiel verwijderen",
    "Delete pool": "Verwijder pool",
    "Deleted": "Verwijderd",
    "Deleted at": "Verwijderd op",
    "Deleted domain": "Verwijderde domeinen",
    "Description": "Beschrijving",
    "Desktop": "Desktop",
    "Destination": "Bestemming",
    "Destination number": "Bestemmingsnummer",
    "Details": "Details",
    "Dialed time": "Beltijd",
    "Difference": "Verschil",
    "Disabled": "Uitgeschakeld",
    "Disabled due to opening hours": "Uitgeschakeld i.v.m. openingstijden",
    "Disconnect account": "Account ontkoppelen",
    "Discontinuation AEN": "Stopzetting AEN",
    "Discontinuation offsite phone number(s)": "Stopzetting offsite telefoonnummer(s)",
    "Discontinue accepted": "Wordt stopgezet",
    "Discontinue requested": "Stopzetting aangevraagd",
    "Discount based on contract duration": "Korting op basis van contractduur",
    "Displayed articles": "Weergegeven artikelen",
    "Do not show again": "Niet meer tonen",
    "Domain": "Domein",
    "Domain ID": "Domein-id",
    "Domain URL": "Domein-URL",
    "Domain kickback": "Domein kickback",
    "Domain or email address": "Domein of e-mailadres",
    "Domain overview": "Domeinoverzicht",
    "Domain properties": "Domein-eigenschappen",
    "Domain setting": "Domein-instelling",
    "Domain settings": "Domein-instellingen",
    "Domain stop": "Domein stop",
    "Domain stop requests": "Domein stopzettingen",
    "Domain title": "Domein titel",
    "Domain user overview": "Domein gebruikersoverzicht",
    "Domains": "Domeinen",
    "Domains (customer center)": "Domeinen (klantcentrum)",
    "Domains (kickback)": "Domeinen (kickback)",
    "Don't deliver domain immediately": "Domein niet direct opleveren",
    "Don't exclude": "Niet uitsluiten",
    "Don't send yet": "Nog niet verzenden",
    "Don't track dynamic traffic sources": "Geen dynamische verkeersbronnen meten",
    "DoubleClick Campaign Manager": "DoubleClick Campaign Manager",
    "Download": "Downloaden",
    "Download {type}": "Download {type}",
    "Downscale": "Afschalen",
    "Dstny issue data": "Dstny issue data",
    "Dstny series": "Reeks Dstny",
    "Duplicate": "Duplicaat",
    "Duration": "Gespreksduur",
    "Dutch": "Nederlands",
    "Dynamic": "Dynamisch",
    "Dynamic distribution (distinguish visitors)": "Dynamische uitgifte (bezoekers onderscheiden)",
    "E-mail": "E-mail",
    "Edit": "Bewerken",
    "Edit FAQ": "FAQ bewerken",
    "Edit IP filter": "IP-filter bewerken",
    "Edit article": "Artikel bewerken",
    "Edit category": "Categorie bewerken",
    "Edit filter": "Filter wijzigen",
    "Edit location": "Locatie bewerken",
    "Edit offsite phone number": "Offsite telefoonnummer wijzigen",
    "Edit order of articles": "Volgorde van artikelen bewerken",
    "Edit order of categories": "Volgorde van categorie\u00ebn bewerken",
    "Edit popular articles": "Populaire artikelen bewerken",
    "Edit raw": "Ruw bewerken",
    "Edit slider articles": "Slider artikelen bewerken",
    "Edit stream": "Wijzig stream",
    "Edit the LEF ID for location": "Bewerk het LEF-id voor locatie",
    "Edit the evaluation to LEF conditions": "Bewerk de beoordeling voor LEF",
    "Email": "E-mail",
    "Email address": "E-mailadres",
    "Email address bcc": "E-mailadres bcc",
    "Email address for invoicing": "E-mailadres voor invoicing",
    "Email address for notifications": "E-mailadres voor notificaties",
    "Email address invoicing": "E-mailadres facturatie",
    "Email address per location": "E-mailadres per locatie",
    "Email address receiver": "E-mailadres ontvanger",
    "Email address support": "E-mailadres support",
    "Email address(es)": "E-mailadres(sen)",
    "Email not verified": "Niet geverifieerd",
    "Email verified": "E-mail geverifieerd",
    "Enable conversion": "Conversie inschakelen",
    "Enable filters": "Filters instellen",
    "Enable two-step verification": "Schakel tweestapsverificatie in",
    "Enable {integration}?": "{integration} inschakelen?",
    "Enabled": "Ingeschakeld",
    "End date": "Einddatum",
    "English": "English",
    "Enter a color": "Geef een kleur op",
    "Enter a format.": "Vul een format in.",
    "Enter a url": "Geef een url op",
    "Enter a video url": "Vul een video url in",
    "Enter an image url": "Vul een afbeelding url in",
    "Enter your code": "Geef je code op",
    "Enterprise": "Enterprise",
    "Enterprise (INT.)": "Enterprise (INT.)",
    "Enterprise (NL)": "Enterprise (NL)",
    "Enterprise international": "Enterprise internationaal",
    "Equal period": "Gelijke periode",
    "Equal to": "Gelijk aan",
    "Error on sending": "Fout bij verzenden",
    "Errors encountered": "Foutmeldingen opgetreden",
    "Evaluate call": "Oproep beoordelen",
    "Evaluate call request": "Terugbelverzoek beoordelen",
    "Evaluated calls": "Beoordeelde oproepen",
    "Evaluation": "Beoordeling",
    "Evaluation menu": "Beoordelingsmenu",
    "Evaluation removed": "Beoordeling verwijderd",
    "Evaluation value": "Waarde beoordeling",
    "Evaluations": "Beoordelingen",
    "Event #": "Gebeurtenis #",
    "Example": "Voorbeeld",
    "Exclude (standard)": "Uitsluiten (standaard)",
    "Exclude certain traffic sources": "Bepaalde verkeersbronnen uitsluiten",
    "Excluded": "Uitgesloten",
    "Excluded from upgrade": "Uitgesloten van upgrade",
    "Excluded traffic sources": "Uitgesloten verkeersbronnen",
    "Executed": "Uitgevoerd",
    "Expiration days": "Vervaldagen",
    "Extend": "Verlengen",
    "Extend subscription": "Verleng abonnement",
    "Extension requested": "Verlenging aangevraagd",
    "Extension summary": "Samenvatting verlenging",
    "Extra 1,000 sessions": "Extra 1.000 sessies",
    "Extra HTTP headers": "Extra HTTP headers",
    "Extra location per 1,000 sessions": "Extra locatie per 1.000 sessies",
    "Extra offsite phone numbers": "Extra offsite telefoonnummers",
    "FAQ": "FAQ",
    "FAQs": "FAQ's",
    "Fallback": "Fallback",
    "Fallback assignments": "Fallback uitgiftes",
    "Filename": "Bestandsnaam",
    "Fill in another request": "Nog een aanvraag doen",
    "Filled in by the lead": "Ingevuld door de lead",
    "Filter": "Filter",
    "Filters": "Filters",
    "First and last name": "Voor- en achternaam",
    "First click": "Eerste klik",
    "First indirect click (prefer AdWords & BingCPC)": "Eerste indirecte klik (AdWords & BingCPC voortrekken)",
    "First name": "Voornaam",
    "Floodlight Activity ID": "Floodlight Activity-id",
    "Floodlight configuration ID": "Floodlight configuration-id",
    "For all calls": "Voor alle oproepen",
    "For missed calls": "Voor gemiste oproepen",
    "Forgot password?": "Wachtwoord vergeten?",
    "Forgot password? Click here.": "Wachtwoord vergeten? Klik hier.",
    "Form Tracking": "Form Tracking",
    "Format": "Format",
    "Format details": "Format details",
    "Format phone number": "Format telefoonnummer",
    "Found formats": "Gevonden formats",
    "French": "Fran\u00e7ais",
    "Frequency report": "Frequentie rapportage",
    "Full name": "Naam",
    "Full screen": "Volledig scherm",
    "Futy": "Futy",
    "GA4 disabled": "GA4 uitgeschakeld",
    "German": "Deutsch",
    "Get numbers": "Nummers ophalen",
    "Get pools": "Pools ophalen",
    "Go back": "Ga terug",
    "Go to Chargebee subscription": "Ga naar Chargebee subscription",
    "Go to support article": "Ga naar support artikel",
    "Go to the login page": "Ga naar de loginpagina",
    "Goal ID": "Goal-id",
    "Google Ads": "Google Ads",
    "Google Ads Enhanced Conversions": "Google Ads Enhanced Conversions",
    "Google Analytics 4": "Google Analytics 4",
    "Google Display & Video 360": "Google Display & Video 360",
    "Google Search Ads 360": "Google Search Ads 360",
    "Google Tag Manager": "Google Tag Manager",
    "Google client ID": "Google Client ID",
    "Grant access": "Toegang verlenen",
    "Grant access to specific domains": "Toegang geven tot specifieke domeinen",
    "Grant access to specific locations": "Toegang geven tot specifieke locaties",
    "Grant access to the data of AdCalls": "Verleen toegang tot de data van AdCalls",
    "HTTP method": "HTTP methode",
    "Hang up cause": "Ophangreden",
    "Header": "Header",
    "Height": "Hoogte",
    "Hide": "Verbergen",
    "Hide numbers": "Verberg nummers",
    "History report": "Rapportage geschiedenis",
    "Hour": "Uur",
    "HubSpot": "HubSpot",
    "I accept the": "Ik accepteer de",
    "I accept the \"AdCalls Data Processor Agreement\" on behalf of the \"Data controller\" mentioned therein. I declare that I am authorized to do so.": "Ik accepteer de \u201cVerwerkersovereenkomst AdCalls\u201d namens de daarin genoemde \u201cVerwerkingsverantwoordelijke\u201d. Ik verklaar daartoe bevoegd te zijn.",
    "ID": "ID",
    "IP address": "IP-adres",
    "IP filter": "IP-filter",
    "IVR menu": "Keuzemenu",
    "Icon": "Icoon",
    "Image": "Afbeelding",
    "Implement AdCalls' JavaScript": "Implementeer het JavaScript van AdCalls",
    "Implement AdCalls' JavaScript and the number spans": "Implementeer het JavaScript van AdCalls \u00e9n de nummer-spans",
    "Implementation": "Implementatie",
    "Implementation mail": "Implementatie-mail",
    "Import domain intake": "Domeinaanvraag importeren",
    "Import evaluations": "Importeer beoordelingen",
    "In progress": "Bezig",
    "In request": "In aanvraag",
    "Inactive": "Inactief",
    "Inactive: Missing agreement": "Inactief: ontbrekende overeenkomst",
    "Included": "Inbegrepen",
    "Included number of offsite phone numbers": "Inbegrepen aantal offsite telefoonnummers",
    "Indent": "Inspringing vergroten",
    "Indicate how the phone number may be displayed": "Geef aan hoe het telefoonnummer getoond mag worden",
    "Information": "Gegevens",
    "Input field": "Invoerveld",
    "Inspiration": "Inspiratie",
    "Intake not found": "Intake niet gevonden",
    "Integration": "Integratie",
    "Integrations": "Integraties",
    "Interval": "Interval",
    "Invalid phone number": "Ongeldig telefoonnummer",
    "Investment per month": "Investering per maand",
    "Invitations": "Uitnodigingen",
    "Invite": "Uitnodigen",
    "Invite domain": "Domein uitnodigen",
    "Invite user": "Gebruiker uitnodigen",
    "Invited": "Uitnodiging verstuurd",
    "Invited by": "Uitgenodigd door",
    "Invoice discount": "Factuurkorting",
    "Invoice profile": "Facturatieprofiel",
    "Invoice profile label": "Label facturatieprofiel",
    "Invoices": "Facturen",
    "Invoicing": "Facturatie",
    "Is equal to": "Is gelijk aan",
    "Is fallback": "Is fallback",
    "Is greater than": "Is groter dan",
    "Is greater than or equal to": "Is groter dan of gelijk aan",
    "Is less than": "Is kleiner dan",
    "Is less than or equal to": "Is kleiner dan of gelijk aan",
    "Is main slug": "Is primaire slug",
    "Is not equal to": "Is niet gelijk aan",
    "Is stopped at": "Gestopt op",
    "Is trial": "Is proefperiode",
    "Italic": "Cursief",
    "JavaScript": "JavaScript",
    "Justify": "Uitvullen",
    "KVK-number": "KVK-nummer",
    "Keyword": "Zoekwoord",
    "Kickback": "Kickback",
    "Knowledge & inspiration": "Kennis & inspiratie",
    "Knowledge center": "Kenniscentrum",
    "LEF": "LEF",
    "LEF ID": "LEF-id",
    "Language": "Taal",
    "Last": "Laatste",
    "Last added": "Laatst toegevoegd",
    "Last call": "Laatste oproep",
    "Last change": "Laatst gewijzigd",
    "Last change date": "Datum laatste wijziging",
    "Last event": "Laatste gebeurtenis",
    "Last login": "Laatste inlog",
    "Last name": "Achternaam",
    "Last page visited": "Laatstbezochte pagina",
    "Last updated": "Laatst gewijzigd",
    "Last updated: {date}": "Laatst gewijzigd: {date}",
    "Latest news": "Laatste nieuws",
    "Lead": "Lead",
    "Lead & E-commerce Follow-up": "Lead & E-commerce Follow-up",
    "Lead rating": "Waardering",
    "Let's go!": "Aan de slag!",
    "Licence from": "Licentie vanaf",
    "Light": "Light",
    "Light international": "Light internationaal",
    "Limit": "Limiet",
    "Link": "Link",
    "Load conversion action": "Conversieactie laden",
    "Location": "Locatie",
    "Location requested": "Locatie in aanvraag",
    "Locations": "Locaties",
    "Locations (included)": "Locaties (inclusief)",
    "Locations overview": "Overzicht locaties",
    "Locked": "Vergrendeld",
    "Log in": "Inloggen",
    "Log in again": "Opnieuw inloggen",
    "Log in as another user": "Inloggen als andere gebruiker",
    "Log out": "Uitloggen",
    "Log phone numbers": "Log telefoonnummers",
    "Logged in": "Ingelogd",
    "Logged in as:": "Ingelogd als:",
    "Longer than": "Langer dan",
    "Looker Studio": "Looker Studio",
    "MM-YYYY": "MM-JJJJ",
    "Mail log": "Mail log",
    "Main": "Primair",
    "Manage offsite phone numbers": "Beheer offsite telefoonnummers",
    "Manual tagging": "Handmatige tagging",
    "Many per click": "Veel-per-klik",
    "Mark": "Markeer",
    "Mark as cancelled at vendor": "Markeer als stopgezet bij vendor",
    "Mark as main": "Markeer als primair",
    "Mark stop requests": "Markeer stopzettingen",
    "Marked": "Gemarkeerd",
    "Marketing": "Marketing",
    "Marketing agency": "Marketingbureau",
    "Marking": "Markering",
    "Match key": "Koppelbenaming",
    "Match type": "Koppeltype",
    "Max. call attempts": "Max. belpogingen",
    "Measurement ID": "Metings-ID",
    "Medium": "Medium",
    "Message": "Bericht",
    "Meta Ads": "Meta Ads",
    "Meta data": "Meta data",
    "Method": "Methode",
    "Microsoft Advertising": "Microsoft Advertising",
    "Microsoft Dynamics": "Microsoft Dynamics",
    "Minimum term": "Minimale looptijd",
    "Minute": "Minuut",
    "Missed": "Gemist",
    "Missed calls": "Gemiste oproepen",
    "Missing agreement": "Ontbrekende overeenkomst",
    "Mobile": "Mobiel",
    "Monthly": "Maandelijks",
    "Monthly terminable": "Maandelijks opzegbaar",
    "More information about pricing plans": "Meer informatie over prijsplannen",
    "More integrations": "Meer integraties",
    "My account": "Mijn account",
    "Name": "Benaming",
    "Name in the dashboard": "Benaming in het dashboard",
    "Needed numbers": "Telefoonnummers nodig",
    "Needed numbers according to assigns": "Benodigd: uitgiftes",
    "Needed numbers according to percentage": "Benodigd: percentage",
    "Needed: {value}": "Benodigd: {value}",
    "Netherlands": "Nederland",
    "New": "Nieuw",
    "New AEN": "Nieuwe AEN",
    "New caller": "Nieuwe beller",
    "New callers": "Nieuwe bellers",
    "New condition": "Nieuwe conditie",
    "New domain": "Nieuw domein",
    "New offsite phone number(s)": "Nieuwe offsite telefoonnummer(s)",
    "New offsite phone numbers": "Nieuwe offsite telefoonnummers",
    "New password": "Nieuw wachtwoord",
    "New phone numbers": "Nieuwe telefoonnummers",
    "New pool": "Nieuwe pool",
    "New value": "Nieuwe waarde",
    "Next": "Volgende",
    "Next step": "Volgende stap",
    "No": "Nee",
    "No IP filter": "Geen IP-filter",
    "No SID found": "Geen SID gevonden",
    "No accounts found": "Geen accounts gevonden",
    "No available languages": "Geen beschikbare talen",
    "No call attempts yet": "Nog geen belpogingen",
    "No call data": "Geen oproep-data",
    "No conditions": "Geen condities",
    "No conditions have been set yet.": "Er zijn nog geen condities ingesteld.",
    "No containers found": "Geen containers gevonden",
    "No conversion actions have been set yet.": "Er zijn nog geen conversieacties ingesteld.",
    "No data": "Geen data",
    "No data pushed": "Geen data verstuurd",
    "No document location": "Geen document location",
    "No domain selected": "Geen domein geselecteerd",
    "No domains found": "Geen domeinen gevonden",
    "No dynamic sources": "Geen dynamische bronnen",
    "No dynamic traffic sources": "Geen dynamische verkeersbronnen",
    "No excluded formats": "Geen uitgesloten formats",
    "No file uploaded": "Geen bestand ge\u00fcpload",
    "No format set": "Geen format ingesteld",
    "No included formats": "Geen inbegrepen formats",
    "No invoice profile": "Geen facturatieprofiel",
    "No location": "Geen locatie",
    "No locations": "Geen locaties",
    "No locations set": "Geen locaties ingesteld",
    "No meta data": "Geen meta data",
    "No notifications": "Geen notificaties",
    "No offsite phone numbers": "Geen offsite telefoonnummers",
    "No profiles": "Geen profielen",
    "No remarks": "Geen opmerkingen",
    "No report": "Geen rapportage",
    "No results, try another search term.": "Geen resultaten, probeer een andere zoekterm.",
    "No streams have been set yet.": "Er zijn nog geen streams ingesteld.",
    "No traffic sources": "Geen verkeersbronnen",
    "No upgrade requested": "Geen upgrade aangevraagd",
    "None": "Geen",
    "Not reached": "Niet bereikt",
    "Not sent": "Niet verzonden",
    "Not sent (Action undefined)": "Niet verzonden (Actie niet gedefinieerd)",
    "Not sent (Campaign not found)": "Niet verzonden (Campagnenaam niet gevonden)",
    "Not sent (Expired)": "Niet verzonden (Verlopen)",
    "Not sent (Label undefined)": "Niet verzonden (Label niet gedefinieerd)",
    "Not sent (Name of domain not found)": "Niet verzonden (Domeinnaam niet gevonden)",
    "Not sent (No CID)": "Niet verzonden (Geen CID)",
    "Not sent (No campaign source)": "Niet verzonden (Geen campagnebron)",
    "Not sent (No campaign)": "Niet verzonden (Geen campagne)",
    "Not sent (No duration)": "Niet verzonden (Geen gespreksduur)",
    "Not sent (No evaluation)": "Niet verzonden (Geen evaluatie)",
    "Not sent (No measurement ID known)": "Niet verzonden (Geen metings-ID bekend)",
    "Not sent (No session-details)": "Niet verzonden (Geen sessie-gegevens)",
    "Not sent (Offsite call)": "Niet verzonden (Offsite oproep)",
    "Not sent (Reason unknown)": "Niet verzonden (Reden onbekend)",
    "Not sent (Recurring caller)": "Niet verzonden (Terugkerende beller)",
    "Not sent (Switched off)": "Niet verzonden (Uitgeschakeld)",
    "Not sent yet": "Nog niet verzonden",
    "Not set": "Niet ingevuld",
    "Not verified": "Niet geverifieerd",
    "Notes": "Notities",
    "Notes history": "Notitiegeschiedenis",
    "Notification": "Notificatie",
    "Notification not found": "Notificatie niet gevonden",
    "Notifications": "Notificaties",
    "Number": "Nummer",
    "Number ID": "Nummer-id",
    "Number check": "Nummers checken",
    "Number display": "Nummerweergave",
    "Number distribution": "Nummeruitgifte",
    "Number of assignments": "Aantal uitgiftes",
    "Number of call attempts": "Aantal belpogingen",
    "Number of call requests": "Aantal terugbelverzoeken",
    "Number of calls accepted": "Aantal oproepen geaccepteerd",
    "Number of domains": "Aantal domeinen",
    "Number of locations": "Aantal locaties",
    "Number of offsite phone numbers": "Aantal offsite telefoonnummers",
    "Number of successful calls": "Aantal succesvolle oproepen",
    "Number of tries": "Belpogingen",
    "Numbers": "Nummers",
    "Numbers to remove": "Weg te halen nummers",
    "Numbers without a stop date": "Nummers zonder stopzettingsdatum",
    "Off": "Uit",
    "Offsite": "Offsite",
    "Offsite phone number": "Offsite telefoonnummer",
    "Offsite phone number(s)": "Offsite telefoonnummer(s)",
    "Offsite phone numbers": "Offsite telefoonnummers",
    "Old value": "Oude waarde",
    "On": "Aan",
    "On hold": "On hold",
    "One per click": "E\u00e9n-per-klik",
    "Only free numbers": "Alleen vrije telefoonnummers",
    "Onsite": "Onsite",
    "Open": "Open",
    "Optimize": "Optimize",
    "Optimize (INT.)": "Optimize (INT.)",
    "Optimize (NL)": "Optimize (NL)",
    "Optimize international": "Optimize internationaal",
    "Order for reading": "Volgorde voor uitlezen",
    "Order numbers at Steam-connect": "Nummers bestellen bij Steam-connect",
    "Order phone numbers": "Telefoonnummers bestellen",
    "Order reference": "Order reference",
    "Ordered list": "Genummerde lijst",
    "Other": "Overig",
    "Others": "Overigen",
    "Our packages": "Onze pakketten",
    "Outbound call": "Uitgaande oproep",
    "Outdent": "Inspringing verkleinen",
    "Overview": "Overzicht",
    "Overview mediums - From portal": "Overzicht mediums - Vanuit portaal",
    "Overview mediums - From website": "Overzicht mediums - Vanuit website",
    "Overview mediums - Total": "Overzicht mediums - Totaal",
    "PBX-id": "PBX-id",
    "PBX-id or Call-id": "PBX-id of oproep-id",
    "Paragraph": "Alinea",
    "Paragraph here": "Alinea hier",
    "Partner": "Partner",
    "Partner account": "Partner account",
    "Partner manager": "Partnermanager",
    "Partner status": "Partnerstatus",
    "Password": "Wachtwoord",
    "Password needs to be updated": "Je wachtwoord dient vernieuwd te worden",
    "Passwords match": "Wachtwoorden komen overeen",
    "Past requests": "Eerdere wijzigingen",
    "Pending": "In afwachting",
    "Per sprint": "Per sprint",
    "Percentage": "Percentage",
    "Period": "Periode",
    "Period last year": "Periode vorig jaar",
    "Periodic report": "Periodieke rapportage",
    "Phone number": "Telefoonnummer",
    "Phone number check": "Telefoonnummer check",
    "Phone number format": "Telefoonnummer format",
    "Phone number input": "Ingevoerd telefoonnummer",
    "Phone numbers": "Telefoonnummers",
    "Phone numbers stop requests": "Telefoonnummer stopzettingen",
    "Picked up": "Opgepakt",
    "Pipedrive": "Pipedrive",
    "Place JavaScript": "JavaScript plaatsen",
    "Place JavaScript using Google Tag Manager": "JavaScript plaatsen met behulp van Google Tag Manager",
    "Place conversion action": "Conversieactie plaatsen",
    "Pool": "Pool",
    "Popular": "Populair",
    "Postal code": "Postcode",
    "Postal code + city": "Postcode + woonplaats",
    "Preferences": "Voorkeuren",
    "Prestation report": "Prestatierapport",
    "Preview": "Voorbeeld",
    "Previous": "Vorige",
    "Previous period": "Voorgaande periode",
    "Previous step": "Vorige stap",
    "Price indication": "Prijsindicatie",
    "Pricing plan": "Prijsplan",
    "Primary action": "Primaire actie",
    "Priority": "Prioriteit",
    "Privacy": "Privacy",
    "Proceed": "Doorgaan",
    "Processed": "Uitgevoerd",
    "Processing": "In behandeling",
    "Profile": "Profiel",
    "Profiles": "Profielen",
    "Project ID": "Project-id",
    "Properties": "Eigenschappen",
    "Public": "Publiek",
    "Publish in live containers only": "Alleen publiceren in live containers",
    "Range": "Reeks",
    "Reaction time": "Reactietijd",
    "Read more": "Lees meer",
    "Ready?": "Klaar voor de start?",
    "Recommendation": "Aanbeveling",
    "Recurring caller": "Terugkerende beller",
    "Redo": "Opnieuw",
    "Reference": "Referentie",
    "Reference \/ Purchase Order Number": "Referentie \/ Kostenplaats",
    "Refresh": "Verversen",
    "Refresh token": "Token vernieuwen",
    "Refused": "Geweigerd",
    "Register": "Registreer",
    "Related": "Gerelateerd",
    "Relay42": "Relay42",
    "Release notes": "Release notes",
    "Release notes from {repo}": "Release notes van {repo}",
    "Release notes per sprint": "Release notes per sprint",
    "Remarks": "Opmerkingen",
    "Remember me": "Onthoud mij",
    "Removal date": "Verwijderingsdatum",
    "Remove": "Verwijderen",
    "Remove from blacklist": "Verwijder van zwarte lijst",
    "Remove location": "Locatie verwijderen",
    "Repeat": "Herhaal",
    "Request": "Aanvraag",
    "Request URL": "Aanvraag-URL",
    "Request date": "Datum aanvraag",
    "Request domain": "Domein aanvragen",
    "Request new domain": "Nieuw domein aanvragen",
    "Requested": "In aanvraag",
    "Requested at": "Aangevraagd op",
    "Requested by": "Aangevraagd door",
    "Requested for": "Aangevraagd voor",
    "Requested location": "Aangevraagde locatie",
    "Requested traffic sources": "Aangevraagde verkeersbronnen",
    "Requested traffic sources setup": "Aangevraagde verkeersbronnen-opzet",
    "Requested: Missing agreement": "Aangevraagd: ontbrekende overeenkomst",
    "Requesting user": "Aanvrager",
    "Requests": "Aanvragen",
    "Reseller": "Partner",
    "Resellers": "Partners",
    "Response time": "Reactietijd",
    "Restore customer ID": "Klant-id herstellen",
    "Revoke access": "Toegang intrekken",
    "Rights": "Rechten",
    "Role": "Rol",
    "SIP trunk": "SIP trunk",
    "Sale": "Verkoop",
    "Salesforce": "Salesforce",
    "Same as general": "Zelfde als algemeen",
    "Save": "Opslaan",
    "Save and send to GA": "Opslaan en versturen naar GA",
    "Save as draft": "Opslaan als concept",
    "Save changes": "Wijzigingen opslaan",
    "Save invoice profile": "Facturatieprofiel opslaan",
    "Scan for formats": "Scan voor formats",
    "Scanning for formats": "Aan het scannen voor formats",
    "Schedule": "Inplannen",
    "Schedule removal": "Verwijdering inplannen",
    "Search": "Zoeken",
    "Search within the knowledge center": "Zoeken in het kenniscentrum",
    "Second event": "Tweede gebeurtenis",
    "Secondary action": "Secundaire actie",
    "Select": "Selecteer",
    "Select a contract duration": "Selecteer een contractduur",
    "Select a number format": "Selecteer een nummer formaat",
    "Select a pricing plan": "Selecteer een prijsplan",
    "Select a start date": "Selecteer een ingangsdatum",
    "Select all": "Alles selecteren",
    "Select an account": "Selecteer een account",
    "Select customer center": "Selecteer klantcentrum",
    "Select evaluation": "Kies beoordeling",
    "Select traffic sources": "Verkeersbronnen selecteren",
    "Select which location you want the assign the format to.": "Selecteer de locatie waar je het format aan wilt toewijzen.",
    "Selected traffic sources": "Geselecteerde verkeersbronnen",
    "Selection": "Keuze",
    "Selection menu": "Keuzemenu",
    "Send": "Verzenden",
    "Send JavaScript by email": "JavaScript versturen per e-mail",
    "Send call data": "Oproepdata meesturen",
    "Send call details by email": "Verstuur oproepdetails per e-mail",
    "Send call request details by email": "Verstuur terugbelverzoekdetails per e-mail",
    "Send calls": "Oproepen versturen",
    "Send conversion values": "Conversiewaarden versturen",
    "Send conversions": "Conversies versturen",
    "Send data": "Data versturen",
    "Send date to API": "Datum meesturen naar de API",
    "Send default event for all calls": "Standaard gebeurtenis versturen voor alle oproepen",
    "Send e-commerce transactions": "E-commerce-transacties versturen",
    "Send email": "E-mail versturen",
    "Send events": "Gebeurtenissen versturen",
    "Send fallback conversions": "Fallback conversies versturen",
    "Send implementation instructions by email": "Implementatie-instructies versturen per e-mail",
    "Send implementation mail": "Implementatie-mail versturen",
    "Send notifications for": "Notificaties versturen voor",
    "Send offsite calls": "Offsite oproepen versturen",
    "Send offsite conversions": "Offsite conversies versturen",
    "Send pre-release": "Pre-release verzenden",
    "Send recurring calls": "Terugkerende oproepen versturen",
    "Send request": "Aanvraag verzenden",
    "Send second event": "Tweede gebeurtenis versturen",
    "Send verification code by email": "Verstuur verificatiecode via e-mail",
    "Sender": "Afzender",
    "Sent": "Verzonden",
    "Sent to Analytics": "Verstuurd naar Analytics",
    "Sent twice": "2x verzonden",
    "Session data": "Sessiedata",
    "Session filter": "Sessie filter",
    "Session filter conditions": "Sessie filter condities",
    "Session stop": "Sessie-stop",
    "Session stop reached at": "Sessie-stop bereikt op",
    "Sessions": "Sessies",
    "Sessions (included)": "Sessies (inclusief)",
    "Set IP filter": "Ingesteld IP-filter",
    "Set IP filters": "Ingestelde IP-filters",
    "Set a new password": "Stel een nieuw wachtwoord in",
    "Set columns": "Kolommen instellen",
    "Set the locations you want to track": "Stel de locaties in die je wilt meten",
    "Set the traffic sources you want to track": "Stel de verkeersbronnen in die je wilt meten",
    "Set up manual tagging": "Handmatige tagging instellen",
    "Set up session stop": "Sessie-stop instellen",
    "Set up traffic sources": "Verkeersbronnen instellen",
    "Setting type": "Instellingstype",
    "Setting type ID": "Instellingstype ID",
    "Shorter than": "Korter dan",
    "Show all calls": "Alle oproepen weergeven",
    "Show all notifications": "Toon alle notificaties",
    "Show intake": "Toon intake",
    "Show numbers": "Toon nummers",
    "Show preview": "Voorbeeld tonen",
    "Show source code": "Code tonen",
    "Sign the latest version": "Teken de laatste versie",
    "Signer name": "Naam ondertekenaar",
    "Skip number check": "Nummer-controle overslaan",
    "Slug": "Slug",
    "Slugs": "Slugs",
    "Sources": "Bronnen",
    "Sources change": "Bronnenwijziging",
    "Space": "Spatie",
    "Specific (inverted)": "Specifiek (omgekeerd)",
    "Specific traffic sources": "Specifieke verkeersbronnen",
    "Specify phone number formats": "Geef telefoonnummer formats op",
    "Standard": "Standaard",
    "Standard implementation": "Standaard implementatie",
    "Start": "Start",
    "Start call request": "Terugbelverzoek starten",
    "Start date": "Ingangsdatum",
    "Start guided tour": "Start de rondleiding",
    "Started at": "Gestart op",
    "Status": "Status",
    "Status Double Click CM": "Status Double Click CM",
    "Status Google Ads": "Status Google Ads",
    "Status Google Ads Enhanced Conversions": "Status Google Ads Enhanced Conversions",
    "Status Google Analytics 4": "Status Google Analytics 4",
    "Status LEF": "Status LEF",
    "Status Microsoft Advertising": "Status Microsoft Advertising",
    "Status integrations": "Status integraties",
    "Stay logged out": "Uitgelogd blijven",
    "Step {x}: {label}": "Stap {x}: {label}",
    "Stop at Voxbone": "Stopzetten bij Voxbone",
    "Stop at Voxbone logs": "Stopzetten bij Voxbone logs",
    "Stop at vendor": "Zet stop bij vendor",
    "Stop call request": "Terugbelverzoek stoppen",
    "Stop domain": "Domein stopzetten",
    "Stop location": "Locatie stopzetten",
    "Stop requested": "Stopzetting aangevraagd",
    "Stop requested for": "Aangevraagd voor",
    "Stopped": "Gestopt",
    "Stopped on": "Stopgezet op",
    "Stream": "Stream",
    "Streams": "Streams",
    "Subject": "Onderwerp",
    "Submit": "Verstuur",
    "Submit a ticket": "Maak een ticket aan",
    "Submit ticket": "Ticket indienen",
    "Subscription": "Abonnement",
    "Success percentage": "Succespercentage",
    "Successful": "Succesvol",
    "Summary": "Samenvatting",
    "Support": "Support",
    "Switch": "Overstappen",
    "Switch to Optimize": "Overstappen naar Optimize",
    "Sync Chargebee invoices to Exact": "Chargebee facturen synchroniseren naar Exact",
    "Sync conversion action": "Conversieactie synchroniseren",
    "Sync script for all domains": "Synchroniseer script voor alle domeinen",
    "Sync script for specific domain": "Synchroniseer script voor een specifiek domein",
    "Tablet": "Tablet",
    "Test webhook": "Webhook testen",
    "Text color": "Tekstkleur",
    "Thanks for registering!": "Bedankt voor je registratie!",
    "The format can't be highlighted because it isn't visible in the iframe.": "Het format kan niet worden gehighlight omdat het niet zichtbaar is in het iframe.",
    "The page cannot be loaded": "De pagina kan niet worden geladen",
    "There are no email addresses set.": "Er zijn geen e-mailadressen ingesteld.",
    "There are no remarks.": "Er zijn geen opmerkingen.",
    "There is no domain available yet": "Er is nog geen domein beschikbaar",
    "This article does not exist": "Dit artikel bestaat niet",
    "This category does not exist": "Deze categorie bestaat niet",
    "This domain": "Dit domein",
    "This domain does not exist": "Dit domein bestaat niet",
    "This field": "Dit veld",
    "This page cannot be loaded at the moment. Try refreshing the page or contact us at <a href=\"mailto:support@adcalls.nl\">support@adcalls.nl<\/a>": "De pagina kan momenteel niet worden geladen. Probeer de pagina te verversen of neem contact met ons op via <a href=\"mailto:support@adcalls.nl\">support@adcalls.nl<\/a>",
    "This reseller does not exist": "Deze partner bestaat niet",
    "This user does not exist": "Deze gebruiker bestaat niet",
    "Till": "Tot",
    "Times": "Tijdstippen",
    "Title": "Titel",
    "To the attention of": "Ter attentie van",
    "To the dashboard": "Naar het dashboard",
    "Token": "Token",
    "Top 5: Best performing mediums": "Top 5: Best presterende mediums",
    "Top 5: Number of contact moments": "Top 5: Aantal contactmomenten",
    "Tot. value": "Tot. waarde",
    "Total": "Totaal",
    "Total assignments": "Totaal uitgiftes",
    "Total calls": "Totale oproepen",
    "Total per month": "Totaal per maand",
    "Total revenue": "Totale omzet",
    "Track all traffic sources": "Alle verkeersbronnen meten",
    "Track e-commerce transactions": "E-commerce-transacties bijhouden",
    "Tracking ID": "Tracking ID",
    "Tracking and filters": "Tracking en filters",
    "Tracking point name": "Tracking point name",
    "Traffic source": "Verkeersbron",
    "Traffic sources": "Verkeersbronnen",
    "Translations": "Vertalingen",
    "Trial": "Proefperiode",
    "Trial end date": "Proefperiode einddatum",
    "Trial month": "Proefperiode maand",
    "Trigger": "Trigger",
    "Try the demo": "Demo bekijken",
    "Two-step verification": "Tweestapsverificatie",
    "Type": "Type",
    "Type a note": "Typ hier je notitie",
    "Type of caller": "Type beller",
    "Type of traffic": "Type verkeer",
    "UA-code \/ G-code": "UA-code \/ G-code",
    "URL": "URL",
    "Underline": "Onderstrepen",
    "Undo": "Ongedaan maken",
    "Unique calls": "Unieke oproepen",
    "Unlink": "Link verwijderen",
    "Unmarked": "Ongemarkeerd",
    "Unordered list": "Lijst met opsommingstekens",
    "Until": "T\/m",
    "Update AEN table": "Update AEN tabel",
    "Update blacklisted phone number": "Telefoonnummer op de zwarte lijst wijzigen",
    "Update conversion action": "Conversieactie updaten",
    "Update invoice profile": "Facturatieprofiel bijwerken",
    "Update numbers": "Update nummers",
    "Update stop request date": "Stopzettingsdatum bewerken",
    "Updates": "Updates",
    "Upgrade": "Upgraden",
    "Upgrade requested": "Upgrade aangevraagd",
    "Upgrade status": "Upgrade status",
    "Upgrade subscription": "Upgrade abonnement",
    "Upgrade summary": "Samenvatting upgrade",
    "Upload a file": "Bestand uploaden",
    "Uploaded file: {file}": "Ge\u00fcploade bestand: {file}",
    "Upscale": "Opschalen",
    "Use stop functions": "Gebruik stop functionaliteiten",
    "User": "Gebruiker",
    "User info": "Gebruikersinformatie",
    "User profile ID": "Gebruikersprofiel-id",
    "Username": "Gebruikersnaam",
    "Users": "Gebruikers",
    "Users overview": "Gebruikersoverzicht",
    "VAT-number": "BTW-nummer",
    "VWO": "VWO",
    "Value": "Waarde",
    "Verification code": "Verificatiecode",
    "Verified": "Geverifieerd",
    "Verify account": "Verifieer account",
    "Verify code": "Code  verifi\u00ebren",
    "Verify email change": "Wijziging e-mail bevestigen",
    "Verify link": "Koppeling verifi\u00ebren",
    "Version": "Versie",
    "View": "Weergeven",
    "View all": "Alles bekijken",
    "View connector": "Connector bekijken",
    "View: {amount} of {total} total.": "Bekijk: {amount} van {total} totaal.",
    "Visual Website Optimizer": "Visual Website Optimizer",
    "VoIP": "VoIP",
    "Waiting for customer": "In afwachting van opdrachtgever",
    "Warning": "Waarschuwing",
    "Webhook": "Webhook",
    "Webhooks": "Webhooks ",
    "Weekly": "Wekelijks",
    "What can we help you with?": "Waar kunnen we je mee helpen?",
    "Whisper": "Whisper",
    "Width": "Breedte",
    "Will be sent at 22:00 o'clock": "Wordt verzonden om 22:00 uur",
    "Will be sent at 9:00 o'clock": "Wordt verzonden om 9:00 uur",
    "Will be stopped": "Wordt stopgezet",
    "Will be stopped at {date}": "Wordt stopgezet op {date}",
    "Without location": "Zonder locatie",
    "Yes": "Ja",
    "Yes, stop the numbers": "Ja, zet de nummers stop",
    "You currently have no requests.": "Je hebt momenteel geen aanvragen.",
    "You don't have any saved requests.": "Je hebt geen opgeslagen aanvragen.",
    "Your session has expired": "Je sessie is verlopen",
    "Zoho": "Zoho",
    "bGenius": "bGenius",
    "dd-mm-yyyy": "dd-mm-jjjj",
    "example-1@domain.com, example-2@domain.com": "voorbeeld-1@domein.nl, voorbeeld-2@domein.nl",
    "yyyy-mm-dd": "jjjj-mm-dd",
    "{days}d": "{days}d",
    "{extraOffsiteNumbers} extra offsite phone number(s)": "{extraOffsiteNumbers} extra offsite telefoonnummer(s)",
    "{hours}h": "{hours}u",
    "{key} (previous period)": "{key} (voorgaande periode)",
    "{locations} extra location(s) per {thousand} sessions": "{locations} extra locatie(s) per {thousand} sessies",
    "{minutes}m": "{minutes}m",
    "{number} characters": "{number} tekens",
    "{offsiteCalls} calls": "{offsiteCalls} oproepen",
    "{offsiteMinutes} call minutes": "{offsiteMinutes} belminuten",
    "{percentage}% discount contract duration {months} months": "{percentage}% korting contractduur {months} maanden",
    "{pricingPlan} incl. {includedSessions} sessions": "{pricingPlan} incl. {includedSessions} sessies",
    "{seconds}s": "{seconds}s",
    "{sessions} extra sessions": "{sessions} extra sessies",
    "{type} categories": "{type} categorie\u00ebn"
}