import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { FormikHelpers } from 'formik';

import ContentSidebar from 'components/unauthenticated/ContentSidebar';
import LoginNewsPage from 'components/unauthenticated/LoginNewsPage';
import RegistrationForm, { FormValues } from 'components/unauthenticated/RegistrationForm';
import { useMessages } from 'contexts/Messages';
import { useAcceptInvitation } from 'hooks/queries/auth';
import { useTranslate } from 'hooks/translate';

export default function AcceptInvitation() {
  const navigate = useNavigate();
  const translateText = useTranslate();
  const { addErrorMessage } = useMessages();
  const { acceptInvitation } = useAcceptInvitation();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  }, [token, navigate]);

  async function save(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    await acceptInvitation({
      company: values.company.trim(),
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      phonenumber: values.phonenumber.trim(),
      newPassword: values.password,
      verifyNewPassword: values.passwordVerification,
      token: token ?? '',
    }).catch(error => {
      if (error.password) formikHelpers.setFieldError('password', error.password[0]);
      else addErrorMessage(error);
    });
  }

  return (
    <ContentSidebar
      left={
        <RegistrationForm
          save={save}
          title={translateText('label', 'Accept invitation')}
          intro={<p>{translateText('text', 'Enter your information to activate your account.')}</p>}
          submitLabel={translateText('label', 'Register')}
          excludeEmail
        />
      }
      right={<LoginNewsPage />}
    />
  );
}
