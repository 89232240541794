import { useLocation } from 'react-router';
import { mapValues } from 'lodash';

import countries from 'assets/data/countries-by-alpha-2-code';
import { DataKey, StatisticsDataKey, TickFormatter } from 'components/widget/types';
import { useGlobal } from 'contexts/Global';
import {
  CALL_REQUEST_STATUS_DUPLICATE,
  CALL_REQUEST_STATUS_IN_PROCESS,
  CALL_REQUEST_STATUS_INVALID_NUMBER,
  CALL_REQUEST_STATUS_MANUALLY_STOPPED,
  CALL_REQUEST_STATUS_MAXIMUM_ATTEMPTS_REACHED,
  CALL_REQUEST_STATUS_OFFICE_CLOSED,
  CALL_REQUEST_STATUS_PENDING,
  CALL_REQUEST_STATUS_PENDING_DISABLED,
  CALL_REQUEST_STATUS_SUCCESSFUL,
  ROLE_ADMIN,
  ROLE_CUSTOMER,
  ROLE_EMPLOYEE,
  ROLE_MANAGER,
  ROLE_SYSTEM,
  TRAFFIC_TYPE_APP,
  TRAFFIC_TYPE_DYNAMIC,
  TRAFFIC_TYPE_OFFSITE,
  TYPE_CALL_TRACKING,
  TYPE_FORM_TRACKING,
} from 'globals/constants';
import { useDateTime } from 'hooks/date-time';
import { useTranslate } from 'hooks/translate';

export function useRoleTranslations() {
  const translateText = useTranslate();
  return {
    [ROLE_SYSTEM]: translateText('role', 'System'),
    [ROLE_ADMIN]: translateText('role', 'Admin'),
    [ROLE_EMPLOYEE]: translateText('role', 'Employee'),
    [ROLE_MANAGER]: translateText('role', 'Manager'),
    [ROLE_CUSTOMER]: translateText('role', 'Customer'),
  };
}

export function useCountryTranslations(): Record<string, string> {
  const translateText = useTranslate();
  return mapValues(countries, c => translateText('country', c));
}

export function useOverviewWidgetContent({
  showSessions = true,
  showConvRate = true,
  showPending = false,
  isSelectable = true,
} = {}) {
  const translateText = useTranslate();
  const { formatSeconds } = useDateTime();
  const callTrackingWidgetKeys: Record<string, StatisticsDataKey> = {
    ...(showSessions && {
      sessions: {
        name: translateText('label', 'Sessions'),
        tooltip: translateText(
          'tooltip/widgets',
          'The total number of sessions. A session is the period in which a user is active on the website. By default, if a user is inactive for 30 minutes or more, new activity is attributed to a new session. If users leave the website and return within 30 minutes, this is counted as the original session.',
        ),
        isSelectable: true,
      },
    }),
    calls: {
      name: translateText('label', 'Calls'),
      tooltip: translateText('tooltip/widgets', 'The total number of realized calls.'),
      isSelectable: true,
    },
    calls_missed: {
      name: translateText('label', 'Missed'),
      tooltip: translateText('tooltip/widgets', 'The total number of missed calls.'),
      isSelectable: true,
      lessIsBetter: true,
    },
    calls_uqcallers: {
      name: translateText('label', 'New callers'),
      tooltip: translateText(
        'tooltip/widgets',
        'A new caller is a caller whose sender has not previously called for that domain within the last 30 days.',
      ),
      isSelectable: true,
    },
    calls_avgduration: {
      name: translateText('label', 'Avg. call duration'),
      tooltip: translateText(
        'tooltip/widgets',
        'The average call duration of the total number of calls made.',
      ),
      isSelectable: true,
      type: 'time',
    },
    sent_to_analytics: {
      name: translateText('label', 'Calls to Analytics'),
      tooltip: translateText(
        'tooltip/widgets',
        'The total number of calls sent to Google Analytics based on the settings.',
      ),
      isSelectable: true,
    },
    ...(showConvRate && {
      conv_rate: {
        name: translateText('label', 'Conversion rate'),
        tooltip: translateText(
          'tooltip/widgets',
          'The ratio between the number of calls and the number of sessions realized, expressed as a percentage. This looks at the number of calls processed in Google Analytics.',
        ),
        isPercentage: true,
      },
    }),
  };

  const formTrackingWidgetKeys: Record<string, StatisticsDataKey> = {
    call_requests: {
      name: translateText('label', 'Call requests'),
      tooltip: translateText('tooltip/widgets', 'The total number of call requests.'),
      isSelectable,
    },
    ...(showPending && {
      call_requests_pending: {
        name: translateText('label', 'Pending'),
        tooltip: translateText(
          'tooltip/widgets',
          'The number of call request(s) that will soon be started based on the chosen settings.',
        ),
      },
    }),
    call_requests_success: {
      name: translateText('label', 'Successful'),
      tooltip: translateText(
        'tooltip/widgets',
        'The total number of call requests for which a successful call has been made between both parties.',
      ),
      isSelectable,
    },
    calls_avgresponse: {
      name: translateText('label', 'Avg. response time'),
      tooltip: translateText(
        'tooltip/widgets',
        'The average time between submitting the call request and establishing a successful call.',
      ),
      format: value => formatSeconds(String(value)),
      isSelectable,
      lessIsBetter: true,
    },
    calls_avgduration: {
      name: translateText('label', 'Avg. duration'),
      tooltip: translateText('tooltip/widgets', 'The average duration of a successful call.'),
      isSelectable,
    },
    calls_avg: {
      name: translateText('label', 'Avg. number of call attempts'),
      tooltip: translateText(
        'tooltip/widgets',
        'The average number of call attempts required to establish a successful call between two parties.',
      ),
      isSelectable,
      lessIsBetter: true,
    },
    total_calls: {
      name: translateText('label', 'Calls'),
      tooltip: translateText(
        'tooltip/widgets',
        'The total number of calls from all call requests.',
      ),
      isSelectable,
    },
  };

  function getCallTrackingWidgetBySelected(
    selected: string,
  ): [Record<string, DataKey>, TickFormatter?] {
    switch (selected) {
      case 'calls_avgduration':
        return [
          { avg_duration: { name: translateText('label', 'Average call duration') } },
          {
            format: value => formatSeconds(String(value)),
            y: true,
          },
        ];
      case 'calls_uqcallers':
        return [{ calls_uqcallers: { name: translateText('label', 'New callers') } }];
      case 'calls_missed':
        return [{ calls_missed: { name: translateText('label', 'Missed calls') } }];
      case 'conv_rate':
        return [{ conv_rate: { name: translateText('label', 'Conversion rate') } }];
      case 'sent_to_analytics':
        return [{ sent_to_analytics: { name: translateText('label', 'Sent to Analytics') } }];
      case 'sessions':
        return [{ sessions: { name: translateText('label', 'Sessions') } }];
      case 'calls':
      default:
        return [
          {
            calls: { name: translateText('label', 'Calls') },
            calls_missed: {
              name: translateText('label', 'Missed calls'),
            },
          },
        ];
    }
  }

  function getFormTrackingWidgetBySelected(
    selected: string,
  ): [Record<string, DataKey>, TickFormatter?] {
    switch (selected) {
      case 'total_calls':
        return [{ calls: { name: translateText('label', 'Total calls') } }];
      case 'calls_avgresponse':
        return [
          {
            calls_avg_response_time: {
              name: translateText('label', 'Average response time'),
            },
          },
          {
            format: value => formatSeconds(String(value)),
            y: true,
          },
        ];
      case 'calls_avgduration':
        return [
          { calls_avgduration: { name: translateText('label', 'Average duration') } },
          {
            format: value => formatSeconds(String(value)),
            y: true,
          },
        ];
      case 'calls_avg':
        return [{ calls_avg: { name: translateText('label', 'Average number of call attempts') } }];
      case 'call_requests_success':
        return [{ call_requests_success: { name: translateText('label', 'Successful') } }];
      case 'call_requests':
      default:
        return [{ call_requests: { name: translateText('label', 'Call requests') } }];
    }
  }

  return {
    callTrackingWidgetKeys,
    formTrackingWidgetKeys,
    getCallTrackingWidgetBySelected,
    getFormTrackingWidgetBySelected,
  };
}

export function useReportsFilterContent() {
  const translateText = useTranslate();
  const location = useLocation();
  const { showReportsFilter } = useGlobal();

  return {
    period: {
      label: translateText('label', 'Period'),
    },
    ...(showReportsFilter === TYPE_CALL_TRACKING && {
      sources: {
        label: translateText('label', 'Traffic source'),
      },
    }),
    ...(showReportsFilter !== 'admin' && {
      locations: {
        label: translateText('label', 'Location'),
      },
    }),
    ...(showReportsFilter === TYPE_CALL_TRACKING && {
      traffic: {
        label: translateText('label', 'Type of traffic'),
        options: {
          [TRAFFIC_TYPE_DYNAMIC]: translateText('label', 'Dynamic'),
          [TRAFFIC_TYPE_OFFSITE]: translateText('label', 'Offsite'),
          [TRAFFIC_TYPE_APP]: translateText('label', 'App'),
        },
      },
    }),
    ...(showReportsFilter === TYPE_CALL_TRACKING && {
      statusCallTracking: {
        label: translateText('label', 'Status'),
        options: {
          0: translateText('label', 'Missed'),
          1: translateText('label', 'Answered'),
          allCalls: translateText('label', 'Show all calls'),
        },
      },
    }),
    ...((showReportsFilter === TYPE_CALL_TRACKING ||
      location.pathname === '/form-tracking/history') && {
      marked: {
        label: translateText('label', 'Marking'),
        options: {
          true: translateText('label', 'Marked'),
          false: translateText('label', 'Unmarked'),
          allCalls: translateText('label', 'Show all calls'),
        },
      },
    }),
    ...(showReportsFilter === TYPE_CALL_TRACKING && {
      unique: {
        label: translateText('label', 'Type of caller'),
        options: {
          0: translateText('label', 'New caller'),
          1: translateText('label', 'Recurring caller'),
          allCalls: translateText('label', 'Show all calls'),
        },
      },
    }),
    ...(showReportsFilter === TYPE_CALL_TRACKING && {
      duration: {
        label: translateText('label', 'Call duration'),
        options: {
          '>': translateText('label', 'Longer than'),
          '<': translateText('label', 'Shorter than'),
          '=': translateText('label', 'Equal to'),
          'allCalls': translateText('label', 'Show all calls'),
        },
      },
    }),
    ...(showReportsFilter === TYPE_FORM_TRACKING && {
      statusFormTracking: {
        label: translateText('label', 'Status'),
        options: {
          [CALL_REQUEST_STATUS_PENDING]: translateText('label', 'Pending'),
          [CALL_REQUEST_STATUS_PENDING_DISABLED]: translateText('label', 'Disabled'),
          [CALL_REQUEST_STATUS_IN_PROCESS]: translateText('label', 'In progress'),
          [CALL_REQUEST_STATUS_INVALID_NUMBER]: translateText('label', 'Invalid phone number'),
          [CALL_REQUEST_STATUS_DUPLICATE]: translateText('label', 'Duplicate'),
          [CALL_REQUEST_STATUS_MANUALLY_STOPPED]: translateText('label', 'Stopped'),
          [CALL_REQUEST_STATUS_OFFICE_CLOSED]: translateText(
            'label',
            'Disabled due to opening hours',
          ),
          [CALL_REQUEST_STATUS_MAXIMUM_ATTEMPTS_REACHED]: translateText(
            'label',
            'Max. call attempts',
          ),
          [CALL_REQUEST_STATUS_SUCCESSFUL]: translateText('label', 'Successful'),
        },
      },
    }),
    ...(showReportsFilter !== 'admin' && {
      statusGA4: {
        label: translateText('label', 'Status Google Analytics 4'),
        options: {
          sent: translateText('label', 'Sent'),
          notSent: translateText('label', 'Not sent'),
          notSentYet: translateText('label', 'Not sent yet'),
        },
      },
    }),
    ...(showReportsFilter !== 'admin' && {
      statusGoogleAds: {
        label: translateText('label', 'Status Google Ads'),
        options: {
          sent: translateText('label', 'Sent'),
          notSent: translateText('label', 'Not sent'),
          errorOnSending: translateText('label', 'Error on sending'),
        },
      },
    }),
    ...(showReportsFilter !== 'admin' && {
      statusMicrosoftAdvertising: {
        label: translateText('label', 'Status Microsoft Advertising'),
        options: {
          sent: translateText('label', 'Sent'),
          notSent: translateText('label', 'Not sent'),
          errorOnSending: translateText('label', 'Error on sending'),
        },
      },
    }),
    ...(showReportsFilter === TYPE_CALL_TRACKING && {
      statusDoubleClick: {
        label: translateText('label', 'Status Double Click CM'),
        options: {
          sent: translateText('label', 'Sent'),
          notSent: translateText('label', 'Not sent'),
          errorOnSending: translateText('label', 'Error on sending'),
        },
      },
    }),
    ...(location.pathname === '/call-tracking/history' && {
      statusLef: {
        label: translateText('label', 'Status LEF'),
        options: {
          sent: translateText('label', 'Sent'),
          notSent: translateText('label', 'Not sent'),
          errorOnSending: translateText('label', 'Error on sending'),
        },
      },
    }),
  };
}

export function useAnalyticsCodes() {
  const translateText = useTranslate();
  return {
    0: translateText('label', 'Not sent yet'),
    1: translateText('label', 'Sent'),
    2: translateText('label', 'Sent'),
    9: translateText('label', 'Outbound call'),
    12: translateText('label', 'Sent twice'),
    20: translateText('label', "Don't send yet"),
    99: translateText('label', 'Not sent (Switched off)'),
    100: translateText('label', 'Not sent (Expired)'),
    101: translateText('label', 'Not sent (Name of domain not found)'),
    102: translateText('label', 'Not sent (Campaign not found)'),
    103: translateText('label', 'Not sent (Recurring caller)'),
    104: translateText('label', 'Not sent (No measurement ID known)'),
    105: translateText('label', 'Not sent'),
    106: translateText('label', 'Not sent (No session-details)'),
    107: translateText('label', 'Not sent (Offsite call)'),
    108: translateText('label', 'Not sent (No CID)'),
    109: translateText('label', 'Not sent'),
    110: translateText('label', 'Not sent (No campaign)'),
    111: translateText('label', 'Not sent (No campaign source)'),
    112: translateText('label', 'Not sent (No evaluation)'),
    113: translateText('label', 'Not sent (Action undefined)'),
    114: translateText('label', 'Not sent (Label undefined)'),
    115: translateText('label', 'Not sent (Reason unknown)'),
    116: translateText('label', 'Not sent (No duration)'),
    117: translateText('label', 'Not sent'),
    209: translateText('label', "Will be sent at 9:00 o'clock"),
    222: translateText('label', "Will be sent at 22:00 o'clock"),
    400: translateText('label', 'GA4 disabled'),
    401: translateText('label', 'No SID found'),
    402: translateText('label', 'No document location'),
    403: translateText('label', 'No call data'),
    404: translateText('label', 'No data pushed'),
    406: translateText('label', 'Not sent'),
  };
}

export function useTrafficSourcesText() {
  const translateText = useTranslate();
  return (sources: string[], included: 0 | 1 | null) => {
    if (included) {
      if (sources.length === 0) {
        return translateText('label', 'No dynamic sources');
      }
      return '' + sources.length;
    } else if (sources.length === 0) {
      return translateText('label', 'All traffic sources');
    }
    return translateText('label', 'All traffic sources, but excluded {amount} traffic source(s)', {
      amount: sources.length,
    });
  };
}
