import { ReactElement } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useIsFetching } from '@tanstack/react-query';

import IntegrationCard from 'components/integrations/IntegrationCard';
import { useHasAccessToIntegration, useHasManagerAccess, useHasOptimizePlan } from 'hooks/access';
import { useIntegrationsContent } from 'hooks/integrations';
import { useStatuses } from 'hooks/queries/integration';
import { useTranslate } from 'hooks/translate';
import GoogleAds from 'pages/authenticated/integrations/advertising/GoogleAds';
import GoogleAdsEc from 'pages/authenticated/integrations/advertising/GoogleAdsEc';
import Lef from 'pages/authenticated/integrations/crm/Lef';
import MicrosoftDynamics from 'pages/authenticated/integrations/crm/MicrosoftDynamics';
import Adform from './advertising/Adform';
import BillyGrace from './advertising/BillyGrace';
import DoubleClick from './advertising/DoubleClick';
import GoogleDisplayVideo360 from './advertising/GoogleDisplayVideo360';
import GoogleSearchAds360 from './advertising/GoogleSearchAds360';
import MetaAds from './advertising/MetaAds';
import Microsoft from './advertising/Microsoft';
import AdCallsApi from './api-webhooks/AdCallsApi';
import Futy from './crm/Futy';
import HubSpot from './crm/HubSpot';
import MicrosoftDynamicsRequest from './crm/MicrosoftDynamicsRequest';
import Pipedrive from './crm/Pipedrive';
import PipedriveRequest from './crm/PipedriveRequest';
import Salesforce from './crm/Salesforce';
import SalesforceRequest from './crm/SalesforceRequest';
import Zoho from './crm/Zoho';
import ZohoRequest from './crm/ZohoRequest';
import BGenius from './data-management/BGenius';
import BigQuery from './data-management/BigQuery';
import BlueConic from './data-management/BlueConic';
import Relay42 from './data-management/Relay42';
import LookerStudio from './reports/LookerStudio';
import GoogleAnalytics4 from './web-analysis/GoogleAnalytics4';
import Vwo from './web-analysis/Vwo';

const GOOGLE_ANALYTICS_4 = 'google-analytics-4' as const;
const GOOGLE_ADS = 'google' as const;
const GOOGLE_ADS_EC = 'google-ads-enhanced-conversions' as const;
const MICROSOFT = 'microsoft' as const;
const DOUBLECLICK = 'doubleclick' as const;
const GOOGLE_DISPLAY_VIDEO_360 = 'display-video-360' as const;
const GOOGLE_SEARCH_ADS_360 = 'search-360' as const;
const ADFORM = 'adform' as const;
const LOOKER_STUDIO = 'looker-studio' as const;
const ADCALLS_API = 'adcalls-api' as const;
const BGENIUS = 'bgenius' as const;
const BIGQUERY = 'bigquery';
const RELAY42 = 'relay42' as const;
const LEF = 'lef' as const;
const BLUECONIC = 'blueconic' as const;
const HUBSPOT = 'hubspot' as const;
const SALESFORCE = 'salesforce' as const;
const SALESFORCE_REQUEST = 'salesforce-request' as const;
const MICROSOFT_DYNAMICS = 'microsoft-dynamics' as const;
const MICROSOFT_DYNAMICS_REQUEST = 'microsoft-dynamics-request' as const;
const PIPEDRIVE = 'pipedrive' as const;
const PIPEDRIVE_REQUEST = 'pipedrive-request' as const;
const ZOHO = 'zoho' as const;
const ZOHO_REQUEST = 'zoho-request' as const;
const BILLY_GRACE = 'billy-grace' as const;
const VWO = 'vwo' as const;
const FUTY = 'futy' as const;
const META_ADS = 'meta-ads' as const;

export default function Integrations() {
  const translateText = useTranslate();
  const navigate = useNavigate();
  const urlParams = useParams();

  const hasManagerAccess = useHasManagerAccess();
  const isOptimize = useHasOptimizePlan();
  const hasAccessToIntegration = useHasAccessToIntegration();

  const integrationsArray = useIntegrationsContent();
  const { statuses, isLoading: loadingStatuses } = useStatuses();
  const queriesLoading = useIsFetching({ queryKey: ['domainAuth'] });
  const isLoading = queriesLoading > 0 || loadingStatuses;

  function goToOverview() {
    navigate('/call-tracking/integrations');
  }

  const integrations: { available: ReactElement[]; unavailable: ReactElement[] } = {
    available: [],
    unavailable: [],
  };

  for (const integrationSettings of integrationsArray) {
    if (
      integrationSettings.onlyShowWhenEnabled &&
      statuses?.[integrationSettings.integrationKey] === 0
    ) {
      continue;
    }

    if (integrationSettings.isRequest) {
      const originalKey = integrationSettings.integrationKey.replace('-request', '');
      if (statuses?.[originalKey] !== 0) {
        continue;
      } else {
        statuses[integrationSettings.integrationKey] = 0;
      }
    }

    const integrationCard = (
      <IntegrationCard
        key={integrationSettings.integrationKey}
        status={statuses?.[integrationSettings.integrationKey] ?? null}
        {...integrationSettings}
        isLoading={isLoading}
        hasPricingPlanAccess={hasAccessToIntegration(integrationSettings.integrationKey)}
      />
    );

    if (!isLoading && !hasAccessToIntegration(integrationSettings.integrationKey)) {
      integrations.unavailable.push(integrationCard);
    } else {
      integrations.available.push(integrationCard);
    }
  }

  function getUnavailableIntegrations() {
    if (!integrations.unavailable.length) return null;
    let title = translateText('label', 'More integrations');
    let text = translateText(
      'integration',
      'These integrations are not available within the Light package. To be able to use these integrations, you must switch to a Basic package. Please contact our support via support@adcalls.nl.',
    );
    if (isOptimize) {
      title = translateText('integration', 'Upgrade to Enterprise');
      text = translateText(
        'integration',
        'Switch to Enterprise to use these integrations. Contact us at support@adcalls.nl for more information.',
      );
    }
    return (
      <div className="integrations-not-available white-block">
        <div className="content">
          <h2 className="bold">{title}</h2>
          <p className="message">{text}</p>
        </div>
        <div className="integration-overview">
          <div className="auto-grid mini">{integrations.unavailable}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="integrations">
      {hasManagerAccess && (
        <>
          <GoogleAnalytics4
            show={urlParams.integration === GOOGLE_ANALYTICS_4}
            close={goToOverview}
          />
          <GoogleAds show={urlParams.integration === GOOGLE_ADS} close={goToOverview} />
          <GoogleAdsEc show={urlParams.integration === GOOGLE_ADS_EC} close={goToOverview} />
          <Microsoft show={urlParams.integration === MICROSOFT} close={goToOverview} />
          <DoubleClick show={urlParams.integration === DOUBLECLICK} close={goToOverview} />
          <GoogleDisplayVideo360
            show={urlParams.integration === GOOGLE_DISPLAY_VIDEO_360}
            close={goToOverview}
          />
          <GoogleSearchAds360
            show={urlParams.integration === GOOGLE_SEARCH_ADS_360}
            close={goToOverview}
          />
          <Adform show={urlParams.integration === ADFORM} close={goToOverview} />
          <LookerStudio show={urlParams.integration === LOOKER_STUDIO} close={goToOverview} />
          <AdCallsApi show={urlParams.integration === ADCALLS_API} close={goToOverview} />
          <BGenius show={urlParams.integration === BGENIUS} close={goToOverview} />
          <BigQuery show={urlParams.integration === BIGQUERY} close={goToOverview} />
          <Relay42 show={urlParams.integration === RELAY42} close={goToOverview} />
          <BlueConic show={urlParams.integration === BLUECONIC} close={goToOverview} />
          <Lef show={urlParams.integration === LEF} close={goToOverview} />
          <HubSpot show={urlParams.integration === HUBSPOT} close={goToOverview} />
          <Salesforce show={urlParams.integration === SALESFORCE} close={goToOverview} />
          <SalesforceRequest
            show={urlParams.integration === SALESFORCE_REQUEST}
            close={goToOverview}
          />
          <MicrosoftDynamics
            show={urlParams.integration === MICROSOFT_DYNAMICS}
            close={goToOverview}
          />
          <MicrosoftDynamicsRequest
            show={urlParams.integration === MICROSOFT_DYNAMICS_REQUEST}
            close={goToOverview}
          />
          <Pipedrive show={urlParams.integration === PIPEDRIVE} close={goToOverview} />
          <PipedriveRequest
            show={urlParams.integration === PIPEDRIVE_REQUEST}
            close={goToOverview}
          />
          <Zoho show={urlParams.integration === ZOHO} close={goToOverview} />
          <ZohoRequest show={urlParams.integration === ZOHO_REQUEST} close={goToOverview} />
          <BillyGrace show={urlParams.integration === BILLY_GRACE} close={goToOverview} />
          <Vwo show={urlParams.integration === VWO} close={goToOverview} />
          <Futy show={urlParams.integration === FUTY} close={goToOverview} />
          <MetaAds show={urlParams.integration === META_ADS} close={goToOverview} />
        </>
      )}
      <h1>{translateText('label', 'Integrations')}</h1>
      <div className="auto-grid tiny integration-overview">{integrations.available}</div>
      {getUnavailableIntegrations()}
    </div>
  );
}
