import { Formik, FormikHelpers } from 'formik';

import Wysiwyg from 'components/admin/Wysiwyg';
import AddButton from 'components/buttons/AddButton';
import SelectFromList from 'components/input/SelectFromList';
import TextField from 'components/input/TextField';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import { CategoryFaq } from 'globals/types';
import { useUpdateCategoryFaqs } from 'hooks/queries/support';
import { useTranslate } from 'hooks/translate';

type Props = {
  categoryId: number;
  faqs: CategoryFaq[];
};

export default function FaqSection({ categoryId, faqs }: Props) {
  const translateText = useTranslate();
  const { updateFaq } = useUpdateCategoryFaqs();

  return (
    <>
      <h1>{translateText('label', 'FAQs')}</h1>
      <Formik<{ faqs: CategoryFaq[]; showEdit: boolean; selectedFaq: CategoryFaq | null }>
        initialValues={{ faqs, showEdit: false, selectedFaq: null }}
        onSubmit={values => updateFaq({ categoryId, faqs: values.faqs })}
      >
        {({ values, setFieldValue, submitForm, isSubmitting, dirty }) => {
          function saveFaq(
            newValues: { title: string; content: string },
            formikHelpers: FormikHelpers<{ title: string; content: string }>,
          ) {
            const newFaqs = values.selectedFaq
              ? values.faqs.map(f => (f.id === values.selectedFaq?.id ? { ...f, ...newValues } : f))
              : [...values.faqs, { ...newValues, id: (values.faqs.at(-1)?.id ?? 0) + 1 }];
            setFieldValue('faqs', newFaqs);
            setFieldValue('showEdit', false);
            formikHelpers.resetForm();
          }

          return (
            <>
              <Formik
                initialValues={{
                  title: values.selectedFaq?.title ?? '',
                  content: values.selectedFaq?.content ?? '',
                }}
                onSubmit={saveFaq}
                enableReinitialize
              >
                {({
                  values: detailValues,
                  handleChange,
                  setFieldValue: setDetailFieldValue,
                  submitForm,
                  dirty,
                }) => {
                  let removeButton;
                  if (values.selectedFaq) {
                    removeButton = (
                      <button
                        className="btn btn-text purple-text"
                        onClick={() => {
                          setFieldValue('showEdit', false);
                          setFieldValue(
                            'faqs',
                            values.faqs.filter(f => f.id !== values.selectedFaq?.id),
                          );
                        }}
                      >
                        {translateText('label', 'Delete FAQ')}
                      </button>
                    );
                  }
                  return (
                    <Setup
                      show={values.showEdit}
                      close={() => {
                        setFieldValue('selectedFaq', null);
                        setFieldValue('showEdit', false);
                      }}
                      title={
                        values.selectedFaq
                          ? translateText('label', 'Edit FAQ')
                          : translateText('label', 'Add FAQ')
                      }
                      save={submitForm}
                      hasChanges={dirty}
                      maxWidth={500}
                      appendage={removeButton}
                    >
                      <InputWrapper label={translateText('label', 'Title')}>
                        <TextField
                          name="title"
                          value={detailValues.title}
                          onChange={handleChange}
                        />
                      </InputWrapper>
                      <InputWrapper label={translateText('label', 'Content')}>
                        <Wysiwyg
                          value={detailValues.content}
                          onChange={value => setDetailFieldValue('content', value)}
                        />
                      </InputWrapper>
                    </Setup>
                  );
                }}
              </Formik>
              <AddButton
                className="faq-add"
                onClick={() => {
                  setFieldValue('selectedFaq', null);
                  setFieldValue('showEdit', true);
                }}
              />
              <button
                className="btn btn-lightblue faq-edit"
                disabled={values.selectedFaq === null}
                onClick={() => setFieldValue('showEdit', true)}
              >
                {translateText('label', 'Edit')}
              </button>
              <SelectFromList
                id="faqs"
                items={values.faqs}
                onChange={value => setFieldValue('faqs', value)}
                nameKey="title"
                selectItem={item => setFieldValue('selectedFaq', item)}
                canChangeOrder
              />
              <button
                className="btn btn-green margin-top"
                disabled={isSubmitting || !dirty}
                onClick={submitForm}
              >
                {translateText('label', 'Save')}
              </button>
            </>
          );
        }}
      </Formik>
    </>
  );
}
