import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { mapValues, pickBy } from 'lodash';
import * as Yup from 'yup';

import ErrorFocus from 'components/ErrorFocus';
import Select from 'components/input/Select';
import SelectFromList from 'components/input/SelectFromList';
import TextField from 'components/input/TextField';
import Toggle from 'components/input/Toggle';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import { useTenant } from 'contexts/Tenant';
import { ROLE_HIERARCHY, ROLE_ID_MAP } from 'globals/constants';
import { Role } from 'globals/types';
import { useRoleTranslations } from 'hooks/content';
import { useTableData } from 'hooks/data';
import { useRevokeInvitation } from 'hooks/queries/invitation';
import { ResellerUser, useInviteUser, useRevokeUser, useUpdateUser } from 'hooks/queries/reseller';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation } from 'hooks/validation';

export type MccUserFormValues = {
  email: string;
  role: string;
  inviteToNewDomains: 0 | 1;
  isAdmin: 0 | 1;
  specificDomains: 0 | 1;
  assignedDomains: string[];
};

type Props = {
  showUser: number | 'new' | false;
  close: () => void;
  resellerId?: number;
};

export default function MccUserSetup({ showUser, close, resellerId }: Props) {
  const translateText = useTranslate();
  const roleTranslations = useRoleTranslations();
  const { email, requiredSelect } = useCustomValidation();
  const { selectedMcc, selectedMccData } = useTenant();
  const { inviteUser } = useInviteUser(resellerId);
  const { updateUser } = useUpdateUser();
  const { revokeInvitation, isLoading: isRevokingInvitation } = useRevokeInvitation();
  const { revokeUser, isLoading: isRevokingUser } = useRevokeUser();

  const mccId = resellerId ?? selectedMcc;
  const isAdmin = resellerId !== undefined; // Reseller ID is only passed on the admin reseller page

  const users = useTableData<ResellerUser>(['reseller', 'users', mccId!]);
  const [user, setUser] = useState<ResellerUser>(emptyUser);

  useEffect(() => {
    if (showUser !== false) {
      setUser(typeof showUser === 'number' ? users!.find(u => u.user_id === showUser)! : emptyUser);
    }
  }, [showUser]); // eslint-disable-line react-hooks/exhaustive-deps

  async function save(values: MccUserFormValues) {
    const promise =
      showUser === 'new' ? inviteUser(values) : updateUser({ values, userId: user.user_id! });
    await promise.then(close);
  }

  async function revokeAccess() {
    const promise = isInvited ? revokeInvitation(String(user.invitation_id)) : revokeUser(user);
    await promise.then(close);
  }

  // Roles can only be the same or lower as the user's mcc role
  const roleOptions = mapValues(
    pickBy<Role>(
      ROLE_ID_MAP,
      value =>
        isAdmin ||
        (selectedMccData?.role &&
          ROLE_HIERARCHY.indexOf(value) >= ROLE_HIERARCHY.indexOf(selectedMccData?.role)),
    ),
    value => roleTranslations[value],
  );

  const isInvited = user?.invited;
  const isNewUser = !user?.user_id || isInvited;

  const validationSchema = Yup.object({
    email,
    role: requiredSelect,
    assignedDomains: Yup.array().test({
      test: function (value) {
        return !this.parent.specificDomains || !!value?.length;
      },
      message: translateText(
        'message',
        'Select at least one domain that the user will be granted access to.',
      ),
    }),
  });

  return (
    <Formik
      initialValues={{
        email: user?.email ?? '',
        role: user?.role?.id ? String(user.role.id) : 'select',
        inviteToNewDomains: user?.invite_to_new ?? 1,
        isAdmin: user?.is_admin ?? 0,
        specificDomains: user?.assigned_domains.length ? 1 : 0,
        assignedDomains: user?.assigned_domains ?? [],
      }}
      onSubmit={save}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({
        values,
        handleChange,
        setFieldValue,
        dirty,
        submitForm,
        isSubmitting,
        errors,
        submitCount,
        resetForm,
      }) => (
        <Setup
          id="invite-user"
          show={showUser !== false}
          close={close}
          afterClose={resetForm}
          save={submitForm}
          title={
            user.user_id
              ? translateText('label', 'User info')
              : translateText('label', 'Invite user')
          }
          saveButtonText={!user.user_id ? translateText('label', 'Invite') : undefined}
          isSaving={isSubmitting}
          maxWidth={isAdmin ? 500 : 700}
          hasChanges={dirty}
        >
          <ErrorFocus element={document.getElementById('invite-user')} />
          {!isNewUser && (
            <InputWrapper label={translateText('label', 'First and last name')}>
              <span>{user?.user}</span>
            </InputWrapper>
          )}
          <InputWrapper label={translateText('label', 'Email address')}>
            {!user.user_id ? (
              <TextField
                name="email"
                value={values.email}
                onChange={handleChange}
                placeholder={translateText('label', 'Email address')}
                error={submitCount > 0 && errors.email}
              />
            ) : (
              <span>{values.email}</span>
            )}
          </InputWrapper>
          {!isNewUser && (
            <>
              <InputWrapper label={translateText('label', 'Last login')}>
                {user?.last_login}
              </InputWrapper>
              <InputWrapper label={translateText('label', 'Status')}>{user?.status}</InputWrapper>
            </>
          )}
          <InputWrapper label={translateText('label', 'Rights')}>
            {isInvited ? (
              user.role?.description
            ) : (
              <Select
                name="role"
                disabled={isInvited}
                options={roleOptions}
                value={values.role}
                onChange={handleChange}
                error={submitCount > 0 && errors.role}
                addSelect
              />
            )}
          </InputWrapper>
          <InputWrapper
            label={translateText('label', 'Automatically grant access to new domains')}
            tooltip={translateText(
              'tooltip/mcc-users',
              'Enable this functionality if you want to automatically give this user access to new domains under this customer center.',
            )}
          >
            <Toggle
              disabled={!!values.isAdmin || isInvited}
              checked={!!values.inviteToNewDomains}
              onClick={() => setFieldValue('inviteToNewDomains', +!values.inviteToNewDomains)}
            />
          </InputWrapper>
          <InputWrapper
            label={translateText('label', 'Admin')}
            tooltip={translateText(
              'tooltip/mcc-users',
              'The admin can invite other domains and users to the customer center.',
            )}
          >
            <Toggle
              disabled={isInvited}
              checked={!!values.isAdmin}
              onClick={() => {
                if (!values.isAdmin) {
                  setFieldValue('inviteToNewDomains', 1);
                  setFieldValue('specificDomains', 0);
                }
                setFieldValue('isAdmin', +!values.isAdmin);
              }}
            />
          </InputWrapper>
          {!isAdmin && (
            <InputWrapper
              label={translateText('label', 'Grant access to specific domains')}
              tooltip={translateText(
                'tooltip/mcc-users',
                'Do you want this user to have access to specific domains of the customer center? Then choose this option and select the domains which the user is allowed to access.',
              )}
            >
              <Toggle
                disabled={!!values.isAdmin || isInvited}
                checked={!!values.specificDomains}
                onClick={() => setFieldValue('specificDomains', +!values.specificDomains)}
              />
            </InputWrapper>
          )}
          {!!values.specificDomains && (
            <SelectFromList
              id="select-domains"
              disabled={isInvited}
              items={selectedMccData?.domains ?? []}
              selected={values.assignedDomains}
              onChange={value => setFieldValue('assignedDomains', value)}
              availableLabel={translateText('label', 'Available domains')}
              selectedLabel={translateText('label', 'Assigned domains')}
              error={submitCount > 0 && (errors.assignedDomains as string)}
            />
          )}
          {!!user.user_id && (
            <button
              className="btn btn-text purple-text no-padding margin-top"
              disabled={isRevokingUser || isRevokingInvitation}
              onClick={() => revokeAccess()}
            >
              {translateText('label', 'Revoke access')}
            </button>
          )}
        </Setup>
      )}
    </Formik>
  );
}

const emptyUser: ResellerUser = {
  email: '',
  first_name: '',
  last_name: '',
  last_login: '',
  status: '',
  role: null,
  invite_to_new: 1,
  is_admin: 0,
  assigned_domains: [],
};
