import AutoGrid from 'components/AutoGrid';
import ReportsPage from 'components/ReportsPage';
import Widget from 'components/widget/Widget';
import { getDefaultFilters } from 'contexts/Filters';
import { TYPE_FORM_TRACKING } from 'globals/constants';
import { useOverviewWidgetContent } from 'hooks/content';
import { useIsFetchingReport } from 'hooks/fetching';
import { useTranslate } from 'hooks/translate';

export default function Overview() {
  const translateText = useTranslate();
  const { formTrackingWidgetKeys } = useOverviewWidgetContent({
    showPending: true,
    isSelectable: false,
  });

  const queryKey = ['formTracking', 'overview'];
  const isLoading = useIsFetchingReport(queryKey);

  const defaultFilters = getDefaultFilters(TYPE_FORM_TRACKING);

  const dataKeysCallRequestStatus = {
    call_requests_success: {
      name: translateText('label', 'Successful'),
      legendTooltip: translateText(
        'tooltip/form-tracking',
        "A call request gets the status 'Successful' when a call has been made between both parties.",
      ),
    },
    call_requests_missed: {
      name: translateText('label', 'Missed'),
      legendTooltip: translateText(
        'tooltip/form-tracking',
        "A call request is given the status 'Missed' when a successful call is not established because the maximum number of call attempts has been reached.",
      ),
    },
    call_requests_unreached: {
      name: translateText('label', 'Not reached'),
      legendTooltip: translateText(
        'tooltip/form-tracking',
        "A call request gets a status of 'Not reached' when a successful call was not established because the phone number was not valid or the call request was stopped.",
      ),
    },
  };

  return (
    <ReportsPage queryKey={queryKey} title={translateText('label', 'Form Tracking')}>
      <Widget
        widgetKey={[...queryKey, 'tiles']}
        endpoint="/widget/form-tracking-overview"
        filters={defaultFilters}
        type="statistics"
        dataKeys={formTrackingWidgetKeys}
        isLoading={isLoading}
      />
      <AutoGrid>
        <Widget
          widgetKey={[...queryKey, 'callRequestsByDate']}
          gridColumns={2}
          endpoint="/widget/call-requests-by-date"
          filters={defaultFilters}
          type="area"
          title={translateText('label', 'Call requests')}
          dataKeys={dataKeysCallRequestStatus}
          titleTooltip={translateText(
            'tooltip/form-tracking',
            'This chart shows an overview of the call requests and the corresponding status per period.',
          )}
          hideTotalIfPrevious
          isLoading={isLoading}
        />
        <Widget
          widgetKey={[...queryKey, 'times']}
          gridColumns={2}
          endpoint="/widget/call-request-times"
          filters={defaultFilters}
          title={translateText('label', 'Times')}
          type="bar"
          dataKeys={dataKeysCallRequestStatus}
          titleTooltip={translateText(
            'tooltip/form-tracking',
            'This bar chart shows the number of call requests per status per hour of the day.',
          )}
          isLoading={isLoading}
        />
        <Widget
          widgetKey={[...queryKey, 'days']}
          gridColumns={2}
          endpoint="/widget/call-request-days"
          filters={defaultFilters}
          title={translateText('label', 'Days')}
          type="bar"
          dataKeys={dataKeysCallRequestStatus}
          titleTooltip={translateText(
            'tooltip/form-tracking',
            'This bar chart shows the number of call requests stacked by status per day of the week.',
          )}
          isLoading={isLoading}
        />
        <Widget
          widgetKey={[...queryKey, 'calls']}
          type="pie"
          title={translateText('label', 'Calls')}
          endpoint="/widget/call-request-calls-by-status"
          filters={defaultFilters}
          emptyLabel={translateText('text', 'No successful call request has taken place.')}
          maxPieces={8}
          titleTooltip={translateText(
            'tooltip/form-tracking',
            'This diagram shows an overview of the number of call requests per status.',
          )}
          legendTooltips={{
            declined_internally: translateText(
              'tooltip/form-tracking',
              "A call request gets the status 'Internally rejected' when the call request has been rejected internally.",
            ),
            declined_visitor: translateText(
              'tooltip/form-tracking',
              "A call request gets the status 'Denied by lead' when the call request is declined by the lead.",
            ),
            missed_internally: translateText(
              'tooltip/form-tracking',
              "A call request gets the status 'Internally missed' when the call request has been missed internally.",
            ),
            missed_by_lead: translateText(
              'tooltip/form-tracking',
              "A call request gets the status 'Missed by lead' when the call request is missed by the lead.",
            ),
            success: translateText(
              'tooltip/form-tracking',
              "A call request gets the status 'Successful' when a call has been made between both parties.",
            ),
          }}
          isLoading={isLoading}
        />
        <Widget
          widgetKey={[...queryKey, 'successPercentage']}
          endpoint="/widget/call-request-success-percentage"
          filters={defaultFilters}
          title={translateText('label', 'Success percentage')}
          type="donut"
          emptyLabel={translateText('text', 'No successful call request has taken place.')}
          titleTooltip={translateText(
            'tooltip/form-tracking',
            'The percentage of call requests for which a successful call was established.',
          )}
          isLoading={isLoading}
        />
        <Widget
          widgetKey={[...queryKey, 'reactionTime']}
          endpoint="/widget/form-tracking-response-time"
          filters={defaultFilters}
          title={translateText('label', 'Reaction time')}
          type="pie"
          emptyLabel={translateText('text', 'No successful call request has taken place.')}
          maxPieces={8}
          titleTooltip={translateText(
            'tooltip/form-tracking',
            'The response time is the time between submitting the call request and establishing a successful call.',
          )}
          isLoading={isLoading}
        />
        <Widget
          widgetKey={[...queryKey, 'evaluations']}
          endpoint="/widget/call-request-evaluations"
          filters={defaultFilters}
          title={translateText('label', 'Evaluations')}
          type="pie"
          emptyLabel={translateText('text', 'There are no call request evaluations.')}
          maxPieces={8}
          titleTooltip={translateText(
            'tooltip/form-tracking',
            'This pie chart shows the number of calls per evaluation.',
          )}
          isLoading={isLoading}
        />
        <Widget
          widgetKey={[...queryKey, 'callDuration']}
          endpoint="/widget/form-tracking-call-duration"
          filters={defaultFilters}
          title={translateText('label', 'Call duration')}
          type="pie"
          emptyLabel={translateText('text', 'No successful call request has taken place.')}
          maxPieces={8}
          titleTooltip={translateText(
            'tooltip/form-tracking',
            'The call duration is the duration of the successful call that resulted from the call request.',
          )}
          isLoading={isLoading}
        />
      </AutoGrid>
    </ReportsPage>
  );
}
