import moment from 'moment';

import Skeleton from 'components/Skeleton';
import Tooltip from 'components/Tooltip';
import { PH_MASK } from 'globals/constants';
import { isReactNode } from 'globals/helpers';
import { useCalculate } from 'hooks/calculate';
import { useDateTime } from 'hooks/date-time';
import { useMedia } from 'hooks/media';
import { useTranslate } from 'hooks/translate';
import { useUserDateFormat, useUserLanguage } from 'hooks/user';
import { ColumnSetting } from './Table';

type Props<T> = {
  row: T;
  rowIndex: number;
  column: keyof T;
  settings: ColumnSetting<T>;
  onRowClick?: (row: T) => void;
  prevValue: unknown;
  isResponsive: boolean;
  isLoading: boolean;
};

export default function Cell<T extends Record<string, unknown>>({
  row,
  rowIndex,
  column,
  settings,
  onRowClick,
  prevValue,
  isResponsive,
  isLoading,
}: Props<T>) {
  const translateText = useTranslate();
  const dateFormat = useUserDateFormat();
  const language = useUserLanguage();
  const { formatSeconds } = useDateTime();
  const { getDifference } = useCalculate();
  const isSmall = useMedia(`(max-width: 1023px)`);

  let value: unknown = row[column];

  function getContent() {
    const numberValue = Number(typeof value === 'string' ? value.replace(',', '.') : value);
    if (isLoading) return <Skeleton className={settings.bigDot ? 'big-dot' : ''} />;
    if (settings.viewCondition && !settings.viewCondition(row)) {
      return null;
    } else if (value === null) {
      return settings?.emptyText !== undefined ? settings.emptyText : '-';
    } else if (settings.keyValues?.[String(value)]) {
      return settings.keyValues[String(value)];
    } else if (settings.compareType === 'seconds') {
      return formatSeconds(String(value));
    } else if (settings.bigDot) {
      const dot = (
        <div
          className={'big-dot ' + settings.bigDot.classes[String(value)]}
          data-testid="big-dot"
        />
      );
      let hoverText = '';
      if (settings.bigDot.hover && typeof settings.bigDot.hover === 'object') {
        hoverText = settings.bigDot.hover[String(value)];
      } else if (typeof settings.bigDot.hover !== undefined) {
        hoverText = row[String(settings.bigDot.hover)] as string;
      }
      return hoverText ? <Tooltip html={dot} text={hoverText} /> : dot;
    } else if (settings.button) {
      return (
        <button
          className={settings.button.className}
          onClick={e => {
            e.stopPropagation();
            settings.button?.onClick(row, rowIndex);
          }}
          disabled={settings.button.disabled}
        >
          {settings.button.text}
        </button>
      );
    } else if (settings.date) {
      const originalDate =
        settings.date === true || !settings.date.original
          ? 'YYYY-MM-DD HH:mm:ss'
          : settings.date.original;
      if (settings.date !== true && settings.date.separateColumns) {
        value = row.year + '-' + row.month + '-' + row.day;
      }
      const timeFormat =
        settings.date !== true && settings.date.includeSeconds ? 'HH:mm:ss' : 'HH:mm';
      let date = moment(String(value), originalDate).format(dateFormat);
      date = date !== 'Invalid date' ? date : '';
      let time =
        settings.date === true || (!settings.date.excludeTime && !settings.date.separateColumns)
          ? moment(String(value), originalDate).format(timeFormat)
          : '';
      time = time === 'Invalid date' ? '' : time;

      if (date && time) {
        let connector = ' ';
        if (
          settings.date === true ||
          settings.date.connector === 'at' ||
          !settings.date.connector
        ) {
          connector += translateText('label', 'At').toLowerCase() + ' ';
        } else if (settings.date.connector === 'till') {
          connector += translateText('label', 'Till').toLowerCase() + ' ';
        }
        return date + connector + time;
      }
      return date + time;
    } else if (settings.customValue) {
      return settings.customValue(value, row);
    } else if (typeof value === 'boolean' || settings.forceBoolean) {
      return value ? translateText('label', 'Yes') : translateText('label', 'No');
    } else if (column !== 'id' && !settings.noNumberFormat && !isNaN(numberValue)) {
      const formatted = numberValue.toLocaleString(language);
      if (settings.customNumber) return settings.customNumber(formatted);
      return formatted;
    } else if (isReactNode(value)) {
      return value;
    }
  }

  function getPrevious() {
    if (
      (typeof prevValue !== 'string' && typeof prevValue !== 'number') ||
      (typeof value !== 'string' && typeof value !== 'number') ||
      settings.compareType === 'none'
    ) {
      return null;
    }

    const numberValue = Number(
      typeof prevValue === 'string' ? prevValue.replace(',', '.') : prevValue,
    );
    let valueFormatted = prevValue;
    if (settings.compareType === 'seconds') {
      valueFormatted = formatSeconds(String(prevValue));
    } else if (!isNaN(numberValue)) {
      valueFormatted = numberValue.toLocaleString(language);
      if (settings.customNumber) valueFormatted = settings.customNumber(valueFormatted);
    }

    const difference = getDifference(value, prevValue, false, settings.compareType);
    return (
      <span className={'difference' + difference[1]}>
        <Tooltip
          html={<>{difference[0]}</>}
          text={translateText('label', 'Previous period') + ': ' + (valueFormatted || '-')}
          textAsHtml
          placement="top"
        />
      </span>
    );
  }

  const title =
    (typeof settings.header === 'string' ? settings.header : settings.header?.name) ?? column;

  let className = settings.className ?? '';
  if (settings.private) className += (className ? ' ' : '') + PH_MASK;

  const content = getContent();

  return (
    <td
      className={className}
      onClick={() => onRowClick && !isLoading && onRowClick(row)}
      title={settings.valueAsTitle && !isLoading && content ? String(content) : undefined}
    >
      {isResponsive && isSmall && title && (
        <div className="title">
          {isLoading ? <Skeleton width={String(title).length * 9} /> : String(title)}
        </div>
      )}
      {content}
      {!isLoading && getPrevious()}
    </td>
  );
}
