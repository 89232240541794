import iconDataStudio from 'assets/images/icons/integrations/looker-studio.svg';
import CostParagraph from 'components/integrations/CostParagraph';
import Setup from 'components/slide-in/Setup';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import SupportParagraph from 'components/SupportParagraph';
import { INTEGRATION_LOOKER_STUDIO } from 'globals/constants';
import { SUP_ART_LOOKER_STUDIO } from 'globals/support';
import { useHasAccessToIntegration } from 'hooks/access';
import { useTranslate } from 'hooks/translate';

export default function LookerStudio({ show, close }: BaseSlideInProps) {
  const translateText = useTranslate();
  const hasAccessToIntegration = useHasAccessToIntegration();

  if (!hasAccessToIntegration(INTEGRATION_LOOKER_STUDIO)) return null;

  return (
    <Setup
      show={show}
      close={close}
      icon={iconDataStudio}
      title={translateText('label', 'Looker Studio')}
      closeButtonText={translateText('label', 'Close')}
      maxWidth={400}
    >
      <p>
        {translateText(
          'integration',
          "Looker Studio is Google's free reporting tool. Using this 'connector' you can report the Call Tracking data from AdCalls in Looker Studio. All call data of the domains you have access to is available.",
        )}
      </p>
      <CostParagraph price={null} />
      <SupportParagraph articleId={SUP_ART_LOOKER_STUDIO} />
      <a
        target="_blank"
        href="https://datastudio.google.com/datasources/create?connectorId=AKfycbzuRvzh4BqgCMrkSyA5NgY4hcCM1jLT4C9rMMxtda94xetS1ZN59B1w0uU5cunv-LIk"
        className="btn btn-lightblue view-connector"
      >
        {translateText('label', 'View connector')}
      </a>
    </Setup>
  );
}
