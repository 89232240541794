import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import ErrorFocus from 'components/ErrorFocus';
import Checkbox from 'components/input/Checkbox';
import TextField from 'components/input/TextField';
import QueryTable from 'components/table/QueryTable';
import { useGlobal } from 'contexts/Global';
import { useSetNumbers } from 'hooks/queries/numberpool';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation } from 'hooks/validation';

export type NumberAssignPool = {
  assignable_numbers: string;
  conditions: string;
  fallback_assignments: string;
  highest_daily_assignments: string;
  needed_numbers_according_to_assigns: string;
  needed_numbers_according_to_percentage: string;
  netnumber: string;
  numberpool: string;
  numbers: string;
  sessions: string;
  total_assignments: string;
};

export default function NumberAssignStats() {
  const { setShowReportsFilter } = useGlobal();
  const { setNumbers } = useSetNumbers();
  const translateText = useTranslate();
  const { required } = useCustomValidation();

  const [sendDates, setSendDates] = useState(false);

  useEffect(() => {
    setShowReportsFilter('admin');
    return () => setShowReportsFilter(false);
  }, [setShowReportsFilter]);

  const validationSchema = Yup.object({
    assignable_numbers: required,
  });

  return (
    <div className="number-assign-stats">
      <h1>{translateText('label', 'Numbers')}</h1>
      <div className="white-block">
        <b>
          {translateText('label', 'Needed: {value}', {
            value: translateText('label', 'Assignments').toLocaleLowerCase(),
          })}
        </b>
        <p>
          {translateText(
            'admin/text',
            'The highest amount of assignments, divided by the amount of workable half hours in a day (20) multiplied by the safety margin (2) per half hour.',
          )}
        </p>
        <b>
          {translateText('label', 'Needed: {value}', {
            value: translateText('label', 'Percentage').toLocaleLowerCase(),
          })}
        </b>
        <p>
          {translateText(
            'admin/text',
            'Fallback assignments divided by total assignments times current amount of numbers (for upscaling only).',
          )}
        </p>
      </div>
      <QueryTable<NumberAssignPool & { input: undefined }>
        columns={{
          netnumber: {
            header: { name: translateText('label', 'Area code'), sort: true },
            search: true,
            noNumberFormat: true,
          },
          conditions: {
            header: { name: translateText('label', 'Conditions'), sort: true },
            search: true,
          },
          numbers: {
            header: { name: translateText('label', 'Numbers'), sort: true },
            search: true,
          },
          assignable_numbers: {
            header: { name: translateText('label', 'Assignable numbers'), sort: true },
            search: true,
          },
          sessions: {
            header: { name: translateText('label', 'Sessions'), sort: true },
            search: true,
          },
          total_assignments: {
            header: { name: translateText('label', 'Total assignments'), sort: true },
            search: true,
          },
          fallback_assignments: {
            header: { name: translateText('label', 'Fallback assignments'), sort: true },
            search: true,
          },
          needed_numbers_according_to_assigns: {
            header: {
              name: translateText('label', 'Needed numbers according to assigns'),
              sort: true,
            },
            search: true,
          },
          needed_numbers_according_to_percentage: {
            header: {
              name: translateText('label', 'Needed numbers according to percentage'),
              sort: true,
            },
            search: true,
          },
          input: {
            header: '',
            className: 'number-input',
            customValue: (value, row) => (
              <Formik
                initialValues={{ assignable_numbers: row.assignable_numbers }}
                validationSchema={validationSchema}
                onSubmit={values =>
                  setNumbers({ id: row.numberpool, amount: values.assignable_numbers })
                }
                enableReinitialize
              >
                {({
                  values,
                  setFieldValue,
                  submitForm,
                  dirty,
                  errors,
                  submitCount,
                  isSubmitting,
                }) => (
                  <>
                    <ErrorFocus />
                    <TextField
                      name="assignable_numbers"
                      value={values.assignable_numbers}
                      onChange={e =>
                        setFieldValue('assignable_numbers', e.target.value.replace(/[^0-9]/g, ''))
                      }
                      error={submitCount > 0 && errors.assignable_numbers}
                    />
                    <button
                      className="btn btn-green"
                      onClick={submitForm}
                      disabled={!dirty || isSubmitting}
                    >
                      {translateText('label', 'Save')}
                    </button>
                  </>
                )}
              </Formik>
            ),
          },
        }}
        tableKey={['admin', 'numberAssignStats']}
        endpoint="/domain/number-assign-stats"
        className="wrap"
        filters={{ date: sendDates }}
        extraTopLeft={
          <Checkbox
            id="send-date"
            onChange={() => setSendDates(!sendDates)}
            checked={sendDates}
            label={translateText('label', 'Send date to API')}
          />
        }
        pagination
        refresh
      />
    </div>
  );
}
