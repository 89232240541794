import { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import ChangePassword from 'components/account/ChangePassword';
import NotificationSettings from 'components/account/NotificationSettings';
import TwoStepAuthQrCode from 'components/account/TwoStepAuthQrCode';
import ErrorFocus from 'components/ErrorFocus';
import Select from 'components/input/Select';
import TextField from 'components/input/TextField';
import UserAvatar from 'components/navigation/UserAvatar';
import Skeleton from 'components/Skeleton';
import Setup from 'components/slide-in/Setup';
import Tooltip from 'components/Tooltip';
import { useMessages } from 'contexts/Messages';
import {
  useNotificationSettings,
  useUpdateDateFormat,
  useUpdateLanguage,
  useUpdateNotificationSettings,
  useUpdateProfile,
  useUser,
} from 'hooks/queries/user';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation } from 'hooks/validation';

type FormValues = {
  company: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  language: string;
  dateFormat: string;
  notificationSettings: { category: number; type: number }[];
};

export default function MyAccount() {
  const translateText = useTranslate();
  const { addSuccessMessage } = useMessages();
  const { email, required } = useCustomValidation();

  const { updateProfile } = useUpdateProfile();
  const { updateLanguage } = useUpdateLanguage();
  const { updateDateFormat } = useUpdateDateFormat();
  const { updateNotificationSettings } = useUpdateNotificationSettings();

  const { user, isLoading: isLoadingUser } = useUser();
  const { notificationSettings, isLoading: isLoadingNotifications } = useNotificationSettings();
  const isLoading = isLoadingUser || isLoadingNotifications;

  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showTwoStepAuth, setShowTwoStepAuth] = useState(false);

  async function save(values: FormValues) {
    let message = translateText('message', 'Changes saved');
    const notificationSettings: Record<number, number> = {};
    values.notificationSettings.forEach(setting => {
      notificationSettings[setting.category] = setting.type;
    });
    await Promise.all([
      updateLanguage({ language: values.language }),
      updateDateFormat(values.dateFormat),
      updateNotificationSettings(notificationSettings),
      updateProfile(values).then(response => {
        if (response.message) message = response.message;
      }),
    ]).then(() => addSuccessMessage(message));
  }

  function getLoadingRow(item: undefined, index: number) {
    return (
      <div key={index} className="form-row">
        <div className="name-label">
          <Skeleton width={150} />
        </div>
        <div className="value-wrapper">
          <Skeleton height={38} />
        </div>
      </div>
    );
  }

  const validationSchema = Yup.object({
    company: required,
    firstName: required,
    lastName: required,
    phoneNumber: required,
    email,
  });

  return (
    <div className="my-account">
      <h1>{translateText('label', 'My account')}</h1>
      <Formik<FormValues>
        initialValues={{
          company: user?.company ?? '',
          firstName: user?.first_name ?? '',
          lastName: user?.last_name ?? '',
          phoneNumber: user?.phonenumber ?? '',
          email: user?.email ?? '',
          language: user?.language.id ?? 'nl-NL',
          dateFormat: user?.date_format ?? 'DD-MM-YYYY',
          notificationSettings: notificationSettings ?? [],
        }}
        onSubmit={save}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values, handleChange, handleSubmit, dirty, errors, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <ErrorFocus />
            <div className="white-block">
              <h2>
                {isLoading ? <Skeleton width={200} /> : translateText('label', 'Information')}
              </h2>
              <div className="form-row">
                <div className="name-label" />
                <div className="value-wrapper">
                  <UserAvatar noNotificationCount isLoading={isLoading} />
                </div>
              </div>
              {isLoading ? (
                Array.from(Array(6)).map(getLoadingRow)
              ) : (
                <>
                  <div className="form-row">
                    <div className="name-label">{translateText('label', 'Company')}</div>
                    <div className="value-wrapper">
                      <TextField
                        name="company"
                        value={values.company}
                        onChange={handleChange}
                        error={errors.company}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="name-label">{translateText('label', 'First name')}</div>
                    <div className="value-wrapper">
                      <TextField
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        error={errors.firstName}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="name-label">{translateText('label', 'Last name')}</div>
                    <div className="value-wrapper">
                      <TextField
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        error={errors.lastName}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="name-label">{translateText('label', 'Phone number')}</div>
                    <div className="value-wrapper">
                      <TextField
                        name="phoneNumber"
                        value={values.phoneNumber}
                        onChange={handleChange}
                        error={errors.phoneNumber}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="name-label">{translateText('label', 'Email address')}</div>
                    <div className="value-wrapper">
                      <TextField
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        error={errors.email}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="name-label">{translateText('label', 'Password')}</div>
                    <div className="value-wrapper">
                      <button
                        type="button"
                        className="btn btn-text no-padding"
                        onClick={() => setShowChangePassword(true)}
                      >
                        {translateText('label', 'Change password')}
                      </button>
                    </div>
                  </div>
                </>
              )}
              <h2>
                {isLoading ? <Skeleton width={200} /> : translateText('label', 'Preferences')}
              </h2>
              {isLoading ? (
                Array.from(Array(3)).map(getLoadingRow)
              ) : (
                <>
                  <div className="form-row">
                    <div className="name-label">{translateText('label', 'Language')}</div>
                    <div className="value-wrapper">
                      <Select
                        name="language"
                        value={values.language}
                        onChange={handleChange}
                        options={{
                          'nl-NL': translateText('label', 'Dutch'),
                          'en-US': translateText('label', 'English'),
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="name-label">{translateText('label', 'Date notation')}</div>
                    <div className="value-wrapper">
                      <Select
                        name="dateFormat"
                        value={values.dateFormat}
                        onChange={handleChange}
                        options={{
                          'DD-MM-YYYY': translateText('label', 'dd-mm-yyyy'),
                          'YYYY-MM-DD': translateText('label', 'yyyy-mm-dd'),
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="name-label">
                      {translateText('label', 'Two-step verification')}
                      <Tooltip
                        text={translateText(
                          'text',
                          'By setting up two-step verification, you add extra security to your user account.',
                        )}
                      />
                    </div>
                    <div className="value-wrapper two-step">
                      <button
                        type="button"
                        className="btn btn-text no-padding"
                        onClick={() => setShowTwoStepAuth(true)}
                      >
                        {translateText('label', 'View')}
                      </button>
                    </div>
                  </div>
                </>
              )}
              <h2>
                {isLoading ? <Skeleton width={200} /> : translateText('label', 'Notifications')}
              </h2>
              <NotificationSettings isLoading={isLoading} />
              <div className="save-container">
                {isLoading ? (
                  <Skeleton className="btn" />
                ) : (
                  <button type="submit" disabled={!dirty || isSubmitting} className="btn btn-green">
                    {translateText('label', 'Save')}
                  </button>
                )}
              </div>
            </div>
          </form>
        )}
      </Formik>
      <ChangePassword show={showChangePassword} close={() => setShowChangePassword(false)} />

      <Setup
        show={showTwoStepAuth}
        close={() => setShowTwoStepAuth(false)}
        title={translateText('label', 'Two-step verification')}
        maxWidth={450}
      >
        <TwoStepAuthQrCode config={user?.twoStep} />
      </Setup>
    </div>
  );
}
