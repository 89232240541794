export default {
    "AdCalls' JavaScript should be implemented on every page of your website before the closing head tag. You can place the JavaScript using our Google Tag Manager integration, manually in Google Tag Manager or you can place the JavaScript in the head of the website yourself.": "Het JavaScript van AdCalls dient v\u00f3\u00f3r de afsluitende head-tag op iedere pagina van je website te worden ge\u00efmplementeerd. Je kunt het JavaScript plaatsen met behulp van onze Google Tag Manager-integratie, handmatig in Google Tag Manager of je kunt het JavaScript zelf plaatsen in de head van de website.",
    "Build your own format": "Bouw zelf een format op",
    "By activating a selection menu, your callers can indicate in advance who or what they are calling for.": "Door een keuzemenu te activeren, kunnen je bellers vooraf aangeven voor wie of wat hij of zij belt.",
    "By activating an evaluation menu, you can evaluate calls afterwards.": "Door een beoordelingsmenu te activeren, kun je oproepen na afloop beoordelen.",
    "By activating call notifications, you will receive a notification for all calls or for only the missed calls.": "Door oproepnotificaties te activeren, ontvang je voor alle oproepen of voor alleen de gemiste oproepen een notificatie.",
    "By setting up IP filters, you can exclude traffic.[manager] Do you wish to set or change IP filters? Then click on the gears.[\/manager]": "Door IP-filters in te stellen, kun je verkeer uitsluiten.[manager] Wens je IP-filters in te stellen of te wijzigen? Klik dan op de tandwielen.[\/manager]",
    "By using a call announcement, you can distinguish between 'normal calls' and 'Call Tracking calls'.": "Door gebruik te maken van een oproep-aankondiging, kun je 'normale oproepen' en 'oproepen via Call Tracking' van elkaar onderscheiden.",
    "Click on the elements to create the desired format.": "Klik op de elementen om het gewenste format samen te stellen.",
    "Copy the telephone numbers in the exact format of the website and place them per location in the input fields shown below. Is the phone number shown in different formats? Then add an extra format for each location.": "Kopieer de telefoonnummers in het exacte format van de website en plaats ze per locatie in de hieronder weergeven invoervelden. Wordt het telefoonnummer in verschillende formats getoond? Voeg dan per locatie een extra format toe.",
    "Create your own format using the elements below or choose a commonly used format.": "Stel zelf een format op via onderstaande elementen of kies een veel gebruikt format.",
    "Enabling call recording will record all incoming calls established via Call Tracking.": "Door call recording in te schakelen worden alle inkomende oproepen die tot stand worden gebracht via Call Tracking opgenomen.",
    "Implement the AdCalls JavaScript on the website and indicate per phone number whether it can be replaced with a dynamic phone number for Call Tracking using number spans.": "Implementeer het JavaScript van AdCalls op de website en geef per telefoonnummer aan of deze vervangen mag worden door een dynamisch telefoonnummer voor Call Tracking met behulp van nummer-spans.",
    "Indicate in which format the dynamic phone number for Call Tracking may be displayed on the website. We advise you to use the same format as the phone numbers without Call Tracking are communicated on the website.": "Geef aan in welk format het dynamische telefoonnummer voor Call Tracking getoond mag worden op de website. Wij adviseren hier hetzelfde format aan te houden zoals de telefoonnummers zonder Call Tracking worden gecommuniceerd op de website.",
    "Manual tagging allows you to better distinguish between different types of tracking.[manager] Would you like to set up or change manual tagging? Then click on the gears.[\/manager]": "Met handmatige tagging kun je beter onderscheid maken tussen verschillende typen verkeer.[manager] Wens je handmatige tagging in te stellen of te wijzigen? Klik dan op de tandwielen.[\/manager]",
    "No locations have been added yet.": "Er zijn nog geen locaties toegevoegd.",
    "Please note: the session stop can only be changed once a month.": "Let op: de sessie-stop kan slechts \u00e9\u00e9n keer per maand worden gewijzigd.",
    "Remove an added element by navigating to it with your cursor and clicking the purple minus sign.": "Verwijder een toegevoegd element door er met je cursor naartoe te navigeren en op het paarse min-teken te klikken.",
    "Select a commonly used format here": "Selecteer hier een veel gebruikt format",
    "Send a periodic report of your Call Tracking data. Depending on the period, the reports will be sent every first of the month or on Monday.": "Verstuur periodiek een rapportage van jouw Call Tracking-data. De rapportages zullen afhankelijk van de periode, elke eerste van de maand of op maandag verstuurd worden.",
    "Set up manual tagging.": "Stel handmatige tagging in.",
    "Set up the IP filters.": "Stel de IP-filters in.",
    "Set up the session stop.": "Stel de sessie-stop in.",
    "Specify the formats of the phone numbers on the website that can be replaced with a dynamic phone number for Call Tracking. Our JavaScript will replace exactly those formats specified with a dynamic phone number for Call Tracking.": "Geef de formats op van de telefoonnummers op de website die vervangen mogen worden door een dynamisch telefoonnummer voor Call Tracking. Ons JavaScript zal precies die formats die zijn opgegeven vervangen door een dynamisch telefoonnummer voor Call Tracking.",
    "The domain is currently in a trial period. During the trial period it is not possible to use offsite phone numbers.": "Het domein zit momenteel in een proefperiode. In de proefperiode is het niet mogelijk om offsite telefoonnummers in te zetten.",
    "We recommend using the advanced implementation for this domain, because more than 20 locations are tracked.": "Voor dit domein adviseren wij gebruik te maken van de geavanceerde implementatie, omdat er meer dan 20 locaties gemeten worden.",
    "With offsite phone numbers you can make media and locations outside of your website measurable.[manager] Click on the gears to manage the offsite phone numbers.[\/manager]": "Met offsite telefoonnummers kun je media en locaties buiten je website meetbaar maken.[manager] Klik op de tandwielen om de offsite telefoonnummers te beheren.[\/manager]",
    "Would you like to set or change a session stop? Then click on the gears.": "Wens je een sessie-stop in te stellen of te wijzigen? Klik dan op de tandwielen.",
    "You can adjust the order of elements by dragging the elements.": "Pas de volgorde van de elementen aan door de elementen te verslepen.",
    "You can choose from two different methods for the technical implementation of AdCalls. Choose one of the two methods below. Read the instructions in the following <a href=\"{link}\">support article<\/a> to determine which method works best for you.": "Voor de technische implementatie van AdCalls kun je kiezen uit twee verschillende methodes. Kies onderstaand een van de twee methodes. Lees de instructies in het volgende <a href=\"{link}\">support-artikel<\/a> om te bepalen welke methode het beste voorziet.",
    "You can choose from two different methods for the technical implementation of AdCalls. Read the instructions in the following <a href=\"{link}\">support article<\/a> to determine which method works best.": "Voor de technische implementatie van AdCalls kun je kiezen uit twee verschillende methodes. Lees de instructies in het volgende <a href=\"{link}\">support-artikel<\/a> om te bepalen welke methode het beste voorziet.",
    "You can only change the session stop once a month. For questions, contact us via support@adcalls.nl.": "Je kunt de sessie-stop slechts eenmaal per maand wijzigen. Neem voor vragen contact met ons op via support@adcalls.nl.",
    "You cannot use offsite phone numbers within the trial period. You can choose to wait for the trial period to end or convert to a paid pricing plan.": "Binnen de proefperiode kun je geen gebruik maken van offsite telefoonnummers. Je kunt ervoor kiezen de proefperiode af te wachten of om te zetten in een betaald prijsplan.",
    "You will be notified by a beep that the incoming call originates from a phone number for Call Tracking.": "Middels een pieptoon word je ervan op de hoogte gebracht dat de inkomende oproep voortkomt uit een telefoonnummer voor Call Tracking."
}