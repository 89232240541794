import AutoGrid from 'components/AutoGrid';
import ReportsPage from 'components/ReportsPage';
import Widget from 'components/widget/Widget';
import { getDefaultFilters } from 'contexts/Filters';
import { TYPE_CALL_TRACKING } from 'globals/constants';
import { useIsFetchingReport } from 'hooks/fetching';
import { useTranslate } from 'hooks/translate';

export default function DaysAndTimes() {
  const translateText = useTranslate();

  const queryKey = ['callTracking', 'daysAndTimes'];
  const isLoading = useIsFetchingReport(queryKey);

  const defaultFilters = getDefaultFilters(TYPE_CALL_TRACKING);
  return (
    <ReportsPage
      queryKey={queryKey}
      title={translateText('label', 'Days and times')}
      download={{
        endpoint: '/reports/days-and-times',
        disabled: isLoading,
      }}
    >
      <Widget
        widgetKey={[...queryKey, 'insights']}
        className="best-days-and-times"
        endpoint="/widget/days-and-times-insights"
        type="statistics"
        dataKeys={{
          most_received: {
            name: translateText('label', 'Best time'),
            tooltip: translateText(
              'tooltip/widgets',
              'Here you can see the day and time frame (hour) of the week with the most calls received.',
            ),
            noTooltipPrevious: true,
          },
          most_missed: {
            name: translateText('label', 'Missed calls'),
            tooltip: translateText(
              'tooltip/widgets',
              'Here you can see the day and time frame (hour) of the week with the most missed calls.',
            ),
            noTooltipPrevious: true,
          },
        }}
        filters={defaultFilters}
        isLoading={isLoading}
      />
      <AutoGrid stretch>
        <Widget
          widgetKey={[...queryKey, 'days']}
          gridColumns={2}
          type="bar"
          title={translateText('label', 'Days')}
          titleTooltip={translateText(
            'tooltip/widgets',
            'This bar graph shows the number of received and missed calls per day of the week in a stack.',
          )}
          endpoint="/widget/days"
          dataKeys={{
            notmissed: { name: translateText('label', 'Calls') },
            missed: { name: translateText('label', 'Missed calls') },
          }}
          filters={defaultFilters}
          isLoading={isLoading}
        />
        <Widget
          widgetKey={[...queryKey, 'times']}
          gridColumns={2}
          type="bar"
          title={translateText('label', 'Times')}
          titleTooltip={translateText(
            'tooltip/widgets',
            'This bar graph shows the number of received and missed calls per hour of the day in a stack.',
          )}
          endpoint="/widget/times"
          dataKeys={{
            notmissed: { name: translateText('label', 'Calls') },
            missed: { name: translateText('label', 'Missed calls') },
          }}
          filters={defaultFilters}
          isLoading={isLoading}
        />
      </AutoGrid>
    </ReportsPage>
  );
}
