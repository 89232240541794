import { useEffect } from 'react';
import { Formik } from 'formik';

import ConfigurationPage from 'components/configuration/ConfigurationPage';
import Browser from 'components/configuration/debug-mode/Browser';
import FoundFormats from 'components/configuration/debug-mode/FoundFormats';
import MessageHandler from 'components/configuration/debug-mode/MessageHandler';
import { useDebugMode } from 'contexts/DebugMode';
import { usePrevious } from 'hooks/effect';
import { useAcceptedUrls } from 'hooks/queries/domain';
import { useTranslate } from 'hooks/translate';

export type DebugFormValues = { host: string; page: string };

export default function DebugMode() {
  const translateText = useTranslate();
  const { acceptedUrls } = useAcceptedUrls();
  const { initialize, addToBrowserHistory } = useDebugMode();

  const firstUrl = acceptedUrls?.[0];
  const previousFirstUrl = usePrevious(firstUrl);
  useEffect(() => {
    if (firstUrl && previousFirstUrl !== firstUrl) {
      initialize({ host: firstUrl, shouldClearHistory: true });
    }
  }, [firstUrl, initialize, previousFirstUrl]);

  function submitForm(values: DebugFormValues) {
    addToBrowserHistory(values.page);
    initialize(values);
  }

  return (
    <ConfigurationPage title={translateText('label', 'Debug mode')}>
      <Formik<DebugFormValues>
        initialValues={{ host: firstUrl ?? '', page: '/' }}
        enableReinitialize
        onSubmit={submitForm}
      >
        <div className="debug-mode">
          <MessageHandler />
          <Browser />
          <FoundFormats />
        </div>
      </Formik>
    </ConfigurationPage>
  );
}
