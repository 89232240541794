import gif from 'assets/images/guided-tour/01-main-nav.gif';
import { useTranslate } from 'hooks/translate';
import BaseTour from './BaseTour';

export default function MainNavigationTour() {
  const translateText = useTranslate();
  return (
    <BaseTour
      title={translateText('guided-tour', 'Main navigation')}
      image={gif}
      description={
        <>
          <p
            dangerouslySetInnerHTML={{
              __html: translateText(
                'guided-tour',
                'Select a domain via the overview of via the domain selector domain, so that the <b>main navigation</b> becomes visible. In the subnavigation you will then find the reports, configuration and integrations.',
              ),
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: translateText(
                'guided-tour',
                'At the bottom of the <b>main navigation</b> you can navigate to the domain overview, the applications, the overarching domain settings and to our knowledge center. In our knowledge center you will find inspiration and all our support articles.',
              ),
            }}
          />
        </>
      }
    />
  );
}
