import { useQuery } from '@tanstack/react-query';

import { useFetch } from 'hooks/fetch';

export function useVoip(id: string) {
  const { fetchData } = useFetch();

  const { data, isFetching, refetch } = useQuery({
    queryKey: ['voip', id],
    queryFn: (): Promise<Record<string, string | number>[]> =>
      fetchData('/log/voip', { queryParams: { id } }),
    enabled: false,
  });

  return { voipLogs: data, isLoading: isFetching, refetch };
}
