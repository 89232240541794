import {
  createContext,
  Dispatch,
  MutableRefObject,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';

import { ServiceType } from 'globals/types';
import { MessagesProvider, useMessages } from './Messages';
import { RoutesProvider } from './Routes';
import { SessionProvider } from './Session';
import { TenantProvider } from './Tenant';

type Global = {
  selectedLanguage: string | null;
  setSelectedLanguage: Dispatch<SetStateAction<string | null>>;
  forceReload: boolean;
  setForceReload: Dispatch<SetStateAction<boolean>>;
  redirectTo: { pathname: string; search: string } | false;
  setRedirectTo: Dispatch<SetStateAction<{ pathname: string; search: string } | false>>;
  showReportsFilter: false | ServiceType | 'admin';
  setShowReportsFilter: Dispatch<SetStateAction<false | ServiceType | 'admin'>>;
  scrollContainer: MutableRefObject<HTMLDivElement | null>;
  scrollTo: (options: ScrollToOptions) => void;
  scrollToTop: () => void;
};

const GlobalContext = createContext<Global | null>(null);

function GlobalProvider({ children }: PropsWithChildren<Record<string, unknown>>) {
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(
    localStorage.getItem('language'),
  );
  const [forceReload, setForceReload] = useState(false);
  const [redirectTo, setRedirectTo] = useState<{ pathname: string; search: string } | false>(false);
  const [showReportsFilter, setShowReportsFilter] = useState<false | ServiceType | 'admin'>(false);

  useEffect(() => {
    if (selectedLanguage && localStorage.getItem('language') !== selectedLanguage) {
      localStorage.setItem('language', selectedLanguage);
    } else if (!selectedLanguage) {
      localStorage.removeItem('language');
    }
  }, [selectedLanguage]);

  const scrollContainer = useRef<HTMLDivElement | null>(null);
  const scrollTo = useCallback(
    (options: ScrollToOptions) => scrollContainer.current?.scrollTo(options),
    [],
  );
  const scrollToTop = useCallback(() => scrollTo({ top: 0, left: 0 }), [scrollTo]);

  return (
    <GlobalContext.Provider
      value={{
        selectedLanguage,
        setSelectedLanguage,
        forceReload,
        setForceReload,
        redirectTo,
        setRedirectTo,
        showReportsFilter,
        setShowReportsFilter,
        scrollContainer,
        scrollTo,
        scrollToTop,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

export function useGlobal() {
  const global = useContext(GlobalContext);
  if (global === null) {
    throw new Error('Global context not available');
  }
  return global;
}

export function AppProviders({
  children,
  testToken,
}: PropsWithChildren<{ testToken?: string | null }>) {
  return (
    <MessagesProvider>
      <QueryProvider>
        <BrowserRouter>
          <GlobalProvider>
            <SessionProvider testToken={testToken}>
              <TenantProvider>
                <RoutesProvider>{children}</RoutesProvider>
              </TenantProvider>
            </SessionProvider>
          </GlobalProvider>
        </BrowserRouter>
        {__DEV__ && <ReactQueryDevtools buttonPosition="bottom-right" />}
      </QueryProvider>
    </MessagesProvider>
  );
}

function QueryProvider({ children }: PropsWithChildren<Record<string, unknown>>) {
  const { addErrorMessage } = useMessages();
  const [queryClient] = useState(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onError: (error: unknown, query) => {
            if (query.meta?.noError !== true) addErrorMessage(error);
          },
        }),
        mutationCache: new MutationCache({
          onError: (error: unknown, variables, context, mutation) => {
            if (mutation.meta?.noError !== true) addErrorMessage(error);
          },
        }),
        defaultOptions: {
          queries: {
            gcTime: 1000 * 60 * 60 * 24, // 24 hours
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
          },
        },
      }),
  );

  if (__TEST__) {
    return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
  }

  return (
    <PersistQueryClientProvider
      persistOptions={{
        persister: createSyncStoragePersister({ storage: window.localStorage }),
        dehydrateOptions: {
          shouldDehydrateQuery: query =>
            query.state.status === 'success' && query.meta?.persist === true,
        },
      }}
      client={queryClient}
    >
      {children}
    </PersistQueryClientProvider>
  );
}
