import { Link } from 'react-router-dom';
import moment from 'moment';

import RestrictedAccess from 'components/RestrictedAccess';
import Skeleton from 'components/Skeleton';
import Tooltip from 'components/Tooltip';
import {
  INTEGRATION_ACTIVE,
  INTEGRATION_ERROR,
  INTEGRATION_INACTIVE,
  ROLE_ADMIN,
} from 'globals/constants';
import { useHasManagerAccess } from 'hooks/access';
import { useTranslate } from 'hooks/translate';

type Props = {
  title: string;
  icon: string;
  link: string;
  status: typeof INTEGRATION_ACTIVE | typeof INTEGRATION_INACTIVE | typeof INTEGRATION_ERROR | null;
  hasPricingPlanAccess?: boolean;
  dateAdded?: string;
  adminOnly?: boolean;
  isLoading?: boolean;
};

export default function IntegrationCard({
  title,
  icon,
  link,
  status,
  hasPricingPlanAccess = false,
  dateAdded,
  adminOnly = false,
  isLoading = false,
}: Props) {
  const translateText = useTranslate();
  const statusData = getStatusData();
  const hasManagerAccess = useHasManagerAccess();

  function getStatusData() {
    switch (status) {
      case INTEGRATION_INACTIVE:
        return {
          value: translateText('label', 'Disabled'),
          class: 'inactive',
        };
      case INTEGRATION_ACTIVE:
        return {
          value: translateText('label', 'Enabled'),
          class: 'active',
        };
      case INTEGRATION_ERROR:
        return {
          value: translateText('label', 'Errors encountered'),
          class: 'warning',
        };
      default:
        return false;
    }
  }

  let className = 'integration-card white-block';
  let href;
  let statusDot = null;
  if (!isLoading && hasPricingPlanAccess) {
    if (hasManagerAccess) {
      className += ' selectable';
      href = link;
    }

    if (statusData) {
      statusDot = (
        <Tooltip html={<div className={`big-dot ${statusData.class}`} />} text={statusData.value} />
      );
    }
  }

  const isNew = dateAdded && moment().diff(moment(dateAdded), 'months') < 6;

  let card = (
    <div className={className}>
      {statusDot}
      {!isLoading && isNew && (
        <div className="ribbon green" data-text={translateText('label', 'New')} />
      )}
      <div className="icon-wrapper">
        {isLoading ? (
          <Skeleton className="icon" />
        ) : (
          <img className="icon" src={icon} alt={title} />
        )}
      </div>
      <span className="title bold">{isLoading ? <Skeleton count={2} /> : title}</span>
    </div>
  );

  if (href) {
    card = <Link to={href}>{card}</Link>;
  }

  if (adminOnly) {
    return (
      <RestrictedAccess role={ROLE_ADMIN} show="ribbon">
        {card}
      </RestrictedAccess>
    );
  }
  return card;
}
