import { useState } from 'react';
import { Formik } from 'formik';
import moment from 'moment';

import accept from 'assets/images/icons/accept.svg';
import pencil from 'assets/images/icons/pencil-dark.svg';
import DatePicker from 'components/input/DatePicker';
import TimePicker from 'components/input/TimePicker';
import Setup from 'components/slide-in/Setup';
import QueryTable from 'components/table/QueryTable';
import Tooltip from 'components/Tooltip';
import {
  CHANGE_REQUEST_STATUS_CANCELLED,
  CHANGE_REQUEST_STATUS_ON_HOLD,
  CHANGE_REQUEST_STATUS_OPEN,
  CHANGE_REQUEST_STATUS_PENDING,
  CHANGE_REQUEST_STATUS_PROCESSED,
  CHANGE_REQUEST_STATUS_REFUSED,
  CHANGE_REQUEST_STATUS_VERIFIED,
} from 'globals/constants';
import { ChangeRequestDeactivate } from 'globals/types';
import { useHasAdminAccess } from 'hooks/access';
import { useScheduleDeletion } from 'hooks/queries/domain';
import {
  AdminChangeRequest,
  AdminChangeRequestDetails,
  useChangeRequestEvaluation,
  useUpdateChangeRequest,
} from 'hooks/queries/domain-change-request';
import { useTranslate } from 'hooks/translate';
import { useUserDateFormat } from 'hooks/user';

export default function StopRequests() {
  const translateText = useTranslate();
  const isAdmin = useHasAdminAccess();
  const userDateFormat = useUserDateFormat();
  const { confirmRequest } = useChangeRequestEvaluation();
  const { updateRequest } = useUpdateChangeRequest();
  const { scheduleDeletion } = useScheduleDeletion();
  const [edit, setEdit] = useState<AdminChangeRequest | null>(null);
  const [schedule, setSchedule] = useState<AdminChangeRequest | null>(null);

  async function update(values: { date: string }) {
    await updateRequest({ id: edit!.id, changes: values.date! }).then(() => setEdit(null));
  }

  async function scheduleRemoval(values: { date: string; hour: string; minute: string }) {
    const date = moment(values.date, 'YYYY-MM-DD')
      .hour(Number(values.hour))
      .minute(Number(values.minute));
    await scheduleDeletion({ id: schedule!.domain_id, date }).then(() => setSchedule(null));
  }

  return (
    <>
      <Formik
        initialValues={{
          date: (edit?.wanted_changes as string) ?? moment().format('YYYY-MM-DD'),
        }}
        onSubmit={update}
        enableReinitialize
      >
        {({ values, submitForm, resetForm, isSubmitting, dirty, setFieldValue }) => (
          <Setup
            title={translateText('label', 'Update stop request date')}
            show={edit !== null}
            save={submitForm}
            close={() => setEdit(null)}
            afterClose={resetForm}
            isSaving={isSubmitting}
            hasChanges={dirty}
          >
            <DatePicker value={values.date} onChange={date => setFieldValue('date', date)} />
          </Setup>
        )}
      </Formik>
      <Formik
        initialValues={{ date: moment().format('YYYY-MM-DD'), hour: '00', minute: '00' }}
        onSubmit={scheduleRemoval}
      >
        {({ values, submitForm, resetForm, isSubmitting, dirty, handleChange, setFieldValue }) => (
          <Setup
            title={translateText('label', 'Schedule removal')}
            show={schedule !== null}
            save={submitForm}
            close={() => setSchedule(null)}
            afterClose={resetForm}
            isSaving={isSubmitting}
            hasChanges={dirty}
          >
            <DatePicker value={values.date} onChange={date => setFieldValue('date', date)} />
            <TimePicker hour={values.hour} minute={values.minute} onChange={handleChange} />
          </Setup>
        )}
      </Formik>
      <h1>{translateText('label', 'Domain stop requests')}</h1>
      <QueryTable<
        AdminChangeRequestDetails &
          ChangeRequestDeactivate & { edit: undefined; approve: undefined }
      >
        tableKey={['changeRequests', 'admin', 'stopRequests']}
        columns={{
          domain_id: {
            header: { name: translateText('label', 'ID'), sort: true },
            noNumberFormat: true,
            search: true,
          },
          domain_title: {
            header: { name: translateText('label', 'Title'), sort: true },
            search: true,
          },
          reseller: {
            header: { name: translateText('label', 'Partner'), sort: true },
            search: true,
          },
          is_active: {
            header: { name: translateText('label', 'Active'), sort: true },
            search: {
              0: translateText('label', 'No'),
              1: translateText('label', 'Yes'),
            },
          },
          requesting_user: {
            header: { name: translateText('label', 'Requesting user'), sort: true },
            search: true,
          },
          wanted_changes: {
            header: { name: translateText('label', 'Requested for'), sort: true },
            search: true,
            customValue: value => moment(value, 'YYYY-MM-DD').format(userDateFormat),
          },
          ...(isAdmin && {
            edit: {
              header: '',
              customValue: (_, row) => (
                <Tooltip
                  text={translateText('label', 'Edit')}
                  html={
                    <img
                      className="clickable-icon"
                      src={pencil}
                      alt="edit"
                      onClick={() => setEdit(row)}
                    />
                  }
                />
              ),
              viewCondition: row =>
                row.status !== translateText('label', 'Processed') &&
                (row.domain_is_trial ||
                  (!row.domain_contract_duration && !row.domain_contract_end_date)),
            },
          }),
          status: {
            header: { name: translateText('label', 'Status'), sort: true },
            search: {
              [CHANGE_REQUEST_STATUS_OPEN]: translateText('label', 'Open'),
              [CHANGE_REQUEST_STATUS_PENDING]: translateText('label', 'Pending'),
              [CHANGE_REQUEST_STATUS_ON_HOLD]: translateText('label', 'On hold'),
              [CHANGE_REQUEST_STATUS_PROCESSED]: translateText('label', 'Processed'),
              [CHANGE_REQUEST_STATUS_VERIFIED]: translateText('label', 'Verified'),
              [CHANGE_REQUEST_STATUS_REFUSED]: translateText('label', 'Refused'),
              [CHANGE_REQUEST_STATUS_CANCELLED]: translateText('label', 'Cancelled'),
            },
          },
          created_at: {
            header: { name: translateText('label', 'Requested at'), sort: true },
            search: true,
            date: true,
          },
          updated_at: {
            header: { name: translateText('label', 'Last change date'), sort: true },
            search: true,
            date: true,
          },
          execution_date: {
            header: translateText('label', 'Removal date'),
            customValue: (value, row) => {
              if (
                isAdmin &&
                !row.is_active &&
                row.execution_date === false &&
                row.wanted_changes < moment().subtract(1, 'month').format('YYYY-MM-DD')
              ) {
                return (
                  <button
                    className="btn btn-text btn-small no-padding"
                    onClick={() => setSchedule(row)}
                  >
                    {translateText('label', 'Schedule')}
                  </button>
                );
              }
              return value === false
                ? '-'
                : moment(value, 'YYYY-MM-DD HH:mm:ss').format(userDateFormat);
            },
          },
          ...(isAdmin && {
            approve: {
              header: '',
              customValue: (_, row) => (
                <Tooltip
                  text={translateText('label', 'Approve')}
                  html={
                    <img
                      className="clickable-icon"
                      src={accept}
                      alt="approve"
                      onClick={() => confirmRequest(row.id, 'approve')}
                    />
                  }
                />
              ),
              viewCondition: row => row.status === translateText('label', 'Open'),
            },
          }),
        }}
        endpoint="/domain-change-request/index"
        filters={{
          custom: {
            type: 'deactivate',
            sort: 'id',
            expand:
              'reseller,is_active,execution_date,domain_contract_duration,domain_contract_end_date,domain_is_trial',
          },
          domain: false,
        }}
        pagination
        refresh
      />
    </>
  );
}
