import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { zipObject } from 'lodash';
import moment from 'moment';

import AutoGrid from 'components/AutoGrid';
import DetailsSection from 'components/DetailsSection';
import Select from 'components/input/Select';
import PricingPlanInfo from 'components/intake/PricingPlanInfo';
import Skeleton from 'components/Skeleton';
import Tooltip from 'components/Tooltip';
import { useTenant } from 'contexts/Tenant';
import { CONTRACT_DURATIONS, PH_MASK } from 'globals/constants';
import { pricingPlanCategoryHasChanged } from 'globals/helpers';
import { useDomainAuth, useEditSubscriptionPrerequisiteData } from 'hooks/queries/domain';
import { usePricingPlans } from 'hooks/queries/pricing';
import { useTranslate } from 'hooks/translate';
import { useUserDateFormat } from 'hooks/user';
import {
  EditSubscriptionFormValues,
  EditSubscriptionProps,
  EditSubscriptionSection,
} from './EditSubscription';
import InvoicingDetails from './InvoicingDetails';
import PricingIndication from './PricingIndication';

export default function PropertiesSection({ type }: EditSubscriptionProps) {
  const translateText = useTranslate();
  const userDateFormat = useUserDateFormat();
  const { selectedDomainData } = useTenant();
  const { domainAuth, isLoading: isLoadingDomainAuth } = useDomainAuth();
  const { pricingPlans, isLoading: isLoadingPricingPlans } = usePricingPlans();
  const { values, handleChange, setFieldValue, errors } =
    useFormikContext<EditSubscriptionFormValues>();

  const { data, isLoading: isLoadingData } = useEditSubscriptionPrerequisiteData(type);

  const isValidated = typeof values.validated[EditSubscriptionSection.Properties] === 'boolean';
  const isLoading = isLoadingData || isLoadingPricingPlans || isLoadingDomainAuth;

  const durations = CONTRACT_DURATIONS[values.pricingPlanId] ?? [];
  const durationOptions = zipObject(
    durations,
    durations.map(m => translateText('datetime', '{months} months', { months: m })),
  );

  // In most cases there should only be one option for the start date: the first of next month
  // or the first of the month after the end of the contract. Only when switching to a higher
  // pricing plan there could be more start dates available. So only if the selected pricing
  // plan is not the same as the current pricing plan (always a higher plan since switching to
  // a lower plan is impossible), we use the options from the API.
  const contractEndDate = data?.contract_end_date ?? undefined; // Needs to be undefined instead of null to get the current date from moment
  let startDates = [moment(contractEndDate).startOf('month').add(1, 'month').format('YYYY-MM-DD')];
  if (
    data?.available_start_dates &&
    values.pricingPlanId !== 'select' &&
    values.pricingPlanId !== domainAuth?.pricing_plan
  ) {
    startDates = data.available_start_dates;
  }
  const startDateOptions = zipObject(
    startDates,
    startDates.map(d => moment(d).format(userDateFormat)),
  );

  // If there is only 1 start date possible, automatically set the start date to this date
  const onlyDate = startDates.length === 1 ? startDates[0] : null;
  useEffect(() => {
    if (onlyDate && values.startDate === 'select' && values.pricingPlanId !== 'select') {
      setFieldValue('startDate', onlyDate);
    }
  }, [setFieldValue, onlyDate, values.startDate, values.pricingPlanId]);

  return (
    <AutoGrid className="properties-section" size="large">
      <DetailsSection title={translateText('label', 'Properties')} isLoading={isLoading}>
        <div className="form-row">
          <div className="name-label">
            <span>
              {isLoading ? <Skeleton width={150} /> : translateText('label', 'Domain URL')}
            </span>
            {!isLoading && (
              <Tooltip
                text={translateText(
                  'tooltip/domain',
                  'This is the website for which the services of AdCalls are used.',
                )}
              />
            )}
          </div>
          <div className={`value-wrapper ${PH_MASK}`}>
            {isLoading ? <Skeleton width={150} /> : selectedDomainData?.url}
          </div>
        </div>
        <div className="form-row">
          <div className={'name-label' + (!isLoading ? ' required' : '')}>
            <span>
              {isLoading ? <Skeleton width={150} /> : translateText('label', 'Pricing plan')}
            </span>
            {!isLoading && (
              <Tooltip text={translateText('tooltip/intake', 'Select the desired pricing plan.')} />
            )}
          </div>
          <div className="value-wrapper">
            {isLoading ? (
              <Skeleton height={38} />
            ) : (
              <Select
                name="pricingPlanId"
                value={values.pricingPlanId}
                onChange={e => {
                  if (
                    pricingPlanCategoryHasChanged(
                      values.pricingPlanId !== 'select' ? values.pricingPlanId : null,
                      e.target.value,
                    )
                  ) {
                    setFieldValue('contractDuration', 'select');
                  }
                  if (
                    values.pricingPlanId !== 'select' &&
                    values.pricingPlanId !== e.target.value
                  ) {
                    setFieldValue('startDate', 'select');
                  }
                  setFieldValue('pricingPlanId', e.target.value);
                }}
                options={
                  data?.available_pricing_plans && pricingPlans
                    ? pricingPlans.filter(p => data.available_pricing_plans?.includes(p.id))
                    : pricingPlans ?? {}
                }
                optionValue="id"
                optionText="invoice_label"
                error={isValidated && errors.pricingPlanId}
                addSelect
                selectText={translateText('label', 'Select a pricing plan')}
              />
            )}
          </div>
        </div>
        <div className="form-row">
          <div className={'name-label' + (!isLoading ? ' required' : '')}>
            <span>
              {isLoading ? <Skeleton width={150} /> : translateText('label', 'Contract duration')}
            </span>
            {!isLoading && (
              <Tooltip
                text={translateText('tooltip/intake', 'Select the desired contract duration.')}
              />
            )}
          </div>
          <div className="value-wrapper">
            {isLoading ? (
              <Skeleton height={38} />
            ) : (
              <Select
                name="contractDuration"
                value={values.contractDuration}
                onChange={handleChange}
                options={durationOptions}
                error={isValidated && values.pricingPlanId !== 'select' && errors.contractDuration}
                addSelect
                selectText={translateText('label', 'Select a contract duration')}
                disabled={values.pricingPlanId === 'select'}
              />
            )}
          </div>
        </div>
        <div className="form-row">
          <div className={'name-label' + (!isLoading ? ' required' : '')}>
            <span>
              {isLoading ? <Skeleton width={150} /> : translateText('label', 'Start date')}
            </span>
            {!isLoading && (
              <Tooltip
                text={translateText('tooltip/intake', 'Indicate when the new contract may start.')}
              />
            )}
          </div>
          <div className="value-wrapper">
            {isLoading ? (
              <Skeleton height={38} />
            ) : (
              <Select
                name="startDate"
                value={values.startDate}
                onChange={handleChange}
                options={startDateOptions}
                error={isValidated && values.pricingPlanId !== 'select' && errors.startDate}
                addSelect
                selectText={translateText('label', 'Select a start date')}
                disabled={values.pricingPlanId === 'select'}
              />
            )}
          </div>
        </div>
        <div className="form-row info-button">
          {isLoading ? (
            <Skeleton width={150} containerClass="no-full-width" />
          ) : (
            <PricingPlanInfo />
          )}
        </div>
      </DetailsSection>
      <PricingIndication type={type} isLoading={isLoading} />
      <InvoicingDetails type={type} isLoading={isLoading} />
    </AutoGrid>
  );
}
