import { camelCase } from 'lodash';

import AutoGrid from 'components/AutoGrid';
import ReportsPage from 'components/ReportsPage';
import QueryTable from 'components/table/QueryTable';
import Widget from 'components/widget/Widget';
import { getDefaultFilters, useFilters } from 'contexts/Filters';
import { TYPE_CALL_TRACKING } from 'globals/constants';
import { useIsFetchingReport } from 'hooks/fetching';
import { useTranslate } from 'hooks/translate';

export default function Locations() {
  const translateText = useTranslate();
  const { getAppliedFilters } = useFilters();
  const defaultFilters = getDefaultFilters(TYPE_CALL_TRACKING);

  const queryKey = ['callTracking', 'locations'];
  const isLoading = useIsFetchingReport(queryKey);

  const aenOverviewFilters = getAppliedFilters(
    defaultFilters,
    camelCase([...queryKey, 'aenOverview'].join(' ')),
    true,
  );
  const aenSourcesFilters = getAppliedFilters(
    defaultFilters,
    camelCase([...queryKey, 'aenSources'].join(' ')),
    true,
  );
  return (
    <ReportsPage
      queryKey={queryKey}
      title={translateText('label', 'Top 5: Number of contact moments')}
      download={{
        endpoint: '/reports/locations',
        disabled: isLoading,
        filters: {
          ...defaultFilters,
          custom: {
            overviewPage: aenOverviewFilters.queryParams.page,
            overviewLimit: aenOverviewFilters.queryParams.limit,
            sourcesPage: aenSourcesFilters.queryParams.page,
            sourcesLimit: aenSourcesFilters.queryParams.limit,
          },
        },
      }}
    >
      <AutoGrid stretch>
        <Widget
          widgetKey={[...queryKey, 'aenCalls']}
          endpoint="/widget/aen-calls"
          type="area"
          filters={defaultFilters}
          hideTotalIfPrevious
          isLoading={isLoading}
        />
      </AutoGrid>
      <h1>{translateText('label', 'Locations overview')}</h1>
      <QueryTable
        tableKey={[...queryKey, 'aenOverview']}
        previousPeriodColumn="name"
        columns={{
          name: { header: translateText('label', 'Location'), compareType: 'none' },
          calls: { header: translateText('label', 'Amount of calls') },
          unique_callers: { header: translateText('label', 'New callers') },
          avg_duration: {
            header: translateText('label', 'Avg. call duration'),
            compareType: 'time',
          },
          avg_leadrating: {
            header: translateText('label', 'Avg. leadrating'),
            customValue: value => (
              <div dangerouslySetInnerHTML={{ __html: typeof value === 'string' ? value : '' }} />
            ),
            compareType: 'star',
            className: 'no-wrap',
          },
          lead: { header: translateText('label', 'Lead') },
          avg_revenue: {
            header: translateText('label', 'Avg. revenue'),
            customNumber: value => '€' + value,
          },
          tot_revenue: {
            header: translateText('label', 'Total revenue'),
            customNumber: value => '€' + value,
          },
          sale: { header: translateText('label', 'Sale') },
          overig: { header: translateText('label', 'Other') },
        }}
        endpoint="/widget/aen-overview"
        isResponsive
        dataKey="data"
        filters={defaultFilters}
        pagination
        isLoading={isLoading}
      />
      <h1>{translateText('label', 'Top 5: Best performing mediums')}</h1>
      <QueryTable
        tableKey={[...queryKey, 'aenSources']}
        previousPeriodColumn="id"
        columns={{ calculateHeaders: {} }}
        endpoint="/widget/aen-sources"
        isResponsive
        dataKey="data"
        filters={defaultFilters}
        pagination
        isLoading={isLoading}
      />
    </ReportsPage>
  );
}
