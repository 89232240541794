import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';

export enum TourSection {
  Start = 'start',
  MainNavigation = 'main-navigation',
  KnowledgeCenter = 'knowledge-center',
  NotificationCenter = 'notification-center',
  Reports = 'reports',
  DomainOverview = 'domain-overview',
  Completed = 'completed',
}
export const TOUR_STEPS = [
  TourSection.MainNavigation,
  TourSection.KnowledgeCenter,
  TourSection.NotificationCenter,
  TourSection.DomainOverview,
  TourSection.Reports,
] as const;

type GuidedTour = {
  section: TourSection;
  setSection: Dispatch<SetStateAction<TourSection>>;
  showCancel: boolean;
  setShowCancel: Dispatch<SetStateAction<boolean>>;
};

const GuidedTourContext = createContext<GuidedTour | null>(null);

export function GuidedTourProvider({ children }: PropsWithChildren<Record<string, unknown>>) {
  const [section, setSection] = useState<TourSection>(TourSection.Start);
  const [showCancel, setShowCancel] = useState(false);

  return (
    <GuidedTourContext.Provider
      value={{
        section,
        setSection,
        showCancel,
        setShowCancel,
      }}
    >
      {children}
    </GuidedTourContext.Provider>
  );
}

export function useGuidedTour() {
  const guidedTour = useContext(GuidedTourContext);
  if (guidedTour === null) throw new Error('Guided tour context not available');
  return guidedTour;
}
