import { ChangeEventHandler } from 'react';

import ErrorTooltip from 'components/ErrorTooltip';
import Tooltip from 'components/Tooltip';

type Props = {
  id: string;
  checked: boolean;
  name?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  error?: boolean | string;
  disabled?: boolean;
  label?: string;
  labelClassName?: string;
  tooltip?: string;
};

export default function Radio({
  labelClassName,
  error,
  checked,
  onChange,
  name,
  id,
  disabled,
  label,
  tooltip,
}: Props) {
  let labelClass = 'radio-label';
  if (labelClassName) labelClass += ' ' + labelClassName;

  let inputClass = 'radio';
  if (error) inputClass += ' error';

  return (
    <div className="custom-input" data-testid="radio-wrapper">
      <ErrorTooltip
        error={error}
        html={
          <>
            <input
              className={inputClass}
              type="radio"
              checked={checked}
              disabled={disabled}
              onChange={onChange}
              name={name}
              id={id}
              data-testid="input-radio"
            />
            <label htmlFor={id}>
              <span className="radio-inner" />
              <span className={labelClass}>{label}</span>
              {tooltip && <Tooltip text={tooltip} />}
            </label>
          </>
        }
      />
    </div>
  );
}
