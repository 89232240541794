import { PropsWithChildren } from 'react';

import { ROLE_ADMIN } from 'globals/constants';
import { Role } from 'globals/types';
import { useHasRoleAccess } from 'hooks/access';

type Props = {
  role: Role;
  right?: true;
  show?: false | 'ribbon' | 'outline';
};

export default function RestrictedAccess({
  children,
  role,
  right,
  show = false,
}: PropsWithChildren<Props>) {
  const hasRoleAccess = useHasRoleAccess();

  if (!hasRoleAccess(role)) return null;

  if (show && role === ROLE_ADMIN) {
    return (
      <div className={'restricted' + (show === 'outline' ? ' outline' : '')}>
        <div
          className={'ribbon purple no-bordered-child' + (right ? ' right' : '')}
          data-text="Admin"
        />
        {children}
      </div>
    );
  }
  return <>{children}</>;
}
