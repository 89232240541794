import { useNavigate } from 'react-router';

import deleteIcon from 'assets/images/icons/delete-purple.svg';
import SettingsPage from 'components/settings/SettingsPage';
import QueryTable from 'components/table/QueryTable';
import Tooltip from 'components/Tooltip';
import { ChangeRequest } from 'globals/types';
import { useCancelChangeRequest } from 'hooks/queries/domain-change-request';
import { useTranslate } from 'hooks/translate';

export default function ChangeRequests() {
  const navigate = useNavigate();
  const translateText = useTranslate();
  const { cancelRequest } = useCancelChangeRequest();

  return (
    <SettingsPage title={translateText('label', 'Changes')}>
      <QueryTable<ChangeRequest & { cancel: undefined }>
        tableKey={['changeRequests']}
        columns={{
          created_at: {
            header: { name: translateText('label', 'Date & time'), sort: true },
            date: true,
          },
          domain_title: {
            header: { name: translateText('label', 'Domain'), sort: true },
            private: true,
          },
          requesting_user: {
            header: { name: translateText('label', 'Requesting user'), sort: true },
            private: true,
          },
          type: { header: translateText('label', 'Change') },
          status: { header: { name: translateText('label', 'Status'), sort: true } },
          updated_at: {
            header: { name: translateText('label', 'Last change date'), sort: true },
            date: true,
          },
          cancel: {
            header: '',
            viewCondition: (row: ChangeRequest) => row.status === 'Open',
            customValue: (value: undefined, row: ChangeRequest) => (
              <Tooltip
                text={translateText('label', 'Cancel request')}
                html={
                  <img
                    alt={translateText('label', 'Cancel request')}
                    src={deleteIcon}
                    className="clickable-icon"
                    onClick={e => {
                      e.stopPropagation();
                      cancelRequest(row.id);
                    }}
                  />
                }
              />
            ),
          },
        }}
        endpoint="/domain-change-request/index"
        canSearch
        onRowClick={row => navigate('/settings/change-requests/' + row.id)}
        pagination
        filters={{ custom: { expand: 'type_key', no_upgrade_requests_by_us: '1' } }}
      />
    </SettingsPage>
  );
}
