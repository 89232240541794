import { ButtonHTMLAttributes, PropsWithChildren } from 'react';

type Props = {
  className?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;
export default function UnstyledButton({
  className,
  children,
  ...props
}: PropsWithChildren<Props>) {
  let buttonClass = 'btn-unstyled';
  if (props.disabled) buttonClass += ' disabled';
  if (className) buttonClass += ' ' + className;

  return (
    <button className={buttonClass} {...props}>
      {children}
    </button>
  );
}
