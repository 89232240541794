import { Navigate, Route, Routes } from 'react-router';

import AuthenticatedRoute from 'components/routes/AuthenticatedRoute';
import { PH_MASK, ROLE_ADMIN } from 'globals/constants';
import ChangeRequestDetails from 'pages/authenticated/admin/ChangeRequestDetails';
import ChangeRequests from 'pages/authenticated/admin/ChangeRequests';
import LoginContent from 'pages/authenticated/admin/content/LoginPage';
import NotificationDetails from 'pages/authenticated/admin/content/NotificationDetails';
import NotificationNew from 'pages/authenticated/admin/content/NotificationNew';
import Notifications from 'pages/authenticated/admin/content/Notifications';
import CampaignBuilder from 'pages/authenticated/admin/domains/CampaignBuilder';
import DeletedDomainLog from 'pages/authenticated/admin/domains/DeletedLog';
import DomainDetails from 'pages/authenticated/admin/domains/DomainDetails';
import DomainSettings from 'pages/authenticated/admin/domains/DomainSettings';
import Domains from 'pages/authenticated/admin/domains/Overview';
import DomainStopRequests from 'pages/authenticated/admin/domains/StopRequests';
import SyncScript from 'pages/authenticated/admin/domains/SyncScript';
import UpgradeStatus from 'pages/authenticated/admin/domains/UpgradeStatus';
import EmailBlacklist from 'pages/authenticated/admin/EmailBlacklist';
import SessionFilter from 'pages/authenticated/admin/functionalities/SessionFilter';
import IntakeDetails from 'pages/authenticated/admin/IntakeDetails';
import Intakes from 'pages/authenticated/admin/Intakes';
import Invoicing from 'pages/authenticated/admin/Invoicing';
import CallHistory from 'pages/authenticated/admin/logs/CallHistory';
import CallHistoryDetails from 'pages/authenticated/admin/logs/CallHistoryDetails';
import Mail from 'pages/authenticated/admin/logs/Mail';
import Voip from 'pages/authenticated/admin/logs/Voip';
import NumberDistribution from 'pages/authenticated/admin/NumberDistribution';
import AvailablePhoneNumbers from 'pages/authenticated/admin/phone-numbers/AvailablePhoneNumbers';
import Blacklist from 'pages/authenticated/admin/phone-numbers/Blacklist';
import BlacklistDetails from 'pages/authenticated/admin/phone-numbers/BlacklistDetails';
import BlacklistNew from 'pages/authenticated/admin/phone-numbers/BlacklistNew';
import Bulk from 'pages/authenticated/admin/phone-numbers/Bulk';
import Check from 'pages/authenticated/admin/phone-numbers/Check';
import Details from 'pages/authenticated/admin/phone-numbers/Details';
import New from 'pages/authenticated/admin/phone-numbers/New';
import NumberAssignOverview from 'pages/authenticated/admin/phone-numbers/NumberAssignOverview';
import NumberAssignStats from 'pages/authenticated/admin/phone-numbers/NumberAssignStats';
import Overview from 'pages/authenticated/admin/phone-numbers/Overview';
import StopLogs from 'pages/authenticated/admin/phone-numbers/StopLogs';
import StopRequests from 'pages/authenticated/admin/phone-numbers/StopRequests';
import ReleaseNotes from 'pages/authenticated/admin/ReleaseNotes';
import ResellerDetails from 'pages/authenticated/admin/ResellerDetails';
import ResellerNew from 'pages/authenticated/admin/ResellerNew';
import Resellers from 'pages/authenticated/admin/Resellers';
import UserDetails from 'pages/authenticated/admin/UserDetails';
import Users from 'pages/authenticated/admin/Users';
import Error404 from 'pages/Error404';

export default function Admin() {
  return (
    <div className={`admin-wrapper ${PH_MASK}`}>
      <Routes>
        <Route path="/" element={<Navigate to="/admin/domains/overview" />} />
        {/* Employee routes */}
        <Route path="/domains/overview" element={<Domains />} />
        <Route path="/domains/:id" element={<DomainDetails />} />
        <Route path="/domains/upgrade-status" element={<UpgradeStatus />} />
        <Route path="/domains/sync-script" element={<SyncScript />} />
        <Route path="/users" element={<Users />} />
        <Route path="/users/:id" element={<UserDetails />} />
        <Route path="/domains/stop-requests" element={<DomainStopRequests />} />
        <Route path="/change-requests" element={<ChangeRequests />} />
        <Route path="/change-requests/:id" element={<ChangeRequestDetails />} />
        <Route path="/resellers" element={<Resellers />} />
        <Route path="/resellers/new" element={<ResellerNew />} />
        <Route path="/resellers/:id" element={<ResellerDetails />} />
        <Route path="/intakes" element={<Intakes />} />
        <Route path="/intakes/:id" element={<IntakeDetails />} />
        <Route path="/invoicing" element={<Invoicing />} />
        <Route path="/release-notes" element={<ReleaseNotes />} />
        <Route path="/domains/deleted-log" element={<DeletedDomainLog />} />
        <Route
          path="/domains/domain-settings"
          element={
            <AuthenticatedRoute checkDomain>
              <DomainSettings />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/domains/campaign-builder"
          element={
            <AuthenticatedRoute checkDomain>
              <CampaignBuilder />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/functionalities/session-filter"
          element={
            <AuthenticatedRoute checkDomain>
              <SessionFilter />
            </AuthenticatedRoute>
          }
        />
        {/* Admin routes */}
        <Route
          path="/phone-numbers/blacklist"
          element={
            <AuthenticatedRoute role={ROLE_ADMIN}>
              <Blacklist />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/phone-numbers/blacklist/new"
          element={
            <AuthenticatedRoute role={ROLE_ADMIN}>
              <BlacklistNew />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/phone-numbers/blacklist/:id"
          element={
            <AuthenticatedRoute role={ROLE_ADMIN}>
              <BlacklistDetails />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/phone-numbers/overview"
          element={
            <AuthenticatedRoute role={ROLE_ADMIN}>
              <Overview />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/phone-numbers/overview/:number"
          element={
            <AuthenticatedRoute role={ROLE_ADMIN}>
              <Details />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/phone-numbers/new"
          element={
            <AuthenticatedRoute role={ROLE_ADMIN}>
              <New />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/phone-numbers/check"
          element={
            <AuthenticatedRoute role={ROLE_ADMIN}>
              <Check />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/phone-numbers/stop-requests"
          element={
            <AuthenticatedRoute role={ROLE_ADMIN}>
              <StopRequests />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/phone-numbers/stop-logs"
          element={
            <AuthenticatedRoute role={ROLE_ADMIN}>
              <StopLogs />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/phone-numbers/available-phone-numbers"
          element={
            <AuthenticatedRoute role={ROLE_ADMIN}>
              <AvailablePhoneNumbers />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/phone-numbers/number-assign-stats"
          element={
            <AuthenticatedRoute role={ROLE_ADMIN} checkDomain>
              <NumberAssignStats />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/phone-numbers/number-assign-overview"
          element={
            <AuthenticatedRoute role={ROLE_ADMIN}>
              <NumberAssignOverview />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/phone-numbers/bulk"
          element={
            <AuthenticatedRoute role={ROLE_ADMIN}>
              <Bulk />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/content/login-page"
          element={
            <AuthenticatedRoute role={ROLE_ADMIN}>
              <LoginContent />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/email-blacklist"
          element={
            <AuthenticatedRoute role={ROLE_ADMIN}>
              <EmailBlacklist />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/logs/voip"
          element={
            <AuthenticatedRoute role={ROLE_ADMIN}>
              <Voip />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/logs/mail"
          element={
            <AuthenticatedRoute role={ROLE_ADMIN}>
              <Mail />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/number-distribution"
          element={
            <AuthenticatedRoute role={ROLE_ADMIN}>
              <NumberDistribution />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/content/notifications"
          element={
            <AuthenticatedRoute role={ROLE_ADMIN}>
              <Notifications />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/content/notifications/new"
          element={
            <AuthenticatedRoute role={ROLE_ADMIN}>
              <NotificationNew />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/content/notifications/:id"
          element={
            <AuthenticatedRoute role={ROLE_ADMIN}>
              <NotificationDetails />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/logs/call-history"
          element={
            <AuthenticatedRoute role={ROLE_ADMIN}>
              <CallHistory />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/logs/call-history/:id"
          element={
            <AuthenticatedRoute role={ROLE_ADMIN}>
              <CallHistoryDetails />
            </AuthenticatedRoute>
          }
        />
        <Route path="/*" element={<Error404 />} />
      </Routes>
    </div>
  );
}
