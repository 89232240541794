import { useRef, useState } from 'react';

import ErrorTooltip from 'components/ErrorTooltip';
import { useStateCallback } from 'hooks/effect';
import { useOnClickOutside } from 'hooks/event';
import { useTranslate } from 'hooks/translate';
import TextField from './TextField';

type Props = {
  options: Record<string, string>;
  value: unknown;
  onChange: (value: string) => void;
  showSearchFrom?: number;
  disabled?: boolean;
  bold?: boolean;
  wide?: boolean;
  className?: string;
  error?: boolean | string;
};

export default function SearchableSelect({
  options,
  value,
  onChange,
  showSearchFrom,
  disabled,
  bold,
  wide,
  className,
  error,
}: Props) {
  const translateText = useTranslate();
  const dropdownRef = useRef(null);
  const searchRef = useRef<HTMLInputElement | null>(null);

  const [isOpen, setIsOpen] = useStateCallback(false);
  const [search, setSearch] = useState('');

  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  let wrapperClass = 'searchable-select';
  if (wide) wrapperClass += ' wide';
  if (className) wrapperClass += ' ' + className;
  if (disabled) wrapperClass += ' disabled';
  if (error) wrapperClass += ' error';

  let selectedClass = 'selected';
  if (bold) selectedClass += ' bold';

  const displayValue =
    typeof value === 'string' || typeof value === 'number' ? options[value] : null;

  return (
    <div className={wrapperClass} ref={dropdownRef}>
      <ErrorTooltip
        error={error}
        html={
          <div
            className={selectedClass}
            onClick={() => {
              setIsOpen(
                open => !open,
                () => {
                  if (searchRef.current) searchRef.current.focus();
                },
              );
            }}
          >
            <div className="option">{displayValue ?? translateText('label', 'Select')}</div>
            <div className="arrow arrow-down" />
          </div>
        }
      />

      {isOpen && (
        <div className="options-wrapper">
          {(!showSearchFrom || Object.keys(options).length >= showSearchFrom) && (
            <TextField
              inputRef={searchRef}
              placeholder={translateText('label', 'Search') + '...'}
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
          )}
          <div className="options">
            {Object.keys(options)
              .filter(o => !search || options[o].toLowerCase().includes(search.toLowerCase()))
              .map(o => (
                <div key={o} onClick={() => setIsOpen(false, () => onChange(o))}>
                  {options[o]}
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
