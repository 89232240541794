import icon from 'assets/images/icons/integrations/google-double-click.svg';
import BaseGoogle360, { TYPE_DOUBLE_CLICK } from 'components/integrations/BaseGoogle360';
import { REDIRECT_URI_GOOGLE_DOUBLE_CLICK } from 'components/oauth2/Verification';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import { INTEGRATION_DOUBLE_CLICK } from 'globals/constants';
import { SUP_ART_DOUBLE_CLICK } from 'globals/support';
import { useHasAccessToIntegration } from 'hooks/access';
import { useTranslate } from 'hooks/translate';

export default function DoubleClick({ show, close }: BaseSlideInProps) {
  const translateText = useTranslate();
  const hasAccessToIntegration = useHasAccessToIntegration();

  if (!hasAccessToIntegration(INTEGRATION_DOUBLE_CLICK)) return null;

  return (
    <BaseGoogle360
      show={show}
      close={close}
      icon={icon}
      infoText={translateText(
        'integration',
        "Campaign Manager is DoubleClick's advertising platform for serving image and text ads across multiple channels. Using this AdCalls integration, you can report call conversions as Floodlight Activity in DoubleClick.",
      )}
      articleId={SUP_ART_DOUBLE_CLICK}
      type={TYPE_DOUBLE_CLICK}
      title={translateText('label', 'DoubleClick Campaign Manager')}
      redirectUriKey={REDIRECT_URI_GOOGLE_DOUBLE_CLICK}
      integrationKey={INTEGRATION_DOUBLE_CLICK}
    />
  );
}
