import { useState } from 'react';
import { useIsFetching, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';

import refresh from 'assets/images/icons/refresh.svg';
import { useEffectOnce, useUpdateEffect } from 'hooks/effect';
import { useTranslate } from 'hooks/translate';

type Props = {
  queryKey: string[];
};

export default function RefreshButton({ queryKey }: Props) {
  const translateText = useTranslate();
  const queryClient = useQueryClient();
  const queriesLoading = useIsFetching({ queryKey });

  const [lastUpdated, setLastUpdated] = useState(moment());
  const [fiveSecondsPassed, setFiveSecondsPassed] = useState(false);
  let timerId: NodeJS.Timeout | null = null;

  useEffectOnce(() => {
    startTimer();
    return () => stopTimer();
  });

  useUpdateEffect(() => {
    if (queriesLoading === 0) {
      stopTimer();
      const queries = queryClient.getQueryCache().findAll({ queryKey });
      const queriesUpdatedAt = queries.map(q => q.state.dataUpdatedAt);
      setLastUpdated(moment(Math.max(...queriesUpdatedAt)));
    }
  }, [queriesLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  function startTimer() {
    timerId = setTimeout(() => setFiveSecondsPassed(true), 5000);
  }

  function stopTimer() {
    if (timerId !== null) clearTimeout(timerId);
  }

  function onRefresh() {
    setFiveSecondsPassed(false);
    startTimer();
    queryClient.invalidateQueries({ queryKey });
  }

  return (
    <button
      className="btn btn-darkblue btn-refresh"
      disabled={fiveSecondsPassed ? false : queriesLoading > 0}
      onClick={onRefresh}
    >
      {translateText('label', 'Last updated: {date}', {
        date: lastUpdated.format('HH:mm:ss'),
      })}
      <img alt="Refresh" src={refresh} />
    </button>
  );
}
