import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useMessages } from 'contexts/Messages';
import { useTenant } from 'contexts/Tenant';
import {
  TABLE_KEY_CALL_INDEX,
  TABLE_KEY_CALL_REQUEST_INDEX,
  TYPE_CALL_TRACKING,
  TYPE_FORM_TRACKING,
} from 'globals/constants';
import { Call, ServiceType } from 'globals/types';
import { useSetQueryData } from 'hooks/data';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';

export function useEvaluationOptions({
  enabled = true,
  domain = null,
}: { enabled?: boolean; domain?: number | null } = {}) {
  const { selectedDomain } = useTenant();
  const { fetchData } = useFetch();

  const currentDomain = domain ?? selectedDomain;

  const { data, isFetching } = useQuery({
    queryKey: ['evaluation', 'options', currentDomain],
    queryFn: (): Promise<{ options: string[]; ga_event_value_lead: boolean }> =>
      fetchData('/evaluation/possible-options', {
        queryParams: { domain_id: currentDomain, dashboard: true },
      }),
    enabled: currentDomain !== null && enabled,
  });

  return {
    evaluationOptions: data?.options,
    isLoading: isFetching,
  };
}

export function useUpdateEvaluation(type: ServiceType = TYPE_CALL_TRACKING) {
  const queryClient = useQueryClient();
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage, addErrorMessage } = useMessages();

  function invalidateEvaluationQueries(itemId: number) {
    queryClient.invalidateQueries({
      queryKey: [
        'evaluation',
        'log',
        { [type === TYPE_CALL_TRACKING ? 'call_id' : 'call_request_id']: itemId },
      ],
    });
    queryClient.invalidateQueries({
      queryKey: type === TYPE_CALL_TRACKING ? TABLE_KEY_CALL_INDEX : TABLE_KEY_CALL_REQUEST_INDEX,
    });
    queryClient.invalidateQueries({
      queryKey: [
        type === TYPE_CALL_TRACKING ? 'callTracking' : 'formTracking',
        'history',
        'evaluations',
      ],
    });
  }

  const { mutateAsync: updateEvaluation } = useMutation({
    mutationFn: ({
      itemId,
      evaluation,
      value,
      analyticsPush,
    }: {
      itemId: number;
      evaluation?: string;
      value?: string;
      analyticsPush?: boolean;
    }) =>
      fetchData(
        (type === TYPE_CALL_TRACKING ? '/evaluation' : '/call-request-evaluation') + '/change',
        {
          method: 'PUT',
          bodyParams: {
            [type === TYPE_CALL_TRACKING ? 'call_id' : 'call_request_id']: itemId,
            evaluation,
            value,
            ...(analyticsPush && { analytics_push: true }),
          },
        },
      ),
    onSuccess: (data, variables) => {
      addSuccessMessage(translateText('message', 'Changes saved'));
      invalidateEvaluationQueries(variables.itemId);
      if (variables.analyticsPush) {
        setQueryData<Call>(['call', variables.itemId], oldData => ({
          ...oldData,
          can_push_extra_ga4_event: data.can_push_extra_ga4_event,
        }));
      }
    },
    onError: (error: { ga4_message?: string }) => {
      if (error?.ga4_message) addErrorMessage(error.ga4_message);
    },
  });

  const { mutateAsync: deleteEvaluation } = useMutation({
    mutationFn: (itemId: number) =>
      fetchData(
        (type === TYPE_CALL_TRACKING ? '/evaluation' : '/call-request-evaluation') + '/delete',
        {
          method: 'DELETE',
          queryParams: { [type === TYPE_CALL_TRACKING ? 'call_id' : 'call_request_id']: itemId },
        },
      ),
    onSuccess: (data, itemId) => {
      addSuccessMessage(translateText('message', 'The evaluation has been successfully deleted.'));
      invalidateEvaluationQueries(itemId);
    },
  });

  return { updateEvaluation, deleteEvaluation };
}

export function useMultiUpdateEvaluations(serviceType: ServiceType = TYPE_CALL_TRACKING) {
  const queryClient = useQueryClient();
  const { fetchData } = useFetch();
  const { addSuccessMessage, addErrorMessage } = useMessages();
  const translateText = useTranslate();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: ({
      isDelete,
      ids,
      evaluation,
      value,
    }: {
      isDelete: boolean;
      ids: number[];
      evaluation?: string;
      value?: string;
    }) =>
      fetchData(
        `/${serviceType === TYPE_FORM_TRACKING ? 'call-request-' : ''}evaluation/${isDelete ? 'multi-delete' : 'change-multi'}`,
        {
          method: isDelete ? 'POST' : 'PUT',
          bodyParams: { ids, ...(!isDelete ? { evaluation, value } : {}) },
        },
      ),
    onSuccess: (data, variables) => {
      addSuccessMessage(
        variables.isDelete
          ? translateText('message', 'The evaluation(s) are successfully deleted.')
          : translateText('message', 'Changes saved'),
      );
      queryClient.invalidateQueries({
        queryKey:
          serviceType === TYPE_CALL_TRACKING ? TABLE_KEY_CALL_INDEX : TABLE_KEY_CALL_REQUEST_INDEX,
      });
      queryClient.invalidateQueries({
        queryKey: [
          serviceType === TYPE_CALL_TRACKING ? 'callTracking' : 'formTracking',
          'history',
          'evaluations',
        ],
      });
    },
  });

  async function multiUpdateEvaluations(
    type: 'update' | 'delete',
    ids: number[],
    evaluation?: string,
    value?: string,
  ) {
    if (ids.length === 0) {
      addErrorMessage(
        serviceType === TYPE_CALL_TRACKING
          ? translateText('message', 'No calls have been selected.')
          : translateText('message', 'No call requests have been selected.'),
      );
      return;
    }
    await mutateAsync({
      isDelete: type === 'delete',
      ids,
      evaluation,
      value,
    });
  }

  return { multiUpdateEvaluations, isUpdating: isPending };
}

export function useEvaluationImport() {
  const queryClient = useQueryClient();
  const { selectedDomain } = useTenant();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (formData: FormData) =>
      fetchData('/evaluation/import', {
        method: 'POST',
        queryParams: { domain_id: selectedDomain },
        bodyParams: formData,
      }),
    onSuccess: data => {
      addSuccessMessage(data);
      queryClient.invalidateQueries({ queryKey: TABLE_KEY_CALL_INDEX });
      queryClient.invalidateQueries({ queryKey: ['callTracking', 'history', 'evaluations'] });
    },
  });

  async function importEvaluations(file: File | undefined) {
    if (!file) return;
    const formData = new FormData();
    formData.append('file', file);
    await mutateAsync(formData);
  }

  return { importEvaluations, isLoading: isPending };
}
