import { Formik } from 'formik';
import * as Yup from 'yup';

import ErrorFocus from 'components/ErrorFocus';
import TextField from 'components/input/TextField';
import PopupModal from 'components/PopupModal';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import { useRequestResetPassword } from 'hooks/queries/auth';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation } from 'hooks/validation';

export default function ForgotPassword({ show, close }: BaseSlideInProps) {
  const translateText = useTranslate();
  const { email } = useCustomValidation();
  const { requestResetPassword, status, reset } = useRequestResetPassword();

  return (
    <PopupModal
      id="forgot-password"
      show={show}
      close={() => {
        reset();
        close();
      }}
    >
      <div className="forgot-password">
        <h2 className="bold">{translateText('label', 'Forgot password?')}</h2>
        {status === 'success' ? (
          <p>
            {translateText(
              'text',
              'If your e-mail address is known to us, you have received an e-mail. Set a new password via the link in the email. The link is valid for 24 hours.',
            )}
          </p>
        ) : (
          <>
            <p>
              {translateText(
                'text',
                'No problem! Enter your email address and receive a link by e-mail with which you can set a new password.',
              )}
            </p>
            <Formik
              initialValues={{ email: '' }}
              validationSchema={Yup.object({ email })}
              onSubmit={values => requestResetPassword(values.email)}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <ErrorFocus element={document.getElementById('forgot-password')} />
                  <TextField
                    id="email"
                    name="email"
                    type="email"
                    placeholder={translateText('label', 'Email address')}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="email"
                    autoFocus
                    error={touched.email && errors.email}
                  />
                  <button type="submit" className="btn btn-green" disabled={isSubmitting}>
                    {translateText('label', 'Send')}
                  </button>
                </form>
              )}
            </Formik>
          </>
        )}
      </div>
    </PopupModal>
  );
}
