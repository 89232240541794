import { isEmpty, mapValues, merge } from 'lodash';
import moment from 'moment';

import { useGlobal } from 'contexts/Global';
import { useSession } from 'contexts/Session';
import { SESSION_EXTENSION_TIME } from 'globals/constants';

import 'whatwg-fetch';

const LATEST_API_VERSION = '24.08.14' as const;

const apiUrl = process.env.REACT_APP_API_URL;
export const nsUrl = process.env.REACT_APP_NS_URL;

type FetchParams = {
  method?: 'DELETE' | 'GET' | 'POST' | 'PUT';
  queryParams?: Record<string, unknown>;
  bodyParams?: Record<string, unknown> | unknown[] | FormData;
  config?: Record<string, unknown>;
  includeHeaders?: boolean;
  includeStatus?: boolean;
  url?: string;
};

export function useFetch() {
  const { token, expiresAt, setExpiresAt } = useSession();
  const { selectedLanguage, setForceReload } = useGlobal();

  function getFetchConfig(
    path: string,
    {
      method = 'GET',
      queryParams = {},
      bodyParams = {},
      config = {},
      url = apiUrl,
    }: FetchParams = {},
  ) {
    const defaultConfig: {
      method: 'DELETE' | 'GET' | 'POST' | 'PUT';
      headers: {
        'Cache-control': 'no-cache';
        'Pragma': 'no-cache';
        'Expires': '0';
        'Accept-Language': string;
        'Authorization'?: string;
        'Content-Type'?: 'application/json';
      };
    } = {
      method,
      headers: {
        'Cache-control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
        'Accept-Language': selectedLanguage ?? '',
        'Content-Type': 'application/json',
      },
    };

    if (token !== null) defaultConfig.headers.Authorization = 'Bearer ' + token;

    const joinedConfig = merge(defaultConfig, config);
    const urlParams = !isEmpty(queryParams)
      ? '?' + new URLSearchParams(mapValues(queryParams, v => String(v))).toString()
      : '';
    const query = url + path + urlParams;

    if (['POST', 'PUT'].includes(method)) {
      if (bodyParams instanceof FormData) {
        joinedConfig.body = bodyParams;
        delete joinedConfig.headers['Content-Type'];
      } else {
        joinedConfig.body = JSON.stringify(bodyParams);
      }
    }

    return { query, config: joinedConfig };
  }

  async function fetchData(
    path: string,
    {
      method = 'GET',
      queryParams = {},
      bodyParams = {},
      config = {},
      includeHeaders = false,
      includeStatus = false,
      url = apiUrl,
    }: FetchParams = {},
  ) {
    const { query, config: fetchConfig } = getFetchConfig(path, {
      method,
      queryParams,
      bodyParams,
      config,
      url,
    });
    const response = await fetch(query, fetchConfig).catch(() => {
      const message = 'Something went wrong, try again later';
      throw includeStatus ? { status: null, error: message } : message;
    });
    const version = response.headers.get('x-version');
    if (version) {
      const apiVersion = moment(version, 'YYYY.MM.DD');
      const dashboardVersion = moment(LATEST_API_VERSION, 'YYYY.MM.DD');
      if (apiVersion.isAfter(dashboardVersion)) {
        setForceReload(true);
      }
    }
    const data = response.status !== 204 ? await response.json() : null;
    if (!response.ok) throw includeStatus ? { status: response.status, error: data } : data;

    if (expiresAt && expiresAt < Date.now() + SESSION_EXTENSION_TIME) {
      setExpiresAt(Date.now() + SESSION_EXTENSION_TIME);
    }

    if (includeHeaders) return { data, headers: response.headers };
    return data;
  }

  return { getFetchConfig, fetchData };
}
