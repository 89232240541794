import { FunctionComponent } from 'react';
import ReactLoadingSkeleton from 'react-loading-skeleton';

export type SkeletonProps = {
  count?: number;
  wrapper?: FunctionComponent;
  className?: string;
  width?: number | string;
  height?: number | string;
  hasFade?: boolean;
  isWhite?: boolean;
  noMargin?: boolean;
  isCircle?: boolean;
  hasAnimation?: boolean;
  containerClass?: string;
};

export default function Skeleton({
  count,
  wrapper,
  className,
  width,
  height,
  hasFade = false,
  isWhite = false,
  noMargin = false,
  isCircle = false,
  hasAnimation = true,
  containerClass,
}: SkeletonProps) {
  let containerClassName = 'skeleton-wrapper';
  if (hasFade) containerClassName += ' fade-out';
  if (containerClass) containerClassName += ' ' + containerClass;
  return (
    <ReactLoadingSkeleton
      containerTestId="skeleton"
      count={count}
      wrapper={wrapper}
      className={className}
      width={typeof width === 'number' ? `min(${width}px, 100%)` : width}
      height={height}
      baseColor={isWhite ? '#ffffff' : undefined}
      containerClassName={containerClassName}
      inline={noMargin}
      circle={isCircle}
      enableAnimation={hasAnimation}
    />
  );
}
