import { useRoutes } from 'contexts/Routes';
import { useSession } from 'contexts/Session';
import { useKnowledgeCenterCheck } from 'hooks/knowledge-center';
import SubItem from './SubItem';

export default function SidebarExtension() {
  const { navigationDisabled, displaySidebarExtension, selectedSubItems } = useRoutes();
  const { token } = useSession();
  const isKnowledgeCenter = useKnowledgeCenterCheck();

  if (!token || !displaySidebarExtension || navigationDisabled) {
    return null;
  }

  return (
    <div
      id="navigation"
      data-testid="sidebar-extension"
      className={isKnowledgeCenter ? 'knowledge-center' : ''}
    >
      {selectedSubItems.map((subRouteItem, index) => (
        <div className="item-wrapper" key={index}>
          <SubItem depth={1} item={subRouteItem} />
        </div>
      ))}
    </div>
  );
}
