export default {
    "Afghanistan": "Afghanistan", 
    "Albania": "Albanië",
    "Algeria": "Algerije",
    "Andorra": "Andorra",
    "Angola": "Angola",
    "Antigua and Barbuda": "Antigua en Barbuda",
    "Argentina": "Argentinië",
    "Armenia": "Armenië",
    "Australia": "Australië",
    "Austria": "Oostenrijk",
    "Azerbaijan": "Azerbeidzjan",
    "Bahamas": "Bahama's",
    "Bahrain": "Bahrein",
    "Bangladesh": "Bangladesh",
    "Barbados": "Barbados",
    "Belarus": "Belarus",
    "Belgium": "België",
    "Belize": "Belize",
    "Benin": "Benin",
    "Bhutan": "Bhutan",
    "Bolivia": "Bolivia",
    "Bosnia and Herzegovina": "Bosnië-Herzegovina",
    "Botswana": "Botswana",
    "Brazil": "Brazilië",
    "Brunei": "Brunei",
    "Bulgaria": "Bulgarije",
    "Burkina Faso": "Burkina Faso",
    "Burundi": "Burundi",
    "Côte d'Ivoire": "Ivoorkust",
    "Cabo Verde": "Kaapverdië",
    "Cambodia": "Cambodja",
    "Cameroon": "Kameroen",
    "Canada": "Canada",
    "Central African Republic": "Centraal Afrikaanse Republiek",
    "Chad": "Tsjaad",
    "Chile": "Chili",
    "China": "China",
    "Colombia": "Colombia",
    "Comoros": "Comoren",
    "Congo (Congo-Brazzaville)": "Congo (Congo-Brazzaville)",
    "Costa Rica": "Costa Rica",
    "Croatia": "Kroatië",
    "Cuba": "Cuba",
    "Cyprus": "Cyprus",
    "Czechia": "Tsjechië",
    "Democratic Republic of the Congo": "Democratische Republiek Congo",
    "Denmark": "Denemarken",
    "Djibouti": "Djibouti",
    "Dominica": "Dominica",
    "Dominican Republic": "Dominicaanse Republiek",
    "Ecuador": "Ecuador",
    "Egypt": "Egypte",
    "El Salvador": "El Salvador",
    "Equatorial Guinea": "Equatoriaal-Guinea",
    "Eritrea": "Eritrea",
    "Estonia": "Estland",
    "Eswatini": "Swatini",
    "Ethiopia": "Ethiopië",
    "Fiji": "Fiji",
    "Finland": "Finland",
    "France": "Frankrijk",
    "Gabon": "Gabon",
    "Gambia": "Gambia",
    "Georgia": "Georgië",
    "Germany": "Duitsland",
    "Ghana": "Ghana",
    "Greece": "Griekenland",
    "Grenada": "Grenada",
    "Guatemala": "Guatemala",
    "Guinea": "Guinea",
    "Guinea-Bissau": "Guinee-Bissau",
    "Guyana": "Guyana",
    "Haiti": "Haïti",
    "Vatican city": "Vaticaanstad",
    "Honduras": "Honduras",
    "Hong Kong": "Hongkong",
    "Hungary": "Hongarije",
    "Iceland": "IJsland",
    "India": "Indië",
    "Indonesia": "Indonesië",
    "Iran": "Iran",
    "Iraq": "Irak",
    "Ireland": "Ierland",
    "Israel": "Israël",
    "Italy": "Italië",
    "Jamaica": "Jamaica",
    "Japan": "Japan",
    "Jordan": "Jordanië",
    "Kazakhstan": "Kazachstan",
    "Kenya": "Kenia",
    "Kiribati": "Kiribati",
    "Kuwait": "Koeweit",
    "Kyrgyzstan": "Kirgizië",
    "Laos": "Laos",
    "Latvia": "Letland",
    "Lebanon": "Libanon",
    "Lesotho": "Lesotho",
    "Liberia": "Liberia",
    "Libya": "Libië",
    "Liechtenstein": "Liechtenstein",
    "Lithuania": "Litouwen",
    "Luxembourg": "Luxemburg",
    "Madagascar": "Madagascar",
    "Malawi": "Malawi",
    "Malaysia": "Maleisië",
    "Maldives": "Malediven",
    "Mali": "Mali",
    "Malta": "Malta",
    "Marshall Islands": "Marshall eilanden",
    "Mauritania": "Mauritanië",
    "Mauritius": "Mauritius",
    "Mexico": "Mexico",
    "Micronesia": "Micronesië",
    "Moldova": "Moldavië",
    "Monaco": "Monaco",
    "Mongolia": "Mongolië",
    "Montenegro": "Montenegro",
    "Morocco": "Marokko",
    "Mozambique": "Mozambique",
    "Myanmar": "Myanmar",
    "Namibia": "Namibië",
    "Nauru": "Nauru",
    "Nepal": "Nepal",
    "Netherlands": "Nederland",
    "New Zealand": "Nieuw-Zeeland",
    "Nicaragua": "Nicaragua",
    "Niger": "Niger",
    "Nigeria": "Nigeria",
    "North Korea": "Noord Korea",
    "North Macedonia": "Noord-Macedonië",
    "Norway": "Noorwegen",
    "Oman": "Oman",
    "Pakistan": "Pakistan",
    "Palau": "Palau",
    "Palestine State": "Palestijnse staat",
    "Panama": "Panama",
    "Papua New Guinea": "Papoea-Nieuw-Guinea",
    "Paraguay": "Paraguay",
    "Peru": "Peru",
    "Philippines": "Filipijnen",
    "Poland": "Polen",
    "Portugal": "Portugal",
    "Qatar": "Qatar",
    "Romania": "Roemenië",
    "Russia": "Rusland",
    "Rwanda": "Rwanda",
    "Saint Kitts and Nevis": "Saint Kitts en Nevis",
    "Saint Lucia": "Sint Lucia",
    "Saint Vincent and the Grenadines": "Saint Vincent en de Grenadines",
    "Samoa": "Samoa",
    "San Marino": "San Marino",
    "Sao Tome and Principe": "Sao Tomé en Principe",
    "Saudi Arabia": "Saoedi-Arabië",
    "Senegal": "Senegal",
    "Serbia": "Servië",
    "Seychelles": "Seychellen",
    "Sierra Leone": "Sierra Leone",
    "Singapore": "Singapore",
    "Slovakia": "Slowakije",
    "Slovenia": "Slovenië",
    "Solomon Islands": "Solomon eilanden",
    "Somalia": "Somalië",
    "South Africa": "Zuid-Afrika",
    "South Korea": "Zuid-Korea",
    "South Sudan": "Zuid-Soedan",
    "Spain": "Spanje",
    "Sri Lanka": "Sri Lanka",
    "Sudan": "Soedan",
    "Suriname": "Suriname",
    "Sweden": "Zweden",
    "Switzerland": "Zwitserland",
    "Syria": "Syrië",
    "Tajikistan": "Tadzjikistan",
    "Taiwan": "Taiwan",
    "Tanzania": "Tanzania",
    "Thailand": "Thailand",
    "Timor-Leste": "Oost-Timor",
    "Togo": "Togo",
    "Tonga": "Tonga",
    "Trinidad and Tobago": "Trinidad en Tobago",
    "Tunisia": "Tunesië",
    "Turkey": "Turkije",
    "Turkmenistan": "Turkmenistan",
    "Tuvalu": "Tuvalu",
    "Uganda": "Oeganda",
    "Ukraine": "Oekraïne",
    "United Arab Emirates": "Verenigde Arabische Emiraten",
    "United Kingdom": "Verenigd Koninkrijk",
    "United States of America": "Verenigde Staten",
    "Uruguay": "Uruguay",
    "Uzbekistan": "Oezbekistan",
    "Vanuatu": "Vanuatu",
    "Venezuela": "Venezuela",
    "Vietnam": "Vietnam",
    "Yemen": "Jemen",
    "Zambia": "Zambia",
    "Zimbabwe": "Zimbabwe"
}