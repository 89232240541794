import { useEffect, useMemo, useState } from 'react';
import { useFormikContext, yupToFormErrors } from 'formik';
import { isEmpty, omit, pick } from 'lodash';

import { focusErrorElement } from 'components/ErrorFocus';
import SearchableSelect from 'components/input/SearchableSelect';
import Select from 'components/input/Select';
import TextField from 'components/input/TextField';
import Toggle from 'components/input/Toggle';
import Skeleton from 'components/Skeleton';
import Tooltip from 'components/Tooltip';
import { useCountryTranslations } from 'hooks/content';
import { useStateCallback } from 'hooks/effect';
import {
  InvoiceProfile as Profile,
  useCreateInvoiceProfile,
  useDeleteInvoiceProfile,
  useInvoiceProfiles,
  useUpdateInvoiceProfile,
} from 'hooks/queries/invoice-profile';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation, useValidationSchema } from 'hooks/validation';
import { IntakeFormValues, IntakeSection } from 'pages/authenticated/requests/Intake';

export const invoiceProfileInitialValues = {
  invoice_profile_id: '',
  label: '',
  email: '',
  company: '',
  name: '',
  reference: '',
  kvk_number: '',
  vat_number: '',
  phone_number: '',
  address: '',
  zipcode: '',
  city: '',
  country: '',
  automatic_collection: 0 as const,
};

export default function InvoiceProfile() {
  const translateText = useTranslate();
  const countries = useCountryTranslations();
  const { invoiceProfiles, isLoading } = useInvoiceProfiles();
  const {
    values: intakeValues,
    setFieldValue,
    errors: intakeErrors,
    setValues: setIntakeValues,
  } = useFormikContext<IntakeFormValues>();
  const { required } = useCustomValidation();
  const { invoiceProfileValidation } = useValidationSchema();
  const { createInvoiceProfile, isCreating } = useCreateInvoiceProfile();
  const { updateInvoiceProfile, isUpdating } = useUpdateInvoiceProfile();
  const { deleteInvoiceProfile, isDeleting } = useDeleteInvoiceProfile();

  const [errors, setErrors] = useState<{
    label?: string;
    email?: string;
    company?: string;
    name?: string;
    vat_number?: string;
    phone_number?: string;
    address?: string;
    zipcode?: string;
    city?: string;
    country?: string;
  }>({});
  const [submitCount, setSubmitCount] = useStateCallback(0);

  const values = useMemo(
    () =>
      pick(
        intakeValues,
        Object.keys(invoiceProfileInitialValues) as (keyof typeof invoiceProfileInitialValues)[],
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      intakeValues.invoice_profile_id,
      intakeValues.name,
      intakeValues.label,
      intakeValues.email,
      intakeValues.company,
      intakeValues.reference,
      intakeValues.kvk_number,
      intakeValues.vat_number,
      intakeValues.phone_number,
      intakeValues.address,
      intakeValues.zipcode,
      intakeValues.city,
      intakeValues.country,
      intakeValues.automatic_collection,
    ],
  );

  useEffect(() => {
    try {
      invoiceProfileValidation
        .shape({ label: required })
        .validateSync(values, { abortEarly: false });
      setErrors({});
    } catch (error) {
      setErrors(yupToFormErrors(error));
    }
  }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

  const isValidated = typeof intakeValues.validated[IntakeSection.Properties] === 'boolean';
  const disabled = isCreating || isUpdating || isDeleting;

  let saveButtonText = translateText('label', 'Update invoice profile');
  let saveButtonTooltip = translateText(
    'tooltip/intake',
    'Do you want to update the current invoice profile? Then adjust it here. The information will be changed for the next invoice.',
  );

  if (values.invoice_profile_id === '') {
    saveButtonText = translateText('label', 'Save invoice profile');
    saveButtonTooltip = translateText(
      'tooltip/intake',
      'Do you want to save the current invoice profile? The information is saved and can be reselected with a new request.',
    );
  }

  function save() {
    const hasError = !isEmpty(errors);
    setSubmitCount(
      count => count + 1,
      () => hasError && focusErrorElement(),
    );
    if (hasError) return;

    if (values.invoice_profile_id === '') {
      createInvoiceProfile(values).then(profile => setInvoiceProfile(profile.id, profile));
    } else {
      updateInvoiceProfile(values);
    }
  }

  function setInvoiceProfile(id: string, newProfile?: { id: number } & Profile) {
    const profile = newProfile ?? (id ? invoiceProfiles?.find(p => p.id === +id) : null);
    const newValues = profile
      ? {
          invoice_profile_id: String(profile.id),
          ...omit(profile, ['id', 'created_by', 'bic', 'iban', 'ascription', '_links']),
        }
      : invoiceProfileInitialValues;
    setIntakeValues({ ...intakeValues, ...newValues });
    setSubmitCount(0);
  }

  return (
    <div className="white-block">
      <div className="form-row">
        <div className="name-label">
          {translateText('label', 'Invoice profile')}
          <Tooltip
            text={translateText(
              'tooltip/intake',
              'Select an existing invoice profile here. The different domains will appear on the same invoice.',
            )}
          />
        </div>
        <div className="value-wrapper">
          {isLoading ? (
            <Skeleton height={38} />
          ) : (
            <Select
              options={[
                { id: '', label: translateText('label', 'No invoice profile') },
                ...(invoiceProfiles ?? []),
              ]}
              optionValue="id"
              optionText="label"
              value={values.invoice_profile_id}
              onChange={e => setInvoiceProfile(e.target.value)}
            />
          )}
        </div>
      </div>
      {values.invoice_profile_id === '' && (
        <div className="form-row">
          <div className="name-label">{translateText('label', 'Invoice profile label')}</div>
          <div className="value-wrapper">
            <TextField
              value={values.label}
              onChange={e => setFieldValue('label', e.target.value)}
              error={submitCount > 0 && errors.label}
            />
          </div>
        </div>
      )}
      <div className="form-row">
        <div className="name-label required">
          {translateText('label', 'Email address')}
          <Tooltip
            text={translateText(
              'tooltip/intake',
              'Enter the e-mail address to which we can send the invoices.',
            )}
          />
        </div>
        <div className="value-wrapper">
          <TextField
            value={values.email}
            onChange={e => setFieldValue('email', e.target.value)}
            error={(submitCount > 0 && errors.email) || (isValidated && intakeErrors.email)}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="name-label required">{translateText('label', 'Company name')}</div>
        <div className="value-wrapper">
          <TextField
            value={values.company}
            onChange={e => setFieldValue('company', e.target.value)}
            error={(submitCount > 0 && errors.company) || (isValidated && intakeErrors.company)}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="name-label required">{translateText('label', 'To the attention of')}</div>
        <div className="value-wrapper">
          <TextField
            value={values.name}
            onChange={e => setFieldValue('name', e.target.value)}
            error={(submitCount > 0 && errors.name) || (isValidated && intakeErrors.name)}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="name-label">
          {translateText('label', 'Reference / Purchase Order Number')}
        </div>
        <div className="value-wrapper">
          <TextField
            value={values.reference}
            onChange={e => setFieldValue('reference', e.target.value)}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="name-label">{translateText('label', 'KVK-number')}</div>
        <div className="value-wrapper">
          <TextField
            value={values.kvk_number}
            onChange={e => setFieldValue('kvk_number', e.target.value)}
          />
        </div>
      </div>
      <div className="form-row">
        <div className={'name-label' + (!['', 'NL'].includes(values.country) ? ' required' : '')}>
          {translateText('label', 'VAT-number')}
        </div>
        <div className="value-wrapper">
          <TextField
            value={values.vat_number ?? ''}
            onChange={e => setFieldValue('vat_number', e.target.value)}
            error={
              (submitCount > 0 && errors.vat_number) || (isValidated && intakeErrors.vat_number)
            }
          />
        </div>
      </div>
      <div className="form-row">
        <div className="name-label required">{translateText('label', 'Phone number')}</div>
        <div className="value-wrapper">
          <TextField
            value={values.phone_number}
            onChange={e => setFieldValue('phone_number', e.target.value)}
            error={
              (submitCount > 0 && errors.phone_number) || (isValidated && intakeErrors.phone_number)
            }
          />
        </div>
      </div>
      <div className="form-row">
        <div className="name-label required">{translateText('label', 'Address')}</div>
        <div className="value-wrapper">
          <TextField
            value={values.address}
            onChange={e => setFieldValue('address', e.target.value)}
            error={(submitCount > 0 && errors.address) || (isValidated && intakeErrors.address)}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="name-label required">{translateText('label', 'Postal code + city')}</div>
        <div className="value-wrapper">
          <div className="value-col-1">
            <TextField
              value={values.zipcode}
              onChange={e => setFieldValue('zipcode', e.target.value)}
              error={(submitCount > 0 && errors.zipcode) || (isValidated && intakeErrors.zipcode)}
            />
          </div>
          <div className="value-col-2">
            <TextField
              value={values.city}
              onChange={e => setFieldValue('city', e.target.value)}
              error={(submitCount > 0 && errors.city) || (isValidated && intakeErrors.city)}
            />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="name-label required">{translateText('label', 'Country')}</div>
        <div className="value-wrapper">
          <SearchableSelect
            options={countries}
            value={values.country}
            onChange={value => setFieldValue('country', value)}
            className="wide"
            error={(submitCount > 0 && errors.country) || (isValidated && intakeErrors.country)}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="name-label">
          {translateText('label', 'Automatic debit')}
          <Tooltip
            text={translateText(
              'tooltip/intake',
              'Do you want the amount to be automatically debited from your account? Then switch on automatic debit.',
            )}
          />
        </div>
        <div className="value-wrapper">
          <Toggle
            checked={!!values.automatic_collection}
            onClick={() => setFieldValue('automatic_collection', +!values.automatic_collection)}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="name-label" />
        <div className="value-wrapper wrap">
          <button className="btn btn-text no-padding" onClick={save} disabled={disabled}>
            {saveButtonText}
            <Tooltip text={saveButtonTooltip} />
          </button>
          {values.invoice_profile_id !== '' && (
            <button
              className="btn btn-text purple-text no-padding"
              onClick={() =>
                deleteInvoiceProfile(values.invoice_profile_id).then(() => setInvoiceProfile(''))
              }
              disabled={disabled}
            >
              {translateText('label', 'Delete invoice profile')}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
