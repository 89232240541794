import { useState } from 'react';
import { Formik } from 'formik';

import ConfigurationCard from 'components/configuration/ConfigurationCard';
import GrayBars from 'components/GrayBars';
import Select from 'components/input/Select';
import TextField from 'components/input/TextField';
import Toggle from 'components/input/Toggle';
import RestrictedAccess from 'components/RestrictedAccess';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import SupportParagraph from 'components/SupportParagraph';
import { ROLE_ADMIN } from 'globals/constants';
import { SUP_ART_BEEP } from 'globals/support';
import { useHasAdminAccess } from 'hooks/access';
import {
  BeepNotificationValues,
  useBeepNotification,
  useCreateBeepNotification,
  useUpdateBeepNotification,
} from 'hooks/queries/beep-notification';
import { useDomainAuth } from 'hooks/queries/domain';
import { useTranslate } from 'hooks/translate';

export default function BeepNotification() {
  const translateText = useTranslate();
  const isAdmin = useHasAdminAccess();
  const { createBeepNotification } = useCreateBeepNotification();
  const { updateBeepNotification } = useUpdateBeepNotification();
  const { beepNotification, isLoading: isLoadingBeepNotification } = useBeepNotification();
  const { isLoading: isLoadingDomainAuth } = useDomainAuth();
  const isLoading = isLoadingBeepNotification || isLoadingDomainAuth;

  const [showEdit, setShowEdit] = useState(false);

  const label = translateText('label', 'Beep');
  const supportText = translateText(
    'call-tracking',
    'You will be notified by a beep that the incoming call originates from a phone number for Call Tracking.',
  );

  const initialEnabled = !!beepNotification?.enabled;
  const initialFilename = beepNotification?.filename ?? '100%';
  const initialInterval = beepNotification?.interval ?? 30_000;

  async function save(values: BeepNotificationValues) {
    (!beepNotification ? createBeepNotification : updateBeepNotification)(values).then(() =>
      setShowEdit(false),
    );
  }

  return (
    <>
      <ConfigurationCard
        title={label}
        supportArticleId={SUP_ART_BEEP}
        onEdit={() => setShowEdit(true)}
        isLoading={isLoading}
        rightParagraph={supportText}
      >
        <GrayBars
          rows={[
            { key: label, status: initialEnabled },
            ...(isAdmin
              ? [
                  {
                    key: translateText('label', 'Filename'),
                    value: initialFilename,
                  },
                  {
                    key: translateText('label', 'Interval'),
                    value: String(initialInterval),
                  },
                ]
              : []),
          ]}
          isLoading={isLoading}
        />
      </ConfigurationCard>
      <Formik<BeepNotificationValues>
        initialValues={{
          enabled: initialEnabled,
          filename: initialFilename,
          interval: initialInterval,
        }}
        onSubmit={save}
        enableReinitialize
      >
        {({ values, submitForm, resetForm, isSubmitting, dirty, handleChange }) => (
          <Setup
            show={showEdit}
            title={label}
            save={submitForm}
            close={() => setShowEdit(false)}
            afterClose={resetForm}
            isSaving={isSubmitting}
            maxWidth={410}
            hasChanges={dirty}
          >
            <p>{supportText}</p>
            <SupportParagraph articleId={SUP_ART_BEEP} />
            <div className="section">
              <InputWrapper label={label}>
                <Toggle
                  name="enabled"
                  checked={values.enabled}
                  onClick={handleChange}
                  disabled={isSubmitting}
                />
              </InputWrapper>
              <RestrictedAccess role={ROLE_ADMIN} show="outline">
                <InputWrapper label={translateText('label', 'Filename')}>
                  <Select
                    name="filename"
                    value={values.filename}
                    onChange={handleChange}
                    options={['25%', '50%', '75%', '100%']}
                  />
                </InputWrapper>
                <InputWrapper label={translateText('label', 'Interval')}>
                  <TextField
                    type="number"
                    name="interval"
                    value={values.interval}
                    onChange={handleChange}
                    minimalValue={0}
                    maximumValue={10_000_000}
                  />
                </InputWrapper>
              </RestrictedAccess>
            </div>
          </Setup>
        )}
      </Formik>
    </>
  );
}
