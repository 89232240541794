import { ReactElement, useRef } from 'react';
import { useParams } from 'react-router';

import Loader from 'components/Loader';
import Skeleton from 'components/Skeleton';
import { useMessages } from 'contexts/Messages';
import { useDateTime } from 'hooks/date-time';
import { useAdminCall } from 'hooks/queries/call';
import { useTranslate } from 'hooks/translate';
import Error404 from 'pages/Error404';

export default function CallHistoryDetails() {
  const params = useParams();
  const translateText = useTranslate();
  const { formatDateTime } = useDateTime();
  const { addSuccessMessage, addErrorMessage } = useMessages();
  const tableRef = useRef<HTMLTableElement | null>(null);

  const callId = Number(params.id);
  const { call, isLoading } = useAdminCall(!isNaN(callId) ? callId : null);

  if (isLoading) return <Loader />;
  if (!call) return <Error404 />;

  function copyToClipBoard() {
    if (!tableRef.current) return;

    try {
      navigator.clipboard
        .write([
          new ClipboardItem({
            'text/html': new Blob([tableRef.current.outerHTML], { type: 'text/html' }),
            'text/plain': new Blob([tableRef.current.innerText], { type: 'text/plain' }),
          }),
        ])
        .then(() => addSuccessMessage(translateText('message', 'Copied to clipboard')));
    } catch {
      addErrorMessage(
        translateText('message', 'The copy functionality is not supported by your browser'),
      );
    }
  }

  return (
    <div className="call-history-details">
      <h1>
        {translateText('label', 'Call history')}
        <span>ID: {call.id}</span>
      </h1>

      <h2 className="section-title">
        {translateText('label', 'Dstny issue data')}
        <button className="btn btn-grey btn-small margin-left" onClick={copyToClipBoard}>
          {translateText('label', 'Copy')}
        </button>
      </h2>
      <div className="white-block">
        <table className="call-history-table" ref={tableRef}>
          <tbody>
            <tr>
              <td className="bold">{translateText('label', 'Date & time')}</td>
              <td>{formatDateTime(call.time_called)}</td>
            </tr>
            <tr>
              <td className="bold">A ({translateText('label', 'Sender')})</td>
              <td>{call.caller}</td>
            </tr>
            <tr>
              <td className="bold">B (DID AdCalls)</td>
              <td>{call.number}</td>
            </tr>
            <tr>
              <td className="bold">C ({translateText('label', 'Destination number')})</td>
              <td>{call.destination}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <h2 className="section-title margin-top">
        {isLoading ? <Skeleton width={120} /> : translateText('label', 'Call details')}
      </h2>
      <div className="white-block">
        <table className="call-history-table">
          <tbody>
            {Object.entries(call).map(([key, value], index) => {
              if (key === '_links') return null;
              let data: string | ReactElement;
              if (value === null) {
                data = <span className="no-data">null</span>;
              } else if (typeof value === 'object') {
                data = JSON.stringify(value, undefined, 4);
              } else if (key === 'time_called' && typeof value === 'string') {
                data = formatDateTime(value);
              } else {
                data = value.toString();
              }

              return (
                <tr key={index}>
                  <td className="bold">{key}</td>
                  <td>{data}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
