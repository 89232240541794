import { useState } from 'react';
import { QueryObserverResult } from '@tanstack/react-query';
import { Formik, FormikHelpers } from 'formik';
import { isEqual } from 'lodash';
import * as Yup from 'yup';

import { ReactComponent as Add } from 'assets/images/icons/add.svg';
import alert from 'assets/images/icons/alert.svg';
import { ReactComponent as Delete } from 'assets/images/icons/delete-purple.svg';
import ErrorFocus from 'components/ErrorFocus';
import ErrorTooltip from 'components/ErrorTooltip';
import SearchableSelect from 'components/input/SearchableSelect';
import Select from 'components/input/Select';
import TextField from 'components/input/TextField';
import { getDirtyValues } from 'globals/helpers';
import { useHasAdminAccess } from 'hooks/access';
import {
  CampaignBuilderPool as Pool,
  useAddNumbers,
  useCreatePool,
  useDeletePool,
  useUpdatePool,
} from 'hooks/queries/campaign-builder';
import { usePutBackToStock } from 'hooks/queries/numberpool';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation } from 'hooks/validation';

export type PoolFormValues = {
  destination: string;
  location_identifier: string;
  offline: string;
  numberCount: string;
  netNumber: string;
  conditions: Pool['conditions'];
};

type Props = {
  locations: Record<string, string>;
  pool: Pool;
  refetch: () => Promise<QueryObserverResult<{ data: Pool[]; headers: Headers }>>;
};

export const NUMBERPOOL_TRACK_TYPE_ONSITE = 0 as const;
export const NUMBERPOOL_TRACK_TYPE_OFFSITE = 1 as const;
export const NUMBERPOOL_TRACK_TYPE_APP = 2 as const;

export const NUMBERPOOL_STATUS_INACTIVE = 0 as const;
export const NUMBERPOOL_STATUS_ACTIVE = 1 as const;
export const NUMBERPOOL_STATUS_DISCONTINUE_ACCEPTED = 2 as const;
export const NUMBERPOOL_STATUS_DISCONTINUE_REQUESTED = 3 as const;
export const NUMBERPOOL_STATUS_PENDING = 4;
const DEFAULT_SOURCES = [
  'BingCPC',
  'Bing',
  'Google Search',
  'AdWords',
  'direct',
  'Facebook',
  'Marktplaats',
  'LinkedIn',
  'Twitter',
] as const;

export default function CampaignBuilderPool({ locations, pool, refetch }: Props) {
  const translateText = useTranslate();
  const isAdmin = useHasAdminAccess();
  const { required } = useCustomValidation();

  const { addNumbers } = useAddNumbers();
  const { deletePool, isDeleting } = useDeletePool();
  const { putBackToStock, isPuttingBackToStock } = usePutBackToStock();
  const { updatePool } = useUpdatePool();
  const { createPool } = useCreatePool();

  const [showNumbers, setShowNumbers] = useState(false);

  const isNew = typeof pool.id === 'string';

  let id = !isNew ? String(pool.id) : translateText('label', 'New');
  switch (!isNew && pool.is_active) {
    case NUMBERPOOL_STATUS_INACTIVE:
      id += ' (' + translateText('label', 'Inactive') + ')';
      break;
    case NUMBERPOOL_STATUS_ACTIVE:
      id += ' (' + translateText('label', 'Active') + ')';
      break;
    case NUMBERPOOL_STATUS_DISCONTINUE_ACCEPTED:
      id += ' (' + translateText('label', 'Discontinue accepted') + ')';
      break;
    case NUMBERPOOL_STATUS_DISCONTINUE_REQUESTED:
      id += ' (' + translateText('label', 'Discontinue requested') + ')';
      break;
    case NUMBERPOOL_STATUS_PENDING:
      id += ' (' + translateText('label', 'Requested') + ')';
  }

  const numbersValidationSchema = Yup.object({
    numbers: required,
    destination: Yup.string().test({
      test: value => pool.number_count !== 0 || !!value,
      message: translateText('message', 'This field is required.'),
    }),
  });

  async function saveNumbers(
    values: { numbers: string; destination: string },
    formikHelpers: FormikHelpers<{ numbers: string; destination: string }>,
  ) {
    await addNumbers({
      id: pool.id as number,
      numbers: values.numbers,
      destination: pool.number_count === 0 ? values.destination : null,
    }).then(() => {
      refetch();
      formikHelpers.resetForm();
    });
  }

  const poolValidationSchema = Yup.object({
    destination: required,
    numberCount: required,
    netNumber: required,
    conditions: Yup.array().min(1, translateText('message', 'Add at least one condition')),
  });

  async function savePool(values: PoolFormValues) {
    if (isNew) {
      await createPool({
        id: pool.id as string,
        ...values,
      });
    } else {
      await updatePool({
        id: pool.id as number,
        ...getDirtyValues<PoolFormValues>(values, initialValues),
      });
    }
  }

  const initialValues = {
    destination: pool.destination ?? '',
    location_identifier: pool.location_identifier ?? '',
    offline: String(pool.is_offline ?? 1),
    conditions: pool.conditions ?? [],
    numberCount: String(pool.number_count ?? ''),
    netNumber: pool.net_number ?? '',
  };

  return (
    <Formik<PoolFormValues>
      initialValues={initialValues}
      onSubmit={savePool}
      validationSchema={poolValidationSchema}
      enableReinitialize
    >
      {({
        values,
        handleChange,
        setFieldValue,
        initialValues,
        dirty,
        isSubmitting,
        submitForm,
        submitCount,
        errors,
      }) => (
        <div
          id={String(pool.id)}
          className={
            'campaign-builder-pool white-block' +
            (pool.is_active !== NUMBERPOOL_STATUS_ACTIVE ? ' inactive' : '')
          }
        >
          <ErrorFocus element={document.getElementById(String(pool.id))} />
          <div className="header">
            <span className={isNew ? 'new' : ''}>
              {!isNew && translateText('label', 'ID') + ': '}
              {id}
            </span>

            <div className="input">
              <span>{translateText('label', 'Destination number')}:</span>
              <TextField
                name="destination"
                value={values.destination}
                onChange={handleChange}
                canEdit={isAdmin}
                className={initialValues.destination !== values.destination ? 'changed' : ''}
                error={submitCount > 0 && errors.destination}
              />
            </div>

            <div className="input location">
              <span>{translateText('label', 'Location')}:</span>
              <SearchableSelect
                options={locations}
                value={values.location_identifier}
                onChange={location => setFieldValue('location_identifier', location)}
                className={
                  initialValues.location_identifier !== values.location_identifier ? 'changed' : ''
                }
                showSearchFrom={10}
              />
            </div>

            <div className="input">
              <span>{translateText('label', 'Type')}:</span>
              <Select
                name="offline"
                value={values.offline}
                onChange={handleChange}
                canEdit={isAdmin}
                className={'offline' + (initialValues.offline !== values.offline ? ' changed' : '')}
                options={{
                  [NUMBERPOOL_TRACK_TYPE_ONSITE]: translateText('label', 'Onsite'),
                  [NUMBERPOOL_TRACK_TYPE_OFFSITE]: translateText('label', 'Offsite'),
                  [NUMBERPOOL_TRACK_TYPE_APP]: translateText('label', 'App'),
                }}
              />
            </div>
          </div>
          <div className="condition-section">
            {isAdmin && (
              <Formik
                initialValues={{ operand: '==', value: '' }}
                onSubmit={(condition, formikHelpers) => {
                  setFieldValue('conditions', [
                    ...values.conditions,
                    { field: 'source', ...condition },
                  ]);
                  formikHelpers.resetForm();
                }}
              >
                {({ values: subValues, handleChange: subHandleChange, submitForm: submitSub }) => (
                  <div className="new-condition">
                    <span>{translateText('label', 'Traffic source')}</span>
                    <Select
                      name="operand"
                      value={subValues.operand}
                      onChange={subHandleChange}
                      options={['==', '!=']}
                    />
                    <TextField
                      name="value"
                      value={subValues.value}
                      onChange={subHandleChange}
                      list="condition-value"
                    />
                    <datalist id="condition-value">
                      {DEFAULT_SOURCES.map(s => (
                        <option key={s} value={s} />
                      ))}
                    </datalist>
                    <Add className="add" onClick={submitSub} />
                  </div>
                )}
              </Formik>
            )}
            <div className="conditions">
              {submitCount > 0 && !!errors.conditions && (
                <ErrorTooltip
                  error={errors.conditions as string}
                  html={
                    <div data-error tabIndex={1} id={id + '-error'}>
                      <img className="error-icon" src={alert} alt="error" />
                    </div>
                  }
                />
              )}
              {values.conditions.map((condition, index) => (
                <div
                  key={'sc' + index}
                  className={
                    'tag' +
                    (!isEqual(initialValues.conditions, values.conditions) ? ' changed' : '')
                  }
                >
                  {condition.field} {condition.operand} {condition.value}
                  {isAdmin && (
                    <Delete
                      className="remove"
                      onClick={() =>
                        setFieldValue(
                          'conditions',
                          values.conditions.filter((_, i) => i !== index),
                        )
                      }
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="number-section">
            <TextField
              name="numberCount"
              value={values.numberCount}
              onChange={handleChange}
              canEdit={isAdmin}
              className={initialValues.numberCount !== values.numberCount ? 'changed' : ''}
              error={submitCount > 0 && errors.numberCount}
            />
            {!isNew && (
              <small>
                ({pool.number_assignable_count} {translateText('label', 'Assignable').toLowerCase()}
                )
              </small>
            )}
            <span> x </span>
            <TextField
              name="netNumber"
              value={values.netNumber}
              onChange={handleChange}
              canEdit={isAdmin}
              className={initialValues.netNumber !== values.netNumber ? 'changed' : ''}
              error={submitCount > 0 && errors.netNumber}
            />
            {!isNew && (
              <div className="show-numbers" onClick={() => setShowNumbers(!showNumbers)}>
                {showNumbers
                  ? translateText('label', 'Hide numbers')
                  : translateText('label', 'Show numbers')}
              </div>
            )}
            {showNumbers && (
              <div className="numbers">
                {pool.numbers.map((number, index) => {
                  let className = '';
                  if (number.is_assignable) className += 'assignable';
                  if (number.is_fallback) className += ' fallback';
                  return (
                    <div key={index} className={className}>
                      {number.number}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {isAdmin && (
            <div id={'footer-' + pool.id} className="footer">
              {!isNew && (
                <>
                  <Formik
                    initialValues={{ numbers: '', destination: '' }}
                    onSubmit={saveNumbers}
                    validationSchema={numbersValidationSchema}
                  >
                    {({ values, handleChange, submitForm, errors, submitCount, isSubmitting }) => (
                      <>
                        <ErrorFocus element={document.getElementById('footer-' + pool.id)} />
                        <TextField
                          name="numbers"
                          placeholder="1,4-9"
                          value={values.numbers}
                          onChange={handleChange}
                          error={submitCount > 0 && errors.numbers}
                        />
                        {pool.number_count === 0 && (
                          <TextField
                            name="destination"
                            placeholder={translateText('label', 'Destination')}
                            value={values.destination}
                            onChange={handleChange}
                            error={submitCount > 0 && errors.destination}
                          />
                        )}
                        <button
                          className="btn btn-lightblue"
                          onClick={submitForm}
                          disabled={isSubmitting}
                        >
                          {translateText('label', 'Add numbers')}
                        </button>
                      </>
                    )}
                  </Formik>
                  <button
                    className="btn btn-purple"
                    onClick={() => deletePool(pool.id as number)}
                    disabled={isDeleting}
                  >
                    {translateText('label', 'Delete')}
                  </button>
                  <button
                    className="btn btn-lightblue"
                    onClick={() => putBackToStock(pool.id as number)}
                    disabled={isPuttingBackToStock}
                  >
                    {translateText('label', 'Back to stock')}
                  </button>
                </>
              )}
              <button
                className="btn btn-green"
                disabled={isSubmitting || !dirty}
                onClick={submitForm}
              >
                {translateText('label', 'Save')}
              </button>
            </div>
          )}
        </div>
      )}
    </Formik>
  );
}
