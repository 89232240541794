import QueryTable from 'components/table/QueryTable';
import { useTranslate } from 'hooks/translate';

export default function StopLogs() {
  const translateText = useTranslate();
  return (
    <>
      <h1>{translateText('label', 'Log phone numbers')}</h1>
      <QueryTable
        tableKey={['log', 'numbers', 'stopped']}
        className="wrap"
        columns={{
          number_id: {
            header: { name: translateText('label', 'Number ID'), sort: true },
            defaultSelected: true,
            search: true,
            noNumberFormat: true,
          },
          domain_id: {
            header: { name: translateText('label', 'Domain ID'), sort: true },
            defaultSelected: true,
            search: true,
            noNumberFormat: true,
          },
          domain_title: {
            header: { name: translateText('label', 'Domain title'), sort: true },
            defaultSelected: true,
            search: true,
          },
          number: {
            header: { name: translateText('label', 'Number'), sort: true },
            defaultSelected: true,
            search: true,
            noNumberFormat: true,
          },
          destination: {
            header: { name: translateText('label', 'Destination'), sort: true },
            defaultSelected: true,
            search: true,
            noNumberFormat: true,
          },
          is_fallback: {
            header: { name: translateText('label', 'Fallback'), sort: true },
            defaultSelected: true,
            search: { 0: translateText('label', 'No'), 1: translateText('label', 'Yes') },
          },
          sip_trunk: {
            header: { name: translateText('label', 'SIP trunk'), sort: true },
            defaultSelected: true,
          },
          sources: {
            header: { name: translateText('label', 'Sources'), sort: true },
            defaultSelected: true,
            search: true,
          },
          location: {
            header: { name: translateText('label', 'Location'), sort: true },
            defaultSelected: true,
            search: true,
          },
          assignments: {
            header: { name: translateText('label', 'Assignments'), sort: true },
            defaultSelected: true,
          },
          calls: {
            header: { name: translateText('label', 'Calls'), sort: true },
            defaultSelected: true,
          },
          created_on: {
            header: { name: translateText('label', 'Created on'), sort: true },
            defaultSelected: true,
            date: true,
            search: true,
          },
          stopped_on: {
            header: { name: translateText('label', 'Stopped on'), sort: true },
            defaultSelected: true,
            date: { excludeTime: true },
            search: true,
          },
        }}
        endpoint="/stopped-number/index"
        filters={{ domain: false }}
        pagination
        refresh
        canChooseColumns
      />
    </>
  );
}
