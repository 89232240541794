import { useState } from 'react';
import { Formik, FormikHelpers, useFormikContext } from 'formik';
import { uniq } from 'lodash';
import * as Yup from 'yup';

import CreateDeleteTable from 'components/CreateDeleteTable';
import ErrorFocus from 'components/ErrorFocus';
import Select from 'components/input/Select';
import TextField from 'components/input/TextField';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import SetupWithSub from 'components/slide-in/SetupWithSub';
import SupportParagraph from 'components/SupportParagraph';
import {
  FREQUENCY_DAILY,
  FREQUENCY_MONTHLY,
  FREQUENCY_OFF,
  FREQUENCY_WEEKLY,
} from 'globals/constants';
import { SUP_ART_PERIODIC_REPORTS } from 'globals/support';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation } from 'hooks/validation';
import { ReportEmailSettings } from 'pages/authenticated/call-tracking/config/SendReports';

type Props = {
  settingsKey: 'history' | 'prestation' | 'reports';
  show: boolean;
  title: string;
  close: () => void;
};

type FormValues = {
  email: string;
};

export default function SetupSendReport({ settingsKey, show, title, close }: Props) {
  const translateText = useTranslate();
  const { multipleEmail } = useCustomValidation();
  const { submitForm, values, errors, isSubmitting, resetForm, dirty, setFieldValue, submitCount } =
    useFormikContext<ReportEmailSettings>();
  const [showSub, setShowSub] = useState(false);

  const validationSchema = Yup.object({
    email: multipleEmail.required(translateText('message', 'This field is required.')).test({
      test: function (value) {
        const firstDoubleEmail = (value ?? '')
          .split(',')
          .map(v => v.trim().toLowerCase())
          .find(v => values[settingsKey].emailAddresses.includes(v));
        return firstDoubleEmail
          ? this.createError({
              message: translateText(
                'message',
                '<span data-private>{email}</span> is already in use.',
                { email: firstDoubleEmail },
              ),
            })
          : true;
      },
    }),
  });

  function addEmailAddress(subValues: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    setFieldValue(settingsKey, {
      ...values[settingsKey],
      emailAddresses: uniq([
        ...values[settingsKey].emailAddresses,
        ...subValues.email.split(',').map(e => e.trim()),
      ]),
    });
    setShowSub(false);
    formikHelpers.resetForm();
  }

  return (
    <Formik // This form is for the sub setup
      initialValues={{ email: '' }}
      onSubmit={addEmailAddress}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({
        values: subValues,
        handleChange,
        touched,
        errors: subErrors,
        submitForm: submitSub,
        handleBlur,
        resetForm: resetSub,
      }) => (
        <SetupWithSub
          show={show}
          title={title}
          save={submitForm}
          isSaving={isSubmitting}
          close={close}
          afterClose={resetForm}
          maxWidth={365}
          hasChanges={dirty}
          sub={
            <Setup
              show={showSub}
              close={() => setShowSub(false)}
              afterClose={resetSub}
              maxWidth={315}
              save={submitSub}
              saveButtonText={translateText('label', 'Add')}
            >
              <p>
                {translateText(
                  'text',
                  'Enter the desired email addresses below. Multiple e-mail addresses must be entered with a comma.',
                )}
              </p>
              <InputWrapper label={translateText('label', 'Email address(es)')}>
                <TextField
                  id="reports-email"
                  name="email"
                  value={subValues.email}
                  onChange={handleChange}
                  error={touched.email && subErrors.email}
                  onBlur={handleBlur}
                />
              </InputWrapper>
            </Setup>
          }
        >
          <ErrorFocus />
          <p>
            {translateText(
              'call-tracking',
              'Send a periodic report of your Call Tracking data. Depending on the period, the reports will be sent every first of the month or on Monday.',
            )}
          </p>
          <SupportParagraph articleId={SUP_ART_PERIODIC_REPORTS} />
          <div className="section">
            <InputWrapper label={translateText('label', 'Frequency report')}>
              <Select
                id="send-reports-dropdown"
                value={values[settingsKey].frequency}
                onChange={e =>
                  setFieldValue(settingsKey, {
                    ...values[settingsKey],
                    frequency: Number(e.target.value),
                  })
                }
                options={{
                  [FREQUENCY_OFF]: translateText('label', 'No report'),
                  [FREQUENCY_MONTHLY]: translateText('label', 'Monthly'),
                  [FREQUENCY_WEEKLY]: translateText('label', 'Weekly'),
                  [FREQUENCY_DAILY]: translateText('label', 'Daily'),
                }}
              />
            </InputWrapper>
            <InputWrapper label={translateText('label', 'Email address')}>
              <CreateDeleteTable
                dataKeys={['email']}
                privateKeys={['email']}
                onAdd={() => setShowSub(true)}
                items={values[settingsKey].emailAddresses.map(e => ({ email: e }))}
                setDeletedItems={items => setFieldValue(settingsKey + '.deletedEmails', items)}
                deletedItems={values[settingsKey].deletedEmails}
                id="email-addresses"
                error={
                  !!errors[settingsKey]?.emailAddresses &&
                  submitCount > 0 &&
                  (errors[settingsKey]!.emailAddresses as string)
                }
              />
            </InputWrapper>
          </div>
        </SetupWithSub>
      )}
    </Formik>
  );
}
