import { useNavigate } from 'react-router';
import moment from 'moment/moment';

import Skeleton from 'components/Skeleton';
import { useTranslate } from 'hooks/translate';

type Props = {
  isLoading: boolean;
  slug: string;
  image: string;
  createdOn: string;
  author: string;
  title: string;
  categoryState?: { categoryName: string | undefined; categorySlug: string | undefined };
};

export default function ImageArticle({
  isLoading,
  slug,
  image,
  createdOn,
  author,
  title,
  categoryState,
}: Props) {
  const translateText = useTranslate();
  const navigate = useNavigate();

  if (!isLoading && (!slug || !title)) return null;

  return (
    <div
      className="image-article-container"
      onClick={() =>
        !isLoading &&
        navigate(`/knowledge-center/article/${encodeURIComponent(slug)}`, {
          state: categoryState,
        })
      }
    >
      <div className="article-inner">
        {isLoading ? <Skeleton /> : <img src={image} alt="" />}
        <div className="article-preview-area">
          <div className="created-by">
            {isLoading ? (
              <Skeleton />
            ) : (
              <>
                {moment(createdOn, 'YYYY-MM-DD HH:mm:ss').format('ll') +
                  ' ' +
                  translateText('label', 'By').toLowerCase()}
                <span className="author"> {author}</span>
              </>
            )}
          </div>
          <h3 className="top-title">{isLoading ? <Skeleton /> : title}</h3>
          {isLoading ? (
            <Skeleton height={38} />
          ) : (
            <button className="btn btn-yellow">{translateText('label', 'Read more')}</button>
          )}
        </div>
      </div>
    </div>
  );
}
