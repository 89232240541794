import { Formik, FormikHelpers } from 'formik';

import ErrorFocus from 'components/ErrorFocus';
import TextField from 'components/input/TextField';
import PasswordValidator from 'components/PasswordValidator';
import TermsAndConditions from 'components/TermsAndConditions';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation, useValidationSchema } from 'hooks/validation';

export type FormValues = {
  company: string;
  firstName: string;
  lastName: string;
  phonenumber: string;
  email: string;
  password: string;
  passwordVerification: string;
  conditionsPassed: boolean;
  termsAndConditions: boolean;
  agreement: boolean;
};

type Props = {
  save: (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => void;
  title: string;
  intro: JSX.Element;
  submitLabel: string;
  excludeEmail?: boolean;
};

export default function RegistrationForm({ save, title, intro, submitLabel, excludeEmail }: Props) {
  const translateText = useTranslate();
  const { email, required, requiredCheckbox } = useCustomValidation();
  const { passwordValidation } = useValidationSchema();

  return (
    <Formik<FormValues>
      initialValues={{
        company: '',
        firstName: '',
        lastName: '',
        phonenumber: '',
        email: '',
        password: '',
        passwordVerification: '',
        conditionsPassed: false,
        termsAndConditions: false,
        agreement: false,
      }}
      validationSchema={passwordValidation.shape({
        company: required,
        firstName: required,
        lastName: required,
        phonenumber: required,
        termsAndConditions: requiredCheckbox,
        agreement: requiredCheckbox,
        ...(!excludeEmail && { email }),
      })}
      onSubmit={save}
    >
      {({
        values,
        handleChange,
        setFieldValue,
        handleSubmit,
        errors,
        isSubmitting,
        submitCount,
      }) => (
        <form onSubmit={handleSubmit}>
          <div id="request-account-info">
            <h2 className="bold center">{title}</h2>
            {intro}
          </div>
          <ErrorFocus />
          <TextField
            name="company"
            placeholder={translateText('label', 'Company name')}
            onChange={handleChange}
            value={values.company}
            error={submitCount > 0 && errors.company}
            disabled={isSubmitting}
          />
          <TextField
            name="firstName"
            placeholder={translateText('label', 'First name')}
            onChange={handleChange}
            value={values.firstName}
            error={submitCount > 0 && errors.firstName}
            disabled={isSubmitting}
          />
          <TextField
            name="lastName"
            placeholder={translateText('label', 'Last name')}
            onChange={handleChange}
            value={values.lastName}
            error={submitCount > 0 && errors.lastName}
            disabled={isSubmitting}
          />
          <TextField
            name="phonenumber"
            placeholder={translateText('label', 'Phone number')}
            onChange={handleChange}
            value={values.phonenumber}
            error={submitCount > 0 && errors.phonenumber}
            disabled={isSubmitting}
          />
          {!excludeEmail && (
            <TextField
              name="email"
              placeholder={translateText('label', 'Email address')}
              onChange={handleChange}
              value={values.email}
              error={submitCount > 0 && errors.email}
              disabled={isSubmitting}
            />
          )}
          <PasswordValidator
            password={values.password}
            passwordVerification={values.passwordVerification}
            setPassword={value => setFieldValue('password', value)}
            setPasswordVerification={value => setFieldValue('passwordVerification', value)}
            setConditionsPassed={value => setFieldValue('conditionsPassed', value)}
            error={submitCount > 0 && (errors.conditionsPassed ?? errors.password)}
            placeholder={translateText('label', 'Password')}
            disabled={isSubmitting}
          />
          <TermsAndConditions
            termsAndConditions={{
              value: values.termsAndConditions,
              setValue: value => setFieldValue('termsAndConditions', value),
              error: submitCount > 0 && errors.termsAndConditions,
            }}
            agreement={{
              value: values.agreement,
              setValue: value => setFieldValue('agreement', value),
              error: submitCount > 0 && errors.agreement,
            }}
            className="margin-top"
            disabled={isSubmitting}
          />
          <button
            type="submit"
            className="btn btn-green btn-wide margin-top"
            disabled={isSubmitting}
          >
            {submitLabel}
          </button>
        </form>
      )}
    </Formik>
  );
}
