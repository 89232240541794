import { MouseEventHandler } from 'react';

import { ReactComponent as Plus } from 'assets/images/icons/plus.svg';
import Skeleton from 'components/Skeleton';
import Tooltip from 'components/Tooltip';
import { useHasManagerAccess } from 'hooks/access';
import { useTranslate } from 'hooks/translate';

type Props = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  className?: string;
  tooltipText?: string | null;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right' | 'top-start' | 'bottom-start';
  isLoading?: boolean;
  itemName?: string; // Use this when the add button should be disabled without manager access, the item name will be used in the hover message
};

export default function AddButton({
  onClick,
  disabled,
  className,
  tooltipText,
  tooltipPlacement,
  isLoading,
  itemName,
}: Props) {
  const translateText = useTranslate();
  const hasManagerAccess = useHasManagerAccess();
  const hasAccess = !itemName || hasManagerAccess;

  let buttonClass = 'btn btn-green btn-add';
  if (disabled || !hasAccess) buttonClass += ' disabled';
  if (className) buttonClass += ' ' + className;

  if (isLoading) return <Skeleton width={35} height={35} className={buttonClass} />;

  let button = (
    <button className={buttonClass} onClick={onClick} disabled={!hasAccess || disabled}>
      <Plus />
    </button>
  );

  if (tooltipText || !hasAccess) {
    button = (
      <Tooltip
        text={
          tooltipText ??
          translateText(
            'text',
            'You cannot add {item}, because you do not have the correct permissions.',
            { item: itemName!.toLowerCase() },
          )
        }
        html={button}
        placement={tooltipPlacement}
      />
    );
  }

  return button;
}
