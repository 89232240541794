export default {
    "Add one or more conditions for the filter. You can add or exclude conditions.": "Voeg \u00e9\u00e9n of meerdere condities toe voor het filter. Je kunt condities toevoegen of uitsluiten.",
    "Additional costs are associated with the use of the {integration} integration at \u20ac{price},- per month.": "Aan de inzet van de {integration} integratie zijn extra kosten verbonden \u00e1 \u20ac{price},- per maand.",
    "Billy Grace provides marketing optimization software for ecommerce businesses. Using the data-driven attribution model, you can optimally use your marketing budget and increase the ROI of your campaigns.": "Billy Grace biedt marketingoptimalisatiesoftware voor e-commercebedrijven. Met behulp van het data-gedreven attributiemodel kun je jouw marketingbudget optimaal inzetten en de ROI van je campagnes verhogen.",
    "BlueConic is a platform for collecting customer data online based on which relevant personal communication can take place.": "BlueConic is een platform voor het online vergaren van klantdata op basis waarvan relevante persoonlijke communicatie kan plaatsvinden.",
    "Campaign Manager is DoubleClick's advertising platform for serving image and text ads across multiple channels. Using this AdCalls integration, you can report call conversions as Floodlight Activity in DoubleClick.": "Campaign Manager is het advertentieplatform van DoubleClick voor het serveren van beeld- en tekstadvertenties binnen meerdere kanalen. Met behulp van deze AdCalls-integratie kun je oproep-conversies als Floodlight Activity rapporteren in DoubleClick.",
    "Determine whether a new contact can be created if it is not yet known in {integration}.": "Bepaal of er een nieuw contact mag worden aangemaakt, wanneer deze nog niet bekend is in {integration}.",
    "Disable and enable the sending of calls to {integration} here.": "Schakel hier het versturen van de oproepen naar {integration} uit en in.",
    "Grant access to the {integration} account so that the calls can be processed as a conversion in {integration}.": "Verleen toegang tot het {integration}-account, zodat de oproepen verwerkt kunnen worden als conversie in {integration}.",
    "Grant access to the {integration} account so that the calls can be processed in {integration}.": "Verleen toegang tot het {integration}-account, zodat de oproepen verwerkt kunnen worden in {integration}.",
    "Here you can set extra headers in case your endpoint requires them.": "Hier kun je extra headers instellen indien jouw endpoint deze verwacht.",
    "HubSpot is a tool for managing sales, marketing and customer service activities. With the help of this integration, you can enrich the contact details in HubSpot with data from incoming calls.": "HubSpot is een tool voor het beheren van sales-, marketing- en customer service-activiteiten. Met behulp van deze integratie kun je de contactgegevens in HubSpot verrijken met gegevens van inkomende oproepen.",
    "I want to use the integration with {integration}": "Ik wil gebruik maken van de integratie met {integration}",
    "LEF lead management system for fast lead qualification and follow-up. LEF ensures that all leads are automatically qualified with the right deadline, objective and call script, and end up with the right people.": "LEF zorgt ervoor dat alle leads automatisch gekwalificeerd worden met de juiste deadline, doelstelling en belscript en bij de juiste personen terechtkomen.",
    "Looker Studio is Google's free reporting tool. Using this 'connector' you can report the Call Tracking data from AdCalls in Looker Studio. All call data of the domains you have access to is available.": "Looker Studio is d\u00e9 gratis rapportage-tool van Google. Met behulp van deze 'connector' kun je de Call Tracking-data van AdCalls rapporteren in Looker Studio. Alle oproep-data van de domeinen waar je toegang toe hebt is beschikbaar.",
    "Microsoft Dynamics is a line of enterprise resource planning (ERP) and customer relationship management (CRM) applications developed by Microsoft. With the help of this integration you can enrich the contact details in Microsoft Dynamics with the data of incoming calls.": "Microsoft Dynamics is een lijn van enterprise resource planning- (ERP) en customer relationship management (CRM)-applicaties die ontwikkeld is door Microsoft. Met behulp van deze integratie kun je de contactgegevens in Microsoft Dynamics verrijken met de gegevens van inkomende oproepen.",
    "Pipedrive is a CRM tool that helps you manage leads and deals. Using this integration you can enrich the leads in Pipedrive with the data from incoming calls.": "Pipedrive is een CRM-tool waarmee je leads en deals kunt beheren. Met behulp van deze integratie kun je de leads in Pipedrive verrijken met de gegevens van inkomende oproepen.",
    "Process your calls in Google Analytics, so you get a complete overview of both your web and phone conversions.": "Verwerk je oproepen in Google Analytics, zodat je een volledig overzicht krijgt over zowel je web- als telefonische conversies.",
    "Process your calls in Meta Ads so you can optimize your campaigns based on call conversions.": "Verwerk je oproepen in Meta Ads, zodat je je campagnes kunt optimaliseren op basis van oproepconversies.",
    "Process your calls in Microsoft Advertising so you can optimize your campaigns down to the keyword level.": "Verwerk je oproepen in Microsoft Advertising, zodat je je campagnes tot op zoekwoordniveau kunt optimaliseren.",
    "Relay42 is a data and tag management platform for compiling online customer profiles which can be used to carry out marketing actions.": "Relay42 is een data- en tag-management platform voor het samenstellen van online klantprofielen op basis waarvan marketingacties kunnen worden verricht.",
    "Salesforce is a cloud based platform for customer relationship management (CRM). With the help of this integration you can enrich contact profiles in Salesforce with data of incoming calls.": "Salesforce is een cloud gebaseerd platform voor customer relationship management (CRM). Met behulp van deze integratie kun je de contactgegevens in Salesforce verrijken met de gegevens van inkomende oproepen.",
    "Set up a webhook to send call or call request data to an endpoint.": "Stel een webhook in om de data van een oproep of terugbelverzoek te versturen naar een endpoint.",
    "Switch to Enterprise to use these integrations. Contact us at support@adcalls.nl for more information.": "Stap over naar Enterprise om gebruik te maken van deze integraties. Neem hiervoor contact met ons op via support@adcalls.nl.",
    "The Adform platform provides access to all major Dutch ad exchanges and networks, such as Improve Digital, AppNexus, Google Display Network, YouTube and more. With the help of this integration you can measure call conversions in Adform.": "Het platform van Adform geeft toegang tot alle grote Nederlandse ad-exchanges en netwerken, zoals Improve Digital, AppNexus, Google Display Network, YouTube en meer. Met behulp van deze integratie kun je oproep-conversies meten in Adform.",
    "There are additional costs associated with the use of the {integration} integration at \u20ac{price},- per month.": "Aan de inzet van de {integration} integratie zijn extra kosten verbonden \u00e1 \u20ac{price},- per maand.",
    "These integrations are not available within the Light package. To be able to use these integrations, you must switch to a Basic package. Please contact our support via support@adcalls.nl.": "Deze integraties zijn niet beschikbaar binnen het Light-pakket. Om toch gebruik te kunnen maken van deze integraties, dien je over te stappen naar een Basis-pakket. Neem hiervoor contact op met onze support via support@adcalls.nl.",
    "This integration is available at no cost in the Enterprise package.": "Deze integratie is kosteloos beschikbaar in het Enterprise-pakket.",
    "This integration is available at no cost in the Optimize and Enterprise packages.": "Deze integratie is kosteloos beschikbaar in het Optimize- en Enterprise-pakket.",
    "This integration is available in the Enterprise package. There are additional costs associated with the use of the {integration} integration at \u20ac{price},- per month.": "Deze integratie is beschikbaar in het Enterprise-pakket. Aan de inzet van de {integration} integratie zijn extra kosten verbonden \u00e1 \u20ac{price},- per maand.",
    "This integration is available in the Optimize and Enterprise packages. There are additional costs associated with the use of the {integration} integration at \u20ac{price},- per month.": "Deze integratie is beschikbaar in het Optimize- en Enterprise-pakket. Aan de inzet van de {integration} integratie zijn extra kosten verbonden \u00e1 \u20ac{price},- per maand.",
    "To link your Microsoft Advertising account, you must have a Microsoft account with the \"Super Admin\" role.": "Voor het koppelen van je Microsoft Advertising-account dien je te beschikken over een Microsoft-account met de rol \u2018Super Admin\u2019.",
    "To set up the integration with {integration}, you can contact our support using the form below.": "Voor het tot stand brengen van de integratie met {integration}, kun je contact opnemen met onze support via onderstaand formulier.",
    "Upgrade to Enterprise": "Upgrade naar Enterprise",
    "Using Enhanced Conversions for Google Ads, you can improve conversion measurements by using additional customer data, leading to more accurate data and better insight into the customer journey. Compared to classic conversion measurement, this integration offers a solution to limitations of cookies and tracking due to privacy considerations.": "Met behulp van Enhanced Conversions voor Google Ads verbeter je de conversiemetingen door aanvullende klantgegevens te gebruiken, wat leidt tot nauwkeurigere gegevens en beter inzicht in de klantreis. Ten opzichte van de klassieke conversiemeting, biedt deze integratie uitkomst bij beperkingen van cookies en tracking in verband met privacyoverwegingen.",
    "Using the AdCalls API you can make Call Tracking data available in external systems, including Analytics, Advertising and CRM software.": "Met behulp van de AdCalls API kun je Call Tracking-data beschikbaar maken in externe systemen, waaronder Analytics-, Advertising- en CRM-software.",
    "Using this functionality, the data of a call is sent to {integration}, so that you can read them in the notes field of the activity in {integration}. In the support article you can read what information is sent.": "Met behulp van deze functionaliteit worden de kenmerken van een oproep meegestuurd naar HubSpot, zodat je deze kunt uitlezen in het notitieveld van de activiteit in HubSpot. In het supportartikel lees je welke informatie er meegestuurd wordt.",
    "Visual Website Optimizer (VWO) is a powerful software tool designed to make website optimization and A\/B testing accessible to marketers and website owners. VWO allows users to enhance their websites and digital experiences through a range of features and tools.": "Visual Website Optimizer (VWO) is een krachtige softwaretool die is ontworpen om website-optimalisatie en A\/B-testen toegankelijk te maken voor marketeers en website-eigenaren. Met VWO kunnen gebruikers hun websites en digitale ervaringen verbeteren door middel van een reeks functies en tools.",
    "With the form below you can send data of a call (request) to an endpoint, first we send a request to test the connection.": "Met het onderstaande formulier kun je de gewenste endpoint instellen. Voordat je deze kunt instellen, versturen wij eerst een testverzoek om de connectie te testen.",
    "With the help of this app you can integrate call-conversions in Google Display & Video 360 (DV360).": "Met behulp van deze app kun je oproep-conversies integreren in Google Display & Video 360 (DV360).",
    "With the help of this app you can integrate call-conversions in Google Search Ads 360.": "Met behulp van deze app kun je oproep-conversies integreren in Google Search Ads 360.",
    "With the help of this functionality, a conversion action is automatically created in Google Ads.": "Met behulp van deze functionaliteit wordt er geautomatiseerd een conversieactie aangemaakt in Google Ads.",
    "With this integration you can add AdCalls as a data source in BigQuery. This allows you to easily report, analyze and combine the data.": "Met behulp van deze integratie voeg je AdCalls toe als gegevensbron in BigQuery. Hierdoor kun je de data eenvoudig rapporteren, analyseren en combineren.",
    "With this integration you can directly place the JavaScript of AdCalls and publish it in Google Tag Manager.": "Met behulp van deze integratie kun je het JavaScript van AdCalls direct plaatsen en publiceren in Google Tag Manager.",
    "With this integration, you can process calls in Google Ads, so you can optimize your campaigns down to the keyword level.": "Met behulp van deze integratie verwerk je oproepen in Google Ads, zodat je je campagnes tot op zoekwoordniveau kunt optimaliseren.",
    "With this integration, you can use incoming calls as offline conversion data for A\/B testing.": "Met behulp van deze integratie kun je inkomende oproepen gebruiken als offline conversiedata voor A\/B-tests.",
    "Zoho is a complete CRM platform. With the help of this integration, you can enrich the contact information in Zoho with the data of incoming calls.": "Zoho is een compleet CRM platform. Met behulp van deze integratie kun je de contactgegevens in Zoho verrijken met de gegevens van inkomende oproepen.",
    "bGenius offers online marketers the opportunity to optimize and manage campaigns in different search engines in one system.": "bGenius biedt online marketeers de mogelijkheid campagnes in verschillende zoekmachines in \u00e9\u00e9n systeem te optimaliseren en te managen."
}