import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import iconApproved from 'assets/images/icons/table/icon-status-approved.svg';
import iconCancelled from 'assets/images/icons/table/icon-status-cancelled.svg';
import QueryTable from 'components/table/QueryTable';
import { useGlobal } from 'contexts/Global';
import { useAnalyticsCodes } from 'hooks/content';
import { useTranslate } from 'hooks/translate';

export const ADMIN_CALL_HISTORY_EXPAND =
  'evaluation,marked,notes,aen,aen_name,is_sent_to_ga4,domain_id,is_sent_to_bing,is_sent_to_google,hangup_cause,dialed_time,call_time,voip_identifier' as const;

export default function CallHistory() {
  const { setShowReportsFilter } = useGlobal();
  const translateText = useTranslate();
  const navigate = useNavigate();
  const analyticsCodes = useAnalyticsCodes();

  useEffect(() => {
    setShowReportsFilter('admin');
    return () => setShowReportsFilter(false);
  }, [setShowReportsFilter]);

  return (
    <>
      <h1>{translateText('label', 'Call history')}</h1>
      <QueryTable
        tableKey={['callHistory', 'admin']}
        endpoint="/call"
        columns={{
          id: {
            header: { name: translateText('label', 'ID'), sort: true },
            search: true,
            defaultSelected: true,
          },
          domain: {
            header: { name: translateText('label', 'Domain'), sort: true },
            search: 'domain_id',
            defaultSelected: true,
          },
          domain_id: {
            header: { name: translateText('label', 'Domain ID'), sort: true },
            search: true,
            noNumberFormat: true,
          },
          is_missed: {
            header: { name: translateText('label', 'Status'), sort: true },
            defaultSelected: true,
            keyValues: {
              true: <img width="18" height="18" src={iconCancelled} alt="missed" />,
              false: <img width="18" height="18" src={iconApproved} alt="answered" />,
            },
          },
          time_called: {
            header: { name: translateText('label', 'Date'), sort: true },
            search: true,
            date: true,
            defaultSelected: true,
          },
          source: {
            header: { name: translateText('label', 'Traffic source'), sort: true },
            defaultSelected: true,
          },
          caller: {
            header: { name: translateText('label', 'Sender'), sort: true },
            search: true,
            keyValues: { 0: translateText('label', 'Anonymous') },
            defaultSelected: true,
            noNumberFormat: true,
          },
          number: {
            header: { name: translateText('label', 'AdCalls number'), sort: true },
            search: true,
            defaultSelected: true,
            noNumberFormat: true,
          },
          destination: {
            header: { name: translateText('label', 'Destination'), sort: true },
            search: true,
            noNumberFormat: true,
          },
          duration: {
            header: { name: translateText('label', 'Duration'), sort: true },
            search: true,
            defaultSelected: true,
          },
          hangup_cause: {
            header: { name: translateText('label', 'Hang up cause'), sort: true },
            search: true,
            defaultSelected: true,
            noNumberFormat: true,
          },
          dialed_time: {
            header: { name: translateText('label', 'Dialed time'), sort: true },
            search: true,
          },
          call_time: {
            header: { name: translateText('label', 'Call time'), sort: true },
            search: true,
          },
          voip_identifier: {
            header: { name: translateText('label', 'PBX-id'), sort: true },
            search: true,
          },
          is_sent_to_ga4: {
            header: translateText('label', 'Status Google Analytics 4'),
            keyValues: analyticsCodes,
          },
          is_sent_to_google: { header: translateText('label', 'Status Google Ads') },
          status_google_ads_ec: {
            header: translateText('label', 'Status Google Ads Enhanced Conversions'),
          },
          is_sent_to_bing: { header: translateText('label', 'Status Microsoft Advertising') },
        }}
        filters={{
          date: true,
          domain: false,
          custom: { expand: ADMIN_CALL_HISTORY_EXPAND },
        }}
        className="wrap"
        pagination={{ defaultLimit: 20 }}
        onRowClick={row => navigate('/admin/logs/call-history/' + row.id)}
        isResponsive
        canChooseColumns
        refresh
      />
    </>
  );
}
