export default {
    "A new caller is a caller whose sender has not previously called for that domain within the last 30 days.": "Een nieuwe beller is een beller waarvan de afzender binnen de afgelopen 30 dagen niet eerder heeft gebeld voor het betreffende domein.",
    "Here you can see the day and time frame (hour) of the week with the most calls received.": "Hier zie je de dag en het tijdskader (uur) van de week met de meeste ontvangen oproepen.",
    "Here you can see the day and time frame (hour) of the week with the most missed calls.": "Hier zie je de dag en het tijdskader (uur) van de week met de meeste gemiste oproepen.",
    "Select this option to show data that is not associated with a location.": "Selecteer deze optie om data te tonen die niet aan een locatie is gekoppeld.",
    "The average call duration of the total number of calls made.": "De gemiddelde gespreksduur van het totaal aantal gerealiseerde oproepen.",
    "The average duration of a successful call.": "De gemiddelde duur van een succesvolle oproep.",
    "The average number of call attempts required to establish a successful call between two parties.": "Het gemiddelde aantal belpogingen dat nodig is om een succesvolle gesprek tussen twee partijen te realiseren.",
    "The average time between submitting the call request and establishing a successful call.": "De gemiddelde tijd tussen het indienen van het terugbelverzoek en het tot stand brengen van een succesvolle oproep.",
    "The number of call request(s) that will soon be started based on the chosen settings.": "Het aantal verzoek(en) dat binnenkort zal worden aangeboden op basis van de gekozen instellingen.",
    "The pie chart shows the top 10 of the chosen options within the rating menu.": "Het cirkeldiagram toont de top 10 van de gekozen opties binnen het beoordelingsmenu.",
    "The pie chart shows the top 10 of the chosen options within the selection menu.": "Het cirkeldiagram toont de top 10 van de gekozen opties binnen het keuzemenu.",
    "The ratio between the number of calls and the number of sessions realized, expressed as a percentage. This looks at the number of calls processed in Google Analytics.": "De verhouding tussen het aantal oproepen en het gerealiseerde aantal sessies, uitgedrukt in een percentage. Hierbij wordt gekeken naar het aantal oproepen dat verwerkt is in Google Analytics.",
    "The total number of call requests for which a successful call has been made between both parties.": "Het totaal aantal terugbelverzoeken waarvoor een succesvolle oproep tussen beide partijen is gerealiseerd.",
    "The total number of call requests.": "Het totaal aantal terugbelverzoeken.",
    "The total number of calls from all call requests.": "Het totaal aantal oproepen van alle terugbelverzoeken.",
    "The total number of calls made.": "Het totaal aantal gerealiseerde oproepen.",
    "The total number of calls sent to Google Analytics based on the settings.": "Het totaal aantal oproepen dat op basis van de instellingen is verstuurd naar Google Analytics.",
    "The total number of missed calls.": "Het totaal aantal gemiste oproepen.",
    "The total number of realized calls.": "Het totaal aantal gerealiseerde oproepen.",
    "The total number of sessions. A session is the period in which a user is active on the website. By default, if a user is inactive for 30 minutes or more, new activity is attributed to a new session. If users leave the website and return within 30 minutes, this is counted as the original session.": "Het totaal aantal sessies. Een sessie is de periode waarin een gebruiker actief is op de website. Als een gebruiker gedurende 30 minuten of langer inactief is, wordt nieuwe activiteit standaard toegeschreven aan een nieuwe sessie. Als gebruikers de website verlaten en binnen 30 minuten terugkeren, wordt dit tot de oorspronkelijke sessie gerekend.",
    "The traffic source that led to the calls.": "De verkeersbron die geleid heeft tot de oproepen.",
    "This bar graph shows the number of received and missed calls per day of the week in a stack.": "Dit staafdiagram toont gestapeld het aantal ontvangen en gemiste oproepen per dag van de week.",
    "This bar graph shows the number of received and missed calls per hour of the day in a stack.": "Dit staafdiagram toont gestapeld het aantal ontvangen en gemiste oproepen per uur van de dag.",
    "This pie chart provides insight into the top 10 evaluations. You will see the number of calls per evaluation over the selected period.": "Dit cirkeldiagram geeft inzicht in de top 10 beoordelingen. Je ziet het aantal oproepen per beoordeling over de geselecteerde periode."
}