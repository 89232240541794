import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  NUMBERPOOL_STATUS_ACTIVE,
  NUMBERPOOL_STATUS_DISCONTINUE_ACCEPTED,
  NUMBERPOOL_STATUS_DISCONTINUE_REQUESTED,
  NUMBERPOOL_STATUS_INACTIVE,
  NUMBERPOOL_STATUS_PENDING,
  NUMBERPOOL_TRACK_TYPE_APP,
  NUMBERPOOL_TRACK_TYPE_OFFSITE,
  NUMBERPOOL_TRACK_TYPE_ONSITE,
  PoolFormValues,
} from 'components/admin/CampaignBuilderPool';
import { useMessages } from 'contexts/Messages';
import { useTenant } from 'contexts/Tenant';
import { useSetTableData } from 'hooks/data';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';

export type CampaignBuilderPool = {
  id: number | string;
  is_active:
    | typeof NUMBERPOOL_STATUS_INACTIVE
    | typeof NUMBERPOOL_STATUS_ACTIVE
    | typeof NUMBERPOOL_STATUS_DISCONTINUE_ACCEPTED
    | typeof NUMBERPOOL_STATUS_DISCONTINUE_REQUESTED
    | typeof NUMBERPOOL_STATUS_PENDING;
  is_offline:
    | typeof NUMBERPOOL_TRACK_TYPE_ONSITE
    | typeof NUMBERPOOL_TRACK_TYPE_OFFSITE
    | typeof NUMBERPOOL_TRACK_TYPE_APP;
  location_identifier: string | null;
  destination: string;
  number_count: number | null;
  number_assignable_count: number | null;
  net_number: string;
  numbers: {
    id: number;
    number: string;
    is_assignable: 0 | 1;
    is_fallback: 0 | 1;
  }[];
  conditions: {
    id: number;
    field: string;
    operand: string;
    value: string;
  }[];
};

export function useCampaignBuilderPools(params: Record<string, unknown> = {}) {
  const { fetchData } = useFetch();
  const { selectedDomain } = useTenant();

  const {
    data: response,
    isFetching,
    dataUpdatedAt,
    refetch,
  } = useQuery({
    queryKey: ['campaignBuilder', 'pools', selectedDomain, params],
    queryFn: (): Promise<{ data: CampaignBuilderPool[]; headers: Headers }> =>
      fetchData('/campaign-builder/index', {
        includeHeaders: true,
        queryParams: { domain_id: selectedDomain, expand: 'numbers,conditions', ...params },
      }),
    enabled: selectedDomain !== null,
  });

  return {
    pools: response?.data,
    isLoading: isFetching,
    pageCount: Number(response?.headers.get('x-pagination-page-count') ?? 1),
    itemCount: Number(response?.headers.get('x-pagination-total-count') ?? 0),
    lastUpdated: dataUpdatedAt,
    refetch,
  };
}

export function useUpdateAenTable() {
  const translateText = useTranslate();
  const queryClient = useQueryClient();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const { mutate, isPending } = useMutation({
    mutationFn: () =>
      fetchData('/campaign-builder/update-aens', {
        method: 'PUT',
        queryParams: { domain_id: selectedDomain },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['campaignBuilder', 'pools', selectedDomain] });
      addSuccessMessage(translateText('admin/text', 'AEN table updated'));
    },
  });

  return { updateAenTable: mutate, isLoading: isPending };
}

export function useAddNumbers() {
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync } = useMutation({
    mutationFn: ({
      id,
      numbers,
      destination,
    }: {
      id: number;
      numbers: string;
      destination: string | null;
    }) =>
      fetchData('/campaign-builder/add-numbers', {
        method: 'POST',
        queryParams: { numberpool_id: id },
        bodyParams: { numbers, ...(destination !== null && { destination }) },
      }),
    onSuccess: data => addSuccessMessage(data),
  });

  return { addNumbers: mutateAsync };
}

export function useDeletePool() {
  const setTableData = useSetTableData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const { mutate, isPending } = useMutation({
    mutationFn: (id: number) =>
      fetchData('/campaign-builder/delete', {
        method: 'DELETE',
        queryParams: { numberpool_id: id },
      }),
    onSuccess: (data, id) => {
      setTableData(['campaignBuilder', 'pools', selectedDomain], oldData =>
        oldData.map(np => (np.id === id ? { ...np, is_active: NUMBERPOOL_STATUS_INACTIVE } : np)),
      );
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  const deletePool = (id: number) => {
    if (
      window.confirm(
        translateText('admin/text', 'Are you sure you want to delete this {type}?', {
          type: translateText('label', 'Pool').toLowerCase(),
        }),
      )
    ) {
      mutate(id);
    }
  };

  return { deletePool, isDeleting: isPending };
}

export function useUpdatePool() {
  const setTableData = useSetTableData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const { mutateAsync } = useMutation({
    mutationFn: ({
      id,
      destination,
      location_identifier,
      offline,
      numberCount,
      netNumber,
      conditions,
    }: { id: number } & Partial<PoolFormValues>) =>
      fetchData('/campaign-builder/update', {
        method: 'PUT',
        queryParams: { expand: 'numbers,conditions', numberpool_id: id, domain_id: selectedDomain },
        bodyParams: {
          ...(destination !== undefined && { destination }),
          ...(location_identifier !== undefined && {
            location_identifier: location_identifier === '' ? null : location_identifier,
          }),
          ...(offline !== undefined && { is_offline: offline }),
          ...(numberCount !== undefined && { amount_of_numbers: numberCount }),
          ...(netNumber !== undefined && { net_number: netNumber }),
          ...(conditions !== undefined && { conditions }),
        },
      }),
    onSuccess: (data, variables) => {
      setTableData(['campaignBuilder', 'pools', selectedDomain], oldData =>
        oldData.map(np => (np.id === variables.id ? data : np)),
      );
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { updatePool: mutateAsync };
}

export function useCreatePool() {
  const setTableData = useSetTableData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const { mutateAsync } = useMutation({
    mutationFn: (values: { id: string } & PoolFormValues) =>
      fetchData('/campaign-builder/create', {
        method: 'POST',
        queryParams: { expand: 'numbers,conditions' },
        bodyParams: {
          destination: values.destination,
          is_offline: values.offline,
          amount_of_numbers: values.numberCount,
          net_number: values.netNumber,
          conditions: values.conditions,
          domain_id: selectedDomain,
          location_identifier:
            values.location_identifier === '' ? null : values.location_identifier,
        },
      }),
    onSuccess: (data, variables) => {
      setTableData(['campaignBuilder', 'pools', selectedDomain], oldData =>
        oldData.map(np => (np.id === variables.id ? data : np)),
      );
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { createPool: mutateAsync };
}
