import { useLocation } from 'react-router';

import {
  KNOWLEDGE_CENTER_TYPE_INSPIRATION,
  KNOWLEDGE_CENTER_TYPE_MARKETING,
  KNOWLEDGE_CENTER_TYPE_SUPPORT,
} from 'globals/constants';
import { CategorySlug, CategoryType, CategoryTypeString } from 'globals/types';
import { useTranslate } from './translate';

export function useKnowledgeCenterCheck(overview = false) {
  const location = useLocation();
  const url = overview ? '/knowledge-center/overview' : '/knowledge-center';
  return location.pathname.startsWith(url);
}

export function useCategoryTypeTranslations() {
  const translateText = useTranslate();
  return {
    [KNOWLEDGE_CENTER_TYPE_SUPPORT]: translateText('label', 'Support'),
    [KNOWLEDGE_CENTER_TYPE_INSPIRATION]: translateText('label', 'Inspiration'),
    [KNOWLEDGE_CENTER_TYPE_MARKETING]: translateText('label', 'Marketing'),
  };
}

export function useCategoryType(type: CategoryType | CategoryTypeString | CategorySlug | null) {
  const translations = useCategoryTypeTranslations();
  let categoryTypeId: CategoryType = null;
  let categoryTypeSlug: CategorySlug | null = null;
  let categoryTypeText = null;

  switch (type) {
    case KNOWLEDGE_CENTER_TYPE_SUPPORT:
    case String(KNOWLEDGE_CENTER_TYPE_SUPPORT):
    case 'support':
      categoryTypeId = KNOWLEDGE_CENTER_TYPE_SUPPORT;
      categoryTypeSlug = 'support';
      categoryTypeText = translations[KNOWLEDGE_CENTER_TYPE_SUPPORT];
      break;
    case KNOWLEDGE_CENTER_TYPE_INSPIRATION:
    case String(KNOWLEDGE_CENTER_TYPE_INSPIRATION):
    case 'inspiration':
      categoryTypeId = KNOWLEDGE_CENTER_TYPE_INSPIRATION;
      categoryTypeSlug = 'inspiration';
      categoryTypeText = translations[KNOWLEDGE_CENTER_TYPE_INSPIRATION];
      break;
    case KNOWLEDGE_CENTER_TYPE_MARKETING:
    case String(KNOWLEDGE_CENTER_TYPE_MARKETING):
    case 'marketing':
      categoryTypeId = KNOWLEDGE_CENTER_TYPE_MARKETING;
      categoryTypeSlug = 'marketing';
      categoryTypeText = translations[KNOWLEDGE_CENTER_TYPE_MARKETING];
      break;
  }

  return { categoryTypeId, categoryTypeSlug, categoryTypeText };
}
