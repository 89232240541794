import { useFormikContext } from 'formik';
import { includes } from 'lodash';

import reload from 'assets/images/icons/refresh-dark.svg';
import { ReactComponent as RightArrow } from 'assets/images/icons/right-arrow.svg';
import Select from 'components/input/Select';
import TextField from 'components/input/TextField';
import Skeleton from 'components/Skeleton';
import { STATUS_INVALID, STATUS_VALID, STATUSES_FINISHED, useDebugMode } from 'contexts/DebugMode';
import { useAcceptedUrls } from 'hooks/queries/domain';
import { DebugFormValues } from 'pages/authenticated/call-tracking/config/DebugMode';

export default function Controls() {
  const { values, setFieldValue, handleSubmit } = useFormikContext<DebugFormValues>();
  const { browserHistory, updateHistoryIndex, mainIframeRef, status, setStatus, initialize } =
    useDebugMode();
  const { acceptedUrls, isLoading } = useAcceptedUrls();

  function navigate(type: 'forward' | 'back') {
    const newIndex = browserHistory.index + (type === 'forward' ? 1 : -1);
    const newPage = browserHistory.history[newIndex];
    if (!newPage || !values.host) return;

    updateHistoryIndex(newIndex);
    setStatus(newPage.isValid ? STATUS_VALID : STATUS_INVALID);
    setFieldValue('page', newPage.page);
    initialize({ host: values.host, page: newPage.page, isVerified: true });
  }

  const disabled = !includes(STATUSES_FINISHED, status) || isLoading;
  return (
    <div className="controls">
      <div className="buttons">
        <button
          onClick={() => navigate('back')}
          disabled={disabled || browserHistory.index === 0}
          className="btn btn-lightblue"
        >
          <RightArrow className="mirror" aria-label="back" />
        </button>
        <button
          onClick={() => navigate('forward')}
          disabled={disabled || !(browserHistory.history.length - 1 > browserHistory.index)}
          className="btn btn-lightblue"
        >
          <RightArrow aria-label="forward" />
        </button>
        <button
          onClick={() => initialize(values)}
          disabled={disabled || !mainIframeRef?.current?.src}
          className="btn btn-lightblue"
        >
          <img alt="refresh" src={reload} className="reload" />
        </button>
      </div>
      <div className="url-bar">
        <div className="domain">
          {isLoading ? (
            <Skeleton width="180px" />
          ) : (
            <Select
              value={values.host ?? ''}
              onChange={e => {
                setFieldValue('host', e.target.value);
                setFieldValue('page', '/');
                initialize({ host: e.target.value, shouldClearHistory: true });
              }}
              options={acceptedUrls ?? []}
              canEdit={!!acceptedUrls && acceptedUrls.length > 1}
              disabled={disabled}
            />
          )}
          <span className="separator">/</span>
        </div>
        <form onSubmit={handleSubmit}>
          <TextField
            value={values.page.replace(/^(\/)/, '')}
            onChange={e => setFieldValue('page', '/' + e.target.value.replace(/^(\/)/, ''))}
            disabled={disabled}
            extraContent={
              <div className="go-button-wrapper">
                <button
                  className="btn btn-green go-button"
                  disabled={
                    disabled || values.page === browserHistory.history[browserHistory.index]?.page
                  }
                  type="submit"
                >
                  <RightArrow aria-label="go" />
                </button>
              </div>
            }
          />
        </form>
      </div>
    </div>
  );
}
