import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { NotificationFormValues } from 'components/admin/NotificationForm';
import { useFilters } from 'contexts/Filters';
import { useMessages } from 'contexts/Messages';
import { useSession } from 'contexts/Session';
import { useSetQueryData, useSetTableData, useTableData } from 'hooks/data';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';

export type Notification = {
  id: number;
  notification_id: number;
  domain_id: number | null;
  created_at: string;
  title: string;
  summary: string;
  message: string;
  category: string;
  category_id: number;
  is_critical: boolean;
  is_read: boolean;
  domain: string;
  translations: {
    notification_id: number;
    language_id: string;
    title: string;
    summary: string;
    message: string;
  }[];
};

export function useNotification(id: number | null = null) {
  const { fetchData } = useFetch();
  const { getAppliedFilters } = useFilters();
  const { bodyParams, queryParams } = getAppliedFilters(
    { domain: false },
    'notificationsIndex',
    true,
  );
  const notificationData = useTableData<Notification>([
    'notifications',
    'index',
    { ...bodyParams, ...queryParams },
  ]);

  const { data, isFetching, isError, error } = useQuery({
    queryKey: ['notification', id],
    queryFn: (): Promise<Notification> => fetchData('/notification/' + id),
    enabled: id !== null,
    meta: { noError: true },
    initialData: () => notificationData?.find(n => n.id === id),
  });

  return { notification: data, isLoading: isFetching, isError, error };
}

export function useUnreadNotifications() {
  const queryClient = useQueryClient();
  const { fetchData } = useFetch();
  const { token } = useSession();

  const {
    data,
    isPending: isLoading,
    refetch: refetchUnread,
  } = useQuery({
    queryKey: ['notifications', 'unread'],
    queryFn: (): Promise<Notification[]> =>
      fetchData('/notification/index', { queryParams: { read: 0 } }),
    enabled: !!token,
  });

  function refetch() {
    refetchUnread();
    queryClient.invalidateQueries({ queryKey: ['notifications', 'index'] });
  }

  return { notifications: data, isLoading, refetch };
}

export function useReadNotification() {
  const setQueryData = useSetQueryData();
  const setTableData = useSetTableData();
  const { fetchData } = useFetch();

  const { mutate } = useMutation({
    mutationFn: (id: number) =>
      fetchData('/notification/read', {
        method: 'POST',
        queryParams: { id },
      }),
    onSuccess: (data, id) => {
      setTableData(['notifications', 'index'], oldData =>
        oldData.map(i => (i?.id === id ? data : i)),
      );
      setQueryData<Notification>(['notification', id], oldData => ({
        ...oldData,
        is_read: true,
      }));
      setQueryData<Notification[]>(['notifications', 'unread'], oldData =>
        oldData.filter(i => i.id !== id),
      );
    },
  });

  return { markAsRead: mutate };
}

export function useAdminNotification(id: number | null = null) {
  const { fetchData } = useFetch();
  const notificationData = useTableData<Notification>(['notifications', 'admin']);

  const { data, isFetching } = useQuery({
    queryKey: ['notification', id],
    queryFn: (): Promise<Notification> =>
      fetchData('/notification/view-custom', { queryParams: { id, expand: 'translations' } }),
    initialData: () => notificationData?.find(n => n.id === id),
  });

  return { notification: data, isLoading: isFetching };
}

export function useSaveNotification() {
  const setTableData = useSetTableData();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const translateText = useTranslate();

  const { mutateAsync } = useMutation({
    mutationFn: (notification: NotificationFormValues) =>
      fetchData('/notification/create', {
        method: 'POST',
        bodyParams: notification,
      }),
    onSuccess: data => {
      setTableData(['notifications', 'admin'], (oldData: NotificationFormValues[]) => [
        ...oldData,
        { ...data, translations: [] },
      ]);
      addSuccessMessage(translateText('admin/text', 'The notification has been created.'));
    },
  });

  return { saveNotification: mutateAsync };
}

export function useUpdateNotification() {
  const setTableData = useSetTableData();
  const setQueryData = useSetQueryData();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const translateText = useTranslate();

  const { mutateAsync } = useMutation({
    mutationFn: ({ id, notification }: { id: number; notification: NotificationFormValues }) =>
      fetchData('/notification/update', {
        method: 'PUT',
        queryParams: { id },
        bodyParams: {
          title: notification.title,
          summary: notification.summary,
          message: notification.message,
        },
      }),
    onSuccess: data => {
      setTableData<NotificationFormValues>(['notifications', 'admin'], oldData =>
        oldData.map(notification =>
          notification.id === data.id ? { ...notification, ...data } : notification,
        ),
      );

      setQueryData<NotificationFormValues>(['notification', data.id], oldData => ({
        ...oldData,
        ...data,
      }));
      addSuccessMessage(translateText('admin/text', 'The notification has been saved.'));
    },
  });

  return { updateNotification: mutateAsync };
}

export function useAddTranslation() {
  const setQueryData = useSetQueryData();
  const { fetchData } = useFetch();
  const { addSuccessMessage, addErrorMessage } = useMessages();
  const translateText = useTranslate();

  const { mutateAsync } = useMutation({
    mutationFn: ({ id, notification }: { id: number; notification: NotificationFormValues }) =>
      fetchData('/notification/translation', {
        method: 'POST',
        queryParams: { id },
        bodyParams: notification,
      }),
    onSuccess: data => {
      setQueryData<NotificationFormValues>(['notification', data.notification_id], oldData => ({
        ...oldData,
        translations: [...(oldData.translations ?? []), data],
      }));
      addSuccessMessage(translateText('admin/text', 'The translation has been created.'));
    },
    onError: (data: { language_id: string[] }) => {
      addErrorMessage(data?.language_id[0] ?? data);
    },
    meta: { noError: true },
  });

  return { addTranslation: mutateAsync };
}

export function useUpdateTranlation() {
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const translateText = useTranslate();

  const { mutateAsync } = useMutation({
    mutationFn: ({ id, notification }: { id: number; notification: NotificationFormValues }) =>
      fetchData('/notification/update-translation', {
        method: 'PUT',
        queryParams: { id },
        bodyParams: notification,
      }),
    onSuccess: () =>
      addSuccessMessage(translateText('admin/text', 'The translation has been saved.')),
  });

  return { updateTranslation: mutateAsync };
}

export function useTrigger() {
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const translateText = useTranslate();

  const { mutateAsync } = useMutation({
    mutationFn: (id: number) =>
      fetchData('/notification/trigger', {
        method: 'POST',
        queryParams: { id },
        bodyParams: { global: true },
      }),
    onSuccess: () =>
      addSuccessMessage(
        translateText('admin/text', 'The notification has been triggered successfully.'),
      ),
  });

  return { triggerNotification: mutateAsync };
}
