import { useMutation } from '@tanstack/react-query';
import fileDownload from 'js-file-download';
import { isEmpty } from 'lodash';
import moment from 'moment';

import ExportButton from 'components/buttons/ExportButton';
import Skeleton from 'components/Skeleton';
import { useFilters } from 'contexts/Filters';
import { useFetch } from 'hooks/fetch';
import { useUserDateFormat } from 'hooks/user';
import { Download, Filters } from './QueryTable';

type Props = {
  method?: 'GET' | 'POST';
  endpoint: string;
  download?: Download;
  filters?: Filters;
  isLoading?: boolean;
  disabled?: boolean;
};

export default function DownloadButtons({
  method = 'POST',
  endpoint,
  download,
  filters,
  isLoading = false,
  disabled = false,
}: Props) {
  const { getFetchConfig } = useFetch();
  const { getAppliedFilters } = useFilters();
  const dateFormat = useUserDateFormat();

  const { mutate, isPending: isDownloading } = useMutation({
    mutationFn: async ({
      query,
      config,
    }: ReturnType<typeof getFetchConfig> & { name: string; mime: string }) => {
      const response = await fetch(query, config);
      if (response.ok) return await response.blob();
      throw await response.json();
    },
    onSuccess: async (data, variables) => fileDownload(data, variables.name, variables.mime),
  });

  function downloadData(extension: 'pdf' | 'csv') {
    const { bodyParams, queryParams } = getAppliedFilters({
      ...filters,
      custom: {
        ...filters?.custom,
        ...(typeof download !== 'string' && download?.filters),
      },
    });

    let mime = '';
    let downloadEndpoint = endpoint?.slice(1);
    switch (extension) {
      case 'csv': {
        const endpointChunks = downloadEndpoint?.split('/');
        endpointChunks?.splice(endpointChunks?.length - 1, 1, 'export');
        downloadEndpoint = endpointChunks?.join('/');
        mime = 'text/csv';
        break;
      }
      case 'pdf':
        mime = 'application/pdf';
        queryParams.pdf = 1;
        break;
    }

    const { query, config } = getFetchConfig('/' + downloadEndpoint, {
      method: method ?? (isEmpty(bodyParams) ? 'GET' : 'POST'),
      bodyParams,
      queryParams,
    });

    const name =
      typeof download !== 'string' && download?.name
        ? download.name
        : downloadEndpoint?.replace('/', '_');
    const date = moment().format(dateFormat);

    mutate({ query, config, name: name + '_' + date + '.' + extension, mime });
  }

  return (
    <>
      {(download === 'csv' || (typeof download === 'object' && download.csv)) && (
        <div className="download">
          {isLoading ? (
            <Skeleton width={25} height={25} isCircle />
          ) : (
            <ExportButton
              type="csv"
              onClick={() => downloadData('csv')}
              disabled={disabled || isDownloading}
            />
          )}
        </div>
      )}
      {(download === 'pdf' || (typeof download === 'object' && download.pdf)) && (
        <div className="download">
          {isLoading ? (
            <Skeleton width={25} height={25} isCircle />
          ) : (
            <ExportButton
              type="pdf"
              onClick={() => downloadData('pdf')}
              disabled={disabled || isDownloading}
            />
          )}
        </div>
      )}
    </>
  );
}
