import { Formik } from 'formik';

import { ReactComponent as Add } from 'assets/images/icons/add.svg';
import Select from 'components/input/Select';
import TextField from 'components/input/TextField';
import { useHasAdminAccess } from 'hooks/access';
import {
  useCreateSessionFilterCondition,
  useDeleteSessionFilterCondition,
  useUpdateSessionFilterCondition,
} from 'hooks/queries/session-filter-condition';
import {
  SessionFilterPool as Pool,
  useDeleteSessionFilterPool,
} from 'hooks/queries/session-filter-pool';
import { useTranslate } from 'hooks/translate';

const SESSION_FILTER_OPERANDS = {
  1: 'is equal to',
  2: 'does start with',
  3: 'does end with',
  4: 'does contain',
  5: "isn't equal to",
  6: "doesn't start with",
  7: "doesn't end with",
  8: "doesn't contain",
} as const;

const SESSION_FILTER_TYPES = {
  1: 'URL',
  2: 'Name of source',
} as const;

type Props = {
  pool: Pool;
};

export default function SessionFilterPool({ pool }: Props) {
  const translateText = useTranslate();
  const isAdmin = useHasAdminAccess();

  const { createCondition } = useCreateSessionFilterCondition();
  const { updateCondition } = useUpdateSessionFilterCondition();
  const { deleteCondition, isDeleting: isDeletingCondition } = useDeleteSessionFilterCondition();
  const { deletePool, isDeleting } = useDeleteSessionFilterPool();

  const operandOptions = Object.entries(SESSION_FILTER_OPERANDS).map(([key, operand]) => ({
    text: translateText('dynamic', operand),
    value: key,
  }));

  return (
    <div id={String(pool.id)} className="session-filter-pool white-block">
      <div className="header">
        <span>
          {translateText('label', 'Pool') + ': '}
          {pool.id}
        </span>
      </div>
      <div className="condition-section">
        <h3>{translateText('label', 'Session filter conditions')}</h3>
        <table>
          <tbody>
            {pool.conditions.length === 0 ? (
              <tr>
                <td className="no-data">{translateText('label', 'No conditions')}</td>
              </tr>
            ) : (
              pool.conditions.map((condition, index) => (
                <Formik
                  key={index}
                  initialValues={{
                    operand: String(condition.operator_const),
                    value: condition.value,
                  }}
                  onSubmit={async (values, formikHelpers) => {
                    await updateCondition({ poolId: pool.id, id: condition.id, ...values }).then(
                      () => formikHelpers.resetForm(),
                    );
                  }}
                  enableReinitialize
                >
                  {({ values, handleChange, submitForm, dirty, isSubmitting }) => (
                    <tr>
                      <td>
                        {translateText('dynamic', SESSION_FILTER_TYPES[condition.type_const])}
                      </td>
                      <td>
                        <Select
                          name="operand"
                          value={values.operand}
                          onChange={handleChange}
                          canEdit={isAdmin}
                          options={operandOptions}
                        />
                      </td>
                      <td>
                        <TextField
                          name="value"
                          value={values.value}
                          onChange={handleChange}
                          canEdit={isAdmin}
                        />
                      </td>
                      {isAdmin && (
                        <>
                          <td>
                            <button
                              className="btn btn-green"
                              onClick={submitForm}
                              disabled={!dirty || isSubmitting}
                            >
                              {translateText('label', 'Save')}
                            </button>
                          </td>
                          <td>
                            <button
                              className="btn btn-purple"
                              onClick={() => deleteCondition({ id: condition.id, poolId: pool.id })}
                              disabled={isDeletingCondition}
                            >
                              {translateText('label', 'Delete')}
                            </button>
                          </td>
                        </>
                      )}
                    </tr>
                  )}
                </Formik>
              ))
            )}
          </tbody>
        </table>
        {isAdmin && (
          <>
            <h3 className="margin-top">{translateText('label', 'New condition')}</h3>
            <Formik
              initialValues={{ type: '1', operand: '1', value: '' }}
              onSubmit={async (condition, formikHelpers) => {
                await createCondition({ poolId: pool.id, ...condition }).then(() =>
                  formikHelpers.resetForm(),
                );
              }}
            >
              {({ values, handleChange, submitForm, isSubmitting }) => (
                <div className="new-condition">
                  <Select
                    name="type"
                    value={values.type}
                    onChange={handleChange}
                    options={Object.entries(SESSION_FILTER_TYPES).map(([key, type]) => ({
                      text: translateText('dynamic', type),
                      value: key,
                    }))}
                  />
                  <Select
                    name="operand"
                    value={values.operand}
                    onChange={handleChange}
                    options={operandOptions}
                  />
                  <TextField name="value" value={values.value} onChange={handleChange} />
                  <Add className={'add' + (isSubmitting ? ' disabled' : '')} onClick={submitForm} />
                </div>
              )}
            </Formik>
          </>
        )}
      </div>
      {isAdmin && (
        <div className="footer">
          <button
            className="btn btn-purple "
            onClick={() => deletePool(pool.id)}
            disabled={isDeleting}
          >
            {translateText('label', 'Delete pool')}
          </button>
        </div>
      )}
    </div>
  );
}
