import { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';

import { TourSection, useGuidedTour } from 'contexts/GuidedTour';
import { useMessages } from 'contexts/Messages';
import { clearSession, useSession } from 'contexts/Session';
import { useTenant } from 'contexts/Tenant';
import { USER_STATUS_INACTIVE } from 'globals/constants';
import { Mcc, User } from 'globals/types';
import { useSetQueryData, useSetTableData, useTableData } from 'hooks/data';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';
import { AdminUser, UserStatus } from 'pages/authenticated/admin/Users';

type NotificationSettings = { category: number; type: number }[];

export function useUser() {
  const { fetchData } = useFetch();
  const { token } = useSession();

  const { data, isFetching, isError } = useQuery({
    queryKey: ['user', 'me'],
    queryFn: (): Promise<User> => fetchData('/user/me'),
    enabled: !!token,
    meta: { persist: true },
  });

  useEffect(() => {
    if (isError && token) clearSession();
  }, [isError, token]);

  return { user: data, isLoading: isFetching, isError };
}

export function useUserDomains() {
  const { fetchData } = useFetch();

  const {
    data,
    isPending: isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ['userDomains'],
    queryFn: (): Promise<{ id: string; title: string }[]> => fetchData('/user/domains'),
  });

  return { domains: data, isLoading, isError, error };
}

export function useUserMcc() {
  const { fetchData } = useFetch();
  const { token } = useSession();

  const { data, isFetching, isError, error } = useQuery({
    queryKey: ['userMcc'],
    queryFn: (): Promise<Mcc[]> => fetchData('/user/mcc'),
    enabled: !!token,
    meta: { persist: true },
  });

  return { mcc: data, isLoading: isFetching, isError, error };
}

export function useChosenColumns(table: string, canChooseColumns: boolean) {
  const setQueryData = useSetQueryData();
  const { fetchData } = useFetch();

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ['chosenColumns', table],
    queryFn: () => fetchData('/user/get-selected-columns', { queryParams: { table } }),
    enabled: canChooseColumns,
  });

  const { mutate } = useMutation({
    mutationFn: (columns: string[]) => {
      // TODO AC-4890 return array instead of object once auth service is in use
      const columnData: Record<string, boolean> = {};
      columns.forEach(i => (columnData[i] = true));
      return fetchData('/user/set-selected-columns', {
        method: 'POST',
        bodyParams: { table, selected_columns: columnData },
      });
    },
    onSuccess: data => setQueryData(['chosenColumns', table], () => data),
  });

  // TODO AC-4890 return array instead of object once auth service is in use
  return {
    chosenColumns: data ? Object.keys(data).filter(i => data[i]) : data,
    mutateChosenColumns: mutate,
    isLoading,
  };
}

export function useUpdatesAndRecommendations() {
  const { fetchData } = useFetch();
  const { selectedDomain } = useTenant();

  const { data, isPending: isLoading } = useQuery({
    queryKey: ['user', 'updatesAndRecommendations', selectedDomain],
    queryFn: () =>
      fetchData('/user/updates-and-recommendations', {
        queryParams: { domain_id: selectedDomain },
      }),
    enabled: selectedDomain !== null,
  });

  return { data, isLoading };
}

export function useNotificationSettings() {
  const { fetchData } = useFetch();

  const { data, isFetching } = useQuery({
    queryKey: ['user', 'notificationSettings'],
    queryFn: (): Promise<NotificationSettings> =>
      fetchData('/v4/user/get-notification-settings', {
        queryParams: { expand: 'tooltip' },
      }),
  });

  return { notificationSettings: data, isLoading: isFetching };
}

export function useDisplayTour() {
  const setQueryData = useSetQueryData();
  const { fetchData } = useFetch();
  const { token } = useSession();

  const { data } = useQuery({
    queryKey: ['user', 'displayTour'],
    queryFn: (): Promise<boolean> => fetchData('/user/display-tour'),
    enabled: !!token,
    meta: { persist: true },
  });

  const setDisplayTour = (displayTour: boolean) =>
    setQueryData<boolean>(['user', 'displayTour'], () => displayTour);

  return { displayTour: data, setDisplayTour };
}

export function useQuitTour() {
  const { fetchData } = useFetch();
  const { setDisplayTour } = useDisplayTour();
  const { setSection } = useGuidedTour();

  const { mutate: quitTour, isPending } = useMutation({
    mutationFn: () =>
      fetchData('/user/tour-options', {
        method: 'POST',
        bodyParams: { display_tour: false },
      }),
    onSuccess: () => {
      setSection(TourSection.Start);
      setDisplayTour(false);
    },
  });

  return { quitTour, isLoading: isPending };
}

export function useUpdateProfile() {
  const { fetchData } = useFetch();
  const setQueryData = useSetQueryData();

  const { mutateAsync: updateProfile } = useMutation({
    mutationFn: (profile: {
      firstName: string;
      lastName: string;
      phoneNumber: string;
      company: string;
      email: string;
    }) =>
      fetchData('/v4/user/update-profile', {
        method: 'PUT',
        bodyParams: {
          firstName: profile.firstName,
          lastName: profile.lastName,
          phonenumber: profile.phoneNumber,
          company: profile.company,
          email: profile.email,
        },
      }),
    onSuccess: (data, profile) => {
      setQueryData<User>(['user', 'me'], oldData => ({
        ...oldData,
        first_name: profile.firstName,
        last_name: profile.lastName,
        phonenumber: profile.phoneNumber,
        company: profile.company,
        email: profile.email,
      }));
    },
  });

  return { updateProfile };
}

export function useUpdateLanguage(updateUser = false) {
  const { fetchData } = useFetch();
  const queryClient = useQueryClient();

  const { mutateAsync: updateLanguage } = useMutation({
    mutationKey: ['user', 'language'],
    mutationFn: ({ language, token }: { language: string; token?: string }): Promise<User> => {
      const config = token ? { headers: { Authorization: 'Bearer ' + token } } : {};
      return fetchData('/user/set-language', {
        method: 'POST',
        bodyParams: { language_id: language },
        config,
      });
    },
    onSuccess: data =>
      queryClient.setQueryData<User>(['user', 'me'], oldData =>
        updateUser || !oldData
          ? data
          : {
              ...oldData,
              language: data.language,
            },
      ),
  });

  return { updateLanguage };
}

export function useUpdateDateFormat() {
  const { fetchData } = useFetch();
  const setQueryData = useSetQueryData();

  const { mutateAsync: updateDateFormat } = useMutation({
    mutationFn: (dateFormat: string) =>
      fetchData('/user/set-date-settings', {
        method: 'PUT',
        bodyParams: { date_format: dateFormat },
      }),
    onSuccess: (data, dateFormat) => {
      setQueryData<User>(['user', 'me'], oldData => ({
        ...oldData,
        date_format: dateFormat,
      }));
    },
  });

  return { updateDateFormat };
}

export function useUpdateNotificationSettings() {
  const { fetchData } = useFetch();
  const setQueryData = useSetQueryData();

  const { mutateAsync: updateNotificationSettings } = useMutation({
    mutationFn: (settings: Record<string, number>) =>
      fetchData('/v4/user/set-notification-settings', {
        method: 'PUT',
        bodyParams: { settings },
      }),
    onSuccess: (data, settings) => {
      setQueryData<NotificationSettings>(['user', 'notificationSettings'], oldData => {
        const newData = cloneDeep(oldData);
        Object.keys(settings).forEach(s => {
          const item = newData.find(i => i.category === Number(s));
          if (item) item.type = settings[s];
        });
        return newData;
      });
    },
  });

  return { updateNotificationSettings };
}

export function useChangePassword() {
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const translateText = useTranslate();

  const { mutateAsync: changePassword } = useMutation({
    mutationFn: (values: {
      password: string;
      newPassword: string;
      newPasswordVerification: string;
    }) =>
      fetchData('/v4/user/change-password', {
        method: 'PUT',
        bodyParams: {
          password: values.password,
          newPassword: values.newPassword,
          verifyNewPassword: values.newPasswordVerification,
        },
      }),
    meta: { noError: true },
    onSuccess: () => {
      addSuccessMessage(
        translateText(
          'message',
          'Your password has been changed. You can now login with your new password.',
        ),
      );
    },
  });

  return { changePassword };
}

export function useUpdatePassword() {
  const { fetchData } = useFetch();
  const { addSuccessMessage, addErrorMessage } = useMessages();
  const translateText = useTranslate();

  const { mutateAsync } = useMutation({
    mutationFn: (values: {
      email: string;
      password: string;
      newPassword: string;
      newPasswordVerification: string;
    }) =>
      fetchData('/user/update-password', {
        method: 'PUT',
        bodyParams: {
          email: values.email,
          password: values.password,
          newPassword: values.newPassword,
          verifyNewPassword: values.newPasswordVerification,
        },
        includeStatus: true,
      }),
    meta: { noError: true },
    onSuccess: () => {
      addSuccessMessage(
        translateText(
          'message',
          'Your password has been changed. You can now login with your new password.',
        ),
      );
    },
    onError: (response: { status: number; error: string }) => {
      if (response.status === 401) {
        addErrorMessage(translateText('message', 'An invalid password has been entered.'));
      }
    },
  });

  return { updatePassword: mutateAsync };
}

export function useAccountManagers() {
  const { fetchData } = useFetch();

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ['accountManagers'],
    queryFn: (): Promise<Record<number, string>> => fetchData('/user/account-managers'),
  });

  return { accountManagers: data, isLoading };
}

export function useAdminUserDetails(id: number | null = null) {
  const { fetchData } = useFetch();
  const domainData = useTableData<AdminUser>(['users', 'admin']);

  const { data, isFetching } = useQuery({
    queryKey: ['adminUser', id],
    queryFn: (): Promise<AdminUser> =>
      fetchData('/user/view', { queryParams: { id, expand: 'reseller_label,link' } }),
    enabled: id !== null,
    meta: { noError: true },
    initialData: () => domainData?.find(u => u.id === id),
  });

  return { user: data, isLoading: isFetching };
}

export function useAdminUpdateUser(id: number) {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const setTableData = useSetTableData();
  const { addSuccessMessage } = useMessages();
  const { fetchData } = useFetch();

  const { mutateAsync } = useMutation({
    mutationFn: (values: { firstName: string; lastName: string; phone: string; company: string }) =>
      fetchData('/user/update', {
        method: 'PUT',
        queryParams: { id, expand: 'reseller_label,link' },
        bodyParams: {
          first_name: values.firstName,
          last_name: values.lastName,
          phonenumber: values.phone,
          company: values.company,
        },
      }),
    onSuccess: data => {
      queryClient.setQueryData(['adminUser', id], () => data);
      setTableData(['users', 'admin'], oldData => oldData.map(i => (i?.id === id ? data : i)));
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { updateUser: mutateAsync };
}

export function useUpdateStatus(id: number) {
  const { fetchData } = useFetch();
  const translateText = useTranslate();
  const setQueryData = useSetQueryData();

  const { mutate, isPending } = useMutation({
    mutationFn: (status: UserStatus) =>
      fetchData('/user/update-status', {
        method: 'PUT',
        queryParams: { id },
        bodyParams: { status },
      }),
    onSuccess: (data, status) =>
      setQueryData<AdminUser>(['adminUser', id], oldData => ({
        ...oldData,
        status_id: status,
        status:
          status === USER_STATUS_INACTIVE
            ? translateText('label', 'Inactive')
            : translateText('label', 'Active'),
      })),
  });

  return { updateStatus: mutate, isLoading: isPending };
}
