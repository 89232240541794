import ConfigurationPage from 'components/configuration/ConfigurationPage';
import FormatsSection from 'components/configuration/implementation/FormatsSection';
import ScriptSection from 'components/configuration/implementation/ScriptSection';
import TypeSection from 'components/configuration/implementation/TypeSection';
import { useTranslate } from 'hooks/translate';

export default function Implementation() {
  const translateText = useTranslate();

  return (
    <ConfigurationPage title={translateText('label', 'Implementation')}>
      <TypeSection />
      <FormatsSection />
      <ScriptSection />
    </ConfigurationPage>
  );
}
