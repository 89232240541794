import { PropsWithChildren } from 'react';
import { includes } from 'lodash';

import { useDomainAuth } from 'hooks/queries/domain';
import { useTranslate } from 'hooks/translate';

type Props = {
  title?: string;
  className?: string;
};

const STATUS_IN_REQUEST = [
  4, // Requested: Not signed Data Processor Agreement
  5, // Requested: Signed Data Processor Agreement
] as const;

export default function ConfigurationPage({
  title,
  children,
  className,
}: PropsWithChildren<Props>) {
  const translateText = useTranslate();
  const { domainAuth } = useDomainAuth();

  function getContent() {
    if (!includes(STATUS_IN_REQUEST, domainAuth?.status)) return children;
    return (
      <div className="white-block not-delivered">
        {translateText(
          'text',
          'This page is not yet visible, because the domain has not yet been delivered. The delivery of a domain usually takes two working days, but can only take place once the Processor Agreement has been signed.',
        )}
      </div>
    );
  }

  return (
    <div className={'configuration-wrapper' + (className ? ' ' + className : '')}>
      {title && <h1>{title}</h1>}
      {getContent()}
    </div>
  );
}
