import { useParams } from 'react-router';

import Loader from 'components/Loader';
import Table from 'components/table/Table';
import { useNumber } from 'hooks/queries/number';
import { useTranslate } from 'hooks/translate';

export default function Details() {
  const translateText = useTranslate();
  const params = useParams();
  const entryId = Number(params.number);
  const { data, isLoading } = useNumber(!isNaN(entryId) ? entryId : null);

  if (isLoading) return <Loader />;

  return (
    <>
      <h1>{translateText('label', 'Phone number')}</h1>
      <Table
        columns={{
          id: { header: translateText('label', 'ID') },
          domain_id: { header: translateText('label', 'Domain ID'), noNumberFormat: true },
          number: { header: translateText('label', 'Number'), noNumberFormat: true },
          destination: { header: translateText('label', 'Destination'), noNumberFormat: true },
          is_fallback: { header: translateText('label', 'Is fallback'), forceBoolean: true },
          call_count: { header: translateText('label', 'Call count') },
        }}
        data={[data ?? {}]}
      />
      <pre className="margin-top">{JSON.stringify(data, undefined, 4)}</pre>
    </>
  );
}
