import starIcon from 'assets/images/icons/star.svg';
import starHollowIcon from 'assets/images/icons/star-hollow.svg';
import { ServiceType } from 'globals/types';
import { useUpdateMarking } from 'hooks/queries/call';

type Props = {
  type: ServiceType;
  itemId: number | undefined; // call or call request id
  marked: boolean | undefined;
  isLoading: boolean;
};

export default function MarkButton({ type, itemId, marked, isLoading }: Props) {
  const { updateMarking, isLoading: isUpdatingCallMark } = useUpdateMarking(type);

  const markedIcon = marked ? starIcon : starHollowIcon;
  const alt = marked ? 'marked' : 'not marked';

  function toggleMarking() {
    updateMarking({ id: itemId!, marking: !marked });
  }

  return (
    <button
      className="btn btn-darkblue btn-round btn-mark"
      onClick={toggleMarking}
      disabled={isUpdatingCallMark || isLoading}
    >
      <img src={markedIcon} alt={alt} />
    </button>
  );
}
