import ReactPaginate from 'react-paginate';

import { useTranslate } from 'hooks/translate';
import Select from './input/Select';

type Props = {
  hideLimitSelector?: boolean;
  activePage: number;
  itemsCountPerPage: number;
  totalItemsCount: number;
  totalPageCount: number;
  options?: number[];
  onChange: (page: number, itemCount: number) => void;
};

export default function Pagination(props: Props) {
  const translateText = useTranslate();
  const maxItems = props.itemsCountPerPage * props.activePage;
  return (
    <div className="pagination-wrapper">
      <div />
      <div className="pagination-selector">
        <ReactPaginate
          marginPagesDisplayed={1}
          pageRangeDisplayed={3}
          className="pagination"
          forcePage={props.activePage - 1}
          pageCount={props.totalPageCount}
          onPageChange={page => props.onChange(page.selected + 1, props.itemsCountPerPage)}
          nextLabel={<div className="arrow arrow-right tiny" />}
          previousLabel={<div className="arrow arrow-left tiny" />}
          previousClassName="pagination-arrow pagination-arrow-prev"
          nextClassName="pagination-arrow pagination-arrow-next"
          pageClassName="pagination-item"
          activeClassName="active"
        />
      </div>
      <div className="size-selector">
        {!props.hideLimitSelector && (
          <>
            <div className="number-of-items">
              {translateText('label', 'View: {amount} of {total} total.', {
                amount:
                  maxItems -
                  props.itemsCountPerPage +
                  1 +
                  '-' +
                  (maxItems > props.totalItemsCount ? props.totalItemsCount : maxItems),
                total: props.totalItemsCount,
              })}
            </div>
            <div className="pagesizer-wrapper">
              <Select
                options={props.options ?? [10, 20, 50, 100, 200, 500]}
                value={props.itemsCountPerPage}
                onChange={e => props.onChange(1, parseInt(e.target.value))}
                selectClassName="no-padding small no-width"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
