import { ReactElement, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import moment from 'moment';

import { SUP_ART_TRIAL_SWITCH } from 'globals/support';
import { useTrial } from 'hooks/access';
import { useUpgradeTrial } from 'hooks/queries/domain';
import { useTranslate } from 'hooks/translate';
import SupportParagraph from './SupportParagraph';
import VerifyProceedPrompt from './VerifyProceedPrompt';

export default function TrialRemainingBar() {
  const translateText = useTranslate();
  const { trialMonth } = useTrial();
  const { upgradeTrial, isUpgrading } = useUpgradeTrial();
  const transitionRef = useRef(null);

  const [showPopup, setShowPopup] = useState(false);
  const [remainingDays, setRemainingDays] = useState<number>(0);

  const today = moment().format('YYYY-MM-DD');

  useEffect(() => {
    if (trialMonth) {
      const trialEndDate = moment(trialMonth, 'DD-MM-YYYY').endOf('month');
      setRemainingDays(trialEndDate.diff(moment(), 'days'));
    }
  }, [trialMonth, today]);

  let className = 'counter ';
  if (remainingDays < 3) className += 'low';
  else if (remainingDays < 5) className += 'medium';
  else if (remainingDays < 7) className += 'high';
  else className += 'full';

  let text = translateText(
    'message',
    'Your trial period ends <span class="counter low">today</span>. Starting tomorrow you can use all the functionalities in the Optimize package.',
  );
  let button: ReactElement | null = null;
  if (remainingDays > 0) {
    text = translateText(
      'message',
      'Your trial period will expire in <span class="{className}">{amount}</span> {unit}. Upgrade to the Optimize package for full functionality.',
      {
        className,
        amount: remainingDays,
        unit: (remainingDays === 1
          ? translateText('label', 'Day')
          : translateText('label', 'Days')
        ).toLowerCase(),
      },
    );
    button = (
      <button className="btn btn-text no-padding" onClick={() => setShowPopup(true)}>
        {translateText('label', 'Upgrade')}
      </button>
    );
  }

  return (
    <>
      <CSSTransition
        classNames="trial-bar"
        nodeRef={transitionRef}
        in={trialMonth !== null && remainingDays >= 0}
        timeout={300}
        unmountOnExit
      >
        <div id="trial-bar" ref={transitionRef}>
          <div id="trial-message">
            <span dangerouslySetInnerHTML={{ __html: text }} /> {button}
          </div>
        </div>
      </CSSTransition>
      <VerifyProceedPrompt
        show={showPopup}
        popupClass="trial-prompt"
        title={translateText('label', 'Switch to Optimize')}
        intro={
          <SupportParagraph
            extraText={translateText(
              'text',
              'Are you sure you want to switch to the Optimize package and start invoicing immediately?',
            )}
            articleId={SUP_ART_TRIAL_SWITCH}
          />
        }
        verifyText={translateText(
          'text',
          'Yes, I want to switch to the Optimize package for full functionality. Billing may be activated.',
        )}
        proceed={{
          onClick: () => upgradeTrial().then(() => setShowPopup(false)),
          text: translateText('label', 'Switch'),
          color: 'green',
        }}
        cancel={{
          onClick: () => setShowPopup(false),
          color: 'lightblue',
        }}
        isSaving={isUpgrading}
      />
    </>
  );
}
