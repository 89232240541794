import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';

import Breadcrumb from 'components/knowledge-center/Breadcrumb';
import ImageArticle from 'components/knowledge-center/ImageArticle';
import NavigationList from 'components/knowledge-center/NavigationList';
import Skeleton from 'components/Skeleton';
import { KNOWLEDGE_CENTER_TYPE_INSPIRATION } from 'globals/constants';
import { CategorySlug, CategoryTypeString } from 'globals/types';
import { useCategoryType } from 'hooks/knowledge-center';
import { useArticles, useCategories } from 'hooks/queries/support';
import { useTranslate } from 'hooks/translate';

export default function CategoryOverview() {
  const translateText = useTranslate();
  const navigate = useNavigate();
  const params = useParams<{ categoryType: CategoryTypeString | CategorySlug }>();
  const { categoryTypeId, categoryTypeSlug, categoryTypeText } = useCategoryType(
    params.categoryType ?? null,
  );
  const { articles, isLoading: isLoadingArticles } = useArticles({
    category_type: categoryTypeId,
    popular_type: categoryTypeId,
    expand: 'popular,image,created_on,author',
    sort: '-updated_at',
  });
  const { categories, isLoading: isLoadingCategories } = useCategories();
  const typeCategories = categories?.filter(
    category => category.type === categoryTypeId && category.articles.length > 0,
  );

  const isLoading = isLoadingCategories || isLoadingArticles;

  useEffect(() => {
    if (params.categoryType !== categoryTypeSlug) {
      navigate(`/knowledge-center/${encodeURIComponent(categoryTypeSlug ?? '')}`, {
        replace: true,
      });
    }
  }, [categoryTypeSlug, navigate, params.categoryType]);

  function getSidebar() {
    const popularArticles =
      articles
        ?.filter(item => !!item.popular?.length)
        .sort((a, b) => a.popular![0]!.order - b.popular![0]!.order) ?? [];
    const navigationList = (
      <>
        <NavigationList
          title={translateText('label', 'Popular')}
          list={popularArticles}
          titleKey="title"
          onClick={item =>
            navigate(`/knowledge-center/article/${encodeURIComponent(item.main_slug)}`)
          }
          isLoading={isLoading}
        />
        <NavigationList
          title={translateText('label', 'Last updated')}
          list={articles?.slice(0, 7) ?? []}
          titleKey="title"
          onClick={item =>
            navigate(`/knowledge-center/article/${encodeURIComponent(item.main_slug)}`)
          }
          isLoading={isLoading}
        />
      </>
    );

    if (categoryTypeId !== KNOWLEDGE_CENTER_TYPE_INSPIRATION) return navigationList;

    const popularArticle = popularArticles[0];
    return (
      <>
        <ImageArticle
          author={popularArticle?.author}
          createdOn={popularArticle?.created_on}
          image={popularArticle?.image}
          slug={popularArticle?.main_slug}
          title={popularArticle?.title}
          isLoading={isLoading}
        />
        {navigationList}
      </>
    );
  }

  let wrapperClass = 'content-wrapper category-type';
  if (categoryTypeSlug !== null) wrapperClass += ' ' + categoryTypeSlug;

  const title = translateText('label', '{type} categories', {
    type: categoryTypeText,
  });

  return (
    <div className={wrapperClass}>
      <div className="title-wrapper">
        <Breadcrumb categoryType={categoryTypeSlug} isLoading={isLoading} />
        <h1>{isLoading ? <Skeleton width={title.length * 10} /> : title}</h1>
      </div>
      <div className={'category-tiles' + (isLoading ? ' fade-out' : '')}>
        {(isLoading ? [...Array(12)] : typeCategories)?.map((category, index) => (
          <div
            key={index}
            className="category-tile clickable"
            onClick={() =>
              navigate(
                `/knowledge-center/${categoryTypeSlug}/category/${encodeURIComponent(
                  category.main_slug,
                )}`,
              )
            }
          >
            <div className="category-title">
              {isLoading ? <Skeleton width="80%" /> : category?.name}
            </div>
            {isLoading ? (
              <Skeleton className="category-image" containerClass="no-full-width" />
            ) : (
              <img src={category?.icon} alt={category?.name} className="category-image" />
            )}
          </div>
        ))}
      </div>
      {getSidebar()}
    </div>
  );
}
