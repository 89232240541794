import { useState } from 'react';
import { useParams } from 'react-router';
import { Formik } from 'formik';
import * as Yup from 'yup';

import ChangeAccountManager from 'components/admin/ChangeAccountManager';
import AddButton from 'components/buttons/AddButton';
import InviteDomain from 'components/domain-overview/InviteDomain';
import ErrorFocus from 'components/ErrorFocus';
import Select from 'components/input/Select';
import TextField from 'components/input/TextField';
import Loader from 'components/Loader';
import MccUserSetup from 'components/settings/account-access/MccUserSetup';
import QueryTable from 'components/table/QueryTable';
import { ColumnSettings } from 'components/table/Table';
import {
  DOMAIN_STATUS_ACTIVE,
  DOMAIN_STATUS_BEFORE_TRIAL,
  DOMAIN_STATUS_INACTIVE,
  DOMAIN_STATUS_INACTIVE_NOT_SIGNED,
  DOMAIN_STATUS_REQUESTED,
  DOMAIN_STATUS_REQUESTED_NOT_SIGNED,
  DOMAIN_STATUS_STOPPED,
} from 'globals/constants';
import { useHasAdminAccess } from 'hooks/access';
import {
  useAdminResellerDetails,
  useUpdateAccountManager,
  useUpdateReseller,
} from 'hooks/queries/reseller';
import { useAccountManagers } from 'hooks/queries/user';
import { useTranslate } from 'hooks/translate';
import { AdminDomain } from './domains/Overview';

export default function ResellerDetails() {
  const translateText = useTranslate();
  const isAdmin = useHasAdminAccess();
  const { accountManagers, isLoading: isLoadingAccountManagers } = useAccountManagers();

  const [showInviteDomain, setShowInviteDomain] = useState(false);
  const [showInviteUser, setShowInviteUser] = useState(false);

  const params = useParams();
  const resellerId = Number(params.id);
  const { reseller, isLoading } = useAdminResellerDetails(!isNaN(resellerId) ? resellerId : null);
  const { updateReseller } = useUpdateReseller(resellerId);
  const { changeAccountManager } = useUpdateAccountManager(resellerId);

  const [selectedKickbackDomains, setSelectedKickbackDomains] = useState<number[]>([]);
  const [selectedMccDomains, setSelectedMccDomains] = useState<number[]>([]);

  function updateSelectedRows(type: 'kickback' | 'mcc', value: number) {
    const selectedRows = type === 'mcc' ? selectedMccDomains : selectedKickbackDomains;
    const setSelectedRows = type === 'mcc' ? setSelectedMccDomains : setSelectedKickbackDomains;
    if (selectedRows.includes(value)) setSelectedRows(selectedRows.filter(i => i !== value));
    else setSelectedRows([...selectedRows, value]);
  }

  if (isLoading || isLoadingAccountManagers) {
    return <Loader />;
  }
  if (!reseller) {
    return <p>{translateText('label', 'This reseller does not exist')}</p>;
  }

  const kickbackValidationSchema = Yup.object({
    email: Yup.string().test({
      test: function (value) {
        return !this.parent.name || !!value;
      },
      message: translateText('message', 'This field is required.'),
    }),
    name: Yup.string().test({
      test: function (value) {
        return !this.parent.email || !!value;
      },
      message: translateText('message', 'This field is required.'),
    }),
  });

  const domainColumns: ColumnSettings<AdminDomain> = {
    id: { header: { name: translateText('label', 'ID'), sort: true }, search: true },
    url: { header: { name: translateText('label', 'URL'), sort: true }, search: true },
    title: {
      header: { name: translateText('label', 'Title'), sort: true },
      search: true,
    },
    status_string: {
      header: { name: translateText('label', 'Status'), sort: true },
      search: {
        [DOMAIN_STATUS_STOPPED]: translateText('label', 'Stopped'),
        [DOMAIN_STATUS_INACTIVE]: translateText('label', 'Inactive'),
        [DOMAIN_STATUS_REQUESTED_NOT_SIGNED]: translateText(
          'label',
          'Requested: Missing agreement',
        ),
        [DOMAIN_STATUS_REQUESTED]: translateText('label', 'Requested'),
        [DOMAIN_STATUS_INACTIVE_NOT_SIGNED]: translateText('label', 'Inactive: Missing agreement'),
        [DOMAIN_STATUS_BEFORE_TRIAL]: translateText('label', 'Before trial'),
        [DOMAIN_STATUS_ACTIVE]: translateText('label', 'Active'),
      },
    },
    account_manager: {
      header: translateText('label', 'Account manager'),
      search: true,
      customValue: value => value.name,
    },
  };

  return (
    <div className="reseller-details">
      <h1>{translateText('label', 'Reseller')}</h1>
      <div className="white-block">
        <h3>{translateText('label', 'Details')}</h3>
        <div className="form-row">
          <div className="name-label">{translateText('label', 'ID')}</div>
          <div className="value-wrapper">{reseller.id}</div>
        </div>
        <div className="form-row">
          <div className="name-label">{translateText('label', 'Name')}</div>
          <div className="value-wrapper">{reseller.name}</div>
        </div>
        <div className="form-row">
          <div className="name-label">{translateText('label', 'Account manager')}</div>
          <div className="value-wrapper">
            <Select
              value={String(reseller.account_manager.id ?? -1)}
              options={accountManagers ?? {}}
              onChange={e => changeAccountManager(e.target.value)}
              canEdit={isAdmin}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="name-label">{translateText('label', 'Partner status')}</div>
          <div className="value-wrapper">
            <Select
              value={reseller.partner_status ?? 'select'}
              options={['Reseller', 'Partner', 'Gold Partner', 'Eindklant']}
              onChange={e => updateReseller({ partner_status: e.target.value })}
              canEdit={isAdmin}
              addSelect
            />
          </div>
        </div>
      </div>

      <Formik
        initialValues={{
          email: reseller.kickback_email ?? '',
          name: reseller.kickback_name ?? '',
        }}
        enableReinitialize
        onSubmit={values =>
          updateReseller({ kickback_email: values.email, kickback_name: values.name })
        }
        validationSchema={kickbackValidationSchema}
      >
        {({ values, handleChange, submitForm, dirty, isSubmitting, submitCount, errors }) => (
          <div className="white-block">
            <ErrorFocus />
            <h3>{translateText('label', 'Kickback')}</h3>
            <div className="form-row">
              <div className="name-label">{translateText('label', 'Email address')}</div>
              <div className="value-wrapper">
                <TextField
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  canEdit={isAdmin}
                  error={submitCount > 0 && errors.email}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="name-label">{translateText('label', 'Full name')}</div>
              <div className="value-wrapper">
                <TextField
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  canEdit={isAdmin}
                  error={submitCount > 0 && errors.name}
                />
              </div>
            </div>
            <div className="form-button">
              {isAdmin && (
                <button
                  className="btn btn-green"
                  onClick={submitForm}
                  disabled={!dirty || isSubmitting}
                >
                  {translateText('label', 'Save')}
                </button>
              )}
            </div>
          </div>
        )}
      </Formik>
      <div className="white-block">
        <h3>{translateText('label', 'Domains (kickback)')}</h3>
        <QueryTable<AdminDomain>
          tableKey={['domains', 'resellerKickback']}
          className="wrap"
          columns={domainColumns}
          endpoint="/domain/index"
          filters={{
            domain: false,
            custom: { expand: 'account_manager', reseller_id: resellerId },
          }}
          pagination
          refresh
          noWhiteBlock
          multiSelect={{
            selected: selectedKickbackDomains,
            update: id => updateSelectedRows('kickback', id as number),
          }}
          isLoading={isLoading}
          extraTopLeft={
            <ChangeAccountManager
              type="domain"
              selectedRows={selectedKickbackDomains}
              onSuccess={() => setSelectedKickbackDomains([])}
            />
          }
        />
      </div>
      <div className="white-block">
        <h3>{translateText('label', 'Domains (customer center)')}</h3>
        <QueryTable<AdminDomain>
          tableKey={['domains', 'resellerMcc']}
          className="wrap"
          columns={domainColumns}
          endpoint="/domain/index"
          filters={{
            domain: false,
            custom: { expand: 'account_manager', mcc_id: resellerId },
          }}
          pagination
          refresh
          noWhiteBlock
          multiSelect={{
            selected: selectedMccDomains,
            update: id => updateSelectedRows('mcc', id as number),
          }}
          isLoading={isLoading}
          extraTopLeft={
            <>
              <AddButton onClick={() => setShowInviteDomain(true)} className="margin-right" />
              <ChangeAccountManager
                type="domain"
                selectedRows={selectedMccDomains}
                onSuccess={() => setSelectedMccDomains([])}
              />
            </>
          }
        />
      </div>
      <div className="white-block">
        <h3>{translateText('label', 'Users')}</h3>
        <QueryTable<{
          user: string;
          role: { description: string; id: number };
          is_admin: 0 | 1;
          email: string;
        }>
          tableKey={['reseller', 'users', resellerId]}
          className="wrap"
          columns={{
            user: { header: translateText('label', 'User') },
            email: { header: translateText('label', 'Email') },
            role: {
              header: translateText('label', 'Rights'),
              customValue: value => value.description,
            },
            is_admin: { header: translateText('label', 'Admin'), forceBoolean: true },
          }}
          endpoint="/reseller/users"
          filters={{ domain: false, custom: { expand: 'email', reseller: resellerId } }}
          pagination
          refresh
          noWhiteBlock
          isLoading={isLoading}
          extraTopLeft={<AddButton onClick={() => setShowInviteUser(true)} />}
        />
      </div>
      <InviteDomain
        show={showInviteDomain}
        close={() => setShowInviteDomain(false)}
        mccId={resellerId}
      />
      <MccUserSetup
        showUser={showInviteUser ? 'new' : false}
        close={() => setShowInviteUser(false)}
        resellerId={resellerId}
      />
    </div>
  );
}
