import { PropsWithChildren } from 'react';

type Props = {
  number?: number;
  title: string;
};

export default function StepBlock({ number, title, children }: PropsWithChildren<Props>) {
  return (
    <div className="step-block">
      <h2>
        {number !== undefined && <span className="number">{number}</span>}
        {title}
      </h2>
      {children}
    </div>
  );
}
