import { useNavigate, useParams } from 'react-router';
import { useIsFetching } from '@tanstack/react-query';
import moment from 'moment';

import AutoGrid from 'components/AutoGrid';
import DetailsSection from 'components/DetailsSection';
import CallRecordingSection from 'components/history/CallRecordingSection';
import EvaluateSection from 'components/history/EvaluateSection';
import IntegrationStatusSection from 'components/history/IntegrationStatusSection';
import MarkButton from 'components/history/MarkButton';
import NoteSection from 'components/history/NoteSection';
import SendEmailSection from 'components/history/SendEmailSection';
import ItemNavigation from 'components/ItemNavigation';
import DownloadButtons from 'components/table/DownloadButtons';
import QueryTable from 'components/table/QueryTable';
import { getDefaultFilters } from 'contexts/Filters';
import { TABLE_KEY_CALL_INDEX, TYPE_CALL_TRACKING } from 'globals/constants';
import { useDateTime } from 'hooks/date-time';
import { useCall } from 'hooks/queries/call';
import { useTranslate } from 'hooks/translate';
import Error404 from 'pages/Error404';
import { CALL_HISTORY_EXPAND } from './History';

export default function HistoryDetails() {
  const translateText = useTranslate();
  const navigate = useNavigate();
  const { formatDateTime } = useDateTime();
  const params = useParams();
  const callId = Number(params.id);
  const { call, isLoading: isLoadingCall, isError } = useCall(!isNaN(callId) ? callId : null);

  const isFetchingSameCaller = useIsFetching({ queryKey: ['call', 'sameCaller'] });
  const isFetchingGoogleAnalytics = useIsFetching({
    queryKey: ['domainSetting', 'googleAnalytics'],
  });
  const isFetchingEvaluationOptions = useIsFetching({ queryKey: ['evaluation', 'options'] });
  const isFetchingEvaluationLog = useIsFetching({ queryKey: ['evaluation', 'log'] });
  const isLoading =
    isLoadingCall ||
    isFetchingGoogleAnalytics > 0 ||
    isFetchingSameCaller > 0 ||
    isFetchingEvaluationOptions > 0 ||
    isFetchingEvaluationLog > 0;

  if (isNaN(callId) || isError) {
    return (
      <Error404
        title={translateText('label', 'Call not found')}
        firstParagraph={translateText(
          'text',
          'Unfortunately, this call does not exist or you do not have access to the call.',
        )}
      />
    );
  }

  let location = call?.aen_name ?? call?.aen;
  if (call?.aen_name && call.aen_name !== call.aen) location += ' (' + call.aen + ')';

  return (
    <div className="history-details">
      <div className="header">
        <h1>{translateText('label', 'Call') + ' #' + callId}</h1>
        <div className="icon-container">
          <DownloadButtons
            method="POST"
            endpoint="/reports/call-details"
            download="pdf"
            disabled={isLoading}
            filters={{ custom: { call: callId } }}
          />
          <MarkButton
            itemId={call?.id}
            marked={call?.marked}
            type={TYPE_CALL_TRACKING}
            isLoading={isLoading}
          />
        </div>
        <ItemNavigation
          itemId={callId}
          itemName={translateText('label', 'Call')}
          tableKey={TABLE_KEY_CALL_INDEX}
          endpoint="/call/index"
          pageRoute="/call-tracking/history"
          filters={{
            ...getDefaultFilters(TYPE_CALL_TRACKING),
            statusLef: true,
            custom: { expand: CALL_HISTORY_EXPAND },
          }}
        />
      </div>
      <AutoGrid dependencies={[call]} size="large">
        <DetailsSection
          title={translateText('label', 'Call details')}
          isLoading={isLoading}
          data={{
            [translateText('label', 'Domain')]: { value: call?.domain ?? '-', private: true },
            ...(location && {
              [translateText('label', 'Location')]: { value: location, private: true },
            }),
            [translateText('label', 'Destination')]: {
              value: call?.destination ?? '-',
              private: true,
            },
            [translateText('label', 'Sender')]: {
              value:
                call && call?.caller !== 0
                  ? String(call.caller)
                  : translateText('label', 'Anonymous'),
              private: true,
            },
            [translateText('label', 'Traffic source')]: {
              value: call?.source ?? '-',
              private: true,
            },
            [translateText('label', 'Date & time')]: formatDateTime(call?.time_called ?? ''),
            [translateText('label', 'Call duration')]: call?.duration ?? '-',
            [translateText('label', 'IVR menu')]: call?.customer_evaluation ?? '-',
          }}
        />
        <CallRecordingSection
          type={TYPE_CALL_TRACKING}
          itemId={callId}
          isLoading={isLoading}
          timeCalled={call?.time_called ? moment(call.time_called) : null}
        />
        <DetailsSection
          title={translateText('label', 'Session data')}
          isLoading={isLoading}
          data={{
            [translateText('label', 'Last page visited')]: {
              value: call?.last_url ?? '-',
              tooltip: translateText(
                'tooltip/call-tracking',
                'This is the last page visited before a call was registered from the visitor.',
              ),
              private: true,
            },
            [translateText('label', 'Campaign')]: {
              value: call?.campaign_data?.campaign ?? '-',
              tooltip: translateText(
                'tooltip/call-tracking',
                'This is the campaign that the displayed advertisement is part of.',
              ),
              private: true,
            },
            [translateText('label', 'Keyword')]: {
              value: call?.campaign_data?.searchTerm ?? '-',
              tooltip: translateText(
                'tooltip/call-tracking',
                'This is the keyword used on the basis of which the ad was displayed.',
              ),
              private: true,
            },
            [translateText('duplicate', 'Match type')]: {
              value: call?.campaign_data?.matchType ?? '-',
              tooltip: translateText(
                'tooltip/call-tracking',
                'This option determines how the query is matched.',
              ),
            },
          }}
        />
        <EvaluateSection type={TYPE_CALL_TRACKING} itemId={callId} isLoading={isLoading} />
        <IntegrationStatusSection callId={callId} isLoading={isLoading} />
        <NoteSection
          type={TYPE_CALL_TRACKING}
          itemId={call?.id}
          notes={call?.notes ?? []}
          isLoading={isLoading}
        />
        {call?.caller !== '0' && (
          <DetailsSection
            title={translateText('label', 'Calls from the same sender')}
            isLoading={isLoading}
            tooltip={translateText(
              'tooltip/call-tracking',
              'Here you will find more calls from the same sender.',
            )}
          >
            <QueryTable
              tableKey={['call', 'sameCaller']}
              columns={{
                time_called: {
                  header: translateText('label', 'Date'),
                  date: { includeSeconds: true, connector: 'none' },
                },
                duration: { header: translateText('label', 'Call duration') },
                id: { header: translateText('label', 'Call ID') },
              }}
              endpoint="/call/from-same-number"
              filters={{ custom: { id: callId } }}
              pagination={{ hideLimit: true, defaultLimit: 5 }}
              onRowClick={row => navigate('/call-tracking/history/' + row.id)}
              isLoading={isLoading}
              isResponsive
              noWhiteBlock
            />
          </DetailsSection>
        )}
        <SendEmailSection itemId={call?.id} type={TYPE_CALL_TRACKING} isLoading={isLoading} />
      </AutoGrid>
    </div>
  );
}
