import { Dispatch, SetStateAction } from 'react';
import { Formik } from 'formik';
import { includes } from 'lodash';
import * as Yup from 'yup';

import Radio from 'components/input/Radio';
import TextField from 'components/input/TextField';
import { STATUSES_FINISHED, useDebugMode } from 'contexts/DebugMode';
import { useTranslate } from 'hooks/translate';
import { BrowserSize } from './Browser';

type Props = {
  size: BrowserSize;
  setSize: Dispatch<SetStateAction<BrowserSize>>;
  dimensions: { width: number; height: number };
  setDimensions: Dispatch<SetStateAction<{ width: number; height: number }>>;
};

export default function SizeSelector({ size, setSize, dimensions, setDimensions }: Props) {
  const translateText = useTranslate();
  const { status } = useDebugMode();

  function setStandardSize(size: Exclude<BrowserSize, 'custom'>) {
    let width = 900;
    if (size === 'tablet') width = 680;
    else if (size === 'mobile') width = 380;

    setSize(size);
    setDimensions({ width, height: 580 });
  }

  const minimumValidation = Yup.number().min(
    200,
    translateText('message', 'The value for must be equal to or bigger than 200.'),
  );
  const validationSchema = Yup.object({
    height: minimumValidation,
    width: minimumValidation,
  });

  const disabled = !includes(STATUSES_FINISHED, status);
  return (
    <div className="size-selector">
      <Radio
        id="input-size-desktop"
        disabled={disabled}
        label={translateText('label', 'Desktop')}
        checked={size === 'desktop'}
        onChange={() => setStandardSize('desktop')}
      />
      <Radio
        id="input-size-tablet"
        disabled={disabled}
        label={translateText('label', 'Tablet')}
        checked={size === 'tablet'}
        onChange={() => setStandardSize('tablet')}
      />
      <Radio
        id="input-size-mobile"
        disabled={disabled}
        label={translateText('label', 'Mobile')}
        checked={size === 'mobile'}
        onChange={() => setStandardSize('mobile')}
      />
      <Radio
        id="input-size-custom"
        disabled={disabled}
        label={translateText('label', 'Custom')}
        checked={size === 'custom'}
        onChange={() => setSize('custom')}
      />
      {size === 'custom' && (
        <Formik
          initialValues={dimensions}
          onSubmit={values => setDimensions(values)}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ values, setFieldValue, submitForm, handleSubmit, errors }) => {
            function getDimensionInput(type: 'width' | 'height') {
              return (
                <div className="dimension-input">
                  <span>
                    {type === 'width'
                      ? translateText('label', 'Width')
                      : translateText('label', 'Height')}
                  </span>
                  <TextField
                    value={values[type]}
                    onChange={e =>
                      setFieldValue(type, Math.max(+e.target.value.replace(/[^0-9]/g, ''), 0))
                    }
                    disabled={disabled}
                    onBlur={submitForm}
                    error={errors[type]}
                  />
                  <span>px</span>
                </div>
              );
            }
            return (
              <form onSubmit={handleSubmit}>
                <input type="submit" hidden />
                {getDimensionInput('width')}
                {getDimensionInput('height')}
              </form>
            );
          }}
        </Formik>
      )}
    </div>
  );
}
