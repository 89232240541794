import { useState } from 'react';
import {
  Bar as RechartsBar,
  BarChart,
  CartesianGrid,
  Legend as RechartsLegend,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { LayoutType } from 'recharts/types/util/types';

import { useTranslate } from 'hooks/translate';
import Legend from './Legend';
import Tooltip from './Tooltip';
import { BarProps, DataProps } from './types';
import { PREVIOUS_PREFIX } from './Widget';

export default function Bar(props: BarProps & DataProps) {
  const translateText = useTranslate();
  const { id, data, dataKeys, palette, isVertical, emptyLabel, tickFormatter, previousData } =
    props;
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  // When there is previous data, each data key needs to be duplicated for the previous period
  // and the previous data needs to be merged with the regular data.
  const dataKeysWithPrev: BarProps['dataKeys'] = {};
  if (previousData) {
    Object.entries(dataKeys).forEach(([key, value]) => {
      dataKeysWithPrev[PREVIOUS_PREFIX + key] = {
        name: translateText('label', '{key} (previous period)', { key: value.name }),
        stack: PREVIOUS_PREFIX + (value.stack ?? ''),
      };
      dataKeysWithPrev[key] = value;
    });
    props.data.forEach((item, index) => {
      Object.keys(item).forEach(key => {
        if (key !== 'argument') {
          data[index][PREVIOUS_PREFIX + key] = previousData.find(
            i => i.argument === data[index].argument,
          )?.[key];
        }
      });
    });
  }

  let formatX, formatY;
  if (tickFormatter?.x) formatX = tickFormatter.format;
  if (tickFormatter?.y === true) formatY = tickFormatter.format;
  else if (tickFormatter?.y) formatY = tickFormatter.y;

  let layout: LayoutType = 'horizontal';
  let x = (
    <XAxis
      dataKey="argument"
      tickLine={false}
      axisLine={false}
      tickFormatter={formatX}
      domain={[emptyLabel, emptyLabel]}
    />
  );
  let y = <YAxis width={40} tickLine={false} axisLine={false} tickFormatter={formatY} />;
  let lines = { vertical: false, horizontal: true };
  if (isVertical) {
    layout = 'vertical';
    lines = { vertical: true, horizontal: false };
    x = (
      <XAxis
        type="number"
        tickLine={false}
        axisLine={false}
        minTickGap={0}
        tickFormatter={formatX}
      />
    );
    y = (
      <YAxis
        type="category"
        dataKey="argument"
        tickLine={false}
        axisLine={false}
        tickFormatter={formatY}
        domain={[emptyLabel, emptyLabel]}
      />
    );
  }

  return (
    <ResponsiveContainer id={id} className="widget">
      <BarChart data={data ?? []} layout={layout}>
        <RechartsLegend content={<Legend setActiveIndex={setActiveIndex} type="bar" />} />
        {x}
        {y}
        <RechartsTooltip cursor={false} content={<Tooltip {...props} hideTotalIfPrevious />} />
        <CartesianGrid {...lines} className="horizontal-lines" />
        {Object.entries(previousData ? dataKeysWithPrev : dataKeys).map(([key, bar], index) => (
          <RechartsBar
            key={index}
            name={bar.name}
            dataKey={activeIndex !== null && activeIndex !== index ? '' : key}
            stackId={activeIndex === null && bar.stack ? bar.stack : 'stack'}
            fill={palette[index]}
            fillOpacity={activeIndex === null || activeIndex === index ? '1' : '0.1'}
            barSize={40}
            label={bar.tooltip}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
}
