import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { isEqual } from 'lodash';

import { Domain, Mcc } from 'globals/types';
import { useUserMcc } from 'hooks/queries/user';
import { useSession } from './Session';

type Tenant = {
  selectedDomain: number | null;
  setSelectedDomain: Dispatch<SetStateAction<number | null>>;
  selectedMcc: number | null;
  setSelectedMcc: Dispatch<SetStateAction<number | null>>;
  selectedMccData: Mcc | null;
  selectedDomainData: Domain | null;
  isLoading: boolean;
};

const TenantContext = createContext<Tenant | null>(null);

export function TenantProvider({ children }: PropsWithChildren<Record<string, unknown>>) {
  const { token } = useSession();
  const { mcc } = useUserMcc();
  const localStorageTenant = JSON.parse(localStorage.getItem('tenant') ?? '{}');
  const [selectedDomain, setSelectedDomain] = useState<number | null>(
    localStorageTenant.selectedDomain ?? null,
  );
  const [selectedMcc, setSelectedMcc] = useState<number | null>(
    localStorageTenant.selectedMcc ?? null,
  );

  const selectedMccData = selectedMcc ? mcc?.find(mcc => mcc.id === selectedMcc) : null;
  const selectedDomainData = selectedDomain
    ? selectedMccData?.domains.find(d => Number(d.id) === selectedDomain)
    : null;

  useEffect(() => {
    if (!token) {
      setSelectedDomain(null);
      setSelectedMcc(null);
    }
  }, [token]);

  useEffect(() => {
    if (
      (selectedDomain || selectedMcc) &&
      !isEqual(localStorageTenant, { selectedDomain, selectedMcc })
    ) {
      localStorage.setItem('tenant', JSON.stringify({ selectedDomain, selectedMcc }));
    } else if (!selectedDomain && !selectedMcc) {
      localStorage.removeItem('tenant');
    }
  }, [selectedDomain, selectedMcc, localStorageTenant]);

  return (
    <TenantContext.Provider
      value={{
        selectedDomain,
        setSelectedDomain,
        selectedMcc,
        setSelectedMcc,
        selectedMccData: selectedMccData ?? null,
        selectedDomainData: selectedDomainData ?? null,
        isLoading: selectedMccData === undefined,
      }}
    >
      {children}
    </TenantContext.Provider>
  );
}

export function useTenant() {
  const tenant = useContext(TenantContext);
  if (tenant === null) {
    throw new Error('Tenant context not available');
  }
  return tenant;
}
