import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { includes } from 'lodash';

import {
  GOOGLE_ADS_ENHANCED_CONVERSIONS_KEY,
  GOOGLE_ADS_KEY,
} from 'components/integrations/google-ads/BaseGoogleAds';
import Loader from 'components/Loader';
import { useMessages } from 'contexts/Messages';
import { useTenant } from 'contexts/Tenant';
import { useEffectOnce } from 'hooks/effect';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';

export const REDIRECT_URI_BIGQUERY = 'bigqueryUri' as const;
export const REDIRECT_URI_GOOGLE_ADS = 'googleAdsUri' as const;
export const REDIRECT_URI_GOOGLE_ADS_EC = 'googleAdsEcUri' as const;
export const REDIRECT_URI_GOOGLE_DOUBLE_CLICK = 'googleDoubleClickUri' as const;
export const REDIRECT_URI_GOOGLE_DV360 = 'googleDv360Uri' as const;
export const REDIRECT_URI_GOOGLE_SA360 = 'googleSa360Uri' as const;
export const REDIRECT_URI_GOOGLE_TAG_MANAGER = 'googleTagManagerUri' as const;
export const REDIRECT_URI_HUBSPOT = 'hubSpotUri' as const;
export const REDIRECT_URI_MICROSOFT_ADVERTISING = 'microsoftAdvertisingUri' as const;
export const REDIRECT_URI_MICROSOFT_DYNAMICS = 'microsoftDynamicsUri' as const;
export const REDIRECT_URI_PIPEDRIVE = 'pipedriveUri' as const;
export const REDIRECT_URI_SALESFORCE = 'salesForceUri' as const;
export const REDIRECT_URI_ZOHO = 'zohoUri' as const;

export type RedirectUriKey =
  | typeof REDIRECT_URI_BIGQUERY
  | typeof REDIRECT_URI_GOOGLE_ADS
  | typeof REDIRECT_URI_GOOGLE_ADS_EC
  | typeof REDIRECT_URI_GOOGLE_DOUBLE_CLICK
  | typeof REDIRECT_URI_GOOGLE_DV360
  | typeof REDIRECT_URI_GOOGLE_SA360
  | typeof REDIRECT_URI_GOOGLE_TAG_MANAGER
  | typeof REDIRECT_URI_HUBSPOT
  | typeof REDIRECT_URI_MICROSOFT_ADVERTISING
  | typeof REDIRECT_URI_MICROSOFT_DYNAMICS
  | typeof REDIRECT_URI_PIPEDRIVE
  | typeof REDIRECT_URI_SALESFORCE
  | typeof REDIRECT_URI_ZOHO;

type Props = {
  redirectUriKey: RedirectUriKey;
};

export default function Verification({ redirectUriKey }: Props) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addErrorMessage, addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();
  const [searchParams] = useSearchParams();

  const receivedCode = searchParams.get('code');
  const receivedState = searchParams.get('state');
  const error = searchParams.get('error');
  const customerId = new URLSearchParams(receivedState ?? '').get('customerId') ?? '';

  const savedUri = localStorage.getItem(redirectUriKey);
  let isXSSed = false;
  if (savedUri && savedUri !== 'null') {
    const sentState = new URL(savedUri).searchParams.get('state');
    isXSSed = receivedState !== sentState;
  }

  let authenticateEndpoint: string | null = null;
  let returnUri: string | null = null;
  let onSuccess: (() => void) | undefined;
  switch (redirectUriKey) {
    case REDIRECT_URI_BIGQUERY:
      authenticateEndpoint = '/integration/big-query/authenticate';
      returnUri = '/call-tracking/integrations/data-management/bigquery';
      break;
    case REDIRECT_URI_GOOGLE_ADS:
      authenticateEndpoint = '/integration/google-advertising/authenticate';
      returnUri = '/call-tracking/integrations/advertising/google';
      onSuccess = () =>
        queryClient.invalidateQueries({ queryKey: [GOOGLE_ADS_KEY, selectedDomain] });
      break;
    case REDIRECT_URI_GOOGLE_ADS_EC:
      authenticateEndpoint = '/integration/google-ads-ec/authenticate';
      returnUri = '/call-tracking/integrations/advertising/google-ads-enhanced-conversions';
      onSuccess = () =>
        queryClient.invalidateQueries({
          queryKey: [GOOGLE_ADS_ENHANCED_CONVERSIONS_KEY, selectedDomain],
        });
      break;
    case REDIRECT_URI_GOOGLE_DOUBLE_CLICK:
      authenticateEndpoint = '/integration/google-double-click/authenticate';
      returnUri = '/call-tracking/integrations/advertising/doubleclick';
      break;
    case REDIRECT_URI_GOOGLE_DV360:
      authenticateEndpoint = '/integration/google-display-video-360/authenticate';
      returnUri = '/call-tracking/integrations/advertising/display-video-360';
      break;
    case REDIRECT_URI_GOOGLE_SA360:
      authenticateEndpoint = '/integration/google-search-ads-360/authenticate';
      returnUri = '/call-tracking/integrations/advertising/search-360';
      break;
    case REDIRECT_URI_GOOGLE_TAG_MANAGER:
      authenticateEndpoint = '/integration/google-tag-manager/authenticate';
      returnUri = '/call-tracking/config/implementation#google-tag-manager';
      break;
    case REDIRECT_URI_HUBSPOT:
      authenticateEndpoint = '/integration/hubspot/authenticate';
      returnUri = '/call-tracking/integrations/crm/hubspot';
      isXSSed = false;
      break;
    case REDIRECT_URI_MICROSOFT_ADVERTISING:
      authenticateEndpoint = '/domain/authenticate-bing';
      returnUri = '/call-tracking/integrations/advertising/microsoft';
      break;
    case REDIRECT_URI_MICROSOFT_DYNAMICS:
      authenticateEndpoint = '/integration/microsoft-dynamics/authenticate';
      returnUri = '/call-tracking/integrations/crm/microsoft-dynamics';
      break;
    case REDIRECT_URI_PIPEDRIVE:
      authenticateEndpoint = '/integration/pipedrive/authenticate';
      returnUri = '/call-tracking/integrations/crm/pipedrive';
      break;
    case REDIRECT_URI_SALESFORCE:
      authenticateEndpoint = '/integration/salesforce/authenticate';
      returnUri = '/call-tracking/integrations/crm/salesforce';
      break;
    case REDIRECT_URI_ZOHO:
      authenticateEndpoint = '/integration/zoho/authenticate';
      returnUri = '/call-tracking/integrations/crm/zoho';
      break;
  }

  const { mutateAsync: authenticate } = useMutation({
    mutationFn: () =>
      fetchData(authenticateEndpoint!, {
        method: 'POST',
        queryParams: {
          id: selectedDomain,
          code: receivedCode,
          type: 'call-tracking',
          ...(includes([REDIRECT_URI_GOOGLE_ADS, REDIRECT_URI_GOOGLE_ADS_EC], redirectUriKey) && {
            customerId: encodeURIComponent(customerId),
          }),
        },
        bodyParams: { uri: window.location.href },
      }),
    onSuccess: () => addSuccessMessage(translateText('label', 'Account verified')),
  });

  useEffectOnce(() => {
    if (error || isXSSed) {
      navigate(returnUri!);
      return addErrorMessage(
        error === 'access_denied'
          ? translateText(
              'message',
              'The account verification has been cancelled. Please try to re-establish the link.',
            )
          : translateText('message', 'Something went wrong, please try again later.'),
      );
    }
    onSuccess?.();
    authenticate().finally(() => navigate(returnUri!));
  });

  return <Loader />;
}
