import { useParams } from 'react-router';

import NotificationForm from 'components/admin/NotificationForm';
import Loader from 'components/Loader';
import {
  useAddTranslation,
  useAdminNotification,
  useUpdateNotification,
  useUpdateTranlation,
} from 'hooks/queries/notification';
import { useTranslate } from 'hooks/translate';
import Error404 from 'pages/Error404';

export default function NotificationDetails() {
  const translateText = useTranslate();

  const params = useParams();
  const notificationId = Number(params?.id);
  const { notification, isLoading } = useAdminNotification(
    !isNaN(notificationId) ? notificationId : null,
  );
  const { updateNotification } = useUpdateNotification();
  const { addTranslation } = useAddTranslation();
  const { updateTranslation } = useUpdateTranlation();

  if (isLoading) return <Loader />;
  if (!notification) return <Error404 />;

  return (
    <>
      <NotificationForm
        title={translateText('label', 'Notification')}
        initValues={notification}
        onSubmit={values => updateNotification({ id: notificationId, notification: values })}
        triggerId={notificationId}
      />
      <NotificationForm
        title={translateText('label', 'Translations')}
        initValues={{ title: '', summary: '', message: '', language_id: 'en-US' }}
        onSubmit={values => addTranslation({ id: notificationId, notification: values })}
        canChangeLanguage
        translations={notification.translations.map(t => t.language_id)}
      />
      {notification.translations?.map((t, i) => (
        <div key={i}>
          <NotificationForm
            title={t.language_id}
            initValues={t}
            onSubmit={values => updateTranslation({ id: notificationId, notification: values })}
          />
        </div>
      ))}
    </>
  );
}
