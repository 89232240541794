import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import PropertiesSection from 'components/edit-subscription/PropertiesSection';
import SummarySection from 'components/edit-subscription/SummarySection';
import ThanksSection from 'components/edit-subscription/ThanksSection';
import Wrapper, { Type as WrapperType } from 'components/intake/Wrapper';
import { useTenant } from 'contexts/Tenant';
import { useHasEmployeeAccess, useHasManagerAccess, useHasNewPricingPlan } from 'hooks/access';
import { useUpdateEffect } from 'hooks/effect';
import {
  useDomainAuth,
  useEditSubscription,
  useEditSubscriptionPrerequisiteData,
} from 'hooks/queries/domain';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation, useValidationSchema } from 'hooks/validation';

export enum EditSubscriptionType {
  Upgrade = 'upgrade-pricing-plan',
  Extend = 'extend-contract',
}

export type EditSubscriptionProps = {
  type: EditSubscriptionType;
};

export enum EditSubscriptionSection {
  Properties = 'properties',
  Summary = 'summary',
  Thanks = 'thanks',
}

export type EditSubscriptionFormValues = {
  changeRequestId: number | null;
  section: EditSubscriptionSection;
  validated: { [key in EditSubscriptionSection]?: boolean };
  pricingPlanId: string;
  contractDuration: string;
  startDate: string;
  termsAndConditions: boolean;
  agreement: boolean;
};

export default function EditSubscription({ type }: EditSubscriptionProps) {
  const navigate = useNavigate();
  const translateText = useTranslate();
  const hasManagerAccess = useHasManagerAccess();
  const hasEmployeeAccess = useHasEmployeeAccess();
  const hasNewPricingPlan = useHasNewPricingPlan();
  const { selectedDomain } = useTenant();
  const { requiredSelect } = useCustomValidation();
  const { termsAndConditionsValidation } = useValidationSchema();
  const { domainAuth } = useDomainAuth();

  const { data } = useEditSubscriptionPrerequisiteData(type);
  const { editSubscription } = useEditSubscription(type);

  const sections = Object.values(EditSubscriptionSection);
  const isExtending =
    type === EditSubscriptionType.Extend &&
    (!!domainAuth?.can_extend_subscription || !domainAuth?.contract_end_date);

  const noManagerAccess = domainAuth !== undefined && !hasManagerAccess;
  const cannotUpgrade =
    type === EditSubscriptionType.Upgrade &&
    (hasNewPricingPlan || (domainAuth?.can_upgrade === false && !hasEmployeeAccess));

  useEffect(() => {
    if (noManagerAccess || cannotUpgrade) navigate('/');
  }, [noManagerAccess, cannotUpgrade, navigate]);

  useUpdateEffect(() => {
    navigate('/');
  }, [selectedDomain, navigate]);

  function getSectionComponent(
    section: EditSubscriptionSection,
  ): [string, FC<EditSubscriptionProps>] {
    switch (section) {
      case EditSubscriptionSection.Properties:
        return [
          isExtending
            ? translateText('label', 'Extend subscription')
            : translateText('label', 'Upgrade subscription'),
          PropertiesSection,
        ];
      case EditSubscriptionSection.Summary:
        return [
          isExtending
            ? translateText('label', 'Extension summary')
            : translateText('label', 'Upgrade summary'),
          SummarySection,
        ];
      case EditSubscriptionSection.Thanks:
        return [
          isExtending
            ? translateText('label', 'Extension requested')
            : translateText('label', 'Upgrade requested'),
          ThanksSection,
        ];
    }
  }

  const validationSchema = Yup.lazy(values => {
    switch (values.section) {
      case EditSubscriptionSection.Properties:
        return Yup.object({
          pricingPlanId: requiredSelect,
          contractDuration: requiredSelect,
          startDate: requiredSelect,
        });
      case EditSubscriptionSection.Summary:
        return termsAndConditionsValidation;
      default:
        return Yup.object();
    }
  });

  async function onSubmit(
    values: EditSubscriptionFormValues,
    formikHelpers: FormikHelpers<EditSubscriptionFormValues>,
  ) {
    formikHelpers.setFieldValue('validated', { ...values.validated, [values.section]: true });
    if (values.section === EditSubscriptionSection.Summary) {
      await editSubscription(values).then(data => {
        formikHelpers.setFieldValue('changeRequestId', data.id);
        formikHelpers.setFieldValue('section', EditSubscriptionSection.Thanks);
      });
    } else {
      formikHelpers.setFieldValue('section', sections[sections.indexOf(values.section) + 1]);
    }
  }

  return (
    <Formik<EditSubscriptionFormValues>
      initialValues={{
        changeRequestId: null,
        section: data?.upgrade_request_id
          ? EditSubscriptionSection.Thanks
          : EditSubscriptionSection.Properties,
        validated: {},
        pricingPlanId:
          hasNewPricingPlan && domainAuth?.pricing_plan ? domainAuth.pricing_plan : 'select',
        contractDuration: 'select',
        startDate: 'select',
        termsAndConditions: false,
        agreement: false,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialErrors={{ section: '' }}
      enableReinitialize
    >
      {({ values }) => {
        const [title, Section] = getSectionComponent(values.section);
        return (
          <Wrapper
            type={WrapperType.EditSubscription}
            title={title}
            disabled={!data}
            isLoading={type === EditSubscriptionType.Extend && !domainAuth}
          >
            <Section type={type} />
          </Wrapper>
        );
      }}
    </Formik>
  );
}
