import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import ChangeAccountManager from 'components/admin/ChangeAccountManager';
import QueryTable from 'components/table/QueryTable';
import { useAccountManagers } from 'hooks/queries/user';
import { useTranslate } from 'hooks/translate';

export type AdminReseller = {
  id: number;
  name: string;
  partner_status: string | null;
  account_manager: { id: number; name: string; email: string; phone: string };
  kickback_email: string | null;
  kickback_name: string | null;
};

export const ADMIN_RESELLER_EXPAND = 'account_manager,kickback_email,kickback_name' as const;

export default function Resellers() {
  const navigate = useNavigate();
  const translateText = useTranslate();
  const { isLoading } = useAccountManagers();
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  function updateSelectedRows(value: number | number[]) {
    if (typeof value !== 'number') return setSelectedRows(value);
    if (selectedRows.includes(value)) setSelectedRows(selectedRows.filter(i => i !== value));
    else setSelectedRows([...selectedRows, value]);
  }

  return (
    <>
      <h1>{translateText('label', 'Resellers')}</h1>
      <QueryTable<AdminReseller>
        tableKey={['resellers', 'admin']}
        columns={{
          id: { header: { name: translateText('label', 'ID'), sort: true }, search: true },
          name: { header: { name: translateText('label', 'Name'), sort: true }, search: true },
          account_manager: {
            header: translateText('label', 'Account manager'),
            search: true,
            customValue: value => value.name,
          },
        }}
        onRowClick={reseller => navigate('/admin/resellers/' + reseller.id)}
        pagination={{ defaultLimit: 20 }}
        endpoint="/reseller/index"
        filters={{
          domain: false,
          custom: { expand: ADMIN_RESELLER_EXPAND },
        }}
        refresh
        multiSelect={{
          selected: selectedRows,
          update: id => updateSelectedRows(id as number),
          canSelectAll: true,
        }}
        isLoading={isLoading}
        extraTopLeft={
          <ChangeAccountManager
            type="reseller"
            selectedRows={selectedRows}
            onSuccess={() => setSelectedRows([])}
          />
        }
        extraTopRight={
          <Link to="/admin/resellers/new" className="btn btn-lightblue margin-right">
            {translateText('label', 'Add new reseller')}
          </Link>
        }
      />
    </>
  );
}
