import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import { useTranslate } from 'hooks/translate';

type Props = {
  format: string;
  location: string | null;
  classes: string[];
} & BaseSlideInProps;

export default function ViewFormat({ show, close, format, location, classes }: Props) {
  const translateText = useTranslate();
  return (
    <Setup
      title={translateText('label', 'Format details')}
      show={show}
      close={close}
      closeButtonText={translateText('label', 'Close')}
      maxWidth={370}
    >
      <InputWrapper label={translateText('label', 'Format')}>
        {decodeURIComponent(format)}
      </InputWrapper>
      <InputWrapper label={translateText('label', 'Location')}>{location ?? '-'}</InputWrapper>
      <InputWrapper label={translateText('label', 'Classes')}>
        {classes.length > 0 ? '.' + classes.join(' .') : '-'}
      </InputWrapper>
    </Setup>
  );
}
