import { useEffectOnce } from 'hooks/effect';
import { useLogout } from 'hooks/queries/auth';

export default function Reset() {
  const { logOut } = useLogout();

  useEffectOnce(() => {
    logOut();
  });

  return null;
}
