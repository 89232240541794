import Skeleton from 'components/Skeleton';
import { PLAN_ENTERPRISE, PLAN_OPTIMIZE } from 'globals/constants';
import { useHasNewPricingPlan } from 'hooks/access';
import { useTranslate } from 'hooks/translate';

type BaseProps = {
  plan?: typeof PLAN_OPTIMIZE | typeof PLAN_ENTERPRISE;
  isLoading?: boolean;
  checkNewPricingPlan?: boolean;
};

type PropsWithPrice = {
  integration: string;
  price: number;
} & BaseProps;

type PropsWithoutPrice = {
  integration?: never;
  price: null;
} & BaseProps;

export default function CostParagraph({
  integration,
  price,
  plan = PLAN_OPTIMIZE,
  isLoading,
  checkNewPricingPlan = true,
}: PropsWithPrice | PropsWithoutPrice) {
  const translateText = useTranslate();
  const hasNewPricingPlan = useHasNewPricingPlan();

  let text = '';
  let skeletonCount = 2;
  if (price === null && plan === PLAN_OPTIMIZE && hasNewPricingPlan) {
    text = translateText(
      'integration',
      'This integration is available at no cost in the Optimize and Enterprise packages.',
    );
  } else if (price === null && plan === PLAN_ENTERPRISE && hasNewPricingPlan) {
    text = translateText(
      'integration',
      'This integration is available at no cost in the Enterprise package.',
    );
  } else if (plan === PLAN_OPTIMIZE && hasNewPricingPlan) {
    skeletonCount = 3;
    text = translateText(
      'integration',
      'This integration is available in the Optimize and Enterprise packages. There are additional costs associated with the use of the {integration} integration at €{price},- per month.',
      { integration, price },
    );
  } else if (plan === PLAN_ENTERPRISE && hasNewPricingPlan) {
    skeletonCount = 3;
    text = translateText(
      'integration',
      'This integration is available in the Enterprise package. There are additional costs associated with the use of the {integration} integration at €{price},- per month.',
      { integration, price },
    );
  } else if (price !== null) {
    text = translateText(
      'integration',
      'There are additional costs associated with the use of the {integration} integration at €{price},- per month.',
      { integration, price },
    );
  }

  if (isLoading) {
    return (
      <p>
        <Skeleton count={skeletonCount} />
      </p>
    );
  }

  if (checkNewPricingPlan && !hasNewPricingPlan) return null;

  return <p dangerouslySetInnerHTML={{ __html: text }} />;
}
