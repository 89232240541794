import finishedVisual from 'assets/images/guided-tour/finished-visual.svg';
import { useQuitTour } from 'hooks/queries/user';
import { useTranslate } from 'hooks/translate';

export default function CompletedTour() {
  const translateText = useTranslate();
  const { quitTour, isLoading } = useQuitTour();

  return (
    <div className="completed-tour">
      <img alt="" className="background-image" src={finishedVisual} />
      <h1>{translateText('guided-tour', 'Well done!')}</h1>
      <p
        dangerouslySetInnerHTML={{
          __html: translateText(
            'guided-tour',
            'You have completed the tour and can now get started in our dashboard. Do you still have questions after this tour? Please contact us via <a href=\\"tel:+31307400990\\">+31 (0)30 7400 990</a> or via <a href=\\"mailto:support@adcalls.nl\\">support@adcalls.nl</a>.',
          ),
        }}
      />
      <button className="btn btn-green" onClick={() => quitTour()} disabled={isLoading}>
        {translateText('guided-tour', 'Complete the tour')}
      </button>
    </div>
  );
}
