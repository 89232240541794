import { useState } from 'react';
import { Link } from 'react-router-dom';

import UserAvatar from 'components/navigation/UserAvatar';
import UserData from 'components/navigation/UserData';
import { useRoutes } from 'contexts/Routes';
import { useSession } from 'contexts/Session';
import { useIsMobileNavigation } from 'hooks/media';
import { useTranslate } from 'hooks/translate';
import NotificationCenter from './NotificationCenter';

export default function Preview() {
  const translateText = useTranslate();
  const { token } = useSession();
  const { navigationDisabled } = useRoutes();
  const isMobileNavigation = useIsMobileNavigation();

  const [show, setShow] = useState(false);

  if (!token) return <Link to="/login">{translateText('label', 'Log in')}</Link>;
  if (navigationDisabled) return <UserData />;

  return (
    <>
      <div className="notification-center-preview" onClick={() => setShow(show => !show)}>
        <div className="arrow-container">
          <div className="arrow-wrapper">
            <div className="arrow arrow-left" />
          </div>
        </div>
        <div className="user-avatar-wrapper">
          <UserAvatar />
          {isMobileNavigation && <span>{translateText('label', 'Account')}</span>}
        </div>
      </div>
      <NotificationCenter show={show} close={() => setShow(false)} />
    </>
  );
}
