import { PropsWithChildren } from 'react';
import { useFormikContext } from 'formik';

import {
  EditSubscriptionFormValues,
  EditSubscriptionSection,
} from 'components/edit-subscription/EditSubscription';
import { focusErrorElement } from 'components/ErrorFocus';
import Skeleton from 'components/Skeleton';
import { useGlobal } from 'contexts/Global';
import { useUpdateEffect } from 'hooks/effect';
import { useTranslate } from 'hooks/translate';
import { IntakeFormValues, IntakeSection } from 'pages/authenticated/requests/Intake';
import ProgressBar from './ProgressBar';

export enum Type {
  Intake = 'intake',
  EditSubscription = 'edit-subscription',
}

type Props = {
  type: Type;
  title: string;
  disabled: boolean;
  onSaveConcept?: () => void;
  isLoading?: boolean;
};

export default function Wrapper({
  type,
  title,
  disabled,
  onSaveConcept,
  isLoading,
  children,
}: PropsWithChildren<Props>) {
  const translateText = useTranslate();
  const { scrollToTop } = useGlobal();
  const { values, setFieldValue, resetForm, isSubmitting, submitForm, isValid } = useFormikContext<
    IntakeFormValues | EditSubscriptionFormValues
  >();

  useUpdateEffect(() => {
    scrollToTop();
  }, [values.section, scrollToTop]);

  const sections = Object.values(type === Type.Intake ? IntakeSection : EditSubscriptionSection);
  const sectionIndex = sections.indexOf(values.section);

  function previous() {
    if (values.section === IntakeSection.Thanks) {
      resetForm();
    } else {
      setFieldValue('section', sections[sectionIndex - 1]);
    }
  }

  let nextText: string | null = translateText('label', 'Next step');
  if (values.section === IntakeSection.Summary) {
    nextText = translateText('label', 'Send');
  } else if (values.section === IntakeSection.Thanks) {
    if (type === Type.EditSubscription) nextText = null;
    else nextText = translateText('label', 'Overview');
  }
  return (
    <div className={`${type}-wrapper`}>
      <h1>{isLoading ? <Skeleton width={title.length * 15} /> : title}</h1>
      <ProgressBar type={type} />
      {children}
      <div className="step-button-wrapper">
        {sectionIndex !== 0 &&
          !(
            type === Type.EditSubscription && values.section === EditSubscriptionSection.Thanks
          ) && (
            <button
              className="btn btn-lightblue"
              onClick={previous}
              disabled={isSubmitting || disabled || isLoading}
            >
              <div className="arrow arrow-left" />
              {values.section === IntakeSection.Thanks
                ? translateText('label', 'Fill in another request')
                : translateText('label', 'Previous step')}
            </button>
          )}
        <div className="right">
          {onSaveConcept && values.section !== IntakeSection.Thanks && (
            <button
              className="btn btn-text"
              onClick={() => onSaveConcept()}
              disabled={isSubmitting || disabled}
            >
              {translateText('label', 'Save as draft')}
            </button>
          )}
          {nextText && (
            <button
              className="btn btn-green"
              onClick={() =>
                submitForm().then(() => {
                  if (!isValid) {
                    setFieldValue('validated', {
                      ...values.validated,
                      [values.section]: false,
                    });
                  }
                  setTimeout(() => focusErrorElement(), 1); // Focus needs to be called manually instead of with ErrorFocus because the error only appears after validated is set
                })
              }
              disabled={isSubmitting || disabled || isLoading}
            >
              {nextText}
              <div className="arrow arrow-right" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
