import { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import loop from 'assets/images/icons/loop.svg';
import pencil from 'assets/images/icons/pencil.svg';
import { ReactComponent as Plus } from 'assets/images/icons/plus.svg';
import { SUP_ART_URL } from 'globals/support';
import { useHasAdminAccess } from 'hooks/access';
import { useOnClickOutside } from 'hooks/event';
import { useTranslate } from 'hooks/translate';

export default function KnowledgeCenterEdit() {
  const translateText = useTranslate();
  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useOnClickOutside(ref, () => {
    if (dropdownOpen) setDropdownOpen(false);
  });

  const hasAccess = useHasAdminAccess();
  if (!hasAccess) return null;

  let viewEdit = (
    <Link to={location.pathname + '/edit'} className="admin-support-icon-wrapper">
      <img src={pencil} alt="edit" className="admin-support-icon" />
    </Link>
  );
  if (
    location.pathname.endsWith('/new') ||
    location.pathname.startsWith('/knowledge-center/admin-overview')
  ) {
    viewEdit = (
      <div className="admin-support-icon-wrapper disabled">
        <img src={pencil} alt="edit" className="admin-support-icon" />
      </div>
    );
  } else if (location.pathname.endsWith('/edit')) {
    viewEdit = (
      <Link
        to={location.pathname.substring(0, location.pathname.length - 5)}
        className="admin-support-icon-wrapper"
      >
        <img src={loop} alt="preview" className="admin-support-icon" />
      </Link>
    );
  }

  return (
    <>
      {viewEdit}
      <div className="admin-support-dropdown-wrapper" ref={ref}>
        <div className="admin-support-icon-wrapper" onClick={() => setDropdownOpen(open => !open)}>
          <Plus className="admin-support-icon" />
        </div>
        {dropdownOpen && (
          <div className="dropdown-open white-block" onClick={() => setDropdownOpen(false)}>
            <div className="option" onClick={() => navigate(SUP_ART_URL + 'new')}>
              {translateText('label', 'Add article')}
            </div>
            <div className="option" onClick={() => navigate('/knowledge-center/category/new')}>
              {translateText('label', 'Add category')}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
