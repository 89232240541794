import { ReactElement } from 'react';
import { OverlayTrigger, Tooltip as ReactBootstrapTooltip } from 'react-bootstrap';

import tooltipIcon from 'assets/images/icons/tooltip.svg';

type Props = {
  text: string;
  className?: string;
  placement?: 'top' | 'bottom' | 'left' | 'right' | 'top-start' | 'bottom-start';
  html?: ReactElement;
  icon?: string;
  textAsHtml?: boolean;
  show?: boolean; // Leave undefined if you want it to be on hover
  inline?: boolean; // If false, remove 'display: inline-block' from the css
  mostImportant?: boolean;
};

export default function Tooltip({
  text,
  className,
  placement,
  html,
  icon,
  textAsHtml,
  show,
  inline = true,
  mostImportant,
}: Props) {
  const tooltipText = textAsHtml ? <div dangerouslySetInnerHTML={{ __html: text }} /> : text;
  let wrapperClass = 'tooltip-wrapper';
  if (inline) wrapperClass += ' inline';
  if (className) wrapperClass += ' ' + className;
  return (
    <div className={wrapperClass}>
      <OverlayTrigger
        placement={placement ?? 'auto'}
        overlay={
          <ReactBootstrapTooltip className={mostImportant ? 'most-important' : undefined}>
            {tooltipText}
          </ReactBootstrapTooltip>
        }
        show={show}
      >
        <div>
          {html ?? (
            <img
              src={icon ?? tooltipIcon}
              alt="tooltip"
              className={'tooltip-icon' + (icon ? ' alt-tooltip' : '')}
            />
          )}
        </div>
      </OverlayTrigger>
    </div>
  );
}
