import { ClipboardEvent, useState } from 'react';

import rightArrow from 'assets/images/icons/right-arrow.svg';
import alignCenter from 'assets/images/icons/wysiwyg/align-center.svg';
import alignJustify from 'assets/images/icons/wysiwyg/align-justify.svg';
import alignLeft from 'assets/images/icons/wysiwyg/align-left.svg';
import alignRight from 'assets/images/icons/wysiwyg/align-right.svg';
import bold from 'assets/images/icons/wysiwyg/bold.svg';
import code from 'assets/images/icons/wysiwyg/code.svg';
import compress from 'assets/images/icons/wysiwyg/compress.svg';
import expand from 'assets/images/icons/wysiwyg/expand.svg';
import fontColor from 'assets/images/icons/wysiwyg/font-color.svg';
import image from 'assets/images/icons/wysiwyg/image.svg';
import indent from 'assets/images/icons/wysiwyg/indent.svg';
import italic from 'assets/images/icons/wysiwyg/italic.svg';
import link from 'assets/images/icons/wysiwyg/link.svg';
import orderedList from 'assets/images/icons/wysiwyg/ordered-list.svg';
import outdent from 'assets/images/icons/wysiwyg/outdent.svg';
import preview from 'assets/images/icons/wysiwyg/preview.svg';
import redo from 'assets/images/icons/wysiwyg/redo.svg';
import removeFormat from 'assets/images/icons/wysiwyg/times.svg';
import underline from 'assets/images/icons/wysiwyg/underline.svg';
import undo from 'assets/images/icons/wysiwyg/undo.svg';
import unlink from 'assets/images/icons/wysiwyg/unlink.svg';
import unorderedList from 'assets/images/icons/wysiwyg/unordered-list.svg';
import video from 'assets/images/icons/wysiwyg/video.svg';
import ErrorTooltip from 'components/ErrorTooltip';
import Select from 'components/input/Select';
import TextField from 'components/input/TextField';
import Tooltip from 'components/Tooltip';
import { useTranslate } from 'hooks/translate';

type Props = {
  value: string;
  onChange: (value: string) => void;
  previewClassName?: string; // Can be used for specific styling on the html preview
  error?: boolean | string;
};

export default function Wysiwyg({ value, onChange, previewClassName, error }: Props) {
  const translateText = useTranslate();

  // This state is needed because if the value gets updated from the outside in the editable
  // div, the cursor jumps to the start and the text gets typed backwards. Therefore, we only
  // pass the initial text and the changes are handled by the div itself. The initial text
  // only gets updated when you edit the source code.
  const [initialValue, setInitialValue] = useState(value);

  const [showSourceCode, setShowSourceCode] = useState(false);
  const [showFullScreen, setShowFullScreen] = useState(false);

  function onPaste(e: ClipboardEvent) {
    e.stopPropagation();
    e.preventDefault();
    const text = e.clipboardData
      ?.getData('text/plain')
      .split('\n')
      .map(line => (line ? `<p>${line}</p>` : '<br>'))
      .join('');
    document.execCommand('insertHTML', false, text);
  }

  function getButton(icon: string, description: string, onClick: () => void, disabled?: boolean) {
    return (
      <Tooltip
        html={
          <button tabIndex={-1} disabled={disabled} onClick={onClick}>
            <img src={icon} alt={description} width={14} height={14} />
          </button>
        }
        placement="bottom"
        text={description}
      />
    );
  }

  function getCommandButton(command: string, icon: string, description: string) {
    const onClick = () => document.execCommand(command, false, '');
    return getButton(icon, description, onClick, showSourceCode);
  }

  function getPromptButton(
    command: string,
    icon: string,
    description: string,
    promptText: string,
    type?: 'video',
  ) {
    const onClick = () => showPrompt(command, promptText, type);
    return getButton(icon, description, onClick, showSourceCode);
  }

  function showPrompt(command: string, promptText: string, type?: 'video') {
    let value = prompt(promptText);
    if (value) {
      if (type === 'video') {
        value = `<iframe src="${value}" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`;
      }
      document.execCommand(command, false, value);
    }
  }

  let input;
  if (showSourceCode) {
    input = (
      <TextField
        type="textarea"
        className="wysiwyg-input source"
        wrapperClassName="full"
        value={value}
        onPaste={onPaste}
        onChange={e => {
          onChange(e.target.value);
          setInitialValue(e.target.value);
        }}
        error={error}
      />
    );
  } else {
    input = (
      <ErrorTooltip
        error={error}
        html={
          <div
            role="textbox"
            className={
              'wysiwyg-input' +
              (previewClassName ? ' ' + previewClassName : '') +
              (error ? ' error' : '')
            }
            contentEditable
            onInput={e => onChange((e.target as HTMLDivElement).innerHTML)}
            dangerouslySetInnerHTML={{ __html: initialValue }}
            onClick={() => value === '' && document.execCommand('formatBlock', false, 'div')}
            onPaste={onPaste}
            tabIndex={1}
          />
        }
      />
    );
  }

  return (
    <div className={'wysiwyg' + (showFullScreen ? ' full-screen' : '')}>
      <div className="header-bar">
        {getCommandButton('undo', undo, translateText('label', 'Undo'))}
        {getCommandButton('redo', redo, translateText('label', 'Redo'))}
        <span className="separator" />
        {getCommandButton('bold', bold, translateText('label', 'Bold'))}
        {getCommandButton('italic', italic, translateText('label', 'Italic'))}
        {getCommandButton('underline', underline, translateText('label', 'Underline'))}
        {getCommandButton('removeFormat', removeFormat, translateText('label', 'Clear formatting'))}
        <span className="separator" />
        {getCommandButton('justifyFull', alignJustify, translateText('label', 'Justify'))}
        {getCommandButton('justifyCenter', alignCenter, translateText('label', 'Center'))}
        {getCommandButton('justifyLeft', alignLeft, translateText('label', 'Align left'))}
        {getCommandButton('justifyRight', alignRight, translateText('label', 'Align right'))}
        <span className="separator" />
        {getCommandButton('insertOrderedList', orderedList, translateText('label', 'Ordered list'))}
        {getCommandButton(
          'insertUnorderedList',
          unorderedList,
          translateText('label', 'Unordered list'),
        )}
        {getCommandButton('indent', indent, translateText('label', 'Indent'))}
        {getCommandButton('outdent', outdent, translateText('label', 'Outdent'))}
        <span className="separator" />
        {getPromptButton(
          'createLink',
          link,
          translateText('label', 'Add link'),
          translateText('label', 'Enter a url'),
        )}
        {getCommandButton('unlink', unlink, translateText('label', 'Unlink'))}
        <span className="separator" />
        {getPromptButton(
          'foreColor',
          fontColor,
          translateText('label', 'Text color'),
          translateText('label', 'Enter a color'),
        )}
        <span className="separator" />
        <Select
          tabIndex={-1}
          id="headings"
          disabled={showSourceCode}
          defaultValue="select"
          className="full"
          onChange={e => {
            if (e.target.value === 'quote') {
              const selected = (document.getSelection() ?? '').toString();
              document.execCommand('insertHTML', false, `<div class="quote">${selected}</div>`);
            } else {
              document.execCommand('formatBlock', false, e.target.value);
            }
            e.currentTarget.value = 'select';
          }}
          addSelect
          selectText={translateText('duplicate', 'Format')}
          options={{
            H1: 'Header 1',
            H2: 'Header 2',
            H3: 'Header 3',
            H4: 'Header 4',
            p: translateText('label', 'Paragraph'),
            div: 'Div',
            pre: translateText('label', 'Code-block'),
            quote: translateText('label', 'Block quote'),
          }}
        />
        <span className="separator" />
        {getPromptButton(
          'insertImage',
          image,
          translateText('label', 'Add image'),
          translateText('label', 'Enter an image url'),
        )}
        {getPromptButton(
          'insertHTML',
          video,
          translateText('label', 'Add video'),
          translateText('label', 'Enter a video url'),
          'video',
        )}
        {getButton(
          rightArrow,
          translateText('label', 'Add expandable paragraph'),
          () => {
            const title = prompt(
              translateText('text', 'Enter a title for the expandable paragraph'),
            );
            if (!title) return;

            let selected = (document.getSelection() ?? '').toString();
            if (selected === '') selected = translateText('label', 'Paragraph here');
            document.execCommand(
              'insertHTML',
              false,
              `<br><details><summary><div><span>${title}</span><span class="arrow"></span></div></summary><p>${selected}</p></details><br>`,
            );
          },
          showSourceCode,
        )}
        {/* TODO AC-7857 Add gallery */}
        <span className="separator" />
        {getButton(
          showSourceCode ? preview : code,
          showSourceCode
            ? translateText('label', 'Show preview')
            : translateText('label', 'Show source code'),
          () => {
            setInitialValue(value);
            setShowSourceCode(!showSourceCode);
          },
        )}
        <span className="separator" />
        {getButton(
          showFullScreen ? compress : expand,
          showFullScreen
            ? translateText('label', 'Close full screen')
            : translateText('label', 'Full screen'),
          () => setShowFullScreen(!showFullScreen),
        )}
      </div>
      {input}
    </div>
  );
}
