import { useQuery } from '@tanstack/react-query';

import { useFetch } from 'hooks/fetch';

type Area = {
  code: string;
};

export function useAreas(countryCode: number) {
  const { fetchData } = useFetch();

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ['area', countryCode],
    queryFn: (): Promise<Area[]> =>
      fetchData('/area/index', { queryParams: { country_code: countryCode } }),
    meta: { persist: true },
  });

  return { areas: data, isLoading };
}
