import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import ErrorFocus from 'components/ErrorFocus';
import Select from 'components/input/Select';
import SelectFromList from 'components/input/SelectFromList';
import TextField from 'components/input/TextField';
import Toggle from 'components/input/Toggle';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import { useMessages } from 'contexts/Messages';
import { useTenant } from 'contexts/Tenant';
import { USER_STATUS_INVITED } from 'globals/constants';
import { useTableData } from 'hooks/data';
import { useAssignableLocations, useAssignAndRevokePermission } from 'hooks/queries/aen-permission';
import { useInviteUser } from 'hooks/queries/auth';
import { useRevokeInvitation } from 'hooks/queries/invitation';
import { useAssignableRoles, useAssignAndRevokeRole, useRevokeRole } from 'hooks/queries/role';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation } from 'hooks/validation';

export type DomainUserFormValues = {
  email: string;
  role: string;
  specificLocations: boolean;
  locations: string[];
};

export type DomainUser = {
  id?: number;
  first_name: string;
  last_name: string;
  email: string;
  highestRole: { id: string; type: number; description: string } | null;
  phonenumber: string;
  company: string;
  status: string;
  status_id?: number;
  aens: { name: string; description: string }[];
  lastLogin: string;
};

type Props = {
  showUser: number | 'new' | false;
  close: () => void;
};

export default function DomainUserSetup({ showUser, close }: Props) {
  const translateText = useTranslate();
  const { email, requiredSelect } = useCustomValidation();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const { roles } = useAssignableRoles();
  const { assignableLocations } = useAssignableLocations();
  const { revokeInvitation, isLoading: isRevokingInvitation } = useRevokeInvitation();
  const { revokeRole, isLoading: isRevokingRole } = useRevokeRole();
  const { inviteUser } = useInviteUser();
  const { assignAndRevokeRole } = useAssignAndRevokeRole();
  const { assignAndRevokePermission } = useAssignAndRevokePermission();

  const users = useTableData<DomainUser>(['domain', 'users', selectedDomain!]);
  const [user, setUser] = useState<DomainUser>(emptyUser);

  useEffect(() => {
    if (showUser !== false) {
      setUser(typeof showUser === 'number' ? users!.find(u => u.id === showUser)! : emptyUser);
    }
  }, [showUser]); // eslint-disable-line react-hooks/exhaustive-deps

  const isNewUser =
    !user?.id || user?.highestRole?.type === -1 || user?.status_id === USER_STATUS_INVITED;

  async function save(values: DomainUserFormValues) {
    const promise =
      showUser === 'new'
        ? inviteUser(values)
        : Promise.all([
            assignAndRevokeRole({ userId: user.id!, role: values.role }),
            assignAndRevokePermission({ userId: user.id!, permissions: values.locations }),
          ]).then(() => addSuccessMessage(translateText('message', 'Changes saved')));
    await promise.then(close);
  }

  async function revokeAccess() {
    const promise = isNewUser
      ? revokeInvitation(user.highestRole!.id)
      : revokeRole({ user: { id: user.id!, email: user.email } });
    await promise.then(close);
  }

  const validationSchema = Yup.object({
    email,
    role: requiredSelect,
    locations: Yup.array().test({
      test: function (value) {
        return !this.parent.specificLocations || !!value?.length;
      },
      message: translateText(
        'message',
        'Select at least one location that the user will be granted access to.',
      ),
    }),
  });

  const initialValues: DomainUserFormValues = {
    email: user?.email ?? '',
    role: user?.highestRole?.id ? String(user.highestRole.id) : 'select',
    specificLocations: !!user?.aens?.length,
    locations: user?.aens?.map(l => l.name) ?? [],
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={save}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({
        values,
        handleChange,
        setFieldValue,
        dirty,
        submitForm,
        isSubmitting,
        errors,
        submitCount,
        resetForm,
      }) => (
        <Setup
          id="invite-user"
          show={showUser !== false}
          close={close}
          afterClose={resetForm}
          save={
            user?.highestRole?.type !== -1 && user?.status_id !== USER_STATUS_INVITED
              ? submitForm
              : undefined // Save button should not be showed when user is invited
          }
          title={
            user.id ? translateText('label', 'User info') : translateText('label', 'Invite user')
          }
          saveButtonText={!user.id ? translateText('label', 'Invite') : undefined}
          isSaving={isSubmitting}
          maxWidth={isNewUser ? 400 : 700}
          hasChanges={dirty}
        >
          <ErrorFocus element={document.getElementById('invite-user')} />
          {!isNewUser && (
            <>
              <InputWrapper label={translateText('label', 'First name')}>
                <span>{user?.first_name}</span>
              </InputWrapper>
              <InputWrapper label={translateText('label', 'Last name')}>
                <span>{user?.last_name}</span>
              </InputWrapper>
            </>
          )}
          <InputWrapper label={translateText('label', 'Email address')}>
            {!user.id ? (
              <TextField
                name="email"
                value={values.email}
                onChange={handleChange}
                placeholder={translateText('label', 'Email address')}
                error={submitCount > 0 && errors.email}
              />
            ) : (
              <span>{values.email}</span>
            )}
          </InputWrapper>
          <InputWrapper label={translateText('label', 'Rights')}>
            {user?.highestRole?.type !== -1 && user?.status_id !== USER_STATUS_INVITED ? (
              <Select
                name="role"
                options={roles ?? []}
                optionValue="id"
                optionText="description"
                value={values.role}
                onChange={handleChange}
                error={submitCount > 0 && errors.role}
                addSelect
              />
            ) : (
              <span>{user?.highestRole?.description}</span>
            )}
          </InputWrapper>
          {!isNewUser && (
            <>
              <InputWrapper label={translateText('label', 'Status')}>{user?.status}</InputWrapper>
              <InputWrapper label={translateText('label', 'Company')}>{user?.company}</InputWrapper>
              <InputWrapper label={translateText('label', 'Phone number')}>
                {user?.phonenumber}
              </InputWrapper>
              <InputWrapper label={translateText('label', 'Last login')}>
                {user?.lastLogin}
              </InputWrapper>
              <InputWrapper
                label={translateText('label', 'Grant access to specific locations')}
                tooltip={translateText(
                  'tooltip/domain',
                  'Do you want this user to have access to specific locations of the domain? Then choose this option and select the locations which the user is allowed to access.',
                )}
              >
                <Toggle
                  name="specificLocations"
                  checked={values.specificLocations}
                  onClick={handleChange}
                />
              </InputWrapper>
            </>
          )}
          {values.specificLocations && (
            <SelectFromList
              id="assignable-locations"
              idKey="identifier"
              items={
                assignableLocations?.map(l => ({
                  ...l,
                  name: l.name ? l.name + ' (' + l.identifier + ')' : l.identifier,
                })) ?? []
              }
              selected={values.locations}
              onChange={value => setFieldValue('locations', value)}
              availableLabel={translateText('label', 'Available locations')}
              selectedLabel={translateText('label', 'Assigned locations')}
              error={submitCount > 0 && (errors.locations as string)}
            />
          )}
          {!!user.id && (
            <button
              className="btn btn-text purple-text no-padding margin-top"
              disabled={isRevokingRole || isRevokingInvitation}
              onClick={() => revokeAccess()}
            >
              {translateText('label', 'Revoke access')}
            </button>
          )}
        </Setup>
      )}
    </Formik>
  );
}

const emptyUser: DomainUser = {
  email: '',
  first_name: '',
  last_name: '',
  lastLogin: '',
  status: '',
  phonenumber: '',
  company: '',
  highestRole: null,
  aens: [],
};
