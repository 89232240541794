import { ReactElement, useRef, useState } from 'react';

import ErrorTooltip from 'components/ErrorTooltip';
import Tooltip from 'components/Tooltip';
import { isReactNode } from 'globals/helpers';
import { useTranslate } from 'hooks/translate';
import TextField from './TextField';

type Props<T> = {
  id: string;
  disabled?: boolean;
  items: ({ searchTerms?: readonly string[] } & T)[];
  selected?: string[];
  onChange: (value: (string | T)[]) => void;
  idKey?: keyof T;
  nameKey?: keyof T;
  previewKey?: keyof T;
  selectedLabel?: string;
  availableLabel?: string;
  selectItem?: (item: T) => void;
  deleteItem?: (item: T) => void;
  limit?: number | null;
  error?: boolean | string;
  tooltip?: string;
  canChangeOrder?: boolean;
};

export default function SelectFromList<T extends Record<string, unknown>>({
  id,
  disabled,
  items,
  selected,
  onChange,
  idKey = 'id',
  nameKey = 'name',
  previewKey,
  selectedLabel,
  availableLabel,
  selectItem,
  deleteItem,
  limit,
  error,
  tooltip,
  canChangeOrder,
}: Props<T>) {
  const translateText = useTranslate();
  const [selectedItem, setSelectedItem] = useState('');
  const [searchAvailable, setSearchAvailable] = useState('');
  const [searchSelected, setSearchSelected] = useState('');
  const selectedElementContainerRef = useRef<HTMLDivElement>(null);

  function getListRow(item: (typeof items)[number], index: number, search = '') {
    if (
      search !== '' &&
      ![item[nameKey], ...(item.searchTerms ?? [])].some(t =>
        String(t).toLowerCase().includes(search.toLowerCase()),
      )
    ) {
      return null;
    }

    const isSelected = selectedItem === String(item[idKey]);
    const previewUrl = previewKey ? item[previewKey] : null;
    const content = item[nameKey];
    return (
      <div
        key={'item' + index}
        className={'list-item' + (isSelected ? ' selected' : '')}
        data-testid="list-item"
        onClick={() => {
          setSelectedItem(isSelected ? '' : String(item[idKey]));
          if (selectItem) selectItem(item);
        }}
      >
        {isReactNode(content) && content}
        {(deleteItem || typeof previewUrl === 'string') && (
          <div className="addition">
            {typeof previewUrl === 'string' && (
              <img alt="preview" src={previewUrl} className="preview-image" />
            )}
            {deleteItem && (
              <div onClick={() => deleteItem(item)} className="close">
                ×
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  function addItem() {
    if (selected?.includes(selectedItem)) return;
    onChange([...(selected ?? []), selectedItem]);
  }

  function removeItem() {
    onChange((selected ?? []).filter(i => i !== selectedItem));
  }

  function changeOrder(direction: 'up' | 'down') {
    const arrayToChange = selected ?? items;
    const newOrder = [...arrayToChange];
    const index = arrayToChange.findIndex(i => {
      const arrayItem = typeof i === 'object' ? String(i[idKey]) : i;
      return arrayItem === selectedItem;
    });

    const newIndex = index + (direction === 'up' ? -1 : 1);
    if (newOrder[newIndex] === undefined) return;

    const current = newOrder[index];
    newOrder[index] = newOrder[newIndex];
    newOrder[newIndex] = current;
    onChange(newOrder);
  }

  const selectedList: (ReactElement | null)[] = [];
  const availableList: (ReactElement | null)[] = [];

  items.forEach((item, index) => {
    const addedItemIndex = selected?.findIndex(i => String(i) === String(item[idKey]));
    if (addedItemIndex !== undefined && addedItemIndex !== -1) {
      selectedList[addedItemIndex] = getListRow(item, index, searchSelected);
    } else {
      availableList.push(getListRow(item, index, searchAvailable));
    }
  });

  let columnClass = 'column';
  let addRemoveButtons = null;
  let selectedElement = null;
  let changeOrderElement = null;
  let searchSelectedElement = null;

  if (canChangeOrder) {
    columnClass += ' change-order';
    changeOrderElement = (
      <div className="select-from-list-button-wrapper">
        <div
          className="select-from-list-button"
          data-testid="up"
          onClick={() => {
            setSearchAvailable('');
            setSearchSelected('');
            changeOrder('up');
          }}
        >
          <div className="arrow-box">
            <div className="arrow arrow-up arrow-white" />
          </div>
        </div>
        <div
          className="select-from-list-button"
          data-testid="down"
          onClick={() => {
            setSearchAvailable('');
            setSearchSelected('');
            changeOrder('down');
            const container = selectedElementContainerRef.current;
            const selectedElement = document.querySelector<HTMLDivElement>('#' + id + ' .selected');
            if (!selectedElement || !container) return;
            container.scrollTo(0, selectedElement.offsetTop);
          }}
        >
          <div className="arrow-box">
            <div className="arrow arrow-down arrow-white" />
          </div>
        </div>
      </div>
    );
  }

  if (selected) {
    columnClass += ' select';
    let canAddItem = false;
    let canRemoveItem = false;
    if (selectedItem) {
      if (selected.includes(selectedItem)) {
        canRemoveItem = true;
      } else if (!limit || selected.length < limit) {
        canAddItem = true;
      }
    }

    const onAddClick = canAddItem
      ? () => {
          setSearchAvailable('');
          addItem();
        }
      : () => null;

    const onRemoveClick = canRemoveItem
      ? () => {
          setSearchSelected('');
          removeItem();
        }
      : () => null;

    addRemoveButtons = (
      <div className="select-from-list-button-wrapper">
        <div
          className={'select-from-list-button add' + (disabled || !canAddItem ? ' disabled' : '')}
          onClick={onAddClick}
        >
          <div className="arrow-box">
            <div className="arrow arrow-right arrow-white" />
          </div>
          <div className="arrow-label">{translateText('label', 'Add')}</div>
        </div>
        <div
          className={
            'select-from-list-button remove' + (disabled || !canRemoveItem ? ' disabled' : '')
          }
          onClick={onRemoveClick}
        >
          <div className="arrow-box">
            <div className="arrow arrow-left arrow-white" />
          </div>
          <div className="arrow-label">{translateText('label', 'Remove')}</div>
        </div>
      </div>
    );

    selectedElement = (
      <div className={columnClass}>
        <ErrorTooltip
          error={error}
          html={
            <div
              id={id + '-right-list'}
              tabIndex={1}
              className={'list' + (error ? ' error' : '')}
              data-testid="right"
              ref={selectedElementContainerRef}
            >
              {selectedList}
            </div>
          }
        />
      </div>
    );

    searchSelectedElement = (
      <div className={columnClass + ' search-selected'}>
        <span className="bold">{selectedLabel}</span>
        <TextField
          placeholder={translateText('label', 'Search') + '..'}
          value={searchSelected}
          onChange={e => setSearchSelected(e.target.value)}
        />
      </div>
    );
  }

  return (
    <div id={id} className="select-from-list-wrapper">
      <div className="row">
        <div className={columnClass}>
          <span className="bold">
            {availableLabel}
            {tooltip && <Tooltip text={tooltip} />}
          </span>
          <TextField
            placeholder={translateText('label', 'Search') + '..'}
            value={searchAvailable}
            onChange={e => setSearchAvailable(e.target.value)}
          />
        </div>
        {searchSelectedElement}
      </div>
      <div className="row">
        <div className={columnClass}>
          <div className="list" data-testid="left">
            {availableList}
          </div>
        </div>
        {addRemoveButtons}
        {selectedElement}
        {changeOrderElement}
      </div>
    </div>
  );
}
