import { Link, useNavigate } from 'react-router-dom';

import QueryTable from 'components/table/QueryTable';
import { useTranslate } from 'hooks/translate';

export default function Blacklist() {
  const translateText = useTranslate();
  const navigate = useNavigate();
  return (
    <>
      <h1>{translateText('label', 'Black listed phone numbers')}</h1>
      <QueryTable<{ id: number; number: string; created_at: string; blacklisted_till: string }>
        tableKey={['admin', 'blacklistedNumber', 'index']}
        columns={{
          number: {
            header: { name: translateText('label', 'Number'), sort: true },
            search: true,
            noNumberFormat: true,
          },
          created_at: {
            header: { name: translateText('label', 'Created on'), sort: true },
            search: true,
            date: true,
          },
          blacklisted_till: {
            header: { name: translateText('label', 'Blacklisted till'), sort: true },
            search: true,
            date: { connector: 'till' },
          },
        }}
        onRowClick={row => navigate('/admin/phone-numbers/blacklist/' + row.id)}
        endpoint="/blacklisted-number/index"
        pagination
        filters={{ domain: false }}
        refresh
        extraTopRight={
          <Link to="/admin/phone-numbers/blacklist/new" className="btn btn-lightblue margin-right">
            {translateText('label', 'Add phone number to blacklist')}
          </Link>
        }
      />
    </>
  );
}
