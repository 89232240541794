import { MutableRefObject, ReactElement } from 'react';

import criticalWarning from 'assets/images/icons/table/icon-status-warning.svg';
import EditButton from 'components/buttons/EditButton';
import { BaseItem } from 'components/configuration/card-list/CardListView';
import Tooltip from 'components/Tooltip';
import { PH_MASK } from 'globals/constants';
import { useTranslate } from 'hooks/translate';

type Props = {
  setEdit: () => void;
  item: BaseItem | false;
  showIdentifier: boolean;
  renderContent: (item: BaseItem) => ReactElement;
  noAccessCheck: boolean;
  cardRef: MutableRefObject<HTMLDivElement | null>;
};

export default function Card({
  setEdit,
  item,
  showIdentifier,
  renderContent,
  noAccessCheck,
  cardRef,
}: Props) {
  const translateText = useTranslate();

  if (!item) return null;

  let extra = null;
  if (item.extra) extra = item.extra;
  else if (item.warning) extra = <div className="pending">{item.warning}</div>;

  return (
    <div className="card-view white-block" ref={cardRef}>
      <EditButton noAccessCheck={noAccessCheck} onClick={() => setEdit()} />
      <div className="card-header">
        <div className="left">
          {showIdentifier && item.identifier && <div>{item.identifier}</div>}
          <div className={PH_MASK}>{item.name}</div>
        </div>
        <div className="right">
          {item.criticalWarning && <Tooltip icon={criticalWarning} text={item.criticalWarning} />}
          {extra}
          {item.enabled !== undefined && (
            <div className="status">
              <div className={'big-dot' + (item.enabled ? ' active' : ' inactive')} />
              {item.enabled
                ? translateText('label', 'Enabled')
                : translateText('label', 'Disabled')}
            </div>
          )}
        </div>
      </div>
      {renderContent(item)}
    </div>
  );
}
