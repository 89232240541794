import { PropsWithChildren } from 'react';

import Tooltip from 'components/Tooltip';
import { PH_MASK } from 'globals/constants';
import { useTranslate } from 'hooks/translate';
import Skeleton from './Skeleton';

type BaseProps = {
  title?: string;
  titleTooltip?: string;
  emptyLabel?: string;
  isLoading?: boolean;
};

type StringRows = {
  rows: string[];
  singleItems?: boolean;
  tooltipColumn?: never;
  private?: boolean;
} & BaseProps;

type Row = {
  key: string;
  value?: string;
  tooltip?: string;
  status?: boolean;
  statusLabel?: string;
  private?: boolean;
};

type ObjectRows = {
  rows: Row[];
  singleItems?: never;
  tooltipColumn?: boolean;
  private?: never;
} & BaseProps;

export default function GrayBars(props: StringRows | ObjectRows) {
  const { title, titleTooltip, emptyLabel, tooltipColumn, singleItems, isLoading } = props;
  let rows = props.rows;
  const translateText = useTranslate();

  let titleElement = null;
  if (title) {
    titleElement = (
      <h4 className="bold gray-bar gray-bar-title">
        {isLoading ? <Skeleton width={200} /> : title}
        {!isLoading && titleTooltip && <Tooltip text={titleTooltip} />}
      </h4>
    );
  }

  const className = 'gray-bar-wrapper';
  if (rows.length === 0 && !isLoading) {
    if (emptyLabel) {
      return (
        <div className={className}>
          <div className="gray-bar gray-bar-title no-data">{emptyLabel}</div>
        </div>
      );
    }

    if (title) return <div className={className}>{titleElement}</div>;
    return null;
  }

  function renderStrings() {
    const length = rows.length;
    if (singleItems) rows = rows.slice(0, 9);
    const rowItems = rows.map((row, i) => (
      <div key={i} className="gray-bar">
        <div className={'gray-bar-item' + (props.private ? ' ' + PH_MASK : '')}>
          {row as string}
        </div>
      </div>
    ));
    if (singleItems && length > 10) {
      rowItems.push(
        <div className="gray-bar" key={9}>
          <div className="gray-bar-item bold">+ {length - 9}</div>
        </div>,
      );
    }
    return rowItems;
  }

  function renderObjects() {
    return (rows as Row[]).map((row, index) => {
      let status = null;
      let value = row.value;
      if (row.status !== undefined) {
        value = row.status ? translateText('label', 'Enabled') : translateText('label', 'Disabled');
        if (row.statusLabel) value += ' (' + row.statusLabel + ')';
        status = <div className={'big-dot ' + (row.status ? 'active' : 'inactive')} />;
      }

      const tooltip = row.tooltip ? <Tooltip text={row.tooltip} /> : null;

      let itemLabel = (
        <span className="item-label bold">
          {row.key}
          {tooltip}
        </span>
      );

      if (tooltipColumn) {
        itemLabel = (
          <>
            <span className="item-label bold no-tooltip">{row.key}</span>
            <div className="item-tooltip">{tooltip}</div>
          </>
        );
      }

      return (
        <div className="gray-bar" key={index}>
          <div className="gray-bar-item">
            {itemLabel}
            <div className="value-wrapper">
              <div className={'main-value' + (row.private ? ' ' + PH_MASK : '')}>
                {status}
                {value}
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  const skeleton = (
    <Skeleton
      wrapper={({ children }: PropsWithChildren) => <div className="gray-bar">{children}</div>}
      className="gray-bar-item"
      count={rows.length || (singleItems ? 4 : 3)}
      noMargin
    />
  );

  return (
    <div className={className + (singleItems ? ' two-columns' : '')}>
      {titleElement}
      {isLoading ? skeleton : typeof rows[0] === 'string' ? renderStrings() : renderObjects()}
    </div>
  );
}
