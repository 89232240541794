import { useParams } from 'react-router';
import { Formik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import AddDebtor from 'components/admin/AddDebtor';
import ErrorFocus from 'components/ErrorFocus';
import Checkbox from 'components/input/Checkbox';
import Select from 'components/input/Select';
import TextField from 'components/input/TextField';
import Loader from 'components/Loader';
import {
  DOMAIN_STATUS_ACTIVE,
  DOMAIN_STATUS_BEFORE_TRIAL,
  DOMAIN_STATUS_INACTIVE,
  DOMAIN_STATUS_INACTIVE_NOT_SIGNED,
  DOMAIN_STATUS_REQUESTED,
  DOMAIN_STATUS_REQUESTED_NOT_SIGNED,
  DOMAIN_STATUS_STOPPED,
} from 'globals/constants';
import { useHasAdminAccess } from 'hooks/access';
import { useChargebeeCustomers } from 'hooks/queries/debtor';
import { useAdminDomainDetails, useUpdateDebtor, useUpdateDomain } from 'hooks/queries/domain';
import { useResellers } from 'hooks/queries/reseller';
import { useAccountManagers } from 'hooks/queries/user';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation } from 'hooks/validation';

export default function DomainDetails() {
  const translateText = useTranslate();
  const isAdmin = useHasAdminAccess();
  const { requiredSelect } = useCustomValidation();
  const { accountManagers, isLoading: isLoadingAccountManagers } = useAccountManagers();
  const { resellers, isLoading: isLoadingResellers } = useResellers();
  const { customers, isLoading: isLoadingCustomers } = useChargebeeCustomers();

  const params = useParams();
  const domainId = Number(params.id);
  const { domain, isLoading, isError } = useAdminDomainDetails(!isNaN(domainId) ? domainId : null);
  const { updateDomain } = useUpdateDomain(domainId);
  const { updateDebtor } = useUpdateDebtor(domainId);

  if (isNaN(domainId) || isError) {
    return <p>{translateText('label', 'This domain does not exist')}</p>;
  }
  if (isLoading || isLoadingAccountManagers || isLoadingResellers || isLoadingCustomers) {
    return <Loader />;
  }

  async function saveInvoicing(values: {
    customer: string;
    isTrial: boolean;
    trialEndDate: string;
  }) {
    if (
      !domain?.chargebee_customer_id ||
      window.confirm(
        translateText(
          'message',
          "You're trying to update a subscription that has already been delivered. Are you sure you want to update it?",
        ),
      )
    ) {
      await updateDebtor({
        customer: values.customer,
        trialEndDate: values.isTrial ? values.trialEndDate : '',
      });
    }
  }

  const invoicingValidation = Yup.object({
    customer: requiredSelect,
    trialEndDate: Yup.string().test({
      message: translateText('message', 'The entry is invalid (desired format: {format}).', {
        format: translateText('label', 'MM-YYYY'),
      }),
      test: function (value) {
        return this.parent.isTrial === false || moment(value, 'MM-YYYY', true).isValid();
      },
    }),
  });

  return (
    <div className="domain-details">
      <h1>{translateText('label', 'Domain')}</h1>
      <Formik
        initialValues={{
          accountManager: String(domain?.account_manager?.id ?? -1),
          url: domain?.url ?? '',
          title: domain?.title ?? '',
          reseller: String(domain?.reseller_id ?? ''),
          status: String(domain?.status ?? -1),
          isActive: domain?.is_active ?? false,
          callRecording: domain?.is_callrecording_enabled ?? false,
          leadRating: domain?.is_leadrating_enabled ?? false,
        }}
        enableReinitialize
        onSubmit={values => updateDomain(values)}
      >
        {({ values, handleChange, submitForm, dirty, isSubmitting }) => (
          <div className="white-block">
            <h3>{translateText('label', 'Details')}</h3>
            <div className="form-row">
              <div className="name-label">{translateText('label', 'ID')}</div>
              <div className="value-wrapper">{domain?.id}</div>
            </div>
            <div className="form-row">
              <div className="name-label">{translateText('label', 'URL')}</div>
              <div className="value-wrapper">
                <TextField
                  name="url"
                  value={values.url}
                  onChange={handleChange}
                  canEdit={isAdmin}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="name-label">{translateText('label', 'Title')}</div>
              <div className="value-wrapper">
                <TextField
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  canEdit={isAdmin}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="name-label">{translateText('label', 'Account manager')}</div>
              <div className="value-wrapper">
                <Select
                  name="accountManager"
                  value={values.accountManager}
                  options={accountManagers ?? {}}
                  onChange={handleChange}
                  canEdit={isAdmin}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="name-label">{translateText('label', 'Partner')}</div>
              <div className="value-wrapper">
                <Select
                  name="reseller"
                  value={values.reseller}
                  options={resellers ?? []}
                  onChange={handleChange}
                  canEdit={isAdmin}
                  optionText="name"
                  optionValue="id"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="name-label">{translateText('label', 'Status')}</div>
              <div className="value-wrapper">
                <Select
                  name="status"
                  value={values.status}
                  onChange={handleChange}
                  canEdit={isAdmin}
                  options={{
                    [DOMAIN_STATUS_STOPPED]: translateText('label', 'Stopped'),
                    [DOMAIN_STATUS_INACTIVE]: translateText('label', 'Inactive'),
                    [DOMAIN_STATUS_REQUESTED_NOT_SIGNED]: translateText(
                      'label',
                      'Requested: Missing agreement',
                    ),
                    [DOMAIN_STATUS_REQUESTED]: translateText('label', 'Requested'),
                    [DOMAIN_STATUS_INACTIVE_NOT_SIGNED]: translateText(
                      'label',
                      'Inactive: Missing agreement',
                    ),
                    [DOMAIN_STATUS_BEFORE_TRIAL]: translateText('label', 'Before trial'),
                    [DOMAIN_STATUS_ACTIVE]: translateText('label', 'Active'),
                  }}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="name-label">{translateText('label', 'Active')}</div>
              <div className="value-wrapper">
                <Checkbox
                  id="isActive"
                  checked={values.isActive}
                  onChange={handleChange}
                  canEdit={isAdmin}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="name-label">{translateText('label', 'Call recording')}</div>
              <div className="value-wrapper">
                <Checkbox
                  id="callRecording"
                  checked={values.callRecording}
                  onChange={handleChange}
                  canEdit={isAdmin}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="name-label">{translateText('label', 'Lead rating')}</div>
              <div className="value-wrapper">
                <Checkbox
                  id="leadRating"
                  checked={values.leadRating}
                  onChange={handleChange}
                  canEdit={isAdmin}
                />
              </div>
            </div>
            {isAdmin && (
              <div className="form-button">
                <button
                  className="btn btn-green"
                  onClick={submitForm}
                  disabled={!dirty || isSubmitting}
                >
                  {translateText('label', 'Save')}
                </button>
              </div>
            )}
          </div>
        )}
      </Formik>
      <Formik
        initialValues={{
          customer: String(domain?.chargebee_customer_id ?? 'select'),
          isTrial: domain?.is_trial ?? false,
          trialEndDate: domain?.trial_end_date ?? '',
        }}
        enableReinitialize
        onSubmit={saveInvoicing}
        validationSchema={invoicingValidation}
      >
        {({
          values,
          handleChange,
          submitForm,
          dirty,
          setFieldValue,
          isSubmitting,
          submitCount,
          errors,
        }) => (
          <div className="white-block">
            <ErrorFocus />
            <h3>{translateText('label', 'Invoicing')}</h3>
            <div className="form-row">
              <div className="name-label">{translateText('label', 'Chargebee customer')}</div>
              <div className="value-wrapper">
                <Select
                  name="customer"
                  value={values.customer}
                  options={customers ?? []}
                  onChange={handleChange}
                  canEdit={isAdmin}
                  optionText="company"
                  optionValue="id"
                  error={submitCount > 0 && errors.customer}
                  addSelect
                />
                {isAdmin && (
                  <AddDebtor
                    initialValues={domain?.intake_invoicing_details}
                    onSuccess={customer => setFieldValue('customer', customer)}
                  />
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="name-label">{translateText('label', 'Trial')}</div>
              <div className="value-wrapper">
                <Checkbox
                  id="isTrial"
                  checked={values.isTrial}
                  onChange={handleChange}
                  canEdit={isAdmin}
                />
              </div>
            </div>
            {values.isTrial && (
              <div className="form-row">
                <div className="name-label">{translateText('label', 'Trial end date')}</div>
                <div className="value-wrapper">
                  <TextField
                    name="trialEndDate"
                    placeholder={translateText('label', 'MM-YYYY')}
                    value={values.trialEndDate}
                    onChange={handleChange}
                    canEdit={isAdmin}
                    error={submitCount > 0 && errors.trialEndDate}
                  />
                </div>
              </div>
            )}
            <div
              className={'form-button' + (domain?.chargebee_subscription_id ? ' left-button' : '')}
            >
              {domain?.chargebee_subscription_id && (
                <a
                  target="_blank"
                  href={
                    process.env.REACT_APP_CHARGEBEE_URL +
                    '/d/subscriptions/' +
                    domain.chargebee_subscription_id
                  }
                >
                  {translateText('label', 'Go to Chargebee subscription')}
                </a>
              )}
              {isAdmin && (
                <button
                  className="btn btn-green"
                  onClick={submitForm}
                  disabled={!dirty || isSubmitting}
                >
                  {translateText('label', 'Save')}
                </button>
              )}
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}
