import { useFormikContext } from 'formik';

import Select from 'components/input/Select';
import TextField from 'components/input/TextField';
import { SelectedFilters } from 'contexts/Filters';
import { useTranslate } from 'hooks/translate';

type Props = {
  options: Record<string, string>;
};

export default function DurationSelector({ options }: Props) {
  const translateText = useTranslate();
  const { values, setFieldValue } = useFormikContext<SelectedFilters>();

  function onChange(value: string, field: 'seconds' | 'selected') {
    setFieldValue('duration', {
      ...values.duration,
      [field]:
        field === 'seconds'
          ? Math.max(Math.min(Number(value.replace(/[^0-9]/g, '')), 999), 0)
          : value,
    });
  }

  const selectedValue = values.duration?.selected;
  let secondsInput = null;
  if (selectedValue && selectedValue !== 'allCalls') {
    secondsInput = (
      <>
        <TextField
          id="duration-seconds-input"
          wrapperClassName="duration-seconds-input"
          onChange={e => onChange(e.target.value, 'seconds')}
          value={values.duration.seconds}
        />
        <label id="duration-seconds-label" htmlFor="duration-seconds-input">
          {translateText('datetime', 'Seconds').toLowerCase()}
        </label>
      </>
    );
  }
  return (
    <div className="filter-selector duration">
      <Select
        value={selectedValue}
        onChange={e => onChange(e.target.value, 'selected')}
        options={options}
      />
      {secondsInput}
    </div>
  );
}
