import { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import deleteIcon from 'assets/images/icons/delete-purple.svg';
import AddButton from 'components/buttons/AddButton';
import ErrorFocus from 'components/ErrorFocus';
import TextField from 'components/input/TextField';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import Table from 'components/table/Table';
import Tooltip from 'components/Tooltip';
import {
  useAddToBlacklist,
  useEmailBlacklist,
  useRemoveFromBlacklist,
} from 'hooks/queries/system-setting';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation } from 'hooks/validation';

export default function EmailBlacklist() {
  const translateText = useTranslate();
  const { emailBlacklist, isLoading } = useEmailBlacklist();
  const { addToBlacklist } = useAddToBlacklist();
  const { removeFromBlacklist, isLoading: isRemoving } = useRemoveFromBlacklist();
  const { required } = useCustomValidation();

  const [showSetup, setShowSetup] = useState(false);

  const validationSchema = Yup.object({
    item: required.test({
      test: item => !!item && !emailBlacklist?.includes(item.trim()),
      message: translateText('message', 'This value is already on the blacklist.'),
    }),
  });

  function deleteOnClick(item: string) {
    if (
      window.confirm(translateText('admin/text', 'Are you sure you want to delete this entry?'))
    ) {
      removeFromBlacklist(item);
    }
  }

  async function save(values: { item: string }) {
    await addToBlacklist(values.item).then(() => setShowSetup(false));
  }

  return (
    <div>
      <h1>{translateText('menu', 'E-mail blacklist')}</h1>
      <Table<{ item: string; button?: undefined }>
        data={emailBlacklist?.map(item => ({ item })) ?? []}
        columns={{
          item: { header: translateText('label', 'Domain or email address') },
          button: {
            header: '',
            customValue: (value, row) => (
              <Tooltip
                text={translateText('label', 'Remove from blacklist')}
                html={
                  <img
                    src={deleteIcon}
                    alt="Delete"
                    className={'clickable-icon' + (isRemoving ? ' disabled' : '')}
                    onClick={() => deleteOnClick(row.item)}
                  />
                }
              />
            ),
          },
        }}
        extraTopLeft={<AddButton onClick={() => setShowSetup(true)} />}
        isLoading={isLoading}
      />
      <Formik initialValues={{ item: '' }} onSubmit={save} validationSchema={validationSchema}>
        {({
          values,
          errors,
          handleChange,
          submitForm,
          submitCount,
          dirty,
          resetForm,
          isSubmitting,
        }) => (
          <Setup
            show={showSetup}
            close={() => setShowSetup(false)}
            title={translateText('label', 'Add domain or email address to blacklist')}
            save={submitForm}
            hasChanges={dirty}
            afterClose={resetForm}
            isSaving={isSubmitting}
          >
            <ErrorFocus />
            <InputWrapper label={translateText('label', 'Domain or email address')}>
              <TextField
                name="item"
                value={values.item}
                onChange={handleChange}
                error={submitCount > 0 && errors.item}
              />
            </InputWrapper>
          </Setup>
        )}
      </Formik>
    </div>
  );
}
