import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import RestrictedAccess from 'components/RestrictedAccess';
import QueryTable from 'components/table/QueryTable';
import { ROLE_ADMIN } from 'globals/constants';
import { useTranslate } from 'hooks/translate';

export default function ChangeRequests() {
  const translateText = useTranslate();
  const navigate = useNavigate();
  return (
    <>
      <h1>{translateText('label', 'Changes')}</h1>
      <QueryTable
        className="wrap"
        endpoint="/domain-change-request/index"
        tableKey={['admin', 'domainChangeRequest']}
        columns={{
          id: { header: { name: translateText('label', 'ID'), sort: true }, search: true },
          created_at: {
            header: { name: translateText('label', 'Request date'), sort: true },
            search: true,
            date: true,
          },
          domain_title: {
            header: { name: translateText('label', 'Domain'), sort: true },
            search: true,
          },
          requesting_user: {
            header: { name: translateText('label', 'Requesting user'), sort: true },
            search: true,
          },
          type: {
            header: { name: translateText('label', 'Change'), sort: true },
            search: {
              'aen-stop': translateText('label', 'Discontinuation AEN'),
              'deactivate': translateText('label', 'Domain stop'),
              'aen-change': translateText('label', 'Change sources for AEN'),
              'aen-new': translateText('label', 'New AEN'),
              'offsite-new': translateText('label', 'New offsite phone number(s)'),
              'offsite-stop': translateText('label', 'Discontinuation offsite phone number(s)'),
              'sources-change': translateText('label', 'Sources change'),
            },
          },
          status: {
            header: { name: translateText('label', 'Status'), sort: true },
            search: {
              1: translateText('label', 'Open'),
              2: translateText('label', 'Pending'),
              3: translateText('label', 'On hold'),
              4: translateText('label', 'Processed'),
              5: translateText('label', 'Verified'),
              6: translateText('label', 'Refused'),
              7: translateText('label', 'Cancelled'),
            },
          },
          updated_at: {
            header: { name: translateText('label', 'Last change date'), sort: true },
            search: true,
            date: true,
          },
          needed_numbers: {
            header: translateText('label', 'Needed numbers'),
            customValue: value => <pre>{JSON.stringify(value, undefined, 4)}</pre>,
          },
          past_requests: { header: translateText('label', 'Past requests') },
        }}
        extraTopRight={
          <RestrictedAccess role={ROLE_ADMIN}>
            <Link to="/admin/phone-numbers/new" className="btn btn-lightblue margin-right">
              {translateText('label', 'Add new phone numbers')}
            </Link>
          </RestrictedAccess>
        }
        filters={{
          domain: false,
          custom: {
            expand: 'needed_numbers,type_key,past_requests',
            type: '!deactivate,!upgrade-pricing-plan',
          },
        }}
        isResponsive
        onRowClick={row => navigate('/admin/change-requests/' + row.id)}
        pagination={{ defaultLimit: 20 }}
        refresh
      />
    </>
  );
}
