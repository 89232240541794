import { useState } from 'react';

import ConfigurationCard from 'components/configuration/ConfigurationCard';
import NumberFormatSetup from 'components/settings/properties/NumberFormatSetup';
import Skeleton from 'components/Skeleton';
import { useProperties } from 'hooks/queries/domain';
import { useNumberFormats, useUpdateNumberFormat } from 'hooks/queries/domain-setting';
import { useTranslate } from 'hooks/translate';

export default function NumberFormatSection() {
  const translateText = useTranslate();
  const { numberFormats, isLoading: isLoadingFormats } = useNumberFormats();
  const { updateNumberFormat } = useUpdateNumberFormat();
  const { data, isLoading: isLoadingProperties } = useProperties();
  const isLoading = isLoadingProperties || isLoadingFormats;

  const [showEdit, setShowEdit] = useState(false);

  function renderNumberFormatValue() {
    if (isLoading) return <Skeleton width="150px" />;
    if (!numberFormats) return null;

    const foundFormat = numberFormats.find(
      format => String(format.id) === String(data?.number_format),
    );
    if (foundFormat) return foundFormat.format;
    else if (/^[0-9]*$/.test(String(data?.number_format ?? ''))) {
      // If the format is numeric, it is an old custom format that is hardcoded in the API. This format is not selectable in the dropdown.
      return translateText('label', 'Custom');
    }
    let value = String(data?.number_format);
    while ((value?.match(/x/g) ?? []).length < 7) {
      value += 'x';
    }
    return value;
  }

  return (
    <>
      <ConfigurationCard
        onEdit={() => setShowEdit(true)}
        title={translateText('label', 'Phone number format')}
        isLoading={isLoading}
      >
        <div className="number-distribution-status">
          <div className="header bold">
            {isLoading ? <Skeleton width="150px" /> : translateText('label', 'Phone number format')}
          </div>
          {renderNumberFormatValue()}
        </div>

        <p>
          {isLoading ? (
            <Skeleton count={4} />
          ) : (
            translateText(
              'text',
              'The dynamic phone number for Call Tracking will be displayed on the website in this format. We advise you to use the same format as the phone numbers without Call Tracking that are communicated on the website.[manager] Do you want to change the format? Then click on the gears.[/manager]',
            )
          )}
        </p>
      </ConfigurationCard>
      <NumberFormatSetup
        show={showEdit}
        close={() => setShowEdit(false)}
        numberFormat={data?.number_format ?? ''}
        updateNumberFormat={updateNumberFormat}
      />
    </>
  );
}
