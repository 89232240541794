import { useCallback, useState } from 'react';
import { useFormikContext } from 'formik';

import AddButton from 'components/buttons/AddButton';
import ConfigurationCard from 'components/configuration/ConfigurationCard';
import ErrorTooltip from 'components/ErrorTooltip';
import GrayBars from 'components/GrayBars';
import { AREA_CODE, COUNTRY_CODE } from 'components/settings/properties/NumberFormatBuilder';
import NumberFormatSetup from 'components/settings/properties/NumberFormatSetup';
import { useNumberFormats } from 'hooks/queries/domain-setting';
import { useTranslate } from 'hooks/translate';
import { IntakeFormValues, IntakeSection } from 'pages/authenticated/requests/Intake';

export default function NumberFormat() {
  const translateText = useTranslate();
  const { numberFormats } = useNumberFormats();
  const { values: intakeValues, setFieldValue, errors } = useFormikContext<IntakeFormValues>();
  const [showEdit, setShowEdit] = useState(false);

  const save = useCallback(
    (format: string | number) => {
      setFieldValue('format', isNaN(Number(format)) ? format : Number(format));
      return Promise.resolve();
    },
    [setFieldValue],
  );

  let formatForSetup = intakeValues.format;
  if (typeof formatForSetup === 'string') {
    formatForSetup = formatForSetup.replace('{nat}', COUNTRY_CODE).replace('{area}', AREA_CODE);
  }

  const error =
    Object.keys(intakeValues.validated).includes(IntakeSection.CallTracking) && errors.format;
  return (
    <>
      {intakeValues.format !== null ? (
        <ConfigurationCard onEdit={() => setShowEdit(true)} noAccessCheck>
          <GrayBars
            rows={[
              {
                key: translateText('label', 'Format phone number'),
                value: getFormatDisplay(intakeValues.format, numberFormats ?? []),
              },
            ]}
            title={translateText('label', 'Number display')}
          />
        </ConfigurationCard>
      ) : (
        <ErrorTooltip
          error={error}
          html={
            <div className={'white-block no-data' + (error ? ' error' : '')} tabIndex={0}>
              <AddButton
                onClick={() => setShowEdit(true)}
                tooltipText={translateText(
                  'tooltip/intake',
                  'Click on the + to indicate in which format the telephone number for Call Tracking may be displayed on the website.',
                )}
              />
              {translateText('label', 'No format set')}
            </div>
          }
          className="no-data"
        />
      )}
      <NumberFormatSetup
        show={showEdit}
        close={() => setShowEdit(false)}
        numberFormat={formatForSetup ?? ''}
        updateNumberFormat={save}
      />
    </>
  );
}

export function getFormatDisplay(
  format: string | number | null,
  numberFormats: { id: number; format: string }[],
) {
  if (typeof format === 'number') {
    return numberFormats?.find(f => f.id === format)?.format ?? '';
  } else if (typeof format === 'string') {
    while ((format.match(/x/g) ?? []).length < 7) format += 'x';
    return format.replace('{nat}', '31').replace('{area}', '20');
  } else {
    return '';
  }
}
