import { ReactElement } from 'react';

import Tooltip from './Tooltip';

type Props = {
  error: boolean | string | undefined;
  html: ReactElement;
  className?: string;
};

export default function ErrorTooltip({ error, html, className }: Props) {
  const errorText = typeof error === 'string' ? error : '';
  return (
    <Tooltip
      html={html}
      className={className}
      placement="bottom"
      text={errorText}
      inline={false}
      show={errorText ? undefined : false} // Undefined so the tooltip handles `show` internally on hover and focus, false so there is no empty tooltip when there is no error
      mostImportant
      textAsHtml
    />
  );
}
