import { useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import AutoGrid from 'components/AutoGrid';
import DetailsSection from 'components/DetailsSection';
import ErrorFocus from 'components/ErrorFocus';
import Checkbox from 'components/input/Checkbox';
import TextField from 'components/input/TextField';
import PopupModal from 'components/PopupModal';
import SettingsPage from 'components/settings/SettingsPage';
import { useTenant } from 'contexts/Tenant';
import { LATEST_DPA_VERSION } from 'globals/constants';
import { useHasManagerAccess } from 'hooks/access';
import { useDateTime } from 'hooks/date-time';
import {
  Sign,
  useDownloadDpa,
  useDpaSigns,
  useSignDpa,
} from 'hooks/queries/data-processor-agreement';
import { useUser } from 'hooks/queries/user';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation } from 'hooks/validation';

export default function Privacy() {
  const translateText = useTranslate();
  const hasManagerAccess = useHasManagerAccess();
  const { selectedDomainData } = useTenant();
  const { required } = useCustomValidation();
  const { formatDateTime } = useDateTime();
  const { user } = useUser();
  const { data, isLoading } = useDpaSigns();
  const { signDpa } = useSignDpa();
  const { downloadDpa, isDownloading } = useDownloadDpa();
  const [showPopup, setShowPopup] = useState(false);

  if (!hasManagerAccess) {
    return (
      <SettingsPage title={translateText('label', 'Data Processor Agreement')}>
        <div className="white-block">
          {translateText(
            'text',
            "You are not the manager of this domain. For this reason you can't view or sign the Data Processor Agreement. Optionally, select a different domain.",
          )}
        </div>
      </SettingsPage>
    );
  }

  const signs: (Sign | null)[] = data ?? [null]; // Array with null is for loading
  const newVersionAvailable =
    !isLoading &&
    !signs.find(
      sign =>
        (sign?.signed_by === user?.id && sign?.version === LATEST_DPA_VERSION) ||
        sign?.version === 'custom',
    );

  async function sign(
    values: { name: string; accepted: boolean },
    formikhelpers: FormikHelpers<{ name: string; accepted: boolean }>,
  ) {
    await signDpa(values.name).then(() => {
      setShowPopup(false);
      formikhelpers.resetForm();
    });
  }

  return (
    <SettingsPage
      title={translateText('label', 'Data Processor Agreement')}
      className="data-processor-agreement"
    >
      <PopupModal
        id="data-processor-agreement"
        show={showPopup}
        close={() => setShowPopup(false)}
        size="big"
      >
        <Formik
          initialValues={{
            name: user?.first_name && user?.last_name ? user.first_name + ' ' + user.last_name : '',
            accepted: false,
          }}
          onSubmit={sign}
          validationSchema={Yup.object({ name: required })}
          enableReinitialize
        >
          {({ values, handleChange, isSubmitting, submitForm, errors, submitCount }) => (
            <div className="sign-dpa-wrapper">
              <ErrorFocus element={document.getElementById('data-processor-agreement')} />
              <div className="document">
                <iframe title="document" src="/data-processor-agreement" role="document" />
              </div>
              <div className="actions">
                <div className="input-wrapper">
                  <div className="input-label">{translateText('label', 'Data controller')}</div>
                  <TextField
                    name="name"
                    placeholder={translateText('label', 'Full name')}
                    value={values.name}
                    onChange={handleChange}
                    error={submitCount > 0 && errors.name}
                  />
                </div>
                <div className="accept">
                  <Checkbox
                    id="accepted"
                    checked={values.accepted}
                    onChange={handleChange}
                    labelClassName="extra-margin"
                    label={translateText(
                      'label',
                      'I accept the "AdCalls Data Processor Agreement" on behalf of the "Data controller" mentioned therein. I declare that I am authorized to do so.',
                    )}
                  />
                </div>
                <div className="buttons">
                  <button
                    disabled={isSubmitting}
                    onClick={() => setShowPopup(false)}
                    className="btn btn-lightblue"
                  >
                    {translateText('label', 'Cancel')}
                  </button>
                  <button
                    disabled={isSubmitting || !values.accepted}
                    onClick={submitForm}
                    className="btn btn-green"
                  >
                    {translateText('label', 'Accept')}
                  </button>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </PopupModal>

      {newVersionAvailable && (
        <button onClick={() => setShowPopup(true)} className="btn btn-green margin-bottom">
          {translateText('label', 'Sign the latest version')}
        </button>
      )}
      <AutoGrid noGrow size="large">
        {signs.map((sign, index) => {
          if (sign?.version === '0') {
            return (
              <DetailsSection title={selectedDomainData?.name ?? ''} key={`dpa-${index}`}>
                {translateText('text', 'The data processor agreement has been signed offline.')}
              </DetailsSection>
            );
          }
          return (
            <DetailsSection
              key={`dpa-${index}`}
              title={{ text: selectedDomainData?.name ?? '', private: true }}
              isLoading={isLoading}
              data={{
                [translateText('label', 'Signer name')]: { value: sign?.signer, private: true },
                [translateText('label', 'Date')]: formatDateTime(sign?.signed_at ?? ''),
                [translateText('label', 'Version')]: {
                  value: (
                    <div className="download-wrapper">
                      <span>{sign?.version}</span>
                      {sign && (
                        <button
                          disabled={isDownloading}
                          onClick={() => downloadDpa({ id: sign.id, version: sign.version })}
                          className="btn btn-text no-padding"
                        >
                          {translateText('label', 'Download')}
                        </button>
                      )}
                    </div>
                  ),
                },
              }}
            />
          );
        })}
      </AutoGrid>
    </SettingsPage>
  );
}
