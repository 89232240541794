import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { posthog } from 'posthog-js';

import { MessageType, useMessages } from './Messages';

type Session = {
  token: string | null;
  setToken: Dispatch<SetStateAction<string | null>>;
  expiresAt: number;
  setExpiresAt: Dispatch<SetStateAction<number>>;
  isTokenValid: boolean;
  setIsTokenValid: Dispatch<SetStateAction<boolean>>;
};

const SessionContext = createContext<Session | null>(null);

export function SessionProvider({
  children,
  testToken,
}: PropsWithChildren<{ testToken?: string | null }>) {
  const queryClient = useQueryClient();
  const { clearMessages } = useMessages();

  const [token, setToken] = useState<string | null>(
    testToken !== undefined ? testToken : localStorage.getItem('session'),
  );
  const [expiresAt, setExpiresAt] = useState(Number(localStorage.getItem('expiresAt')));
  const [isTokenValid, setIsTokenValid] = useState(true);

  useEffect(() => {
    if (expiresAt && Number(localStorage.getItem('expiresAt')) !== expiresAt) {
      localStorage.setItem('expiresAt', String(expiresAt));
    }
  }, [expiresAt]);

  // Listen to local storage changes
  Storage.prototype.setItem = new Proxy(Storage.prototype.setItem, {
    apply(target, thisArg, argumentList) {
      if (argumentList[0] === 'session') {
        setToken(argumentList[1]);
      }
      return Reflect.apply(target, thisArg, argumentList);
    },
  });

  Storage.prototype.removeItem = new Proxy(Storage.prototype.removeItem, {
    apply(target, thisArg, argumentList) {
      if (argumentList[0] === 'session') {
        setToken(null);
        queryClient.clear();
        posthog.reset();
        clearMessages(MessageType.Permanent);
      }
      return Reflect.apply(target, thisArg, argumentList);
    },
  });

  return (
    <SessionContext.Provider
      value={{
        token,
        setToken,
        expiresAt,
        setExpiresAt,
        isTokenValid,
        setIsTokenValid,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}

export function useSession() {
  const session = useContext(SessionContext);
  if (session === null) {
    throw new Error('Session context not available');
  }
  return session;
}

export function clearSession() {
  // These local storage items do not need to be cleared on log out
  const language = localStorage.getItem('language');
  const rememberMe = localStorage.getItem('rememberMe');

  localStorage.removeItem('session'); // Needs to be called specifically because the session provider listens to it
  localStorage.clear();

  if (language) localStorage.setItem('language', language);
  if (rememberMe) localStorage.setItem('rememberMe', rememberMe);
}
