import { Formik } from 'formik';
import * as Yup from 'yup';

import Select from 'components/input/Select';
import TextField from 'components/input/TextField';
import { useCreateReseller } from 'hooks/queries/reseller';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation } from 'hooks/validation';

export const PARTNER_STATUS = ['Reseller', 'Partner', 'Gold Partner', 'Eindklant'] as const;

const ACCOUNT_MANAGER_FLEUR = 6260 as const;
const ACCOUNT_MANAGER_MAXIME = 6388 as const;
const ACCOUNT_MANAGER_DAVE = 6977 as const;
const ACCOUNT_MANAGER_TAMER = 6999 as const;
const ACCOUNT_MANAGERS = {
  [ACCOUNT_MANAGER_FLEUR]: 'Fleur Petiet',
  [ACCOUNT_MANAGER_MAXIME]: 'Maxime Aartsen',
  [ACCOUNT_MANAGER_DAVE]: 'Dave van Vliet',
  [ACCOUNT_MANAGER_TAMER]: 'Tamer Saglambilek',
} as const;

export default function ResellerNew() {
  const translateText = useTranslate();
  const { required, email, requiredSelect } = useCustomValidation();
  const { createReseller } = useCreateReseller();

  const initialValues = {
    name: '',
    email: '',
    account_manager: 'select',
    partner_status: 'select',
  };

  const validationSchema = Yup.object({
    name: required,
    partner_status: requiredSelect,
    account_manager: requiredSelect,
    email,
  });

  return (
    <>
      <h1>{translateText('label', 'Add new partner')}</h1>
      <Formik
        initialValues={initialValues}
        onSubmit={values => createReseller(values)}
        validationSchema={validationSchema}
      >
        {({ values, handleChange, submitForm, errors, isSubmitting, dirty, submitCount }) => (
          <div className="white-block">
            <div className="form-row">
              <div className="name-label">{translateText('label', 'Name')}</div>
              <div className="value-wrapper">
                <TextField
                  name="name"
                  value={values.name}
                  placeholder={translateText('label', 'Name')}
                  onChange={handleChange}
                  disabled={isSubmitting}
                  error={submitCount > 0 && errors.name}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="name-label">{translateText('label', 'Partner status')}</div>
              <div className="value-wrapper">
                <Select
                  name="partner_status"
                  value={values.partner_status}
                  onChange={handleChange}
                  options={PARTNER_STATUS}
                  addSelect
                  disabled={isSubmitting}
                  error={submitCount > 0 && errors.partner_status}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="name-label">{translateText('label', 'Account manager')}</div>
              <div className="value-wrapper">
                <Select
                  name="account_manager"
                  value={values.account_manager}
                  onChange={handleChange}
                  options={ACCOUNT_MANAGERS}
                  addSelect
                  disabled={isSubmitting}
                  error={submitCount > 0 && errors.account_manager}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="name-label">
                {translateText('label', 'Administrative user email')}
              </div>
              <div className="value-wrapper">
                <TextField
                  name="email"
                  value={values.email}
                  placeholder={translateText('label', 'Email address')}
                  onChange={handleChange}
                  disabled={isSubmitting}
                  error={submitCount > 0 && errors.email}
                />
              </div>
            </div>
            <div className="form-button">
              <button
                className="btn btn-green"
                type="submit"
                onClick={submitForm}
                disabled={!dirty || isSubmitting}
              >
                {translateText('label', 'Add')}
              </button>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
}
