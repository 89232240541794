import { ReactNode, useState } from 'react';

import error from 'assets/images/icons/messages/error.svg';
import info from 'assets/images/icons/messages/info.svg';
import { ReactComponent as Minus } from 'assets/images/icons/minus.svg';
import space from 'assets/images/icons/space.svg';
import { useTranslate } from 'hooks/translate';

export const COUNTRY_CODE = 'c' as const;
export const AREA_CODE = 'a' as const;

export const MAX_LENGTH_IN_BUILDER = 25 as const;

type Props = {
  format: string;
  setFormat: (value: string) => void;
  validateFormat: () => [string[], string[]];
};

export default function NumberFormatBuilder({ format, setFormat, validateFormat }: Props) {
  const translateText = useTranslate();
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null);
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);
  const [lastHoveredChar, setLastHoveredChar] = useState('');

  function addToBuilder(char: string) {
    if (format.length > MAX_LENGTH_IN_BUILDER) return;

    if (char === '+') {
      const index = format.indexOf(COUNTRY_CODE);
      if (index === -1) {
        setFormat(char + format);
      } else {
        setFormat(format.slice(0, index) + char + format.slice(index));
      }
    } else if (char === COUNTRY_CODE) {
      const zerosIndex = format.includes('00') ? format.indexOf('00') + 1 : -1;
      const plusIndex = format.indexOf('+');
      const index = plusIndex === -1 ? zerosIndex : plusIndex;
      setFormat(format.slice(0, index + 1) + char + format.slice(index + 1));
    } else {
      setFormat(format + char);
    }
  }

  function deleteCharFromBuilder(index: number) {
    if (index === 0) {
      setFormat(format.slice(1));
    } else {
      setFormat(format.slice(0, index) + format.slice(index + 1));
    }
  }

  function getNewDisplayFormat() {
    if (draggingIndex === null || hoverIndex === null) return null;

    const draggedChar = format[draggingIndex];
    if (draggingIndex < hoverIndex) {
      return (
        format.slice(0, draggingIndex) +
        format.slice(draggingIndex + 1, hoverIndex + 1) +
        draggedChar +
        format.slice(hoverIndex + 1)
      );
    } else {
      return (
        format.slice(0, hoverIndex) +
        draggedChar +
        format.slice(hoverIndex, draggingIndex) +
        format.slice(draggingIndex + 1)
      );
    }
  }

  function dragEnd() {
    const newDisplayFormat = getNewDisplayFormat();
    if (newDisplayFormat === null) return;

    setDraggingIndex(null);
    setHoverIndex(null);
    setLastHoveredChar('');
    setFormat(newDisplayFormat);
  }

  function getBuilderContainer() {
    const displayFormat = getNewDisplayFormat() ?? format;
    return displayFormat.split('').map((char, index) => {
      let extraClassName = '';
      let displayChar: ReactNode | string = char;
      switch (char) {
        case COUNTRY_CODE:
          extraClassName = ' country';
          displayChar = translateText('label', 'Country code');
          break;
        case AREA_CODE:
          extraClassName = ' area';
          displayChar = translateText('label', 'Area code');
          break;
        case ' ':
          extraClassName = ' space';
          displayChar = <img alt={translateText('label', 'Space')} src={space} />;
          break;
        case 'x':
          displayChar = 'X';
          break;
      }

      return (
        <div
          key={String(index)}
          className={'format-builder-block-wrapper' + (draggingIndex ? ' dragging' : '')}
        >
          <button
            className="btn btn-small-round btn-purple"
            onClick={() => deleteCharFromBuilder(index)}
            data-testid="builder-item-remove"
          >
            <Minus />
          </button>
          <div
            id={String(index)}
            data-testid="builder-item"
            draggable="true"
            className={
              'format-builder-block' + extraClassName + (hoverIndex === index ? ' dragged' : '')
            }
            onDrop={e => {
              e.preventDefault();
              dragEnd();
            }}
            onDragStart={e => setDraggingIndex(Number(e.currentTarget.id))}
            onDragEnter={e => {
              e.preventDefault();
              const newHoverIndex = Number(e.currentTarget.id);
              const hoveringChar = displayFormat[newHoverIndex];
              if (
                (hoveringChar === AREA_CODE && lastHoveredChar === AREA_CODE) ||
                (hoveringChar === COUNTRY_CODE && lastHoveredChar === COUNTRY_CODE)
              ) {
                // this prevents flickering on the large blocks
                return;
              }
              setLastHoveredChar(hoveringChar);
              setHoverIndex(newHoverIndex);
            }}
            onDragEnd={e => {
              e.preventDefault();
              dragEnd();
            }}
          >
            {displayChar}
          </div>
        </div>
      );
    });
  }

  const [errors, warnings] = validateFormat();

  return (
    <div className="builder">
      <div
        className={'builder-preview' + (draggingIndex ? ' dragging' : '')}
        onDragOver={e => e.preventDefault()}
        data-testid="builder-preview"
      >
        {getBuilderContainer()}
      </div>
      <div className="builder-buttons">
        <button
          className="btn btn-lightblue"
          onClick={() => addToBuilder('+')}
          disabled={format.includes('+')}
        >
          +
        </button>
        <button className="btn btn-lightblue" onClick={() => addToBuilder('-')}>
          -
        </button>
        <button className="btn btn-lightblue" onClick={() => addToBuilder('(')}>
          (
        </button>
        <button className="btn btn-lightblue" onClick={() => addToBuilder(')')}>
          )
        </button>
        <button className="btn btn-lightblue" onClick={() => addToBuilder('0')}>
          0
        </button>
        <button
          className="btn btn-lightblue"
          onClick={() => addToBuilder('x')}
          disabled={format === ''}
        >
          X
        </button>
        <button
          className="btn btn-lightblue"
          onClick={() => addToBuilder(COUNTRY_CODE)}
          disabled={format.includes(COUNTRY_CODE)}
        >
          {translateText('label', 'Country code')}
        </button>
        <button
          className="btn btn-lightblue"
          onClick={() => addToBuilder(AREA_CODE)}
          disabled={format.includes(AREA_CODE)}
        >
          {translateText('label', 'Area code')}
        </button>
        <button
          className="btn btn-lightblue"
          onClick={() => addToBuilder(' ')}
          disabled={format === ''}
        >
          <img alt={translateText('label', 'Space')} src={space} />
        </button>
      </div>
      <div className="error-wrapper">
        {errors.map((text, index) => (
          <div key={'error' + index} className="errors">
            <img src={error} alt="error" />
            {text}
          </div>
        ))}
        {warnings.map((text, index) => (
          <div key={'warning' + index} className="warnings">
            <img src={info} alt="warning" />
            {text}
          </div>
        ))}
      </div>
    </div>
  );
}
