import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import UserData from 'components/navigation/UserData';
import SlideIn, { BaseSlideInProps } from 'components/slide-in/SlideIn';
import { useTenant } from 'contexts/Tenant';
import { useIsMediumScreen } from 'hooks/media';
import { useInvitationCount } from 'hooks/queries/invitation';
import { useReadNotification, useUnreadNotifications } from 'hooks/queries/notification';
import { useDisplayTour } from 'hooks/queries/user';
import { useTranslate } from 'hooks/translate';
import AccountTab from './AccountTab';
import DomainTab from './DomainTab';
import NotificationPreview from './NotificationPreview';

export default function NotificationCenter({ show, close }: BaseSlideInProps) {
  const navigate = useNavigate();
  const translateText = useTranslate();
  const isMediumScreen = useIsMediumScreen();
  const { selectedDomain } = useTenant();
  const { setDisplayTour } = useDisplayTour();
  const [selectedTab, setSelectedTab] = useState<'account' | 'domain'>('account');

  const { notifications, refetch: refetchNotifications } = useUnreadNotifications();
  const { invitationCount, refetch: refetchInvitations } = useInvitationCount();
  const { markAsRead } = useReadNotification();

  useEffect(() => {
    if (show) {
      refetchNotifications();
      refetchInvitations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const userNotifications = notifications?.filter(n => n.domain_id === null);
  const domainNotifications = notifications?.filter(
    n => selectedDomain && n.domain_id === selectedDomain,
  );

  function clearNotifications() {
    (selectedTab === 'domain' ? domainNotifications : userNotifications)?.forEach(n =>
      markAsRead(n.id),
    );
  }

  function renderNotificationCount(count: number) {
    if (count === 0) return null;
    return <div className="notification-count">{count}</div>;
  }

  return (
    <SlideIn
      show={show}
      close={close}
      removeClose
      overlay="transparent"
      className="notification-center-wrapper"
      maxWidth={isMediumScreen ? 320 : 400}
    >
      <div className="notification-center">
        <div className="header">
          <UserData toggleDisplay={() => close()} hasWhiteSkeleton={false} />
        </div>

        {selectedDomain && (
          <div className="tabs">
            <div
              className={'tab left' + (selectedTab === 'account' ? ' selected' : '')}
              onClick={() => setSelectedTab('account')}
            >
              {translateText('label', 'Account')}
              {renderNotificationCount((userNotifications?.length ?? 0) + (invitationCount ?? 0))}
            </div>
            <div
              className={'tab right' + (selectedTab === 'domain' ? ' selected' : '')}
              onClick={() => setSelectedTab('domain')}
            >
              {translateText('label', 'Domain')}
              {renderNotificationCount(domainNotifications?.length ?? 0)}
            </div>
          </div>
        )}
        {selectedTab === 'domain' ? <DomainTab /> : <AccountTab close={close} />}
        <div className="notification-buttons">
          <span
            onClick={() => {
              navigate('/account/notifications');
              close();
            }}
          >
            {translateText('label', 'Show all notifications')}
          </span>
          <span onClick={clearNotifications}>
            {translateText('label', 'Clear all notifications')}
          </span>
        </div>
        <div className="notifications">
          {(selectedTab === 'domain' ? domainNotifications : userNotifications)?.map((n, i) => (
            <NotificationPreview key={i} notification={n} close={close} />
          ))}
        </div>
      </div>
      <div className="start-tour-wrapper">
        <button
          className="btn btn-text"
          onClick={() => {
            close();
            setDisplayTour(true);
          }}
        >
          {translateText('label', 'Start guided tour')}
        </button>
      </div>
    </SlideIn>
  );
}
