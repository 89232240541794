import { useState } from 'react';
import { Formik } from 'formik';

import CreateDeleteTable from 'components/CreateDeleteTable';
import TextField from 'components/input/TextField';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import { Attachment } from 'globals/types';
import { useUpdateAttachments } from 'hooks/queries/support';
import { useTranslate } from 'hooks/translate';

type Props = {
  attachments: Attachment[];
  articleId: number;
  article: string;
};

export default function AttachmentSection({ attachments, articleId, article }: Props) {
  const translateText = useTranslate();
  const { updateAttachments } = useUpdateAttachments();
  const [showAdd, setShowAdd] = useState(false);

  return (
    <Formik
      initialValues={{ attachments, name: '', path: '' }}
      enableReinitialize
      onSubmit={values =>
        updateAttachments({ attachments: values.attachments, articleId, article })
      }
    >
      {({ values, handleChange, setFieldValue, submitForm, dirty, isSubmitting }) => (
        <>
          <h1>{translateText('label', 'Attachments')}</h1>
          <CreateDeleteTable
            dataKeys={['name', 'path']}
            items={values.attachments}
            onAdd={() => setShowAdd(true)}
            onDelete={item =>
              setFieldValue(
                'attachments',
                values.attachments.filter(i => i !== item),
              )
            }
          />
          <button
            className="btn btn-green margin-top"
            disabled={isSubmitting || !dirty}
            onClick={submitForm}
          >
            {translateText('label', 'Save')}
          </button>
          <Setup
            show={showAdd}
            close={() => setShowAdd(false)}
            title={translateText('label', 'Add attachment')}
            save={() => {
              setFieldValue('attachments', [
                ...values.attachments,
                { id: null, name: values.name, path: values.path },
              ]);
              setShowAdd(false);
            }}
            saveButtonText={translateText('label', 'Add')}
            isSaving={isSubmitting}
            afterClose={() => {
              setFieldValue('name', '');
              setFieldValue('path', '');
            }}
            maxWidth={330}
          >
            <InputWrapper label={translateText('label', 'Name')}>
              <TextField
                name="name"
                placeholder={translateText('label', 'Name')}
                value={values.name}
                onChange={handleChange}
              />
            </InputWrapper>
            <InputWrapper label={translateText('label', 'URL')}>
              <TextField
                name="path"
                placeholder={translateText('label', 'URL')}
                value={values.path}
                onChange={handleChange}
              />
            </InputWrapper>
          </Setup>
        </>
      )}
    </Formik>
  );
}
