export default {
  "Off": "Uit",
  "Exclude (standard)": "Uitsluiten (standaard)",
  "Specific (inverted)": "Specifiek (omgekeerd)",
  "is equal to": "is gelijk aan",
  "does start with": "begint met",
  "does end with": "eindigt met",
  "does contain": "bevat",
  "isn't equal to": "is niet gelijk aan",
  "doesn't start with": "begint niet met",
  "doesn't end with": "eindigt niet met",
  "doesn't contain": "bevat niet",
  "Name of source": "Naam van bron",
  "URL": "URL"
}
