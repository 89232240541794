import { useState } from 'react';
import { useIsFetching } from '@tanstack/react-query';

import acceptIcon from 'assets/images/icons/accept.svg';
import deleteIcon from 'assets/images/icons/delete-purple.svg';
import AddButton from 'components/buttons/AddButton';
import QueryTable from 'components/table/QueryTable';
import Tooltip from 'components/Tooltip';
import { useMessages } from 'contexts/Messages';
import { useTenant } from 'contexts/Tenant';
import { useHasManagerAccess } from 'hooks/access';
import { useAssignableLocations } from 'hooks/queries/aen-permission';
import { useLeaveMcc } from 'hooks/queries/domain';
import { useAcceptInvitation } from 'hooks/queries/reseller-domain-invitation';
import { useAssignableRoles } from 'hooks/queries/role';
import { useUser } from 'hooks/queries/user';
import { useTranslate } from 'hooks/translate';
import { AccountAccessProps } from 'pages/authenticated/settings/AccountAccess';
import SettingsPage from '../SettingsPage';
import DomainUserSetup, { DomainUser } from './DomainUserSetup';

type Mcc = {
  id: string;
  name: string;
  email: string;
  invitation: string;
};

export default function DomainAccountAccess({
  getRoleTooltip,
  statusColumn,
}: AccountAccessProps<DomainUser>) {
  const translateText = useTranslate();
  const hasManagerAccess = useHasManagerAccess();
  const { addErrorMessage } = useMessages();
  const { selectedDomain } = useTenant();
  const { user, isLoading: isLoadingUser } = useUser();
  const { isLoading: isLoadingRoles } = useAssignableRoles();
  const { isLoading: isLoadingLocations } = useAssignableLocations();
  const { acceptInvitation } = useAcceptInvitation();
  const { revokeMcc } = useLeaveMcc();

  const [showUser, setShowUser] = useState<number | 'new' | false>(false);

  const queryKeyUsers = ['domain', 'users', selectedDomain!];
  const queryKeyMcc = ['domain', 'mcc', selectedDomain!];
  const isFetchingUsers = useIsFetching({ queryKey: queryKeyUsers });
  const isFetchingMcc = useIsFetching({ queryKey: queryKeyMcc });
  const isLoading =
    !!isFetchingUsers || !!isFetchingMcc || isLoadingUser || isLoadingRoles || isLoadingLocations;

  function onRowClick(userId: number) {
    if (userId !== user?.id) return setShowUser(userId);
    addErrorMessage(
      translateText(
        'message',
        'It is not possible to manage your own rights or remove yourself as a user.',
      ),
    );
  }

  function getMccButton(_: unknown, mcc: Mcc) {
    const label =
      mcc.invitation === null
        ? translateText('label', 'Revoke access')
        : translateText('label', 'Accept invitation');
    return (
      <Tooltip
        text={label}
        html={
          <img
            alt={label}
            src={mcc.invitation === null ? deleteIcon : acceptIcon}
            className="clickable-icon"
            onClick={e => {
              e.stopPropagation();
              if (mcc.invitation === null) revokeMcc({ id: mcc.id, name: mcc.name });
              else acceptInvitation({ id: mcc.id, invitation: mcc.invitation });
            }}
          />
        }
      />
    );
  }

  return (
    <SettingsPage title={translateText('label', 'Users')}>
      <DomainUserSetup showUser={showUser} close={() => setShowUser(false)} />
      <QueryTable<DomainUser>
        tableKey={queryKeyUsers}
        columns={{
          first_name: {
            header: translateText('label', 'First and last name'),
            customValue: (value, row) => value + ' ' + row.last_name,
            private: true,
          },
          email: { header: translateText('label', 'Email address'), private: true },
          highestRole: {
            header: {
              name: translateText('label', 'Rights'),
              tooltip: getRoleTooltip(hasManagerAccess),
            },
            customValue: value => value?.description,
          },
          status_id: statusColumn,
        }}
        endpoint="/user/index"
        extraTopLeft={
          hasManagerAccess ? (
            <AddButton
              isLoading={isLoading}
              onClick={() => setShowUser('new')}
              tooltipText={translateText('label', 'Invite user')}
            />
          ) : undefined
        }
        onRowClick={hasManagerAccess ? row => onRowClick(row.id!) : undefined}
        isLoading={isLoading}
        filters={{ custom: { expand: 'highestRole,lastLogin,aens' } }}
        download={{ csv: true, name: translateText('label', 'Domain user overview') }}
        pagination
        canSearch
      />
      <h1>{translateText('label', 'Customer center')}</h1>
      <QueryTable<Mcc>
        tableKey={queryKeyMcc}
        columns={{
          name: { header: translateText('label', 'Customer center'), private: true },
          email: { header: translateText('label', 'Email address'), private: true },
          ...(hasManagerAccess && { x: { header: '', customValue: getMccButton } }),
        }}
        endpoint="/domain/mcc"
        isLoading={isLoading}
        pagination={{ defaultLimit: 5 }}
        canSearch
      />
    </SettingsPage>
  );
}
