import { Children, cloneElement, isValidElement, PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { cloneDeep, includes } from 'lodash';

const ROUTES_TO_NOT_REDIRECT_TO = [
  '/reset',
  '/reset/',
  '/domain-overview',
  '/call-tracking/integrations/advertising/google/verify',
  '/call-tracking/integrations/advertising/google-ads-enhanced-conversions/verify',
  '/call-tracking/integrations/advertising/microsoft/verify',
  '/call-tracking/integrations/advertising/display-video-360/verify',
  '/call-tracking/integrations/advertising/doubleclick/verify',
  '/call-tracking/integrations/advertising/search-360/verify',
  '/call-tracking/integrations/data-management/bigquery/verify',
  '/call-tracking/integrations/crm/salesforce/verify',
  '/call-tracking/integrations/crm/hubspot/verify',
  '/call-tracking/integrations/crm/microsoft-dynamics/verify',
  '/form-tracking/integrations/advertising/google/verify',
  '/form-tracking/integrations/advertising/microsoft/verify',
] as const;

type Props = {
  redirectTo?: { pathname: string; search: string };
  shouldRedirect?: boolean;
};

export default function BaseRoute({
  children,
  redirectTo = { pathname: '/', search: '' },
  shouldRedirect,
}: PropsWithChildren<Props>) {
  const location = useLocation();
  if (!shouldRedirect) {
    return (
      <>
        {Children.map(children, child => {
          if (isValidElement(child)) {
            return cloneElement(child);
          }
        })}
      </>
    );
  }
  const from = cloneDeep(location);
  if (includes(ROUTES_TO_NOT_REDIRECT_TO, from.pathname)) {
    from.pathname = '/overview';
  }
  return <Navigate to={redirectTo} state={{ from }} />;
}
