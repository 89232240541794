import { useNavigate } from 'react-router';
import { Formik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import DatePicker from 'components/input/DatePicker';
import TextField from 'components/input/TextField';
import { useMessages } from 'contexts/Messages';
import { useTranslate } from 'hooks/translate';
import { useUserDateFormat } from 'hooks/user';
import { useCustomValidation } from 'hooks/validation';

type BaseProps = {
  lastAdded?: string;
  disabled?: boolean;
  onSave: (values: { number: string; date: string }) => Promise<void>;
};

type AddProps = {
  type: 'new';
  number?: never;
  date?: never;
  delete?: never;
} & BaseProps;

type EditProps = {
  type: 'edit';
  number: string | undefined;
  date: string | undefined;
  delete: () => void;
} & BaseProps;

export default function BlacklistForm(props: AddProps | EditProps) {
  const translateText = useTranslate();
  const navigate = useNavigate();
  const { addErrorMessage } = useMessages();
  const { required } = useCustomValidation();
  const userDateFormat = useUserDateFormat();

  const validationSchema = Yup.object({
    number: required,
    date: Yup.string().test({
      message: translateText('message', 'The entry is invalid (desired format: {format}).', {
        format: userDateFormat,
      }),
      test: value => !!value && moment(value, userDateFormat, true).isValid(),
    }),
  });

  return (
    <Formik
      initialValues={{
        number: props.number ?? '',
        date: props.date ?? moment().format(userDateFormat),
      }}
      onSubmit={(values, formikHelpers) =>
        props
          .onSave(values)
          .then(() => formikHelpers.resetForm())
          .catch(error => {
            if (error.number || error.blacklisted_till) {
              if (error.number) {
                formikHelpers.setFieldError('number', error.number[0]);
              }
              if (error.blacklisted_till) {
                formikHelpers.setFieldError('date', error.blacklisted_till[0]);
              }
            } else {
              addErrorMessage(error);
            }
          })
      }
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ values, handleChange, setFieldValue, submitForm, dirty, errors, isSubmitting }) => (
        <>
          <div>
            <div className="form-row">
              <div className="name-label">{translateText('label', 'Phone number')}</div>
              <div className="value-wrapper">
                <TextField
                  name="number"
                  value={values.number}
                  disabled={props.disabled}
                  onChange={handleChange}
                  error={errors.number}
                />
              </div>
            </div>
            <div className="form-row no-space">
              <div className="name-label">{translateText('label', 'Blacklisted till')}</div>
              <div className="date-selector">
                <DatePicker
                  value={values.date}
                  onChange={date => setFieldValue('date', date)}
                  disabled={props.disabled}
                  error={errors.date}
                  minDate={moment().toDate()}
                  format={userDateFormat}
                />
              </div>
            </div>
          </div>
          {props.lastAdded && (
            <div className="form-row">
              <div className="bold">
                {translateText('label', 'Last added') + ': ' + props.lastAdded}
              </div>
            </div>
          )}
          <div className="form-button left-button">
            <button
              className="btn btn-lightblue"
              type="button"
              onClick={() => navigate('/admin/phone-numbers/blacklist')}
            >
              {translateText('label', 'Go back')}
            </button>
            <div>
              {props.type === 'edit' && (
                <button
                  className="btn btn-purple button-right"
                  type="button"
                  disabled={props.disabled}
                  onClick={() => props.delete()}
                >
                  {translateText('label', 'Delete')}
                </button>
              )}
              <button
                className="btn btn-green"
                type="submit"
                onClick={submitForm}
                disabled={isSubmitting || !dirty || props.disabled}
              >
                {translateText('label', 'Save')}
              </button>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
}
