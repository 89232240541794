import { useRef, useState } from 'react';
import { zipObject } from 'lodash';

import EditableJson from 'components/admin/EditableJson';
import SearchableSelect from 'components/input/SearchableSelect';
import Select from 'components/input/Select';
import TextField from 'components/input/TextField';
import { AREA_CODE, COUNTRY_CODE } from 'components/settings/properties/NumberFormatBuilder';
import NumberFormatSetup from 'components/settings/properties/NumberFormatSetup';
import QueryTable from 'components/table/QueryTable';
import { useMessages } from 'contexts/Messages';
import { useTenant } from 'contexts/Tenant';
import { DomainSetting } from 'globals/types';
import { useHasAdminAccess } from 'hooks/access';
import { useDomainSettingOptions } from 'hooks/admin';
import {
  useAddDomainSetting,
  useDeleteDomainSetting,
  useUpdateDomainSetting,
} from 'hooks/queries/domain-setting';
import { useDomainSettingTypes } from 'hooks/queries/setting-type';
import { useTranslate } from 'hooks/translate';

export default function DomainSettings() {
  const translateText = useTranslate();
  const { selectedDomain } = useTenant();
  const { addErrorMessage } = useMessages();
  const isAdmin = useHasAdminAccess();

  const options = useDomainSettingOptions();
  const { settingTypes } = useDomainSettingTypes();
  const { addDomainSetting, isLoading: isAdding } = useAddDomainSetting();
  const { deleteDomainSetting, isLoading: isDeleting } = useDeleteDomainSetting();
  const { updateDomainSetting, isLoading: isUpdating } = useUpdateDomainSetting();

  const refCollection = useRef<Record<string, HTMLInputElement | HTMLSelectElement | string>>({});

  const [newSettingType, setNewSettingType] = useState<string>('-1');
  const [showNumberFormatSetup, setShowNumberFormatSetup] = useState<string | false>(false);

  async function addSetting(settingTypeId: string, value: string) {
    if (settingTypeId === '-1' && value === '') {
      return addErrorMessage(
        translateText('admin/text', 'Select a domain setting and fill in a value.'),
      );
    } else if (settingTypeId === '-1' && value !== '') {
      return addErrorMessage(translateText('admin/text', 'Select a domain setting.'));
    } else if (settingTypeId !== '-1' && value === '') {
      return addErrorMessage(
        translateText('admin/text', 'Fill in a value for the domain setting.'),
      );
    }
    await addDomainSetting({ settingTypeId, value }).then(() => {
      setNewSettingType('-1');
      refCollection.current.new = '';
    });
  }

  function renderValueElement(collectionId: string, value = '') {
    const settingTypeId = collectionId === 'new' ? newSettingType : collectionId;
    const canEdit = isAdmin && options[settingTypeId]?.canEdit !== false;
    if (settingTypeId === '-1') {
      return <TextField disabled placeholder={translateText('label', 'Value')} />;
    } else if (options[settingTypeId]?.type === 'list') {
      return (
        <Select
          key={settingTypeId}
          defaultValue={value}
          onChange={() => null}
          options={options[settingTypeId]?.options ?? []}
          addSelect
          selectValue=""
          inputRef={(element: HTMLSelectElement) => (refCollection.current[collectionId] = element)}
          canEdit={canEdit}
        />
      );
    } else if (options[settingTypeId]?.type === 'object') {
      return (
        <EditableJson
          key={settingTypeId}
          id="new-editable-json"
          initialValue={value || '{}'}
          inputRef={(element: HTMLInputElement) => (refCollection.current[collectionId] = element)}
          initialView="preview"
          canEdit={canEdit}
        />
      );
    } else if (options[settingTypeId]?.type === 'numberFormat') {
      const valueFromCollection = fetchValueFromCollection(collectionId);
      return (
        <div key={settingTypeId} className="number-formatter-preview-wrapper">
          {(valueFromCollection ?? value) || translateText('label', 'Select a number format')}
          {canEdit && (
            <button
              className="btn btn-text no-padding"
              onClick={() => {
                setShowNumberFormatSetup(collectionId);
                if (!valueFromCollection) refCollection.current[collectionId] = value;
              }}
            >
              {translateText('label', 'Edit')}
            </button>
          )}
        </div>
      );
    } else if (!canEdit) {
      return value;
    }
    return (
      <TextField
        key={settingTypeId}
        defaultValue={value}
        placeholder={translateText('label', 'Value')}
        inputRef={(element: HTMLInputElement) => (refCollection.current[collectionId] = element)}
      />
    );
  }

  function fetchValueFromCollection(key: string): string | undefined {
    const refValue = refCollection.current[key];
    return refValue instanceof HTMLInputElement || refValue instanceof HTMLSelectElement
      ? refValue.value
      : refValue;
  }

  let formatForSetup: string | number = '';
  if (showNumberFormatSetup !== false) {
    formatForSetup = fetchValueFromCollection(showNumberFormatSetup) ?? '';
    if (formatForSetup !== '' && /^[0-9]*$/.test(String(formatForSetup))) {
      formatForSetup = Number(formatForSetup);
    } else if (typeof formatForSetup === 'string') {
      formatForSetup = formatForSetup.replace('{nat}', COUNTRY_CODE).replace('{area}', AREA_CODE);
    }
  }

  return (
    <>
      <NumberFormatSetup
        show={showNumberFormatSetup !== false}
        close={() => setShowNumberFormatSetup(false)}
        numberFormat={formatForSetup}
        updateNumberFormat={value => {
          refCollection.current[showNumberFormatSetup as string] = value;
          return Promise.resolve();
        }}
      />
      <h1>{translateText('label', 'Domain settings')}</h1>
      {isAdmin && (
        <div className="white-block">
          <h3 className="gap-bottom">{translateText('label', 'Add new domain setting')}</h3>
          <div className="form-row">
            <div className="name-label">{translateText('label', 'Setting type')}</div>
            <div className="value-wrapper">
              <SearchableSelect
                options={zipObject(
                  settingTypes?.map(s => s.id) ?? [],
                  settingTypes?.map(s => `${s.id} - ${s.name}`) ?? [],
                )}
                onChange={setting => {
                  refCollection.current.new = '';
                  setNewSettingType(setting);
                }}
                value={newSettingType}
                className="wide"
                disabled={!settingTypes?.length}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="name-label">{translateText('label', 'Value')}</div>
            <div className="value-wrapper">{renderValueElement('new')}</div>
          </div>
          <div className="form-button">
            <button
              onClick={() => {
                const value = fetchValueFromCollection('new');
                if (value === undefined) return;
                addSetting(newSettingType, value);
              }}
              disabled={newSettingType === '-1' || isAdding}
              className="btn btn-green"
            >
              {translateText('label', 'Add domain setting')}
            </button>
          </div>
        </div>
      )}
      <QueryTable<DomainSetting & { save: undefined; delete: undefined }>
        tableKey={['domainSetting', 'index', selectedDomain]}
        endpoint="/domain-setting/index"
        columns={{
          setting_type_id: {
            header: translateText('label', 'Setting type ID'),
            noNumberFormat: true,
          },
          name: { header: translateText('label', 'Setting type') },
          value: {
            header: translateText('label', 'Value'),
            customValue: (value, row) => renderValueElement(String(row.setting_type_id), value),
          },
          save: {
            header: '',
            button: {
              text: translateText('label', 'Save'),
              onClick: row => {
                const value = fetchValueFromCollection(String(row.setting_type_id));
                if (value === undefined) return;
                updateDomainSetting({
                  domainSettingId: String(row.id),
                  settingTypeId: String(row.setting_type_id),
                  value,
                });
              },
              className: 'btn btn-green',
              disabled: isUpdating,
            },
            viewCondition: row => isAdmin && options[row.setting_type_id]?.canEdit !== false,
          },
          delete: {
            header: '',
            button: {
              text: translateText('label', 'Delete'),
              onClick: row => deleteDomainSetting(String(row.id)),
              className: 'btn btn-purple',
              disabled: isDeleting,
            },
            viewCondition: row => isAdmin && options[row.setting_type_id]?.canEdit !== false,
          },
        }}
        filters={{ custom: { expand: 'setting_type_id' } }}
        pagination={{ defaultLimit: 20 }}
        isResponsive
        refresh
      />
    </>
  );
}
