import { useNavigate } from 'react-router';

import QueryTable from 'components/table/QueryTable';
import { useTranslate } from 'hooks/translate';

export default function ArticlesWithStatusConcept() {
  const navigate = useNavigate();
  const translateText = useTranslate();

  return (
    <>
      <h1>{translateText('label', 'Articles with status concept')}</h1>
      <QueryTable
        tableKey={['knowledgeCenter', 'articleOverview']}
        endpoint="/support/article/overview"
        dataKey="articlesWithStatus.concept"
        columns={{
          id: { header: translateText('label', 'ID') },
          title: { header: translateText('label', 'Title') },
        }}
        filters={{ domain: false }}
        onRowClick={article => navigate('/knowledge-center/article/' + article.id + '/edit')}
        refresh
      />
    </>
  );
}
