import { useFormikContext } from 'formik';

import Checkbox from 'components/input/Checkbox';
import Skeleton from 'components/Skeleton';
import Tooltip from 'components/Tooltip';
import { useFilters } from 'contexts/Filters';
import { ColumnSetting } from './Table';

type Props<T> = {
  tableKey: string;
  tableData: T[];
  multiSelect: {
    selected: (string | number)[];
    update: (row: T | number | T[] | string[] | number[]) => void;
    canSelectAll?: boolean;
    fullRow?: boolean;
  } | null;
  followNumber: boolean;
  rowCount: number;
  columns: [string, ColumnSetting<T>][];
  isLoading: boolean;
};

export default function Head<T extends Record<string, unknown>>({
  tableKey,
  tableData,
  multiSelect,
  followNumber,
  rowCount,
  columns,
  isLoading = false,
}: Props<T>) {
  const { values } = useFormikContext<{ columns: string[] }>();
  const { filters, updateTableFilter } = useFilters();
  const sortFilter = filters.tables?.[tableKey]?.sort;
  const sortArray = sortFilter ? sortFilter.split(',') : [];

  function updateSort(value: string) {
    updateTableFilter({ table: tableKey, type: 'sort', value });
  }

  function onSortClick(header: string) {
    if (!sortFilter) {
      // There is nothing sorted yet, so the header needs to be added descending
      updateSort('-' + header);
    } else if (sortArray.includes('-' + header)) {
      // The header is now sorted descending, so it needs to be changed to ascending
      sortArray.splice(sortArray.indexOf('-' + header), 1, header);
      updateSort(sortArray.join());
    } else if (sortArray.includes(header)) {
      // The header is now sorted ascending, so the sort needs to be removed
      updateSort(sortArray.filter((i: string) => i !== header).join());
    } else {
      // Another header is already sorted but the current header not yet, so the header needs to be added descending
      updateSort([...sortArray, '-' + header].join());
    }
  }

  let selectAll = null;
  if (multiSelect) {
    selectAll = <th />;
    if (multiSelect.canSelectAll) {
      selectAll = (
        <th>
          <Checkbox
            id="multi-select-all"
            checked={multiSelect.selected.length === rowCount}
            onChange={e => {
              let value: T[] | string[] = [];
              if (e.target.checked) {
                if (multiSelect.fullRow) value = tableData;
                else value = tableData.map(data => ('id' in data ? (data.id as string) : ''));
              }
              multiSelect.update(value);
            }}
          />
        </th>
      );
    }
  }
  return (
    <thead>
      <tr>
        {selectAll}
        {followNumber && <th />}
        {columns
          .filter(column => values.columns.includes(column[0]))
          .map((header, index) => {
            const [name, { header: config }] = header;
            const colHeader =
              typeof config === 'string' ? config : config?.name ?? name.replace(/_/g, ' ');

            let className = typeof config === 'object' && config.className ? config.className : '';
            const sort = typeof config === 'object' && config.sort;
            if (sort && !isLoading) className += ' clickable table-header';
            return (
              <th
                key={index}
                onClick={sort && !isLoading ? () => onSortClick(name) : () => null}
                className={className}
              >
                {isLoading ? ( // TODO AC-9655 remove string check
                  <Skeleton width={typeof colHeader === 'string' ? colHeader.length * 9 : 100} />
                ) : (
                  colHeader
                )}
                {!isLoading && (sortArray.includes(name) || sortArray.includes('-' + name)) && (
                  <span
                    data-testid="sort-arrow"
                    className={'arrow arrow-' + (sortArray.includes('-' + name) ? 'down' : 'up')}
                  />
                )}
                {!isLoading && typeof config === 'object' && config.tooltip && (
                  <Tooltip text={config.tooltip} />
                )}
              </th>
            );
          })}
      </tr>
    </thead>
  );
}
