import { useParams } from 'react-router';
import { Formik } from 'formik';

import SelectFromList from 'components/input/SelectFromList';
import Loader from 'components/Loader';
import { CategorySlug } from 'globals/types';
import { useCategoryType } from 'hooks/knowledge-center';
import {
  useArticles,
  useCategories,
  useUpdateCategoryOrder,
  useUpdateCategoryPopular,
} from 'hooks/queries/support';
import { useTranslate } from 'hooks/translate';

export default function CategoryOverviewEdit() {
  const translateText = useTranslate();
  const params = useParams<{ categoryType: CategorySlug }>();
  const { categoryTypeId } = useCategoryType(params?.categoryType ?? null);

  const { categories, isLoading: isLoadingOrder } = useCategories();
  const { articles, isLoading: isLoadingPopular } = useArticles({
    category_type: categoryTypeId,
    popular_type: categoryTypeId,
    expand: 'popular',
  });
  const { updateOrder, isLoading: isUpdatingOrder } = useUpdateCategoryOrder();
  const { updateCategoryPopular, isLoading: isUpdatingPopular } = useUpdateCategoryPopular(
    categoryTypeId ?? null,
  );

  if (isLoadingOrder || isLoadingPopular || isUpdatingOrder || isUpdatingPopular) return <Loader />;

  const typeCategories = categories?.filter(category => category.type === categoryTypeId) ?? [];

  return (
    <div className="category-type-edit">
      <div className="category-order">
        <h1>{translateText('label', 'Edit order of categories')}</h1>
        <Formik
          initialValues={{ categories: typeCategories }}
          onSubmit={values => updateOrder(values.categories)}
          enableReinitialize
        >
          {({ values, setFieldValue, submitForm, dirty }) => (
            <>
              <SelectFromList
                id="change-order"
                items={values.categories}
                onChange={categoryOrder => setFieldValue('categories', categoryOrder)}
                canChangeOrder
              />
              <button className="btn btn-green" disabled={!dirty} onClick={submitForm}>
                {translateText('label', 'Save')}
              </button>
            </>
          )}
        </Formik>
      </div>
      <h1>{translateText('label', 'Edit popular articles')}</h1>
      <Formik
        initialValues={{
          popularArticles:
            articles
              ?.filter(article => (article.popular ?? []).length > 0)
              .sort((a, b) => a.popular![0]!.order - b.popular![0]!.order)
              .map(article => String(article.id)) ?? [],
        }}
        onSubmit={values => updateCategoryPopular(values.popularArticles)}
        enableReinitialize
      >
        {({ values, setFieldValue, submitForm, dirty }) => (
          <>
            <SelectFromList
              id="set-articles"
              items={articles ?? []}
              nameKey="title"
              selected={values.popularArticles}
              canChangeOrder
              onChange={articles => setFieldValue('popularArticles', articles)}
              availableLabel={translateText('label', 'Available articles')}
              selectedLabel={translateText('label', 'Displayed articles')}
              limit={7}
            />
            <button className="btn btn-green" disabled={!dirty} onClick={submitForm}>
              {translateText('label', 'Save')}
            </button>
          </>
        )}
      </Formik>
    </div>
  );
}
