import { Navigate, Route, Routes } from 'react-router';

import { TYPE_FORM_TRACKING } from 'globals/constants';
import History from 'pages/authenticated/form-tracking/history/History';
import HistoryDetails from 'pages/authenticated/form-tracking/history/HistoryDetails';
import Error404 from 'pages/Error404';
import Reports from './Reports';

export default function FormTracking() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/form-tracking/reports/overview" />} />
      <Route path="/reports" element={<Navigate to="/form-tracking/reports/overview" />} />
      <Route path="/reports/*" element={<Reports type={TYPE_FORM_TRACKING} />} />
      <Route path="/history" element={<History />} />
      <Route path="/history/:id" element={<HistoryDetails />} />
      <Route path="/*" element={<Error404 />} />
    </Routes>
  );
}
