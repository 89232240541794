import { Formik } from 'formik';
import * as Yup from 'yup';

import ErrorFocus from 'components/ErrorFocus';
import TextField from 'components/input/TextField';
import Toggle from 'components/input/Toggle';
import {
  REDIRECT_URI_GOOGLE_DOUBLE_CLICK,
  REDIRECT_URI_GOOGLE_DV360,
  REDIRECT_URI_GOOGLE_SA360,
} from 'components/oauth2/Verification';
import VerificationButton from 'components/oauth2/VerificationButton';
import Skeleton from 'components/Skeleton';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import SupportParagraph from 'components/SupportParagraph';
import { useTenant } from 'contexts/Tenant';
import {
  INTEGRATION_DOUBLE_CLICK,
  INTEGRATION_GOOGLE_DISPLAY_VIDEO_360,
  INTEGRATION_GOOGLE_SEARCH_ADS_360,
  PLAN_ENTERPRISE,
  TYPE_CALL_TRACKING,
} from 'globals/constants';
import { useSetQueryData } from 'hooks/data';
import { useGoogle360 } from 'hooks/queries/integration';
import { useTranslate } from 'hooks/translate';
import CostParagraph from './CostParagraph';

export const TYPE_DISPLAY_VIDEO_360 = 'display-video-360' as const;
export const TYPE_DOUBLE_CLICK = 'double-click' as const;
export const TYPE_SEARCH_ADS_360 = 'search-ads-360' as const;

export type Google360Type =
  | typeof TYPE_DOUBLE_CLICK
  | typeof TYPE_DISPLAY_VIDEO_360
  | typeof TYPE_SEARCH_ADS_360;

export type Google360Data = {
  enabled: boolean;
  verified: boolean;
  userProfileId: string;
  floodlightActivityId: string;
  floodlightConfigurationId: string;
};

type Props = {
  type: Google360Type;
  title: string;
  icon: string;
  infoText: string;
  redirectUriKey:
    | typeof REDIRECT_URI_GOOGLE_DOUBLE_CLICK
    | typeof REDIRECT_URI_GOOGLE_DV360
    | typeof REDIRECT_URI_GOOGLE_SA360;
  integrationKey:
    | typeof INTEGRATION_DOUBLE_CLICK
    | typeof INTEGRATION_GOOGLE_DISPLAY_VIDEO_360
    | typeof INTEGRATION_GOOGLE_SEARCH_ADS_360;
  articleId?: number;
} & BaseSlideInProps;

export default function BaseGoogle360({
  type,
  show,
  close,
  icon,
  title,
  infoText,
  articleId,
  redirectUriKey,
  integrationKey,
}: Props) {
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { selectedDomain } = useTenant();
  const { data, isLoading, updateGoogle360 } = useGoogle360(type, integrationKey, show);

  // Form data is saved in local storage when the account gets verified, so the form is
  // still filled after redirect
  const localStorageData = localStorage.getItem(type);
  const savedValues = localStorageData ? (JSON.parse(localStorageData) as Google360Data) : null;

  async function save(values: Google360Data) {
    await updateGoogle360(values).then(() => close());
  }

  // The form values only need to be validated when the setting is enabled
  const valueValidation = Yup.string().test(function (value) {
    if (this.parent.enabled === false) return true;
    if (!value?.trim()) {
      return this.createError({ message: translateText('message', 'This field is required.') });
    }
    return /^[0-9]*$/.test(value)
      ? true
      : this.createError({
          message: translateText('message', 'The ID must be a numeric value.'),
        });
  });
  const validationSchema = Yup.object({
    userProfileId: valueValidation,
    floodlightActivityId: valueValidation,
    floodlightConfigurationId: valueValidation,
  });

  const initialValues: Google360Data = {
    enabled: savedValues?.enabled ?? !!data?.enabled ?? false,
    verified: data?.verified ?? false,
    userProfileId: savedValues?.userProfileId ?? data?.userProfileId ?? '',
    floodlightActivityId: savedValues?.floodlightActivityId ?? data?.floodlightActivityId ?? '',
    floodlightConfigurationId:
      savedValues?.floodlightConfigurationId ?? data?.floodlightConfigurationId ?? '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={save}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({
        values,
        handleChange,
        submitForm,
        resetForm,
        errors,
        isSubmitting,
        submitCount,
        dirty,
      }) => (
        <Setup
          show={show}
          close={close}
          afterClose={() => {
            resetForm();
            if (savedValues) localStorage.removeItem(type);
          }}
          icon={icon}
          title={title}
          hasChanges={dirty || !!savedValues}
          save={submitForm}
          isSaving={isSubmitting}
          maxWidth={500}
        >
          <ErrorFocus element={document.getElementsByClassName('setup-wrapper')[0]} />
          <p>{isLoading ? <Skeleton count={5} /> : infoText}</p>
          <CostParagraph price={null} plan={PLAN_ENTERPRISE} isLoading={isLoading} />
          {articleId && <SupportParagraph articleId={articleId} isLoading={isLoading} />}
          <h3 className="section-title">
            {isLoading ? <Skeleton width={150} /> : translateText('label', 'Connect account')}
          </h3>
          <InputWrapper
            label={translateText('label', 'User profile ID')}
            tooltip={translateText(
              'tooltip/google-360',
              'Enter the user profile ID associated with the relevant {integration} account here. The ID is a numeric value.',
              { integration: title },
            )}
            isLoading={isLoading}
          >
            {isLoading ? (
              <Skeleton width={300} height={38} />
            ) : (
              <TextField
                id="user-profile-id"
                name="userProfileId"
                value={values.userProfileId}
                onChange={handleChange}
                disabled={isSubmitting}
                error={submitCount > 0 && errors.userProfileId}
              />
            )}
          </InputWrapper>
          <VerificationButton
            verified={values.verified}
            disconnectEndpoint={'/integration/google-' + type + '/disconnect'}
            onDisconnectSuccess={() =>
              setQueryData<Google360Data>(['google360', type, selectedDomain], oldData => ({
                ...oldData,
                verified: false,
              }))
            }
            integrationName={title}
            redirectUri={'/integration/google-' + type + '/redirect-uri'}
            redirectUriKey={redirectUriKey}
            queryParams={{ type: TYPE_CALL_TRACKING }}
            beforeRedirect={() => dirty && localStorage.setItem(type, JSON.stringify(values))}
            isGoogle
            isLoading={isLoading}
          />

          <h3 className="section-title">
            {isLoading ? <Skeleton width={150} /> : translateText('label', 'Send conversions')}{' '}
          </h3>
          <InputWrapper
            label={translateText('label', 'Send conversions')}
            tooltip={translateText(
              'integration',
              'Disable and enable the sending of calls to {integration} here.',
              { integration: title },
            )}
            isLoading={isLoading}
          >
            <Toggle
              name="enabled"
              checked={values.enabled}
              onClick={handleChange}
              disabled={isSubmitting}
              isLoading={isLoading}
            />
          </InputWrapper>
          <InputWrapper
            label={translateText('label', 'Floodlight Activity ID')}
            tooltip={translateText(
              'tooltip/google-360',
              'Enter the ID associated with the Floodlight Activity for call conversions here. The ID is a numeric value.',
            )}
            isLoading={isLoading}
          >
            {isLoading ? (
              <Skeleton width={300} height={38} />
            ) : (
              <TextField
                id="floodlight-activity-id"
                name="floodlightActivityId"
                disabled={isSubmitting || !values.enabled}
                value={values.floodlightActivityId}
                onChange={handleChange}
                error={submitCount > 0 && errors.floodlightActivityId}
              />
            )}
          </InputWrapper>
          <InputWrapper
            label={translateText('label', 'Floodlight configuration ID')}
            tooltip={translateText(
              'tooltip/google-360',
              'Enter the configuration ID associated with the Floodlight Activity for call conversions here. The ID is a numeric value.',
            )}
            isLoading={isLoading}
          >
            {isLoading ? (
              <Skeleton width={300} height={38} />
            ) : (
              <TextField
                id="floodlight-configuration-id"
                name="floodlightConfigurationId"
                disabled={isSubmitting || !values.enabled}
                value={values.floodlightConfigurationId}
                onChange={handleChange}
                error={submitCount > 0 && errors.floodlightConfigurationId}
              />
            )}
          </InputWrapper>
        </Setup>
      )}
    </Formik>
  );
}
