import { useState } from 'react';
import { Formik } from 'formik';

import AddButton from 'components/buttons/AddButton';
import ErrorFocus from 'components/ErrorFocus';
import SearchableSelect from 'components/input/SearchableSelect';
import Select from 'components/input/Select';
import TextField from 'components/input/TextField';
import Toggle from 'components/input/Toggle';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import { useCountryTranslations } from 'hooks/content';
import { DebtorFormValues, useCreateDebtor } from 'hooks/queries/debtor';
import { useTranslate } from 'hooks/translate';
import { AdminDomain } from 'pages/authenticated/admin/domains/Overview';

type Props = {
  initialValues?: AdminDomain['intake_invoicing_details'];
  onSuccess?: (debtorId: string) => void;
};

export default function AddDebtor({ initialValues, onSuccess }: Props) {
  const translateText = useTranslate();
  const countries = useCountryTranslations();
  const { createDebtor } = useCreateDebtor();
  const [show, setShow] = useState(false);

  async function save(values: DebtorFormValues) {
    await createDebtor(values).then(data => {
      setShow(false);
      if (onSuccess) onSuccess(data.chargebee_customer_id);
    });
  }

  return (
    <>
      <AddButton onClick={() => setShow(true)} className="margin-left" />
      <Formik
        initialValues={{
          company: initialValues?.company ?? '',
          firstName: initialValues?.name.split(' ')[0] ?? '',
          lastName: initialValues?.name.split(' ').slice(1).join(' ') ?? '',
          address: initialValues?.address ?? '',
          postalCode: initialValues?.postal_code ?? '',
          city: initialValues?.city ?? '',
          email: initialValues?.email ?? '',
          automaticDebit: !!initialValues?.automatic_collection,
          vat: initialValues?.vat_number ?? '',
          country: initialValues?.country ?? 'NL',
          discount: '0',
          expirationDays: '14',
          oneInvoice: false,
        }}
        enableReinitialize
        onSubmit={save}
      >
        {({ values, handleChange, submitForm, setFieldValue, isSubmitting }) => (
          <Setup
            id="add-debtor"
            show={show}
            close={() => setShow(false)}
            title={translateText('label', 'Add Chargebee customer')}
            save={submitForm}
            isSaving={isSubmitting}
            maxWidth={400}
          >
            <ErrorFocus element={document.getElementById('add-debtor')} />
            <InputWrapper label={translateText('label', 'Company name')}>
              <TextField
                name="company"
                value={values.company}
                onChange={handleChange}
                placeholder={translateText('label', 'Company name')}
              />
            </InputWrapper>
            <InputWrapper label={translateText('label', 'First name')}>
              <TextField
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                placeholder={translateText('label', 'First name')}
              />
            </InputWrapper>
            <InputWrapper label={translateText('label', 'Last name')}>
              <TextField
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                placeholder={translateText('label', 'Last name')}
              />
            </InputWrapper>
            <InputWrapper label={translateText('label', 'Country')}>
              <SearchableSelect
                options={countries}
                value={values.country}
                onChange={value => setFieldValue('country', value)}
              />
            </InputWrapper>
            <InputWrapper label={translateText('label', 'VAT-number')}>
              <TextField
                name="vat"
                value={values.vat}
                onChange={handleChange}
                placeholder={translateText('label', 'VAT-number')}
              />
            </InputWrapper>
            <InputWrapper label={translateText('label', 'Address')}>
              <TextField
                name="address"
                value={values.address}
                onChange={handleChange}
                placeholder={translateText('label', 'Address')}
              />
            </InputWrapper>
            <InputWrapper label={translateText('label', 'Postal code')}>
              <TextField
                name="postalCode"
                value={values.postalCode}
                onChange={handleChange}
                placeholder={translateText('label', 'Postal code')}
              />
            </InputWrapper>
            <InputWrapper label={translateText('label', 'City')}>
              <TextField
                name="city"
                value={values.city}
                onChange={handleChange}
                placeholder={translateText('label', 'City')}
              />
            </InputWrapper>
            <InputWrapper label={translateText('label', 'Email address invoicing')}>
              <TextField
                name="email"
                value={values.email}
                onChange={handleChange}
                placeholder={translateText('label', 'Email address invoicing')}
              />
            </InputWrapper>
            <InputWrapper label={translateText('label', 'Invoice discount')}>
              <TextField
                value={values.discount}
                onChange={e => {
                  setFieldValue(
                    'discount',
                    Math.max(Math.min(Number(e.target.value.replace(/[^0-9]/g, '')), 100), 0),
                  );
                }}
                placeholder={translateText('label', 'Invoice discount')}
              />
              %
            </InputWrapper>
            <InputWrapper label={translateText('label', 'Expiration days')}>
              <Select
                name="expirationDays"
                value={values.expirationDays}
                onChange={handleChange}
                options={[14, 30, 45]}
              />
            </InputWrapper>
            <InputWrapper label={translateText('label', 'All domains on one invoice')}>
              <Toggle name="oneInvoice" checked={values.oneInvoice} onClick={handleChange} />
            </InputWrapper>
            <InputWrapper label={translateText('label', 'Automatic debit')}>
              <Toggle
                name="automaticDebit"
                checked={values.automaticDebit}
                onClick={handleChange}
              />
            </InputWrapper>
          </Setup>
        )}
      </Formik>
    </>
  );
}
