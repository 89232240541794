import Skeleton from 'components/Skeleton';
import { useRoutes } from 'contexts/Routes';
import { useTenant } from 'contexts/Tenant';
import { PH_MASK } from 'globals/constants';
import { useInvitationCount } from 'hooks/queries/invitation';
import { useUnreadNotifications } from 'hooks/queries/notification';
import { useUser } from 'hooks/queries/user';

type Props = {
  whiteLoader?: boolean;
  noNotificationCount?: boolean;
  isLoading?: boolean;
};

export default function UserAvatar({ noNotificationCount, whiteLoader, isLoading }: Props) {
  const { navigationDisabled } = useRoutes();
  const { selectedDomain } = useTenant();

  const { user, isLoading: isLoadingUser } = useUser();
  const { notifications } = useUnreadNotifications();
  const { invitationCount } = useInvitationCount();
  isLoading = !!isLoading || isLoadingUser;

  function renderNotificationCount() {
    if (navigationDisabled || noNotificationCount || isLoading) return null;

    const count =
      (invitationCount ?? 0) +
      (notifications?.filter(n => n.domain_id === null || n.domain_id === selectedDomain).length ??
        0);

    if (count === 0) return null;
    return (
      <div className="notification-count">
        <div className="count-icon">{count}</div>
      </div>
    );
  }

  let initials = null;
  if (user?.first_name && user?.last_name) {
    initials =
      user.first_name[0].toUpperCase() + user.last_name.split(' ').pop()?.[0].toUpperCase();
  }

  const avatar = (
    <div className="avatar">
      <span>{initials}</span>
    </div>
  );

  return (
    <div className={`user-avatar ${PH_MASK}`}>
      {renderNotificationCount()}
      {isLoading ? <Skeleton className="avatar" isWhite={whiteLoader} noMargin /> : avatar}
    </div>
  );
}
