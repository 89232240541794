import warning from 'assets/images/icons/exclamation-blue.svg';
import criticalWarning from 'assets/images/icons/table/icon-status-warning.svg';
import EditButton from 'components/buttons/EditButton';
import { BaseItem } from 'components/configuration/card-list/CardListView';
import Skeleton from 'components/Skeleton';
import Tooltip from 'components/Tooltip';
import { PH_MASK } from 'globals/constants';

type Props = {
  item: BaseItem;
  isSelected: boolean;
  setSelected: () => void;
  hasError?: boolean;
  showIdentifier?: boolean;
  setEdit: () => void;
  isLoading?: boolean;
  noAccessCheck?: boolean;
};

export default function ListItem({
  item = { identifier: '', name: '' },
  isSelected = false,
  setSelected,
  hasError = false,
  showIdentifier = false,
  setEdit,
  isLoading = false,
  noAccessCheck = false,
}: Props) {
  let className = 'list-item white-block';
  if (isSelected) className += ' selected bold';
  if (hasError) className += ' error';

  function getLeftSide() {
    if (isLoading) return <Skeleton />;
    return (
      <>
        {item.enabled !== undefined && (
          <div className={'big-dot ' + (item.enabled ? 'active' : 'inactive')} />
        )}
        {showIdentifier && <div className="identifier">{item.identifier ?? ''}</div>}
        <div className={PH_MASK}>{item.name}</div>
      </>
    );
  }

  return (
    <div className={className} onClick={() => setSelected()}>
      <div className="left">{getLeftSide()}</div>
      <div className="right">
        {!isLoading && item.criticalWarning && (
          <Tooltip icon={criticalWarning} text={item.criticalWarning} />
        )}
        {!isLoading && item.warning && <Tooltip icon={warning} text={item.warning} />}
        {!isLoading && <div className={'arrow arrow-' + (isSelected ? 'down-right' : 'right')} />}
        <EditButton
          onClick={e => {
            e.stopPropagation();
            setEdit();
          }}
          isSmall
          isLoading={isLoading}
          noAccessCheck={noAccessCheck}
        />
      </div>
    </div>
  );
}
