import { Formik } from 'formik';
import * as Yup from 'yup';

import ErrorFocus from 'components/ErrorFocus';
import TextField from 'components/input/TextField';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import { useImplementation } from 'hooks/queries/domain';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation } from 'hooks/validation';

export default function SendImplementation({ show, close }: BaseSlideInProps) {
  const translateText = useTranslate();
  const { multipleEmail } = useCustomValidation();
  const { sendImplementation } = useImplementation();

  async function save(values: { email: string; comment: string }) {
    await sendImplementation(values).then(close);
  }

  return (
    <Formik
      initialValues={{ email: '', comment: '' }}
      onSubmit={async values => await save(values)}
      validationSchema={Yup.object({
        email: multipleEmail.required(translateText('message', 'This field is required.')),
      })}
    >
      {({ values, submitForm, isSubmitting, handleChange, touched, errors, handleBlur }) => (
        <Setup
          id="send-implementation"
          show={show}
          title={translateText('label', 'Send implementation instructions by email')}
          close={close}
          save={submitForm}
          saveButtonText={translateText('label', 'Send email')}
          isSaving={isSubmitting}
          maxWidth={440}
        >
          <ErrorFocus element={document.getElementById('send-implementation')} />
          <p>
            {translateText(
              'text',
              'Is someone else performing the implementation? In this case, you can send the full implementation instructions by email. Enter the desired email addresses below. Multiple e-mail addresses must be entered with a comma.',
            )}
          </p>
          <h3 className="section-title">{translateText('label', 'Send implementation mail')}</h3>
          <InputWrapper label={translateText('label', 'Email address(es)')}>
            <TextField
              id="implementation-email"
              name="email"
              disabled={isSubmitting}
              placeholder={translateText('label', 'example-1@domain.com, example-2@domain.com')}
              value={values.email}
              onChange={handleChange}
              error={touched.email && errors.email}
              onBlur={handleBlur}
            />
          </InputWrapper>
          <InputWrapper label={translateText('label', 'Comment (optional)')}>
            <TextField
              name="comment"
              disabled={isSubmitting}
              value={values.comment}
              onChange={handleChange}
            />
          </InputWrapper>
        </Setup>
      )}
    </Formik>
  );
}
