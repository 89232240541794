import { useSearchParams } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import { omit } from 'lodash';
import * as Yup from 'yup';

import successVisual from 'assets/images/create-account-success.svg';
import ErrorFocus from 'components/ErrorFocus';
import PasswordValidator from 'components/PasswordValidator';
import ContentSidebar from 'components/unauthenticated/ContentSidebar';
import LoginNewsPage from 'components/unauthenticated/LoginNewsPage';
import { useMessages } from 'contexts/Messages';
import { useResetPassword } from 'hooks/queries/auth';
import { useTranslate } from 'hooks/translate';

type FormValues = {
  password: string;
  verifyPassword: string;
  conditionsPassed: boolean;
};

export default function ResetPassword() {
  const translateText = useTranslate();
  const { addErrorMessage } = useMessages();
  const { resetPassword, status } = useResetPassword();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  async function save(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    await resetPassword({ ...omit(values, 'conditionsPassed'), token: token! }).catch(error => {
      if (error.password) formikHelpers.setFieldError('password', error.password[0]);
      else addErrorMessage(error);
    });
  }

  const validationSchema = Yup.object({
    conditionsPassed: Yup.boolean().isTrue(
      translateText('message', 'Not all password conditions have been met'),
    ),
  });

  return (
    <ContentSidebar
      left={
        <Formik
          initialValues={{ password: '', verifyPassword: '', conditionsPassed: false }}
          onSubmit={save}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, handleSubmit, errors, isSubmitting, submitCount }) => (
            <form onSubmit={handleSubmit}>
              <ErrorFocus />
              <h2 className="bold margin-bottom">{translateText('label', 'Set a new password')}</h2>
              <p>{translateText('text', 'Enter your new password below.')}</p>
              <PasswordValidator
                password={values.password}
                passwordVerification={values.verifyPassword}
                setPassword={value => setFieldValue('password', value)}
                setPasswordVerification={value => setFieldValue('verifyPassword', value)}
                setConditionsPassed={value => setFieldValue('conditionsPassed', value)}
                error={submitCount > 0 && (errors.conditionsPassed ?? errors.password)}
                placeholder={translateText('label', 'New password')}
                disabled={!token || isSubmitting}
              />
              <button
                className="btn btn-green btn-wide margin-top"
                type="submit"
                disabled={!token || isSubmitting}
              >
                {translateText('label', 'Save')}
              </button>
            </form>
          )}
        </Formik>
      }
      right={status === 'success' ? <img alt="AdCalls" src={successVisual} /> : <LoginNewsPage />}
      alignCenter
    />
  );
}
