import { ChangeEventHandler } from 'react';

import ErrorTooltip from 'components/ErrorTooltip';
import Tooltip from 'components/Tooltip';
import { useTranslate } from 'hooks/translate';

type BaseProps = {
  id: string;
  checked?: boolean;
  name?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  hasIndent?: boolean;
  error?: boolean | string;
  disabled?: boolean;
  tooltip?: string;
  hover?: string | null;
  canEdit?: boolean;
  title?: string;
};

type PropsWithLabel = {
  label: string | JSX.Element;
  labelClassName?: string;
} & BaseProps;

type PropsWithoutLabel = {
  label?: never;
  labelClassName?: never;
} & BaseProps;

export default function Checkbox({
  labelClassName,
  hasIndent,
  error,
  checked,
  onChange,
  name,
  id,
  disabled,
  label,
  tooltip,
  hover,
  canEdit = true,
  title,
}: PropsWithLabel | PropsWithoutLabel) {
  const translateText = useTranslate();
  let labelClass = 'checkbox-label';
  if (labelClassName) labelClass += ' ' + labelClassName;

  let wrapperClassName = 'custom-input';
  if (hasIndent) wrapperClassName += ' indent';

  let inputClass = 'checkbox';
  if (error) inputClass += ' error';

  if (!canEdit) {
    return <span>{checked ? translateText('label', 'Yes') : translateText('label', 'No')}</span>;
  }

  return (
    <div className={wrapperClassName} data-testid="checkbox-wrapper">
      <ErrorTooltip
        error={error}
        html={
          <>
            <input
              className={inputClass}
              type="checkbox"
              checked={checked}
              onChange={onChange}
              name={name}
              id={id}
              disabled={disabled}
              data-testid="checkbox"
            />
            <label htmlFor={id}>
              <span className="checkbox-inner" />
              <span className={labelClass} title={title}>
                {hover ? (
                  <Tooltip text={hover} html={<>{label}</>} placement="bottom" inline={false} />
                ) : (
                  label
                )}
                {tooltip && <Tooltip text={tooltip} />}
              </span>
            </label>
          </>
        }
      />
    </div>
  );
}
