import { useMutation, useQuery } from '@tanstack/react-query';
import { omit } from 'lodash';

import { useMessages } from 'contexts/Messages';
import { useTenant } from 'contexts/Tenant';
import { useSetQueryData } from 'hooks/data';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';
import { BlueConicData } from 'pages/authenticated/integrations/data-management/BlueConic';

export function useBlueConic(enabled: boolean) {
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { selectedDomain: id } = useTenant();
  const { addSuccessMessage } = useMessages();

  const { data: blueConic, isFetching: isLoading } = useQuery({
    queryKey: ['blueConic', id],
    queryFn: (): Promise<BlueConicData> =>
      fetchData('/app/blue-conic/view', { queryParams: { id } }),
    enabled: enabled && id !== null,
  });

  const { mutateAsync: updateBlueConic } = useMutation({
    mutationFn: (values: BlueConicData) =>
      fetchData('/app/blue-conic/change', {
        method: 'PUT',
        queryParams: { id },
        bodyParams: {
          enabled: values.enabled,
          create_profile: values.createProfile,
          match_type: values.match.type,
          match_key: values.match.key,
          token: values.token,
          address: values.address,
        },
      }),

    meta: { noError: true },
    onSuccess: (data, variables) => {
      setQueryData<Record<string, number>>(['integrationStatuses', id], oldData => ({
        ...oldData,
        blueConic: +variables.enabled,
      }));
      setQueryData(['blueConic', id], () => ({
        address: variables.address,
        createProfile: variables.createProfile,
        enabled: variables.enabled,
        match: { key: variables.match.key, type: variables.match.type },
        token: variables.token,
      }));
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { blueConic, isLoading, updateBlueConic };
}

// Google Analytics 4
export type Stream = {
  id: number;
  measurement_id: string;
  api_secret: string;
  enabled: boolean;
  ecommerce_enabled: boolean;
  ecommerce_currency: string;
  send_recurring_calls: boolean;
  second_event_enabled: boolean;
  send_default_event: boolean;
  send_offsite_calls: boolean;
  send_fallback_calls: boolean;
  filters_enabled: boolean;
  filters: Filter[];
};

export type Filter = {
  id: number;
  ga4_stream_id: number;
  enabled: boolean;
  event_name: string;
  conditions: Condition[];
};

export type Condition = {
  ga4_filter_id: number;
  key: ConditionKey;
  operand: string;
  value: string;
};

export type ConditionKey =
  | 'adcalls_location'
  | 'adcalls_evaluation'
  | 'adcalls_selection_menu'
  | 'adcalls_answered'
  | 'adcalls_returning_caller'
  | 'adcalls_second_event'
  | 'adcalls_evaluation_value';

export function useStreams(enabled: boolean) {
  const { fetchData } = useFetch();
  const { selectedDomain: domain } = useTenant();

  const { data, isFetching } = useQuery({
    queryKey: ['ga4', 'streams', domain],
    queryFn: (): Promise<Stream[]> =>
      fetchData('/app/google-analytics-4/streams', {
        queryParams: { domain_id: domain, expand: 'filters.conditions' },
      }),
    enabled: domain !== null && enabled,
  });

  return { streams: data, isLoading: isFetching };
}

export function useCreateStream() {
  const translateText = useTranslate();
  const setQueryData = useSetQueryData();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain: domain } = useTenant();

  const { mutateAsync, isPending: isSaving } = useMutation({
    mutationFn: (stream: Omit<Stream, 'id' | 'filters'>) =>
      fetchData('/app/google-analytics-4/create-stream', {
        method: 'POST',
        bodyParams: {
          ...stream,
          domain_id: domain,
          debug: false,
        },
      }),
    meta: { noError: true },
    onSuccess: (data, variables) => {
      if (variables.enabled) {
        setQueryData<Record<string, number>>(['integrationStatuses', domain], oldData => ({
          ...oldData,
          googleAnalytics4: 1,
        }));
      }
      setQueryData<Stream[]>(['ga4', 'streams', domain], oldData => [...oldData, data]);
      addSuccessMessage(translateText('message', 'Stream saved'));
    },
  });

  return { createStream: mutateAsync, isSaving };
}

export function useUpdateStream() {
  const translateText = useTranslate();
  const setQueryData = useSetQueryData();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain: domain } = useTenant();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (stream: Omit<Stream, 'filters'>) =>
      fetchData('/app/google-analytics-4/update-stream', {
        method: 'PUT',
        queryParams: { id: stream.id },
        bodyParams: {
          ...omit(stream, 'id'),
          debug: false,
        },
      }),
    onSuccess: (data, variables) => {
      let integrationEnabled = variables.enabled;
      setQueryData<Stream[]>(['ga4', 'streams', domain], oldData =>
        oldData.map(stream => {
          if (stream.id === data.id) return data;
          if (stream.enabled) integrationEnabled = true;
          return stream;
        }),
      );
      setQueryData<Record<string, number>>(['integrationStatuses', domain], oldData => ({
        ...oldData,
        googleAnalytics4: +integrationEnabled,
      }));
      addSuccessMessage(translateText('message', 'Stream saved'));
    },
  });

  return { updateStream: mutateAsync, isUpdating: isPending };
}

export function useDeleteStream() {
  const translateText = useTranslate();
  const setQueryData = useSetQueryData();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain: domain } = useTenant();

  const { mutate, isPending } = useMutation({
    mutationFn: (id: number) =>
      fetchData('/app/google-analytics-4/delete-stream', {
        method: 'DELETE',
        queryParams: { id },
      }),
    onSuccess: (_, id) => {
      let integrationEnabled = false;
      setQueryData<Stream[]>(['ga4', 'streams', domain], oldData =>
        oldData.filter(stream => {
          if (stream.id !== id) {
            integrationEnabled = integrationEnabled || stream.enabled;
            return true;
          }
          return false;
        }),
      );
      setQueryData<Record<string, number>>(['integrationStatuses', domain], oldData => ({
        ...oldData,
        googleAnalytics4: +integrationEnabled,
      }));
      addSuccessMessage(translateText('message', 'Stream deleted'));
    },
  });

  return {
    deleteStream: (id: number) => {
      if (
        window.confirm(
          translateText('message', 'Are you sure you want to delete this {type}?', {
            type: translateText('label', 'Stream').toLowerCase(),
          }),
        )
      ) {
        mutate(id);
      }
    },
    isDeleting: isPending,
  };
}

export function useCreateFilter() {
  const translateText = useTranslate();
  const setQueryData = useSetQueryData();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain: domain } = useTenant();
  const { updateConditions } = useUpdateConditions();

  const { mutateAsync } = useMutation({
    mutationFn: ({
      streamId,
      filter,
    }: {
      streamId: number;
      filter: Omit<Filter, 'id' | 'ga4_stream_id'>;
    }) =>
      fetchData('/app/google-analytics-4/create-filter', {
        method: 'POST',
        bodyParams: {
          ga4_stream_id: streamId,
          ...omit(filter, 'conditions'),
        },
      }),
    onSuccess: async (data, variables) => {
      await updateConditions({ filterId: data.id, conditions: variables.filter.conditions }).then(
        () => {
          setQueryData<Stream[]>(['ga4', 'streams', domain], oldData =>
            oldData.map(i =>
              i?.id === variables.streamId ? { ...i, filters: [...(i.filters ?? []), data] } : i,
            ),
          );
          addSuccessMessage(translateText('message', 'Filter saved'));
        },
      );
    },
  });

  return { createFilter: mutateAsync };
}

export function useUpdateFilter() {
  const translateText = useTranslate();
  const setQueryData = useSetQueryData();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain: domain } = useTenant();
  const { updateConditions } = useUpdateConditions();

  const { mutateAsync } = useMutation({
    mutationFn: ({
      streamId,
      filter,
    }: {
      streamId: number;
      filter: Omit<Filter, 'ga4_stream_id'>;
    }) =>
      fetchData('/app/google-analytics-4/update-filter', {
        method: 'PUT',
        queryParams: { id: filter.id },
        bodyParams: {
          ga4_stream_id: streamId,
          ...omit(filter, ['id', 'conditions']),
        },
      }),
    onSuccess: async (data, variables) => {
      await updateConditions({ filterId: data.id, conditions: variables.filter.conditions }).then(
        () => {
          setQueryData<Stream[]>(['ga4', 'streams', domain], oldData =>
            oldData.map(i =>
              i?.id === variables.streamId
                ? {
                    ...i,
                    filters: i.filters.map(f =>
                      f.id === variables.filter.id ? { ...variables.filter, ...data } : f,
                    ),
                  }
                : i,
            ),
          );
          addSuccessMessage(translateText('message', 'Filter saved'));
        },
      );
    },
  });

  return { updateFilter: mutateAsync };
}

export function useDeleteFilter() {
  const translateText = useTranslate();
  const setQueryData = useSetQueryData();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain: domain } = useTenant();

  const { mutate, isPending } = useMutation({
    mutationFn: (filter: Filter) =>
      fetchData('/app/google-analytics-4/delete-filter', {
        method: 'DELETE',
        queryParams: { id: filter.id },
      }),
    onSuccess: (data, filter) => {
      setQueryData<Stream[]>(['ga4', 'streams', domain], oldData =>
        oldData.map(stream =>
          stream.id === filter.ga4_stream_id
            ? { ...stream, filters: stream.filters.filter(f => f.id !== filter.id) }
            : stream,
        ),
      );
      addSuccessMessage(translateText('message', 'Filter deleted'));
    },
  });

  return {
    deleteFilter: (filter: Filter) => {
      if (
        window.confirm(
          translateText('message', 'Are you sure you want to delete this {type}?', {
            type: translateText('label', 'Filter').toLowerCase(),
          }),
        )
      ) {
        mutate(filter);
      }
    },
    isDeleting: isPending,
  };
}

export function useUpdateConditions() {
  const { fetchData } = useFetch();

  const { mutateAsync } = useMutation({
    mutationFn: ({ filterId, conditions }: { filterId: number; conditions: Condition[] }) =>
      fetchData('/app/google-analytics-4/set-conditions', {
        method: 'PUT',
        queryParams: { filter_id: filterId },
        bodyParams: conditions,
      }),
  });

  return { updateConditions: mutateAsync };
}
