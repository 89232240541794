import { PropsWithChildren } from 'react';

import { getDefaultFilters } from 'contexts/Filters';
import { TYPE_CALL_TRACKING } from 'globals/constants';
import RefreshButton from './buttons/RefreshButton';
import DownloadButtons from './table/DownloadButtons';
import { Filters } from './table/QueryTable';

type Props = {
  queryKey: string[];
  title?: string;
  className?: string;
  download?: {
    method?: 'GET' | 'POST';
    endpoint: string;
    filters?: Filters;
    disabled?: boolean;
  };
};

export default function ReportsPage({
  queryKey,
  title,
  children,
  className,
  download,
}: PropsWithChildren<Props>) {
  const defaultFilters = getDefaultFilters(TYPE_CALL_TRACKING);
  return (
    <div className={'reports-wrapper' + (className ? ' ' + className : '')}>
      <div className="header">
        {title && <h1>{title}</h1>}
        <div className="export-refresh-buttons">
          {download && (
            <DownloadButtons
              method={download.method}
              endpoint={download.endpoint}
              download="pdf"
              disabled={download?.disabled ?? false}
              filters={download?.filters ?? defaultFilters}
            />
          )}
          <RefreshButton queryKey={queryKey} />
        </div>
      </div>
      {children}
    </div>
  );
}
