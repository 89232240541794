import { ReactElement, useState } from 'react';
import { useFormikContext } from 'formik';

import Checkbox from 'components/input/Checkbox';
import TextField from 'components/input/TextField';
import { SelectedFilters } from 'contexts/Filters';
import { PH_MASK } from 'globals/constants';
import { useTranslate } from 'hooks/translate';

type Props = {
  filterKey:
    | 'sources'
    | 'locations'
    | 'statusFormTracking'
    | 'statusGA4'
    | 'statusGoogleAds'
    | 'statusMicrosoftAdvertising'
    | 'statusDoubleClick'
    | 'statusLef'
    | 'traffic';
  options:
    | Record<string | number, string>
    | Record<string, { name: string; tooltip?: string; isActive?: boolean }>;
  twoColumns?: boolean;
  sort?: boolean;
  isPrivate?: boolean;
};

export default function CheckboxList({ filterKey, options, twoColumns, sort, isPrivate }: Props) {
  const translateText = useTranslate();
  const { values, setFieldValue } = useFormikContext<SelectedFilters>();

  const [search, setSearch] = useState('');
  const selectedValues = values[filterKey] ?? [];

  function toggleCheckbox(id: number | string, checked: boolean) {
    if (checked) {
      setFieldValue(filterKey, [...selectedValues, id]);
    } else {
      setFieldValue(
        filterKey,
        [...selectedValues].filter(currentId => String(id) !== String(currentId)),
      );
    }
  }

  function selectAll() {
    setFieldValue(filterKey, Object.keys(options));
  }

  function clearAll() {
    setFieldValue(filterKey, []);
  }

  function getCheckbox(id: number | string, label: string, tooltip?: string, isActive = true) {
    let className = 'extra-margin';
    if (!isActive) className += ' no-data';
    if (isPrivate) className += ' ' + PH_MASK;
    return (
      <Checkbox
        key={id}
        onChange={e => toggleCheckbox(id, e.target.checked)}
        checked={selectedValues.map((v: number | string) => String(v)).includes(String(id))}
        label={label}
        id={`${filterKey}-${id}`}
        labelClassName={className}
        tooltip={tooltip}
        hover={!isActive ? translateText('label', 'Inactive') : null}
        title={label}
      />
    );
  }

  let items = Object.entries(options);
  if (search !== '') {
    items = items.filter(i => {
      if (typeof i[1] === 'string') return i[1].toLowerCase().includes(search.toLowerCase());
      return i[1].name.toLowerCase().includes(search.toLowerCase());
    });
  }
  if (sort)
    items.sort((a, b) => {
      if (b[0] === 'null') return -1;
      if (typeof b[1] === 'string') return a[1].toLowerCase().localeCompare(b[1].toLowerCase());
      return a[1].name.toLowerCase().localeCompare(b[1].name.toLowerCase());
    });

  let content: ReactElement | ReactElement[] = items.map(([key, value]) => {
    if (typeof value === 'string') return getCheckbox(key, value);
    return getCheckbox(
      key,
      value.name,
      value.tooltip,
      value.isActive === undefined || value.isActive,
    );
  });

  if (twoColumns) {
    const cutoff = content.length > 9 ? Math.ceil(content.length / 2) : content.length;
    content = (
      <>
        <TextField
          wrapperClassName="search"
          onChange={e => setSearch(e.target.value)}
          value={search}
          placeholder={translateText('label', 'Search')}
        />
        <div className="checkbox-list">
          <div className="column">{content.splice(0, cutoff)}</div>
          {content.length > 0 && <div className="column">{content}</div>}
        </div>
      </>
    );
  }

  return (
    <div className="filter-selector">
      {content}
      <div className="select-clear-all">
        <div onClick={selectAll}>{translateText('label', 'Select all')}</div>
        <div onClick={clearAll}>{translateText('label', 'Clear selection')}</div>
      </div>
    </div>
  );
}
