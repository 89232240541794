export default {
    "At the bottom of the <b>main navigation<\/b> you can navigate to the domain overview, the applications, the overarching domain settings and to our knowledge center. In our knowledge center you will find inspiration and all our support articles.": "Navigeer in de onderzijde van de <b>hoofdnavigatie<\/b> naar het domeinoverzicht, de aanvragen, de overkoepelende domein-instellingen en naar ons kenniscentrum. In ons kenniscentrum vind je inspiratie en al onze support-artikelen.",
    "Complete the tour": "Rondleiding afronden",
    "Domain overview": "Domeinoverzicht",
    "Don't have time for the tour? Or do you already know how to find your way in our dashboard? Then you can skip the tour. If you want to start the tour again later, click on your avatar and then on the 'Start tour' button.": "Heb je geen tijd voor de rondleiding? Of weet jij al goed de weg te vinden in ons dashboard? Dan kun je de rondleiding overslaan. Mocht je later toch nog eens de rondleiding willen starten, klik dan op jouw avatar en vervolgens op de button \u2018Rondleiding starten\u2019.",
    "Easily navigate to our <b>knowledge center<\/b> from the main navigation. Within the knowledge center you will find a lot of knowledge and inspiration about all our services, functionalities and integrations. Under support you will find all our support articles in which we explain how you can get the most out of our services.": "Navigeer vanuit de hoofdnavigatie gemakkelijk naar ons <b>kenniscentrum<\/b>. Binnen het kenniscentrum vind je veel kennis en inspiratie over al onze diensten, functionaliteiten en integraties. Onder support vind je al onze support-artikelen waarin wij toelichten hoe je het maximale uit onze diensten kunt halen.",
    "In addition, you will also find marketing material in the knowledge center, such as cases, leaflets and white papers.": "Daarnaast vind je in het kenniscentrum ook marketingmateriaal, zoals cases, leaflets en whitepapers. ",
    "In the sub-navigation you can view different <b>reporting pages<\/b> per service, go through the entire <b>configuration<\/b> and create <b>integrations<\/b>.": "In de subnavigatie kun je per dienst verschillende <b>rapportage-pagina\u2019s<\/b> inzien, de gehele <b>configuratie<\/b> doorlopen en <b>integraties<\/b> tot stand brengen.",
    "Knowledge center": "Kenniscentrum",
    "Main navigation": "Hoofdnavigatie",
    "Next": "Volgende",
    "No, continue with the tour": "Nee, ga verder met de rondleiding",
    "Notification center": "Notificatiecentrum",
    "On the <b>domain overview<\/b> page you will find an overview of all your domains. Select a domain to view reports of the domain, request an extension of the domain or create an integration.": "Op de <b>domeinoverzicht<\/b> pagina vind je een overzicht van al jouw domeinen. Selecteer een domein om rapportages van het domein in te zien, een uitbreiding van het domein aan te vragen of een integratie tot stand te brengen.",
    "Reports, configuration and integrations": "Rapportages, configuratie en integraties",
    "Select a domain via the overview of via the domain selector domain, so that the <b>main navigation<\/b> becomes visible. In the subnavigation you will then find the reports, configuration and integrations.": "Selecteer een domein via het domeinoverzicht of via de domein selector, zodat de <b>hoofdnavigatie<\/b> zichtbaar wordt. In de subnavigatie vind je vervolgens de rapportages, configuratie en integraties terug.",
    "Skip tour": "Rondleiding overslaan",
    "Start the tour": "Start de rondleiding",
    "Start the tour, so you can immediately find your way in our dashboard. Do you have any questions, feedback or would you like to share your compliments? Please contact us via <a href=\\\"tel:+31307400990\\\">+31 (0)30 7400 990<\/a> or via <a href=\\\"mailto:support@adcalls.nl\\\">support@adcalls.nl<\/a>.": "Start de rondleiding, zodat je direct wegwijs wordt in ons dashboard. Heb jij nog vragen, feedback of wil je graag je complimenten kwijt? Neem dan contact met ons op via <a href=\"tel:+31307400990\">+31 (0)30 7400 990<\/a> of via <a href=\"mailto:support@adcalls.nl\">support@adcalls.nl<\/a>.",
    "Welcome to AdCalls": "Welkom bij AdCalls",
    "Well done!": "Goed bezig!",
    "Yes, I'll stop the tour": "Ja, ik stop met de rondleiding",
    "Yes, skip the tour": "Ja, sla de rondleiding over",
    "You can switch domains at any time in the entire dashboard with the <b>domain selector<\/b>.": "Je kunt op elk gewenst moment in het gehele dashboard van domein wisselen met de <b>domein selector<\/b>.",
    "You have completed the tour and can now get started in our dashboard. Do you still have questions after this tour? Please contact us via <a href=\\\"tel:+31307400990\\\">+31 (0)30 7400 990<\/a> or via <a href=\\\"mailto:support@adcalls.nl\\\">support@adcalls.nl<\/a>.": "Je hebt de rondleiding afgerond en kunt nu aan de slag in ons dashboard. Heb jij na deze rondleiding toch nog vragen? Neem dan contact met ons op via <a href=\"mailto:support@adcalls.nl\">support@adcalls.nl<\/a> of via <a href=\"tel:+31307400990\">+31 (0)30 7400 990<\/a>.",
    "You will also find your account settings and the invitations under your avatar.": "Verder vind je onder jouw avatar ook jouw account-instellingen en de uitnodigingen.",
    "You will find all your notifications in the <b>notification center<\/b>. The notifications will ensure that you get the most out of our services. Click on your avatar to open the notification center. The notifications with a pink color are urgent. If you click on a notification, you can read the entire notification.": "In het <b>notificatiecentrum<\/b> vind je al jouw notificaties. De notificaties zullen ervoor zorgen dat jij het maximale haalt uit onze diensten. Klik op jouw avatar om het notificatiecentrum te openen. De notificaties met een roze kleur zijn urgent. Klik je op een notificatie, dan kun je de gehele notificatie lezen."
}