import { useNavigate } from 'react-router';

import { useInvitationCount } from 'hooks/queries/invitation';
import { useTranslate } from 'hooks/translate';

type Props = {
  close: () => void;
};

export default function AccountTab({ close }: Props) {
  const navigate = useNavigate();
  const translateText = useTranslate();
  const { invitationCount } = useInvitationCount();

  function handleClick(path: string) {
    navigate(path);
    close();
  }

  return (
    <div className="account-settings">
      <h3 className="bold">{translateText('label', 'Account settings')}</h3>
      <button className="btn btn-lightblue" onClick={() => handleClick('/account/my-account')}>
        {translateText('label', 'My account')}
      </button>
      <button className="btn btn-lightblue" onClick={() => handleClick('/account/invitations')}>
        <div className="invitations">
          {translateText('label', 'Invitations')}
          {!!invitationCount && <div className="notification-count">{invitationCount}</div>}
        </div>
      </button>
    </div>
  );
}
