import { useState } from 'react';
import { Formik } from 'formik';
import { cloneDeep } from 'lodash';
import * as Yup from 'yup';

import ConfigurationCard from 'components/configuration/ConfigurationCard';
import CreateDeleteTable from 'components/CreateDeleteTable';
import ErrorFocus from 'components/ErrorFocus';
import GrayBars from 'components/GrayBars';
import TextField from 'components/input/TextField';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import SetupWithSub from 'components/slide-in/SetupWithSub';
import SupportParagraph from 'components/SupportParagraph';
import { useMessages } from 'contexts/Messages';
import { SUP_ART_IP_FILTER } from 'globals/support';
import { useIpFilters } from 'hooks/queries/domain';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation } from 'hooks/validation';

type Props = {
  isLoading: boolean;
};

export type Ip = {
  name: string;
  ip: string;
};

export default function IpFilter({ isLoading }: Props) {
  const translateText = useTranslate();
  const { addErrorMessage } = useMessages();
  const { required } = useCustomValidation();
  const { ipFilters, updateIpFilters } = useIpFilters();
  const [showEdit, setShowEdit] = useState(false);
  const [showFilter, setShowFilter] = useState<'new' | Ip | false>(false);

  let innerTitle = translateText('label', 'No IP filter');
  if (ipFilters && ipFilters.length > 0) {
    innerTitle = translateText('label', 'Set IP filters');
    if (ipFilters.length === 1) {
      innerTitle = translateText('label', 'Set IP filter');
    }
  }

  async function save(values: { ipFilters: Ip[]; deletedFilters: Ip[] }) {
    const filters = values.ipFilters.filter(f => values.deletedFilters.every(df => df.ip !== f.ip));
    await updateIpFilters({ filters })
      .then(() => setShowEdit(false))
      .catch(error => addErrorMessage(error.ip ?? error));
  }

  return (
    <>
      <ConfigurationCard
        title={translateText('label', 'IP filter')}
        supportArticleId={SUP_ART_IP_FILTER}
        onEdit={() => setShowEdit(true)}
        isLoading={isLoading}
        rightParagraph={translateText(
          'call-tracking',
          'By setting up IP filters, you can exclude traffic.[manager] Do you wish to set or change IP filters? Then click on the gears.[/manager]',
        )}
      >
        <GrayBars
          rows={ipFilters ? ipFilters.map(f => f.name) : []}
          isLoading={isLoading}
          title={innerTitle}
          singleItems
        />
      </ConfigurationCard>
      <Formik
        initialValues={{ ipFilters: ipFilters ?? [], deletedFilters: [] }}
        onSubmit={save}
        enableReinitialize
      >
        {({ values, submitForm, resetForm, isSubmitting, dirty, setFieldValue }) => {
          const subValidationSchema = Yup.object({
            name: required,
            ipAddress: required
              .matches(
                /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|\*)$/,
                translateText('message', 'Invalid IP address'),
              )
              .test({
                test: value => !values.ipFilters.find(f => f.ip === value),
                message: translateText('message', 'There is already a filter for this IP address'),
              }),
          });

          function saveFilter(subValues: { name: string; ipAddress: string }) {
            const currentFilters = cloneDeep(values.ipFilters);
            if (showFilter === 'new') {
              currentFilters.push({ name: subValues.name, ip: subValues.ipAddress });
            } else if (showFilter !== false) {
              const index = currentFilters.findIndex(f => f.ip === showFilter.ip);
              currentFilters[index] = { name: subValues.name, ip: subValues.ipAddress };
            }
            setFieldValue('ipFilters', currentFilters);
            setShowFilter(false);
          }

          return (
            <Formik
              initialValues={{
                name: showFilter && showFilter !== 'new' ? showFilter.name : '',
                ipAddress: showFilter && showFilter !== 'new' ? showFilter.ip : '',
              }}
              onSubmit={saveFilter}
              validationSchema={subValidationSchema}
              validateOnChange={false}
              enableReinitialize
            >
              {({
                values: subValues,
                submitForm: subSubmitForm,
                resetForm: subResetForm,
                dirty: subDirty,
                handleChange: subHandleChange,
                errors: subErrors,
              }) => (
                <SetupWithSub
                  show={showEdit}
                  title={translateText('label', 'IP filter')}
                  save={submitForm}
                  close={() => setShowEdit(false)}
                  afterClose={resetForm}
                  maxWidth={450}
                  isSaving={isSubmitting}
                  hasChanges={dirty}
                  sub={
                    <Setup
                      show={showFilter !== false}
                      title={
                        showFilter === 'new'
                          ? translateText('label', 'Add IP filter')
                          : translateText('label', 'Edit IP filter')
                      }
                      close={() => setShowFilter(false)}
                      afterClose={subResetForm}
                      maxWidth={400}
                      save={subSubmitForm}
                      hasChanges={subDirty}
                    >
                      <ErrorFocus element={document.getElementsByClassName('setup-wrapper')[0]} />
                      <InputWrapper label={translateText('label', 'Name')}>
                        <TextField
                          className="name"
                          name="name"
                          value={subValues.name}
                          onChange={subHandleChange}
                          error={subErrors.name}
                          maxLength={50}
                        />
                      </InputWrapper>
                      <InputWrapper label={translateText('label', 'IP address')}>
                        <TextField
                          name="ipAddress"
                          value={subValues.ipAddress}
                          onChange={subHandleChange}
                          error={subErrors.ipAddress}
                        />
                      </InputWrapper>
                    </Setup>
                  }
                >
                  <SupportParagraph
                    articleId={SUP_ART_IP_FILTER}
                    extraText={translateText('call-tracking', 'Set up the IP filters.')}
                  />
                  <CreateDeleteTable
                    dataKeys={['name', 'ip']}
                    privateKeys={['ip']}
                    items={values.ipFilters}
                    onAdd={() => setShowFilter('new')}
                    onEdit={ipFilter => setShowFilter(ipFilter)}
                    deletedItems={values.deletedFilters}
                    setDeletedItems={deletedFilters =>
                      setFieldValue('deletedFilters', deletedFilters)
                    }
                    title={translateText('label', 'Current filters')}
                  />
                </SetupWithSub>
              )}
            </Formik>
          );
        }}
      </Formik>
    </>
  );
}
