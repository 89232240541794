import { useMutation, useQuery } from '@tanstack/react-query';
import fileDownload from 'js-file-download';

import { useMessages } from 'contexts/Messages';
import { useTenant } from 'contexts/Tenant';
import { useSetQueryData } from 'hooks/data';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';

export type Sign = {
  id: number;
  domain_id: number;
  signed_by: number;
  signed_at: string;
  signer: string;
  version: string;
};

export function useDataProcessorAgreement(version?: string) {
  const { fetchData } = useFetch();

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ['dataProcessorAgreement', version],
    queryFn: (): Promise<{ file: string }> =>
      fetchData('/data-processor-agreement/document', {
        queryParams: version ? { version } : {},
      }),
  });

  return { data, isLoading };
}

export function useDpaSigns() {
  const { selectedDomain } = useTenant();
  const { fetchData } = useFetch();

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ['dataProcessorAgreementSigns', selectedDomain],
    queryFn: (): Promise<Sign[]> =>
      fetchData('/data-processor-agreement/index', {
        queryParams: { domain_id: selectedDomain, sort: '-signed_at' },
      }),
    enabled: selectedDomain !== null,
  });

  return { data, isLoading };
}

export function useSignDpa() {
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const { mutateAsync } = useMutation({
    mutationFn: async (signer: string) =>
      fetchData('/data-processor-agreement/sign', {
        method: 'POST',
        bodyParams: { domain: selectedDomain, signer },
      }),
    onSuccess: data => {
      addSuccessMessage(translateText('message', 'The Data Processor Agreement has been signed.'));
      setQueryData<Sign[]>(['dataProcessorAgreementSigns', selectedDomain], oldData => [
        data,
        ...oldData,
      ]);
    },
  });

  return { signDpa: mutateAsync };
}

export function useDownloadDpa() {
  const translateText = useTranslate();
  const { getFetchConfig } = useFetch();
  const { addErrorMessage } = useMessages();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: async ({ id }: { id: number; version: string }) => {
      const { query, config } = getFetchConfig('/data-processor-agreement/signed-document', {
        queryParams: { id },
        config: { responseType: 'arraybuffer' },
      });

      const response = await fetch(query, config);
      if (!response.ok) throw { status: response.status };
      return response;
    },
    meta: { noError: true },
    onSuccess: async (response, variables) => {
      const data = await response.arrayBuffer();
      fileDownload(
        data,
        'Verwerkersovereenkomst_AdCalls_v' + variables.version + '.pdf',
        'application/pdf',
      );
    },
    onError: (error: unknown) => {
      if (error && typeof error === 'object' && 'status' in error && error.status === 404) {
        addErrorMessage(translateText('message', 'File not found.'));
      } else {
        addErrorMessage(translateText('message', 'Something went wrong, please try again later.'));
      }
    },
  });

  return { downloadDpa: mutateAsync, isDownloading: isPending };
}
