import { Navigate, Route, Routes } from 'react-router';

import Details from 'pages/authenticated/requests/Details';
import Intake from 'pages/authenticated/requests/Intake';
import Overview from 'pages/authenticated/requests/Overview';
import Error404 from 'pages/Error404';

export default function Requests() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/requests/intake" />} />
      <Route path="/intake" element={<Intake />} />
      <Route path="/overview" element={<Overview />} />
      <Route path="/overview/:id" element={<Details />} />
      <Route path="/*" element={<Error404 />} />
    </Routes>
  );
}
