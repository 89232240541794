import { useParams } from 'react-router';
import { Formik } from 'formik';

import deleteIcon from 'assets/images/icons/delete-purple.svg';
import TextField from 'components/input/TextField';
import Loader from 'components/Loader';
import QueryTable from 'components/table/QueryTable';
import Tooltip from 'components/Tooltip';
import { useMessages } from 'contexts/Messages';
import { USER_STATUS_ACTIVE, USER_STATUS_CLASSES, USER_STATUS_INACTIVE } from 'globals/constants';
import { useHasAdminAccess } from 'hooks/access';
import { useRevokeRole } from 'hooks/queries/role';
import { useAdminUpdateUser, useAdminUserDetails, useUpdateStatus } from 'hooks/queries/user';
import { useTranslate } from 'hooks/translate';

export type UserDomain = {
  domain: { id: string; title: string };
  role: { id: string; name: string };
};

export default function UserDetails() {
  const translateText = useTranslate();
  const isAdmin = useHasAdminAccess();
  const { addSuccessMessage, addErrorMessage } = useMessages();

  const params = useParams();
  const userId = Number(params.id);
  const { user, isLoading } = useAdminUserDetails(!isNaN(userId) ? userId : null);
  const { updateUser } = useAdminUpdateUser(userId);
  const { updateStatus, isLoading: isUpdatingStatus } = useUpdateStatus(userId);
  const { revokeRole, isLoading: isRevokingRole } = useRevokeRole();

  if (isLoading) return <Loader />;
  if (!user) return <p>{translateText('label', 'This user does not exist')}</p>;

  function copyToClipBoard() {
    navigator.clipboard
      ?.writeText(user!.link)
      .then(() => addSuccessMessage(translateText('message', 'Copied to clipboard')))
      .catch(error => addErrorMessage(error));
  }

  return (
    <div className="user-details">
      <h1>{translateText('label', 'User')}</h1>
      <Formik
        initialValues={{
          firstName: user.first_name,
          lastName: user.last_name,
          phone: user.phonenumber,
          company: user.company ?? '',
        }}
        enableReinitialize
        onSubmit={async values => await updateUser(values)}
      >
        {({ values, handleChange, submitForm, dirty, isSubmitting }) => (
          <div className="white-block">
            <h3>{translateText('label', 'Details')}</h3>
            <div className="form-row">
              <div className="name-label">{translateText('label', 'First name')}</div>
              <div className="value-wrapper">
                <TextField
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  canEdit={isAdmin}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="name-label">{translateText('label', 'Last name')}</div>
              <div className="value-wrapper">
                <TextField
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  canEdit={isAdmin}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="name-label">{translateText('label', 'Email')}</div>
              <div className="value-wrapper">{user.email}</div>
            </div>
            <div className="form-row">
              <div className="name-label">{translateText('label', 'Phone number')}</div>
              <div className="value-wrapper">
                <TextField
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  canEdit={isAdmin}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="name-label">{translateText('label', 'Company')}</div>
              <div className="value-wrapper">
                <TextField
                  name="company"
                  value={values.company}
                  onChange={handleChange}
                  canEdit={isAdmin}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="name-label">{translateText('label', 'Marketing agency')}</div>
              <div className="value-wrapper">{user.marketing_agency}</div>
            </div>
            <div className="form-row">
              <div className="name-label">{translateText('label', 'Partner account')}</div>
              <div className="value-wrapper">{user.reseller_label}</div>
            </div>
            {isAdmin && (
              <div className="form-button">
                <button
                  className="btn btn-green"
                  onClick={submitForm}
                  disabled={!dirty || isSubmitting}
                >
                  {translateText('label', 'Save')}
                </button>
              </div>
            )}
          </div>
        )}
      </Formik>
      <div className="white-block">
        <h3>{translateText('label', 'Status')}</h3>
        <div className="form-row">
          <div className="name-label">{translateText('label', 'Status')}</div>
          <div className="value-wrapper">
            <div className="user-status">
              {user.status}
              <div
                className={'big-dot ' + USER_STATUS_CLASSES[user.status_id ?? USER_STATUS_ACTIVE]}
              />
            </div>
          </div>
        </div>
        {user.link && (
          <div className="form-row">
            <div className="name-label">{translateText('label', 'Link')}</div>
            <div className="value-wrapper user-link">
              {user.link}
              <button className="btn btn-text no-padding margin-left" onClick={copyToClipBoard}>
                {translateText('label', 'Copy')}
              </button>
            </div>
          </div>
        )}
        {isAdmin && (
          <button
            className="btn btn-lightblue margin-top"
            onClick={() =>
              updateStatus(
                user.status_id === USER_STATUS_ACTIVE ? USER_STATUS_INACTIVE : USER_STATUS_ACTIVE,
              )
            }
            disabled={isUpdatingStatus}
          >
            {user.status_id === USER_STATUS_ACTIVE
              ? translateText('label', 'Deactivate')
              : translateText('label', 'Activate')}{' '}
            {translateText('label', 'User').toLowerCase()}
          </button>
        )}
      </div>
      <div className="white-block">
        <h3>{translateText('label', 'Domains')}</h3>
        <QueryTable<UserDomain & { revoke: undefined }>
          tableKey={['user', 'domains']}
          columns={{
            domain: {
              header: translateText('label', 'Domain'),
              customValue: value => value.title,
            },
            role: { header: translateText('label', 'Role'), customValue: value => value.name },
            ...(isAdmin && {
              revoke: {
                header: ' ',
                customValue: (value, row) => (
                  <Tooltip
                    text={translateText('label', 'Revoke access')}
                    show={isRevokingRole ? false : undefined}
                    html={
                      <img
                        alt={translateText('label', 'Revoke access')}
                        src={deleteIcon}
                        className={'clickable-icon' + (isRevokingRole ? ' disabled' : '')}
                        onClick={e => {
                          e.stopPropagation();
                          revokeRole({
                            user: { id: userId, email: user.email },
                            domain: row.domain,
                          });
                        }}
                      />
                    }
                  />
                ),
              },
            }),
          }}
          endpoint="/user/roles"
          filters={{ domain: false, custom: { id: userId } }}
          noWhiteBlock
        />
      </div>
    </div>
  );
}
