import { Formik } from 'formik';
import * as Yup from 'yup';

import ErrorFocus from 'components/ErrorFocus';
import TextField from 'components/input/TextField';
import Loader from 'components/Loader';
import { LANGUAGE_DUTCH, LANGUAGE_ENGLISH_US } from 'globals/constants';
import { useLoginContent, useUpdateLoginContent } from 'hooks/queries/system-setting';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation } from 'hooks/validation';
import Error404 from 'pages/Error404';

type LanguageForm = {
  title: string;
  content: string;
  url: string;
  button: string;
  image: string;
};

export type LoginContent = {
  [LANGUAGE_DUTCH]: LanguageForm;
  [LANGUAGE_ENGLISH_US]: LanguageForm;
};

export default function LoginPage() {
  const translateText = useTranslate();
  const { loginContent, isLoading } = useLoginContent();
  const { updateLoginContent } = useUpdateLoginContent();
  const { required } = useCustomValidation();

  if (isLoading) return <Loader />;
  if (!loginContent) return <Error404 />;

  const languageFormValidation = Yup.object({
    title: required,
    content: required,
    url: required,
    button: required,
    image: required,
  });

  const validationSchema = Yup.object({
    [LANGUAGE_DUTCH]: languageFormValidation,
    [LANGUAGE_ENGLISH_US]: languageFormValidation,
  });

  return (
    <div className="login-content">
      <h1>{translateText('menu', 'Login content')}</h1>
      <Formik
        initialValues={loginContent}
        onSubmit={values => updateLoginContent(values)}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values, handleChange, submitForm, errors, submitCount, dirty, isSubmitting }) => {
          function getLanguageForm(language: keyof LoginContent) {
            const content = values[language];
            const languageErrors = errors[language];
            return (
              <div className="white-block">
                <h3>
                  {language === LANGUAGE_DUTCH
                    ? translateText('label', 'Dutch')
                    : translateText('label', 'English')}
                </h3>
                <div className="form-row">
                  <div className="name-label">{translateText('label', 'Title')}</div>
                  <div className="value-wrapper">
                    <TextField
                      name={language + '.title'}
                      value={content.title}
                      onChange={handleChange}
                      error={submitCount > 0 && languageErrors?.title}
                      disabled={isSubmitting}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="name-label">{translateText('label', 'Content')}</div>
                  <div className="value-wrapper">
                    <TextField
                      name={language + '.content'}
                      value={content.content}
                      onChange={handleChange}
                      error={submitCount > 0 && languageErrors?.content}
                      disabled={isSubmitting}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="name-label">{translateText('label', 'URL')}</div>
                  <div className="value-wrapper">
                    <TextField
                      name={language + '.url'}
                      value={content.url}
                      onChange={handleChange}
                      error={submitCount > 0 && languageErrors?.url}
                      disabled={isSubmitting}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="name-label">{translateText('label', 'Button text')}</div>
                  <div className="value-wrapper">
                    <TextField
                      name={language + '.button'}
                      value={content.button}
                      onChange={handleChange}
                      error={submitCount > 0 && languageErrors?.button}
                      disabled={isSubmitting}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="name-label">{translateText('label', 'Image')}</div>
                  <div className="value-wrapper">
                    <TextField
                      name={language + '.image'}
                      value={content.image}
                      onChange={handleChange}
                      error={submitCount > 0 && languageErrors?.image}
                      disabled={isSubmitting}
                    />
                  </div>
                </div>
              </div>
            );
          }
          return (
            <>
              <ErrorFocus />
              {getLanguageForm(LANGUAGE_DUTCH)}
              {getLanguageForm(LANGUAGE_ENGLISH_US)}
              <div className="form-button">
                <button
                  className="btn btn-green"
                  onClick={submitForm}
                  disabled={isSubmitting || !dirty}
                >
                  {translateText('label', 'Save')}
                </button>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
}
