import { useFormikContext } from 'formik';

import {
  ConversionActionSetting,
  GoogleAdsType,
} from 'components/integrations/google-ads/BaseGoogleAds';
import { useGoogleAdsConversionAction } from 'hooks/queries/integration';
import { useTranslate } from 'hooks/translate';

type Props = {
  type: GoogleAdsType;
  loadedConversionAction: string | boolean;
  setLoadedConversionAction: (value: boolean | 'loading') => void;
  setShowConversionAction: (value: number | 'new' | false) => void;
};

export default function ConversionActionButtons({
  type,
  loadedConversionAction,
  setLoadedConversionAction,
  setShowConversionAction,
}: Props) {
  const translateText = useTranslate();
  const { isCreating, isLoading } = useGoogleAdsConversionAction(type);
  const { dirty, values, submitForm } = useFormikContext<ConversionActionSetting<typeof type>>();

  return (
    <>
      <button
        className="btn btn-text no-padding"
        disabled={isCreating}
        onClick={() => {
          setShowConversionAction(false);
          setLoadedConversionAction(false);
        }}
      >
        {translateText('label', 'Cancel')}
      </button>
      <button
        className="btn btn-text no-padding"
        disabled={
          !dirty ||
          isLoading ||
          isCreating ||
          (loadedConversionAction !== true && !values.resource_name)
        }
        onClick={submitForm}
      >
        {!values.resource_name
          ? translateText('label', 'Place conversion action')
          : translateText('label', 'Update conversion action')}
      </button>
    </>
  );
}
