import { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router';
import moment from 'moment';

import EditSubscription, {
  EditSubscriptionType,
} from 'components/edit-subscription/EditSubscription';
import ExtendSession from 'components/ExtendSession';
import Footer from 'components/Footer';
import GuidedTour from 'components/guided-tour/GuidedTour';
import Header from 'components/navigation/Header';
import Sidebar from 'components/navigation/Sidebar';
import SidebarExtension from 'components/navigation/SidebarExtension';
import PopupModal from 'components/PopupModal';
import FilterBar from 'components/reports-filter/FilterBar';
import AuthenticatedRoute from 'components/routes/AuthenticatedRoute';
import { FiltersProvider } from 'contexts/Filters';
import { useGlobal } from 'contexts/Global';
import { GuidedTourProvider } from 'contexts/GuidedTour';
import { MessageType, useMessages } from 'contexts/Messages';
import { useRoutes } from 'contexts/Routes';
import { useSession } from 'contexts/Session';
import { useTenant } from 'contexts/Tenant';
import {
  DOMAIN_STATUS_STOPPED,
  PH_MASK,
  ROLE_EMPLOYEE,
  SESSION_CHECK_INTERVAL,
} from 'globals/constants';
import { useHasManagerAccess, useHasNewPricingPlan } from 'hooks/access';
import { useKnowledgeCenterCheck } from 'hooks/knowledge-center';
import { useIsMobileNavigation } from 'hooks/media';
import { useDomainAuth } from 'hooks/queries/domain';
import { useDisplayTour, useUserMcc } from 'hooks/queries/user';
import { useTranslate } from 'hooks/translate';
import DomainOverview from 'pages/authenticated/DomainOverview';
import Authorize from 'pages/authenticated/oauth2/Authorize';
import Overview from 'pages/authenticated/Overview';
import Error404 from 'pages/Error404';
import Account from './Account';
import Admin from './Admin';
import CallTracking from './CallTracking';
import FormTracking from './FormTracking';
import KnowledgeCenter from './KnowledgeCenter';
import Requests from './Requests';
import Settings from './Settings';

export default function Authenticated() {
  const location = useLocation();
  const translateText = useTranslate();
  const { initRoutes } = useRoutes();
  const { scrollContainer, scrollToTop } = useGlobal();
  const { selectedDomain, selectedMcc, setSelectedMcc } = useTenant();
  const { mcc } = useUserMcc();
  const { displayTour } = useDisplayTour();

  const { showReportsFilter } = useGlobal();

  const isMobileNavigation = useIsMobileNavigation();
  const isKnowledgeCenter = useKnowledgeCenterCheck();
  const isKnowledgeCenterOverview = useKnowledgeCenterCheck(true);
  const { clearMessages, addPermanentMessage } = useMessages();
  const { domainAuth } = useDomainAuth();
  const hasNewPricingPlan = useHasNewPricingPlan();
  const hasManagerAccess = useHasManagerAccess();

  const { expiresAt, isTokenValid, setIsTokenValid } = useSession();

  useEffect(() => {
    clearMessages(MessageType.Permanent);
    if (domainAuth?.can_extend_subscription) {
      const daysLeft =
        moment(domainAuth.contract_end_date).diff(moment().startOf('day'), 'days') + 1; // Plus 1 because the subscription changes the day after the contract end date

      let message = translateText(
        'message',
        'In {days} days your subscription will become monthly terminable. Any discounts will expire. Extend your subscription or contact us for an extension proposal.',
        { days: daysLeft },
      );
      if (daysLeft === 1) {
        message = translateText(
          'message',
          'Starting tomorrow, your subscription will become monthly terminable. Any discounts will expire. Extend your subscription or contact us for an extension proposal.',
        );
      }
      addPermanentMessage(
        message,
        hasManagerAccess
          ? {
              url: '/settings/properties/edit-subscription',
              text: translateText('label', 'Extend'),
            }
          : undefined,
      );
    } else if (
      domainAuth?.can_upgrade ||
      (hasNewPricingPlan &&
        !domainAuth?.contract_end_date &&
        !domainAuth?.is_trial &&
        domainAuth?.status !== DOMAIN_STATUS_STOPPED)
    ) {
      addPermanentMessage(
        translateText(
          'message',
          'Your subscription is monthly terminable. Extend your subscription or contact us for an attractive renewal proposal.',
        ),
        hasManagerAccess
          ? {
              url: domainAuth?.can_upgrade ? '/upgrade' : '/settings/properties/edit-subscription',
              text: translateText('label', 'Extend'),
            }
          : undefined,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    domainAuth?.status,
    domainAuth?.can_extend_subscription,
    domainAuth?.is_trial,
    domainAuth?.can_upgrade,
    domainAuth?.contract_end_date,
    hasNewPricingPlan,
    hasManagerAccess,
  ]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isTokenValid && expiresAt && expiresAt - SESSION_CHECK_INTERVAL <= Date.now()) {
        setIsTokenValid(false);
      }
    }, SESSION_CHECK_INTERVAL);
    return () => clearInterval(intervalId);
  }, [isTokenValid, expiresAt]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    initRoutes();
    scrollToTop();
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedMcc === null && mcc?.length === 1) {
      setSelectedMcc(mcc[0].id);
      initRoutes();
    }
  }, [mcc, selectedMcc]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => initRoutes(), [selectedDomain]); // eslint-disable-line react-hooks/exhaustive-deps

  function renderRoutes() {
    if (!displayTour && !selectedMcc && (mcc?.length ?? 0) > 1) {
      return (
        <Routes>
          <Route path="/oauth2/authorize" element={<Authorize />} />
          <Route
            path="/*"
            element={
              <AuthenticatedRoute checkDomain>
                <PopupModal extraClass="mcc-popup" left show>
                  <h1>{translateText('label', 'Select an account')}</h1>
                  <div className="mcc-options">
                    {mcc?.map((mcc, index) => (
                      <button
                        key={`mcc-option-${index}`}
                        className={'mcc-option btn btn-lightblue ' + PH_MASK}
                        onClick={() => {
                          setSelectedMcc(mcc.id);
                          initRoutes();
                        }}
                      >
                        {mcc.name}
                      </button>
                    ))}
                  </div>
                </PopupModal>
                <DomainOverview />
              </AuthenticatedRoute>
            }
          />
        </Routes>
      );
    }
    return (
      <Routes>
        <Route path="/" element={<Navigate to="/overview" />} />
        <Route path="/site/login" element={<Navigate to="/overview" />} />
        <Route
          path="/reports/overview"
          element={<Navigate to="/call-tracking/reports/overview" />}
        />
        <Route
          path="/overview"
          element={
            <AuthenticatedRoute checkDomain>
              <Overview />
            </AuthenticatedRoute>
          }
        />
        <Route path="/domain-overview" element={<DomainOverview />} />
        <Route
          path="/call-tracking/*"
          element={
            <AuthenticatedRoute>
              <CallTracking />
            </AuthenticatedRoute>
          }
        />
        <Route
          path="/form-tracking/*"
          element={
            <AuthenticatedRoute checkDomain>
              <FormTracking />
            </AuthenticatedRoute>
          }
        />
        <Route path="/account/*" element={<Account />} />
        <Route
          path="/support/*"
          element={<Navigate to={location.pathname.replace('/support', '/knowledge-center')} />}
        />
        <Route path="/knowledge-center/*" element={<KnowledgeCenter />} />
        <Route path="/settings/*" element={<Settings />} />
        <Route path="/requests/*" element={<Requests />} />
        <Route
          path="/admin/*"
          element={
            <AuthenticatedRoute role={ROLE_EMPLOYEE}>
              <Admin />
            </AuthenticatedRoute>
          }
        />
        <Route path="/oauth2/authorize" element={<Authorize />} />
        <Route
          path="/upgrade"
          element={
            <AuthenticatedRoute checkDomain>
              <EditSubscription type={EditSubscriptionType.Upgrade} />
            </AuthenticatedRoute>
          }
        />

        <Route path="/*" element={<Error404 />} />
      </Routes>
    );
  }

  let contentInnerClass = '';
  if (isKnowledgeCenter) {
    contentInnerClass += 'knowledge-center';
    if (isKnowledgeCenterOverview) contentInnerClass += ' is-overview';
  }

  if (showReportsFilter || location.pathname === '/overview') contentInnerClass += ' has-filter';

  return (
    <FiltersProvider>
      <div id="authenticated-wrapper" data-testid="authenticated">
        <GuidedTourProvider>
          <GuidedTour />
        </GuidedTourProvider>
        <ExtendSession />
        {!isMobileNavigation && <Sidebar />}
        <div id="page" data-testid="page" className={isKnowledgeCenter ? 'knowledge-center' : ''}>
          <Header />
          <div id="content-wrapper">
            {!isMobileNavigation && <SidebarExtension />}
            <div id="content-outer">
              <FilterBar />
              <div
                id="content-inner"
                data-testid="content-inner"
                className={contentInnerClass}
                ref={scrollContainer}
              >
                <div id="content">{renderRoutes()}</div>
                <Footer placement="right" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </FiltersProvider>
  );
}
