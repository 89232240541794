import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useMessages } from 'contexts/Messages';
import { useSetQueryData } from 'hooks/data';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';
import { LoginContent } from 'pages/authenticated/admin/content/LoginPage';
import { NumberDistributionSetting } from 'pages/authenticated/admin/NumberDistribution';

export function useNumberDistribution() {
  const { fetchData } = useFetch();

  const { data, isFetching } = useQuery({
    queryKey: ['admin', 'numberDistribution'],
    queryFn: (): Promise<NumberDistributionSetting> =>
      fetchData('/system-setting/number-distribution'),
  });

  return { distribution: data, isLoading: isFetching };
}

export function useSaveNumberDistribution() {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync } = useMutation({
    mutationFn: ({
      distribution,
      password,
    }: {
      distribution: NumberDistributionSetting;
      password: string;
    }) =>
      fetchData('/system-setting/save-number-distribution', {
        method: 'POST',
        bodyParams: {
          state: distribution,
          password,
        },
      }),
    onSuccess: data => {
      queryClient.setQueryData(['admin', 'numberDistribution'], () => JSON.parse(data.state));
      addSuccessMessage(translateText('message', 'The number distribution has been changed.'));
    },
  });

  return { saveNumberDistribution: mutateAsync };
}

export function useEmailBlacklist() {
  const { fetchData } = useFetch();

  const { data: emailBlacklist, isFetching: isLoading } = useQuery({
    queryKey: ['admin', 'emailBlacklist'],
    queryFn: (): Promise<string[]> => fetchData('/system-setting/get-blacklist'),
  });

  return { emailBlacklist, isLoading };
}

export function useAddToBlacklist() {
  const translateText = useTranslate();
  const queryClient = useQueryClient();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync } = useMutation({
    mutationFn: (item: string) =>
      fetchData('/system-setting/add-blacklist', { method: 'POST', bodyParams: { state: item } }),
    onSuccess: data => {
      queryClient.setQueryData(['admin', 'emailBlacklist'], () => JSON.parse(data.state));
      addSuccessMessage(translateText('message', 'Blacklist has been saved'));
    },
  });

  return { addToBlacklist: mutateAsync };
}

export function useRemoveFromBlacklist() {
  const translateText = useTranslate();
  const queryClient = useQueryClient();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (state: string) =>
      fetchData('/system-setting/remove-blacklist', { method: 'POST', bodyParams: { state } }),
    onSuccess: data => {
      queryClient.setQueryData(['admin', 'emailBlacklist'], () => JSON.parse(data.state));
      addSuccessMessage(translateText('message', 'Blacklist has been saved'));
    },
  });

  return { removeFromBlacklist: mutateAsync, isLoading: isPending };
}

export function useLoginContent() {
  const { fetchData } = useFetch();

  const {
    data,
    isFetching: isLoading,
    isError,
  } = useQuery({
    queryKey: ['admin', 'loginContent'],
    queryFn: (): Promise<LoginContent> => fetchData('/system-setting/get-login-content'),
  });

  return { loginContent: data, isLoading, isError };
}

export function useUpdateLoginContent() {
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const setQueryData = useSetQueryData();

  const { mutateAsync } = useMutation({
    mutationFn: (state: LoginContent) =>
      fetchData('/system-setting/set-login-content', {
        method: 'POST',
        bodyParams: { state },
      }),
    onSuccess: data => {
      setQueryData(['admin', 'loginContent'], () => JSON.parse(data.state));
      addSuccessMessage(translateText('admin/text', 'Login content has been saved'));
    },
  });

  return { updateLoginContent: mutateAsync };
}
