import { MouseEventHandler } from 'react';

import csvIcon from 'assets/images/icons/csv.svg';
import pdfIcon from 'assets/images/icons/pdf.svg';
import Skeleton from 'components/Skeleton';
import Tooltip from 'components/Tooltip';
import { useTranslate } from 'hooks/translate';

type Props = {
  type: 'csv' | 'pdf';
  onClick: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  isLoading?: boolean;
};

export default function ExportButton({ type, onClick, disabled, isLoading }: Props) {
  const translateText = useTranslate();
  if (isLoading) return <Skeleton isCircle height={25} width={25} className="btn" />;
  return (
    <Tooltip
      text={translateText('label', 'Download {type}', { type: type.toUpperCase() })}
      html={
        <button
          className="btn btn-darkblue btn-round btn-export"
          onClick={onClick}
          disabled={disabled}
        >
          <img
            alt={translateText('label', 'Download {type}', { type: type.toUpperCase() })}
            src={type === 'pdf' ? pdfIcon : csvIcon}
          />
        </button>
      }
    />
  );
}
