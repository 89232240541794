import { useState } from 'react';
import { Link } from 'react-router-dom';

import noDomain from 'assets/images/no-domain.svg';
import ImagePage from 'components/ImagePage';
import { useTenant } from 'contexts/Tenant';
import { DEMO_DOMAIN } from 'globals/constants';
import { useHasMccAdminAccess } from 'hooks/access';
import { useUserMcc } from 'hooks/queries/user';
import { useTranslate } from 'hooks/translate';
import InviteDomain from './InviteDomain';

export default function NoDomains() {
  const translateText = useTranslate();
  const { selectedDomain, setSelectedDomain, setSelectedMcc } = useTenant();
  const { mcc } = useUserMcc();
  const isMccAdmin = useHasMccAdminAccess();
  const domains = mcc?.flatMap(m => m.domains) ?? [];
  const [showInviteDomain, setShowInviteDomain] = useState(false);

  function selectDemoDomain() {
    setSelectedMcc(mcc?.[0].id ?? null);
    setSelectedDomain(Number(domains[0].id));
  }

  let demoButton;
  if (domains?.length === 1 && Number(domains[0].id) === DEMO_DOMAIN && selectedDomain === null) {
    demoButton = (
      <div className="button-wrapper">
        <button className="btn btn-white" onClick={selectDemoDomain}>
          {translateText('label', 'Try the demo')}
        </button>
      </div>
    );
  }

  return (
    <>
      <ImagePage
        title={translateText('label', 'There is no domain available yet')}
        subtitle={translateText('label', 'Ready?')}
        image={noDomain}
        content={
          <>
            <p>
              {translateText(
                'text',
                'This account does not have access to a domain yet. A manager can invite you to an existing domain, or you can request a new domain yourself.',
              )}
            </p>
            {demoButton}
            <div className="button-wrapper">
              <Link to="/requests/intake">
                <button className="btn btn-lightblue">
                  {translateText('label', 'Request new domain')}
                </button>
              </Link>
            </div>
            {isMccAdmin && (
              <div className="button-wrapper">
                <button className="btn btn-lightblue" onClick={() => setShowInviteDomain(true)}>
                  {translateText('label', 'Invite domain')}
                </button>
              </div>
            )}
          </>
        }
      />
      <InviteDomain show={showInviteDomain} close={() => setShowInviteDomain(false)} />
    </>
  );
}
