import { Link } from 'react-router-dom';

import { isLeafItem, Route, useRoutes } from 'contexts/Routes';

type Props = {
  item: Route;
  depth: number;
};

export default function SubItem({ item, depth }: Props) {
  const { setOpenItems, selectedItems, openItems } = useRoutes();

  function getClassName(collection: string[], route: string) {
    return 'sub-item ' + (collection.includes(route) ? 'selected-item' : 'unselected-item');
  }

  if (isLeafItem(item)) {
    return (
      <Link to={item.endRoute} className="extension-nav-link">
        <div data-testid="sub-item" className={getClassName(selectedItems, item.endRoute)}>
          <span className="sub-item-selectable">{item.title}</span>
        </div>
      </Link>
    );
  }
  let arrowDirection = 'arrow-right',
    subItems;
  if (openItems.includes(item.endRoute)) {
    arrowDirection = 'arrow-down-right';
    subItems = (
      <div className="sub-items">
        {item.subItems?.map((subItem, index) => (
          <SubItem key={index} depth={depth + 1} item={subItem} />
        ))}
      </div>
    );
  }
  return (
    <div data-testid="sub-item" className={getClassName(openItems, item.endRoute)}>
      <div className="sub-item-selectable" onClick={() => setOpenItems(item.endRoute, depth)}>
        <span>{item.title}</span>
        <div className={'arrow ' + arrowDirection} />
      </div>
      {subItems}
    </div>
  );
}
