import DetailsSection from 'components/DetailsSection';
import Skeleton from 'components/Skeleton';
import { useTenant } from 'contexts/Tenant';
import { useReseller } from 'hooks/queries/reseller';
import { useTranslate } from 'hooks/translate';
import SettingsPage from '../SettingsPage';

export default function MccProperties() {
  const translateText = useTranslate();
  const { selectedMccData } = useTenant();
  const { data, isLoading } = useReseller();

  function getPhoto() {
    if (isLoading) return <Skeleton className="account-manager-photo" />;
    try {
      const src = require('assets/images/account-managers/' + data?.account_manager.id + '.jpeg');
      return <img alt="account manager" className="account-manager-photo" src={src} />;
    } catch {
      return null;
    }
  }

  return (
    <SettingsPage title={translateText('label', 'Customer center') + ' ' + selectedMccData?.name}>
      <div className="mcc-properties">
        <DetailsSection
          data={{
            [translateText('label', 'Customer center ID')]: data?.id,
            [translateText('label', 'Partner status')]: data?.partner_status ?? '-',
            [translateText('label', 'Partner manager')]: data?.account_manager.name,
            [translateText('label', 'Phone number')]: data?.account_manager.phone,
            [translateText('label', 'Email address')]: data?.account_manager.email,
            [translateText('label', 'Email address support')]: 'support@adcalls.nl',
          }}
          isLoading={isLoading}
          boldKeys
        />
        {getPhoto()}
      </div>
    </SettingsPage>
  );
}
