import { Formik } from 'formik';

import SessionFilterPool from 'components/admin/SessionFilterPool';
import Select from 'components/input/Select';
import Loader from 'components/Loader';
import StickyBar from 'components/StickyBar';
import { useTenant } from 'contexts/Tenant';
import { useHasAdminAccess } from 'hooks/access';
import { useSessionFilterSetting, useUpdateSessionFilter } from 'hooks/queries/domain-setting';
import {
  useCreateSessionFilterPool,
  useSessionFilterPools,
} from 'hooks/queries/session-filter-pool';
import { useTranslate } from 'hooks/translate';

const SESSION_FILTER_METHODS = {
  0: 'Off',
  1: 'Exclude (standard)',
  2: 'Specific (inverted)',
} as const;

export default function SessionFilter() {
  const translateText = useTranslate();
  const isAdmin = useHasAdminAccess();
  const { selectedDomainData } = useTenant();

  const { pools, isLoading, refetch } = useSessionFilterPools();
  const { createPool, isCreatingPool } = useCreateSessionFilterPool();
  const { sessionFilterSetting, isLoading: isLoadingSetting } = useSessionFilterSetting();
  const { updateSessionFilter } = useUpdateSessionFilter();

  if (isLoading || isLoadingSetting) return <Loader />;

  return (
    <Formik
      initialValues={{ method: sessionFilterSetting?.[0]?.value ?? '0' }}
      onSubmit={(values, helpers) =>
        updateSessionFilter(values.method).then(() => helpers.resetForm())
      }
      enableReinitialize
    >
      {({ values, handleChange, isSubmitting, submitForm, dirty }) => {
        let helpText = [
          translateText(
            'admin/text',
            'Attention! Session filter is disabled. All sessions are tracked.',
          ),
        ];
        if (Number(values.method) === 1) {
          helpText = [
            translateText(
              'admin/text',
              'When all conditions in a pool are TRUE, the session is NOT tracked.',
            ),
            translateText('admin/text', 'You are therefore EXCLUDED from Call Tracking.'),
          ];
        } else if (Number(values.method) === 2) {
          helpText = [
            translateText(
              'admin/text',
              'When all conditions in a pool are TRUE, the session IS tracked.',
            ),
            translateText(
              'admin/text',
              'When there is no pool in which all conditions are met, the session will NOT be tracked.',
            ),
          ];
        }
        return (
          <div className="session-filter">
            <h1>
              {translateText('label', 'Session filter')}
              <span>{selectedDomainData?.name}</span>
            </h1>
            <div className="session-filter-method">
              <div>{translateText('admin/text', 'Session filter method')}</div>
              <Select
                name="method"
                value={values.method}
                onChange={handleChange}
                canEdit={isAdmin}
                options={Object.entries(SESSION_FILTER_METHODS).map(([key, method]) => ({
                  text: translateText('dynamic', method),
                  value: key,
                }))}
              />
              {isAdmin && (
                <button
                  className="btn btn-lightblue"
                  disabled={isSubmitting || !dirty}
                  onClick={submitForm}
                >
                  {translateText('label', 'Save')}
                </button>
              )}
            </div>
            <p
              className="purple-text"
              dangerouslySetInnerHTML={{ __html: helpText.join('<br/>') }}
            />
            <StickyBar>
              <div className="top-bar">
                <button
                  disabled={isLoading}
                  className="btn btn-text right"
                  onClick={() => refetch()}
                >
                  {translateText('label', 'Refresh')}
                </button>
                {isAdmin && (
                  <button
                    className="btn btn-lightblue"
                    disabled={isCreatingPool}
                    onClick={() => createPool()}
                  >
                    {translateText('label', 'New pool')}
                  </button>
                )}
              </div>
            </StickyBar>
            {pools?.length
              ? pools.map(p => <SessionFilterPool key={p.id} pool={p} />)
              : translateText('admin/text', 'No session filters set for this domain.')}
          </div>
        );
      }}
    </Formik>
  );
}
