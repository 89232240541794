import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Route, useRoutes } from 'contexts/Routes';
import { useKnowledgeCenterCheck } from 'hooks/knowledge-center';

type Props = {
  item: Route;
};

export default function MainItem({ item }: Props) {
  const [showTooltip, setShowTooltip] = useState(false);
  const { selectedItems } = useRoutes();
  const isKnowledgeCenter = useKnowledgeCenterCheck();

  const containerClass =
    'sidebar-item-container' +
    (selectedItems.includes(item.endRoute) ? ' sidebar-item-selected' : '');

  return (
    <div
      className={containerClass}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      data-testid="main-item"
    >
      <Link to={item.directGo ?? item.endRoute}>
        <div
          className={'sidebar-item' + (isKnowledgeCenter ? ' knowledge-center' : '')}
          onClick={() => setShowTooltip(false)}
        >
          {item.icon}
        </div>
      </Link>
      {showTooltip && (
        <div className="nav-icon-tooltip" onMouseEnter={() => setShowTooltip(false)}>
          <div className="outer-tooltip">{item.title}</div>
        </div>
      )}
    </div>
  );
}
