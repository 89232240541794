import { useParams } from 'react-router';
import { useIsFetching } from '@tanstack/react-query';
import moment from 'moment';

import { ReactComponent as Play } from 'assets/images/icons/play.svg';
import { ReactComponent as Stop } from 'assets/images/icons/stop.svg';
import AutoGrid from 'components/AutoGrid';
import UnstyledButton from 'components/buttons/UnstyledButton';
import DetailsSection from 'components/DetailsSection';
import CallRecordingSection from 'components/history/CallRecordingSection';
import EvaluateSection from 'components/history/EvaluateSection';
import MarkButton from 'components/history/MarkButton';
import NoteSection from 'components/history/NoteSection';
import SendEmailSection from 'components/history/SendEmailSection';
import ItemNavigation from 'components/ItemNavigation';
import Table from 'components/table/Table';
import Tooltip from 'components/Tooltip';
import { getDefaultFilters } from 'contexts/Filters';
import {
  CALL_REQUEST_STATUS_PENDING,
  TABLE_KEY_CALL_REQUEST_INDEX,
  TYPE_FORM_TRACKING,
} from 'globals/constants';
import { useDateTime } from 'hooks/date-time';
import {
  useCallRequest,
  useStartCallRequest,
  useStopCallRequest,
} from 'hooks/queries/call-request';
import { useTranslate } from 'hooks/translate';
import Error404 from 'pages/Error404';
import { CALL_REQUEST_HISTORY_EXPAND } from './History';

export default function HistoryDetails() {
  const translateText = useTranslate();
  const { formatDateTime } = useDateTime();
  const { startCallRequest, isStarting } = useStartCallRequest();
  const { stopCallRequest, isStopping } = useStopCallRequest();

  const params = useParams();
  const callRequestId = Number(params.id);
  const {
    callRequest,
    isLoading: isLoadingCallRequest,
    isError,
  } = useCallRequest(!isNaN(callRequestId) ? callRequestId : null);

  const isFetchingGoogleAnalytics = useIsFetching({
    queryKey: ['domainSetting', 'googleAnalytics'],
  });
  const isFetchingEvaluationOptions = useIsFetching({ queryKey: ['evaluation', 'options'] });
  const isFetchingEvaluationLog = useIsFetching({ queryKey: ['evaluation', 'log'] });
  const isLoading =
    isLoadingCallRequest ||
    isFetchingGoogleAnalytics > 0 ||
    isFetchingEvaluationOptions > 0 ||
    isFetchingEvaluationLog > 0;

  if (isNaN(callRequestId) || isError) {
    return (
      <Error404
        title={translateText('label', 'Call request not found')}
        firstParagraph={translateText(
          'text',
          'Unfortunately, this call request does not exist or you do not have access to the request.',
        )}
      />
    );
  }

  let location = callRequest?.aen_name ?? callRequest?.location_identifier;
  if (callRequest?.aen_name && callRequest.aen_name !== callRequest.location_identifier) {
    location += ' (' + callRequest.location_identifier + ')';
  }

  return (
    <div className="history-details form-tracking">
      <div className="header">
        <h1>
          {translateText('label', 'Call request') + ' #' + callRequestId}
          {callRequest?.status === CALL_REQUEST_STATUS_PENDING && (
            <>
              <Tooltip
                text={translateText('label', 'Start call request')}
                placement="bottom"
                html={
                  <UnstyledButton
                    onClick={() => startCallRequest(callRequestId)}
                    disabled={isStarting || isStopping}
                  >
                    <Play />
                  </UnstyledButton>
                }
              />
              <Tooltip
                text={translateText('label', 'Stop call request')}
                placement="bottom"
                html={
                  <UnstyledButton
                    onClick={() => stopCallRequest(callRequestId)}
                    disabled={isStarting || isStopping}
                  >
                    <Stop />
                  </UnstyledButton>
                }
              />
            </>
          )}
        </h1>
        <div className="icon-container">
          <MarkButton
            itemId={callRequest?.id}
            marked={callRequest?.marked}
            type={TYPE_FORM_TRACKING}
            isLoading={isLoading}
          />
        </div>
        <ItemNavigation
          itemId={callRequestId}
          itemName={translateText('label', 'Call request')}
          tableKey={TABLE_KEY_CALL_REQUEST_INDEX}
          endpoint="/call-request/index"
          pageRoute="/form-tracking/history"
          filters={{
            ...getDefaultFilters(TYPE_FORM_TRACKING),
            previousPeriod: false,
            custom: { expand: CALL_REQUEST_HISTORY_EXPAND },
          }}
        />
      </div>
      <AutoGrid dependencies={[callRequest]} size="large">
        <DetailsSection
          title={translateText('label', 'Details')}
          isLoading={isLoading}
          data={{
            [translateText('label', 'Status')]: callRequest?.status_message,
            ...(location && {
              [translateText('label', 'Location')]: { value: location, private: true },
            }),
            [translateText('label', 'Profile')]: callRequest?.call_agent ?? '-',
            [translateText('label', 'Date & time')]: formatDateTime(
              callRequest?.requested_at ?? '',
            ),
            [translateText('label', 'Call attempts')]: callRequest?.calls.length,
            [translateText('label', 'Country code')]: callRequest?.country_code,
            [translateText('label', 'Phone number input')]: callRequest?.phone_number_raw,
          }}
        />
        <CallRecordingSection
          type={TYPE_FORM_TRACKING}
          itemId={callRequestId}
          isLoading={isLoading}
          timeCalled={
            callRequest?.calls.at(-1) ? moment(callRequest.calls.at(-1)?.time_called) : null
          }
        />
        <DetailsSection
          title={translateText('label', 'Call attempts')}
          tooltip={translateText(
            'tooltip/form-tracking',
            'Here you will find all call attempts of this call request.',
          )}
          isLoading={isLoading}
        >
          <Table
            data={callRequest?.calls ?? []}
            columns={{
              time_called: { header: translateText('label', 'Date & time'), date: true },
              duration: { header: translateText('label', 'Duration') },
              status_string: { header: translateText('label', 'Status') },
            }}
            emptyText={translateText('label', 'No call attempts yet')}
            isLoading={isLoading}
            followNumber
            isResponsive
            noWhiteBlock
          />
        </DetailsSection>
        <DetailsSection
          title={translateText('label', 'Filled in by the lead')}
          isLoading={isLoading}
        >
          <Table
            columns={{
              key: {
                header: translateText('label', 'Input field'),
                customValue: value => decodeURIComponent(value),
              },
              value: {
                header: translateText('label', 'Value'),
                customValue: value => decodeURIComponent(value),
              },
              whisper: {
                header: translateText('label', 'Whisper'),
                bigDot: {
                  classes: {
                    0: 'inactive',
                    1: 'active',
                  },
                },
              },
            }}
            data={callRequest?.form_data?.filter(item => !item.meta) ?? []}
            isLoading={isLoading}
            emptyText={translateText('label', 'No meta data')}
            isResponsive
            noWhiteBlock
          />
        </DetailsSection>
        <DetailsSection
          key="meta-data"
          title={translateText('label', 'Meta data')}
          isLoading={isLoading}
        >
          <Table
            columns={{
              key: {
                header: translateText('label', 'Input field'),
                customValue: value => decodeURIComponent(value),
              },
              value: {
                header: translateText('label', 'Value'),
                customValue: value => decodeURIComponent(value),
              },
              whisper: {
                header: translateText('label', 'Whisper'),
                bigDot: {
                  classes: {
                    0: 'inactive',
                    1: 'active',
                  },
                },
              },
            }}
            data={callRequest?.form_data?.filter(item => item.meta) ?? []}
            isLoading={isLoading}
            emptyText={translateText('label', 'No meta data')}
            isResponsive
            noWhiteBlock
          />
        </DetailsSection>
        <EvaluateSection type={TYPE_FORM_TRACKING} itemId={callRequestId} isLoading={isLoading} />
        <NoteSection
          type={TYPE_FORM_TRACKING}
          itemId={callRequest?.id}
          notes={callRequest?.notes ?? []}
          isLoading={isLoading}
        />
        <SendEmailSection
          itemId={callRequest?.id}
          type={TYPE_FORM_TRACKING}
          isLoading={isLoading}
        />
      </AutoGrid>
    </div>
  );
}
