import { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import ConfigurationCard from 'components/configuration/ConfigurationCard';
import ErrorFocus from 'components/ErrorFocus';
import GrayBars from 'components/GrayBars';
import TextField from 'components/input/TextField';
import Toggle from 'components/input/Toggle';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import SupportParagraph from 'components/SupportParagraph';
import { SUP_ART_SESSION_STOP } from 'globals/support';
import {
  useHasAdminAccess,
  useHasEnterprisePlan,
  useHasManagerAccess,
  useHasOptimizePlan,
} from 'hooks/access';
import { useSessionStop } from 'hooks/queries/domain-setting';
import { useTranslate } from 'hooks/translate';
import { useUserLanguage } from 'hooks/user';

type Props = {
  isLoading: boolean;
};

export default function SessionStop({ isLoading }: Props) {
  const translateText = useTranslate();
  const language = useUserLanguage();
  const hasManagerAccess = useHasManagerAccess();
  const hasAdminAccess = useHasAdminAccess();
  const hasOptimizePlan = useHasOptimizePlan();
  const hasEnterprisePlan = useHasEnterprisePlan();

  const [showEdit, setShowEdit] = useState(false);
  const { sessionStop, updateSessionStop } = useSessionStop();

  let minimumSessions = 1;
  if (hasOptimizePlan) minimumSessions = 5;
  if (hasEnterprisePlan) minimumSessions = 25;

  let warningText = translateText(
    'call-tracking',
    'Please note: the session stop can only be changed once a month.',
  );
  if (sessionStop?.is_changed_this_month) {
    warningText = translateText(
      'call-tracking',
      'You can only change the session stop once a month. For questions, contact us via support@adcalls.nl.',
    );
  }

  async function save(values: { enabled: boolean; amount: number }) {
    await updateSessionStop(values).then(() => {
      setShowEdit(false);
    });
  }

  function getRows() {
    if (!sessionStop?.enabled) return [];
    return [{ key: translateText('label', 'Limit'), value: String(sessionStop?.session_amount) }];
  }

  const validationSchema = Yup.object({
    amount: Yup.number().test(function (value) {
      // The amount only needs to be validated when the setting is enabled
      if (this.parent.enabled === false) return true;
      if (!value) {
        return this.createError({ message: translateText('message', 'This field is required.') });
      }
      return value >= minimumSessions
        ? true
        : this.createError({
            message: translateText(
              'message',
              'You cannot set the session stop below {sessions} because your package includes {sessions} sessions.',
              { sessions: (minimumSessions * 1000).toLocaleString(language) },
            ),
          });
    }),
  });

  return (
    <>
      <ConfigurationCard
        title={translateText('label', 'Session stop')}
        supportArticleId={SUP_ART_SESSION_STOP}
        onEdit={() => setShowEdit(true)}
        isLoading={isLoading}
        rightParagraph={
          hasManagerAccess
            ? translateText(
                'call-tracking',
                'Would you like to set or change a session stop? Then click on the gears.',
              )
            : ''
        }
        enabled={!!sessionStop?.enabled}
      >
        <GrayBars rows={getRows()} isLoading={isLoading} />
      </ConfigurationCard>
      <Formik
        initialValues={{
          enabled: !!sessionStop?.enabled,
          amount:
            sessionStop && sessionStop.session_amount !== 0
              ? sessionStop?.session_amount / 1000
              : minimumSessions,
        }}
        onSubmit={async values => await save(values)}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({
          values,
          submitForm,
          resetForm,
          isSubmitting,
          dirty,
          handleChange,
          setFieldValue,
          errors,
          submitCount,
        }) => {
          const disabled = isSubmitting || (sessionStop?.is_changed_this_month && !hasAdminAccess);
          return (
            <Setup
              id="session-stop"
              show={showEdit}
              title={translateText('label', 'Set up session stop')}
              save={submitForm}
              close={() => setShowEdit(false)}
              afterClose={resetForm}
              isSaving={isSubmitting}
              maxWidth={340}
              hasChanges={dirty}
              saveButtonDisabled={disabled}
            >
              <ErrorFocus element={document.getElementById('session-stop')} />
              <SupportParagraph
                articleId={SUP_ART_SESSION_STOP}
                extraText={translateText('call-tracking', 'Set up the session stop.')}
              />
              <i>
                <p>{warningText}</p>
              </i>
              <div className="section session-stop">
                <InputWrapper label={translateText('label', 'Session stop')}>
                  <Toggle
                    name="enabled"
                    checked={values.enabled}
                    onClick={handleChange}
                    disabled={disabled}
                  />
                </InputWrapper>
                {values.enabled && (
                  <InputWrapper label={translateText('label', 'Limit')}>
                    <TextField
                      type="number"
                      name="amount"
                      wrapperClassName="value"
                      value={values.amount}
                      onChange={e =>
                        setFieldValue(
                          'amount',
                          e.target.valueAsNumber > 0 ? e.target.valueAsNumber : '', // 0 instead of minimumSessions so you can't type negative numbers but you can still type for example "10"
                        )
                      }
                      disabled={disabled}
                      minimalValue={minimumSessions}
                      error={submitCount > 0 && errors.amount}
                    />
                    <span className="sessions-label">
                      {(1000).toLocaleString(language).substring(1)}{' '}
                      {translateText('label', 'Sessions').toLowerCase()}
                    </span>
                    <button
                      disabled={disabled}
                      className="btn btn-lightblue btn-square btn-small no-padding bold"
                      onClick={() => setFieldValue('amount', values.amount + 1)}
                    >
                      +
                    </button>
                    <button
                      disabled={!!disabled || values.amount <= minimumSessions}
                      className="btn btn-lightblue btn-square btn-small no-padding bold"
                      onClick={() => setFieldValue('amount', values.amount - 1)}
                    >
                      -
                    </button>
                  </InputWrapper>
                )}
                {sessionStop?.reached_at && (
                  <InputWrapper label={translateText('label', 'Session stop reached at')}>
                    {sessionStop?.reached_at}
                  </InputWrapper>
                )}
                {values.enabled && (hasOptimizePlan || hasEnterprisePlan) && (
                  <p className="margin-top">
                    {translateText(
                      'message',
                      'Within the {package} package, {sessions} sessions are included.',
                      {
                        package: hasOptimizePlan
                          ? translateText('label', 'Optimize')
                          : translateText('label', 'Enterprise'),
                        sessions: (minimumSessions * 1000).toLocaleString(language),
                      },
                    )}
                  </p>
                )}
              </div>
            </Setup>
          );
        }}
      </Formik>
    </>
  );
}
