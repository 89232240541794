import { ErrorBoundary } from 'react-error-boundary';
import { Route, Routes } from 'react-router';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import moment from 'moment/moment';

import ErrorPage from 'components/ErrorPage';
import PublicArticle from 'components/knowledge-center/PublicArticle';
import MessageBar from 'components/MessageBar';
import AuthenticatedRoute from 'components/routes/AuthenticatedRoute';
import BaseRoute from 'components/routes/BaseRoute';
import UnauthenticatedRoute from 'components/routes/UnauthenticatedRoute';
import TrialRemainingBar from 'components/TrialRemainingBar';
import { MessageType } from 'contexts/Messages';
import { useSession } from 'contexts/Session';
import { useUserLanguage } from 'hooks/user';
import DataProcessorAgreement from 'pages/DataProcessorAgreement';
import Reset from 'pages/Reset';
import AcceptInvitation from 'pages/unauthenticated/AcceptInvitation';
import Login from 'pages/unauthenticated/Login';
import NewEmail from 'pages/unauthenticated/NewEmail';
import RequestAccount from 'pages/unauthenticated/RequestAccount';
import ResetPassword from 'pages/unauthenticated/ResetPassword';
import VerifyEmail from 'pages/unauthenticated/VerifyEmail';
import Authenticated from 'wrappers/Authenticated';

import 'moment/locale/nl';

export default function App() {
  const language = useUserLanguage();
  moment.locale(language);

  const { token } = useSession();

  const queriesFetching = useIsFetching();
  const queriesMutating = useIsMutating();

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <div className="message-bars">
          <MessageBar type={MessageType.Permanent} />
          <MessageBar type={MessageType.Toast} />
        </div>
        <div
          id="app-wrapper"
          className={
            queriesMutating ? 'general-mutating' : queriesFetching ? 'general-loading' : ''
          }
        >
          <Routes>
            <Route
              path="/login"
              element={
                <UnauthenticatedRoute>
                  <Login />
                </UnauthenticatedRoute>
              }
            />
            <Route
              path="/reset-password"
              element={
                <UnauthenticatedRoute>
                  <ResetPassword />
                </UnauthenticatedRoute>
              }
            />
            <Route
              path="/accept-invitation"
              element={
                <UnauthenticatedRoute>
                  <AcceptInvitation />
                </UnauthenticatedRoute>
              }
            />
            <Route
              path="/request-account"
              element={
                <UnauthenticatedRoute>
                  <RequestAccount />
                </UnauthenticatedRoute>
              }
            />
            <Route
              path="/verify-email"
              element={
                <UnauthenticatedRoute>
                  <VerifyEmail />
                </UnauthenticatedRoute>
              }
            />
            <Route
              path="/new-email"
              element={
                <BaseRoute>
                  <NewEmail />
                </BaseRoute>
              }
            />
            <Route
              path="/reset"
              element={
                <AuthenticatedRoute>
                  <Reset />
                </AuthenticatedRoute>
              }
            />
            {!token && (
              <Route
                path="/knowledge-center/article/:article"
                element={
                  <UnauthenticatedRoute>
                    <PublicArticle />
                  </UnauthenticatedRoute>
                }
              />
            )}
            <Route path="/data-processor-agreement" element={<DataProcessorAgreement />}>
              <Route
                path="/data-processor-agreement/:version"
                element={<DataProcessorAgreement />}
              />
            </Route>
            <Route
              path="/*"
              element={
                <AuthenticatedRoute>
                  <Authenticated />
                </AuthenticatedRoute>
              }
            />
          </Routes>
        </div>
        <TrialRemainingBar />
      </ErrorBoundary>
    </>
  );
}
