import { useMutation } from '@tanstack/react-query';
import fileDownload from 'js-file-download';

import { useMessages } from 'contexts/Messages';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';

export function useCompareNumbers() {
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync: compareNumbers, isPending } = useMutation({
    mutationFn: (formData: FormData) =>
      fetchData('/flux/compare-numbers', {
        method: 'POST',
        bodyParams: formData,
      }),
    onSuccess: data => {
      fileDownload(data['missing in AdCalls'].join(',\n'), 'number_check_missing_in_adcalls.txt');
      fileDownload(
        JSON.stringify(data['missing in Flux'], null, 2),
        'number_check_missing_in_flux.txt',
      );
      addSuccessMessage(translateText('message', 'Your file is ready.'));
    },
  });

  return { compareNumbers, isLoading: isPending };
}
