import PopupModal from 'components/PopupModal';
import { TourSection, useGuidedTour } from 'contexts/GuidedTour';
import { useDisplayTour } from 'hooks/queries/user';
import CompletedTour from './CompletedTour';
import DomainOverviewTour from './DomainOverviewTour';
import KnowledgeCenterTour from './KnowledgeCenterTour';
import MainNavigationTour from './MainNavigationTour';
import NotificationCenterTour from './NotificationCenterTour';
import QuitTour from './QuitTour';
import ReportsTour from './ReportsTour';
import StartTour from './StartTour';

export default function GuidedTour() {
  const { displayTour } = useDisplayTour();
  const { section, showCancel } = useGuidedTour();

  if (displayTour !== true) return null;
  else if (showCancel) return <QuitTour />;

  function getSectionComponent() {
    switch (section) {
      case TourSection.Start:
        return <StartTour />;
      case TourSection.MainNavigation:
        return <MainNavigationTour />;
      case TourSection.KnowledgeCenter:
        return <KnowledgeCenterTour />;
      case TourSection.NotificationCenter:
        return <NotificationCenterTour />;
      case TourSection.DomainOverview:
        return <DomainOverviewTour />;
      case TourSection.Reports:
        return <ReportsTour />;
      case TourSection.Completed:
        return <CompletedTour />;
    }
  }

  return (
    <PopupModal
      show
      size={['start', 'completed'].includes(section) ? 'standard' : 'big'}
      extraClass="guided-tour"
    >
      {getSectionComponent()}
    </PopupModal>
  );
}
