import { FormikProps } from 'formik';

import TextField from 'components/input/TextField';
import Toggle from 'components/input/Toggle';
import InputWrapper from 'components/slide-in/InputWrapper';
import { ConditionKey, Filter as FilterValues } from 'hooks/queries/app';
import { useTranslate } from 'hooks/translate';
import Conditions from '../Conditions';

type Props = {
  formProps: FormikProps<Omit<FilterValues, 'id' | 'ga4_stream_id'>>;
};

export default function Filter({ formProps }: Props) {
  const translateText = useTranslate();
  const { values, handleChange, setFieldValue, isSubmitting, errors, submitCount } = formProps;

  const conditionKeys: Record<ConditionKey, string> = {
    adcalls_answered: translateText('label', 'Call answered'),
    adcalls_location: translateText('label', 'Location'),
    adcalls_evaluation: translateText('label', 'Evaluation'),
    adcalls_evaluation_value: translateText('label', 'Evaluation value'),
    adcalls_returning_caller: translateText('label', 'Recurring caller'),
    adcalls_second_event: translateText('label', 'Second event'),
    adcalls_selection_menu: translateText('label', 'Customer evaluation'),
  };

  return (
    <>
      <InputWrapper label={translateText('label', 'Enabled')}>
        <Toggle
          name="enabled"
          checked={values.enabled}
          onClick={handleChange}
          disabled={isSubmitting}
        />
      </InputWrapper>
      <InputWrapper
        label={translateText('label', 'Name')}
        tooltip={translateText(
          'tooltip/google-analytics-4',
          'Name the filter. This is also the name for the event in Google Analytics 4.',
        )}
      >
        <TextField
          name="event_name"
          value={values.event_name}
          onChange={e => setFieldValue('event_name', e.target.value.replace(' ', ''))}
          disabled={isSubmitting}
          error={submitCount > 0 && errors.event_name}
        />
      </InputWrapper>
      <Conditions
        options={conditionKeys}
        conditions={values.conditions}
        setConditions={conditions => setFieldValue('conditions', conditions)}
        disabled={isSubmitting}
      />
    </>
  );
}
