import ReactDatePicker from 'react-datepicker';
import { Formik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import { useTranslate } from 'hooks/translate';
import { useUserDateFormat } from 'hooks/user';
import TextField from './TextField';

type Props = {
  value: string;
  format?: string;
  disabled?: boolean;
  error?: string;
  minDate?: Date;
  onChange: (date: string) => void;
};

export default function DatePicker({
  value,
  format = 'YYYY-MM-DD',
  disabled = false,
  onChange,
  minDate,
  error,
}: Props) {
  const translateText = useTranslate();
  const userDateFormat = useUserDateFormat();
  const dateFormat =
    userDateFormat === 'YYYY-MM-DD'
      ? translateText('label', 'yyyy-mm-dd')
      : translateText('label', 'dd-mm-yyyy');
  const date = moment(value, format);
  return (
    <div className="date-selector">
      <ReactDatePicker
        onChange={date => onChange(moment(date).format(format))}
        selected={date.toDate()}
        minDate={minDate}
        inline
      />
      <Formik
        initialValues={{ date: date.format(userDateFormat) }}
        validationSchema={Yup.object({
          date: Yup.string().test({
            message: translateText('message', 'The entry is invalid (desired format: {format}).', {
              format: dateFormat,
            }),
            test: value => !!value && moment(value, userDateFormat, true).isValid(),
          }),
        })}
        onSubmit={values => onChange(moment(values.date, userDateFormat).format(format))}
        validateOnChange={false}
        validateOnMount={false}
        enableReinitialize
      >
        {({ values, handleChange, handleSubmit, submitForm, errors }) => (
          <div className="date-picker-input no-flex">
            <form onSubmit={handleSubmit}>
              <TextField
                name="date"
                value={values.date}
                onChange={handleChange}
                onBlur={submitForm}
                placeholder={dateFormat}
                error={error ?? errors.date}
                disabled={disabled}
              />
            </form>
          </div>
        )}
      </Formik>
    </div>
  );
}
