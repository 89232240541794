import { Dispatch, SetStateAction } from 'react';
import { Payload } from 'recharts/types/component/DefaultLegendContent';

import Tooltip from 'components/Tooltip';
import { useCalculate } from 'hooks/calculate';
import { useTranslate } from 'hooks/translate';
import { useUserLanguage } from 'hooks/user';

export type CustomLegendPayload = Payload & {
  payload?: { strokeDasharray: string; [key: string]: unknown };
};

type BaseProps = {
  payload?: CustomLegendPayload[];
  setActiveIndex?: Dispatch<SetStateAction<number | null>>;
  previousData?: Record<string, unknown>[] | undefined;
  legendKey?: string;
};

type DefaultProps = {
  type: 'donut' | 'bar' | 'area';
  compareWithPrev?: never;
  totalOther?: never;
  totalPrevOther?: never;
} & BaseProps;

type PieProps = {
  type: 'pie';
  compareWithPrev: boolean;
  totalOther: number;
  totalPrevOther: number;
} & BaseProps;

export default function Legend({
  payload,
  setActiveIndex,
  type,
  previousData,
  legendKey,
  compareWithPrev,
  totalOther,
  totalPrevOther,
}: DefaultProps | PieProps) {
  const translateText = useTranslate();
  const language = useUserLanguage();
  const { getDifference } = useCalculate();
  return (
    <ul className="recharts-custom-legend">
      {payload?.map((entry, index) => {
        if (entry.payload?.noLegend) return null;
        let value = entry.value;
        if (legendKey !== undefined) {
          value = entry.payload?.[legendKey];
          if (value === undefined) return null;
        }

        let text = value;
        if (typeof entry.payload?.label === 'string') {
          text = <Tooltip placement="bottom" text={entry.payload.label} html={text} />;
        }

        text = (
          <div className="legend-item">
            <div className="ball" style={{ background: entry.color }} />
            <span className="text">{text}</span>
          </div>
        );

        if (type === 'pie' || type === 'donut') {
          let prevElement = null;
          const otherLabel = translateText('label', 'Others');
          let numberValue = (Number(entry.payload?.value) || '-').toLocaleString(language);

          if (value === otherLabel && totalOther !== undefined) {
            numberValue = (Number(totalOther) || '-').toLocaleString(language);
          }

          if (compareWithPrev && previousData) {
            let prevValue = totalPrevOther;
            if (value !== otherLabel || totalPrevOther === undefined) {
              prevValue = Number(previousData.find(prevItem => prevItem.argument === value)?.value);
            }
            const prevPercentage = getDifference(numberValue, prevValue);

            prevElement = (
              <div className={'difference' + prevPercentage[1]}>
                <Tooltip
                  html={<>{prevPercentage[0]}</>}
                  text={
                    translateText('label', 'Previous period') +
                    ': ' +
                    (Number(prevValue) || '-').toLocaleString(language)
                  }
                  placement="bottom"
                />
              </div>
            );
          }

          if (type === 'donut') numberValue += '%';
          text = (
            <div className="space-between">
              {text}
              <div className="legend-item right">
                <span className="space-between">
                  {numberValue}
                  {prevElement}
                </span>
              </div>
            </div>
          );
        }

        return (
          <li
            key={index}
            onMouseEnter={() => setActiveIndex?.(index)}
            onMouseLeave={() => setActiveIndex?.(null)}
          >
            <div className="legend-item-wrapper">{text}</div>
          </li>
        );
      })}
    </ul>
  );
}

export function EmptyLegend({ label }: { label: string }) {
  return (
    <ul className="recharts-custom-legend">
      <li>
        <div className="legend-item-wrapper">
          <div className="legend-item">
            <div className="ball empty" />
            <span className="text italic">{label}</span>
          </div>
        </div>
      </li>
    </ul>
  );
}
