import { MouseEventHandler } from 'react';

import Skeleton from 'components/Skeleton';
import { useTranslate } from 'hooks/translate';

type Props = {
  checked: boolean;
  onClick: MouseEventHandler<HTMLInputElement>;
  id?: string;
  label?: string;
  disabled?: boolean;
  name?: string;
  canEdit?: boolean;
  isLoading?: boolean;
};

export default function Toggle({
  checked,
  onClick,
  id,
  label,
  disabled,
  name,
  canEdit = true,
  isLoading,
}: Props) {
  const translateText = useTranslate();

  if (isLoading) return <Skeleton className="toggle-skeleton" />;
  if (!canEdit) {
    return <span>{checked ? translateText('label', 'Yes') : translateText('label', 'No')}</span>;
  }

  return (
    <div className="slider-toggle" data-testid="toggle">
      {label && (
        <span className="clickable" onClick={onClick}>
          {label}
        </span>
      )}
      <label className="switch">
        <input
          disabled={disabled}
          type="checkbox"
          readOnly
          name={name}
          checked={checked}
          onClick={onClick}
        />
        <div id={id} className={'slider round' + (disabled ? ' disabled' : '')} />
      </label>
    </div>
  );
}
