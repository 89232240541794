import Skeleton from 'components/Skeleton';
import { useGlobal } from 'contexts/Global';
import { LANGUAGE_DUTCH } from 'globals/constants';
import { useLoginContent } from 'hooks/queries/system-setting';

export default function LoginNewsPage() {
  const { selectedLanguage } = useGlobal();
  const { loginContent, isError } = useLoginContent();
  if (isError) return null;

  const { image, title, url, button, content } =
    loginContent?.[(selectedLanguage as keyof typeof loginContent) ?? LANGUAGE_DUTCH] ?? {};

  return (
    <div className="news-wrapper" data-testid="login-news-page">
      <div className="image-wrapper">
        {image ? (
          <img alt="AdCalls" className="news-image" src={image} />
        ) : (
          <Skeleton className="news-image" width={500} height={200} isWhite />
        )}
      </div>
      <div className="news-title bold">{title ?? <Skeleton width={250} isWhite />}</div>
      <div className="news-text">
        <p>{content ?? <Skeleton count={2} isWhite />}</p>
      </div>
      <div className="button-wrapper">
        {url ? (
          <a href={url} target="_blank">
            <button className="btn btn-lightblue btn-border">{button ?? url}</button>
          </a>
        ) : (
          <Skeleton height={38} width={100} isWhite />
        )}
      </div>
    </div>
  );
}
