import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import moment from 'moment';

import important from 'assets/images/icons/important.svg';
import ItemNavigation from 'components/ItemNavigation';
import Skeleton from 'components/Skeleton';
import { PH_MASK } from 'globals/constants';
import { useNotification, useReadNotification } from 'hooks/queries/notification';
import { useTranslate } from 'hooks/translate';
import Error404 from 'pages/Error404';

export default function NotificationDetails() {
  const navigate = useNavigate();
  const translateText = useTranslate();
  const { markAsRead } = useReadNotification();

  const params = useParams();
  const notificationId = Number(params.id);

  const { notification, isLoading, isError } = useNotification(
    !isNaN(notificationId) ? notificationId : null,
  );

  useEffect(() => {
    if (notification?.is_read === false) markAsRead(notification.id);
  }, [markAsRead, notification?.is_read, notification?.id]);

  if (isNaN(notificationId) || isError) {
    return (
      <Error404
        title={translateText('label', 'Notification not found')}
        firstParagraph={translateText(
          'text',
          'Unfortunately, this notification does not exist or you do not have access to the notication.',
        )}
      />
    );
  }

  return (
    <div className="notifications">
      <h1>
        {translateText('label', 'Notification')} #{notificationId}
      </h1>
      <div className="notification-detail white-block">
        <h2 className={PH_MASK}>
          {isLoading ? <Skeleton width={150} /> : notification?.title}
          {!isLoading && notification?.is_critical && (
            <img className="important" src={important} alt="important" />
          )}
        </h2>
        {isLoading ? (
          <Skeleton width={120} />
        ) : (
          <span>
            {moment(notification?.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY - HH:mm')}
          </span>
        )}
        {!isLoading && notification?.domain && (
          <div className={`bold ${PH_MASK}`}>{notification.domain}</div>
        )}
        {isLoading ? (
          <p>
            <Skeleton count={2} />
          </p>
        ) : (
          <p
            className={PH_MASK}
            dangerouslySetInnerHTML={{ __html: notification?.message ?? '' }}
          />
        )}
      </div>
      <div className="notification-footer">
        <button
          onClick={() => navigate('/account/notifications')}
          className="btn btn-text no-padding"
        >
          {translateText('label', 'Back to all notifications')}
        </button>
        <ItemNavigation
          itemId={notificationId}
          itemName={translateText('label', 'Notification')}
          pageRoute="/account/notifications"
          tableKey={['notifications', 'index']}
          endpoint="/notification/index"
          filters={{ domain: false }}
        />
      </div>
    </div>
  );
}
