import { useNavigate } from 'react-router';

import important from 'assets/images/icons/important.svg';
import QueryTable from 'components/table/QueryTable';
import { Notification } from 'hooks/queries/notification';
import { useTranslate } from 'hooks/translate';

export default function Notifications() {
  const navigate = useNavigate();
  const translateText = useTranslate();

  return (
    <div className="notifications">
      <h1>{translateText('label', 'Notifications')}</h1>
      <QueryTable<Notification>
        tableKey={['notifications', 'index']}
        columns={{
          is_read: {
            header: '',
            keyValues: {
              true: ' ',
              false: <div className="big-dot not-read" />,
            },
          },
          is_critical: {
            header: '',
            keyValues: { true: <img src={important} alt="important" />, false: ' ' },
          },
          created_at: { header: translateText('label', 'Date & time'), date: true },
          domain: { header: translateText('label', 'Domain'), private: true },
          category: { header: translateText('label', 'Category') },
          title: { header: translateText('label', 'Title') },
        }}
        endpoint="/notification/index"
        refresh={['notification']}
        onRowClick={row => navigate('/account/notifications/' + row.id)}
        pagination
        filters={{ domain: false }}
      />
    </div>
  );
}
