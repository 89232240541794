import { useNavigate } from 'react-router';

import knowledgeCenterImg from 'assets/images/knowledge-center/knowledge-center.svg';
import marketingImg from 'assets/images/knowledge-center/marketing.svg';
import supportImg from 'assets/images/knowledge-center/support.svg';
import Carousel from 'components/knowledge-center/Carousel';
import CategoryTypeTile from 'components/knowledge-center/CategoryTypeTile';
import { SearchBar } from 'components/knowledge-center/SearchBar';
import RandomWidthSkeleton from 'components/RandomWidthSkeleton';
import Skeleton from 'components/Skeleton';
import { KNOWLEDGE_CENTER_TYPE_OVERVIEW } from 'globals/constants';
import { Article } from 'globals/types';
import { useArticles } from 'hooks/queries/support';
import { useTranslate } from 'hooks/translate';

export default function Overview() {
  const translateText = useTranslate();
  const navigate = useNavigate();

  const { articles: latestArticles, isLoading: isLoadingLatest } = useArticles({
    sort: '-updated_at',
    limit: 7,
  });
  const { articles: popularArticles, isLoading: isLoadingPopular } = useArticles({
    sort: 'sup_popular_article.order',
    popular: KNOWLEDGE_CENTER_TYPE_OVERVIEW,
    limit: 7,
  });
  const { isLoading: isLoadingCarousel } = useArticles({
    slider: true,
    limit: 3,
    sort: 'sup_slider.order',
  });

  const isLoading = isLoadingLatest || isLoadingPopular || isLoadingCarousel;

  function getArticleList(list: Article[] | undefined) {
    if (isLoading) {
      return <RandomWidthSkeleton minWidth={60} count={7} containerClass="item" />;
    }
    return (list ?? []).map((item, index) => (
      <div
        key={index}
        className="item clickable"
        onClick={() => navigate('/knowledge-center/article/' + encodeURIComponent(item.main_slug))}
      >
        <span>{item.title}</span>
      </div>
    ));
  }

  return (
    <div className="knowledge-center-overview">
      <div className="title-container">
        <div className="title">{translateText('label', 'Knowledge center')}</div>
        <div className="sub-title">{translateText('label', 'What can we help you with?')}</div>
        <SearchBar />
        <Carousel isLoading={isLoading} />
      </div>
      <div className="categories">
        <CategoryTypeTile
          title={translateText('label', 'Knowledge & inspiration')}
          image={knowledgeCenterImg}
          content={translateText(
            'knowledge-center',
            "Get inspired. Stay informed of our latest developments and updates. Want to improve your knowledge of Call Tracking? Then you've come to the right place!",
          )}
          link="/knowledge-center/1"
          isLoading={isLoading}
        />
        <CategoryTypeTile
          title={translateText('label', 'Support')}
          image={supportImg}
          content={translateText(
            'knowledge-center',
            'Looking for a specific functionality? Or are you curious how to implement Call Tracking in your organization? Then take a quick look and read all the instructions in our support articles. To work!',
          )}
          link="/knowledge-center/0"
          isLoading={isLoading}
        />
        <CategoryTypeTile
          title={translateText('label', 'Marketing')}
          image={marketingImg}
          content={translateText(
            'knowledge-center',
            'Are you looking for marketing material to share? You will find all leaflets, presentations and other marketing material here. Enough to share!',
          )}
          link="/knowledge-center/2"
          isLoading={isLoading}
        />
      </div>
      <div className="latest-items">
        <div className="item-container left">
          <h2>{isLoading ? <Skeleton width="135px" /> : translateText('label', 'Latest news')}</h2>
          <div className="items">{getArticleList(latestArticles)}</div>
        </div>
        <div className="item-container right">
          <h2>{isLoading ? <Skeleton width="90px" /> : translateText('label', 'Popular')}</h2>
          <div className="items">{getArticleList(popularArticles)}</div>
        </div>
      </div>
    </div>
  );
}
