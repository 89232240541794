import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import moment from 'moment';

import AddButton from 'components/buttons/AddButton';
import ExportButton from 'components/buttons/ExportButton';
import ConfigurationCard from 'components/configuration/ConfigurationCard';
import ConfigurationPage from 'components/configuration/ConfigurationPage';
import OffsiteSetup from 'components/configuration/offsites/OffsiteSetup';
import TextField from 'components/input/TextField';
import Pagination from 'components/Pagination';
import Skeleton from 'components/Skeleton';
import Tooltip from 'components/Tooltip';
import { useTenant } from 'contexts/Tenant';
import {
  OFFSITE_STATUS_DISCONTINUE_ACCEPTED,
  OFFSITE_STATUS_DISCONTINUE_REQUESTED,
  OFFSITE_STATUS_PENDING,
  PH_MASK,
} from 'globals/constants';
import { SUP_ART_OFFSITE } from 'globals/support';
import { useTrial } from 'hooks/access';
import {
  useDomainAuth,
  useExportOffsites,
  useOffsites,
  useUpdateOffsites,
} from 'hooks/queries/domain';
import { useTranslate } from 'hooks/translate';
import { useUserDateFormat } from 'hooks/user';

export default function OffsitePhoneNumbers() {
  const translateText = useTranslate();
  const dateFormat = useUserDateFormat();
  const { isTrial } = useTrial();
  const { selectedDomain } = useTenant();
  const { updateOffsites } = useUpdateOffsites();

  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState(1);
  const [showEdit, setShowEdit] = useState<'add' | 'edit' | false>(false);

  useEffect(() => setPage(1), [selectedDomain, search]);

  const { exportOffsites, isExporting } = useExportOffsites();
  const {
    offsites,
    isLoading: isLoadingOffsites,
    pageCount,
    itemCount,
  } = useOffsites({ search, limit, page });
  const { isLoading: isLoadingDomainAuth } = useDomainAuth();
  const isLoading = isLoadingOffsites || isLoadingDomainAuth;

  let content = null;
  if (isLoading) {
    content = <Skeleton height={33} count={3} />;
  } else if (isTrial) {
    content = (
      <p>
        {translateText(
          'call-tracking',
          'You cannot use offsite phone numbers within the trial period. You can choose to wait for the trial period to end or convert to a paid pricing plan.',
        )}
      </p>
    );
  } else if (offsites?.length === 0) {
    content = <p className="no-data">{translateText('label', 'No offsite phone numbers')}</p>;
  } else if (offsites?.length) {
    const rows = offsites.map((offsite, index) => {
      let extra;
      switch (offsite.status) {
        case OFFSITE_STATUS_PENDING:
          extra = <div className="requested-new">{translateText('label', 'In request')}</div>;
          break;
        case OFFSITE_STATUS_DISCONTINUE_REQUESTED:
          extra = <div className="requested-stop">{translateText('label', 'Stop requested')}</div>;
          break;
        case OFFSITE_STATUS_DISCONTINUE_ACCEPTED:
          extra = (
            <div className="requested-stop">
              <Tooltip
                html={<>{translateText('label', 'Will be stopped')}</>}
                text={translateText('label', 'Will be stopped at {date}', {
                  date: moment(offsite.stop_requested_for).format(dateFormat),
                })}
              />
            </div>
          );
          break;
      }

      let lastCallDate = '-';
      if (offsite.last_call) {
        lastCallDate = moment(offsite.last_call, 'YYYY-MM-DD HH:mm:ss').format(
          `${dateFormat} HH:mm:ss`,
        );
      }

      return (
        <tr key={index}>
          <td className={PH_MASK}>{offsite.name}</td>
          <td className={PH_MASK}>{offsite.number}</td>
          <td className={PH_MASK}>{offsite.destination}</td>
          <td>{extra}</td>
          <td>
            <Tooltip text={translateText('label', 'Last call') + ': ' + lastCallDate} />
          </td>
        </tr>
      );
    });

    content = (
      <table className="offsites-table">
        <colgroup>
          <col style={{ width: '30%' }} />
          <col style={{ width: '30%' }} />
          <col style={{ width: '20%' }} />
          <col style={{ width: '20%' }} />
        </colgroup>
        <thead>
          <tr>
            <th>{translateText('label', 'Name')}</th>
            <th>{translateText('label', 'Offsite phone number')}</th>
            <th>{translateText('label', 'Destination')}</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  }

  return (
    <ConfigurationPage title={translateText('label', 'Offsite phone numbers')} className="offsites">
      <div className="search-row">
        <AddButton
          onClick={() => setShowEdit('add')}
          isLoading={isLoading}
          itemName={translateText('label', 'Offsite phone numbers')}
          tooltipText={
            isTrial
              ? translateText(
                  'call-tracking',
                  'The domain is currently in a trial period. During the trial period it is not possible to use offsite phone numbers.',
                )
              : null
          }
          disabled={isTrial}
        />
        <div className="right-wrapper">
          <ExportButton
            type="csv"
            onClick={() => exportOffsites()}
            disabled={isTrial || isExporting}
            isLoading={isLoading}
          />
          <div className="search">
            <Formik initialValues={{ search: '' }} onSubmit={values => setSearch(values.search)}>
              {({ values, handleChange, handleSubmit, submitForm }) => (
                <form onSubmit={handleSubmit}>
                  {isLoading ? (
                    <Skeleton height={38} width={180} />
                  ) : (
                    <TextField
                      name="search"
                      value={values.search}
                      onChange={handleChange}
                      onBlur={submitForm}
                      placeholder={translateText('label', 'Search') + '...'}
                      disabled={isTrial}
                    />
                  )}
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <ConfigurationCard
        onEdit={() => setShowEdit('edit')}
        isLoading={isLoading}
        disabled={isTrial}
        disabledText={translateText(
          'call-tracking',
          'The domain is currently in a trial period. During the trial period it is not possible to use offsite phone numbers.',
        )}
        supportArticleId={SUP_ART_OFFSITE}
        rightParagraph={translateText(
          'call-tracking',
          'With offsite phone numbers you can make media and locations outside of your website measurable.[manager] Click on the gears to manage the offsite phone numbers.[/manager]',
        )}
      >
        {content}
        {itemCount > 50 && (
          <Pagination
            activePage={page}
            itemsCountPerPage={limit}
            totalItemsCount={itemCount}
            totalPageCount={pageCount}
            options={[50, 100, 200, 500]}
            onChange={(pageNumber: number, itemsPerPage: number) => {
              setPage(pageNumber);
              setLimit(itemsPerPage);
            }}
          />
        )}
      </ConfigurationCard>
      <OffsiteSetup
        type={showEdit}
        offsites={offsites}
        close={() => setShowEdit(false)}
        onSave={async values =>
          await updateOffsites({
            request: values.addedOffsites,
            update: Object.values(values.changedOffsites),
            delete: values.deletedOffsites.map(o => o.numberpool_id!),
          })
        }
      />
    </ConfigurationPage>
  );
}
