import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { camelCase } from 'lodash';
import moment from 'moment';

import { getDefaultFilters, useFilters } from 'contexts/Filters';
import { useMessages } from 'contexts/Messages';
import { TABLE_KEY_CALL_REQUEST_INDEX, TYPE_FORM_TRACKING } from 'globals/constants';
import { CallRequest } from 'globals/types';
import { useSetQueryData, useTableData } from 'hooks/data';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';
import { useUserDateFormat } from 'hooks/user';
import { CALL_REQUEST_HISTORY_EXPAND } from 'pages/authenticated/form-tracking/history/History';
import { useUpdateMarking } from './call';

export function useCallRequest(id: number | null = null) {
  const { fetchData } = useFetch();

  const { getAppliedFilters } = useFilters();
  const tableKey = camelCase(TABLE_KEY_CALL_REQUEST_INDEX.join(' '));
  const filters = {
    ...getDefaultFilters(TYPE_FORM_TRACKING),
    previousPeriod: false,
    custom: { expand: CALL_REQUEST_HISTORY_EXPAND },
  };
  const { bodyParams, queryParams } = getAppliedFilters(filters, tableKey, true);
  const callRequestData = useTableData<CallRequest>([
    ...TABLE_KEY_CALL_REQUEST_INDEX,
    { ...bodyParams, ...queryParams },
  ]);

  const { data, isFetching, isError, error } = useQuery({
    queryKey: ['callRequest', id],
    queryFn: (): Promise<CallRequest> =>
      fetchData('/call-request/' + id, { queryParams: { expand: CALL_REQUEST_HISTORY_EXPAND } }),
    enabled: id !== null,
    meta: { noError: true },
    initialData: () => callRequestData?.find(c => c.id === id),
  });

  return { callRequest: data, isLoading: isFetching, isError, error };
}

export function useUpdateCallRequestMarking() {
  return useUpdateMarking(TYPE_FORM_TRACKING);
}

export function useStartCallRequest() {
  const translateText = useTranslate();
  const queryClient = useQueryClient();
  const setQueryData = useSetQueryData();
  const userDateFormat = useUserDateFormat();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (id: number): Promise<{ model: CallRequest; date: string; time: string }> =>
      fetchData('/call-request/start', {
        method: 'POST',
        queryParams: { id, expand: CALL_REQUEST_HISTORY_EXPAND },
      }),
    onSuccess: (data, id) => {
      queryClient.invalidateQueries({ queryKey: TABLE_KEY_CALL_REQUEST_INDEX });
      setQueryData<CallRequest>(['callRequest', id], () => data.model);
      addSuccessMessage(
        translateText('message', 'The call request will be started at {date} around {time}.', {
          date: moment(data.date, 'YYYY-MM-DD').format(userDateFormat),
          time: data.time,
        }),
      );
    },
  });

  function startCallRequest(id: number) {
    if (
      window.confirm(translateText('message', 'Are you sure you want to start this call request?'))
    ) {
      mutate(id);
    }
  }

  return {
    startCallRequest,
    isStarting: isLoading,
  };
}

export function useStopCallRequest() {
  const translateText = useTranslate();
  const queryClient = useQueryClient();
  const setQueryData = useSetQueryData();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (id: number): Promise<CallRequest> =>
      fetchData('/call-request/stop', {
        method: 'POST',
        queryParams: { id, expand: CALL_REQUEST_HISTORY_EXPAND },
      }),
    onSuccess: (data, id) => {
      queryClient.invalidateQueries({ queryKey: TABLE_KEY_CALL_REQUEST_INDEX });
      setQueryData<CallRequest>(['callRequest', id], () => data);
      addSuccessMessage(translateText('message', 'The call request has been stopped.'));
    },
  });

  function stopCallRequest(id: number) {
    if (
      window.confirm(translateText('message', 'Are you sure you want to stop this call request?'))
    ) {
      mutate(id);
    }
  }

  return {
    stopCallRequest,
    isStopping: isLoading,
  };
}
