import { useState } from 'react';
import { Formik } from 'formik';
import { isEqual } from 'lodash';

import ErrorFocus from 'components/ErrorFocus';
import Setup from 'components/slide-in/Setup';
import SetupWithSub from 'components/slide-in/SetupWithSub';
import { Offsite } from 'globals/types';
import { useTranslate } from 'hooks/translate';
import { useValidationSchema } from 'hooks/validation';
import OffsiteForm, { getOffsiteInitialValues, saveOffsite } from './OffsiteForm';
import OffsitesTable from './OffsitesTable';

type FormValues = {
  offsites: Offsite[];
  addedOffsites: Offsite[];
  deletedOffsites: Offsite[];
  changedOffsites: Record<number, Offsite>;
};

type Props = {
  type: 'add' | 'edit' | false;
  offsites: Offsite[] | undefined;
  onSave: (values: FormValues) => Promise<void>;
  close: () => void;
  intake?: boolean;
  canChangeCountry?: boolean;
};

export default function OffsiteSetup({
  type,
  offsites,
  close,
  onSave,
  intake,
  canChangeCountry,
}: Props) {
  const translateText = useTranslate();
  const { getOffsiteValidation } = useValidationSchema();

  const [showOffsite, setShowOffsite] = useState<'new' | Offsite | false>(false);

  async function save(values: FormValues) {
    await onSave(values).then(() => close());
  }

  const initialValues: FormValues = {
    offsites: offsites ?? [],
    addedOffsites: intake && offsites ? offsites : [],
    deletedOffsites: [],
    changedOffsites: {},
  };

  return (
    <>
      <Formik
        initialValues={getOffsiteInitialValues('new')}
        validationSchema={getOffsiteValidation(offsites ?? [])}
        onSubmit={async values =>
          await save({ ...initialValues, addedOffsites: [{ ...values, area: values.area! }] })
        }
        validateOnChange={false}
      >
        {({ submitForm, dirty, resetForm, isSubmitting }) => (
          <Setup
            show={type === 'add'}
            close={close}
            afterClose={resetForm}
            save={submitForm}
            maxWidth={390}
            title={translateText('label', 'Add offsite phone number')}
            hasChanges={dirty}
            saveButtonText={translateText('label', 'Add')}
            isSaving={isSubmitting}
          >
            <ErrorFocus element={document.getElementsByClassName('setup-wrapper')[0]} />
            <OffsiteForm isEdit={false} canChangeCountry={canChangeCountry} intake={intake} />
          </Setup>
        )}
      </Formik>
      <Formik initialValues={initialValues} onSubmit={save} enableReinitialize>
        {({ values, submitForm, setFieldValue, dirty, resetForm, isSubmitting }) => {
          const validationSchema = getOffsiteValidation(
            // To validate unique names, we need all existing + new offsites minus the one that is being edited
            [...values.offsites, ...values.addedOffsites].filter(i => !isEqual(showOffsite, i)),
          );
          return (
            <Formik // Form for the sub slide in
              initialValues={getOffsiteInitialValues(showOffsite)}
              validationSchema={validationSchema}
              onSubmit={offsiteValues =>
                saveOffsite(showOffsite, offsiteValues, values, setFieldValue, () =>
                  setShowOffsite(false),
                )
              }
              validateOnChange={false}
              validateOnMount={false}
              enableReinitialize
            >
              {({
                submitForm: submitSub,
                dirty: dirtySub,
                resetForm: resetSub,
                isSubmitting: isSubmittingSub,
              }) => (
                <SetupWithSub
                  show={type === 'edit'}
                  close={close}
                  afterClose={resetForm}
                  title={translateText('label', 'Manage offsite phone numbers')}
                  maxWidth={750}
                  hasChanges={dirty}
                  save={submitForm}
                  isSaving={isSubmitting}
                  sub={
                    <Setup
                      show={showOffsite !== false}
                      close={() => setShowOffsite(false)}
                      afterClose={resetSub}
                      save={submitSub}
                      maxWidth={400}
                      title={
                        showOffsite === 'new'
                          ? translateText('label', 'Add offsite phone number')
                          : translateText('label', 'Edit offsite phone number')
                      }
                      hasChanges={dirtySub}
                      isSaving={isSubmittingSub}
                    >
                      <OffsiteForm
                        isEdit={showOffsite !== 'new' && showOffsite && !!showOffsite.numberpool_id}
                        intake={intake}
                        canChangeCountry={canChangeCountry}
                      />
                    </Setup>
                  }
                >
                  <ErrorFocus element={document.getElementsByClassName('setup-wrapper')[0]} />
                  <p>
                    {translateText(
                      'tooltip/intake',
                      "With offsite phone numbers you can track media and locations outside your website. An 'offsite phone number' is hard-coded in a place outside your website. This way you know exactly how many calls have been made from those specific locations.",
                    )}
                  </p>
                  <OffsitesTable
                    offsites={values.offsites}
                    onAdd={() => setShowOffsite('new')}
                    onEdit={setShowOffsite}
                    addedOffsites={values.addedOffsites}
                    deletedOffsites={values.deletedOffsites}
                    setAddedOffsites={items => setFieldValue('addedOffsites', items)}
                    setDeletedOffsites={items => setFieldValue('deletedOffsites', items)}
                    intake={intake}
                  />
                </SetupWithSub>
              )}
            </Formik>
          );
        }}
      </Formik>
    </>
  );
}
