import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';

import Skeleton from 'components/Skeleton';
import Tooltip from 'components/Tooltip';
import { useFilters } from 'contexts/Filters';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';
import { useUserLanguage } from 'hooks/user';
import Area from './Area';
import Bar from './Bar';
import Donut from './Donut';
import Pie from './Pie';
import Statistics from './Statistics';
import { TickFormatter, WidgetProps } from './types';

export const PREVIOUS_PREFIX = 'prev_' as const;

const BLUE_1 = '#0142D3' as const;
const MAIN_PURPLE = '#B92896' as const;
const MAIN_BEIGE = '#DFC39F' as const;
const MAIN_GREEN = '#28B9AD' as const;
const MAIN_YELLOW = '#FFCD52' as const;

const BLUE_5 = '#A9C4FF' as const;
const LIGHT_PURPLE = '#EC98D8' as const;
const LIGHT_BEIGE = '#EDDBC3' as const;
const LIGHT_GREEN = '#8FDDD7' as const;
const LIGHT_YELLOW = '#FFE7AC' as const;

const BLUE_2 = '#004FFF' as const;
const BLUE_3 = '#3574FF' as const;
const BLUE_4 = '#739EFF' as const;

const BLUE_6 = '#CCDCFF' as const;
const BLUE_7 = '#E1EAFF' as const;
const BLUE_8 = '#043CA0' as const;

const PALETTE_PIE = [BLUE_1, BLUE_2, BLUE_3, BLUE_4, BLUE_5, BLUE_6, BLUE_7, BLUE_8] as const;
const PALETTE = [BLUE_1, MAIN_PURPLE, MAIN_BEIGE, MAIN_GREEN, MAIN_YELLOW] as const;
const PALETTE_PREV = [
  BLUE_5,
  BLUE_1,
  LIGHT_PURPLE,
  MAIN_PURPLE,
  LIGHT_BEIGE,
  MAIN_BEIGE,
  LIGHT_GREEN,
  MAIN_GREEN,
  LIGHT_YELLOW,
  MAIN_YELLOW,
] as const;

export default function Widget(props: WidgetProps) {
  const { fetchData } = useFetch();
  const translateText = useTranslate();
  const language = useUserLanguage();
  const { getAppliedFilters } = useFilters();

  const { queryParams, bodyParams } = props.requestParams ?? getAppliedFilters(props.filters ?? {});
  const { isPending, isFetching, data } = useQuery({
    queryKey: [...props.widgetKey, { ...bodyParams, ...queryParams }],
    queryFn: () =>
      fetchData(props.endpoint ?? '', {
        method: isEmpty(bodyParams) ? 'GET' : 'POST',
        bodyParams,
        queryParams,
      }),
    enabled:
      queryParams.domain !== null &&
      // This check is included to ensure that report data is not fetched when locations and sources have not been fetched yet
      (!props.filters?.locations || bodyParams.hasOwnProperty('aen')) &&
      (!props.filters?.sources || bodyParams.hasOwnProperty('source')),
    placeholderData: keepPreviousData,
  });

  const showLoading = !!props.isLoading || isPending || isFetching;
  const emptyLabel = props.emptyLabel ?? translateText('label', 'No data');
  const palette = data?.prevData ? PALETTE_PREV : PALETTE;

  const tickFormatter: TickFormatter = {
    format: value =>
      !isNaN(Number(value)) ? Number(value).toLocaleString(language) : String(value),
    x: true,
    y: value => {
      const number = Number(value);
      if (isNaN(number)) return String(value);
      return new Intl.NumberFormat('nl-NL', {
        compactDisplay: 'short',
        notation: 'compact',
      }).format(number);
    },
  };

  function getWidget() {
    if (showLoading) {
      if (props.type === 'donut' || props.type === 'pie') {
        return (
          <div className="loading-wrapper">
            <Skeleton width={152} height={152} isCircle />
          </div>
        );
      }
      return <Skeleton height={152} />;
    }

    switch (props.type) {
      case 'area':
        return (
          <Area
            {...props}
            palette={palette}
            data={data?.data ?? []}
            previousData={data?.prevData}
            tickFormatter={props.tickFormatter ?? tickFormatter}
            emptyLabel={emptyLabel}
          />
        );
      case 'bar':
        return (
          <Bar
            {...props}
            palette={palette}
            data={data?.data ?? []}
            previousData={data?.prevData}
            tickFormatter={props.tickFormatter ?? tickFormatter}
            emptyLabel={emptyLabel}
          />
        );
      case 'donut':
        return (
          <Donut
            {...props}
            data={data.data ?? 0}
            previousData={data?.prevData}
            emptyLabel={emptyLabel}
          />
        );
      case 'pie':
        return (
          <Pie
            {...props}
            data={data?.data ?? []}
            previousData={data?.prevData}
            palette={PALETTE_PIE}
            emptyLabel={emptyLabel}
          />
        );
    }
  }

  if (props.type === 'statistics') {
    return (
      <Statistics
        {...props}
        data={data?.data ?? []}
        previousData={data?.prevData}
        isLoading={showLoading}
        emptyLabel={emptyLabel}
      />
    );
  }

  return (
    <div className="white-block" data-col={props.gridColumns}>
      <div className="widget-container">
        <div className="top-row">
          {props.title && (
            <h3 className="title">
              {showLoading ? <Skeleton width={200} /> : props.title}
              {props.titleTooltip && !showLoading && <Tooltip text={props.titleTooltip} />}
            </h3>
          )}
        </div>
        {getWidget()}
      </div>
    </div>
  );
}
