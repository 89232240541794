import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import MultiEvaluate from 'components/history/MultiEvaluate';
import ReportsPage from 'components/ReportsPage';
import QueryTable from 'components/table/QueryTable';
import Widget from 'components/widget/Widget';
import { getDefaultFilters } from 'contexts/Filters';
import { useGlobal } from 'contexts/Global';
import { TYPE_FORM_TRACKING } from 'globals/constants';
import { CallRequest } from 'globals/types';
import { useIsFetchingReport } from 'hooks/fetching';
import { useUpdateCallRequestMarking } from 'hooks/queries/call-request';
import { useMultiUpdateEvaluations } from 'hooks/queries/evaluation';
import { useTranslate } from 'hooks/translate';

export const CALL_REQUEST_HISTORY_EXPAND =
  'calls,domain_id,number_of_tries,has_evaluation,evaluation,form_data,notes,status_message,aen_name,call_agent,is_sent_to_bing,is_sent_to_google,is_sent_to_doubleclick,marked' as const;

export default function History() {
  const translateText = useTranslate();
  const navigate = useNavigate();
  const { setShowReportsFilter } = useGlobal();
  const { updateMarking } = useUpdateCallRequestMarking();
  const { multiUpdateEvaluations } = useMultiUpdateEvaluations(TYPE_FORM_TRACKING);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  useEffect(() => {
    setShowReportsFilter(TYPE_FORM_TRACKING);
    return () => setShowReportsFilter(false);
  }, [setShowReportsFilter]);

  const queryKey = ['formTracking', 'history'];
  const isLoading = useIsFetchingReport(queryKey);

  const defaultFilters = getDefaultFilters(TYPE_FORM_TRACKING);

  function updateEvaluations(evaluation: string, value: string, close: () => void) {
    multiUpdateEvaluations('update', selectedRows, evaluation, value).then(() => {
      close();
      setSelectedRows([]);
    });
  }

  function deleteEvaluations(close: () => void) {
    multiUpdateEvaluations('delete', selectedRows).then(() => {
      close();
      setSelectedRows([]);
    });
  }

  function updateSelectedRows(id: number) {
    if (selectedRows.includes(id)) setSelectedRows(selectedRows.filter(i => i !== id));
    else setSelectedRows([...selectedRows, id]);
  }

  return (
    <ReportsPage
      queryKey={queryKey}
      title={translateText('label', 'Evaluations')}
      className="call-history-overview"
    >
      <Widget
        widgetKey={[...queryKey, 'evaluations']}
        endpoint="/widget/call-request-evaluations"
        type="statistics"
        filters={{ ...defaultFilters, previousPeriod: false }}
        isLoading={isLoading}
      />
      <h1>{translateText('label', 'Calls')}</h1>
      <QueryTable<
        CallRequest & { 'evaluation.evaluation': undefined; 'evaluation.value': undefined }
      >
        tableKey={[...queryKey, 'index']}
        columns={{
          'status_message': {
            header: { name: translateText('label', 'Status'), sort: true },
            defaultSelected: true,
          },
          'number_of_tries': {
            header: translateText('label', 'Number of tries'),
            defaultSelected: true,
          },
          'requested_at': {
            header: { name: translateText('label', 'Date'), sort: true },
            defaultSelected: true,
            date: { includeSeconds: true, connector: 'none' },
          },
          'country_code': { header: translateText('label', 'Country code'), noNumberFormat: true },
          'phone_number_raw': {
            header: translateText('label', 'Phone number input'),
            noNumberFormat: true,
          },
          'phone_number': {
            header: { name: translateText('label', 'Phone number'), sort: true },
            defaultSelected: true,
            noNumberFormat: true,
          },
          'call_agent': {
            header: translateText('label', 'Profile'),
            defaultSelected: true,
          },
          'evaluation.evaluation': {
            header: { name: translateText('label', 'Evaluation'), sort: true },
            defaultSelected: true,
            customValue: (value, row) => row?.evaluation?.evaluation ?? '-',
          },
          'evaluation.value': {
            header: { name: translateText('label', 'Value'), sort: true },
            defaultSelected: true,
            customValue: (value, row) => row?.evaluation?.value ?? '-',
          },
          'id': {
            header: { name: translateText('label', 'ID'), sort: true },
            defaultSelected: true,
          },
          'is_sent_to_google': { header: translateText('label', 'Status Google Ads') },
          'is_sent_to_doubleclick': { header: translateText('label', 'Status Double Click CM') },
          'is_sent_to_bing': { header: translateText('label', 'Status Microsoft Advertising') },
          'marked': {
            header: translateText('label', 'Marked'),
            defaultSelected: true,
            className: 'marked',
            customValue: (value, row) => (
              <button
                className={'star-icon' + (value ? ' marked' : '')}
                onClick={e => {
                  e.stopPropagation();
                  updateMarking({ id: row.id, marking: !value });
                }}
              />
            ),
          },
        }}
        endpoint="/call-request/index"
        pagination
        canSearch
        refresh={['callRequest']}
        onRowClick={row => navigate('/form-tracking/history/' + row.id)}
        isResponsive
        filters={{
          ...defaultFilters,
          previousPeriod: false,
          custom: { expand: CALL_REQUEST_HISTORY_EXPAND, sort: '-requested_at' },
        }}
        canChooseColumns
        download="csv"
        isLoading={isLoading}
        extraTopLeft={
          <MultiEvaluate
            onSave={updateEvaluations}
            onDelete={deleteEvaluations}
            isLoadingTable={isLoading}
          />
        }
        multiSelect={{ selected: selectedRows, update: id => updateSelectedRows(id as number) }}
      />
    </ReportsPage>
  );
}
