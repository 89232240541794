import { useParams } from 'react-router';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Wysiwyg from 'components/admin/Wysiwyg';
import ErrorFocus from 'components/ErrorFocus';
import Select from 'components/input/Select';
import SelectFromList from 'components/input/SelectFromList';
import TextField from 'components/input/TextField';
import AttachmentSection from 'components/knowledge-center/AttachmentSection';
import SlugSection from 'components/knowledge-center/SlugSection';
import Loader from 'components/Loader';
import {
  ARTICLE_STATUS_ARCHIVED,
  ARTICLE_STATUS_CONCEPT,
  ARTICLE_STATUS_LOGGED_IN,
  ARTICLE_STATUS_PUBLIC,
  LANGUAGE_DUTCH,
  LANGUAGE_ENGLISH_US,
} from 'globals/constants';
import { useCategoryTypeTranslations } from 'hooks/knowledge-center';
import {
  useArticle,
  useArticles,
  useCategories,
  useCreateArticle,
  useUpdateArticle,
  useUpdateCategories,
  useUpdateRelatedArticles,
} from 'hooks/queries/support';
import { useTranslate } from 'hooks/translate';

type Props = {
  type: 'new' | 'edit';
};
export default function ArticleEdit({ type }: Props) {
  const translateText = useTranslate();
  const categoryTypeTranslations = useCategoryTypeTranslations();
  const { createArticle } = useCreateArticle();
  const { updateArticle } = useUpdateArticle();
  const { articles, isLoading: isLoadingArticles } = useArticles();
  const { categories, isLoading: isLoadingCategories } = useCategories();
  const { updateCategories } = useUpdateCategories();
  const { updateRelatedArticles } = useUpdateRelatedArticles();

  const params = useParams<{ article: string }>();
  const { article, isLoading, isError } = useArticle(type === 'edit' ? params.article : null);

  if (isError) return <p>{translateText('label', 'This article does not exist')}</p>;
  if (isLoading || ((isLoadingCategories || isLoadingArticles) && type !== 'new')) {
    return <Loader />;
  }

  async function save(values: {
    title: string;
    image: string;
    language: string;
    status: string;
    content: string;
    summary: string;
  }) {
    type === 'new'
      ? await createArticle(values)
      : await updateArticle({ id: article!.id, ...values });
  }

  const selectableCategories =
    article?.category_type !== null
      ? categories?.filter(c => c.type === article?.category_type)
      : categories?.map(c => ({
          id: c.id,
          name: c.name + ' / ' + categoryTypeTranslations[c.type],
        }));

  const validationSchema = Yup.object({
    categories: Yup.array().test({
      message: translateText(
        'message',
        'An article can only be assigned to categories that have the same category type.',
      ),
      test: value => {
        const selectedCategories = categories?.filter(c => value?.includes(String(c.id)));
        return !selectedCategories?.some(c => c.type !== selectedCategories[0].type);
      },
    }),
  });

  return (
    <div className="article-wrapper knowledge-center-edit">
      <h1>
        {type === 'new'
          ? translateText('label', 'Add article')
          : translateText('label', 'Edit article') + ' (' + article?.id + ')'}
      </h1>
      <Formik
        initialValues={{
          title: article?.title ?? '',
          image: article?.image ?? '',
          language: LANGUAGE_DUTCH,
          status: article?.status ?? String(ARTICLE_STATUS_CONCEPT),
          content: article?.content ?? '',
          summary: article?.summary ?? '',
        }}
        onSubmit={save}
      >
        {({ values, handleChange, setFieldValue, submitForm, dirty, isSubmitting }) => (
          <>
            <div className="double-row">
              <div className="form-row">
                <div className="name-label">{translateText('label', 'Language')}</div>
                <div className="value-wrapper">
                  <Select
                    name="language"
                    value={values.language}
                    onChange={handleChange}
                    disabled={type === 'new'}
                    options={{
                      [LANGUAGE_DUTCH]: translateText('label', 'Dutch'),
                      [LANGUAGE_ENGLISH_US]: translateText('label', 'English'),
                    }}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="name-label">{translateText('label', 'Status')}</div>
                <div className="value-wrapper">
                  <Select
                    name="status"
                    value={values.status}
                    onChange={handleChange}
                    options={{
                      [ARTICLE_STATUS_ARCHIVED]: translateText('label', 'Archived'),
                      [ARTICLE_STATUS_CONCEPT]: translateText('label', 'Concept'),
                      [ARTICLE_STATUS_LOGGED_IN]: translateText('label', 'Logged in'),
                      [ARTICLE_STATUS_PUBLIC]: translateText('label', 'Public'),
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="name-label">{translateText('label', 'Title')}</div>
              <div className="value-wrapper">
                <TextField
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  placeholder={translateText('label', 'Title')}
                />
              </div>
            </div>
            <div className="form-row margin-top">
              <div className="name-label">{translateText('label', 'Content')}</div>
              <div className="value-wrapper">
                <Wysiwyg
                  value={values.content}
                  onChange={value => setFieldValue('content', value)}
                  previewClassName="article-content"
                />
              </div>
            </div>
            <div className="form-row margin-top">
              <div className="name-label">{translateText('label', 'Summary')}</div>
              <div className="value-wrapper">
                <Wysiwyg
                  value={values.summary}
                  onChange={value => setFieldValue('summary', value)}
                />
              </div>
            </div>
            <div className="form-row margin-top">
              <div className="name-label">{translateText('label', 'Image')}</div>
              <div className="value-wrapper">
                <TextField
                  name="image"
                  value={values.image}
                  onChange={handleChange}
                  placeholder={translateText('label', 'Image')}
                />
              </div>
            </div>
            {values.image && (
              <div className="form-row margin-top">
                <div className="name-label">{translateText('label', 'Preview')}</div>
                <div className="value-wrapper">
                  <img src={values.image} className="preview" alt="preview" />
                </div>
              </div>
            )}
            <button
              className="btn btn-green margin-top"
              disabled={isSubmitting || !dirty}
              onClick={submitForm}
            >
              {translateText('label', 'Save')}
            </button>
          </>
        )}
      </Formik>
      {article && (
        <>
          <AttachmentSection
            articleId={article.id}
            attachments={article.attachments}
            article={params.article!}
          />
          <Formik
            initialValues={{ categories: article.categories.map(c => String(c)) }}
            onSubmit={values =>
              updateCategories({
                categories: values.categories,
                articleId: article.id,
                article: params.article!,
              })
            }
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ values, setFieldValue, submitForm, isSubmitting, dirty, errors }) => (
              <>
                <ErrorFocus />
                <h1>{translateText('label', 'Add to categories')}</h1>
                <SelectFromList
                  id="categories-list"
                  items={selectableCategories ?? []}
                  selected={values.categories}
                  onChange={items => setFieldValue('categories', items)}
                  error={errors.categories as string | undefined}
                />
                <button
                  className="btn btn-green margin-top"
                  disabled={isSubmitting || !dirty}
                  onClick={submitForm}
                >
                  {translateText('label', 'Save')}
                </button>
              </>
            )}
          </Formik>
          <Formik
            initialValues={{
              related: article.related_articles.map(a => String(a.related_article_id)),
            }}
            onSubmit={values =>
              updateRelatedArticles({
                related: values.related,
                articleId: article.id,
                article: params.article!,
              })
            }
            enableReinitialize
          >
            {({ values, setFieldValue, submitForm, isSubmitting, dirty }) => (
              <>
                <h1>{translateText('label', 'Add related articles')}</h1>
                <SelectFromList
                  id="related-articles-list"
                  items={articles ?? []}
                  selected={values.related}
                  onChange={items => setFieldValue('related', items)}
                  nameKey="title"
                  canChangeOrder
                />
                <button
                  className="btn btn-green margin-top"
                  disabled={isSubmitting || !dirty}
                  onClick={submitForm}
                >
                  {translateText('label', 'Save')}
                </button>
              </>
            )}
          </Formik>
          <SlugSection
            slugs={article.slugs}
            type="article"
            target={params.article!}
            targetId={article.id}
          />
        </>
      )}
    </div>
  );
}
