import { useMemo } from 'react';
import { omit } from 'lodash';

import Skeleton, { SkeletonProps } from './Skeleton';

type Props = {
  minWidth?: number; // Should be a percentage between 0-100, default is 80%
} & Omit<SkeletonProps, 'width'>;

export default function RandomWidthSkeleton(props: Props) {
  const minWidth = props.minWidth ?? 80;
  const widths = useMemo(
    () => [...Array(props.count ?? 1)].map(() => Math.random() * (100 - minWidth) + minWidth),
    [minWidth, props.count],
  );

  return (
    <>
      {[...Array(props.count ?? 1)].map((item, index) => (
        <Skeleton key={index} {...omit(props, 'count')} width={widths[index] + '%'} />
      ))}
    </>
  );
}
