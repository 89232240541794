import MessageCard from 'components/overview/MessageCard';
import Tooltip from 'components/Tooltip';
import { SUP_ART_URL } from 'globals/support';
import { useUpdatesAndRecommendations } from 'hooks/queries/user';
import { useTranslate } from 'hooks/translate';

const EMPTY_MESSAGE = {
  id: null,
  title: '',
  summary: '',
  link: '',
  is_critical: false,
} as const;

type Props = {
  isLoading: boolean;
};

export type Message = {
  id: number | null;
  title: string;
  summary: string;
  link: string | null;
  is_critical?: boolean;
};

export default function UpdatesAndRecommendations({ isLoading }: Props) {
  const translateText = useTranslate();
  const { data, isLoading: isLoadingUpdates } = useUpdatesAndRecommendations();
  isLoading = isLoadingUpdates || isLoading;

  return (
    <div className="updates-and-recommendation-wrapper">
      {(data?.recommendation?.length > 0 || isLoading) && (
        <div className="message">
          <div className="title-wrapper">
            <h1>
              {translateText('label', 'Recommendation')}
              <Tooltip
                text={translateText(
                  'tooltip/overview',
                  'Here are our recommendations based on the notifications. Click on a recommendation to navigate to the notification detail page.',
                )}
              />
            </h1>
          </div>
          {(isLoading ? [EMPTY_MESSAGE] : (data.recommendation as Message[])).map(
            (recommendation, index) => (
              <MessageCard
                key={index}
                title={recommendation.title}
                summary={recommendation.summary}
                link={
                  recommendation.id === null ? null : '/account/notifications/' + recommendation.id
                }
                isLoading={isLoading}
                isCritical={recommendation.is_critical}
                isPrivate
              />
            ),
          )}
        </div>
      )}
      {(data?.updates?.length > 0 || isLoading) &&
        (isLoading ? [EMPTY_MESSAGE, EMPTY_MESSAGE] : (data.updates as Message[])).map(
          (update, index) => (
            <div key={index} className="message">
              <div className="title-wrapper">
                {index === 0 && (
                  <h1>
                    {translateText('label', 'Updates')}
                    <Tooltip
                      text={translateText(
                        'tooltip/overview',
                        "Find the most newsworthy articles from our knowledge center here. Click on an update to navigate to an article's detail page.",
                      )}
                    />
                  </h1>
                )}
              </div>
              <MessageCard
                key={index}
                title={update.title}
                summary={update.summary}
                link={update.id === null ? null : SUP_ART_URL + update.id}
                isLoading={isLoading}
                isCritical={update.is_critical}
              />
            </div>
          ),
        )}
    </div>
  );
}
