import { Formik } from 'formik';
import * as Yup from 'yup';

import icon from 'assets/images/icons/integrations/bigquery.svg';
import ErrorFocus from 'components/ErrorFocus';
import TextField from 'components/input/TextField';
import Toggle from 'components/input/Toggle';
import CostParagraph from 'components/integrations/CostParagraph';
import { REDIRECT_URI_BIGQUERY } from 'components/oauth2/Verification';
import VerificationButton from 'components/oauth2/VerificationButton';
import Skeleton from 'components/Skeleton';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import SupportParagraph from 'components/SupportParagraph';
import { useTenant } from 'contexts/Tenant';
import { INTEGRATION_BIGQUERY, PLAN_ENTERPRISE } from 'globals/constants';
import { SUP_ART_BIG_QUERY } from 'globals/support';
import { useHasAccessToIntegration } from 'hooks/access';
import { useSetQueryData } from 'hooks/data';
import { useBigQuery } from 'hooks/queries/integration';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation } from 'hooks/validation';

export type BigQueryData = {
  enabled: boolean;
  projectId: string;
  verified: boolean;
};

export default function BigQuery({ show, close }: BaseSlideInProps) {
  const translateText = useTranslate();
  const hasAccessToIntegration = useHasAccessToIntegration();
  const setQueryData = useSetQueryData();
  const { selectedDomain } = useTenant();
  const { required } = useCustomValidation();

  const { data, isLoading, updateBigQuery } = useBigQuery(show);

  if (!hasAccessToIntegration(INTEGRATION_BIGQUERY)) return null;

  // Form data is saved in local storage when the account gets verified, so the form is
  // still filled after redirect
  const localStorageData = localStorage.getItem('bigQuery');
  const savedValues = localStorageData ? (JSON.parse(localStorageData) as BigQueryData) : null;

  async function save(values: BigQueryData) {
    await updateBigQuery(values).then(() => close());
  }

  const validationSchema = Yup.object({
    projectId: required,
  });

  const initialValues: BigQueryData = {
    enabled: savedValues?.enabled ?? !!data?.enabled ?? false,
    verified: data?.verified ?? false,
    projectId: savedValues?.projectId ?? data?.projectId ?? '',
  };

  const title = translateText('label', 'BigQuery');

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={save}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({
        values,
        handleChange,
        submitForm,
        resetForm,
        errors,
        isSubmitting,
        submitCount,
        dirty,
      }) => (
        <Setup
          show={show}
          close={close}
          afterClose={() => {
            resetForm();
            if (savedValues) localStorage.removeItem('bigQuery');
          }}
          icon={icon}
          title={title}
          hasChanges={dirty || !!savedValues}
          save={submitForm}
          isSaving={isSubmitting}
          maxWidth={500}
        >
          <ErrorFocus element={document.getElementsByClassName('setup-wrapper')[0]} />
          <p>
            {isLoading ? (
              <Skeleton count={5} />
            ) : (
              translateText(
                'integration',
                'With this integration you can add AdCalls as a data source in BigQuery. This allows you to easily report, analyze and combine the data.',
              )
            )}
          </p>
          <CostParagraph price={null} plan={PLAN_ENTERPRISE} isLoading={isLoading} />
          <SupportParagraph articleId={SUP_ART_BIG_QUERY} isLoading={isLoading} />
          <h3 className="section-title">
            {isLoading ? <Skeleton width={150} /> : translateText('label', 'Connect account')}
          </h3>
          <InputWrapper
            label={translateText('label', 'Project ID')}
            tooltip={translateText(
              'tooltip/bigquery',
              'This is the ID that belongs to the project in BigQuery.',
            )}
            isLoading={isLoading}
          >
            {isLoading ? (
              <Skeleton width={300} height={38} />
            ) : (
              <TextField
                name="projectId"
                value={values.projectId}
                onChange={handleChange}
                disabled={isSubmitting}
                error={submitCount > 0 && errors.projectId}
              />
            )}
          </InputWrapper>
          <VerificationButton
            verified={values.verified}
            disconnectEndpoint="/integration/big-query/disconnect"
            onDisconnectSuccess={() =>
              setQueryData<BigQueryData>(['bigquery', selectedDomain], oldData => ({
                ...oldData,
                verified: false,
              }))
            }
            integrationName={title}
            redirectUri="/integration/big-query/redirect-uri"
            redirectUriKey={REDIRECT_URI_BIGQUERY}
            queryParams={{ type: 'call-tracking' }}
            beforeRedirect={() => dirty && localStorage.setItem('bigQuery', JSON.stringify(values))}
            isGoogle
            isLoading={isLoading}
          />
          <InputWrapper
            label={translateText('label', 'Send data')}
            tooltip={translateText(
              'tooltip/bigquery',
              'With this setting you can enable or disable sending data for this domain.',
              { integration: title },
            )}
            isLoading={isLoading}
          >
            <Toggle
              name="enabled"
              checked={values.enabled}
              onClick={handleChange}
              disabled={isSubmitting || !values.verified}
              isLoading={isLoading}
            />
          </InputWrapper>
        </Setup>
      )}
    </Formik>
  );
}
