import { useMutation, useQuery } from '@tanstack/react-query';

import { useMessages } from 'contexts/Messages';
import { useSetQueryData } from 'hooks/data';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';

type Debtor = {
  id: string;
  company: string;
};

export type DebtorFormValues = {
  company: string;
  firstName: string;
  lastName: string;
  address: string;
  postalCode: string;
  city: string;
  email: string;
  automaticDebit: boolean;
  vat: string;
  country: string;
  discount: string;
  expirationDays: string;
  oneInvoice: boolean;
};

export function useChargebeeCustomers() {
  const { fetchData } = useFetch();

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ['customers'],
    queryFn: (): Promise<Debtor[]> => fetchData('/debtor/customers'),
  });

  return { customers: data, isLoading };
}

export function useCreateDebtor() {
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { addSuccessMessage } = useMessages();
  const { fetchData } = useFetch();

  const { mutateAsync } = useMutation({
    mutationFn: (values: DebtorFormValues) =>
      fetchData('/debtor/create-with-chargebee', {
        method: 'POST',
        bodyParams: {
          customer: {
            company_name: values.company,
            firstname: values.firstName,
            lastname: values.lastName,
            email: values.email,
            country: values.country,
            vat_number: values.vat,
            address: values.address,
            zipcode: values.postalCode,
            city: values.city,
            invoice_discount: values.discount,
            expiration_days: values.expirationDays,
            invoice_multidomain: values.oneInvoice,
            automatic_collection: values.automaticDebit,
          },
        },
      }),
    onSuccess: (data, variables) => {
      setQueryData<Debtor[]>(['customers'], oldData => [
        ...oldData,
        { id: data.chargebee_customer_id, company: variables.company },
      ]);
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { createDebtor: mutateAsync };
}
