import { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import AddVoipProviderNumbers from 'components/admin/AddVoipProviderNumbers';
import ErrorFocus from 'components/ErrorFocus';
import Checkbox from 'components/input/Checkbox';
import Select from 'components/input/Select';
import TextField from 'components/input/TextField';
import {
  SIP_TRUNK_DSTNY_PBX_AD_CALLS,
  SIP_TRUNK_DSTNY_PBX_ELEVATE,
  SIP_TRUNK_DSTNY_PBX_LIVE,
  SIP_TRUNK_DSTNY_PBX_TEST,
  SIP_TRUNK_DSTNY_PROBLEMEN_KWIJT,
  SIP_TRUNK_DSTNY_VPS_LIVE,
  SIP_TRUNK_DSTNY_VPS_LIVE_2,
  SIP_TRUNK_DSTNY_VPS_TEST,
  SIP_TRUNK_STEAM_CONNECT_LIVE,
  SIP_TRUNK_STEAM_CONNECT_PROD,
  SIP_TRUNK_STEAM_CONNECT_TEST,
  SIP_TRUNK_VOXBONE,
} from 'globals/constants';
import { useCreateRange } from 'hooks/queries/number';
import { useUserDomains } from 'hooks/queries/user';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation } from 'hooks/validation';

// This function calculates the count of numbers in a range
// For example for 1, 2, 7-14, 20 the count would be 11
function calculateRange(value: string) {
  if (!value) return 0;

  const numbers = value.replace(/ /g, '').split(',');
  let count = 0;
  for (const number of numbers) {
    if (number.includes('-')) {
      const [rangeStart, rangeEnd] = number.split('-');
      if (Number(rangeStart) < Number(rangeEnd)) {
        count += Number(rangeEnd) - Number(rangeStart) + 1; // add 1 for the first (or last) number in the range
      } else {
        return 'Invalid';
      }
    } else if (number) {
      count++;
    } else {
      return 'Invalid';
    }
  }

  return count;
}

type FormValues = {
  numbers: string;
  sipTrunk: string;
  specificDomain: boolean;
  domain: string;
};

export default function New() {
  const translateText = useTranslate();
  const { required, requiredSelect } = useCustomValidation();
  const { domains } = useUserDomains();
  const { createRange } = useCreateRange();

  const [lastAdded, setLastAdded] = useState('');

  async function saveRange(values: FormValues) {
    await createRange({
      numbers: values.numbers,
      sip_trunk: values.sipTrunk,
      ...(values.specificDomain && { domain_id: values.domain }),
    }).then(() => setLastAdded(values.numbers));
  }

  const validationSchema = Yup.object({
    numbers: required,
    sipTrunk: requiredSelect,
    domain: Yup.string().test({
      test: function (value) {
        if (!this.parent.specificDomain) return true;
        return !!value && value !== 'select';
      },
      message: translateText('message', 'This field is required.'),
    }),
  });

  return (
    <>
      <h1>{translateText('label', 'New phone numbers')}</h1>
      <Formik
        initialValues={{ numbers: '', sipTrunk: 'select', specificDomain: false, domain: 'select' }}
        onSubmit={values => saveRange(values)}
        validationSchema={validationSchema}
      >
        {({ values, handleChange, submitForm, isSubmitting, dirty, submitCount, errors }) => (
          <div className="white-block">
            <ErrorFocus />
            <div className="form-row">
              <div className="name-label">{translateText('label', 'Phone numbers')}</div>
              <div className="value-wrapper">
                <TextField
                  name="numbers"
                  placeholder="10 - 19, 25"
                  value={values.numbers}
                  onChange={handleChange}
                  error={submitCount > 0 && errors.numbers}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="name-label">{translateText('label', 'SIP trunk')}</div>
              <div className="value-wrapper">
                <Select
                  name="sipTrunk"
                  value={values.sipTrunk}
                  onChange={handleChange}
                  options={{
                    [SIP_TRUNK_DSTNY_PBX_LIVE]: 'Dstny Live (PBX)',
                    [SIP_TRUNK_DSTNY_PBX_TEST]: 'Dstny Test (PBX)',
                    [SIP_TRUNK_DSTNY_PBX_AD_CALLS]: 'Dstny AdCalls (PBX)',
                    [SIP_TRUNK_DSTNY_PBX_ELEVATE]: 'Dstny Elevate (PBX)',
                    [SIP_TRUNK_DSTNY_VPS_LIVE]: 'Dstny Live (VPS)',
                    [SIP_TRUNK_DSTNY_VPS_LIVE_2]: 'Dstny Live 2 (VPS)',
                    [SIP_TRUNK_DSTNY_PROBLEMEN_KWIJT]: 'Dstny Problemen Kwijt',
                    [SIP_TRUNK_DSTNY_VPS_TEST]: 'Dstny Test (VPS)',
                    [SIP_TRUNK_VOXBONE]: 'Voxbone',
                    [SIP_TRUNK_STEAM_CONNECT_PROD]: 'Steam-connect Prod',
                    [SIP_TRUNK_STEAM_CONNECT_LIVE]: 'Steam-connect Live (outbound)',
                    [SIP_TRUNK_STEAM_CONNECT_TEST]: 'Steam-connect Test',
                  }}
                  error={submitCount > 0 && errors.sipTrunk}
                  addSelect
                />
              </div>
            </div>
            <div className="form-row">
              <Checkbox
                id="specificDomain"
                checked={values.specificDomain}
                onChange={handleChange}
                label={translateText('label', 'Add for a specific domain')}
                labelClassName="extra-margin"
              />
            </div>
            {values.specificDomain && (
              <div className="form-row">
                <div className="name-label">{translateText('label', 'Domain')}</div>
                <div className="value-wrapper">
                  <Select
                    name="domain"
                    value={values.domain}
                    onChange={handleChange}
                    options={domains ?? []}
                    optionText="title"
                    optionValue="id"
                    error={submitCount > 0 && errors.domain}
                    disabled={isSubmitting}
                    addSelect
                  />
                </div>
              </div>
            )}
            <div className="gap-top">
              <div>
                {translateText('admin/text', 'Range: {amount} numbers', {
                  amount: calculateRange(values.numbers),
                })}
              </div>
              {lastAdded && (
                <div>
                  {`${translateText('label', 'Last added')}: ${lastAdded} (${translateText(
                    'label',
                    'Range',
                  )}: ${calculateRange(lastAdded)})`}
                </div>
              )}
            </div>
            <div className="form-button">
              <button
                className="btn btn-green"
                onClick={submitForm}
                disabled={isSubmitting || !dirty}
              >
                {translateText('label', 'Save')}
              </button>
            </div>
          </div>
        )}
      </Formik>
      <h1>{translateText('label', 'Add numbers')}</h1>
      <div className="white-block">
        <AddVoipProviderNumbers
          providerName="Voxbone"
          providerKey="voxbone"
          dataKey="dids"
          saveParam="numbers"
          columns={{
            e164: { header: translateText('label', 'Number'), noNumberFormat: true },
            countryCodeA3: { header: translateText('label', 'Country') },
            areaCode: { header: translateText('label', 'Area code'), noNumberFormat: true },
            cityName: { header: translateText('label', 'City') },
            type: { header: translateText('label', 'Type') },
            orderReference: { header: translateText('label', 'Order reference') },
          }}
        />
        <AddVoipProviderNumbers providerName="Steam Connect" providerKey="steam-connect" disabled />
        <AddVoipProviderNumbers providerName="Dstny" providerKey="dstny" disabled />
      </div>
    </>
  );
}
