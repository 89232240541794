import acceptIcon from 'assets/images/icons/accept.svg';
import declineIcon from 'assets/images/icons/delete-purple.svg';
import QueryTable from 'components/table/QueryTable';
import Tooltip from 'components/Tooltip';
import { useAcceptInvitation, useDeclineInvitation } from 'hooks/queries/invitation';
import { useTranslate } from 'hooks/translate';

type Invitation = {
  id: number;
  name: string;
  type: string;
  status: string;
  status_code: number;
  invited_by: string;
  invited_on: string;
  role: string;
};

export default function Invitations() {
  const translateText = useTranslate();
  const { acceptInvitation, isLoading: isAccepting } = useAcceptInvitation();
  const { declineInvitation, isLoading: isDeclining } = useDeclineInvitation();

  function getButton(type: 'accept' | 'decline', invitation: Invitation) {
    const label =
      type === 'decline' ? translateText('label', 'Decline') : translateText('label', 'Accept');
    return (
      <Tooltip
        text={label}
        html={
          <img
            alt={label}
            src={type === 'decline' ? declineIcon : acceptIcon}
            className="clickable-icon"
            onClick={e => {
              e.stopPropagation();
              if (isAccepting || isDeclining) return;
              if (type === 'decline') declineInvitation(invitation.id);
              else acceptInvitation(invitation.id);
            }}
          />
        }
      />
    );
  }

  return (
    <>
      <h1>{translateText('label', 'Invitations')}</h1>
      <QueryTable<Invitation & { approve: undefined; decline: undefined }>
        tableKey={['invitations']}
        columns={{
          name: { header: translateText('label', 'Name'), private: true },
          type: { header: translateText('label', 'Type') },
          role: { header: translateText('label', 'Rights') },
          invited_by: { header: translateText('label', 'Invited by'), private: true },
          invited_on: { header: translateText('label', 'Date') },
          status: { header: translateText('label', 'Status') },
          approve: {
            header: '',
            viewCondition: row => row.status_code === 10,
            customValue: (value, row) => getButton('accept', row),
          },
          decline: {
            header: '',
            viewCondition: row => row.status_code === 10,
            customValue: (value, row) => getButton('decline', row),
          },
        }}
        pagination
        endpoint="/invitation/index"
        filters={{ domain: false }}
        refresh
      />
    </>
  );
}
