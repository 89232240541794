import { useParams } from 'react-router';
import moment from 'moment';

import BlacklistForm from 'components/admin/BlacklistForm';
import Loader from 'components/Loader';
import {
  useBlacklistedNumber,
  useDeleteBlacklistedNumber,
  useUpdateBlacklistedNumber,
} from 'hooks/queries/blacklisted-number';
import { useTranslate } from 'hooks/translate';
import { useUserDateFormat } from 'hooks/user';

export default function BlacklistDetails() {
  const translateText = useTranslate();
  const userDateFormat = useUserDateFormat();
  const params = useParams();
  const entryId = Number(params.id);
  const { updateBlacklistedNumber } = useUpdateBlacklistedNumber();
  const { deleteBlacklistedNumber, isDeleting } = useDeleteBlacklistedNumber();
  const { data, isLoading } = useBlacklistedNumber(!isNaN(entryId) ? entryId : null);

  async function save(values: { number: string; date: string }) {
    await updateBlacklistedNumber({ id: entryId, ...values });
  }

  function remove(id: number) {
    if (
      window.confirm(
        translateText(
          'admin/text',
          'Are you sure you want to remove this number from the blacklist?',
        ),
      )
    ) {
      deleteBlacklistedNumber(id);
    }
  }
  return (
    <>
      <h1>{translateText('label', 'Update blacklisted phone number')}</h1>
      <div className="white-block">
        {isLoading ? (
          <Loader />
        ) : (
          <BlacklistForm
            disabled={isDeleting}
            type="edit"
            delete={() => remove(entryId)}
            number={data?.number}
            date={moment(data?.blacklisted_till, 'YYYY-MM-DD HH:mm:ss').format(userDateFormat)}
            onSave={values => save(values)}
          />
        )}
      </div>
    </>
  );
}
