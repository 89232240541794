import { useState } from 'react';
import { useNavigate } from 'react-router';

import ChangeAccountManager from 'components/admin/ChangeAccountManager';
import QueryTable from 'components/table/QueryTable';
import {
  DOMAIN_STATUS_ACTIVE,
  DOMAIN_STATUS_BEFORE_TRIAL,
  DOMAIN_STATUS_INACTIVE,
  DOMAIN_STATUS_INACTIVE_NOT_SIGNED,
  DOMAIN_STATUS_REQUESTED,
  DOMAIN_STATUS_REQUESTED_NOT_SIGNED,
  DOMAIN_STATUS_STOPPED,
} from 'globals/constants';
import { useAccountManagers } from 'hooks/queries/user';
import { useTranslate } from 'hooks/translate';

export type AdminDomain = {
  id: number;
  url: string;
  title: string;
  is_active: boolean;
  status: number;
  status_string: string;
  is_callrecording_enabled: boolean;
  is_leadrating_enabled: boolean;
  created_on: string;
  start_date: string;
  reseller: string;
  number_distribution: { label: string; enabled: boolean };
  chargebee_customer_id: string | null;
  chargebee_subscription_id: string | null;
  is_trial: boolean;
  trial_end_date: string | null;
  reseller_id: number;
  account_manager: { id: number; name: string; email: string; phone: string };
  trial_month: string | false;
  intake_invoicing_details: {
    company: string;
    name: string;
    address: string;
    postal_code: string;
    city: string;
    email: string;
    country: string;
    automatic_collection: 0 | 1;
    vat_number: string | null;
  } | null;
};

export const ADMIN_DOMAIN_EXPAND =
  'reseller_id,account_manager,trial_month,chargebee_customer_id,intake_invoicing_details,trial_end_date,is_trial,chargebee_subscription_id' as const;

export default function Overview() {
  const navigate = useNavigate();
  const translateText = useTranslate();
  const { isLoading } = useAccountManagers();
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  function updateSelectedRows(value: number | number[]) {
    if (typeof value !== 'number') return setSelectedRows(value);
    if (selectedRows.includes(value)) setSelectedRows(selectedRows.filter(i => i !== value));
    else setSelectedRows([...selectedRows, value]);
  }

  return (
    <>
      <h1>{translateText('label', 'Domains')}</h1>
      <QueryTable<AdminDomain>
        tableKey={['domains', 'admin']}
        className="wrap"
        columns={{
          id: { header: { name: translateText('label', 'ID'), sort: true }, search: true },
          url: { header: { name: translateText('label', 'URL'), sort: true }, search: true },
          title: { header: { name: translateText('label', 'Title'), sort: true }, search: true },
          status_string: {
            header: { name: translateText('label', 'Status'), sort: true },
            search: {
              [DOMAIN_STATUS_STOPPED]: translateText('label', 'Stopped'),
              [DOMAIN_STATUS_INACTIVE]: translateText('label', 'Inactive'),
              [DOMAIN_STATUS_REQUESTED_NOT_SIGNED]: translateText(
                'label',
                'Requested: Missing agreement',
              ),
              [DOMAIN_STATUS_REQUESTED]: translateText('label', 'Requested'),
              [DOMAIN_STATUS_INACTIVE_NOT_SIGNED]: translateText(
                'label',
                'Inactive: Missing agreement',
              ),
              [DOMAIN_STATUS_BEFORE_TRIAL]: translateText('label', 'Before trial'),
              [DOMAIN_STATUS_ACTIVE]: translateText('label', 'Active'),
            },
          },
          reseller: {
            header: { name: translateText('label', 'Partner'), sort: true },
            search: true,
          },
          account_manager: {
            header: translateText('label', 'Account manager'),
            search: true,
            customValue: value => value.name,
          },
          trial_month: {
            header: { name: translateText('label', 'Trial month'), sort: true },
            search: true,
          },
        }}
        onRowClick={domain => navigate('/admin/domains/' + domain.id)}
        pagination={{ defaultLimit: 20 }}
        endpoint="/domain/index"
        filters={{ domain: false, custom: { expand: ADMIN_DOMAIN_EXPAND } }}
        refresh
        multiSelect={{
          selected: selectedRows,
          update: id => updateSelectedRows(id as number),
          canSelectAll: true,
        }}
        download={{ csv: true, filters: { admin: '1' } }}
        isLoading={isLoading}
        extraTopLeft={
          <ChangeAccountManager
            type="domain"
            selectedRows={selectedRows}
            onSuccess={() => setSelectedRows([])}
          />
        }
      />
    </>
  );
}
