import { useQuery } from '@tanstack/react-query';

import { useHasIntlPlan } from 'hooks/access';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';

type Country = {
  code: number;
  name: string;
};

export function useCountries(checkPricingplan = true) {
  const translateText = useTranslate();
  const isInternational = useHasIntlPlan();
  const { fetchData } = useFetch();

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ['countries', isInternational],
    queryFn: (): Promise<Country[]> => fetchData('/country/index'),
    initialData: () =>
      checkPricingplan && !isInternational
        ? [{ code: 31, name: translateText('label', 'Netherlands') }]
        : undefined,
    enabled: !checkPricingplan || isInternational,
    meta: { persist: true },
  });

  return { countries: data, isLoading };
}
