import { useNavigate } from 'react-router';
import moment from 'moment';

import AutoGrid from 'components/AutoGrid';
import DetailsSection from 'components/DetailsSection';
import NumberDistributionSection from 'components/settings/properties/NumberDistributionSection';
import NumberFormatSection from 'components/settings/properties/NumberFormatSection';
import StopDomainSection from 'components/settings/properties/StopDomainSection';
import SettingsPage from 'components/settings/SettingsPage';
import { useTenant } from 'contexts/Tenant';
import { NEW_PROPOSITION_PLANS } from 'globals/constants';
import { useHasEnterprisePlan, useHasManagerAccess, useHasNewPricingPlan } from 'hooks/access';
import { useDomainAuth, useProperties } from 'hooks/queries/domain';
import { useNumberFormats } from 'hooks/queries/domain-setting';
import { useTranslate } from 'hooks/translate';
import { useUserDateFormat } from 'hooks/user';

export default function DomainProperties() {
  const navigate = useNavigate();
  const translateText = useTranslate();
  const hasManagerAccess = useHasManagerAccess();
  const hasNewPricingPlan = useHasNewPricingPlan();
  const hasEnterprisePlan = useHasEnterprisePlan();
  const dateFormat = useUserDateFormat();
  const { selectedDomain, selectedDomainData, selectedMccData } = useTenant();

  const { isLoading: isLoadingFormats } = useNumberFormats();
  const { domainAuth, isLoading: isLoadingDomainAuth } = useDomainAuth();
  const { data, isLoading: isLoadingProperties } = useProperties();
  const isLoading = isLoadingProperties || isLoadingFormats || isLoadingDomainAuth;

  const activeSinceMoment = moment(data?.start_date, 'YYYY-MM-DD HH:mm:ss');
  const activeSince = activeSinceMoment.isValid()
    ? translateText('datetime', '{date} at {time}', {
        date: activeSinceMoment.format(dateFormat),
        time: activeSinceMoment.format('HH:mm'),
      })
    : '-';

  function getContractDurationLabel() {
    if (domainAuth?.is_trial && data?.contract_duration && data?.contract_start_date) {
      return (
        data.contract_duration +
        ' ' +
        translateText('duplicate', 'From').toLowerCase() +
        ' ' +
        moment(data.contract_start_date).format(dateFormat)
      );
    } else if (data?.contract_duration && data?.contract_end_date) {
      return (
        data.contract_duration +
        ' (' +
        translateText('label', 'Until').toLowerCase() +
        ' ' +
        moment(data.contract_end_date).format(dateFormat) +
        ')'
      );
    }
    return translateText('label', 'Monthly terminable');
  }

  function getSubscriptionButton() {
    let text, onClick, disabled, tooltip;

    if (data?.pricing_plan_id && !data.stop_requested) {
      const hasUpgradeablePlan =
        hasNewPricingPlan && domainAuth?.pricing_plan !== NEW_PROPOSITION_PLANS.at(-1); // The last one cannot be upgraded since it's already the highest

      if (data.upgrade_request_id) {
        if (hasManagerAccess) {
          text = translateText('label', 'Change requested');
          onClick = () => navigate('/settings/change-requests/' + data.upgrade_request_id);
        }
      } else if (
        domainAuth?.can_extend_subscription ||
        (hasNewPricingPlan && !data.contract_end_date)
      ) {
        text = translateText('label', 'Extend subscription');
        onClick = () => navigate('/settings/properties/edit-subscription');
        if (!hasManagerAccess) {
          disabled = true;
          tooltip = translateText(
            'text',
            'You cannot extend the subscription, because you do not have the correct permissions. Ask a domain manager for help.',
          );
        }
      } else if (domainAuth?.can_upgrade || hasUpgradeablePlan) {
        text = translateText('label', 'Upgrade subscription');
        onClick = () =>
          navigate(hasUpgradeablePlan ? '/settings/properties/edit-subscription' : '/upgrade');
        if (!hasManagerAccess) {
          disabled = true;
          tooltip = translateText(
            'text',
            'You cannot upgrade the subscription, because you do not have the correct permissions. Ask a domain manager for help.',
          );
        }
      }
    }

    return text && onClick ? { text, onClick, disabled, tooltip } : undefined;
  }

  return (
    <SettingsPage title={translateText('label', 'Domain properties')}>
      <AutoGrid size="large" className="properties">
        <DetailsSection
          title={{ text: selectedDomainData?.name ?? '', private: true }}
          boldKeys
          data={{
            [translateText('label', 'Domain ID')]: {
              value: selectedDomain,
              tooltip: translateText(
                'tooltip/domain',
                'This is the unique identifier that belongs to the domain.',
              ),
            },
            [translateText('label', 'Domain URL')]: {
              value: data?.url,
              tooltip: translateText(
                'tooltip/domain',
                'This is the website for which the services of AdCalls are used.',
              ),
              private: true,
            },
            [translateText('label', 'Customer center')]: {
              value: selectedMccData?.id === -1 ? '-' : selectedMccData?.name,
              tooltip: translateText(
                'tooltip/domain',
                "Here you see which 'customer center' this domain belongs to.",
              ),
              private: true,
            },
            [translateText('label', 'Active since')]: {
              value: activeSince,
              tooltip: translateText(
                'tooltip/domain',
                'This is the date the domain was delivered by AdCalls.',
              ),
            },
          }}
          isLoading={isLoading}
        />
        {hasEnterprisePlan && (
          <DetailsSection
            title={translateText('label', 'Customer Success Manager')}
            boldKeys
            intro={translateText(
              'domain',
              'Do you have a technical or marketing-related question? Please contact your Customer Success Manager.',
            )}
            data={{
              [translateText('label', 'Full name')]: 'Dennis van Dijk',
              [translateText('label', 'Email address')]: 'dennis@adcalls.nl',
              [translateText('label', 'Phone number')]: '+31 (0)30 7400 990',
            }}
            isLoading={isLoading}
          />
        )}
        <DetailsSection
          title={translateText('label', 'Account manager')}
          boldKeys
          intro={translateText(
            'domain',
            'Do you have a commercial question? Please contact your account manager.',
          )}
          data={{
            [translateText('label', 'Full name')]: data?.account_manager.name,
            [translateText('label', 'Email address')]: data?.account_manager.email,
            [translateText('label', 'Phone number')]: data?.account_manager.phone,
          }}
          isLoading={isLoading}
        />
        {data?.stop_requested?.status !== 4 && (
          <DetailsSection
            title={translateText('label', 'Subscription')}
            boldKeys
            data={{
              [translateText('label', 'Pricing plan')]: data?.invoice_label,
              [translateText('label', 'Contract duration')]: getContractDurationLabel(),
            }}
            button={getSubscriptionButton()}
            isLoading={isLoading}
          />
        )}
        <NumberFormatSection />
        <NumberDistributionSection />
        {hasManagerAccess && <StopDomainSection />}
      </AutoGrid>
    </SettingsPage>
  );
}
