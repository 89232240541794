import { ReactComponent as ArrowBlock } from 'assets/images/icons/arrow-block.svg';
import Skeleton from 'components/Skeleton';

type Props<T> = {
  title: string;
  list: T[];
  titleKey: keyof T;
  onClick: (item: T, index: number) => void;
  isLoading: boolean;
};

export default function NavigationList<T extends Record<string, unknown> | Element>({
  title,
  list,
  titleKey,
  onClick,
  isLoading,
}: Props<T>) {
  if (!isLoading && !list.length) return null;
  return (
    <section className="navigation-list" data-testid="navigation-list">
      <h2>{isLoading ? <Skeleton width={title.length * 12} /> : title}</h2>
      <div className={'items' + (isLoading ? ' fade-out' : '')}>
        {(isLoading ? [...Array(4)] : list).map((item, index) => (
          <div
            key={title + index}
            className="clickable"
            onClick={() => !isLoading && onClick(item, index)}
          >
            <div className="item" data-testid="navigation-list-item">
              {isLoading ? <Skeleton containerClass="arrow-skeleton" /> : <ArrowBlock />}
              {isLoading ? (
                <Skeleton />
              ) : (
                <span className="title" dangerouslySetInnerHTML={{ __html: item[titleKey] }} />
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
