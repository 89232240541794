import { Formik } from 'formik';
import * as Yup from 'yup';

import ErrorFocus from 'components/ErrorFocus';
import TextField from 'components/input/TextField';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import { useTenant } from 'contexts/Tenant';
import { useInviteDomain } from 'hooks/queries/reseller';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation } from 'hooks/validation';

type Props = {
  mccId?: number;
} & BaseSlideInProps;

export default function InviteDomain({ show, close, mccId }: Props) {
  const translateText = useTranslate();
  const { required } = useCustomValidation();
  const { inviteDomain } = useInviteDomain();
  const { selectedMcc } = useTenant();

  async function sendInvitation(values: { domainId: string; reference: string }) {
    await inviteDomain({
      mccId: mccId ?? selectedMcc!,
      domainId: values.domainId,
      reference: values.reference,
    }).then(() => close());
  }

  const validationSchema = Yup.object({
    domainId: required.matches(/^[0-9]*$/, translateText('message', 'Invalid domain ID.')),
  });

  return (
    <Formik
      initialValues={{ domainId: '', reference: '' }}
      validationSchema={validationSchema}
      onSubmit={async values => await sendInvitation(values)}
    >
      {({
        values,
        handleChange,
        submitForm,
        handleBlur,
        touched,
        errors,
        isSubmitting,
        dirty,
        resetForm,
      }) => (
        <Setup
          id="invite-domain"
          show={show}
          save={submitForm}
          close={close}
          afterClose={resetForm}
          title={translateText('label', 'Invite domain')}
          saveButtonText={translateText('label', 'Invite')}
          isSaving={isSubmitting}
          hasChanges={dirty}
        >
          <ErrorFocus element={document.getElementById('invite-domain')} />
          <InputWrapper
            label={translateText('label', 'Domain ID')}
            tooltip={translateText(
              'tooltip/mcc-users',
              'Enter the domain ID of the domain you wish to invite to your customer center here.',
            )}
          >
            <TextField
              id="domain-id"
              name="domainId"
              value={values.domainId}
              onBlur={handleBlur}
              onChange={handleChange}
              error={touched.domainId && errors.domainId}
            />
          </InputWrapper>
          <InputWrapper
            label={translateText('label', 'Reference')}
            tooltip={translateText(
              'tooltip/mcc-users',
              'If necessary, send a reference. This is shown with the invitation.',
            )}
          >
            <TextField
              name="reference"
              type="textarea"
              value={values.reference}
              onChange={handleChange}
            />
          </InputWrapper>
        </Setup>
      )}
    </Formik>
  );
}
