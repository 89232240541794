import { useFormikContext } from 'formik';
import { cloneDeep } from 'lodash';

import Toggle from 'components/input/Toggle';
import Skeleton from 'components/Skeleton';
import Tooltip from 'components/Tooltip';
import { useTranslate } from 'hooks/translate';

const TYPE_DISABLED = 0 as const;
const TYPE_DASHBOARD_AND_EMAIL = 1 as const;
const TYPE_DASHBOARD = 2 as const;
const TYPE_EMAIL = 3 as const;

type NotificationSetting = {
  type:
    | typeof TYPE_DISABLED
    | typeof TYPE_DASHBOARD_AND_EMAIL
    | typeof TYPE_DASHBOARD
    | typeof TYPE_EMAIL;
  category: number;
  category_string: string;
  tooltip: string;
};

type Props = {
  isLoading: boolean;
};

export default function NotificationSettings({ isLoading }: Props) {
  const translateText = useTranslate();
  const { values, setFieldValue } = useFormikContext<{
    notificationSettings: NotificationSetting[];
  }>();

  function updateToggle(medium: 'dashboard' | 'email', index: number) {
    const notificationSettings = cloneDeep(values.notificationSettings);
    let type = notificationSettings[index].type;
    if (type === TYPE_DASHBOARD_AND_EMAIL) {
      // Both are on, so the selected medium needs to be turned off
      type = medium === 'dashboard' ? TYPE_EMAIL : TYPE_DASHBOARD;
    } else if (type === TYPE_EMAIL) {
      // Email is on, so if selected medium is email, both need to be turned off, otherwise both need to be turned on
      type = medium === 'email' ? TYPE_DISABLED : TYPE_DASHBOARD_AND_EMAIL;
    } else if (type === TYPE_DASHBOARD) {
      // Dashboard is on, so if selected medium is dashboard, both need to be turned off, otherwise both need to be turned on
      type = medium === 'dashboard' ? TYPE_DISABLED : TYPE_DASHBOARD_AND_EMAIL;
    } else {
      // Both are off, so the selected medium needs to be turned on
      type = medium === 'email' ? TYPE_EMAIL : TYPE_DASHBOARD;
    }
    notificationSettings[index].type = type;
    setFieldValue('notificationSettings', notificationSettings);
  }

  return (
    <div className="notification-settings">
      <div className="form-row">
        <div className="name-label" />
        <div className="value-wrapper bold">
          <div className="column-one">
            {isLoading ? <Skeleton width={100} /> : translateText('label', 'Dashboard')}
          </div>
          <div className="column-two">
            {isLoading ? <Skeleton width={100} /> : translateText('label', 'Email')}
          </div>
        </div>
      </div>
      {(isLoading ? Array.from(Array(5)) : values.notificationSettings).map((setting, index) => (
        <div className="form-row" key={index}>
          <div className="name-label">
            {isLoading ? (
              <Skeleton width={100} />
            ) : (
              <>
                {setting.category_string}
                <Tooltip text={setting.tooltip} />
              </>
            )}
          </div>
          <div className="value-wrapper">
            <div className="column-one">
              <Toggle
                checked={[TYPE_DASHBOARD_AND_EMAIL, TYPE_DASHBOARD].includes(setting?.type)}
                onClick={() => updateToggle('dashboard', index)}
                isLoading={isLoading}
              />
            </div>
            <div className="column-two">
              <Toggle
                checked={[TYPE_DASHBOARD_AND_EMAIL, TYPE_EMAIL].includes(setting?.type)}
                onClick={() => updateToggle('email', index)}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
