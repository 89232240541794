import { ReactElement } from 'react';
import { indexOf } from 'lodash';

import { TOUR_STEPS, TourSection, useGuidedTour } from 'contexts/GuidedTour';
import { useTranslate } from 'hooks/translate';

type Props = {
  title: string;
  image: string;
  description: ReactElement;
};

export default function BaseTour({ title, image, description }: Props) {
  const translateText = useTranslate();
  const { section, setSection, setShowCancel } = useGuidedTour();

  const sectionIndex = indexOf(TOUR_STEPS, section);

  return (
    <>
      <h1>{title}</h1>
      <img alt="" src={image} />
      <div className="description">{description}</div>
      <div className="navigation">
        <div className="buttons">
          <button className="btn btn-text no-padding" onClick={() => setShowCancel(true)}>
            {translateText('guided-tour', 'Skip tour')}
          </button>
          <button
            className="btn btn-text no-padding"
            onClick={() =>
              setSection(
                sectionIndex === TOUR_STEPS.length - 1
                  ? TourSection.Completed
                  : TOUR_STEPS[sectionIndex + 1],
              )
            }
          >
            {translateText('guided-tour', 'Next')}
            <span className="arrow arrow-right" />
          </button>
        </div>
        <div className="step-navigation">
          {TOUR_STEPS.map(step => (
            <button
              key={step}
              onClick={() => setSection(step)}
              className={section === step ? 'active' : ''}
            />
          ))}
        </div>
      </div>
    </>
  );
}
