import { useMutation } from '@tanstack/react-query';

import { useMessages } from 'contexts/Messages';
import { useTenant } from 'contexts/Tenant';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';

export function useSync() {
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomainData, selectedDomain } = useTenant();

  const { mutate: sync, isPending: isSyncingSpecific } = useMutation({
    mutationFn: () =>
      fetchData('/script/sync', {
        method: 'POST',
        queryParams: { domain_id: selectedDomain },
      }),
    onSuccess: () => addSuccessMessage(translateText('message', 'Script has been synced')),
  });

  const { mutate: syncAll, isPending: isSyncingAll } = useMutation({
    mutationFn: () => fetchData('/script/sync-all', { method: 'POST' }),
    onSuccess: () => addSuccessMessage(translateText('message', 'Scripts have been synced')),
  });

  function syncSpecificScript() {
    if (
      window.confirm(
        translateText('text', 'Are you sure you want to sync script for {domain}?', {
          domain: selectedDomainData?.name,
        }),
      )
    ) {
      sync();
    }
  }

  function syncAllScripts() {
    if (
      window.confirm(
        translateText('text', 'Are you sure you want to sync scripts for all domains?'),
      )
    ) {
      syncAll();
    }
  }

  return {
    syncSpecificScript,
    syncAllScripts,
    isSyncing: isSyncingSpecific || isSyncingAll,
  };
}
