import { Navigate, Route, Routes } from 'react-router';

import Invitations from 'pages/authenticated/account/Invitations';
import MyAccount from 'pages/authenticated/account/MyAccount';
import NotificationDetails from 'pages/authenticated/account/NotificationDetails';
import Notifications from 'pages/authenticated/account/Notifications';
import Error404 from 'pages/Error404';

export default function Account() {
  return (
    <Routes>
      <Route path="/my-profile" element={<Navigate to="/account/my-account" />} />
      <Route path="/my-account" element={<MyAccount />} />
      <Route path="/invitations" element={<Invitations />} />
      <Route path="/notifications" element={<Notifications />} />
      <Route path="/notifications/:id" element={<NotificationDetails />} />
      <Route path="/*" element={<Error404 />} />
    </Routes>
  );
}
