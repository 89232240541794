import { Formik, useFormikContext } from 'formik';

import Select from 'components/input/Select';
import TextField from 'components/input/TextField';
import Skeleton from 'components/Skeleton';
import { useFilters } from 'contexts/Filters';
import { useMedia } from 'hooks/media';
import { DEFAULT_PAGE_LIMIT } from './QueryTable';
import { ColumnSetting } from './Table';

type Props<T> = {
  tableKey: string;
  columns: [string, ColumnSetting<T>][];
  multiSelect: boolean;
  followNumber: boolean;
  isResponsive: boolean;
  isLoading?: boolean;
};

export default function FilterRow<T>({
  tableKey,
  columns,
  multiSelect,
  followNumber,
  isResponsive,
  isLoading = false,
}: Props<T>) {
  const isSmall = useMedia(`(max-width: 1023px)`);
  const { values } = useFormikContext<{ columns: string[] }>();
  const chosenColumns = values.columns;

  const { filters, updateTableFilter } = useFilters();
  const currentFilters = filters.tables?.[tableKey];

  if (!columns.some(col => col[1].search)) return null;

  function changeFilter(value: string, name: string) {
    if (value !== currentFilters?.[name]) {
      updateTableFilter({
        table: tableKey,
        type: 'pagination',
        value: { ...(currentFilters?.pagination ?? { pageLimit: DEFAULT_PAGE_LIMIT }), page: 1 },
      }); // always set page to 1 if filters change
      updateTableFilter({ table: tableKey, type: name, value });
    }
  }

  function getFilter(column: [string, ColumnSetting<T>], index: number) {
    const filterName = typeof column[1].search === 'string' ? column[1].search : column[0]; // If search is string; use it as search param else use the column name
    const title =
      (typeof column[1].header === 'string' ? column[1].header : column[1].header?.name) ??
      column[0];

    if (filterName && typeof column[1].search === 'object') {
      const options = Object.keys(column[1].search).map(key => {
        return {
          text: (column[1].search as Record<string, string>)[key],
          value: key,
        };
      });

      options.unshift({ text: '', value: '' });
      return (
        <td key={index}>
          <div className="title">
            {isResponsive &&
              isSmall &&
              title &&
              (isLoading ? <Skeleton width={String(title).length * 9} /> : title)}
          </div>
          <Formik
            initialValues={{ filter: currentFilters?.[filterName] ?? '' }}
            onSubmit={values => changeFilter(values.filter, filterName)}
          >
            {({ values, handleSubmit, setFieldValue, submitForm }) => (
              <form onSubmit={handleSubmit}>
                <Select
                  name="filter"
                  onChange={e => {
                    setFieldValue('filter', e.target.value);
                    submitForm();
                  }}
                  value={values.filter}
                  options={options}
                />
              </form>
            )}
          </Formik>
        </td>
      );
    } else if (filterName && column[1].search) {
      return (
        <td key={index}>
          {isResponsive && isSmall && title && (
            <div className="title">
              {isLoading ? <Skeleton width={String(title).length * 9} /> : title}
            </div>
          )}
          <Formik
            initialValues={{ filter: currentFilters?.[filterName] ?? '' }}
            onSubmit={values => changeFilter(values.filter, filterName)}
          >
            {({ values, handleSubmit, handleChange, submitForm }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  value={values.filter}
                  name="filter"
                  onChange={handleChange}
                  onBlur={submitForm}
                />
              </form>
            )}
          </Formik>
        </td>
      );
    } else {
      return <td key={index} />;
    }
  }

  return (
    <tr className="search-bar">
      {multiSelect && <td />}
      {followNumber && <td />}
      {columns
        .filter(column => chosenColumns.includes(column[0]))
        .map((column, index) => {
          return getFilter(column, index);
        })}
    </tr>
  );
}
