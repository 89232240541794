import { useNavigate } from 'react-router';

import Skeleton from 'components/Skeleton';

type Props = {
  title: string;
  content: string;
  image: string;
  link: string;
  isLoading: boolean;
};

export default function CategoryTypeTile({ title, content, image, link, isLoading }: Props) {
  const navigate = useNavigate();
  return (
    <div
      className={'category-outer' + (!isLoading ? ' selectable' : '')}
      onClick={() => !isLoading && navigate(link)}
    >
      <div className="category">
        <h2>{isLoading ? <Skeleton width="120px" /> : title}</h2>
        <div className="category-image-wrapper">
          {isLoading ? <Skeleton /> : <img alt="" src={image} />}
        </div>
        <p>{isLoading ? <Skeleton count={6} /> : content}</p>
      </div>
    </div>
  );
}
