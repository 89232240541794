import { useParams } from 'react-router';
import { Formik } from 'formik';

import Wysiwyg from 'components/admin/Wysiwyg';
import Select from 'components/input/Select';
import SelectFromList from 'components/input/SelectFromList';
import TextField from 'components/input/TextField';
import FaqSection from 'components/knowledge-center/FaqSection';
import SlugSection from 'components/knowledge-center/SlugSection';
import Loader from 'components/Loader';
import {
  KNOWLEDGE_CENTER_TYPE_SUPPORT,
  LANGUAGE_DUTCH,
  LANGUAGE_ENGLISH_US,
} from 'globals/constants';
import { CategorySlug, CategoryType } from 'globals/types';
import { useCategoryType, useCategoryTypeTranslations } from 'hooks/knowledge-center';
import {
  useCategories,
  useCreateCategory,
  useUpdateArticleOrder,
  useUpdateCategory,
} from 'hooks/queries/support';
import { useTranslate } from 'hooks/translate';

type Props = {
  type: 'new' | 'edit';
};
export default function CategoryEdit({ type }: Props) {
  const translateText = useTranslate();
  const params = useParams<{ category: string; categoryType: CategorySlug }>();
  const categoryTypes = useCategoryTypeTranslations();
  const { categories, isLoading } = useCategories();

  const { categoryTypeId } = useCategoryType(params?.categoryType ?? null);
  const { createCategory } = useCreateCategory();
  const { updateCategory } = useUpdateCategory();
  const { updateArticleOrder } = useUpdateArticleOrder();

  const category = categories?.find(
    category =>
      category.type === categoryTypeId &&
      (category.slugs.find(slug => slug.slug === params.category) ||
        category.id === Number(params.category)),
  );

  if (type !== 'new' && isLoading) return <Loader />;
  if (type !== 'new' && !category) {
    return <p>{translateText('label', 'This category does not exist')}</p>;
  }

  async function save(values: {
    name: string;
    icon: string;
    language: string;
    description: string;
    type: CategoryType;
  }) {
    type === 'new'
      ? await createCategory(values)
      : await updateCategory({ id: category!.id, ...values });
  }

  return (
    <div className="category-wrapper knowledge-center-edit">
      <h1>
        {type === 'new'
          ? translateText('label', 'Add category')
          : translateText('label', 'Edit category') + ' (' + category?.id + ')'}
      </h1>
      <Formik
        initialValues={{
          name: category?.name ?? '',
          icon: category?.icon ?? '',
          language: LANGUAGE_DUTCH,
          description: category?.description ?? '',
          type: categoryTypeId ?? KNOWLEDGE_CENTER_TYPE_SUPPORT,
        }}
        onSubmit={save}
      >
        {({ values, handleChange, setFieldValue, submitForm, dirty, isSubmitting }) => (
          <>
            <div className="double-row">
              <div className="form-row">
                <div className="name-label">{translateText('label', 'Language')}</div>
                <div className="value-wrapper">
                  <Select
                    name="language"
                    value={values.language}
                    onChange={handleChange}
                    disabled={type === 'new'}
                    options={{
                      [LANGUAGE_DUTCH]: translateText('label', 'Dutch'),
                      [LANGUAGE_ENGLISH_US]: translateText('label', 'English'),
                    }}
                  />
                </div>
              </div>
              {type === 'new' && (
                <div className="form-row">
                  <div className="name-label">{translateText('label', 'Type')}</div>
                  <div className="value-wrapper">
                    <Select
                      name="type"
                      value={values.type}
                      onChange={handleChange}
                      options={categoryTypes}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="form-row">
              <div className="name-label">{translateText('label', 'Title')}</div>
              <div className="value-wrapper">
                <TextField
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  placeholder={translateText('label', 'Title')}
                />
              </div>
            </div>
            <div className="form-row margin-top">
              <div className="name-label">{translateText('label', 'Description')}</div>
              <div className="value-wrapper">
                <Wysiwyg
                  value={values.description}
                  onChange={value => setFieldValue('description', value)}
                />
              </div>
            </div>
            <div className="form-row margin-top">
              <div className="name-label">{translateText('label', 'Icon')}</div>
              <div className="value-wrapper">
                <TextField
                  name="icon"
                  value={values.icon}
                  onChange={handleChange}
                  placeholder={translateText('label', 'Icon')}
                />
              </div>
            </div>
            {values.icon && (
              <div className="form-row margin-top">
                <div className="name-label">{translateText('label', 'Preview')}</div>
                <div className="value-wrapper">
                  <img src={values.icon} className="preview small" alt="preview" />
                </div>
              </div>
            )}
            <button
              className="btn btn-green margin-top"
              disabled={isSubmitting || !dirty}
              onClick={submitForm}
            >
              {translateText('label', 'Save')}
            </button>
          </>
        )}
      </Formik>
      {category && (
        <>
          <Formik
            initialValues={{ articles: category.articles }}
            onSubmit={async values => await updateArticleOrder(values.articles)}
            enableReinitialize
          >
            {({ values, setFieldValue, submitForm, isSubmitting, dirty }) => (
              <>
                <h1>{translateText('label', 'Edit order of articles')}</h1>
                <SelectFromList
                  id="articles-list"
                  items={values.articles ?? []}
                  onChange={items => setFieldValue('articles', items)}
                  idKey="article_id"
                  nameKey="article_title"
                  canChangeOrder
                />
                <button
                  className="btn btn-green margin-top"
                  disabled={isSubmitting || !dirty}
                  onClick={submitForm}
                >
                  {translateText('label', 'Save')}
                </button>
              </>
            )}
          </Formik>
          <FaqSection categoryId={category.id} faqs={category.faqs} />
          <SlugSection
            slugs={category.slugs}
            type="category"
            target={params.category!}
            targetId={category.id}
          />
        </>
      )}
    </div>
  );
}
