import { useState } from 'react';
import { Formik } from 'formik';
import { cloneDeep, difference, isEmpty, mapValues, omit, pickBy } from 'lodash';
import * as Yup from 'yup';

import icon from 'assets/images/icons/integrations/lef.svg';
import CreateDeleteTable from 'components/CreateDeleteTable';
import ErrorFocus from 'components/ErrorFocus';
import SearchableSelect from 'components/input/SearchableSelect';
import Select from 'components/input/Select';
import TextField from 'components/input/TextField';
import Toggle from 'components/input/Toggle';
import CostParagraph from 'components/integrations/CostParagraph';
import RestrictedAccess from 'components/RestrictedAccess';
import Skeleton from 'components/Skeleton';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import SetupWithSub from 'components/slide-in/SetupWithSub';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import SupportParagraph from 'components/SupportParagraph';
import { INTEGRATION_LEF, PH_MASK, ROLE_ADMIN } from 'globals/constants';
import { SUP_ART_LEF } from 'globals/support';
import { useHasAccessToIntegration, useHasAdminAccess } from 'hooks/access';
import { useTogglePaidIntegration } from 'hooks/integrations';
import { useLef } from 'hooks/queries/integration';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation } from 'hooks/validation';

type LefLocation = {
  value: string | null;
  label: string;
  identifier: string;
};

export type LefSettings = {
  enabled: boolean;
  username: string;
  password: string;
  pushWithoutEvaluation: boolean;
  locations: Record<string, LefLocation>;
  pushForEvaluations: string[];
};

type FormValues = Omit<LefSettings, 'pushWithoutEvaluation'> & {
  pushWithoutEvaluation: '0' | '1';
  deletedIds: LefLocation[];
  deletedEvaluations: Record<string, string>[];
};

export default function Lef({ show, close }: BaseSlideInProps) {
  const translateText = useTranslate();
  const isAdmin = useHasAdminAccess();
  const { required } = useCustomValidation();

  const title = translateText('label', 'LEF');
  const PRICE = 49 as const;

  const toggleEnabled = useTogglePaidIntegration(title, PRICE, false);
  const hasAccessToIntegration = useHasAccessToIntegration();

  const { lef, isLoading, updateLef } = useLef(show);
  const [showLocation, setShowLocation] = useState<LefLocation | false>(false);
  const [showEvaluation, setShowEvaluation] = useState<string | false>(false);

  if (!hasAccessToIntegration(INTEGRATION_LEF)) return null;

  const validationSchema = Yup.object({
    username: required,
    password: required,
  });

  function save(values: FormValues) {
    const locations: Record<string, string | null> = {};
    values.deletedIds.forEach(value => (locations[value.identifier] = null));
    Object.keys(lef!.locations).forEach(key => {
      if (values.locations[key].value !== lef!.locations[key].value) {
        locations[key] = values.locations[key].value;
      }
    });

    const evaluations = difference(
      values.pushForEvaluations,
      values.deletedEvaluations.map(item => item.evaluation),
    );

    updateLef({
      ...omit(values, ['deletedIds', 'deletedEvaluations']),
      pushWithoutEvaluation: values.pushWithoutEvaluation === '0',
      locations: locations,
      pushForEvaluations: evaluations,
    }).then(close);
  }

  return (
    <Formik
      initialValues={{
        enabled: lef?.enabled ?? false,
        username: lef?.username ?? '',
        password: lef?.password ?? '',
        pushWithoutEvaluation: lef?.pushWithoutEvaluation ? '0' : '1',
        locations: lef?.locations ?? {},
        pushForEvaluations: lef?.pushForEvaluations ?? [],
        deletedIds: [],
        deletedEvaluations: [],
      }}
      onSubmit={save}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({
        values,
        errors,
        setFieldValue,
        submitForm,
        handleChange,
        dirty,
        isSubmitting,
        resetForm,
        submitCount,
      }) => {
        const possibleOptions = mapValues(
          pickBy(values.locations, l => l.value === null),
          l => l.label,
        );

        let tooltipText = translateText(
          'tooltip/lef',
          'The accepted calls are sent as soon as there are evaluated in the dashboard. The missed unevaluated calls are sent if there is no successful call after five minutes.',
        );
        if (values.pushWithoutEvaluation === '0') {
          tooltipText = translateText(
            'tooltip/lef',
            'All calls - excluding calls with an anonymous sender - are registered in LEF.',
          );
        }
        if (!isAdmin && values.pushForEvaluations.length > 0) {
          tooltipText = translateText(
            'tooltip/lef',
            'This setting only applies when there is a custom configuration',
          );
        }

        const subValidationSchema = Yup.object({
          value: Yup.string()
            .required(translateText('message', 'This field is required.'))
            .test({
              test: value => {
                if (showLocation) return true; // Should only be validated for evaluations
                return !!value && !values.pushForEvaluations.includes(value);
              },
              message: translateText('message', 'Evaluations must be unique.'),
            }),
        });

        async function saveSub(subValues: { identifier: string; value: string }) {
          if (showLocation !== false) {
            const locations = cloneDeep(values.locations);
            locations[subValues.identifier] = {
              value: subValues.value,
              identifier: subValues.identifier,
              label: values.locations[subValues.identifier].label,
            };
            setFieldValue('locations', locations);
            setShowLocation(false);
          } else {
            const evaluations = cloneDeep(values.pushForEvaluations);
            if (showEvaluation === '') {
              evaluations.push(subValues.value);
            } else {
              const index = evaluations.findIndex(ev => ev === showEvaluation);
              evaluations[index] = subValues.value;
            }
            setFieldValue('pushForEvaluations', evaluations);
            setShowEvaluation(false);
          }
        }

        return (
          <>
            <ErrorFocus element={document.getElementsByClassName('setup-wrapper')[0]} />
            <Formik
              initialValues={{
                value:
                  showLocation && showLocation.value !== null // eslint-disable-line @typescript-eslint/prefer-optional-chain
                    ? showLocation!.value
                    : showEvaluation || '',
                identifier:
                  showLocation && showLocation.identifier // eslint-disable-line @typescript-eslint/prefer-optional-chain
                    ? showLocation?.identifier
                    : Object.keys(possibleOptions)[0],
              }}
              validationSchema={subValidationSchema}
              onSubmit={saveSub}
              enableReinitialize
              validateOnChange={false}
            >
              {({
                values: subValues,
                dirty: subDirty,
                submitForm: subSubmit,
                handleChange: subHandleChange,
                setFieldValue: subSetFieldValue,
                resetForm: subResetForm,
                errors: subErrors,
              }) => (
                <SetupWithSub
                  show={show}
                  save={submitForm}
                  close={close}
                  afterClose={resetForm}
                  icon={icon}
                  title={title}
                  maxWidth={450}
                  hasChanges={dirty}
                  sub={
                    <Setup
                      show={showLocation !== false || showEvaluation !== false}
                      close={() => {
                        setShowLocation(false);
                        setShowEvaluation(false);
                      }}
                      afterClose={subResetForm}
                      save={subSubmit}
                      maxWidth={400}
                      title={
                        showLocation !== false
                          ? showLocation.label === ''
                            ? translateText('label', 'Add a LEF ID to location')
                            : translateText('label', 'Edit the LEF ID for location') +
                              ' ' +
                              showLocation.label
                          : showEvaluation === ''
                            ? translateText('label', 'Add an evaluation to LEF')
                            : translateText('label', 'Edit the evaluation to LEF conditions')
                      }
                      hasChanges={subDirty}
                    >
                      <ErrorFocus element={document.getElementsByClassName('setup-wrapper')[0]} />
                      {showLocation && (
                        <InputWrapper label={translateText('label', 'Location')}>
                          {showLocation.identifier ? (
                            <p className={PH_MASK}>{showLocation.label}</p>
                          ) : (
                            <SearchableSelect
                              options={possibleOptions}
                              value={subValues.identifier}
                              onChange={id => subSetFieldValue('identifier', id)}
                              disabled={showLocation.identifier !== ''}
                            />
                          )}
                        </InputWrapper>
                      )}
                      <InputWrapper
                        label={
                          showLocation
                            ? translateText('label', 'LEF ID')
                            : translateText('label', 'Evaluation')
                        }
                      >
                        <TextField
                          name="value"
                          value={subValues.value}
                          onChange={subHandleChange}
                          error={subErrors.value}
                        />
                      </InputWrapper>
                    </Setup>
                  }
                >
                  <p>
                    {isLoading ? (
                      <Skeleton count={5} />
                    ) : (
                      translateText(
                        'integration',
                        'LEF lead management system for fast lead qualification and follow-up. LEF ensures that all leads are automatically qualified with the right deadline, objective and call script, and end up with the right people.',
                      )
                    )}
                  </p>
                  <CostParagraph
                    integration={title}
                    price={PRICE}
                    isLoading={isLoading}
                    checkNewPricingPlan={false}
                  />
                  <SupportParagraph articleId={SUP_ART_LEF} isLoading={isLoading} />
                  <h3 className="section-title">
                    {isLoading ? (
                      <Skeleton width={200} />
                    ) : (
                      translateText('label', 'Send conversions')
                    )}
                  </h3>
                  <InputWrapper
                    label={translateText('label', 'Send conversions')}
                    isLoading={isLoading}
                  >
                    <Toggle
                      id="enabled"
                      name="enabled"
                      checked={values.enabled}
                      onClick={() => toggleEnabled(values.enabled, setFieldValue)}
                      disabled={isSubmitting}
                      isLoading={isLoading}
                    />
                  </InputWrapper>
                  <InputWrapper label={translateText('label', 'Username')} isLoading={isLoading}>
                    {isLoading ? (
                      <Skeleton width={300} height={38} />
                    ) : (
                      <TextField
                        name="username"
                        value={values.username}
                        onChange={handleChange}
                        error={submitCount > 0 && errors.username}
                        disabled={isSubmitting}
                      />
                    )}
                  </InputWrapper>
                  <InputWrapper label={translateText('label', 'Password')} isLoading={isLoading}>
                    {isLoading ? (
                      <Skeleton width={300} height={38} />
                    ) : (
                      <TextField
                        name="password"
                        type="password"
                        id="password"
                        value={values.password}
                        onChange={handleChange}
                        error={submitCount > 0 && errors.password}
                        disabled={isSubmitting}
                      />
                    )}
                  </InputWrapper>
                  <InputWrapper
                    label={translateText('label', 'Send calls')}
                    tooltip={tooltipText}
                    isLoading={isLoading}
                  >
                    {isLoading ? (
                      <Skeleton width={300} height={38} />
                    ) : !isAdmin && !!lef?.pushForEvaluations?.length ? (
                      <Select
                        name="pushWithoutEvaluation"
                        value="custom"
                        onChange={handleChange}
                        options={{ custom: translateText('label', 'Custom') }}
                        disabled
                      />
                    ) : (
                      <Select
                        name="pushWithoutEvaluation"
                        value={values.pushWithoutEvaluation}
                        onChange={handleChange}
                        options={{
                          0: translateText('label', 'All calls'),
                          1: translateText('label', 'Evaluated calls'),
                        }}
                        disabled={isSubmitting}
                      />
                    )}
                  </InputWrapper>
                  {!isLoading && (
                    <>
                      <CreateDeleteTable
                        dataKeys={['label', 'value']}
                        privateKeys={['label']}
                        items={Object.values(values.locations).filter(v => v.value !== null)}
                        onAdd={() => setShowLocation({ value: '', label: '', identifier: '' })}
                        onEdit={location => setShowLocation(location)}
                        deletedItems={values.deletedIds}
                        setDeletedItems={deletedIds => setFieldValue('deletedIds', deletedIds)}
                        title={translateText('label', 'Locations')}
                        addButtonDisabled={isEmpty(possibleOptions)}
                      />
                      <RestrictedAccess role={ROLE_ADMIN} show="outline">
                        <CreateDeleteTable
                          dataKeys={['evaluation']}
                          items={values.pushForEvaluations.map(ev => ({ evaluation: ev }))}
                          onAdd={() => setShowEvaluation('')}
                          onEdit={item => setShowEvaluation(item.evaluation)}
                          deletedItems={values.deletedEvaluations}
                          setDeletedItems={deletedEvaluations =>
                            setFieldValue('deletedEvaluations', deletedEvaluations)
                          }
                          title={translateText('label', 'Evaluations')}
                        />
                      </RestrictedAccess>
                    </>
                  )}
                </SetupWithSub>
              )}
            </Formik>
          </>
        );
      }}
    </Formik>
  );
}
