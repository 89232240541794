import { Formik } from 'formik';
import { kebabCase } from 'lodash';

import Toggle from 'components/input/Toggle';
import { RedirectUriKey } from 'components/oauth2/Verification';
import VerificationButton from 'components/oauth2/VerificationButton';
import Skeleton from 'components/Skeleton';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import SupportParagraph from 'components/SupportParagraph';
import { useTenant } from 'contexts/Tenant';
import { PLAN_ENTERPRISE, PLAN_OPTIMIZE, TYPE_CALL_TRACKING } from 'globals/constants';
import { useSetQueryData } from 'hooks/data';
import { useTogglePaidIntegration } from 'hooks/integrations';
import { useCrmIntegration } from 'hooks/queries/integration';
import { useTranslate } from 'hooks/translate';
import CostParagraph from './CostParagraph';

export type CrmIntegrationData = {
  enabled: 0 | 1;
  verified: boolean;
  createMissingContact?: 0 | 1;
  sendExtraData?: 0 | 1;
};

type Props = {
  integrationKey: string;
  redirectUriKey: RedirectUriKey;
  title: string;
  icon: string;
  infoText: string;
  articleId?: number;
  canAddContact?: boolean;
  canSendExtraData?: boolean;
  verificationTooltip?: string;
  includeOptimize?: boolean;
  price: number;
} & BaseSlideInProps;

export default function BaseCrmIntegration({
  integrationKey,
  redirectUriKey,
  show,
  close,
  icon,
  title,
  infoText,
  articleId,
  canAddContact,
  canSendExtraData,
  verificationTooltip,
  includeOptimize = false,
  price,
}: Props) {
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const toggleEnabled = useTogglePaidIntegration(title, price);
  const { selectedDomain } = useTenant();
  const { data, isLoading, updateCrmIntegration } = useCrmIntegration(integrationKey, show);

  // Form data is saved in local storage when the account gets verified, so the form is
  // still filled after redirect
  const localStorageData = localStorage.getItem(integrationKey);
  const savedValues = localStorageData
    ? (JSON.parse(localStorageData) as CrmIntegrationData)
    : null;

  async function save(values: CrmIntegrationData) {
    await updateCrmIntegration(values).then(() => close());
  }

  return (
    <Formik
      initialValues={{
        enabled: savedValues?.enabled ?? data?.enabled ?? 0,
        verified: data?.verified ?? false,
        ...(canAddContact && {
          createMissingContact:
            savedValues?.createMissingContact ?? data?.createMissingContact ?? 0,
        }),
        ...(canSendExtraData && {
          sendExtraData: savedValues?.sendExtraData ?? data?.sendExtraData ?? 0,
        }),
      }}
      onSubmit={save}
      enableReinitialize
    >
      {({ values, submitForm, resetForm, setFieldValue, isSubmitting, dirty }) => (
        <Setup
          show={show}
          close={close}
          afterClose={() => {
            resetForm();
            if (savedValues) localStorage.removeItem(integrationKey);
          }}
          icon={icon}
          title={title}
          hasChanges={dirty || !!savedValues}
          save={submitForm}
          isSaving={isSubmitting}
          maxWidth={440}
        >
          <p>{isLoading ? <Skeleton count={3} /> : infoText}</p>
          <CostParagraph
            integration={title}
            price={price}
            plan={includeOptimize ? PLAN_OPTIMIZE : PLAN_ENTERPRISE}
            isLoading={isLoading}
          />
          {articleId && <SupportParagraph articleId={articleId} isLoading={isLoading} />}
          <h3 className="section-title">
            {isLoading ? <Skeleton width={200} /> : translateText('label', 'Connect account')}
          </h3>
          <InputWrapper
            label={translateText('label', 'Send conversions')}
            tooltip={translateText(
              'integration',
              'Disable and enable the sending of calls to {integration} here.',
              { integration: title },
            )}
            isLoading={isLoading}
          >
            <Toggle
              checked={!!values.enabled}
              disabled={isSubmitting}
              onClick={() =>
                toggleEnabled(values.enabled, setFieldValue, () => {
                  if (!values.enabled && canAddContact) setFieldValue('createMissingContact', 1);
                })
              }
              isLoading={isLoading}
            />
          </InputWrapper>
          <VerificationButton
            verified={values.verified}
            integrationName={title}
            disconnectEndpoint={`/integration/${kebabCase(integrationKey)}/disconnect`}
            redirectUri={`/integration/${kebabCase(integrationKey)}/redirect-uri`}
            redirectUriKey={redirectUriKey}
            queryParams={{ type: TYPE_CALL_TRACKING }}
            beforeRedirect={() =>
              dirty && localStorage.setItem(integrationKey, JSON.stringify(values))
            }
            tooltip={verificationTooltip}
            isLoading={isLoading}
            onDisconnectSuccess={() =>
              setQueryData<CrmIntegrationData>([integrationKey, selectedDomain], oldData => ({
                ...oldData,
                verified: false,
              }))
            }
          />
          {canAddContact && (
            <InputWrapper
              label={translateText('label', 'Create new contact')}
              tooltip={translateText(
                'integration',
                'Determine whether a new contact can be created if it is not yet known in {integration}.',
                { integration: title },
              )}
              isLoading={isLoading}
            >
              <Toggle
                checked={!!values.createMissingContact}
                onClick={() => setFieldValue('createMissingContact', +!values.createMissingContact)}
                disabled={isSubmitting}
                isLoading={isLoading}
              />
            </InputWrapper>
          )}
          {canSendExtraData && (
            <InputWrapper
              label={translateText('label', 'Send call data')}
              tooltip={translateText(
                'integration',
                'Using this functionality, the data of a call is sent to {integration}, so that you can read them in the notes field of the activity in {integration}. In the support article you can read what information is sent.',
                { integration: title },
              )}
              isLoading={isLoading}
            >
              <Toggle
                checked={!!values.sendExtraData}
                onClick={() => setFieldValue('sendExtraData', +!values.sendExtraData)}
                disabled={isSubmitting}
                isLoading={isLoading}
              />
            </InputWrapper>
          )}
        </Setup>
      )}
    </Formik>
  );
}
