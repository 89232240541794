import { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import AddButton from 'components/buttons/AddButton';
import ConfigurationCard from 'components/configuration/ConfigurationCard';
import ErrorFocus from 'components/ErrorFocus';
import ErrorTooltip from 'components/ErrorTooltip';
import GrayBars from 'components/GrayBars';
import Checkbox from 'components/input/Checkbox';
import Radio from 'components/input/Radio';
import SelectFromList from 'components/input/SelectFromList';
import Setup from 'components/slide-in/Setup';
import {
  SOURCE_TYPE_ALL,
  SOURCE_TYPE_EXCLUDE,
  SOURCE_TYPE_INCLUDE,
  SOURCE_TYPE_NOT,
} from 'globals/constants';
import { SUP_ART_SOURCES, SUP_ART_URL } from 'globals/support';
import { useHasLightPlan, useHasManagerAccess } from 'hooks/access';
import { useCancelChangeRequest, usePendingChanges } from 'hooks/queries/domain-change-request';
import { useTranslate } from 'hooks/translate';

type Props = {
  sources: string[];
  included: 0 | 1 | null;
  isLoading: boolean;
  onSave: (variables: { included: 0 | 1; sources: string[] }) => Promise<void>;
  isIntake?: boolean;
  error?: string | false;
};

const SEARCHABLE_SOURCES = {
  'AdWords': ['Google AdWords'],
  'autokopen.nl': [],
  'autoscout24.nl': [],
  'autotrack.nl': [],
  'autotrader.nl': [],
  'autowereld.nl': [],
  'Bing': ['Bing Search'],
  'BingCPC': ['Bing Ads', 'Microsoft Advertising'],
  'direct': [],
  'Facebook': [],
  'gaspedaal.nl': [],
  'Google Search': [],
  'Linkedin': [],
  'Marktplaats': [],
  'Twitter': [],
  'doubleclick': [],
  'autoweek.nl': [],
  'criteo': [],
  'huren.nl': [],
  'adcrowd': [],
  'Yahoo': [],
  'Wikipedia': [],
  'AOL': [],
  'duckduckgo': [],
  'Outlook.com': [],
  'adroll': [],
  'msn.com': [],
  'Ask.com': [],
  'Yandex': [],
  'vergelijk.nl': [],
  'instagram': [],
  'vinden.nl': [],
  'Gmail': [],
  'youtube': [],
  'KPN Mail': [],
  'adform': [],
  'Pinterest': [],
  'tweakers.net': [],
  'search.com': [],
  'viabovag.nl': [],
  'TikTok': [],
} as const;

export default function Sources({ sources, included, isLoading, isIntake, onSave, error }: Props) {
  const translateText = useTranslate();
  const { pending } = usePendingChanges('', !isIntake);
  const { cancelRequest, isDeleting } = useCancelChangeRequest();
  const [showEdit, setShowEdit] = useState(false);
  const hasManagerAccess = useHasManagerAccess();
  const hasLightPlan = useHasLightPlan();

  let supportText = '';
  if (!isIntake && hasManagerAccess) {
    supportText += translateText(
      'text',
      'To set or change the traffic sources, click on the gears.',
    );
  }

  let infoText = translateText(
    'text',
    'Specify which traffic sources you want to track for Call Tracking.',
  );
  if (!isIntake) {
    infoText +=
      ' ' +
      translateText(
        'knowledge-center',
        'For more information, see the following <a href="{link}">support article</a>.',
        { link: SUP_ART_URL + SUP_ART_SOURCES },
      );
  }
  const trackedType = getTrackedType(sources ?? [], included);

  function getTrackedType(sources: string[], included: 0 | 1 | null) {
    if (sources?.length === 0) return included === 0 ? SOURCE_TYPE_ALL : SOURCE_TYPE_NOT;
    return included === 1 ? SOURCE_TYPE_INCLUDE : SOURCE_TYPE_EXCLUDE;
  }

  function getTypeText(type: string, pending = false) {
    let text = pending ? translateText('label', 'Requested traffic sources') + ': ' : '';
    if (type === SOURCE_TYPE_EXCLUDE) {
      text += translateText('label', 'All traffic sources except');
      if (!pending) text += ' ' + translateText('label', 'Below').toLowerCase();
      else text += ': ';
    } else if (type === SOURCE_TYPE_INCLUDE && !pending) {
      text += translateText('label', 'Specific traffic sources');
    } else if (type === SOURCE_TYPE_ALL) {
      text += translateText('label', 'All traffic sources');
    } else if (type === SOURCE_TYPE_NOT) {
      text += translateText('label', 'No dynamic traffic sources');
    }
    return text;
  }

  function getPending() {
    if (isIntake) return null;
    if (pending?.sourcesChange && !isLoading) {
      const type = getTrackedType(pending?.sourcesChange.sources, pending?.sourcesChange.included);
      return (
        <div className="pending">
          <span className="pending-text">
            {getTypeText(type, true)}
            {pending?.sourcesChange.sources.join(', ')}
          </span>
          <div className="pending-button">
            <button
              className="btn btn-purple"
              onClick={() => cancelRequest(pending?.sourcesChange.id)}
              disabled={isDeleting}
            >
              {translateText('label', 'Cancel request')}
            </button>
          </div>
        </div>
      );
    }
  }

  const validationSchema = Yup.object({
    selected: Yup.array().test({
      test: function (value) {
        return (this.parent.type === SOURCE_TYPE_INCLUDE ||
          this.parent.type === SOURCE_TYPE_EXCLUDE) &&
          value?.length === 0
          ? this.createError({
              message: translateText(
                'message',
                'Please select at least one traffic source before proceeding.',
              ),
            })
          : true;
      },
    }),
  });

  async function save(values: { selected: string[]; type: string }) {
    const included = values.type === SOURCE_TYPE_EXCLUDE || values.type === SOURCE_TYPE_ALL ? 0 : 1;
    if (values.type === SOURCE_TYPE_ALL || values.type === SOURCE_TYPE_NOT) {
      values.selected = [];
    }

    await onSave({
      included,
      sources: values.selected,
    }).then(() => {
      setShowEdit(false);
    });
  }

  return (
    <>
      {isIntake && trackedType === SOURCE_TYPE_NOT ? (
        <ErrorTooltip
          error={error}
          html={
            <div className={'white-block no-data' + (error ? ' error' : '')} tabIndex={0}>
              <AddButton
                onClick={() => setShowEdit(true)}
                tooltipText={translateText(
                  'tooltip/intake',
                  'Click the + to set the traffic sources for Call Tracking.',
                )}
              />
              {translateText('label', 'No traffic sources')}
            </div>
          }
          className="no-data"
        />
      ) : (
        <ConfigurationCard
          title={!isIntake ? translateText('label', 'Traffic sources') : undefined}
          supportArticleId={!isIntake ? SUP_ART_SOURCES : undefined}
          className="sources-list"
          onEdit={() => setShowEdit(true)}
          isLoading={isLoading}
          rightParagraph={supportText}
          topParagraph={getPending()}
          disabled={!isIntake && pending?.sourcesChange}
          noAccessCheck={isIntake}
        >
          <GrayBars
            rows={sources ?? []}
            isLoading={isLoading}
            title={getTypeText(trackedType)}
            titleTooltip={translateText(
              'tooltip/intake',
              'The traffic sources that will be tracked for Call Tracking.',
            )}
            singleItems
          />
        </ConfigurationCard>
      )}
      <Formik
        initialValues={{ type: trackedType, selected: sources ?? [] }}
        onSubmit={save}
        enableReinitialize
        validationSchema={validationSchema}
        validateOnMount={false}
      >
        {({
          values,
          submitForm,
          resetForm,
          isSubmitting,
          dirty,
          setFieldValue,
          errors,
          submitCount,
        }) => (
          <Setup
            id="sources"
            show={showEdit}
            title={translateText('label', 'Set up traffic sources')}
            save={submitForm}
            close={() => setShowEdit(false)}
            afterClose={resetForm}
            isSaving={isSubmitting}
            maxWidth={670}
            hasChanges={dirty}
            className="sources-setup"
          >
            <ErrorFocus element={document.getElementById('sources')} />
            <p dangerouslySetInnerHTML={{ __html: infoText }} />
            <div className="section">
              {(isIntake || !hasLightPlan) && (
                <>
                  <Radio
                    id="radio-all"
                    label={translateText('label', 'Track all traffic sources')}
                    checked={values.type === SOURCE_TYPE_ALL || values.type === SOURCE_TYPE_EXCLUDE}
                    labelClassName="extra-margin"
                    onChange={() => setFieldValue('type', SOURCE_TYPE_ALL)}
                  />
                  <Checkbox
                    id="check-exc"
                    label={translateText('label', 'Exclude certain traffic sources')}
                    checked={values.type === SOURCE_TYPE_EXCLUDE}
                    labelClassName="extra-margin"
                    hasIndent
                    onChange={e =>
                      setFieldValue(
                        'type',
                        e.target.checked ? SOURCE_TYPE_EXCLUDE : SOURCE_TYPE_ALL,
                      )
                    }
                  />
                </>
              )}
              <Radio
                name="type"
                id="radio-inc"
                label={translateText('label', 'Select traffic sources')}
                checked={values.type === SOURCE_TYPE_INCLUDE}
                labelClassName="extra-margin"
                onChange={() => setFieldValue('type', SOURCE_TYPE_INCLUDE)}
              />
              {!isIntake && hasLightPlan && (
                <Radio
                  name="type"
                  id="radio-not"
                  label={translateText('label', "Don't track dynamic traffic sources")}
                  tooltip={translateText(
                    'tooltip/call-tracking',
                    'Choose this option if you only want to use offsite Call Tracking.',
                  )}
                  checked={values.type === SOURCE_TYPE_NOT}
                  labelClassName="extra-margin"
                  onChange={() => setFieldValue('type', SOURCE_TYPE_NOT)}
                />
              )}
            </div>
            {(values.type === SOURCE_TYPE_EXCLUDE || values.type === SOURCE_TYPE_INCLUDE) && (
              <div className="section">
                <SelectFromList
                  selected={values.selected}
                  items={Object.entries(SEARCHABLE_SOURCES).map(([source, searchTerms]) => ({
                    id: source,
                    name: source,
                    searchTerms,
                  }))}
                  availableLabel={translateText('label', 'Available traffic sources')}
                  tooltip={translateText(
                    'tooltip/call-tracking',
                    'Is the desired traffic source not listed? Please contact us via support@adcalls.nl and we will add the traffic source for you.',
                  )}
                  selectedLabel={
                    values.type === SOURCE_TYPE_INCLUDE
                      ? translateText('label', 'Selected traffic sources')
                      : translateText('label', 'Excluded traffic sources')
                  }
                  id="selected"
                  onChange={value => setFieldValue('selected', value)}
                  error={submitCount > 0 && !!errors.selected && (errors.selected as string)}
                />
              </div>
            )}
          </Setup>
        )}
      </Formik>
    </>
  );
}
