import { useState } from 'react';
import { Formik } from 'formik';

import AddButton from 'components/buttons/AddButton';
import TextField from 'components/input/TextField';
import Toggle from 'components/input/Toggle';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import Table from 'components/table/Table';
import { Slug } from 'globals/types';
import { useSlugs } from 'hooks/queries/support';
import { useTranslate } from 'hooks/translate';

type Props = {
  slugs: Slug[];
  type: 'article' | 'category';
  target: string;
  targetId: number;
};

export default function SlugSection({ slugs, type, target, targetId }: Props) {
  const translateText = useTranslate();
  const { addSlug, markAsMain, isMarkingAsMain } = useSlugs();
  const [showAdd, setShowAdd] = useState(false);

  async function save(values: Slug) {
    await addSlug({ ...values, type, target, targetId }).then(() => setShowAdd(false));
  }

  return (
    <>
      <h1>{translateText('label', 'Slugs')}</h1>
      <AddButton onClick={() => setShowAdd(true)} />
      <Table<Slug & { markAsMain?: undefined }>
        data={slugs}
        noWhiteBlock
        columns={{
          slug: { header: translateText('label', 'Slug') },
          main: {
            header: translateText('label', 'Is main slug'),
            forceBoolean: true,
          },
          markAsMain: {
            header: ' ',
            viewCondition: row => !row.main,
            button: {
              text: translateText('label', 'Mark as main'),
              onClick: row => markAsMain({ ...row, type, target, targetId }),
              className: 'btn btn-lightblue btn-small',
              disabled: isMarkingAsMain,
            },
          },
        }}
      />
      <Formik initialValues={{ slug: '', main: 0 }} onSubmit={save}>
        {({ values, handleChange, setFieldValue, submitForm, isSubmitting, resetForm }) => (
          <Setup
            show={showAdd}
            close={() => setShowAdd(false)}
            title={translateText('label', 'Add slug')}
            save={submitForm}
            isSaving={isSubmitting}
            afterClose={resetForm}
            maxWidth={330}
          >
            <InputWrapper label={translateText('label', 'Slug')}>
              <TextField
                name="slug"
                placeholder={translateText('label', 'Slug')}
                value={values.slug}
                onChange={handleChange}
              />
            </InputWrapper>
            <InputWrapper label={translateText('label', 'Main')}>
              <Toggle
                checked={!!values.main}
                onClick={() => setFieldValue('main', +!values.main)}
              />
            </InputWrapper>
          </Setup>
        )}
      </Formik>
    </>
  );
}
