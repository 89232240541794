import { useState } from 'react';
import { useLocation } from 'react-router';

import ConfigurationCard from 'components/configuration/ConfigurationCard';
import Skeleton from 'components/Skeleton';
import SupportParagraph from 'components/SupportParagraph';
import { useMessages } from 'contexts/Messages';
import {
  IMPLEMENTATION_TYPE_ADVANCED,
  IMPLEMENTATION_TYPE_STANDARD,
  PH_MASK,
} from 'globals/constants';
import { SUP_ART_ADVANCED_IMPLEMENTATION, SUP_ART_STANDARD_IMPLEMENTATION } from 'globals/support';
import { useHasManagerAccess } from 'hooks/access';
import { useDomainAuth, useImplementation } from 'hooks/queries/domain';
import { useTranslate } from 'hooks/translate';
import GoogleTagManager from './GoogleTagManager';
import SendImplementation from './SendImplementation';

const scriptUrl = process.env.REACT_APP_SCRIPT_URL;

export default function ScriptSection() {
  const location = useLocation();
  const translateText = useTranslate();
  const hasManagerAccess = useHasManagerAccess();
  const { addSuccessMessage, addErrorMessage } = useMessages();
  const { implementation, isLoading: isLoadingImplementation } = useImplementation();
  const { isLoading: isLoadingDomainAuth } = useDomainAuth();
  const isLoading = isLoadingImplementation || isLoadingDomainAuth;

  const [showGoogleTagManager, setShowGoogleTagManager] = useState(
    location.hash === '#google-tag-manager',
  );
  const [showSendEmail, setShowSendEmail] = useState(false);

  const step = implementation?.type === IMPLEMENTATION_TYPE_STANDARD ? 3 : 2;
  const title =
    implementation?.type === IMPLEMENTATION_TYPE_ADVANCED
      ? translateText('label', "Implement AdCalls' JavaScript and the number spans")
      : translateText('label', "Implement AdCalls' JavaScript");
  const supportArticleId =
    implementation?.type === IMPLEMENTATION_TYPE_ADVANCED
      ? SUP_ART_ADVANCED_IMPLEMENTATION
      : SUP_ART_STANDARD_IMPLEMENTATION;
  const infoText =
    implementation?.type === IMPLEMENTATION_TYPE_ADVANCED
      ? translateText(
          'call-tracking',
          'Implement the AdCalls JavaScript on the website and indicate per phone number whether it can be replaced with a dynamic phone number for Call Tracking using number spans.',
        )
      : translateText(
          'call-tracking',
          "AdCalls' JavaScript should be implemented on every page of your website before the closing head tag. You can place the JavaScript using our Google Tag Manager integration, manually in Google Tag Manager or you can place the JavaScript in the head of the website yourself.",
        );

  const script = implementation?.script_id
    ? `<script type="text/javascript" src="${scriptUrl}/${implementation?.script_id}.js" async></script>`
    : '';

  function copyToClipBoard() {
    navigator.clipboard
      ?.writeText(script)
      .then(() => addSuccessMessage(translateText('message', 'Copied to clipboard')))
      .catch(error => addErrorMessage(error));
  }

  return (
    <>
      <ConfigurationCard
        title={translateText('label', 'Step {x}: {label}', { x: step, label: title })}
        isLoading={isLoading}
        className="place-javascript"
      >
        <SupportParagraph
          articleId={supportArticleId}
          extraText={infoText}
          isLoading={isLoading}
          skeletonCount={3}
        />
        <div className="bold code-title">
          {isLoading ? <Skeleton width={80} /> : translateText('label', 'JavaScript')}
        </div>
        {isLoading ? (
          <Skeleton className="code" height={60} />
        ) : (
          <pre className={`code ${PH_MASK}`}>{script}</pre>
        )}
        <div
          className={
            'copy-script small' +
            (!isLoading ? ' link' : '') +
            (!implementation?.script_id || !navigator.clipboard ? ' disabled' : '')
          }
          role="button"
          onClick={copyToClipBoard}
        >
          {isLoading ? <Skeleton width={80} /> : translateText('label', 'Copy JavaScript')}
        </div>
        {!isLoading && hasManagerAccess && (
          <div className="buttons-wrapper">
            <button className="btn btn-lightblue" onClick={() => setShowGoogleTagManager(true)}>
              {translateText('label', 'Place JavaScript using Google Tag Manager')}
            </button>
            <button className="btn btn-lightblue" onClick={() => setShowSendEmail(true)}>
              {translateText('label', 'Send JavaScript by email')}
            </button>
          </div>
        )}
      </ConfigurationCard>
      <GoogleTagManager show={showGoogleTagManager} close={() => setShowGoogleTagManager(false)} />
      <SendImplementation show={showSendEmail} close={() => setShowSendEmail(false)} />
    </>
  );
}
