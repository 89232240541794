import logo from 'assets/images/icons/integrations/adcalls-api.svg';
import { TOUR_STEPS, useGuidedTour } from 'contexts/GuidedTour';
import { useTranslate } from 'hooks/translate';

export default function StartTour() {
  const translateText = useTranslate();
  const { setSection, setShowCancel } = useGuidedTour();

  return (
    <div className="start-tour">
      <img alt="AdCalls logo" src={logo} />
      <h1>{translateText('guided-tour', 'Welcome to AdCalls')}</h1>
      <p
        dangerouslySetInnerHTML={{
          __html: translateText(
            'guided-tour',
            'Start the tour, so you can immediately find your way in our dashboard. Do you have any questions, feedback or would you like to share your compliments? Please contact us via <a href=\\"tel:+31307400990\\">+31 (0)30 7400 990</a> or via <a href=\\"mailto:support@adcalls.nl\\">support@adcalls.nl</a>.',
          ),
        }}
      />
      <button className="btn btn-green" onClick={() => setSection(TOUR_STEPS[0])}>
        {translateText('guided-tour', 'Start the tour')}
      </button>
      <button className="btn btn-text" onClick={() => setShowCancel(true)}>
        {translateText('guided-tour', 'Skip tour')}
      </button>
    </div>
  );
}
