import { useLocation, useNavigate } from 'react-router';
import { useIsFetching } from '@tanstack/react-query';

import deleteIcon from 'assets/images/icons/delete-purple.svg';
import AddDomain from 'components/domain-overview/AddDomain';
import NoDomains from 'components/domain-overview/NoDomains';
import QueryTable from 'components/table/QueryTable';
import { ColumnSettings } from 'components/table/Table';
import Tooltip from 'components/Tooltip';
import { useTenant } from 'contexts/Tenant';
import { DEMO_DOMAIN } from 'globals/constants';
import { useHasMccAdminAccess } from 'hooks/access';
import { useRevokeDomain } from 'hooks/queries/reseller';
import { useUserMcc } from 'hooks/queries/user';
import { useTranslate } from 'hooks/translate';

type Domain = {
  id: number;
  title: string;
  status: number;
  reseller: string;
  invoice_label: string;
  alerts_count: { regular: number; critical: number };
};

export default function DomainOverview() {
  const navigate = useNavigate();
  const location = useLocation();
  const translateText = useTranslate();
  const { setSelectedDomain, setSelectedMcc, selectedMcc } = useTenant();
  const { mcc, isLoading: isLoadingUserMcc } = useUserMcc();
  const { revokeDomain } = useRevokeDomain();
  const isMccAdmin = useHasMccAdminAccess();

  const queryKey = ['domains'];
  const isLoadingDomains = useIsFetching({ queryKey: queryKey });

  function showNoDomains() {
    return mcc?.every(
      m =>
        m.domains.length === 0 ||
        (m.domains.length === 1 && Number(m.domains[0].id) === DEMO_DOMAIN),
    );
  }

  if (showNoDomains()) return <NoDomains />;

  function selectDomain(domain: Domain) {
    setSelectedDomain(Number(domain.id));
    if (selectedMcc === null) setSelectedMcc(-1);
    if (location.pathname !== '/overview') navigate('/overview');
  }

  const columns: ColumnSettings<Domain & { revoke?: undefined }> = {
    id: {
      header: {
        name: translateText('label', 'ID'),
        tooltip: translateText(
          'tooltip/domain',
          'This is the unique identifier that belongs to the domain.',
        ),
      },
    },
    title: {
      header: {
        name: translateText('label', 'Domain'),
        tooltip: translateText(
          'tooltip/domain-overview',
          'The website for which the services of AdCalls are used.',
        ),
      },
      className: 'bold',
      private: true,
    },
    status: {
      header: {
        name: translateText('label', 'Active'),
        tooltip: translateText('tooltip/domain-overview', 'The status of the domain.'),
      },
      bigDot: {
        classes: {
          0: 'inactive',
          20: 'active',
        },
      },
    },
    invoice_label: {
      header: {
        name: translateText('label', 'Pricing plan'),
        tooltip: translateText(
          'tooltip/domain-overview',
          'The pricing plan associated with the domain.',
        ),
      },
    },
    reseller: {
      header: {
        name: translateText('label', 'Customer center'),
        tooltip: translateText(
          'tooltip/domain-overview',
          'The customer center that this domain is part of.',
        ),
      },
      private: true,
    },
    alerts_count: {
      header: {
        name: translateText('label', 'Alerts'),
        tooltip: translateText(
          'tooltip/domain-overview',
          'The number of critical alerts compared to the total number of alerts. A critical alert usually refers to an incomplete implementation.',
        ),
      },
      customValue: value => (
        <span>
          {value?.regular ?? 0} / <span className="purple-text">{value?.critical ?? 0}</span>
        </span>
      ),
    },
  };

  if (isMccAdmin) {
    columns.revoke = {
      header: { name: ' ', className: 'revoke-header' },
      customValue: (value, row) => (
        <Tooltip
          text={translateText('label', 'Revoke access')}
          html={
            <img
              alt={translateText('label', 'Revoke access')}
              src={deleteIcon}
              className="clickable-icon"
              onClick={e => {
                e.stopPropagation();
                revokeDomain(row, selectedMcc!);
              }}
            />
          }
        />
      ),
    };
  }

  return (
    <>
      <h1>{translateText('label', 'Domain overview')}</h1>
      <QueryTable<Domain>
        tableKey={queryKey}
        columns={columns}
        endpoint="/domain/index"
        filters={{
          domain: false,
          custom: {
            expand: ['invoice_label', 'alerts_count'],
            fields: 'id,title,status,reseller',
            mcc_id: selectedMcc !== -1 ? selectedMcc : null,
          },
        }}
        pagination={{ defaultLimit: 10 }}
        isResponsive
        canSearch
        onRowClick={domain => selectDomain(domain)}
        className="domain-overview-table"
        extraTopLeft={<AddDomain isLoading={isLoadingUserMcc || !!isLoadingDomains} />}
        download="csv"
        isLoading={isLoadingUserMcc}
      />
    </>
  );
}
