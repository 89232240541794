import { useTenant } from 'contexts/Tenant';
import { useSync } from 'hooks/queries/script';
import { useTranslate } from 'hooks/translate';

export default function SyncScript() {
  const translateText = useTranslate();
  const { selectedDomainData } = useTenant();
  const { syncSpecificScript, syncAllScripts, isSyncing } = useSync();

  return (
    <>
      <h1>{translateText('menu', 'Sync script')}</h1>
      <div className="white-block">
        <h3 className="gap-bottom">{translateText('label', 'Sync script for specific domain')}</h3>
        {selectedDomainData ? (
          <button className="btn btn-lightblue" onClick={syncSpecificScript} disabled={isSyncing}>
            {translateText('message', 'Sync script for {domain}', {
              domain: selectedDomainData.name,
            })}
          </button>
        ) : (
          <p>{translateText('text', 'Select a domain in the domain selector.')}</p>
        )}
      </div>
      <div className="white-block">
        <h3 className="gap-bottom">{translateText('label', 'Sync script for all domains')}</h3>
        <button className="btn btn-lightblue" onClick={syncAllScripts} disabled={isSyncing}>
          {translateText('message', 'Sync all scripts')}
        </button>
      </div>
    </>
  );
}
