export default {
    "Click on the + to indicate in which format the telephone number for Call Tracking may be displayed on the website.": "Klik op de + om aan te geven in welk format het telefoonnummer voor Call Tracking getoond mag worden op de website.",
    "Click the + to create or edit the offsite phone numbers. With offsite phone numbers you can track media and locations outside your website.": "Klik op de + om de offsite telefoonnummers aan te maken of te wijzigen. Met offsite telefoonnummers kun je media en locaties buiten je website meetbaar maken.",
    "Click the + to set the locations for Call Tracking.": "Klik op de + om de locaties in te stellen voor Call Tracking.",
    "Click the + to set the traffic sources for Call Tracking.": "Klik op de + om de verkeersbronnen in te stellen voor Call Tracking.",
    "Do you want the amount to be automatically debited from your account? Then switch on automatic debit.": "Wil je dat het bedrag automatisch van je rekening wordt afgeschreven? Vink dan automatische incasso aan.",
    "Do you want to save the current invoice profile? The information is saved and can be reselected with a new request.": "Wil je het huidige facturatieprofiel opslaan? De informatie wordt bewaard en kan opnieuw geselecteerd worden bij een nieuwe aanvraag.",
    "Do you want to update the current invoice profile? Then adjust it here. The information will be changed for the next invoice.": "Wil je het huidige facturatieprofiel updaten? Pas deze dan hier aan. Vanaf de eerstvolgende factuur zullen de facturatie gegevens zijn gewijzigd.",
    "Enter the URL of the website for which you wish to use AdCalls. We recommend copying the address from your browser to make sure you don't make any typos.": "Vul hier de URL in van de website waarvoor je AdCalls wenst in te zetten. Wij adviseren het adres vanuit je browser te kopi\u00ebren, zodat je zeker weet dat je geen typefouten maakt.",
    "Enter the e-mail address to which we can send the invoices.": "Vul hier het e-mailadres in waar wij de facturen naartoe mogen sturen.",
    "If the domain does not need to be delivered immediately, you can indicate this here. For example, because the delivery does not have to take place until later. Please use the comment field for additional information about the request.": "Zodra het domein niet direct opgeleverd hoeft te worden, kun je dat hier aangeven. Bijvoorbeeld omdat de oplevering pas later plaats hoeft te vinden. Gebruik het opmerkingenveld voor aanvullende informatie over de aanvraag.",
    "Indicate when the new contract may start.": "Geef aan vanaf wanneer het nieuwe contract mag ingaan.",
    "Select an existing invoice profile here. The different domains will appear on the same invoice.": "Selecteer hier een al bestaand facturatieprofiel. De verschillende domeinen zullen zo op dezelfde factuur komen te staan.",
    "Select the customer center this domain needs to be added to.": "Selecteer het klantcentrum waaraan het domein moet worden toegevoegd.",
    "Select the desired contract duration.": "Selecteer de gewenste contractduur.",
    "Select the desired pricing plan.": "Selecteer het gewenste prijsplan.",
    "The offsite phone numbers that are requested.": "De offsite telefoonnummers die aangevraagd worden.",
    "The traffic sources that will be tracked for Call Tracking.": "De verkeersbronnen die gemeten zullen worden voor Call Tracking.",
    "With offsite phone numbers you can track media and locations outside your website. An 'offsite phone number' is hard-coded in a place outside your website. This way you know exactly how many calls have been made from those specific locations.": "Met offsite telefoonnummers kun je media en locaties buiten je website meetbaar maken. Een 'offsite telefoonnummer' staat hard-gecodeerd weergegeven op een plek buiten je website. Zodoende weet je precies hoeveel oproepen er hebben plaatsgevonden vanaf die specifieke locaties."
}