import { useState } from 'react';
import { useParams } from 'react-router';
import { Formik } from 'formik';

import EditableJson from 'components/admin/EditableJson';
import Checkbox from 'components/input/Checkbox';
import Select from 'components/input/Select';
import Loader from 'components/Loader';
import Table from 'components/table/Table';
import { useHasAdminAccess } from 'hooks/access';
import {
  useAdminChangeRequest,
  useChangeRequestEvaluation,
  useUpdateChangeRequest,
} from 'hooks/queries/domain-change-request';
import { useTranslate } from 'hooks/translate';

export default function ChangeRequestDetails() {
  const translateText = useTranslate();
  const isAdmin = useHasAdminAccess();
  const params = useParams();
  const changeRequestId = Number(params.id);
  const [skipNumberCheck, setSkipNumberCheck] = useState(false);

  const { changeRequest, isLoading } = useAdminChangeRequest(
    !isNaN(changeRequestId) ? changeRequestId : null,
  );
  const { evaluateChangeRequest, isSaving } = useChangeRequestEvaluation();
  const { updateRequest } = useUpdateChangeRequest();

  const options = [
    { value: 1, text: translateText('label', 'Open') },
    { value: 2, text: translateText('label', 'Pending') },
    { value: 3, text: translateText('label', 'On hold') },
    { value: 4, text: translateText('label', 'Executed') },
    { value: 5, text: translateText('label', 'Verified') },
    { value: 6, text: translateText('label', 'Declined') },
  ];

  if (isLoading) return <Loader />;

  if (!changeRequest) {
    return (
      <div className="white-block">
        <div className="no-data">{translateText('label', 'No data')}</div>
      </div>
    );
  }

  function getSourcesText(included: number, sources: string[]): string {
    let text = '';
    if (included === 0 && sources?.length === 0) {
      text += translateText('label', 'All traffic sources');
    } else if (included === 0) {
      text += translateText('label', 'Excluded') + ': ';
    }

    if (sources) text += sources.join(', ');
    return text;
  }

  return (
    <div className="change-request-details">
      <h1>{translateText('label', 'Request')}</h1>
      <Table
        columns={{
          id: { header: translateText('label', 'ID') },
          created_at: { header: translateText('label', 'Request date'), date: true },
          domain_title: { header: translateText('label', 'Domain') },
          requesting_user: { header: translateText('label', 'Requesting user') },
          status: { header: translateText('label', 'Status') },
          type: { header: translateText('label', 'Change') },
          updated_at: { header: translateText('label', 'Last change date'), date: true },
          needed_numbers: {
            header: { name: translateText('label', 'Needed numbers') },
            customValue: value => <pre>{JSON.stringify(value, undefined, 4)}</pre>,
          },
        }}
        data={[changeRequest]}
      />
      {isAdmin && (
        <div className="white-block">
          <Formik
            initialValues={{
              status: options.find(x => x.text === changeRequest.status)?.value ?? '1',
            }}
            onSubmit={values =>
              updateRequest({ id: changeRequestId, status: Number(values.status) })
            }
            enableReinitialize
          >
            {({ values, submitForm, handleChange }) => (
              <div className="form-row">
                <div className="name-label">{translateText('label', 'Status')}</div>
                <div className="value-wrapper">
                  <Select
                    name="status"
                    value={values.status}
                    onChange={handleChange}
                    options={options}
                  />
                  <button onClick={submitForm} className="btn btn-green margin-left">
                    {translateText('label', 'Save')}
                  </button>
                </div>
              </div>
            )}
          </Formik>
        </div>
      )}
      {changeRequest.type_name === 'sources-change' && (
        <div className="white-block">
          <div className="form-row">
            <div className="name-label">
              {translateText('label', 'Requested traffic sources setup')}
            </div>
            <div className="value-wrapper">
              {getSourcesText(
                changeRequest.wanted_changes.included,
                changeRequest.wanted_changes.sources,
              )}
            </div>
          </div>
        </div>
      )}
      <Formik
        initialValues={{ json: JSON.stringify(changeRequest) }}
        onSubmit={values =>
          updateRequest({ id: changeRequestId, changes: JSON.parse(values.json).wanted_changes })
        }
        enableReinitialize
      >
        {({ values, submitForm, setFieldValue, dirty, isSubmitting }) => (
          <div className="white-block">
            <EditableJson
              id="json-intake"
              initialView="preview"
              value={values.json}
              canEdit={isAdmin ? ['wanted_changes'] : false}
              onChange={value => setFieldValue('json', value)}
            />
            {isAdmin && (
              <div className="form-button">
                <button
                  onClick={submitForm}
                  className="btn btn-lightblue"
                  disabled={!dirty || isSubmitting}
                >
                  {translateText('label', 'Save')}
                </button>
              </div>
            )}
          </div>
        )}
      </Formik>
      {isAdmin && (
        <div className="evaluation-buttons-wrapper">
          <div className="skip-number-check">
            <Checkbox
              id="skip_number_check"
              label={translateText('label', 'Skip number check')}
              checked={skipNumberCheck}
              onChange={e => setSkipNumberCheck(e.target.checked)}
            />
          </div>
          <div className="buttons">
            <div className="evaluation-buttons">
              <button
                disabled={isSaving}
                className="btn btn-purple"
                onClick={() =>
                  evaluateChangeRequest({
                    id: changeRequestId,
                    evaluation: 'decline',
                    skipNumberCheck,
                  })
                }
              >
                {translateText('label', 'Decline')}
              </button>
              <button
                disabled={isSaving}
                className="btn btn-green"
                onClick={() =>
                  evaluateChangeRequest({
                    id: changeRequestId,
                    evaluation: 'approve',
                    skipNumberCheck,
                  })
                }
              >
                {translateText('label', 'Approve')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
