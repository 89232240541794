import { useRef, useState } from 'react';

import error from 'assets/images/icons/messages/error.svg';
import Controls from 'components/configuration/debug-mode/Controls';
import Loader from 'components/Loader';
import { STATUS_INVALID, STATUS_LOADING, useDebugMode } from 'contexts/DebugMode';
import { useAcceptedUrls } from 'hooks/queries/domain';
import { useTranslate } from 'hooks/translate';
import Resizer from './Resizer';
import SizeSelector from './SizeSelector';

export type BrowserSize = 'desktop' | 'tablet' | 'mobile' | 'custom';

export default function Browser() {
  const translateText = useTranslate();
  const { status, mainIframeRef, verificationIframeRef } = useDebugMode();
  const { isLoading: isLoadingAcceptedUrls } = useAcceptedUrls();

  const containerRef = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState<BrowserSize>('desktop');
  const [dimensions, setDimensions] = useState({ width: 900, height: 580 });
  const [isResizing, setIsResizing] = useState(false);

  const isLoading = status === STATUS_LOADING || isLoadingAcceptedUrls;

  let wrapperClassName = 'iframe-wrapper';
  if (isLoading) wrapperClassName += ' loading';
  if (isResizing) wrapperClassName += ' resizing';

  return (
    <div className="frame white-block">
      <Controls />
      {status === STATUS_INVALID && (
        <p className="invalid-page purple-text">
          <img src={error} alt="error" />
          {translateText(
            'message',
            'Please note: this page does not contain the AdCalls JavaScript. The debug functionality cannot be used without the script. The cookies may still need to be accepted.',
          )}
        </p>
      )}
      <div className="iframe-container" ref={containerRef}>
        <div className={wrapperClassName} style={dimensions}>
          <iframe id="main-iframe" ref={mainIframeRef} title="Website" />
          {isLoading && (
            <div className="iframe-overlay">
              <Loader />
            </div>
          )}
          {isResizing && <div className="block-user-select" />}
          {size === 'custom' && (
            <Resizer
              dimensions={dimensions}
              setDimensions={setDimensions}
              setIsResizing={setIsResizing}
              containerRef={containerRef}
            />
          )}
        </div>
        <iframe id="verification-iframe" ref={verificationIframeRef} title="Verification" />
      </div>
      <SizeSelector
        size={size}
        setSize={setSize}
        dimensions={dimensions}
        setDimensions={setDimensions}
      />
    </div>
  );
}
