import moment from 'moment';

import { useRoutes } from 'contexts/Routes';
import { useKnowledgeCenterCheck } from 'hooks/knowledge-center';
import { useTranslate } from 'hooks/translate';

type Props = {
  placement: 'center' | 'right';
};

export default function Footer({ placement }: Props) {
  const translateText = useTranslate();
  const { navigationDisabled } = useRoutes();
  const isKnowledgeCenter = useKnowledgeCenterCheck();

  return (
    <div id="footer" className={placement + (isKnowledgeCenter ? ' knowledge-center' : '')}>
      {!navigationDisabled && (
        <div className="links">
          <a href="https://adcalls.nl/algemene-voorwaarden/" target="_blank">
            {translateText('label', 'Conditions')}
          </a>
          {placement !== 'center' ? <span>-</span> : null}
          <a href="https://adcalls.nl/privacy-statement/" target="_blank">
            {translateText('label', 'Privacy')}
          </a>
        </div>
      )}
      <div id="copyright">Copyright &copy; AdCalls {moment().year()}</div>
    </div>
  );
}
