import { useFormikContext } from 'formik';

import Checkbox from 'components/input/Checkbox';
import Skeleton from 'components/Skeleton';
import Cell from './Cell';
import { ColumnSetting } from './Table';

type Props<T> = {
  row: T;
  previousData?: T | undefined;
  index: number;
  multiSelect?: {
    selected: (string | number)[];
    update: (row: T | number | string) => void;
    canSelectAll?: boolean;
    fullRow?: boolean;
  };
  followNumber: number | null;
  columns: [string, ColumnSetting<T>][];
  onRowClick?: (row: T) => void;
  isResponsive: boolean;
  isLoading: boolean;
};

export default function Row<T extends Record<string, unknown>>({
  row,
  previousData,
  index,
  multiSelect,
  followNumber,
  columns,
  onRowClick,
  isResponsive,
  isLoading,
}: Props<T>) {
  const { values } = useFormikContext<{ columns: string[] }>();

  let rowSelect = null;
  if (multiSelect) {
    const id = (row.id !== undefined ? row.id : index) as number | string;
    rowSelect = (
      <td className="select">
        {isLoading ? (
          <Skeleton width={14} />
        ) : (
          <Checkbox
            checked={multiSelect.selected.includes(id)}
            onChange={() => multiSelect.update(multiSelect.fullRow ? row : id)}
            id={String(id)}
          />
        )}
      </td>
    );
  }

  return (
    <tr>
      {rowSelect}
      {followNumber !== null && (
        <td className="follow-number">
          {isLoading ? <Skeleton width={followNumber.toString().length * 9} /> : followNumber}
        </td>
      )}
      {columns
        .filter(column => values.columns.includes(column[0]))
        .map((column, columnIndex) => {
          const [columnName, columnSettings] = column;
          return (
            <Cell
              key={columnIndex}
              column={columnName}
              row={row}
              rowIndex={index}
              settings={columnSettings}
              prevValue={previousData ? previousData[columnName] ?? '-' : undefined}
              onRowClick={onRowClick}
              isResponsive={isResponsive}
              isLoading={isLoading}
            />
          );
        })}
    </tr>
  );
}
