import { useState } from 'react';

import Skeleton from 'components/Skeleton';
import { useTranslate } from 'hooks/translate';
import SubmitTicket from './SubmitTicket';

type Props = {
  isLoading?: boolean;
};

export default function SubmitTicketSection({ isLoading = false }: Props) {
  const translateText = useTranslate();
  const [show, setShow] = useState(false);

  if (isLoading) return <Skeleton width={400} height={150} />;
  return (
    <div className="submit-ticket-wrapper">
      <p>{translateText('label', "Can't find the answer to your question?")}</p>
      <button className="btn btn-lightblue" onClick={() => setShow(true)}>
        {translateText('label', 'Submit a ticket')}
      </button>
      <SubmitTicket
        show={show}
        close={() => setShow(false)}
        title={translateText('label', 'Submit ticket')}
        infoText={translateText(
          'text',
          'If you have not been able to solve your problem via our support page, request a support ticket using the form below.',
        )}
      />
    </div>
  );
}
