import { useState } from 'react';
import { isEmpty, omit } from 'lodash';

import StopNumbers from 'components/admin/StopNumbers';
import QueryTable from 'components/table/QueryTable';
import {
  SIP_TRUNK_DSTNY_PBX_AD_CALLS,
  SIP_TRUNK_DSTNY_PBX_ELEVATE,
  SIP_TRUNK_DSTNY_PBX_LIVE,
  SIP_TRUNK_DSTNY_PBX_TEST,
  SIP_TRUNK_DSTNY_PROBLEMEN_KWIJT,
  SIP_TRUNK_DSTNY_VPS_LIVE,
  SIP_TRUNK_DSTNY_VPS_LIVE_2,
  SIP_TRUNK_DSTNY_VPS_TEST,
  SIP_TRUNK_STEAM_CONNECT_LIVE,
  SIP_TRUNK_STEAM_CONNECT_PROD,
  SIP_TRUNK_STEAM_CONNECT_TEST,
  SIP_TRUNK_VOXBONE,
} from 'globals/constants';
import { useTranslate } from 'hooks/translate';

type NumberStopRange = {
  id: number;
  start: string;
  last: string;
  amount: number;
  requested_for: string | null;
  series: string;
  nums: string[];
  sip_trunk: string;
};

export default function StopRequests() {
  const translateText = useTranslate();
  // This is a record with the row ID as key and the expected value of StopNumbers.numbers as value. For example: {123: {"3120000001": "Voxbone", "3120000002": "Voxbone"}}.
  const [selectedRows, setSelectedRows] = useState<Record<number, Record<string, string>>>({});
  const [logs, setLogs] = useState<(string | string[])[]>([]);

  function updateSelected(range: NumberStopRange | NumberStopRange[]) {
    // If it's an array it means the whole current page has been (de)selected so update to be all given values
    if (Array.isArray(range)) {
      const rows: Record<number, Record<string, string>> = {};
      range.forEach(r => {
        const numbers: Record<string, string> = {};
        r.nums.forEach(n => (numbers[n] = r.sip_trunk));
        rows[r.id] = numbers;
      });
      setSelectedRows(rows);
      return;
    }
    if (selectedRows.hasOwnProperty(range.id)) {
      setSelectedRows(omit(selectedRows, range.id));
    } else {
      const numbers: Record<string, string> = {};
      range.nums.forEach(n => (numbers[n] = range.sip_trunk));
      setSelectedRows({ ...selectedRows, ...{ [range.id]: numbers } });
    }
  }

  const numbers = isEmpty(selectedRows)
    ? {}
    : Object.values(selectedRows).reduce((a, v) => ({ ...a, ...v }));

  return (
    <>
      <h1>{translateText('label', 'Phone numbers stop requests')}</h1>
      {logs.length > 0 && (
        <pre>
          <h2>{translateText('label', 'Stop at Voxbone logs')}</h2>
          {logs.map((log, i) => {
            if (typeof log === 'string') {
              return <p key={i}>{log}</p>;
            }
            return (
              <ul key={i}>
                {log.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            );
          })}
        </pre>
      )}
      <QueryTable<NumberStopRange>
        tableKey={['admin', 'numbers', 'stopRequests']}
        columns={{
          start: {
            header: { name: translateText('label', 'Start'), sort: true },
            noNumberFormat: true,
          },
          last: {
            header: { name: translateText('label', 'Last'), sort: true },
            noNumberFormat: true,
          },
          amount: { header: { name: translateText('label', 'Amount'), sort: true } },
          requested_for: {
            header: { name: translateText('label', 'Request date'), sort: true },
            date: true,
            search: 'stop_requested_for',
          },
          series: { header: { name: translateText('label', 'Dstny series'), sort: true } },
          sip_trunk: {
            header: translateText('label', 'SIP trunk'),
            search: {
              [SIP_TRUNK_DSTNY_PBX_LIVE]: 'Dstny live (PBX)',
              [SIP_TRUNK_DSTNY_PBX_TEST]: 'Dstny Test (PBX)',
              [SIP_TRUNK_DSTNY_PBX_AD_CALLS]: 'Dstny AdCalls (PBX)',
              [SIP_TRUNK_DSTNY_PBX_ELEVATE]: 'Dstny Elevate (PBX)',
              [SIP_TRUNK_DSTNY_VPS_LIVE]: 'Dstny Live (VPS)',
              [SIP_TRUNK_DSTNY_VPS_LIVE_2]: 'Dstny Live 2 (VPS)',
              [SIP_TRUNK_DSTNY_PROBLEMEN_KWIJT]: 'Dstny Problemen Kwijt',
              [SIP_TRUNK_DSTNY_VPS_TEST]: 'Dstny Test (VPS)',
              [SIP_TRUNK_VOXBONE]: 'Voxbone',
              [SIP_TRUNK_STEAM_CONNECT_PROD]: 'Steam-connect Prod',
              [SIP_TRUNK_STEAM_CONNECT_LIVE]: 'Steam-connect Live (outbound)',
              [SIP_TRUNK_STEAM_CONNECT_TEST]: 'Steam-connect Test',
            },
          },
        }}
        endpoint="/number-stop-range/index"
        multiSelect={{
          selected: Object.keys(selectedRows).map(id => Number(id)),
          update: numberRange => updateSelected(numberRange as NumberStopRange | NumberStopRange[]),
          fullRow: true,
          canSelectAll: true,
        }}
        filters={{ domain: false }}
        pagination
        refresh
        extraTopLeft={<StopNumbers numbers={numbers} setLogs={setLogs} />}
      />
    </>
  );
}
