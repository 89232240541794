export default {
    "Change the evaluation of the call here.": "Wijzig hier de beoordeling van de oproep.",
    "Choose this option if you only want to use offsite Call Tracking.": "Kies deze optie wanneer je alleen gebruik wilt maken van offsite Call Tracking.",
    "Enter the name under which the calls may be registered in our dashboard.": "Vul de benaming in waaronder de oproepen geregistreerd mogen worden in ons dashboard.",
    "Enter the name you wish to associate with the location. For example 'Location Amsterdam' or 'Support department'.": "Vul de benaming in die je wenst te koppelen aan de locatie. Bijvoorbeeld \u2018Locatie Amsterdam\u2019 of \u2018Support-afdeling\u2019.",
    "Here you will find a log of the previous evaluations associated with this call.": "Hier vind je een log van de eerdere beoordelingen die aan deze oproep zijn gekoppeld.",
    "Here you will find more calls from the same sender.": "Hier vind je meer oproepen van dezelfde afzender.",
    "Here you will find the status of the call per integration.": "Hier vind je de status van de oproep per integratie.",
    "Is the desired traffic source not listed? Please contact us via support@adcalls.nl and we will add the traffic source for you.": "Staat de gewenste verkeersbron niet in de lijst? Neem dan contact met ons op via support@adcalls.nl en wij voegen de verkeersbron alsnog voor je toe.",
    "Listen to the recording of this call here.": "Luister hier de gespreksopname van deze oproep terug.",
    "Select the area code that may be used for the offsite phone number.": "Kies het netnummer dat mag worden ingezet voor het offsite telefoonnummer.",
    "Select the desired country code that may be used for the offsite phone number.": "Kies de gewenste landcode dat mag worden ingezet voor het offsite telefoonnummer.",
    "This is the area code of the Call Tracking phone numbers that will be distributed on the website.": "Dit is het netnummer van de telefoonnummers voor Call Tracking die uitgegeven zullen worden op de website.",
    "This is the campaign that the displayed advertisement is part of.": "Dit is de campagne waar de getoonde advertentie onderdeel van uitmaakt.",
    "This is the keyword used on the basis of which the ad was displayed.": "Dit is het ingezette zoekwoord op basis waarvan de advertentie is vertoond.",
    "This is the last page visited before a call was registered from the visitor.": "Dit is de laatstbezochte pagina alvorens er een oproep is geregistreerd van de bezoeker.",
    "This is the phone number to which calls for Call Tracking will be forwarded.": "Dit is het telefoonnummer waarnaar de oproepen voor Call Tracking zullen worden doorgeschakeld.",
    "This is the phone number to which the calls for offsite Call Tracking will be forwarded.": "Dit is het telefoonnummer waarnaar de oproepen voor offsite Call Tracking zullen worden doorgeschakeld.",
    "This option determines how the query is matched.": "Deze optie bepaalt de wijze waarop de zoekopdracht gematcht is."
}