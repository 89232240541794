import { LATEST_DPA_VERSION } from 'globals/constants';
import { useTranslate } from 'hooks/translate';
import Checkbox from './input/Checkbox';
import Tooltip from './Tooltip';

type CheckboxProps = {
  value: boolean;
  setValue: (value: boolean) => void;
  error?: false | string;
};

type Props = {
  termsAndConditions: CheckboxProps;
  agreement: CheckboxProps;
  privacy?: CheckboxProps;
  className?: string;
  disabled?: boolean;
};

const apiUrl = process.env.REACT_APP_API_URL;

export default function TermsAndConditions({
  termsAndConditions,
  agreement,
  privacy,
  className,
  disabled,
}: Props) {
  const translateText = useTranslate();

  function getLabel(filename: string, text: string, tooltip?: string) {
    return (
      <>
        {translateText('label', 'I accept the')}{' '}
        <a href={apiUrl + '/assets/' + filename} target="_blank">
          {text}
        </a>
        {tooltip && <Tooltip text={tooltip} />}
      </>
    );
  }

  return (
    <div id="terms-and-agreement" className={className}>
      <Checkbox
        id="termsAndConditions"
        label={getLabel(
          'AdCalls_Voorwaarden.pdf',
          translateText('label', 'AdCalls terms and conditions'),
        )}
        onChange={e => termsAndConditions.setValue(e.target.checked)}
        checked={termsAndConditions.value}
        error={termsAndConditions.error}
        disabled={disabled}
        labelClassName="extra-margin"
      />
      <Checkbox
        id="agreement"
        label={getLabel('AdCalls_Overeenkomst.pdf', translateText('label', 'AdCalls agreement'))}
        onChange={e => agreement.setValue(e.target.checked)}
        checked={agreement.value}
        error={agreement.error}
        disabled={disabled}
        labelClassName="extra-margin"
      />
      {privacy && (
        <Checkbox
          id="privacy"
          label={getLabel(
            `AdCalls_Verwerkersovereenkomst_v${LATEST_DPA_VERSION}.pdf`,
            translateText('label', 'AdCalls processor agreement'),
            translateText(
              'text',
              "By accepting the 'AdCalls Processor Agreement' you declare that you are authorized as a 'Data Controller'. Are you not authorized? Then invite the 'Data Controller' to the AdCalls domain as soon as you have completed the application. So that he or she can still accept the processing agreement via our dashboard.",
            ),
          )}
          onChange={e => privacy.setValue(e.target.checked)}
          checked={privacy.value}
          error={privacy.error}
          disabled={disabled}
          labelClassName="extra-margin"
        />
      )}
    </div>
  );
}
