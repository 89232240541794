import gif from 'assets/images/guided-tour/03-notification-center.gif';
import { useTranslate } from 'hooks/translate';
import BaseTour from './BaseTour';

export default function NotificationCenterTour() {
  const translateText = useTranslate();
  return (
    <BaseTour
      title={translateText('guided-tour', 'Notification center')}
      image={gif}
      description={
        <>
          <p
            dangerouslySetInnerHTML={{
              __html: translateText(
                'guided-tour',
                'You will find all your notifications in the <b>notification center</b>. The notifications will ensure that you get the most out of our services. Click on your avatar to open the notification center. The notifications with a pink color are urgent. If you click on a notification, you can read the entire notification.',
              ),
            }}
          />
          <p>
            {translateText(
              'guided-tour',
              'You will also find your account settings and the invitations under your avatar.',
            )}
          </p>
        </>
      }
    />
  );
}
