import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { omit } from 'lodash';

import { useMessages } from 'contexts/Messages';
import { getIntakeValues } from 'globals/helpers';
import { AdminIntake, Intake } from 'globals/types';
import { useSetQueryData, useSetTableData, useTableData } from 'hooks/data';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';
import { IntakeFormValues } from 'pages/authenticated/requests/Intake';

const adminExpand =
  'needed_numbers,chargebee_customer_id,reseller_id,account_manager,status_id,contract_duration,trial_end_date';

export function useIntake(id: number | null = null) {
  const { fetchData } = useFetch();
  const intakes = useTableData<Intake>(['requests', 'intakes']);

  const { data, isFetching, isError, error } = useQuery({
    queryKey: ['intake', id],
    queryFn: (): Promise<Intake> =>
      fetchData('/domain-intake/' + id, {
        queryParams: { expand: 'reseller_id,contract_duration' },
      }),
    enabled: id !== null,
    meta: { noError: true },
    initialData: () => intakes?.find(i => i.id === id),
  });

  return { intake: data, isLoading: isFetching, isError, error };
}

export function useCreateIntake() {
  const queryClient = useQueryClient();
  const { fetchData } = useFetch();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: ({ intake, concept }: { intake: IntakeFormValues; concept?: boolean }) =>
      fetchData('/domain-intake/create', {
        method: 'POST',
        bodyParams: getIntakeValues(intake, concept),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
      queryClient.invalidateQueries({ queryKey: ['userMcc'] });
      queryClient.invalidateQueries({ queryKey: ['domains'] });
    },
  });

  return { createIntake: mutateAsync, isLoading: isPending };
}

export function useUpdateIntake() {
  const queryClient = useQueryClient();
  const { fetchData } = useFetch();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: ({
      intake,
      concept,
    }: {
      intake: IntakeFormValues & { id: number };
      concept?: boolean;
    }) =>
      fetchData('/domain-intake/update', {
        method: 'PUT',
        queryParams: { id: intake.id },
        bodyParams: getIntakeValues(intake, concept),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['requests'] });
      queryClient.invalidateQueries({ queryKey: ['userMcc'] });
      queryClient.invalidateQueries({ queryKey: ['domains'] });
    },
  });

  return { updateIntake: mutateAsync, isLoading: isPending };
}

export function useUpdateAdminIntake() {
  const translateText = useTranslate();
  const setQueryData = useSetQueryData();
  const setTableData = useSetTableData();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: ({ id, intake }: { id: number; intake: AdminIntake }) =>
      fetchData('/domain-intake/update', {
        method: 'PUT',
        queryParams: { id, expand: adminExpand },
        bodyParams: {
          ...omit(intake, ['pricing_plan_id', 'status_id']),
          account_manager: intake.account_manager.id,
          status: intake.status_id,
          chargebee_pricing_plan_id: intake.pricing_plan_id,
        },
      }),
    onSuccess: (data, variables) => {
      setTableData(['admin', 'intake'], oldData => oldData.map(i => (i.id === data.id ? data : i)));
      setQueryData(['admin', 'intakeDetails', variables.id], () => data);
      addSuccessMessage(translateText('admin/text', 'The domain intake has been updated'));
    },
  });

  return { updateIntake: mutateAsync, isSaving: isPending };
}

export function useAdminIntakeDetails(id: number | null = null) {
  const { fetchData } = useFetch();
  const intakeData = useTableData<AdminIntake>(['admin', 'intake']);

  const { data, isFetching } = useQuery({
    queryKey: ['admin', 'intakeDetails', id],
    queryFn: (): Promise<AdminIntake> =>
      fetchData('/domain-intake/view', {
        queryParams: { id, expand: adminExpand },
      }),
    enabled: id !== null,
    initialData: () => intakeData?.find(i => i.id === id),
  });

  return { intake: data, isLoading: isFetching };
}

export function useIntakeImport() {
  const { addSuccessMessage } = useMessages();
  const translateText = useTranslate();
  const { fetchData } = useFetch();

  const { mutate, isPending } = useMutation({
    mutationFn: (formData: FormData) =>
      fetchData('/domain-intake/import', {
        method: 'POST',
        bodyParams: formData,
      }),
    onSuccess: () =>
      addSuccessMessage(
        translateText('admin/text', 'The domain intake has been imported successfully'),
      ),
  });

  return {
    importIntake: mutate,
    isLoading: isPending,
  };
}

export function useIntakeEvaluation() {
  const { addSuccessMessage } = useMessages();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const setQueryData = useSetQueryData();
  const setTableData = useSetTableData();

  const { mutate, isPending } = useMutation({
    mutationFn: ({
      id,
      evaluation,
      skipNumberCheck,
    }: {
      id: number;
      evaluation: 'approve' | 'decline';
      skipNumberCheck: boolean;
    }) =>
      fetchData('/domain-intake/' + evaluation, {
        method: 'POST',
        queryParams: {
          id,
          skip_number_check: skipNumberCheck,
          expand: adminExpand,
        },
      }),
    onSuccess: (data, variables) => {
      setTableData(['admin', 'intake'], oldData => oldData.map(i => (i.id === data.id ? data : i)));
      setQueryData(['admin', 'intakeDetails', variables.id], () => data);
      addSuccessMessage(
        variables.evaluation === 'approve'
          ? 'Champie! 🍾🥂'
          : translateText('admin/text', 'The domain intake has been declined'),
      );
    },
  });

  return {
    evaluateIntake: mutate,
    isSaving: isPending,
  };
}
