import { useFormikContext } from 'formik';

import { STATUS_SCANNING, STATUS_VALID, useDebugMode } from 'contexts/DebugMode';
import { useEventListener } from 'hooks/event';
import { useAcceptedUrls } from 'hooks/queries/domain';
import { DebugFormValues } from 'pages/authenticated/call-tracking/config/DebugMode';

export default function MessageHandler() {
  const { acceptedUrls } = useAcceptedUrls();
  const { values, setFieldValue } = useFormikContext<DebugFormValues>();
  const {
    browserHistory,
    addToBrowserHistory,
    validateCurrentPage,
    verificationIframeRef,
    mainIframeRef,
    verifyPageRef,
    initialize,
    setStatus,
    setFoundFormats,
  } = useDebugMode();

  useEventListener('message', e => receiveMessage(e as MessageEvent));

  function receiveMessage(event: MessageEvent) {
    if (!acceptedUrls?.some(url => getStrippedUrl(url) === getStrippedUrl(event.origin))) {
      // Don't do anything if message isn't from an accepted host of the selected domain
      return;
    }
    if (
      verificationIframeRef.current &&
      event.source === verificationIframeRef.current.contentWindow
    ) {
      switch (event.data?.type) {
        case 'VERIFY_MODE_LOADED':
          verifyPageRef.current = event.data.page;
          break;
      }
    } else if (mainIframeRef.current && event.source === mainIframeRef.current.contentWindow) {
      switch (event.data?.type) {
        case 'DEBUG_MODE_LOADED': {
          const page = event.data.page + (event.data.queryParams ?? '');
          // If the loaded page is not the current page in history, it means the navigation came
          // from within the iframe and not the url text field, so we add the page to the history
          if (browserHistory.history[browserHistory.index].page !== page) {
            addToBrowserHistory(page, true);
            setFieldValue('page', page);
          } else {
            validateCurrentPage();
          }

          setStatus(STATUS_SCANNING);
          mainIframeRef.current.contentWindow?.postMessage(
            { type: 'SCAN_FOR_FORMATS' },
            values.host,
          );
          break;
        }
        case 'FOUND_FORMATS':
          setStatus(STATUS_VALID);
          setFoundFormats(event.data.foundFormats);
          break;
        case 'VERIFY_NEW_URL':
          initialize({
            host: event.data.host,
            page: event.data.page + event.data.queryParams,
          });
          break;
      }
    }
  }

  return null;
}

function getStrippedUrl(url: string) {
  return new URL(url).host.replace('www.', '');
}
