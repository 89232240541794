import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { camelCase, isEmpty } from 'lodash';

import { useFilters } from 'contexts/Filters';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';
import { DEFAULT_PAGE_LIMIT, Filters } from './table/QueryTable';

type Props = {
  itemId: number; // The id of the current item
  itemName: string; // The name that is shown next to next/previous
  tableKey: readonly string[]; // The key of the table that contains all the data
  endpoint: string; // The endpoint of the table that contains all the data
  pageRoute: string; // The route for the detail page
  filters: Filters; // The filters that are used on the table data
};

// This component is used to navigate to the next/previous details page of items in a table with pagination
export default function ItemNavigation({
  itemId,
  itemName,
  tableKey,
  endpoint,
  pageRoute,
  filters = {},
}: Props) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { filters: currentFilters, getAppliedFilters, updateTableFilter } = useFilters();

  const filterKey = camelCase(tableKey.join(' '));
  const { bodyParams, queryParams } = getAppliedFilters(filters, filterKey, true);

  // Navigate to the next or previous item in the table when `navType` changes (after prev/next button is clicked).
  // If the data for this item is not cached yet, update the table pagination to fetch a new page.
  const [navType, setNavType] = useState<'next' | 'prev' | null>(null);
  useEffect(() => {
    if (!navType) return;
    const { item, page } = getPaginationInfo(navType);
    updateTableFilter({
      table: filterKey,
      type: 'pagination',
      value: {
        ...(currentFilters.tables?.[filterKey]?.pagination ?? { pageLimit: DEFAULT_PAGE_LIMIT }),
        page,
      },
    });
    // If `item` is not set it means the page has changed and no cache exists yet, changing the
    // route will be handled in the useEffect below
    if (item) {
      setNavType(null);
      navigate(`${pageRoute}/${item}`);
    }
  }, [navType]); // eslint-disable-line react-hooks/exhaustive-deps

  const { data, isFetched } = useQuery({
    queryKey: [...tableKey, { ...bodyParams, ...queryParams }],
    queryFn: () =>
      fetchData(endpoint, {
        method: isEmpty(bodyParams) ? 'GET' : 'POST',
        bodyParams,
        queryParams,
        includeHeaders: true,
      }),
    enabled: navType !== null,
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    if (isFetched && navType !== null) {
      // Go to the first item of new page when navigating to next, or the last item of the new
      // page when navigating to previous
      const id = data.data[navType === 'next' ? 0 : data.data.length - 1].id;
      navigate(`${pageRoute}/${id}`);
      setNavType(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched]);

  function getPaginationInfo(type: 'next' | 'prev') {
    let cached = getCache();
    const pagination = currentFilters.tables?.[filterKey]?.pagination;
    let page = pagination?.page ?? 1;

    if (!cached) return { item: null, hasNext: false, hasPrev: false, page };

    let index = cached.data.findIndex(row => row.id === itemId);
    const hasNextItem = index !== -1 && index + 1 in cached.data;
    const hasNextPage = page < Number(cached.headers.get('x-pagination-page-count') ?? 1);
    const hasPrevItem = index !== -1 && index - 1 in cached.data;
    const hasPrevPage = page !== 1;
    // If the item cannot be found in the cache of the current page, check if the next/prev page is cached.
    // In case the next/prev page is cached the item will be the first one of the page when navigating to the
    // next item, otherwise it will be the last one.
    if (type === 'next') {
      if (hasNextItem) index++;
      else if (hasNextPage) {
        page++;
        cached = getCache(page);
        if (cached) index = 0;
      }
    } else if (type === 'prev') {
      if (hasPrevItem) index--;
      else if (hasPrevPage) {
        page--;
        cached = getCache(page);
        if (cached) index = cached.data.length - 1;
      }
    }
    return {
      item: cached?.data[index]?.id,
      hasNext: hasNextItem || hasNextPage,
      hasPrev: hasPrevItem || hasPrevPage,
      page,
    };
  }

  function getCache(page?: number) {
    const { bodyParams, queryParams } = getAppliedFilters(filters, filterKey, true);
    return queryClient.getQueryData<{ data: { id: number }[]; headers: Headers }>([
      ...tableKey,
      { ...bodyParams, ...queryParams, ...(page && { page }) },
    ]);
  }

  function getButton(type: 'prev' | 'next') {
    const { hasNext, hasPrev } = getPaginationInfo(type);
    const available = type === 'next' ? hasNext : hasPrev;
    return (
      <span
        className={'clickable' + (!available ? ' disabled' : '')}
        onClick={() => available && setNavType(type)}
      >
        <div className={'arrow tiny arrow-' + (type === 'next' ? 'right' : 'left')} />
        {type === 'next'
          ? translateText('label', 'Next') + ' ' + itemName.toLowerCase()
          : translateText('label', 'Previous') + ' ' + itemName.toLowerCase()}
      </span>
    );
  }

  return (
    <div className="prev-next-container">
      {getButton('prev')}
      &nbsp; / &nbsp;
      {getButton('next')}
    </div>
  );
}
