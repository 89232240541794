import { useRef, useState } from 'react';
import { useFormikContext } from 'formik';

import Checkbox from 'components/input/Checkbox';
import Skeleton from 'components/Skeleton';
import { useOnClickOutside } from 'hooks/event';
import { useTranslate } from 'hooks/translate';
import { ColumnSetting } from './Table';

type Props<T> = {
  id: string;
  columns: [string, ColumnSetting<T>][];
  isLoading: boolean;
};

export default function ColumnSelector<T>({ id, columns, isLoading = false }: Props<T>) {
  const translateText = useTranslate();
  const { values, submitForm, setFieldValue } = useFormikContext<{ columns: string[] }>();
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    if (isOpen) {
      submitForm();
      setIsOpen(false);
    }
  });

  if (isLoading) {
    return (
      <div className="column-selector">
        <Skeleton className="btn" width={165} />
      </div>
    );
  }

  function updateChosenColumns(column: string, checked: boolean) {
    if (checked) {
      setFieldValue('columns', [...values.columns, column]);
    } else {
      setFieldValue(
        'columns',
        values.columns.filter(col => col !== column),
      );
    }
  }

  return (
    <div className="column-selector" ref={ref}>
      <button className="btn btn-lightblue" onClick={() => setIsOpen(open => !open)}>
        <span className="extra-button-text">{translateText('label', 'Set columns')}</span>
        <div className="extra-button-arrow arrow-wrapper">
          <div className={`arrow ${isOpen ? 'arrow-down-right' : 'arrow-right'}`} />
        </div>
      </button>
      {isOpen && (
        <ul className="choose-columns">
          {columns.map((header, index) => {
            return (
              <li key={index}>
                <Checkbox
                  id={`${id}-${index}`}
                  label={
                    typeof header[1].header === 'string'
                      ? header[1].header
                      : header[1].header?.name ?? header[0].replace(/_/g, ' ')
                  }
                  checked={values.columns.includes(header[0])}
                  onChange={e => updateChosenColumns(header[0], e.target.checked)}
                  disabled={values.columns.length === 1 && values.columns.includes(header[0])}
                  labelClassName="push-right"
                />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}
