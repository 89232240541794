import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useMessages } from 'contexts/Messages';
import { useTenant } from 'contexts/Tenant';
import { FREQUENCY_MONTHLY, FREQUENCY_OFF } from 'globals/constants';
import { DomainSetting } from 'globals/types';
import { useSetQueryData, useSetTableData } from 'hooks/data';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';
import { ReportEmailSettings } from 'pages/authenticated/call-tracking/config/SendReports';
import { MicrosoftAdsFormValues } from 'pages/authenticated/integrations/advertising/Microsoft';
import { CallFunctionalities, DomainProperties } from './domain';

export function useGoogleAnalyticsSettings() {
  const { fetchData } = useFetch();
  const { selectedDomain } = useTenant();

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ['domainSetting', 'googleAnalytics', selectedDomain],
    queryFn: () =>
      fetchData('/domain-setting/google-analytics', { queryParams: { domain_id: selectedDomain } }),
    enabled: selectedDomain !== null,
  });

  return { googleAnalyticsSettings: data, isLoading };
}

export function useUpdateBasicCallFunctionality(
  path: string,
  settingsKey: keyof CallFunctionalities,
  enabledKey?: string,
) {
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const { mutateAsync } = useMutation({
    mutationFn: ({ enabled }: { enabled: boolean }) =>
      fetchData('/domain-setting/' + path, {
        method: 'PUT',
        bodyParams: { domain_id: selectedDomain, enabled },
      }),
    onSuccess: (data, variables) => {
      setQueryData<CallFunctionalities>(['callFunctionalities', selectedDomain], oldData => ({
        ...oldData,
        [settingsKey]: { ...oldData[settingsKey], [enabledKey ?? 'enabled']: variables.enabled },
      }));
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { updateCallFunctionality: mutateAsync };
}

export function useCallRecordingSettings() {
  const { fetchData } = useFetch();
  const { selectedDomain } = useTenant();

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ['domainSetting', 'callRecording', selectedDomain],
    queryFn: () =>
      fetchData('/domain-setting/call-recording', { queryParams: { domain_id: selectedDomain } }),
    enabled: selectedDomain !== null,
  });

  return { callRecordingSettings: data, isLoading };
}

export function useUpdateCallRecording() {
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const { mutateAsync } = useMutation({
    mutationFn: ({ enabled, notification }: { enabled: boolean; notification: string }) =>
      fetchData('/domain-setting/change-call-recording', {
        method: 'PUT',
        bodyParams: { domain_id: selectedDomain, enabled, notification },
      }),
    onSuccess: (data, variables) => {
      setQueryData<CallFunctionalities>(['callFunctionalities', selectedDomain], oldData => ({
        ...oldData,
        call_recording: {
          ...oldData.call_recording,
          call_recording: variables.enabled,
          notification: variables.notification,
        },
      }));
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { updateCallRecording: mutateAsync };
}

export function useCallNotifications() {
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const { mutateAsync } = useMutation({
    mutationFn: ({
      type,
      email,
      perLocation,
      locations,
    }: {
      type: number;
      email: string;
      perLocation: boolean;
      locations: { identifier: string; name: string; type: number; value: string }[];
    }) =>
      fetchData('/domain-setting/change-mail-notification', {
        method: 'PUT',
        bodyParams: {
          domain_id: selectedDomain,
          email: email || '',
          use_locations: perLocation,
          locations,
          type,
        },
      }),
    onSuccess: (data, variables) => {
      setQueryData<CallFunctionalities>(['callFunctionalities', selectedDomain], oldData => ({
        ...oldData,
        call_notification: {
          type: variables.type,
          email: variables.email,
          use_locations: variables.perLocation ? 'true' : 'false',
          locations: variables.locations,
        },
      }));
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { updateCallNotifications: mutateAsync };
}

export function useReportEmail() {
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ['domainSetting', 'reportEmail', selectedDomain],
    queryFn: () =>
      fetchData('/domain-setting/report-email', { queryParams: { domain_id: selectedDomain } }),
    enabled: selectedDomain !== null,
  });

  const { mutateAsync } = useMutation({
    mutationFn: (settings: ReportEmailSettings) =>
      fetchData('/domain-setting/change-report-email', {
        method: 'POST',
        bodyParams: {
          domain_id: selectedDomain,
          email_csv: settings.history.emailAddresses,
          email_prestation: settings.prestation.emailAddresses,
          email_pdf: settings.reports.emailAddresses,
          csv_frequency: settings.history.frequency,
          prestation_frequency: settings.prestation.frequency,
          pdf_frequency: settings.reports.frequency,
        },
      }),
    onSuccess: data => {
      setQueryData(['domainSetting', 'reportEmail', selectedDomain], () => JSON.parse(data.value));
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return {
    reportEmailSettings: {
      history: {
        emailAddresses: data?.csv ?? [],
        frequency: data?.csvFrequency ?? (data?.csv?.length ? FREQUENCY_MONTHLY : FREQUENCY_OFF),
      },
      reports: {
        emailAddresses: data?.pdf ?? [],
        frequency: data?.pdfFrequency ?? (data?.pdf?.length ? FREQUENCY_MONTHLY : FREQUENCY_OFF),
      },
      prestation: {
        emailAddresses: data?.prestation ?? [],
        frequency: data?.prestationFrequency ?? FREQUENCY_OFF,
      },
    },
    isLoading,
    updateReportEmail: mutateAsync,
  };
}

export function useTrackedSources() {
  const { selectedDomain } = useTenant();
  const { fetchData } = useFetch();

  const { data, isFetching } = useQuery({
    queryKey: ['sources', selectedDomain],
    queryFn: (): Promise<
      { allTracked: true; denied: string[] } | { allTracked: false; tracked: string[] }
    > =>
      fetchData('/domain-setting/tracked-sources', { queryParams: { domain_id: selectedDomain } }),
    enabled: selectedDomain !== null,
  });

  return { trackedSources: data, isLoading: isFetching };
}

export function useManualTagging() {
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const { data, isFetching } = useQuery({
    queryKey: ['manualTagging', selectedDomain],
    queryFn: (): Promise<{ enabled: number; order: number }> =>
      fetchData('/domain-setting/utm-order', { queryParams: { domain_id: selectedDomain } }),
    enabled: selectedDomain !== null,
  });

  const { mutateAsync } = useMutation({
    mutationFn: ({ enabled, order }: { enabled: boolean; order: number }) =>
      fetchData('/domain-setting/change-utm-order', {
        method: 'POST',
        bodyParams: { domain_id: selectedDomain, enabled: enabled, order },
      }),
    onSuccess: data => {
      setQueryData(['manualTagging', selectedDomain], () => ({
        order: data.order?.value ?? 0,
        enabled: data.enabled.value,
      }));
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { manualTagging: data, isLoading: isFetching, updateManualTagging: mutateAsync };
}

type SessionStop = {
  enabled: boolean;
  session_amount: number;
  is_changed_this_month: boolean;
  reached_at: string | boolean;
};

export function useSessionStop() {
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const { data, isFetching } = useQuery({
    queryKey: ['sessionStop', selectedDomain],
    queryFn: (): Promise<SessionStop> =>
      fetchData('/domain-setting/session-stop', { queryParams: { domain_id: selectedDomain } }),
    enabled: selectedDomain !== null,
  });

  const { mutateAsync } = useMutation({
    mutationFn: ({ enabled, amount }: { enabled: boolean; amount: number }) =>
      fetchData('/domain-setting/change-session-stop', {
        method: 'POST',
        bodyParams: {
          domain_id: selectedDomain,
          enabled,
          session_amount: amount * 1000,
        },
      }),
    onSuccess: data => {
      setQueryData<SessionStop>(['sessionStop', selectedDomain], oldData => ({
        ...oldData,
        session_amount: typeof data.value === 'number' ? data.value : 0,
        enabled: typeof data.value === 'number' && data.value > 0,
        is_changed_this_month: true,
      }));
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { sessionStop: data, isLoading: isFetching, updateSessionStop: mutateAsync };
}

export function useNumberFormats() {
  const { fetchData } = useFetch();

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ['numberFormats'],
    queryFn: (): Promise<{ id: number; format: string }[]> =>
      fetchData('/domain-setting/numberformats'),
  });

  return { numberFormats: data, isLoading };
}

export function useUpdateNumberFormat() {
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const { mutateAsync } = useMutation({
    mutationFn: (numberFormat: string) =>
      fetchData('/domain-setting/change-number-format', {
        method: 'POST',
        bodyParams: { domain_id: selectedDomain, number_format: numberFormat },
      }),
    onSuccess: (data, numberFormat) => {
      setQueryData<DomainProperties>(['domainView', selectedDomain], oldData => ({
        ...oldData,
        number_format: numberFormat,
      }));
      addSuccessMessage(translateText('message', 'The phone number format has been changed.'));
    },
  });

  return { updateNumberFormat: mutateAsync };
}

export function useUpdateNumberDistribution() {
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const { mutateAsync } = useMutation({
    mutationFn: (enabled: boolean) =>
      fetchData('/domain-setting/change-number-distribution', {
        method: 'POST',
        bodyParams: { domain_id: selectedDomain, enabled },
      }),
    onSuccess: (data, enabled) => {
      setQueryData<DomainProperties>(['domainView', selectedDomain], oldData => ({
        ...oldData,
        number_distribution: { ...oldData.number_distribution, enabled },
      }));
      addSuccessMessage(
        translateText('message', 'The number distribution has been {action}.', {
          action: (enabled
            ? translateText('label', 'Enabled')
            : translateText('label', 'Disabled')
          ).toLowerCase(),
        }),
      );
    },
  });

  return { updateNumberDistribution: mutateAsync };
}

export function useSessionFilterSetting() {
  const { fetchData } = useFetch();
  const { selectedDomain } = useTenant();

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ['sessionFilter', 'setting', selectedDomain],
    queryFn: (): Promise<[{ id: number; value: string }]> =>
      fetchData('/domain-setting/index', {
        queryParams: { domain_id: selectedDomain, setting_type_id: 750 },
      }),
  });

  return { sessionFilterSetting: data, isLoading };
}

export function useUpdateSessionFilter() {
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const { mutateAsync } = useMutation({
    mutationFn: (value: string) =>
      fetchData('/domain-setting/change-session-filter', {
        method: 'POST',
        queryParams: { domain_id: selectedDomain },
        bodyParams: { value },
      }),
    onSuccess: (data, value) => {
      setQueryData<[{ id: number; value: string }]>(
        ['sessionFilter', 'setting', selectedDomain],
        oldData => [{ ...oldData[0], value }],
      );
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { updateSessionFilter: mutateAsync };
}

export function useMicrosoftAds(enabled: boolean) {
  const translateText = useTranslate();
  const setQueryData = useSetQueryData();
  const { fetchData } = useFetch();
  const { selectedDomain } = useTenant();
  const { addSuccessMessage } = useMessages();

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ['domainSetting', 'microsoftAds', selectedDomain],
    queryFn: () =>
      fetchData('/domain-setting/bing-ads', { queryParams: { domain_id: selectedDomain } }),
    enabled: selectedDomain !== null && enabled,
  });

  const { mutateAsync } = useMutation({
    mutationFn: (values: MicrosoftAdsFormValues) =>
      fetchData('/domain-setting/change-bing-ads', {
        method: 'POST',
        bodyParams: {
          domain_id: String(selectedDomain),
          data: {
            ...values,
            enabledFormTracking: 0,
            sendFailedCr: 0,
            sendSuccessfulCr: 0,
            sendUnsuccessfulCr: 0,
          },
        },
      }),
    onSuccess: data => {
      const values = JSON.parse(data);
      setQueryData<MicrosoftAdsFormValues>(
        ['domainSetting', 'microsoftAds', selectedDomain],
        () => values,
      );
      const status = values.enabledCallTracking ? (values.verified ? 1 : 2) : 0;
      setQueryData<Record<string, number>>(['integrationStatuses', selectedDomain], oldData => ({
        ...oldData,
        microsoftAdvertising: status,
      }));
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { microsoftAdsSettings: data, isLoading, updateMicrosoftAds: mutateAsync };
}

export function useAddDomainSetting() {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (data: { settingTypeId: string; value: string }): Promise<DomainSetting> => {
      let url = '/domain-setting';
      if (parseInt(data.settingTypeId) === 700) {
        url = '/domain-setting/create-accepted-urls';
      }
      return fetchData(url, {
        method: 'POST',
        queryParams: { expand: 'message' },
        bodyParams: {
          domain_id: selectedDomain,
          setting_type_id: data.settingTypeId,
          value: data.value,
        },
      });
    },
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: ['domainSetting', 'index', selectedDomain] });
      addSuccessMessage(translateText('admin/text', '{value} added.', { value: data.name }));
      if (data.message) alert(data.message);
    },
  });

  return { addDomainSetting: mutateAsync, isLoading: isPending };
}

export function useUpdateDomainSetting() {
  const translateText = useTranslate();
  const setTableData = useSetTableData();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const { mutate, isPending } = useMutation({
    mutationFn: (data: { domainSettingId: string; settingTypeId: string; value: string }) => {
      let url = '/domain-setting/update';
      if (parseInt(data.settingTypeId) === 700) {
        url = '/domain-setting/update-accepted-urls';
      }
      return fetchData(url, {
        method: 'PUT',
        queryParams: { id: data.domainSettingId, expand: 'message,setting_type_id' },
        bodyParams: { value: data.value },
      });
    },
    onSuccess: (data: DomainSetting) => {
      addSuccessMessage(
        translateText('admin/text', '{name} saved as: {value}', {
          name: data.name,
          value: data.value,
        }),
      );
      setTableData(['domainSetting', 'index', selectedDomain], oldData =>
        oldData.map(i => (i?.id === data.id ? data : i)),
      );
      if (data.message) alert(data.message);
    },
  });

  return { updateDomainSetting: mutate, isLoading: isPending };
}

export function useDeleteDomainSetting() {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const { mutate, isPending } = useMutation({
    mutationFn: (id: string) =>
      fetchData('/domain-setting/delete', {
        method: 'DELETE',
        queryParams: { id },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['domain', 'users', selectedDomain] });
      addSuccessMessage(
        translateText('admin/text', 'The {type} has been deleted.', {
          type: translateText('label', 'Domain setting').toLowerCase(),
        }),
      );
    },
  });

  function deleteDomainSetting(id: string) {
    if (
      window.confirm(
        translateText('admin/text', 'Are you sure you want to delete this {type}?', {
          type: translateText('label', 'Domain setting').toLowerCase(),
        }),
      )
    ) {
      mutate(id);
    }
  }

  return { deleteDomainSetting, isLoading: isPending };
}
