import iconFuty from 'assets/images/icons/integrations/futy.svg';
import Setup from 'components/slide-in/Setup';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import { INTEGRATION_FUTY } from 'globals/constants';
import { useHasAccessToIntegration } from 'hooks/access';
import { useTranslate } from 'hooks/translate';

export default function Futy({ show, close }: BaseSlideInProps) {
  const translateText = useTranslate();
  const hasAccessToIntegration = useHasAccessToIntegration();
  if (!hasAccessToIntegration(INTEGRATION_FUTY)) return null;

  return (
    <Setup
      show={show}
      close={close}
      closeButtonText={translateText('label', 'Close')}
      icon={iconFuty}
      title={translateText('label', 'Futy')}
      maxWidth={440}
    >
      <p>
        {translateText(
          'text',
          'Futy is a tool that helps you convert website visitors into leads. These leadbots are small, interactive chatbots that appear on your website and can help visitors with various tasks, such as contacting your company, answering questions, scheduling appointments, or filling out forms.',
        )}
      </p>
      <p>
        {translateText(
          'text',
          "Using the AdCalls integration, you can also show phone numbers for Call Tracking in Futy's chatbot. Use of this integration must be enabled on the Futy side.",
        )}
      </p>
    </Setup>
  );
}
