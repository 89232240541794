import { useTenant } from 'contexts/Tenant';
import {
  BASIC_PLANS,
  ENTERPRISE_PLANS,
  INTL_PLANS,
  LIGHT_PLANS,
  NEW_PROPOSITION_PLANS,
  OPTIMIZE_PLANS,
  PLAN_LIGHT,
  PLAN_OPTIMIZE,
  ROLE_ADMIN,
  ROLE_EMPLOYEE,
  ROLE_HIERARCHY,
  ROLE_MANAGER,
} from 'globals/constants';
import { PricingPlan, Role } from 'globals/types';
import { useIntegrationsContent } from './integrations';
import { useDomainAuth } from './queries/domain';
import { useUser } from './queries/user';

export function useHasRoleAccess() {
  const { domainAuth } = useDomainAuth();
  const { user } = useUser();

  return (role: Role) => {
    const domainRole = domainAuth?.role ?? user?.internalRole;
    if (domainRole === undefined || domainRole === null) return false;

    const domainRoleIndex = ROLE_HIERARCHY.indexOf(domainRole);
    const roleIndex = ROLE_HIERARCHY.indexOf(role);
    if (domainRoleIndex === -1 || roleIndex === -1) return false;
    return domainRoleIndex <= roleIndex;
  };
}

export function useHasAdminAccess() {
  const hasRoleAccess = useHasRoleAccess();
  return hasRoleAccess(ROLE_ADMIN);
}

export function useHasEmployeeAccess() {
  const hasRoleAccess = useHasRoleAccess();
  return hasRoleAccess(ROLE_EMPLOYEE);
}

export function useHasManagerAccess() {
  const hasRoleAccess = useHasRoleAccess();
  return hasRoleAccess(ROLE_MANAGER);
}

export function useHasMccAdminAccess() {
  const { selectedMccData } = useTenant();
  return !!selectedMccData?.isAdmin;
}

function useHasPricingPlan() {
  const { domainAuth } = useDomainAuth();

  return (pricingPlans: readonly PricingPlan[]) => {
    return domainAuth !== undefined && pricingPlans.includes(domainAuth.pricing_plan);
  };
}

export function useHasLightPlan() {
  const hasPricingPlan = useHasPricingPlan();
  return hasPricingPlan(LIGHT_PLANS);
}

export function useHasBasicPlan() {
  const hasPricingPlan = useHasPricingPlan();
  return hasPricingPlan(BASIC_PLANS);
}

export function useHasOptimizePlan() {
  const hasPricingPlan = useHasPricingPlan();
  return hasPricingPlan(OPTIMIZE_PLANS);
}

export function useHasEnterprisePlan() {
  const hasPricingPlan = useHasPricingPlan();
  return hasPricingPlan(ENTERPRISE_PLANS);
}

export function useHasIntlPlan() {
  const hasPricingPlan = useHasPricingPlan();
  return hasPricingPlan(INTL_PLANS);
}

export function useHasNewPricingPlan() {
  const hasPricingPlan = useHasPricingPlan();
  return hasPricingPlan(NEW_PROPOSITION_PLANS);
}

export function useTrial() {
  const { domainAuth } = useDomainAuth();
  const isTrial = domainAuth === undefined ? true : domainAuth.is_trial;
  const trialMonth = domainAuth === undefined || !isTrial ? null : domainAuth.trial_month;
  return { isTrial, trialMonth };
}

export function useHasAccessToIntegration() {
  const { isLoading } = useDomainAuth();
  const isLight = useHasLightPlan();
  const isOptimize = useHasOptimizePlan();
  const integrations = useIntegrationsContent();

  return (integrationKey: string) => {
    if (isLoading) return false;
    if (!isLight && !isOptimize) return true;

    const restrictedPricingPlans =
      integrations.find(entry => entry.integrationKey === integrationKey)?.restrictedPricingPlans ??
      [];

    return !(
      (restrictedPricingPlans?.includes(PLAN_LIGHT) && isLight) ||
      (restrictedPricingPlans?.includes(PLAN_OPTIMIZE) && isOptimize)
    );
  };
}
