import { useState } from 'react';
import { useParams } from 'react-router';
import { Formik } from 'formik';
import * as Yup from 'yup';

import AddDebtor from 'components/admin/AddDebtor';
import EditableJson from 'components/admin/EditableJson';
import Checkbox from 'components/input/Checkbox';
import Select from 'components/input/Select';
import TextField from 'components/input/TextField';
import Toggle from 'components/input/Toggle';
import Loader from 'components/Loader';
import Table from 'components/table/Table';
import Tooltip from 'components/Tooltip';
import { CONTRACT_DURATIONS, PLAN_OPTIMIZE_ID } from 'globals/constants';
import { pricingPlanCategoryHasChanged } from 'globals/helpers';
import { useHasAdminAccess } from 'hooks/access';
import { useChargebeeCustomers } from 'hooks/queries/debtor';
import {
  useAdminIntakeDetails,
  useIntakeEvaluation,
  useUpdateAdminIntake,
} from 'hooks/queries/domain-intake';
import { usePricingPlans } from 'hooks/queries/pricing';
import { useResellers } from 'hooks/queries/reseller';
import { useAccountManagers } from 'hooks/queries/user';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation } from 'hooks/validation';

export default function IntakeDetails() {
  const translateText = useTranslate();

  const isAdmin = useHasAdminAccess();
  const params = useParams();
  const intakeId = Number(params.id);
  const { intake, isLoading: isLoadingIntakeDetails } = useAdminIntakeDetails(
    !isNaN(intakeId) ? intakeId : null,
  );

  const [skipNumberCheck, setSkipNumberCheck] = useState(false);
  const [showIntake, setShowIntake] = useState(false);
  const { required } = useCustomValidation();

  const { accountManagers, isLoading: isLoadingAccountManagers } = useAccountManagers();
  const { resellers, isLoading: isLoadingResellers } = useResellers();
  const { customers, isLoading: isLoadingCustomers } = useChargebeeCustomers();
  const { pricingPlans, isLoading: isLoadingPricingPlans } = usePricingPlans();

  const { updateIntake, isSaving: isSavingIntake } = useUpdateAdminIntake();
  const { evaluateIntake, isSaving: isSavingIntakeEvaluation } = useIntakeEvaluation();

  const isSaving = isSavingIntake || isSavingIntakeEvaluation;

  if (
    isLoadingIntakeDetails ||
    isLoadingAccountManagers ||
    isLoadingResellers ||
    isLoadingCustomers ||
    isLoadingPricingPlans
  ) {
    return <Loader />;
  }

  if (!intake) {
    return (
      <div className="white-block">
        <div className="no-data">{translateText('label', 'No data')}</div>
      </div>
    );
  }

  const validationSchema = Yup.object({
    address: required,
    city: required,
    company: required,
    domain_title: required,
    domain_url: required,
    email: required,
    name: required,
    phone_number: required,
    postal_code: required,
  });

  return (
    <div className="intake-details">
      <h1>{translateText('label', 'Request')}</h1>
      <Table
        columns={{
          id: { header: { name: translateText('label', 'ID') } },
          created_by: { header: { name: translateText('label', 'Requesting user') } },
          status: { header: { name: translateText('label', 'Status') } },
          needed_numbers: {
            header: { name: translateText('label', 'Needed numbers') },
            customValue: value => <pre>{JSON.stringify(value, undefined, 4)}</pre>,
          },
        }}
        data={[intake]}
      />
      <div className="white-block">
        <div className="form-row">
          <div className="name-label">{translateText('label', 'Remarks')}</div>
          <div className="value-wrapper">
            {intake.remarks ? intake.remarks : translateText('label', 'No remarks')}
          </div>
        </div>
        <div className="form-row">
          <div className="name-label">{translateText('label', 'Implementation mail')}</div>
          <div className="value-wrapper">
            {intake.implementation_emails
              ? intake.implementation_emails
              : translateText('label', 'An automatic implementation mail will not be sent.')}
          </div>
        </div>
        <div className="form-row">
          <div className="name-label">{translateText('label', 'UA-code / G-code')}</div>
          <div className="value-wrapper">
            {intake.analytics_code ? intake.analytics_code : translateText('label', 'Not set')}
          </div>
        </div>
      </div>

      <Formik
        initialValues={{
          ...intake,
          chargebee_customer_id: intake.chargebee_customer_id ?? 'select',
          account_manager: {
            ...intake.account_manager,
            ...(!Object.keys(accountManagers!).includes(intake.account_manager.id + '') && {
              id: -1,
            }),
          },
        }}
        onSubmit={values => updateIntake({ id: intakeId, intake: values })}
        enableReinitialize
        validationSchema={validationSchema}
      >
        {({
          values,
          initialValues,
          submitForm,
          setFieldValue,
          handleChange,
          dirty,
          errors,
          submitCount,
        }) => {
          const hasOffsite =
            !!values.offsite_numbers.length ||
            !!(
              Array.isArray(values.destinations) &&
              values.destinations.find(loc => loc.offsite.length)
            );
          const canBeTrial =
            values.pricing_plan_id === PLAN_OPTIMIZE_ID &&
            Array.isArray(values.destinations) &&
            values.destinations.length <= 5 &&
            !hasOffsite;
          return (
            <div className="white-block">
              <div className="form-row">
                <div className="name-label">{translateText('label', 'Account manager')}</div>
                <div className="value-wrapper">
                  <Select
                    name="account_manager.id"
                    value={values.account_manager.id}
                    onChange={handleChange}
                    canEdit={isAdmin}
                    options={accountManagers ?? []}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="name-label">{translateText('label', 'Status')}</div>
                <div className="value-wrapper">
                  <Select
                    name="status_id"
                    value={values.status_id}
                    onChange={handleChange}
                    canEdit={isAdmin}
                    options={[
                      { value: 1, text: translateText('label', 'Open') },
                      { value: 2, text: translateText('label', 'Waiting for customer') },
                      { value: 3, text: translateText('label', 'Processing') },
                      ...(intake?.status_id === 4
                        ? [{ value: 4, text: translateText('label', 'Processed') }]
                        : []),
                      { value: 5, text: translateText('label', 'Verified') },
                      ...(intake?.status_id === 6
                        ? [{ value: 6, text: translateText('label', 'Refused') }]
                        : []),
                      { value: 9, text: translateText('label', 'Missing agreement') },
                    ]}
                  />
                </div>
              </div>

              {resellers && (
                <div className="form-row">
                  <div className="name-label">{translateText('label', 'Partner')}</div>
                  <div className="value-wrapper">
                    <Select
                      id="reseller_id"
                      value={values.reseller_id}
                      onChange={handleChange}
                      canEdit={isAdmin}
                      options={resellers}
                      optionText="name"
                      optionValue="id"
                    />
                  </div>
                </div>
              )}

              {customers && (
                <div className="form-row">
                  <div className="name-label">{translateText('label', 'Chargebee customer')}</div>
                  <div className="value-wrapper">
                    <Select
                      id="chargebee_customer_id"
                      value={values.chargebee_customer_id}
                      onChange={handleChange}
                      canEdit={isAdmin}
                      options={customers}
                      optionText="company"
                      optionValue="id"
                      addSelect
                    />
                    {isAdmin && (
                      <AddDebtor
                        initialValues={{
                          company: initialValues?.company ?? '',
                          name: initialValues?.name ?? '',
                          address: initialValues?.address ?? '',
                          postal_code: initialValues?.postal_code ?? '',
                          city: initialValues?.city ?? '',
                          email: initialValues?.email ?? '',
                          automatic_collection: initialValues?.automatic_collection ? 1 : 0,
                          vat_number: initialValues?.vat_number ?? '',
                          country: initialValues?.country ?? 'NL',
                        }}
                        onSuccess={customer => setFieldValue('customer', customer)}
                      />
                    )}
                  </div>
                </div>
              )}

              {pricingPlans && (
                <div className="form-row">
                  <div className="name-label">{translateText('label', 'Pricing plan')}</div>
                  <div className="value-wrapper">
                    <Select
                      id="pricing_plan_id"
                      value={values.pricing_plan_id}
                      onChange={e => {
                        if (pricingPlanCategoryHasChanged(values.pricing_plan_id, e.target.value)) {
                          setFieldValue('contract_duration', 'select');
                        }
                        setFieldValue('pricing_plan_id', e.target.value);
                      }}
                      optionValue="id"
                      optionText="invoice_label"
                      canEdit={isAdmin}
                      options={pricingPlans}
                      addSelect
                      selectText={translateText('label', 'Select a pricing plan')}
                    />
                  </div>
                </div>
              )}

              <div className="form-row">
                <div className="name-label">
                  <span>{translateText('label', 'Contract duration')}</span>
                </div>
                <div className="value-wrapper">
                  <Select
                    name="contract_duration"
                    value={values.contract_duration}
                    options={CONTRACT_DURATIONS[values.pricing_plan_id] ?? []}
                    onChange={handleChange}
                    addSelect
                    canEdit={isAdmin}
                    selectText={translateText('label', 'Select a contract duration')}
                    disabled={!values.pricing_plan_id}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="name-label">{translateText('label', 'Is trial')}</div>
                <div className="value-wrapper">
                  <Tooltip
                    text={translateText(
                      'text',
                      'This intake does not meet all the conditions for a trial.',
                    )}
                    html={
                      <Checkbox
                        id="is_trial"
                        checked={!!values.is_trial}
                        onChange={e => setFieldValue('is_trial', e.target.checked)}
                        disabled={!canBeTrial}
                        canEdit={isAdmin}
                      />
                    }
                    show={!canBeTrial ? undefined : false}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="name-label">{translateText('label', 'Trial end date')}</div>
                <div className="value-wrapper">
                  <TextField
                    name="trial_end_date"
                    placeholder={translateText('label', 'MM-YYYY')}
                    value={values.trial_end_date ?? ''}
                    onChange={handleChange}
                    canEdit={isAdmin}
                    disabled={!values.is_trial}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="name-label">{translateText('label', 'Domain kickback')}</div>
                <div className="value-wrapper">
                  <TextField
                    name="domain_kickback"
                    placeholder="10"
                    value={values.domain_kickback ?? ''}
                    onChange={handleChange}
                    canEdit={isAdmin}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="name-label">{translateText('label', 'Domain URL')}</div>
                <div className="value-wrapper">
                  <TextField
                    name="domain_url"
                    placeholder={translateText('label', 'Domain URL')}
                    value={values.domain_url}
                    onChange={handleChange}
                    canEdit={isAdmin}
                    error={submitCount > 0 && errors.domain_url}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="name-label">{translateText('label', 'Domain title')}</div>
                <div className="value-wrapper">
                  <TextField
                    name="domain_title"
                    placeholder={translateText('label', 'Domain title')}
                    value={values.domain_title}
                    onChange={handleChange}
                    canEdit={isAdmin}
                    error={submitCount > 0 && errors.domain_title}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="name-label">{translateText('label', 'Session stop')}</div>
                <div className="value-wrapper">
                  <TextField
                    name="session_stop"
                    placeholder={translateText('label', 'Session stop')}
                    value={values.session_stop}
                    onChange={handleChange}
                    canEdit={isAdmin}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="name-label">{translateText('label', 'E-mail')}</div>
                <div className="value-wrapper">
                  <TextField
                    name="email"
                    placeholder={translateText('label', 'E-mail')}
                    value={values.email}
                    onChange={handleChange}
                    canEdit={isAdmin}
                    error={submitCount > 0 && errors.email}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="name-label">{translateText('label', 'Company')}</div>
                <div className="value-wrapper">
                  <TextField
                    name="company"
                    placeholder={translateText('label', 'Company')}
                    value={values.company}
                    onChange={handleChange}
                    canEdit={isAdmin}
                    error={submitCount > 0 && errors.company}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="name-label">{translateText('label', 'Full name')}</div>
                <div className="value-wrapper">
                  <TextField
                    name="name"
                    placeholder={translateText('label', 'Full name')}
                    value={values.name}
                    onChange={handleChange}
                    canEdit={isAdmin}
                    error={submitCount > 0 && errors.name}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="name-label">
                  {translateText('label', 'Reference / Purchase Order Number')}
                </div>
                <div className="value-wrapper">
                  <TextField
                    name="reference"
                    placeholder={translateText('label', 'Reference / Purchase Order Number')}
                    value={values.reference}
                    onChange={handleChange}
                    canEdit={isAdmin}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="name-label">{translateText('label', 'KVK-number')}</div>
                <div className="value-wrapper">
                  <TextField
                    name="kvk_number"
                    placeholder={translateText('label', 'KVK-number')}
                    value={values.kvk_number}
                    onChange={handleChange}
                    canEdit={isAdmin}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="name-label">{translateText('label', 'VAT-number')}</div>
                <div className="value-wrapper">
                  <TextField
                    name="vat_number"
                    placeholder={translateText('label', 'VAT-number')}
                    value={values.vat_number}
                    onChange={handleChange}
                    canEdit={isAdmin}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="name-label">{translateText('label', 'Phone number')}</div>
                <div className="value-wrapper">
                  <TextField
                    name="phone_number"
                    placeholder={translateText('label', 'Phone number')}
                    value={values.phone_number}
                    onChange={handleChange}
                    canEdit={isAdmin}
                    error={submitCount > 0 && errors.phone_number}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="name-label">{translateText('label', 'Address')}</div>
                <div className="value-wrapper">
                  <TextField
                    name="address"
                    placeholder={translateText('label', 'Address')}
                    value={values.address}
                    onChange={handleChange}
                    canEdit={isAdmin}
                    error={submitCount > 0 && errors.address}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="name-label">{translateText('label', 'Postal code')}</div>
                <div className="value-wrapper">
                  <TextField
                    name="postal_code"
                    placeholder={translateText('label', 'Postal code')}
                    value={values.postal_code}
                    onChange={handleChange}
                    canEdit={isAdmin}
                    error={submitCount > 0 && errors.postal_code}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="name-label">{translateText('label', 'City')}</div>
                <div className="value-wrapper">
                  <TextField
                    name="city"
                    placeholder={translateText('label', 'City')}
                    value={values.city}
                    onChange={handleChange}
                    canEdit={isAdmin}
                    error={submitCount > 0 && errors.city}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="name-label">{translateText('label', 'Automatic debit')}</div>
                <div className="value-wrapper">
                  <Toggle
                    name="automatic_collection"
                    checked={!!values.automatic_collection}
                    onClick={() =>
                      setFieldValue('automatic_collection', +!values.automatic_collection)
                    }
                    canEdit={isAdmin}
                  />
                </div>
              </div>

              {isAdmin && (
                <div className="form-button">
                  <button
                    disabled={!dirty || isSaving}
                    className="btn btn-lightblue"
                    onClick={submitForm}
                  >
                    {translateText('label', 'Save')}
                  </button>
                </div>
              )}
            </div>
          );
        }}
      </Formik>
      {isAdmin && (
        <div className="evaluation-buttons-wrapper">
          <div className="skip-number-check">
            <Checkbox
              id="skip_number_check"
              label={translateText('label', 'Skip number check')}
              checked={skipNumberCheck}
              onChange={e => setSkipNumberCheck(e.target.checked)}
            />
          </div>
          <div className="buttons">
            <div className="show-intake">
              <button className="btn btn-lightblue" onClick={() => setShowIntake(!showIntake)}>
                {showIntake
                  ? translateText('label', 'Hide')
                  : translateText('label', 'Show intake')}
              </button>
            </div>
            <div className="evaluation-buttons">
              <button
                disabled={isSaving}
                className="btn btn-purple"
                onClick={() =>
                  evaluateIntake({ id: intakeId, evaluation: 'decline', skipNumberCheck })
                }
              >
                {translateText('label', 'Decline')}
              </button>
              <button
                disabled={isSaving}
                className="btn btn-green"
                onClick={() =>
                  evaluateIntake({ id: intakeId, evaluation: 'approve', skipNumberCheck })
                }
              >
                {translateText('label', 'Approve')}
              </button>
            </div>
          </div>
        </div>
      )}
      {showIntake && (
        <Formik
          initialValues={{ json: JSON.stringify(intake) }}
          onSubmit={values => updateIntake({ id: intakeId, intake: JSON.parse(values.json) })}
        >
          {({ values, submitForm, setFieldValue, dirty }) => (
            <div className="white-block">
              <EditableJson
                id="json-intake"
                initialView="preview"
                value={values.json}
                canEdit={isAdmin ? ['separate_pools', 'numbers_per_pool', 'destinations'] : false}
                onChange={value => setFieldValue('json', value)}
              />
              <div className="form-button">
                <button
                  onClick={submitForm}
                  className="btn btn-lightblue"
                  disabled={!dirty || isSaving}
                >
                  {translateText('label', 'Save')}
                </button>
              </div>
            </div>
          )}
        </Formik>
      )}
    </div>
  );
}
