import { useCallback, useRef } from 'react';
import { useFormikContext } from 'formik';
import { debounce, isEqual } from 'lodash';

import { useEffectOnce, useUpdateEffect } from 'hooks/effect';

type Props = {
  name: string;
};

export default function PersistForm({ name }: Props) {
  const { values, setValues, validateForm } = useFormikContext<Record<string, unknown>>();
  const ref = useRef(values);

  if (!isEqual(ref.current, values)) {
    ref.current = values;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateValues = useCallback(
    debounce((values: unknown) => {
      localStorage.setItem(name, JSON.stringify(values));
    }, 1000),
    [name],
  );

  useEffectOnce(() => {
    const storageValues = localStorage.getItem(name);
    if (storageValues) setValues({ ...values, ...JSON.parse(storageValues) });
  });

  useUpdateEffect(() => {
    validateForm();
    const storageValues = localStorage.getItem(name);
    if (storageValues !== JSON.stringify(values)) updateValues(values);
  }, [name, ref.current]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
}
