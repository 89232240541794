import { useFormikContext } from 'formik';

import StepBlock from 'components/intake/StepBlock';
import { useDomainAuth, useEditSubscriptionPrerequisiteData } from 'hooks/queries/domain';
import { useTranslate } from 'hooks/translate';
import {
  EditSubscriptionFormValues,
  EditSubscriptionProps,
  EditSubscriptionType,
} from './EditSubscription';

export default function ThanksSection({ type }: EditSubscriptionProps) {
  const translateText = useTranslate();
  const { domainAuth } = useDomainAuth();
  const { data } = useEditSubscriptionPrerequisiteData(type);
  const { values } = useFormikContext<EditSubscriptionFormValues>();

  const isExtending =
    type === EditSubscriptionType.Extend &&
    (!!domainAuth?.can_extend_subscription || !domainAuth?.contract_end_date);
  const changeRequestId = data?.upgrade_request_id ?? values.changeRequestId;

  return (
    <div className="thanks-section">
      <StepBlock
        title={
          isExtending
            ? translateText('label', 'Extension requested')
            : translateText('label', 'Upgrade requested')
        }
      >
        <div className="white-block">
          <h4 className="bold">
            {isExtending
              ? translateText('text', 'Thank you for extending your subscription!')
              : translateText('text', 'Thank you for upgrading your subscription!')}
          </h4>
          <p
            dangerouslySetInnerHTML={{
              __html: translateText(
                'text',
                'Do you have any questions about your subscription? Please contact us via <a href="tel:+31307400990">+31 (0)30 7400 990</a> or via <a href="mailto:support@adcalls.nl">support@adcalls.nl</a>.',
              ),
            }}
          />
          {changeRequestId && (
            <a href={'/settings/change-requests/' + changeRequestId}>
              {translateText('text', 'Click here for the change request.')}
            </a>
          )}
        </div>
      </StepBlock>
    </div>
  );
}
