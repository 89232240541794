import DatePicker from 'react-datepicker';
import { enUS, nl } from 'date-fns/locale';
import { Formik, useFormikContext } from 'formik';
import moment, { Moment } from 'moment';
import * as Yup from 'yup';

import Radio from 'components/input/Radio';
import TextField from 'components/input/TextField';
import Toggle from 'components/input/Toggle';
import { SelectedFilters } from 'contexts/Filters';
import { DATE_PREVIOUS_TYPE_PERIOD, DATE_PREVIOUS_TYPE_YEAR } from 'globals/constants';
import { useTranslate } from 'hooks/translate';
import { useUserDateFormat, useUserLanguage } from 'hooks/user';

export default function DateSelector() {
  const translateText = useTranslate();
  const { values, setFieldValue } = useFormikContext<SelectedFilters>();
  const from = values.dates?.from;
  const to = values.dates?.to;

  const userDateFormat = useUserDateFormat();
  const dateFormat =
    userDateFormat === 'YYYY-MM-DD'
      ? translateText('label', 'yyyy-mm-dd')
      : translateText('label', 'dd-mm-yyyy');

  const language = useUserLanguage();
  const locale = language.startsWith('en') ? enUS : nl;

  function changeDates([from, to]: [Date, Date]) {
    setFieldValue('dates', {
      ...values.dates,
      from: moment(from).format('DD-MM-YYYY'),
      to: to ? moment(to).format('DD-MM-YYYY') : null,
    });
  }

  function changePreviousPeriod(
    value:
      | { enabled: boolean }
      | { type: typeof DATE_PREVIOUS_TYPE_PERIOD | typeof DATE_PREVIOUS_TYPE_YEAR },
  ) {
    setFieldValue('dates', {
      ...values.dates,
      previous: {
        ...values.dates.previous,
        ...value,
      },
    });
  }

  function getButton(dates: [Moment, Moment], label: string) {
    const isSelected =
      from === dates[0].format('DD-MM-YYYY') && to === dates[1].format('DD-MM-YYYY');
    return (
      <button
        className={'btn btn-lightblue bold' + (isSelected ? ' selected' : '')}
        onClick={() => changeDates([dates[0].toDate(), dates[1].toDate()])}
      >
        {label}
      </button>
    );
  }

  function getPreviousPeriodDates() {
    const dates: Date[] = [];
    if (from && to && values.dates.previous?.enabled) {
      const numberOfDays = moment(to, 'DD-MM-YYYY').diff(moment(from, 'DD-MM-YYYY'), 'days') + 1;
      let startDate = moment(from, 'DD-MM-YYYY').subtract(numberOfDays, 'days');
      if (values.dates.previous.type === DATE_PREVIOUS_TYPE_YEAR) {
        startDate = moment(from, 'DD-MM-YYYY').subtract(1, 'year');
      }
      for (let i = 0; i < numberOfDays; i++) {
        dates.push(startDate.toDate());
        startDate.add(1, 'day');
      }
    }
    return dates;
  }

  const validateDate = Yup.string().test({
    message: translateText('message', 'The entry is invalid (desired format: {format}).', {
      format: dateFormat,
    }),
    test: value => !!value && moment(value, userDateFormat, true).isValid(),
  });

  const validationSchema = Yup.object({
    from: validateDate,
    to: validateDate,
  });

  return (
    <div className="filter-selector date-selector">
      <div className="range-buttons">
        {getButton([moment(), moment()], translateText('datetime', 'Today'))}
        {getButton(
          [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          translateText('datetime', 'Yesterday'),
        )}
        {getButton(
          [
            moment().subtract(1, 'week').startOf('isoWeek'),
            moment().subtract(1, 'week').endOf('isoWeek'),
          ],
          translateText('datetime', 'Past week'),
        )}
        {getButton(
          [
            moment().subtract(1, 'month').startOf('month'),
            moment().subtract(1, 'month').endOf('month'),
          ],
          translateText('datetime', 'Past month'),
        )}
        {getButton(
          [moment().subtract(6, 'days'), moment()],
          translateText('datetime', 'Past {days} days', { days: '7' }),
        )}
        {getButton(
          [moment().subtract(29, 'days'), moment()],
          translateText('datetime', 'Past {days} days', { days: '30' }),
        )}
      </div>
      <DatePicker
        selectsRange
        onChange={changeDates}
        selected={from ? moment(from, 'DD-MM-YYYY').toDate() : null}
        startDate={from ? moment(from, 'DD-MM-YYYY').toDate() : null}
        endDate={to ? moment(to, 'DD-MM-YYYY').toDate() : null}
        maxDate={moment().toDate()}
        highlightDates={[{ previous: getPreviousPeriodDates() }]}
        locale={locale}
        monthsShown={2}
        showPreviousMonths
        inline
      />
      <Formik
        initialValues={{
          from: moment(from, 'DD-MM-YYYY').format(userDateFormat),
          to: to
            ? moment(to, 'DD-MM-YYYY').format(userDateFormat)
            : moment(from, 'DD-MM-YYYY').format(userDateFormat),
        }}
        validationSchema={validationSchema}
        onSubmit={values => {
          const from = moment(values.from, userDateFormat);
          const to = moment(values.to, userDateFormat);
          changeDates([from.toDate(), (from > to ? from : to).toDate()]);
        }}
        validateOnChange={false}
        validateOnMount={false}
        enableReinitialize
      >
        {({ values, handleChange, handleSubmit, submitForm, errors }) => (
          <div className="date-picker-input">
            <form onSubmit={handleSubmit}>
              <input type="submit" hidden /> {/* Needed to submit form on enter */}
              <div className="input">
                <div className="bold">{translateText('duplicate', 'From')}</div>
                <TextField
                  name="from"
                  value={values.from}
                  onChange={handleChange}
                  onBlur={submitForm}
                  placeholder={dateFormat}
                  error={errors.from}
                />
              </div>
              <div className="input">
                <div className="bold">{translateText('label', 'Until').toLowerCase()}</div>
                <TextField
                  name="to"
                  value={values.to}
                  onChange={handleChange}
                  onBlur={submitForm}
                  placeholder={dateFormat}
                  error={errors.to}
                />
              </div>
            </form>
          </div>
        )}
      </Formik>
      <div className="date-picker-previous">
        <div className="left">
          <Toggle
            id="previous-period-enabled"
            name="previous-period-enabled"
            checked={values.dates.previous?.enabled}
            onClick={e => changePreviousPeriod({ enabled: (e.target as HTMLInputElement).checked })}
          />
          {translateText('label', 'Compare period')}
        </div>
        {values.dates.previous?.enabled && (
          <div className="right">
            <div className="options-divide">
              <div className="options-below">
                <div className="global-filter-radio-button">
                  <Radio
                    id="input-previous-period"
                    name="previous-type"
                    label={translateText('label', 'Equal period')}
                    labelClassName="extra-margin"
                    checked={values.dates.previous?.type === DATE_PREVIOUS_TYPE_PERIOD}
                    onChange={() => changePreviousPeriod({ type: DATE_PREVIOUS_TYPE_PERIOD })}
                  />
                </div>
                <div className="global-filter-radio-button">
                  <Radio
                    id="input-previous-year"
                    name="previous-type"
                    label={translateText('label', 'Period last year')}
                    labelClassName="extra-margin"
                    checked={values.dates.previous?.type === DATE_PREVIOUS_TYPE_YEAR}
                    onChange={() => changePreviousPeriod({ type: DATE_PREVIOUS_TYPE_YEAR })}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
