import { ChangeEventHandler } from 'react';
import { range } from 'lodash';

import InputWrapper from 'components/slide-in/InputWrapper';
import { useTranslate } from 'hooks/translate';
import Select from './Select';

type Props = {
  hour: string;
  minute: string;
  onChange: ChangeEventHandler;
};

function pad(value: number): string {
  if (value < 10) return '0' + value;
  return String(value);
}

export default function TimePicker({ hour, minute, onChange }: Props) {
  const translateText = useTranslate();
  const hours = range(0, 24).map(h => pad(h));
  const minutes = range(0, 60).map(m => pad(m));
  return (
    <div className="time-selector">
      <InputWrapper label={translateText('label', 'Hour')}>
        <Select
          name="hour"
          value={hours.includes(hour) ? hour : hours[0]}
          options={hours}
          onChange={onChange}
        />
      </InputWrapper>
      <InputWrapper label={translateText('label', 'Minute')}>
        <Select
          name="minute"
          value={minutes.includes(minute) ? minute : minutes[0]}
          options={minutes}
          onChange={onChange}
        />
      </InputWrapper>
    </div>
  );
}
