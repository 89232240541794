import { useEffect, useState } from 'react';
import moment from 'moment';

import CardListView, { BaseItem } from 'components/configuration/card-list/CardListView';
import ConfigurationPage from 'components/configuration/ConfigurationPage';
import LocationCard from 'components/configuration/locations/LocationCard';
import LocationSetup from 'components/configuration/locations/LocationSetup';
import Tooltip from 'components/Tooltip';
import { useTenant } from 'contexts/Tenant';
import { LOCATION_STATUS_IN_REQUEST } from 'globals/constants';
import { Offsite } from 'globals/types';
import { useTrial } from 'hooks/access';
import {
  useCreateLocation,
  useDeleteLocation,
  useExportLocations,
  useLocations,
} from 'hooks/queries/aen';
import { useUpdateLocation } from 'hooks/queries/domain';
import { usePendingChanges } from 'hooks/queries/domain-change-request';
import { useTranslate } from 'hooks/translate';
import { useUserDateFormat } from 'hooks/user';

export type LocationItem = {
  netnumber?: string;
  destination?: string;
  tracking_type?: string;
  offsite?: Offsite[];
} & BaseItem;

export default function Locations() {
  const translateText = useTranslate();
  const dateFormat = useUserDateFormat();
  const { selectedDomain } = useTenant();
  const { isTrial } = useTrial();
  const { createLocation } = useCreateLocation();
  const { updateLocation } = useUpdateLocation();
  const { deleteLocation } = useDeleteLocation();
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [editItem, setEditItem] = useState<'new' | number | false>(false);

  useEffect(() => setPage(1), [selectedDomain, search]);

  const { exportLocations, isExporting } = useExportLocations();
  const {
    locations,
    isLoading: locationsIsLoading,
    pageCount,
    itemCount,
  } = useLocations({ expand: 'offsite,status,tracking_type', search, limit, page });
  const { pending, isLoading: pendingIsLoading } = usePendingChanges(search);
  const isLoading = locationsIsLoading || pendingIsLoading;

  const locationItems = locations?.map(location => {
    const isNew = location.status === LOCATION_STATUS_IN_REQUEST;
    const identifier = location.aen_identifier;
    let requestId = null;
    let warning = null;
    let extra = null;
    if (isNew) {
      requestId = Number(pending?.aenNew?.[identifier]?.request_id);
      warning = translateText('label', 'Location requested');
    } else if (pending?.aenStop?.[identifier]) {
      requestId = Number(pending?.aenStop?.[identifier]);
      warning = translateText('label', 'Stop requested');
    } else if (pending?.aenChange?.[identifier]) {
      warning = translateText('label', 'Change requested');
    } else if (location.stop_requested_for) {
      warning = translateText('label', 'Will be stopped at {date}', {
        date: moment(location.stop_requested_for).format(dateFormat),
      });
      extra = (
        <Tooltip
          html={<div className="pending">{translateText('label', 'Will be stopped')}</div>}
          text={warning}
        />
      );
    }

    return { ...location, identifier, isNew, requestId, extra, warning };
  });

  let rowCount = 1;
  if (itemCount > limit) rowCount = limit;
  if (pageCount !== 1 && pageCount === page) {
    rowCount = itemCount % limit || limit;
  }

  return (
    <ConfigurationPage title={translateText('label', 'Locations')}>
      <CardListView
        itemName={translateText('label', 'Locations')}
        setEdit={value => setEditItem(value)}
        search={(value?: string) => setSearch(value ?? '')}
        isLoading={isLoading}
        items={locationItems}
        showIdentifier
        renderContent={location => <LocationCard location={location} />}
        rowCount={rowCount}
        exportCsv={() => exportLocations()}
        isExporting={isExporting}
        pagination={{
          page,
          limit,
          itemCount,
          pageCount,
          onChange: (pageNumber, itemsPerPage) => {
            setPage(pageNumber);
            setLimit(itemsPerPage);
          },
        }}
        disableAdd={
          isTrial && locations && locations.length >= 5
            ? translateText(
                'tooltip/domain',
                'You can track up to 5 locations within the trial period. If you want to track more than 5 locations, you can choose to wait for the trial period to end or immediately convert to a paid pricing plan.',
              )
            : null
        }
      />
      <LocationSetup
        item={typeof editItem === 'number' ? locationItems![editItem] : editItem}
        close={() => setEditItem(false)}
        onSave={async values => {
          if (editItem === 'new') {
            return await createLocation({
              name: values.name,
              net_number: String(values.country) + String(values.area),
              destination: values.destination,
              offsitesRequest: values.addedOffsites,
            });
          } else {
            return await updateLocation({
              aen_identifier: locationItems![editItem as number]!.identifier,
              name: values.name,
              destination: values.destination,
              offsitesRequest: values.addedOffsites,
              offsitesUpdate: Object.values(values.changedOffsites),
              offsitesDelete: values.deletedOffsites.map(o => o.numberpool_id!),
            });
          }
        }}
        deleteLocation={location =>
          deleteLocation(location.identifier).then(() => setEditItem(false))
        }
      />
    </ConfigurationPage>
  );
}
