import { DependencyList, PropsWithChildren, useCallback, useRef } from 'react';
import { useIsFetching } from '@tanstack/react-query';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { useFilters } from 'contexts/Filters';
import { useTenant } from 'contexts/Tenant';
import { useEventListener } from 'hooks/event';

const BIG_COLUMNS = 475 as const; // If changed also change AutoGrid.scss
const MEDIUM_COLUMNS = 250 as const; // If changed also change AutoGrid.scss
const SMALL_COLUMNS = 200 as const; // If changed also change AutoGrid.scss

// Due to a limit of the grid rows in browsers, the grid can only be 1000 rows. (about 60 average widgets)

type Props = {
  id?: string;
  dependencies?: DependencyList;
  size?: 'small' | 'medium' | 'large';
  className?: string;
  evenly?: boolean; // If you have 6 tiles and it doesn't fit it wil be split 2x3, for the best result the items need to be the same height
  stretch?: boolean; // If you have 1 row with 2 items it will always take the 2 column width instead of max columns
  noGrow?: boolean; // If the tiles may not grow (default will grow 1 column and till next row appearance)
  growToEnd?: boolean; // If there are no tiles below a growable tile should it grow to the end? default is false
  maxRows?: number; // If there needs to be a maximum of rows. (only use with same sized items)
};

export default function AutoGrid({
  id,
  children,
  dependencies = [],
  size = 'medium',
  className,
  evenly = false,
  stretch = false,
  noGrow = false,
  growToEnd = false,
  maxRows,
}: PropsWithChildren<Props>) {
  const queriesLoading = useIsFetching();
  const ref = useRef<HTMLDivElement | null>(null);
  const resizeBlocks = useCallback(() => {
    if (!ref.current) return;
    const element = ref.current as HTMLDivElement;

    let gap = 25; // If changed also change main.scss
    if (document.body.clientWidth < 1440) gap = 15; // If changed also change main.scss

    let minColumnWidth: number = MEDIUM_COLUMNS; // If changed also change AutoGrid.scss
    switch (size) {
      case 'small':
        minColumnWidth = SMALL_COLUMNS; // If changed also change AutoGrid.scss
        break;
      case 'large':
        minColumnWidth = BIG_COLUMNS; // If changed also change AutoGrid.scss
        break;
    }

    const width = ref.current.getBoundingClientRect().width + gap;
    let numberOfColumnsPossible = Math.floor(width / (minColumnWidth + gap));

    const numberOfItems = ref.current.children.length;

    let showItems;
    if (typeof maxRows === 'number') {
      const numberOfRows = numberOfItems / numberOfColumnsPossible;
      if (numberOfRows > maxRows) showItems = maxRows * numberOfColumnsPossible;
    }

    for (let i = 0; i < numberOfItems; i++) {
      // Setting the end row, the height of the card
      const block = element.children[i] as HTMLElement;
      // Calculating without the min height, so it can shrink
      block.style.minHeight = '0';
      const endRow = Math.ceil((block?.getBoundingClientRect().height + gap) / gap);
      block.style.gridRowEnd = 'span ' + endRow;
      block.style.minHeight = '';

      // recalculate number of columns needed for evenly divided without an extra row
      if (evenly && numberOfItems > numberOfColumnsPossible) {
        const numberOfRows = Math.ceil(numberOfItems / numberOfColumnsPossible);
        numberOfColumnsPossible = Math.ceil(numberOfItems / numberOfRows);
        element.style.gridTemplateColumns =
          'repeat(' + numberOfColumnsPossible + ', minmax(' + minColumnWidth + 'px, 1fr))';
      } else {
        element.style.gridTemplateColumns = '';
      }

      // Prevent an item to have more columns than there are available
      let maxColumns = numberOfColumnsPossible;
      const wantedColumns = block.dataset?.col ?? '1';
      if (Number(wantedColumns) < maxColumns) {
        maxColumns = Number(wantedColumns);
      }
      // Always set gridColumnEnd so it calculates position on wanted columns, it'll later grow when possible
      block.style.gridColumnEnd = 'span ' + maxColumns;

      if (showItems && showItems <= i) {
        block.style.display = 'none';
      } else if (showItems) {
        if (showItems - 1 === i) {
          block.classList.add('more-items');
          block.dataset.content = '+' + (numberOfItems - i);
        } else {
          block.classList.remove('more-items');
          block.dataset.content = '';
        }
        block.style.display = '';
      }
    }

    if (noGrow) return;

    // The code below is used for growing vertically (till possible) and horizontally (1 column)
    const gridPlacement = element.getBoundingClientRect();
    const objOfPlacement: Record<string, Record<string, HTMLElement | false>> = {};
    const fullGrid: Record<string, Record<string, HTMLElement | false>> = {};

    // fill objOfPlacement with all the starting rows and gridPlacement all the cells that are taken
    for (let i = 0; i < numberOfItems; i++) {
      const block = element.children[i] as HTMLElement;
      const numberOfColumnsForItem = Number(block.dataset?.col ?? '1');
      const itemPlacement = block.getBoundingClientRect();
      const startRow = Math.round((itemPlacement.y - gridPlacement.y) / gap) + 1;
      const startColumn =
        Math.round((itemPlacement.x - gridPlacement.x) / (width / numberOfColumnsPossible)) + 1;
      const endRow = Number(block.style.gridRowEnd.replace('span ', '')) + Number(startRow);

      if (objOfPlacement[startRow] === undefined) objOfPlacement[startRow] = {};
      objOfPlacement[startRow][startColumn] = block;

      for (let j = 1; j < numberOfColumnsForItem; j++) {
        objOfPlacement[startRow][startColumn + j] = false;
      }

      for (let k = startRow; k < endRow; k++) {
        if (fullGrid[k] === undefined) fullGrid[k] = {};
        fullGrid[k][startColumn] = k === startRow ? block : false;
        for (let l = 1; l < numberOfColumnsForItem; l++) {
          fullGrid[k][startColumn + l] = false;
        }
      }
    }

    const rows = Object.keys(fullGrid);
    const lastRow = Number(rows[rows.length - 1]);
    const rowIds = Object.keys(objOfPlacement);

    // Loop through all stating rows
    for (const id of rowIds) {
      const rowId = Number(id);
      const row = objOfPlacement[rowId];
      const columnIds = Object.keys(row);

      // Loop through all columns in the stating row
      for (let j = 0; j < columnIds.length; j++) {
        const columnId = Number(columnIds[j]);
        const block = row[columnId];

        // Only when block is a element (so its is not false, false is the expand of the element)
        // Calculate where the element needs to stop (gridRowEnd, vertically)
        if (block !== false) {
          let numberOfRows = Number(block.style.gridRowEnd.replace('span ', ''));
          const numberOfColumns = Number(block.dataset?.col || 1); // eslint-disable-line @typescript-eslint/prefer-nullish-coalescing
          let shouldGrowToEnd = false;
          checkGrowRight: while (
            (fullGrid[rowId + numberOfRows] === undefined ||
              fullGrid[rowId + numberOfRows][columnId] === undefined) &&
            rowId + numberOfRows <= lastRow
          ) {
            for (let k = 1; k < numberOfColumns; k++) {
              if (fullGrid[rowId + numberOfRows]?.[columnId + k] !== undefined) {
                break checkGrowRight;
              }
            }
            if (fullGrid[rowId + numberOfRows] === undefined) fullGrid[rowId + numberOfRows] = {};
            if (fullGrid[rowId + numberOfRows][columnId] === undefined)
              fullGrid[rowId + numberOfRows][columnId] = false;
            if (!growToEnd) shouldGrowToEnd = rowId + numberOfRows === lastRow;
            numberOfRows++;
          }
          if (shouldGrowToEnd) numberOfRows = Number(block.style.gridRowEnd.replace('span ', ''));
          block.style.gridRowEnd = 'span ' + numberOfRows;
        }

        // Only when block is a element, and column id is not the last column (otherwise it will expand outside of the grid)
        // Calculate where the element needs to stop (gridColumnEnd, horizontally)
        const numberOfColumns = Number((block && block?.dataset?.col) || 1); // eslint-disable-line @typescript-eslint/prefer-optional-chain
        if (block !== false && columnId + numberOfColumns <= numberOfColumnsPossible) {
          const endRow = Number(block.style.gridRowEnd.replace('span ', '')) + Number(rowId);
          const previousRowsToCheckIfEmpty = [];
          for (let k = rowId; k < endRow; k++) {
            previousRowsToCheckIfEmpty.push(Number(k));
          }

          const nextColumnId = row[columnIds[j + numberOfColumns]];
          if (columnId < numberOfColumnsPossible && nextColumnId === undefined) {
            let canGrow = true;
            for (const rowToCheck of previousRowsToCheckIfEmpty) {
              if (
                fullGrid[rowToCheck] !== undefined &&
                fullGrid[rowToCheck][columnId + numberOfColumns] !== undefined
              ) {
                canGrow = false;
                break;
              }
            }

            if (canGrow) block.style.gridColumnEnd = 'span ' + (numberOfColumns + 1);
          }
        }
      }
    }
  }, [evenly, growToEnd, maxRows, noGrow, size]);

  const { selectedDomain } = useTenant();
  const { filters } = useFilters();

  useEventListener('resize', resizeBlocks);
  useDeepCompareEffect(() => {
    setTimeout(() => resizeBlocks(), 100); // Timeout is needed because it needs to be triggered after the widgets.
  }, [resizeBlocks, selectedDomain, filters, queriesLoading, ...dependencies]); // eslint-disable-line react-hooks/exhaustive-deps

  let autoGridClass = 'auto-grid ' + (size === 'small' ? 'tiny' : size);
  if (className) autoGridClass += ' ' + className;
  if (stretch) autoGridClass += ' stretch';

  return (
    <div id={id} className={autoGridClass} ref={ref}>
      {children}
    </div>
  );
}
