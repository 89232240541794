import { useRef, useState } from 'react';

import flagEn from 'assets/images/flags/en-US.svg';
import flagNl from 'assets/images/flags/nl-NL.svg';
import { useGlobal } from 'contexts/Global';
import { LANGUAGE_DUTCH, LANGUAGE_ENGLISH_US } from 'globals/constants';
import { useOnClickOutside } from 'hooks/event';

export default function LanguageSelector() {
  const { selectedLanguage, setSelectedLanguage } = useGlobal();
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => setOpen(false));

  const options = {
    [LANGUAGE_DUTCH]: {
      name: 'Nederlands',
      flag: flagNl,
    },
    [LANGUAGE_ENGLISH_US]: {
      name: 'English',
      flag: flagEn,
    },
  };

  const currentLanguage = selectedLanguage ?? LANGUAGE_DUTCH;
  return (
    <div className="language-wrapper" ref={ref}>
      <div className="selected-language-wrapper" onClick={() => setOpen(open => !open)}>
        <div className="selected-language">
          <img
            alt={options[currentLanguage as keyof typeof options].name}
            className="flag"
            src={options[currentLanguage as keyof typeof options].flag}
          />
          <div className="triangle-down" />
        </div>
      </div>
      {open && (
        <div className="language-options">
          {Object.keys(options)
            .filter(option => option !== currentLanguage)
            .map(option => {
              return (
                <div
                  className="language-option"
                  key={option}
                  onClick={() => {
                    setSelectedLanguage(option);
                    setOpen(false);
                  }}
                >
                  <img
                    alt={options[option as keyof typeof options].name}
                    className="flag"
                    src={options[option as keyof typeof options].flag}
                  />
                  <span className="language">{options[option as keyof typeof options].name}</span>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}
