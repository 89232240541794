import { useNavigate } from 'react-router';

import QueryTable from 'components/table/QueryTable';
import {
  USER_STATUS_ACTIVE,
  USER_STATUS_DELETED,
  USER_STATUS_EMAIL_VERIFICATION,
  USER_STATUS_INACTIVE,
  USER_STATUS_INVITED,
  USER_STATUS_LOCKED,
} from 'globals/constants';
import { useTranslate } from 'hooks/translate';

export type UserStatus =
  | typeof USER_STATUS_ACTIVE
  | typeof USER_STATUS_DELETED
  | typeof USER_STATUS_EMAIL_VERIFICATION
  | typeof USER_STATUS_INACTIVE
  | typeof USER_STATUS_INVITED
  | typeof USER_STATUS_LOCKED;

export type AdminUser = {
  id: number;
  phonenumber: string;
  email: string;
  first_name: string;
  last_name: string;
  company: string | null;
  marketing_agency: string | null;
  status: string;
  status_id: UserStatus;
  reseller_label: string | null;
  link: string;
};

export default function Users() {
  const navigate = useNavigate();
  const translateText = useTranslate();

  return (
    <>
      <h1>{translateText('label', 'Users')}</h1>
      <QueryTable
        tableKey={['users', 'admin']}
        columns={{
          id: { header: { name: translateText('label', 'ID'), sort: true }, search: true },
          first_name: {
            header: { name: translateText('label', 'First name'), sort: true },
            search: true,
          },
          last_name: {
            header: { name: translateText('label', 'Last name'), sort: true },
            search: true,
          },
          email: {
            header: { name: translateText('label', 'Email'), sort: true },
            search: true,
          },
          reseller_label: { header: translateText('label', 'Partner'), search: true },
          status: {
            header: { name: translateText('label', 'Status'), sort: true },
            search: {
              [USER_STATUS_DELETED]: translateText('label', 'Deleted'),
              [USER_STATUS_INACTIVE]: translateText('label', 'Inactive'),
              [USER_STATUS_ACTIVE]: translateText('label', 'Active'),
              [USER_STATUS_INVITED]: translateText('label', 'Invited'),
              [USER_STATUS_EMAIL_VERIFICATION]: translateText('label', 'Email not verified'),
              [USER_STATUS_LOCKED]: translateText('label', 'Locked'),
            },
          },
        }}
        onRowClick={user => navigate('/admin/users/' + user.id)}
        pagination={{ defaultLimit: 20 }}
        endpoint="/user/index"
        filters={{ domain: false, custom: { expand: 'reseller_label,link' } }}
        refresh
      />
    </>
  );
}
