import { useMutation, useQuery } from '@tanstack/react-query';
import { omit } from 'lodash';

import { useMessages } from 'contexts/Messages';
import { useSetQueryData } from 'hooks/data';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';
import { useUser } from './user';

export type InvoiceProfile = {
  label: string;
  email: string;
  kvk_number: string;
  vat_number: string | null;
  company: string;
  name: string;
  phone_number: string;
  reference: string;
  address: string;
  zipcode: string;
  city: string;
  country: string;
  automatic_collection: 0 | 1;
};

export function useInvoiceProfiles() {
  const { fetchData } = useFetch();

  const { data, isPending: isLoading } = useQuery({
    queryKey: ['invoiceProfiles'],
    queryFn: (): Promise<({ id: number; created_by: number } & InvoiceProfile)[]> =>
      fetchData('/v4/invoice-profile'),
  });

  return { invoiceProfiles: data, isLoading };
}

export function useCreateInvoiceProfile() {
  const translateText = useTranslate();
  const setQueryData = useSetQueryData();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { user } = useUser();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (values: InvoiceProfile & { invoice_profile_id: string }) =>
      fetchData('/v4/invoice-profile/create', {
        method: 'POST',
        bodyParams: { created_by: user?.id, ...omit(values, 'invoice_profile_id') },
      }),
    onSuccess: data => {
      setQueryData<({ id: number; created_by: number } & InvoiceProfile)[]>(
        ['invoiceProfiles'],
        oldData => [...oldData, data],
      );
      addSuccessMessage(translateText('message', 'The invoice profile has been created.'));
    },
  });

  return { createInvoiceProfile: mutateAsync, isCreating: isPending };
}

export function useUpdateInvoiceProfile() {
  const translateText = useTranslate();
  const setQueryData = useSetQueryData();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { user } = useUser();

  const { mutate, isPending } = useMutation({
    mutationFn: (values: InvoiceProfile & { invoice_profile_id: string }) =>
      fetchData('/v4/invoice-profile/' + values.invoice_profile_id, {
        method: 'PUT',
        bodyParams: { created_by: user?.id, ...omit(values, 'invoice_profile_id') },
      }),
    onSuccess: (data, variables) => {
      setQueryData<({ id: number; created_by: number } & InvoiceProfile)[]>(
        ['invoiceProfiles'],
        oldData => oldData.map(p => (p.id === +variables.invoice_profile_id ? data : p)),
      );
      addSuccessMessage(translateText('message', 'The invoice profile has been saved.'));
    },
  });

  return { updateInvoiceProfile: mutate, isUpdating: isPending };
}

export function useDeleteInvoiceProfile() {
  const translateText = useTranslate();
  const setQueryData = useSetQueryData();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (id: string) =>
      fetchData('/v4/invoice-profile/delete', {
        method: 'DELETE',
        queryParams: { id },
      }),
    onSuccess: (data, id) => {
      setQueryData<({ id: number; created_by: number } & InvoiceProfile)[]>(
        ['invoiceProfiles'],
        oldData => oldData.filter(p => p.id !== +id),
      );
      addSuccessMessage(
        translateText('message', 'The invoice profile has been successfully deleted.'),
      );
    },
  });

  const deleteInvoiceProfile = async (id: string) => {
    if (
      window.confirm(
        translateText('message', 'Are you sure you want to delete this invoice profile?'),
      )
    ) {
      return await mutateAsync(id);
    }
  };

  return { deleteInvoiceProfile, isDeleting: isPending };
}
