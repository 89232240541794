import { useFormikContext } from 'formik';

import ErrorFocus from 'components/ErrorFocus';
import TextField from 'components/input/TextField';
import InputWrapper from 'components/slide-in/InputWrapper';
import { useTranslate } from 'hooks/translate';
import { WebhookFormValues } from './WebhookForm';

export default function HttpHeaderForm() {
  const translateText = useTranslate();

  // Fields are validated manually, because otherwise the form validates on slide in close and it looks weird
  const { values, handleChange, errors, validateField } = useFormikContext<WebhookFormValues>();

  return (
    <div className="api-test-form">
      <ErrorFocus element={document.getElementsByClassName('setup-wrapper')[0]} onValidate />
      <InputWrapper label={translateText('label', 'Header')}>
        <TextField
          name="headerKey"
          value={values.headerKey}
          onChange={e => {
            handleChange(e);
            setTimeout(() => validateField('headerKey'), 1); // Timeout is needed to validate the correct value
          }}
          error={errors.headerKey}
        />
      </InputWrapper>
      <InputWrapper label={translateText('label', 'Value')}>
        <TextField
          name="headerValue"
          value={values.headerValue}
          onChange={e => {
            handleChange(e);
            setTimeout(() => validateField('headerValue'), 1); // Timeout is needed to validate the correct value
          }}
          error={errors.headerValue}
        />
      </InputWrapper>
    </div>
  );
}
