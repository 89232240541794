import { useMutation, useQuery } from '@tanstack/react-query';

import ErrorTooltip from 'components/ErrorTooltip';
import Skeleton from 'components/Skeleton';
import InputWrapper from 'components/slide-in/InputWrapper';
import { useMessages } from 'contexts/Messages';
import { useTenant } from 'contexts/Tenant';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';
import { RedirectUriKey } from './Verification';

type Props = {
  integrationName: string;
  verified: boolean;
  redirectUri: string;
  redirectUriKey: RedirectUriKey;
  disconnectEndpoint: string;
  tooltip?: string;
  isGoogle?: boolean;
  onDisconnectSuccess?: () => void;
  validateBeforeVerify?: () => Promise<boolean>;
  beforeRedirect?: () => void;
  queryParams?: Record<string, string>;
  isLoading?: boolean;
  error?: boolean | string;
};

export default function VerificationButton({
  integrationName,
  verified,
  redirectUri,
  redirectUriKey,
  disconnectEndpoint,
  tooltip,
  isGoogle,
  onDisconnectSuccess,
  validateBeforeVerify,
  beforeRedirect,
  queryParams = {},
  isLoading,
  error,
}: Props) {
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();
  const {
    data: oauthUri,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: [redirectUriKey, selectedDomain],
    queryFn: () => fetchData(redirectUri, { queryParams: { ...queryParams, id: selectedDomain } }),
    enabled: validateBeforeVerify === undefined && selectedDomain !== null,
  });

  const { mutate, isPending: isDisconnecting } = useMutation({
    mutationFn: () =>
      fetchData(disconnectEndpoint, { method: 'DELETE', queryParams: { id: selectedDomain } }),
    onSuccess: data => {
      localStorage.setItem(redirectUriKey, data);
      addSuccessMessage(
        translateText(
          'message',
          'The {integrationName} account has been successfully disconnected.',
          { integrationName },
        ),
      );
      if (onDisconnectSuccess) onDisconnectSuccess();
    },
  });

  let classname = isGoogle && !verified ? 'google-button' : 'btn btn-lightblue';
  if (error) classname += ' error';

  async function verify() {
    let redirect = oauthUri;
    if (validateBeforeVerify) {
      if (await validateBeforeVerify()) redirect = (await refetch()).data;
      else return;
    }

    if (!redirect) return;
    if (beforeRedirect) beforeRedirect();
    window.location.assign(redirect);
  }

  function disconnect() {
    const warning = translateText('message', 'Are you sure you want to disconnect your account?');
    if (window.confirm(warning)) mutate();
  }

  return (
    <InputWrapper
      label={translateText('label', 'Verify account')}
      tooltip={
        tooltip ??
        translateText(
          'integration',
          'Grant access to the {integration} account so that the calls can be processed as a conversion in {integration}.',
          { integration: integrationName },
        )
      }
      isLoading={isLoading}
      fieldClassName={'verification-button' + (isLoading ? ' loading' : '')}
    >
      {isLoading ? (
        <Skeleton className="btn" width={200} />
      ) : (
        <ErrorTooltip
          error={error}
          html={
            <button
              disabled={isFetching || isDisconnecting}
              className={classname}
              onClick={verified ? disconnect : verify}
            >
              {!verified && !isGoogle
                ? translateText('label', 'Verify account')
                : verified
                  ? translateText('label', 'Disconnect account')
                  : ''}
            </button>
          }
        />
      )}
      <div className="verification-status">
        {isLoading ? (
          <>
            <Skeleton className="big-dot" />
            <Skeleton width={120} />
          </>
        ) : (
          <>
            <div className={'big-dot ' + (verified ? 'active' : 'inactive')} />
            {verified ? translateText('label', 'Verified') : translateText('label', 'Not verified')}
          </>
        )}
      </div>
    </InputWrapper>
  );
}
