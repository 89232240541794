import icon from 'assets/images/icons/integrations/google-display-video-360.svg';
import BaseGoogle360, { TYPE_DISPLAY_VIDEO_360 } from 'components/integrations/BaseGoogle360';
import { REDIRECT_URI_GOOGLE_DV360 } from 'components/oauth2/Verification';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import { INTEGRATION_GOOGLE_DISPLAY_VIDEO_360 } from 'globals/constants';
import { SUP_ART_DV360 } from 'globals/support';
import { useHasAccessToIntegration } from 'hooks/access';
import { useTranslate } from 'hooks/translate';

export default function GoogleDisplayVideo360({ show, close }: BaseSlideInProps) {
  const translateText = useTranslate();
  const hasAccessToIntegration = useHasAccessToIntegration();

  if (!hasAccessToIntegration(INTEGRATION_GOOGLE_DISPLAY_VIDEO_360)) return null;

  return (
    <BaseGoogle360
      show={show}
      close={close}
      icon={icon}
      infoText={translateText(
        'integration',
        'With the help of this app you can integrate call-conversions in Google Display & Video 360 (DV360).',
      )}
      articleId={SUP_ART_DV360}
      type={TYPE_DISPLAY_VIDEO_360}
      title={translateText('label', 'Google Display & Video 360')}
      redirectUriKey={REDIRECT_URI_GOOGLE_DV360}
      integrationKey={INTEGRATION_GOOGLE_DISPLAY_VIDEO_360}
    />
  );
}
