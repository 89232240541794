import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useMessages } from 'contexts/Messages';
import { useTenant } from 'contexts/Tenant';
import { useHasManagerAccess } from 'hooks/access';
import { useSetTableData } from 'hooks/data';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';
import { UserDomain } from 'pages/authenticated/admin/UserDetails';

export function useAssignableRoles() {
  const { fetchData } = useFetch();
  const { selectedDomain } = useTenant();
  const hasManagerAccess = useHasManagerAccess();

  const { data, isFetching } = useQuery({
    queryKey: ['role', 'assignable'],
    queryFn: (): Promise<{ id: string; description: string }[]> =>
      fetchData('/role/assignable', { queryParams: { domain: selectedDomain } }),
    enabled: selectedDomain !== null && hasManagerAccess,
  });

  return { roles: data, isLoading: isFetching };
}

export function useAssignAndRevokeRole() {
  const queryClient = useQueryClient();
  const { fetchData } = useFetch();
  const { selectedDomain } = useTenant();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (values: { userId: number; role: string }) =>
      fetchData('/role/assign-and-revoke', {
        method: 'POST',
        bodyParams: { domain: selectedDomain, user: values.userId, roles: [values.role] },
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['domain', 'users', selectedDomain] }),
  });

  return { assignAndRevokeRole: mutateAsync, isLoading: isPending };
}

export function useRevokeRole() {
  const setTableData = useSetTableData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (values: {
      user: { id: number; email: string };
      domain?: { id: string; title: string };
    }) =>
      fetchData('/role/revoke', {
        method: 'POST',
        bodyParams: {
          domain: values.domain?.id ?? selectedDomain,
          user: values.user.id,
          reseller: false,
        },
      }),
    onSuccess: (data, variables) => {
      setTableData(['domain', 'users', selectedDomain], oldData =>
        oldData.filter(u => u.id !== variables.user.id),
      );
      setTableData<UserDomain>(['user', 'domains', { id: variables.user.id }], oldData =>
        oldData.filter(item => item.domain.id !== variables.domain?.id),
      );
      addSuccessMessage(
        translateText(
          'message',
          'Access for user <span data-private>{user}</span> has been denied.',
          { user: variables.user.email },
        ),
      );
    },
  });

  return {
    revokeRole: async (values: {
      user: { id: number; email: string };
      domain?: { id: string; title: string };
    }) => {
      if (
        window.confirm(
          translateText(
            'text',
            'Are you sure you want to revoke access to {domain} for user {user}?',
            {
              domain: values.domain?.title ?? translateText('label', 'This domain').toLowerCase(),
              user: values.user.email,
            },
          ),
        )
      ) {
        await mutateAsync(values);
      }
    },
    isLoading: isPending,
  };
}
