import { useMutation } from '@tanstack/react-query';

import { NUMBERPOOL_STATUS_INACTIVE } from 'components/admin/CampaignBuilderPool';
import { useMessages } from 'contexts/Messages';
import { useTenant } from 'contexts/Tenant';
import { useSetTableData } from 'hooks/data';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';
import { NumberAssignPool } from 'pages/authenticated/admin/phone-numbers/NumberAssignStats';

export function usePutBackToStock() {
  const setTableData = useSetTableData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const { mutate, isPending } = useMutation({
    mutationFn: (id: number) =>
      fetchData('/numberpool/to-stock', {
        method: 'PUT',
        queryParams: { id },
      }),
    onSuccess: (data, id) => {
      setTableData(['campaignBuilder', 'pools', selectedDomain], oldData =>
        oldData.map(np =>
          np.id === id
            ? { ...np, is_active: NUMBERPOOL_STATUS_INACTIVE, number_assignable_count: 0 }
            : np,
        ),
      );
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  const putBackToStock = (id: number) => {
    if (
      window.confirm(
        translateText('admin/text', 'Are you sure you want to put this number pool back in stock?'),
      )
    ) {
      mutate(id);
    }
  };

  return { putBackToStock, isPuttingBackToStock: isPending };
}

export function useSetNumbers() {
  const { fetchData } = useFetch();
  const setTableData = useSetTableData();
  const { addErrorMessage, addSuccessMessage } = useMessages();

  const { mutateAsync } = useMutation({
    mutationFn: ({ id, amount }: { id: string; amount: string }) =>
      fetchData('/numberpool/set-numbers', {
        method: 'PUT',
        queryParams: { id },
        bodyParams: { amount },
      }),
    onError: data => addErrorMessage(data, 20),
    onSuccess: (data: string, values) => {
      addSuccessMessage(data, 20);
      // The data says how many numbers were added, for example "Added 10 numbers of 30."
      const [, addedCount, , , requestedCount] = data.replace('.', '').split(' ');
      setTableData<NumberAssignPool>(['admin', 'numberAssignStats'], oldData =>
        oldData.map(item => {
          if (item.numberpool !== values.id) return item;
          return {
            ...item,
            assignable_numbers: String(
              +addedCount < +requestedCount
                ? +addedCount + +item.assignable_numbers
                : values.amount,
            ),
          };
        }),
      );
    },
    meta: { noError: true },
  });

  return { setNumbers: mutateAsync };
}
