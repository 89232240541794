import { useState } from 'react';
import { Formik } from 'formik';
import { cloneDeep, findKey, isEmpty, mapValues } from 'lodash';
import * as Yup from 'yup';

import ConfigurationCard from 'components/configuration/ConfigurationCard';
import CreateDeleteTable from 'components/CreateDeleteTable';
import SearchableSelect from 'components/input/SearchableSelect';
import TextField from 'components/input/TextField';
import Skeleton from 'components/Skeleton';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import SetupWithSub from 'components/slide-in/SetupWithSub';
import SupportParagraph from 'components/SupportParagraph';
import { IMPLEMENTATION_TYPE_STANDARD, PH_MASK } from 'globals/constants';
import { SUP_ART_STANDARD_IMPLEMENTATION } from 'globals/support';
import { useDomainAuth, useImplementation, useUpdateLocationFormats } from 'hooks/queries/domain';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation } from 'hooks/validation';

type FormValues = {
  formatsPerLocation: FormatsPerLocation;
  selectedLocation: string;
  deletedFormats: Record<string, Format[]>;
};

export type FormatsPerLocation = Record<
  string,
  { name: string; formats: Format[]; destination: number }
>;

type Format = {
  format: string;
  type: 0 | 1;
};

export default function FormatsSection() {
  const translateText = useTranslate();
  const { required } = useCustomValidation();
  const { implementation, isLoading: isLoadingImplementation } = useImplementation();
  const { isLoading: isLoadingDomainAuth } = useDomainAuth();
  const isLoading = isLoadingImplementation || isLoadingDomainAuth;
  const { updateLocationFormats } = useUpdateLocationFormats();
  const [showEdit, setShowEdit] = useState(false);
  const [showFormat, setShowFormat] = useState<string | false>(false);

  if (!isLoading && implementation?.type !== IMPLEMENTATION_TYPE_STANDARD) return null;

  const formatsPerLocation: FormatsPerLocation = formatLocations(
    implementation?.aens ?? {},
    'decode',
  );

  const locationOptions = mapValues(formatsPerLocation, (location, identifier) =>
    location.name ? location.name + ' (' + identifier + ')' : identifier,
  );

  function getFormats() {
    if (isLoading) {
      return (
        <div className="implementation-formats auto-grid tiny">
          {Array(3)
            .fill({})
            .map((item, index) => (
              <div key={index} className="location">
                <Skeleton width={120} />
                <Skeleton width={80} />
              </div>
            ))}
        </div>
      );
    }
    if (isEmpty(formatsPerLocation)) {
      return (
        <div className="no-data">
          {translateText('call-tracking', 'No locations have been added yet.')}
        </div>
      );
    }
    return (
      <div className="implementation-formats auto-grid tiny">
        {Object.entries(formatsPerLocation).map(([identifier, location]) => (
          <div key={identifier} className="location">
            <div className="bold">
              <div className={PH_MASK}>{location.name}</div>
              <span className={location.formats.length === 0 ? 'purple-text' : ''}>
                ({location.formats.length})
              </span>
            </div>
            {location.name !== identifier && <div>{identifier}</div>}
          </div>
        ))}
      </div>
    );
  }

  async function save(values: FormValues) {
    const locationFormats = cloneDeep(values.formatsPerLocation);
    Object.entries(values.deletedFormats).forEach(([locationIdentifier, deletedFormats]) => {
      const location = locationFormats[locationIdentifier];
      locationFormats[locationIdentifier] = {
        ...location,
        formats: location.formats.filter(
          f => !deletedFormats.map(df => df.format).includes(f.format),
        ),
      };
    });
    await updateLocationFormats(formatLocations(locationFormats, 'encode')).then(() => {
      setShowEdit(false);
    });
  }

  const initialValues: FormValues = {
    formatsPerLocation: formatsPerLocation,
    selectedLocation: Object.keys(formatsPerLocation)[0],
    deletedFormats: {},
  };
  return (
    <>
      <ConfigurationCard
        title={translateText('label', 'Step {x}: {label}', {
          x: 2,
          label: translateText('label', 'Specify phone number formats'),
        })}
        disabled={isEmpty(formatsPerLocation)}
        disabledText={translateText(
          'text',
          'You cannot edit the settings, because there is no active location available for this domain.',
        )}
        onEdit={() => setShowEdit(true)}
        isLoading={isLoading}
      >
        {getFormats()}
      </ConfigurationCard>
      <Formik
        initialValues={initialValues}
        onSubmit={async values => await save(values)}
        enableReinitialize
      >
        {({ values, submitForm, resetForm, isSubmitting, dirty, setFieldValue }) => {
          const subValidationSchema = Yup.object({
            format: required
              .min(
                6,
                translateText('message', '{fieldName} has a minimum of {number} characters.', {
                  fieldName: translateText('label', 'Format'),
                  number: 6,
                }),
              )
              .max(
                40,
                translateText('message', '{fieldName} has a limit of {number} characters.', {
                  fieldName: translateText('label', 'Format'),
                  number: 40,
                }),
              )
              .test({
                test: function (value) {
                  const newFormats = (value ?? '')
                    .split(',')
                    .map(v => v.trim())
                    .filter(v => !!v);
                  for (const format of newFormats) {
                    const identifier = findKey(values.formatsPerLocation, l =>
                      l.formats.map(f => f.format).includes(format),
                    );
                    if (identifier) {
                      return this.createError({
                        message: translateText(
                          'message',
                          '{format} is already used in location {location}',
                          {
                            format,
                            location: locationOptions[identifier],
                          },
                        ),
                      });
                    }
                  }
                  return true;
                },
              }),
          });

          function saveFormat({ format }: { format: string }) {
            const currentLocation = cloneDeep(values.formatsPerLocation[values.selectedLocation]);
            const newFormats = format.split(',');
            if (showFormat === 'new') {
              newFormats.forEach(format => {
                currentLocation.formats.push({ format, type: 0 });
              });
            } else {
              const index = currentLocation.formats.findIndex(f => f.format === showFormat);
              currentLocation.formats[index] = {
                ...currentLocation.formats[index],
                format,
              };
            }
            setFieldValue('formatsPerLocation', {
              ...values.formatsPerLocation,
              [values.selectedLocation]: currentLocation,
            });
            setShowFormat(false);
          }

          return (
            <Formik
              initialValues={{ format: showFormat && showFormat !== 'new' ? showFormat : '' }}
              onSubmit={saveFormat}
              validationSchema={subValidationSchema}
              validateOnChange={false}
              enableReinitialize
            >
              {({
                values: subValues,
                errors: subErrors,
                submitForm: subSubmit,
                handleChange: subHandleChange,
                resetForm: subResetForm,
                dirty: subDirty,
              }) => (
                <SetupWithSub
                  show={showEdit}
                  title={
                    translateText('label', 'Step {x}: {label}', {
                      x: 2,
                      label: translateText('label', 'Specify phone number formats'),
                    }) + ' (2/3)'
                  }
                  save={submitForm}
                  isSaving={isSubmitting}
                  close={() => setShowEdit(false)}
                  afterClose={resetForm}
                  hasChanges={dirty}
                  maxWidth={550}
                  sub={
                    <Setup
                      show={!!showFormat}
                      close={() => setShowFormat(false)}
                      afterClose={subResetForm}
                      maxWidth={365}
                      save={subSubmit}
                      hasChanges={subDirty}
                    >
                      <p>
                        {translateText(
                          'call-tracking',
                          'Copy the telephone numbers in the exact format of the website and place them per location in the input fields shown below. Is the phone number shown in different formats? Then add an extra format for each location.',
                        )}
                      </p>
                      {showFormat === 'new' && (
                        <p>
                          {translateText(
                            'text',
                            'You can enter multiple formats by separating them with a comma (,).',
                          )}
                        </p>
                      )}
                      <InputWrapper
                        label={
                          translateText('label', 'Format') +
                          ' ' +
                          locationOptions[values.selectedLocation]
                        }
                        wrapperClassName={PH_MASK}
                      >
                        <TextField
                          name="format"
                          value={subValues.format}
                          onChange={subHandleChange}
                          error={subErrors.format}
                        />
                      </InputWrapper>
                    </Setup>
                  }
                >
                  <p>
                    {translateText(
                      'call-tracking',
                      'Specify the formats of the phone numbers on the website that can be replaced with a dynamic phone number for Call Tracking. Our JavaScript will replace exactly those formats specified with a dynamic phone number for Call Tracking.',
                    )}
                  </p>
                  <p>
                    {translateText(
                      'call-tracking',
                      'Copy the telephone numbers in the exact format of the website and place them per location in the input fields shown below. Is the phone number shown in different formats? Then add an extra format for each location.',
                    )}
                  </p>
                  <SupportParagraph articleId={SUP_ART_STANDARD_IMPLEMENTATION} />
                  <div className="section">
                    <CreateDeleteTable
                      dataKeys={['format']}
                      items={values.formatsPerLocation[values.selectedLocation]?.formats}
                      onAdd={() => setShowFormat('new')}
                      onEdit={format => setShowFormat(format.format)}
                      deletedItems={values.deletedFormats[values.selectedLocation]}
                      setDeletedItems={deletedFormats => {
                        const allDeletedFormats = cloneDeep(values.deletedFormats);
                        allDeletedFormats[values.selectedLocation] = deletedFormats;
                        setFieldValue('deletedFormats', allDeletedFormats);
                      }}
                      extraAddElement={
                        <SearchableSelect
                          options={locationOptions}
                          value={values.selectedLocation}
                          onChange={location => setFieldValue('selectedLocation', location)}
                          className={PH_MASK}
                          showSearchFrom={10}
                          bold
                        />
                      }
                    />
                  </div>
                </SetupWithSub>
              )}
            </Formik>
          );
        }}
      </Formik>
    </>
  );
}

function formatLocations(
  locations: FormatsPerLocation,
  type: 'encode' | 'decode',
): FormatsPerLocation {
  return mapValues(cloneDeep(locations), location => ({
    ...location,
    formats: location.formats.map(format => ({
      ...format,
      format: type === 'encode' ? encodeURIComponent(format.format) : decode(format.format),
    })),
  }));
}

function decode(format: string) {
  // Place the value in an element first so HTML characters like &nbsp are also decoded
  const element = document.createElement('textarea');
  element.innerHTML = format;
  return decodeURIComponent(element.value);
}
