import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useTenant } from 'contexts/Tenant';
import { useHasManagerAccess } from 'hooks/access';
import { useFetch } from 'hooks/fetch';

export function useAssignableLocations() {
  const { fetchData } = useFetch();
  const { selectedDomain } = useTenant();
  const hasManagerAccess = useHasManagerAccess();

  const { data, isFetching } = useQuery({
    queryKey: ['aen', 'assignable'],
    queryFn: (): Promise<{ identifier: string; name: string }[]> =>
      fetchData('/aen-permission/assignable', { queryParams: { domain: selectedDomain } }),
    enabled: selectedDomain !== null && hasManagerAccess,
  });

  return { assignableLocations: data, isLoading: isFetching };
}

export function useAssignAndRevokePermission() {
  const queryClient = useQueryClient();
  const { fetchData } = useFetch();
  const { selectedDomain } = useTenant();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (values: { userId: number; permissions: string[] }) =>
      fetchData('/aen-permission/assign-and-revoke', {
        method: 'POST',
        bodyParams: {
          domain: selectedDomain,
          user: values.userId,
          aen_permissions: values.permissions,
        },
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['domain', 'users', selectedDomain] }),
  });

  return { assignAndRevokePermission: mutateAsync, isLoading: isPending };
}
