import Skeleton from 'components/Skeleton';
import { PH_NO_CAPTURE } from 'globals/constants';
import { useTranslate } from 'hooks/translate';

type Props = {
  config?: { qrUri: string; secret: string } | null;
};

export default function TwoStepAuthQrCode({ config }: Props) {
  const translateText = useTranslate();

  return (
    <div className="two-step-auth-qr-code">
      <div className={`qr-code ${PH_NO_CAPTURE}`}>
        {config ? <img src={config.qrUri} alt="qr-code" /> : <Skeleton />}
      </div>
      <div className="qr-info">
        {config ? (
          <>
            {translateText(
              'text',
              'Scan the QR code with an authenticator app or enter the code below into the app. We recommend using the Google Authenticator app.',
            )}
            <p className="bold">{config.secret.match(/.{1,4}/g)?.join(' ')}</p>
          </>
        ) : (
          <>
            <Skeleton count={2} hasAnimation={false} />
            <p className="bold">
              <Skeleton hasAnimation={false} />
            </p>
          </>
        )}
      </div>
      <p
        dangerouslySetInnerHTML={{
          __html: translateText(
            'text',
            'You can use different authenticators. We recommend the one from Google. Download the Google Authenticator app in the <a href="{playStoreLink}" target="_blank">Play store</a> or in the <a href="{appStoreLink}" target="_blank">App store</a>.',
            {
              playStoreLink:
                'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US&gl=US',
              appStoreLink: 'https://apps.apple.com/us/app/google-authenticator/id388497605',
            },
          ),
        }}
      />
    </div>
  );
}
