import { FormikProps, useFormikContext } from 'formik';
import { cloneDeep, isEqual } from 'lodash';

import AreaCodeSelector from 'components/configuration/AreaCodeSelector';
import TextField from 'components/input/TextField';
import InputWrapper from 'components/slide-in/InputWrapper';
import { changeCountryAndArea } from 'globals/helpers';
import { Offsite } from 'globals/types';
import { useTranslate } from 'hooks/translate';

type Props = {
  isEdit: boolean;
  intake?: boolean;
  canChangeCountry?: boolean;
};

export default function OffsiteForm({ isEdit, intake, canChangeCountry }: Props) {
  const translateText = useTranslate();
  const { values, errors, setFieldValue, handleChange } = useFormikContext<{
    name: string;
    country: number;
    area: number | null;
    destination: string;
  }>();

  const countryTooltip = translateText(
    'tooltip/call-tracking',
    'Select the desired country code that may be used for the offsite phone number.',
  );

  const areaTooltip = translateText(
    'tooltip/call-tracking',
    'Select the area code that may be used for the offsite phone number.',
  );

  return (
    <>
      <InputWrapper
        label={translateText('label', 'Name')}
        tooltip={translateText(
          'tooltip/call-tracking',
          'Enter the name under which the calls may be registered in our dashboard.',
        )}
      >
        <TextField
          name="name"
          placeholder={translateText('label', 'Name')}
          value={values.name}
          onChange={handleChange}
          wrapperClassName="full"
          error={errors.name}
          maxLength={50}
        />
      </InputWrapper>
      <div className="double-input">
        <AreaCodeSelector
          selectedCountry={values.country}
          selectedArea={values.area}
          setAreaCode={(c, a) => changeCountryAndArea(c, a, values, setFieldValue)}
          areaError={errors.area}
          countryError={errors.country}
          addTitles
          countryTooltip={countryTooltip}
          areaTooltip={areaTooltip}
          onlyShow={isEdit && !intake}
          selectClassName="full"
          canChangeCountry={canChangeCountry}
        />
      </div>
      <InputWrapper
        label={translateText('label', 'Destination')}
        tooltip={translateText(
          'tooltip/call-tracking',
          'This is the phone number to which the calls for offsite Call Tracking will be forwarded.',
        )}
      >
        <TextField
          name="destination"
          placeholder={translateText('label', 'Destination')}
          value={values.destination}
          onChange={handleChange}
          wrapperClassName="full"
          error={errors.destination}
        />
      </InputWrapper>
    </>
  );
}

// Offsite form helpers
type OffsiteFormValues = {
  name: string;
  country: number;
  area: number | null;
  destination: string;
};

export function getOffsiteInitialValues(offsite: 'new' | Offsite | false): OffsiteFormValues {
  return {
    name: typeof offsite === 'object' ? offsite.name : '',
    country: typeof offsite === 'object' ? offsite.country : 31,
    area: typeof offsite === 'object' ? offsite.area : null,
    destination: typeof offsite === 'object' ? offsite.destination : '31',
  };
}

export function saveOffsite(
  offsite: 'new' | Offsite | false,
  offsiteValues: OffsiteFormValues,
  parentValues: {
    offsites: Offsite[];
    addedOffsites: Offsite[];
    deletedOffsites: Offsite[];
    changedOffsites: Record<number, Offsite>;
  },
  setParentValue: FormikProps<unknown>['setFieldValue'],
  close: () => void,
) {
  if (offsite === false) return; // This should never happen, but is needed to tell typescript that offsite is not false
  if (offsite === 'new') {
    // The offsite is new so only needs to be added to the array of new offsites
    setParentValue('addedOffsites', [...parentValues.addedOffsites, offsiteValues]);
  } else {
    let index = parentValues.addedOffsites.findIndex(o => isEqual(o, offsite));
    if (index !== -1) {
      // The offsite is new but being edited, so needs to be replaced in the array of new offsites
      const addedOffsites = cloneDeep(parentValues.addedOffsites);
      addedOffsites[index] = { ...offsiteValues, area: offsiteValues.area! };
      setParentValue('addedOffsites', addedOffsites);
    } else {
      // The offsite already exists, so needs to be added to the changed offsites object and
      // needs to be replaced in the offsite array
      index = parentValues.offsites.findIndex(o => isEqual(o, offsite));
      const changedOffsites = cloneDeep(parentValues.changedOffsites);
      changedOffsites[offsite.numberpool_id!] = {
        ...offsite,
        ...offsiteValues,
        area: offsiteValues.area!,
      };
      setParentValue('changedOffsites', changedOffsites);
      const offsites = cloneDeep(parentValues.offsites);
      offsites[index] = { ...offsite, ...offsiteValues, area: offsiteValues.area! };
      setParentValue('offsites', offsites);
    }
  }
  close();
}
