import { useNavigate } from 'react-router';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useMessages } from 'contexts/Messages';
import { KNOWLEDGE_CENTER_TYPE_OVERVIEW, KNOWLEDGE_CENTER_TYPE_SUPPORT } from 'globals/constants';
import {
  Article,
  Attachment,
  Category,
  CategoryArticle,
  CategoryFaq,
  CategoryType,
  KnowledgeCenterSearch,
  Slug,
} from 'globals/types';
import { useSetQueryData } from 'hooks/data';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';

const ARTICLE_EXPAND = 'author,created_on,category_type,summary,image' as const;

export function useTicketSubmit() {
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync } = useMutation({
    mutationFn: (formData: FormData) =>
      fetchData('/support/ticket/submit', { method: 'POST', bodyParams: formData }),
    onSuccess: () =>
      addSuccessMessage(
        translateText('message', 'Your ticket has been sent to support@adcalls.nl'),
      ),
  });

  return { sendTicket: mutateAsync };
}

export function useSearch(search = '') {
  const { fetchData } = useFetch();

  const { data: searchResults, isFetching: isLoading } = useQuery({
    queryKey: ['knowledgeCenter', 'search', search],
    queryFn: (): Promise<Record<string, KnowledgeCenterSearch>> =>
      fetchData('/support/article/search', {
        queryParams: { term: search },
      }),
    enabled: search !== '',
  });

  return { searchResults, isLoading };
}

export function useArticles(params: Record<string, unknown> = {}) {
  const { fetchData } = useFetch();

  const { data: articles, isFetching: isLoading } = useQuery({
    queryKey: ['knowledgeCenter', 'articles', params],
    queryFn: (): Promise<Article[]> =>
      fetchData('/support/article', {
        queryParams: { expand: ARTICLE_EXPAND, ...params },
      }),
  });

  return { articles, isLoading };
}

export function useCategories() {
  const { fetchData } = useFetch();

  const { data: categories, isFetching: isLoading } = useQuery({
    queryKey: ['knowledgeCenter', 'categories'],
    queryFn: (): Promise<Category[]> =>
      fetchData('/support/category', { queryParams: { sort: 'order' } }),
  });

  return { categories, isLoading };
}

export function useArticle(article: string | null | undefined) {
  const queryClient = useQueryClient();
  const { fetchData } = useFetch();

  // Get all lists of articles that have the default expand (to ensure that all needed data is there) and flatten them into one array
  const articles = queryClient
    .getQueriesData<Article[]>({
      predicate: query =>
        query.queryKey[0] === 'articles' && !Object(query.queryKey[1]).hasOwnProperty('expand'),
    })
    .flatMap(query => query[1]);

  const { data, isFetching, isError } = useQuery({
    queryKey: ['knowledgeCenter', 'article', article],
    queryFn: (): Promise<Article> =>
      fetchData('/support/article/view', { queryParams: { id: article, expand: ARTICLE_EXPAND } }),
    enabled: !!article,
    meta: { noError: true },
    initialData: () =>
      // Match the article on the id or one of the slugs
      articles?.find(
        a =>
          !!article &&
          (a?.id === Number(article) ||
            !!a?.slugs.find(s => s.slug === decodeURIComponent(article))),
      ),
  });

  return { article: data, isLoading: isFetching, isError };
}

export function useCreateArticle() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync } = useMutation({
    mutationFn: (article: {
      title: string;
      image: string;
      language: string;
      status: string;
      content: string;
      summary: string;
    }) =>
      fetchData('/support/article/create', {
        method: 'POST',
        queryParams: { expand: ARTICLE_EXPAND },
        bodyParams: {
          title: article.title.trim(),
          content: article.content,
          summary: article.summary,
          status: article.status,
          image: article.image,
        },
      }),
    onSuccess: data => {
      queryClient.setQueryData(['knowledgeCenter', 'article', data.main_slug], () => data);
      queryClient.invalidateQueries({ queryKey: ['knowledgeCenter', 'articleOverview'] });
      addSuccessMessage(translateText('message', 'The article has been added.'));
      navigate('/knowledge-center/article/' + encodeURIComponent(data.main_slug) + '/edit');
    },
  });

  return { createArticle: mutateAsync };
}

export function useUpdateArticle() {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync } = useMutation({
    mutationFn: (article: {
      id: number;
      title: string;
      image: string;
      language: string;
      status: string;
      content: string;
      summary: string;
    }) =>
      fetchData('/support/article/update', {
        method: 'PUT',
        queryParams: { id: article.id, expand: ARTICLE_EXPAND },
        bodyParams: {
          title: article.title.trim(),
          language_id: article.language,
          content: article.content,
          summary: article.summary,
          status: article.status,
          image: article.image,
        },
      }),
    onSuccess: data => {
      queryClient.setQueryData(['knowledgeCenter', 'article', data.main_slug], () => data);
      queryClient.invalidateQueries({ queryKey: ['knowledgeCenter', 'articleOverview'] });
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { updateArticle: mutateAsync };
}

export function useCreateCategory() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync } = useMutation({
    mutationFn: (category: {
      name: string;
      icon: string;
      language: string;
      description: string;
      type: CategoryType;
    }) =>
      fetchData('/support/category/create', {
        method: 'POST',
        bodyParams: {
          name: category.name.trim(),
          description: category.description,
          icon: category.icon,
          language_id: category.language,
          type: category.type,
        },
      }),
    onSuccess: (data, category) => {
      setQueryData<Category[]>(['knowledgeCenter', 'categories'], oldData => [...oldData, data]);
      queryClient.invalidateQueries({ queryKey: ['knowledgeCenter', 'articleOverview'] });
      addSuccessMessage(translateText('message', 'The category has been added.'));
      navigate(
        '/knowledge-center/' +
          category.type +
          '/category/' +
          encodeURIComponent(data.main_slug) +
          '/edit',
      );
    },
  });

  return { createCategory: mutateAsync };
}

export function useUpdateCategory() {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const setQueryData = useSetQueryData();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync } = useMutation({
    mutationFn: (category: {
      id: number;
      name: string;
      icon: string;
      language: string;
      description: string;
      type: CategoryType;
    }) =>
      fetchData('/support/category/update', {
        method: 'PUT',
        queryParams: { id: category.id },
        bodyParams: {
          name: category.name.trim(),
          description: category.description,
          icon: category.icon,
          language_id: category.language,
          type: category.type,
        },
      }),
    onSuccess: (data, category) => {
      setQueryData<Category[]>(['knowledgeCenter', 'categories'], oldData =>
        oldData.map(c => (c.id === category.id ? data : c)),
      );
      queryClient.invalidateQueries({ queryKey: ['knowledgeCenter', 'articleOverview'] });
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { updateCategory: mutateAsync };
}

export function useUpdateSlider() {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync: updateSlider, isPending } = useMutation({
    mutationFn: (updateSlider: string[]) =>
      fetchData('/support/article/update-slider', {
        method: 'PUT',
        bodyParams: {
          article_ids: updateSlider,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          'knowledgeCenter',
          'articles',
          {
            popular_type: KNOWLEDGE_CENTER_TYPE_OVERVIEW,
            expand: 'slider,popular',
          },
        ],
      });
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { updateSlider, isLoading: isPending };
}

export function useUpdatePopular(type: CategoryType) {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync: updatePopular, isPending } = useMutation({
    mutationFn: (updatePopular: string[]) =>
      fetchData('/support/article/update-popular', {
        method: 'PUT',
        bodyParams: {
          article_ids: updatePopular,
          type,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          'knowledgeCenter',
          'articles',
          {
            popular_type: KNOWLEDGE_CENTER_TYPE_OVERVIEW,
            expand: 'slider,popular',
          },
        ],
      });
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { updatePopular, isLoading: isPending };
}

export function useUpdateCategoryOrder() {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync: updateOrder, isPending } = useMutation({
    mutationFn: (category: Category[]) =>
      fetchData('/support/category/multi-update', {
        method: 'PUT',
        bodyParams: {
          models: category.map((category, index) => ({ pk: category.id, order: index })),
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['knowledgeCenter', 'categories'] });
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { updateOrder, isLoading: isPending };
}

export function useUpdateCategoryPopular(type: CategoryType) {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync: updateCategoryPopular, isPending } = useMutation({
    mutationFn: (updatePopular: string[]) =>
      fetchData('/support/article/update-popular', {
        method: 'PUT',
        bodyParams: {
          article_ids: updatePopular,
          type,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          'knowledgeCenter',
          'articles',
          {
            popular_type: type,
            category_type: type,
            expand: 'popular',
          },
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [
          'knowledgeCenter',
          'articles',
          {
            category_type: type,
            popular_type: type,
            expand: 'popular,image,created_on,author',
            sort: '-updated_at',
          },
        ],
      });
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { updateCategoryPopular, isLoading: isPending };
}

export function useSlugs() {
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync: addSlug } = useMutation({
    mutationFn: (slug: Slug & { type: 'article' | 'category'; targetId: number; target: string }) =>
      fetchData('/support/slug/create', {
        method: 'PUT',
        bodyParams: {
          slug: slug.slug,
          main: slug.main,
          target_id: slug.targetId,
          type: slug.type,
          section: KNOWLEDGE_CENTER_TYPE_SUPPORT,
        },
      }),
    onSuccess: (data, variables) => {
      const updateSlugs = <T extends Article | Category>(data: T) => {
        let slugs = data.slugs;
        if (variables.main) {
          slugs = data.slugs.map((s: Slug) => ({ ...s, main: 0 }));
        }
        slugs.push({ slug: variables.slug, main: variables.main });
        return { ...data, slugs };
      };

      if (variables.type === 'article') {
        setQueryData<Article>(['knowledgeCenter', 'article', variables.target], updateSlugs);
      } else {
        setQueryData<Category[]>(['knowledgeCenter', 'categories'], oldData =>
          oldData.map(c => (c.id === variables.targetId ? updateSlugs(c) : c)),
        );
      }

      addSuccessMessage(translateText('message', 'The slug has been added.'));
    },
  });

  const { mutateAsync: markAsMain, isPending: isMarkingAsMain } = useMutation({
    mutationFn: (slug: Slug & { type: 'article' | 'category'; targetId: number; target: string }) =>
      fetchData('/support/slug/set-main', {
        method: 'PUT',
        bodyParams: {
          slug: slug.slug,
          target_id: slug.targetId,
          type: slug.type,
          section: KNOWLEDGE_CENTER_TYPE_SUPPORT,
        },
      }),
    onSuccess: (data, variables) => {
      const updateSlugs = <T extends Article | Category>(data: T) => {
        const slugs = data.slugs.map((s: Slug) => ({
          ...s,
          main: s.slug === variables.slug ? 1 : 0,
        }));
        return { ...data, slugs };
      };

      if (variables.type === 'article') {
        setQueryData<Article>(['knowledgeCenter', 'article', variables.target], updateSlugs);
      } else {
        setQueryData<Category[]>(['knowledgeCenter', 'categories'], oldData =>
          oldData.map(c => (c.id === variables.targetId ? updateSlugs(c) : c)),
        );
      }

      addSuccessMessage(
        translateText('message', 'The slug {slug} is marked as main slug.', {
          slug: variables.slug,
        }),
      );
    },
  });

  return { addSlug, markAsMain, isMarkingAsMain };
}

export function useUpdateAttachments() {
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync } = useMutation({
    mutationFn: ({
      attachments,
      articleId,
    }: {
      attachments: Attachment[];
      articleId: number;
      article: string;
    }) =>
      fetchData('/support/article/update-attachments', {
        method: 'PUT',
        bodyParams: {
          article_id: articleId,
          attachments,
        },
      }),
    onSuccess: (data, variables) => {
      setQueryData<Article>(['knowledgeCenter', 'article', variables.article], oldData => ({
        ...oldData,
        attachments: data,
      }));
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { updateAttachments: mutateAsync };
}

export function useUpdateCategories() {
  const queryClient = useQueryClient();
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync } = useMutation({
    mutationFn: ({
      categories,
      articleId,
    }: {
      categories: string[];
      articleId: number;
      article: string;
    }) =>
      fetchData('/support/article/update-categories', {
        method: 'POST',
        bodyParams: {
          id: articleId,
          category_ids: categories,
        },
      }),
    onSuccess: (data, variables) => {
      setQueryData<Article>(['knowledgeCenter', 'article', variables.article], oldData => ({
        ...oldData,
        categories: variables.categories.map(c => Number(c)),
      }));
      queryClient.invalidateQueries({ queryKey: ['knowledgeCenter', 'articleOverview'] });
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { updateCategories: mutateAsync };
}

export function useUpdateRelatedArticles() {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync } = useMutation({
    mutationFn: ({
      related,
      articleId,
    }: {
      related: string[];
      articleId: number;
      article: string;
    }) =>
      fetchData('/support/article/update-related-articles', {
        method: 'POST',
        bodyParams: {
          id: articleId,
          related_articles: related.map((a, i) => ({
            article_id: articleId,
            related_article_id: Number(a),
            order: i,
          })),
        },
      }),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ['knowledgeCenter', 'article', variables.article],
      });
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { updateRelatedArticles: mutateAsync };
}

export function useUpdateArticleOrder() {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync } = useMutation({
    mutationFn: (articles: CategoryArticle[]) =>
      fetchData('/support/category/update-article-order', {
        method: 'POST',
        bodyParams: {
          category_articles: articles.map((a, i) => ({
            category_id: a.category_id,
            article_id: a.article_id,
            order: i,
          })),
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['knowledgeCenter', 'categories'] });
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { updateArticleOrder: mutateAsync };
}

export function useUpdateCategoryFaqs() {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync } = useMutation({
    mutationFn: ({ categoryId, faqs }: { categoryId: number; faqs: CategoryFaq[] }) =>
      fetchData('/support/category-faq/create-in-order', {
        method: 'POST',
        bodyParams: {
          faqs: faqs,
          category_id: categoryId,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['knowledgeCenter', 'categories'] });
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { updateFaq: mutateAsync };
}
