import moment from 'moment';

import DetailsSection from 'components/DetailsSection';
import { useAnalyticsCodes } from 'hooks/content';
import { useCall } from 'hooks/queries/call';
import { useTranslate } from 'hooks/translate';
import { useUserDateFormat } from 'hooks/user';

type Props = {
  callId: number;
  isLoading: boolean;
};

export default function IntegrationStatusSection({ callId, isLoading }: Props) {
  const translateText = useTranslate();
  const analyticsCodes = useAnalyticsCodes();
  const dateFormat = useUserDateFormat();
  const { call } = useCall(!isNaN(callId) ? callId : null);

  let ga4First = null,
    ga4Second = null;
  const labels = call?.is_sent_to_ga4_labels;
  // If the status is not 2 or 12 the call is not sent, so no second push either
  if (call && ![2, 12].includes(call?.is_sent_to_ga4 ?? 0)) {
    const codes = analyticsCodes;
    ga4First =
      codes[call?.is_sent_to_ga4 as keyof typeof codes] ?? translateText('label', 'Not sent');
  } else if (typeof labels !== undefined) {
    if (labels?.first !== null) ga4First = labels?.first;
    if (labels?.second !== null) {
      ga4Second = moment(labels?.second, 'YYYY-MM-DD').format(dateFormat);
      if (ga4Second === 'Invalid date') ga4Second = labels?.second;
    }
  }

  return (
    <DetailsSection
      data={{
        [translateText('label', 'Status Google Analytics 4')]:
          !ga4Second || isLoading
            ? ga4First
            : {
                value: {
                  [translateText('label', '1<sup>st</sup> event')]: ga4First,
                  [translateText('label', 'Last event')]: ga4Second ?? '-',
                },
              },
        [translateText('label', 'Status Microsoft Advertising')]: call?.is_sent_to_bing,
        [translateText('label', 'Status Google Ads')]: call?.is_sent_to_google,
        [translateText('label', 'Status Google Ads Enhanced Conversions')]:
          call?.status_google_ads_ec,
        [translateText('label', 'Status Double Click CM')]: call?.is_sent_to_doubleclick,
        [translateText('label', 'Status LEF')]: call?.is_sent_to_lef,
      }}
      isLoading={isLoading}
      title={translateText('label', 'Status integrations')}
      tooltip={translateText(
        'tooltip/call-tracking',
        'Here you will find the status of the call per integration.',
      )}
    />
  );
}
