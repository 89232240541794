import VerifyProceedPrompt from 'components/VerifyProceedPrompt';
import { useGuidedTour } from 'contexts/GuidedTour';
import { useQuitTour } from 'hooks/queries/user';
import { useTranslate } from 'hooks/translate';

export default function QuitTour() {
  const translateText = useTranslate();
  const { setShowCancel } = useGuidedTour();
  const { quitTour, isLoading } = useQuitTour();

  return (
    <VerifyProceedPrompt
      show
      size="standard"
      title={translateText('guided-tour', 'Skip tour')}
      intro={translateText(
        'guided-tour',
        "Don't have time for the tour? Or do you already know how to find your way in our dashboard? Then you can skip the tour. If you want to start the tour again later, click on your avatar and then on the 'Start tour' button.",
      )}
      proceed={{
        text: translateText('guided-tour', 'Yes, skip the tour'),
        onClick: () => quitTour(),
      }}
      cancel={{
        text: translateText('guided-tour', 'No, continue with the tour'),
        onClick: () => setShowCancel(false),
      }}
      verifyText={translateText('guided-tour', "Yes, I'll stop the tour")}
      popupClass="quit-tour"
      isSaving={isLoading}
    />
  );
}
