import { useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import icon from 'assets/images/icons/integrations/google-analytics-4.svg';
import CreateDeleteTable from 'components/CreateDeleteTable';
import ErrorFocus from 'components/ErrorFocus';
import CostParagraph from 'components/integrations/CostParagraph';
import Filter from 'components/integrations/google-analytics-4/Filter';
import Stream from 'components/integrations/google-analytics-4/Stream';
import Skeleton from 'components/Skeleton';
import Setup from 'components/slide-in/Setup';
import SetupWithSub from 'components/slide-in/SetupWithSub';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import SupportParagraph from 'components/SupportParagraph';
import { useMessages } from 'contexts/Messages';
import { INTEGRATION_GOOGLE_ANALYTICS_4 } from 'globals/constants';
import { useHasAccessToIntegration } from 'hooks/access';
import { useLocations } from 'hooks/queries/aen';
import {
  Filter as FilterValues,
  Stream as StreamValues,
  useCreateFilter,
  useCreateStream,
  useDeleteStream,
  useStreams,
  useUpdateFilter,
  useUpdateStream,
} from 'hooks/queries/app';
import { useKeystrokeMenu } from 'hooks/queries/domain';
import { useEvaluationOptions } from 'hooks/queries/evaluation';
import { useTranslate } from 'hooks/translate';

export default function GoogleAnalytics4({ show, close }: BaseSlideInProps) {
  const translateText = useTranslate();
  const hasAccessToIntegration = useHasAccessToIntegration();
  const { addErrorMessage } = useMessages();

  const { streams, isLoading: isLoadingStreams } = useStreams(show);
  const { isLoading: isLoadingLocations } = useLocations({ active: 1 }, show);
  const { isLoading: isLoadingKeystroke } = useKeystrokeMenu(show);
  const { isLoading: isLoadingEvaluations } = useEvaluationOptions({ enabled: show });
  const isLoading =
    isLoadingStreams || isLoadingLocations || isLoadingKeystroke || isLoadingEvaluations;

  const { createStream, isSaving } = useCreateStream();
  const { updateStream, isUpdating } = useUpdateStream();
  const { deleteStream, isDeleting } = useDeleteStream();
  const { createFilter } = useCreateFilter();
  const { updateFilter } = useUpdateFilter();

  const [showStream, setShowStream] = useState<number | 'new' | false>(false);
  const [showFilter, setShowFilter] = useState<number | 'new' | false>(false);

  if (!hasAccessToIntegration(INTEGRATION_GOOGLE_ANALYTICS_4)) return null;

  const streamValidationSchema = Yup.object({
    measurement_id: Yup.string()
      .required(translateText('message', 'The measurement ID must be filled in.'))
      .matches(
        /^G-[a-zA-Z0-9]{1,}$/,
        translateText('message', 'The measurement ID should start with `G-`.'),
      ),
    api_secret: Yup.string().required(
      translateText('message', 'The API secret must be filled in.'),
    ),
  });

  const filterValidationSchema = Yup.object({
    event_name: Yup.string()
      .required(translateText('message', 'The name must be filled in.'))
      .matches(/^\S+$/, translateText('message', 'The name cannot contain spaces.'))
      .matches(
        /^(?!call$).*$/,
        translateText(
          'message',
          'The name "call" should not be used because it is already used for the standard event.',
        ),
      ),
  });

  async function saveStream(
    values: Omit<StreamValues, 'id' | 'filters'>,
    formikHelpers: FormikHelpers<Omit<StreamValues, 'id' | 'filters'>>,
  ) {
    const promise =
      typeof showStream === 'number'
        ? updateStream({ id: showStream, ...values })
        : createStream(values);

    await promise
      .then(() => setShowStream(false))
      .catch(error => {
        if (error.measurement_id) {
          formikHelpers.setFieldError('measurement_id', error.measurement_id[0]);
        } else {
          addErrorMessage(error);
        }
      });
  }

  async function saveFilter(values: Omit<FilterValues, 'id' | 'ga4_stream_id'>) {
    const promise =
      typeof showFilter === 'number'
        ? updateFilter({ streamId: showStream as number, filter: { id: showFilter, ...values } })
        : createFilter({ streamId: showStream as number, filter: values });

    await promise.then(() => setShowFilter(false));
  }

  const selectedStream =
    typeof showStream === 'number' ? streams?.find(stream => stream.id === showStream) : null;
  const selectedFilter =
    selectedStream && typeof showFilter === 'number'
      ? selectedStream.filters.find(filter => filter.id === showFilter)
      : null;

  return (
    <Formik
      initialValues={{
        enabled: selectedStream?.enabled ?? true,
        measurement_id: selectedStream?.measurement_id ?? '',
        api_secret: selectedStream?.api_secret ?? '',
        filters_enabled: selectedStream?.filters_enabled ?? false,
        send_recurring_calls: selectedStream?.send_recurring_calls ?? false,
        send_offsite_calls: selectedStream?.send_offsite_calls ?? false,
        send_fallback_calls: selectedStream?.send_fallback_calls ?? false,
        second_event_enabled: selectedStream?.second_event_enabled ?? false,
        send_default_event: selectedStream?.send_default_event ?? true,
        ecommerce_enabled: selectedStream?.ecommerce_enabled ?? false,
        ecommerce_currency: selectedStream?.ecommerce_currency ?? 'EUR',
      }}
      onSubmit={saveStream}
      validationSchema={streamValidationSchema}
      enableReinitialize
    >
      {streamFormProps => (
        <>
          <ErrorFocus element={document.getElementById('google-analytics-stream')} />
          <Formik
            initialValues={{
              enabled: selectedFilter?.enabled ?? true,
              event_name: selectedFilter?.event_name ?? '',
              conditions: selectedFilter?.conditions ?? [],
            }}
            onSubmit={saveFilter}
            validationSchema={filterValidationSchema}
            enableReinitialize
          >
            {filterFormProps => (
              <SetupWithSub
                show={show}
                close={close}
                icon={icon}
                title={translateText('label', 'Google Analytics 4')}
                maxWidth={570}
                className="google-analytics-4"
                sub={
                  <SetupWithSub
                    id="google-analytics-stream"
                    show={showStream !== false}
                    close={() => setShowStream(false)}
                    hasChanges={streamFormProps.dirty}
                    afterClose={streamFormProps.resetForm}
                    title={
                      showStream === 'new'
                        ? translateText('label', 'Add stream')
                        : translateText('label', 'Edit stream')
                    }
                    maxWidth={520}
                    save={streamFormProps.submitForm}
                    isSaving={streamFormProps.isSubmitting}
                    className="google-analytics-4"
                    sub={
                      <Setup
                        id="google-analytics-filter"
                        show={showFilter !== false}
                        close={() => setShowFilter(false)}
                        title={
                          showFilter === 'new'
                            ? translateText('label', 'Add filter')
                            : translateText('label', 'Edit filter')
                        }
                        maxWidth={470}
                        hasChanges={filterFormProps.dirty}
                        afterClose={filterFormProps.resetForm}
                        save={filterFormProps.submitForm}
                        isSaving={filterFormProps.isSubmitting}
                        className="google-analytics-4"
                      >
                        <ErrorFocus element={document.getElementById('google-analytics-filter')} />
                        <Filter formProps={filterFormProps} />
                      </Setup>
                    }
                  >
                    <Stream
                      stream={showStream}
                      setShowFilter={setShowFilter}
                      formProps={streamFormProps}
                      filters={selectedStream?.filters ?? []}
                    />
                  </SetupWithSub>
                }
              >
                <p>
                  {isLoading ? (
                    <Skeleton count={2} />
                  ) : (
                    translateText(
                      'integration',
                      'Process your calls in Google Analytics, so you get a complete overview of both your web and phone conversions.',
                    )
                  )}
                </p>
                <CostParagraph price={null} isLoading={isLoading} />
                <SupportParagraph
                  customLink="/knowledge-center/support/category/48"
                  isLoading={isLoading}
                />
                {isLoading ? (
                  <Skeleton height={35} />
                ) : (
                  <CreateDeleteTable
                    className="streams-table"
                    title={translateText('label', 'Streams')}
                    dataKeys={['status', 'measurement_id']}
                    items={streams?.map(stream => ({
                      ...stream,
                      status: (
                        <div className={'big-dot ' + (stream.enabled ? 'active' : 'inactive')} />
                      ),
                    }))}
                    onAdd={() => setShowStream('new')}
                    onEdit={row => setShowStream(row.id)}
                    onDelete={row => deleteStream(row.id)}
                    addButtonDisabled={isLoading || isSaving || isUpdating || isDeleting}
                    buttonsDisabled={isLoading || isSaving || isUpdating || isDeleting}
                    emptyText={translateText('label', 'No streams have been set yet.')}
                  />
                )}
              </SetupWithSub>
            )}
          </Formik>
        </>
      )}
    </Formik>
  );
}
