import { useState } from 'react';

import TextField from 'components/input/TextField';
import Table, { ColumnSetting } from 'components/table/Table';
import { useVoipProviderNumbers } from 'hooks/queries/number';
import { useTranslate } from 'hooks/translate';

type Props = {
  providerName: string;
  providerKey: 'voxbone' | 'steam-connect' | 'dstny';
  disabled?: boolean;
  dataKey?: 'dids' | 'numbers';
  columns?: Record<string, ColumnSetting>;
  saveParam?: 'numbers' | 'orderReference';
};

export default function AddVoipProviderNumbers({
  providerName,
  providerKey,
  disabled = false,
  dataKey = 'numbers',
  saveParam = 'orderReference',
  columns,
}: Props) {
  const translateText = useTranslate();
  const [reference, setReference] = useState('');

  const { data, getNumbers, isLoading, saveNumbers, isSaving } = useVoipProviderNumbers(
    providerKey,
    reference,
  );

  let providerData: Record<string, unknown>[] = [];
  if (data) {
    if (dataKey === 'numbers' && 'numbers' in data) {
      providerData = data.numbers;
    } else if (dataKey === 'dids' && 'dids' in data) {
      providerData = data.dids;
    }
  }

  return (
    <>
      <div className="form-row">
        <div className="name-label">{providerName}</div>
        <div className="value-wrapper">
          <TextField
            value={reference}
            onChange={e => setReference(e.target.value)}
            placeholder={translateText('label', 'Order reference')}
            disabled={disabled}
          />
        </div>
        <div className="margin-left">
          <button
            className="btn btn-lightblue"
            onClick={() => getNumbers()}
            disabled={disabled || !reference || isLoading}
          >
            {translateText('label', 'Get numbers')}
          </button>
        </div>
      </div>
      {data && (
        <div className="margin-top">
          <Table
            columns={
              columns ?? {
                id: { header: translateText('label', 'ID'), noNumberFormat: true },
                number: { header: translateText('label', 'Number'), noNumberFormat: true },
              }
            }
            data={providerData}
            noWhiteBlock
          />
          <div className="form-button">
            <div>
              {translateText('admin/text', 'Amount: {amount} numbers', {
                amount: data.resultCount,
              })}
              {data.resultCount > 5000 && (
                <span className="purple-text">
                  {translateText(
                    'admin/text',
                    'Not all numbers have been fetched due to the limit of 5000',
                  )}
                </span>
              )}
            </div>
            <button
              disabled={data.resultCount === 0 || isSaving}
              className="btn btn-green"
              onClick={() =>
                saveNumbers(
                  saveParam === 'numbers'
                    ? { [providerKey + 'Numbers']: providerData }
                    : { orderReference: reference },
                ).then(() => setReference(''))
              }
            >
              {translateText('label', 'Save')}
            </button>
          </div>
        </div>
      )}
    </>
  );
}
