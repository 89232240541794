import { useState } from 'react';

import PopupModal from 'components/PopupModal';
import { useTranslate } from 'hooks/translate';
import Checkbox from './input/Checkbox';

type Props = {
  show: boolean;
  isSaving: boolean;
  title: string;
  intro: string | JSX.Element;
  verifyText: string;
  proceed: {
    onClick: () => void;
    text?: string;
    color?: 'green';
  };
  cancel: {
    onClick: () => void;
    text?: string;
    color?: 'lightblue';
  };
  popupClass?: string;
  size?: 'small' | 'medium' | 'big' | 'standard';
};

export default function VerifyProceedPrompt({
  show,
  isSaving,
  title,
  intro,
  verifyText,
  proceed,
  cancel,
  popupClass,
  size = 'small',
}: Props) {
  const translateText = useTranslate();
  const [verified, setVerified] = useState(false);
  return (
    <PopupModal show={show} size={size} extraClass={popupClass}>
      <h1>{title}</h1>
      {typeof intro === 'string' ? (
        <p className="intro" dangerouslySetInnerHTML={{ __html: intro }} />
      ) : (
        intro
      )}
      <Checkbox
        id="verified"
        label={verifyText}
        checked={verified}
        onChange={e => setVerified(e.target.checked)}
      />
      <div className="buttons-wrapper">
        <button
          disabled={!verified || isSaving}
          className={`btn btn-${proceed.color ?? 'purple'}`}
          onClick={proceed.onClick}
        >
          {proceed.text ?? translateText('label', 'Proceed')}
        </button>
        <button
          disabled={isSaving}
          className={`btn btn-${cancel.color ?? 'green'}`}
          onClick={() => {
            setVerified(false);
            cancel.onClick();
          }}
        >
          {cancel.text ?? translateText('label', 'Cancel')}
        </button>
      </div>
    </PopupModal>
  );
}
