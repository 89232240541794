import { ReactElement, useState } from 'react';
import { cloneDeep } from 'lodash';
import {
  Cell,
  Legend as RechartsLegend,
  Pie as RechartsPie,
  PieChart,
  ResponsiveContainer,
  Sector,
  SectorProps,
  Tooltip as RechartsTooltip,
} from 'recharts';

import { useTranslate } from 'hooks/translate';
import Legend, { EmptyLegend } from './Legend';
import Tooltip from './Tooltip';
import { DataProps, PieProps } from './types';

function ActiveShape(props: SectorProps) {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;
  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius ? outerRadius + 5 : 0}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
}

export default function Pie(props: PieProps & DataProps) {
  const { id, data, palette, emptyLabel, previousData, maxPieces, legendTooltips } = props;
  const translateText = useTranslate();
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const othersLabel = translateText('label', 'Others');
  let totalOther = 0;
  let totalPrevOther = 0;
  const noData = data.every(i => (i.value ?? 0) === 0);

  if (legendTooltips) {
    for (const [key, tooltip] of Object.entries(legendTooltips)) {
      const item = data.find(entry => entry.dataKey === key);
      if (item) item.label = tooltip;
    }
  }

  const renderData = cloneDeep(data);
  if (maxPieces !== undefined && renderData.length > maxPieces) {
    const otherData = renderData.splice(maxPieces - 1);
    otherData.forEach(i => (totalOther += Number(i.value)));
    renderData.push({
      argument: othersLabel,
      value: totalOther,
    });
  }

  let prevData = null;
  if (previousData && !noData) {
    const rawPrevData = cloneDeep(previousData);
    const renderPrevData = [];

    renderData.forEach(i => {
      const index = rawPrevData.findIndex(row => row.argument === i.argument);
      if (i.argument !== othersLabel) {
        if (index !== -1) {
          rawPrevData[index].noLegend = true;
          renderPrevData.push(rawPrevData[index]);
          rawPrevData.splice(index, 1);
        } else {
          renderPrevData.push({
            argument: i.argument,
            value: 0,
            noLegend: true,
          });
        }
      }
    });

    if (rawPrevData.length !== 0) {
      rawPrevData.forEach(row => (totalPrevOther += Number(row.value)));
      renderPrevData.push({
        argument: othersLabel,
        value: totalPrevOther,
        noLegend: true,
      });
    }

    const cells = renderPrevData.map((entry, index) => {
      return (
        <Cell
          key={index}
          fill={palette[index % palette.length]}
          fillOpacity={activeIndex === null || activeIndex === index ? '1' : '0.5'}
        />
      );
    });

    prevData = (
      <RechartsPie
        nameKey="argument"
        data={renderPrevData}
        outerRadius="33%"
        innerRadius="17%"
        dataKey="value"
        activeIndex={activeIndex ? [activeIndex] : undefined}
        activeShape={<ActiveShape />}
        onMouseOver={(e, index) => setActiveIndex(index)}
        onMouseOut={() => setActiveIndex(null)}
      >
        {cells}
      </RechartsPie>
    );
  }

  const cells = renderData.map((_, index) => (
    <Cell
      key={index}
      fill={palette[index % palette.length]}
      fillOpacity={activeIndex === null || activeIndex === index ? '1' : '0.5'}
    />
  ));

  let emptyPie = null;
  let legend = (
    <Legend
      type="pie"
      setActiveIndex={setActiveIndex}
      compareWithPrev={!!previousData}
      previousData={previousData}
      totalOther={totalOther}
      totalPrevOther={totalPrevOther}
    />
  );
  let tooltip: ReactElement | null = (
    <RechartsTooltip
      cursor={false}
      content={
        <Tooltip {...props} isPercentage totalOther={totalOther} totalPrevOther={totalPrevOther} />
      }
    />
  );
  if (noData) {
    tooltip = null;
    emptyPie = (
      <RechartsPie
        key="empty"
        nameKey="argument"
        paddingAngle={0}
        stroke="0"
        data={[{ value: 1 }]}
        outerRadius="80%"
        innerRadius="48%"
        dataKey="value"
        startAngle={90}
        endAngle={-270}
      >
        <Cell fill="transparent" stroke="#E3E6EE" stroke-width="0.8" />
      </RechartsPie>
    );
    legend = <EmptyLegend label={emptyLabel} />;
  }

  return (
    <ResponsiveContainer id={id} className="widget pie">
      <PieChart>
        <RechartsLegend content={legend} />
        {tooltip}
        <RechartsPie
          nameKey="argument"
          data={renderData}
          labelLine={false}
          outerRadius="80%"
          innerRadius="48%"
          dataKey="value"
          activeIndex={activeIndex ? [activeIndex] : undefined}
          activeShape={<ActiveShape />}
          onMouseOver={(e, index) => setActiveIndex(index)}
          onMouseOut={() => setActiveIndex(null)}
        >
          {cells}
        </RechartsPie>
        {prevData}
        {emptyPie}
      </PieChart>
    </ResponsiveContainer>
  );
}
