import { MouseEventHandler, ReactElement } from 'react';
import { useNavigate } from 'react-router';

import logo from 'assets/images/adcalls-logo.svg';
import Footer from 'components/Footer';
import LanguageSelector from 'components/unauthenticated/LanguageSelector';

type Props = {
  left: ReactElement;
  right: ReactElement | null;
  canClick?: boolean;
  alignCenter?: boolean;
};

export default function ContentSidebar({ left, right, canClick = true, alignCenter }: Props) {
  const navigate = useNavigate();

  let imgClassName = '';
  let imgClick: MouseEventHandler = () => null;
  if (canClick) {
    imgClassName = ' clickable';
    imgClick = () => navigate('/');
  }
  return (
    <div id="content-sidebar">
      <div className="left" data-testid="left">
        <LanguageSelector />
        <div className={'content' + (alignCenter ? ' align-center' : '')}>
          <div className="image-wrapper">
            <img alt="AdCalls" className={'logo' + imgClassName} src={logo} onClick={imgClick} />
          </div>
          {left}
        </div>
        <div className="content-footer">
          <Footer placement="center" />
        </div>
      </div>
      <div className="right" data-testid="right">
        <div className="content">{right}</div>
      </div>
    </div>
  );
}
