import { useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { cloneDeep } from 'lodash';

import ConfigurationCard from 'components/configuration/ConfigurationCard';
import GrayBars from 'components/GrayBars';
import Select from 'components/input/Select';
import TextField from 'components/input/TextField';
import Toggle from 'components/input/Toggle';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import SupportParagraph from 'components/SupportParagraph';
import { PH_MASK } from 'globals/constants';
import { SUP_ART_CALL_NOTIFICATIONS } from 'globals/support';
import { useCallFunctionalities, useDomainAuth } from 'hooks/queries/domain';
import { useCallNotifications } from 'hooks/queries/domain-setting';
import { useTranslate } from 'hooks/translate';

const CALL_NOTIFICATION_TYPE_DISABLED = 0 as const;
const CALL_NOTIFICATION_TYPE_MISSED = 1 as const;
const CALL_NOTIFICATION_TYPE_ALL = 2 as const;

type FormValues = {
  type: number;
  email: string;
  perLocation: boolean;
  locations: { identifier: string; name: string; type: number; value: string }[];
};

export default function CallNotifications() {
  const translateText = useTranslate();
  const { updateCallNotifications } = useCallNotifications();
  const { callFunctionalities, isLoading: isLoadingCallFunctionalities } = useCallFunctionalities();
  const { isLoading: isLoadingDomainAuth } = useDomainAuth();
  const isLoading = isLoadingCallFunctionalities || isLoadingDomainAuth;
  const [showEdit, setShowEdit] = useState(false);

  const supportText = translateText(
    'call-tracking',
    'By activating call notifications, you will receive a notification for all calls or for only the missed calls.',
  );

  const settings = callFunctionalities?.call_notification;

  function getRows() {
    const status =
      settings &&
      (settings.type !== CALL_NOTIFICATION_TYPE_DISABLED || settings.use_locations === 'true');

    let statusLabel;
    if (Number(settings?.type) === CALL_NOTIFICATION_TYPE_ALL) {
      statusLabel = translateText('label', 'For all calls').toLowerCase();
    } else if (Number(settings?.type) === CALL_NOTIFICATION_TYPE_MISSED) {
      statusLabel = translateText('label', 'For missed calls').toLowerCase();
    }

    const rows: { key: string; status?: boolean; statusLabel?: string; value?: string }[] = [
      { key: translateText('label', 'Call notifications'), status, statusLabel },
    ];
    if (status) {
      const value =
        settings.use_locations === 'true'
          ? translateText('label', 'Yes')
          : translateText('label', 'No');
      rows.push({ key: translateText('label', 'Email address per location'), value });
    }
    return rows;
  }

  function getEmailInput(
    values: FormValues,
    handleChange: FormikProps<FormValues>['handleChange'],
    setFieldValue: FormikProps<FormValues>['setFieldValue'],
    isSubmitting: FormikProps<FormValues>['isSubmitting'],
  ) {
    if (!values.perLocation) {
      return (
        <InputWrapper label={translateText('label', 'Email address for notifications')}>
          <TextField
            name="email"
            value={values.email}
            onChange={handleChange}
            disabled={isSubmitting || Number(values.type) === CALL_NOTIFICATION_TYPE_DISABLED}
          />
        </InputWrapper>
      );
    }

    function updateLocation(value: string, identifier: string, keyToUpdate: 'type' | 'value') {
      const locations = cloneDeep(values.locations);
      const location = locations.find(location => location.identifier === identifier);
      if (!location) return;
      if (keyToUpdate === 'type') location[keyToUpdate] = Number(value);
      if (keyToUpdate === 'value') location[keyToUpdate] = value;
      setFieldValue('locations', locations);
    }

    return (
      <div>
        <div className="form-row">
          <div className="name-label bold">{translateText('label', 'Location')}</div>
          <div className="value-wrapper">
            <div className="value-col-1 bold">
              {translateText('label', 'Send notifications for')}
            </div>
            <div className="value-col-2 bold">{translateText('label', 'Email address')}</div>
          </div>
        </div>
        {values.locations.map((location, index) => {
          let locationName = location.name || location.identifier;
          if (locationName !== location.identifier) {
            locationName += ' (' + location.identifier + ')';
          }

          return (
            <div key={index} className="form-row">
              <div className={'name-label thin ' + PH_MASK}>{locationName}</div>
              <div className="value-wrapper">
                <div className="value-col-1">
                  <Select
                    id={location.identifier + '-call-notifications-dropdown'}
                    value={location.type}
                    onChange={e => updateLocation(e.target.value, location.identifier, 'type')}
                    options={{
                      '-1': translateText('label', 'Same as general'),
                      '0': translateText('label', 'No notifications'),
                      '1': translateText('label', 'Missed calls'),
                      '2': translateText('label', 'All calls'),
                    }}
                  />
                </div>

                <div className="value-col-2">
                  <TextField
                    id={location.identifier + '-email-input'}
                    value={location.value === 'false' ? '' : location.value}
                    onChange={e => updateLocation(e.target.value, location.identifier, 'value')}
                    disabled={
                      location.type === 0 ||
                      (location.type === -1 &&
                        Number(values.type) === CALL_NOTIFICATION_TYPE_DISABLED)
                    }
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  async function save(values: FormValues) {
    await updateCallNotifications(values).then(() => {
      setShowEdit(false);
    });
  }

  return (
    <>
      <ConfigurationCard
        title={translateText('label', 'Call notifications')}
        supportArticleId={SUP_ART_CALL_NOTIFICATIONS}
        onEdit={() => setShowEdit(true)}
        isLoading={isLoading}
        rightParagraph={supportText}
      >
        <GrayBars rows={getRows()} isLoading={isLoading} />
      </ConfigurationCard>
      <Formik
        initialValues={{
          type: settings?.type ?? 0,
          email: settings?.email ?? '',
          perLocation: settings?.use_locations === 'true',
          locations: settings?.locations ?? [],
        }}
        onSubmit={async values => await save(values)}
        enableReinitialize
      >
        {({ values, submitForm, resetForm, isSubmitting, dirty, handleChange, setFieldValue }) => (
          <Setup
            show={showEdit}
            title={translateText('label', 'Call notifications')}
            save={submitForm}
            close={() => setShowEdit(false)}
            afterClose={resetForm}
            isSaving={isSubmitting}
            maxWidth={750}
            hasChanges={dirty}
          >
            <p>{supportText}</p>
            <SupportParagraph articleId={SUP_ART_CALL_NOTIFICATIONS} />
            <div className="section">
              <InputWrapper label={translateText('label', 'Send notifications for')}>
                <Select
                  name="type"
                  value={values.type}
                  onChange={handleChange}
                  options={{
                    0: translateText('label', 'No notifications'),
                    1: translateText('label', 'Missed calls'),
                    2: translateText('label', 'All calls'),
                  }}
                />
              </InputWrapper>
              <InputWrapper label={translateText('label', 'Email address per location')}>
                <Toggle
                  name="perLocation"
                  checked={values.perLocation}
                  onClick={handleChange}
                  disabled={isSubmitting}
                />
              </InputWrapper>
            </div>
            {getEmailInput(values, handleChange, setFieldValue, isSubmitting)}
          </Setup>
        )}
      </Formik>
    </>
  );
}
