import { ReactElement, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import authorize from 'assets/images/authorize.svg';
import ImagePage from 'components/ImagePage';
import { useSession } from 'contexts/Session';
import { useTranslate } from 'hooks/translate';

const SUPPORTED_SCOPES = ['user-all'] as const;

export default function Authorize() {
  const translateText = useTranslate();
  const { token } = useSession();

  const [searchParams] = useSearchParams();
  const error = searchParams.get('error');

  const [isLoading, setIsLoading] = useState(false);

  function authenticate(approved: boolean) {
    setIsLoading(true);
    searchParams.append('approved', String(+approved));
    searchParams.append('bearer', token ?? '');
    (window as Window).location =
      process.env.REACT_APP_API_URL + '/oauth2/authorize?' + searchParams.toString();
  }

  let data: ReactElement;
  if (error) {
    let errorText = translateText('message', 'Unknown error.');
    let errorHelp = null;
    switch (error) {
      case 'invalid_client':
        errorText = translateText(
          'message',
          'The requested client is not registered or its credentials are invalid. Make sure you supplied the correct client ID and redirect URI.',
        );
        break;
      case 'invalid_request':
        errorText = translateText(
          'message',
          'The request is missing a required parameter, included an invalid parameter value, included a parameter value more than once, or is otherwise malformed.',
        );
        break;
      case 'invalid_scope':
        errorText = translateText(
          'message',
          'The requested scope is invalid. We currently support the scopes listed below.',
        );
        errorHelp = (
          <ul className="purple-text">
            {SUPPORTED_SCOPES.map((scope, i) => (
              <li key={i}>{scope}</li>
            ))}
          </ul>
        );
        break;
      case 'server_error':
        errorText = translateText(
          'message',
          'Something unexpected happened that prevented the authorization from completing.',
        );
        break;
      case 'unsupported_grant_type':
        errorText = translateText('message', 'The requested grant type is not supported.');
        break;
    }

    data = (
      <>
        <p className="purple-text">
          {translateText(
            'message',
            'Access to the account could not be granted. Try again or contact us at support@adcalls.nl.',
          )}
        </p>
        <p className="purple-text">
          {translateText('message', 'Error: {errorText}', { errorText })}
        </p>
        {errorHelp}
      </>
    );
  } else {
    data = (
      <>
        <div className="button-wrapper">
          <button
            className="btn btn-lightblue"
            onClick={() => authenticate(true)}
            disabled={isLoading}
          >
            {translateText('label', 'Grant access')}
          </button>
        </div>
        <div className="button-wrapper">
          <button
            className="btn btn-white"
            onClick={() => authenticate(false)}
            disabled={isLoading}
          >
            {translateText('label', 'Cancel')}
          </button>
        </div>
      </>
    );
  }

  return (
    <ImagePage
      title={translateText('label', 'Grant access to the data of AdCalls')}
      content={
        <>
          <p>
            {translateText(
              'text',
              'An application is requesting access to the data of the AdCalls domains to which you have access.',
            )}
          </p>
          {data}
        </>
      }
      image={authorize}
    />
  );
}
