import icon from 'assets/images/icons/integrations/microsoft-dynamics.svg';
import BaseCrmIntegration from 'components/integrations/BaseCrmIntegration';
import { REDIRECT_URI_MICROSOFT_DYNAMICS } from 'components/oauth2/Verification';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import { INTEGRATION_MICROSOFT_DYNAMICS } from 'globals/constants';
import { SUP_ART_MICROSOFT_DYNAMICS } from 'globals/support';
import { useHasAccessToIntegration } from 'hooks/access';
import { useTranslate } from 'hooks/translate';

export default function MicrosoftDynamics({ show, close }: BaseSlideInProps) {
  const translateText = useTranslate();
  const hasAccessToIntegration = useHasAccessToIntegration();

  if (!hasAccessToIntegration(INTEGRATION_MICROSOFT_DYNAMICS)) return null;

  return (
    <BaseCrmIntegration
      integrationKey="microsoftDynamics"
      redirectUriKey={REDIRECT_URI_MICROSOFT_DYNAMICS}
      title={translateText('label', 'Microsoft Dynamics')}
      show={show}
      close={close}
      icon={icon}
      articleId={SUP_ART_MICROSOFT_DYNAMICS}
      infoText={translateText(
        'integration',
        'Microsoft Dynamics is a line of enterprise resource planning (ERP) and customer relationship management (CRM) applications developed by Microsoft. With the help of this integration you can enrich the contact details in Microsoft Dynamics with the data of incoming calls.',
      )}
      price={99}
    />
  );
}
