import SettingsPage from 'components/settings/SettingsPage';
import QueryTable from 'components/table/QueryTable';
import { useHasEmployeeAccess } from 'hooks/access';
import { useTranslate } from 'hooks/translate';

export default function ChangeHistory() {
  const translateText = useTranslate();
  const isEmployee = useHasEmployeeAccess();

  return (
    <SettingsPage title={translateText('label', 'Change history')}>
      <QueryTable
        tableKey={['changeHistory']}
        className="change-history"
        columns={{
          date: {
            header: { name: translateText('label', 'Last change date'), sort: true },
            date: true,
          },
          user: {
            header: { name: translateText('label', 'Changed by'), sort: true },
            private: true,
          },
          type: { header: translateText('label', 'Change type') },
          ...(isEmployee && {
            value: {
              header: {
                // TODO AC-9655 remove ribbon
                name: (
                  <div className="restricted outline">
                    <div className="ribbon purple no-bordered-child right" data-text="Admin" />
                    {translateText('label', 'New value')}
                  </div>
                ),
              },
              noNumberFormat: true,
              private: true,
              className: 'max-length',
              valueAsTitle: true,
            },
            old_value: {
              header: {
                // TODO AC-9655 remove ribbon
                name: (
                  <div className="restricted outline">
                    <div className="ribbon purple no-bordered-child right" data-text="Admin" />
                    {translateText('label', 'Old value')}
                  </div>
                ),
              },
              noNumberFormat: true,
              private: true,
              className: 'max-length',
              valueAsTitle: true,
            },
          }),
        }}
        endpoint="/change-history/index"
        tooltip={translateText(
          'domain',
          'Below you will find an overview of all changes that have been made for this domain.',
        )}
        canSearch
        pagination
      />
    </SettingsPage>
  );
}
