import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useMessages } from 'contexts/Messages';
import { useTenant } from 'contexts/Tenant';
import { ChangeRequest } from 'globals/types';
import { useSetQueryData, useSetTableData, useTableData } from 'hooks/data';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';

export type AdminChangeRequest = ChangeRequest & AdminChangeRequestDetails;

export type AdminChangeRequestDetails = {
  needed_numbers?: Record<string, number>;
  reseller?: string | null;
  is_active?: boolean;
  past_requests?: number;
  execution_date?: string | false;
  domain_contract_duration?: string;
  domain_contract_end_date?: string;
  domain_is_trial?: boolean;
};

export function useChangeRequest(id: number | null = null) {
  const { fetchData } = useFetch();
  const overviewData = useTableData<ChangeRequest>(['changeRequests']);

  const { data, isFetching, isError, error } = useQuery({
    queryKey: ['changeRequest', id],
    queryFn: (): Promise<ChangeRequest> => fetchData('/domain-change-request/' + id),
    enabled: id !== null,
    meta: { noError: true },
    initialData: () => overviewData.find(i => i.id === id),
  });

  return { changeRequest: data, isLoading: isFetching, isError, error };
}

export function useAdminChangeRequest(id: number | null = null) {
  const { fetchData } = useFetch();
  const changeRequestData = useTableData<AdminChangeRequest>(['admin', 'domainChangeRequest']);

  const { data, isFetching } = useQuery({
    queryKey: ['admin', 'changeRequestDetails', id],
    queryFn: (): Promise<AdminChangeRequest> =>
      fetchData('/domain-change-request/view', {
        queryParams: { id, expand: 'needed_numbers,type_key' },
      }),
    enabled: id !== null,
    initialData: () => changeRequestData?.find(i => i.id === id),
  });

  return { changeRequest: data, isLoading: isFetching };
}

export function usePendingChanges(search: string, enabled = true) {
  const { selectedDomain: domain } = useTenant();
  const { fetchData } = useFetch();

  const { data, isFetching } = useQuery({
    queryKey: ['pendingChanges', domain, search],
    queryFn: () =>
      fetchData('/domain-change-request/pending-changes', { queryParams: { domain, search } }),
    enabled: domain !== null && enabled,
  });

  return { pending: data, isLoading: isFetching };
}

export function useChangeRequestEvaluation() {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const setQueryData = useSetQueryData();
  const setTableData = useSetTableData();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: ({
      id,
      evaluation,
      skipNumberCheck,
    }: {
      id: number;
      evaluation: 'approve' | 'decline';
      skipNumberCheck: boolean;
    }) =>
      fetchData('/domain-change-request/' + evaluation, {
        method: 'POST',
        queryParams: {
          id,
          expand: 'needed_numbers,type_key',
        },
        bodyParams: {
          skip_number_check: skipNumberCheck,
        },
      }),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['pendingChanges'] });
      queryClient.invalidateQueries({ queryKey: ['changeRequests'] });
      setTableData(['admin', 'domainChangeRequest'], oldData =>
        oldData.map(cr => (cr.id === data.id ? data : cr)),
      );
      setQueryData(['admin', 'changeRequestDetails', variables.id], () => data);

      addSuccessMessage(
        translateText('admin/text', 'Domain change request has been {action}.', {
          action:
            variables.evaluation === 'approve'
              ? translateText('label', 'Approved').toLowerCase()
              : translateText('label', 'Declined').toLowerCase(),
        }),
      );
    },
  });

  async function confirmRequest(
    id: number,
    evaluation: 'approve' | 'decline',
    skipNumberCheck = false,
  ) {
    if (window.confirm(translateText('admin/text', 'Approve domain change request?'))) {
      await mutateAsync({ id, evaluation, skipNumberCheck });
    }
  }

  return {
    confirmRequest,
    evaluateChangeRequest: mutateAsync,
    isSaving: isPending,
  };
}

export function useCancelChangeRequest() {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const { fetchData } = useFetch();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (id: number) =>
      fetchData('/domain-change-request/cancel', {
        method: 'DELETE',
        queryParams: { id },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['pendingChanges'] });
      queryClient.invalidateQueries({ queryKey: ['changeRequests'] });
    },
  });

  async function cancelRequest(id: number) {
    if (window.confirm(translateText('message', 'Cancel domain change request?'))) {
      await mutateAsync(id);
    }
  }

  return {
    cancelRequest,
    cancelWithoutConfirm: mutateAsync,
    isDeleting: isPending,
  };
}

export function useUpdateChangeRequest() {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage, addErrorMessage } = useMessages();
  const setQueryData = useSetQueryData();
  const setTableData = useSetTableData();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: ({
      id,
      changes,
      status,
    }: {
      id: number;
      changes?: ChangeRequest['wanted_changes'];
      status?: number;
    }) =>
      fetchData('/domain-change-request/' + id, {
        method: 'PUT',
        queryParams: { expand: 'needed_numbers,type_key' },
        bodyParams: {
          wanted_changes: typeof changes !== 'string' ? JSON.stringify(changes) : changes,
          status,
        },
      }),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['pendingChanges'] });
      queryClient.invalidateQueries({ queryKey: ['changeRequests'] });
      setTableData(['admin', 'domainChangeRequest'], oldData =>
        oldData.map(cr => (cr.id === data.id ? data : cr)),
      );
      setQueryData(['admin', 'changeRequestDetails', variables.id], () => data);
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
    onError: (error: { wanted_changes: string[] } | string) =>
      addErrorMessage(typeof error === 'object' ? error?.wanted_changes[0] : error),
    meta: { noError: true },
  });

  return { updateRequest: mutateAsync, isUpdating: isPending };
}
