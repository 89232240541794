type Props = {
  small?: boolean;
};

export default function Loader({ small = false }: Props) {
  return (
    <div className="loader-wrapper" data-testid="loader">
      <div className={'loader' + (small ? ' small' : '')}>
        <div className="animation">
          <div>
            <div>
              <div />
            </div>
            <div>
              <div />
            </div>
            <div>
              <div />
            </div>
            <div>
              <div />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
