import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';

import ErrorFocus, { focusErrorElement } from 'components/ErrorFocus';
import Select from 'components/input/Select';
import TextField from 'components/input/TextField';
import Toggle from 'components/input/Toggle';
import {
  CONVERSION_ACTION_OPTIONS,
  ConversionActionSetting,
  GOOGLE_ADS_ENHANCED_CONVERSIONS_KEY,
  GoogleAdsConditionKey,
  GoogleAdsType,
} from 'components/integrations/google-ads/BaseGoogleAds';
import RestrictedAccess from 'components/RestrictedAccess';
import Skeleton from 'components/Skeleton';
import InputWrapper from 'components/slide-in/InputWrapper';
import { CURRENCIES_LIST, ROLE_ADMIN } from 'globals/constants';
import { useGoogleAdsConversionAction } from 'hooks/queries/integration';
import { useTranslate } from 'hooks/translate';
import Conditions from '../Conditions';

type Props<T> = {
  type: T;
  loadedConversionAction: string | boolean;
  setLoadedConversionAction: (value: boolean | 'loading' | 'failed') => void;
};

export default function ConversionAction<T extends GoogleAdsType>({
  type,
  loadedConversionAction,
  setLoadedConversionAction,
}: Props<T>) {
  const translateText = useTranslate();
  const {
    values,
    handleChange,
    setFieldValue,
    errors,
    submitCount,
    validateForm,
    initialValues,
    isSubmitting,
    setErrors,
  } = useFormikContext<ConversionActionSetting<T>>();
  const {
    isCreating,
    loadAction,
    syncAction,
    isLoading: isLoadingAction,
  } = useGoogleAdsConversionAction(type);

  const isLoading = isLoadingAction || loadedConversionAction === 'loading';

  const conditionKeys: Record<GoogleAdsConditionKey, string> = {
    location: translateText('label', 'Location'),
    evaluation: translateText('label', 'Evaluation'),
    evaluation_value: translateText('label', 'Evaluation value'),
    selection_menu: translateText('label', 'Customer evaluation'),
    answered: translateText('label', 'Answered'),
    returning_caller: translateText('label', 'Recurring caller'),
    second_event: translateText('label', 'Second event'),
    ...(type === GOOGLE_ADS_ENHANCED_CONVERSIONS_KEY
      ? { offsite_number: translateText('label', 'Offsite phone number') }
      : {}),
  };

  async function loadConversionAction() {
    setLoadedConversionAction('loading');
    const type = values.id === 'new' ? 'load' : 'sync';
    const errors = await validateForm();
    if (!isEmpty(errors) && (errors.name || type === 'sync')) {
      focusErrorElement(document.getElementsByClassName('setup-wrapper')[0]);
      setLoadedConversionAction('failed');
    } else {
      if (type === 'load') {
        await loadAction({ name: values.name, hasResourceName: !!initialValues.resource_name })
          .then(() => setLoadedConversionAction(true))
          .catch(response => {
            if (response.status === 404) {
              // Set to true, because we need to make a new conversion action if conversion action is not found in google ads
              setLoadedConversionAction(true);
              setErrors({});
            } else {
              // Set to 'failed', because something went wrong like a BadRequest or a ServerError
              setLoadedConversionAction('failed');
            }
          });
      } else {
        await syncAction(+values.id).finally(() => setLoadedConversionAction(true));
      }
    }
  }

  return (
    <>
      <ErrorFocus element={document.getElementsByClassName('setup-wrapper')[0]} />
      <p>
        {translateText(
          'integration',
          'With the help of this functionality, a conversion action is automatically created in Google Ads.',
        )}
      </p>
      <div className="section conversion-action">
        <InputWrapper
          label={translateText('label', 'Name')}
          tooltip={translateText('tooltip/google-ads', 'Set the name of the conversion action.')}
        >
          <TextField
            name="name"
            value={values.name}
            onChange={handleChange}
            error={(submitCount > 0 || loadedConversionAction) && errors.name}
            disabled={isCreating}
          />
        </InputWrapper>
        <button
          className="btn btn-text no-padding margin-top"
          disabled={isLoading || isCreating}
          onClick={loadConversionAction}
        >
          {values.id === 'new'
            ? translateText('label', 'Load conversion action')
            : translateText('label', 'Sync conversion action')}
        </button>
        {(!!values.resource_name ||
          loadedConversionAction === true ||
          loadedConversionAction === 'loading') && (
          <>
            <InputWrapper label={translateText('label', 'Enable conversion')} isLoading={isLoading}>
              <Toggle
                name="enabled"
                checked={values.enabled}
                onClick={handleChange}
                disabled={isSubmitting}
                isLoading={isLoading}
              />
            </InputWrapper>
            <InputWrapper
              label={translateText('label', 'Category')}
              tooltip={translateText(
                'tooltip/google-ads',
                'Determine the category in which the conversion action belongs.',
              )}
              isLoading={isLoading}
            >
              {isLoading ? (
                <Skeleton height={38} />
              ) : (
                <Select
                  name="category"
                  addSelect
                  hideSelectOption={false}
                  disabled={isCreating}
                  error={(submitCount > 0 || loadedConversionAction) && errors.category}
                  value={values.category}
                  onChange={handleChange}
                  options={{
                    0: 'Unspecified',
                    1: 'Unknown',
                    2: 'PBDefault',
                    3: 'Page view',
                    4: 'Purchase',
                    5: 'Signup',
                    7: 'Download',
                    8: 'Add to cart',
                    9: 'Begin checkout',
                    10: 'Subscribe paid',
                    11: 'Phone call lead',
                    12: 'Imported lead',
                    13: 'Submit lead form',
                    14: 'Book appointment',
                    15: 'Request quote',
                    16: 'Get directions',
                    17: 'Outbound click',
                    18: 'Contact',
                    19: 'Engagement',
                    20: 'Store visit',
                    21: 'Store sale',
                    22: 'Qualified lead',
                    23: 'Converted lead',
                  }}
                />
              )}
            </InputWrapper>
            <InputWrapper
              label={translateText('label', 'Attribution model')}
              tooltip={translateText(
                'tooltip/google-ads',
                'Determine which attribution model applies.',
              )}
              isLoading={isLoading}
            >
              {isLoading ? (
                <Skeleton height={38} />
              ) : (
                <Select
                  name="attribution_model"
                  addSelect
                  disabled={isCreating}
                  error={(submitCount > 0 || loadedConversionAction) && errors.attribution_model}
                  value={values.attribution_model}
                  onChange={handleChange}
                  options={CONVERSION_ACTION_OPTIONS}
                />
              )}
            </InputWrapper>
            <InputWrapper
              label={translateText('label', 'Counting type')}
              tooltip={translateText(
                'tooltip/google-ads',
                'Determine how many conversions to count per click or interaction.',
              )}
              isLoading={isLoading}
            >
              {isLoading ? (
                <Skeleton height={38} />
              ) : (
                <Select
                  name="counting_type"
                  addSelect
                  disabled={isCreating}
                  value={values.counting_type}
                  onChange={handleChange}
                  options={{
                    2: translateText('label', 'One per click'),
                    3: translateText('label', 'Many per click'),
                  }}
                />
              )}
            </InputWrapper>
            <InputWrapper
              label={translateText('label', 'Conversion period')}
              tooltip={translateText(
                'tooltip/google-ads',
                'Determine the period in which the conversion may be counted.',
              )}
              isLoading={isLoading}
            >
              {isLoading ? (
                <Skeleton height={38} />
              ) : (
                <TextField
                  disabled={isCreating}
                  name="conversion_period"
                  value={values.conversion_period}
                  onChange={e => {
                    const value = e.target.value.replace(/[^0-9]/g, '');
                    setFieldValue('conversion_period', value ? parseInt(value) : 0);
                  }}
                  error={(submitCount > 0 || loadedConversionAction) && errors.conversion_period}
                />
              )}
            </InputWrapper>
            <InputWrapper
              label={translateText('label', 'Conversion action')}
              tooltip={translateText(
                'tooltip/google-ads',
                "At the conversion action level, you can control whether a separate bid optimization conversion action is used (primary action) or not (secondary action). Secondary actions are used for reporting in the 'All conversions' column but not for bidding strategies.",
              )}
              isLoading={isLoading}
            >
              {isLoading ? (
                <Skeleton height={38} />
              ) : (
                <Select
                  name="primary_for_goal"
                  value={values.primary_for_goal ? 'primary' : 'secondary'}
                  disabled={isCreating}
                  onChange={e => setFieldValue('primary_for_goal', e.target.value === 'primary')}
                  options={{
                    primary: translateText('label', 'Primary action'),
                    secondary: translateText('label', 'Secondary action'),
                  }}
                />
              )}
            </InputWrapper>
            <InputWrapper
              label={translateText('label', 'Send conversion values')}
              tooltip={translateText(
                'tooltip/google-ads',
                'Determine whether the conversion values of the calls can be processed in Google Ads.',
              )}
              isLoading={isLoading}
            >
              <Toggle
                name="ecommerce_enabled"
                checked={values.ecommerce_enabled}
                disabled={isSubmitting}
                onClick={handleChange}
                isLoading={isLoading}
              />
            </InputWrapper>
            <InputWrapper
              label={translateText('label', 'Currency value')}
              tooltip={translateText('tooltip/google-ads', 'Select the desired currency.')}
              isLoading={isLoading}
            >
              {isLoading ? (
                <Skeleton height={38} width={300} />
              ) : (
                <Select
                  id="select_currency"
                  disabled={isLoading || isSubmitting}
                  value={values.ecommerce_currency}
                  onChange={e => setFieldValue('ecommerce_currency', e.target.value)}
                  options={CURRENCIES_LIST}
                />
              )}
            </InputWrapper>
            {type === GOOGLE_ADS_ENHANCED_CONVERSIONS_KEY && (
              <RestrictedAccess role={ROLE_ADMIN} show="outline" right>
                <InputWrapper
                  label={translateText('label', 'Send offsite calls')}
                  isLoading={isLoading}
                >
                  <Toggle
                    name="send_offsite_calls"
                    checked={!!values.send_offsite_calls}
                    disabled={isSubmitting}
                    onClick={handleChange}
                    isLoading={isLoading}
                  />
                </InputWrapper>
              </RestrictedAccess>
            )}
            <h3 className="section-title">
              {isLoading ? <Skeleton width={200} /> : translateText('label', 'Filters')}
            </h3>
            <InputWrapper label={translateText('label', 'Enable filters')} isLoading={isLoading}>
              <Toggle
                name="filters_enabled"
                checked={values.filters_enabled}
                onClick={handleChange}
                disabled={isSubmitting}
                isLoading={isLoading}
              />
            </InputWrapper>
            {values.filters_enabled && (
              <Conditions
                options={conditionKeys}
                conditions={values.conditions}
                setConditions={conditions => setFieldValue('conditions', conditions)}
                disabled={isSubmitting}
                removeOptionsForNew={
                  type === GOOGLE_ADS_ENHANCED_CONVERSIONS_KEY && !values.send_offsite_calls
                    ? ['offsite_number' as GoogleAdsConditionKey]
                    : undefined
                }
              />
            )}
          </>
        )}
      </div>
    </>
  );
}
