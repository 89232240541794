import { PropsWithChildren } from 'react';

import Skeleton from 'components/Skeleton';
import Tooltip from 'components/Tooltip';

type Props = {
  id?: string;
  label: string;
  tooltip?: string;
  isLoading?: boolean;
  wrapperClassName?: string;
  fieldClassName?: string;
};

export default function InputWrapper({
  id,
  label,
  tooltip,
  isLoading,
  wrapperClassName,
  fieldClassName,
  children,
}: PropsWithChildren<Props>) {
  return (
    <div className={'input-wrapper' + (wrapperClassName ? ' ' + wrapperClassName : '')}>
      <label htmlFor={id} className="input-label">
        {isLoading ? <Skeleton width={label.length * 10} /> : label}
        {tooltip && !isLoading && <Tooltip text={tooltip} />}
      </label>
      <div className={'input-field' + (fieldClassName ? ' ' + fieldClassName : '')}>{children}</div>
    </div>
  );
}
