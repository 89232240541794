import { useQuery } from '@tanstack/react-query';

import { PricingPlan, PricingPlanId } from 'globals/types';
import { useFetch } from 'hooks/fetch';

type PricingPlanDetails = {
  id: PricingPlanId;
  invoice_label: PricingPlan;
};

export function usePricingPlans() {
  const { fetchData } = useFetch();

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ['pricingPlans'],
    queryFn: (): Promise<PricingPlanDetails[]> => fetchData('/pricing/plans'),
    meta: { persist: true },
  });

  return { pricingPlans: data, isLoading };
}
