import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import moment from 'moment';

import RandomWidthSkeleton from 'components/RandomWidthSkeleton';
import Skeleton from 'components/Skeleton';
import { useArticles } from 'hooks/queries/support';
import { useTranslate } from 'hooks/translate';

type Props = {
  isLoading: boolean;
};

export default function Carousel({ isLoading }: Props) {
  const translateText = useTranslate();
  const navigate = useNavigate();
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const { articles } = useArticles({
    slider: true,
    limit: 3,
    sort: 'sup_slider.order',
  });

  const [slide, setSlide] = useState(0);
  const article = articles?.[slide];

  useEffect(() => {
    if (articles) startCycle();
    return () => stopCycle();
  }, [articles]); // eslint-disable-line react-hooks/exhaustive-deps

  function stopCycle() {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }

  function startCycle() {
    if (intervalRef.current !== null) stopCycle();
    intervalRef.current = setInterval(() => changeSlide(), 5000);
  }

  function changeSlide(next = true) {
    if (!articles) return;
    setSlide(slide => {
      const lastSlide = articles.length - 1;
      let newSlide = slide + (next ? 1 : -1);

      if (newSlide > lastSlide) newSlide = 0;
      else if (newSlide < 0) newSlide = lastSlide;

      return newSlide;
    });
  }

  return (
    <div
      className="carousel-container"
      onMouseEnter={() => stopCycle()}
      onMouseLeave={() => startCycle()}
    >
      <div key={article?.id} className="fading">
        {isLoading ? (
          <div className="carousel-image-skeleton" />
        ) : (
          <img src={article?.image} className="carousel-image" alt="" />
        )}
        <div className="dots-container">
          {isLoading ? (
            <Skeleton />
          ) : (
            articles?.map((article, index) => (
              <span
                key={'dot' + index}
                className={'dot' + (index === slide ? ' active' : '')}
                onClick={() => setSlide(index)}
                data-testid={'dot' + index}
              />
            ))
          )}
        </div>
      </div>
      <div className={'content-preview' + (isLoading ? ' is-loading' : '')}>
        <div className="top-text">
          <span className="created-by">
            {isLoading ? (
              <Skeleton width="min(150px, 80%)" />
            ) : (
              <>
                {moment(article?.created_on, 'YYYY-MM-DD HH:mm:ss').format('ll') + ' '}
                {translateText('label', 'By').toLowerCase() + ' '}
                <span className="author">{article?.author}</span>
              </>
            )}
          </span>
        </div>
        <h2>{isLoading ? <RandomWidthSkeleton /> : article?.title}</h2>
        {isLoading ? (
          <p>
            <RandomWidthSkeleton count={7} />
          </p>
        ) : article?.summary ? (
          <p dangerouslySetInnerHTML={{ __html: article.summary }} />
        ) : (
          ''
        )}
        {isLoading ? (
          <Skeleton className="btn" width={120} />
        ) : (
          <button
            className="btn btn-lightblue"
            onClick={() =>
              article?.main_slug
                ? navigate('/knowledge-center/article/' + encodeURIComponent(article.main_slug))
                : null
            }
          >
            {translateText('label', 'Read more')}
          </button>
        )}
      </div>
      <div
        data-testid="left-arrow"
        className="images-prev-button"
        onClick={() => changeSlide(false)}
      >
        {isLoading ? (
          <Skeleton height="100%" isCircle containerClass="full-height" />
        ) : (
          <span className="arrow arrow-left" />
        )}
      </div>
      <div data-testid="right-arrow" className="images-next-button" onClick={() => changeSlide()}>
        {isLoading ? (
          <Skeleton height="100%" isCircle containerClass="full-height" />
        ) : (
          <span className="arrow arrow-right" />
        )}
      </div>
    </div>
  );
}
