import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import fileDownload from 'js-file-download';
import moment, { Moment } from 'moment';

import { FormatsPerLocation } from 'components/configuration/implementation/FormatsSection';
import { Ip } from 'components/configuration/tracking-and-filters/IpFilter';
import {
  EditSubscriptionFormValues,
  EditSubscriptionType,
} from 'components/edit-subscription/EditSubscription';
import { PricingIndicationRow } from 'components/edit-subscription/PricingIndication';
import { useMessages } from 'contexts/Messages';
import { useTenant } from 'contexts/Tenant';
import { IMPLEMENTATION_TYPE_ADVANCED, IMPLEMENTATION_TYPE_STANDARD } from 'globals/constants';
import { DomainAuth, Offsite } from 'globals/types';
import { useSetQueryData, useSetTableData, useTableData } from 'hooks/data';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';
import { useUserDateFormat } from 'hooks/user';
import { ADMIN_DOMAIN_EXPAND, AdminDomain } from 'pages/authenticated/admin/domains/Overview';

type Implementation = {
  aens: FormatsPerLocation;
  type: 0 | 1;
  script_id: string;
};

export type CallFunctionalities = {
  call_recording: {
    call_recording: boolean;
    notification: string;
  };
  customer_evaluation_menu: {
    enabled: boolean;
    custom_menu: string | null;
  };
  evaluation_menu: { enabled: boolean; custom_menu: string | false };
  call_announcement: { whisper: boolean };
  call_notification: {
    type: number;
    email: string;
    use_locations: 'true' | 'false';
    locations: { identifier: string; name: string; value: string; type: number }[];
  };
};

export type DomainProperties = {
  account_manager: { id: number; name: string; email: string; phone: string };
  invoice_label: string;
  number_distribution: { enabled: boolean; label: string };
  number_format: number | string;
  pricing_plan_id: number;
  start_date: string;
  stop_requested: null | { id: number; status: number; date: string };
  title: string;
  url: string;
  contract_end_date: string;
  contract_start_date: string;
  contract_duration: string;
  upgrade_request_id: number | null;
};

export function useDomainAuth() {
  const { selectedDomain: id } = useTenant();
  const { fetchData } = useFetch();

  const { data, isPending: isLoading } = useQuery({
    queryKey: ['domainAuth', id],
    queryFn: (): Promise<DomainAuth> => fetchData('/domain/auth', { queryParams: { id } }),
    enabled: id !== null,
    meta: { persist: true },
  });

  return { domainAuth: data, isLoading };
}

export function useImplementation() {
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { selectedDomain: id } = useTenant();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { data, isPending: isLoading } = useQuery({
    queryKey: ['implementation', id],
    queryFn: (): Promise<Implementation> =>
      fetchData('/domain/get-implementation', { queryParams: { id } }),
    enabled: id !== null,
  });

  const { mutateAsync: updateImplementation } = useMutation({
    mutationFn: (type: typeof IMPLEMENTATION_TYPE_ADVANCED | typeof IMPLEMENTATION_TYPE_STANDARD) =>
      fetchData('/domain/set-implementation-type', {
        method: 'PUT',
        queryParams: { id },
        bodyParams: { type },
      }),
    onSuccess: (data, type) => {
      setQueryData<Implementation>(['implementation', id], oldData => ({
        ...oldData,
        type,
      }));
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  const { mutateAsync: sendImplementation } = useMutation({
    mutationFn: ({ email, comment }: { email: string; comment: string }) =>
      fetchData('/domain/send-implementation', {
        method: 'POST',
        queryParams: { id },
        bodyParams: { email, comment },
      }),
    onSuccess: (data, variables) => {
      addSuccessMessage(
        translateText(
          'message',
          'The implementation mail has been sent to <span data-private>{email}</span>',
          { email: variables.email },
        ),
      );
    },
  });

  return { implementation: data, isLoading, updateImplementation, sendImplementation };
}

export function useAcceptedUrls() {
  const { fetchData } = useFetch();
  const { selectedDomain } = useTenant();

  const { data, isFetching } = useQuery({
    queryKey: ['acceptedUrls', selectedDomain],
    queryFn: (): Promise<string[]> =>
      fetchData('/domain/accepted-urls', {
        queryParams: { domainId: selectedDomain },
      }),
    enabled: selectedDomain !== null,
  });

  return { acceptedUrls: data, isLoading: isFetching };
}

export function useCallFunctionalities() {
  const { selectedDomain: id } = useTenant();
  const { fetchData } = useFetch();

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ['callFunctionalities', id],
    queryFn: (): Promise<CallFunctionalities> =>
      fetchData('/domain/get-call-functionalities', { queryParams: { id } }),
    enabled: id !== null,
  });

  return { callFunctionalities: data, isLoading };
}

export function useUpdateLocation() {
  const queryClient = useQueryClient();
  const { selectedDomain: domain } = useTenant();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync } = useMutation({
    mutationFn: (location: {
      aen_identifier: string;
      name: string;
      destination: string;
      offsitesRequest: Offsite[];
      offsitesUpdate: Offsite[];
      offsitesDelete: number[];
    }) =>
      fetchData('/domain/request-implementation-change', {
        method: 'POST',
        queryParams: { domain },
        bodyParams: location,
      }),

    onSuccess: data => {
      if (data.message) addSuccessMessage(data.message);
      queryClient.invalidateQueries({ queryKey: ['locations'] });
      queryClient.invalidateQueries({ queryKey: ['pendingChanges'] });
      queryClient.invalidateQueries({ queryKey: ['changeRequests'] });
    },
  });

  return { updateLocation: mutateAsync };
}

export function useOffsites(params: Record<string, unknown> = {}) {
  const { fetchData } = useFetch();
  const { selectedDomain: domain } = useTenant();

  const { data: response, isFetching } = useQuery({
    queryKey: ['offsites', domain, params],
    queryFn: (): Promise<{ data: Offsite[]; headers: Headers }> =>
      fetchData('/domain/offsite-numbers', {
        queryParams: { domain, ...params },
        includeHeaders: true,
      }),
    enabled: domain !== null,
  });

  return {
    offsites: response?.data,
    isLoading: isFetching,
    pageCount: Number(response?.headers.get('x-pagination-page-count') ?? 1),
    itemCount: Number(response?.headers.get('x-pagination-total-count') ?? 0),
  };
}

export function useExportOffsites() {
  const { selectedDomain: domain } = useTenant();
  const { getFetchConfig } = useFetch();
  const dateFormat = useUserDateFormat();

  const { query, config } = getFetchConfig('/domain/export-offsite-numbers', {
    queryParams: { domain },
  });

  const { mutateAsync, isPending } = useMutation({
    mutationFn: () => fetch(query, config),
    onSuccess: async response => {
      const data = await response.blob();
      const date = moment().format(dateFormat);
      fileDownload(data, 'offsite_numbers_' + date + '.csv', 'text/csv');
    },
  });

  return { exportOffsites: mutateAsync, isExporting: isPending };
}

export function useUpdateOffsites() {
  const queryClient = useQueryClient();
  const { selectedDomain: domain } = useTenant();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync } = useMutation({
    mutationFn: (changes: { request: Offsite[]; update: Offsite[]; delete: number[] }) =>
      fetchData('/domain/update-offsite-numbers', {
        method: 'POST',
        queryParams: { domain },
        bodyParams: {
          ...changes,
          request: changes.request.map(o => ({
            ...o,
            net_number: String(o.country) + String(o.area),
          })),
        },
      }),
    onSuccess: data => {
      if (data.message) addSuccessMessage(data.message);
      queryClient.invalidateQueries({ queryKey: ['offsites'] });
      queryClient.invalidateQueries({ queryKey: ['pendingChanges'] });
      queryClient.invalidateQueries({ queryKey: ['changeRequests'] });
    },
  });

  return { updateOffsites: mutateAsync };
}

export function useUpdateTrackedSources() {
  const queryClient = useQueryClient();
  const setQueryData = useSetQueryData();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();
  const translateText = useTranslate();

  const { mutateAsync } = useMutation({
    mutationFn: ({ included, sources }: { included: 0 | 1; sources: string[] }) =>
      fetchData('/domain/change-sources', {
        method: 'POST',
        queryParams: { domain: selectedDomain },
        bodyParams: { included: included, sources: sources },
      }),
    onSuccess: data => {
      setQueryData<Record<string, unknown>>(['pendingChanges', selectedDomain, ''], oldData => ({
        ...oldData,
        sourcesChange: {
          included: data.data.wanted_changes.included,
          sources: data.data.wanted_changes.sources,
          id: data.data.id,
        },
      }));
      queryClient.invalidateQueries({ queryKey: ['changeRequests'] });
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { updateTrackedSources: mutateAsync };
}

export function useUpdateLocationFormats() {
  const setQueryData = useSetQueryData();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();
  const translateText = useTranslate();

  const { mutateAsync } = useMutation({
    mutationFn: (formatsPerLocation: FormatsPerLocation) =>
      fetchData('/domain/site-formats', {
        method: 'PUT',
        queryParams: { id: selectedDomain },
        bodyParams: { formats: formatsPerLocation },
      }),
    onSuccess: (data, formatsPerLocation) => {
      setQueryData<Implementation>(['implementation', selectedDomain], oldData => ({
        ...oldData,
        aens: formatsPerLocation,
      }));
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { updateLocationFormats: mutateAsync };
}

export function useIpFilters() {
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { selectedDomain } = useTenant();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { data: ipFilters, isFetching: isLoading } = useQuery({
    queryKey: ['ipFilters', selectedDomain],
    queryFn: (): Promise<Ip[]> =>
      fetchData('/domain/blacklisted-ips', { queryParams: { domain: selectedDomain } }),
  });

  const { mutateAsync } = useMutation({
    mutationFn: ({ filters }: { filters: Ip[] }) =>
      fetchData('/domain/update-ip-filter', {
        method: 'POST',
        queryParams: { domain: selectedDomain },
        bodyParams: { list: filters },
      }),
    meta: { noError: true },
    onSuccess: (data, variables) => {
      setQueryData(['ipFilters', selectedDomain], () => variables.filters);
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { ipFilters, isLoading, updateIpFilters: mutateAsync };
}

export function useProperties() {
  const { selectedDomain: id } = useTenant();
  const { fetchData } = useFetch();

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ['domainView', id],
    queryFn: (): Promise<DomainProperties> =>
      fetchData('/domain/view', {
        queryParams: {
          id,
          fields: ['number_distribution', 'start_date', 'title', 'url'].join(),
          expand: [
            'account_manager',
            'invoice_label',
            'number_format',
            'pricing_plan_id',
            'stop_requested',
            'contract_start_date',
            'contract_end_date',
            'contract_duration',
            'upgrade_request_id',
          ].join(),
        },
      }),
    enabled: id !== null,
  });

  if (data !== undefined && /^[0-9]*$/.test(String(data.number_format ?? ''))) {
    data.number_format = Number(data.number_format);
  } else if (data !== undefined && typeof data.number_format === 'string') {
    data.number_format = data.number_format.replace('{nat}', '31').replace('{area}', '20');
  }

  return { data, isLoading };
}

export function useStopDomain() {
  const queryClient = useQueryClient();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();
  const { fetchData } = useFetch();

  const { mutateAsync } = useMutation({
    mutationFn: (values: { reason: string; explanation: string; rating: number }) =>
      fetchData('/domain/request-stop', {
        method: 'POST',
        queryParams: { domain_id: selectedDomain },
        bodyParams: {
          reason_selected: values.reason,
          reason_explained: values.explanation,
          star_rating: values.rating,
        },
      }),
    onSuccess: message => {
      queryClient.invalidateQueries({ queryKey: ['domainView', selectedDomain] });
      queryClient.invalidateQueries({ queryKey: ['domainAuth', selectedDomain] });
      addSuccessMessage(message);
    },
  });

  return { stopDomain: mutateAsync };
}

export function useLeaveMcc() {
  const queryClient = useQueryClient();
  const setTableData = useSetTableData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const { mutate } = useMutation({
    mutationFn: (mcc: { id: string; name: string }) =>
      fetchData('/domain/leave-mcc', {
        method: 'DELETE',
        queryParams: { mcc: mcc.id, domain: selectedDomain },
      }),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['userMcc'] });
      queryClient.invalidateQueries({ queryKey: ['domains'] });
      setTableData(['domain', 'mcc', selectedDomain], oldData =>
        oldData.filter(mcc => mcc.id !== variables.id),
      );
      addSuccessMessage(
        translateText(
          'message',
          'Access has been successfully revoked from the customer center <span data-private>{name}</span>.',
          { name: variables.name },
        ),
      );
    },
  });

  return {
    revokeMcc: (mcc: { id: string; name: string }) => {
      if (
        window.confirm(
          translateText(
            'text',
            'Are you sure you want to revoke access from the customer center {name}?',
            { name: mcc.name },
          ),
        )
      ) {
        mutate(mcc);
      }
    },
  };
}

export function useMultiUpdateDomain() {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const { addSuccessMessage } = useMessages();
  const { fetchData } = useFetch();

  const { mutateAsync } = useMutation({
    mutationFn: (models: Record<string, unknown>[]) =>
      fetchData('/domain/multi-update', {
        method: 'PUT',
        bodyParams: { models },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['domains'] });
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { domainMultiUpdate: mutateAsync };
}

export function useAdminDomainDetails(id: number | null) {
  const { fetchData } = useFetch();
  const domainData = useTableData<AdminDomain>(['domains', 'admin']);

  const { data, isFetching, isError } = useQuery({
    queryKey: ['adminDomain', id],
    queryFn: (): Promise<AdminDomain> =>
      fetchData('/domain/' + id, { queryParams: { expand: ADMIN_DOMAIN_EXPAND } }),
    enabled: id !== null,
    meta: { noError: true },
    initialData: () => domainData?.find(d => d.id === id),
  });

  return { domain: data, isLoading: isFetching, isError };
}

export function useUpdateDomain(id: number) {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const setTableData = useSetTableData();
  const { addSuccessMessage } = useMessages();
  const { fetchData } = useFetch();

  const { mutateAsync } = useMutation({
    mutationFn: (values: {
      url: string;
      title: string;
      reseller: string;
      isActive: boolean;
      status: string;
      leadRating: boolean;
      callRecording: boolean;
      accountManager: string;
    }) =>
      fetchData('/domain/update', {
        method: 'PUT',
        queryParams: { id, expand: ADMIN_DOMAIN_EXPAND },
        bodyParams: {
          url: values.url,
          title: values.title,
          reseller_id: values.reseller,
          is_active: values.isActive,
          status: values.status,
          is_leadrating_enabled: values.leadRating,
          is_callrecording_enabled: values.callRecording,
          account_manager: values.accountManager,
        },
      }),
    onSuccess: data => {
      queryClient.setQueryData(['adminDomain', id], () => data);
      setTableData(['domains', 'admin'], oldData => oldData.map(i => (i?.id === id ? data : i)));
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { updateDomain: mutateAsync };
}

export function useUpdateDebtor(id: number) {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const setTableData = useSetTableData();
  const { addSuccessMessage } = useMessages();
  const { fetchData } = useFetch();

  const { mutateAsync } = useMutation({
    mutationFn: (values: { customer: string; trialEndDate: string }) =>
      fetchData('/domain/update-debtor', {
        method: 'PUT',
        queryParams: {
          domainId: id,
          chargebeeCustomerId: values.customer,
          trialEndDate: values.trialEndDate,
          expand: ADMIN_DOMAIN_EXPAND,
        },
      }),
    onSuccess: (_, variables) => {
      const updateDebtor = (oldData: AdminDomain): AdminDomain => ({
        ...oldData,
        chargebee_customer_id: variables.customer,
        is_trial: !!variables.trialEndDate,
        trial_end_date: variables.trialEndDate || null,
        trial_month: variables.trialEndDate || false,
      });
      queryClient.setQueryData<AdminDomain>(['adminDomain', id], oldData =>
        oldData ? updateDebtor(oldData) : undefined,
      );
      setTableData<AdminDomain>(['domains', 'admin'], oldData =>
        oldData.map(i => (i?.id === id ? updateDebtor(i) : i)),
      );
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { updateDebtor: mutateAsync };
}

export function useScheduleDeletion() {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutateAsync } = useMutation({
    mutationFn: ({ id, date }: { id: number; date: Moment }) =>
      fetchData('/domain/schedule-delete', {
        method: 'POST',
        queryParams: { id },
        bodyParams: { date: date.format('YYYY-MM-DD HH:mm') },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['pendingChanges'] });
      queryClient.invalidateQueries({ queryKey: ['changeRequests'] });
      addSuccessMessage(
        translateText('message', 'The deletion of this domain has been scheduled.'),
      );
    },
  });

  return { scheduleDeletion: mutateAsync };
}

export function useUpgradeTrial() {
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain, selectedDomainData } = useTenant();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: () =>
      fetchData('/domain/trial-to-basic', {
        method: 'PUT',
        queryParams: { domainId: selectedDomain },
      }),
    onSuccess: () => {
      setQueryData<DomainAuth>(['domainAuth', selectedDomain], oldData => ({
        ...oldData,
        is_trial: false,
        trial_month: false,
      }));
      addSuccessMessage(
        translateText(
          'message',
          'The domain <span data-private>{domain}</span> has been upgraded to the Optimize package. Do you have any questions? Let us know via support@adcalls.nl.',
          { domain: selectedDomainData?.name },
        ),
      );
    },
  });
  return { upgradeTrial: mutateAsync, isUpgrading: isPending };
}

export function useCanRequestDomain() {
  const { fetchData } = useFetch();

  const { mutateAsync } = useMutation({
    mutationFn: (value: string) =>
      fetchData('/domain/can-request', {
        method: 'GET',
        queryParams: { url: value },
      }),
    meta: { noError: true },
  });

  return { checkCanRequest: mutateAsync };
}

export function useKeystrokeMenu(enabled = true) {
  const { fetchData } = useFetch();
  const { selectedDomain } = useTenant();

  const { data, isFetching } = useQuery({
    queryKey: ['keystrokeMenu', selectedDomain],
    queryFn: (): Promise<string[]> =>
      fetchData('/domain/keystroke-menu', {
        queryParams: { id: selectedDomain },
      }),
    enabled: selectedDomain !== null && enabled,
  });

  return { keystrokeMenu: data, isLoading: isFetching };
}

export function useRemoveExcludeFromUpgrade() {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();

  const { mutate, isPending } = useMutation({
    mutationFn: ({ id }: { id: number; domain: string }) =>
      fetchData('/domain/remove-exclude-from-upgrade', {
        method: 'DELETE',
        queryParams: { id },
      }),
    onSuccess: (_, vars) => {
      queryClient.invalidateQueries({ queryKey: ['admin', 'upgrade'] });
      addSuccessMessage(
        translateText(
          'message',
          'The domain <span data-private>{domain}</span> (id: {id}) is not excluded anymore.',
          { domain: vars.domain, id: vars.id },
        ),
      );
    },
  });

  const removeExcludeFromUpgrade = ({ id, domain }: { id: number; domain: string }) => {
    if (
      window.confirm(
        translateText(
          'message',
          'Are you sure you want to include {domain} (id: {id}) in the upgrade?',
          { domain, id },
        ),
      )
    ) {
      mutate({ id, domain });
    }
  };

  return { removeExcludeFromUpgrade, isLoading: isPending };
}

export function useEditSubscriptionPrerequisiteData(type: EditSubscriptionType) {
  const { selectedDomain: id } = useTenant();
  const { fetchData } = useFetch();

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ['editSubscription', 'prerequisiteData', type, id],
    queryFn: (): Promise<{
      upgrade_request_id: number | null;
      billing_info: {
        email: string;
        company: string;
        customer_name: string;
        reference: string;
        address: string;
        zip_code_and_city: string;
        country: string;
      };
      requested_by_us: boolean;
      upgrade_processed: boolean;
      contract_end_date: string | null;
      available_start_dates: string[] | null;
      available_pricing_plans: string[] | null;
    }> => fetchData('/domain/prerequisite-data-upgrade-request', { queryParams: { id, type } }),
    enabled: id !== null,
  });

  return { data, isLoading };
}

export function useEditSubscriptionPricingIndication(
  type: EditSubscriptionType,
  contractDuration: string | null,
  pricingPlan: string | null,
) {
  const { selectedDomain: id } = useTenant();
  const { fetchData } = useFetch();

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ['upgrade', 'pricingIndication', id, contractDuration, pricingPlan],
    queryFn: (): Promise<{
      usage: Record<string, PricingIndicationRow>;
      discounts: Record<string, PricingIndicationRow>;
      total: number;
      invest: number;
    }> =>
      fetchData('/domain/pricing-plan-upgrade-price-indication', {
        method: 'POST',
        queryParams: { id, type },
        bodyParams: { pricing_plan_id: pricingPlan, contract_duration: contractDuration },
      }),
    enabled: id !== null && pricingPlan !== null && contractDuration !== null,
  });

  return { data, isLoading };
}

export function useEditSubscription(type: EditSubscriptionType) {
  const queryClient = useQueryClient();
  const { fetchData } = useFetch();
  const { selectedDomain: id } = useTenant();

  const { mutateAsync } = useMutation({
    mutationFn: (values: EditSubscriptionFormValues) =>
      fetchData(
        `/domain/${
          type === EditSubscriptionType.Extend
            ? 'request-contract-extension'
            : 'request-pricing-plan-upgrade'
        }`,
        {
          method: 'POST',
          queryParams: { id },
          bodyParams: {
            pricing_plan_id: values.pricingPlanId,
            contract_duration: values.contractDuration,
            starting_date: values.startDate,
          },
        },
      ),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['domainAuth', id] }),
  });

  return { editSubscription: mutateAsync };
}

export function useAddFormat() {
  const translateText = useTranslate();
  const queryClient = useQueryClient();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain: id } = useTenant();

  const { mutateAsync } = useMutation({
    mutationFn: ({ format, location }: { format: string; location: string }) =>
      fetchData('/domain/add-format', {
        method: 'POST',
        queryParams: { id, aen_identifier: location },
        bodyParams: { format },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['implementation', id] });
      addSuccessMessage(translateText('message', 'Format added'));
    },
  });

  return { addFormat: mutateAsync };
}
