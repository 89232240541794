import { useFormikContext } from 'formik';
import { isEmpty, pick, zipObject } from 'lodash';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { focusErrorElement } from 'components/ErrorFocus';
import Select from 'components/input/Select';
import TextField from 'components/input/TextField';
import Skeleton from 'components/Skeleton';
import Tooltip from 'components/Tooltip';
import { CONTRACT_DURATIONS, PH_MASK } from 'globals/constants';
import { pricingPlanCategoryHasChanged } from 'globals/helpers';
import { usePrevious } from 'hooks/effect';
import { usePricingPlans } from 'hooks/queries/pricing';
import { useUserMcc } from 'hooks/queries/user';
import { useTranslate } from 'hooks/translate';
import { IntakeFormValues, IntakeSection } from 'pages/authenticated/requests/Intake';
import InvoiceProfile from './InvoiceProfile';
import PricingPlanInfo from './PricingPlanInfo';
import StepBlock from './StepBlock';

export default function PropertiesSection() {
  const translateText = useTranslate();
  const { pricingPlans, isLoading } = usePricingPlans();
  const { mcc } = useUserMcc();
  const { values, handleChange, setFieldValue, setValues, errors } =
    useFormikContext<IntakeFormValues>();

  const isValidated = typeof values.validated[IntakeSection.Properties] === 'boolean';
  const isConceptValidated = values.validated[IntakeSection.Properties] === 'concept';

  // URL, pricingPlanId and contractDuration are also validated when saving a concept.
  // To make sure the errors are correctly focused the first time the concept is validated,
  // we need to listen to isConceptValidated changing to true and having at least one error
  // for those properties.
  const conceptErrors = isConceptValidated // eslint-disable-line react-hooks/exhaustive-deps
    ? pick(errors, ['url', 'pricingPlanId', 'contract_duration'])
    : {};
  const prevConceptErrors = usePrevious(conceptErrors);

  useDeepCompareEffect(() => {
    if (!isEmpty(conceptErrors) && isEmpty(prevConceptErrors)) focusErrorElement();
  }, [conceptErrors, prevConceptErrors]);

  function updateUrl(url: string, scheme: 'http://' | 'https://') {
    if (url.startsWith('http://')) {
      scheme = 'http://';
      url = url.replace(scheme, '');
    } else if (url.startsWith('https://')) {
      scheme = 'https://';
      url = url.replace(scheme, '');
    }
    setValues({ ...values, url, urlScheme: scheme });
  }

  const durations = CONTRACT_DURATIONS[values.pricingPlanId] ?? [];
  const durationOptions = zipObject(
    durations,
    durations.map(m => translateText('datetime', '{months} months', { months: m })),
  );

  const resellers = mcc?.filter(mcc => mcc.id !== -1) ?? [];
  return (
    <div className="properties-section">
      <StepBlock number={1} title={translateText('label', 'Properties')}>
        <div className="white-block">
          {resellers.length >= 1 && (
            <div className="form-row">
              <div className="name-label required">
                <span>{translateText('label', 'Customer center')}</span>
                <Tooltip
                  text={translateText(
                    'tooltip/intake',
                    'Select the customer center this domain needs to be added to.',
                  )}
                />
              </div>
              <div className="value-wrapper">
                <Select
                  name="reseller_id"
                  value={values.reseller_id}
                  onChange={handleChange}
                  options={resellers}
                  optionValue="id"
                  optionText="name"
                  error={isValidated && errors.reseller_id}
                  addSelect
                  selectText={translateText('label', 'Select customer center')}
                  canEdit={resellers.length > 1}
                  className={PH_MASK}
                />
              </div>
            </div>
          )}
          <div className="form-row">
            <div className="name-label required">
              <span>{translateText('label', 'Domain URL')}</span>
              <Tooltip
                text={translateText(
                  'tooltip/intake',
                  "Enter the URL of the website for which you wish to use AdCalls. We recommend copying the address from your browser to make sure you don't make any typos.",
                )}
              />
            </div>
            <div className="value-wrapper">
              <div className="value-col-1">
                <Select
                  name="urlScheme"
                  value={values.urlScheme}
                  options={['https://', 'http://']}
                  onChange={handleChange}
                />
              </div>
              <div className="value-col-2">
                <TextField
                  value={values.url}
                  onChange={e => updateUrl(e.target.value, values.urlScheme)}
                  error={(isConceptValidated || isValidated) && errors.url}
                />
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="name-label required">
              <span>{translateText('label', 'Pricing plan')}</span>
              <Tooltip text={translateText('tooltip/intake', 'Select the desired pricing plan.')} />
            </div>
            <div className="value-wrapper">
              {isLoading ? (
                <Skeleton height={38} />
              ) : (
                <Select
                  name="pricingPlanId"
                  value={values.pricingPlanId}
                  onChange={e => {
                    if (
                      pricingPlanCategoryHasChanged(
                        values.pricingPlanId !== 'select' ? values.pricingPlanId : null,
                        e.target.value,
                      )
                    ) {
                      setFieldValue('contract_duration', 'select');
                    }
                    setFieldValue('pricingPlanId', e.target.value);
                  }}
                  options={pricingPlans ?? {}}
                  optionValue="id"
                  optionText="invoice_label"
                  error={(isConceptValidated || isValidated) && errors.pricingPlanId}
                  addSelect
                  selectText={translateText('label', 'Select a pricing plan')}
                />
              )}
            </div>
          </div>
          <div className="form-row">
            <div className="name-label required">
              <span>{translateText('label', 'Contract duration')}</span>
              <Tooltip
                text={translateText('tooltip/intake', 'Select the desired contract duration.')}
              />
            </div>
            <div className="value-wrapper">
              <Select
                name="contract_duration"
                value={values.contract_duration}
                onChange={handleChange}
                options={durationOptions}
                error={
                  (isConceptValidated || isValidated) &&
                  values.pricingPlanId !== 'select' &&
                  errors.contract_duration
                }
                addSelect
                selectText={translateText('label', 'Select a contract duration')}
                disabled={values.pricingPlanId === 'select'}
              />
            </div>
          </div>
          <div className="form-row info-button">
            <PricingPlanInfo />
          </div>
        </div>
      </StepBlock>
      <StepBlock number={2} title={translateText('label', 'Invoicing')}>
        <InvoiceProfile />
      </StepBlock>
    </div>
  );
}
