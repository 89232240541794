import { CSSProperties, useCallback, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { Message, MessageType, useMessages } from 'contexts/Messages';
import { useEffectOnce, usePrevious, useUpdateEffect } from 'hooks/effect';
import { useTranslate } from 'hooks/translate';

type Props = {
  type: MessageType;
};

export default function MessageBar({ type }: Props) {
  const location = useLocation();
  const translateText = useTranslate();
  const transitionRef = useRef(null);
  const [prevMessage, setPrevMessage] = useState<Message | null>(null);
  const [timerClassName, setTimerClassName] = useState('');

  const { toasts, permanents, nextMessage, clearMessages } = useMessages();
  const messages = type === MessageType.Toast ? toasts : permanents;
  const prevMessages = usePrevious(messages);

  const nextTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const clearTimer = useCallback(() => {
    if (nextTimer.current) {
      clearTimeout(nextTimer.current);
      nextTimer.current = null;
    }
  }, []);

  const next = useCallback(() => {
    clearTimer();
    setPrevMessage(messages?.[0]);
    setTimerClassName('reset'); // next message is handled in useEffect
  }, [clearTimer, messages, setPrevMessage, setTimerClassName]);

  const setTimer = useCallback(() => {
    const message = messages?.[0];
    const duration = message?.duration ?? 0;
    if (message === undefined || duration <= 0) return;
    nextTimer.current = setTimeout(() => next(), duration * 1000);
  }, [messages, next]);

  const clear = () => {
    clearTimer();
    setPrevMessage(messages?.[0]);
    clearMessages(type);
  };

  useEffectOnce(() => {
    if (messages.length > 0) setTimer();
    return () => clearTimer();
  });

  useUpdateEffect(() => {
    if (messages.length !== 0 && prevMessages?.length === 0) {
      clearTimer();
      setPrevMessage(null);
      setTimerClassName('');
      setTimer();
    }
  }, [clearTimer, messages, prevMessages?.length, setTimer]);

  useUpdateEffect(() => {
    if (timerClassName === 'reset' && nextTimer.current === null) {
      nextMessage(type);
      setTimeout(() => setTimerClassName(''), 1); // Timeout needed because otherwise the reset is too short
    } else if (timerClassName === '' && nextTimer.current === null) {
      setTimer();
    }
  }, [timerClassName, type]); // eslint-disable-line react-hooks/exhaustive-deps

  let message = messages?.[0];
  let count = messages?.length;
  if (message === undefined && prevMessage) {
    message = prevMessage;
    count += 1;
  }

  if (location.pathname.startsWith('/data-processor-agreement')) return null;

  return (
    <CSSTransition
      classNames="message-bar"
      nodeRef={transitionRef}
      in={messages?.[0] !== undefined}
      timeout={300}
      unmountOnExit
    >
      {message ? (
        <div className={`message-bar ${message.className}`} ref={transitionRef}>
          <div className="message">
            <div className="left" />
            <div className="middle">
              {message.icon && <img alt={message.className} src={message.icon} />}
              <div dangerouslySetInnerHTML={{ __html: message.message }} />
              {message.link && (
                <u onClick={() => next()}>
                  <Link to={message.link.url}>{message.link.text}</Link>
                </u>
              )}
            </div>
            <div className="right">
              {count > 1 && (
                <div
                  className="next"
                  onClick={e => {
                    e.stopPropagation();
                    next();
                  }}
                >
                  <div className="bold">{translateText('label', 'Next')}</div>
                  <div className="counter">({count})</div>
                </div>
              )}
              <div
                className="clear bold"
                onClick={e => {
                  e.stopPropagation();
                  clear();
                }}
              >
                {translateText('label', 'Close')}
              </div>
            </div>
          </div>
          <div
            className={'message-timer ' + (messages?.[0] !== undefined ? timerClassName : '')} // Reset is not needed when there is no new message
            data-testid="message-timer"
            style={{ '--duration': message.duration } as CSSProperties}
          />
        </div>
      ) : (
        <div className="message-bar" />
      )}
    </CSSTransition>
  );
}
