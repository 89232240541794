import { isValidElement, ReactElement, ReactNode } from 'react';
import { isEmpty } from 'lodash';

import Skeleton from 'components/Skeleton';
import Tooltip from 'components/Tooltip';
import { PH_MASK } from 'globals/constants';
import { useTranslate } from 'hooks/translate';

type BaseProps = {
  title?: string | { text: string; private: true };
  intro?: string;
  className?: string;
  tooltip?: string;
  isLoading?: boolean;
  boldKeys?: true;
};

type Value = string | undefined | number | null;

type PropsWithData = {
  data: Record<
    string,
    | Value
    | { value: Value | ReactElement | Record<string, Value>; tooltip?: string; private?: boolean }
  >;
  children?: never;
  button?: { text: string; onClick: () => void; disabled?: boolean; tooltip?: string };
} & BaseProps;

type PropsWithCustomContent = {
  data?: never;
  children: ReactNode;
  button?: never;
} & BaseProps;

export default function DetailsSection({
  className,
  title,
  intro,
  tooltip,
  data,
  children,
  isLoading,
  boldKeys,
  button,
}: PropsWithData | PropsWithCustomContent) {
  const translateText = useTranslate();

  function renderData() {
    if (children) return children;
    if (isEmpty(data) && !isLoading) {
      return <div className="no-data">{translateText('label', 'No data')}</div>;
    }
    const rows: ReactElement[] = [];
    Object.entries(data!).forEach(([key, value], index) => {
      const displayValue = typeof value === 'object' ? value?.value : value;
      const hasSubEntries =
        displayValue && typeof displayValue === 'object' && !isValidElement(displayValue);

      rows.push(
        <tr key={index}>
          <td className={boldKeys && 'bold'}>
            <span>
              {isLoading ? <Skeleton width={120} /> : key}
              {!isLoading && typeof value === 'object' && value?.tooltip && (
                <Tooltip text={value.tooltip} />
              )}
            </span>
          </td>
          <td className={typeof value === 'object' && value?.private ? PH_MASK : ''}>
            {!hasSubEntries && (isLoading ? <Skeleton width={120} /> : <span>{displayValue}</span>)}
          </td>
        </tr>,
      );
      if (hasSubEntries) {
        Object.entries(displayValue).forEach(([key, value]) => {
          rows.push(
            <tr key={index + '-' + key} className="sub-entry">
              {isLoading ? (
                <td>
                  <Skeleton width={120} />
                </td>
              ) : (
                <td className={boldKeys && 'bold'} dangerouslySetInnerHTML={{ __html: key }} />
              )}
              <td>
                <span>{isLoading ? <Skeleton width={120} /> : value}</span>
              </td>
            </tr>,
          );
        });
      }
    });
    if (button) {
      const buttonElement = (
        <button
          className="btn btn-text no-padding"
          onClick={button.onClick}
          disabled={button.disabled}
        >
          {button.text}
        </button>
      );
      rows.push(
        <tr key="button-row">
          <td colSpan={2}>
            {isLoading ? (
              <Skeleton width={120} />
            ) : button.tooltip ? (
              <Tooltip html={buttonElement} text={button.tooltip} />
            ) : (
              buttonElement
            )}
          </td>
        </tr>,
      );
    }
    return (
      <table className="table key-value-table">
        <tbody>{rows}</tbody>
      </table>
    );
  }

  const titleText = typeof title === 'string' ? title : title?.text;
  return (
    <div className={'details-section white-block' + (className ? ' ' + className : '')}>
      {!isLoading && tooltip && (
        <div className="tooltip-i">
          <Tooltip text={tooltip} />
        </div>
      )}
      {titleText && (
        <h3 className={typeof title !== 'string' && title?.private ? PH_MASK : ''}>
          {isLoading ? <Skeleton width={titleText.length * 10} /> : titleText}
        </h3>
      )}
      {intro &&
        (isLoading ? (
          <p>
            <Skeleton count={2} />
          </p>
        ) : (
          <p dangerouslySetInnerHTML={{ __html: intro }} />
        ))}
      {renderData()}
    </div>
  );
}
