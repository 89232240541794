import { useIsMutating } from '@tanstack/react-query';
import { useFormikContext } from 'formik';
import { isEqual } from 'lodash';

import CreateDeleteTable from 'components/CreateDeleteTable';
import Select from 'components/input/Select';
import TextField from 'components/input/TextField';
import InputWrapper from 'components/slide-in/InputWrapper';
import { useTranslate } from 'hooks/translate';

export type HookValues = {
  id?: number;
  method: 'POST' | 'PUT';
  address: string;
  format: 0 | 1;
  trigger: 0 | 1 | 3 | 4 | 5;
  authType: 0 | 1;
  authToken: string;
  headers: { key: string; value: string }[];
};

export type WebhookFormValues = HookValues & {
  isTest: boolean;
  isTested: boolean;
  headerKey: string;
  headerValue: string;
};

export const initHookValues: HookValues = {
  method: 'POST',
  address: '',
  format: 0,
  trigger: 0,
  authType: 0,
  authToken: '',
  headers: [],
};

type Props = {
  addHeader: () => void;
};

export default function WebhookForm({ addHeader }: Props) {
  const translateText = useTranslate();
  const { values, handleChange, setFieldValue, submitForm, submitCount, errors } =
    useFormikContext<WebhookFormValues>();

  const mutating = useIsMutating({ mutationKey: ['hook'] });
  const disabled = mutating > 0;

  return (
    <div className="api-test-form">
      <p>
        {translateText(
          'integration',
          'With the form below you can send data of a call (request) to an endpoint, first we send a request to test the connection.',
        )}
      </p>
      <div className="input-section">
        <InputWrapper
          label={translateText('label', 'HTTP method')}
          tooltip={translateText('tooltip/api', 'Select the HTTP method the endpoint accepts.')}
        >
          <Select
            name="method"
            value={values.method}
            onChange={handleChange}
            options={['POST', 'PUT']}
            disabled={disabled}
          />
        </InputWrapper>
        <InputWrapper
          label={translateText('label', 'Address')}
          tooltip={translateText('tooltip/api', 'Enter the address of the desired endpoint.')}
        >
          <TextField
            name="address"
            value={values.address}
            onChange={e => setFieldValue('address', e.target.value, true)}
            disabled={disabled}
            error={submitCount > 0 && errors.address}
          />
        </InputWrapper>
        <InputWrapper
          label={translateText('label', 'Format')}
          tooltip={translateText('tooltip/api', 'Select the data format the endpoint accepts.')}
        >
          <Select
            value={values.format}
            onChange={e => setFieldValue('format', Number(e.target.value))}
            options={{ 0: 'JSON', 1: 'XML' }}
            disabled={disabled}
          />
        </InputWrapper>
        <InputWrapper
          label={translateText('label', 'Trigger')}
          tooltip={translateText('tooltip/api', 'Select when you want the data to be sent.')}
        >
          <Select
            value={values.trigger}
            onChange={e => setFieldValue('trigger', Number(e.target.value))}
            options={{
              0: translateText('label', 'Before the call'),
              1: translateText('label', 'After the call'),
              3: translateText('label', 'After the call has been evaluated in the dashboard'),
              4: translateText('label', 'After the call request'),
              5: translateText(
                'label',
                'After the call request has been evaluated in the dashboard',
              ),
            }}
            disabled={disabled}
          />
        </InputWrapper>
        <InputWrapper
          label={translateText('label', 'Authentication type')}
          tooltip={translateText(
            'tooltip/api',
            "In case the endpoint requires authentication, select 'Token' and enter the token in the field below",
          )}
        >
          <Select
            name="authType"
            value={values.authType}
            onChange={e => setFieldValue('authType', Number(e.target.value))}
            options={{ 0: translateText('label', 'None'), 1: translateText('label', 'Token') }}
            disabled={disabled}
          />
        </InputWrapper>
        {!!values.authType && (
          <InputWrapper label={translateText('label', 'Token')}>
            <TextField
              name="authToken"
              value={values.authToken}
              onChange={e => setFieldValue('authToken', e.target.value, true)}
              disabled={disabled}
              error={submitCount > 0 && errors.authToken}
            />
          </InputWrapper>
        )}
        <div className="input-section">
          <InputWrapper
            label={translateText('label', 'Extra HTTP headers')}
            tooltip={translateText(
              'integration',
              'Here you can set extra headers in case your endpoint requires them.',
            )}
          >
            <CreateDeleteTable
              dataKeys={['key', 'value']}
              privateKeys={['key', 'value']}
              items={values.headers}
              onAdd={addHeader}
              onDelete={item =>
                setFieldValue(
                  'headers',
                  values.headers.filter(i => !isEqual(i, item)),
                )
              }
              addButtonDisabled={disabled}
            />
          </InputWrapper>
          <div className="buttons">
            <button
              className="btn btn-lightblue test-webhook"
              disabled={disabled}
              onClick={() => {
                setFieldValue('isTest', true, false);
                submitForm();
              }}
            >
              {translateText('label', 'Test webhook')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
