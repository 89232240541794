import { useEffect } from 'react';
import { Route, Routes } from 'react-router';

import { useGlobal } from 'contexts/Global';
import { TYPE_FORM_TRACKING } from 'globals/constants';
import { ServiceType } from 'globals/types';
import DaysAndTimes from 'pages/authenticated/call-tracking/reports/DaysAndTimes';
import Evaluations from 'pages/authenticated/call-tracking/reports/Evaluations';
import CallTrackingLocations from 'pages/authenticated/call-tracking/reports/Locations';
import CallTrackingOverview from 'pages/authenticated/call-tracking/reports/Overview';
import Portals from 'pages/authenticated/call-tracking/reports/Portals';
import Sources from 'pages/authenticated/call-tracking/reports/Sources';
import FormTrackingLocations from 'pages/authenticated/form-tracking/reports/Locations';
import FormTrackingOverview from 'pages/authenticated/form-tracking/reports/Overview';
import Profiles from 'pages/authenticated/form-tracking/reports/Profiles';
import Error404 from 'pages/Error404';

type Props = {
  type: ServiceType;
};

export default function Reports({ type }: Props) {
  const { setShowReportsFilter } = useGlobal();
  useEffect(() => {
    setShowReportsFilter(type);
    return () => setShowReportsFilter(false);
  }, [type, setShowReportsFilter]);

  let routes = (
    <>
      <Route path="/overview" element={<CallTrackingOverview />} />
      <Route path="/overview/sources" element={<Sources />} />
      <Route path="/locations" element={<CallTrackingLocations />} />
      <Route path="/portals" element={<Portals />} />
      <Route path="/days-and-times" element={<DaysAndTimes />} />
      <Route path="/evaluations" element={<Evaluations />} />
    </>
  );
  if (type === TYPE_FORM_TRACKING) {
    routes = (
      <>
        <Route path="/overview" element={<FormTrackingOverview />} />
        <Route path="/locations" element={<FormTrackingLocations />} />
        <Route path="/profiles" element={<Profiles />} />
      </>
    );
  }

  return (
    <Routes>
      {routes}
      <Route path="/*" element={<Error404 />} />
    </Routes>
  );
}
