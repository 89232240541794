import iconRelay42 from 'assets/images/icons/integrations/relay-42.svg';
import IntegrationRequest from 'components/integrations/IntegrationRequest';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import { INTEGRATION_RELAY42 } from 'globals/constants';
import { SUP_ART_RELAY42 } from 'globals/support';
import { useHasAccessToIntegration } from 'hooks/access';
import { useTranslate } from 'hooks/translate';

export default function Relay42({ show, close }: BaseSlideInProps) {
  const translateText = useTranslate();
  const hasAccessToIntegration = useHasAccessToIntegration();

  if (!hasAccessToIntegration(INTEGRATION_RELAY42)) return null;

  return (
    <IntegrationRequest
      show={show}
      title={translateText('label', 'Relay42')}
      icon={iconRelay42}
      infoText={translateText(
        'integration',
        'Relay42 is a data and tag management platform for compiling online customer profiles which can be used to carry out marketing actions.',
      )}
      articleId={SUP_ART_RELAY42}
      close={close}
      price={49}
    />
  );
}
