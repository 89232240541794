import { useFormikContext } from 'formik';

import Radio from 'components/input/Radio';
import { SelectedFilters } from 'contexts/Filters';

type Props = {
  filterKey: 'statusCallTracking' | 'marked' | 'unique';
  options: Record<string, string>;
};

export default function RadioList({ filterKey, options }: Props) {
  const { values, setFieldValue } = useFormikContext<SelectedFilters>();
  return (
    <div className="filter-selector">
      {Object.keys(options).map(option => {
        return (
          <Radio
            key={option}
            onChange={() => setFieldValue(filterKey, option)}
            id={filterKey + '-' + option}
            label={options[option]}
            checked={values[filterKey] === option}
            labelClassName="extra-margin"
          />
        );
      })}
    </div>
  );
}
