import { useEffect, useState } from 'react';
import moment from 'moment';

import CampaignBuilderPool from 'components/admin/CampaignBuilderPool';
import TextField from 'components/input/TextField';
import Loader from 'components/Loader';
import Pagination from 'components/Pagination';
import StickyBar from 'components/StickyBar';
import { useTenant } from 'contexts/Tenant';
import { useHasAdminAccess } from 'hooks/access';
import { useSetTableData } from 'hooks/data';
import { useLocations } from 'hooks/queries/aen';
import {
  CampaignBuilderPool as Pool,
  useCampaignBuilderPools,
  useUpdateAenTable,
} from 'hooks/queries/campaign-builder';
import { useTranslate } from 'hooks/translate';

let timerId: NodeJS.Timeout | null = null;

export default function CampaignBuilder() {
  const setTableData = useSetTableData();
  const translateText = useTranslate();
  const isAdmin = useHasAdminAccess();
  const { selectedDomain, selectedDomainData } = useTenant();

  const [searchText, setSearchText] = useState('');
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  useEffect(() => setPage(1), [selectedDomain, search]);
  useEffect(() => setSearch(''), [selectedDomain]);

  const { locations, isLoading: isLoadingLocations } = useLocations();
  const {
    pools,
    isLoading: isLoadingPools,
    pageCount,
    itemCount,
    lastUpdated,
    refetch,
  } = useCampaignBuilderPools({
    search,
    limit,
    page,
  });
  const { updateAenTable, isLoading: isUpdating } = useUpdateAenTable();
  const isLoading = isLoadingLocations || isLoadingPools;

  const locationOptions: Record<string, string> = { '': translateText('label', 'No location') };
  (locations ?? []).forEach(location => {
    let name = location.name;
    if (location.aen_identifier !== name) {
      name += ' (' + location.aen_identifier + ')';
    }
    locationOptions[location.aen_identifier] = name;
  });

  function getNewId() {
    // The id for a new pool is used to replace the data when the pool is saved,
    // so it needs to be unique. It's structure is "new-[currentPage]-[count]"
    const lastNewPool = pools?.filter(p => typeof p.id === 'string').at(-1);
    const lastNewId = lastNewPool ? (lastNewPool.id as string).split('-').at(-1) : '0';
    return 'new-' + page + '-' + (Number(lastNewId) + 1);
  }

  function addPool() {
    const newId = getNewId();
    setTableData<Pool>(
      ['campaignBuilder', 'pools', selectedDomain, { search, limit, page }],
      data => [
        ...data,
        {
          id: newId,
          conditions: [],
          destination: '',
          location_identifier: '',
          is_active: 1,
          is_offline: 0,
          net_number: '',
          number_assignable_count: null,
          number_count: null,
          numbers: [],
        },
      ],
    );
    setTimeout(() => document.getElementById(newId)?.scrollIntoView(), 1); // Timeout is needed because otherwise the element does not exist yet
  }

  return (
    <div className="campaign-builder">
      <h1>
        {translateText('label', 'Campaign builder')}
        <span>
          {selectedDomainData?.name}
          {!!lastUpdated && ' (' + moment(lastUpdated).format('HH:mm:ss') + ')'}
        </span>
      </h1>
      <StickyBar>
        <div className="top-bar">
          <TextField
            id="filter"
            className="edit"
            value={searchText}
            placeholder={translateText('label', 'Search')}
            onChange={e => {
              if (timerId !== null) clearTimeout(timerId);
              setSearchText(e.target.value);
              timerId = setTimeout(() => setSearch(e.target.value.trim()), 500);
            }}
          />
          <button className="btn btn-lightblue" disabled={isLoading} onClick={() => refetch()}>
            {translateText('label', 'Get pools')}
          </button>
          {isAdmin && (
            <>
              <button disabled={isLoading} className="btn btn-lightblue" onClick={addPool}>
                {translateText('label', 'New pool')}
              </button>
              <button
                className="btn btn-lightblue right"
                disabled={isUpdating}
                onClick={() => updateAenTable()}
              >
                {translateText('label', 'Update AEN table')}
              </button>
            </>
          )}
        </div>
      </StickyBar>
      {isLoading ? (
        <Loader />
      ) : (
        pools?.map(p => (
          <CampaignBuilderPool key={p.id} locations={locationOptions} pool={p} refetch={refetch} />
        ))
      )}
      {!!pools && (
        <StickyBar type="bottom" noStick={pageCount === 1}>
          <Pagination
            activePage={page}
            itemsCountPerPage={limit}
            totalItemsCount={itemCount}
            totalPageCount={pageCount}
            onChange={(pageNumber, itemsPerPage) => {
              setPage(pageNumber);
              setLimit(itemsPerPage);
            }}
          />
        </StickyBar>
      )}
    </div>
  );
}
