import { useState } from 'react';

import Setup from 'components/slide-in/Setup';
import { useTranslate } from 'hooks/translate';
import { useUserLanguage } from 'hooks/user';

export default function PricingPlanInfo() {
  const translateText = useTranslate();
  const userLanguage = useUserLanguage();

  const [showInfo, setShowInfo] = useState(false);

  return (
    <>
      <button className="btn btn-text no-padding" onClick={() => setShowInfo(true)}>
        {translateText('label', 'More information about pricing plans')}
      </button>
      <Setup
        show={showInfo}
        close={() => setShowInfo(false)}
        closeButtonText={translateText('label', 'Close')}
        title={translateText('label', 'Our packages')}
        className="pricing-plan-info"
        maxWidth={820}
      >
        <p>
          {translateText(
            'text',
            'With Call Tracking, you link your search and browsing behavior to your phone conversations. This way, you know what the full results of your marketing activities are. With this information, you can use your marketing budget much more efficiently and increase your return on investment.',
          )}
        </p>
        <p>
          {translateText(
            'text',
            'The costs for Call Tracking consist of fixed costs and variable costs. We apply a fixed rate for the license fee, and the costs are further calculated based on additional usage. We offer two packages:',
          )}
        </p>

        <h4>{translateText('label', 'Optimize')}</h4>
        <p>
          {translateText(
            'text',
            'In the Optimize package, we offer dynamic Call Tracking for businesses that want to measure phone conversions to optimize their campaigns based on search behavior.',
          )}
        </p>
        <p>
          {translateText(
            'text',
            'This package is available from €{price},-. This includes {sessions} sessions, 1 dynamic location (destination number), and 10 offsite phone numbers. Of course, you can expand with more sessions, locations, and offsite phone numbers.',
            { price: '99', sessions: (5000).toLocaleString(userLanguage) },
          )}
        </p>

        <h4>{translateText('label', 'Enterprise')}</h4>
        <p>
          {translateText(
            'text',
            'In the Enterprise package, we offer dynamic Call Tracking for businesses that want to demonstrate the value of phone calls by integrating Call Tracking data into their CRM system and campaigns.',
          )}
        </p>
        <p>
          {translateText(
            'text',
            'This package is available from €{price},-. This includes {sessions} sessions, 1 dynamic location (destination number), and 10 offsite phone numbers. Of course, you can expand with more sessions, locations, and offsite phone numbers.',
            { price: '339', sessions: (25000).toLocaleString(userLanguage) },
          )}
        </p>
        <p>
          {translateText(
            'text',
            'In addition, within the Enterprise package, we offer an onboarding process, customized solutions, and assign a dedicated Customer Success Manager to you. You also have the option for an SLA, and we provide support for privacy issues.',
          )}
        </p>
        <i>
          {translateText(
            'text',
            'The listed prices are per license, per month excluding 21% VAT, unless otherwise stated.',
          )}
        </i>
        <table className="table">
          <thead>
            <tr>
              <th />
              <th className="bold">{translateText('label', 'Optimize (NL)')}</th>
              <th className="bold">{translateText('label', 'Enterprise (NL)')}</th>
              <th className="bold">{translateText('label', 'Optimize (INT.)')}</th>
              <th className="bold">{translateText('label', 'Enterprise (INT.)')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{translateText('label', 'Licence from')}</td>
              <td>€ 99,-</td>
              <td>€ 339,-</td>
              <td>€ 129,-</td>
              <td>€ 419,-</td>
            </tr>
            <tr>
              <td>{translateText('label', 'Sessions (included)')}</td>
              <td>5.000</td>
              <td>25.000</td>
              <td>5.000</td>
              <td>25.000</td>
            </tr>
            <tr>
              <td>{translateText('label', 'Locations (included)')}</td>
              <td>1</td>
              <td>1</td>
              <td>1</td>
              <td>1</td>
            </tr>
            <tr>
              <td>{translateText('label', 'Extra 1,000 sessions')}</td>
              <td>€ 11,-</td>
              <td>€ 11,-</td>
              <td>€ 13,75</td>
              <td>€ 13,75</td>
            </tr>
            <tr>
              <td>{translateText('label', 'Extra location per 1,000 sessions')}</td>
              <td>€ 2,-</td>
              <td>€ 2,-</td>
              <td>€ 13,75</td>
              <td>€ 13,75</td>
            </tr>
            <tr>
              <td>{translateText('label', 'Minimum term')}</td>
              <td>{translateText('datetime', '{months} months', { months: 6 })}</td>
              <td>{translateText('datetime', '{months} months', { months: 12 })}</td>
              <td>{translateText('datetime', '{months} months', { months: 6 })}</td>
              <td>{translateText('datetime', '{months} months', { months: 12 })}</td>
            </tr>
            <tr>
              <td colSpan={5} className="bold">
                {translateText('label', 'Extra offsite phone numbers')}
              </td>
            </tr>
            <tr>
              <td>{translateText('label', 'Included number of offsite phone numbers')}</td>
              <td>10</td>
              <td>10</td>
              <td>╳</td>
              <td>╳</td>
            </tr>
            <tr>
              <td>{translateText('label', 'Extra offsite phone numbers')}</td>
              <td>€ 1,-</td>
              <td>€ 1,-</td>
              <td>€ 3,-</td>
              <td>€ 3,-</td>
            </tr>
            <tr>
              <td>{translateText('label', 'Call minutes (start + minute)')}</td>
              <td>€ 0,053</td>
              <td>€ 0,053</td>
              <td>€ 0,053</td>
              <td>€ 0,053</td>
            </tr>
            <tr>
              <td colSpan={5} className="bold">
                {translateText('label', 'Discount based on contract duration')}
              </td>
            </tr>
            <tr>
              <td>{translateText('datetime', '{months} months', { months: 12 })}</td>
              <td>5%</td>
              <td>5%</td>
              <td>5%</td>
              <td>5%</td>
            </tr>
            <tr>
              <td>{translateText('datetime', '{months} months', { months: 24 })}</td>
              <td>10%</td>
              <td>10%</td>
              <td>10%</td>
              <td>10%</td>
            </tr>
            <tr>
              <td />
              <td colSpan={4}>
                {translateText(
                  'text',
                  'For a longer term, please contact your AdCalls Partner Manager.',
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <p
          dangerouslySetInnerHTML={{
            __html: translateText(
              'text',
              'If you have any questions about this, you can always contact us via <a href="mailto:support@adcalls.nl">support@adcalls.nl</a> or by calling <a href="tel:+31307400990">+31 (0)30 7400 990</a>.',
            ),
          }}
        />
      </Setup>
    </>
  );
}
