import { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import ErrorFocus from 'components/ErrorFocus';
import TextField from 'components/input/TextField';
import PopupModal from 'components/PopupModal';
import TwoStepAuthForm from 'components/unauthenticated/TwoStepAuthForm';
import { clearSession, useSession } from 'contexts/Session';
import { LoginData, useLogin, useLogout } from 'hooks/queries/auth';
import { useUser } from 'hooks/queries/user';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation } from 'hooks/validation';
import { RememberMe } from 'pages/unauthenticated/Login';
import UpdatePassword from './unauthenticated/UpdatePassword';

enum Show {
  Login,
  UpdatePassword,
  VerifyTwoStep,
}

export default function ExtendSession() {
  const translateText = useTranslate();
  const { required } = useCustomValidation();
  const { isTokenValid } = useSession();
  const { user } = useUser();
  const { logIn, isLoading } = useLogin();
  const { logOut } = useLogout();

  const rememberMe: RememberMe = JSON.parse(localStorage.getItem('rememberMe') ?? '{}');
  const [show, setShow] = useState(Show.Login);

  async function handleLogin(values: { password?: string }) {
    const data: LoginData = { email: user?.email, remember_me: !!rememberMe.authKey };

    if (rememberMe.authKey && rememberMe.email) {
      data.auth_key = rememberMe.authKey;
    } else if (values.password) {
      data.password = values.password;
    }

    await logIn(data).catch(response => {
      if (response.status === 409) {
        if (response.error.two_step_enabled) setShow(Show.VerifyTwoStep);
        else logOut();
      }
    });
  }

  const buttons = (
    <div className="buttons-wrapper">
      <button
        className="btn btn-lightblue"
        type="button"
        onClick={clearSession}
        disabled={isLoading}
      >
        {translateText('label', 'Stay logged out')}
      </button>
      <button className="btn btn-green margin" type="submit" disabled={isLoading}>
        {translateText('label', 'Log in again')}
      </button>
    </div>
  );

  function getTwoStepAuthForm(password?: string) {
    return (
      <TwoStepAuthForm
        email={user?.email}
        cancel={() => setShow(Show.Login)}
        rememberMe={rememberMe}
        onLoginSuccess={() => setShow(Show.Login)}
        password={password}
        onUpdatePassword={() => setShow(Show.UpdatePassword)}
      />
    );
  }

  function getLoginForm() {
    if (rememberMe.authKey) {
      if (show === Show.VerifyTwoStep) return getTwoStepAuthForm();
      return (
        <Formik initialValues={{}} onSubmit={handleLogin}>
          {({ handleSubmit }) => <form onSubmit={handleSubmit}>{buttons}</form>}
        </Formik>
      );
    }

    return (
      <Formik
        initialValues={{ password: '' }}
        validationSchema={Yup.object({ password: required })}
        onSubmit={handleLogin}
      >
        {({ values, errors, handleChange, handleBlur, handleSubmit }) => {
          if (show === Show.VerifyTwoStep) return getTwoStepAuthForm(values.password);
          return (
            <form onSubmit={handleSubmit}>
              <ErrorFocus element={document.getElementById('extend-session')} />
              <TextField
                name="password"
                id="password-field"
                type="password"
                placeholder={translateText('label', 'Password')}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="current-password"
                error={errors.password}
                autoFocus
              />
              {buttons}
            </form>
          );
        }}
      </Formik>
    );
  }

  function getLoggedInAsText() {
    if (user?.first_name || user?.last_name) {
      return translateText(
        'text',
        'You were logged in as <span data-private>{name}</span> with the e-mail address <span data-private>{email}</span>.',
        {
          name: (user?.first_name || '') + (user?.first_name ? ' ' : '') + (user?.last_name || ''),
          email: user?.email,
        },
      );
    } else if (user?.email) {
      return translateText(
        'text',
        'You were logged in with the email address <span data-private>{email}</span>.',
        { email: user?.email },
      );
    }
    return null;
  }

  function getExpiredText() {
    if (show !== Show.Login) {
      return null;
    } else if (rememberMe.authKey) {
      return translateText('text', 'Indicate whether you want to resume the session.');
    }
    return translateText('text', 'Enter your password to resume the session.');
  }

  const loggedInAsText = getLoggedInAsText();
  const expiredText = getExpiredText();

  return (
    <PopupModal id="extend-session" show={!isTokenValid} left mostImportant>
      {show === Show.UpdatePassword ? (
        <UpdatePassword email={user!.email} onSuccess={() => setShow(Show.Login)} />
      ) : (
        <>
          <h1>{translateText('label', 'Your session has expired')}</h1>
          {loggedInAsText && <p dangerouslySetInnerHTML={{ __html: loggedInAsText }} />}
          {expiredText && <p>{expiredText}</p>}
          {getLoginForm()}
        </>
      )}
    </PopupModal>
  );
}
