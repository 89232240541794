import {
  ChangeEventHandler,
  ClipboardEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  LegacyRef,
  MouseEventHandler,
  ReactElement,
  useState,
} from 'react';

import alert from 'assets/images/icons/alert.svg';
import alertBlue from 'assets/images/icons/alert-blue.svg';
import ErrorTooltip from 'components/ErrorTooltip';
import { PH_NO_CAPTURE } from 'globals/constants';
import { useTranslate } from 'hooks/translate';

export type TextFieldProps = {
  id?: string;
  className?: string;
  wrapperClassName?: string;
  onKeyUp?: KeyboardEventHandler;
  onKeyDown?: KeyboardEventHandler;
  onClick?: MouseEventHandler;
  onBlur?: FocusEventHandler;
  onFocus?: FocusEventHandler;
  onPaste?: ClipboardEventHandler;
  title?: string;
  placeholder?: string;
  value?: string | number;
  defaultValue?: string | number;
  name?: string;
  list?: string;
  maxLength?: number;
  autoComplete?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  inputRef?: LegacyRef<HTMLInputElement> | ((elementRef: HTMLInputElement) => void);
  rows?: number;
  error?: boolean | string;
  canEdit?: boolean;
  extraContent?: ReactElement;
  minimalValue?: number;
  maximumValue?: number;
};

type InputProps = {
  type?: 'text' | 'password' | 'email' | 'number';
  onChange?: ChangeEventHandler<HTMLInputElement>;
} & TextFieldProps;

type TextAreaProps = {
  type: 'textarea';
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
} & TextFieldProps;

export default function TextField({
  id,
  className = '',
  wrapperClassName = '',
  onKeyUp,
  onKeyDown,
  onChange,
  onClick,
  onBlur,
  onFocus,
  onPaste,
  type = 'text',
  title,
  placeholder,
  value,
  defaultValue,
  name,
  list,
  maxLength,
  autoComplete,
  autoFocus,
  disabled,
  inputRef,
  rows = 5,
  error,
  canEdit = true,
  extraContent,
  minimalValue,
  maximumValue,
}: InputProps | TextAreaProps) {
  const translateText = useTranslate();
  const [capslock, setCapsLock] = useState(false);

  if (!error && maxLength && String(value ?? '').length >= maxLength) {
    error = translateText('message', '{fieldName} has a limit of {number} characters.', {
      fieldName: title ?? translateText('label', 'This field'),
      number: maxLength,
    });
  }

  function renderInput(): ReactElement | string | number | undefined {
    if (!canEdit) return value;

    className += (className ? ' ' : '') + 'text-field';
    if (error) className += ' error';

    if (type === 'textarea') {
      return (
        <textarea
          id={id}
          name={name}
          className={className}
          placeholder={placeholder}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange as ChangeEventHandler<HTMLTextAreaElement>}
          rows={rows}
          disabled={disabled}
        />
      );
    }

    return (
      <input
        id={id}
        className={className}
        name={name}
        onKeyUp={onKeyUpFinal}
        onKeyDown={onKeyDown}
        onChange={onChange as ChangeEventHandler<HTMLInputElement>}
        onClick={onClick}
        onBlur={onBlurFinal}
        onFocus={onFocus}
        onPaste={onPaste}
        type={type}
        title={title}
        list={list}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        disabled={disabled}
        ref={inputRef}
        maxLength={maxLength}
        min={minimalValue}
        max={maximumValue}
      />
    );
  }

  wrapperClassName += (wrapperClassName ? ' ' : '') + 'text-field-wrapper';
  if (type === 'password') wrapperClassName += ' ' + PH_NO_CAPTURE;

  let onKeyUpFinal = onKeyUp;
  let onBlurFinal = onBlur;
  if (type === 'password') {
    onKeyUpFinal = e => {
      setCapsLock(e.getModifierState('CapsLock'));
      typeof onKeyUp === 'function' && onKeyUp(e);
    };
    onBlurFinal = e => {
      setCapsLock(false);
      typeof onBlur === 'function' && onBlur(e);
    };
  }

  const capslockText = capslock ? translateText('label', 'Caps lock is on') : '';
  return (
    <div className={wrapperClassName}>
      <ErrorTooltip
        error={typeof error === 'string' ? error : capslockText}
        html={
          <>
            {renderInput()}
            {error && <img className="error-icon" src={alert} alt="error" />}
            {!error && capslock && <img className="warning-icon" src={alertBlue} alt="warning" />}
          </>
        }
      />
      {extraContent}
    </div>
  );
}
