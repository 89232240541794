import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import { cloneDeep } from 'lodash';

import info from 'assets/images/icons/info.svg';
import error from 'assets/images/icons/messages/error.svg';
import success from 'assets/images/icons/messages/success.svg';
import { clearSession } from './Session';

type Messages = {
  toasts: Message[];
  permanents: Message[];
  addSuccessMessage: (message: string, duration?: number, icon?: string) => void;
  addErrorMessage: (message: unknown, duration?: number, icon?: string) => void;
  addInfoMessage: (message: string, duration?: number, icon?: string) => void;
  addPermanentMessage: (message: string, link?: Link, icon?: string) => void;
  nextMessage: (messageType: MessageType) => void;
  clearMessages: (messageType: MessageType) => void;
};

export const MessagesContext = createContext<Messages | null>(null);

const MESSAGE_ADD = 'MESSAGE_ADD' as const;
const MESSAGE_CLEAR = 'MESSAGE_CLEAR' as const;
const MESSAGE_NEXT = 'MESSAGE_NEXT' as const;

export enum MessageType {
  Toast = 'toast',
  Permanent = 'permanent',
}

export type Message = {
  className: string;
  icon: string;
  duration: number | null;
  message: string;
  link: Link | null;
};

type Link = {
  text: string;
  url: string;
};

type State = {
  toasts: Message[];
  permanents: Message[];
};

type Action =
  | { type: typeof MESSAGE_ADD; message: Message; messageType: MessageType }
  | { type: typeof MESSAGE_CLEAR; messageType: MessageType }
  | { type: typeof MESSAGE_NEXT; messageType: MessageType };

export function MessagesProvider({ children }: PropsWithChildren<Record<string, unknown>>) {
  const [state, dispatch] = useReducer(reducer, { toasts: [], permanents: [] });

  function reducer(state: State, action: Action) {
    let toasts = cloneDeep(state.toasts);
    let permanents = cloneDeep(state.permanents);
    switch (action.type) {
      case MESSAGE_ADD:
        if (action.messageType === MessageType.Toast) {
          toasts.push(action.message);
        } else {
          permanents.push(action.message);
        }
        break;
      case MESSAGE_CLEAR:
        if (action.messageType === MessageType.Toast) {
          toasts = [];
        } else {
          permanents = [];
        }
        break;
      case MESSAGE_NEXT:
        if (action.messageType === MessageType.Toast) {
          toasts = [...toasts.slice(1)];
        } else {
          permanents = [...permanents.slice(1)];
        }
        break;
    }
    return { toasts, permanents };
  }

  const addMessage = useCallback(
    (
      className: string,
      icon: string,
      message: string | string[],
      duration: number | null = 5,
      link: Link | null = null,
      messageType: MessageType = MessageType.Toast,
    ) => {
      if (!__PROD__ && className === 'error-message') {
        console.error(message);
      }
      if (Array.isArray(message)) {
        message.forEach(msg =>
          dispatch({
            type: MESSAGE_ADD,
            messageType,
            message: {
              className,
              icon,
              duration,
              message: msg,
              link,
            },
          }),
        );
      } else {
        dispatch({
          type: MESSAGE_ADD,
          messageType,
          message: {
            className,
            icon,
            duration,
            message,
            link,
          },
        });
      }
    },
    [],
  );

  const nextMessage = useCallback(
    (messageType: MessageType) => dispatch({ type: MESSAGE_NEXT, messageType }),
    [],
  );

  const clearMessages = useCallback(
    (messageType: MessageType) => dispatch({ type: MESSAGE_CLEAR, messageType }),
    [],
  );

  const addSuccessMessage = useCallback(
    (message: string | string[], duration = 5, icon = success) =>
      addMessage('success-message', icon, message, duration),
    [addMessage],
  );

  const addInfoMessage = useCallback(
    (message: string | string[], duration = 5, icon = info) =>
      addMessage('info-message', icon, message, duration),
    [addMessage],
  );

  const addErrorMessage = useCallback(
    (message: unknown, duration = 7, icon = error) => {
      if (message && typeof message === 'object' && 'status' in message && message.status === 401) {
        return clearSession();
      }
      addMessage('error-message', icon, createError(message), duration);
    },
    [addMessage],
  );

  const addPermanentMessage = useCallback(
    (message: string, link: Link | null = null, icon = info) =>
      addMessage('info-message', icon, message, null, link, MessageType.Permanent),
    [addMessage],
  );

  return (
    <MessagesContext.Provider
      value={{
        toasts: useMemo(() => state.toasts, [state.toasts]),
        permanents: useMemo(() => state.permanents, [state.permanents]),
        addSuccessMessage,
        addErrorMessage,
        addInfoMessage,
        nextMessage,
        clearMessages,
        addPermanentMessage,
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
}

export function useMessages() {
  const messages = useContext(MessagesContext);
  if (messages === null) {
    throw new Error('Messages context not available');
  }
  return messages;
}

export function createError(error: unknown): string | string[] {
  if (error && typeof error === 'object') {
    const values = Object.values(error).flat();
    if ('message' in error && typeof error.message === 'string') {
      // Error from API has structure { status: 401, messsage: "Your request was made with invalid credentials."}
      return error.message;
    } else if (values.every(item => typeof item === 'string')) {
      // Error from API has structure { password: ["Your password is too easy to guess."] }
      return values;
    }
  } else if (
    typeof error === 'string' ||
    (Array.isArray(error) && error.every(item => typeof item === 'string'))
  ) {
    return error;
  }
  return '';
}
