import { useQuery } from '@tanstack/react-query';
import moment, { Moment } from 'moment';

import { useFetch } from 'hooks/fetch';

export function useCallRecordingFile(id: number, timeCalled: Moment | null) {
  const { getFetchConfig } = useFetch();

  const { data, isFetching } = useQuery({
    queryKey: ['audio', id],
    queryFn: async () => {
      const { query, config } = getFetchConfig('/audio/file/' + id + '.wav');
      const response = await fetch(query, config);
      if (response.ok) return await response.text();
      throw await response.json();
    },
    meta: { noError: true },
    enabled: !isNaN(id) && timeCalled !== null && timeCalled >= moment().subtract(3, 'months'),
  });

  return { file: data, isLoading: isFetching };
}
