import { useMutation } from '@tanstack/react-query';

import { useMessages } from 'contexts/Messages';
import { useTenant } from 'contexts/Tenant';
import { useSetQueryData } from 'hooks/data';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';
import { SessionFilterPool } from './session-filter-pool';

export function useCreateSessionFilterCondition() {
  const translateText = useTranslate();
  const setQueryData = useSetQueryData();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const { mutateAsync } = useMutation({
    mutationFn: (values: { poolId: number; type: string; operand: string; value: string }) =>
      fetchData('/session-filter-condition/create', {
        method: 'POST',
        bodyParams: {
          operator_const: values.operand,
          type_const: values.type,
          value: values.value,
          session_filter_pool_id: values.poolId,
        },
      }),
    onSuccess: (data, variables) => {
      setQueryData<SessionFilterPool[]>(['sessionFilter', 'pools', selectedDomain], oldData =>
        oldData.map(p =>
          p.id === variables.poolId ? { ...p, conditions: [...p.conditions, data] } : p,
        ),
      );
      addSuccessMessage(
        translateText('admin/text', 'The {type} has been added.', {
          type: translateText('label', 'Condition').toLowerCase(),
        }),
      );
    },
  });

  return { createCondition: mutateAsync };
}

export function useUpdateSessionFilterCondition() {
  const translateText = useTranslate();
  const setQueryData = useSetQueryData();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const { mutateAsync } = useMutation({
    mutationFn: (values: { poolId: number; id: number; operand: string; value: string }) =>
      fetchData('/session-filter-condition/update', {
        method: 'PUT',
        queryParams: { id: values.id },
        bodyParams: {
          operator_const: values.operand,
          value: values.value,
        },
      }),
    onSuccess: (data, variables) => {
      setQueryData<SessionFilterPool[]>(['sessionFilter', 'pools', selectedDomain], oldData =>
        oldData.map(p =>
          p.id === variables.poolId
            ? { ...p, conditions: p.conditions.map(c => (c.id === variables.id ? data : c)) }
            : p,
        ),
      );
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { updateCondition: mutateAsync };
}

export function useDeleteSessionFilterCondition() {
  const translateText = useTranslate();
  const setQueryData = useSetQueryData();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const { mutate, isPending } = useMutation({
    mutationFn: (variables: { id: number; poolId: number }) =>
      fetchData('/session-filter-condition/delete', {
        method: 'DELETE',
        queryParams: { id: variables.id },
      }),
    onSuccess: (data, variables) => {
      setQueryData<SessionFilterPool[]>(['sessionFilter', 'pools', selectedDomain], oldData =>
        oldData.map(p =>
          p.id === variables.poolId
            ? { ...p, conditions: p.conditions.filter(c => c.id !== variables.id) }
            : p,
        ),
      );
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  const deleteCondition = (variables: { id: number; poolId: number }) => {
    if (
      window.confirm(
        translateText('admin/text', 'Are you sure you want to delete this {type}?', {
          type: translateText('label', 'Condition').toLowerCase(),
        }),
      )
    ) {
      mutate(variables);
    }
  };

  return { deleteCondition, isDeleting: isPending };
}
