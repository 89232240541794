import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Formik } from 'formik';
import * as Yup from 'yup';

import ErrorFocus from 'components/ErrorFocus';
import Checkbox from 'components/input/Checkbox';
import TextField from 'components/input/TextField';
import ContentSidebar from 'components/unauthenticated/ContentSidebar';
import ForgotPassword from 'components/unauthenticated/ForgotPassword';
import LoginNewsPage from 'components/unauthenticated/LoginNewsPage';
import TwoStepAuthForm from 'components/unauthenticated/TwoStepAuthForm';
import TwoStepAuthSetupForm, { Section } from 'components/unauthenticated/TwoStepAuthSetupForm';
import UpdatePassword from 'components/unauthenticated/UpdatePassword';
import { PH_MASK } from 'globals/constants';
import { LoginData, useLogin, useTwoStep } from 'hooks/queries/auth';
import { useTranslate } from 'hooks/translate';
import { useIsLoggingIn } from 'hooks/user';
import { useCustomValidation } from 'hooks/validation';

export type RememberMe = {
  email?: string;
  name?: string;
  authKey?: string;
  twoStep?: boolean;
};

enum Show {
  ForgotPassword,
  Login,
  UpdatePassword,
  EnableTwoStep,
  VerifyTwoStep,
}

export default function Login() {
  const translateText = useTranslate();
  const navigate = useNavigate();
  const { email, required } = useCustomValidation();
  const { logIn } = useLogin();
  const { enableTwoStep, isLoadingEnable } = useTwoStep();
  const isLoggingIn = useIsLoggingIn();

  const [rememberMe, setRememberMe] = useState<RememberMe>(
    JSON.parse(localStorage.getItem('rememberMe') ?? '{}'),
  );
  const [show, setShow] = useState(Show.Login);
  const [twoStepSection, setTwoStepSection] = useState(Section.Enable);
  const [twoStepConfig, setTwoStepConfig] = useState<{
    qrUri: string;
    secret: string;
  }>();

  function closeTwoStepSetup() {
    setTwoStepSection(Section.Enable);
    setShow(Show.Login);
  }

  async function handleLogin(values?: { email: string; password: string; rememberMe: boolean }) {
    const data: LoginData = { remember_me: !!rememberMe.authKey || !!values?.rememberMe };

    if (rememberMe.authKey && rememberMe.email) {
      data.email = rememberMe.email;
      data.auth_key = rememberMe.authKey;
    } else if (values) {
      data.email = values.email;
      data.password = values.password;
    }

    await logIn(data).catch(response => {
      if (response.status === 409) {
        if (response.error.two_step_enabled) setShow(Show.VerifyTwoStep);
        else {
          enableTwoStep(data).then(response => {
            setTwoStepConfig(response);
            setShow(Show.EnableTwoStep);
          });
        }
      }
    });
  }

  function getTwoStepSetupForm(auth: LoginData) {
    return (
      <TwoStepAuthSetupForm
        config={twoStepConfig}
        close={closeTwoStepSetup}
        section={twoStepSection}
        setSection={setTwoStepSection}
        auth={auth}
      />
    );
  }

  function getLoginForm() {
    if (rememberMe.authKey) {
      return (
        <Formik initialValues={{}} onSubmit={async () => await handleLogin()}>
          {({ handleSubmit, isSubmitting }) => {
            if (show === Show.EnableTwoStep) {
              return getTwoStepSetupForm({
                email: rememberMe.email!,
                auth_key: rememberMe.authKey!,
                remember_me: true,
              });
            } else if (show === Show.VerifyTwoStep) {
              return (
                <TwoStepAuthForm
                  cancel={() => setShow(Show.Login)}
                  rememberMe={rememberMe}
                  onUpdatePassword={() => setShow(Show.UpdatePassword)}
                />
              );
            } else if (show === Show.UpdatePassword) {
              return (
                <UpdatePassword email={rememberMe.email!} onSuccess={() => setShow(Show.Login)} />
              );
            }

            return (
              <form onSubmit={handleSubmit}>
                <div className="logged-in-as">
                  <span>{translateText('label', 'Logged in as:')}</span>
                  <h2 className={`${PH_MASK} bold`}>{rememberMe.name ?? rememberMe.email}</h2>
                  <p>
                    {translateText(
                      'text',
                      'Your session has expired, but we have remembered your details. Indicate whether you want to proceed to the dashboard or log in as another user.',
                    )}
                  </p>
                  <button
                    id="login-button"
                    type="submit"
                    className="btn btn-green btn-wide"
                    disabled={isSubmitting}
                  >
                    {translateText('label', 'To the dashboard')}
                  </button>
                  <button
                    className="btn btn-text no-padding"
                    onClick={e => {
                      e.preventDefault();
                      setRememberMe({});
                      localStorage.removeItem('rememberMe');
                    }}
                  >
                    {translateText('label', 'Log in as another user')}
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
      );
    }

    const validationSchema = Yup.object({
      email,
      password: required,
    });

    return (
      <Formik
        initialValues={{ email: '', password: '', rememberMe: false }}
        validationSchema={validationSchema}
        onSubmit={handleLogin}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          values,
        }) => {
          if (show === Show.EnableTwoStep) {
            return getTwoStepSetupForm({
              email: values.email,
              password: values.password,
              remember_me: values.rememberMe,
            });
          } else if (show === Show.VerifyTwoStep) {
            return (
              <TwoStepAuthForm
                cancel={() => setShow(Show.Login)}
                email={values.email}
                password={values.password}
                rememberMe={{ ...rememberMe, enabled: values.rememberMe }}
                onUpdatePassword={() => setShow(Show.UpdatePassword)}
              />
            );
          } else if (show === Show.UpdatePassword) {
            return (
              <UpdatePassword
                email={values.email}
                onSuccess={() => {
                  setShow(Show.Login);
                  setFieldValue('password', '');
                }}
              />
            );
          }

          return (
            <form onSubmit={handleSubmit} noValidate>
              <ErrorFocus />
              <TextField
                name="email"
                id="login-email-field"
                type="email"
                placeholder={translateText('label', 'Email address')}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="email"
                autoFocus={!!(!touched.email || errors.email)}
                error={touched.email && errors.email}
              />
              <TextField
                name="password"
                id="login-pass-field"
                type="password"
                placeholder={translateText('label', 'Password')}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="current-password"
                autoFocus={!!(!errors.email && errors.password)}
                error={touched.password && errors.password}
              />
              <div id="login-remember-me-bar">
                <Checkbox
                  label={translateText('label', 'Remember me')}
                  checked={values.rememberMe}
                  name="rememberMe"
                  onChange={handleChange}
                  labelClassName="remember-me-label"
                  id="remember-me"
                />
              </div>
              <button
                id="login-button"
                type="submit"
                className="btn btn-green btn-wide"
                disabled={isSubmitting || isLoggingIn || isLoadingEnable}
              >
                {translateText('label', 'Log in')}
              </button>
            </form>
          );
        }}
      </Formik>
    );
  }

  function getLoginContent() {
    return (
      <div className="login-wrapper">
        <ForgotPassword show={show === Show.ForgotPassword} close={() => setShow(Show.Login)} />
        {getLoginForm()}
        {[Show.Login, Show.ForgotPassword].includes(show) && !rememberMe.authKey && (
          <>
            <div id="login-footer">
              <button
                className="btn btn-text no-padding"
                onClick={() => setShow(Show.ForgotPassword)}
              >
                {translateText('label', 'Forgot password? Click here.')}
              </button>
            </div>
            <div id="login-request-domain">
              <h2 className="title bold">{translateText('label', 'Are you new?')}</h2>
              <p>
                {translateText(
                  'text',
                  "Don't have an account yet, but want to start with AdCalls? Create an account and start today.",
                )}
              </p>
              <button
                className="btn btn-lightblue btn-wide"
                onClick={() => navigate('/request-account')}
              >
                {translateText('label', "Let's go!")}
              </button>
            </div>
          </>
        )}
      </div>
    );
  }

  return <ContentSidebar left={getLoginContent()} right={<LoginNewsPage />} canClick={false} />;
}
