import {
  createContext,
  Dispatch,
  MutableRefObject,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { cloneDeep, set } from 'lodash';

import { DEBUG_MODE_MAX_WAIT_FOR_VERIFICATION } from 'globals/constants';

export const MAIN_IFRAME = 'main' as const;
export const VERIFICATION_IFRAME = 'verification' as const;

const ADD_TO_HISTORY = 'ADD_TO_HISTORY' as const;
const UPDATE_HISTORY_INDEX = 'UPDATE_HISTORY_INDEX' as const;
const VALIDATE_CURRENT_PAGE = 'VALIDATE_CURRENT_PAGE' as const;
const CLEAR_HISTORY = 'CLEAR_HISTORY' as const;

export const STATUS_IDLE = 'idle' as const;
export const STATUS_LOADING = 'loading' as const;
export const STATUS_SCANNING = 'scanning' as const;
export const STATUS_INVALID = 'invalid' as const;
export const STATUS_VALID = 'valid' as const;

export const STATUSES_FINISHED = [STATUS_INVALID, STATUS_VALID] as const;

export type Status =
  | typeof STATUS_IDLE
  | typeof STATUS_LOADING
  | typeof STATUS_SCANNING
  | typeof STATUS_INVALID
  | typeof STATUS_VALID;

type IframeType = typeof MAIN_IFRAME | typeof VERIFICATION_IFRAME;

type InitializeParams = {
  host: string;
  page?: string;
  isVerified?: boolean;
  shouldClearHistory?: boolean;
};

type Format = {
  element: {
    tagName: string;
    id: string;
    classes: string[];
  };
  type: 'regular' | 'regular-replaced' | 'href';
  aenIdentifier: string;
  visible: boolean;
};

type FoundFormats = {
  nonReplacedFormats: Record<string, Record<string, Format>>;
  replacedFormats: Record<string, Record<string, Format>>;
};

type DebugMode = {
  status: Status;
  setStatus: Dispatch<SetStateAction<Status>>;
  mainIframeRef: MutableRefObject<HTMLIFrameElement | null>;
  verificationIframeRef: MutableRefObject<HTMLIFrameElement | null>;
  verifyPageRef: MutableRefObject<string | null>;
  initialize: (params: InitializeParams) => Promise<void>;
  foundFormats: FoundFormats | null;
  setFoundFormats: Dispatch<SetStateAction<FoundFormats | null>>;
  browserHistory: BrowserHistory;
  addToBrowserHistory: (page: string, isValid?: boolean) => void;
  updateHistoryIndex: (index: number) => void;
  validateCurrentPage: () => void;
};

type BrowserHistory = {
  history: { page: string; isValid: boolean }[];
  index: number;
};

type HistoryAction =
  | { type: typeof ADD_TO_HISTORY; page: string; isValid: boolean }
  | { type: typeof UPDATE_HISTORY_INDEX; index: number }
  | { type: typeof VALIDATE_CURRENT_PAGE }
  | { type: typeof CLEAR_HISTORY; page: string };

const DebugModeContext = createContext<DebugMode | null>(null);

export function DebugModeProvider({ children }: PropsWithChildren<Record<string, unknown>>) {
  const [status, setStatus] = useState<Status>(STATUS_IDLE);
  const [foundFormats, setFoundFormats] = useState<FoundFormats | null>(null);

  const mainIframeRef = useRef<HTMLIFrameElement>(null);
  const verificationIframeRef = useRef<HTMLIFrameElement>(null);
  const verifyPageRef = useRef<string | null>(null);

  const [browserHistory, dispatch] = useReducer(browserHistoryReducer, { history: [], index: 0 });

  const addToBrowserHistory = useCallback((page: string, isValid = false) => {
    dispatch({ type: ADD_TO_HISTORY, page, isValid });
  }, []);

  const updateHistoryIndex = useCallback((index: number) => {
    dispatch({ type: UPDATE_HISTORY_INDEX, index });
  }, []);

  const validateCurrentPage = useCallback(() => {
    dispatch({ type: VALIDATE_CURRENT_PAGE });
  }, []);

  const clearBrowserHistory = useCallback((page: string) => {
    dispatch({ type: CLEAR_HISTORY, page });
  }, []);

  const setIframeSrc = useCallback((type: IframeType, page: string) => {
    const element = type === MAIN_IFRAME ? mainIframeRef.current : verificationIframeRef.current;
    if (element) element.src = page;
  }, []);

  const verifyUrlHasAdCallsJavascript = useCallback(
    async (host: string, page: string): Promise<boolean> => {
      return new Promise(resolve => {
        const src = host + page;
        const url = new URL(src);
        setIframeSrc(
          VERIFICATION_IFRAME,
          src + (url.search ? '&' : '?') + 'adcalls-debug-verification-element=1',
        );
        // Repeat check until verifyPage is received from host script, or until 7 seconds have passed
        const start = new Date().getTime();
        let timeout: ReturnType<typeof setTimeout>;
        const checkPageIsVerified = () => {
          if (
            verifyPageRef.current &&
            url.pathname &&
            verifyPageRef.current.replace(/\/$/, '') === url.pathname.replace(/\/$/, '')
          ) {
            setIframeSrc(VERIFICATION_IFRAME, '');
            clearTimeout(timeout);
            resolve(true);
            verifyPageRef.current = null;
          } else if (new Date().getTime() - start > DEBUG_MODE_MAX_WAIT_FOR_VERIFICATION) {
            setIframeSrc(VERIFICATION_IFRAME, '');
            clearTimeout(timeout);
            resolve(false);
            verifyPageRef.current = null;
          } else {
            timeout = setTimeout(checkPageIsVerified, 10);
          }
        };
        timeout = setTimeout(checkPageIsVerified, 10);
      });
    },
    [setIframeSrc],
  );

  const initialize = useCallback(
    async ({
      host,
      page = '/',
      isVerified = false,
      shouldClearHistory = false,
    }: InitializeParams) => {
      setIframeSrc(MAIN_IFRAME, host + page);
      setFoundFormats(null);
      if (shouldClearHistory) clearBrowserHistory(page);

      if (!isVerified) {
        setStatus(STATUS_LOADING);
        const isValid = await verifyUrlHasAdCallsJavascript(host, page);
        if (!isValid) {
          setStatus(STATUS_INVALID);
          return;
        }
      }
    },
    [setIframeSrc, verifyUrlHasAdCallsJavascript, clearBrowserHistory],
  );

  const contextState = useMemo(
    () => ({
      status,
      setStatus,
      foundFormats,
      setFoundFormats,
      browserHistory,
      addToBrowserHistory,
      updateHistoryIndex,
      validateCurrentPage,
      mainIframeRef,
      verificationIframeRef,
      verifyPageRef,
      initialize,
    }),
    [
      status,
      foundFormats,
      browserHistory,
      addToBrowserHistory,
      updateHistoryIndex,
      validateCurrentPage,
      initialize,
    ],
  );

  return <DebugModeContext.Provider value={contextState}>{children}</DebugModeContext.Provider>;
}

export function useDebugMode() {
  const debugMode = useContext(DebugModeContext);
  if (debugMode === null) throw new Error('Debug mode context not available');
  return debugMode;
}

function browserHistoryReducer(state: BrowserHistory, action: HistoryAction): BrowserHistory {
  switch (action.type) {
    case ADD_TO_HISTORY: {
      // Clear any history after the current page
      const history = state.history.slice(0, state.index + 1);
      const index = history.length > 0 ? state.index + 1 : 0;
      history.push({ page: action.page, isValid: action.isValid });
      return { history, index };
    }
    case UPDATE_HISTORY_INDEX:
      return { ...state, index: action.index };
    case VALIDATE_CURRENT_PAGE:
      return set(cloneDeep(state), ['history', state.index, 'isValid'], true);
    case CLEAR_HISTORY:
      return { history: [{ page: action.page, isValid: false }], index: 0 };
  }
}
