import { useState } from 'react';
import { Formik } from 'formik';

import ConfigurationCard from 'components/configuration/ConfigurationCard';
import Toggle from 'components/input/Toggle';
import Skeleton from 'components/Skeleton';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import SupportParagraph from 'components/SupportParagraph';
import { SUP_ART_NUMBER_DISTRIBUTION } from 'globals/support';
import { useProperties } from 'hooks/queries/domain';
import { useNumberFormats, useUpdateNumberDistribution } from 'hooks/queries/domain-setting';
import { useTranslate } from 'hooks/translate';

export default function NumberDistributionSection() {
  const translateText = useTranslate();
  const { updateNumberDistribution } = useUpdateNumberDistribution();
  const { isLoading: isLoadingFormats } = useNumberFormats();
  const { data, isLoading: isLoadingProperties } = useProperties();
  const isLoading = isLoadingProperties || isLoadingFormats;

  const [showEdit, setShowEdit] = useState(false);

  const warningText = translateText(
    'domain',
    'Please note: sessions are still registered and invoicing will continue in all cases.',
  );

  async function save(values: { enabled: boolean }) {
    await updateNumberDistribution(values.enabled).then(() => setShowEdit(false));
  }

  return (
    <>
      <ConfigurationCard
        onEdit={() => setShowEdit(true)}
        title={translateText('label', 'Number distribution')}
        isLoading={isLoading}
      >
        <div className="number-distribution-status">
          <div className="header bold">
            {isLoading ? <Skeleton width="100px" /> : translateText('label', 'Number distribution')}
          </div>
          {isLoading ? (
            <Skeleton className="big-dot" />
          ) : (
            <div
              className={'big-dot ' + (data?.number_distribution.enabled ? 'active' : 'inactive')}
            />
          )}
          {isLoading ? (
            <Skeleton width="85px" />
          ) : data?.number_distribution.enabled ? (
            translateText('label', 'Enabled')
          ) : (
            translateText('label', 'Disabled')
          )}
        </div>
        <p>
          {isLoading ? (
            <Skeleton count={3} />
          ) : (
            translateText(
              'domain',
              'With this function you can enable and disable the number distribution. When number distribution is disabled, no more dynamic phone numbers for Call Tracking are distributed on the website.[manager] Click on the gears to adjust the status of the number distribution.[/manager]',
            )
          )}
        </p>
        <p className="purple-text">{isLoading ? <Skeleton /> : warningText}</p>
      </ConfigurationCard>
      <Formik
        initialValues={{ enabled: data?.number_distribution.enabled ?? false }}
        onSubmit={save}
        enableReinitialize
      >
        {({ values, handleChange, submitForm, dirty, isSubmitting, resetForm }) => (
          <Setup
            show={showEdit}
            close={() => setShowEdit(false)}
            afterClose={resetForm}
            title={translateText('label', 'Number distribution')}
            save={submitForm}
            hasChanges={dirty}
            isSaving={isSubmitting}
            maxWidth={360}
          >
            <p>
              {translateText(
                'domain',
                'With this function you can enable and disable the number distribution. When number distribution is disabled, no more dynamic phone numbers for Call Tracking are distributed on the website.',
              )}
            </p>
            <p className="purple-text">{warningText}</p>
            <SupportParagraph articleId={SUP_ART_NUMBER_DISTRIBUTION} />
            <div className="section">
              <InputWrapper label={translateText('label', 'Number distribution')}>
                <Toggle
                  name="enabled"
                  checked={values.enabled}
                  disabled={isSubmitting}
                  onClick={handleChange}
                />
              </InputWrapper>
            </div>
          </Setup>
        )}
      </Formik>
    </>
  );
}
