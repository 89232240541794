import { CSSProperties, PropsWithChildren, ReactElement, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

export type BaseSlideInProps = {
  show: boolean;
  close: () => void;
};

export type SlideInProps = {
  id?: string;
  removeClose?: boolean;
  subSlideIn?: ReactElement;
  overlay?: 'regular' | 'under' | 'transparent' | false;
  maxWidth?: number;
  className?: string;
  style?: CSSProperties;
  wrapperClassName?: string;
  wrapperStyle?: CSSProperties;
} & BaseSlideInProps;

export const slideInTransitionTime = __TEST__ ? 0 : 400;

export default function SlideIn({
  id,
  children,
  show,
  close,
  removeClose = false,
  subSlideIn,
  overlay = 'regular',
  maxWidth,
  className,
  style,
  wrapperClassName,
  wrapperStyle,
}: PropsWithChildren<SlideInProps>) {
  const slideInRef = useRef(null);
  const overlayRef = useRef(null);

  let wrapperClass = 'slide-in-wrapper';
  if (wrapperClassName) wrapperClass += ' ' + wrapperClassName;

  let slideInClass = 'slide-in';
  if (className) slideInClass += ' ' + className;

  return (
    <>
      {overlay !== false && (
        <CSSTransition
          classNames="slide-in-background"
          in={show}
          timeout={slideInTransitionTime}
          nodeRef={overlayRef}
          unmountOnExit
        >
          <div
            className={'overlay' + (overlay !== 'regular' ? ' ' + overlay : '')}
            onClick={close}
            data-testid="overlay"
            ref={overlayRef}
          />
        </CSSTransition>
      )}
      <CSSTransition
        classNames="slide-in"
        in={show}
        timeout={slideInTransitionTime}
        nodeRef={slideInRef}
        unmountOnExit
      >
        <div className={wrapperClass} style={wrapperStyle} ref={slideInRef}>
          {subSlideIn}
          <div id={id} className={slideInClass} style={style ?? { width: maxWidth }}>
            <div
              className={'close-icon' + (!removeClose ? ' clickable' : '')}
              onClick={!removeClose ? close : () => null}
            >
              {!removeClose && '×'}
            </div>
            {children}
          </div>
        </div>
      </CSSTransition>
    </>
  );
}
