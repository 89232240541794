import { useState } from 'react';
import { useLocation } from 'react-router';
import moment from 'moment';

import Skeleton from 'components/Skeleton';
import { useFilters } from 'contexts/Filters';
import { useGlobal } from 'contexts/Global';
import { DATE_PREVIOUS_TYPE_YEAR, TYPE_CALL_TRACKING, TYPE_FORM_TRACKING } from 'globals/constants';
import { useIsFetchingReportsFilter } from 'hooks/fetching';
import { useTranslate } from 'hooks/translate';
import ReportsFilter from './ReportsFilter';

export default function FilterBar() {
  const translateText = useTranslate();
  const location = useLocation();
  const { filters } = useFilters();
  const { showReportsFilter } = useGlobal();
  const isLoading = useIsFetchingReportsFilter();
  const [showApplied, setShowApplied] = useState(true);

  if (!showReportsFilter && location.pathname !== '/overview') return null;

  function getPeriod(isPrev = false) {
    let from = moment(filters.dates.from, 'DD-MM-YYYY');
    let to = moment(filters.dates.to, 'DD-MM-YYYY');
    if (window.location.pathname === '/overview') {
      from = moment().subtract(28, 'days');
      to = moment().subtract(1, 'days');
    } else if (isPrev) {
      if (filters.dates.previous.type === DATE_PREVIOUS_TYPE_YEAR) {
        from = from.subtract(1, 'years');
        to = to.subtract(1, 'years');
      } else {
        const daysDiff = to.diff(from, 'days') + 1;
        from = from.subtract(daysDiff, 'days');
        to = to.subtract(daysDiff, 'days');
      }
    }

    const start = from.format('DD MMM YYYY');
    const end = to.format('DD MMM YYYY');
    return start === end ? start : start + ' - ' + end;
  }

  function getAppliedFilters() {
    if (!showApplied) return null;
    const appliedFilters = [{ label: translateText('label', 'Period'), value: getPeriod() }];

    if (filters.dates?.previous?.enabled) {
      appliedFilters.push({
        label: translateText('label', 'Previous period'),
        value: getPeriod(true),
      });
    }

    if (showReportsFilter === TYPE_CALL_TRACKING) {
      appliedFilters.push({
        label: translateText('label', 'Traffic sources'),
        value: String((filters.sources ?? []).length),
      });
    }

    if (showReportsFilter === TYPE_CALL_TRACKING || showReportsFilter === TYPE_FORM_TRACKING) {
      appliedFilters.push({
        label: translateText('label', 'Locations'),
        value: String((filters.locations ?? []).length),
      });
    }

    return (
      <div className="filter-bar">
        {appliedFilters.map((filter, index) =>
          isLoading ? (
            <span key={index} className="applied-filter loading">
              <Skeleton isWhite width={80} />
              <Skeleton isWhite width={80} />
            </span>
          ) : (
            <span
              key={index}
              className="applied-filter"
            >{`${filter.label} (${filter.value})`}</span>
          ),
        )}
      </div>
    );
  }

  return (
    <div id="filters-wrapper" className={showApplied ? '' : 'open'}>
      <div id="filters">
        {location.pathname !== '/overview' && <ReportsFilter setShowApplied={setShowApplied} />}
      </div>
      <div id="applied-filters" className={location.pathname === '/overview' ? 'no-padding' : ''}>
        {getAppliedFilters()}
      </div>
    </div>
  );
}
