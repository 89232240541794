import { MouseEventHandler } from 'react';

import Skeleton from 'components/Skeleton';
import Tooltip from 'components/Tooltip';
import { useCalculate } from 'hooks/calculate';
import { useTranslate } from 'hooks/translate';
import { useUserLanguage } from 'hooks/user';
import { StatisticTileProps } from './types';

export default function StatisticsTile({
  item,
  value,
  previousValue,
  updateSelected,
  selected,
  isLoading,
}: StatisticTileProps) {
  const translateText = useTranslate();
  const language = useUserLanguage();
  const { getDifference } = useCalculate();

  let tileClass = 'statistic-tile white-block';
  let onClick: MouseEventHandler = () => null;
  if (updateSelected && item.isSelectable && !isLoading) {
    tileClass += ' clickable selectable';
    if (selected === item.key) tileClass += ' selected';
    else onClick = () => updateSelected(item.key);
  }

  let difference = null;
  if (!isLoading && value !== undefined && previousValue !== null) {
    const percentage = getDifference(value, previousValue, false, item.type, item.lessIsBetter);

    let previousValueFormatted = previousValue.toString();
    if (!isNaN(Number(previousValue))) {
      previousValueFormatted = Number(previousValue).toLocaleString(language);
      if (item.isPercentage) previousValueFormatted += '%';
    }

    difference = (
      <div className={'difference' + percentage[1]}>
        <Tooltip
          html={<>{percentage[0]}</>}
          text={translateText('label', 'Previous period') + ': ' + (previousValueFormatted || '-')}
          placement="bottom"
        />
      </div>
    );

    if (item.noTooltipPrevious) {
      difference = (
        <div className={'difference' + percentage[1]}>{previousValueFormatted || '-'}</div>
      );
    }
  }

  if (item.format && value !== undefined) {
    value = item.format(value);
  } else if (!isNaN(Number(value))) {
    value = Number(value).toLocaleString(language);
    if (item.isPercentage) value += '%';
  }

  return (
    <div className={tileClass} onClick={onClick}>
      {item.tooltip && !isLoading && (
        <div className="tooltip-i">
          <Tooltip text={item.tooltip} />
        </div>
      )}
      <div className={'content' + (item.key === 'no data' ? ' no-data' : '')}>
        <div className={'name' + (item.key !== 'no data' ? ' bold' : '')}>
          {isLoading ? <Skeleton width={175} /> : item.name}
        </div>
        <div className="value-wrapper">
          <div className="value">{isLoading ? <Skeleton width={75} /> : value}</div>
          {difference}
        </div>
      </div>
    </div>
  );
}
