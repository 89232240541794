import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';

import Breadcrumb from 'components/knowledge-center/Breadcrumb';
import { Faq } from 'components/knowledge-center/Faq';
import ImageArticleList from 'components/knowledge-center/ImageArticleList';
import NavigationList from 'components/knowledge-center/NavigationList';
import SubmitTicketSection from 'components/knowledge-center/SubmitTicketSection';
import Skeleton from 'components/Skeleton';
import {
  KNOWLEDGE_CENTER_TYPE_INSPIRATION,
  KNOWLEDGE_CENTER_TYPE_SUPPORT,
} from 'globals/constants';
import { CategorySlug } from 'globals/types';
import { useCategoryType } from 'hooks/knowledge-center';
import { useCategories } from 'hooks/queries/support';
import { useTranslate } from 'hooks/translate';
import Error404 from 'pages/Error404';

export default function Category() {
  const translateText = useTranslate();
  const navigate = useNavigate();
  const params = useParams<{ category: string; categoryType: CategorySlug }>();
  const { categories, isLoading } = useCategories();

  const { categoryTypeId, categoryTypeSlug } = useCategoryType(params?.categoryType ?? null);

  const category = categories?.find(
    category =>
      category.type === categoryTypeId &&
      (category.slugs.find(slug => slug.slug === params.category) ||
        category.id === Number(params.category)),
  );

  useEffect(() => {
    if (
      category &&
      (params.category !== category.main_slug || params.categoryType !== categoryTypeSlug)
    ) {
      navigate(
        `/knowledge-center/${categoryTypeSlug}/category/${encodeURIComponent(category.main_slug)}`,
        { replace: true },
      );
    }
  }, [category, categoryTypeSlug, navigate, params.category, params.categoryType]);

  if (!isLoading && !category) return <Error404 />;

  const description = category?.description.split('__GAL__').map((part, index) => {
    return <p key={index} className="main-text" dangerouslySetInnerHTML={{ __html: part }} />;
    // TODO AC-7857
    // return isNaN(Number(part)) ? (
    //   <p key={index} className="main-text" dangerouslySetInnerHTML={{ __html: part }} />
    // ) : (
    //   <Gallery key={index} galleryId={part} />
    // );
  }) ?? <Skeleton count={3} />;

  function getArticles() {
    if (categoryTypeId === KNOWLEDGE_CENTER_TYPE_INSPIRATION) {
      return (
        <ImageArticleList
          articles={category?.articles ?? []}
          categoryState={{ categoryName: category?.name, categorySlug: category?.main_slug }}
          isLoading={isLoading}
        />
      );
    }

    return (
      <NavigationList
        title={translateText('label', 'Articles')}
        list={category?.articles ?? []}
        titleKey="article_title"
        onClick={item =>
          navigate(`/knowledge-center/article/${encodeURIComponent(item.article_slug)}`, {
            state: { categoryName: category?.name, categorySlug: category?.main_slug },
          })
        }
        isLoading={isLoading}
      />
    );
  }

  return (
    <div className={'content-wrapper category-details ' + categoryTypeSlug}>
      <div className="title-container">
        <Breadcrumb
          categoryType={categoryTypeSlug ?? null}
          category={category ? { title: category.name, slug: category.main_slug } : null}
          isLoading={isLoading}
        />
        <h1 className="bold">
          {isLoading ? <Skeleton width={200} /> : category?.name ?? ''}
          {isLoading ? (
            <Skeleton width={50} height={50} />
          ) : (
            <img alt="" src={category?.icon ?? ''} />
          )}
        </h1>
        {description}
      </div>
      {categoryTypeId === KNOWLEDGE_CENTER_TYPE_SUPPORT && (
        <SubmitTicketSection isLoading={isLoading} />
      )}
      {getArticles()}
      {((isLoading && categoryTypeId !== KNOWLEDGE_CENTER_TYPE_SUPPORT) ||
        !!category?.faqs.length) && (
        <Faq faqs={category?.faqs ?? []} categoryTypeId={categoryTypeId} isLoading={isLoading} />
      )}
    </div>
  );
}
