import { isEmpty, keyBy, mapValues, pickBy } from 'lodash';

import { ReactComponent as Minus } from 'assets/images/icons/minus.svg';
import Select from 'components/input/Select';
import TextField from 'components/input/TextField';
import { PH_MASK } from 'globals/constants';
import { useLocations } from 'hooks/queries/aen';
import { useKeystrokeMenu } from 'hooks/queries/domain';
import { useEvaluationOptions } from 'hooks/queries/evaluation';
import { useOffsitesForGoogleAds } from 'hooks/queries/integration';
import { useTranslate } from 'hooks/translate';

type BaseProps = {
  operand: { value: string; setValue: (value: string) => void };
  value: { value: string; setValue: (value: string) => void };
  disabled?: boolean;
  keyOptions: Record<string, string>;
};

type NewConditionProps = {
  conditionKey: { value: string; setValue: (value: string) => void };
  conditions: { key: string; operand: string; value: string }[]; // All the existing conditions for the current filter
  onDelete?: never;
} & BaseProps;

type ExistingConditionProps = {
  conditionKey: string;
  conditions?: never;
  onDelete: () => void;
} & BaseProps;

export default function Condition({
  keyOptions,
  conditionKey,
  operand,
  value,
  conditions,
  onDelete,
  disabled = false,
}: NewConditionProps | ExistingConditionProps) {
  const translateText = useTranslate();

  const { locations } = useLocations({ active: 1 });
  const { keystrokeMenu } = useKeystrokeMenu();
  const { evaluationOptions } = useEvaluationOptions();
  const { offsiteNumbers } = useOffsitesForGoogleAds(
    Object.keys(keyOptions).includes('offsite_number'),
  );

  const isNew = typeof conditionKey !== 'string'; // The key of existing conditions cannot be changed, so if the key is a string, the condition already exists
  const key = isNew ? conditionKey.value : conditionKey;

  const operandOptions = {
    '==': translateText('label', 'Is equal to').toLowerCase(),
    '!=': translateText('label', 'Is not equal to').toLowerCase(),
    ...(key.replace('adcalls_', '') === 'evaluation_value'
      ? {
          '>': translateText('label', 'Is greater than').toLowerCase(),
          '<': translateText('label', 'Is less than').toLowerCase(),
          '>=': translateText('label', 'Is greater than or equal to').toLowerCase(),
          '<=': translateText('label', 'Is less than or equal to').toLowerCase(),
        }
      : {}),
  };

  const locationOptions = mapValues(keyBy(locations ?? [], 'aen_identifier'), location =>
    location.name ? location.name + ' (' + location.aen_identifier + ')' : location.aen_identifier,
  );
  const filteredLocations = pickBy(
    locationOptions,
    (_, identifier) =>
      !(conditions ?? []).find(c => c.key.includes('location') && c.value === identifier),
  );

  const filteredEvaluations = (evaluationOptions ?? []).filter(
    e => !(conditions ?? []).find(c => c.key.includes('evaluation') && c.value === e),
  );

  const filteredKeystrokeMenu = (keystrokeMenu ?? []).filter(
    k => !(conditions ?? []).find(c => c.key.includes('selection_menu') && c.value === k),
  );

  const offsiteOptions = mapValues(keyBy(offsiteNumbers ?? [], 'number'), offsite => offsite.name);
  const filteredOffsites = pickBy(
    offsiteOptions,
    (_, number) =>
      !(conditions ?? []).find(c => c.key.includes('offsite_number') && c.value === number),
  );

  const filteredKeys = pickBy(keyOptions, (_, key) => {
    switch (key.replace('adcalls_', '')) {
      case 'location':
        return !isEmpty(filteredLocations);
      case 'evaluation':
        return !isEmpty(filteredEvaluations);
      case 'selection_menu':
        return !isEmpty(filteredKeystrokeMenu);
      case 'offsite_number':
        return !isEmpty(filteredOffsites);
      default:
        return !(conditions ?? []).find(c => c.key === key);
    }
  });

  function getValueOptions() {
    switch (key.replace('adcalls_', '')) {
      case 'location':
        return isNew ? filteredLocations : locationOptions;
      case 'evaluation':
        return isNew ? filteredEvaluations : evaluationOptions ?? [];
      case 'selection_menu':
        return isNew ? filteredKeystrokeMenu : keystrokeMenu ?? [];
      case 'offsite_number':
        return isNew ? filteredOffsites : offsiteOptions;
      default:
        return { No: translateText('label', 'No'), Yes: translateText('label', 'Yes') };
    }
  }

  function getValueField() {
    switch (key.replace('adcalls_', '')) {
      case 'select':
        return <TextField value={value.value} disabled wrapperClassName="full" />;
      case 'evaluation_value':
        return (
          <TextField
            value={value.value}
            onChange={e => value.setValue(e.target.value.replace(/[^0-9]/g, ''))}
            disabled={disabled}
            wrapperClassName="full"
          />
        );
      default: {
        return (
          <Select
            value={value.value}
            onChange={e => value.setValue(e.target.value)}
            options={getValueOptions()}
            disabled={disabled}
            className={'full ' + PH_MASK}
          />
        );
      }
    }
  }

  return (
    <div className="input-field">
      <div className="condition-wrapper">
        <div className="condition-row">
          {!isNew ? (
            <span className="condition-key">{keyOptions[conditionKey]}</span>
          ) : (
            <Select
              value={conditionKey.value}
              options={filteredKeys}
              disabled={disabled || isEmpty(filteredKeys)}
              onChange={e => {
                conditionKey.setValue(e.target.value);
                operand.setValue('==');
                switch (e.target.value.replace('adcalls_', '')) {
                  case 'location':
                    value.setValue(Object.keys(filteredLocations)[0]);
                    break;
                  case 'evaluation':
                    value.setValue(filteredEvaluations[0]);
                    break;
                  case 'selection_menu':
                    value.setValue(filteredKeystrokeMenu[0]);
                    break;
                  case 'offsite_number':
                    value.setValue(Object.keys(filteredOffsites)[0]);
                    break;
                  case 'answered':
                  case 'returning_caller':
                  case 'second_event':
                    value.setValue('Yes');
                    break;
                  case 'evaluation_value':
                    value.setValue('1');
                    break;
                  default:
                    value.setValue('');
                    break;
                }
              }}
              addSelect
            />
          )}
          <Select
            value={operand.value}
            options={operandOptions}
            onChange={e => operand.setValue(e.target.value)}
            disabled={disabled || key === 'select'}
          />
        </div>
        <div className="condition-value">{getValueField()}</div>
      </div>
      {!isNew && (
        <button className="btn btn-small-round btn-purple" disabled={disabled} onClick={onDelete}>
          <Minus />
        </button>
      )}
    </div>
  );
}
