import { useState } from 'react';
import { useNavigate } from 'react-router';
import moment from 'moment';

import { ReactComponent as ArrowBlock } from 'assets/images/icons/arrow-block.svg';
import AutoGrid from 'components/AutoGrid';
import UpdatesAndRecommendations from 'components/overview/UpdatesAndRecommendations';
import ReportsPage from 'components/ReportsPage';
import Widget from 'components/widget/Widget';
import { useTenant } from 'contexts/Tenant';
import { DATE_PREVIOUS_TYPE_PERIOD } from 'globals/constants';
import { useOverviewWidgetContent } from 'hooks/content';
import { useIsFetchingReport } from 'hooks/fetching';
import { useDomainAuth } from 'hooks/queries/domain';
import { useUpdatesAndRecommendations } from 'hooks/queries/user';
import { useTranslate } from 'hooks/translate';

export default function Overview() {
  const navigate = useNavigate();
  const translateText = useTranslate();
  const isLoadingReports = useIsFetchingReport(['overview']);
  const { isLoading: isLoadingUpdates } = useUpdatesAndRecommendations();
  const { selectedDomain } = useTenant();
  const { domainAuth } = useDomainAuth();

  const isLoading = isLoadingReports || isLoadingUpdates;
  const [selectedCallTracking, setSelectedCallTracking] = useState('sessions');
  const [selectedFormTracking, setSelectedFormTracking] = useState('call_requests');
  const {
    callTrackingWidgetKeys,
    formTrackingWidgetKeys,
    getCallTrackingWidgetBySelected,
    getFormTrackingWidgetBySelected,
  } = useOverviewWidgetContent();

  const queryParams = {
    domain: selectedDomain,
    from: moment().subtract(28, 'days').format('DD-MM-YYYY'),
    to: moment().subtract(1, 'days').format('DD-MM-YYYY'),
    comparison_type: DATE_PREVIOUS_TYPE_PERIOD,
  };

  function getTitle(text: string, link: string) {
    return (
      <h1 onClick={() => navigate(link)}>
        {text}
        <ArrowBlock />
      </h1>
    );
  }

  return (
    <ReportsPage
      queryKey={['overview']}
      className="overview"
      download={{
        endpoint: '/reports/overview',
        disabled: isLoading,
        filters: {
          custom: { selectedKey: selectedCallTracking, ...queryParams },
        },
      }}
    >
      <UpdatesAndRecommendations isLoading={isLoading} />
      {getTitle(translateText('label', 'Call Tracking'), '/call-tracking/reports/overview')}
      <Widget
        widgetKey={['overview', 'callTracking']}
        endpoint="/widget/call-tracking-overview"
        type="statistics"
        selected={selectedCallTracking}
        updateSelected={value => setSelectedCallTracking(value)}
        dataKeys={callTrackingWidgetKeys}
        isLoading={isLoading}
        requestParams={{ bodyParams: {}, queryParams }}
      />
      <AutoGrid>
        <Widget
          widgetKey={['overview', 'callTracking', 'details']}
          gridColumns={6}
          endpoint="/widget/calls"
          type="area"
          hideTooltipTitle
          requestParams={{ bodyParams: {}, queryParams }}
          dataKeys={getCallTrackingWidgetBySelected(selectedCallTracking)[0]}
          tickFormatter={getCallTrackingWidgetBySelected(selectedCallTracking)[1]}
          dateAsTooltipKey
          isLoading={isLoading}
        />
      </AutoGrid>
      {domainAuth?.ft_enabled && (
        <>
          {getTitle(translateText('label', 'Form Tracking'), '/form-tracking/reports/overview')}
          <Widget
            widgetKey={['overview', 'formTracking']}
            endpoint="/widget/form-tracking-overview"
            type="statistics"
            requestParams={{ bodyParams: {}, queryParams }}
            selected={selectedFormTracking}
            updateSelected={value => setSelectedFormTracking(value)}
            dataKeys={formTrackingWidgetKeys}
            isLoading={isLoading}
          />
          <AutoGrid>
            <Widget
              widgetKey={['overview', 'formTracking', 'details']}
              endpoint="/widget/call-requests-by-date"
              type="area"
              requestParams={{ bodyParams: {}, queryParams }}
              dataKeys={getFormTrackingWidgetBySelected(selectedFormTracking)[0]}
              tickFormatter={getFormTrackingWidgetBySelected(selectedFormTracking)[1]}
              hideTooltipTitle
              dateAsTooltipKey
              gridColumns={6}
              isLoading={isLoading}
            />
          </AutoGrid>
        </>
      )}
    </ReportsPage>
  );
}
