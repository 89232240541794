import { CSSProperties, Fragment, LegacyRef, ReactElement, useState } from 'react';
import { Formik } from 'formik';
import { get, set } from 'lodash';

import acceptIcon from 'assets/images/icons/accept.svg';
import addIcon from 'assets/images/icons/add.svg';
import cancelIcon from 'assets/images/icons/delete-purple.svg';
import TextField from 'components/input/TextField';
import { useTranslate } from 'hooks/translate';
import EditableJsonRow from './EditableJsonRow';

type View = 'table' | 'input' | 'preview';

type BaseProps = {
  id?: string;
  initialView?: View;
  canEdit?: boolean | string[]; // Pass an array of keys if only certain keys of the object can be edited
  inputRef?: LegacyRef<HTMLInputElement> | ((elementRef: HTMLInputElement) => void);
};

type PropsWithValue = {
  value: string;
  onChange: (value: string) => void;
  initialValue?: never;
} & BaseProps;

type PropsWithInitialValue = {
  id: string;
  value?: never;
  onChange?: never;
  initialValue: string;
} & BaseProps;

function checkJson(value: string) {
  try {
    JSON.parse(value);
    return true;
  } catch {
    return false;
  }
}

export function parseValue(value: string) {
  try {
    return JSON.parse(value);
  } catch {
    return {};
  }
}

export default function EditableJson({
  id,
  value,
  onChange,
  initialValue,
  inputRef,
  initialView = 'table',
  canEdit = true,
}: PropsWithValue | PropsWithInitialValue) {
  const translateText = useTranslate();

  const [view, setView] = useState<View>(initialView);
  const [internalValue, setInternalValue] = useState(initialValue);

  const currentValue = value ?? internalValue ?? '{}';
  const [isValid, setIsValid] = useState(checkJson(currentValue));

  const parsedValue = parseValue(currentValue);

  function handleChange(value: string, checkInput = false) {
    if (checkInput) setIsValid(checkJson(value));
    if (onChange) onChange(value);
    else setInternalValue(value);
  }

  let mainElement = null;
  if (view === 'preview') {
    mainElement = <pre>{JSON.stringify(parsedValue, null, 4)}</pre>;
  } else if (view === 'table') {
    mainElement = (
      <table>
        <tbody>{getRows(parsedValue)}</tbody>
      </table>
    );
  }

  function getRows(
    object: object,
    path = '',
    level = 0,
    canEditParent = canEdit === true,
  ): ReactElement {
    const isArray = Array.isArray(object);

    function updateRow(value: unknown) {
      // Set the entire object when it's level 0, otherwise only part of the object
      if (level === 0) handleChange(JSON.stringify(value));
      else handleChange(JSON.stringify(set(parsedValue, path, value)));
    }

    return (
      <>
        {Object.entries(object ?? {}).map(([key, value]) => {
          const canEditCurrent = (Array.isArray(canEdit) && canEdit.includes(key)) || canEditParent;
          return (
            <Fragment key={path + key}>
              <EditableJsonRow
                objectKey={key}
                value={value}
                parent={level === 0 ? parsedValue : get(parsedValue, path)}
                handleChange={value => updateRow(value)}
                level={level}
                canEdit={canEditCurrent}
              />
              {typeof value === 'object' &&
                getRows(value, path + '[' + key + ']', level + 1, canEditCurrent)}
            </Fragment>
          );
        })}
        {canEditParent && (
          <Formik
            initialValues={{ view: 'add', key: '' }}
            onSubmit={values => {
              if (isArray) updateRow([...object, values.key]);
              else updateRow({ ...object, [values.key]: '' });
            }}
          >
            {({ values, handleChange, setFieldValue, submitForm, resetForm }) => (
              <tr>
                {level > 0 && (
                  <td>
                    <span style={{ '--level': level } as CSSProperties}>↳</span>
                  </td>
                )}
                <td colSpan={level === 0 && !isArray ? 3 : 2}>
                  {values.view === 'add' ? (
                    <img
                      alt="add"
                      src={addIcon}
                      className="clickable-icon"
                      onClick={() => setFieldValue('view', 'input')}
                    />
                  ) : (
                    <TextField name="key" value={values.key} onChange={handleChange} autoFocus />
                  )}
                </td>
                {values.view === 'input' && (
                  <>
                    <td className="align-right">
                      <img
                        alt="accept"
                        src={acceptIcon}
                        className="clickable-icon"
                        onClick={() => submitForm().then(() => resetForm())}
                      />
                    </td>
                    <td>
                      <img
                        alt="cancel"
                        src={cancelIcon}
                        className="clickable-icon"
                        onClick={() => resetForm()}
                      />
                    </td>
                  </>
                )}
              </tr>
            )}
          </Formik>
        )}
      </>
    );
  }

  return (
    <div className="editable-json">
      <TextField
        id={id}
        wrapperClassName={view !== 'input' ? 'hide' : ''}
        value={currentValue}
        onChange={e => handleChange(e.target.value, true)}
        inputRef={inputRef}
      />
      {mainElement}
      {!isValid && (
        <small className="purple-text">{translateText('message', 'JSON is not valid.')}</small>
      )}
      {canEdit && (
        <div className="buttons">
          {view !== 'table' && (
            <button className="btn btn-lightblue" onClick={() => setView('table')}>
              {translateText('label', 'Edit')}
            </button>
          )}
          {view !== 'input' && (
            <button className="btn btn-lightblue" onClick={() => setView('input')}>
              {translateText('label', 'Edit raw')}
            </button>
          )}
          {view !== 'preview' && (
            <button
              className="btn btn-lightblue"
              onClick={() => setView('preview')}
              disabled={!isValid}
            >
              {translateText('label', 'Preview')}
            </button>
          )}
        </div>
      )}
    </div>
  );
}
