import { PropsWithChildren, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useQueryClient } from '@tanstack/react-query';

import { useGlobal } from 'contexts/Global';
import { useSession } from 'contexts/Session';
import { useTenant } from 'contexts/Tenant';
import { Role } from 'globals/types';
import { useHasRoleAccess } from 'hooks/access';
import Error404 from 'pages/Error404';
import BaseRoute from './BaseRoute';

type Props = {
  checkDomain?: boolean;
  role?: Role;
};

export default function AuthenticatedRoute({
  checkDomain = false,
  role,
  children,
}: PropsWithChildren<Props>) {
  const queryClient = useQueryClient();
  const location = useLocation();
  const hasRoleAccess = useHasRoleAccess();
  const { token } = useSession();
  const { forceReload, setForceReload, redirectTo, setRedirectTo } = useGlobal();
  const { selectedDomain } = useTenant();
  const [redirectUrl, setRedirectUrl] = useState({ pathname: '/login', search: '' });
  const [shouldRedirect, setShouldRedirect] = useState(!token);

  useEffect(() => {
    if (!token) setShouldRedirect(true);
  }, [token]);

  useEffect(() => {
    if (forceReload) {
      setForceReload(false);
      queryClient.clear();
      setTimeout(() => window.location.reload(), 500);
    }
  }, [forceReload, queryClient, setForceReload]);

  useEffect(() => {
    if (
      !shouldRedirect &&
      checkDomain &&
      !selectedDomain &&
      !['/domain-overview', '/reset'].includes(location.pathname)
    ) {
      setRedirectUrl({ pathname: '/domain-overview', search: '' });
      setShouldRedirect(true);
      setRedirectTo({ pathname: location.pathname, search: location.search });
    } else if (selectedDomain && redirectTo !== false) {
      setRedirectUrl(redirectTo);
      setShouldRedirect(true);
      setRedirectTo(false);
    } else if (location.pathname === redirectUrl.pathname) {
      setShouldRedirect(false);
    }
  }, [
    selectedDomain,
    location.pathname,
    location.search,
    shouldRedirect,
    checkDomain,
    redirectTo,
    setRedirectTo,
    redirectUrl,
  ]);

  if (role !== undefined && !hasRoleAccess(role)) return <Error404 />;

  return (
    <BaseRoute redirectTo={redirectUrl} shouldRedirect={shouldRedirect}>
      {children}
    </BaseRoute>
  );
}
