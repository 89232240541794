import { useEffect } from 'react';
import { keepPreviousData, QueryKey, useQuery, useQueryClient } from '@tanstack/react-query';
import { Formik } from 'formik';
import { camelCase, get, isEmpty } from 'lodash';

import refresh from 'assets/images/icons/refresh-dark.svg';
import TextField from 'components/input/TextField';
import Skeleton from 'components/Skeleton';
import { useFilters } from 'contexts/Filters';
import { useSession } from 'contexts/Session';
import { useTenant } from 'contexts/Tenant';
import { useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';
import DownloadButtons from './DownloadButtons';
import Table, { TableProps } from './Table';

type Props<T> = {
  tableKey: QueryKey; // Unique key for the table, used to store filters and for react query
  dataKey?: string; // Use this if the data set is an object with multiple keys and you need only one
  endpoint: string; // The endpoint of the table data
  filters?: Filters; // Filters that are used as query parameters
  // Add pagination to the table
  pagination?:
    | true
    | {
        hideOnOne?: boolean; // Hide pagination when there's only one page
        defaultLimit?: number; // Set the default for the pagination limit. Default is set by `DEFAULT_PAGE_LIMIT`..
        hideLimit?: boolean; // Hide the selector to select limit
      };
  // Add the option to download the table data in csv or pdf
  download?: Download;
  previousPeriodColumn?: string; // The column you want to compare previous period with
  canSearch?: boolean; // Add a global search field to the table
  refresh?: boolean | QueryKey; // Add a button to refresh the data. If you pass a query key, this query will be reset on refresh
  isLoading?: boolean; // Use this when the loading state is dependent on more requests than just the one in the table
} & Omit<TableProps<T>, 'data' | 'previousData' | 'pagination'>;

export type Filters = {
  domain?: boolean; // Adds the selected domain, this is true by default
  date?: boolean; // Adds the selected date from the reports filter
  previousPeriod?: boolean; // Adds the selected previous period from the reports filter
  traffic?: boolean; // Adds the traffic filter from the reports filter
  sources?: boolean; // Adds the sources filter from the reports filter
  locations?: boolean; // Adds the locations filter from the reports filter
  marked?: boolean; // Adds the marked filter from the reports filter
  statusCallTracking?: boolean; // Adds the status filter from the reports filter
  statusFormTracking?: boolean; // Adds the status filter from the reports filter
  unique?: boolean; // Adds the unique filter from the reports filter
  duration?: boolean; // Adds the duration filter from the reports filter
  statusGA4?: boolean; // Adds the Google Analytics 4 status filter from the reports filter
  statusGoogleAds?: boolean; // Adds the Google Ads status filter from the reports filter
  statusGoogleAdsEC?: boolean; // Adds the Google Ads Enhanced Conversions status filter from the reports filter
  statusMicrosoftAdvertising?: boolean; // Adds the Microsoft Advertising status filter from the reports filter
  statusDoubleClick?: boolean; // Adds the Double Click status filter from the reports filter
  statusLef?: boolean; // Adds the Lef status filter from the reports filter
  custom?: Record<string, unknown>; // Adds custom query parameters
};

export type Download =
  | 'csv'
  | 'pdf'
  | {
      csv?: boolean;
      pdf?: boolean;
      name?: string; // Add a name for the downloadable file
      filters?: Record<string, unknown>; // Add specific query filters to the download request
    };

export const DEFAULT_PAGE_LIMIT = 10 as const;

export default function QueryTable<T extends Record<string, unknown>>(props: Props<T>) {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { token } = useSession();
  const { selectedDomain } = useTenant();
  const { filters, updateTableFilter, getAppliedFilters } = useFilters();

  const tableKey = camelCase(props.tableKey.join(' '));
  const currentFilters = filters.tables?.[tableKey];

  // Set pagination filters on first table render
  useEffect(() => {
    if (props.pagination && !currentFilters) {
      updateTableFilter({
        table: tableKey,
        type: 'pagination',
        value: {
          page: 1,
          pageLimit:
            (typeof props.pagination !== 'boolean' && props.pagination.defaultLimit) ||
            DEFAULT_PAGE_LIMIT,
        },
      });
    }
  }, [tableKey]); // eslint-disable-line react-hooks/exhaustive-deps

  const { bodyParams, queryParams } = getAppliedFilters(
    props.filters ?? {},
    tableKey,
    !!props.pagination,
  );

  const {
    isPending: isLoadingFirst,
    isFetching,
    isError,
    data: response,
    refetch,
  } = useQuery({
    queryKey: [...props.tableKey, { ...bodyParams, ...queryParams }],
    queryFn: () =>
      fetchData(props.endpoint, {
        method: isEmpty(bodyParams) ? 'GET' : 'POST',
        bodyParams,
        queryParams,
        includeHeaders: true,
      }),
    enabled:
      token !== null &&
      (props.filters?.domain === false || selectedDomain !== null) &&
      (!props.pagination || !!currentFilters) && // This check ensures that the data is not fetched twice when the pagination limit is different than default
      (!props.filters?.locations || bodyParams.hasOwnProperty('aen')) &&
      (!props.filters?.sources || bodyParams.hasOwnProperty('source')), // This check is included to ensure that report data is not fetched when locations and sources have not been fetched yet
    placeholderData: keepPreviousData,
  });

  const isLoading = !!props.isLoading || isFetching;
  const data: T[] = props.dataKey ? get(response?.data, props.dataKey) : response?.data;

  const pagination = {
    ...currentFilters?.pagination,
    pageCount: Number(response?.headers.get('x-pagination-page-count') ?? 1),
    itemCount: Number(response?.headers.get('x-pagination-total-count') ?? 0),
  };
  const showPagination =
    !isLoadingFirst &&
    !!props.pagination &&
    pagination?.itemCount > 1 &&
    (props.pagination === true || !props.pagination?.hideOnOne || pagination?.pageCount > 1);

  let globalSearch = null;
  if (props.canSearch) {
    globalSearch = (
      <div className="global-search">
        {isLoading ? (
          <Skeleton className="btn" width={200} />
        ) : (
          <Formik
            initialValues={{ search: currentFilters?.globalSearch ?? '' }}
            onSubmit={values =>
              updateTableFilter({ table: tableKey, type: 'globalSearch', value: values.search })
            }
          >
            {({ values, handleSubmit, handleChange, submitForm }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  name="search"
                  value={values.search}
                  onChange={handleChange}
                  onBlur={submitForm}
                  placeholder={translateText('label', 'Search')}
                />
              </form>
            )}
          </Formik>
        )}
      </div>
    );
  }

  let refresher = null;
  if (props.refresh) {
    refresher = (
      <div className="refresher">
        {isLoading ? (
          <Skeleton isCircle width={15} height={15} />
        ) : (
          <img
            alt="refresh"
            onClick={() => {
              refetch();
              if (typeof props.refresh !== 'boolean') {
                queryClient.resetQueries({ queryKey: props.refresh });
              }
            }}
            className="clickable"
            src={refresh}
          />
        )}
      </div>
    );
  }

  function handlePaginationSelect(page: number, pageLimit: number) {
    updateTableFilter({
      table: tableKey,
      type: 'pagination',
      value: { ...currentFilters.pagination, page, pageLimit },
    });
  }

  return (
    <Table
      {...props}
      data={data ?? []}
      previousData={
        response?.data?.prevData && {
          data: response?.data?.prevData,
          column: props.previousPeriodColumn,
        }
      }
      isLoading={isLoading}
      emptyText={
        isError
          ? translateText('message', 'Something went wrong, please try again later.')
          : props.emptyText
      }
      pagination={
        props.pagination
          ? {
              show: showPagination,
              page: pagination?.page ?? 1,
              pageLimit: pagination?.pageLimit ?? DEFAULT_PAGE_LIMIT,
              itemCount: pagination?.itemCount ?? 0,
              pageCount: pagination?.pageCount ?? 1,
              update: (page, limit) => handlePaginationSelect(page, limit),
              hideLimit: props.pagination !== true && props.pagination?.hideLimit,
            }
          : undefined
      }
      extraTopRight={
        (props.extraTopRight || refresher || props.download || globalSearch) && (
          <>
            {props.extraTopRight}
            {refresher}
            <DownloadButtons
              endpoint={props.endpoint}
              download={props.download}
              filters={props.filters}
              isLoading={isLoading}
            />
            {globalSearch}
          </>
        )
      }
    />
  );
}
