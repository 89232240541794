import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useMessages } from 'contexts/Messages';
import { useTenant } from 'contexts/Tenant';
import { useSetQueryData } from 'hooks/data';
import { nsUrl, useFetch } from 'hooks/fetch';
import { useTranslate } from 'hooks/translate';

export type BeepNotificationValues = {
  enabled: boolean;
  filename: '25%' | '50%' | '75%' | '100%';
  interval: number;
};

type BeepNotification = BeepNotificationValues & { domain_id: number };

export function useBeepNotification() {
  const { fetchData } = useFetch();
  const { selectedDomain } = useTenant();

  const { data, isLoading } = useQuery<BeepNotification>({
    queryKey: ['beepNotification', selectedDomain],
    queryFn: () => fetchData(`/beep-notification/${selectedDomain}`, { url: nsUrl }),
    enabled: selectedDomain !== null,
    meta: { noError: true }, // The endpoint returns 404 when there is no setting yet but this shouldn't be shown
  });

  return { beepNotification: data, isLoading };
}

export function useCreateBeepNotification() {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const { mutateAsync } = useMutation({
    mutationFn: (beepNotification: BeepNotificationValues) =>
      fetchData(`/beep-notification`, {
        method: 'POST',
        bodyParams: { ...beepNotification, domain_id: selectedDomain },
        url: nsUrl,
      }),
    onSuccess: data => {
      queryClient.setQueryData<BeepNotification>(['beepNotification', selectedDomain], data);
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { createBeepNotification: mutateAsync };
}

export function useUpdateBeepNotification() {
  const setQueryData = useSetQueryData();
  const translateText = useTranslate();
  const { fetchData } = useFetch();
  const { addSuccessMessage } = useMessages();
  const { selectedDomain } = useTenant();

  const { mutateAsync } = useMutation({
    mutationFn: (beepNotification: BeepNotificationValues) =>
      fetchData(`/beep-notification/${selectedDomain}`, {
        method: 'PUT',
        bodyParams: { ...beepNotification, domain_id: selectedDomain },
        url: nsUrl,
      }),
    onSuccess: data => {
      setQueryData<BeepNotification>(['beepNotification', selectedDomain], () => data);
      addSuccessMessage(translateText('message', 'Changes saved'));
    },
  });

  return { updateBeepNotification: mutateAsync };
}
