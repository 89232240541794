import { useCallback, useState } from 'react';
import { useFormikContext } from 'formik';
import { includes } from 'lodash';

import AddButton from 'components/buttons/AddButton';
import CardListView from 'components/configuration/card-list/CardListView';
import LocationCard from 'components/configuration/locations/LocationCard';
import LocationSetup, {
  LocationFormValues,
} from 'components/configuration/locations/LocationSetup';
import ErrorTooltip from 'components/ErrorTooltip';
import { INTL_PLAN_IDS } from 'globals/constants';
import { useTranslate } from 'hooks/translate';
import { useValidationSchema } from 'hooks/validation';
import { LocationItem } from 'pages/authenticated/call-tracking/config/Locations';
import { IntakeFormValues, IntakeSection } from 'pages/authenticated/requests/Intake';

export default function Locations() {
  const translateText = useTranslate();
  const { getOffsiteValidation } = useValidationSchema();
  const [showEdit, setShowEdit] = useState<'new' | number | false>(false);

  const { values: intakeValues, setFieldValue, errors } = useFormikContext<IntakeFormValues>();

  const locations = intakeValues.destinations !== 'offsite_only' ? intakeValues.destinations : [];
  const trackingType = translateText('label', 'Dynamic distribution (distinguish visitors)');

  const save = useCallback(
    (values: LocationFormValues) => {
      const locations =
        intakeValues.destinations !== 'offsite_only' ? intakeValues.destinations : [];
      const index = showEdit === 'new' ? locations.length : showEdit;
      locations[index as number] = {
        name: values.name,
        offsite: values.addedOffsites,
        destination: values.destination,
        area: values.area,
        country: values.country,
        netnumber: values.country + String(values.area),
        tracking_type: trackingType,
        aen_identifier: '',
        identifier: '',
      };
      setFieldValue('destinations', locations);
      return Promise.resolve();
    },
    [intakeValues.destinations, showEdit, trackingType, setFieldValue],
  );

  const confirmText = translateText('message', 'Are you sure you want to delete this location?');
  const deleteLocation = useCallback(
    (location: LocationItem) => {
      if (intakeValues.destinations === 'offsite_only') return; // Should never happen, but needed to tell typescript destinations is an array

      if (window.confirm(confirmText)) {
        if (intakeValues.destinations.length === 1) {
          setFieldValue('destinations', 'offsite_only');
        } else {
          setFieldValue(
            'destinations',
            intakeValues.destinations.filter(l => l.name !== location.name),
          );
        }
        setShowEdit(false);
      }
    },
    [intakeValues.destinations, setFieldValue, confirmText],
  );

  const error =
    Object.keys(intakeValues.validated).includes(IntakeSection.CallTracking) &&
    (errors.offsite_numbers as string); // Offsite error because it checks both locations and offsites

  return (
    <>
      {intakeValues.destinations !== 'offsite_only' ? (
        <CardListView
          setEdit={value => setShowEdit(value)}
          items={locations}
          renderContent={location => <LocationCard location={location} isIntake />}
          noAccessCheck
        />
      ) : (
        <ErrorTooltip
          error={error}
          html={
            <div className={'white-block no-data' + (error ? ' error' : '')} tabIndex={0}>
              <AddButton
                onClick={() => setShowEdit('new')}
                tooltipText={translateText(
                  'tooltip/intake',
                  'Click the + to set the locations for Call Tracking.',
                )}
              />
              {translateText('label', 'No locations set')}
            </div>
          }
          className="no-data"
        />
      )}
      <LocationSetup
        item={
          showEdit !== false ? (typeof showEdit === 'number' ? locations[showEdit] : 'new') : false
        }
        close={() => setShowEdit(false)}
        onSave={save}
        deleteLocation={deleteLocation}
        intake
        canChangeCountry={includes(INTL_PLAN_IDS, intakeValues.pricingPlanId)}
        validationSchema={getOffsiteValidation(
          // The validation for a location is the same as an offsite, we pass the current locations minus the one being edited to check for unique names
          intakeValues.destinations !== 'offsite_only'
            ? intakeValues.destinations.filter((_, index) => showEdit !== index)
            : [],
          translateText('message', 'A location with this name has already been requested.'),
        )}
      />
    </>
  );
}
