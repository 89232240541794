export default {
    "Click the user's row to change its rights.": "Klik op de rij van de gebruiker om de rechten ervan te wijzigen.",
    "Do you want this user to have access to specific locations of the domain? Then choose this option and select the locations which the user is allowed to access.": "Wil je dat deze gebruiker toegang heeft tot specifieke locaties van het domein? Kies dan voor deze optie en selecteer de locaties waartoe de gebruiker toegang mag krijgen.",
    "Here you see which 'customer center' this domain belongs to.": "Hier zie je onder welk \u2018Klantcentrum\u2019 dit domein valt.",
    "The rights of the user.": "De rechten van de gebruiker.",
    "The status of the user.": "De status van de gebruiker.",
    "This is the date the domain was delivered by AdCalls.": "Dit is de datum waarop het domein is opgeleverd vanuit AdCalls.",
    "This is the unique identifier that belongs to the domain.": "Dit is het unieke id dat toebehoort aan het domein.",
    "This is the website for which the services of AdCalls are used.": "Dit is de website waarvoor de diensten van AdCalls worden ingezet.",
    "You can track up to 5 locations within the trial period. If you want to track more than 5 locations, you can choose to wait for the trial period to end or immediately convert to a paid pricing plan.": "Binnen de proefperiode kun je maximaal 5 locaties meten. Wil je meer dan 5 locaties meten, dan kun je ervoor kiezen de proefperiode af te wachten of direct om te zetten in een betaald prijsplan."
}