import icon from 'assets/images/icons/integrations/pipedrive.svg';
import BaseCrmIntegration from 'components/integrations/BaseCrmIntegration';
import { REDIRECT_URI_PIPEDRIVE } from 'components/oauth2/Verification';
import { BaseSlideInProps } from 'components/slide-in/SlideIn';
import { INTEGRATION_PIPEDRIVE } from 'globals/constants';
import { useHasAccessToIntegration } from 'hooks/access';
import { useTranslate } from 'hooks/translate';

export default function Pipedrive({ show, close }: BaseSlideInProps) {
  const translateText = useTranslate();
  const hasAccessToIntegration = useHasAccessToIntegration();

  if (!hasAccessToIntegration(INTEGRATION_PIPEDRIVE)) return null;

  return (
    <BaseCrmIntegration
      show={show}
      close={close}
      icon={icon}
      integrationKey="pipedrive"
      redirectUriKey={REDIRECT_URI_PIPEDRIVE}
      title={translateText('label', 'Pipedrive')}
      infoText={translateText(
        'integration',
        'Pipedrive is a CRM tool that helps you manage leads and deals. Using this integration you can enrich the leads in Pipedrive with the data from incoming calls.',
      )}
      verificationTooltip={translateText(
        'integration',
        'Grant access to the {integration} account so that the calls can be processed in {integration}.',
        { integration: translateText('label', 'Pipedrive') },
      )}
      price={99}
    />
  );
}
