import { isValidElement, ReactNode } from 'react';
import { findKey, includes, isEqual, omit, pickBy } from 'lodash';

import {
  PLAN_ENTERPRISE_ID,
  PLAN_ENTERPRISE_INTL_ID,
  PLAN_OPTIMIZE_ID,
  PLAN_OPTIMIZE_INTL_ID,
} from 'globals/constants';
import { IntakeFormValues } from 'pages/authenticated/requests/Intake';

// Get dirty values from form
export function getDirtyValues<T extends Record<string, unknown>>(
  values: T,
  initialValues: T,
): Partial<T> {
  const dirtyKeys = Object.keys(values).filter(key => !isEqual(values[key], initialValues[key]));
  return pickBy<T>(values, (_, key) => dirtyKeys.includes(key));
}

// Check if type is valid React Node
export function isReactNode(item: unknown): item is ReactNode {
  return isSingleReactNode(item) || (Array.isArray(item) && item.every(e => isSingleReactNode(e)));
}

function isSingleReactNode(item: unknown) {
  return (
    item === null ||
    item === undefined ||
    typeof item === 'string' ||
    typeof item === 'number' ||
    typeof item === 'boolean' ||
    isValidElement(item)
  );
}

// Update destination when country or area is changed
export function changeCountryAndArea(
  country: number,
  area: number | null,
  values: { country: number; area: number | null; destination: string },
  setFieldValue: (field: string, value: number | string | null) => void,
) {
  let destination = values.destination;
  if (
    destination.replace(/\D/g, '') === '' ||
    destination === String(values.country || '') ||
    destination === String(values.country || '') + (values.area || '')
  ) {
    destination = String(country) + String(area ?? '');
  }

  setFieldValue('country', country);
  setFieldValue('area', area);
  setFieldValue('destination', destination);
}

export function pricingPlanCategoryHasChanged(
  oldPricingPlan: string | null,
  newPricingPlan: string,
) {
  if (!oldPricingPlan) return false;

  const categories = {
    optimize: [PLAN_OPTIMIZE_ID, PLAN_OPTIMIZE_INTL_ID],
    enterprise: [PLAN_ENTERPRISE_ID, PLAN_ENTERPRISE_INTL_ID],
  };

  const oldCategory = findKey(categories, plans => includes(plans, oldPricingPlan));
  const newCategory = findKey(categories, plans => includes(plans, newPricingPlan));

  return oldCategory !== newCategory;
}

// Get intake values to send to API
export function getIntakeValues(intake: IntakeFormValues, concept = false) {
  return {
    ...omit(intake, [
      'id',
      'section',
      'validated',
      'urlScheme',
      'url',
      'pricingPlanId',
      'zipcode',
      'format',
      'termsAndConditions',
      'agreement',
      'privacy',
      'reseller_id',
      'invoice_profile_id',
    ]),
    domain_title: intake.url,
    domain_url: intake.urlScheme.concat(intake.url),
    chargebee_pricing_plan_id: intake.pricingPlanId,
    invoice_profile_id: intake.invoice_profile_id || null,
    postal_code: intake.zipcode,
    reseller_id: intake.reseller_id === 'select' ? null : intake.reseller_id,
    numberformat: String(intake.format ?? ''),
    data_processor_agreement: intake.privacy,
    concept,
  };
}
