import { useFormikContext } from 'formik';

import { useUpdateEffect } from 'hooks/effect';

type Props = {
  element?: Element | null;
  onValidate?: boolean;
};

export function focusErrorElement(element?: Element | null) {
  const el = (element ?? document).querySelector<HTMLElement>('.error, [data-error]');
  el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  el?.focus({ preventScroll: true });
}

export default function ErrorFocus({ element, onValidate }: Props) {
  const { isValidating, isSubmitting } = useFormikContext();

  const dependencies = [isSubmitting];
  if (onValidate) dependencies.push(isValidating);

  useUpdateEffect(() => {
    focusErrorElement(element);
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
}
