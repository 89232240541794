import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';

import attachmentIcon from 'assets/images/icons/support-attachment.svg';
import Breadcrumb from 'components/knowledge-center/Breadcrumb';
import NavigationList from 'components/knowledge-center/NavigationList';
import SubmitTicketSection from 'components/knowledge-center/SubmitTicketSection';
import RandomWidthSkeleton from 'components/RandomWidthSkeleton';
import Skeleton from 'components/Skeleton';
import { useGlobal } from 'contexts/Global';
import { useSession } from 'contexts/Session';
import { KNOWLEDGE_CENTER_TYPE_SUPPORT } from 'globals/constants';
import { useCategoryType } from 'hooks/knowledge-center';
import { useArticle } from 'hooks/queries/support';
import { useTranslate } from 'hooks/translate';
import Error404 from 'pages/Error404';

export default function Article() {
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();
  const translateText = useTranslate();
  const { token } = useSession();
  const { scrollTo } = useGlobal();

  const params = useParams<{ article: string }>();
  const { article, isError } = useArticle(params.article);
  const { categoryTypeId, categoryTypeSlug } = useCategoryType(article?.category_type ?? null);
  const { categorySlug, categoryName } = location.state ?? {};

  const [articleElement, setArticleElement] = useState<HTMLDivElement | null>(null);
  const articleRef = useCallback((node: HTMLDivElement | null) => {
    // This ref is made with useCallback instead of useRef to make sure that the
    // component rerenders when the ref changes
    setArticleElement(node);
  }, []);

  useEffect(() => {
    if (article && params.article !== article.main_slug) {
      queryClient.setQueryData(['knowledgeCenter', 'article', article.main_slug], () => article); // Set the article data in the query client so that it does not fetch it again
      navigate(`/knowledge-center/article/${encodeURIComponent(article.main_slug)}`, {
        replace: true,
      });
    }
  }, [article, navigate, params.article, queryClient]);

  if (isError) return <Error404 />;

  let wrapperClass = 'content-wrapper article';
  if (categoryTypeSlug !== null) wrapperClass += ' ' + categoryTypeSlug;

  const content = article?.content.split('__GAL__').map((part, index) => {
    return <article key={index} dangerouslySetInnerHTML={{ __html: part }} />;
    // TODO AC-7857
    // return isNaN(Number(part)) ? (
    //   <article key={index} dangerouslySetInnerHTML={{ __html: part }} />
    // ) : (
    //   <Gallery key={index} galleryId={part} />
    // );
  });

  return (
    <div className={wrapperClass}>
      <div className="title-wrapper">
        <Breadcrumb
          categoryType={categoryTypeSlug}
          category={categoryName ? { title: categoryName, slug: categorySlug } : null}
          article={article ? { title: article.title, slug: article.main_slug } : null}
          isLoading={article === undefined}
        />
        <h1 className="article-title">{article?.title ?? <Skeleton width="min(80%, 600px)" />}</h1>
        <div className="created-by">
          {article ? (
            <>
              {moment(article.created_on, 'YYYY-MM-DD HH:mm:ss').format('ll')}{' '}
              {translateText('label', 'By').toLowerCase()}{' '}
              <span className="author">{article.author}</span>
            </>
          ) : (
            <Skeleton width={200} />
          )}
        </div>
      </div>
      {categoryTypeId === KNOWLEDGE_CENTER_TYPE_SUPPORT && <SubmitTicketSection />}
      {content ? (
        <div className="article-content" ref={articleRef}>
          {content}
          {!!article?.attachments.length && (
            <div className="attachments">
              <h2>{translateText('label', 'Attachments')}</h2>
              {article.attachments.map((attachment, index) => (
                <div className="attachment" key={index}>
                  <img src={attachmentIcon} alt="attachment" />
                  <a href={attachment.path} target="_blank">
                    {attachment.name}
                  </a>
                </div>
              ))}
            </div>
          )}
        </div>
      ) : (
        <div className="fade-out article-content">
          <p>
            <RandomWidthSkeleton count={8} />
          </p>
          <p className="margin-top">
            <RandomWidthSkeleton count={5} />
          </p>
          <p className="margin-top">
            <RandomWidthSkeleton count={10} />
          </p>
        </div>
      )}
      <NavigationList
        title={translateText('label', 'Content')}
        list={Array.from(articleElement?.querySelectorAll('h1,h2') ?? [])}
        titleKey="textContent"
        isLoading={article === undefined}
        onClick={(item, index) => {
          const element = articleElement?.querySelectorAll('h1,h2')[index];
          if (element) {
            scrollTo({
              top: element.getBoundingClientRect().top + window.pageYOffset - 75,
              behavior: 'smooth',
            });
          }
        }}
      />
      {!!token && (
        <NavigationList
          title={translateText('label', 'Related')}
          list={article?.related_articles ?? []}
          titleKey="related_article_title"
          isLoading={article === undefined}
          onClick={item =>
            navigate('/knowledge-center/article/' + encodeURIComponent(item.related_article_slug))
          }
        />
      )}
    </div>
  );
}
