import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { includes } from 'lodash';
import remarkGemoji from 'remark-gemoji';

import Select from 'components/input/Select';
import Loader from 'components/Loader';
import Pagination from 'components/Pagination';
import { USER_DEVS } from 'globals/constants';
import { useReleaseNotes, useSendReleaseNotes } from 'hooks/queries/github';
import { useUser } from 'hooks/queries/user';
import { useTranslate } from 'hooks/translate';

export default function ReleaseNotes() {
  const translateText = useTranslate();
  const { user } = useUser();
  const { sendRelease, isSending } = useSendReleaseNotes();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [method, setMethod] = useState('sprint');
  const [openRow, setOpenRow] = useState<number | false>(false);

  const { releaseNotes, isLoading, pageCount, itemCount } = useReleaseNotes(page, limit, method);

  function getRows() {
    if (isLoading || !releaseNotes.length) {
      return (
        <div className="white-block no-data">
          {isLoading ? <Loader /> : translateText('label', 'No data')}
        </div>
      );
    }

    return releaseNotes.map((row, i) => {
      const isOpen = openRow === i;
      const isPreRelease = !!row.draft;
      const isDev = includes(USER_DEVS, user?.id);
      // Notes can only be sent once, except for sprint notes, which can be sent once before the
      // release as a pre-release, and once after the actual release
      const canSendNotes = row.sent_at === null && (method === 'sprint' || !isPreRelease);

      return (
        <div key={i} className="row-wrapper">
          <div className="white-block clickable" onClick={() => setOpenRow(isOpen ? false : i)}>
            <div className="release-row">
              {row.name}
              <div>
                {isDev && canSendNotes && (
                  <button
                    className="btn btn-text no-padding margin-right"
                    onClick={e => {
                      e.stopPropagation();
                      sendNotes(row, isPreRelease);
                    }}
                    disabled={isSending}
                  >
                    {isPreRelease
                      ? translateText('label', 'Send pre-release')
                      : translateText('label', 'Send')}
                  </button>
                )}
                <div className={'arrow arrow-' + (isOpen ? 'down-right' : 'right')} />
              </div>
            </div>
          </div>
          {isOpen && <div className="white-block">{getReleaseNotesPerRepo(i)}</div>}
        </div>
      );
    });
  }

  function getReleaseNotesPerRepo(index: number) {
    const storyNumber = /\[AC-([0-9]*)](?!\()/g; // Get text `[AC-` followed by a number and a `]`. But the next character is not a `(`.
    const pullRequestNumber = /\(#([0-9]*)\)/g; // Get text `(#` followed by a number and a `)`
    const comment = /(<!-- ).{1,}(-->)/g;
    return Object.entries(releaseNotes[index].data).map(([repo, notes], index) => (
      <div key={index}>
        {index !== 0 && <hr />}
        <h1 className="repo-title">
          <div>
            {repo.charAt(0).toUpperCase() + repo.substring(1)}{' '}
            {!!notes.draft && <span className="no-data"> (draft)</span>}
          </div>
          {!!notes.released_at && <small>Released at: {notes.released_at}</small>}
        </h1>
        <ReactMarkdown remarkPlugins={[remarkGemoji]}>
          {notes.notes
            .replace(storyNumber, '[[AC-$1](https://adcalls.atlassian.net/browse/AC-$1)]')
            .replace(pullRequestNumber, `([#$1](https://github.com/AdCalls/${repo}/pull/$1))`)
            .replace(comment, '')}
        </ReactMarkdown>
      </div>
    ));
  }

  function updatePage(page: number, limit: number) {
    setPage(page);
    setLimit(limit);
  }

  function sendNotes(
    row: { name: string; data: Record<string, Record<string, string>> },
    isPreRelease: boolean,
  ) {
    sendRelease({
      name: method === 'sprint' ? row.name : row.data[method].release_name,
      method,
      isPreRelease,
    });
  }

  let title = translateText('label', 'Release notes from {repo}', { repo: method });
  if (method === 'sprint') title = translateText('label', 'Release notes per sprint');
  return (
    <>
      <h1>{translateText('label', 'Release notes')}</h1>
      <div className="white-block release-select">
        <p>{translateText('message', 'Select a method to show the release notes.')}</p>
        <Select
          options={{
            'sprint': translateText('label', 'Per sprint'),
            'dashboard': 'Dashboard',
            'react': 'Oude dashboard',
            'api': 'API',
            'asterisk-scripts': 'Asterisk Scripts',
            'asterisk-config': 'Asterisk Config',
          }}
          value={method}
          onChange={e => setMethod(e.target.value)}
          disabled={isLoading || isSending}
        />
      </div>
      <div className="release-notes">
        <h1>{title}</h1>
        {getRows()}
        {!isLoading && !!releaseNotes.length && (
          <Pagination
            activePage={page}
            totalItemsCount={itemCount}
            totalPageCount={pageCount}
            itemsCountPerPage={limit}
            onChange={(page, limit) => updatePage(page, limit)}
          />
        )}
      </div>
    </>
  );
}
