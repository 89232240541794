// Data processor agreement
export const LATEST_DPA_VERSION = '1.0.11' as const;

// PostHog classnames
export const PH_MASK = 'ph-mask' as const;
export const PH_NO_CAPTURE = 'ph-no-capture' as const;

// Languages
export const LANGUAGE_DUTCH = 'nl-NL' as const;
export const LANGUAGE_ENGLISH_US = 'en-US' as const;
export const LANGUAGE_ENGLISH_GB = 'en-GB' as const;

// Service types
export const TYPE_CALL_TRACKING = 'call-tracking';
export const TYPE_FORM_TRACKING = 'form-tracking';

// Roles
export const ROLE_SYSTEM = 'system' as const;
export const ROLE_ADMIN = 'admin' as const;
export const ROLE_EMPLOYEE = 'employee' as const;
export const ROLE_MANAGER = 'manager' as const;
export const ROLE_CUSTOMER = 'customer' as const;
export const ROLE_MANAGER_ID = 4 as const;
export const ROLE_CUSTOMER_ID = 6 as const;
export const ROLE_ID_MAP = {
  [ROLE_MANAGER_ID]: ROLE_MANAGER,
  [ROLE_CUSTOMER_ID]: ROLE_CUSTOMER,
} as const;
export const ROLE_HIERARCHY = [
  ROLE_SYSTEM,
  ROLE_ADMIN,
  ROLE_EMPLOYEE,
  ROLE_MANAGER,
  ROLE_CUSTOMER,
] as const;

// Pricing plans
export const PLAN_LIGHT = 'light' as const;
export const PLAN_LIGHT_INTL = 'light-intl' as const;
export const PLAN_BASIC = 'basic' as const;
export const PLAN_BASIC_INTL = 'basic-intl' as const;
export const PLAN_NONE = 'none' as const;
export const PLAN_PREMIUM_INTL = 'premium-intl' as const;
export const PLAN_OPTIMIZE = 'optimize' as const;
export const PLAN_OPTIMIZE_INTL = 'optimize-intl' as const;
export const PLAN_ENTERPRISE = 'enterprise' as const;
export const PLAN_ENTERPRISE_INTL = 'enterprise-intl' as const;
export const PLAN_HIERARCHY = {
  [PLAN_BASIC]: 1,
  [PLAN_BASIC_INTL]: 1,
  [PLAN_LIGHT]: 2,
  [PLAN_LIGHT_INTL]: 2,
  [PLAN_NONE]: 3,
} as const;
export const PLAN_LIGHT_ID = 'light' as const;
export const PLAN_BASIC_ID = 'basic' as const;
export const PLAN_LIGHT_INTL_ID = 'light-international' as const;
export const PLAN_BASIC_INTL_ID = 'basic-international' as const;
export const PLAN_OPTIMIZE_ID = 'optimize' as const;
export const PLAN_ENTERPRISE_ID = 'enterprise' as const;
export const PLAN_OPTIMIZE_INTL_ID = 'optimize-international' as const;
export const PLAN_ENTERPRISE_INTL_ID = 'enterprise-international' as const;
export const INTL_PLAN_IDS = [PLAN_OPTIMIZE_INTL_ID, PLAN_ENTERPRISE_INTL_ID] as const;
export const INTL_PLANS = [
  PLAN_LIGHT_INTL,
  PLAN_BASIC_INTL,
  PLAN_OPTIMIZE_INTL,
  PLAN_ENTERPRISE_INTL,
] as const;
export const BASIC_PLANS = [PLAN_BASIC, PLAN_BASIC_INTL] as const;
export const LIGHT_PLANS = [PLAN_LIGHT, PLAN_LIGHT_INTL] as const;
export const OPTIMIZE_PLANS = [PLAN_OPTIMIZE, PLAN_OPTIMIZE_INTL] as const;
export const ENTERPRISE_PLANS = [PLAN_ENTERPRISE, PLAN_ENTERPRISE_INTL] as const;

export const NEW_PROPOSITION_PLANS = [
  PLAN_OPTIMIZE,
  PLAN_OPTIMIZE_INTL,
  PLAN_ENTERPRISE,
  PLAN_ENTERPRISE_INTL,
] as const;

// Users
export const USER_PAUL = 1305 as const;
export const USER_BRAM = 1308 as const;
export const USER_MANNO = 3329 as const;
export const USER_ANNA = 5749 as const;
export const USER_MARCO = 6006 as const;
export const USER_RICHARD = 6053 as const;
export const USER_DEVS = [
  USER_PAUL,
  USER_BRAM,
  USER_MANNO,
  USER_ANNA,
  USER_MARCO,
  USER_RICHARD,
] as const;

// Contract duration
const ENTERPRISE_DURATIONS = [12, 24] as const;
const OPTIMIZE_DURATIONS = [6, ...ENTERPRISE_DURATIONS] as const;
export const CONTRACT_DURATIONS: Record<string, readonly number[]> = {
  [PLAN_OPTIMIZE_ID]: OPTIMIZE_DURATIONS,
  [PLAN_OPTIMIZE_INTL_ID]: OPTIMIZE_DURATIONS,
  [PLAN_ENTERPRISE_ID]: ENTERPRISE_DURATIONS,
  [PLAN_ENTERPRISE_INTL_ID]: ENTERPRISE_DURATIONS,
} as const;

// User statuses
export const USER_STATUS_DELETED = 0 as const;
export const USER_STATUS_INACTIVE = 5 as const;
export const USER_STATUS_ACTIVE = 10 as const;
export const USER_STATUS_INVITED = 15 as const;
export const USER_STATUS_EMAIL_VERIFICATION = 16 as const;
export const USER_STATUS_LOCKED = 20 as const;
export const USER_STATUS_CLASSES = {
  [USER_STATUS_DELETED]: 'inactive' as const,
  [USER_STATUS_INACTIVE]: 'inactive' as const,
  [USER_STATUS_ACTIVE]: 'active' as const,
  [USER_STATUS_INVITED]: 'invited' as const,
  [USER_STATUS_EMAIL_VERIFICATION]: 'invited' as const,
  [USER_STATUS_LOCKED]: 'inactive' as const,
};

// Domain statuses
export const DOMAIN_STATUS_STOPPED = 0 as const;
export const DOMAIN_STATUS_INACTIVE = 1 as const;
export const DOMAIN_STATUS_REQUESTED_NOT_SIGNED = 4 as const;
export const DOMAIN_STATUS_REQUESTED = 5 as const;
export const DOMAIN_STATUS_INACTIVE_NOT_SIGNED = 10 as const;
export const DOMAIN_STATUS_BEFORE_TRIAL = 15 as const;
export const DOMAIN_STATUS_ACTIVE = 20 as const;

// SIP trunks
export const SIP_TRUNK_DSTNY_PBX_LIVE = 'SIP/208896 - Motto PBX Live' as const;
export const SIP_TRUNK_DSTNY_PBX_TEST = 'SIP/209522 - Motto PBX Test' as const;
export const SIP_TRUNK_DSTNY_PBX_AD_CALLS = 'SIP/209524 - Motto PBX AdCalls' as const;
export const SIP_TRUNK_DSTNY_PBX_ELEVATE = 'SIP/209527 - Motto PBX Elevate' as const;
export const SIP_TRUNK_DSTNY_VPS_LIVE = 'SIP/224471 - Motto VPS Live' as const;
export const SIP_TRUNK_DSTNY_VPS_TEST = 'SIP/224470 - Motto VPS Test' as const;
export const SIP_TRUNK_DSTNY_VPS_LIVE_2 = 'SIP/238050 - Motto VPS Live 2' as const;
export const SIP_TRUNK_DSTNY_PROBLEMEN_KWIJT = 'SIP/812331 - Dstny Problemen Kwijt' as const;
export const SIP_TRUNK_VOXBONE = 'Voxbone' as const;
export const SIP_TRUNK_STEAM_CONNECT_LIVE = 'SIP/TRK794152 - In2voice Live' as const;
export const SIP_TRUNK_STEAM_CONNECT_PROD = 'SIP/TRK482596 - In2voice Prod' as const;
export const SIP_TRUNK_STEAM_CONNECT_TEST = 'SIP/TRK551961 - In2voice Test' as const;

// Domains
export const DEMO_DOMAIN = 1 as const;
export const CALLTRACKINGSOFTWARE = 260 as const;
export const RIOOLPROBLEMENKWIJT = 297 as const;
export const BEESTJESKWIJTBE = 486 as const;
export const UNGEZIEFERRAUS = 529 as const;
export const BEESTJESKWIJT = 579 as const;
export const RIOOLPROBLEMENKWIJTBE = 934 as const;
export const MERIDE = 1315 as const;
export const ELEVATE_DEV = 1664 as const;
export const VIABOVAG = 2100 as const;
export const VIABOVAG_TEST = 2102 as const;

// Change requests
export const CHANGE_REQUEST_STATUS_OPEN = 1 as const;
export const CHANGE_REQUEST_STATUS_PENDING = 2 as const;
export const CHANGE_REQUEST_STATUS_ON_HOLD = 3 as const;
export const CHANGE_REQUEST_STATUS_PROCESSED = 4 as const;
export const CHANGE_REQUEST_STATUS_VERIFIED = 5 as const;
export const CHANGE_REQUEST_STATUS_REFUSED = 6 as const;
export const CHANGE_REQUEST_STATUS_CANCELLED = 7 as const;
export const CHANGE_REQUEST_STATUS_REVERSED = 8 as const;

// Locations
export const LOCATION_STATUS_INACTIVE = 0 as const;
export const LOCATION_STATUS_IN_REQUEST = 5 as const;
export const LOCATION_STATUS_ACTIVE = 10 as const;

// Source state types
export const SOURCE_TYPE_ALL = 'all' as const;
export const SOURCE_TYPE_EXCLUDE = 'exc' as const;
export const SOURCE_TYPE_INCLUDE = 'inc' as const;
export const SOURCE_TYPE_NOT = 'not' as const;

// Implementation types
export const IMPLEMENTATION_TYPE_STANDARD = 0 as const;
export const IMPLEMENTATION_TYPE_ADVANCED = 1 as const;

// Traffic types
export const TRAFFIC_TYPE_DYNAMIC = 0 as const;
export const TRAFFIC_TYPE_OFFSITE = 1 as const;
export const TRAFFIC_TYPE_APP = 2 as const;

// Call request statuses
export const CALL_REQUEST_STATUS_PENDING = 0 as const;
export const CALL_REQUEST_STATUS_PENDING_DISABLED = 1 as const;
export const CALL_REQUEST_STATUS_IN_PROCESS = 2 as const;
export const CALL_REQUEST_STATUS_INVALID_NUMBER = 5 as const;
export const CALL_REQUEST_STATUS_DUPLICATE = 6 as const;
export const CALL_REQUEST_STATUS_MANUALLY_STOPPED = 7 as const;
export const CALL_REQUEST_STATUS_OFFICE_CLOSED = 8 as const;
export const CALL_REQUEST_STATUS_MAXIMUM_ATTEMPTS_REACHED = 9 as const;
export const CALL_REQUEST_STATUS_SUCCESSFUL = 10 as const;

// Previous period filter
export const DATE_PREVIOUS_TYPE_PERIOD = 'previousPeriod' as const;
export const DATE_PREVIOUS_TYPE_YEAR = 'previousYear' as const;

// Offsite Numbers
export const OFFSITE_STATUS_INACTIVE = 0 as const;
export const OFFSITE_STATUS_ACTIVE = 1 as const;
export const OFFSITE_STATUS_DISCONTINUE_ACCEPTED = 2 as const;
export const OFFSITE_STATUS_DISCONTINUE_REQUESTED = 3 as const;
export const OFFSITE_STATUS_PENDING = 4 as const;

// Integration
export const INTEGRATION_INACTIVE = 0 as const;
export const INTEGRATION_ACTIVE = 1 as const;
export const INTEGRATION_ERROR = 2 as const;

export const INTEGRATION_GOOGLE_ANALYTICS_4 = 'googleAnalytics4' as const;
export const INTEGRATION_GOOGLE_ADS = 'googleAds' as const;
export const INTEGRATION_GOOGLE_ADS_ENHANCED_CONVERSIONS = 'googleAdsEC' as const;
export const INTEGRATION_MICROSOFT_ADVERTISING = 'microsoftAdvertising' as const;
export const INTEGRATION_LOOKER_STUDIO = 'lookerStudio' as const;
export const INTEGRATION_API = 'adCallsApi' as const;
export const INTEGRATION_DOUBLE_CLICK = 'doubleClick' as const;
export const INTEGRATION_GOOGLE_DISPLAY_VIDEO_360 = 'googleDisplayVideo360' as const;
export const INTEGRATION_GOOGLE_SEARCH_ADS_360 = 'googleSearchAds360' as const;
export const INTEGRATION_BGENIUS = 'bGenius' as const;
export const INTEGRATION_BIGQUERY = 'bigquery' as const;
export const INTEGRATION_RELAY42 = 'relay42' as const;
export const INTEGRATION_LEF = 'lef' as const;
export const INTEGRATION_BLUE_CONIC = 'blueConic' as const;
export const INTEGRATION_HUBSPOT = 'hubspot' as const;
export const INTEGRATION_META_ADS = 'metaAds' as const;
export const INTEGRATION_ADFORM = 'adform' as const;
export const INTEGRATION_SALESFORCE = 'salesforce' as const;
export const INTEGRATION_SALESFORCE_REQUEST = 'salesforce-request' as const;
export const INTEGRATION_MICROSOFT_DYNAMICS = 'microsoftDynamics' as const;
export const INTEGRATION_MICROSOFT_DYNAMICS_REQUEST = 'microsoftDynamics-request' as const;
export const INTEGRATION_PIPEDRIVE_REQUEST = 'pipedrive-request' as const;
export const INTEGRATION_PIPEDRIVE = 'pipedrive' as const;
export const INTEGRATION_ZOHO_REQUEST = 'zoho-request' as const;
export const INTEGRATION_ZOHO = 'zoho' as const;
export const INTEGRATION_BILLY_GRACE = 'billyGrace' as const;
export const INTEGRATION_VWO = 'vwo' as const;
export const INTEGRATION_FUTY = 'futy' as const;

// Report email frequencies
export const FREQUENCY_OFF = 0 as const;
export const FREQUENCY_WEEKLY = 1 as const;
export const FREQUENCY_MONTHLY = 2 as const;
export const FREQUENCY_DAILY = 3 as const;

// Table keys
export const TABLE_KEY_CALL_INDEX = ['callTracking', 'history', 'index'] as const;
export const TABLE_KEY_CALL_REQUEST_INDEX = ['formTracking', 'history', 'index'] as const;

// Session
export const SESSION_CHECK_INTERVAL = 60000 as const; // 1 minute
export const SESSION_EXTENSION_TIME = 1800000 as const; // 30 minutes

// Knowledge center types
export const KNOWLEDGE_CENTER_TYPE_OVERVIEW = -1 as const;
export const KNOWLEDGE_CENTER_TYPE_SUPPORT = 0 as const;
export const KNOWLEDGE_CENTER_TYPE_INSPIRATION = 1 as const;
export const KNOWLEDGE_CENTER_TYPE_MARKETING = 2 as const;

// Article statuses
export const ARTICLE_STATUS_ARCHIVED = 0 as const;
export const ARTICLE_STATUS_CONCEPT = 5 as const;
export const ARTICLE_STATUS_LOGGED_IN = 10 as const;
export const ARTICLE_STATUS_PUBLIC = 11 as const;

// Currencies
export const CURRENCIES_LIST = [
  'USD',
  'AED',
  'ARS',
  'AUD',
  'BGN',
  'BOB',
  'BRL',
  'CAD',
  'CHF',
  'CLP',
  'CNY',
  'COP',
  'CZK',
  'DKK',
  'EGP',
  'EUR',
  'FRF',
  'GBP',
  'HKD',
  'HRK',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'JPY',
  'KRW',
  'LTL',
  'MAD',
  'MXN',
  'MYR',
  'NOK',
  'NZD',
  'PEN',
  'PHP',
  'PKR',
  'PLN',
  'RON',
  'RSD',
  'RUB',
  'SAR',
  'SEK',
  'SGD',
  'THB',
  'TRY',
  'UAH',
  'VEF',
  'VND',
  'ZAR',
] as const;

// Debug mode constants
export const DEBUG_MODE_MAX_WAIT_FOR_VERIFICATION = 3000 as const;
