import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { CSSTransition } from 'react-transition-group';
import { Formik } from 'formik';
import { isEmpty, isEqual } from 'lodash';
import * as Yup from 'yup';

import alert from 'assets/images/icons/alert.svg';
import filterIcon from 'assets/images/icons/filter.svg';
import ErrorFocus from 'components/ErrorFocus';
import Skeleton from 'components/Skeleton';
import { SelectedFilters, useFilters } from 'contexts/Filters';
import { TRAFFIC_TYPE_APP, TRAFFIC_TYPE_DYNAMIC, TRAFFIC_TYPE_OFFSITE } from 'globals/constants';
import { useReportsFilterContent } from 'hooks/content';
import { useOnClickOutside } from 'hooks/event';
import { useLocations } from 'hooks/queries/aen';
import { useSources } from 'hooks/queries/source';
import { useTranslate } from 'hooks/translate';
import CheckboxList from './CheckboxList';
import DateSelector from './DateSelector';
import DurationSelector from './DurationSelector';
import RadioList from './RadioList';

type Props = {
  setShowApplied: Dispatch<SetStateAction<boolean>>;
};

export default function ReportsFilter({ setShowApplied }: Props) {
  const location = useLocation();
  const translateText = useTranslate();
  const content = useReportsFilterContent();
  const { filters, updateFilter, resetFilters } = useFilters();

  const ref = useRef(null);
  const buttonRef = useRef(null);
  const wrapperRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<string | boolean>('period');

  const { locations, isLoading: isLoadingLocations } = useLocations({
    limit: 10000,
    include_inactive: 1,
    expand: 'active',
    is_offline:
      filters.traffic?.join() ??
      [TRAFFIC_TYPE_DYNAMIC, TRAFFIC_TYPE_OFFSITE, TRAFFIC_TYPE_APP].join(),
  });

  const { sources, isLoading: isLoadingSources } = useSources({
    is_offline:
      filters.traffic?.join() ??
      [TRAFFIC_TYPE_DYNAMIC, TRAFFIC_TYPE_OFFSITE, TRAFFIC_TYPE_APP].join(),
  });
  const isLoading = isLoadingLocations || isLoadingSources;

  useOnClickOutside(wrapperRef, () => {
    if (isOpen) toggleOpen();
  });

  useEffect(() => {
    if (isOpen) toggleOpen();
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  function toggleOpen() {
    setTimeout(() => setShowApplied(isOpen), isOpen ? 300 : 0); // Timeout needed for the animation of the reports filter
    setIsOpen(open => !open);
  }

  function applyFilters(values: SelectedFilters) {
    if (values.dates.to === null) values.dates.to = values.dates.from; // "From" can never be null, but "to" can be null when a date is clicked only once
    Object.entries(values).forEach(([filterKey, value]) => {
      if (!isEqual(filters[filterKey as keyof typeof filters], value)) {
        updateFilter(filterKey, value);
      }
    });
    toggleOpen();
  }

  function clearFilters() {
    resetFilters();
    toggleOpen();
  }

  function getContent(errors: Record<string, unknown>) {
    return Object.entries(content).map(([key, filter]) => {
      const filterRow = (
        <div
          onClick={() => setSelected(selected !== key ? key : false)}
          className={'filter-option ' + (selected === key && !isLoading ? 'selected' : '')}
        >
          {isLoading ? <Skeleton /> : <div className="option-label">{filter.label}</div>}
          {!isLoading && (
            <div>
              {!!errors[key] && <img alt="error" src={alert} data-error />}
              <div className={'arrow arrow-' + (selected === key ? 'down-right' : 'right')} />
            </div>
          )}
        </div>
      );

      let filterSelector = null;
      switch (key) {
        case 'statusFormTracking':
        case 'statusGA4':
        case 'statusGoogleAds':
        case 'statusMicrosoftAdvertising':
        case 'statusDoubleClick':
        case 'statusLef':
        case 'traffic':
          filterSelector = (
            <CheckboxList
              filterKey={key}
              options={(filter as { label: string; options: Record<string, string> }).options}
            />
          );
          break;
        case 'sources': {
          const options: Record<string, string> = {};
          sources?.forEach(s => (options[s.id] = s.name));
          filterSelector = (
            <CheckboxList filterKey={key} options={options} twoColumns sort isPrivate />
          );
          break;
        }
        case 'locations': {
          const options: Record<string, { name: string; tooltip?: string; isActive?: boolean }> = {
            null: {
              name: translateText('label', 'Without location'),
              tooltip: translateText(
                'tooltip/widgets',
                'Select this option to show data that is not associated with a location.',
              ),
            },
          };
          locations?.forEach(
            l => (options[l.aen_identifier] = { name: l.name, isActive: !!l.active }),
          );
          filterSelector = (
            <CheckboxList filterKey={key} options={options} twoColumns sort isPrivate />
          );
          break;
        }
        case 'statusCallTracking':
        case 'marked':
        case 'unique':
          filterSelector = (
            <RadioList
              filterKey={key}
              options={(filter as { label: string; options: Record<string, string> }).options}
            />
          );
          break;
        case 'duration':
          filterSelector = (
            <DurationSelector
              options={(filter as { label: string; options: Record<string, string> }).options}
            />
          );
          break;
        case 'period':
          filterSelector = <DateSelector />;
      }

      return (
        <div key={key} className="filter-selector-wrapper">
          {filterRow}
          {!isLoading && selected === key && filterSelector}
        </div>
      );
    });
  }

  const validationSchema = Yup.object({
    sources: Yup.array().test({
      message: translateText('message', 'Select at least 1 traffic source.'),
      test: value => !content.sources || value?.length !== 0,
    }),
    locations: Yup.array().test({
      message: translateText('message', 'Select at least 1 location.'),
      test: value => !content.locations || value?.length !== 0,
    }),
    traffic: Yup.array().test({
      message: translateText('message', 'Select at least 1 traffic type.'),
      test: value => !content.traffic || value?.length !== 0,
    }),
    duration: Yup.object({ selected: Yup.string(), seconds: Yup.string() }).test({
      message: translateText(
        'message',
        "Enter the amount of seconds for the filter 'call duration'.",
      ),
      test: value =>
        !content.duration ||
        value?.selected === 'allCalls' ||
        (value?.seconds !== undefined && value?.seconds !== ''),
    }),
  });

  return (
    <div className="reports-filter-wrapper" ref={wrapperRef}>
      <CSSTransition
        classNames="reports-filter-button"
        in={isOpen}
        timeout={600}
        nodeRef={buttonRef}
      >
        <div className="display-button-wrapper" onClick={toggleOpen} ref={buttonRef}>
          <div className="display-button">
            <div className="arrow-container">
              <div className={'arrow arrow-white arrow-' + (isOpen ? 'down-right' : 'left')} />
            </div>
            <div className="filter-icon-wrapper">
              <img className="filter-icon" alt="filter" src={filterIcon} />
              {translateText('label', 'Filters')}
            </div>
          </div>
        </div>
      </CSSTransition>
      <CSSTransition
        classNames="reports-filter"
        in={isOpen}
        timeout={600}
        nodeRef={ref}
        unmountOnExit
      >
        <Formik<SelectedFilters>
          initialValues={filters}
          onSubmit={applyFilters}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnMount={false}
          enableReinitialize
        >
          {({ submitForm, errors }) => (
            <div id="reports-filter" ref={ref}>
              <ErrorFocus element={document.getElementById('reports-filter')} />
              <div className="content">{getContent(errors)}</div>
              {!isEmpty(errors) && (
                <div className="filter-errors">
                  {Object.values(errors).map((error, index) => (
                    <div key={index} className="message">
                      <img alt="error" src={alert} />
                      <span>{String(error)}</span>
                    </div>
                  ))}
                </div>
              )}
              <div className="buttons-wrapper">
                {isLoading ? (
                  <Skeleton className="btn btn-wide" />
                ) : (
                  <button className="btn btn-green btn-wide apply" onClick={submitForm}>
                    {translateText('label', 'Apply filters')}
                  </button>
                )}
                {isLoading ? (
                  <Skeleton width={180} />
                ) : (
                  <button className="btn btn-text btn-wide delete" onClick={clearFilters}>
                    {translateText('label', 'Clear filters')}
                  </button>
                )}
              </div>
            </div>
          )}
        </Formik>
      </CSSTransition>
    </div>
  );
}
