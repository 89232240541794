import { useCallback } from 'react';
import { useFormikContext } from 'formik';

import Sources from 'components/configuration/tracking-and-filters/Sources';
import NumberFormat from 'components/intake/NumberFormat';
import StepBlock from 'components/intake/StepBlock';
import { Offsite } from 'globals/types';
import { Location } from 'hooks/queries/aen';
import { useTranslate } from 'hooks/translate';
import { IntakeFormValues, IntakeSection } from 'pages/authenticated/requests/Intake';
import Locations from './Locations';
import OffsiteNumbers from './OffsiteNumbers';

export type CallTrackingFormValues = {
  sources: string[];
  included: 1 | 0 | null;
  format: string | number | null;
  destinations: (Location & { identifier: '' })[] | 'offsite_only';
  offsite_numbers: Offsite[];
};

export const callTrackingInitialValues = {
  sources: [],
  included: null,
  format: null,
  destinations: 'offsite_only' as const,
  offsite_numbers: [],
};

export default function CallTrackingSection() {
  const translateText = useTranslate();

  const {
    values: intakeValues,
    setFieldValue,
    errors: intakeErrors,
  } = useFormikContext<IntakeFormValues>();

  const saveSources = useCallback(
    (values: { included: 0 | 1; sources: string[] }) => {
      setFieldValue('sources', values.sources);
      setFieldValue('included', values.included);
      return Promise.resolve();
    },
    [setFieldValue],
  );

  return (
    <div className="call-tracking-section">
      <StepBlock
        number={1}
        title={translateText('label', 'Set the traffic sources you want to track')}
      >
        <Sources
          isLoading={false}
          isIntake
          onSave={saveSources}
          sources={intakeValues.sources}
          included={intakeValues.included}
          error={
            typeof intakeValues.validated[IntakeSection.CallTracking] === 'boolean' &&
            intakeErrors.included
          }
        />
      </StepBlock>
      <StepBlock
        number={2}
        title={translateText('label', 'Indicate how the phone number may be displayed')}
      >
        <NumberFormat />
      </StepBlock>
      <StepBlock number={3} title={translateText('label', 'Set the locations you want to track')}>
        <Locations />
      </StepBlock>
      <StepBlock number={4} title={translateText('label', 'Create offsite phone numbers')}>
        <OffsiteNumbers />
      </StepBlock>
    </div>
  );
}
