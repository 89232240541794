import QueryTable from 'components/table/QueryTable';
import { useTranslate } from 'hooks/translate';

type DeletedDomain = {
  sessions: string;
  sources: string;
  date: string;
  stop_date: string;
  type: string;
  domain_id: number;
  account_manager: string;
  partner: string;
  calls: string;
  domain: string;
  start_date: string;
};

export default function DeletedLog() {
  const translateText = useTranslate();

  return (
    <>
      <h1>{translateText('label', 'Deleted domain')}</h1>
      <QueryTable<DeletedDomain>
        tableKey={['admin', 'deletedDomains']}
        className="wrap"
        columns={{
          domain_id: { header: translateText('label', 'ID'), search: true, noNumberFormat: true },
          domain: { header: translateText('label', 'Domain'), search: true },
          date: { header: translateText('label', 'Deleted at'), date: { excludeTime: true } },
          partner: { header: translateText('label', 'Partner') },
          account_manager: { header: translateText('label', 'Account manager') },
          sources: { header: translateText('label', 'Sources') },
          sessions: { header: translateText('label', 'Sessions') },
          calls: { header: translateText('label', 'Calls') },
          start_date: { header: translateText('label', 'Started at'), date: { excludeTime: true } },
          stop_date: {
            header: translateText('label', 'Is stopped at'),
            date: { excludeTime: true },
          },
        }}
        endpoint="/domain/deleted-log"
        filters={{ domain: false }}
        pagination={{ defaultLimit: 50 }}
        refresh
      />
    </>
  );
}
