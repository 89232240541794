import { ReactElement, ReactNode } from 'react';
import { NameType, Payload, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { useCalculate } from 'hooks/calculate';
import { useTranslate } from 'hooks/translate';
import { BaseProps, DataProps } from './types';
import { PREVIOUS_PREFIX } from './Widget';

type Props = {
  payload?: Payload<ValueType, NameType>[];
  label?: string;
  dateAsTooltipKey?: boolean;
  isPercentage?: boolean;
  totalOther?: number;
  totalPrevOther?: number;
  emptyLabel: string;
} & BaseProps &
  DataProps;

export default function Tooltip({
  payload,
  label,
  hideTooltipTitle,
  tickFormatter,
  hideTotal,
  hideTotalIfPrevious,
  dateAsTooltipKey,
  isPercentage,
  totalOther,
  totalPrevOther,
  data,
  previousData,
}: Props) {
  const translateText = useTranslate();
  const { getPercentage, getDifference } = useCalculate();

  if (!payload?.[0]) return null;

  function getTooltipRow(
    name: NameType | undefined,
    value: ValueType | undefined,
    key: number | string,
  ) {
    return (
      <div className="tooltip-row" key={key}>
        <div className="name">{name}</div>
        <div className="value">{value}</div>
      </div>
    );
  }

  function getTooltipBase(name: string | undefined, content: ReactNode) {
    return (
      <div className="recharts-tooltip">
        {!hideTooltipTitle && <div className="name">{name}</div>}
        {content}
      </div>
    );
  }

  let total = 0;
  let difference: (string | string[])[] = [];
  const text: (ReactElement | [])[] = [];

  if (isPercentage) {
    if (data) data.forEach(data => (total += Number(data.value)));
    let text: string | ReactElement[] = getPercentage(Number(payload[0].value), total, true);

    if (previousData) {
      let prevTotal = 0;
      previousData.forEach(data => (prevTotal += Number(data.value)));

      const findData = (data: Record<string, unknown>) => data.argument === payload[0].name;
      let dataValue = (data?.find(findData)?.value ?? 0) as number;
      let prevValue = (previousData?.find(findData)?.value ?? 0) as number;
      if (
        payload[0].name === translateText('label', 'Others') &&
        totalOther !== undefined &&
        totalPrevOther !== undefined
      ) {
        dataValue = totalOther;
        prevValue = totalPrevOther;
      }

      text = [
        getTooltipRow(
          translateText('label', 'Current period') + ':',
          getPercentage(dataValue, total, true),
          'data',
        ),
        getTooltipRow(
          translateText('label', 'Previous period') + ':',
          getPercentage(prevValue, prevTotal, true),
          'prev',
        ),
        getTooltipRow(
          translateText('label', 'Difference') + ':',
          getPercentage(dataValue, prevValue, 'diff'),
          'diff',
        ),
      ];
    }

    return getTooltipBase(String(payload[0].name), <div className="value">{text}</div>);
  }

  payload.forEach((entry, index) => {
    total += Number(entry.value);
    if (!dateAsTooltipKey) {
      let value = entry.value;
      if (tickFormatter) value = tickFormatter.format(Number(value));
      text.push(getTooltipRow(entry.name, value, index));
    }
  });

  // This prop is only used on the overview page, where the x-axis shows the numbers 1-28 for the days,
  // and the tooltip should show the specific date instead of the number. It supports having one or two keys
  // in the graph.
  if (dateAsTooltipKey) {
    difference = getDifference(String(payload[1]?.value), String(payload[0]?.value), true);

    const values = payload.map(i =>
      tickFormatter ? tickFormatter.format(Number(i.value)) : i.value,
    );

    if (payload.length === 4) {
      difference = [
        getDifference(String(payload[1]?.value), String(payload[0]?.value)),
        getDifference(String(payload[3]?.value), String(payload[2]?.value)),
      ];

      const names: (NameType | undefined)[] = [];
      payload.forEach(p => {
        if (!String(p.dataKey).includes(PREVIOUS_PREFIX)) names.push(p.name);
      });

      // names.length is always 2, so i is either 0 or 1. Since each name need 2 rows, we need to increase i when it's 1.
      names.forEach((name, i) => {
        const index = i === 0 ? i : i + 1;
        text.push(
          getTooltipRow(payload[index].payload.prev_date + ' ' + name, values[index], index),
        );
        text.push(
          getTooltipRow(payload[index].payload.date + ' ' + name, values[index + 1], index + 1),
        );
      });
    } else {
      text.push(getTooltipRow(payload[0].payload.prev_date, values[0], 'prev_date'));
      text.push(getTooltipRow(payload[0].payload.date, values[1], 'date'));
    }
  }

  if (text.length === 4 && Array.isArray(difference[0])) {
    return getTooltipBase(label, [
      [text[0], text[1]],
      getTooltipRow(translateText('label', 'Difference'), difference[0][0], 1),
      [text[2], text[3]],
      getTooltipRow(translateText('label', 'Difference'), difference[1][0], 2),
    ]);
  }

  let finalRow;
  if (!hideTotal && !(previousData && hideTotalIfPrevious)) {
    if (previousData) {
      finalRow = getTooltipRow(translateText('label', 'Difference'), difference[0], 'diff');
    } else {
      finalRow = getTooltipRow(
        translateText('label', 'Total'),
        tickFormatter ? tickFormatter.format(total) : total,
        'total',
      );
    }
  }

  return getTooltipBase(label, [text, finalRow]);
}
