import { ReactElement, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Formik } from 'formik';
import { isEqual } from 'lodash';
import { Schema } from 'yup';

import AreaCodeSelector from 'components/configuration/AreaCodeSelector';
import OffsiteForm, {
  getOffsiteInitialValues,
  saveOffsite,
} from 'components/configuration/offsites/OffsiteForm';
import OffsitesTable from 'components/configuration/offsites/OffsitesTable';
import ErrorFocus from 'components/ErrorFocus';
import TextField from 'components/input/TextField';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import SetupWithSub from 'components/slide-in/SetupWithSub';
import { useTenant } from 'contexts/Tenant';
import { changeCountryAndArea } from 'globals/helpers';
import { Offsite } from 'globals/types';
import { useTrial } from 'hooks/access';
import { Location } from 'hooks/queries/aen';
import { useCancelChangeRequest } from 'hooks/queries/domain-change-request';
import { useTranslate } from 'hooks/translate';
import { useValidationSchema } from 'hooks/validation';

type LocationItem = Location & {
  identifier: string;
  isNew?: boolean;
  requestId?: number | null;
  warning?: string | null;
  extra?: ReactElement | null;
};

export type LocationFormValues = {
  name: string;
  area: number | null;
  country: number;
  destination: string;
  offsites: Offsite[];
  addedOffsites: Offsite[];
  deletedOffsites: Offsite[];
  changedOffsites: Record<number, Offsite>;
};

type Props = {
  item: LocationItem | 'new' | false;
  close: () => void;
  onSave: (values: LocationFormValues) => Promise<void>;
  deleteLocation: (location: LocationItem) => void;
  intake?: boolean;
  canChangeCountry?: boolean;
  validationSchema?: Schema;
};

export default function LocationSetup({
  item,
  close,
  onSave,
  deleteLocation,
  intake = false,
  canChangeCountry,
  validationSchema,
}: Props) {
  const queryClient = useQueryClient();
  const translateText = useTranslate();
  const { selectedDomain } = useTenant();
  const { isTrial } = useTrial();
  const { getOffsiteValidation } = useValidationSchema();
  const { cancelRequest } = useCancelChangeRequest();
  const [showOffsite, setShowOffsite] = useState<'new' | Offsite | false>(false);

  const location = typeof item === 'object' ? item : null;

  async function save(values: LocationFormValues) {
    await onSave(values).then(close);
  }

  let title = translateText('label', 'Add location');
  let infoText: string | null = translateText(
    'text',
    'Add the phone numbers you want to track for Call Tracking here. Create multiple locations for, for example, different locations or departments.',
  );
  if (location) {
    if (location?.isNew) {
      title = translateText('label', 'Requested location');
      infoText = null;
    } else {
      title = translateText('label', 'Edit location');
      infoText = translateText('text', 'Change the details of this location here.');
    }
  }

  function onCancelRequest(id: number) {
    cancelRequest(id).then(() => {
      queryClient.invalidateQueries({ queryKey: ['locations', 'index', selectedDomain] });
      close();
    });
  }

  let stopButtonBottom: ReactElement | undefined;
  let stopButtonTop: ReactElement | undefined;
  if (location?.isNew) {
    stopButtonTop = (
      <div className="location-stop-requested">
        <span>{translateText('text', 'This location is in request.')}</span>
        <button
          className="btn btn-text purple-text"
          onClick={() => onCancelRequest(location.requestId!)}
        >
          {translateText('label', 'Cancel request')}
        </button>
      </div>
    );
  } else if (location?.requestId) {
    stopButtonTop = (
      <div className="location-stop-requested">
        <span>{translateText('text', 'There is a request to stop this location.')}</span>
        <button className="btn btn-text" onClick={() => onCancelRequest(location.requestId!)}>
          {translateText('label', 'Cancel stop request')}
        </button>
      </div>
    );
  } else if (location && !location?.requestId && !location?.stop_requested_for) {
    stopButtonBottom = (
      <button className="btn btn-text purple-text" onClick={() => deleteLocation(location)}>
        {intake
          ? translateText('label', 'Remove location')
          : translateText('label', 'Stop location')}
      </button>
    );
  }

  const initialValues: LocationFormValues = {
    name: location?.name ?? '',
    area: item === 'new' || intake ? location?.area ?? null : 1,
    country: location?.country ?? 31,
    destination: location?.destination ?? '31',
    offsites: location?.offsite ?? [],
    addedOffsites: intake && location?.offsite ? location.offsite : [],
    deletedOffsites: [],
    changedOffsites: {},
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={save}
      validationSchema={validationSchema ?? getOffsiteValidation([])} // Location validation is the same as offsite but without a unique name check
      validateOnChange={false}
      enableReinitialize
    >
      {({
        values,
        handleChange,
        submitForm,
        setFieldValue,
        errors,
        dirty,
        resetForm,
        isSubmitting,
      }) => {
        const validationSchema = getOffsiteValidation(
          // To validate unique names, we need all existing + new offsites minus the one that is being edited
          [...values.offsites, ...values.addedOffsites].filter(i => !isEqual(showOffsite, i)),
        );

        return (
          <>
            <ErrorFocus element={document.getElementsByClassName('setup-wrapper')[0]} />
            <Formik // Form for the sub slide in
              initialValues={getOffsiteInitialValues(showOffsite)}
              validationSchema={validationSchema}
              onSubmit={offsiteValues =>
                saveOffsite(showOffsite, offsiteValues, values, setFieldValue, () =>
                  setShowOffsite(false),
                )
              }
              validateOnChange={false}
              validateOnMount={false}
              enableReinitialize
            >
              {({
                submitForm: submitSub,
                dirty: dirtySub,
                resetForm: resetSub,
                isSubmitting: isSubmittingSub,
              }) => (
                <>
                  <ErrorFocus element={document.getElementsByClassName('setup-wrapper')[0]} />
                  <SetupWithSub
                    show={item !== false}
                    close={close}
                    afterClose={resetForm}
                    title={title}
                    maxWidth={660}
                    appendage={stopButtonBottom}
                    hasChanges={dirty}
                    save={!location?.isNew ? submitForm : null} // Save button should not be shown if the location is in request
                    isSaving={isSubmitting}
                    closeButtonText={translateText('label', 'Close')}
                    sub={
                      <Setup
                        show={showOffsite !== false}
                        close={() => setShowOffsite(false)}
                        afterClose={resetSub}
                        save={submitSub}
                        maxWidth={400}
                        title={
                          showOffsite === 'new'
                            ? translateText('label', 'Add offsite phone number')
                            : translateText('label', 'Edit offsite phone number')
                        }
                        hasChanges={dirtySub}
                        isSaving={isSubmittingSub}
                      >
                        <OffsiteForm
                          isEdit={
                            showOffsite !== 'new' && showOffsite && !!showOffsite.numberpool_id
                          }
                        />
                      </Setup>
                    }
                  >
                    {stopButtonTop}
                    {infoText}
                    <h3 className="section-title">{translateText('label', 'Properties')}</h3>
                    <div className="double-input">
                      <InputWrapper
                        label={translateText('label', 'Name in the dashboard')}
                        tooltip={translateText(
                          'tooltip/call-tracking',
                          "Enter the name you wish to associate with the location. For example 'Location Amsterdam' or 'Support department'.",
                        )}
                      >
                        <TextField
                          onChange={handleChange}
                          value={values.name}
                          name="name"
                          error={errors.name}
                          disabled={!!location?.isNew}
                          maxLength={50}
                        />
                      </InputWrapper>
                      {location?.identifier && (
                        <InputWrapper label={translateText('label', 'AEN code')}>
                          {location?.identifier}
                        </InputWrapper>
                      )}
                    </div>
                    <div className="double-input">
                      <InputWrapper
                        label={translateText('label', 'Area code for distribution')}
                        tooltip={translateText(
                          'tooltip/call-tracking',
                          'This is the area code of the Call Tracking phone numbers that will be distributed on the website.',
                        )}
                      >
                        {location?.netnumber && !intake ? (
                          location.netnumber
                        ) : (
                          <AreaCodeSelector
                            selectedCountry={values.country}
                            selectedArea={values.area}
                            setAreaCode={(c, a) =>
                              changeCountryAndArea(c, a, values, setFieldValue)
                            }
                            areaError={errors.area}
                            canChangeCountry={canChangeCountry}
                          />
                        )}
                      </InputWrapper>
                      <InputWrapper
                        label={translateText('label', 'Destination number')}
                        tooltip={translateText(
                          'tooltip/call-tracking',
                          'This is the phone number to which calls for Call Tracking will be forwarded.',
                        )}
                      >
                        <TextField
                          name="destination"
                          value={values.destination}
                          onChange={handleChange}
                          disabled={!!location?.isNew}
                          error={errors.destination}
                        />
                      </InputWrapper>
                    </div>
                    {(!isTrial || intake) && (
                      <OffsitesTable
                        offsites={values.offsites}
                        onAdd={() => setShowOffsite('new')}
                        onEdit={setShowOffsite}
                        addedOffsites={values.addedOffsites}
                        deletedOffsites={values.deletedOffsites}
                        setAddedOffsites={items => setFieldValue('addedOffsites', items)}
                        setDeletedOffsites={items => setFieldValue('deletedOffsites', items)}
                        intake={intake}
                        addButtonDisabled={location?.isNew}
                      />
                    )}
                  </SetupWithSub>
                </>
              )}
            </Formik>
          </>
        );
      }}
    </Formik>
  );
}
