import BasicCallFunctionality from 'components/configuration/call-functionalities/BasicCallFunctionality';
import BeepNotification from 'components/configuration/call-functionalities/BeepNotification';
import CallNotifications from 'components/configuration/call-functionalities/CallNotifications';
import CallRecording from 'components/configuration/call-functionalities/CallRecording';
import ConfigurationPage from 'components/configuration/ConfigurationPage';
import {
  SUP_ART_ANNOUNCEMENT,
  SUP_ART_CALL_SELECTION_MENU,
  SUP_ART_EVALUATION_MENU,
} from 'globals/support';
import { CallFunctionalities as CallFunctionalitiesData } from 'hooks/queries/domain';
import { useUpdateBasicCallFunctionality } from 'hooks/queries/domain-setting';
import { useTranslate } from 'hooks/translate';

export default function CallFunctionalities() {
  const translateText = useTranslate();
  const { updateCallFunctionality: updateCustomerEvaluationMenu } = useUpdateBasicCallFunctionality(
    'change-customer-evaluation-menu',
    'customer_evaluation_menu',
  );
  const { updateCallFunctionality: updateEvaluationMenu } = useUpdateBasicCallFunctionality(
    'change-evaluation-menu',
    'evaluation_menu',
  );
  const { updateCallFunctionality: updateWhisper } = useUpdateBasicCallFunctionality(
    'change-whisper',
    'call_announcement',
    'whisper',
  );

  function renderExtraMenuRows(
    settings: CallFunctionalitiesData['customer_evaluation_menu' | 'evaluation_menu'] | undefined,
  ) {
    if (settings?.enabled) {
      let value = translateText('label', 'Standard');
      if (settings?.custom_menu) value = translateText('label', 'Custom');
      return [{ key: translateText('label', 'Type'), value }];
    }
    return [];
  }

  return (
    <ConfigurationPage title={translateText('label', 'Call functionalities')}>
      <CallRecording />
      <BasicCallFunctionality
        settingsKey="customer_evaluation_menu"
        updateFunctionality={updateCustomerEvaluationMenu}
        label={translateText('label', 'Selection menu')}
        renderExtraRows={renderExtraMenuRows}
        supportArticleId={SUP_ART_CALL_SELECTION_MENU}
        supportText={translateText(
          'call-tracking',
          'By activating a selection menu, your callers can indicate in advance who or what they are calling for.',
        )}
      />
      <BasicCallFunctionality
        settingsKey="evaluation_menu"
        updateFunctionality={updateEvaluationMenu}
        label={translateText('label', 'Evaluation menu')}
        renderExtraRows={renderExtraMenuRows}
        supportArticleId={SUP_ART_EVALUATION_MENU}
        supportText={translateText(
          'call-tracking',
          'By activating an evaluation menu, you can evaluate calls afterwards.',
        )}
      />
      <BasicCallFunctionality
        settingsKey="call_announcement"
        enabledKey="whisper"
        updateFunctionality={updateWhisper}
        label={translateText('label', 'Announcements')}
        supportArticleId={SUP_ART_ANNOUNCEMENT}
        supportText={translateText(
          'call-tracking',
          "By using a call announcement, you can distinguish between 'normal calls' and 'Call Tracking calls'.",
        )}
      />
      <BeepNotification />
      <CallNotifications />
    </ConfigurationPage>
  );
}
