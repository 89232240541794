import { useState } from 'react';
import { useFormikContext } from 'formik';
import { includes } from 'lodash';

import add from 'assets/images/icons/add.svg';
import { ReactComponent as Loop } from 'assets/images/icons/loop.svg';
import UnstyledButton from 'components/buttons/UnstyledButton';
import AddFormat from 'components/configuration/debug-mode/AddFormat';
import ViewFormat from 'components/configuration/debug-mode/ViewFormat';
import TextField from 'components/input/TextField';
import Skeleton from 'components/Skeleton';
import Tooltip from 'components/Tooltip';
import { STATUS_LOADING, STATUS_SCANNING, STATUS_VALID, useDebugMode } from 'contexts/DebugMode';
import { IMPLEMENTATION_TYPE_ADVANCED, IMPLEMENTATION_TYPE_STANDARD } from 'globals/constants';
import { useLocations } from 'hooks/queries/aen';
import { useAcceptedUrls, useImplementation } from 'hooks/queries/domain';
import { useTranslate } from 'hooks/translate';
import { DebugFormValues } from 'pages/authenticated/call-tracking/config/DebugMode';

const EXCLUDED = 'nonReplacedFormats' as const;
const INCLUDED = 'replacedFormats' as const;

export default function FoundFormats() {
  const translateText = useTranslate();
  const { implementation, isLoading: isLoadingImplementation } = useImplementation();
  const { isLoading: isLoadingAcceptedUrls } = useAcceptedUrls();
  const { locations } = useLocations({ active: 1 });
  const { values } = useFormikContext<DebugFormValues>();
  const { status, setStatus, foundFormats, mainIframeRef } = useDebugMode();

  const [formatType, setFormatType] = useState<typeof EXCLUDED | typeof INCLUDED>(EXCLUDED);
  const [search, setSearch] = useState('');
  const [viewFormat, setViewFormat] = useState<{
    show: boolean;
    format: string;
    location: string | null;
    classes: string[];
  }>({
    show: false,
    format: '',
    location: null,
    classes: [],
  });
  const [addFormat, setAddFormat] = useState<{
    show: boolean;
    format: string;
    location: string;
    isManual: boolean;
  }>({
    show: false,
    format: '',
    location: 'select',
    isManual: false,
  });

  const isStandardImplementation = implementation?.type === IMPLEMENTATION_TYPE_STANDARD;
  const isAdvancedImplementation = implementation?.type === IMPLEMENTATION_TYPE_ADVANCED;

  const isLoading =
    isLoadingImplementation ||
    isLoadingAcceptedUrls ||
    includes([STATUS_LOADING, STATUS_SCANNING], status);

  function postMessage(message: { type: string; [key: string]: string }) {
    mainIframeRef.current?.contentWindow?.postMessage(message, values.host);
  }

  function getContent() {
    if (isLoading) return <Skeleton count={8} hasFade />;
    if (!foundFormats) return null;

    const formats = foundFormats[formatType];
    return (
      <>
        <div className="tabs">
          <UnstyledButton
            className={'tab left' + (formatType === EXCLUDED ? ' selected' : '')}
            onClick={() => setFormatType(EXCLUDED)}
          >
            {translateText('label', 'Excluded')}
          </UnstyledButton>
          <UnstyledButton
            className={'tab right' + (formatType === INCLUDED ? ' selected' : '')}
            onClick={() => setFormatType(INCLUDED)}
          >
            {translateText('label', 'Included')}
          </UnstyledButton>
        </div>
        <div className="content">
          {Object.keys(formats).length === 0 ? (
            <div className="no-formats-box">
              {formatType === EXCLUDED
                ? translateText('label', 'No excluded formats')
                : translateText('label', 'No included formats')}
            </div>
          ) : (
            <div className="inner-content">
              <div className={`formats ${formatType}`}>
                <TextField
                  onChange={e => setSearch(e.target.value)}
                  value={search}
                  placeholder={translateText('label', 'Search')}
                />
                <div className="formats-inner">
                  {Object.entries(formats).map(([format, elementsData]) => {
                    if (search !== '' && !format.includes(search)) return null;

                    const decodedFormat = decodeURIComponent(format);
                    return (
                      <div className="format" key={`format-${decodedFormat}`}>
                        <div className="header">
                          <div className={'text' + (isAdvancedImplementation ? ' advanced' : '')}>
                            {decodedFormat}
                            {isStandardImplementation && formatType === EXCLUDED && (
                              <UnstyledButton
                                onClick={() =>
                                  setAddFormat({
                                    show: true,
                                    format,
                                    location: 'select',
                                    isManual: false,
                                  })
                                }
                              >
                                <img className="add-icon" src={add} alt="add" />
                              </UnstyledButton>
                            )}
                          </div>
                        </div>
                        <div className="elements">
                          {Object.entries(elementsData).map(
                            ([elementId, { element, type, aenIdentifier, visible }]) => {
                              let label = element.tagName;
                              if (type === 'href') label += '[href]';
                              if (element.id) label += ' #' + element.id;
                              if (element.classes?.length > 0) label += ' .' + element.classes[0];

                              let viewIcon = null;
                              if (
                                isAdvancedImplementation ||
                                (isStandardImplementation && formatType === INCLUDED)
                              ) {
                                let locationName: string | null = null;
                                const location = locations?.find(
                                  l => l.aen_identifier === aenIdentifier,
                                );
                                if (location) {
                                  locationName = location.name
                                    ? location.name + ' (' + location.aen_identifier + ')'
                                    : location.aen_identifier;
                                }
                                viewIcon = (
                                  <UnstyledButton
                                    className="view-icon"
                                    onClick={() => {
                                      setViewFormat({
                                        show: true,
                                        format,
                                        location: locationName,
                                        classes: element.classes,
                                      });
                                    }}
                                  >
                                    <Loop />
                                  </UnstyledButton>
                                );
                              }

                              const pathToElement = `${formatType}|${format}|${elementId}`;
                              let button = (
                                <div
                                  key={`element-id-${elementId}-button`}
                                  className="element-wrapper"
                                >
                                  <button
                                    className="btn btn-text element"
                                    disabled={!visible}
                                    onClick={() =>
                                      postMessage({ type: 'HIGHLIGHT_FORMAT', pathToElement })
                                    }
                                  >
                                    <span className="element-description">{label}</span>
                                  </button>
                                  {viewIcon}
                                </div>
                              );

                              if (!visible) {
                                button = (
                                  <Tooltip
                                    key={`element-id-${elementId}-tooltip`}
                                    text={translateText(
                                      'label',
                                      "The format can't be highlighted because it isn't visible in the iframe.",
                                    )}
                                    html={button}
                                  />
                                );
                              }
                              return button;
                            },
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }

  return (
    <>
      <ViewFormat {...viewFormat} close={() => setViewFormat({ ...viewFormat, show: false })} />
      <AddFormat {...addFormat} close={() => setAddFormat({ ...addFormat, show: false })} />
      <div className="side-controls white-block">
        <h3>
          {isLoading
            ? translateText('label', 'Scanning for formats')
            : translateText('label', 'Found formats')}
        </h3>
        {getContent()}
        {isLoading ? (
          <Skeleton height={38} />
        ) : (
          <button
            disabled={status !== STATUS_VALID}
            className="btn btn-green"
            onClick={() => {
              setStatus(STATUS_SCANNING);
              postMessage({ type: 'SCAN_FOR_FORMATS' });
            }}
          >
            {translateText('label', 'Scan for formats')}
          </button>
        )}

        {isLoading ? (
          <Skeleton height={38} />
        ) : (
          <button
            disabled={status !== STATUS_VALID}
            className="btn btn-lightblue"
            onClick={() =>
              setAddFormat({
                show: true,
                format: '',
                location: 'select',
                isManual: true,
              })
            }
          >
            {translateText('label', 'Add manually')}
          </button>
        )}
      </div>
    </>
  );
}
