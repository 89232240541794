import { useState } from 'react';
import { Formik } from 'formik';
import { get } from 'lodash';

import ConfigurationCard from 'components/configuration/ConfigurationCard';
import GrayBars from 'components/GrayBars';
import Toggle from 'components/input/Toggle';
import InputWrapper from 'components/slide-in/InputWrapper';
import Setup from 'components/slide-in/Setup';
import SupportParagraph from 'components/SupportParagraph';
import { CallFunctionalities, useCallFunctionalities, useDomainAuth } from 'hooks/queries/domain';

type Key = 'customer_evaluation_menu' | 'evaluation_menu' | 'call_announcement';

type Props<K extends Key> = {
  settingsKey: K;
  enabledKey?: keyof CallFunctionalities[K];
  label: string;
  updateFunctionality: (values: { enabled: boolean }) => Promise<void>;
  supportText: string;
  supportArticleId: number;
  renderExtraRows?: (
    settings?: CallFunctionalities[K],
  ) => { key: string; status?: boolean; value?: string }[];
};

export default function BasicCallFunctionality<K extends Key>({
  label,
  settingsKey,
  enabledKey,
  updateFunctionality,
  supportText,
  supportArticleId,
  renderExtraRows,
}: Props<K>) {
  const { callFunctionalities, isLoading: isLoadingCallFunctionalities } = useCallFunctionalities();
  const { isLoading: isLoadingDomainAuth } = useDomainAuth();
  const isLoading = isLoadingCallFunctionalities || isLoadingDomainAuth;
  const [showEdit, setShowEdit] = useState(false);

  const settings = callFunctionalities?.[settingsKey];

  async function save(values: { enabled: boolean }) {
    await updateFunctionality(values).then(() => {
      setShowEdit(false);
    });
  }

  const key = get(settings, enabledKey ?? 'enabled');
  const isEnabled: boolean | undefined = typeof key === 'string' ? !!key : key;

  return (
    <>
      <ConfigurationCard
        title={label}
        supportArticleId={supportArticleId}
        onEdit={() => setShowEdit(true)}
        isLoading={isLoading}
        rightParagraph={supportText}
      >
        <GrayBars
          rows={[{ key: label, status: isEnabled }, ...(renderExtraRows?.(settings) ?? [])]}
          isLoading={isLoading}
        />
      </ConfigurationCard>
      <Formik
        initialValues={{ enabled: isEnabled ?? false }}
        onSubmit={async values => await save(values)}
        enableReinitialize
      >
        {({ values, submitForm, resetForm, isSubmitting, dirty, handleChange }) => (
          <Setup
            show={showEdit}
            title={label}
            save={submitForm}
            close={() => setShowEdit(false)}
            afterClose={resetForm}
            isSaving={isSubmitting}
            maxWidth={410}
            hasChanges={dirty}
          >
            <p>{supportText}</p>
            <SupportParagraph articleId={supportArticleId} />
            <div className="section">
              <InputWrapper label={label}>
                <Toggle
                  name="enabled"
                  checked={values.enabled}
                  onClick={handleChange}
                  disabled={isSubmitting}
                />
              </InputWrapper>
            </div>
          </Setup>
        )}
      </Formik>
    </>
  );
}
