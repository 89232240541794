import { useFormikContext } from 'formik';
import moment from 'moment';

import AutoGrid from 'components/AutoGrid';
import DetailsSection from 'components/DetailsSection';
import TermsAndConditions from 'components/TermsAndConditions';
import { useTenant } from 'contexts/Tenant';
import { usePricingPlans } from 'hooks/queries/pricing';
import { useTranslate } from 'hooks/translate';
import { useUserDateFormat } from 'hooks/user';
import {
  EditSubscriptionFormValues,
  EditSubscriptionProps,
  EditSubscriptionSection,
} from './EditSubscription';
import InvoicingDetails from './InvoicingDetails';

export default function SummarySection({ type }: EditSubscriptionProps) {
  const translateText = useTranslate();
  const userDateFormat = useUserDateFormat();
  const { selectedDomainData } = useTenant();
  const { pricingPlans } = usePricingPlans();
  const { values, setFieldValue, errors, isSubmitting } =
    useFormikContext<EditSubscriptionFormValues>();

  const isValidated = typeof values.validated[EditSubscriptionSection.Summary] === 'boolean';

  return (
    <AutoGrid className="summary-section" size="large">
      <DetailsSection
        title={translateText('label', 'Properties')}
        data={{
          [translateText('label', 'Domain URL')]: { value: selectedDomainData?.url, private: true },
          [translateText('label', 'Pricing plan')]: pricingPlans?.find(
            pp => pp.id === values.pricingPlanId,
          )?.invoice_label,
          [translateText('label', 'Contract duration')]: translateText(
            'datetime',
            '{months} months',
            { months: values.contractDuration },
          ),
          [translateText('label', 'Start date')]: moment(values.startDate).format(userDateFormat),
        }}
        button={{
          text: translateText('label', 'Adjust'),
          onClick: () => setFieldValue('section', EditSubscriptionSection.Properties),
          disabled: isSubmitting,
        }}
        boldKeys
      />
      <InvoicingDetails type={type} />
      <TermsAndConditions
        termsAndConditions={{
          value: values.termsAndConditions,
          setValue: value => setFieldValue('termsAndConditions', value),
          error: isValidated && errors.termsAndConditions,
        }}
        agreement={{
          value: values.agreement,
          setValue: value => setFieldValue('agreement', value),
          error: isValidated && errors.agreement,
        }}
        className="margin-top"
        disabled={isSubmitting}
      />
    </AutoGrid>
  );
}
