import { Formik, FormikHelpers } from 'formik';
import { isEmpty, pickBy } from 'lodash';
import * as Yup from 'yup';

import ErrorFocus from 'components/ErrorFocus';
import Select from 'components/input/Select';
import TextField from 'components/input/TextField';
import { useTrigger } from 'hooks/queries/notification';
import { useTranslate } from 'hooks/translate';
import { useCustomValidation } from 'hooks/validation';
import Wysiwyg from './Wysiwyg';

export type NotificationFormValues = {
  id?: number;
  title: string;
  summary: string;
  message: string;
  language_id?: string;
  notification_id?: number;
  translations?: NotificationFormValues[];
};

type Props = {
  title: string;
  initValues: NotificationFormValues;
  onSubmit: (values: NotificationFormValues) => Promise<NotificationFormValues>;
  canChangeLanguage?: boolean;
  triggerId?: number;
  translations?: string[];
};

export default function NotificationDetails({
  title,
  initValues,
  onSubmit,
  canChangeLanguage,
  triggerId,
  translations,
}: Props) {
  const translateText = useTranslate();
  const { required } = useCustomValidation();
  const { triggerNotification } = useTrigger();

  const availableLanguages = pickBy(
    { 'en-US': translateText('label', 'English') },
    (_, key) => !translations?.includes(key),
  );

  const validationSchema = Yup.object({
    title: required,
    summary: required,
    message: Yup.string().test({
      test: value =>
        !!value && value !== '<br>' && value !== '<div></div>' && value !== '<div><br></div>',
      message: translateText('message', 'This field is required.'),
    }),
  });

  async function submit(
    values: NotificationFormValues,
    formikHelpers: FormikHelpers<NotificationFormValues>,
  ) {
    await onSubmit(values).then(() => {
      formikHelpers.resetForm();
    });
  }

  return (
    <div className="content-notifications">
      <h1>{title}</h1>
      <Formik
        initialValues={initValues}
        onSubmit={submit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({
          values,
          handleChange,
          setFieldValue,
          errors,
          submitForm,
          submitCount,
          dirty,
          isSubmitting,
        }) => (
          <>
            <ErrorFocus />
            {canChangeLanguage && values.language_id && (
              <Select
                name="language_id"
                value={values.language_id}
                onChange={handleChange}
                options={
                  !isEmpty(availableLanguages)
                    ? availableLanguages
                    : { '': translateText('label', 'No available languages') }
                }
                disabled={isEmpty(availableLanguages)}
              />
            )}
            <h2 className="margin-top">{translateText('label', 'Title')}</h2>
            <TextField
              name="title"
              value={values.title}
              onChange={handleChange}
              disabled={isEmpty(availableLanguages)}
              error={submitCount > 0 && errors.title}
            />
            <h2 className="margin-top">{translateText('label', 'Summary')}</h2>
            <TextField
              name="summary"
              type="textarea"
              value={values.summary}
              onChange={handleChange}
              disabled={isEmpty(availableLanguages)}
              error={submitCount > 0 && errors.summary}
            />
            <h2 className="margin-top">{translateText('label', 'Message')}</h2>
            <Wysiwyg
              value={values.message}
              onChange={message => setFieldValue('message', message)}
              error={submitCount > 0 && errors.message}
            />
            <button
              className="btn btn-green margin-top"
              onClick={submitForm}
              disabled={!dirty || isSubmitting || isEmpty(availableLanguages)}
            >
              {translateText('label', 'Save')}
            </button>

            {!!triggerId && (
              <button
                className="btn btn-purple margin-top float-right"
                onClick={() => triggerNotification(triggerId)}
                disabled={dirty}
              >
                {translateText('label', 'Trigger')}
              </button>
            )}
          </>
        )}
      </Formik>
    </div>
  );
}
