import { CSSProperties, PropsWithChildren, ReactElement, useEffect } from 'react';

import { usePrevious } from 'hooks/effect';
import { useTranslate } from 'hooks/translate';
import SlideIn, { SlideInProps, slideInTransitionTime } from './SlideIn';

export type SetupProps = {
  title?: string;
  icon?: string;
  appendage?: ReactElement;
  hasCloseButton?: boolean;
  closeButtonText?: string;
  afterClose?: () => void;
  save?: (() => void) | null;
  saveButtonColor?: 'green' | 'purple';
  saveButtonDisabled?: boolean;
  saveButtonText?: string;
  isSaving?: boolean;
  hasChanges?: boolean;
  alternativeButtons?: ReactElement;
  backButton?: ReactElement;
  setupStyle?: CSSProperties;
} & SlideInProps;

export default function Setup(props: PropsWithChildren<SetupProps>) {
  const translateText = useTranslate();
  const prevShow = usePrevious(props.show);
  const {
    title,
    icon,
    subSlideIn,
    save,
    saveButtonText = translateText('label', 'Save'),
    saveButtonColor = 'green',
    saveButtonDisabled = false,
    hasCloseButton = true,
    closeButtonText = translateText('label', 'Cancel'),
    alternativeButtons,
    backButton,
    appendage,
    children,
    isSaving,
    hasChanges,
    maxWidth,
    setupStyle,
  } = props;

  function closeSetup() {
    if (isSaving) return;
    if (
      !props.hasChanges ||
      window.confirm(
        translateText('message', 'You have unsaved changes! Are you sure you want to leave?'),
      )
    ) {
      props.close();
    }
  }

  useEffect(() => {
    if (props.afterClose && !props.show && prevShow) {
      setTimeout(props.afterClose, slideInTransitionTime); // Timeout so it gets called after slide in transition
    }
  }, [props.show, prevShow, props.afterClose]);

  function renderFooter() {
    const hasButtons = hasCloseButton || !!save || !!alternativeButtons;
    if (!hasButtons && !appendage) return null;

    const className = isSaving ? ' disabled' : '';
    return (
      <div className="setup-footer">
        {appendage && (
          <div className={'appendage' + (subSlideIn?.props.show ? ' sub-open' : '')}>
            {appendage}
          </div>
        )}
        {hasButtons && (
          <div className="buttons-wrapper">
            {alternativeButtons ?? (
              <>
                {hasCloseButton && (
                  <button
                    disabled={isSaving}
                    className={'btn btn-lightblue' + className}
                    onClick={() => closeSetup()}
                  >
                    {closeButtonText}
                  </button>
                )}
                {save && (
                  <button
                    disabled={hasChanges === false || saveButtonDisabled || isSaving}
                    className={'btn btn-' + saveButtonColor + className}
                    onClick={save ?? (() => null)}
                  >
                    {saveButtonText}
                  </button>
                )}
              </>
            )}
          </div>
        )}
      </div>
    );
  }

  const className = 'setup-wrapper' + (props.className ? ' ' + props.className : '');
  const minWidth = subSlideIn?.props.show
    ? 'calc(100vh - 150px)'
    : icon
      ? 'calc(100% - 100px)'
      : '100%';

  return (
    <SlideIn {...props} close={() => closeSetup()} className={className}>
      {title && (
        <div className={'header' + (icon ? ' has-icon' : '')}>
          {icon && <img alt={title} src={icon} className="setup-icon" />}
          <h3 style={{ minWidth }}>
            <span>{title}</span>
          </h3>
        </div>
      )}
      <div className="setup" style={setupStyle ?? { maxWidth }}>
        <div className="setup-body">{children}</div>
      </div>
      {backButton}
      {renderFooter()}
    </SlideIn>
  );
}
