import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import adCallsLogo from 'assets/images/adcalls-logo.svg';
import KnowledgeCenterEdit from 'components/admin/KnowledgeCenterEdit';
import NotificationCenterPreview from 'components/notification-center/Preview';
import { useRoutes } from 'contexts/Routes';
import { useSession } from 'contexts/Session';
import { useKnowledgeCenterCheck } from 'hooks/knowledge-center';
import { useIsMobileNavigation } from 'hooks/media';
import { useTranslate } from 'hooks/translate';
import DomainSelector from './DomainSelector';
import MobileMenu from './MobileMenu';
import UserData from './UserData';

type Props = {
  hasError?: boolean;
};

export default function Header({ hasError = false }: Props) {
  const translateText = useTranslate();
  const navigate = useNavigate();
  const { token } = useSession();
  const { navigationDisabled } = useRoutes();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [resetMobileMenu, setResetMobileMenu] = useState(false);
  const isKnowledgeCenter = useKnowledgeCenterCheck();
  const isMobileNavigation = useIsMobileNavigation();

  function renderMobileHamburger() {
    let hamburger, onClick;
    if (!token) {
      hamburger = <Link to="/login">{translateText('label', 'Log in')}</Link>;
    } else if (navigationDisabled) {
      hamburger = <UserData />;
    } else {
      hamburger = (
        <div className={'hamburger' + (mobileMenuOpen ? ' open' : '')} data-testid="hamburger">
          <div />
          <div />
          <div />
        </div>
      );
      onClick = () => toggleMobileMenu();
    }
    return (
      <div className="inner">
        {isMobileNavigation && (
          <div className="hamburger-wrapper" onClick={onClick}>
            {hamburger}
          </div>
        )}
        <img
          alt="AdCalls"
          className={'clickable logo' + (navigationDisabled ? ' disabled' : '')}
          src={adCallsLogo}
          onClick={() => (!navigationDisabled ? navigate('/overview') : null)}
        />
      </div>
    );
  }

  function renderSearch() {
    return null;
    // TODO AC-4943
    // if (!token || isKnowledgeCenter || navigationDisabled) {
    //   return null;
    // }
    // return (
    //   <TextField
    //     id="global-search-field"
    //     placeholder={translateText('label', 'Search')}
    //     value={this.state.search}
    //     onChange={e => this.setState({search: e.target.value})}
    //   />
    // );
  }

  function renderLeftSide() {
    if (hasError) return <img alt="AdCalls" className="logo" src={adCallsLogo} />;
    return (
      <>
        {renderMobileHamburger()}
        <DomainSelector />
      </>
    );
  }

  function renderRightSide() {
    if (hasError) {
      if (token) return <UserData hasError />;
      return (
        <Link to="/login" reloadDocument>
          {translateText('label', 'Log in')}
        </Link>
      );
    }
    return (
      <>
        {renderSearch()}
        {isKnowledgeCenter && !navigationDisabled && <KnowledgeCenterEdit />}
        <NotificationCenterPreview />
      </>
    );
  }

  function toggleMobileMenu() {
    if (mobileMenuOpen) setResetMobileMenu(true);
    setMobileMenuOpen(open => !open);
  }

  let headerColorClass = 'header-color';
  if (isKnowledgeCenter) {
    headerColorClass += ' knowledge-center';
  }
  const openClass = mobileMenuOpen ? ' open' : '';
  const setShouldReset = useCallback((value: boolean) => setResetMobileMenu(value), []);
  const setMenuOpen = useCallback((value: boolean) => setMobileMenuOpen(value), []);

  return (
    <div id="header" className={headerColorClass + openClass}>
      <div className="left-middle-wrapper">
        <div className="left-side">{renderLeftSide()}</div>
        {isMobileNavigation && mobileMenuOpen && (
          <div className="middle">
            <MobileMenu
              shouldReset={resetMobileMenu}
              setShouldReset={setShouldReset}
              setMenuOpen={setMenuOpen}
            />
          </div>
        )}
      </div>
      <div className="right-side">{renderRightSide()}</div>
    </div>
  );
}
